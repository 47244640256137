import React from 'react';
import * as T from '../../components/Typography';
import * as S from './style';
import { AutoComplete, Textfield } from '../../components/Inputs';
import { Col, Row } from '../../components/Grid';

import VerifySchoolModal from './VerifySchoolModal';
const HoldingSchoolId = process.env.REACT_APP_SF_HOLDING_ID;
const isHoldingSchool = (Id) => Id === HoldingSchoolId;

const SchoolAddress = ({ schoolDetails = {} }) => {
  const { Name: name, BillingAddress: address } = schoolDetails;

  return (
    <S.SchoolAddressWrapper>
      <T.P weight="bold">
        Please check the address and make sure you have selected the correct
        school:
      </T.P>
      {name ? <T.P>{name},</T.P> : null}
      {address?.street ? <T.P>{address.street},</T.P> : null}
      {address?.postalCode ? <T.P>{address.postalCode},</T.P> : null}
      {address?.city ? <T.P>{address.city},</T.P> : null}
      {address?.state ? <T.P>{address.state},</T.P> : null}
      {address?.country ? <T.P>{address.country}</T.P> : null}
    </S.SchoolAddressWrapper>
  );
};

const SchoolSection = ({
  schoolDetails,
  gradSchoolDetails,
  validationErrors,
  setFormData,
  initialFormState,
  schools
}) => {
  const [selectedSchoolType, setSelectedSchoolType] = React.useState(null);

  return (
    <>
      <S.Card>
        <T.H2 color="white">2. SCHOOL DETAILS</T.H2>
        <Row>
          <div key={`school-${selectedSchoolType}`} style={{ width: '100%' }}>
            <Col w={[4, 6, 6]}>
              <AutoComplete
                label="Please select your school *"
                placeholder="Start typing..."
                id="school-autocomplete"
                dataTestId="school-autocomplete"
                options={schools?.data}
                handleChange={(value) => {
                  setFormData({ schoolDetails: { ...(value || null) } });
                  setSelectedSchoolType('school');
                }}
                listItemProps={(option) => ({
                  key: `option-${option.Id}`,
                  label: option.Name
                })}
                getOptionLabel={(option) => (option.Name ? option.Name : '')}
                disabled={isHoldingSchool(schoolDetails.Id)}
                valueProps={{ data: schoolDetails, key: 'Name' }}
                error={
                  validationErrors.schoolDetails
                    ? 'Please select a school'
                    : null
                }
              />
            </Col>
          </div>
          <Col w={[4, 6, 6]}>
            <Textfield
              label="Can’t find your school? Write it down"
              placeholder="Type the name of your school..."
              id="school-details-name"
              handleChange={(name) => {
                setFormData({
                  schoolDetails: {
                    Name: name,
                    Id: name.length ? HoldingSchoolId : null
                  }
                });
                setSelectedSchoolType(null);
              }}
              disabled={schoolDetails?.Id && !isHoldingSchool(schoolDetails.Id)}
              error={
                validationErrors.schoolDetails ? 'Please select a school' : null
              }
            />
          </Col>
        </Row>
        {schoolDetails.Id && !isHoldingSchool(schoolDetails.Id) ? (
          <Row mt={4}>
            <SchoolAddress schoolDetails={schoolDetails} />
          </Row>
        ) : null}

        <Row mt={7} mtM={6}>
          <div
            key={`grad-school-${selectedSchoolType}`}
            style={{ width: '100%' }}
          >
            <Col w={[4, 6, 6]}>
              <AutoComplete
                label="Please select where you did you GCSEs, National 5s or equivalent *"
                placeholder="Start typing..."
                id="grad-school-autocomplete"
                dataTestId="grad-school-autocomplete"
                options={schools?.data}
                getOptionLabel={(option) => (option.Name ? option.Name : '')}
                listItemProps={(option) => ({
                  key: `option-${option.Id}`,
                  label: option.Name
                })}
                disabled={isHoldingSchool(gradSchoolDetails.Id)}
                handleChange={(value) => {
                  setFormData({
                    gradSchoolDetails: { ...(value || null) }
                  });
                  setSelectedSchoolType('grad');
                }}
                valueProps={{ data: gradSchoolDetails, key: 'Name' }}
                error={
                  validationErrors.gradSchoolDetails
                    ? 'Please select a school'
                    : null
                }
              />
            </Col>
          </div>
          <Col w={[4, 6, 6]}>
            <Textfield
              label="Can’t find your school? Write it down"
              placeholder="Type the name of your school..."
              id="grad-school-details-name"
              handleChange={(name) => {
                setFormData({
                  gradSchoolDetails: {
                    Name: name,
                    Id: name.length ? HoldingSchoolId : null
                  }
                });
                setSelectedSchoolType(null);
              }}
              disabled={
                gradSchoolDetails?.Id && !isHoldingSchool(gradSchoolDetails.Id)
              }
              value={gradSchoolDetails}
              error={
                validationErrors.gradSchoolDetails
                  ? 'Please select a school'
                  : null
              }
            />
          </Col>
        </Row>
        {gradSchoolDetails.Id && !isHoldingSchool(gradSchoolDetails.Id) ? (
          <Row mt={4}>
            <SchoolAddress schoolDetails={gradSchoolDetails} />
          </Row>
        ) : null}
      </S.Card>

      <VerifySchoolModal
        selectedSchoolType={selectedSchoolType}
        setSelectedSchoolType={setSelectedSchoolType}
        schoolDetails={
          selectedSchoolType === 'grad' ? gradSchoolDetails : schoolDetails
        }
        setFormData={setFormData}
        initialFormState={initialFormState}
      />
    </>
  );
};

export default SchoolSection;
