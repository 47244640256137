import Modal from '../../components/Modal';
import { Col, Row } from '../../components/Grid';
import Button from '../../components/Button';
import * as T from '../../components/Typography';

const VerifySchoolModal = ({
  selectedSchoolType,
  setSelectedSchoolType,
  setFormData,
  schoolDetails = {},
  initialFormState
}) => {
  const {
    Name: name,
    BillingAddress: address,
    Admissions_Policy_New__c: admissionsPolicy
  } = schoolDetails;

  return (
    <Modal
      visible={typeof selectedSchoolType === 'string'}
      setIsModalVisible={setSelectedSchoolType}
      bgColor="primaryLight"
    >
      <Row>
        <T.H2 size="med" color="white">
          2. SCHOOL DETAILS
        </T.H2>
      </Row>

      <Row mt={5}>
        <Col w={[4, 6, 6]}>
          <Col w={[4, 12, 12]}>
            <T.P color="white" weight="bold">
              NAME
            </T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">{name || 'N/A'}</T.P>
          </Col>
        </Col>
        <Col w={[4, 6, 6]}>
          <Col w={[4, 12, 12]} mtM={5}>
            <T.P color="white" weight="bold">
              ADDRESS
            </T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">Street: {address?.street || 'N/A'}</T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">Postcode: {address?.postalCode || 'N/A'}</T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">City: {address?.city || 'N/A'}</T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">Region: {address?.state || 'N/A'}</T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <T.P color="white">Country: {address?.country || 'N/A'}</T.P>
          </Col>
        </Col>
      </Row>
      {admissionsPolicy ? (
        <Row mt={5}>
          <Col w={[4, 6, 6]}>
            <Col w={[4, 12, 12]}>
              <T.P color="white" weight="bold">
                ADMISSIONS POLICY
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P color="white">{admissionsPolicy}</T.P>
            </Col>
          </Col>
        </Row>
      ) : null}
      <Row mt={5} mtM={5}>
        <Col w={[4, 6, 6]}>
          <Button variant="primary" onClick={() => setSelectedSchoolType(null)}>
            Verify school
          </Button>
        </Col>
        <Col w={[4, 6, 6]} mtM={5}>
          <Button
            variant="secondary"
            onClick={() => {
              const schoolStateProp =
                selectedSchoolType === 'grad'
                  ? 'gradSchoolDetails'
                  : 'schoolDetails';
              setFormData({
                [schoolStateProp]: initialFormState[schoolStateProp]
              });
              setSelectedSchoolType(null);
            }}
          >
            Clear selection
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default VerifySchoolModal;
