export const jobRecords = [
  { Id: 0, Code: '9999', Name: 'Unemployed' },
  { Id: 1, Code: '1115', Name: 'Ambassador, Foreign and Commonwealth Office' },
  { Id: 2, Code: '1115', Name: 'Band 0, Health and Safety Executive' },
  { Id: 3, Code: '1115', Name: 'Band 1B, Meteorological Office' },
  { Id: 4, Code: '1115', Name: 'Band 1C, Meteorological Office' },
  { Id: 5, Code: '1115', Name: 'Chairman' },
  { Id: 6, Code: '1115', Name: 'Chairman, company' },
  { Id: 7, Code: '1115', Name: 'Chairman, health authority' },
  {
    Id: 8,
    Code: '1115',
    Name: 'Clerk to the commissioners, HM Revenue and Customs'
  },
  { Id: 9, Code: '1115', Name: 'Commissioner, government' },
  { Id: 10, Code: '1115', Name: 'Diplomat' },
  { Id: 11, Code: '1115', Name: 'Director, deputy, government' },
  { Id: 12, Code: '1115', Name: 'Director, government' },
  { Id: 13, Code: '1115', Name: 'Director, group, government' },
  { Id: 14, Code: '1115', Name: 'Director, National Assembly for Wales' },
  { Id: 15, Code: '1115', Name: 'Executive, chief' },
  { Id: 16, Code: '1115', Name: 'Executive, chief, CHARITABLE ORGANISATION' },
  {
    Id: 17,
    Code: '1115',
    Name: 'Executive, chief, health authority: hospital service'
  },
  { Id: 18, Code: '1115', Name: 'Executive, chief, local government' },
  {
    Id: 19,
    Code: '1115',
    Name: 'Executive, chief, National Assembly for Wales'
  },
  { Id: 20, Code: '1115', Name: 'Grade 1, Foreign and Commonwealth Office' },
  { Id: 21, Code: '1115', Name: 'Grade 1, government' },
  { Id: 22, Code: '1115', Name: 'Grade 2, Foreign and Commonwealth Office' },
  { Id: 23, Code: '1115', Name: 'Grade 2, government' },
  { Id: 24, Code: '1115', Name: 'Grade 3, Foreign and Commonwealth Office' },
  { Id: 25, Code: '1115', Name: 'Grade 3, government' },
  { Id: 26, Code: '1115', Name: 'Grade 4, Foreign and Commonwealth Office' },
  { Id: 27, Code: '1115', Name: 'Grade 5, Foreign and Commonwealth Office' },
  { Id: 28, Code: '1115', Name: 'Grade 5, government' },
  {
    Id: 29,
    Code: '1115',
    Name: 'Inspector of Constabulary, HM Inspectorate of Constabulary'
  },
  { Id: 30, Code: '1115', Name: 'Keeper of the Signet' },
  { Id: 31, Code: '1115', Name: 'Officer, executive, chief' },
  { Id: 32, Code: '1115', Name: 'Officer, medical, chief' },
  { Id: 33, Code: '1115', Name: 'President' },
  { Id: 34, Code: '1115', Name: 'President, company' },
  { Id: 35, Code: '1115', Name: 'President, vice' },
  { Id: 36, Code: '1115', Name: 'Secretary, assistant, government' },
  { Id: 37, Code: '1115', Name: 'Secretary, command' },
  { Id: 38, Code: '1115', Name: 'Secretary, deputy, government' },
  { Id: 39, Code: '1115', Name: 'Secretary, first' },
  { Id: 40, Code: '1115', Name: 'Secretary, parliamentary' },
  { Id: 41, Code: '1115', Name: 'Secretary, permanent, government' },
  { Id: 42, Code: '1115', Name: 'Secretary, private, parliamentary' },
  { Id: 43, Code: '1115', Name: 'Secretary, second' },
  { Id: 44, Code: '1115', Name: 'Secretary, under, government' },
  {
    Id: 45,
    Code: '1115',
    Name: 'Servant, civil, ASSISTANT SECRETARY AND ABOVE'
  },
  { Id: 46, Code: '1115', Name: 'Servant, civil, GRADE 5 AND ABOVE' },
  { Id: 47, Code: '1116', Name: 'AM, National Assembly for Wales' },
  { Id: 48, Code: '1116', Name: 'Councillor, local government' },
  { Id: 49, Code: '1116', Name: 'Member of European Parliament' },
  { Id: 50, Code: '1116', Name: 'Member of Northern Ireland Assembly' },
  { Id: 51, Code: '1116', Name: 'Member of Parliament' },
  { Id: 52, Code: '1116', Name: 'Member of Scottish Parliament' },
  { Id: 53, Code: '1116', Name: 'Member of the Legislative Assembly' },
  { Id: 54, Code: '1116', Name: 'Member, assembly, National Assembly' },
  { Id: 55, Code: '1116', Name: 'MEP' },
  { Id: 56, Code: '1116', Name: 'Minister, government' },
  { Id: 57, Code: '1116', Name: 'MLA, Northern Ireland Assembly' },
  { Id: 58, Code: '1116', Name: 'MP' },
  { Id: 59, Code: '1116', Name: 'MSP' },
  { Id: 60, Code: '1116', Name: 'Secretary of state' },
  { Id: 61, Code: '1121', Name: 'Director of manufacturing' },
  { Id: 62, Code: '1121', Name: 'Director, company, engineering' },
  { Id: 63, Code: '1121', Name: 'Director, company, manufacturing' },
  { Id: 64, Code: '1121', Name: 'Director, engineering' },
  { Id: 65, Code: '1121', Name: 'Director, managing, engineering' },
  { Id: 66, Code: '1121', Name: 'Director, managing, manufacturing' },
  { Id: 67, Code: '1121', Name: 'Director, manufacturing' },
  { Id: 68, Code: '1121', Name: 'Director, operations, manufacturing' },
  { Id: 69, Code: '1121', Name: 'Director, printing' },
  { Id: 70, Code: '1121', Name: 'Director, technical' },
  { Id: 71, Code: '1121', Name: 'Director, technical, engineering' },
  { Id: 72, Code: '1121', Name: 'Director, works, manufacturing' },
  { Id: 73, Code: '1121', Name: 'Head of manufacturing' },
  { Id: 74, Code: '1121', Name: 'Manager, abattoir' },
  { Id: 75, Code: '1121', Name: 'Manager, brewery' },
  { Id: 76, Code: '1121', Name: 'Manager, clothing, manufacturing' },
  { Id: 77, Code: '1121', Name: 'Manager, coke ovens' },
  { Id: 78, Code: '1121', Name: 'Manager, composition' },
  { Id: 79, Code: '1121', Name: 'Manager, control, production' },
  { Id: 80, Code: '1121', Name: 'Manager, dairy, food products mfr' },
  { Id: 81, Code: '1121', Name: 'Manager, district, manufacturing' },
  { Id: 82, Code: '1121', Name: 'Manager, divisional, manufacturing' },
  { Id: 83, Code: '1121', Name: 'Manager, electro-plating' },
  { Id: 84, Code: '1121', Name: 'Manager, engineering' },
  { Id: 85, Code: '1121', Name: 'Manager, fabrication' },
  { Id: 86, Code: '1121', Name: 'Manager, factory' },
  { Id: 87, Code: '1121', Name: 'Manager, floor, manufacturing' },
  { Id: 88, Code: '1121', Name: 'Manager, foundry' },
  { Id: 89, Code: '1121', Name: 'Manager, general, manufacturing' },
  { Id: 90, Code: '1121', Name: 'Manager, house, dye' },
  { Id: 91, Code: '1121', Name: 'Manager, line, front, manufacturing' },
  { Id: 92, Code: '1121', Name: 'Manager, line, production' },
  { Id: 93, Code: '1121', Name: 'Manager, manufacturing' },
  { Id: 94, Code: '1121', Name: 'Manager, mill' },
  { Id: 95, Code: '1121', Name: 'Manager, mineral oil processing' },
  { Id: 96, Code: '1121', Name: 'Manager, nuclear fuel production' },
  { Id: 97, Code: '1121', Name: 'Manager, operations, manufacturing' },
  { Id: 98, Code: '1121', Name: 'Manager, packaging activities' },
  { Id: 99, Code: '1121', Name: 'Manager, plant' },
  { Id: 100, Code: '1121', Name: 'Manager, printing' },
  { Id: 101, Code: '1121', Name: 'Manager, product, manufacturing' },
  { Id: 102, Code: '1121', Name: 'Manager, production' },
  { Id: 103, Code: '1121', Name: 'Manager, progress' },
  { Id: 104, Code: '1121', Name: 'Manager, regional, manufacturing' },
  { Id: 105, Code: '1121', Name: 'Manager, room, composing' },
  { Id: 106, Code: '1121', Name: 'Manager, sawmill' },
  { Id: 107, Code: '1121', Name: 'Manager, service' },
  { Id: 108, Code: '1121', Name: 'Manager, services, technical' },
  { Id: 109, Code: '1121', Name: 'Manager, shift, manufacturing' },
  { Id: 110, Code: '1121', Name: 'Manager, shift, production' },
  { Id: 111, Code: '1121', Name: 'Manager, ship building, repairing' },
  { Id: 112, Code: '1121', Name: 'Manager, shop, colour' },
  { Id: 113, Code: '1121', Name: 'Manager, shop, manufacturing' },
  { Id: 114, Code: '1121', Name: 'Manager, shop, metal trades' },
  { Id: 115, Code: '1121', Name: 'Manager, solid fuel mfr' },
  { Id: 116, Code: '1121', Name: 'Manager, steelworks' },
  { Id: 117, Code: '1121', Name: 'Manager, systems, printing' },
  { Id: 118, Code: '1121', Name: 'Manager, technical' },
  { Id: 119, Code: '1121', Name: 'Manager, textile' },
  { Id: 120, Code: '1121', Name: 'Manager, works' },
  { Id: 121, Code: '1121', Name: 'Manager, yard, boat' },
  { Id: 122, Code: '1121', Name: 'Manager, yard, ship' },
  { Id: 123, Code: '1121', Name: 'Owner, engineering works' },
  { Id: 124, Code: '1121', Name: 'Owner, foundry' },
  { Id: 125, Code: '1121', Name: 'Owner, manufacturing' },
  { Id: 126, Code: '1121', Name: 'Owner, mill, textile' },
  { Id: 127, Code: '1121', Name: 'Owner, sawmill' },
  { Id: 128, Code: '1121', Name: 'Owner, textile mill' },
  { Id: 129, Code: '1121', Name: 'Owner, yard, boat' },
  { Id: 130, Code: '1122', Name: "Agent, builder's" },
  { Id: 131, Code: '1122', Name: 'Agent, building construction' },
  { Id: 132, Code: '1122', Name: "Agent, contractor's" },
  { Id: 133, Code: '1122', Name: 'Agent, engineering, civil' },
  { Id: 134, Code: '1122', Name: 'Agent, site' },
  { Id: 135, Code: '1122', Name: 'Director, building' },
  { Id: 136, Code: '1122', Name: 'Director, building construction' },
  { Id: 137, Code: '1122', Name: 'Director, civil engineering' },
  { Id: 138, Code: '1122', Name: 'Director, construction' },
  { Id: 139, Code: '1122', Name: 'Director, contracts, construction' },
  { Id: 140, Code: '1122', Name: 'Director, managing, building construction' },
  { Id: 141, Code: '1122', Name: 'Director, technical, building construction' },
  { Id: 142, Code: '1122', Name: 'Manager, alarm, security installation' },
  { Id: 143, Code: '1122', Name: 'Manager, area, construction' },
  { Id: 144, Code: '1122', Name: 'Manager, building' },
  { Id: 145, Code: '1122', Name: 'Manager, building construction' },
  { Id: 146, Code: '1122', Name: 'Manager, civil engineering' },
  { Id: 147, Code: '1122', Name: 'Manager, coal mine: opencast' },
  { Id: 148, Code: '1122', Name: 'Manager, construction' },
  { Id: 149, Code: '1122', Name: 'Manager, demolition' },
  {
    Id: 150,
    Code: '1122',
    Name: 'Manager, development, building construction'
  },
  { Id: 151, Code: '1122', Name: 'Manager, development, property' },
  { Id: 152, Code: '1122', Name: 'Manager, electrical contracting' },
  {
    Id: 153,
    Code: '1122',
    Name: 'Manager, engineering, building construction'
  },
  { Id: 154, Code: '1122', Name: 'Manager, highway' },
  { Id: 155, Code: '1122', Name: 'Manager, highways' },
  { Id: 156, Code: '1122', Name: 'Manager, local government: highways dept' },
  { Id: 157, Code: '1122', Name: 'Manager, operations, building construction' },
  { Id: 158, Code: '1122', Name: 'Manager, plant, building construction' },
  { Id: 159, Code: '1122', Name: 'Manager, production, building construction' },
  { Id: 160, Code: '1122', Name: 'Manager, service, building construction' },
  { Id: 161, Code: '1122', Name: 'Manager, services, building' },
  { Id: 162, Code: '1122', Name: 'Manager, services, building construction' },
  { Id: 163, Code: '1122', Name: 'Manager, services, site' },
  { Id: 164, Code: '1122', Name: 'Manager, site' },
  { Id: 165, Code: '1122', Name: 'Manager, technical, building construction' },
  { Id: 166, Code: '1122', Name: 'Manager, works, building construction' },
  { Id: 167, Code: '1122', Name: 'Master of works' },
  { Id: 168, Code: '1122', Name: 'Merchant, building construction' },
  { Id: 169, Code: '1122', Name: 'Owner, building construction' },
  { Id: 170, Code: '1122', Name: 'Owner, electrical contracting' },
  { Id: 171, Code: '1122', Name: 'Owner, plumbing, heating contracting' },
  { Id: 172, Code: '1122', Name: 'Owner, property renovation' },
  { Id: 173, Code: '1122', Name: 'Sub-agent, building construction' },
  { Id: 174, Code: '1123', Name: 'Agent, colliery' },
  { Id: 175, Code: '1123', Name: 'Agent, mine' },
  { Id: 176, Code: '1123', Name: 'Agent, mining' },
  { Id: 177, Code: '1123', Name: 'Agent, surface' },
  { Id: 178, Code: '1123', Name: 'Boss, mine' },
  { Id: 179, Code: '1123', Name: 'Director, technical, water treatment' },
  { Id: 180, Code: '1123', Name: 'Manager, colliery' },
  { Id: 181, Code: '1123', Name: 'Manager, distribution, energy suppliers' },
  { Id: 182, Code: '1123', Name: 'Manager, electricity supplier' },
  { Id: 183, Code: '1123', Name: 'Manager, exploration, oil' },
  { Id: 184, Code: '1123', Name: 'Manager, gas' },
  { Id: 185, Code: '1123', Name: 'Manager, installation, offshore' },
  { Id: 186, Code: '1123', Name: 'Manager, installations, offshore' },
  { Id: 187, Code: '1123', Name: 'Manager, mine not opencast: quarry' },
  {
    Id: 188,
    Code: '1123',
    Name: 'Manager, operations, mining, water and energy'
  },
  { Id: 189, Code: '1123', Name: 'Manager, planning, public utilities' },
  { Id: 190, Code: '1123', Name: 'Manager, production, electricity supplier' },
  { Id: 191, Code: '1123', Name: 'Manager, production, public utilities' },
  { Id: 192, Code: '1123', Name: 'Manager, public utilities' },
  { Id: 193, Code: '1123', Name: 'Manager, quarry' },
  { Id: 194, Code: '1123', Name: 'Manager, quarrying and extraction' },
  { Id: 195, Code: '1123', Name: 'Manager, rig, oil' },
  { Id: 196, Code: '1123', Name: 'Manager, service, energy suppliers' },
  { Id: 197, Code: '1123', Name: 'Manager, service, public utilities' },
  { Id: 198, Code: '1123', Name: 'Manager, station, power' },
  { Id: 199, Code: '1123', Name: 'Manager, well drilling' },
  { Id: 200, Code: '1123', Name: 'Owner, quarry' },
  { Id: 201, Code: '1123', Name: 'Pusher, tool' },
  { Id: 202, Code: '1123', Name: 'Superintendent, drilling' },
  { Id: 203, Code: '1131', Name: 'ACIS' },
  { Id: 204, Code: '1131', Name: 'Banker' },
  { Id: 205, Code: '1131', Name: 'Banker, international' },
  { Id: 206, Code: '1131', Name: 'Banker, investment' },
  { Id: 207, Code: '1131', Name: 'Banker, merchant' },
  { Id: 208, Code: '1131', Name: 'Chamberlain, burgh' },
  { Id: 209, Code: '1131', Name: 'Chamberlain, city' },
  { Id: 210, Code: '1131', Name: 'Director and Secretary' },
  { Id: 211, Code: '1131', Name: 'Director of finance' },
  { Id: 212, Code: '1131', Name: 'Director, finance' },
  { Id: 213, Code: '1131', Name: 'Director, financial' },
  { Id: 214, Code: '1131', Name: 'FCIS' },
  { Id: 215, Code: '1131', Name: 'Manager, accountancy' },
  { Id: 216, Code: '1131', Name: 'Manager, accountancy services' },
  { Id: 217, Code: '1131', Name: 'Manager, assessment, credit' },
  { Id: 218, Code: '1131', Name: 'Manager, banking: investment' },
  { Id: 219, Code: '1131', Name: 'Manager, branch, credit company' },
  { Id: 220, Code: '1131', Name: 'Manager, branch, financial services' },
  { Id: 221, Code: '1131', Name: 'Manager, collateral' },
  { Id: 222, Code: '1131', Name: 'Manager, credit company' },
  { Id: 223, Code: '1131', Name: 'Manager, development, agency' },
  { Id: 224, Code: '1131', Name: 'Manager, exchange, foreign' },
  { Id: 225, Code: '1131', Name: 'Manager, finance' },
  { Id: 226, Code: '1131', Name: 'Manager, financial' },
  { Id: 227, Code: '1131', Name: 'Manager, financial services' },
  { Id: 228, Code: '1131', Name: 'Manager, loan company' },
  { Id: 229, Code: '1131', Name: 'Manager, market, money' },
  { Id: 230, Code: '1131', Name: 'Manager, markets, financial services' },
  { Id: 231, Code: '1131', Name: 'Manager, money lending' },
  { Id: 232, Code: '1131', Name: 'Manager, operations, financial services' },
  { Id: 233, Code: '1131', Name: 'Manager, planning, capital' },
  { Id: 234, Code: '1131', Name: 'Manager, practice, insolvency' },
  { Id: 235, Code: '1131', Name: 'Manager, purchase, hire' },
  { Id: 236, Code: '1131', Name: 'Manager, systems, financial' },
  { Id: 237, Code: '1131', Name: 'Manager, treasury' },
  {
    Id: 238,
    Code: '1131',
    Name: 'Officer, operating, chief, financial services'
  },
  { Id: 239, Code: '1131', Name: 'Principal, banking' },
  { Id: 240, Code: '1131', Name: 'Registrar' },
  { Id: 241, Code: '1131', Name: 'Registrar of stock' },
  { Id: 242, Code: '1131', Name: 'Registrar of stocks and bonds' },
  { Id: 243, Code: '1131', Name: 'Registrar, company' },
  { Id: 244, Code: '1131', Name: 'Secretary and company director' },
  { Id: 245, Code: '1131', Name: 'Secretary and legal adviser' },
  { Id: 246, Code: '1131', Name: 'Secretary of health authority' },
  { Id: 247, Code: '1131', Name: 'Secretary of health board' },
  { Id: 248, Code: '1131', Name: 'Secretary, company, DIRECTOR' },
  { Id: 249, Code: '1131', Name: 'Secretary-accountant' },
  { Id: 250, Code: '1131', Name: 'Secretary-director' },
  { Id: 251, Code: '1131', Name: 'Treasurer, company' },
  { Id: 252, Code: '1131', Name: 'Treasurer, county' },
  { Id: 253, Code: '1131', Name: 'Treasurer, QUALIFIED' },
  { Id: 254, Code: '1132', Name: 'Director of business development' },
  { Id: 255, Code: '1132', Name: 'Director of marketing' },
  { Id: 256, Code: '1132', Name: 'Director of sales' },
  { Id: 257, Code: '1132', Name: 'Director, account' },
  { Id: 258, Code: '1132', Name: 'Director, commercial' },
  { Id: 259, Code: '1132', Name: 'Director, development, business' },
  { Id: 260, Code: '1132', Name: 'Director, export' },
  { Id: 261, Code: '1132', Name: 'Director, export agency' },
  { Id: 262, Code: '1132', Name: 'Director, import agency' },
  { Id: 263, Code: '1132', Name: 'Director, market research' },
  { Id: 264, Code: '1132', Name: 'Director, marketing' },
  { Id: 265, Code: '1132', Name: 'Director, sales' },
  { Id: 266, Code: '1132', Name: 'Director, sales and export' },
  { Id: 267, Code: '1132', Name: 'Director, sales and marketing' },
  { Id: 268, Code: '1132', Name: 'Director, ship brokers' },
  { Id: 269, Code: '1132', Name: 'Director, technical, MARKETING' },
  { Id: 270, Code: '1132', Name: 'Director, TELEPHONE SALES' },
  { Id: 271, Code: '1132', Name: 'Head of business development' },
  { Id: 272, Code: '1132', Name: 'Head of commercial' },
  { Id: 273, Code: '1132', Name: 'Head of communications, MARKETING' },
  { Id: 274, Code: '1132', Name: 'Head of digital marketing' },
  { Id: 275, Code: '1132', Name: 'Head of marketing' },
  { Id: 276, Code: '1132', Name: 'Head of sales' },
  { Id: 277, Code: '1132', Name: 'Owner, export agency' },
  { Id: 278, Code: '1132', Name: 'Owner, import agency' },
  { Id: 279, Code: '1133', Name: 'Director of contracts, government' },
  { Id: 280, Code: '1133', Name: 'Director, chain, supply' },
  { Id: 281, Code: '1133', Name: 'Director, purchasing' },
  { Id: 282, Code: '1133', Name: 'Manager, bid' },
  { Id: 283, Code: '1133', Name: 'Manager, buying' },
  { Id: 284, Code: '1133', Name: 'Manager, category, PURCHASING' },
  { Id: 285, Code: '1133', Name: 'Manager, chain, supply' },
  { Id: 286, Code: '1133', Name: 'Manager, commissioning' },
  { Id: 287, Code: '1133', Name: 'Manager, contract, PURCHASING' },
  { Id: 288, Code: '1133', Name: 'Manager, contracts, PURCHASING' },
  { Id: 289, Code: '1133', Name: 'Manager, estimating' },
  { Id: 290, Code: '1133', Name: 'Manager, procurement' },
  { Id: 291, Code: '1133', Name: 'Manager, production, bid' },
  { Id: 292, Code: '1133', Name: 'Manager, purchasing' },
  { Id: 293, Code: '1133', Name: 'Manager, supplies' },
  { Id: 294, Code: '1134', Name: 'Director of external relations' },
  { Id: 295, Code: '1134', Name: 'Director of fund raising' },
  { Id: 296, Code: '1134', Name: 'Director, account, advertising' },
  { Id: 297, Code: '1134', Name: 'Director, advertising' },
  { Id: 298, Code: '1134', Name: 'Director, appeal' },
  { Id: 299, Code: '1134', Name: 'Director, appeals' },
  { Id: 300, Code: '1134', Name: 'Director, media' },
  { Id: 301, Code: '1134', Name: 'Director, media, social' },
  { Id: 302, Code: '1134', Name: 'Director, PUBLIC RELATIONS' },
  { Id: 303, Code: '1134', Name: 'Director, relations, public' },
  { Id: 304, Code: '1134', Name: 'Head of communications, PUBLIC RELATIONS' },
  { Id: 305, Code: '1134', Name: 'Head of digital media' },
  { Id: 306, Code: '1134', Name: 'Head of public affairs' },
  { Id: 307, Code: '1134', Name: 'Head of public relations' },
  { Id: 308, Code: '1134', Name: 'Head, PUBLIC RELATIONS' },
  { Id: 309, Code: '1134', Name: 'Owner, advertising agency' },
  { Id: 310, Code: '1135', Name: 'Director of communications' },
  { Id: 311, Code: '1135', Name: 'Director of human resources' },
  { Id: 312, Code: '1135', Name: 'Director of personnel' },
  { Id: 313, Code: '1135', Name: 'Director of training' },
  { Id: 314, Code: '1135', Name: 'Director, development, management' },
  { Id: 315, Code: '1135', Name: 'Director, personnel' },
  { Id: 316, Code: '1135', Name: 'Director, recruitment' },
  { Id: 317, Code: '1135', Name: 'Director, resources, human' },
  { Id: 318, Code: '1135', Name: 'Director, training' },
  { Id: 319, Code: '1135', Name: 'Head of learning and development' },
  { Id: 320, Code: '1135', Name: 'Head of personnel' },
  { Id: 321, Code: '1135', Name: 'Manager, delivery, service, HR' },
  { Id: 322, Code: '1135', Name: 'Manager, development, employee' },
  { Id: 323, Code: '1135', Name: 'Manager, development, self' },
  { Id: 324, Code: '1135', Name: 'Manager, development, training' },
  { Id: 325, Code: '1135', Name: 'Manager, O&M' },
  { Id: 326, Code: '1135', Name: 'Manager, organisation and efficiency' },
  { Id: 327, Code: '1135', Name: 'Manager, organisation and methods' },
  { Id: 328, Code: '1135', Name: 'Manager, personnel' },
  { Id: 329, Code: '1135', Name: 'Manager, personnel and training' },
  { Id: 330, Code: '1135', Name: 'Manager, planning, resource' },
  { Id: 331, Code: '1135', Name: 'Manager, recruitment' },
  { Id: 332, Code: '1135', Name: 'Manager, relations, employee' },
  { Id: 333, Code: '1135', Name: 'Manager, relations, industrial' },
  { Id: 334, Code: '1135', Name: 'Manager, research, operational' },
  { Id: 335, Code: '1135', Name: 'Manager, resource, human' },
  { Id: 336, Code: '1135', Name: 'Manager, resources, human' },
  { Id: 337, Code: '1135', Name: 'Manager, services, management' },
  { Id: 338, Code: '1135', Name: 'Manager, staff' },
  { Id: 339, Code: '1135', Name: 'Manager, study, works' },
  { Id: 340, Code: '1135', Name: 'Manager, training establishment' },
  { Id: 341, Code: '1135', Name: 'Manager, unit, NVQ' },
  { Id: 342, Code: '1135', Name: 'Manager, vocational training' },
  { Id: 343, Code: '1135', Name: 'Manager, volunteer' },
  { Id: 344, Code: '1135', Name: 'Partner, business, resources, human' },
  { Id: 345, Code: '1136', Name: 'Director of communications, COMPUTING' },
  { Id: 346, Code: '1136', Name: 'Director of IT' },
  { Id: 347, Code: '1136', Name: 'Director, COMPUTING' },
  { Id: 348, Code: '1136', Name: 'Director, IT' },
  { Id: 349, Code: '1136', Name: 'Director, managing, company, software' },
  { Id: 350, Code: '1136', Name: 'Director, managing, computer services' },
  { Id: 351, Code: '1136', Name: 'Director, product, computer services' },
  { Id: 352, Code: '1136', Name: 'Director, technical, computer' },
  { Id: 353, Code: '1136', Name: 'Director, technical, computer services' },
  { Id: 354, Code: '1136', Name: 'Director, technical, telecommunications' },
  { Id: 355, Code: '1136', Name: 'Director, telecommunications' },
  { Id: 356, Code: '1136', Name: 'Director, telecommunications services' },
  { Id: 357, Code: '1136', Name: 'Head of computer services' },
  { Id: 358, Code: '1136', Name: 'Head of ICT' },
  { Id: 359, Code: '1136', Name: 'Head of information security' },
  { Id: 360, Code: '1136', Name: 'Head of IT' },
  { Id: 361, Code: '1136', Name: 'Head of technology' },
  { Id: 362, Code: '1136', Name: 'Officer, information, chief' },
  { Id: 363, Code: '1136', Name: 'Officer, technical, chief' },
  { Id: 364, Code: '1136', Name: 'Owner, computer services' },
  { Id: 365, Code: '1139', Name: 'Clerk of the council' },
  { Id: 366, Code: '1139', Name: 'Clerk to the board, local government' },
  { Id: 367, Code: '1139', Name: 'Clerk to the council' },
  { Id: 368, Code: '1139', Name: 'Clerk to the county council' },
  { Id: 369, Code: '1139', Name: 'Clerk to the district council' },
  { Id: 370, Code: '1139', Name: 'Clerk to the parish council' },
  { Id: 371, Code: '1139', Name: 'Clerk, chief, local government' },
  { Id: 372, Code: '1139', Name: 'Clerk, deputy, local government' },
  { Id: 373, Code: '1139', Name: 'Clerk, principal, local government' },
  { Id: 374, Code: '1139', Name: 'Clerk, staff, local government' },
  { Id: 375, Code: '1139', Name: 'Clerk, town' },
  { Id: 376, Code: '1139', Name: 'Collector, rate, chief' },
  {
    Id: 377,
    Code: '1139',
    Name: 'Collector, superintendent, local government'
  },
  { Id: 378, Code: '1139', Name: 'Director of research and development' },
  { Id: 379, Code: '1139', Name: 'Director, accounts' },
  { Id: 380, Code: '1139', Name: 'Director, administration' },
  { Id: 381, Code: '1139', Name: 'Director, audit, internal' },
  { Id: 382, Code: '1139', Name: 'Director, charity' },
  { Id: 383, Code: '1139', Name: 'Director, cleaning' },
  { Id: 384, Code: '1139', Name: 'Director, conference organisers' },
  { Id: 385, Code: '1139', Name: 'Director, corporate hospitality' },
  { Id: 386, Code: '1139', Name: 'Director, divisional, Red Cross' },
  { Id: 387, Code: '1139', Name: 'Director, Environment Agency' },
  { Id: 388, Code: '1139', Name: 'Director, local government' },
  { Id: 389, Code: '1139', Name: 'Director, research' },
  { Id: 390, Code: '1139', Name: 'Director, research and development' },
  { Id: 391, Code: '1139', Name: 'Director, service, customer' },
  { Id: 392, Code: '1139', Name: 'Director, services, customer' },
  { Id: 393, Code: '1139', Name: 'Director, services, property management' },
  {
    Id: 394,
    Code: '1139',
    Name: 'Director, technical, research and development'
  },
  { Id: 395, Code: '1139', Name: 'Head of events' },
  { Id: 396, Code: '1139', Name: 'Head of intellectual property' },
  { Id: 397, Code: '1139', Name: 'Head of internal audit' },
  { Id: 398, Code: '1139', Name: 'Manager, branch, trade association' },
  {
    Id: 399,
    Code: '1139',
    Name: 'Manager, centre, mail, postal distribution services'
  },
  { Id: 400, Code: '1139', Name: 'Manager, CHARITABLE ORGANISATION' },
  { Id: 401, Code: '1139', Name: 'Manager, charity' },
  {
    Id: 402,
    Code: '1139',
    Name: 'Manager, development, donor, CHARITABLE ORGANISATION'
  },
  {
    Id: 403,
    Code: '1139',
    Name: 'Manager, mail, postal distribution services'
  },
  { Id: 404, Code: '1139', Name: 'Manager, operations, local government' },
  { Id: 405, Code: '1139', Name: 'Manager, planning, manufacturing' },
  { Id: 406, Code: '1139', Name: 'Manager, postal distribution services' },
  {
    Id: 407,
    Code: '1139',
    Name: 'Manager, process, postal distribution services'
  },
  {
    Id: 408,
    Code: '1139',
    Name: 'Manager, processing, postal distribution services'
  },
  { Id: 409, Code: '1139', Name: 'Manager, professional association' },
  { Id: 410, Code: '1139', Name: 'Manager, property, intellectual' },
  { Id: 411, Code: '1139', Name: 'Manager, religious organisation' },
  { Id: 412, Code: '1139', Name: 'Manager, service, registration' },
  { Id: 413, Code: '1139', Name: 'Manager, services, environmental' },
  { Id: 414, Code: '1139', Name: 'Manager, trade association' },
  { Id: 415, Code: '1139', Name: 'Manager, trade union' },
  { Id: 416, Code: '1139', Name: 'Officer, chief, local government' },
  { Id: 417, Code: '1139', Name: 'Ombudsman' },
  {
    Id: 418,
    Code: '1139',
    Name: 'Organiser, national, CHARITABLE ORGANISATION'
  },
  { Id: 419, Code: '1139', Name: 'Organiser, national, trade union' },
  { Id: 420, Code: '1139', Name: 'President, trade union' },
  { Id: 421, Code: '1139', Name: 'President, union' },
  { Id: 422, Code: '1139', Name: 'Secretary, area, CHARITABLE ORGANISATION' },
  { Id: 423, Code: '1139', Name: 'Secretary, area, professional organisation' },
  { Id: 424, Code: '1139', Name: 'Secretary, area, trade association' },
  { Id: 425, Code: '1139', Name: 'Secretary, area, trade union' },
  { Id: 426, Code: '1139', Name: 'Secretary, diocesan' },
  {
    Id: 427,
    Code: '1139',
    Name: 'Secretary, general, CHARITABLE ORGANISATION'
  },
  {
    Id: 428,
    Code: '1139',
    Name: 'Secretary, general, professional association'
  },
  { Id: 429, Code: '1139', Name: 'Secretary, general, trade association' },
  { Id: 430, Code: '1139', Name: 'Secretary, general, trade union' },
  { Id: 431, Code: '1139', Name: 'Secretary, managing, co-operative society' },
  { Id: 432, Code: '1139', Name: 'Secretary, national, trade union' },
  { Id: 433, Code: '1150', Name: 'Banker, business' },
  { Id: 434, Code: '1150', Name: 'Banker, finance' },
  { Id: 435, Code: '1150', Name: 'Director, bank' },
  { Id: 436, Code: '1150', Name: 'Head of lending' },
  { Id: 437, Code: '1150', Name: 'Manager, average adjusting' },
  { Id: 438, Code: '1150', Name: 'Manager, bank' },
  { Id: 439, Code: '1150', Name: 'Manager, banking' },
  { Id: 440, Code: '1150', Name: 'Manager, branch, assurance company' },
  { Id: 441, Code: '1150', Name: 'Manager, branch, bank' },
  { Id: 442, Code: '1150', Name: 'Manager, branch, building society' },
  { Id: 443, Code: '1150', Name: 'Manager, branch, insurance' },
  { Id: 444, Code: '1150', Name: 'Manager, broking' },
  { Id: 445, Code: '1150', Name: 'Manager, building society' },
  { Id: 446, Code: '1150', Name: 'Manager, card, credit' },
  { Id: 447, Code: '1150', Name: 'Manager, collection' },
  { Id: 448, Code: '1150', Name: 'Manager, collections' },
  { Id: 449, Code: '1150', Name: 'Manager, company, insurance' },
  { Id: 450, Code: '1150', Name: 'Manager, corporate, bank' },
  { Id: 451, Code: '1150', Name: 'Manager, corporate, building society' },
  { Id: 452, Code: '1150', Name: 'Manager, cost, bank' },
  { Id: 453, Code: '1150', Name: 'Manager, costing, bank' },
  { Id: 454, Code: '1150', Name: 'Manager, district, friendly society' },
  { Id: 455, Code: '1150', Name: 'Manager, divisional, insurance' },
  { Id: 456, Code: '1150', Name: 'Manager, equity' },
  { Id: 457, Code: '1150', Name: 'Manager, fund, pension' },
  { Id: 458, Code: '1150', Name: 'Manager, insurance' },
  { Id: 459, Code: '1150', Name: 'Manager, insurance brokers' },
  { Id: 460, Code: '1150', Name: 'Manager, lending' },
  { Id: 461, Code: '1150', Name: 'Manager, market, mortgages' },
  { Id: 462, Code: '1150', Name: 'Manager, mortgage' },
  { Id: 463, Code: '1150', Name: 'Manager, office, post' },
  { Id: 464, Code: '1150', Name: 'Manager, operations, bank' },
  { Id: 465, Code: '1150', Name: 'Manager, operations, building society' },
  { Id: 466, Code: '1150', Name: 'Manager, postal services' },
  { Id: 467, Code: '1150', Name: 'Manager, sales, bank' },
  { Id: 468, Code: '1150', Name: 'Manager, sales, building society' },
  { Id: 469, Code: '1150', Name: 'Manager, settlements' },
  { Id: 470, Code: '1150', Name: 'Manager, society, building' },
  { Id: 471, Code: '1150', Name: 'Manager, society, friendly' },
  { Id: 472, Code: '1150', Name: 'Manager, stock jobbers' },
  { Id: 473, Code: '1150', Name: 'Manager, stockbrokers' },
  { Id: 474, Code: '1150', Name: 'Manager, technical, bank' },
  { Id: 475, Code: '1150', Name: 'Manager, technical, building society' },
  { Id: 476, Code: '1150', Name: 'Manager, technical, insurance' },
  { Id: 477, Code: '1150', Name: 'Manager, transaction' },
  { Id: 478, Code: '1150', Name: 'Manager, unit, mortgage' },
  { Id: 479, Code: '1150', Name: 'Officer, operating, chief, banking' },
  {
    Id: 480,
    Code: '1150',
    Name: 'Officer, operating, chief, building society'
  },
  { Id: 481, Code: '1150', Name: 'Owner, loan office' },
  { Id: 482, Code: '1161', Name: 'Captain, port' },
  { Id: 483, Code: '1161', Name: 'Commandant, airport' },
  { Id: 484, Code: '1161', Name: 'Controller, chief, railways' },
  { Id: 485, Code: '1161', Name: 'Director, airport' },
  { Id: 486, Code: '1161', Name: 'Director, operations, transport' },
  { Id: 487, Code: '1161', Name: 'Director, technical, transport' },
  { Id: 488, Code: '1161', Name: 'Director, traffic, transport' },
  { Id: 489, Code: '1161', Name: "Husband, ship's" },
  { Id: 490, Code: '1161', Name: 'Manager, airport' },
  { Id: 491, Code: '1161', Name: 'Manager, area, transport' },
  { Id: 492, Code: '1161', Name: 'Manager, berthing' },
  { Id: 493, Code: '1161', Name: 'Manager, branch, furniture removals' },
  { Id: 494, Code: '1161', Name: 'Manager, branch, transport' },
  { Id: 495, Code: '1161', Name: 'Manager, capacity, cargo' },
  { Id: 496, Code: '1161', Name: 'Manager, chartering' },
  { Id: 497, Code: '1161', Name: 'Manager, courier service' },
  { Id: 498, Code: '1161', Name: 'Manager, crew, transport' },
  { Id: 499, Code: '1161', Name: 'Manager, delivery, home' },
  { Id: 500, Code: '1161', Name: 'Manager, delivery, operations, railways' },
  { Id: 501, Code: '1161', Name: 'Manager, delivery, parcel' },
  { Id: 502, Code: '1161', Name: 'Manager, depot' },
  { Id: 503, Code: '1161', Name: 'Manager, despatch' },
  { Id: 504, Code: '1161', Name: 'Manager, dispatch' },
  { Id: 505, Code: '1161', Name: 'Manager, distribution' },
  { Id: 506, Code: '1161', Name: 'Manager, DISTRIBUTION COMPANY' },
  { Id: 507, Code: '1161', Name: 'Manager, district, transport' },
  {
    Id: 508,
    Code: '1161',
    Name: 'Manager, divisional, petroleum distribution'
  },
  { Id: 509, Code: '1161', Name: 'Manager, divisional, transport' },
  { Id: 510, Code: '1161', Name: 'Manager, docks' },
  { Id: 511, Code: '1161', Name: 'Manager, fleet' },
  { Id: 512, Code: '1161', Name: 'Manager, fleet, transport' },
  { Id: 513, Code: '1161', Name: 'Manager, flight' },
  { Id: 514, Code: '1161', Name: 'Manager, freight' },
  { Id: 515, Code: '1161', Name: 'Manager, freight forwarding' },
  { Id: 516, Code: '1161', Name: 'Manager, garage, bus' },
  { Id: 517, Code: '1161', Name: 'Manager, haulage contractor' },
  { Id: 518, Code: '1161', Name: 'Manager, minicab service' },
  { Id: 519, Code: '1161', Name: 'Manager, operations, courier service' },
  { Id: 520, Code: '1161', Name: 'Manager, operations, DISTRIBUTION COMPANY' },
  { Id: 521, Code: '1161', Name: 'Manager, operations, flight' },
  { Id: 522, Code: '1161', Name: 'Manager, operations, haulage contractors' },
  { Id: 523, Code: '1161', Name: 'Manager, operations, port' },
  { Id: 524, Code: '1161', Name: 'Manager, operations, transport' },
  { Id: 525, Code: '1161', Name: 'Manager, pilot' },
  { Id: 526, Code: '1161', Name: 'Manager, port' },
  { Id: 527, Code: '1161', Name: 'Manager, production, DISTRIBUTION COMPANY' },
  { Id: 528, Code: '1161', Name: 'Manager, production, transport' },
  { Id: 529, Code: '1161', Name: 'Manager, removals' },
  { Id: 530, Code: '1161', Name: 'Manager, removals company' },
  { Id: 531, Code: '1161', Name: 'Manager, service, distribution' },
  { Id: 532, Code: '1161', Name: 'Manager, service, transport' },
  { Id: 533, Code: '1161', Name: 'Manager, shift, DISTRIBUTION COMPANY' },
  { Id: 534, Code: '1161', Name: 'Manager, shipping' },
  {
    Id: 535,
    Code: '1161',
    Name: 'Manager, shipping and freight forwarding agency'
  },
  { Id: 536, Code: '1161', Name: 'Manager, site, transport' },
  { Id: 537, Code: '1161', Name: 'Manager, station, airlines' },
  { Id: 538, Code: '1161', Name: 'Manager, station, BUS' },
  { Id: 539, Code: '1161', Name: 'Manager, station, COACH' },
  { Id: 540, Code: '1161', Name: 'Manager, station, RAILWAYS' },
  { Id: 541, Code: '1161', Name: 'Manager, taxi service' },
  { Id: 542, Code: '1161', Name: 'Manager, terminal, oil distribution' },
  { Id: 543, Code: '1161', Name: 'Manager, traffic' },
  { Id: 544, Code: '1161', Name: 'Manager, transport' },
  { Id: 545, Code: '1161', Name: 'Manager, transport, road' },
  { Id: 546, Code: '1161', Name: 'Master, berthing, port authority' },
  { Id: 547, Code: '1161', Name: 'Master, dock' },
  { Id: 548, Code: '1161', Name: 'Master, harbour' },
  { Id: 549, Code: '1161', Name: 'Master, pier' },
  { Id: 550, Code: '1161', Name: 'Master, port' },
  { Id: 551, Code: '1161', Name: 'Master, quay' },
  { Id: 552, Code: '1161', Name: 'Master, station' },
  { Id: 553, Code: '1161', Name: 'Owner, bus service' },
  { Id: 554, Code: '1161', Name: 'Owner, coach service' },
  { Id: 555, Code: '1161', Name: 'Owner, courier service' },
  { Id: 556, Code: '1161', Name: 'Owner, delivery service' },
  { Id: 557, Code: '1161', Name: 'Owner, haulage service' },
  { Id: 558, Code: '1161', Name: 'Owner, removals company' },
  { Id: 559, Code: '1161', Name: 'Owner, service, courier' },
  { Id: 560, Code: '1161', Name: 'Owner, ship' },
  { Id: 561, Code: '1161', Name: 'Stationmaster' },
  { Id: 562, Code: '1162', Name: 'Director, logistics' },
  { Id: 563, Code: '1162', Name: 'Director, warehousing' },
  { Id: 564, Code: '1162', Name: 'Head of logistics' },
  { Id: 565, Code: '1162', Name: 'Manager, accounting, stock' },
  { Id: 566, Code: '1162', Name: 'Manager, bond, warehousing' },
  { Id: 567, Code: '1162', Name: 'Manager, cargo' },
  { Id: 568, Code: '1162', Name: 'Manager, cellar, brewery' },
  { Id: 569, Code: '1162', Name: 'Manager, cellar, wine merchants' },
  { Id: 570, Code: '1162', Name: 'Manager, consignment' },
  { Id: 571, Code: '1162', Name: 'Manager, contracts, warehousing' },
  { Id: 572, Code: '1162', Name: 'Manager, control, material' },
  { Id: 573, Code: '1162', Name: 'Manager, control, materials' },
  { Id: 574, Code: '1162', Name: 'Manager, control, stock' },
  { Id: 575, Code: '1162', Name: 'Manager, inventory' },
  { Id: 576, Code: '1162', Name: 'Manager, inwards, goods' },
  { Id: 577, Code: '1162', Name: 'Manager, logistics' },
  { Id: 578, Code: '1162', Name: 'Manager, logistics and distribution' },
  { Id: 579, Code: '1162', Name: 'Manager, materials' },
  { Id: 580, Code: '1162', Name: 'Manager, operations, depot' },
  { Id: 581, Code: '1162', Name: 'Manager, operations, warehousing' },
  { Id: 582, Code: '1162', Name: 'Manager, out, goods' },
  { Id: 583, Code: '1162', Name: 'Manager, packaging' },
  { Id: 584, Code: '1162', Name: 'Manager, planning, logistics' },
  { Id: 585, Code: '1162', Name: 'Manager, stock' },
  { Id: 586, Code: '1162', Name: 'Manager, storage' },
  { Id: 587, Code: '1162', Name: 'Manager, store' },
  { Id: 588, Code: '1162', Name: 'Manager, store, cold' },
  { Id: 589, Code: '1162', Name: 'Manager, stores' },
  { Id: 590, Code: '1162', Name: "Manager, stores, ship's" },
  { Id: 591, Code: '1162', Name: 'Manager, systems, inventory' },
  { Id: 592, Code: '1162', Name: 'Manager, team, warehousing' },
  { Id: 593, Code: '1162', Name: 'Manager, terminal' },
  { Id: 594, Code: '1162', Name: 'Manager, terminals, container' },
  { Id: 595, Code: '1162', Name: 'Manager, transport and warehouse' },
  { Id: 596, Code: '1162', Name: 'Manager, warehouse' },
  { Id: 597, Code: '1162', Name: 'Manager, warehousing' },
  { Id: 598, Code: '1162', Name: 'Manager, yard' },
  { Id: 599, Code: '1171', Name: 'Adjutant' },
  { Id: 600, Code: '1171', Name: 'Adjutant-General' },
  { Id: 601, Code: '1171', Name: 'Admiral' },
  { Id: 602, Code: '1171', Name: 'Admiral of the Fleet' },
  { Id: 603, Code: '1171', Name: 'Aide-de-Camp' },
  { Id: 604, Code: '1171', Name: 'Air-Marshal' },
  { Id: 605, Code: '1171', Name: 'Brigadier' },
  { Id: 606, Code: '1171', Name: 'Cadet, officer' },
  { Id: 607, Code: '1171', Name: 'Captain, armed forces' },
  { Id: 608, Code: '1171', Name: "Captain, ship's, armed forces" },
  { Id: 609, Code: '1171', Name: 'Chaplain, armed forces' },
  { Id: 610, Code: '1171', Name: 'Colonel' },
  { Id: 611, Code: '1171', Name: 'Colonel-Commandant' },
  { Id: 612, Code: '1171', Name: 'Commandant, armed forces' },
  { Id: 613, Code: '1171', Name: 'Commander, armed forces' },
  { Id: 614, Code: '1171', Name: 'Commodore, armed forces' },
  { Id: 615, Code: '1171', Name: 'Director, WRNS' },
  { Id: 616, Code: '1171', Name: 'Field-Marshal' },
  { Id: 617, Code: '1171', Name: 'Flight-Lieutenant' },
  { Id: 618, Code: '1171', Name: 'General' },
  { Id: 619, Code: '1171', Name: 'Leader, Squadron' },
  { Id: 620, Code: '1171', Name: 'Lieutenant' },
  { Id: 621, Code: '1171', Name: 'Lieutenant-Colonel' },
  { Id: 622, Code: '1171', Name: 'Lieutenant-Commander' },
  { Id: 623, Code: '1171', Name: 'Lieutenant-General' },
  { Id: 624, Code: '1171', Name: 'Major, armed forces' },
  { Id: 625, Code: '1171', Name: 'Major-General' },
  { Id: 626, Code: '1171', Name: 'Marshal of the RAF' },
  { Id: 627, Code: '1171', Name: 'Marshal of the Royal Air Force' },
  { Id: 628, Code: '1171', Name: 'Marshal, air' },
  { Id: 629, Code: '1171', Name: 'Marshal, field' },
  { Id: 630, Code: '1171', Name: 'Midshipman, armed forces' },
  { Id: 631, Code: '1171', Name: 'Officer, armed forces' },
  { Id: 632, Code: '1171', Name: 'Officer, army' },
  { Id: 633, Code: '1171', Name: 'Officer, commanding' },
  { Id: 634, Code: '1171', Name: 'Officer, commando, Marines, Royal' },
  { Id: 635, Code: '1171', Name: 'Officer, commissioned' },
  { Id: 636, Code: '1171', Name: 'Officer, disposal, bomb' },
  { Id: 637, Code: '1171', Name: 'Officer, flag' },
  { Id: 638, Code: '1171', Name: 'Officer, flying' },
  { Id: 639, Code: '1171', Name: 'Officer, Force, Air, Royal' },
  { Id: 640, Code: '1171', Name: 'Officer, Marines, Royal' },
  { Id: 641, Code: '1171', Name: 'Officer, medical, armed forces' },
  { Id: 642, Code: '1171', Name: 'Officer, naval, Royal' },
  { Id: 643, Code: '1171', Name: 'Officer, Navy, Royal' },
  { Id: 644, Code: '1171', Name: 'Officer, pilot, armed forces' },
  { Id: 645, Code: '1171', Name: 'Officer, RAF' },
  { Id: 646, Code: '1171', Name: 'Officer, staff, general' },
  { Id: 647, Code: '1171', Name: 'Officer, WRNS' },
  { Id: 648, Code: '1171', Name: 'Quartermaster-General' },
  { Id: 649, Code: '1171', Name: 'Squadron-Leader' },
  { Id: 650, Code: '1171', Name: 'Sub-Lieutenant' },
  { Id: 651, Code: '1171', Name: 'Superintendent, WRNS' },
  { Id: 652, Code: '1172', Name: 'Commander, police service' },
  { Id: 653, Code: '1172', Name: 'Commissioner, police service' },
  { Id: 654, Code: '1172', Name: 'Constable, chief' },
  { Id: 655, Code: '1172', Name: 'Constable, chief, assistant' },
  { Id: 656, Code: '1172', Name: 'Constable, chief, deputy' },
  { Id: 657, Code: '1172', Name: 'Inspector, chief, police service' },
  { Id: 658, Code: '1172', Name: 'Inspector, detective' },
  { Id: 659, Code: '1172', Name: 'Inspector, divisional, police service' },
  { Id: 660, Code: '1172', Name: 'Inspector, police' },
  { Id: 661, Code: '1172', Name: 'Inspector, police service' },
  { Id: 662, Code: '1172', Name: 'Officer, CID' },
  { Id: 663, Code: '1172', Name: 'Sub-inspector, MOD' },
  { Id: 664, Code: '1172', Name: 'Superintendent, chief, police service' },
  { Id: 665, Code: '1172', Name: 'Superintendent, police service' },
  { Id: 666, Code: '1173', Name: 'Captain, fire' },
  { Id: 667, Code: '1173', Name: 'Commandant, fire service' },
  { Id: 668, Code: '1173', Name: 'Commander, ambulance service' },
  { Id: 669, Code: '1173', Name: 'Commander, fire service' },
  { Id: 670, Code: '1173', Name: 'Controller, area, fire service' },
  { Id: 671, Code: '1173', Name: 'Controller, fire service' },
  { Id: 672, Code: '1173', Name: 'Controller, prison service' },
  { Id: 673, Code: '1173', Name: 'Controller, senior, fire service' },
  { Id: 674, Code: '1173', Name: 'Firemaster, SCOTLAND' },
  { Id: 675, Code: '1173', Name: 'Governor, prison' },
  { Id: 676, Code: '1173', Name: 'Governor, prison service' },
  { Id: 677, Code: '1173', Name: 'Inspector, fire, fire service' },
  { Id: 678, Code: '1173', Name: 'Inspector, senior, HM Revenue and Customs' },
  { Id: 679, Code: '1173', Name: 'Manager, area, fire service' },
  { Id: 680, Code: '1173', Name: 'Manager, brigade' },
  { Id: 681, Code: '1173', Name: 'Manager, group, fire service' },
  { Id: 682, Code: '1173', Name: 'Manager, shift, UK Border Agency' },
  { Id: 683, Code: '1173', Name: 'Manager, station, AMBULANCE' },
  { Id: 684, Code: '1173', Name: 'Manager, station, fire service' },
  { Id: 685, Code: '1173', Name: 'Officer, ambulance, chief' },
  { Id: 686, Code: '1173', Name: 'Officer, brigade, fire' },
  { Id: 687, Code: '1173', Name: 'Officer, chief, fire service' },
  { Id: 688, Code: '1173', Name: 'Officer, chief, prison service' },
  { Id: 689, Code: '1173', Name: 'Officer, company, fire service' },
  {
    Id: 690,
    Code: '1173',
    Name: 'Officer, control, fire, principal, fire service'
  },
  { Id: 691, Code: '1173', Name: 'Officer, corps, salvage' },
  { Id: 692, Code: '1173', Name: 'Officer, district, salvage corps' },
  { Id: 693, Code: '1173', Name: 'Officer, divisional, fire service' },
  { Id: 694, Code: '1173', Name: 'Officer, fire, authority, local' },
  { Id: 695, Code: '1173', Name: 'Officer, fire, chief' },
  { Id: 696, Code: '1173', Name: 'Officer, fire, divisional' },
  { Id: 697, Code: '1173', Name: 'Officer, first, fire service' },
  {
    Id: 698,
    Code: '1173',
    Name: 'Officer, fraud, chief, HM Revenue and Customs'
  },
  { Id: 699, Code: '1173', Name: 'Officer, immigration, chief' },
  {
    Id: 700,
    Code: '1173',
    Name: 'Officer, investigating, chief, HM Revenue and Customs'
  },
  {
    Id: 701,
    Code: '1173',
    Name: 'Officer, investigating, fraud, chief, HM Revenue and Customs'
  },
  {
    Id: 702,
    Code: '1173',
    Name: 'Officer, investigation, chief, HM Revenue and Customs'
  },
  {
    Id: 703,
    Code: '1173',
    Name: 'Officer, investigation, fraud, chief, HM Revenue and Customs'
  },
  { Id: 704, Code: '1173', Name: 'Officer, preventive, chief' },
  { Id: 705, Code: '1173', Name: 'Officer, principal, prison service' },
  { Id: 706, Code: '1173', Name: 'Officer, prison, chief' },
  { Id: 707, Code: '1173', Name: 'Officer, prison, principal' },
  { Id: 708, Code: '1173', Name: 'Officer, protection, fire' },
  { Id: 709, Code: '1173', Name: 'Officer, salvage, fire' },
  { Id: 710, Code: '1173', Name: 'Officer, salvage, salvage corps' },
  { Id: 711, Code: '1173', Name: 'Officer, second, fire service' },
  { Id: 712, Code: '1173', Name: 'Officer, senior, HM Revenue and Customs' },
  { Id: 713, Code: '1173', Name: 'Officer, service, fire, government' },
  { Id: 714, Code: '1173', Name: 'Officer, station, ambulance service' },
  { Id: 715, Code: '1173', Name: 'Officer, station, fire service' },
  { Id: 716, Code: '1173', Name: 'Officer, station, salvage corps' },
  { Id: 717, Code: '1173', Name: 'Officer, third, fire service' },
  { Id: 718, Code: '1173', Name: 'Superintendent, ambulance service' },
  { Id: 719, Code: '1173', Name: 'Superintendent, fire' },
  { Id: 720, Code: '1173', Name: 'Superintendent, HM Revenue and Customs' },
  { Id: 721, Code: '1173', Name: 'Superintendent, prison service' },
  { Id: 722, Code: '1173', Name: 'Superintendent, salvage corps' },
  { Id: 723, Code: '1173', Name: 'Surveyor of Customs and Excise' },
  { Id: 724, Code: '1173', Name: 'Surveyor, HM Revenue and Customs' },
  { Id: 725, Code: '1173', Name: 'Warder, chief' },
  { Id: 726, Code: '1181', Name: 'Commissioner, health authority' },
  { Id: 727, Code: '1181', Name: 'Director of clinical services' },
  { Id: 728, Code: '1181', Name: 'Director of nursing' },
  { Id: 729, Code: '1181', Name: 'Director of nursing services' },
  {
    Id: 730,
    Code: '1181',
    Name: 'Director, non-executive, health authority: hospital service'
  },
  { Id: 731, Code: '1181', Name: 'Director, nursing' },
  { Id: 732, Code: '1181', Name: 'Director, operations, health authority' },
  { Id: 733, Code: '1181', Name: 'Manager, care, health' },
  {
    Id: 734,
    Code: '1181',
    Name: 'Manager, care, health authority: hospital service'
  },
  {
    Id: 735,
    Code: '1181',
    Name: 'Manager, centre, postgraduate, health authority: hospital service'
  },
  { Id: 736, Code: '1181', Name: 'Manager, clinical' },
  {
    Id: 737,
    Code: '1181',
    Name: 'Manager, district, health authority: hospital service'
  },
  {
    Id: 738,
    Code: '1181',
    Name: 'Manager, general, unit, health authority: hospital service'
  },
  { Id: 739, Code: '1181', Name: 'Manager, governance, clinical' },
  { Id: 740, Code: '1181', Name: 'Manager, health authority' },
  { Id: 741, Code: '1181', Name: 'Manager, healthcare' },
  { Id: 742, Code: '1181', Name: 'Manager, hospital service' },
  {
    Id: 743,
    Code: '1181',
    Name: 'Manager, information, health authority: hospital service'
  },
  {
    Id: 744,
    Code: '1181',
    Name: 'Manager, locality, health authority: hospital service'
  },
  { Id: 745, Code: '1181', Name: 'Manager, nursing services' },
  { Id: 746, Code: '1181', Name: 'Manager, operations, hospital' },
  {
    Id: 747,
    Code: '1181',
    Name: 'Manager, screening, health, health authority: hospital service'
  },
  {
    Id: 748,
    Code: '1181',
    Name: 'Manager, sector, health authority: hospital service'
  },
  { Id: 749, Code: '1181', Name: 'Manager, service, health' },
  { Id: 750, Code: '1181', Name: 'Manager, service, health authority' },
  { Id: 751, Code: '1181', Name: 'Manager, service, hospital' },
  { Id: 752, Code: '1181', Name: 'Manager, services, clinical' },
  { Id: 753, Code: '1181', Name: 'Manager, services, hospital, area' },
  { Id: 754, Code: '1181', Name: 'Manager, services, limb' },
  { Id: 755, Code: '1181', Name: 'Manager, site, clinical' },
  { Id: 756, Code: '1181', Name: 'Manager, support, RADIOLOGY' },
  { Id: 757, Code: '1181', Name: 'Manager, vaccination' },
  { Id: 758, Code: '1181', Name: 'Superintendent, hospital service' },
  { Id: 759, Code: '1184', Name: 'Commissioner, social services' },
  { Id: 760, Code: '1184', Name: 'Director of social services' },
  { Id: 761, Code: '1184', Name: 'Manager, care, community' },
  {
    Id: 762,
    Code: '1184',
    Name: 'Manager, care, local government: social services'
  },
  { Id: 763, Code: '1184', Name: 'Manager, engagement, community' },
  {
    Id: 764,
    Code: '1184',
    Name: 'Manager, foyer, local government: social services'
  },
  { Id: 765, Code: '1184', Name: 'Manager, involvement, community' },
  { Id: 766, Code: '1184', Name: 'Manager, local government: social services' },
  {
    Id: 767,
    Code: '1184',
    Name: 'Manager, locality, local government: social services'
  },
  {
    Id: 768,
    Code: '1184',
    Name: 'Manager, people, supporting, local government'
  },
  {
    Id: 769,
    Code: '1184',
    Name: 'Manager, service, local government: social services'
  },
  { Id: 770, Code: '1184', Name: 'Manager, service, welfare services' },
  {
    Id: 771,
    Code: '1184',
    Name: "Manager, services, children's, social, welfare services"
  },
  { Id: 772, Code: '1184', Name: 'Manager, services, community' },
  { Id: 773, Code: '1184', Name: 'Manager, services, social' },
  {
    Id: 774,
    Code: '1184',
    Name: 'Manager, support, local government social services, NON-RESIDENTIAL'
  },
  {
    Id: 775,
    Code: '1184',
    Name: 'Manager, unit, local government social services, NON-RESIDENTIAL'
  },
  { Id: 776, Code: '1184', Name: 'Manager, welfare services' },
  { Id: 777, Code: '1184', Name: 'Manager, work, social' },
  { Id: 778, Code: '1184', Name: 'Secretary, managing, welfare services' },
  { Id: 779, Code: '1190', Name: 'Director, franchise' },
  {
    Id: 780,
    Code: '1190',
    Name: 'Director, managing, wholesale, retail trade'
  },
  { Id: 781, Code: '1190', Name: 'Director, retail' },
  { Id: 782, Code: '1190', Name: 'Director, retail trade' },
  {
    Id: 783,
    Code: '1190',
    Name: 'Director, technical, wholesale, retail trade'
  },
  { Id: 784, Code: '1190', Name: 'Director, wholesale trade' },
  { Id: 785, Code: '1190', Name: 'Licensee, petrol station' },
  { Id: 786, Code: '1190', Name: 'Manager, area, retail trade' },
  { Id: 787, Code: '1190', Name: 'Manager, bookshop' },
  { Id: 788, Code: '1190', Name: 'Manager, branch, wholesale, retail trade' },
  { Id: 789, Code: '1190', Name: "Manager, builders' merchants" },
  { Id: 790, Code: '1190', Name: "Manager, butcher's" },
  { Id: 791, Code: '1190', Name: 'Manager, centre, garden' },
  { Id: 792, Code: '1190', Name: 'Manager, CHARITABLE ORGANISATION: RETAIL' },
  { Id: 793, Code: '1190', Name: 'Manager, circulation' },
  { Id: 794, Code: '1190', Name: 'Manager, club, clothing' },
  { Id: 795, Code: '1190', Name: 'Manager, dairy' },
  { Id: 796, Code: '1190', Name: 'Manager, district, retail trade' },
  { Id: 797, Code: '1190', Name: 'Manager, district, wholesale trade' },
  { Id: 798, Code: '1190', Name: 'Manager, filling station' },
  { Id: 799, Code: '1190', Name: "Manager, fishmonger's" },
  { Id: 800, Code: '1190', Name: 'Manager, florist' },
  { Id: 801, Code: '1190', Name: 'Manager, fuel merchant' },
  { Id: 802, Code: '1190', Name: 'Manager, garden centre' },
  { Id: 803, Code: '1190', Name: 'Manager, licence, off' },
  { Id: 804, Code: '1190', Name: 'Manager, livestock dealing' },
  { Id: 805, Code: '1190', Name: 'Manager, mail order establishment' },
  { Id: 806, Code: '1190', Name: 'Manager, NAAFI: shop' },
  { Id: 807, Code: '1190', Name: 'Manager, off-licence' },
  { Id: 808, Code: '1190', Name: 'Manager, operations, retail' },
  { Id: 809, Code: '1190', Name: 'Manager, operations, wholesale' },
  {
    Id: 810,
    Code: '1190',
    Name: 'Manager, operations, wholesale, retail trade'
  },
  { Id: 811, Code: '1190', Name: 'Manager, order, mail' },
  { Id: 812, Code: '1190', Name: 'Manager, pawnbrokers' },
  { Id: 813, Code: '1190', Name: 'Manager, petrol station' },
  { Id: 814, Code: '1190', Name: 'Manager, practice, opticians' },
  {
    Id: 815,
    Code: '1190',
    Name: 'Manager, production, wholesale, retail trade'
  },
  { Id: 816, Code: '1190', Name: 'Manager, retail' },
  { Id: 817, Code: '1190', Name: 'Manager, retail trade' },
  { Id: 818, Code: '1190', Name: 'Manager, room, sales' },
  { Id: 819, Code: '1190', Name: 'Manager, room, show' },
  { Id: 820, Code: '1190', Name: 'Manager, sales, fleet' },
  { Id: 821, Code: '1190', Name: 'Manager, sales, retail trade' },
  { Id: 822, Code: '1190', Name: 'Manager, sales, wholesale trade' },
  { Id: 823, Code: '1190', Name: 'Manager, service, retail trade' },
  { Id: 824, Code: '1190', Name: 'Manager, shift, wholesale, retail trade' },
  { Id: 825, Code: '1190', Name: 'Manager, shop, agriculture' },
  { Id: 826, Code: '1190', Name: "Manager, shop, butcher's" },
  { Id: 827, Code: '1190', Name: 'Manager, shop, CHARITABLE ORGANISATION' },
  { Id: 828, Code: '1190', Name: 'Manager, shop, charity' },
  { Id: 829, Code: '1190', Name: 'Manager, shop, farm' },
  { Id: 830, Code: '1190', Name: "Manager, shop, fishmonger's" },
  { Id: 831, Code: '1190', Name: 'Manager, shop, horticulture' },
  { Id: 832, Code: '1190', Name: 'Manager, shop, retail trade: pharmacists' },
  { Id: 833, Code: '1190', Name: 'Manager, shop, retail, wholesale trade' },
  { Id: 834, Code: '1190', Name: 'Manager, showroom' },
  { Id: 835, Code: '1190', Name: 'Manager, site, retail trade' },
  { Id: 836, Code: '1190', Name: 'Manager, stall, book' },
  { Id: 837, Code: '1190', Name: 'Manager, station, petrol' },
  { Id: 838, Code: '1190', Name: 'Manager, station, service' },
  { Id: 839, Code: '1190', Name: 'Manager, steel stockholders' },
  { Id: 840, Code: '1190', Name: 'Manager, store, wholesale, retail trade' },
  { Id: 841, Code: '1190', Name: 'Manager, stores, wholesale, retail trade' },
  { Id: 842, Code: '1190', Name: 'Manager, supermarket' },
  { Id: 843, Code: '1190', Name: "Manager, tailor's" },
  {
    Id: 844,
    Code: '1190',
    Name: 'Manager, technical, wholesale, retail trade'
  },
  { Id: 845, Code: '1190', Name: 'Manager, timber merchants' },
  { Id: 846, Code: '1190', Name: 'Manager, trade' },
  { Id: 847, Code: '1190', Name: 'Manager, wholesale' },
  { Id: 848, Code: '1190', Name: 'Manager, wholesale trade' },
  { Id: 849, Code: '1211', Name: 'Maker, wine' },
  { Id: 850, Code: '1211', Name: 'Manager, agricultural contracting' },
  { Id: 851, Code: '1211', Name: 'Manager, agriculture' },
  { Id: 852, Code: '1211', Name: 'Manager, farm' },
  { Id: 853, Code: '1211', Name: 'Manager, fruit growing' },
  { Id: 854, Code: '1211', Name: 'Manager, horticulture' },
  { Id: 855, Code: '1211', Name: 'Manager, market gardening' },
  { Id: 856, Code: '1211', Name: 'Manager, nursery, horticulture' },
  { Id: 857, Code: '1211', Name: 'Manager, operations, horticulture' },
  { Id: 858, Code: '1211', Name: 'Manager, production, horticulture' },
  { Id: 859, Code: '1211', Name: 'Manager, stock, farm' },
  { Id: 860, Code: '1211', Name: 'Manager, stock, farming' },
  { Id: 861, Code: '1211', Name: 'Manager, stock, live' },
  { Id: 862, Code: '1211', Name: 'Owner, farm' },
  { Id: 863, Code: '1211', Name: 'Owner, horticulture' },
  { Id: 864, Code: '1211', Name: 'Owner, nursery: horticultural' },
  { Id: 865, Code: '1211', Name: 'Viticulturist' },
  { Id: 866, Code: '1213', Name: 'Manager, clam cultivation' },
  { Id: 867, Code: '1213', Name: 'Manager, farm, fish' },
  { Id: 868, Code: '1213', Name: 'Manager, farm, stud' },
  { Id: 869, Code: '1213', Name: 'Manager, fish hatchery' },
  { Id: 870, Code: '1213', Name: 'Manager, fishing company' },
  { Id: 871, Code: '1213', Name: 'Manager, forest' },
  { Id: 872, Code: '1213', Name: 'Manager, forestry' },
  {
    Id: 873,
    Code: '1213',
    Name: 'Manager, gardening, grounds keeping services'
  },
  { Id: 874, Code: '1213', Name: 'Manager, kennel' },
  { Id: 875, Code: '1213', Name: 'Manager, livery stable' },
  { Id: 876, Code: '1213', Name: 'Manager, mussel cultivation' },
  { Id: 877, Code: '1213', Name: 'Manager, nursery, forest' },
  { Id: 878, Code: '1213', Name: 'Manager, oyster cultivation' },
  { Id: 879, Code: '1213', Name: 'Manager, stud' },
  { Id: 880, Code: '1213', Name: 'Manager, tree felling services and related' },
  { Id: 881, Code: '1213', Name: 'Manager, yard, livery' },
  { Id: 882, Code: '1213', Name: 'Manager, yard, livery stable' },
  { Id: 883, Code: '1213', Name: 'Manager, zoological gardens' },
  { Id: 884, Code: '1213', Name: 'Mariner, master, fishing' },
  { Id: 885, Code: '1213', Name: 'Master, fishing' },
  { Id: 886, Code: '1213', Name: 'Owner, agricultural machinery contracting' },
  { Id: 887, Code: '1213', Name: 'Owner, boat, fishing' },
  { Id: 888, Code: '1213', Name: 'Owner, boat: fishing' },
  { Id: 889, Code: '1213', Name: 'Owner, cattery' },
  { Id: 890, Code: '1213', Name: 'Owner, fish hatchery' },
  { Id: 891, Code: '1213', Name: 'Owner, fishing vessel' },
  { Id: 892, Code: '1213', Name: 'Owner, hatchery, fish' },
  { Id: 893, Code: '1213', Name: 'Owner, kennels' },
  { Id: 894, Code: '1213', Name: 'Owner, landscape gardening' },
  { Id: 895, Code: '1213', Name: 'Owner, livery stable' },
  { Id: 896, Code: '1213', Name: 'Trainer, horse, race' },
  { Id: 897, Code: '1213', Name: 'Trainer, racehorse' },
  { Id: 898, Code: '1221', Name: 'Bursar, domestic' },
  { Id: 899, Code: '1221', Name: 'Hirer, site, caravan' },
  { Id: 900, Code: '1221', Name: 'Hotelier' },
  { Id: 901, Code: '1221', Name: 'Keeper, hotel' },
  { Id: 902, Code: '1221', Name: 'Keeper, house, boarding' },
  { Id: 903, Code: '1221', Name: 'Landlady, boarding, guest, lodging house' },
  { Id: 904, Code: '1221', Name: 'Landlord, boarding, guest, lodging house' },
  { Id: 905, Code: '1221', Name: 'Manager, accommodation' },
  { Id: 906, Code: '1221', Name: 'Manager, accommodations' },
  { Id: 907, Code: '1221', Name: 'Manager, boatel' },
  { Id: 908, Code: '1221', Name: 'Manager, camp, holiday' },
  { Id: 909, Code: '1221', Name: 'Manager, camping site' },
  { Id: 910, Code: '1221', Name: 'Manager, caravan site' },
  { Id: 911, Code: '1221', Name: 'Manager, centre, holiday' },
  { Id: 912, Code: '1221', Name: 'Manager, club, residential' },
  { Id: 913, Code: '1221', Name: 'Manager, farm, health' },
  { Id: 914, Code: '1221', Name: 'Manager, flats, holiday' },
  { Id: 915, Code: '1221', Name: 'Manager, flats, service' },
  { Id: 916, Code: '1221', Name: 'Manager, hall of residence' },
  {
    Id: 917,
    Code: '1221',
    Name: 'Manager, hall, higher education, university'
  },
  { Id: 918, Code: '1221', Name: 'Manager, holiday camp' },
  { Id: 919, Code: '1221', Name: 'Manager, holiday flats' },
  { Id: 920, Code: '1221', Name: 'Manager, hostel' },
  { Id: 921, Code: '1221', Name: 'Manager, hotel' },
  { Id: 922, Code: '1221', Name: 'Manager, hotels' },
  { Id: 923, Code: '1221', Name: 'Manager, house, boarding' },
  { Id: 924, Code: '1221', Name: 'Manager, house, guest' },
  { Id: 925, Code: '1221', Name: 'Manager, house, lodging' },
  { Id: 926, Code: '1221', Name: 'Manager, motel' },
  { Id: 927, Code: '1221', Name: 'Manager, park, caravan' },
  { Id: 928, Code: '1221', Name: 'Manager, passenger ships' },
  { Id: 929, Code: '1221', Name: 'Manager, residential club' },
  { Id: 930, Code: '1221', Name: 'Manager, services, hotel' },
  { Id: 931, Code: '1221', Name: 'Manager, site, camping' },
  { Id: 932, Code: '1221', Name: 'Manager, site, caravan' },
  { Id: 933, Code: '1221', Name: 'Operator, site, caravan' },
  { Id: 934, Code: '1221', Name: 'Owner, bed and breakfast accommodation' },
  { Id: 935, Code: '1221', Name: 'Owner, camping site' },
  { Id: 936, Code: '1221', Name: 'Owner, caravan site' },
  { Id: 937, Code: '1221', Name: 'Owner, flat, holiday' },
  { Id: 938, Code: '1221', Name: 'Owner, flats, holiday' },
  { Id: 939, Code: '1221', Name: 'Owner, guest house' },
  { Id: 940, Code: '1221', Name: 'Owner, holiday camp' },
  { Id: 941, Code: '1221', Name: 'Owner, holiday flats' },
  { Id: 942, Code: '1221', Name: 'Owner, hotel' },
  { Id: 943, Code: '1221', Name: 'Owner, house, guest' },
  { Id: 944, Code: '1221', Name: 'Owner, park, caravan' },
  { Id: 945, Code: '1221', Name: 'Owner, site, camping' },
  { Id: 946, Code: '1221', Name: 'Owner, site, caravan' },
  { Id: 947, Code: '1221', Name: 'Steward, college' },
  { Id: 948, Code: '1221', Name: 'Steward, hostel' },
  { Id: 949, Code: '1223', Name: 'Dealer, fish and chip' },
  { Id: 950, Code: '1223', Name: 'Manager, bar, sandwich' },
  { Id: 951, Code: '1223', Name: 'Manager, bar, snack' },
  { Id: 952, Code: '1223', Name: 'Manager, buffet' },
  { Id: 953, Code: '1223', Name: 'Manager, cafe' },
  { Id: 954, Code: '1223', Name: 'Manager, CATERING' },
  { Id: 955, Code: '1223', Name: 'Manager, club, CATERING' },
  { Id: 956, Code: '1223', Name: 'Manager, club, refreshment' },
  { Id: 957, Code: '1223', Name: 'Manager, district, catering' },
  { Id: 958, Code: '1223', Name: 'Manager, mess' },
  { Id: 959, Code: '1223', Name: 'Manager, NAAFI: canteen' },
  { Id: 960, Code: '1223', Name: 'Manager, operations, catering' },
  { Id: 961, Code: '1223', Name: 'Manager, restaurant' },
  { Id: 962, Code: '1223', Name: 'Manager, retail, catering' },
  { Id: 963, Code: '1223', Name: 'Manager, service, food' },
  { Id: 964, Code: '1223', Name: 'Manager, services, catering' },
  { Id: 965, Code: '1223', Name: 'Manager, services, food' },
  { Id: 966, Code: '1223', Name: 'Manager, shift, catering' },
  { Id: 967, Code: '1223', Name: 'Manager, shift, fast food outlet' },
  { Id: 968, Code: '1223', Name: 'Manager, shift, restaurant' },
  { Id: 969, Code: '1223', Name: 'Manager, shift, take-away food shop' },
  { Id: 970, Code: '1223', Name: 'Manager, shop, fish and chip' },
  { Id: 971, Code: '1223', Name: 'Manager, shop, take-away food shop' },
  { Id: 972, Code: '1223', Name: 'Manager, store, cafe' },
  { Id: 973, Code: '1223', Name: 'Manager, store, coffee shop' },
  { Id: 974, Code: '1223', Name: 'Manager, store, restaurant' },
  { Id: 975, Code: '1223', Name: 'Manager, store, sandwich shop' },
  { Id: 976, Code: '1223', Name: 'Manager, tea room' },
  { Id: 977, Code: '1223', Name: 'Owner, cafe' },
  { Id: 978, Code: '1223', Name: 'Owner, fast food outlet' },
  { Id: 979, Code: '1223', Name: 'Owner, fish and chip shop' },
  { Id: 980, Code: '1223', Name: 'Owner, restaurant' },
  { Id: 981, Code: '1223', Name: 'Owner, room, tea' },
  { Id: 982, Code: '1223', Name: 'Owner, sandwich bar' },
  { Id: 983, Code: '1223', Name: 'Owner, shop, chip' },
  { Id: 984, Code: '1223', Name: 'Owner, shop, coffee' },
  { Id: 985, Code: '1223', Name: 'Owner, take-away food shop' },
  { Id: 986, Code: '1223', Name: 'Restaurateur' },
  { Id: 987, Code: '1223', Name: 'Retailer, take-away food shop' },
  { Id: 988, Code: '1223', Name: 'Shopkeeper, fish and chip' },
  { Id: 989, Code: '1223', Name: 'Shopkeeper, fish, fried' },
  { Id: 990, Code: '1223', Name: 'Shopkeeper, FRIED FISH' },
  { Id: 991, Code: '1223', Name: 'Shopkeeper, take-away food shop' },
  { Id: 992, Code: '1224', Name: 'Innkeeper' },
  { Id: 993, Code: '1224', Name: 'Keeper, inn' },
  { Id: 994, Code: '1224', Name: 'Landlady, public house' },
  { Id: 995, Code: '1224', Name: 'Landlord, house, public' },
  { Id: 996, Code: '1224', Name: 'Landlord, public house' },
  { Id: 997, Code: '1224', Name: 'Licensee' },
  { Id: 998, Code: '1224', Name: 'Licensee, house, public' },
  { Id: 999, Code: '1224', Name: 'Manager, area, brewery' },
  { Id: 1000, Code: '1224', Name: 'Manager, bar, wine' },
  { Id: 1001, Code: '1224', Name: 'Manager, house, licensed' },
  { Id: 1002, Code: '1224', Name: 'Manager, house, public' },
  { Id: 1003, Code: '1224', Name: 'Manager, licensee' },
  { Id: 1004, Code: '1224', Name: 'Manager, pub' },
  { Id: 1005, Code: '1224', Name: 'Manager, public house' },
  { Id: 1006, Code: '1224', Name: 'Manager, retail, public house' },
  { Id: 1007, Code: '1224', Name: 'Manager, shift, public house' },
  { Id: 1008, Code: '1224', Name: 'Manager, wine bar' },
  { Id: 1009, Code: '1224', Name: 'Owner, bar, wine' },
  { Id: 1010, Code: '1224', Name: 'Owner, club' },
  { Id: 1011, Code: '1224', Name: 'Owner, house, public' },
  { Id: 1012, Code: '1224', Name: 'Owner, public house' },
  { Id: 1013, Code: '1224', Name: 'Owner, wine bar' },
  { Id: 1014, Code: '1224', Name: 'Publican' },
  { Id: 1015, Code: '1224', Name: 'Publican-licensee' },
  { Id: 1016, Code: '1224', Name: 'Tenant, house, public' },
  { Id: 1017, Code: '1224', Name: 'Victualler, licensed' },
  { Id: 1018, Code: '1225', Name: 'Clerk and steward' },
  { Id: 1019, Code: '1225', Name: 'Clerk of the course' },
  { Id: 1020, Code: '1225', Name: 'Director, gallery' },
  { Id: 1021, Code: '1225', Name: 'Director, gallery, art' },
  { Id: 1022, Code: '1225', Name: 'Director, museum' },
  { Id: 1023, Code: '1225', Name: 'Director, operational, bound, outward' },
  { Id: 1024, Code: '1225', Name: 'Director, zoo' },
  { Id: 1025, Code: '1225', Name: 'Manager, alley, bowling' },
  { Id: 1026, Code: '1225', Name: 'Manager, amusement arcade' },
  { Id: 1027, Code: '1225', Name: 'Manager, arcade, amusement' },
  { Id: 1028, Code: '1225', Name: 'Manager, baths' },
  { Id: 1029, Code: '1225', Name: 'Manager, baths, sauna' },
  {
    Id: 1030,
    Code: '1225',
    Name: 'Manager, betting and gambling: casino, gaming club'
  },
  { Id: 1031, Code: '1225', Name: 'Manager, bingo' },
  { Id: 1032, Code: '1225', Name: 'Manager, bingo hall' },
  { Id: 1033, Code: '1225', Name: 'Manager, bound, outward' },
  { Id: 1034, Code: '1225', Name: 'Manager, broadcasting' },
  { Id: 1035, Code: '1225', Name: 'Manager, cable television broadcasting' },
  { Id: 1036, Code: '1225', Name: 'Manager, casino' },
  { Id: 1037, Code: '1225', Name: 'Manager, centre, arts' },
  { Id: 1038, Code: '1225', Name: 'Manager, centre, bound, outward' },
  { Id: 1039, Code: '1225', Name: 'Manager, centre, equestrian' },
  { Id: 1040, Code: '1225', Name: 'Manager, centre, fitness' },
  { Id: 1041, Code: '1225', Name: 'Manager, centre, leisure' },
  { Id: 1042, Code: '1225', Name: 'Manager, centre, sports' },
  { Id: 1043, Code: '1225', Name: 'Manager, cinema' },
  { Id: 1044, Code: '1225', Name: 'Manager, circuit, ENTERTAINMENT' },
  { Id: 1045, Code: '1225', Name: 'Manager, circus' },
  { Id: 1046, Code: '1225', Name: 'Manager, club' },
  { Id: 1047, Code: '1225', Name: 'Manager, club, football club' },
  { Id: 1048, Code: '1225', Name: 'Manager, club, health' },
  { Id: 1049, Code: '1225', Name: 'Manager, club, social' },
  { Id: 1050, Code: '1225', Name: 'Manager, course, golf' },
  { Id: 1051, Code: '1225', Name: 'Manager, entertainment' },
  { Id: 1052, Code: '1225', Name: 'Manager, fairground' },
  { Id: 1053, Code: '1225', Name: 'Manager, football club' },
  { Id: 1054, Code: '1225', Name: 'Manager, front of house, ENTERTAINMENT' },
  { Id: 1055, Code: '1225', Name: 'Manager, gallery' },
  { Id: 1056, Code: '1225', Name: 'Manager, golf club' },
  { Id: 1057, Code: '1225', Name: 'Manager, ground, cricket' },
  { Id: 1058, Code: '1225', Name: 'Manager, ground, football' },
  { Id: 1059, Code: '1225', Name: 'Manager, hall, bingo' },
  { Id: 1060, Code: '1225', Name: 'Manager, hall, concert' },
  { Id: 1061, Code: '1225', Name: 'Manager, hall, dance' },
  { Id: 1062, Code: '1225', Name: 'Manager, health and fitness club' },
  { Id: 1063, Code: '1225', Name: 'Manager, health club' },
  { Id: 1064, Code: '1225', Name: 'Manager, house, ENTERTAINMENT' },
  { Id: 1065, Code: '1225', Name: 'Manager, leisure' },
  { Id: 1066, Code: '1225', Name: 'Manager, leisure centre' },
  { Id: 1067, Code: '1225', Name: 'Manager, LEISURE SERVICES' },
  { Id: 1068, Code: '1225', Name: 'Manager, marina' },
  { Id: 1069, Code: '1225', Name: 'Manager, museum' },
  { Id: 1070, Code: '1225', Name: 'Manager, night club' },
  { Id: 1071, Code: '1225', Name: 'Manager, operations, leisure services' },
  { Id: 1072, Code: '1225', Name: 'Manager, park' },
  { Id: 1073, Code: '1225', Name: 'Manager, park, theme' },
  { Id: 1074, Code: '1225', Name: 'Manager, promotions, sports' },
  { Id: 1075, Code: '1225', Name: 'Manager, public baths' },
  { Id: 1076, Code: '1225', Name: 'Manager, pursuits, outdoor' },
  { Id: 1077, Code: '1225', Name: 'Manager, racecourse' },
  { Id: 1078, Code: '1225', Name: 'Manager, radio broadcasting' },
  { Id: 1079, Code: '1225', Name: 'Manager, radio station' },
  { Id: 1080, Code: '1225', Name: 'Manager, riding school' },
  { Id: 1081, Code: '1225', Name: 'Manager, rink, skating' },
  { Id: 1082, Code: '1225', Name: 'Manager, room, ball' },
  { Id: 1083, Code: '1225', Name: 'Manager, school, riding school' },
  { Id: 1084, Code: '1225', Name: 'Manager, service, leisure services' },
  { Id: 1085, Code: '1225', Name: 'Manager, services, leisure' },
  { Id: 1086, Code: '1225', Name: 'Manager, services, recreation' },
  { Id: 1087, Code: '1225', Name: 'Manager, snooker, billiards hall' },
  { Id: 1088, Code: '1225', Name: 'Manager, social club' },
  { Id: 1089, Code: '1225', Name: 'Manager, sports' },
  { Id: 1090, Code: '1225', Name: 'Manager, sports activities' },
  { Id: 1091, Code: '1225', Name: 'Manager, stadium' },
  { Id: 1092, Code: '1225', Name: 'Manager, stand, ENTERTAINMENT' },
  {
    Id: 1093,
    Code: '1225',
    Name: 'Manager, television: production, broadcasting'
  },
  { Id: 1094, Code: '1225', Name: 'Manager, theatre' },
  { Id: 1095, Code: '1225', Name: 'Master, club' },
  { Id: 1096, Code: '1225', Name: 'Owner, amusement arcade' },
  { Id: 1097, Code: '1225', Name: 'Owner, arcade, amusement' },
  { Id: 1098, Code: '1225', Name: 'Owner, cinema' },
  { Id: 1099, Code: '1225', Name: 'Owner, circus' },
  { Id: 1100, Code: '1225', Name: 'Owner, club, night' },
  { Id: 1101, Code: '1225', Name: 'Owner, club, sports' },
  { Id: 1102, Code: '1225', Name: 'Owner, club: night' },
  { Id: 1103, Code: '1225', Name: 'Owner, club: sports' },
  { Id: 1104, Code: '1225', Name: 'Owner, dance hall' },
  { Id: 1105, Code: '1225', Name: 'Owner, hall, dance' },
  { Id: 1106, Code: '1225', Name: 'Owner, health and fitness studio' },
  { Id: 1107, Code: '1225', Name: 'Owner, museum' },
  { Id: 1108, Code: '1225', Name: 'Owner, riding stable' },
  { Id: 1109, Code: '1225', Name: 'Owner, school, riding' },
  { Id: 1110, Code: '1225', Name: 'Owner, school: riding' },
  { Id: 1111, Code: '1225', Name: 'Owner, skating rink' },
  { Id: 1112, Code: '1225', Name: 'Owner, skittle alley' },
  { Id: 1113, Code: '1225', Name: 'Owner, snooker, billiards hall' },
  { Id: 1114, Code: '1226', Name: 'Manager, agency, travel' },
  { Id: 1115, Code: '1226', Name: 'Manager, agent, travel' },
  { Id: 1116, Code: '1226', Name: 'Manager, information, tourist' },
  { Id: 1117, Code: '1226', Name: 'Manager, operations, travel agents' },
  { Id: 1118, Code: '1226', Name: 'Manager, retail, travel agents' },
  { Id: 1119, Code: '1226', Name: 'Manager, shop, travel agents' },
  { Id: 1120, Code: '1226', Name: 'Manager, tourist' },
  { Id: 1121, Code: '1226', Name: 'Manager, tours' },
  { Id: 1122, Code: '1226', Name: 'Manager, travel' },
  { Id: 1123, Code: '1226', Name: 'Manager, travel agents' },
  { Id: 1124, Code: '1226', Name: 'Owner, agency, travel' },
  { Id: 1125, Code: '1226', Name: 'Owner, travel agents' },
  { Id: 1126, Code: '1241', Name: 'Manager, clinic' },
  { Id: 1127, Code: '1241', Name: 'Manager, fundholding, medical practice' },
  { Id: 1128, Code: '1241', Name: 'Manager, holding, fund, medical practice' },
  { Id: 1129, Code: '1241', Name: 'Manager, medical practice' },
  { Id: 1130, Code: '1241', Name: 'Manager, practice, dental practice' },
  { Id: 1131, Code: '1241', Name: 'Manager, practice, GP' },
  { Id: 1132, Code: '1241', Name: 'Manager, practice, health services' },
  { Id: 1133, Code: '1241', Name: 'Manager, practice, medical practice' },
  { Id: 1134, Code: '1241', Name: 'Manager, practice, veterinary' },
  { Id: 1135, Code: '1241', Name: 'Manager, practice, veterinary surgery' },
  { Id: 1136, Code: '1241', Name: 'Manager, services, patient' },
  { Id: 1137, Code: '1242', Name: 'Manager of residential home' },
  { Id: 1138, Code: '1242', Name: 'Manager, care' },
  { Id: 1139, Code: '1242', Name: 'Manager, care, domiciliary' },
  { Id: 1140, Code: '1242', Name: 'Manager, care, home' },
  { Id: 1141, Code: '1242', Name: 'Manager, care, residential' },
  { Id: 1142, Code: '1242', Name: 'Manager, care, residential home' },
  { Id: 1143, Code: '1242', Name: "Manager, centre, children's" },
  { Id: 1144, Code: '1242', Name: 'Manager, centre, community' },
  { Id: 1145, Code: '1242', Name: 'Manager, centre, day' },
  { Id: 1146, Code: '1242', Name: "Manager, children's home" },
  { Id: 1147, Code: '1242', Name: 'Manager, home, nursing' },
  { Id: 1148, Code: '1242', Name: 'Manager, home, registered' },
  { Id: 1149, Code: '1242', Name: 'Manager, home, welfare services' },
  {
    Id: 1150,
    Code: '1242',
    Name: 'Manager, house, local government: social services'
  },
  { Id: 1151, Code: '1242', Name: 'Manager, house, residential home' },
  { Id: 1152, Code: '1242', Name: 'Manager, housing, sheltered' },
  { Id: 1153, Code: '1242', Name: 'Manager, nursing home' },
  { Id: 1154, Code: '1242', Name: "Manager, old people's home" },
  { Id: 1155, Code: '1242', Name: 'Manager, residential home' },
  { Id: 1156, Code: '1242', Name: 'Manager, residential, residential home' },
  { Id: 1157, Code: '1242', Name: 'Manager, service, care' },
  { Id: 1158, Code: '1242', Name: 'Manager, service, day' },
  { Id: 1159, Code: '1242', Name: 'Manager, service, residential home' },
  { Id: 1160, Code: '1242', Name: 'Manager, sheltered housing' },
  {
    Id: 1161,
    Code: '1242',
    Name: 'Manager, support, local government social services, RESIDENTIAL'
  },
  {
    Id: 1162,
    Code: '1242',
    Name: 'Manager, unit, local government social services, RESIDENTIAL'
  },
  { Id: 1163, Code: '1242', Name: 'Manager, unit, rehabilitation' },
  { Id: 1164, Code: '1242', Name: "Officer, principal, children's home" },
  { Id: 1165, Code: '1242', Name: "Officer, principal, old people's home" },
  {
    Id: 1166,
    Code: '1242',
    Name: 'Officer-in-charge, local government: social services'
  },
  { Id: 1167, Code: '1242', Name: 'Owner, convalescent home' },
  { Id: 1168, Code: '1242', Name: 'Owner, home, care' },
  { Id: 1169, Code: '1242', Name: 'Owner, home, convalescent' },
  { Id: 1170, Code: '1242', Name: 'Owner, home, nursing' },
  { Id: 1171, Code: '1242', Name: 'Owner, home, residential' },
  { Id: 1172, Code: '1242', Name: 'Owner, home, rest' },
  { Id: 1173, Code: '1242', Name: 'Owner, home, retirement' },
  { Id: 1174, Code: '1242', Name: 'Owner, nursing home' },
  { Id: 1175, Code: '1242', Name: "Owner, old people's home" },
  { Id: 1176, Code: '1242', Name: 'Owner, residential home' },
  { Id: 1177, Code: '1242', Name: 'Owner, rest home' },
  { Id: 1178, Code: '1242', Name: 'Owner, retirement home' },
  { Id: 1179, Code: '1251', Name: 'Developer, property' },
  { Id: 1180, Code: '1251', Name: 'Director, housing' },
  { Id: 1181, Code: '1251', Name: 'Director, land, building construction' },
  { Id: 1182, Code: '1251', Name: 'Factor, estate, SCOTLAND' },
  { Id: 1183, Code: '1251', Name: 'Factor, housing, SCOTLAND' },
  { Id: 1184, Code: '1251', Name: 'Head of facilities' },
  { Id: 1185, Code: '1251', Name: 'Head of facilities management' },
  { Id: 1186, Code: '1251', Name: 'Investor, property' },
  { Id: 1187, Code: '1251', Name: 'Landlady, property management' },
  { Id: 1188, Code: '1251', Name: 'Landlord, property' },
  { Id: 1189, Code: '1251', Name: 'Landlord, property management' },
  { Id: 1190, Code: '1251', Name: 'Landowner' },
  { Id: 1191, Code: '1251', Name: 'Manager, agent, estate' },
  { Id: 1192, Code: '1251', Name: 'Manager, auction' },
  { Id: 1193, Code: '1251', Name: 'Manager, auctioneers' },
  { Id: 1194, Code: '1251', Name: 'Manager, branch, property letting' },
  { Id: 1195, Code: '1251', Name: 'Manager, building, property management' },
  { Id: 1196, Code: '1251', Name: 'Manager, caretaking' },
  { Id: 1197, Code: '1251', Name: 'Manager, centre, city' },
  { Id: 1198, Code: '1251', Name: 'Manager, centre, shopping' },
  { Id: 1199, Code: '1251', Name: 'Manager, centre, town' },
  { Id: 1200, Code: '1251', Name: 'Manager, environment, railways' },
  { Id: 1201, Code: '1251', Name: 'Manager, estate' },
  { Id: 1202, Code: '1251', Name: 'Manager, estate agents' },
  { Id: 1203, Code: '1251', Name: 'Manager, estates' },
  { Id: 1204, Code: '1251', Name: 'Manager, facilities' },
  { Id: 1205, Code: '1251', Name: 'Manager, hall, town' },
  { Id: 1206, Code: '1251', Name: 'Manager, house, property management' },
  { Id: 1207, Code: '1251', Name: 'Manager, housing' },
  { Id: 1208, Code: '1251', Name: 'Manager, housing association' },
  { Id: 1209, Code: '1251', Name: 'Manager, land, building construction' },
  { Id: 1210, Code: '1251', Name: 'Manager, lettings' },
  { Id: 1211, Code: '1251', Name: 'Manager, market' },
  { Id: 1212, Code: '1251', Name: 'Manager, markets' },
  { Id: 1213, Code: '1251', Name: 'Manager, neighbourhood' },
  { Id: 1214, Code: '1251', Name: 'Manager, premises' },
  { Id: 1215, Code: '1251', Name: 'Manager, property' },
  { Id: 1216, Code: '1251', Name: 'Manager, property investment company' },
  { Id: 1217, Code: '1251', Name: 'Manager, property management' },
  { Id: 1218, Code: '1251', Name: 'Manager, relocation' },
  { Id: 1219, Code: '1251', Name: 'Manager, resident' },
  { Id: 1220, Code: '1251', Name: 'Manager, sales, estate agents' },
  { Id: 1221, Code: '1251', Name: 'Manager, service, property' },
  { Id: 1222, Code: '1251', Name: 'Manager, services, house' },
  { Id: 1223, Code: '1251', Name: 'Manager, site, property management' },
  { Id: 1224, Code: '1251', Name: 'Manager, support, facilities' },
  { Id: 1225, Code: '1251', Name: 'Manager, technical, property management' },
  { Id: 1226, Code: '1251', Name: 'Officer, estate' },
  { Id: 1227, Code: '1251', Name: 'Officer, estates' },
  { Id: 1228, Code: '1251', Name: 'Owner, agency, letting' },
  { Id: 1229, Code: '1251', Name: 'Owner, auction' },
  { Id: 1230, Code: '1251', Name: 'Owner, estate agents' },
  { Id: 1231, Code: '1251', Name: 'Owner, land' },
  { Id: 1232, Code: '1251', Name: 'Owner, letting agency' },
  { Id: 1233, Code: '1251', Name: 'Owner, property' },
  { Id: 1234, Code: '1251', Name: 'Owner, property management' },
  { Id: 1235, Code: '1252', Name: 'Dealer, principal' },
  { Id: 1236, Code: '1252', Name: 'Director, garage' },
  { Id: 1237, Code: '1252', Name: 'Manager, bodyshop, vehicle trades' },
  { Id: 1238, Code: '1252', Name: 'Manager, branch, garage' },
  { Id: 1239, Code: '1252', Name: 'Manager, depot, tyre' },
  { Id: 1240, Code: '1252', Name: 'Manager, engineering, public transport' },
  { Id: 1241, Code: '1252', Name: 'Manager, garage' },
  { Id: 1242, Code: '1252', Name: 'Manager, production, garage' },
  { Id: 1243, Code: '1252', Name: 'Manager, repairing: motor vehicles' },
  { Id: 1244, Code: '1252', Name: 'Manager, service, motorcycles' },
  { Id: 1245, Code: '1252', Name: 'Manager, shop, body, vehicle trades' },
  { Id: 1246, Code: '1252', Name: 'Manager, tyre dealers' },
  { Id: 1247, Code: '1252', Name: 'Manager, workshop, garage' },
  { Id: 1248, Code: '1252', Name: 'Owner, garage' },
  { Id: 1249, Code: '1252', Name: 'Owner, motor vehicles repairing' },
  { Id: 1250, Code: '1252', Name: 'Principal, dealer' },
  { Id: 1251, Code: '1253', Name: "Manager, barber's" },
  { Id: 1252, Code: '1253', Name: 'Manager, beautician' },
  { Id: 1253, Code: '1253', Name: 'Manager, beauty salon' },
  { Id: 1254, Code: '1253', Name: "Manager, hairdresser's" },
  { Id: 1255, Code: '1253', Name: 'Manager, hairdressing' },
  { Id: 1256, Code: '1253', Name: 'Manager, health and fitness' },
  { Id: 1257, Code: '1253', Name: 'Manager, shop, hairdressing' },
  { Id: 1258, Code: '1253', Name: 'Manager, spa' },
  { Id: 1259, Code: '1253', Name: 'Owner, beauty salon' },
  { Id: 1260, Code: '1253', Name: 'Owner, beauty, health studio' },
  { Id: 1261, Code: '1253', Name: 'Owner, club, health' },
  { Id: 1262, Code: '1253', Name: 'Owner, hairdressing salon, shop' },
  { Id: 1263, Code: '1253', Name: 'Owner, salon, hairdressing' },
  { Id: 1264, Code: '1253', Name: 'Owner, studio, beauty' },
  { Id: 1265, Code: '1253', Name: 'Owner, studio, health' },
  { Id: 1266, Code: '1253', Name: 'Owner, tanning studio' },
  { Id: 1267, Code: '1254', Name: 'Agent, news' },
  { Id: 1268, Code: '1254', Name: 'Agent, newspaper' },
  { Id: 1269, Code: '1254', Name: 'Bookseller, antiquarian' },
  { Id: 1270, Code: '1254', Name: 'Chandler' },
  { Id: 1271, Code: '1254', Name: 'Cheesemonger' },
  { Id: 1272, Code: '1254', Name: 'Clothier and outfitter' },
  { Id: 1273, Code: '1254', Name: 'Clothier, retail trade' },
  { Id: 1274, Code: '1254', Name: 'Confectioner and tobacconist' },
  { Id: 1275, Code: '1254', Name: 'Dealer, accessories, motor' },
  { Id: 1276, Code: '1254', Name: 'Dealer, antique' },
  { Id: 1277, Code: '1254', Name: 'Dealer, antiques' },
  { Id: 1278, Code: '1254', Name: 'Dealer, art' },
  { Id: 1279, Code: '1254', Name: 'Dealer, book' },
  { Id: 1280, Code: '1254', Name: 'Dealer, car' },
  { Id: 1281, Code: '1254', Name: 'Dealer, cattle' },
  { Id: 1282, Code: '1254', Name: 'Dealer, game' },
  { Id: 1283, Code: '1254', Name: 'Dealer, livestock' },
  { Id: 1284, Code: '1254', Name: 'Dealer, motor' },
  { Id: 1285, Code: '1254', Name: 'Dealer, pig' },
  { Id: 1286, Code: '1254', Name: 'Dealer, poultry' },
  { Id: 1287, Code: '1254', Name: 'Dealer, sheep' },
  { Id: 1288, Code: '1254', Name: 'Dealer, stamp' },
  { Id: 1289, Code: '1254', Name: 'Dealer, store, marine' },
  { Id: 1290, Code: '1254', Name: 'Dealer, tyre' },
  { Id: 1291, Code: '1254', Name: 'Dealer, wholesale, retail trade' },
  { Id: 1292, Code: '1254', Name: 'Distributor, wholesale, retail trade' },
  { Id: 1293, Code: '1254', Name: 'Draper' },
  { Id: 1294, Code: '1254', Name: 'Factor, coal' },
  { Id: 1295, Code: '1254', Name: 'Factor, motor' },
  { Id: 1296, Code: '1254', Name: 'Fruiterer' },
  { Id: 1297, Code: '1254', Name: 'Furnisher, retail trade' },
  { Id: 1298, Code: '1254', Name: 'Furrier, retail trade' },
  { Id: 1299, Code: '1254', Name: 'Greengrocer' },
  { Id: 1300, Code: '1254', Name: 'Grocer' },
  { Id: 1301, Code: '1254', Name: 'Haberdasher' },
  { Id: 1302, Code: '1254', Name: 'Hatter, retail trade' },
  { Id: 1303, Code: '1254', Name: 'Holder, stock, steel' },
  { Id: 1304, Code: '1254', Name: 'Hosier' },
  { Id: 1305, Code: '1254', Name: 'Hosier and haberdasher' },
  { Id: 1306, Code: '1254', Name: 'Ironmonger' },
  { Id: 1307, Code: '1254', Name: 'Jeweller, retail' },
  { Id: 1308, Code: '1254', Name: 'Jeweller, retail trade' },
  { Id: 1309, Code: '1254', Name: 'Keeper, stall, book' },
  { Id: 1310, Code: '1254', Name: 'Keeper, store, drug' },
  { Id: 1311, Code: '1254', Name: 'Keeper, store, general' },
  { Id: 1312, Code: '1254', Name: 'Licensee, off-licence' },
  { Id: 1313, Code: '1254', Name: 'Manager, retail trade: mobile shop' },
  { Id: 1314, Code: '1254', Name: 'Mercer' },
  { Id: 1315, Code: '1254', Name: 'Merchant, agricultural' },
  { Id: 1316, Code: '1254', Name: "Merchant, builders'" },
  { Id: 1317, Code: '1254', Name: 'Merchant, coal' },
  { Id: 1318, Code: '1254', Name: 'Merchant, fish' },
  { Id: 1319, Code: '1254', Name: 'Merchant, glass' },
  { Id: 1320, Code: '1254', Name: 'Merchant, potato' },
  { Id: 1321, Code: '1254', Name: 'Merchant, timber' },
  { Id: 1322, Code: '1254', Name: 'Merchant, wine' },
  { Id: 1323, Code: '1254', Name: 'Milliner, wholesale, retail trade' },
  { Id: 1324, Code: '1254', Name: 'Newsagent' },
  { Id: 1325, Code: '1254', Name: 'Numismatist' },
  { Id: 1326, Code: '1254', Name: 'Off-licensee' },
  { Id: 1327, Code: '1254', Name: 'Outfitter, retail trade' },
  { Id: 1328, Code: '1254', Name: 'Owner, butchers' },
  { Id: 1329, Code: '1254', Name: 'Owner, centre, garden' },
  { Id: 1330, Code: '1254', Name: 'Owner, delicatessen' },
  { Id: 1331, Code: '1254', Name: 'Owner, filling station' },
  { Id: 1332, Code: '1254', Name: 'Owner, fishmongers' },
  { Id: 1333, Code: '1254', Name: 'Owner, florists' },
  { Id: 1334, Code: '1254', Name: 'Owner, garden centre' },
  { Id: 1335, Code: '1254', Name: 'Owner, general store' },
  { Id: 1336, Code: '1254', Name: 'Owner, milk delivery round' },
  { Id: 1337, Code: '1254', Name: 'Owner, off-licence' },
  { Id: 1338, Code: '1254', Name: 'Owner, pet shop' },
  { Id: 1339, Code: '1254', Name: 'Owner, petrol station' },
  { Id: 1340, Code: '1254', Name: 'Owner, post office' },
  { Id: 1341, Code: '1254', Name: 'Owner, retail pharmacy' },
  { Id: 1342, Code: '1254', Name: 'Owner, retail trade' },
  { Id: 1343, Code: '1254', Name: "Owner, shop, butcher's" },
  { Id: 1344, Code: '1254', Name: 'Owner, shop, florist' },
  { Id: 1345, Code: '1254', Name: 'Owner, shop, retail' },
  { Id: 1346, Code: '1254', Name: 'Owner, station, filling' },
  { Id: 1347, Code: '1254', Name: 'Owner, station, petrol' },
  { Id: 1348, Code: '1254', Name: 'Owner, store, drug' },
  { Id: 1349, Code: '1254', Name: 'Owner, store, general' },
  { Id: 1350, Code: '1254', Name: 'Owner, street stall' },
  { Id: 1351, Code: '1254', Name: 'Owner, wholesale trade' },
  { Id: 1352, Code: '1254', Name: 'Philatelist' },
  { Id: 1353, Code: '1254', Name: 'Retailer' },
  { Id: 1354, Code: '1254', Name: 'Retailer, fashion' },
  { Id: 1355, Code: '1254', Name: 'Retailer, fish' },
  { Id: 1356, Code: '1254', Name: 'Seedsman, retail trade' },
  { Id: 1357, Code: '1254', Name: 'Seller, book, antiquarian' },
  { Id: 1358, Code: '1254', Name: 'Seller, eBay' },
  { Id: 1359, Code: '1254', Name: 'Seller, map' },
  { Id: 1360, Code: '1254', Name: 'Shopkeeper' },
  { Id: 1361, Code: '1254', Name: 'Shopkeeper, FISH' },
  { Id: 1362, Code: '1254', Name: 'Specialist, book, antiquarian' },
  { Id: 1363, Code: '1254', Name: 'Stationer' },
  { Id: 1364, Code: '1254', Name: 'Stockholder, steel' },
  { Id: 1365, Code: '1254', Name: 'Supplier, wholesale, retail trade' },
  { Id: 1366, Code: '1254', Name: 'Tailor, merchant' },
  { Id: 1367, Code: '1254', Name: 'Tobacconist' },
  { Id: 1368, Code: '1254', Name: 'Trader, eBay' },
  { Id: 1369, Code: '1254', Name: 'Trader, motor' },
  { Id: 1370, Code: '1254', Name: 'Trader, retail trade' },
  { Id: 1371, Code: '1254', Name: 'Vendor, horsemeat' },
  { Id: 1372, Code: '1254', Name: 'Victualler, licensed, OFF-LICENCE' },
  { Id: 1373, Code: '1254', Name: 'Wholesaler' },
  { Id: 1374, Code: '1255', Name: 'Broker, scrap' },
  { Id: 1375, Code: '1255', Name: 'Dealer, general' },
  { Id: 1376, Code: '1255', Name: 'Dealer, metal, scrap' },
  { Id: 1377, Code: '1255', Name: 'Dealer, paper, waste' },
  { Id: 1378, Code: '1255', Name: 'Dealer, rag' },
  { Id: 1379, Code: '1255', Name: 'Dealer, rag and bone' },
  { Id: 1380, Code: '1255', Name: 'Dealer, scrap' },
  { Id: 1381, Code: '1255', Name: 'Man, rag' },
  { Id: 1382, Code: '1255', Name: 'Man, rag and bone' },
  { Id: 1383, Code: '1255', Name: 'Manager, cleansing' },
  { Id: 1384, Code: '1255', Name: 'Manager, cleansing services' },
  { Id: 1385, Code: '1255', Name: 'Manager, environmental, refuse disposal' },
  { Id: 1386, Code: '1255', Name: 'Manager, incinerator' },
  { Id: 1387, Code: '1255', Name: 'Manager, landfill' },
  { Id: 1388, Code: '1255', Name: 'Manager, local government: cleansing dept' },
  { Id: 1389, Code: '1255', Name: 'Manager, merchant, scrap' },
  { Id: 1390, Code: '1255', Name: 'Manager, operations, waste disposal' },
  { Id: 1391, Code: '1255', Name: 'Manager, operations, water' },
  { Id: 1392, Code: '1255', Name: 'Manager, plant, recycling' },
  { Id: 1393, Code: '1255', Name: 'Manager, reclaim' },
  { Id: 1394, Code: '1255', Name: 'Manager, reclamation' },
  { Id: 1395, Code: '1255', Name: 'Manager, recycling' },
  { Id: 1396, Code: '1255', Name: 'Manager, recycling plant' },
  { Id: 1397, Code: '1255', Name: 'Manager, refuse disposal' },
  {
    Id: 1398,
    Code: '1255',
    Name: 'Manager, refuse, waste disposal, sanitation'
  },
  { Id: 1399, Code: '1255', Name: 'Manager, scrap merchants, breakers' },
  { Id: 1400, Code: '1255', Name: 'Manager, sewage works' },
  { Id: 1401, Code: '1255', Name: 'Manager, site, landfill' },
  { Id: 1402, Code: '1255', Name: 'Manager, technical, WASTE' },
  { Id: 1403, Code: '1255', Name: 'Manager, waste disposal' },
  { Id: 1404, Code: '1255', Name: 'Manager, works, sewage' },
  { Id: 1405, Code: '1255', Name: 'Manager, yard, scrap merchants, breakers' },
  { Id: 1406, Code: '1255', Name: 'Merchant, metal, scrap' },
  { Id: 1407, Code: '1255', Name: 'Merchant, paper, waste' },
  { Id: 1408, Code: '1255', Name: 'Merchant, rag and bone' },
  { Id: 1409, Code: '1255', Name: 'Merchant, salvage' },
  { Id: 1410, Code: '1255', Name: 'Merchant, scrap' },
  { Id: 1411, Code: '1255', Name: 'Owner, plant, recycling' },
  { Id: 1412, Code: '1255', Name: 'Owner, recycling plant' },
  { Id: 1413, Code: '1255', Name: 'Owner, scrap merchants, breakers' },
  { Id: 1414, Code: '1255', Name: 'Owner, yard, scrap' },
  { Id: 1415, Code: '1255', Name: 'Totter' },
  { Id: 1416, Code: '1259', Name: 'Director, architectural service' },
  { Id: 1417, Code: '1259', Name: "Director, children's nursery" },
  { Id: 1418, Code: '1259', Name: 'Director, commission agents' },
  { Id: 1419, Code: '1259', Name: 'Director, educational establishments' },
  { Id: 1420, Code: '1259', Name: 'Director, employment agency: private' },
  { Id: 1421, Code: '1259', Name: 'Director, environmental consultancy' },
  { Id: 1422, Code: '1259', Name: 'Director, health and safety' },
  { Id: 1423, Code: '1259', Name: 'Director, management consultancy' },
  { Id: 1424, Code: '1259', Name: 'Director, private detective agency' },
  { Id: 1425, Code: '1259', Name: 'Director, publishing' },
  { Id: 1426, Code: '1259', Name: 'Director, security services' },
  { Id: 1427, Code: '1259', Name: 'Director, technical, design consultancy' },
  { Id: 1428, Code: '1259', Name: 'Director, technical, ENTERTAINMENT' },
  { Id: 1429, Code: '1259', Name: 'Keeper, laundry' },
  { Id: 1430, Code: '1259', Name: 'Manager and Registrar, CEMETERY' },
  { Id: 1431, Code: '1259', Name: 'Manager and Registrar, CREMATORIUM' },
  { Id: 1432, Code: '1259', Name: 'Manager, architect' },
  { Id: 1433, Code: '1259', Name: 'Manager, architectural service' },
  { Id: 1434, Code: '1259', Name: 'Manager, architecture' },
  { Id: 1435, Code: '1259', Name: 'Manager, betting and gambling' },
  { Id: 1436, Code: '1259', Name: "Manager, bookmaker's" },
  { Id: 1437, Code: '1259', Name: 'Manager, bookmakers, turf accountants' },
  { Id: 1438, Code: '1259', Name: 'Manager, branch, car hire' },
  { Id: 1439, Code: '1259', Name: 'Manager, branch, library' },
  {
    Id: 1440,
    Code: '1259',
    Name: 'Manager, branch, RADIO, TELEVISION AND VIDEO HIRE'
  },
  { Id: 1441, Code: '1259', Name: 'Manager, car hire' },
  { Id: 1442, Code: '1259', Name: 'Manager, car park' },
  { Id: 1443, Code: '1259', Name: 'Manager, car wash, valeting' },
  { Id: 1444, Code: '1259', Name: 'Manager, cemeteries' },
  { Id: 1445, Code: '1259', Name: 'Manager, cemetery' },
  { Id: 1446, Code: '1259', Name: 'Manager, centre, assessment' },
  { Id: 1447, Code: '1259', Name: 'Manager, centre, hire' },
  { Id: 1448, Code: '1259', Name: 'Manager, centre, skills' },
  { Id: 1449, Code: '1259', Name: 'Manager, centre, training' },
  { Id: 1450, Code: '1259', Name: 'Manager, Citizens Advice Bureau' },
  { Id: 1451, Code: '1259', Name: 'Manager, club, out of school' },
  { Id: 1452, Code: '1259', Name: 'Manager, club, school, after' },
  { Id: 1453, Code: '1259', Name: 'Manager, crematorium' },
  { Id: 1454, Code: '1259', Name: 'Manager, dating agency' },
  { Id: 1455, Code: '1259', Name: 'Manager, design, graphic' },
  { Id: 1456, Code: '1259', Name: 'Manager, driving school' },
  { Id: 1457, Code: '1259', Name: 'Manager, DVS' },
  { Id: 1458, Code: '1259', Name: 'Manager, experience, customer' },
  { Id: 1459, Code: '1259', Name: 'Manager, flying school' },
  { Id: 1460, Code: '1259', Name: 'Manager, funeral directors' },
  { Id: 1461, Code: '1259', Name: 'Manager, furnishing' },
  { Id: 1462, Code: '1259', Name: 'Manager, general, theatrical productions' },
  { Id: 1463, Code: '1259', Name: 'Manager, health and safety' },
  { Id: 1464, Code: '1259', Name: 'Manager, hire shop' },
  { Id: 1465, Code: '1259', Name: 'Manager, hire, equipment' },
  { Id: 1466, Code: '1259', Name: 'Manager, hire, plant' },
  { Id: 1467, Code: '1259', Name: 'Manager, hire, skip' },
  {
    Id: 1468,
    Code: '1259',
    Name: 'Manager, hire, television and video, radio'
  },
  { Id: 1469, Code: '1259', Name: 'Manager, hire, tool' },
  { Id: 1470, Code: '1259', Name: 'Manager, hygiene' },
  { Id: 1471, Code: '1259', Name: 'Manager, launderette' },
  { Id: 1472, Code: '1259', Name: 'Manager, laundry' },
  {
    Id: 1473,
    Code: '1259',
    Name: 'Manager, laundry, launderette, dry cleaning'
  },
  { Id: 1474, Code: '1259', Name: 'Manager, library' },
  { Id: 1475, Code: '1259', Name: 'Manager, machinery hire' },
  { Id: 1476, Code: '1259', Name: 'Manager, management consultancy' },
  { Id: 1477, Code: '1259', Name: 'Manager, office, betting' },
  {
    Id: 1478,
    Code: '1259',
    Name: 'Manager, office, bookmakers, turf accountants'
  },
  { Id: 1479, Code: '1259', Name: 'Manager, operations, cleaning services' },
  { Id: 1480, Code: '1259', Name: 'Manager, operations, plant hire' },
  { Id: 1481, Code: '1259', Name: 'Manager, operations, recruitment agency' },
  { Id: 1482, Code: '1259', Name: 'Manager, park, car' },
  { Id: 1483, Code: '1259', Name: 'Manager, parking' },
  { Id: 1484, Code: '1259', Name: 'Manager, photographic studios' },
  { Id: 1485, Code: '1259', Name: 'Manager, plant hire' },
  { Id: 1486, Code: '1259', Name: 'Manager, plant, hire service' },
  { Id: 1487, Code: '1259', Name: 'Manager, production, broadcasting' },
  { Id: 1488, Code: '1259', Name: 'Manager, production, ENTERTAINMENT' },
  {
    Id: 1489,
    Code: '1259',
    Name: 'Manager, production, film, television production'
  },
  { Id: 1490, Code: '1259', Name: 'Manager, production, publishing' },
  { Id: 1491, Code: '1259', Name: 'Manager, production, theatrical' },
  { Id: 1492, Code: '1259', Name: 'Manager, production, video' },
  { Id: 1493, Code: '1259', Name: "Manager, publisher's" },
  { Id: 1494, Code: '1259', Name: 'Manager, radio, television and video' },
  { Id: 1495, Code: '1259', Name: 'Manager, RADIO, TELEVISION AND VIDEO HIRE' },
  { Id: 1496, Code: '1259', Name: 'Manager, recruitment agency' },
  { Id: 1497, Code: '1259', Name: 'Manager, rental, vehicle' },
  { Id: 1498, Code: '1259', Name: 'Manager, repairing: consumer goods' },
  { Id: 1499, Code: '1259', Name: 'Manager, retail trade: video shop' },
  { Id: 1500, Code: '1259', Name: 'Manager, retail, betting shop' },
  {
    Id: 1501,
    Code: '1259',
    Name: 'Manager, retail, laundry, launderette, dry cleaning'
  },
  { Id: 1502, Code: '1259', Name: 'Manager, rig' },
  { Id: 1503, Code: '1259', Name: 'Manager, safe deposit' },
  { Id: 1504, Code: '1259', Name: 'Manager, safety' },
  { Id: 1505, Code: '1259', Name: 'Manager, safety and hygiene' },
  { Id: 1506, Code: '1259', Name: 'Manager, safety, community' },
  { Id: 1507, Code: '1259', Name: 'Manager, service, advice' },
  { Id: 1508, Code: '1259', Name: 'Manager, service, Citizens Advice Bureau' },
  { Id: 1509, Code: '1259', Name: 'Manager, service, cleaning services' },
  { Id: 1510, Code: '1259', Name: 'Manager, service, crane' },
  { Id: 1511, Code: '1259', Name: 'Manager, service, library' },
  { Id: 1512, Code: '1259', Name: 'Manager, service, motoring organisation' },
  { Id: 1513, Code: '1259', Name: 'Manager, service, rental' },
  { Id: 1514, Code: '1259', Name: 'Manager, service, rental services' },
  { Id: 1515, Code: '1259', Name: 'Manager, service, valet, car' },
  { Id: 1516, Code: '1259', Name: 'Manager, services, parking' },
  { Id: 1517, Code: '1259', Name: 'Manager, services, student' },
  { Id: 1518, Code: '1259', Name: 'Manager, services, violence, domestic' },
  { Id: 1519, Code: '1259', Name: 'Manager, shift, recruitment agency' },
  { Id: 1520, Code: '1259', Name: 'Manager, shop, betting' },
  {
    Id: 1521,
    Code: '1259',
    Name: 'Manager, shop, bookmakers, turf accountants'
  },
  { Id: 1522, Code: '1259', Name: 'Manager, shop, dyeing and cleaning' },
  { Id: 1523, Code: '1259', Name: 'Manager, shop, hire' },
  { Id: 1524, Code: '1259', Name: 'Manager, shop, laundry receiving shop' },
  {
    Id: 1525,
    Code: '1259',
    Name: 'Manager, shop, radio, television, video hire'
  },
  { Id: 1526, Code: '1259', Name: 'Manager, shop, video' },
  { Id: 1527, Code: '1259', Name: 'Manager, site, cleaning services' },
  { Id: 1528, Code: '1259', Name: 'Manager, store, loan company' },
  { Id: 1529, Code: '1259', Name: 'Manager, studio, design' },
  { Id: 1530, Code: '1259', Name: 'Manager, studio, printing' },
  { Id: 1531, Code: '1259', Name: 'Manager, television: transmission station' },
  { Id: 1532, Code: '1259', Name: 'Manager, theatrical agency' },
  { Id: 1533, Code: '1259', Name: 'Manager, ticket agency' },
  { Id: 1534, Code: '1259', Name: 'Manager, tour, ENTERTAINMENT' },
  { Id: 1535, Code: '1259', Name: 'Manager, touring, ENTERTAINMENT' },
  { Id: 1536, Code: '1259', Name: 'Manager, tourism' },
  { Id: 1537, Code: '1259', Name: 'Manager, typewriting agency' },
  { Id: 1538, Code: '1259', Name: 'Manager, video shop' },
  { Id: 1539, Code: '1259', Name: 'Owner, agency, employment' },
  { Id: 1540, Code: '1259', Name: 'Owner, agency, ticket' },
  { Id: 1541, Code: '1259', Name: 'Owner, alarm, security installation' },
  { Id: 1542, Code: '1259', Name: 'Owner, art gallery' },
  { Id: 1543, Code: '1259', Name: 'Owner, boat: pleasure cruise' },
  { Id: 1544, Code: '1259', Name: 'Owner, boat: pleasure hire' },
  { Id: 1545, Code: '1259', Name: 'Owner, cab hire service' },
  { Id: 1546, Code: '1259', Name: 'Owner, car hire service' },
  { Id: 1547, Code: '1259', Name: 'Owner, caravan hire service' },
  { Id: 1548, Code: '1259', Name: 'Owner, chiropody practice' },
  { Id: 1549, Code: '1259', Name: 'Owner, cleaning services' },
  { Id: 1550, Code: '1259', Name: 'Owner, company, taxi' },
  { Id: 1551, Code: '1259', Name: 'Owner, contract cleaning services' },
  { Id: 1552, Code: '1259', Name: 'Owner, dating agency' },
  { Id: 1553, Code: '1259', Name: 'Owner, design consultancy' },
  { Id: 1554, Code: '1259', Name: 'Owner, domestic appliances repairing' },
  { Id: 1555, Code: '1259', Name: 'Owner, dry cleaning service' },
  { Id: 1556, Code: '1259', Name: 'Owner, employment agency' },
  { Id: 1557, Code: '1259', Name: 'Owner, fairground stall' },
  { Id: 1558, Code: '1259', Name: 'Owner, funeral directors' },
  { Id: 1559, Code: '1259', Name: 'Owner, gallery, art' },
  { Id: 1560, Code: '1259', Name: 'Owner, garden machinery repairing' },
  { Id: 1561, Code: '1259', Name: 'Owner, hire, plant' },
  { Id: 1562, Code: '1259', Name: 'Owner, home care service' },
  { Id: 1563, Code: '1259', Name: 'Owner, launderette' },
  { Id: 1564, Code: '1259', Name: 'Owner, laundry' },
  { Id: 1565, Code: '1259', Name: 'Owner, management consultancy' },
  { Id: 1566, Code: '1259', Name: 'Owner, marquee hire service' },
  { Id: 1567, Code: '1259', Name: 'Owner, newspaper' },
  { Id: 1568, Code: '1259', Name: 'Owner, pet crematorium' },
  { Id: 1569, Code: '1259', Name: 'Owner, photographic agency' },
  { Id: 1570, Code: '1259', Name: 'Owner, plant machinery repairing' },
  { Id: 1571, Code: '1259', Name: 'Owner, printers' },
  { Id: 1572, Code: '1259', Name: 'Owner, radio, television, video servicing' },
  { Id: 1573, Code: '1259', Name: 'Owner, recording studio' },
  { Id: 1574, Code: '1259', Name: 'Owner, recruitment agency' },
  { Id: 1575, Code: '1259', Name: 'Owner, school, driving' },
  { Id: 1576, Code: '1259', Name: 'Owner, school: driving' },
  { Id: 1577, Code: '1259', Name: 'Owner, school: flying' },
  { Id: 1578, Code: '1259', Name: 'Owner, service, cab' },
  { Id: 1579, Code: '1259', Name: 'Owner, service, taxi' },
  { Id: 1580, Code: '1259', Name: 'Owner, shoe repairing' },
  { Id: 1581, Code: '1259', Name: 'Owner, shop, betting' },
  { Id: 1582, Code: '1259', Name: 'Owner, shop, video' },
  { Id: 1583, Code: '1259', Name: 'Owner, soft furnishings mfr' },
  { Id: 1584, Code: '1259', Name: 'Owner, studio, photographic' },
  { Id: 1585, Code: '1259', Name: 'Owner, taxi service' },
  { Id: 1586, Code: '1259', Name: 'Owner, theatrical agency' },
  { Id: 1587, Code: '1259', Name: 'Owner, ticket agents' },
  { Id: 1588, Code: '1259', Name: 'Owner, vehicle hire service' },
  { Id: 1589, Code: '1259', Name: 'Owner, video shop' },
  { Id: 1590, Code: '1259', Name: 'Owner-publisher' },
  { Id: 1591, Code: '1259', Name: 'Publisher' },
  { Id: 1592, Code: '1259', Name: 'Retailer, video shop' },
  {
    Id: 1593,
    Code: '1259',
    Name: 'Shopkeeper, laundry, launderette, dry cleaning'
  },
  { Id: 1594, Code: '1259', Name: 'Shopkeeper, video shop' },
  { Id: 1595, Code: '1259', Name: 'Superintendent, market' },
  { Id: 1596, Code: '2111', Name: 'Analyst, chemical' },
  { Id: 1597, Code: '2111', Name: 'Analyst, chief' },
  { Id: 1598, Code: '2111', Name: 'Analyst, county' },
  { Id: 1599, Code: '2111', Name: 'Analyst, public' },
  { Id: 1600, Code: '2111', Name: 'Assistant, research, CHEMICAL' },
  { Id: 1601, Code: '2111', Name: 'Associate, research, CHEMICAL' },
  { Id: 1602, Code: '2111', Name: 'Chemist' },
  { Id: 1603, Code: '2111', Name: 'Chemist, analytical' },
  { Id: 1604, Code: '2111', Name: 'Chemist, chief' },
  { Id: 1605, Code: '2111', Name: 'Chemist, consulting' },
  { Id: 1606, Code: '2111', Name: 'Chemist, development' },
  { Id: 1607, Code: '2111', Name: 'Chemist, electroplating' },
  { Id: 1608, Code: '2111', Name: 'Chemist, government' },
  { Id: 1609, Code: '2111', Name: 'Chemist, industrial' },
  { Id: 1610, Code: '2111', Name: 'Chemist, inorganic' },
  { Id: 1611, Code: '2111', Name: 'Chemist, laboratory' },
  { Id: 1612, Code: '2111', Name: 'Chemist, managing' },
  { Id: 1613, Code: '2111', Name: 'Chemist, manufacturing' },
  { Id: 1614, Code: '2111', Name: 'Chemist, metallurgical' },
  { Id: 1615, Code: '2111', Name: 'Chemist, nuclear' },
  { Id: 1616, Code: '2111', Name: 'Chemist, organic' },
  { Id: 1617, Code: '2111', Name: 'Chemist, photographic' },
  { Id: 1618, Code: '2111', Name: 'Chemist, physical' },
  { Id: 1619, Code: '2111', Name: 'Chemist, polymer' },
  { Id: 1620, Code: '2111', Name: 'Chemist, research' },
  { Id: 1621, Code: '2111', Name: 'Chemist, research and development' },
  { Id: 1622, Code: '2111', Name: 'Chemist, shift' },
  { Id: 1623, Code: '2111', Name: 'Chemist, superintending' },
  { Id: 1624, Code: '2111', Name: 'Chemist, technical' },
  { Id: 1625, Code: '2111', Name: 'Chemist, textile' },
  { Id: 1626, Code: '2111', Name: 'Chemist, works' },
  { Id: 1627, Code: '2111', Name: 'Consultant, chemical' },
  { Id: 1628, Code: '2111', Name: 'Director of research, CHEMISTRY' },
  { Id: 1629, Code: '2111', Name: 'Doctor of chemistry' },
  { Id: 1630, Code: '2111', Name: 'Electro-chemist' },
  { Id: 1631, Code: '2111', Name: 'Flavourist' },
  { Id: 1632, Code: '2111', Name: 'Head, section, chemical mfr' },
  { Id: 1633, Code: '2111', Name: 'Manager, research, CHEMICAL' },
  { Id: 1634, Code: '2111', Name: 'Officer, experimental, chief, CHEMIST' },
  { Id: 1635, Code: '2111', Name: 'Officer, experimental, senior, CHEMIST' },
  { Id: 1636, Code: '2111', Name: 'Officer, research, CHEMICAL' },
  { Id: 1637, Code: '2111', Name: 'Officer, sampling, milk' },
  { Id: 1638, Code: '2111', Name: 'Officer, scientific, CHEMICAL' },
  { Id: 1639, Code: '2111', Name: 'Officer, technical, chemical mfr' },
  { Id: 1640, Code: '2111', Name: 'Researcher, CHEMICAL' },
  { Id: 1641, Code: '2111', Name: 'Scientist, CHEMICAL' },
  { Id: 1642, Code: '2111', Name: 'Scientist, research, CHEMICAL' },
  { Id: 1643, Code: '2111', Name: 'Worker, research, CHEMICAL' },
  { Id: 1644, Code: '2112', Name: 'Adviser, agricultural' },
  { Id: 1645, Code: '2112', Name: 'Adviser, horticultural' },
  { Id: 1646, Code: '2112', Name: 'Adviser, poultry' },
  { Id: 1647, Code: '2112', Name: 'Agroclimatologist' },
  { Id: 1648, Code: '2112', Name: 'Agronomist' },
  { Id: 1649, Code: '2112', Name: 'Agrostologist' },
  { Id: 1650, Code: '2112', Name: 'Anatomist' },
  { Id: 1651, Code: '2112', Name: 'Assistant, research, AGRICULTURAL' },
  { Id: 1652, Code: '2112', Name: 'Assistant, research, BIOCHEMICAL' },
  { Id: 1653, Code: '2112', Name: 'Assistant, research, BIOLOGICAL' },
  { Id: 1654, Code: '2112', Name: 'Assistant, research, BOTANICAL' },
  { Id: 1655, Code: '2112', Name: 'Assistant, research, HORTICULTURAL' },
  { Id: 1656, Code: '2112', Name: 'Assistant, research, ZOOLOGICAL' },
  { Id: 1657, Code: '2112', Name: 'Associate, research, AGRICULTURAL' },
  { Id: 1658, Code: '2112', Name: 'Associate, research, BIOCHEMICAL' },
  { Id: 1659, Code: '2112', Name: 'Associate, research, BIOLOGICAL' },
  { Id: 1660, Code: '2112', Name: 'Associate, research, BOTANICAL' },
  { Id: 1661, Code: '2112', Name: 'Associate, research, clinical' },
  { Id: 1662, Code: '2112', Name: 'Associate, research, HORTICULTURAL' },
  { Id: 1663, Code: '2112', Name: 'Associate, research, MEDICAL' },
  { Id: 1664, Code: '2112', Name: 'Associate, research, ZOOLOGICAL' },
  { Id: 1665, Code: '2112', Name: 'Bacteriologist' },
  { Id: 1666, Code: '2112', Name: 'Biochemist' },
  { Id: 1667, Code: '2112', Name: 'Biologist' },
  { Id: 1668, Code: '2112', Name: 'Biotechnologist' },
  { Id: 1669, Code: '2112', Name: 'Botanist' },
  { Id: 1670, Code: '2112', Name: 'Breeder, plant' },
  { Id: 1671, Code: '2112', Name: 'Chemist, agricultural' },
  { Id: 1672, Code: '2112', Name: 'Chemist, biological' },
  { Id: 1673, Code: '2112', Name: 'Chemist, forensic' },
  { Id: 1674, Code: '2112', Name: 'Chemist, soil' },
  { Id: 1675, Code: '2112', Name: 'Conchologist' },
  { Id: 1676, Code: '2112', Name: 'Consultant, agricultural' },
  { Id: 1677, Code: '2112', Name: 'Consultant, arboricultural, PROFESSIONAL' },
  { Id: 1678, Code: '2112', Name: 'Consultant, dairy' },
  { Id: 1679, Code: '2112', Name: 'Consultant, farming' },
  { Id: 1680, Code: '2112', Name: 'Consultant, fisheries' },
  { Id: 1681, Code: '2112', Name: 'Consultant, forestry' },
  { Id: 1682, Code: '2112', Name: 'Consultant, horticultural' },
  { Id: 1683, Code: '2112', Name: 'Cytogeneticist' },
  { Id: 1684, Code: '2112', Name: 'Cytologist' },
  { Id: 1685, Code: '2112', Name: 'Cytotaxonomist' },
  { Id: 1686, Code: '2112', Name: 'Director of research, BIOLOGICAL SCIENCE' },
  { Id: 1687, Code: '2112', Name: 'Embryologist' },
  { Id: 1688, Code: '2112', Name: 'Endocrinologist' },
  { Id: 1689, Code: '2112', Name: 'Engineer, genetic' },
  { Id: 1690, Code: '2112', Name: 'Engineer, PROFESSIONAL, GENETICS' },
  { Id: 1691, Code: '2112', Name: 'Entomologist' },
  { Id: 1692, Code: '2112', Name: 'Examiner, forensic' },
  { Id: 1693, Code: '2112', Name: 'Fieldman, PROFESSIONALLY QUALIFIED' },
  { Id: 1694, Code: '2112', Name: 'Fieldsman, PROFESSIONALLY QUALIFIED' },
  { Id: 1695, Code: '2112', Name: 'Geneticist' },
  { Id: 1696, Code: '2112', Name: 'Helminthologist' },
  { Id: 1697, Code: '2112', Name: 'Herpetologist' },
  { Id: 1698, Code: '2112', Name: 'Histologist' },
  { Id: 1699, Code: '2112', Name: 'Histopathologist' },
  { Id: 1700, Code: '2112', Name: 'Horticulturalist, PROFESSIONAL' },
  { Id: 1701, Code: '2112', Name: 'Horticulturist, PROFESSIONAL' },
  { Id: 1702, Code: '2112', Name: 'Hydrobiologist' },
  { Id: 1703, Code: '2112', Name: 'Ichthyologist' },
  { Id: 1704, Code: '2112', Name: 'Immunologist' },
  { Id: 1705, Code: '2112', Name: 'Kinesiologist' },
  { Id: 1706, Code: '2112', Name: 'Lepidopterist' },
  { Id: 1707, Code: '2112', Name: 'Limnologist' },
  { Id: 1708, Code: '2112', Name: 'Manager, arboricultural' },
  { Id: 1709, Code: '2112', Name: 'Manager, research, AGRICULTURAL' },
  { Id: 1710, Code: '2112', Name: 'Manager, research, BIOCHEMICAL' },
  { Id: 1711, Code: '2112', Name: 'Manager, research, BIOLOGICAL' },
  { Id: 1712, Code: '2112', Name: 'Manager, research, BOTANICAL' },
  { Id: 1713, Code: '2112', Name: 'Manager, research, HORTICULTURAL' },
  { Id: 1714, Code: '2112', Name: 'Manager, research, MEDICAL' },
  { Id: 1715, Code: '2112', Name: 'Manager, research, ZOOLOGICAL' },
  { Id: 1716, Code: '2112', Name: 'Microbiologist' },
  { Id: 1717, Code: '2112', Name: 'MLSO' },
  { Id: 1718, Code: '2112', Name: 'Monitor, trials, clinical' },
  { Id: 1719, Code: '2112', Name: 'Mycologist' },
  { Id: 1720, Code: '2112', Name: 'Naturalist' },
  { Id: 1721, Code: '2112', Name: 'Nematologist' },
  { Id: 1722, Code: '2112', Name: 'Neuroscientist, computational' },
  { Id: 1723, Code: '2112', Name: 'Nutritionist, agricultural' },
  { Id: 1724, Code: '2112', Name: 'Nutritionist, animal' },
  { Id: 1725, Code: '2112', Name: 'Nutritionist, research' },
  { Id: 1726, Code: '2112', Name: 'Nutritionist, ruminant' },
  { Id: 1727, Code: '2112', Name: 'Officer, advisory, DEFRA' },
  { Id: 1728, Code: '2112', Name: 'Officer, advisory, district' },
  { Id: 1729, Code: '2112', Name: 'Officer, advisory, POULTRY' },
  { Id: 1730, Code: '2112', Name: 'Officer, experimental, chief, BIOLOGIST' },
  { Id: 1731, Code: '2112', Name: 'Officer, experimental, senior, BIOLOGIST' },
  { Id: 1732, Code: '2112', Name: 'Officer, fatstock, DEFRA' },
  { Id: 1733, Code: '2112', Name: 'Officer, field, ADVISORY' },
  { Id: 1734, Code: '2112', Name: 'Officer, field, PROFESSIONAL' },
  { Id: 1735, Code: '2112', Name: 'Officer, horticultural' },
  { Id: 1736, Code: '2112', Name: 'Officer, production, milk' },
  { Id: 1737, Code: '2112', Name: 'Officer, research, AGRICULTURAL' },
  { Id: 1738, Code: '2112', Name: 'Officer, research, BIOCHEMICAL' },
  { Id: 1739, Code: '2112', Name: 'Officer, research, BIOLOGICAL' },
  { Id: 1740, Code: '2112', Name: 'Officer, research, BOTANICAL' },
  { Id: 1741, Code: '2112', Name: 'Officer, research, HORTICULTURAL' },
  { Id: 1742, Code: '2112', Name: 'Officer, research, ZOOLOGICAL' },
  { Id: 1743, Code: '2112', Name: 'Officer, science, laboratory, medical' },
  { Id: 1744, Code: '2112', Name: 'Officer, scientific, AGRICULTURAL' },
  { Id: 1745, Code: '2112', Name: 'Officer, scientific, BIOCHEMICAL' },
  { Id: 1746, Code: '2112', Name: 'Officer, scientific, BIOLOGICAL' },
  { Id: 1747, Code: '2112', Name: 'Officer, scientific, BOTANICAL' },
  { Id: 1748, Code: '2112', Name: 'Officer, scientific, HORTICULTURAL' },
  { Id: 1749, Code: '2112', Name: 'Officer, scientific, laboratory, medical' },
  { Id: 1750, Code: '2112', Name: 'Officer, scientific, ZOOLOGICAL' },
  { Id: 1751, Code: '2112', Name: 'Officer, stock, live' },
  { Id: 1752, Code: '2112', Name: 'Officer, technical, government: DEFRA' },
  { Id: 1753, Code: '2112', Name: 'Officer, trials, field' },
  { Id: 1754, Code: '2112', Name: 'Ornithologist' },
  { Id: 1755, Code: '2112', Name: 'Osteologist' },
  { Id: 1756, Code: '2112', Name: 'Parasitologist' },
  { Id: 1757, Code: '2112', Name: 'Pathologist' },
  { Id: 1758, Code: '2112', Name: 'Pathologist, plant' },
  { Id: 1759, Code: '2112', Name: 'Pathologist, veterinary' },
  { Id: 1760, Code: '2112', Name: 'Pharmacologist' },
  { Id: 1761, Code: '2112', Name: 'Physiologist' },
  { Id: 1762, Code: '2112', Name: 'Researcher, AGRICULTURAL' },
  { Id: 1763, Code: '2112', Name: 'Researcher, BIOCHEMICAL' },
  { Id: 1764, Code: '2112', Name: 'Researcher, BIOLOGICAL' },
  { Id: 1765, Code: '2112', Name: 'Researcher, BOTANICAL' },
  { Id: 1766, Code: '2112', Name: 'Researcher, HORTICULTURAL' },
  { Id: 1767, Code: '2112', Name: 'Researcher, ZOOLOGICAL' },
  { Id: 1768, Code: '2112', Name: 'Scientist, agricultural' },
  { Id: 1769, Code: '2112', Name: 'Scientist, animal' },
  { Id: 1770, Code: '2112', Name: 'Scientist, aquatic' },
  { Id: 1771, Code: '2112', Name: 'Scientist, audiological' },
  { Id: 1772, Code: '2112', Name: 'Scientist, BIOCHEMICAL' },
  { Id: 1773, Code: '2112', Name: 'Scientist, BIOLOGICAL' },
  { Id: 1774, Code: '2112', Name: 'Scientist, biomedical' },
  { Id: 1775, Code: '2112', Name: 'Scientist, BOTANICAL' },
  { Id: 1776, Code: '2112', Name: 'Scientist, clinical' },
  { Id: 1777, Code: '2112', Name: 'Scientist, forensic' },
  { Id: 1778, Code: '2112', Name: 'Scientist, horticultural' },
  { Id: 1779, Code: '2112', Name: 'Scientist, laboratory' },
  { Id: 1780, Code: '2112', Name: 'Scientist, laboratory, medical' },
  { Id: 1781, Code: '2112', Name: 'Scientist, marine' },
  { Id: 1782, Code: '2112', Name: 'Scientist, MEDICAL' },
  { Id: 1783, Code: '2112', Name: 'Scientist, research, AGRICULTURAL' },
  { Id: 1784, Code: '2112', Name: 'Scientist, research, BIOCHEMICAL' },
  { Id: 1785, Code: '2112', Name: 'Scientist, research, BIOLOGICAL' },
  { Id: 1786, Code: '2112', Name: 'Scientist, research, BOTANICAL' },
  { Id: 1787, Code: '2112', Name: 'Scientist, research, fisheries' },
  { Id: 1788, Code: '2112', Name: 'Scientist, research, HORTICULTURAL' },
  { Id: 1789, Code: '2112', Name: 'Scientist, research, MEDICAL' },
  { Id: 1790, Code: '2112', Name: 'Scientist, research, ZOOLOGICAL' },
  { Id: 1791, Code: '2112', Name: 'Scientist, ZOOLOGICAL' },
  { Id: 1792, Code: '2112', Name: 'Serologist, veterinary' },
  { Id: 1793, Code: '2112', Name: 'Silviculturist, PROFESSIONALLY QUALIFIED' },
  { Id: 1794, Code: '2112', Name: 'Systematist' },
  { Id: 1795, Code: '2112', Name: 'Taxonomist' },
  { Id: 1796, Code: '2112', Name: 'Technologist, biomedical' },
  { Id: 1797, Code: '2112', Name: 'Technologist, genetic' },
  { Id: 1798, Code: '2112', Name: 'Toxicologist' },
  { Id: 1799, Code: '2112', Name: 'Virologist' },
  { Id: 1800, Code: '2112', Name: 'Worker, research, AGRICULTURAL' },
  { Id: 1801, Code: '2112', Name: 'Worker, research, BIOCHEMICAL' },
  { Id: 1802, Code: '2112', Name: 'Worker, research, BIOLOGICAL' },
  { Id: 1803, Code: '2112', Name: 'Worker, research, BOTANICAL' },
  { Id: 1804, Code: '2112', Name: 'Worker, research, HORTICULTURAL' },
  { Id: 1805, Code: '2112', Name: 'Worker, research, ZOOLOGICAL' },
  { Id: 1806, Code: '2112', Name: 'Zoologist' },
  { Id: 1807, Code: '2113', Name: 'Adviser, geological' },
  { Id: 1808, Code: '2113', Name: 'Adviser, protection, radiation' },
  { Id: 1809, Code: '2113', Name: 'Aerodynamicist' },
  { Id: 1810, Code: '2113', Name: 'Assistant, meteorological' },
  { Id: 1811, Code: '2113', Name: 'Assistant, research, GEOLOGICAL' },
  { Id: 1812, Code: '2113', Name: 'Assistant, research, METEOROLOGICAL' },
  { Id: 1813, Code: '2113', Name: 'Assistant, research, PHYSICAL SCIENCE' },
  { Id: 1814, Code: '2113', Name: 'Associate, research, GEOLOGICAL' },
  { Id: 1815, Code: '2113', Name: 'Associate, research, METEOROLOGICAL' },
  { Id: 1816, Code: '2113', Name: 'Associate, research, MINING' },
  { Id: 1817, Code: '2113', Name: 'Associate, research, PHYSICAL SCIENCE' },
  { Id: 1818, Code: '2113', Name: 'Astronomer' },
  { Id: 1819, Code: '2113', Name: 'Astrophysicist' },
  { Id: 1820, Code: '2113', Name: 'Ballistician' },
  { Id: 1821, Code: '2113', Name: 'Biophysicist' },
  { Id: 1822, Code: '2113', Name: 'Consultant, geological' },
  { Id: 1823, Code: '2113', Name: 'Consultant, geophysical' },
  { Id: 1824, Code: '2113', Name: 'Crystallographer' },
  { Id: 1825, Code: '2113', Name: 'Director of research, PHYSICAL SCIENCE' },
  { Id: 1826, Code: '2113', Name: 'Engineer, geophysical' },
  { Id: 1827, Code: '2113', Name: 'Engineer, PROFESSIONAL, GEOPHYSICS' },
  {
    Id: 1828,
    Code: '2113',
    Name: 'Examiner, gas, Dept for Business Innovation and Skills'
  },
  { Id: 1829, Code: '2113', Name: 'Forecaster, METEOROLOGICAL' },
  { Id: 1830, Code: '2113', Name: 'Gemmologist' },
  { Id: 1831, Code: '2113', Name: 'Geochemist' },
  { Id: 1832, Code: '2113', Name: 'Geohydrologist' },
  { Id: 1833, Code: '2113', Name: 'Geologist' },
  { Id: 1834, Code: '2113', Name: 'Geologist, engineering' },
  { Id: 1835, Code: '2113', Name: 'Geomorphologist' },
  { Id: 1836, Code: '2113', Name: 'Geophysicist' },
  { Id: 1837, Code: '2113', Name: 'Geoscientist' },
  { Id: 1838, Code: '2113', Name: 'Geotechnologist' },
  { Id: 1839, Code: '2113', Name: 'Hydrogeologist' },
  { Id: 1840, Code: '2113', Name: 'Hydrologist' },
  { Id: 1841, Code: '2113', Name: 'Hydrometrist' },
  { Id: 1842, Code: '2113', Name: 'Interpreter, seismic' },
  { Id: 1843, Code: '2113', Name: 'Logger, mud' },
  { Id: 1844, Code: '2113', Name: 'Manager, research, GEOLOGICAL' },
  { Id: 1845, Code: '2113', Name: 'Manager, research, METEOROLOGICAL' },
  { Id: 1846, Code: '2113', Name: 'Manager, research, PHYSICAL SCIENCE' },
  { Id: 1847, Code: '2113', Name: 'Mapper, geological' },
  { Id: 1848, Code: '2113', Name: 'Meteorologist' },
  { Id: 1849, Code: '2113', Name: 'Mineralogist' },
  { Id: 1850, Code: '2113', Name: 'Navigator, seismic' },
  { Id: 1851, Code: '2113', Name: 'Oceanographer' },
  { Id: 1852, Code: '2113', Name: 'Officer, meteorological' },
  { Id: 1853, Code: '2113', Name: 'Officer, research, GEOLOGICAL' },
  { Id: 1854, Code: '2113', Name: 'Officer, research, METEOROLOGICAL' },
  { Id: 1855, Code: '2113', Name: 'Officer, research, PHYSICAL SCIENCE' },
  { Id: 1856, Code: '2113', Name: 'Officer, scientific, GEOLOGICAL' },
  { Id: 1857, Code: '2113', Name: 'Officer, scientific, METEOROLOGICAL' },
  { Id: 1858, Code: '2113', Name: 'Officer, scientific, PHYSICAL SCIENCE' },
  { Id: 1859, Code: '2113', Name: 'Palaeontologist' },
  { Id: 1860, Code: '2113', Name: 'Palynologist' },
  { Id: 1861, Code: '2113', Name: 'Petrologist' },
  { Id: 1862, Code: '2113', Name: 'Petrophysicist' },
  { Id: 1863, Code: '2113', Name: 'Physicist' },
  { Id: 1864, Code: '2113', Name: 'Physicist, medical' },
  { Id: 1865, Code: '2113', Name: 'Practitioner, protection, radiation' },
  { Id: 1866, Code: '2113', Name: 'Processor, data, geophysical' },
  { Id: 1867, Code: '2113', Name: 'Reducer, data, geophysical' },
  { Id: 1868, Code: '2113', Name: 'Researcher, GEOLOGICAL' },
  { Id: 1869, Code: '2113', Name: 'Researcher, METEOROLOGICAL' },
  { Id: 1870, Code: '2113', Name: 'Researcher, PHYSICAL SCIENCE' },
  { Id: 1871, Code: '2113', Name: 'Rheologist' },
  { Id: 1872, Code: '2113', Name: 'Scientist, GEOLOGICAL' },
  { Id: 1873, Code: '2113', Name: 'Scientist, METEOROLOGICAL' },
  { Id: 1874, Code: '2113', Name: 'Scientist, PHYSICAL SCIENCE' },
  { Id: 1875, Code: '2113', Name: 'Scientist, research, GEOLOGICAL' },
  { Id: 1876, Code: '2113', Name: 'Scientist, research, METEOROLOGICAL' },
  { Id: 1877, Code: '2113', Name: 'Scientist, research, PHYSICAL SCIENCE' },
  { Id: 1878, Code: '2113', Name: 'Seismologist' },
  { Id: 1879, Code: '2113', Name: 'Spectroscopist' },
  { Id: 1880, Code: '2113', Name: 'Stratigrapher' },
  {
    Id: 1881,
    Code: '2113',
    Name: 'Superintendent, National Physical Laboratory'
  },
  { Id: 1882, Code: '2113', Name: 'Thermodynamicist' },
  { Id: 1883, Code: '2113', Name: 'Worker, research, GEOLOGICAL' },
  { Id: 1884, Code: '2113', Name: 'Worker, research, METEOROLOGICAL' },
  { Id: 1885, Code: '2113', Name: 'Worker, research, MINING' },
  { Id: 1886, Code: '2113', Name: 'Worker, research, PHYSICAL SCIENCE' },
  { Id: 1887, Code: '2114', Name: 'Analyst, GIS, senior' },
  { Id: 1888, Code: '2114', Name: 'Analyst, health, public' },
  {
    Id: 1889,
    Code: '2114',
    Name: 'Analyst, systems, information, geographical, senior'
  },
  { Id: 1890, Code: '2114', Name: 'Anthropologist' },
  { Id: 1891, Code: '2114', Name: 'Antiquary' },
  { Id: 1892, Code: '2114', Name: 'Archaeologist' },
  { Id: 1893, Code: '2114', Name: 'Assistant, research, government' },
  { Id: 1894, Code: '2114', Name: 'Assistant, research, HISTORICAL' },
  { Id: 1895, Code: '2114', Name: 'Assistant, site, archaeologist' },
  { Id: 1896, Code: '2114', Name: 'Associate, research, ECONOMIC' },
  { Id: 1897, Code: '2114', Name: 'Associate, research, government' },
  { Id: 1898, Code: '2114', Name: 'Associate, research, HISTORICAL' },
  { Id: 1899, Code: '2114', Name: 'Consultant, GIS' },
  { Id: 1900, Code: '2114', Name: 'Criminologist' },
  { Id: 1901, Code: '2114', Name: 'Egyptologist' },
  { Id: 1902, Code: '2114', Name: 'Epidemiologist' },
  { Id: 1903, Code: '2114', Name: 'Ethnologist' },
  { Id: 1904, Code: '2114', Name: 'Ethnomusicologist' },
  { Id: 1905, Code: '2114', Name: 'Etymologist' },
  {
    Id: 1906,
    Code: '2114',
    Name: 'Fellow, research, university, SOCIAL SCIENCES'
  },
  { Id: 1907, Code: '2114', Name: 'Genealogist' },
  { Id: 1908, Code: '2114', Name: 'Geographer' },
  { Id: 1909, Code: '2114', Name: 'Historian' },
  { Id: 1910, Code: '2114', Name: 'Manager, GIS' },
  { Id: 1911, Code: '2114', Name: 'Officer, GIS' },
  { Id: 1912, Code: '2114', Name: 'Officer, research, GOVERNMENT' },
  { Id: 1913, Code: '2114', Name: 'Officer, research, HISTORICAL' },
  { Id: 1914, Code: '2114', Name: 'Officer, research, political' },
  {
    Id: 1915,
    Code: '2114',
    Name: 'Officer, survey, social, assistant, government'
  },
  { Id: 1916, Code: '2114', Name: 'Officer, survey, social, government' },
  {
    Id: 1917,
    Code: '2114',
    Name: 'Officer, survey, social, principal, government'
  },
  {
    Id: 1918,
    Code: '2114',
    Name: 'Officer, survey, social, senior, government'
  },
  {
    Id: 1919,
    Code: '2114',
    Name: 'Officer, systems, information, geographical'
  },
  { Id: 1920, Code: '2114', Name: 'Palaeographist' },
  { Id: 1921, Code: '2114', Name: 'Philologist' },
  { Id: 1922, Code: '2114', Name: 'Philosopher' },
  { Id: 1923, Code: '2114', Name: 'Researcher, government' },
  { Id: 1924, Code: '2114', Name: 'Researcher, historical' },
  { Id: 1925, Code: '2114', Name: 'Researcher, parliamentary' },
  { Id: 1926, Code: '2114', Name: 'Researcher, policy' },
  { Id: 1927, Code: '2114', Name: 'Researcher, political' },
  { Id: 1928, Code: '2114', Name: 'Researcher, social' },
  { Id: 1929, Code: '2114', Name: 'Scientist, behavioural' },
  { Id: 1930, Code: '2114', Name: 'Scientist, political' },
  { Id: 1931, Code: '2114', Name: 'Scientist, social' },
  { Id: 1932, Code: '2114', Name: 'Sociologist' },
  { Id: 1933, Code: '2114', Name: 'Specialist, health, public' },
  { Id: 1934, Code: '2114', Name: 'Surveyor, archaeological' },
  { Id: 1935, Code: '2114', Name: 'Worker, research, ECONOMIC' },
  { Id: 1936, Code: '2114', Name: 'Worker, research, HISTORICAL' },
  { Id: 1937, Code: '2119', Name: 'Adviser, scientific' },
  { Id: 1938, Code: '2119', Name: 'Assistant, research, university' },
  { Id: 1939, Code: '2119', Name: 'Associate, research, university' },
  { Id: 1940, Code: '2119', Name: 'Associate, safety, drug' },
  { Id: 1941, Code: '2119', Name: 'Biomechanist' },
  { Id: 1942, Code: '2119', Name: 'Fellow, research, university' },
  { Id: 1943, Code: '2119', Name: 'Fellow, research, university, nos' },
  { Id: 1944, Code: '2119', Name: 'Fellow, research, university, SCIENCES' },
  { Id: 1945, Code: '2119', Name: 'Officer, experimental, chief' },
  { Id: 1946, Code: '2119', Name: 'Officer, experimental, senior' },
  { Id: 1947, Code: '2119', Name: 'Officer, scientific' },
  { Id: 1948, Code: '2119', Name: 'Officer, technical, LABORATORY' },
  { Id: 1949, Code: '2119', Name: 'Researcher, postdoctoral' },
  { Id: 1950, Code: '2119', Name: 'Researcher, university' },
  { Id: 1951, Code: '2119', Name: 'Scientist' },
  { Id: 1952, Code: '2119', Name: 'Scientist, consumer' },
  { Id: 1953, Code: '2119', Name: 'Scientist, research' },
  { Id: 1954, Code: '2119', Name: 'Scientist, research, operational' },
  { Id: 1955, Code: '2119', Name: 'Scientist, sports' },
  { Id: 1956, Code: '2119', Name: 'Scientist, sports and exercise' },
  { Id: 1957, Code: '2121', Name: 'Consultant, civil engineering' },
  { Id: 1958, Code: '2121', Name: 'Consultant, engineering, civil' },
  { Id: 1959, Code: '2121', Name: 'Consultant, mining' },
  { Id: 1960, Code: '2121', Name: 'Engineer and architect' },
  { Id: 1961, Code: '2121', Name: 'Engineer, aquaculture' },
  { Id: 1962, Code: '2121', Name: 'Engineer, assistant, coal mine' },
  { Id: 1963, Code: '2121', Name: 'Engineer, assistant, local government' },
  { Id: 1964, Code: '2121', Name: 'Engineer, assurance, flow' },
  { Id: 1965, Code: '2121', Name: 'Engineer, boring, PROFESSIONAL' },
  { Id: 1966, Code: '2121', Name: 'Engineer, borough' },
  { Id: 1967, Code: '2121', Name: 'Engineer, building' },
  { Id: 1968, Code: '2121', Name: 'Engineer, building and civil' },
  { Id: 1969, Code: '2121', Name: 'Engineer, city' },
  { Id: 1970, Code: '2121', Name: 'Engineer, civil, PROFESSIONAL' },
  { Id: 1971, Code: '2121', Name: 'Engineer, colliery' },
  { Id: 1972, Code: '2121', Name: 'Engineer, construction, PROFESSIONAL' },
  { Id: 1973, Code: '2121', Name: 'Engineer, constructional, PROFESSIONAL' },
  { Id: 1974, Code: '2121', Name: "Engineer, contractor's" },
  { Id: 1975, Code: '2121', Name: 'Engineer, control, strata' },
  { Id: 1976, Code: '2121', Name: 'Engineer, county' },
  { Id: 1977, Code: '2121', Name: 'Engineer, defence, flood' },
  { Id: 1978, Code: '2121', Name: 'Engineer, demolition, PROFESSIONAL' },
  { Id: 1979, Code: '2121', Name: 'Engineer, design, highways' },
  { Id: 1980, Code: '2121', Name: 'Engineer, district, railways' },
  { Id: 1981, Code: '2121', Name: 'Engineer, dock' },
  { Id: 1982, Code: '2121', Name: 'Engineer, docks' },
  { Id: 1983, Code: '2121', Name: 'Engineer, drainage, PROFESSIONAL' },
  { Id: 1984, Code: '2121', Name: 'Engineer, dredger, PROFESSIONAL' },
  { Id: 1985, Code: '2121', Name: 'Engineer, drilling, mining' },
  { Id: 1986, Code: '2121', Name: 'Engineer, field, oil wells' },
  { Id: 1987, Code: '2121', Name: 'Engineer, fluids, drilling' },
  { Id: 1988, Code: '2121', Name: 'Engineer, gas, natural' },
  { Id: 1989, Code: '2121', Name: 'Engineer, geotechnical' },
  { Id: 1990, Code: '2121', Name: 'Engineer, health, public' },
  { Id: 1991, Code: '2121', Name: 'Engineer, highway' },
  { Id: 1992, Code: '2121', Name: 'Engineer, highways' },
  { Id: 1993, Code: '2121', Name: 'Engineer, incorporated, civil engineering' },
  { Id: 1994, Code: '2121', Name: 'Engineer, irrigation, PROFESSIONAL' },
  { Id: 1995, Code: '2121', Name: 'Engineer, maintenance, highways' },
  {
    Id: 1996,
    Code: '2121',
    Name: 'Engineer, maintenance, local government: highways dept'
  },
  { Id: 1997, Code: '2121', Name: 'Engineer, mining' },
  { Id: 1998, Code: '2121', Name: 'Engineer, mud, oil wells' },
  { Id: 1999, Code: '2121', Name: 'Engineer, municipal' },
  { Id: 2000, Code: '2121', Name: 'Engineer, MWD' },
  { Id: 2001, Code: '2121', Name: 'Engineer, offshore' },
  { Id: 2002, Code: '2121', Name: 'Engineer, oil' },
  { Id: 2003, Code: '2121', Name: 'Engineer, oil and natural gas' },
  { Id: 2004, Code: '2121', Name: 'Engineer, petroleum' },
  { Id: 2005, Code: '2121', Name: 'Engineer, pipeline' },
  { Id: 2006, Code: '2121', Name: 'Engineer, PROFESSIONAL, CIVIL' },
  { Id: 2007, Code: '2121', Name: 'Engineer, PROFESSIONAL, CONSTRUCTIONAL' },
  { Id: 2008, Code: '2121', Name: 'Engineer, PROFESSIONAL, HIGHWAY' },
  { Id: 2009, Code: '2121', Name: 'Engineer, PROFESSIONAL, HIGHWAYS' },
  { Id: 2010, Code: '2121', Name: 'Engineer, PROFESSIONAL, IRRIGATION' },
  { Id: 2011, Code: '2121', Name: 'Engineer, PROFESSIONAL, MINING' },
  { Id: 2012, Code: '2121', Name: 'Engineer, PROFESSIONAL, MUNICIPAL' },
  {
    Id: 2013,
    Code: '2121',
    Name: 'Engineer, PROFESSIONAL, OIL AND NATURAL GAS'
  },
  { Id: 2014, Code: '2121', Name: 'Engineer, PROFESSIONAL, PUBLIC HEALTH' },
  { Id: 2015, Code: '2121', Name: 'Engineer, PROFESSIONAL, PUBLIC WORKS' },
  { Id: 2016, Code: '2121', Name: 'Engineer, PROFESSIONAL, QUARRYING' },
  { Id: 2017, Code: '2121', Name: 'Engineer, PROFESSIONAL, SANITARY' },
  { Id: 2018, Code: '2121', Name: 'Engineer, PROFESSIONAL, STRUCTURAL' },
  { Id: 2019, Code: '2121', Name: 'Engineer, PROFESSIONAL, WATER' },
  { Id: 2020, Code: '2121', Name: 'Engineer, quarrying' },
  { Id: 2021, Code: '2121', Name: 'Engineer, reservoir' },
  { Id: 2022, Code: '2121', Name: 'Engineer, resident' },
  { Id: 2023, Code: '2121', Name: 'Engineer, risk, flood' },
  { Id: 2024, Code: '2121', Name: 'Engineer, risk, flood, development' },
  { Id: 2025, Code: '2121', Name: 'Engineer, road' },
  { Id: 2026, Code: '2121', Name: 'Engineer, sanitary, PROFESSIONAL' },
  { Id: 2027, Code: '2121', Name: 'Engineer, services, building' },
  { Id: 2028, Code: '2121', Name: 'Engineer, site, building construction' },
  { Id: 2029, Code: '2121', Name: 'Engineer, station, MOD' },
  { Id: 2030, Code: '2121', Name: 'Engineer, structural' },
  { Id: 2031, Code: '2121', Name: 'Engineer, subsidence' },
  {
    Id: 2032,
    Code: '2121',
    Name: 'Engineer, sustainability, building construction'
  },
  { Id: 2033, Code: '2121', Name: 'Engineer, treatment, water, PROFESSIONAL' },
  { Id: 2034, Code: '2121', Name: 'Engineer, tunnelling' },
  { Id: 2035, Code: '2121', Name: 'Engineer, utilities, PROFESSIONAL' },
  { Id: 2036, Code: '2121', Name: 'Engineer, water, PROFESSIONAL' },
  { Id: 2037, Code: '2121', Name: 'Engineer, well' },
  { Id: 2038, Code: '2121', Name: 'Engineer, works, public' },
  {
    Id: 2039,
    Code: '2121',
    Name: 'Inspector, civils, local government: highways dept'
  },
  { Id: 2040, Code: '2121', Name: 'Inspector, engineering, DETR' },
  {
    Id: 2041,
    Code: '2121',
    Name: 'Inspector, highways, NRSWA, local government'
  },
  {
    Id: 2042,
    Code: '2121',
    Name: 'Inspector, network, highway, senior, local government'
  },
  { Id: 2043, Code: '2121', Name: 'Modeller, hydraulic' },
  { Id: 2044, Code: '2121', Name: 'Officer, works' },
  { Id: 2045, Code: '2121', Name: 'Surveyor, borough' },
  { Id: 2046, Code: '2121', Name: 'Surveyor, city' },
  { Id: 2047, Code: '2121', Name: 'Surveyor, county' },
  { Id: 2048, Code: '2121', Name: 'Surveyor, district' },
  { Id: 2049, Code: '2121', Name: 'Surveyor, divisional, county' },
  { Id: 2050, Code: '2122', Name: 'Architect, naval' },
  {
    Id: 2051,
    Code: '2122',
    Name: 'Assistant, research, ENGINEERING, MECHANICAL'
  },
  {
    Id: 2052,
    Code: '2122',
    Name: 'Associate, research, ENGINEERING, MECHANICAL'
  },
  { Id: 2053, Code: '2122', Name: 'Constructor, naval' },
  { Id: 2054, Code: '2122', Name: 'Consultant, engineering' },
  { Id: 2055, Code: '2122', Name: 'Designer, aircraft' },
  { Id: 2056, Code: '2122', Name: 'Designer, nautical' },
  { Id: 2057, Code: '2122', Name: 'Designer, naval' },
  { Id: 2058, Code: '2122', Name: 'Engineer, aeronautical, PROFESSIONAL' },
  { Id: 2059, Code: '2122', Name: 'Engineer, aerospace' },
  { Id: 2060, Code: '2122', Name: 'Engineer, automobile, PROFESSIONAL' },
  { Id: 2061, Code: '2122', Name: 'Engineer, automotive, PROFESSIONAL' },
  { Id: 2062, Code: '2122', Name: 'Engineer, aviation, PROFESSIONAL' },
  { Id: 2063, Code: '2122', Name: 'Engineer, chassis' },
  { Id: 2064, Code: '2122', Name: 'Engineer, chief, area, coal mine' },
  { Id: 2065, Code: '2122', Name: 'Engineer, chief, MAINTENANCE' },
  { Id: 2066, Code: '2122', Name: 'Engineer, diesel, PROFESSIONAL' },
  { Id: 2067, Code: '2122', Name: 'Engineer, heavy, PROFESSIONAL' },
  { Id: 2068, Code: '2122', Name: 'Engineer, hydraulic, PROFESSIONAL' },
  {
    Id: 2069,
    Code: '2122',
    Name: 'Engineer, incorporated, mechanical engineering'
  },
  { Id: 2070, Code: '2122', Name: 'Engineer, locomotive, PROFESSIONAL' },
  { Id: 2071, Code: '2122', Name: 'Engineer, marine, chief' },
  { Id: 2072, Code: '2122', Name: 'Engineer, marine, PROFESSIONAL' },
  { Id: 2073, Code: '2122', Name: 'Engineer, marine, senior' },
  { Id: 2074, Code: '2122', Name: 'Engineer, marine, superintendent' },
  {
    Id: 2075,
    Code: '2122',
    Name: 'Engineer, mechanical and electrical, PROFESSIONAL'
  },
  { Id: 2076, Code: '2122', Name: 'Engineer, mechanical, area, coal mine' },
  { Id: 2077, Code: '2122', Name: 'Engineer, mechanical, chief' },
  { Id: 2078, Code: '2122', Name: 'Engineer, mechanical, group, coal mine' },
  { Id: 2079, Code: '2122', Name: 'Engineer, mechanical, PROFESSIONAL' },
  { Id: 2080, Code: '2122', Name: 'Engineer, mechanical, unit, coal mine' },
  { Id: 2081, Code: '2122', Name: 'Engineer, mechanisation' },
  { Id: 2082, Code: '2122', Name: 'Engineer, mill, PROFESSIONAL' },
  { Id: 2083, Code: '2122', Name: 'Engineer, motorsport' },
  {
    Id: 2084,
    Code: '2122',
    Name: 'Engineer, operations, electricity supplier'
  },
  { Id: 2085, Code: '2122', Name: 'Engineer, piping, PROFESSIONAL' },
  { Id: 2086, Code: '2122', Name: 'Engineer, plant, PROFESSIONAL' },
  { Id: 2087, Code: '2122', Name: 'Engineer, PROFESSIONAL' },
  { Id: 2088, Code: '2122', Name: 'Engineer, PROFESSIONAL, AERONAUTICAL' },
  { Id: 2089, Code: '2122', Name: 'Engineer, PROFESSIONAL, AUTOMOBILE' },
  { Id: 2090, Code: '2122', Name: 'Engineer, PROFESSIONAL, AVIATION' },
  { Id: 2091, Code: '2122', Name: 'Engineer, PROFESSIONAL, HYDRAULIC' },
  { Id: 2092, Code: '2122', Name: 'Engineer, PROFESSIONAL, LOCOMOTIVE' },
  { Id: 2093, Code: '2122', Name: 'Engineer, PROFESSIONAL, MARINE' },
  { Id: 2094, Code: '2122', Name: 'Engineer, PROFESSIONAL, MECHANICAL' },
  { Id: 2095, Code: '2122', Name: 'Engineer, PROFESSIONAL, STRESS' },
  { Id: 2096, Code: '2122', Name: 'Engineer, PROFESSIONAL, TEXTILE' },
  { Id: 2097, Code: '2122', Name: 'Engineer, salvage, marine' },
  { Id: 2098, Code: '2122', Name: 'Engineer, services, building, mechanical' },
  { Id: 2099, Code: '2122', Name: 'Engineer, stress, aeronautical' },
  { Id: 2100, Code: '2122', Name: 'Engineer, stress, aircraft' },
  { Id: 2101, Code: '2122', Name: 'Engineer, stress, aircraft mfr' },
  { Id: 2102, Code: '2122', Name: 'Engineer, stress, PROFESSIONAL' },
  { Id: 2103, Code: '2122', Name: 'Engineer, textile, PROFESSIONAL' },
  { Id: 2104, Code: '2122', Name: 'Engineer, tool, machine, PROFESSIONAL' },
  { Id: 2105, Code: '2122', Name: 'Engineer, welding, PROFESSIONAL' },
  { Id: 2106, Code: '2122', Name: 'Engineer, works' },
  { Id: 2107, Code: '2122', Name: 'Expert, salvage, marine' },
  { Id: 2108, Code: '2122', Name: 'Hydrodynamicist' },
  { Id: 2109, Code: '2122', Name: 'Inspector of fighting vehicles' },
  {
    Id: 2110,
    Code: '2122',
    Name: 'Inspector, chief, engineering, PROFESSIONAL'
  },
  { Id: 2111, Code: '2122', Name: 'Inspector, engineering, chief' },
  { Id: 2112, Code: '2122', Name: 'Inspector, engineering, Jobcentre Plus' },
  { Id: 2113, Code: '2122', Name: 'Officer, certifying, DETR' },
  { Id: 2114, Code: '2122', Name: 'Officer, engineering, government' },
  {
    Id: 2115,
    Code: '2122',
    Name: 'Officer, inspection, Dept for Business Innovation and Skills'
  },
  {
    Id: 2116,
    Code: '2122',
    Name: 'Officer, research, ENGINEERING, MECHANICAL'
  },
  {
    Id: 2117,
    Code: '2122',
    Name: 'Officer, scientific, ENGINEERING, MECHANICAL'
  },
  { Id: 2118, Code: '2122', Name: 'Officer, technical, principal' },
  { Id: 2119, Code: '2122', Name: 'Officer, transport, mechanical, chief' },
  { Id: 2120, Code: '2122', Name: 'Researcher, ENGINEERING, MECHANICAL' },
  { Id: 2121, Code: '2122', Name: 'Surveyor, aircraft' },
  {
    Id: 2122,
    Code: '2122',
    Name: 'Surveyor, design, Civil Aviation Authority'
  },
  {
    Id: 2123,
    Code: '2123',
    Name: 'Assistant, research, ENGINEERING, ELECTRICAL'
  },
  {
    Id: 2124,
    Code: '2123',
    Name: 'Associate, research, ENGINEERING, ELECTRICAL'
  },
  { Id: 2125, Code: '2123', Name: 'Engineer, area, TECHNICAL' },
  { Id: 2126, Code: '2123', Name: 'Engineer, assistant, electricity supplier' },
  { Id: 2127, Code: '2123', Name: 'Engineer, branch, electricity supplier' },
  { Id: 2128, Code: '2123', Name: 'Engineer, charge, electricity supplier' },
  { Id: 2129, Code: '2123', Name: 'Engineer, chief, electricity supplier' },
  { Id: 2130, Code: '2123', Name: 'Engineer, control, electricity supplier' },
  { Id: 2131, Code: '2123', Name: 'Engineer, design, electrical' },
  {
    Id: 2132,
    Code: '2123',
    Name: 'Engineer, distribution, electricity supplier'
  },
  { Id: 2133, Code: '2123', Name: 'Engineer, district, electricity supplier' },
  {
    Id: 2134,
    Code: '2123',
    Name: 'Engineer, electrical and mechanical, PROFESSIONAL'
  },
  { Id: 2135, Code: '2123', Name: 'Engineer, electrical, area, coal mine' },
  {
    Id: 2136,
    Code: '2123',
    Name: 'Engineer, electrical, charge, assistant, electricity supplier'
  },
  { Id: 2137, Code: '2123', Name: 'Engineer, electrical, chief' },
  { Id: 2138, Code: '2123', Name: 'Engineer, electrical, group, coal mine' },
  { Id: 2139, Code: '2123', Name: 'Engineer, electrical, head' },
  { Id: 2140, Code: '2123', Name: 'Engineer, electrical, PROFESSIONAL' },
  { Id: 2141, Code: '2123', Name: 'Engineer, electrical, senior' },
  { Id: 2142, Code: '2123', Name: 'Engineer, electrical, works' },
  { Id: 2143, Code: '2123', Name: 'Engineer, equipment' },
  { Id: 2144, Code: '2123', Name: 'Engineer, first, power station' },
  {
    Id: 2145,
    Code: '2123',
    Name: 'Engineer, generating, electricity supplier'
  },
  { Id: 2146, Code: '2123', Name: 'Engineer, incorporated, ELECTRICAL' },
  { Id: 2147, Code: '2123', Name: 'Engineer, laser' },
  {
    Id: 2148,
    Code: '2123',
    Name: 'Engineer, mechanical and electrical, electricity supplier'
  },
  { Id: 2149, Code: '2123', Name: 'Engineer, power' },
  { Id: 2150, Code: '2123', Name: 'Engineer, PROFESSIONAL, ELECTRICAL' },
  { Id: 2151, Code: '2123', Name: 'Engineer, resident, electricity supplier' },
  { Id: 2152, Code: '2123', Name: 'Engineer, second, electricity supplier' },
  { Id: 2153, Code: '2123', Name: 'Engineer, second, power station' },
  { Id: 2154, Code: '2123', Name: 'Engineer, shift, electricity supplier' },
  { Id: 2155, Code: '2123', Name: 'Engineer, signal, railways, PROFESSIONAL' },
  {
    Id: 2156,
    Code: '2123',
    Name: 'Engineer, station, power, electricity supplier'
  },
  { Id: 2157, Code: '2123', Name: 'Engineer, sub-area, electricity supplier' },
  {
    Id: 2158,
    Code: '2123',
    Name: 'Engineer, sub-station, electricity supplier'
  },
  { Id: 2159, Code: '2123', Name: 'Engineer, switchgear, PROFESSIONAL' },
  { Id: 2160, Code: '2123', Name: 'Engineer, systems, power' },
  { Id: 2161, Code: '2123', Name: 'Engineer, telephone, PROFESSIONAL' },
  { Id: 2162, Code: '2123', Name: 'Engineer, testing, cable' },
  { Id: 2163, Code: '2123', Name: 'Engineer, thermographic, PROFESSIONAL' },
  { Id: 2164, Code: '2123', Name: 'Engineer, traction, electric' },
  { Id: 2165, Code: '2123', Name: 'Engineer, transmission, power' },
  { Id: 2166, Code: '2123', Name: 'Engineer-in-charge, electricity supplier' },
  {
    Id: 2167,
    Code: '2123',
    Name: 'Inspector, chief, engineering, PROFESSIONAL, ELECTRICAL ENGINEERING'
  },
  {
    Id: 2168,
    Code: '2123',
    Name: 'Officer, research, ENGINEERING, ELECTRICAL'
  },
  {
    Id: 2169,
    Code: '2123',
    Name: 'Officer, scientific, ENGINEERING, ELECTRICAL'
  },
  { Id: 2170, Code: '2123', Name: 'Researcher, ENGINEERING, ELECTRICAL' },
  { Id: 2171, Code: '2123', Name: 'Superintendent, electrical, MOD' },
  {
    Id: 2172,
    Code: '2123',
    Name: 'Superintendent, meter, electricity supplier'
  },
  { Id: 2173, Code: '2123', Name: 'Surveyor, electrical' },
  {
    Id: 2174,
    Code: '2124',
    Name: 'Assistant, research, ENGINEERING, ELECTRONIC'
  },
  {
    Id: 2175,
    Code: '2124',
    Name: 'Associate, research, ENGINEERING, ELECTRONIC'
  },
  {
    Id: 2176,
    Code: '2124',
    Name: 'Consultant, communications, telecommunications'
  },
  {
    Id: 2177,
    Code: '2124',
    Name: 'Controller, transmission, television: production, broadcasting'
  },
  { Id: 2178, Code: '2124', Name: 'Designer, telecommunications' },
  { Id: 2179, Code: '2124', Name: 'Engineer, area, telecommunications' },
  { Id: 2180, Code: '2124', Name: 'Engineer, avionics' },
  { Id: 2181, Code: '2124', Name: 'Engineer, broadcast, PROFESSIONAL' },
  { Id: 2182, Code: '2124', Name: 'Engineer, broadcasting, PROFESSIONAL' },
  { Id: 2183, Code: '2124', Name: 'Engineer, communication, PROFESSIONAL' },
  { Id: 2184, Code: '2124', Name: 'Engineer, communication, radio' },
  { Id: 2185, Code: '2124', Name: 'Engineer, communications, PROFESSIONAL' },
  { Id: 2186, Code: '2124', Name: 'Engineer, electronic, PROFESSIONAL' },
  { Id: 2187, Code: '2124', Name: 'Engineer, electronics, PROFESSIONAL' },
  { Id: 2188, Code: '2124', Name: 'Engineer, microwave' },
  { Id: 2189, Code: '2124', Name: 'Engineer, optic, fibre, PROFESSIONAL' },
  { Id: 2190, Code: '2124', Name: 'Engineer, optimisation, RF' },
  { Id: 2191, Code: '2124', Name: 'Engineer, PROFESSIONAL, AVIONICS' },
  { Id: 2192, Code: '2124', Name: 'Engineer, PROFESSIONAL, BROADCASTING' },
  { Id: 2193, Code: '2124', Name: 'Engineer, PROFESSIONAL, ELECTRONIC' },
  { Id: 2194, Code: '2124', Name: 'Engineer, PROFESSIONAL, MICROWAVE' },
  { Id: 2195, Code: '2124', Name: 'Engineer, PROFESSIONAL, RADAR' },
  { Id: 2196, Code: '2124', Name: 'Engineer, PROFESSIONAL, RADIO' },
  { Id: 2197, Code: '2124', Name: 'Engineer, PROFESSIONAL, TELECOMMUNICATION' },
  { Id: 2198, Code: '2124', Name: 'Engineer, PROFESSIONAL, TELEVISION' },
  { Id: 2199, Code: '2124', Name: 'Engineer, radar, PROFESSIONAL' },
  { Id: 2200, Code: '2124', Name: 'Engineer, radar, RESEARCH' },
  { Id: 2201, Code: '2124', Name: 'Engineer, radio, PROFESSIONAL' },
  { Id: 2202, Code: '2124', Name: 'Engineer, regional, telecommunications' },
  { Id: 2203, Code: '2124', Name: 'Engineer, staff, telecommunications' },
  { Id: 2204, Code: '2124', Name: 'Engineer, systems, aerospace' },
  { Id: 2205, Code: '2124', Name: 'Engineer, systems, avionics' },
  { Id: 2206, Code: '2124', Name: 'Engineer, systems, mission' },
  {
    Id: 2207,
    Code: '2124',
    Name: 'Engineer, telecommunications, PROFESSIONAL'
  },
  { Id: 2208, Code: '2124', Name: 'Engineer, television, PROFESSIONAL' },
  { Id: 2209, Code: '2124', Name: 'Engineer, transmission, power, TELEVISION' },
  {
    Id: 2210,
    Code: '2124',
    Name: 'Inspector, chief, engineering, PROFESSIONAL, ELECTRONIC ENGINEERING'
  },
  {
    Id: 2211,
    Code: '2124',
    Name: 'Officer, research, ENGINEERING, ELECTRONIC'
  },
  {
    Id: 2212,
    Code: '2124',
    Name: 'Officer, scientific, ENGINEERING, ELECTRONIC'
  },
  { Id: 2213, Code: '2124', Name: 'Researcher, ENGINEERING, ELECTRONIC' },
  { Id: 2214, Code: '2126', Name: 'Consultant, design, engineering' },
  { Id: 2215, Code: '2126', Name: 'Designer, avionics' },
  { Id: 2216, Code: '2126', Name: 'Designer, circuit, telecommunications' },
  { Id: 2217, Code: '2126', Name: 'Designer, electrical' },
  { Id: 2218, Code: '2126', Name: 'Designer, electronics' },
  { Id: 2219, Code: '2126', Name: 'Designer, machinery, electrical' },
  { Id: 2220, Code: '2126', Name: 'Designer, metal trades' },
  { Id: 2221, Code: '2126', Name: 'Designer, systems, RAILWAY SIGNALLING' },
  { Id: 2222, Code: '2126', Name: 'Engineer, clinical' },
  { Id: 2223, Code: '2126', Name: 'Engineer, design' },
  { Id: 2224, Code: '2126', Name: 'Engineer, design and development' },
  { Id: 2225, Code: '2126', Name: 'Engineer, design and test' },
  { Id: 2226, Code: '2126', Name: 'Engineer, design, hardware, COMPUTER' },
  { Id: 2227, Code: '2126', Name: 'Engineer, design, mechanical' },
  { Id: 2228, Code: '2126', Name: 'Engineer, development' },
  { Id: 2229, Code: '2126', Name: 'Engineer, factors, human' },
  { Id: 2230, Code: '2126', Name: 'Engineer, illuminating, PROFESSIONAL' },
  { Id: 2231, Code: '2126', Name: 'Engineer, lighting, PROFESSIONAL' },
  { Id: 2232, Code: '2126', Name: 'Engineer, PROFESSIONAL, DESIGN' },
  { Id: 2233, Code: '2126', Name: 'Engineer, PROFESSIONAL, DEVELOPMENT' },
  { Id: 2234, Code: '2126', Name: 'Engineer, PROFESSIONAL, ILLUMINATING' },
  { Id: 2235, Code: '2126', Name: 'Engineer, PROFESSIONAL, LIGHTING' },
  { Id: 2236, Code: '2126', Name: 'Engineer, r and d' },
  { Id: 2237, Code: '2126', Name: 'Engineer, research and development' },
  { Id: 2238, Code: '2126', Name: 'Engineer, robotics' },
  { Id: 2239, Code: '2126', Name: 'Engineer, systems, control' },
  { Id: 2240, Code: '2126', Name: 'Ergonomist' },
  { Id: 2241, Code: '2126', Name: 'Integrator, systems, engineering' },
  { Id: 2242, Code: '2126', Name: 'Psychologist, environmental' },
  { Id: 2243, Code: '2126', Name: 'Specialist, factors, human' },
  { Id: 2244, Code: '2127', Name: 'Adviser, production' },
  { Id: 2245, Code: '2127', Name: 'Consultant, engineering, chemical' },
  { Id: 2246, Code: '2127', Name: 'Consultant, industrial' },
  { Id: 2247, Code: '2127', Name: 'Consultant, production' },
  { Id: 2248, Code: '2127', Name: 'Coordinator, project, manufacturing' },
  { Id: 2249, Code: '2127', Name: 'Engineer, chemical' },
  { Id: 2250, Code: '2127', Name: 'Engineer, control, production' },
  { Id: 2251, Code: '2127', Name: 'Engineer, explosive, coal mine' },
  { Id: 2252, Code: '2127', Name: 'Engineer, improvement, continuous' },
  { Id: 2253, Code: '2127', Name: 'Engineer, industrial' },
  { Id: 2254, Code: '2127', Name: 'Engineer, petrochemical' },
  { Id: 2255, Code: '2127', Name: 'Engineer, pharmaceutical' },
  { Id: 2256, Code: '2127', Name: 'Engineer, plastics, PROFESSIONAL' },
  { Id: 2257, Code: '2127', Name: 'Engineer, process' },
  { Id: 2258, Code: '2127', Name: 'Engineer, product, PROFESSIONAL' },
  { Id: 2259, Code: '2127', Name: 'Engineer, production' },
  { Id: 2260, Code: '2127', Name: 'Engineer, production and planning' },
  { Id: 2261, Code: '2127', Name: 'Engineer, PROFESSIONAL, CHEMICAL' },
  { Id: 2262, Code: '2127', Name: 'Engineer, PROFESSIONAL, PETROCHEMICAL' },
  { Id: 2263, Code: '2127', Name: 'Engineer, PROFESSIONAL, PHARMACEUTICAL' },
  { Id: 2264, Code: '2127', Name: 'Engineer, PROFESSIONAL, PLASTICS' },
  { Id: 2265, Code: '2127', Name: 'Engineer, PROFESSIONAL, PROCESS' },
  { Id: 2266, Code: '2127', Name: 'Engineer, PROFESSIONAL, PRODUCTION' },
  { Id: 2267, Code: '2127', Name: 'Engineer, systems, manufacturing' },
  { Id: 2268, Code: '2127', Name: 'Leader, section, PRODUCTION CONTROL' },
  {
    Id: 2269,
    Code: '2127',
    Name: 'Officer, productivity and costs, coal mine'
  },
  { Id: 2270, Code: '2127', Name: 'Superintendent, production, MOD' },
  { Id: 2271, Code: '2129', Name: 'Acoustician, PROFESSIONAL' },
  { Id: 2272, Code: '2129', Name: 'Adviser, heating, PROFESSIONAL' },
  { Id: 2273, Code: '2129', Name: 'Adviser, textile' },
  { Id: 2274, Code: '2129', Name: 'Agent, patent' },
  { Id: 2275, Code: '2129', Name: 'Analyst, engineering' },
  { Id: 2276, Code: '2129', Name: 'Analyst, patent' },
  { Id: 2277, Code: '2129', Name: 'Assayer' },
  { Id: 2278, Code: '2129', Name: 'Assayist' },
  { Id: 2279, Code: '2129', Name: 'Assistant, assay' },
  { Id: 2280, Code: '2129', Name: "Assistant, assayer's" },
  { Id: 2281, Code: '2129', Name: 'Associate, patent' },
  { Id: 2282, Code: '2129', Name: 'Brewer, brewery' },
  { Id: 2283, Code: '2129', Name: 'Brewer, distillery' },
  { Id: 2284, Code: '2129', Name: 'Brewer, head' },
  { Id: 2285, Code: '2129', Name: 'Brewer, QUALIFIED' },
  { Id: 2286, Code: '2129', Name: 'Brewer, technical' },
  { Id: 2287, Code: '2129', Name: 'Brewer, under' },
  { Id: 2288, Code: '2129', Name: 'Brewer, vinegar' },
  { Id: 2289, Code: '2129', Name: 'Brewer, vinegar mfr' },
  { Id: 2290, Code: '2129', Name: 'Brewer, working' },
  { Id: 2291, Code: '2129', Name: 'Ceramicist' },
  { Id: 2292, Code: '2129', Name: 'Ceramist' },
  { Id: 2293, Code: '2129', Name: 'Consultant, acoustics' },
  { Id: 2294, Code: '2129', Name: 'Consultant, ceramics' },
  { Id: 2295, Code: '2129', Name: 'Consultant, colour, paint mfr' },
  { Id: 2296, Code: '2129', Name: 'Consultant, energy' },
  { Id: 2297, Code: '2129', Name: 'Consultant, heating, PROFESSIONAL' },
  { Id: 2298, Code: '2129', Name: 'Consultant, metallurgical' },
  { Id: 2299, Code: '2129', Name: 'Consultant, patent' },
  { Id: 2300, Code: '2129', Name: 'Consultant, scientific' },
  { Id: 2301, Code: '2129', Name: 'Dyer, technical' },
  { Id: 2302, Code: '2129', Name: 'Engineer, acoustics, PROFESSIONAL' },
  { Id: 2303, Code: '2129', Name: 'Engineer, agricultural, PROFESSIONAL' },
  { Id: 2304, Code: '2129', Name: 'Engineer, biomass, PROFESSIONAL' },
  { Id: 2305, Code: '2129', Name: 'Engineer, biomedical' },
  { Id: 2306, Code: '2129', Name: 'Engineer, ceramics' },
  { Id: 2307, Code: '2129', Name: 'Engineer, chief, gas supplier' },
  { Id: 2308, Code: '2129', Name: 'Engineer, combustion' },
  { Id: 2309, Code: '2129', Name: 'Engineer, conditioning, air, PROFESSIONAL' },
  { Id: 2310, Code: '2129', Name: 'Engineer, control, noise, PROFESSIONAL' },
  { Id: 2311, Code: '2129', Name: 'Engineer, corrosion, PROFESSIONAL' },
  { Id: 2312, Code: '2129', Name: 'Engineer, cryogenic' },
  {
    Id: 2313,
    Code: '2129',
    Name: 'Engineer, decommissioning, nuclear fuel production'
  },
  { Id: 2314, Code: '2129', Name: 'Engineer, experimental' },
  { Id: 2315, Code: '2129', Name: 'Engineer, forensic' },
  { Id: 2316, Code: '2129', Name: 'Engineer, fuel' },
  { Id: 2317, Code: '2129', Name: 'Engineer, gas, PROFESSIONAL' },
  { Id: 2318, Code: '2129', Name: 'Engineer, glass' },
  {
    Id: 2319,
    Code: '2129',
    Name: 'Engineer, heating and ventilating, PROFESSIONAL'
  },
  { Id: 2320, Code: '2129', Name: 'Engineer, heating, PROFESSIONAL' },
  { Id: 2321, Code: '2129', Name: 'Engineer, horticultural, PROFESSIONAL' },
  { Id: 2322, Code: '2129', Name: 'Engineer, lubrication, PROFESSIONAL' },
  { Id: 2323, Code: '2129', Name: 'Engineer, mechatronics' },
  { Id: 2324, Code: '2129', Name: 'Engineer, medical, PROFESSIONAL' },
  { Id: 2325, Code: '2129', Name: 'Engineer, metallurgical' },
  { Id: 2326, Code: '2129', Name: 'Engineer, nuclear' },
  { Id: 2327, Code: '2129', Name: 'Engineer, packaging' },
  { Id: 2328, Code: '2129', Name: 'Engineer, patent' },
  { Id: 2329, Code: '2129', Name: 'Engineer, physics, health' },
  { Id: 2330, Code: '2129', Name: 'Engineer, PROFESSIONAL, ACOUSTICS' },
  { Id: 2331, Code: '2129', Name: 'Engineer, PROFESSIONAL, AGRICULTURAL' },
  { Id: 2332, Code: '2129', Name: 'Engineer, PROFESSIONAL, CERAMICS' },
  { Id: 2333, Code: '2129', Name: 'Engineer, PROFESSIONAL, COMBUSTION' },
  { Id: 2334, Code: '2129', Name: 'Engineer, PROFESSIONAL, CONDITIONING, AIR' },
  { Id: 2335, Code: '2129', Name: 'Engineer, PROFESSIONAL, CORROSION' },
  { Id: 2336, Code: '2129', Name: 'Engineer, PROFESSIONAL, CRYOGENICS' },
  { Id: 2337, Code: '2129', Name: 'Engineer, PROFESSIONAL, FUEL' },
  { Id: 2338, Code: '2129', Name: 'Engineer, PROFESSIONAL, GAS' },
  { Id: 2339, Code: '2129', Name: 'Engineer, PROFESSIONAL, GLASS' },
  {
    Id: 2340,
    Code: '2129',
    Name: 'Engineer, PROFESSIONAL, HEATING AND VENTILATING'
  },
  { Id: 2341, Code: '2129', Name: 'Engineer, PROFESSIONAL, HORTICULTURAL' },
  { Id: 2342, Code: '2129', Name: 'Engineer, PROFESSIONAL, LUBRICATION' },
  { Id: 2343, Code: '2129', Name: 'Engineer, PROFESSIONAL, METALLURGICS' },
  { Id: 2344, Code: '2129', Name: 'Engineer, PROFESSIONAL, NOISE CONTROL' },
  { Id: 2345, Code: '2129', Name: 'Engineer, PROFESSIONAL, NUCLEAR' },
  { Id: 2346, Code: '2129', Name: 'Engineer, PROFESSIONAL, PACKAGING' },
  { Id: 2347, Code: '2129', Name: 'Engineer, PROFESSIONAL, PATENT' },
  { Id: 2348, Code: '2129', Name: 'Engineer, PROFESSIONAL, REFRIGERATION' },
  { Id: 2349, Code: '2129', Name: 'Engineer, PROFESSIONAL, THERMAL' },
  { Id: 2350, Code: '2129', Name: 'Engineer, PROFESSIONAL, TRAFFIC' },
  { Id: 2351, Code: '2129', Name: 'Engineer, project' },
  { Id: 2352, Code: '2129', Name: 'Engineer, project, engineering' },
  { Id: 2353, Code: '2129', Name: 'Engineer, project, manufacturing' },
  {
    Id: 2354,
    Code: '2129',
    Name: 'Engineer, project, PROFESSIONAL, AEROSPACE'
  },
  {
    Id: 2355,
    Code: '2129',
    Name: 'Engineer, project, PROFESSIONAL, OIL & NATURAL GAS'
  },
  { Id: 2356, Code: '2129', Name: 'Engineer, projects' },
  { Id: 2357, Code: '2129', Name: 'Engineer, projects, engineering' },
  { Id: 2358, Code: '2129', Name: 'Engineer, projects, manufacturing' },
  {
    Id: 2359,
    Code: '2129',
    Name: 'Engineer, projects, PROFESSIONAL, AEROSPACE'
  },
  {
    Id: 2360,
    Code: '2129',
    Name: 'Engineer, projects, PROFESSIONAL, OIL & NATURAL GAS'
  },
  { Id: 2361, Code: '2129', Name: 'Engineer, proposals, senior' },
  { Id: 2362, Code: '2129', Name: 'Engineer, refrigeration, PROFESSIONAL' },
  { Id: 2363, Code: '2129', Name: 'Engineer, scientific' },
  { Id: 2364, Code: '2129', Name: 'Engineer, supervising, government' },
  { Id: 2365, Code: '2129', Name: 'Engineer, technical' },
  { Id: 2366, Code: '2129', Name: 'Engineer, test, PROFESSIONAL' },
  {
    Id: 2367,
    Code: '2129',
    Name: 'Engineer, thermal and acoustic, PROFESSIONAL'
  },
  { Id: 2368, Code: '2129', Name: 'Engineer, thermal, PROFESSIONAL' },
  { Id: 2369, Code: '2129', Name: 'Engineer, traffic' },
  { Id: 2370, Code: '2129', Name: 'Engineer, traffic, air, PROFESSIONAL' },
  { Id: 2371, Code: '2129', Name: 'Engineer, transport, PROFESSIONAL' },
  { Id: 2372, Code: '2129', Name: 'Examiner, Board of Trade' },
  { Id: 2373, Code: '2129', Name: 'Examiner, Intellectual Property Office' },
  { Id: 2374, Code: '2129', Name: 'Inspector, district, government: MOD' },
  { Id: 2375, Code: '2129', Name: 'Inspector, divisional, government: MOD' },
  {
    Id: 2376,
    Code: '2129',
    Name: 'Inspector, engineering, Dept for Business Innovation and Skills'
  },
  { Id: 2377, Code: '2129', Name: 'Manager, contract, manufacturing' },
  { Id: 2378, Code: '2129', Name: 'Manager, contracts, manufacturing' },
  { Id: 2379, Code: '2129', Name: 'Manager, project, manufacturing' },
  { Id: 2380, Code: '2129', Name: 'Manager, sensory' },
  { Id: 2381, Code: '2129', Name: 'Metallographer' },
  { Id: 2382, Code: '2129', Name: 'Metallurgist' },
  { Id: 2383, Code: '2129', Name: 'Officer, carbonisation, coal mine' },
  { Id: 2384, Code: '2129', Name: 'Officer, communications, Home Office' },
  { Id: 2385, Code: '2129', Name: 'Officer, compliance, quality' },
  { Id: 2386, Code: '2129', Name: 'Officer, patent, government' },
  { Id: 2387, Code: '2129', Name: 'Officer, patents, government' },
  {
    Id: 2388,
    Code: '2129',
    Name: 'Officer, technical, carbonisation, coal mine'
  },
  { Id: 2389, Code: '2129', Name: 'Overseer, MOD' },
  { Id: 2390, Code: '2129', Name: 'Overseer, ship, MOD' },
  { Id: 2391, Code: '2129', Name: 'Researcher, PATENT' },
  { Id: 2392, Code: '2129', Name: 'Researcher, PATENTS' },
  { Id: 2393, Code: '2129', Name: 'Scientist, food' },
  { Id: 2394, Code: '2129', Name: 'Scientist, materials' },
  { Id: 2395, Code: '2129', Name: 'Technician, dyeing' },
  { Id: 2396, Code: '2129', Name: 'Technologist' },
  { Id: 2397, Code: '2129', Name: 'Technologist, food' },
  { Id: 2398, Code: '2129', Name: 'Technologist, scientific, coal mine' },
  { Id: 2399, Code: '2129', Name: 'Tribologist' },
  { Id: 2400, Code: '2133', Name: 'Manager, administration, computer' },
  { Id: 2401, Code: '2133', Name: 'Manager, applications, COMPUTING' },
  { Id: 2402, Code: '2133', Name: 'Manager, assurance, quality, systems' },
  { Id: 2403, Code: '2133', Name: 'Manager, CAD' },
  { Id: 2404, Code: '2133', Name: 'Manager, capture, data' },
  { Id: 2405, Code: '2133', Name: 'Manager, centre, data' },
  { Id: 2406, Code: '2133', Name: 'Manager, communications, COMPUTING' },
  { Id: 2407, Code: '2133', Name: 'Manager, communications, data' },
  { Id: 2408, Code: '2133', Name: 'Manager, computer' },
  { Id: 2409, Code: '2133', Name: 'Manager, computer services' },
  { Id: 2410, Code: '2133', Name: 'Manager, COMPUTING' },
  { Id: 2411, Code: '2133', Name: 'Manager, delivery, COMPUTING' },
  { Id: 2412, Code: '2133', Name: 'Manager, delivery, service' },
  { Id: 2413, Code: '2133', Name: 'Manager, delivery, service, IT' },
  { Id: 2414, Code: '2133', Name: 'Manager, delivery, telecommunications' },
  { Id: 2415, Code: '2133', Name: 'Manager, desk, help, computer services' },
  { Id: 2416, Code: '2133', Name: 'Manager, desk, help, COMPUTING' },
  { Id: 2417, Code: '2133', Name: 'Manager, development, COMPUTING' },
  { Id: 2418, Code: '2133', Name: 'Manager, development, IT' },
  { Id: 2419, Code: '2133', Name: 'Manager, development, software' },
  { Id: 2420, Code: '2133', Name: 'Manager, development, systems' },
  { Id: 2421, Code: '2133', Name: 'Manager, engineering, system, COMPUTING' },
  { Id: 2422, Code: '2133', Name: 'Manager, experience, user, COMPUTING' },
  { Id: 2423, Code: '2133', Name: 'Manager, field, telecommunications' },
  { Id: 2424, Code: '2133', Name: 'Manager, GIS, PROGRAMMING' },
  { Id: 2425, Code: '2133', Name: 'Manager, helpdesk, IT' },
  { Id: 2426, Code: '2133', Name: 'Manager, information' },
  { Id: 2427, Code: '2133', Name: 'Manager, information systems' },
  { Id: 2428, Code: '2133', Name: 'Manager, information, COMPUTING' },
  { Id: 2429, Code: '2133', Name: 'Manager, infrastructure' },
  { Id: 2430, Code: '2133', Name: 'Manager, installation, computer' },
  { Id: 2431, Code: '2133', Name: 'Manager, installation, systems' },
  { Id: 2432, Code: '2133', Name: 'Manager, internet' },
  { Id: 2433, Code: '2133', Name: 'Manager, intranet' },
  { Id: 2434, Code: '2133', Name: 'Manager, IT' },
  { Id: 2435, Code: '2133', Name: 'Manager, MIS' },
  { Id: 2436, Code: '2133', Name: 'Manager, network' },
  { Id: 2437, Code: '2133', Name: 'Manager, networking' },
  { Id: 2438, Code: '2133', Name: 'Manager, operations, computer' },
  { Id: 2439, Code: '2133', Name: 'Manager, operations, computer services' },
  {
    Id: 2440,
    Code: '2133',
    Name: 'Manager, operations, network, computer services'
  },
  {
    Id: 2441,
    Code: '2133',
    Name: 'Manager, operations, telecommunications services'
  },
  { Id: 2442, Code: '2133', Name: 'Manager, processing, data' },
  { Id: 2443, Code: '2133', Name: 'Manager, product, computer services' },
  { Id: 2444, Code: '2133', Name: 'Manager, production, COMPUTING' },
  { Id: 2445, Code: '2133', Name: 'Manager, production, digital' },
  { Id: 2446, Code: '2133', Name: 'Manager, programming' },
  { Id: 2447, Code: '2133', Name: 'Manager, quality, COMPUTING' },
  { Id: 2448, Code: '2133', Name: 'Manager, service, computer' },
  { Id: 2449, Code: '2133', Name: 'Manager, service, COMPUTING' },
  { Id: 2450, Code: '2133', Name: 'Manager, service, digital' },
  { Id: 2451, Code: '2133', Name: 'Manager, service, IT' },
  { Id: 2452, Code: '2133', Name: 'Manager, services, client, COMPUTING' },
  { Id: 2453, Code: '2133', Name: 'Manager, services, computer' },
  { Id: 2454, Code: '2133', Name: 'Manager, services, COMPUTING' },
  { Id: 2455, Code: '2133', Name: 'Manager, services, IT' },
  { Id: 2456, Code: '2133', Name: 'Manager, services, network' },
  { Id: 2457, Code: '2133', Name: 'Manager, software' },
  { Id: 2458, Code: '2133', Name: 'Manager, software company' },
  { Id: 2459, Code: '2133', Name: 'Manager, support, COMPUTING' },
  { Id: 2460, Code: '2133', Name: 'Manager, support, desktop' },
  { Id: 2461, Code: '2133', Name: 'Manager, support, IT' },
  { Id: 2462, Code: '2133', Name: 'Manager, support, PC' },
  { Id: 2463, Code: '2133', Name: 'Manager, support, systems' },
  { Id: 2464, Code: '2133', Name: 'Manager, support, technical, COMPUTING' },
  { Id: 2465, Code: '2133', Name: 'Manager, system, network, computer' },
  { Id: 2466, Code: '2133', Name: 'Manager, systems' },
  { Id: 2467, Code: '2133', Name: 'Manager, systems, business' },
  { Id: 2468, Code: '2133', Name: 'Manager, systems, information' },
  { Id: 2469, Code: '2133', Name: 'Manager, systems, IT' },
  { Id: 2470, Code: '2133', Name: 'Manager, team, COMPUTING' },
  { Id: 2471, Code: '2133', Name: 'Manager, technical, computer' },
  { Id: 2472, Code: '2133', Name: 'Manager, technical, COMPUTING' },
  { Id: 2473, Code: '2133', Name: 'Manager, technology, information' },
  { Id: 2474, Code: '2133', Name: 'Manager, Telecom, British' },
  { Id: 2475, Code: '2133', Name: 'Manager, telecommunications' },
  { Id: 2476, Code: '2133', Name: 'Manager, telecommunications services' },
  { Id: 2477, Code: '2133', Name: 'Manager, telecoms' },
  { Id: 2478, Code: '2133', Name: 'Manager, test, COMPUTING' },
  { Id: 2479, Code: '2133', Name: 'Manager, testing, COMPUTING' },
  { Id: 2480, Code: '2133', Name: 'Manager, transmission, COMPUTING' },
  { Id: 2481, Code: '2133', Name: 'Manager, UX, COMPUTING' },
  { Id: 2482, Code: '2133', Name: 'Manager, validation, COMPUTING' },
  { Id: 2483, Code: '2133', Name: 'Manager, visualisation, data' },
  { Id: 2484, Code: '2133', Name: 'Manager, warehouse, data' },
  { Id: 2485, Code: '2134', Name: 'Leader, project, software' },
  { Id: 2486, Code: '2134', Name: 'Leader, project, software design' },
  { Id: 2487, Code: '2134', Name: 'Manager, change, COMPUTING' },
  { Id: 2488, Code: '2134', Name: 'Manager, change, IT' },
  { Id: 2489, Code: '2134', Name: 'Manager, change, telecommunications' },
  { Id: 2490, Code: '2134', Name: 'Manager, delivery, project, COMPUTING' },
  { Id: 2491, Code: '2134', Name: 'Manager, implementation, COMPUTING' },
  { Id: 2492, Code: '2134', Name: 'Manager, programme, COMPUTING' },
  { Id: 2493, Code: '2134', Name: 'Manager, programme, telecommunications' },
  { Id: 2494, Code: '2134', Name: 'Manager, project, COMPUTING' },
  { Id: 2495, Code: '2134', Name: 'Manager, project, development, software' },
  { Id: 2496, Code: '2134', Name: 'Manager, project, digital' },
  { Id: 2497, Code: '2134', Name: 'Manager, project, IT' },
  { Id: 2498, Code: '2134', Name: 'Manager, project, technical' },
  { Id: 2499, Code: '2134', Name: 'Manager, project, telecommunications' },
  { Id: 2500, Code: '2134', Name: 'Manager, projects, IT' },
  { Id: 2501, Code: '2134', Name: 'Officer, projects, computer' },
  { Id: 2502, Code: '2134', Name: 'Planner, IP' },
  { Id: 2503, Code: '2135', Name: 'Accountant, systems' },
  { Id: 2504, Code: '2135', Name: 'Adviser, systems' },
  { Id: 2505, Code: '2135', Name: 'Analyst, business, COMPUTING' },
  { Id: 2506, Code: '2135', Name: 'Analyst, business, IT' },
  { Id: 2507, Code: '2135', Name: 'Analyst, business, technical' },
  { Id: 2508, Code: '2135', Name: 'Analyst, comms, data' },
  { Id: 2509, Code: '2135', Name: 'Analyst, comms, technical' },
  { Id: 2510, Code: '2135', Name: 'Analyst, communications, data' },
  { Id: 2511, Code: '2135', Name: 'Analyst, computer' },
  {
    Id: 2512,
    Code: '2135',
    Name: 'Analyst, maintenance, COMPUTING: PROFESSIONAL'
  },
  { Id: 2513, Code: '2135', Name: 'Analyst, operations, COMPUTING' },
  { Id: 2514, Code: '2135', Name: 'Analyst, system' },
  { Id: 2515, Code: '2135', Name: 'Analyst, systems' },
  { Id: 2516, Code: '2135', Name: 'Analyst, technical, COMPUTING' },
  { Id: 2517, Code: '2135', Name: 'Analyst, telecommunications' },
  { Id: 2518, Code: '2135', Name: 'Analyst, voice, communications' },
  { Id: 2519, Code: '2135', Name: 'Analyst, warehouse, data' },
  { Id: 2520, Code: '2135', Name: 'Architect, chain, supply' },
  { Id: 2521, Code: '2135', Name: 'Architect, cloud' },
  { Id: 2522, Code: '2135', Name: 'Architect, COMPUTING' },
  { Id: 2523, Code: '2135', Name: 'Architect, data' },
  { Id: 2524, Code: '2135', Name: 'Architect, database' },
  { Id: 2525, Code: '2135', Name: 'Architect, datawarehouse' },
  { Id: 2526, Code: '2135', Name: 'Architect, digital' },
  { Id: 2527, Code: '2135', Name: 'Architect, domain' },
  { Id: 2528, Code: '2135', Name: 'Architect, enterprise' },
  { Id: 2529, Code: '2135', Name: 'Architect, information' },
  { Id: 2530, Code: '2135', Name: 'Architect, infrastructure' },
  { Id: 2531, Code: '2135', Name: 'Architect, integration' },
  { Id: 2532, Code: '2135', Name: 'Architect, IT' },
  { Id: 2533, Code: '2135', Name: 'Architect, network' },
  { Id: 2534, Code: '2135', Name: 'Architect, process' },
  { Id: 2535, Code: '2135', Name: 'Architect, product' },
  { Id: 2536, Code: '2135', Name: 'Architect, services, COMPUTING' },
  { Id: 2537, Code: '2135', Name: 'Architect, solution' },
  { Id: 2538, Code: '2135', Name: 'Architect, solution, customer' },
  { Id: 2539, Code: '2135', Name: 'Architect, solution, IT' },
  { Id: 2540, Code: '2135', Name: 'Architect, solutions' },
  { Id: 2541, Code: '2135', Name: 'Architect, solutions, customer' },
  { Id: 2542, Code: '2135', Name: 'Architect, solutions, IT' },
  { Id: 2543, Code: '2135', Name: 'Architect, strategic' },
  { Id: 2544, Code: '2135', Name: 'Architect, systems, COMPUTING' },
  { Id: 2545, Code: '2135', Name: 'Architect, technical' },
  { Id: 2546, Code: '2135', Name: 'Architect, technology, SAP' },
  { Id: 2547, Code: '2135', Name: 'Architect, web' },
  { Id: 2548, Code: '2135', Name: 'Consultant, ERP' },
  { Id: 2549, Code: '2135', Name: 'Consultant, systems' },
  { Id: 2550, Code: '2135', Name: 'Designer, app' },
  { Id: 2551, Code: '2135', Name: 'Designer, applications, COMPUTING' },
  { Id: 2552, Code: '2135', Name: 'Designer, computer' },
  { Id: 2553, Code: '2135', Name: 'Designer, database' },
  { Id: 2554, Code: '2135', Name: 'Designer, systems' },
  { Id: 2555, Code: '2135', Name: 'Engineer, cloud' },
  { Id: 2556, Code: '2135', Name: 'Engineer, computer, DESIGN' },
  { Id: 2557, Code: '2135', Name: 'Engineer, data, big' },
  { Id: 2558, Code: '2135', Name: 'Engineer, design, computer' },
  { Id: 2559, Code: '2135', Name: 'Engineer, infrastructure, COMPUTING' },
  { Id: 2560, Code: '2135', Name: 'Engineer, infrastructure, IT' },
  { Id: 2561, Code: '2135', Name: 'Engineer, systems' },
  { Id: 2562, Code: '2135', Name: 'Integrator, systems, COMPUTING' },
  { Id: 2563, Code: '2135', Name: 'Officer, development, systems' },
  { Id: 2564, Code: '2135', Name: 'Planner, technology, information' },
  { Id: 2565, Code: '2135', Name: 'Scientist, computer' },
  { Id: 2566, Code: '2135', Name: 'Scientist, data, COMPUTING' },
  { Id: 2567, Code: '2135', Name: 'Specialist, multimedia' },
  { Id: 2568, Code: '2135', Name: 'Specialist, services, cloud' },
  { Id: 2569, Code: '2135', Name: 'Specialist, systems' },
  { Id: 2570, Code: '2135', Name: 'Specialist, technical, computer' },
  { Id: 2571, Code: '2135', Name: 'Specialist, technical, computer services' },
  { Id: 2572, Code: '2135', Name: 'Specialist, visualisation, data' },
  { Id: 2573, Code: '2135', Name: 'Strategist, IT' },
  { Id: 2574, Code: '2135', Name: 'Writer, technical, COMPUTING' },
  { Id: 2575, Code: '2136', Name: 'Analyst, data, COMPUTING' },
  { Id: 2576, Code: '2136', Name: 'Analyst, database' },
  { Id: 2577, Code: '2136', Name: 'Analyst, implementation' },
  { Id: 2578, Code: '2136', Name: 'Analyst, information, COMPUTING' },
  { Id: 2579, Code: '2136', Name: 'Analyst, IT' },
  { Id: 2580, Code: '2136', Name: 'Analyst, PROGRAMMING' },
  { Id: 2581, Code: '2136', Name: 'Analyst, project, IT' },
  { Id: 2582, Code: '2136', Name: 'Analyst, software' },
  { Id: 2583, Code: '2136', Name: 'Analyst, technology' },
  { Id: 2584, Code: '2136', Name: 'Analyst, technology, information' },
  { Id: 2585, Code: '2136', Name: 'Analyst-programmer' },
  { Id: 2586, Code: '2136', Name: 'Architect, interface, user' },
  { Id: 2587, Code: '2136', Name: 'Architect, software' },
  { Id: 2588, Code: '2136', Name: 'Artist, lead, GAMES DEVELOPMENT' },
  { Id: 2589, Code: '2136', Name: 'Author, software' },
  { Id: 2590, Code: '2136', Name: 'Author, software, application' },
  { Id: 2591, Code: '2136', Name: 'Author, technical, software' },
  { Id: 2592, Code: '2136', Name: 'Consultant, software' },
  { Id: 2593, Code: '2136', Name: 'Consultant, support, software' },
  { Id: 2594, Code: '2136', Name: 'Consultant, technical, software design' },
  { Id: 2595, Code: '2136', Name: 'Designer, games, computer' },
  { Id: 2596, Code: '2136', Name: 'Designer, interaction' },
  { Id: 2597, Code: '2136', Name: 'Designer, interactive' },
  { Id: 2598, Code: '2136', Name: 'Designer, software' },
  { Id: 2599, Code: '2136', Name: 'Developer, android' },
  { Id: 2600, Code: '2136', Name: 'Developer, app, mobile' },
  { Id: 2601, Code: '2136', Name: 'Developer, application' },
  { Id: 2602, Code: '2136', Name: 'Developer, application, mobile' },
  { Id: 2603, Code: '2136', Name: 'Developer, applications' },
  { Id: 2604, Code: '2136', Name: 'Developer, applications, mobile' },
  { Id: 2605, Code: '2136', Name: 'Developer, apps, mobile' },
  { Id: 2606, Code: '2136', Name: 'Developer, computer' },
  { Id: 2607, Code: '2136', Name: 'Developer, COMPUTING' },
  { Id: 2608, Code: '2136', Name: 'Developer, database' },
  { Id: 2609, Code: '2136', Name: 'Developer, end, front, SOFTWARE' },
  { Id: 2610, Code: '2136', Name: 'Developer, games' },
  { Id: 2611, Code: '2136', Name: 'Developer, intelligence, business' },
  { Id: 2612, Code: '2136', Name: 'Developer, interface, user' },
  { Id: 2613, Code: '2136', Name: 'Developer, iOS' },
  { Id: 2614, Code: '2136', Name: 'Developer, IT' },
  { Id: 2615, Code: '2136', Name: 'Developer, JavaScript' },
  { Id: 2616, Code: '2136', Name: 'Developer, mobile' },
  { Id: 2617, Code: '2136', Name: 'Developer, net' },
  { Id: 2618, Code: '2136', Name: 'Developer, Python' },
  { Id: 2619, Code: '2136', Name: 'Developer, Scala' },
  { Id: 2620, Code: '2136', Name: 'Developer, SharePoint' },
  { Id: 2621, Code: '2136', Name: 'Developer, software' },
  { Id: 2622, Code: '2136', Name: 'Developer, SQL' },
  { Id: 2623, Code: '2136', Name: 'Developer, stack, full' },
  { Id: 2624, Code: '2136', Name: 'Developer, systems' },
  { Id: 2625, Code: '2136', Name: 'Developer, UI' },
  { Id: 2626, Code: '2136', Name: 'Developer, Xamarin' },
  { Id: 2627, Code: '2136', Name: 'Engineer, design, software' },
  { Id: 2628, Code: '2136', Name: 'Engineer, development, software' },
  { Id: 2629, Code: '2136', Name: 'Engineer, PROFESSIONAL, SOFTWARE' },
  { Id: 2630, Code: '2136', Name: 'Engineer, software' },
  { Id: 2631, Code: '2136', Name: 'Engineer, software, interactive' },
  { Id: 2632, Code: '2136', Name: 'Engineer, software, principal' },
  { Id: 2633, Code: '2136', Name: 'Engineer, software, senior' },
  { Id: 2634, Code: '2136', Name: 'Engineer, support, software' },
  {
    Id: 2635,
    Code: '2136',
    Name: 'Leader, team, analyst, COMPUTING, PROGRAMMING'
  },
  { Id: 2636, Code: '2136', Name: 'Leader, team, programming' },
  { Id: 2637, Code: '2136', Name: 'Leader, team, software, COMPUTING' },
  { Id: 2638, Code: '2136', Name: 'Officer, development, software' },
  { Id: 2639, Code: '2136', Name: 'Programmer' },
  { Id: 2640, Code: '2136', Name: 'Programmer, analyst, computer' },
  { Id: 2641, Code: '2136', Name: 'Programmer, applications' },
  { Id: 2642, Code: '2136', Name: 'Programmer, computer' },
  { Id: 2643, Code: '2136', Name: 'Programmer, database' },
  { Id: 2644, Code: '2136', Name: 'Programmer, games' },
  { Id: 2645, Code: '2136', Name: 'Programmer, software' },
  { Id: 2646, Code: '2136', Name: 'Programmer, systems' },
  { Id: 2647, Code: '2136', Name: 'Programmer-analyst' },
  { Id: 2648, Code: '2136', Name: 'Specialist, software' },
  { Id: 2649, Code: '2136', Name: 'Writer, shader' },
  { Id: 2650, Code: '2136', Name: 'Writer, software' },
  { Id: 2651, Code: '2137', Name: 'Architect, experience, user' },
  { Id: 2652, Code: '2137', Name: 'Consultant, design, web' },
  { Id: 2653, Code: '2137', Name: 'Consultant, experience, user' },
  { Id: 2654, Code: '2137', Name: 'Consultant, internet' },
  { Id: 2655, Code: '2137', Name: 'Creator, content' },
  { Id: 2656, Code: '2137', Name: 'Designer, app, web' },
  { Id: 2657, Code: '2137', Name: 'Designer, application, web' },
  { Id: 2658, Code: '2137', Name: 'Designer, applications, web' },
  { Id: 2659, Code: '2137', Name: 'Designer, experience, user, COMPUTING' },
  { Id: 2660, Code: '2137', Name: 'Designer, interface, user' },
  { Id: 2661, Code: '2137', Name: 'Designer, media, interactive' },
  { Id: 2662, Code: '2137', Name: 'Designer, media, new' },
  { Id: 2663, Code: '2137', Name: 'Designer, UI' },
  { Id: 2664, Code: '2137', Name: 'Designer, UX, COMPUTING' },
  { Id: 2665, Code: '2137', Name: 'Designer, visual' },
  { Id: 2666, Code: '2137', Name: 'Designer, web' },
  { Id: 2667, Code: '2137', Name: 'Designer, website' },
  { Id: 2668, Code: '2137', Name: 'Developer, app, web' },
  { Id: 2669, Code: '2137', Name: 'Developer, application, web' },
  { Id: 2670, Code: '2137', Name: 'Developer, applications, web' },
  { Id: 2671, Code: '2137', Name: 'Developer, Drupal' },
  { Id: 2672, Code: '2137', Name: 'Developer, e-learning' },
  { Id: 2673, Code: '2137', Name: 'Developer, internet' },
  { Id: 2674, Code: '2137', Name: 'Developer, multimedia' },
  { Id: 2675, Code: '2137', Name: 'Developer, site, web' },
  { Id: 2676, Code: '2137', Name: 'Developer, web' },
  { Id: 2677, Code: '2137', Name: 'Developer, web, end, front' },
  { Id: 2678, Code: '2137', Name: 'Developer, web, technical' },
  { Id: 2679, Code: '2137', Name: 'Editor, content, digital' },
  { Id: 2680, Code: '2137', Name: 'Editor, content, online' },
  { Id: 2681, Code: '2137', Name: 'Editor, content, web' },
  { Id: 2682, Code: '2137', Name: 'Editor, content, WEBSITE' },
  { Id: 2683, Code: '2137', Name: 'Editor, web' },
  { Id: 2684, Code: '2137', Name: 'Engineer, hosting, web' },
  { Id: 2685, Code: '2137', Name: 'Executive, communications, web' },
  { Id: 2686, Code: '2137', Name: 'Executive, content' },
  { Id: 2687, Code: '2137', Name: 'Manager, content, digital' },
  { Id: 2688, Code: '2137', Name: 'Manager, content, web' },
  { Id: 2689, Code: '2137', Name: 'Manager, content, website' },
  { Id: 2690, Code: '2137', Name: 'Manager, site, web' },
  { Id: 2691, Code: '2137', Name: 'Manager, web' },
  { Id: 2692, Code: '2137', Name: 'Manager, website' },
  { Id: 2693, Code: '2137', Name: 'Master, web' },
  { Id: 2694, Code: '2137', Name: 'Officer, content' },
  { Id: 2695, Code: '2137', Name: 'Officer, development, web' },
  { Id: 2696, Code: '2137', Name: 'Producer, content, digital' },
  { Id: 2697, Code: '2137', Name: 'Producer, digital' },
  { Id: 2698, Code: '2137', Name: 'Producer, web' },
  { Id: 2699, Code: '2137', Name: 'Publisher, desktop' },
  { Id: 2700, Code: '2137', Name: 'Publisher, top, desk' },
  { Id: 2701, Code: '2137', Name: 'Researcher, experience, user' },
  { Id: 2702, Code: '2137', Name: 'Researcher, UX' },
  { Id: 2703, Code: '2137', Name: 'Technician, web' },
  { Id: 2704, Code: '2137', Name: 'Webmaster' },
  { Id: 2705, Code: '2137', Name: 'Webmaster, online, education' },
  { Id: 2706, Code: '2137', Name: 'Writer, content, DIGITAL' },
  { Id: 2707, Code: '2139', Name: 'Analyst, network' },
  { Id: 2708, Code: '2139', Name: 'Analyst, quality, COMPUTING' },
  { Id: 2709, Code: '2139', Name: 'Analyst, risk, IT' },
  { Id: 2710, Code: '2139', Name: 'Analyst, security, cyber' },
  { Id: 2711, Code: '2139', Name: 'Analyst, security, information' },
  { Id: 2712, Code: '2139', Name: 'Analyst, security, IT' },
  { Id: 2713, Code: '2139', Name: 'Analyst, test, automation' },
  { Id: 2714, Code: '2139', Name: 'Analyst, test, COMPUTING' },
  { Id: 2715, Code: '2139', Name: 'Analyst, test, software' },
  { Id: 2716, Code: '2139', Name: 'Auditor, IT' },
  { Id: 2717, Code: '2139', Name: 'Consultant, application' },
  { Id: 2718, Code: '2139', Name: 'Consultant, applications' },
  { Id: 2719, Code: '2139', Name: 'Consultant, business, IT' },
  { Id: 2720, Code: '2139', Name: 'Consultant, communications' },
  { Id: 2721, Code: '2139', Name: 'Consultant, computer' },
  { Id: 2722, Code: '2139', Name: 'Consultant, COMPUTING' },
  { Id: 2723, Code: '2139', Name: 'Consultant, data' },
  { Id: 2724, Code: '2139', Name: 'Consultant, digital' },
  { Id: 2725, Code: '2139', Name: 'Consultant, IT' },
  { Id: 2726, Code: '2139', Name: 'Consultant, network' },
  { Id: 2727, Code: '2139', Name: 'Consultant, SAP' },
  { Id: 2728, Code: '2139', Name: 'Consultant, security, computer' },
  { Id: 2729, Code: '2139', Name: 'Consultant, security, cyber' },
  { Id: 2730, Code: '2139', Name: 'Consultant, security, information' },
  { Id: 2731, Code: '2139', Name: 'Consultant, security, IT' },
  { Id: 2732, Code: '2139', Name: 'Consultant, security, technical' },
  { Id: 2733, Code: '2139', Name: 'Consultant, services, network' },
  { Id: 2734, Code: '2139', Name: 'Consultant, support, technical' },
  { Id: 2735, Code: '2139', Name: 'Consultant, technical, computer' },
  { Id: 2736, Code: '2139', Name: 'Consultant, technical, computer services' },
  { Id: 2737, Code: '2139', Name: 'Consultant, technology, information' },
  { Id: 2738, Code: '2139', Name: 'Consultant, telecommunications' },
  { Id: 2739, Code: '2139', Name: 'Consultant, telecoms' },
  { Id: 2740, Code: '2139', Name: 'Designer, network' },
  { Id: 2741, Code: '2139', Name: 'Engineer, design, network, COMPUTING' },
  {
    Id: 2742,
    Code: '2139',
    Name: 'Engineer, installation, network, COMPUTING'
  },
  { Id: 2743, Code: '2139', Name: 'Engineer, integration, network' },
  { Id: 2744, Code: '2139', Name: 'Engineer, internet, PROFESSIONAL' },
  { Id: 2745, Code: '2139', Name: 'Engineer, network' },
  { Id: 2746, Code: '2139', Name: 'Engineer, project, telecommunications' },
  { Id: 2747, Code: '2139', Name: 'Engineer, projects, telecommunications' },
  { Id: 2748, Code: '2139', Name: 'Engineer, security, network' },
  { Id: 2749, Code: '2139', Name: 'Engineer, systems, network' },
  { Id: 2750, Code: '2139', Name: 'Engineer, test, PROFESSIONAL, SOFTWARE' },
  { Id: 2751, Code: '2139', Name: 'Engineer, test, software' },
  { Id: 2752, Code: '2139', Name: 'Evangelist, technical' },
  { Id: 2753, Code: '2139', Name: 'Evangelist, technology' },
  { Id: 2754, Code: '2139', Name: 'Facilitator, online' },
  { Id: 2755, Code: '2139', Name: 'Facilitator, on-line' },
  { Id: 2756, Code: '2139', Name: 'Hacker, ethical' },
  { Id: 2757, Code: '2139', Name: 'Manager, account, COMPUTING' },
  { Id: 2758, Code: '2139', Name: 'Manager, security, computer services' },
  { Id: 2759, Code: '2139', Name: 'Manager, security, data' },
  { Id: 2760, Code: '2139', Name: 'Manager, security, information' },
  { Id: 2761, Code: '2139', Name: 'Manager, security, IT' },
  { Id: 2762, Code: '2139', Name: 'Officer, security, information' },
  { Id: 2763, Code: '2139', Name: 'Planner, IT' },
  { Id: 2764, Code: '2139', Name: 'Planner, network' },
  { Id: 2765, Code: '2139', Name: 'Planner, telecommunications' },
  { Id: 2766, Code: '2139', Name: 'Specialist, network' },
  { Id: 2767, Code: '2139', Name: 'Tester, penetration' },
  { Id: 2768, Code: '2139', Name: 'Tester, software' },
  { Id: 2769, Code: '2139', Name: 'Tester, systems, COMPUTING' },
  { Id: 2770, Code: '2141', Name: 'Adviser, conservation' },
  { Id: 2771, Code: '2141', Name: 'Adviser, countryside' },
  { Id: 2772, Code: '2141', Name: 'Adviser, management, land' },
  { Id: 2773, Code: '2141', Name: 'Conservationist' },
  { Id: 2774, Code: '2141', Name: 'Conservationist, marine' },
  { Id: 2775, Code: '2141', Name: 'Conservationist, nature, coastal' },
  { Id: 2776, Code: '2141', Name: 'Consultant, ecological' },
  { Id: 2777, Code: '2141', Name: 'Ecologist' },
  { Id: 2778, Code: '2141', Name: 'Ecologist, marine' },
  { Id: 2779, Code: '2141', Name: 'Inspector, buildings, historic' },
  { Id: 2780, Code: '2141', Name: 'Inspector, HISTORIC BUILDINGS' },
  { Id: 2781, Code: '2141', Name: 'Interpreter, heritage, CONSERVATION' },
  { Id: 2782, Code: '2141', Name: 'Manager, conservation' },
  { Id: 2783, Code: '2141', Name: 'Manager, countryside' },
  { Id: 2784, Code: '2141', Name: 'Manager, heritage' },
  { Id: 2785, Code: '2141', Name: 'Manager, park, national' },
  { Id: 2786, Code: '2141', Name: 'Officer, access, countryside' },
  {
    Id: 2787,
    Code: '2141',
    Name: 'Officer, access, local government, CONSERVATION'
  },
  { Id: 2788, Code: '2141', Name: 'Officer, conservancy' },
  { Id: 2789, Code: '2141', Name: 'Officer, conservation' },
  {
    Id: 2790,
    Code: '2141',
    Name: 'Officer, conservation, assistant, government'
  },
  { Id: 2791, Code: '2141', Name: 'Officer, conservation, chief, government' },
  { Id: 2792, Code: '2141', Name: 'Officer, conservation, energy' },
  { Id: 2793, Code: '2141', Name: 'Officer, conservation, government' },
  { Id: 2794, Code: '2141', Name: 'Officer, conservation, senior, government' },
  { Id: 2795, Code: '2141', Name: 'Officer, conservation, water' },
  { Id: 2796, Code: '2141', Name: 'Officer, countryside' },
  { Id: 2797, Code: '2141', Name: 'Officer, development, rural' },
  { Id: 2798, Code: '2141', Name: 'Officer, district, Forestry Commission' },
  { Id: 2799, Code: '2141', Name: 'Officer, divisional, Forestry Commission' },
  { Id: 2800, Code: '2141', Name: 'Officer, ecology' },
  { Id: 2801, Code: '2141', Name: 'Officer, field, CONSERVATION' },
  { Id: 2802, Code: '2141', Name: 'Officer, heritage' },
  { Id: 2803, Code: '2141', Name: 'Officer, park, national' },
  { Id: 2804, Code: '2141', Name: 'Scientist, landscape' },
  { Id: 2805, Code: '2141', Name: 'Surveyor, field, biological' },
  { Id: 2806, Code: '2142', Name: 'Chemist, environmental' },
  { Id: 2807, Code: '2142', Name: 'Consultant, environmental' },
  { Id: 2808, Code: '2142', Name: 'Engineer, environmental' },
  { Id: 2809, Code: '2142', Name: 'Engineer, geoenvironmental' },
  { Id: 2810, Code: '2142', Name: 'Engineer, land, contaminated' },
  { Id: 2811, Code: '2142', Name: 'Engineer, landfill' },
  { Id: 2812, Code: '2142', Name: 'Engineer, PROFESSIONAL, ENVIRONMENTAL' },
  { Id: 2813, Code: '2142', Name: 'Environmentalist' },
  { Id: 2814, Code: '2142', Name: 'Manager, energy' },
  { Id: 2815, Code: '2142', Name: 'Manager, Environment Agency' },
  { Id: 2816, Code: '2142', Name: 'Manager, environmental' },
  { Id: 2817, Code: '2142', Name: 'Manager, environmental consultancy' },
  { Id: 2818, Code: '2142', Name: 'Manager, risk, flood' },
  { Id: 2819, Code: '2142', Name: 'Manager, safety and environmental, health' },
  { Id: 2820, Code: '2142', Name: 'Manager, warning, flood' },
  { Id: 2821, Code: '2142', Name: 'Officer, carbon and sustainability' },
  { Id: 2822, Code: '2142', Name: 'Officer, defence, flood' },
  { Id: 2823, Code: '2142', Name: 'Officer, education, environmental' },
  { Id: 2824, Code: '2142', Name: 'Officer, environmental' },
  { Id: 2825, Code: '2142', Name: 'Officer, interpretation' },
  { Id: 2826, Code: '2142', Name: 'Officer, interpretative' },
  { Id: 2827, Code: '2142', Name: 'Officer, protection, environmental' },
  { Id: 2828, Code: '2142', Name: 'Officer, sustainability' },
  { Id: 2829, Code: '2142', Name: 'Officer, warning, flood' },
  { Id: 2830, Code: '2142', Name: 'Scientist, environmental' },
  { Id: 2831, Code: '2142', Name: 'Scientist, quality, water' },
  { Id: 2832, Code: '2142', Name: 'Specialist, geoenvironmental' },
  { Id: 2833, Code: '2142', Name: 'Specialist, land, contaminated' },
  { Id: 2834, Code: '2150', Name: 'Head, research and development' },
  { Id: 2835, Code: '2150', Name: 'Manager of field trials, NIAB' },
  { Id: 2836, Code: '2150', Name: 'Manager, analytics' },
  {
    Id: 2837,
    Code: '2150',
    Name: 'Manager, creative, research and development'
  },
  { Id: 2838, Code: '2150', Name: 'Manager, design' },
  {
    Id: 2839,
    Code: '2150',
    Name: 'Manager, development, process, manufacturing'
  },
  {
    Id: 2840,
    Code: '2150',
    Name: 'Manager, development, research and development'
  },
  {
    Id: 2841,
    Code: '2150',
    Name: 'Manager, information, research and development'
  },
  { Id: 2842, Code: '2150', Name: 'Manager, insights' },
  { Id: 2843, Code: '2150', Name: 'Manager, insights, consumer' },
  { Id: 2844, Code: '2150', Name: 'Manager, laboratory' },
  { Id: 2845, Code: '2150', Name: 'Manager, link, passenger, government' },
  {
    Id: 2846,
    Code: '2150',
    Name: 'Manager, product, research and development'
  },
  {
    Id: 2847,
    Code: '2150',
    Name: 'Manager, project, research and development'
  },
  { Id: 2848, Code: '2150', Name: 'Manager, research' },
  { Id: 2849, Code: '2150', Name: 'Manager, research and development' },
  { Id: 2850, Code: '2150', Name: 'Manager, research, broadcasting' },
  {
    Id: 2851,
    Code: '2150',
    Name: 'Manager, research, ENGINEERING, ELECTRICAL'
  },
  {
    Id: 2852,
    Code: '2150',
    Name: 'Manager, research, ENGINEERING, ELECTRONIC'
  },
  {
    Id: 2853,
    Code: '2150',
    Name: 'Manager, research, ENGINEERING, MECHANICAL'
  },
  { Id: 2854, Code: '2150', Name: 'Manager, research, government' },
  { Id: 2855, Code: '2150', Name: 'Manager, research, HISTORICAL' },
  { Id: 2856, Code: '2150', Name: 'Manager, research, journalism' },
  { Id: 2857, Code: '2150', Name: 'Manager, research, market' },
  {
    Id: 2858,
    Code: '2150',
    Name: 'Manager, research, printing and publishing'
  },
  { Id: 2859, Code: '2150', Name: 'Manager, RM&G' },
  { Id: 2860, Code: '2150', Name: 'Manager, services, forensic' },
  { Id: 2861, Code: '2150', Name: 'Manager, study, clinical' },
  {
    Id: 2862,
    Code: '2150',
    Name: 'Manager, technical, research and development'
  },
  { Id: 2863, Code: '2150', Name: 'Manager, trial, clinical' },
  { Id: 2864, Code: '2150', Name: 'Manager, trials, clinical' },
  { Id: 2865, Code: '2150', Name: 'Manager, tunnel, wind' },
  { Id: 2866, Code: '2211', Name: 'Adviser, medical' },
  { Id: 2867, Code: '2211', Name: 'Anaesthetist' },
  { Id: 2868, Code: '2211', Name: 'Anaesthetist, consultant' },
  { Id: 2869, Code: '2211', Name: 'Assistant, clinical, QUALIFIED' },
  { Id: 2870, Code: '2211', Name: 'Cardiologist' },
  {
    Id: 2871,
    Code: '2211',
    Name: 'Consultant in public health medicine, health authority'
  },
  { Id: 2872, Code: '2211', Name: 'Consultant, haematology' },
  { Id: 2873, Code: '2211', Name: 'Consultant, hospital' },
  { Id: 2874, Code: '2211', Name: 'Consultant, hospital service' },
  { Id: 2875, Code: '2211', Name: 'Consultant, medical' },
  { Id: 2876, Code: '2211', Name: 'Dermatologist' },
  { Id: 2877, Code: '2211', Name: 'Doctor' },
  { Id: 2878, Code: '2211', Name: 'Doctor of medicine' },
  { Id: 2879, Code: '2211', Name: 'Doctor, health, public' },
  { Id: 2880, Code: '2211', Name: 'Doctor, hospital' },
  { Id: 2881, Code: '2211', Name: 'Doctor, medical' },
  { Id: 2882, Code: '2211', Name: 'Examiner, medical, forensic' },
  { Id: 2883, Code: '2211', Name: 'Examiner, medical, Jobcentre Plus' },
  { Id: 2884, Code: '2211', Name: 'Fellow, MEDICINE' },
  { Id: 2885, Code: '2211', Name: 'Fellow, research, clinical' },
  { Id: 2886, Code: '2211', Name: 'Fellow, research, medicine' },
  { Id: 2887, Code: '2211', Name: 'Fellow, RESEARCH, SURGERY' },
  { Id: 2888, Code: '2211', Name: 'Fellow, SURGERY' },
  { Id: 2889, Code: '2211', Name: 'Fellow, university:, MEDICINE' },
  { Id: 2890, Code: '2211', Name: 'Fellow, university:, SURGERY' },
  { Id: 2891, Code: '2211', Name: 'FRCOG' },
  { Id: 2892, Code: '2211', Name: 'FRCP' },
  { Id: 2893, Code: '2211', Name: 'FRCS' },
  { Id: 2894, Code: '2211', Name: 'Gastroenterologist' },
  { Id: 2895, Code: '2211', Name: 'Geriatrician' },
  { Id: 2896, Code: '2211', Name: 'GP' },
  { Id: 2897, Code: '2211', Name: 'Gynaecologist' },
  { Id: 2898, Code: '2211', Name: 'Homeopath, MEDICALLY QUALIFIED' },
  { Id: 2899, Code: '2211', Name: 'Homoeopath, MEDICALLY QUALIFIED' },
  { Id: 2900, Code: '2211', Name: 'Laryngologist' },
  { Id: 2901, Code: '2211', Name: 'Lecturer, MEDICINE' },
  { Id: 2902, Code: '2211', Name: 'Lecturer, SURGERY' },
  { Id: 2903, Code: '2211', Name: 'LRCP' },
  { Id: 2904, Code: '2211', Name: 'MRCP' },
  { Id: 2905, Code: '2211', Name: 'MRCS' },
  { Id: 2906, Code: '2211', Name: 'Nephrologist, consultant' },
  { Id: 2907, Code: '2211', Name: 'Neurologist' },
  { Id: 2908, Code: '2211', Name: 'Neurosurgeon' },
  { Id: 2909, Code: '2211', Name: 'Obstetrician' },
  { Id: 2910, Code: '2211', Name: 'Obstetrician, consultant' },
  { Id: 2911, Code: '2211', Name: 'Officer, house, hospital service' },
  { Id: 2912, Code: '2211', Name: 'Officer, medical' },
  { Id: 2913, Code: '2211', Name: 'Oncologist' },
  { Id: 2914, Code: '2211', Name: 'Ophthalmologist' },
  { Id: 2915, Code: '2211', Name: 'Otologist' },
  { Id: 2916, Code: '2211', Name: 'Otorhinolaryngologist' },
  { Id: 2917, Code: '2211', Name: 'Paediatrician' },
  { Id: 2918, Code: '2211', Name: 'Physician' },
  { Id: 2919, Code: '2211', Name: 'Physician, consultant' },
  { Id: 2920, Code: '2211', Name: 'Physician, pharmaceutical' },
  { Id: 2921, Code: '2211', Name: 'Practitioner, general' },
  {
    Id: 2922,
    Code: '2211',
    Name: 'Practitioner, homeopathic, MEDICALLY QUALIFIED'
  },
  {
    Id: 2923,
    Code: '2211',
    Name: 'Practitioner, homoeopathic, MEDICALLY QUALIFIED'
  },
  { Id: 2924, Code: '2211', Name: 'Practitioner, medical' },
  { Id: 2925, Code: '2211', Name: 'Practitioner, medical, general' },
  { Id: 2926, Code: '2211', Name: 'Practitioner, medical, registered' },
  { Id: 2927, Code: '2211', Name: 'Proctologist' },
  { Id: 2928, Code: '2211', Name: 'Professor, MEDICINE' },
  { Id: 2929, Code: '2211', Name: 'Professor, SURGERY' },
  { Id: 2930, Code: '2211', Name: 'Psychiatrist' },
  { Id: 2931, Code: '2211', Name: 'Psychiatrist, consultant' },
  { Id: 2932, Code: '2211', Name: 'Psycho-analyst' },
  { Id: 2933, Code: '2211', Name: 'Radiodiagnostician' },
  { Id: 2934, Code: '2211', Name: 'Radiologist' },
  { Id: 2935, Code: '2211', Name: 'Radiologist, consultant' },
  { Id: 2936, Code: '2211', Name: 'Radiotherapist' },
  { Id: 2937, Code: '2211', Name: 'Reader, MEDICINE' },
  { Id: 2938, Code: '2211', Name: 'Reader, SURGERY' },
  { Id: 2939, Code: '2211', Name: 'Referee, government, MEDICAL' },
  { Id: 2940, Code: '2211', Name: 'Registrar, hospital service' },
  { Id: 2941, Code: '2211', Name: 'Registrar, medical practice' },
  { Id: 2942, Code: '2211', Name: 'Registrar, practice, general' },
  { Id: 2943, Code: '2211', Name: 'Registrar, specialty' },
  { Id: 2944, Code: '2211', Name: 'Rheumatologist' },
  { Id: 2945, Code: '2211', Name: 'Specialist, associate, hospital service' },
  { Id: 2946, Code: '2211', Name: 'Specialist, ear, nose and throat' },
  { Id: 2947, Code: '2211', Name: 'Specialist-registrar' },
  { Id: 2948, Code: '2211', Name: 'Superintendent, medical' },
  { Id: 2949, Code: '2211', Name: 'Surgeon' },
  { Id: 2950, Code: '2211', Name: 'Surgeon, consultant' },
  { Id: 2951, Code: '2211', Name: 'Urologist' },
  { Id: 2952, Code: '2211', Name: 'Venereologist' },
  { Id: 2953, Code: '2212', Name: 'Assistant, psychology' },
  { Id: 2954, Code: '2212', Name: 'Psychologist' },
  { Id: 2955, Code: '2212', Name: 'Psychologist, business' },
  { Id: 2956, Code: '2212', Name: 'Psychologist, clinical' },
  { Id: 2957, Code: '2212', Name: 'Psychologist, counselling' },
  { Id: 2958, Code: '2212', Name: 'Psychologist, educational' },
  { Id: 2959, Code: '2212', Name: 'Psychologist, forensic' },
  { Id: 2960, Code: '2212', Name: 'Psychologist, health' },
  { Id: 2961, Code: '2212', Name: 'Psychologist, occupational' },
  { Id: 2962, Code: '2212', Name: 'Psychologist, practitioner' },
  { Id: 2963, Code: '2212', Name: 'Psychologist, registered' },
  { Id: 2964, Code: '2212', Name: 'Psychologist, research' },
  { Id: 2965, Code: '2212', Name: 'Psychologist, sport and exercise' },
  { Id: 2966, Code: '2212', Name: 'Psychometrician' },
  { Id: 2967, Code: '2212', Name: 'Psychometrist' },
  { Id: 2968, Code: '2213', Name: 'Adviser, prescribing' },
  { Id: 2969, Code: '2213', Name: 'Associate, pharmacovigilance' },
  { Id: 2970, Code: '2213', Name: 'Chemist and druggist, retail trade' },
  { Id: 2971, Code: '2213', Name: 'Chemist, homeopathic' },
  { Id: 2972, Code: '2213', Name: 'Chemist, homoeopathic' },
  { Id: 2973, Code: '2213', Name: 'Chemist, nos' },
  { Id: 2974, Code: '2213', Name: 'Chemist, pharmaceutical' },
  { Id: 2975, Code: '2213', Name: 'Chemist, retail trade' },
  { Id: 2976, Code: '2213', Name: 'Druggist' },
  { Id: 2977, Code: '2213', Name: 'Manager, dispensary' },
  { Id: 2978, Code: '2213', Name: 'Manager, hospital pharmacists' },
  { Id: 2979, Code: '2213', Name: "Manager, pharmacist's" },
  { Id: 2980, Code: '2213', Name: 'Manager, pharmacy' },
  { Id: 2981, Code: '2213', Name: 'Manager, retail trade: pharmacists' },
  { Id: 2982, Code: '2213', Name: 'MPS' },
  { Id: 2983, Code: '2213', Name: 'Officer, pharmaceutical, health authority' },
  { Id: 2984, Code: '2213', Name: 'Owner, pharmacists' },
  { Id: 2985, Code: '2213', Name: 'Pharmaceutist' },
  { Id: 2986, Code: '2213', Name: 'Pharmacist' },
  { Id: 2987, Code: '2214', Name: 'Optician' },
  { Id: 2988, Code: '2214', Name: 'Optician, ophthalmic' },
  { Id: 2989, Code: '2214', Name: 'Optologist' },
  { Id: 2990, Code: '2214', Name: 'Optometrist' },
  { Id: 2991, Code: '2215', Name: 'Consultant, dental' },
  { Id: 2992, Code: '2215', Name: 'Dentist' },
  { Id: 2993, Code: '2215', Name: 'Fellow, DENTISTRY' },
  { Id: 2994, Code: '2215', Name: 'Fellow, RESEARCH, DENTISTRY' },
  { Id: 2995, Code: '2215', Name: 'Fellow, university:, DENTISTRY' },
  { Id: 2996, Code: '2215', Name: 'Lecturer, DENTISTRY' },
  { Id: 2997, Code: '2215', Name: 'Officer, dental' },
  { Id: 2998, Code: '2215', Name: 'Orthodontist' },
  { Id: 2999, Code: '2215', Name: 'Periodontist' },
  { Id: 3000, Code: '2215', Name: 'Practitioner, dental' },
  { Id: 3001, Code: '2215', Name: 'Professor, DENTISTRY' },
  { Id: 3002, Code: '2215', Name: 'Reader, DENTISTRY' },
  { Id: 3003, Code: '2215', Name: 'Surgeon, dental' },
  { Id: 3004, Code: '2216', Name: 'Consultant, veterinary' },
  { Id: 3005, Code: '2216', Name: 'Inspector, veterinary' },
  { Id: 3006, Code: '2216', Name: 'MRCVS' },
  { Id: 3007, Code: '2216', Name: 'Officer, field, veterinary' },
  { Id: 3008, Code: '2216', Name: 'Officer, investigating, veterinary' },
  { Id: 3009, Code: '2216', Name: 'Officer, investigation, veterinary' },
  { Id: 3010, Code: '2216', Name: 'Officer, research, veterinary' },
  { Id: 3011, Code: '2216', Name: 'Officer, veterinary' },
  { Id: 3012, Code: '2216', Name: 'Practitioner, veterinary' },
  { Id: 3013, Code: '2216', Name: 'Scientist, veterinary' },
  { Id: 3014, Code: '2216', Name: 'Surgeon, veterinary' },
  { Id: 3015, Code: '2216', Name: 'Vet' },
  { Id: 3016, Code: '2216', Name: 'Veterinarian' },
  { Id: 3017, Code: '2217', Name: 'Operator, x-ray' },
  { Id: 3018, Code: '2217', Name: 'Photographer, clinical' },
  { Id: 3019, Code: '2217', Name: 'Practitioner, advanced, radiography' },
  { Id: 3020, Code: '2217', Name: 'Practitioner, advanced, RADIOLOGY' },
  { Id: 3021, Code: '2217', Name: 'Practitioner, medicine, nuclear' },
  { Id: 3022, Code: '2217', Name: 'Practitioner, physics, radiotherapy' },
  { Id: 3023, Code: '2217', Name: 'Radiographer' },
  { Id: 3024, Code: '2217', Name: 'Radiographer, diagnostic' },
  { Id: 3025, Code: '2217', Name: 'Radiographer, medical' },
  { Id: 3026, Code: '2217', Name: 'Radiographer, superintendent' },
  { Id: 3027, Code: '2217', Name: 'Radiographer, therapeutic' },
  { Id: 3028, Code: '2217', Name: 'Scientist, physics, radiotherapy' },
  { Id: 3029, Code: '2217', Name: 'Sonographer' },
  { Id: 3030, Code: '2217', Name: 'Technologist, vascular' },
  { Id: 3031, Code: '2217', Name: 'Ultrasonographer' },
  { Id: 3032, Code: '2218', Name: 'Chiropodist' },
  { Id: 3033, Code: '2218', Name: 'Chiropodist-podiatrist' },
  { Id: 3034, Code: '2218', Name: 'Podiatrist' },
  { Id: 3035, Code: '2218', Name: 'Practitioner, health, foot, QUALIFIED' },
  { Id: 3036, Code: '2218', Name: 'Professional, health, foot, QUALIFIED' },
  { Id: 3037, Code: '2218', Name: 'Surgeon, podiatric' },
  { Id: 3038, Code: '2219', Name: 'Adviser, health, occupational' },
  { Id: 3039, Code: '2219', Name: 'Adviser, health, public' },
  { Id: 3040, Code: '2219', Name: 'Adviser, health, sexual' },
  { Id: 3041, Code: '2219', Name: 'Adviser, nurse, health, occupational' },
  {
    Id: 3042,
    Code: '2219',
    Name: 'Adviser, occupational health and wellbeing'
  },
  { Id: 3043, Code: '2219', Name: 'Associate, physician' },
  { Id: 3044, Code: '2219', Name: 'Audiologist' },
  { Id: 3045, Code: '2219', Name: 'Consultant, health, public' },
  { Id: 3046, Code: '2219', Name: 'Coordinator, transplant' },
  { Id: 3047, Code: '2219', Name: 'Dietician' },
  { Id: 3048, Code: '2219', Name: 'Dietician-nutritionist' },
  { Id: 3049, Code: '2219', Name: 'Dietitian' },
  { Id: 3050, Code: '2219', Name: 'Dietitian-nutritionist' },
  { Id: 3051, Code: '2219', Name: 'Dosimetrist' },
  { Id: 3052, Code: '2219', Name: 'Dosimetrist, medical' },
  { Id: 3053, Code: '2219', Name: 'Educator, health' },
  { Id: 3054, Code: '2219', Name: 'Facilitator, care, primary' },
  { Id: 3055, Code: '2219', Name: 'Facilitator, medical practice' },
  { Id: 3056, Code: '2219', Name: 'Leader, team, clinical, HEALTH' },
  { Id: 3057, Code: '2219', Name: 'Manager, control, infection' },
  { Id: 3058, Code: '2219', Name: 'Manager, immunisation' },
  { Id: 3059, Code: '2219', Name: 'Manager, prosthetic, senior' },
  { Id: 3060, Code: '2219', Name: 'Manager, resource, community' },
  { Id: 3061, Code: '2219', Name: 'Manager, services, sterile' },
  { Id: 3062, Code: '2219', Name: 'Neurophysiologist' },
  { Id: 3063, Code: '2219', Name: 'Officer, education, community health' },
  { Id: 3064, Code: '2219', Name: 'Officer, education, HEALTH' },
  { Id: 3065, Code: '2219', Name: 'Officer, promotion, health' },
  { Id: 3066, Code: '2219', Name: 'Orthotist' },
  { Id: 3067, Code: '2219', Name: 'Orthotist and prosthetist' },
  { Id: 3068, Code: '2219', Name: 'Perfusionist' },
  { Id: 3069, Code: '2219', Name: 'Physiologist, cardiac, senior' },
  { Id: 3070, Code: '2219', Name: 'Physiologist, respiratory, clinical' },
  { Id: 3071, Code: '2219', Name: 'Planner, family' },
  { Id: 3072, Code: '2219', Name: 'Practitioner, improvement, health' },
  { Id: 3073, Code: '2219', Name: 'Practitioner, neurophysiology' },
  { Id: 3074, Code: '2219', Name: 'Practitioner, paramedical' },
  { Id: 3075, Code: '2219', Name: 'Practitioner, perioperative' },
  { Id: 3076, Code: '2219', Name: 'Prosthetist' },
  { Id: 3077, Code: '2219', Name: 'Prosthetist and orthotist' },
  { Id: 3078, Code: '2219', Name: 'Scientist, healthcare, neurophysiology' },
  { Id: 3079, Code: '2219', Name: 'Scientist, medicine, nuclear' },
  { Id: 3080, Code: '2219', Name: 'Specialist, education, health' },
  { Id: 3081, Code: '2219', Name: 'Specialist, perioperative' },
  { Id: 3082, Code: '2219', Name: 'Specialist, promotion, health' },
  { Id: 3083, Code: '2219', Name: 'Technician, measurement, physiological' },
  { Id: 3084, Code: '2219', Name: 'Technologist, renal' },
  { Id: 3085, Code: '2219', Name: 'Therapist, hygiene, dental' },
  { Id: 3086, Code: '2219', Name: 'Worker, health, mental' },
  { Id: 3087, Code: '2221', Name: 'Electro-therapeutist' },
  { Id: 3088, Code: '2221', Name: 'Electro-therapist' },
  { Id: 3089, Code: '2221', Name: 'Physiotherapist' },
  { Id: 3090, Code: '2221', Name: 'Physiotherapist, superintendent' },
  { Id: 3091, Code: '2221', Name: 'Physiotherapist, veterinary' },
  { Id: 3092, Code: '2221', Name: 'Practitioner, physiotherapy' },
  { Id: 3093, Code: '2221', Name: 'Therapist, physical' },
  { Id: 3094, Code: '2222', Name: 'Therapist, occupational' },
  { Id: 3095, Code: '2223', Name: 'Therapist, communication' },
  { Id: 3096, Code: '2223', Name: 'Therapist, language' },
  { Id: 3097, Code: '2223', Name: 'Therapist, speech' },
  { Id: 3098, Code: '2223', Name: 'Therapist, speech and language' },
  { Id: 3099, Code: '2229', Name: 'Acupuncturist, MEDICALLY QUALIFIED' },
  { Id: 3100, Code: '2229', Name: 'Behaviourist, animal' },
  { Id: 3101, Code: '2229', Name: 'Chiropractor' },
  { Id: 3102, Code: '2229', Name: 'Counsellor, behaviour, pet' },
  { Id: 3103, Code: '2229', Name: 'Counsellor, practice, medical practice' },
  { Id: 3104, Code: '2229', Name: 'Dramatherapist' },
  { Id: 3105, Code: '2229', Name: 'Gymnast, remedial' },
  { Id: 3106, Code: '2229', Name: 'Herbalist, medical' },
  { Id: 3107, Code: '2229', Name: 'Hydrotherapist, colon' },
  { Id: 3108, Code: '2229', Name: 'Nutritionist' },
  { Id: 3109, Code: '2229', Name: 'Orthoptist' },
  { Id: 3110, Code: '2229', Name: 'Osteomyologist' },
  { Id: 3111, Code: '2229', Name: 'Osteopath' },
  { Id: 3112, Code: '2229', Name: 'Practitioner, wellbeing, psychological' },
  { Id: 3113, Code: '2229', Name: 'Psychologist, pet' },
  { Id: 3114, Code: '2229', Name: 'Psychotherapist' },
  { Id: 3115, Code: '2229', Name: 'Psychotherapist, analytical' },
  { Id: 3116, Code: '2229', Name: 'Psychotherapist, art' },
  { Id: 3117, Code: '2229', Name: 'Psychotherapist, child' },
  { Id: 3118, Code: '2229', Name: "Psychotherapist, children's" },
  { Id: 3119, Code: '2229', Name: 'Psychotherapist, counsellor' },
  { Id: 3120, Code: '2229', Name: 'Therapist, art' },
  { Id: 3121, Code: '2229', Name: 'Therapist, artistic' },
  { Id: 3122, Code: '2229', Name: 'Therapist, behavioural, cognitive' },
  { Id: 3123, Code: '2229', Name: 'Therapist, child' },
  { Id: 3124, Code: '2229', Name: "Therapist, children's" },
  { Id: 3125, Code: '2229', Name: 'Therapist, drama' },
  { Id: 3126, Code: '2229', Name: 'Therapist, family' },
  { Id: 3127, Code: '2229', Name: 'Therapist, movement' },
  { Id: 3128, Code: '2229', Name: 'Therapist, movement, dance' },
  { Id: 3129, Code: '2229', Name: 'Therapist, music' },
  { Id: 3130, Code: '2229', Name: 'Therapist, play' },
  { Id: 3131, Code: '2229', Name: 'Therapist, psychological' },
  { Id: 3132, Code: '2229', Name: 'Therapist, remedial' },
  { Id: 3133, Code: '2231', Name: 'Adviser, nurse' },
  { Id: 3134, Code: '2231', Name: 'Cadet, NURSING' },
  { Id: 3135, Code: '2231', Name: 'Companion-nurse' },
  { Id: 3136, Code: '2231', Name: 'ENG' },
  { Id: 3137, Code: '2231', Name: 'Leader, team, clinical, health authority' },
  { Id: 3138, Code: '2231', Name: 'Leader, team, nurse' },
  { Id: 3139, Code: '2231', Name: 'Leader, team, NURSING' },
  { Id: 3140, Code: '2231', Name: 'Manager, bank, nurse' },
  { Id: 3141, Code: '2231', Name: 'Manager, nurse' },
  { Id: 3142, Code: '2231', Name: 'Manager, theatre, hospital service' },
  { Id: 3143, Code: '2231', Name: 'Manager, unit, hospital service' },
  { Id: 3144, Code: '2231', Name: 'Manager, ward' },
  { Id: 3145, Code: '2231', Name: 'Matron, community' },
  { Id: 3146, Code: '2231', Name: 'Matron, medical services' },
  { Id: 3147, Code: '2231', Name: 'Matron, modern' },
  { Id: 3148, Code: '2231', Name: 'Nun, nursing' },
  { Id: 3149, Code: '2231', Name: 'Nurse' },
  { Id: 3150, Code: '2231', Name: 'Nurse, adult' },
  { Id: 3151, Code: '2231', Name: 'Nurse, care, ambulatory' },
  { Id: 3152, Code: '2231', Name: 'Nurse, care, breast' },
  { Id: 3153, Code: '2231', Name: 'Nurse, care, intensive' },
  { Id: 3154, Code: '2231', Name: 'Nurse, care, palliative' },
  { Id: 3155, Code: '2231', Name: 'Nurse, care, primary, advanced' },
  { Id: 3156, Code: '2231', Name: 'Nurse, care, stoma' },
  { Id: 3157, Code: '2231', Name: 'Nurse, charge' },
  { Id: 3158, Code: '2231', Name: "Nurse, children's" },
  { Id: 3159, Code: '2231', Name: "Nurse, children's, community" },
  { Id: 3160, Code: '2231', Name: "Nurse, children's, registered" },
  { Id: 3161, Code: '2231', Name: "Nurse, children's, sick, registered" },
  { Id: 3162, Code: '2231', Name: 'Nurse, community' },
  { Id: 3163, Code: '2231', Name: 'Nurse, disability, learning, community' },
  { Id: 3164, Code: '2231', Name: 'Nurse, district' },
  { Id: 3165, Code: '2231', Name: 'Nurse, enrolled' },
  { Id: 3166, Code: '2231', Name: 'Nurse, health, mental' },
  { Id: 3167, Code: '2231', Name: 'Nurse, health, mental, community' },
  { Id: 3168, Code: '2231', Name: 'Nurse, health, occupational' },
  { Id: 3169, Code: '2231', Name: 'Nurse, ITU' },
  { Id: 3170, Code: '2231', Name: 'Nurse, lead, clinical' },
  { Id: 3171, Code: '2231', Name: 'Nurse, Macmillan' },
  { Id: 3172, Code: '2231', Name: 'Nurse, chief' },
  { Id: 3173, Code: '2231', Name: 'Nurse, misuse, substance' },
  { Id: 3174, Code: '2231', Name: 'Nurse, paediatric' },
  { Id: 3175, Code: '2231', Name: 'Nurse, practice' },
  { Id: 3176, Code: '2231', Name: 'Nurse, psychiatric' },
  { Id: 3177, Code: '2231', Name: 'Nurse, psychiatric, community' },
  { Id: 3178, Code: '2231', Name: 'Nurse, recovery' },
  { Id: 3179, Code: '2231', Name: 'Nurse, registered' },
  { Id: 3180, Code: '2231', Name: 'Nurse, registered, state' },
  { Id: 3181, Code: '2231', Name: 'Nurse, school' },
  { Id: 3182, Code: '2231', Name: 'Nurse, specialist' },
  { Id: 3183, Code: '2231', Name: 'Nurse, staff' },
  { Id: 3184, Code: '2231', Name: 'Nurse, student' },
  { Id: 3185, Code: '2231', Name: 'Nurse, theatre' },
  { Id: 3186, Code: '2231', Name: 'Nurse, theatre, operating' },
  { Id: 3187, Code: '2231', Name: 'Nurse-companion' },
  { Id: 3188, Code: '2231', Name: 'Nurse-ECP' },
  { Id: 3189, Code: '2231', Name: 'Nurse-practitioner' },
  { Id: 3190, Code: '2231', Name: 'Nurse-teacher' },
  { Id: 3191, Code: '2231', Name: 'Nurse-tutor' },
  { Id: 3192, Code: '2231', Name: 'Officer, nursing' },
  { Id: 3193, Code: '2231', Name: 'Officer, resuscitation' },
  { Id: 3194, Code: '2231', Name: 'Practitioner, care, health' },
  { Id: 3195, Code: '2231', Name: 'Practitioner, department, operating' },
  { Id: 3196, Code: '2231', Name: 'Practitioner, health, mental' },
  { Id: 3197, Code: '2231', Name: 'Practitioner, nurse' },
  { Id: 3198, Code: '2231', Name: 'Practitioner, NURSING' },
  { Id: 3199, Code: '2231', Name: 'Practitioner, scrub, theatre' },
  { Id: 3200, Code: '2231', Name: 'Practitioner, theatre' },
  { Id: 3201, Code: '2231', Name: 'Practitioner, theatre, operating' },
  { Id: 3202, Code: '2231', Name: 'RGN' },
  { Id: 3203, Code: '2231', Name: 'RMN' },
  { Id: 3204, Code: '2231', Name: 'RSCN' },
  { Id: 3205, Code: '2231', Name: 'SEN' },
  { Id: 3206, Code: '2231', Name: 'Sister' },
  { Id: 3207, Code: '2231', Name: 'Sister, nursing' },
  { Id: 3208, Code: '2231', Name: 'Sister, nursing, district' },
  { Id: 3209, Code: '2231', Name: 'Sister, nursing, religious order' },
  { Id: 3210, Code: '2231', Name: 'Sister, theatre' },
  { Id: 3211, Code: '2231', Name: 'Sister, ward' },
  { Id: 3212, Code: '2231', Name: 'Sister-tutor' },
  { Id: 3213, Code: '2231', Name: 'Specialist, nurse' },
  { Id: 3214, Code: '2231', Name: 'Specialist, nurse, clinical' },
  { Id: 3215, Code: '2231', Name: 'SRN' },
  { Id: 3216, Code: '2231', Name: 'Superintendent, night, hospital service' },
  { Id: 3217, Code: '2231', Name: 'Supervisor, nurse' },
  { Id: 3218, Code: '2231', Name: 'Tutor, hospital service' },
  { Id: 3219, Code: '2231', Name: 'Visitor, district' },
  { Id: 3220, Code: '2231', Name: 'Visitor, health' },
  { Id: 3221, Code: '2231', Name: 'Visitor, health, superintendent' },
  { Id: 3222, Code: '2231', Name: 'Visitor, home' },
  { Id: 3223, Code: '2232', Name: 'Leader, team, MIDWIFERY' },
  { Id: 3224, Code: '2232', Name: 'Manager, midwife' },
  { Id: 3225, Code: '2232', Name: 'Manager, midwifery' },
  { Id: 3226, Code: '2232', Name: 'Manager, suite, delivery' },
  { Id: 3227, Code: '2232', Name: 'Midwife' },
  { Id: 3228, Code: '2232', Name: 'Midwife, superintendent' },
  { Id: 3229, Code: '2232', Name: 'Midwife-tutor' },
  { Id: 3230, Code: '2232', Name: 'Practitioner, midwifery' },
  { Id: 3231, Code: '2232', Name: 'Sister, midwifery' },
  {
    Id: 3232,
    Code: '2311',
    Name: 'Assistant, teaching, higher education, university'
  },
  { Id: 3233, Code: '2311', Name: 'Assistant, teaching, postgraduate' },
  { Id: 3234, Code: '2311', Name: 'Assistant, teaching, university' },
  {
    Id: 3235,
    Code: '2311',
    Name: 'Assistant, tutorial, higher education, university'
  },
  {
    Id: 3236,
    Code: '2311',
    Name: 'Demonstrator, higher education, university'
  },
  { Id: 3237, Code: '2311', Name: 'Demonstrator, university' },
  {
    Id: 3238,
    Code: '2311',
    Name: 'Director of studies, higher education, university'
  },
  {
    Id: 3239,
    Code: '2311',
    Name: 'Director, course, higher education, university'
  },
  { Id: 3240, Code: '2311', Name: 'Fellow, university' },
  {
    Id: 3241,
    Code: '2311',
    Name: 'Head of department, higher education, university'
  },
  {
    Id: 3242,
    Code: '2311',
    Name: 'Head, assistant, higher education, university'
  },
  {
    Id: 3243,
    Code: '2311',
    Name: 'Head, deputy, higher education, university'
  },
  { Id: 3244, Code: '2311', Name: 'Instructor, higher education, university' },
  { Id: 3245, Code: '2311', Name: 'Lecturer, clinical' },
  {
    Id: 3246,
    Code: '2311',
    Name: 'Lecturer, college, higher education, university'
  },
  {
    Id: 3247,
    Code: '2311',
    Name: 'Lecturer, dance, higher education, university'
  },
  { Id: 3248, Code: '2311', Name: 'Lecturer, higher education, university' },
  { Id: 3249, Code: '2311', Name: 'Lecturer, midwifery' },
  { Id: 3250, Code: '2311', Name: 'Lecturer, nursing' },
  { Id: 3251, Code: '2311', Name: 'Lecturer, nursing, midwifery' },
  { Id: 3252, Code: '2311', Name: 'Lecturer, political' },
  { Id: 3253, Code: '2311', Name: 'Lecturer, polytechnic' },
  { Id: 3254, Code: '2311', Name: 'Lecturer, university' },
  { Id: 3255, Code: '2311', Name: 'Officer, research, university' },
  { Id: 3256, Code: '2311', Name: 'Professor, higher education, university' },
  { Id: 3257, Code: '2311', Name: 'Professor, university' },
  { Id: 3258, Code: '2311', Name: 'Reader, university' },
  { Id: 3259, Code: '2311', Name: 'Rector, university' },
  { Id: 3260, Code: '2311', Name: 'Teacher, clinical' },
  {
    Id: 3261,
    Code: '2311',
    Name: 'Teacher, dance, higher education, university'
  },
  {
    Id: 3262,
    Code: '2311',
    Name: 'Teacher, dancing, higher education, university'
  },
  { Id: 3263, Code: '2311', Name: 'Teacher, higher education, university' },
  { Id: 3264, Code: '2311', Name: 'Teacher, university' },
  { Id: 3265, Code: '2311', Name: 'Tutor, clinical' },
  {
    Id: 3266,
    Code: '2311',
    Name: 'Tutor, course, higher education, university'
  },
  { Id: 3267, Code: '2311', Name: 'Tutor, higher education, university' },
  { Id: 3268, Code: '2311', Name: 'Tutor, midwife' },
  { Id: 3269, Code: '2311', Name: 'Tutor, skills, clinical' },
  { Id: 3270, Code: '2312', Name: 'Director of studies, further education' },
  { Id: 3271, Code: '2312', Name: 'Head of department, further education' },
  { Id: 3272, Code: '2312', Name: 'Head of faculties, further education' },
  { Id: 3273, Code: '2312', Name: 'Head, assistant, further education' },
  { Id: 3274, Code: '2312', Name: 'Head, deputy, further education' },
  { Id: 3275, Code: '2312', Name: 'Instructor, further education' },
  { Id: 3276, Code: '2312', Name: 'Instructor, woodwork, further education' },
  { Id: 3277, Code: '2312', Name: 'Leader, course, further education' },
  { Id: 3278, Code: '2312', Name: 'Leader, curriculum, further education' },
  { Id: 3279, Code: '2312', Name: 'Lecturer' },
  { Id: 3280, Code: '2312', Name: 'Lecturer, college, FE' },
  { Id: 3281, Code: '2312', Name: 'Lecturer, college, further education' },
  { Id: 3282, Code: '2312', Name: 'Lecturer, dance, further education' },
  { Id: 3283, Code: '2312', Name: 'Lecturer, further education' },
  { Id: 3284, Code: '2312', Name: 'Professor, further education' },
  { Id: 3285, Code: '2312', Name: 'Rector, further education' },
  { Id: 3286, Code: '2312', Name: 'Teacher, dance, further education' },
  { Id: 3287, Code: '2312', Name: 'Teacher, dancing, further education' },
  { Id: 3288, Code: '2312', Name: 'Teacher, further education' },
  { Id: 3289, Code: '2312', Name: 'Teacher, music, further education' },
  { Id: 3290, Code: '2312', Name: 'Teacher, tertiary college' },
  { Id: 3291, Code: '2312', Name: 'Tutor, further education' },
  { Id: 3292, Code: '2314', Name: 'Director of studies, secondary school' },
  { Id: 3293, Code: '2314', Name: 'Director of studies, sixth form college' },
  { Id: 3294, Code: '2314', Name: 'Head of department, secondary school' },
  { Id: 3295, Code: '2314', Name: 'Head of department, sixth form college' },
  { Id: 3296, Code: '2314', Name: 'Head of year, secondary school' },
  { Id: 3297, Code: '2314', Name: 'Head, assistant, secondary school' },
  { Id: 3298, Code: '2314', Name: 'Head, assistant, sixth form college' },
  { Id: 3299, Code: '2314', Name: 'Head, deputy, secondary school' },
  { Id: 3300, Code: '2314', Name: 'Head, deputy, sixth form college' },
  { Id: 3301, Code: '2314', Name: 'Instructor, secondary school' },
  {
    Id: 3302,
    Code: '2314',
    Name: 'Instructor, secondary school, PHYSICAL TRAINING'
  },
  { Id: 3303, Code: '2314', Name: 'Instructor, sixth form college' },
  {
    Id: 3304,
    Code: '2314',
    Name: 'Instructor, training, physical, secondary school'
  },
  { Id: 3305, Code: '2314', Name: 'Instructor, woodwork, secondary school' },
  { Id: 3306, Code: '2314', Name: 'Instructor, woodwork, sixth form college' },
  { Id: 3307, Code: '2314', Name: 'Leader, curriculum, secondary school' },
  { Id: 3308, Code: '2314', Name: 'Lecturer, dance, secondary school' },
  { Id: 3309, Code: '2314', Name: 'Lecturer, secondary school' },
  { Id: 3310, Code: '2314', Name: 'Lecturer, sixth form college' },
  { Id: 3311, Code: '2314', Name: 'Master, head, assistant, secondary school' },
  {
    Id: 3312,
    Code: '2314',
    Name: 'Master, head, assistant, sixth form college'
  },
  { Id: 3313, Code: '2314', Name: 'Master, head, deputy, secondary school' },
  { Id: 3314, Code: '2314', Name: 'Master, head, deputy, sixth form college' },
  { Id: 3315, Code: '2314', Name: 'Master, house, secondary school' },
  { Id: 3316, Code: '2314', Name: 'Master, house, sixth form college' },
  { Id: 3317, Code: '2314', Name: 'Master, school, secondary school' },
  { Id: 3318, Code: '2314', Name: 'Master, school, sixth form college' },
  { Id: 3319, Code: '2314', Name: 'Master, secondary school' },
  { Id: 3320, Code: '2314', Name: 'Master, sixth form college' },
  {
    Id: 3321,
    Code: '2314',
    Name: 'Mistress, head, assistant, secondary school'
  },
  {
    Id: 3322,
    Code: '2314',
    Name: 'Mistress, head, assistant, sixth form college'
  },
  { Id: 3323, Code: '2314', Name: 'Mistress, head, deputy, secondary school' },
  {
    Id: 3324,
    Code: '2314',
    Name: 'Mistress, head, deputy, sixth form college'
  },
  { Id: 3325, Code: '2314', Name: 'Mistress, house, secondary school' },
  { Id: 3326, Code: '2314', Name: 'Mistress, house, sixth form college' },
  { Id: 3327, Code: '2314', Name: 'Mistress, school, secondary school' },
  { Id: 3328, Code: '2314', Name: 'Mistress, school, sixth form college' },
  { Id: 3329, Code: '2314', Name: 'Mistress, secondary school' },
  { Id: 3330, Code: '2314', Name: 'Mistress, sixth form college' },
  { Id: 3331, Code: '2314', Name: 'Monk, secondary school, TEACHING' },
  { Id: 3332, Code: '2314', Name: 'Nun, secondary school, TEACHING' },
  { Id: 3333, Code: '2314', Name: 'Rector, secondary school' },
  { Id: 3334, Code: '2314', Name: 'Teacher, college, form, sixth' },
  { Id: 3335, Code: '2314', Name: 'Teacher, dance, secondary school' },
  { Id: 3336, Code: '2314', Name: 'Teacher, dancing, secondary school' },
  { Id: 3337, Code: '2314', Name: 'Teacher, form, sixth' },
  {
    Id: 3338,
    Code: '2314',
    Name: 'Teacher, head, assistant, secondary school'
  },
  {
    Id: 3339,
    Code: '2314',
    Name: 'Teacher, head, assistant, sixth form college'
  },
  { Id: 3340, Code: '2314', Name: 'Teacher, head, deputy, secondary school' },
  { Id: 3341, Code: '2314', Name: 'Teacher, head, deputy, sixth form college' },
  { Id: 3342, Code: '2314', Name: 'Teacher, music, secondary school' },
  { Id: 3343, Code: '2314', Name: 'Teacher, school, comprehensive' },
  { Id: 3344, Code: '2314', Name: 'Teacher, school, secondary' },
  { Id: 3345, Code: '2314', Name: 'Teacher, school, upper' },
  { Id: 3346, Code: '2314', Name: 'Teacher, secondary' },
  { Id: 3347, Code: '2314', Name: 'Teacher, secondary school' },
  { Id: 3348, Code: '2314', Name: 'Teacher, sixth form college' },
  { Id: 3349, Code: '2314', Name: 'Tutor, secondary school' },
  { Id: 3350, Code: '2314', Name: 'Tutor, sixth form college' },
  { Id: 3351, Code: '2315', Name: 'Consultant, stage, foundation' },
  { Id: 3352, Code: '2315', Name: 'Coordinator, stage, foundation' },
  { Id: 3353, Code: '2315', Name: 'Head, assistant, nursery school' },
  { Id: 3354, Code: '2315', Name: 'Head, assistant, primary school' },
  { Id: 3355, Code: '2315', Name: 'Head, deputy, nursery school' },
  { Id: 3356, Code: '2315', Name: 'Head, deputy, primary school' },
  { Id: 3357, Code: '2315', Name: 'Instructor, nursery school' },
  { Id: 3358, Code: '2315', Name: 'Instructor, primary school' },
  { Id: 3359, Code: '2315', Name: 'Leader, curriculum, primary school' },
  { Id: 3360, Code: '2315', Name: 'Lecturer, dance, primary school' },
  { Id: 3361, Code: '2315', Name: 'Master, head, assistant, nursery school' },
  { Id: 3362, Code: '2315', Name: 'Master, head, assistant, primary school' },
  { Id: 3363, Code: '2315', Name: 'Master, head, deputy, nursery school' },
  { Id: 3364, Code: '2315', Name: 'Master, head, deputy, primary school' },
  { Id: 3365, Code: '2315', Name: 'Master, house, nursery school' },
  { Id: 3366, Code: '2315', Name: 'Master, house, primary school' },
  { Id: 3367, Code: '2315', Name: 'Master, nursery school' },
  { Id: 3368, Code: '2315', Name: 'Master, primary school' },
  { Id: 3369, Code: '2315', Name: 'Master, school, nursery school' },
  { Id: 3370, Code: '2315', Name: 'Master, school, primary school' },
  { Id: 3371, Code: '2315', Name: 'Mistress, head, assistant, nursery school' },
  { Id: 3372, Code: '2315', Name: 'Mistress, head, assistant, primary school' },
  { Id: 3373, Code: '2315', Name: 'Mistress, head, deputy, nursery school' },
  { Id: 3374, Code: '2315', Name: 'Mistress, head, deputy, primary school' },
  { Id: 3375, Code: '2315', Name: 'Mistress, house, nursery school' },
  { Id: 3376, Code: '2315', Name: 'Mistress, house, primary school' },
  { Id: 3377, Code: '2315', Name: 'Mistress, nursery school' },
  { Id: 3378, Code: '2315', Name: 'Mistress, primary school' },
  { Id: 3379, Code: '2315', Name: 'Mistress, school, nursery school' },
  { Id: 3380, Code: '2315', Name: 'Mistress, school, primary school' },
  { Id: 3381, Code: '2315', Name: 'Monk, primary school, TEACHING' },
  { Id: 3382, Code: '2315', Name: 'Nun, primary school, TEACHING' },
  { Id: 3383, Code: '2315', Name: "Pedagogue, centre, children's" },
  { Id: 3384, Code: '2315', Name: 'Teacher, dance, primary school' },
  { Id: 3385, Code: '2315', Name: 'Teacher, dancing, primary school' },
  { Id: 3386, Code: '2315', Name: 'Teacher, head, assistant, nursery school' },
  { Id: 3387, Code: '2315', Name: 'Teacher, head, assistant, primary school' },
  { Id: 3388, Code: '2315', Name: 'Teacher, head, deputy, nursery school' },
  { Id: 3389, Code: '2315', Name: 'Teacher, head, deputy, primary school' },
  { Id: 3390, Code: '2315', Name: 'Teacher, infant' },
  { Id: 3391, Code: '2315', Name: 'Teacher, junior school' },
  { Id: 3392, Code: '2315', Name: 'Teacher, kindergarten' },
  { Id: 3393, Code: '2315', Name: 'Teacher, music, primary school' },
  { Id: 3394, Code: '2315', Name: 'Teacher, nursery school' },
  { Id: 3395, Code: '2315', Name: 'Teacher, nursery, PROFESSIONAL' },
  { Id: 3396, Code: '2315', Name: 'Teacher, pre-school, PROFESSIONAL' },
  { Id: 3397, Code: '2315', Name: 'Teacher, primary' },
  { Id: 3398, Code: '2315', Name: 'Teacher, primary school' },
  { Id: 3399, Code: '2315', Name: 'Teacher, school, junior' },
  { Id: 3400, Code: '2315', Name: 'Teacher, school, nursery' },
  { Id: 3401, Code: '2315', Name: 'Teacher, school, preparatory' },
  { Id: 3402, Code: '2315', Name: 'Teacher, school, primary' },
  { Id: 3403, Code: '2315', Name: 'Teacher, years, early' },
  { Id: 3404, Code: '2316', Name: 'Coordinator, needs, educational, special' },
  { Id: 3405, Code: '2316', Name: 'Coordinator, needs, special' },
  { Id: 3406, Code: '2316', Name: 'Educationalist, needs, special' },
  { Id: 3407, Code: '2316', Name: 'Head, assistant, special school' },
  { Id: 3408, Code: '2316', Name: 'Head, deputy, special school' },
  { Id: 3409, Code: '2316', Name: 'Instructor, special school' },
  { Id: 3410, Code: '2316', Name: 'Lecturer, dance, special school' },
  { Id: 3411, Code: '2316', Name: 'Lecturer, special school' },
  { Id: 3412, Code: '2316', Name: 'Master, head, assistant, special school' },
  { Id: 3413, Code: '2316', Name: 'Master, head, deputy, special school' },
  { Id: 3414, Code: '2316', Name: 'Master, house, special school' },
  { Id: 3415, Code: '2316', Name: 'Master, school, special school' },
  { Id: 3416, Code: '2316', Name: 'Master, special school' },
  { Id: 3417, Code: '2316', Name: 'Mistress, head, assistant, special school' },
  { Id: 3418, Code: '2316', Name: 'Mistress, head, deputy, special school' },
  { Id: 3419, Code: '2316', Name: 'Mistress, house, special school' },
  { Id: 3420, Code: '2316', Name: 'Mistress, school, special school' },
  { Id: 3421, Code: '2316', Name: 'Mistress, special school' },
  { Id: 3422, Code: '2316', Name: 'Owner, school: special' },
  { Id: 3423, Code: '2316', Name: 'SENCO' },
  { Id: 3424, Code: '2316', Name: 'Teacher of the deaf' },
  { Id: 3425, Code: '2316', Name: 'Teacher of the visually impaired' },
  { Id: 3426, Code: '2316', Name: 'Teacher, behaviour' },
  { Id: 3427, Code: '2316', Name: 'Teacher, dance, special school' },
  { Id: 3428, Code: '2316', Name: 'Teacher, dancing, special school' },
  { Id: 3429, Code: '2316', Name: 'Teacher, head, assistant, special school' },
  { Id: 3430, Code: '2316', Name: 'Teacher, head, deputy, special school' },
  { Id: 3431, Code: '2316', Name: 'Teacher, music, special school' },
  { Id: 3432, Code: '2316', Name: 'Teacher, needs, special' },
  {
    Id: 3433,
    Code: '2316',
    Name: 'Teacher, portage, educational establishments'
  },
  { Id: 3434, Code: '2316', Name: 'Teacher, SPECIAL NEEDS' },
  { Id: 3435, Code: '2316', Name: 'Teacher, special school' },
  { Id: 3436, Code: '2316', Name: 'Teacher, support, behaviour' },
  { Id: 3437, Code: '2316', Name: 'Teacher, support, learning' },
  { Id: 3438, Code: '2316', Name: 'Tutor, needs, special' },
  { Id: 3439, Code: '2316', Name: 'Tutor, SPECIAL NEEDS' },
  { Id: 3440, Code: '2316', Name: 'Tutor, support, learning' },
  { Id: 3441, Code: '2317', Name: 'Administrator, college' },
  {
    Id: 3442,
    Code: '2317',
    Name: 'Administrator, education, further education, higher education'
  },
  {
    Id: 3443,
    Code: '2317',
    Name: 'Administrator, higher education, university'
  },
  { Id: 3444, Code: '2317', Name: 'Administrator, university' },
  { Id: 3445, Code: '2317', Name: 'Bursar' },
  { Id: 3446, Code: '2317', Name: 'Chancellor, vice, university' },
  { Id: 3447, Code: '2317', Name: 'Coordinator, academic' },
  {
    Id: 3448,
    Code: '2317',
    Name: 'Coordinator, project, educational establishments'
  },
  { Id: 3449, Code: '2317', Name: 'Dean, further education' },
  { Id: 3450, Code: '2317', Name: 'Dean, higher education, university' },
  { Id: 3451, Code: '2317', Name: 'Director, academic' },
  { Id: 3452, Code: '2317', Name: 'Governess' },
  {
    Id: 3453,
    Code: '2317',
    Name: 'Head of administration, educational establishments'
  },
  {
    Id: 3454,
    Code: '2317',
    Name: 'Head of school, higher education, university'
  },
  { Id: 3455, Code: '2317', Name: 'Head, further education' },
  { Id: 3456, Code: '2317', Name: 'Head, higher education, university' },
  { Id: 3457, Code: '2317', Name: 'Head, nursery school' },
  { Id: 3458, Code: '2317', Name: 'Head, primary school' },
  { Id: 3459, Code: '2317', Name: 'Head, secondary school' },
  { Id: 3460, Code: '2317', Name: 'Head, sixth form college' },
  { Id: 3461, Code: '2317', Name: 'Head, special school' },
  { Id: 3462, Code: '2317', Name: 'Manager, academic' },
  {
    Id: 3463,
    Code: '2317',
    Name: 'Manager, administrative, further education'
  },
  { Id: 3464, Code: '2317', Name: 'Manager, business, school' },
  { Id: 3465, Code: '2317', Name: 'Manager, educational establishments' },
  {
    Id: 3466,
    Code: '2317',
    Name: 'Manager, school, educational establishments'
  },
  { Id: 3467, Code: '2317', Name: 'Master, head, nursery school' },
  { Id: 3468, Code: '2317', Name: 'Master, head, primary school' },
  { Id: 3469, Code: '2317', Name: 'Master, head, secondary school' },
  { Id: 3470, Code: '2317', Name: 'Master, head, sixth form college' },
  { Id: 3471, Code: '2317', Name: 'Master, head, special school' },
  { Id: 3472, Code: '2317', Name: 'Mistress, head, nursery school' },
  { Id: 3473, Code: '2317', Name: 'Mistress, head, primary school' },
  { Id: 3474, Code: '2317', Name: 'Mistress, head, secondary school' },
  { Id: 3475, Code: '2317', Name: 'Mistress, head, sixth form college' },
  { Id: 3476, Code: '2317', Name: 'Mistress, head, special school' },
  { Id: 3477, Code: '2317', Name: 'Officer, executive, university' },
  {
    Id: 3478,
    Code: '2317',
    Name: 'Officer, recruitment, international, university'
  },
  { Id: 3479, Code: '2317', Name: 'President, COLLEGE' },
  { Id: 3480, Code: '2317', Name: 'Principal teacher of guidance' },
  { Id: 3481, Code: '2317', Name: 'Principal, further education' },
  { Id: 3482, Code: '2317', Name: 'Principal, higher education, university' },
  { Id: 3483, Code: '2317', Name: 'Principal, primary school' },
  { Id: 3484, Code: '2317', Name: 'Principal, secondary school' },
  { Id: 3485, Code: '2317', Name: 'Principal, sixth form college' },
  { Id: 3486, Code: '2317', Name: 'Principal, special school' },
  { Id: 3487, Code: '2317', Name: 'Provost, further education' },
  { Id: 3488, Code: '2317', Name: 'Provost, higher education, university' },
  { Id: 3489, Code: '2317', Name: 'Registrar, academic' },
  { Id: 3490, Code: '2317', Name: 'Registrar, educational establishments' },
  { Id: 3491, Code: '2317', Name: 'Teacher, head, nursery school' },
  { Id: 3492, Code: '2317', Name: 'Teacher, head, primary school' },
  { Id: 3493, Code: '2317', Name: 'Teacher, head, secondary school' },
  { Id: 3494, Code: '2317', Name: 'Teacher, head, sixth form college' },
  { Id: 3495, Code: '2317', Name: 'Teacher, head, special school' },
  { Id: 3496, Code: '2317', Name: 'Vice-Chancellor, university' },
  { Id: 3497, Code: '2318', Name: 'Adviser, academic' },
  { Id: 3498, Code: '2318', Name: 'Adviser, curriculum' },
  { Id: 3499, Code: '2318', Name: 'Adviser, development, curriculum' },
  { Id: 3500, Code: '2318', Name: 'Adviser, education' },
  { Id: 3501, Code: '2318', Name: 'Adviser, improvement, school' },
  { Id: 3502, Code: '2318', Name: 'Adviser, local government: education dept' },
  { Id: 3503, Code: '2318', Name: 'Assessor, education' },
  { Id: 3504, Code: '2318', Name: 'Assessor, educational' },
  { Id: 3505, Code: '2318', Name: 'Assessor, further, higher education' },
  { Id: 3506, Code: '2318', Name: 'Convenor, module' },
  { Id: 3507, Code: '2318', Name: 'Coordinator, music' },
  { Id: 3508, Code: '2318', Name: 'Director of education' },
  { Id: 3509, Code: '2318', Name: 'HMIS' },
  { Id: 3510, Code: '2318', Name: 'Inspector of schools' },
  { Id: 3511, Code: '2318', Name: 'Inspector, education' },
  {
    Id: 3512,
    Code: '2318',
    Name: 'Inspector, local government: education dept'
  },
  { Id: 3513, Code: '2318', Name: 'Inspector, OFSTED' },
  { Id: 3514, Code: '2318', Name: 'Inspector, school' },
  { Id: 3515, Code: '2318', Name: 'Inspector, years, early' },
  { Id: 3516, Code: '2318', Name: 'Officer, development, curriculum' },
  { Id: 3517, Code: '2318', Name: 'Officer, education' },
  { Id: 3518, Code: '2318', Name: 'Officer, improvement, school' },
  {
    Id: 3519,
    Code: '2318',
    Name: 'Officer, inclusion, local government: education dept'
  },
  { Id: 3520, Code: '2318', Name: 'Teacher, advisory' },
  {
    Id: 3521,
    Code: '2319',
    Name: 'Adviser, teaching, years, early, local government, social inclusion dept'
  },
  { Id: 3522, Code: '2319', Name: 'Adviser, years, early' },
  { Id: 3523, Code: '2319', Name: 'Assistant, language' },
  { Id: 3524, Code: '2319', Name: 'Coach, vocal' },
  { Id: 3525, Code: '2319', Name: 'Coach, voice' },
  { Id: 3526, Code: '2319', Name: 'Communicator, science' },
  { Id: 3527, Code: '2319', Name: 'Consultant, education' },
  { Id: 3528, Code: '2319', Name: 'Consultant, educational' },
  { Id: 3529, Code: '2319', Name: 'Consultant, years, early' },
  { Id: 3530, Code: '2319', Name: 'Coordinator, schools, active' },
  { Id: 3531, Code: '2319', Name: 'Coordinator, teaching' },
  { Id: 3532, Code: '2319', Name: 'Examiner, examination board' },
  { Id: 3533, Code: '2319', Name: 'Instructor, music' },
  { Id: 3534, Code: '2319', Name: 'Instructor, piano' },
  { Id: 3535, Code: '2319', Name: 'Instructor, staff, senior' },
  { Id: 3536, Code: '2319', Name: 'Instructor, violin' },
  { Id: 3537, Code: '2319', Name: 'Lecturer, dance' },
  { Id: 3538, Code: '2319', Name: 'Lecturer, ESOL' },
  { Id: 3539, Code: '2319', Name: 'Manager, care, child, day nursery' },
  { Id: 3540, Code: '2319', Name: 'Manager, childcare' },
  { Id: 3541, Code: '2319', Name: "Manager, children's nursery" },
  { Id: 3542, Code: '2319', Name: 'Manager, nursery, day nursery' },
  { Id: 3543, Code: '2319', Name: 'Manager, pre-school' },
  { Id: 3544, Code: '2319', Name: 'Manager, school, language' },
  { Id: 3545, Code: '2319', Name: 'Manager, school, nursery' },
  { Id: 3546, Code: '2319', Name: 'Manager, support, learning' },
  { Id: 3547, Code: '2319', Name: 'Marker, exam' },
  { Id: 3548, Code: '2319', Name: 'Moderator, examination board' },
  { Id: 3549, Code: '2319', Name: 'Officer, examinations, examination board' },
  { Id: 3550, Code: '2319', Name: 'Officer, support, teaching' },
  { Id: 3551, Code: '2319', Name: 'Organiser, adult education centre' },
  { Id: 3552, Code: '2319', Name: 'Organiser, education, adult' },
  { Id: 3553, Code: '2319', Name: "Owner, nursery, children's" },
  { Id: 3554, Code: '2319', Name: "Owner, nursery: children's" },
  { Id: 3555, Code: '2319', Name: 'Owner, school, dancing' },
  { Id: 3556, Code: '2319', Name: 'Owner, school, language' },
  { Id: 3557, Code: '2319', Name: 'Owner, school: dancing' },
  { Id: 3558, Code: '2319', Name: 'Owner, school: language' },
  { Id: 3559, Code: '2319', Name: 'Owner, school: nursery' },
  { Id: 3560, Code: '2319', Name: 'Owner, school: primary' },
  { Id: 3561, Code: '2319', Name: 'Owner, school: secondary' },
  { Id: 3562, Code: '2319', Name: 'Principal, adult education centre' },
  { Id: 3563, Code: '2319', Name: 'Principal, dancing school' },
  { Id: 3564, Code: '2319', Name: 'Principal, school, DANCING, PRIVATE' },
  { Id: 3565, Code: '2319', Name: 'Principal, school, MUSIC, PRIVATE' },
  { Id: 3566, Code: '2319', Name: 'Principal, training establishment' },
  { Id: 3567, Code: '2319', Name: 'Teacher of English as a foreign language' },
  { Id: 3568, Code: '2319', Name: 'Teacher, adult education centre' },
  { Id: 3569, Code: '2319', Name: 'Teacher, ballet, QUALIFIED' },
  { Id: 3570, Code: '2319', Name: 'Teacher, centre, education, adult' },
  { Id: 3571, Code: '2319', Name: 'Teacher, EFL' },
  { Id: 3572, Code: '2319', Name: 'Teacher, English as a second language' },
  { Id: 3573, Code: '2319', Name: 'Teacher, English for specific purposes' },
  { Id: 3574, Code: '2319', Name: 'Teacher, ESOL' },
  { Id: 3575, Code: '2319', Name: 'Teacher, ESP' },
  { Id: 3576, Code: '2319', Name: 'Teacher, hospital service' },
  { Id: 3577, Code: '2319', Name: 'Teacher, language' },
  { Id: 3578, Code: '2319', Name: 'Teacher, language, assistant' },
  { Id: 3579, Code: '2319', Name: 'Teacher, music' },
  { Id: 3580, Code: '2319', Name: 'Teacher, music, instrumental' },
  { Id: 3581, Code: '2319', Name: 'Teacher, music, peripatetic' },
  { Id: 3582, Code: '2319', Name: 'Teacher, music, private' },
  { Id: 3583, Code: '2319', Name: 'Teacher, MUSICAL INSTRUMENTS' },
  { Id: 3584, Code: '2319', Name: 'Teacher, PRIVATE' },
  { Id: 3585, Code: '2319', Name: 'Teacher, singing' },
  { Id: 3586, Code: '2319', Name: 'TEFL' },
  { Id: 3587, Code: '2319', Name: 'Tutor, adult education centre' },
  { Id: 3588, Code: '2319', Name: 'Tutor, education, adult' },
  { Id: 3589, Code: '2319', Name: 'Tutor, ESOL' },
  { Id: 3590, Code: '2319', Name: 'Tutor, home' },
  { Id: 3591, Code: '2319', Name: 'Tutor, private' },
  { Id: 3592, Code: '2319', Name: 'Verifier, educational establishments' },
  { Id: 3593, Code: '2319', Name: 'Worker, drama' },
  { Id: 3594, Code: '2412', Name: 'Advocate' },
  { Id: 3595, Code: '2412', Name: 'Advocate, crown' },
  { Id: 3596, Code: '2412', Name: 'Advocate, legal' },
  { Id: 3597, Code: '2412', Name: 'Barrister' },
  { Id: 3598, Code: '2412', Name: 'Barrister-at-law' },
  { Id: 3599, Code: '2412', Name: 'Chairman, APPEALS TRIBUNAL, INQUIRY' },
  { Id: 3600, Code: '2412', Name: 'Clerk, sheriff, SCOTLAND' },
  { Id: 3601, Code: '2412', Name: 'Coroner' },
  { Id: 3602, Code: '2412', Name: "Counsel, Queen's" },
  { Id: 3603, Code: '2412', Name: 'JP, STIPENDIARY' },
  { Id: 3604, Code: '2412', Name: 'Judge, circuit' },
  { Id: 3605, Code: '2412', Name: 'Judge, district' },
  { Id: 3606, Code: '2412', Name: 'Judge, legal services' },
  { Id: 3607, Code: '2412', Name: 'Justice of the Peace, STIPENDIARY' },
  { Id: 3608, Code: '2412', Name: 'Magistrate, STIPENDIARY' },
  { Id: 3609, Code: '2412', Name: 'Master, high courts' },
  { Id: 3610, Code: '2412', Name: 'Member of the Inner Temple' },
  { Id: 3611, Code: '2412', Name: 'Procurator fiscal' },
  { Id: 3612, Code: '2412', Name: 'Prosecutor, Crown' },
  { Id: 3613, Code: '2412', Name: 'QC' },
  { Id: 3614, Code: '2412', Name: "Queen's Counsel" },
  { Id: 3615, Code: '2412', Name: 'Recorder, legal services' },
  { Id: 3616, Code: '2412', Name: 'Referee, official, legal services' },
  { Id: 3617, Code: '2412', Name: 'Registrar, court, county' },
  { Id: 3618, Code: '2412', Name: 'Registrar, legal services' },
  { Id: 3619, Code: '2412', Name: 'Registrar, probate' },
  { Id: 3620, Code: '2412', Name: 'Sheriff, Scottish Courts' },
  { Id: 3621, Code: '2412', Name: 'Sheriff-substitute, Scottish Courts' },
  { Id: 3622, Code: '2412', Name: 'Solicitor of the Supreme Court' },
  { Id: 3623, Code: '2412', Name: 'Writer to the Signet' },
  { Id: 3624, Code: '2413', Name: 'Clerk, managing, QUALIFIED SOLICITOR' },
  { Id: 3625, Code: '2413', Name: 'Solicitor' },
  { Id: 3626, Code: '2413', Name: 'Solicitor to the Council' },
  { Id: 3627, Code: '2413', Name: 'Solicitor-partner' },
  { Id: 3628, Code: '2419', Name: 'Accountant of Court, SCOTLAND' },
  { Id: 3629, Code: '2419', Name: 'Adjudicator, parking' },
  { Id: 3630, Code: '2419', Name: 'Adviser, LAW' },
  { Id: 3631, Code: '2419', Name: 'Adviser, legal' },
  { Id: 3632, Code: '2419', Name: 'Adviser, legal, court' },
  { Id: 3633, Code: '2419', Name: 'Agent, law' },
  { Id: 3634, Code: '2419', Name: 'Assistant, court, QUALIFIED' },
  { Id: 3635, Code: '2419', Name: 'Attorney' },
  { Id: 3636, Code: '2419', Name: 'Auditor of Court, SCOTLAND' },
  { Id: 3637, Code: '2419', Name: 'Clerk of arraigns' },
  { Id: 3638, Code: '2419', Name: 'Clerk of the court' },
  { Id: 3639, Code: '2419', Name: 'Clerk of the peace' },
  { Id: 3640, Code: '2419', Name: 'Clerk to the justices' },
  { Id: 3641, Code: '2419', Name: 'Clerk, articled' },
  { Id: 3642, Code: '2419', Name: 'Clerk, chief, courts of justice' },
  { Id: 3643, Code: '2419', Name: 'Clerk, court, QUALIFIED' },
  { Id: 3644, Code: '2419', Name: "Clerk, justice's" },
  { Id: 3645, Code: '2419', Name: 'Clerk, law, ARTICLED' },
  { Id: 3646, Code: '2419', Name: "Clerk, magistrate's" },
  { Id: 3647, Code: '2419', Name: 'Clerk, sessions, quarter' },
  { Id: 3648, Code: '2419', Name: "Clerk, solicitor's, ARTICLED" },
  { Id: 3649, Code: '2419', Name: 'Commissioner of oaths' },
  { Id: 3650, Code: '2419', Name: 'Commissioner, legal services' },
  { Id: 3651, Code: '2419', Name: 'Consultant, immigration' },
  { Id: 3652, Code: '2419', Name: 'Consultant, legal' },
  { Id: 3653, Code: '2419', Name: 'Coordinator, legal' },
  { Id: 3654, Code: '2419', Name: 'Counsel, general' },
  { Id: 3655, Code: '2419', Name: 'Counsel, legal' },
  { Id: 3656, Code: '2419', Name: 'Draftsman, parliamentary' },
  { Id: 3657, Code: '2419', Name: 'Executive, legal, chartered' },
  { Id: 3658, Code: '2419', Name: 'Executive, legal, PROFESSIONAL' },
  { Id: 3659, Code: '2419', Name: 'Lawyer' },
  { Id: 3660, Code: '2419', Name: 'Notary' },
  { Id: 3661, Code: '2419', Name: 'Owner, legal services' },
  { Id: 3662, Code: '2419', Name: "Reporter to the Children's Panel" },
  { Id: 3663, Code: '2419', Name: 'Reporter, children�s, SCOTLAND' },
  { Id: 3664, Code: '2421', Name: 'ACA' },
  { Id: 3665, Code: '2421', Name: 'Accountant, bank' },
  { Id: 3666, Code: '2421', Name: 'Accountant, borough' },
  { Id: 3667, Code: '2421', Name: 'Accountant, branch' },
  { Id: 3668, Code: '2421', Name: 'Accountant, certified' },
  { Id: 3669, Code: '2421', Name: 'Accountant, chartered' },
  { Id: 3670, Code: '2421', Name: 'Accountant, chief' },
  { Id: 3671, Code: '2421', Name: 'Accountant, chief, group' },
  { Id: 3672, Code: '2421', Name: 'Accountant, company' },
  {
    Id: 3673,
    Code: '2421',
    Name: 'Accountant, cost and management, QUALIFIED'
  },
  { Id: 3674, Code: '2421', Name: 'Accountant, cost and works, QUALIFIED' },
  { Id: 3675, Code: '2421', Name: 'Accountant, cost, QUALIFIED' },
  { Id: 3676, Code: '2421', Name: 'Accountant, district' },
  { Id: 3677, Code: '2421', Name: 'Accountant, financial' },
  {
    Id: 3678,
    Code: '2421',
    Name: 'Accountant, financial, coal mine, QUALIFIED'
  },
  { Id: 3679, Code: '2421', Name: 'Accountant, forensic' },
  { Id: 3680, Code: '2421', Name: 'Accountant, fund' },
  { Id: 3681, Code: '2421', Name: 'Accountant, government' },
  { Id: 3682, Code: '2421', Name: 'Accountant, group, QUALIFIED' },
  { Id: 3683, Code: '2421', Name: 'Accountant, incorporated' },
  { Id: 3684, Code: '2421', Name: 'Accountant, management, chartered' },
  { Id: 3685, Code: '2421', Name: 'Accountant, management, QUALIFIED' },
  { Id: 3686, Code: '2421', Name: 'Accountant, principal' },
  { Id: 3687, Code: '2421', Name: 'Accountant, QUALIFIED' },
  {
    Id: 3688,
    Code: '2421',
    Name: 'Accountant, QUALIFIED, COST AND WORKS ACCOUNTANCY'
  },
  {
    Id: 3689,
    Code: '2421',
    Name: 'Accountant, QUALIFIED, MANAGEMENT ACCOUNTANCY'
  },
  { Id: 3690, Code: '2421', Name: 'Accountant, treasury' },
  { Id: 3691, Code: '2421', Name: 'Accountant, works, QUALIFIED' },
  { Id: 3692, Code: '2421', Name: 'Accountant-secretary, coal mine' },
  { Id: 3693, Code: '2421', Name: 'ACWA' },
  { Id: 3694, Code: '2421', Name: 'Administrator, insolvency, QUALIFIED' },
  { Id: 3695, Code: '2421', Name: 'Adviser, technical, ACCOUNTANCY' },
  { Id: 3696, Code: '2421', Name: 'ASAA' },
  { Id: 3697, Code: '2421', Name: 'Auditor and accountant, QUALIFIED' },
  { Id: 3698, Code: '2421', Name: 'Auditor, chief, coal mine' },
  { Id: 3699, Code: '2421', Name: 'Auditor, government' },
  { Id: 3700, Code: '2421', Name: 'Auditor, internal, QUALIFIED' },
  { Id: 3701, Code: '2421', Name: 'Auditor, local government' },
  { Id: 3702, Code: '2421', Name: 'Auditor, QUALIFIED' },
  { Id: 3703, Code: '2421', Name: 'Clerk, articled, accountancy' },
  { Id: 3704, Code: '2421', Name: 'Clerk, audit, QUALIFIED' },
  { Id: 3705, Code: '2421', Name: 'Consultant, accountancy' },
  { Id: 3706, Code: '2421', Name: 'Consultant, accounting' },
  { Id: 3707, Code: '2421', Name: 'Consultant, audit' },
  { Id: 3708, Code: '2421', Name: 'Consultant, cost, QUALIFIED' },
  { Id: 3709, Code: '2421', Name: 'Controller, financial, QUALIFIED' },
  { Id: 3710, Code: '2421', Name: 'Controller, unit, business' },
  { Id: 3711, Code: '2421', Name: 'Examiner, audit, DETR' },
  { Id: 3712, Code: '2421', Name: 'Examiner, bankruptcy' },
  { Id: 3713, Code: '2421', Name: 'Examiner, insolvency' },
  { Id: 3714, Code: '2421', Name: 'Executive, audit' },
  { Id: 3715, Code: '2421', Name: 'Executive, finance, corporate, QUALIFIED' },
  { Id: 3716, Code: '2421', Name: 'FCA' },
  { Id: 3717, Code: '2421', Name: 'FCWA' },
  { Id: 3718, Code: '2421', Name: 'FSAA' },
  { Id: 3719, Code: '2421', Name: 'Inspector of audits, DETR' },
  { Id: 3720, Code: '2421', Name: 'Liquidator, company' },
  { Id: 3721, Code: '2421', Name: 'Manager, audit, internal' },
  { Id: 3722, Code: '2421', Name: 'Manager, insolvency' },
  { Id: 3723, Code: '2421', Name: 'Officer, development, cost, coal mine' },
  { Id: 3724, Code: '2421', Name: 'Officer, expenditure, coal mine' },
  { Id: 3725, Code: '2421', Name: 'Practitioner, insolvency' },
  { Id: 3726, Code: '2421', Name: 'Receiver, Board of Trade' },
  { Id: 3727, Code: '2421', Name: 'Receiver, official' },
  { Id: 3728, Code: '2423', Name: 'Adviser, business' },
  { Id: 3729, Code: '2423', Name: 'Adviser, development, management' },
  { Id: 3730, Code: '2423', Name: 'Adviser, management' },
  { Id: 3731, Code: '2423', Name: 'Adviser, services, management' },
  { Id: 3732, Code: '2423', Name: 'Analyst, business' },
  { Id: 3733, Code: '2423', Name: 'Analyst, business, digital' },
  { Id: 3734, Code: '2423', Name: 'Analyst, catastrophe' },
  { Id: 3735, Code: '2423', Name: 'Analyst, commercial' },
  { Id: 3736, Code: '2423', Name: 'Analyst, intelligence, business' },
  { Id: 3737, Code: '2423', Name: 'Analyst, risk' },
  { Id: 3738, Code: '2423', Name: 'Analyst, risk, financial' },
  { Id: 3739, Code: '2423', Name: 'Analyst, trading' },
  { Id: 3740, Code: '2423', Name: 'Architect, business' },
  { Id: 3741, Code: '2423', Name: 'Consultant, business' },
  { Id: 3742, Code: '2423', Name: 'Consultant, intelligence, business' },
  { Id: 3743, Code: '2423', Name: 'Consultant, management' },
  { Id: 3744, Code: '2423', Name: 'Consultant, management consultancy' },
  { Id: 3745, Code: '2423', Name: 'Consultant, services, management' },
  { Id: 3746, Code: '2423', Name: 'Controller, services, management' },
  { Id: 3747, Code: '2423', Name: 'Developer, quantitative' },
  { Id: 3748, Code: '2423', Name: 'Executive, intelligence, business' },
  { Id: 3749, Code: '2423', Name: 'Facilitator, audit, clinical' },
  { Id: 3750, Code: '2423', Name: 'Facilitator, effectiveness, clinical' },
  { Id: 3751, Code: '2423', Name: 'Facilitator, governance, clinical' },
  { Id: 3752, Code: '2423', Name: 'Leader, team, consultancy, management' },
  { Id: 3753, Code: '2423', Name: 'Leader, team, management consultancy' },
  { Id: 3754, Code: '2423', Name: 'Manager, continuity, business' },
  { Id: 3755, Code: '2423', Name: 'Manager, improvement, business' },
  { Id: 3756, Code: '2423', Name: 'Manager, improvement, continuous' },
  { Id: 3757, Code: '2423', Name: 'Manager, intelligence, business' },
  { Id: 3758, Code: '2423', Name: 'Officer, intelligence, trade' },
  { Id: 3759, Code: '2423', Name: 'Officer, services, management' },
  { Id: 3760, Code: '2423', Name: 'Surveyor, risk, insurance' },
  { Id: 3761, Code: '2424', Name: 'Coordinator, programme' },
  { Id: 3762, Code: '2424', Name: 'Coordinator, project, clinical' },
  { Id: 3763, Code: '2424', Name: 'Coordinator, study, clinical' },
  { Id: 3764, Code: '2424', Name: 'Coordinator, trial, clinical' },
  { Id: 3765, Code: '2424', Name: 'Coordinator, trials, clinical' },
  { Id: 3766, Code: '2424', Name: 'Director, programme' },
  { Id: 3767, Code: '2424', Name: 'Director, project' },
  { Id: 3768, Code: '2424', Name: 'Head of PMO' },
  { Id: 3769, Code: '2424', Name: 'Head of programme management office' },
  { Id: 3770, Code: '2424', Name: 'Manager, analysis, risk' },
  { Id: 3771, Code: '2424', Name: 'Manager, assurance, risk' },
  { Id: 3772, Code: '2424', Name: 'Manager, business, support, project' },
  { Id: 3773, Code: '2424', Name: 'Manager, change' },
  { Id: 3774, Code: '2424', Name: 'Manager, change, business' },
  { Id: 3775, Code: '2424', Name: 'Manager, contract, cleaning services' },
  { Id: 3776, Code: '2424', Name: 'Manager, contract, PROGRAMME MANAGEMENT' },
  { Id: 3777, Code: '2424', Name: 'Manager, contracts, cleaning services' },
  { Id: 3778, Code: '2424', Name: 'Manager, contracts, security services' },
  {
    Id: 3779,
    Code: '2424',
    Name: 'Manager, delivery, client, PROJECT MANAGEMENT'
  },
  { Id: 3780, Code: '2424', Name: 'Manager, delivery, project' },
  { Id: 3781, Code: '2424', Name: 'Manager, development, investor' },
  { Id: 3782, Code: '2424', Name: 'Manager, fraud' },
  { Id: 3783, Code: '2424', Name: 'Manager, office, programme' },
  { Id: 3784, Code: '2424', Name: 'Manager, office, project' },
  { Id: 3785, Code: '2424', Name: 'Manager, package, work, PROFESSIONAL' },
  { Id: 3786, Code: '2424', Name: 'Manager, PMO' },
  { Id: 3787, Code: '2424', Name: 'Manager, portfolio' },
  { Id: 3788, Code: '2424', Name: 'Manager, programme' },
  { Id: 3789, Code: '2424', Name: 'Manager, project' },
  { Id: 3790, Code: '2424', Name: 'Manager, project, financial services' },
  { Id: 3791, Code: '2424', Name: 'Manager, project, local government' },
  {
    Id: 3792,
    Code: '2424',
    Name: 'Manager, project, local government social services, RESIDENTIAL'
  },
  {
    Id: 3793,
    Code: '2424',
    Name: 'Manager, project, local government: social services'
  },
  { Id: 3794, Code: '2424', Name: 'Manager, project, publishing' },
  { Id: 3795, Code: '2424', Name: 'Manager, project, university' },
  { Id: 3796, Code: '2424', Name: 'Manager, projects' },
  { Id: 3797, Code: '2424', Name: 'Manager, risk' },
  { Id: 3798, Code: '2424', Name: 'Manager, risk, credit' },
  { Id: 3799, Code: '2424', Name: 'Manager, support, project' },
  { Id: 3800, Code: '2424', Name: 'Master, scrum' },
  { Id: 3801, Code: '2424', Name: 'Officer, delivery, project' },
  { Id: 3802, Code: '2424', Name: 'Officer, knowledge, chief' },
  { Id: 3803, Code: '2424', Name: 'Officer, programme' },
  { Id: 3804, Code: '2424', Name: 'Officer, support, research' },
  { Id: 3805, Code: '2425', Name: 'Actuary' },
  { Id: 3806, Code: '2425', Name: 'Adviser, economic' },
  { Id: 3807, Code: '2425', Name: 'Adviser, statistical' },
  { Id: 3808, Code: '2425', Name: 'Analyst, actuarial' },
  { Id: 3809, Code: '2425', Name: 'Analyst, campaign' },
  { Id: 3810, Code: '2425', Name: 'Analyst, digital' },
  { Id: 3811, Code: '2425', Name: 'Analyst, economic' },
  { Id: 3812, Code: '2425', Name: 'Analyst, political' },
  { Id: 3813, Code: '2425', Name: 'Analyst, quantitative' },
  { Id: 3814, Code: '2425', Name: 'Analyst, statistical' },
  { Id: 3815, Code: '2425', Name: 'Analyst, web' },
  { Id: 3816, Code: '2425', Name: 'Assistant, actuarial' },
  { Id: 3817, Code: '2425', Name: 'Assistant, economic' },
  { Id: 3818, Code: '2425', Name: 'Assistant, statistical' },
  { Id: 3819, Code: '2425', Name: 'Bioinformatician' },
  { Id: 3820, Code: '2425', Name: 'Biostatistician' },
  { Id: 3821, Code: '2425', Name: 'Consultant, actuarial' },
  { Id: 3822, Code: '2425', Name: 'Consultant, economic' },
  { Id: 3823, Code: '2425', Name: 'Consultant, statistical' },
  { Id: 3824, Code: '2425', Name: 'Controller, economics' },
  { Id: 3825, Code: '2425', Name: 'Controller, statistical' },
  { Id: 3826, Code: '2425', Name: 'Demographer' },
  { Id: 3827, Code: '2425', Name: 'Economist' },
  { Id: 3828, Code: '2425', Name: 'Engineer, financial' },
  { Id: 3829, Code: '2425', Name: 'Forecaster, economic' },
  { Id: 3830, Code: '2425', Name: 'Head of statistics' },
  { Id: 3831, Code: '2425', Name: 'Informatician' },
  { Id: 3832, Code: '2425', Name: 'Manager, analytics, web' },
  { Id: 3833, Code: '2425', Name: 'Mathematician' },
  { Id: 3834, Code: '2425', Name: 'Modeller, statistical' },
  { Id: 3835, Code: '2425', Name: 'Officer, development, economic' },
  { Id: 3836, Code: '2425', Name: 'Officer, statistical, coal mine' },
  { Id: 3837, Code: '2425', Name: 'Officer, statistical, government' },
  { Id: 3838, Code: '2425', Name: 'Officer, support, bioinformatics' },
  { Id: 3839, Code: '2425', Name: 'Planner, economic' },
  { Id: 3840, Code: '2425', Name: 'Scientist, data' },
  { Id: 3841, Code: '2425', Name: 'Statistician' },
  { Id: 3842, Code: '2425', Name: 'Strategist, quantitative' },
  { Id: 3843, Code: '2426', Name: 'Analyst, computer, forensic' },
  { Id: 3844, Code: '2426', Name: 'Analyst, crime, police force' },
  { Id: 3845, Code: '2426', Name: 'Analyst, forensic, digital' },
  { Id: 3846, Code: '2426', Name: 'Analyst, information' },
  { Id: 3847, Code: '2426', Name: 'Analyst, intelligence' },
  { Id: 3848, Code: '2426', Name: 'Analyst, intelligence, criminal' },
  { Id: 3849, Code: '2426', Name: 'Analyst, sports' },
  { Id: 3850, Code: '2426', Name: 'Assistant, research' },
  { Id: 3851, Code: '2426', Name: 'Assistant, research, broadcasting' },
  { Id: 3852, Code: '2426', Name: 'Assistant, research, journalism' },
  {
    Id: 3853,
    Code: '2426',
    Name: 'Assistant, research, printing and publishing'
  },
  { Id: 3854, Code: '2426', Name: 'Associate, research' },
  { Id: 3855, Code: '2426', Name: 'Associate, research, broadcasting' },
  { Id: 3856, Code: '2426', Name: 'Associate, research, journalism' },
  {
    Id: 3857,
    Code: '2426',
    Name: 'Associate, research, printing and publishing'
  },
  { Id: 3858, Code: '2426', Name: 'Consultant, research' },
  { Id: 3859, Code: '2426', Name: 'Fellow, RESEARCH' },
  { Id: 3860, Code: '2426', Name: 'Inventor' },
  { Id: 3861, Code: '2426', Name: 'Investigator, forensic, computer' },
  { Id: 3862, Code: '2426', Name: 'Officer, intelligence, government' },
  { Id: 3863, Code: '2426', Name: 'Officer, intelligence, grade II, MOD' },
  { Id: 3864, Code: '2426', Name: 'Officer, research' },
  { Id: 3865, Code: '2426', Name: 'Officer, research, broadcasting' },
  { Id: 3866, Code: '2426', Name: 'Officer, research, journalism' },
  {
    Id: 3867,
    Code: '2426',
    Name: 'Officer, research, printing and publishing'
  },
  { Id: 3868, Code: '2426', Name: 'Practitioner, forensic, police force' },
  { Id: 3869, Code: '2426', Name: 'Researcher' },
  { Id: 3870, Code: '2426', Name: 'Researcher, broadcasting' },
  { Id: 3871, Code: '2426', Name: 'Researcher, games, broadcasting' },
  { Id: 3872, Code: '2426', Name: 'Researcher, games, ENTERTAINMENT' },
  { Id: 3873, Code: '2426', Name: 'Researcher, journalism' },
  { Id: 3874, Code: '2426', Name: 'Researcher, picture' },
  { Id: 3875, Code: '2426', Name: 'Researcher, printing and publishing' },
  { Id: 3876, Code: '2426', Name: 'Technician, research' },
  { Id: 3877, Code: '2426', Name: 'Technician, research and development' },
  { Id: 3878, Code: '2426', Name: 'Worker, research' },
  { Id: 3879, Code: '2426', Name: 'Worker, research, FIRE PROTECTION' },
  { Id: 3880, Code: '2426', Name: 'Worker, research, FUEL' },
  { Id: 3881, Code: '2426', Name: 'Worker, research, PHOTOGRAPHIC' },
  { Id: 3882, Code: '2426', Name: 'Worker, research, PLASTICS' },
  { Id: 3883, Code: '2426', Name: 'Worker, research, TEXTILE' },
  { Id: 3884, Code: '2429', Name: 'A, Cabinet Office' },
  { Id: 3885, Code: '2429', Name: 'A, Northern Ireland Office' },
  { Id: 3886, Code: '2429', Name: 'A1, Dept for International Development' },
  { Id: 3887, Code: '2429', Name: 'A2, Dept for International Development' },
  { Id: 3888, Code: '2429', Name: 'Administrator, arts' },
  {
    Id: 3889,
    Code: '2429',
    Name: 'Administrator, registration, senior, Land Registry'
  },
  { Id: 3890, Code: '2429', Name: 'Adviser, conflict' },
  { Id: 3891, Code: '2429', Name: 'Adviser, policy, government' },
  { Id: 3892, Code: '2429', Name: 'Adviser, policy, local government' },
  { Id: 3893, Code: '2429', Name: 'Advocate, policy' },
  {
    Id: 3894,
    Code: '2429',
    Name: 'Assistant, secretarial, company, QUALIFIED'
  },
  { Id: 3895, Code: '2429', Name: 'Band 1, Health and Safety Executive' },
  { Id: 3896, Code: '2429', Name: 'Band 2, Health and Safety Executive' },
  { Id: 3897, Code: '2429', Name: 'Band 2C, Meteorological Office' },
  { Id: 3898, Code: '2429', Name: 'Band F, Ministry of Justice' },
  { Id: 3899, Code: '2429', Name: 'Band F, National Assembly for Wales' },
  { Id: 3900, Code: '2429', Name: 'Band G, Ministry of Justice' },
  { Id: 3901, Code: '2429', Name: 'Band G, National Assembly for Wales' },
  { Id: 3902, Code: '2429', Name: 'C1, Scottish Government' },
  { Id: 3903, Code: '2429', Name: 'C2, Scottish Government' },
  { Id: 3904, Code: '2429', Name: 'C3, Scottish Government' },
  { Id: 3905, Code: '2429', Name: 'Consul' },
  { Id: 3906, Code: '2429', Name: 'D1, Jobcentre Plus' },
  { Id: 3907, Code: '2429', Name: 'D2, Jobcentre Plus' },
  { Id: 3908, Code: '2429', Name: 'D2, Office for National Statistics' },
  { Id: 3909, Code: '2429', Name: 'D3, Jobcentre Plus' },
  { Id: 3910, Code: '2429', Name: 'D3, Office for National Statistics' },
  { Id: 3911, Code: '2429', Name: 'D4, Jobcentre Plus' },
  { Id: 3912, Code: '2429', Name: 'D4, Office for National Statistics' },
  { Id: 3913, Code: '2429', Name: 'Director, regional, government' },
  {
    Id: 3914,
    Code: '2429',
    Name: 'Executive, registration, senior, Land Registry'
  },
  { Id: 3915, Code: '2429', Name: 'Grade 6, Foreign and Commonwealth Office' },
  { Id: 3916, Code: '2429', Name: 'Grade 6, government' },
  { Id: 3917, Code: '2429', Name: 'Grade 7, Foreign and Commonwealth Office' },
  { Id: 3918, Code: '2429', Name: 'Grade 7, government' },
  { Id: 3919, Code: '2429', Name: 'Grade A, DCMS' },
  {
    Id: 3920,
    Code: '2429',
    Name: 'Manager, group, accounting, local government'
  },
  {
    Id: 3921,
    Code: '2429',
    Name: 'Manager, information, management consultancy'
  },
  { Id: 3922, Code: '2429', Name: 'Manager, protection, public' },
  { Id: 3923, Code: '2429', Name: 'Manager, regeneration, local government' },
  { Id: 3924, Code: '2429', Name: 'Manager, service, government' },
  { Id: 3925, Code: '2429', Name: 'Manager, service, local government' },
  { Id: 3926, Code: '2429', Name: 'Officer, area, local government' },
  { Id: 3927, Code: '2429', Name: 'Officer, committee' },
  {
    Id: 3928,
    Code: '2429',
    Name: 'Officer, committee, principal, local government'
  },
  { Id: 3929, Code: '2429', Name: 'Officer, compliance, information' },
  { Id: 3930, Code: '2429', Name: 'Officer, consular' },
  { Id: 3931, Code: '2429', Name: 'Officer, divisional, local government' },
  { Id: 3932, Code: '2429', Name: 'Officer, executive, chief, government' },
  { Id: 3933, Code: '2429', Name: 'Officer, executive, chief, PO' },
  { Id: 3934, Code: '2429', Name: 'Officer, governance' },
  { Id: 3935, Code: '2429', Name: 'Officer, group, chief, MOD' },
  {
    Id: 3936,
    Code: '2429',
    Name: 'Officer, heritage and culture, principal, local government'
  },
  { Id: 3937, Code: '2429', Name: 'Officer, policy' },
  { Id: 3938, Code: '2429', Name: 'Officer, principal, government' },
  {
    Id: 3939,
    Code: '2429',
    Name: 'Officer, regeneration, senior, local government'
  },
  { Id: 3940, Code: '2429', Name: 'Officer, registration, local government' },
  { Id: 3941, Code: '2429', Name: 'Payband 4, Dept of Health' },
  { Id: 3942, Code: '2429', Name: 'Principal, government' },
  { Id: 3943, Code: '2429', Name: 'RBDM' },
  {
    Id: 3944,
    Code: '2429',
    Name: 'Registrar of births, deaths, marriages and civil partnerships'
  },
  { Id: 3945, Code: '2429', Name: 'Registrar of deeds' },
  { Id: 3946, Code: '2429', Name: 'Registrar of marriages' },
  { Id: 3947, Code: '2429', Name: 'Registrar, additional' },
  { Id: 3948, Code: '2429', Name: 'Registrar, government' },
  { Id: 3949, Code: '2429', Name: 'Registrar, local government' },
  { Id: 3950, Code: '2429', Name: 'Registrar, superintendent' },
  { Id: 3951, Code: '2429', Name: 'Secretary, assistant, local government' },
  { Id: 3952, Code: '2429', Name: 'Secretary, chartered' },
  { Id: 3953, Code: '2429', Name: 'Secretary, company, assistant, QUALIFIED' },
  { Id: 3954, Code: '2429', Name: 'Secretary, company, QUALIFIED' },
  { Id: 3955, Code: '2429', Name: 'Secretary, private, principal' },
  { Id: 3956, Code: '2429', Name: 'Secretary, third' },
  { Id: 3957, Code: '2429', Name: 'Servant, civil, GRADE 6, 7' },
  { Id: 3958, Code: '2429', Name: 'SRA1, Land Registry' },
  { Id: 3959, Code: '2429', Name: 'SRA2, Land Registry' },
  { Id: 3960, Code: '2429', Name: 'SRE, Land Registry' },
  { Id: 3961, Code: '2431', Name: 'Architect' },
  { Id: 3962, Code: '2431', Name: 'Architect, chartered' },
  { Id: 3963, Code: '2431', Name: 'Architect, landscape' },
  { Id: 3964, Code: '2431', Name: 'Consultant, architectural' },
  { Id: 3965, Code: '2431', Name: 'Designer, architectural service' },
  { Id: 3966, Code: '2431', Name: 'Designer, concrete, reinforced' },
  { Id: 3967, Code: '2431', Name: 'Designer, formwork' },
  { Id: 3968, Code: '2431', Name: 'Designer, landscape' },
  { Id: 3969, Code: '2431', Name: 'Designer, structural' },
  { Id: 3970, Code: '2431', Name: 'Superintendent, MOD Air designs office' },
  { Id: 3971, Code: '2432', Name: 'Adviser, planning, county' },
  { Id: 3972, Code: '2432', Name: 'Consultant, planning, town' },
  { Id: 3973, Code: '2432', Name: 'Designer, urban' },
  { Id: 3974, Code: '2432', Name: 'Officer, control, development' },
  {
    Id: 3975,
    Code: '2432',
    Name: 'Officer, planning, local government: building and contracting'
  },
  { Id: 3976, Code: '2432', Name: 'Planner, enforcement' },
  { Id: 3977, Code: '2432', Name: 'Planner, town' },
  { Id: 3978, Code: '2432', Name: 'Planner, town and country' },
  { Id: 3979, Code: '2432', Name: 'Planner, town planning' },
  { Id: 3980, Code: '2433', Name: "Assistant, surveyor's, quantity" },
  { Id: 3981, Code: '2433', Name: 'Assistant, technical, QUANTITY SURVEYING' },
  {
    Id: 3982,
    Code: '2433',
    Name: "Assistant, technical, surveyor's, quantity"
  },
  { Id: 3983, Code: '2433', Name: 'Surveyor, bonus' },
  { Id: 3984, Code: '2433', Name: 'Surveyor, measuring' },
  { Id: 3985, Code: '2433', Name: 'Surveyor, quantity' },
  { Id: 3986, Code: '2433', Name: 'Surveyor, quantity surveying' },
  { Id: 3987, Code: '2433', Name: 'Surveyor, quantity, chartered' },
  { Id: 3988, Code: '2433', Name: 'Taker-off, QUANTITY SURVEYING' },
  { Id: 3989, Code: '2433', Name: 'Technician, survey, QUANTITY SURVEYING' },
  { Id: 3990, Code: '2433', Name: 'Technician, surveying, quantity' },
  { Id: 3991, Code: '2433', Name: 'Worker-up, QUANTITY SURVEYING' },
  {
    Id: 3992,
    Code: '2434',
    Name: "Assistant, professional, local government: surveyor's dept"
  },
  { Id: 3993, Code: '2434', Name: 'Commissioner, land' },
  { Id: 3994, Code: '2434', Name: 'Estimator and surveyor' },
  { Id: 3995, Code: '2434', Name: 'Hydrographer' },
  {
    Id: 3996,
    Code: '2434',
    Name: "Inspector, local government: surveyor's dept"
  },
  { Id: 3997, Code: '2434', Name: 'Officer, hydrographic' },
  { Id: 3998, Code: '2434', Name: 'Officer, land and minerals, coal mine' },
  { Id: 3999, Code: '2434', Name: 'Officer, survey, government' },
  { Id: 4000, Code: '2434', Name: 'Surveyor' },
  { Id: 4001, Code: '2434', Name: 'Surveyor and estimator' },
  { Id: 4002, Code: '2434', Name: 'Surveyor and water engineer' },
  { Id: 4003, Code: '2434', Name: 'Surveyor, agricultural' },
  { Id: 4004, Code: '2434', Name: 'Surveyor, building' },
  { Id: 4005, Code: '2434', Name: 'Surveyor, cartographic' },
  { Id: 4006, Code: '2434', Name: 'Surveyor, chartered' },
  { Id: 4007, Code: '2434', Name: 'Surveyor, colliery' },
  { Id: 4008, Code: '2434', Name: 'Surveyor, estate' },
  { Id: 4009, Code: '2434', Name: 'Surveyor, group, coal mine' },
  { Id: 4010, Code: '2434', Name: 'Surveyor, hydrographic' },
  { Id: 4011, Code: '2434', Name: 'Surveyor, land' },
  { Id: 4012, Code: '2434', Name: 'Surveyor, mineral' },
  { Id: 4013, Code: '2434', Name: 'Surveyor, mining' },
  { Id: 4014, Code: '2434', Name: 'Surveyor, photogrammetric' },
  { Id: 4015, Code: '2434', Name: 'Surveyor, photographic' },
  { Id: 4016, Code: '2434', Name: 'Surveyor, planning and development' },
  { Id: 4017, Code: '2434', Name: 'Surveyor, property' },
  { Id: 4018, Code: '2434', Name: 'Surveyor, river' },
  { Id: 4019, Code: '2434', Name: 'Surveyor, rural' },
  { Id: 4020, Code: '2434', Name: 'Surveyor, topographic' },
  { Id: 4021, Code: '2434', Name: 'Surveyor, utilities' },
  { Id: 4022, Code: '2434', Name: 'Surveyor, utility' },
  { Id: 4023, Code: '2434', Name: 'Surveyor-engineer' },
  { Id: 4024, Code: '2434', Name: 'Topographer' },
  { Id: 4025, Code: '2435', Name: 'Technologist, architectural' },
  { Id: 4026, Code: '2436', Name: 'Clerk of works' },
  { Id: 4027, Code: '2436', Name: 'Engineer, civil, consulting' },
  {
    Id: 4028,
    Code: '2436',
    Name: 'Manager, commercial, building construction'
  },
  { Id: 4029, Code: '2436', Name: 'Manager, contract, building construction' },
  { Id: 4030, Code: '2436', Name: 'Manager, contracts, building' },
  { Id: 4031, Code: '2436', Name: 'Manager, contracts, building construction' },
  { Id: 4032, Code: '2436', Name: 'Manager, infrastructure, railways' },
  { Id: 4033, Code: '2436', Name: 'Manager, project, building construction' },
  { Id: 4034, Code: '2436', Name: 'Manager, projects, building construction' },
  { Id: 4035, Code: '2436', Name: 'Manager, proposals' },
  { Id: 4036, Code: '2436', Name: 'Planner, traffic' },
  { Id: 4037, Code: '2436', Name: 'Planner, transport' },
  { Id: 4038, Code: '2436', Name: 'Planner, transportation' },
  { Id: 4039, Code: '2442', Name: 'Almoner' },
  {
    Id: 4040,
    Code: '2442',
    Name: 'Coordinator, protection, adult, SOCIAL WORKER'
  },
  { Id: 4041, Code: '2442', Name: 'IRO, local government: social services' },
  { Id: 4042, Code: '2442', Name: 'Leader, SOCIAL WORKER' },
  {
    Id: 4043,
    Code: '2442',
    Name: 'Leader, team, local government: social services'
  },
  {
    Id: 4044,
    Code: '2442',
    Name: 'Manager, practice, local government: social services'
  },
  {
    Id: 4045,
    Code: '2442',
    Name: 'Manager, team, local government: social services'
  },
  { Id: 4046, Code: '2442', Name: 'Manager, team, work, social' },
  { Id: 4047, Code: '2442', Name: 'Officer, fostering' },
  {
    Id: 4048,
    Code: '2442',
    Name: 'Officer, review, independent, SOCIAL WORKER'
  },
  {
    Id: 4049,
    Code: '2442',
    Name: 'Officer, reviewing, independent, SOCIAL WORKER'
  },
  {
    Id: 4050,
    Code: '2442',
    Name: 'Officer, reviewing, local government: social services'
  },
  {
    Id: 4051,
    Code: '2442',
    Name: 'Practitioner, advanced, local government: social services'
  },
  {
    Id: 4052,
    Code: '2442',
    Name: 'Practitioner, senior, local government: social services'
  },
  { Id: 4053, Code: '2442', Name: 'Worker, case, family' },
  {
    Id: 4054,
    Code: '2442',
    Name: 'Worker, field, local government: social services'
  },
  { Id: 4055, Code: '2442', Name: 'Worker, social' },
  { Id: 4056, Code: '2442', Name: 'Worker, social, forensic' },
  { Id: 4057, Code: '2442', Name: 'Worker, social, health, mental' },
  { Id: 4058, Code: '2442', Name: 'Worker, social, medical' },
  { Id: 4059, Code: '2442', Name: 'Worker, social, psychiatric' },
  { Id: 4060, Code: '2443', Name: 'Inspector, National Probation Service' },
  { Id: 4061, Code: '2443', Name: 'Officer, justice, youth' },
  { Id: 4062, Code: '2443', Name: 'Officer, probation' },
  { Id: 4063, Code: '2443', Name: 'Officer, probation, chief' },
  {
    Id: 4064,
    Code: '2443',
    Name: 'Officer, service, community, probation service'
  },
  { Id: 4065, Code: '2444', Name: 'Abbot' },
  { Id: 4066, Code: '2444', Name: 'Archbishop' },
  { Id: 4067, Code: '2444', Name: 'Archdeacon' },
  { Id: 4068, Code: '2444', Name: 'Bishop' },
  { Id: 4069, Code: '2444', Name: 'Brother' },
  { Id: 4070, Code: '2444', Name: 'Canon' },
  { Id: 4071, Code: '2444', Name: 'Chaplain' },
  { Id: 4072, Code: '2444', Name: 'Clergyman' },
  { Id: 4073, Code: '2444', Name: 'Clerk in holy orders' },
  { Id: 4074, Code: '2444', Name: 'Curate' },
  { Id: 4075, Code: '2444', Name: 'Deacon' },
  { Id: 4076, Code: '2444', Name: 'Dean' },
  { Id: 4077, Code: '2444', Name: 'Ecclesiastic' },
  { Id: 4078, Code: '2444', Name: 'Friar' },
  { Id: 4079, Code: '2444', Name: 'Imam' },
  { Id: 4080, Code: '2444', Name: 'Incumbent' },
  { Id: 4081, Code: '2444', Name: 'Major, Salvation Army' },
  { Id: 4082, Code: '2444', Name: 'Member of Religious Community' },
  { Id: 4083, Code: '2444', Name: 'Minister of religion' },
  { Id: 4084, Code: '2444', Name: 'Minister, religious organisation' },
  { Id: 4085, Code: '2444', Name: 'Moderator, Presbyterian Church' },
  { Id: 4086, Code: '2444', Name: 'Monk' },
  { Id: 4087, Code: '2444', Name: 'Nun' },
  { Id: 4088, Code: '2444', Name: 'Officer, Army, Church' },
  { Id: 4089, Code: '2444', Name: 'Officer, Army, Salvation' },
  { Id: 4090, Code: '2444', Name: 'Officer, commanding, Salvation Army' },
  { Id: 4091, Code: '2444', Name: 'Pastor' },
  { Id: 4092, Code: '2444', Name: 'Practitioner, science, christian' },
  { Id: 4093, Code: '2444', Name: 'Prebendary' },
  { Id: 4094, Code: '2444', Name: 'Priest' },
  { Id: 4095, Code: '2444', Name: 'Prior' },
  { Id: 4096, Code: '2444', Name: 'Rabbi' },
  { Id: 4097, Code: '2444', Name: 'Rector' },
  { Id: 4098, Code: '2444', Name: 'Sister, religious organisation' },
  { Id: 4099, Code: '2444', Name: 'Spiritualist' },
  { Id: 4100, Code: '2444', Name: 'Superior, lady' },
  { Id: 4101, Code: '2444', Name: 'Superior, mother' },
  { Id: 4102, Code: '2444', Name: 'Theologian' },
  { Id: 4103, Code: '2444', Name: 'Vicar' },
  { Id: 4104, Code: '2449', Name: 'Adviser, court, family' },
  { Id: 4105, Code: '2449', Name: 'Adviser, protection, child' },
  { Id: 4106, Code: '2449', Name: 'Aid, family' },
  { Id: 4107, Code: '2449', Name: 'Aide, family' },
  { Id: 4108, Code: '2449', Name: 'Caseworker, family' },
  { Id: 4109, Code: '2449', Name: 'Consultant, protection, child' },
  { Id: 4110, Code: '2449', Name: 'Coordinator, disability' },
  { Id: 4111, Code: '2449', Name: 'Coordinator, inclusion' },
  { Id: 4112, Code: '2449', Name: 'Guardian ad litem' },
  { Id: 4113, Code: '2449', Name: "Guardian, children's" },
  { Id: 4114, Code: '2449', Name: 'Instructor, mobility, dog, guide' },
  { Id: 4115, Code: '2449', Name: 'Manager, justice, restorative' },
  { Id: 4116, Code: '2449', Name: 'Mediator, family' },
  { Id: 4117, Code: '2449', Name: 'Officer, adoption' },
  { Id: 4118, Code: '2449', Name: 'Officer, commission, care' },
  { Id: 4119, Code: '2449', Name: 'Officer, guardian ad litem and report' },
  { Id: 4120, Code: '2449', Name: 'Officer, health, mental' },
  { Id: 4121, Code: '2449', Name: 'Officer, offending, youth' },
  { Id: 4122, Code: '2449', Name: 'Officer, rehabilitation' },
  { Id: 4123, Code: '2449', Name: 'Officer, services, social' },
  { Id: 4124, Code: '2449', Name: 'Officer, team, offending, youth' },
  { Id: 4125, Code: '2449', Name: 'Organiser, district, COMMUNITY SERVICES' },
  { Id: 4126, Code: '2449', Name: 'Professional, years, early' },
  { Id: 4127, Code: '2449', Name: 'Worker, support, youth, PROFESSIONAL' },
  { Id: 4128, Code: '2449', Name: 'Worker, youth and community, PROFESSIONAL' },
  { Id: 4129, Code: '2449', Name: 'Worker, youth, professional' },
  { Id: 4130, Code: '2451', Name: 'Librarian' },
  { Id: 4131, Code: '2451', Name: 'Librarian, branch' },
  { Id: 4132, Code: '2451', Name: 'Librarian, chartered' },
  { Id: 4133, Code: '2451', Name: 'Librarian, film' },
  { Id: 4134, Code: '2451', Name: 'Librarian, hospital' },
  { Id: 4135, Code: '2451', Name: 'Librarian, magazine' },
  { Id: 4136, Code: '2451', Name: 'Librarian, media' },
  { Id: 4137, Code: '2451', Name: 'Librarian, newspaper' },
  { Id: 4138, Code: '2451', Name: 'Librarian, picture' },
  { Id: 4139, Code: '2451', Name: 'Librarian, tape, computer' },
  { Id: 4140, Code: '2451', Name: 'Librarian, tape, magnetic' },
  { Id: 4141, Code: '2451', Name: 'Librarian, technical' },
  { Id: 4142, Code: '2451', Name: 'Librarian, university' },
  { Id: 4143, Code: '2451', Name: 'Librarian, visual, audio' },
  { Id: 4144, Code: '2451', Name: 'Manager, resource, learning' },
  { Id: 4145, Code: '2451', Name: 'Scientist, information' },
  { Id: 4146, Code: '2451', Name: 'Superintendent, library' },
  { Id: 4147, Code: '2452', Name: 'Archivist' },
  { Id: 4148, Code: '2452', Name: 'Assistant, curatorial' },
  { Id: 4149, Code: '2452', Name: 'Conservator' },
  { Id: 4150, Code: '2452', Name: 'Curator' },
  { Id: 4151, Code: '2452', Name: 'Inspector of ancient monuments' },
  {
    Id: 4152,
    Code: '2452',
    Name: 'Investigator, Historical Monuments Commission'
  },
  { Id: 4153, Code: '2452', Name: 'Keeper, art gallery' },
  { Id: 4154, Code: '2452', Name: 'Keeper, museum' },
  { Id: 4155, Code: '2452', Name: 'Keeper, Public Record Office' },
  { Id: 4156, Code: '2452', Name: 'Officer, collection, museum' },
  { Id: 4157, Code: '2452', Name: 'Officer, conservation, museum' },
  { Id: 4158, Code: '2452', Name: 'Officer, education, art gallery, museum' },
  { Id: 4159, Code: '2452', Name: 'Officer, education, arts' },
  { Id: 4160, Code: '2452', Name: 'Officer, education, MUSEUM' },
  { Id: 4161, Code: '2452', Name: 'Officer, museum' },
  { Id: 4162, Code: '2452', Name: 'Officer, museums' },
  { Id: 4163, Code: '2461', Name: 'Consultant, assurance, quality' },
  { Id: 4164, Code: '2461', Name: 'Consultant, management, quality' },
  { Id: 4165, Code: '2461', Name: 'Consultant, quality' },
  {
    Id: 4166,
    Code: '2461',
    Name: 'Controller, maintenance, planned, coal mine'
  },
  { Id: 4167, Code: '2461', Name: 'Controller, project, metal trades' },
  { Id: 4168, Code: '2461', Name: 'Controller, quality, PROFESSIONAL' },
  { Id: 4169, Code: '2461', Name: 'Engineer, assurance, quality' },
  { Id: 4170, Code: '2461', Name: 'Engineer, control, quality' },
  { Id: 4171, Code: '2461', Name: 'Engineer, planning' },
  { Id: 4172, Code: '2461', Name: 'Engineer, planning, windfarm' },
  { Id: 4173, Code: '2461', Name: 'Engineer, PROFESSIONAL, QUALITY CONTROL' },
  { Id: 4174, Code: '2461', Name: 'Engineer, progress' },
  { Id: 4175, Code: '2461', Name: 'Engineer, quality' },
  { Id: 4176, Code: '2461', Name: 'Engineer, systems, quality' },
  {
    Id: 4177,
    Code: '2461',
    Name: 'Inspector, chief, engineering, PROFESSIONAL, QUALITY CONTROL'
  },
  { Id: 4178, Code: '2461', Name: 'Inspector, civil engineering' },
  { Id: 4179, Code: '2461', Name: 'Manager, engineering, quality' },
  { Id: 4180, Code: '2461', Name: 'Officer, assurance, quality, PROFESSIONAL' },
  { Id: 4181, Code: '2461', Name: 'Officer, control, quality, PROFESSIONAL' },
  { Id: 4182, Code: '2461', Name: 'Officer, planning, manufacturing' },
  { Id: 4183, Code: '2461', Name: 'Planner, engineering' },
  { Id: 4184, Code: '2461', Name: 'Planner, group, coal mine' },
  { Id: 4185, Code: '2461', Name: 'Planner, mine, coal mine' },
  { Id: 4186, Code: '2461', Name: 'Planner, offshore' },
  { Id: 4187, Code: '2461', Name: 'Planner, process' },
  { Id: 4188, Code: '2461', Name: 'Radiologist, industrial' },
  { Id: 4189, Code: '2461', Name: 'Technologist, clothing' },
  { Id: 4190, Code: '2461', Name: 'Technologist, garment' },
  { Id: 4191, Code: '2462', Name: 'Accreditor' },
  {
    Id: 4192,
    Code: '2462',
    Name: 'Adjudicator, NATIONAL INSURANCE REGULATIONS'
  },
  { Id: 4193, Code: '2462', Name: 'Adviser, regulatory' },
  { Id: 4194, Code: '2462', Name: 'Analyst, affairs, regulatory' },
  { Id: 4195, Code: '2462', Name: 'Analyst, surveillance, compliance' },
  { Id: 4196, Code: '2462', Name: 'Analyst, surveillance, market' },
  { Id: 4197, Code: '2462', Name: 'Associate, affairs, regulatory' },
  { Id: 4198, Code: '2462', Name: 'Associate, regulatory' },
  { Id: 4199, Code: '2462', Name: 'Attorney, mark, trade' },
  { Id: 4200, Code: '2462', Name: 'Attorney, patent' },
  { Id: 4201, Code: '2462', Name: 'Auditor, clinical' },
  { Id: 4202, Code: '2462', Name: 'Consultant, accreditation' },
  { Id: 4203, Code: '2462', Name: 'Coordinator, governance, clinical' },
  { Id: 4204, Code: '2462', Name: 'Executive, affairs, regulatory' },
  { Id: 4205, Code: '2462', Name: 'Executive, regulatory' },
  { Id: 4206, Code: '2462', Name: 'Manager, accreditation' },
  { Id: 4207, Code: '2462', Name: 'Manager, affairs, regulatory' },
  {
    Id: 4208,
    Code: '2462',
    Name: 'Manager, affairs, regulatory, pharmaceutical mfr'
  },
  { Id: 4209, Code: '2462', Name: 'Manager, asset' },
  { Id: 4210, Code: '2462', Name: 'Manager, assurance, quality' },
  {
    Id: 4211,
    Code: '2462',
    Name: 'Manager, client, British Standards Institute'
  },
  { Id: 4212, Code: '2462', Name: 'Manager, coding, clinical' },
  { Id: 4213, Code: '2462', Name: 'Manager, compliance' },
  { Id: 4214, Code: '2462', Name: 'Manager, control, quality' },
  { Id: 4215, Code: '2462', Name: 'Manager, finishing' },
  { Id: 4216, Code: '2462', Name: 'Manager, governance, information' },
  { Id: 4217, Code: '2462', Name: 'Manager, health and safety, quality' },
  { Id: 4218, Code: '2462', Name: 'Manager, performance and planning' },
  { Id: 4219, Code: '2462', Name: 'Manager, pharmacovigilance' },
  { Id: 4220, Code: '2462', Name: 'Manager, QA' },
  { Id: 4221, Code: '2462', Name: 'Manager, quality' },
  { Id: 4222, Code: '2462', Name: 'Manager, quality and performance' },
  { Id: 4223, Code: '2462', Name: 'Manager, rigour' },
  { Id: 4224, Code: '2462', Name: 'Manager, standards, operations' },
  { Id: 4225, Code: '2462', Name: 'Manager, systems, quality' },
  { Id: 4226, Code: '2462', Name: 'Officer, affairs, regulatory' },
  { Id: 4227, Code: '2462', Name: 'Officer, audit, clinical' },
  { Id: 4228, Code: '2462', Name: 'Officer, compliance, chief' },
  { Id: 4229, Code: '2462', Name: 'Officer, quality and compliance' },
  { Id: 4230, Code: '2462', Name: 'Regulator, financial' },
  { Id: 4231, Code: '2462', Name: 'Regulator, financial, government' },
  { Id: 4232, Code: '2462', Name: 'Scientist, pharmacovigilance' },
  { Id: 4233, Code: '2463', Name: 'Inspector, food' },
  { Id: 4234, Code: '2463', Name: 'Inspector, health, environmental' },
  { Id: 4235, Code: '2463', Name: 'Inspector, health, port' },
  { Id: 4236, Code: '2463', Name: 'Inspector, health, public' },
  { Id: 4237, Code: '2463', Name: 'Inspector, pollution, air' },
  { Id: 4238, Code: '2463', Name: 'Inspector, sanitary' },
  { Id: 4239, Code: '2463', Name: 'Inspector, shops, local government' },
  { Id: 4240, Code: '2463', Name: 'Inspector, smoke, local government' },
  { Id: 4241, Code: '2463', Name: 'Manager, health, environmental' },
  { Id: 4242, Code: '2463', Name: 'Officer, control, smoke, local government' },
  {
    Id: 4243,
    Code: '2463',
    Name: 'Officer, enforcement, professional, ENVIRONMENTAL HEALTH'
  },
  { Id: 4244, Code: '2463', Name: 'Officer, health, environmental' },
  { Id: 4245, Code: '2463', Name: 'Officer, health, port' },
  { Id: 4246, Code: '2463', Name: 'Officer, health, public' },
  { Id: 4247, Code: '2463', Name: 'Officer, sanitary' },
  { Id: 4248, Code: '2463', Name: 'Officer, technical, ENVIRONMENTAL HEALTH' },
  { Id: 4249, Code: '2471', Name: 'Columnist' },
  { Id: 4250, Code: '2471', Name: 'Commentator' },
  { Id: 4251, Code: '2471', Name: 'Compiler, crossword' },
  { Id: 4252, Code: '2471', Name: 'Correspondent, foreign' },
  { Id: 4253, Code: '2471', Name: 'Correspondent, newspaper' },
  { Id: 4254, Code: '2471', Name: 'Correspondent, newspaper publishing' },
  { Id: 4255, Code: '2471', Name: 'Correspondent, political' },
  { Id: 4256, Code: '2471', Name: 'Correspondent, technical' },
  { Id: 4257, Code: '2471', Name: 'Correspondent, turf' },
  { Id: 4258, Code: '2471', Name: 'Critic' },
  { Id: 4259, Code: '2471', Name: 'Diarist' },
  { Id: 4260, Code: '2471', Name: 'Director, editorial' },
  { Id: 4261, Code: '2471', Name: 'Editor' },
  { Id: 4262, Code: '2471', Name: 'Editor, art' },
  { Id: 4263, Code: '2471', Name: 'Editor, content, newspapers, magazines' },
  { Id: 4264, Code: '2471', Name: 'Editor, copy' },
  { Id: 4265, Code: '2471', Name: 'Editor, desk, multimedia' },
  { Id: 4266, Code: '2471', Name: 'Editor, digital' },
  { Id: 4267, Code: '2471', Name: 'Editor, listings' },
  { Id: 4268, Code: '2471', Name: 'Editor, managing, newspapers, magazines' },
  { Id: 4269, Code: '2471', Name: 'Editor, news, newspaper' },
  { Id: 4270, Code: '2471', Name: 'Editor, newspaper' },
  { Id: 4271, Code: '2471', Name: 'Editor, newspapers, magazines' },
  { Id: 4272, Code: '2471', Name: 'Editor, on-line' },
  { Id: 4273, Code: '2471', Name: 'Editor, picture' },
  { Id: 4274, Code: '2471', Name: 'Editor, production' },
  { Id: 4275, Code: '2471', Name: 'Editor, sub' },
  { Id: 4276, Code: '2471', Name: 'Executive, editorial, newspaper' },
  { Id: 4277, Code: '2471', Name: 'Journalist' },
  { Id: 4278, Code: '2471', Name: 'Journalist, broadcast' },
  { Id: 4279, Code: '2471', Name: 'Journalist, broadcasting' },
  { Id: 4280, Code: '2471', Name: 'Journalist, data' },
  { Id: 4281, Code: '2471', Name: 'Journalist, digital' },
  { Id: 4282, Code: '2471', Name: 'Journalist, multimedia' },
  { Id: 4283, Code: '2471', Name: 'Journalist, online' },
  { Id: 4284, Code: '2471', Name: 'Journalist, on-line' },
  { Id: 4285, Code: '2471', Name: 'Journalist, radio' },
  { Id: 4286, Code: '2471', Name: 'Manager, editorial' },
  { Id: 4287, Code: '2471', Name: 'Officer, publications' },
  { Id: 4288, Code: '2471', Name: 'Reporter' },
  { Id: 4289, Code: '2471', Name: 'Reporter, court, newspaper' },
  { Id: 4290, Code: '2471', Name: 'Representative, press' },
  { Id: 4291, Code: '2471', Name: 'Sub-editor' },
  { Id: 4292, Code: '2471', Name: 'Writer and editor' },
  { Id: 4293, Code: '2471', Name: 'Writer, feature' },
  { Id: 4294, Code: '2471', Name: 'Writer, features' },
  { Id: 4295, Code: '2471', Name: 'Writer, freelance, NEWSPAPER, MAGAZINE' },
  { Id: 4296, Code: '2471', Name: 'Writer, journalism' },
  { Id: 4297, Code: '2471', Name: 'Writer, leader' },
  { Id: 4298, Code: '2471', Name: 'Writer, news' },
  { Id: 4299, Code: '2471', Name: 'Writer, newspaper publishing' },
  { Id: 4300, Code: '2471', Name: 'Writer, sports, NEWSPAPER' },
  { Id: 4301, Code: '2471', Name: 'Writer, travel, NEWSPAPER, MAGAZINE' },
  { Id: 4302, Code: '2472', Name: 'Adviser, PR' },
  { Id: 4303, Code: '2472', Name: 'Adviser, relations, public' },
  { Id: 4304, Code: '2472', Name: 'Agent, press' },
  { Id: 4305, Code: '2472', Name: 'Agent, publicity' },
  { Id: 4306, Code: '2472', Name: 'Aide, communications' },
  { Id: 4307, Code: '2472', Name: 'Assistant, communications, digital' },
  {
    Id: 4308,
    Code: '2472',
    Name: 'Assistant, communications, press, public relations'
  },
  { Id: 4309, Code: '2472', Name: 'Assistant, PR' },
  { Id: 4310, Code: '2472', Name: 'Assistant, publications' },
  { Id: 4311, Code: '2472', Name: 'Assistant, publicity' },
  { Id: 4312, Code: '2472', Name: 'Assistant, publishing' },
  { Id: 4313, Code: '2472', Name: 'Assistant, relations, public' },
  { Id: 4314, Code: '2472', Name: 'Consultant, affairs, public' },
  { Id: 4315, Code: '2472', Name: 'Consultant, communications, media' },
  { Id: 4316, Code: '2472', Name: 'Consultant, media' },
  { Id: 4317, Code: '2472', Name: 'Consultant, media, social' },
  { Id: 4318, Code: '2472', Name: 'Consultant, PR' },
  { Id: 4319, Code: '2472', Name: 'Consultant, publicity' },
  { Id: 4320, Code: '2472', Name: 'Consultant, relations, public' },
  { Id: 4321, Code: '2472', Name: 'Coordinator, media' },
  { Id: 4322, Code: '2472', Name: 'Executive, account, PUBLIC RELATIONS' },
  { Id: 4323, Code: '2472', Name: 'Executive, liaison, media' },
  { Id: 4324, Code: '2472', Name: 'Executive, media, social' },
  { Id: 4325, Code: '2472', Name: 'Executive, relations, public' },
  { Id: 4326, Code: '2472', Name: 'Interviewer, press' },
  { Id: 4327, Code: '2472', Name: 'Lobbyist' },
  { Id: 4328, Code: '2472', Name: 'Manager, account, public relations' },
  { Id: 4329, Code: '2472', Name: 'Manager, affairs, corporate' },
  { Id: 4330, Code: '2472', Name: 'Manager, affairs, public' },
  { Id: 4331, Code: '2472', Name: 'Manager, communications, PUBLIC RELATIONS' },
  { Id: 4332, Code: '2472', Name: 'Manager, media' },
  { Id: 4333, Code: '2472', Name: 'Manager, media, social' },
  { Id: 4334, Code: '2472', Name: 'Manager, PUBLIC RELATIONS' },
  { Id: 4335, Code: '2472', Name: 'Manager, publicity' },
  { Id: 4336, Code: '2472', Name: 'Manager, relations, alumni' },
  { Id: 4337, Code: '2472', Name: 'Manager, relations, public' },
  {
    Id: 4338,
    Code: '2472',
    Name: 'Officer, communications, media, public relations'
  },
  { Id: 4339, Code: '2472', Name: 'Officer, information, PUBLIC RELATIONS' },
  { Id: 4340, Code: '2472', Name: 'Officer, information, research' },
  { Id: 4341, Code: '2472', Name: 'Officer, liaison, press' },
  { Id: 4342, Code: '2472', Name: 'Officer, liaison, railways' },
  { Id: 4343, Code: '2472', Name: 'Officer, media' },
  { Id: 4344, Code: '2472', Name: 'Officer, press' },
  { Id: 4345, Code: '2472', Name: 'Officer, press and information' },
  { Id: 4346, Code: '2472', Name: 'Officer, publicity' },
  { Id: 4347, Code: '2472', Name: 'Officer, relations, alumni' },
  { Id: 4348, Code: '2472', Name: 'Officer, relations, media' },
  { Id: 4349, Code: '2472', Name: 'Officer, relations, public' },
  { Id: 4350, Code: '2472', Name: 'Organiser, publicity' },
  { Id: 4351, Code: '2472', Name: 'Publicist' },
  { Id: 4352, Code: '2472', Name: 'Secretary, press' },
  { Id: 4353, Code: '2473', Name: 'Consultant, creative, advertising' },
  { Id: 4354, Code: '2473', Name: 'Director, art' },
  { Id: 4355, Code: '2473', Name: 'Director, art, digital' },
  { Id: 4356, Code: '2473', Name: 'Director, creative' },
  { Id: 4357, Code: '2473', Name: 'Director, creative, digital' },
  { Id: 4358, Code: '2473', Name: 'Director, design' },
  { Id: 4359, Code: '2473', Name: 'Manager, account, advertising' },
  { Id: 4360, Code: '2473', Name: 'Manager, advertisement' },
  { Id: 4361, Code: '2473', Name: 'Manager, advertising' },
  { Id: 4362, Code: '2473', Name: 'Manager, advertising, online' },
  { Id: 4363, Code: '2473', Name: 'Manager, advertising, sales' },
  { Id: 4364, Code: '2473', Name: 'Manager, appeal' },
  { Id: 4365, Code: '2473', Name: 'Manager, appeals' },
  { Id: 4366, Code: '2473', Name: 'Manager, campaign' },
  { Id: 4367, Code: '2473', Name: 'Manager, campaign, marketing' },
  { Id: 4368, Code: '2473', Name: 'Manager, fundraising' },
  { Id: 4369, Code: '2473', Name: 'Manager, lottery' },
  { Id: 4370, Code: '2473', Name: 'Manager, portfolio, advertising' },
  { Id: 4371, Code: '2473', Name: 'Manager, press, ADVERTISING' },
  { Id: 4372, Code: '2473', Name: 'Manager, production, advertising' },
  { Id: 4373, Code: '2473', Name: 'Manager, projects, advertising' },
  { Id: 4374, Code: '2473', Name: 'Manager, raising, fund' },
  { Id: 4375, Code: '2473', Name: 'Manager, traffic, advertising' },
  { Id: 4376, Code: '2741', Name: 'Editor, online' },
  { Id: 4377, Code: '2741', Name: 'Editor, publishing' },
  { Id: 4378, Code: '3111', Name: 'Aid, laboratory' },
  { Id: 4379, Code: '3111', Name: 'Aide, laboratory' },
  { Id: 4380, Code: '3111', Name: 'Analyser' },
  { Id: 4381, Code: '3111', Name: 'Analyst, DNA' },
  { Id: 4382, Code: '3111', Name: 'Analyst, geophysical' },
  { Id: 4383, Code: '3111', Name: 'Analyst, laboratory' },
  { Id: 4384, Code: '3111', Name: 'Analyst, material' },
  { Id: 4385, Code: '3111', Name: 'Analyst, materials' },
  { Id: 4386, Code: '3111', Name: 'Analyst, pharmaceutical' },
  { Id: 4387, Code: '3111', Name: 'Analyst, seed' },
  { Id: 4388, Code: '3111', Name: "Assistant, analyst's" },
  { Id: 4389, Code: '3111', Name: 'Assistant, anatomical' },
  { Id: 4390, Code: '3111', Name: 'Assistant, chemical' },
  { Id: 4391, Code: '3111', Name: 'Assistant, control, quality, chemical mfr' },
  { Id: 4392, Code: '3111', Name: 'Assistant, house, test, steelworks' },
  { Id: 4393, Code: '3111', Name: 'Assistant, laboratory' },
  { Id: 4394, Code: '3111', Name: 'Assistant, laboratory, medical' },
  { Id: 4395, Code: '3111', Name: 'Assistant, research, MEDICAL' },
  { Id: 4396, Code: '3111', Name: 'Assistant, science, healthcare' },
  { Id: 4397, Code: '3111', Name: 'Assistant, scientific' },
  { Id: 4398, Code: '3111', Name: 'Associate, trial, clinical' },
  { Id: 4399, Code: '3111', Name: 'Attendant, laboratory' },
  { Id: 4400, Code: '3111', Name: 'Cytoscreener' },
  { Id: 4401, Code: '3111', Name: 'Foreman, laboratory' },
  { Id: 4402, Code: '3111', Name: 'Foreman, physics, health' },
  { Id: 4403, Code: '3111', Name: 'Geotechnician' },
  { Id: 4404, Code: '3111', Name: 'Haematologist' },
  { Id: 4405, Code: '3111', Name: 'Inspector, laboratory, glass mfr' },
  { Id: 4406, Code: '3111', Name: 'Logger, well' },
  { Id: 4407, Code: '3111', Name: 'Man, bench, laboratory' },
  { Id: 4408, Code: '3111', Name: 'Man, laboratory' },
  { Id: 4409, Code: '3111', Name: 'Metrologist' },
  { Id: 4410, Code: '3111', Name: 'Microscopist, electron' },
  { Id: 4411, Code: '3111', Name: 'Monitor, atomic energy establishment' },
  {
    Id: 4412,
    Code: '3111',
    Name: 'Monitor, industrial, atomic energy establishment'
  },
  { Id: 4413, Code: '3111', Name: 'Monitor, physics, health' },
  { Id: 4414, Code: '3111', Name: 'Monitor, radiation' },
  { Id: 4415, Code: '3111', Name: 'Officer, experimental' },
  { Id: 4416, Code: '3111', Name: 'Officer, laboratory' },
  { Id: 4417, Code: '3111', Name: 'Officer, research, medical' },
  { Id: 4418, Code: '3111', Name: 'Officer, science, laboratory' },
  {
    Id: 4419,
    Code: '3111',
    Name: 'Officer, science, laboratory, medical, junior'
  },
  { Id: 4420, Code: '3111', Name: 'Officer, scientific, assistant' },
  { Id: 4421, Code: '3111', Name: 'Officer, scientific, laboratory' },
  {
    Id: 4422,
    Code: '3111',
    Name: 'Officer, scientific, laboratory, medical, junior'
  },
  { Id: 4423, Code: '3111', Name: 'Officer, scientific, MEDICAL' },
  {
    Id: 4424,
    Code: '3111',
    Name: 'Officer, technical, assistant, chemical mfr'
  },
  {
    Id: 4425,
    Code: '3111',
    Name: 'Officer, technical, National Institute of Agricultural Botany'
  },
  { Id: 4426, Code: '3111', Name: 'Operator, research, oil refining' },
  { Id: 4427, Code: '3111', Name: 'Operator, spectroscope' },
  { Id: 4428, Code: '3111', Name: 'Practitioner, associate, LABORATORY' },
  { Id: 4429, Code: '3111', Name: 'Researcher, medical' },
  { Id: 4430, Code: '3111', Name: 'Scientist, assistant' },
  { Id: 4431, Code: '3111', Name: 'Screener, cytology' },
  { Id: 4432, Code: '3111', Name: 'Spectrographer' },
  { Id: 4433, Code: '3111', Name: 'Steward, laboratory' },
  { Id: 4434, Code: '3111', Name: 'Superintendent, LABORATORY' },
  { Id: 4435, Code: '3111', Name: 'Supervisor, laboratory' },
  { Id: 4436, Code: '3111', Name: 'Technician, analytical' },
  { Id: 4437, Code: '3111', Name: 'Technician, anatomy' },
  { Id: 4438, Code: '3111', Name: 'Technician, botanical' },
  { Id: 4439, Code: '3111', Name: 'Technician, chemistry' },
  { Id: 4440, Code: '3111', Name: 'Technician, cytological' },
  { Id: 4441, Code: '3111', Name: 'Technician, food' },
  { Id: 4442, Code: '3111', Name: 'Technician, geological' },
  { Id: 4443, Code: '3111', Name: 'Technician, geology' },
  { Id: 4444, Code: '3111', Name: 'Technician, histological' },
  { Id: 4445, Code: '3111', Name: 'Technician, hospital' },
  { Id: 4446, Code: '3111', Name: 'Technician, hospital service' },
  { Id: 4447, Code: '3111', Name: 'Technician, laboratory' },
  { Id: 4448, Code: '3111', Name: 'Technician, laboratory, chemistry' },
  { Id: 4449, Code: '3111', Name: 'Technician, laboratory, medical' },
  { Id: 4450, Code: '3111', Name: 'Technician, laboratory, microbiology' },
  { Id: 4451, Code: '3111', Name: 'Technician, laboratory, school' },
  { Id: 4452, Code: '3111', Name: 'Technician, laboratory, teaching' },
  { Id: 4453, Code: '3111', Name: 'Technician, microbiology' },
  { Id: 4454, Code: '3111', Name: 'Technician, physics, hospital service' },
  { Id: 4455, Code: '3111', Name: 'Technician, rubber' },
  { Id: 4456, Code: '3111', Name: 'Technician, scientific' },
  { Id: 4457, Code: '3111', Name: 'Technician, TEXTILE' },
  { Id: 4458, Code: '3111', Name: 'Technologist, clinical' },
  { Id: 4459, Code: '3111', Name: 'Technologist, laboratory, medical' },
  { Id: 4460, Code: '3111', Name: 'Technologist, medical' },
  { Id: 4461, Code: '3111', Name: 'Tester, acid' },
  { Id: 4462, Code: '3111', Name: 'Tester, alkali' },
  { Id: 4463, Code: '3111', Name: 'Tester, carbon, steelworks' },
  { Id: 4464, Code: '3111', Name: 'Tester, chemical' },
  { Id: 4465, Code: '3111', Name: 'Tester, gas, chemical mfr' },
  { Id: 4466, Code: '3111', Name: 'Tester, gas, gas works' },
  { Id: 4467, Code: '3111', Name: 'Tester, head, section, oil refining' },
  { Id: 4468, Code: '3111', Name: 'Tester, oil' },
  { Id: 4469, Code: '3111', Name: 'Tester, seed' },
  { Id: 4470, Code: '3111', Name: 'Tester, soil' },
  { Id: 4471, Code: '3111', Name: 'Tester, water' },
  { Id: 4472, Code: '3111', Name: 'Worker, laboratory' },
  { Id: 4473, Code: '3111', Name: 'Worker, research, MEDICAL' },
  { Id: 4474, Code: '3111', Name: 'Worker, support, biomedical' },
  { Id: 4475, Code: '3112', Name: 'Assistant, electronics' },
  { Id: 4476, Code: '3112', Name: 'Engineer, assistant, broadcasting' },
  { Id: 4477, Code: '3112', Name: 'Engineer, executive, telecommunications' },
  {
    Id: 4478,
    Code: '3112',
    Name: 'Engineer, installation, electricity supplier'
  },
  { Id: 4479, Code: '3112', Name: 'Engineer, simulator, flight' },
  { Id: 4480, Code: '3112', Name: 'Engineer, testing, cable, assistant' },
  { Id: 4481, Code: '3112', Name: 'Officer, signals, MOD' },
  { Id: 4482, Code: '3112', Name: 'Specialist, telecommunications' },
  { Id: 4483, Code: '3112', Name: 'Technician, avionics' },
  { Id: 4484, Code: '3112', Name: 'Technician, electrical' },
  { Id: 4485, Code: '3112', Name: 'Technician, ELECTRICAL EQUIPMENT' },
  { Id: 4486, Code: '3112', Name: 'Technician, ELECTRONIC EQUIPMENT' },
  { Id: 4487, Code: '3112', Name: 'Technician, electronics' },
  { Id: 4488, Code: '3112', Name: 'Technician, maintenance, electrical' },
  { Id: 4489, Code: '3112', Name: 'Technician, telemetry' },
  { Id: 4490, Code: '3113', Name: 'Adviser, aeronautical' },
  { Id: 4491, Code: '3113', Name: 'Adviser, technical' },
  { Id: 4492, Code: '3113', Name: 'Analyst, maintenance, engineering' },
  { Id: 4493, Code: '3113', Name: 'Artificer, armed forces' },
  { Id: 4494, Code: '3113', Name: 'Assessor, technical' },
  { Id: 4495, Code: '3113', Name: 'Assistant, engineering' },
  { Id: 4496, Code: '3113', Name: "Assistant, engineer's" },
  { Id: 4497, Code: '3113', Name: 'Controller, gas, steelworks' },
  { Id: 4498, Code: '3113', Name: 'Coordinator, engineering' },
  { Id: 4499, Code: '3113', Name: 'Engineer, aircraft, LICENSED' },
  { Id: 4500, Code: '3113', Name: 'Engineer, assistant, gas supplier' },
  { Id: 4501, Code: '3113', Name: 'Engineer, assistant, government' },
  { Id: 4502, Code: '3113', Name: 'Engineer, assistant, manufacturing' },
  { Id: 4503, Code: '3113', Name: 'Engineer, commissioning' },
  { Id: 4504, Code: '3113', Name: 'Engineer, contract' },
  { Id: 4505, Code: '3113', Name: 'Engineer, contracts' },
  { Id: 4506, Code: '3113', Name: 'Engineer, distribution' },
  { Id: 4507, Code: '3113', Name: 'Engineer, distribution, voltage, high' },
  { Id: 4508, Code: '3113', Name: 'Engineer, manufacturing' },
  { Id: 4509, Code: '3113', Name: 'Engineer, prevention, fire' },
  { Id: 4510, Code: '3113', Name: 'Engineer, rig, test' },
  { Id: 4511, Code: '3113', Name: 'Engineer, technician' },
  { Id: 4512, Code: '3113', Name: 'Engineer, test, TECHNICIAN' },
  { Id: 4513, Code: '3113', Name: 'Engineer, track and catenary' },
  { Id: 4514, Code: '3113', Name: 'ETGII' },
  { Id: 4515, Code: '3113', Name: 'Officer, design, control, traffic, air' },
  { Id: 4516, Code: '3113', Name: 'Officer, mechanisation' },
  {
    Id: 4517,
    Code: '3113',
    Name: 'Officer, professional and technical, government'
  },
  { Id: 4518, Code: '3113', Name: 'Officer, technical, gas supplier' },
  { Id: 4519, Code: '3113', Name: 'Officer, transport, DETR' },
  { Id: 4520, Code: '3113', Name: 'Officer, transport, mechanical' },
  { Id: 4521, Code: '3113', Name: 'PTO, government' },
  { Id: 4522, Code: '3113', Name: 'Radiographer, engineering' },
  { Id: 4523, Code: '3113', Name: 'Radiographer, INDUSTRIAL' },
  { Id: 4524, Code: '3113', Name: 'Radiographer, manufacturing' },
  { Id: 4525, Code: '3113', Name: 'Shooter, trouble' },
  { Id: 4526, Code: '3113', Name: 'Technician, aircraft' },
  { Id: 4527, Code: '3113', Name: 'Technician, airframe' },
  { Id: 4528, Code: '3113', Name: 'Technician, engineering' },
  { Id: 4529, Code: '3113', Name: 'Technician, engineering, aerospace' },
  { Id: 4530, Code: '3113', Name: 'Technician, engineering, agricultural' },
  { Id: 4531, Code: '3113', Name: 'Technician, engineering, automotive' },
  { Id: 4532, Code: '3113', Name: 'Technician, engineering, chemical' },
  { Id: 4533, Code: '3113', Name: 'Technician, engineering, electrical' },
  { Id: 4534, Code: '3113', Name: 'Technician, engineering, marine' },
  { Id: 4535, Code: '3113', Name: 'Technician, engineering, mechanical' },
  { Id: 4536, Code: '3113', Name: 'Technician, FLIGHT TEST' },
  { Id: 4537, Code: '3113', Name: 'Technician, installation' },
  { Id: 4538, Code: '3113', Name: 'Technician, instrument, steelworks' },
  { Id: 4539, Code: '3113', Name: 'Technician, mechanical' },
  { Id: 4540, Code: '3113', Name: 'Technician, steelworks, INSTRUMENT' },
  { Id: 4541, Code: '3113', Name: 'Technician, traffic' },
  { Id: 4542, Code: '3113', Name: 'Technician, turbine, wind, construction' },
  { Id: 4543, Code: '3113', Name: 'Technician, WIND TUNNEL' },
  {
    Id: 4544,
    Code: '3114',
    Name: 'Analyst, stress, construction, engineering'
  },
  { Id: 4545, Code: '3114', Name: 'Assistant, building' },
  { Id: 4546, Code: '3114', Name: 'Assistant, engineering, civil' },
  { Id: 4547, Code: '3114', Name: 'Assistant, technical, civil engineering' },
  { Id: 4548, Code: '3114', Name: 'Consultant, building' },
  { Id: 4549, Code: '3114', Name: 'Consultant, construction' },
  { Id: 4550, Code: '3114', Name: 'Consultant, services, building' },
  {
    Id: 4551,
    Code: '3114',
    Name: 'Controller, production, building construction'
  },
  { Id: 4552, Code: '3114', Name: 'Coordinator, project, construction' },
  { Id: 4553, Code: '3114', Name: 'Officer, technical, civil engineering' },
  { Id: 4554, Code: '3114', Name: 'Technician, building' },
  { Id: 4555, Code: '3114', Name: 'Technician, engineering, civil' },
  { Id: 4556, Code: '3114', Name: 'Technician, engineering, structural' },
  { Id: 4557, Code: '3114', Name: 'Technician, highway' },
  { Id: 4558, Code: '3114', Name: 'Technician, survey' },
  { Id: 4559, Code: '3114', Name: 'Technician, surveying' },
  { Id: 4560, Code: '3115', Name: 'Analyst, control, quality' },
  { Id: 4561, Code: '3115', Name: 'Analyst, quality' },
  { Id: 4562, Code: '3115', Name: 'Auditor, assurance, quality' },
  { Id: 4563, Code: '3115', Name: 'Auditor, technical' },
  { Id: 4564, Code: '3115', Name: 'Coordinator, assurance, quality' },
  { Id: 4565, Code: '3115', Name: 'Coordinator, control, quality' },
  { Id: 4566, Code: '3115', Name: 'Coordinator, QA' },
  { Id: 4567, Code: '3115', Name: 'Coordinator, quality' },
  { Id: 4568, Code: '3115', Name: 'Engineer, evaluation' },
  { Id: 4569, Code: '3115', Name: 'Officer, assurance, quality' },
  { Id: 4570, Code: '3115', Name: 'Officer, quality' },
  { Id: 4571, Code: '3115', Name: 'Technician, assurance, quality' },
  { Id: 4572, Code: '3115', Name: 'Technician, control, quality' },
  { Id: 4573, Code: '3115', Name: 'Technician, materials' },
  { Id: 4574, Code: '3115', Name: 'Technician, ndt' },
  { Id: 4575, Code: '3115', Name: 'Technician, quality' },
  { Id: 4576, Code: '3115', Name: 'Technician, quality, water' },
  { Id: 4577, Code: '3115', Name: 'Technician, test' },
  { Id: 4578, Code: '3115', Name: 'Technician, testing, non-destructive' },
  { Id: 4579, Code: '3116', Name: 'Controller, production' },
  { Id: 4580, Code: '3116', Name: 'Controller, technical' },
  { Id: 4581, Code: '3116', Name: 'Coordinator, production' },
  { Id: 4582, Code: '3116', Name: 'Planner, production' },
  { Id: 4583, Code: '3116', Name: 'Technician, dyeing, textile' },
  { Id: 4584, Code: '3116', Name: 'Technician, process' },
  { Id: 4585, Code: '3116', Name: 'Technician, production' },
  { Id: 4586, Code: '3119', Name: 'Assistant, experimental' },
  { Id: 4587, Code: '3119', Name: 'Assistant, metallurgical' },
  { Id: 4588, Code: '3119', Name: 'Assistant, technical' },
  { Id: 4589, Code: '3119', Name: 'Assistant, test' },
  { Id: 4590, Code: '3119', Name: 'Consultant, technical, aviation' },
  { Id: 4591, Code: '3119', Name: 'Consultant, textile' },
  { Id: 4592, Code: '3119', Name: 'Controller, oxygen' },
  { Id: 4593, Code: '3119', Name: 'Engineer, investigating, technical' },
  { Id: 4594, Code: '3119', Name: 'Grade I Technical Class, government' },
  { Id: 4595, Code: '3119', Name: 'Grade II Technical Class, government' },
  { Id: 4596, Code: '3119', Name: 'Officer, liaison, technical' },
  { Id: 4597, Code: '3119', Name: 'Officer, technical, government' },
  { Id: 4598, Code: '3119', Name: 'Officer, technical, nos' },
  { Id: 4599, Code: '3119', Name: 'Officer, technical, scientific, coal mine' },
  { Id: 4600, Code: '3119', Name: 'Overseer, assistant, MOD' },
  { Id: 4601, Code: '3119', Name: 'Technical Class, grade I, government' },
  { Id: 4602, Code: '3119', Name: 'Technical Class, grade II, government' },
  { Id: 4603, Code: '3119', Name: 'Technical grade, government' },
  { Id: 4604, Code: '3119', Name: 'Technician' },
  { Id: 4605, Code: '3119', Name: 'Technician, composite' },
  { Id: 4606, Code: '3119', Name: 'Technician, development' },
  { Id: 4607, Code: '3119', Name: 'Technician, meteorological' },
  { Id: 4608, Code: '3119', Name: 'Technician, PLASTICS' },
  { Id: 4609, Code: '3119', Name: 'Technician, school' },
  {
    Id: 4610,
    Code: '3119',
    Name: 'Technician, science, educational establishment'
  },
  { Id: 4611, Code: '3119', Name: 'Technician, science, school' },
  { Id: 4612, Code: '3119', Name: 'Technician, service' },
  { Id: 4613, Code: '3119', Name: 'Technician, technology, design' },
  { Id: 4614, Code: '3119', Name: 'Technician, workshop' },
  { Id: 4615, Code: '3119', Name: 'Worker, experimental' },
  {
    Id: 4616,
    Code: '3121',
    Name: 'Administrator, local government: town planning'
  },
  { Id: 4617, Code: '3121', Name: "Assistant, architect's" },
  { Id: 4618, Code: '3121', Name: 'Assistant, architectural' },
  { Id: 4619, Code: '3121', Name: 'Assistant, planning, local government' },
  { Id: 4620, Code: '3121', Name: 'Assistant, planning, town' },
  { Id: 4621, Code: '3121', Name: 'Associate, architectural' },
  { Id: 4622, Code: '3121', Name: 'Designer, architectural' },
  { Id: 4623, Code: '3121', Name: 'Officer, enforcement, planning' },
  { Id: 4624, Code: '3121', Name: 'Officer, enforcement, town planning' },
  { Id: 4625, Code: '3121', Name: 'Planner, architectural' },
  { Id: 4626, Code: '3121', Name: 'Planner, construction' },
  { Id: 4627, Code: '3121', Name: 'Technician, architectural' },
  { Id: 4628, Code: '3121', Name: 'Technician, planning, town' },
  { Id: 4629, Code: '3122', Name: 'Analyst, tool' },
  { Id: 4630, Code: '3122', Name: 'Assistant, cartographic' },
  { Id: 4631, Code: '3122', Name: 'Assistant, design' },
  { Id: 4632, Code: '3122', Name: "Assistant, draughtsman's" },
  { Id: 4633, Code: '3122', Name: 'Assistant, drawing, technical' },
  { Id: 4634, Code: '3122', Name: 'Assistant, office, drawing' },
  { Id: 4635, Code: '3122', Name: 'Cartographer' },
  { Id: 4636, Code: '3122', Name: 'Cartographer, digital' },
  { Id: 4637, Code: '3122', Name: 'Cartographer-draughtsman' },
  { Id: 4638, Code: '3122', Name: 'Checker, drawing' },
  { Id: 4639, Code: '3122', Name: 'Checker, DRAWING OFFICE' },
  { Id: 4640, Code: '3122', Name: 'Checker, map' },
  { Id: 4641, Code: '3122', Name: 'Colourist, copy' },
  { Id: 4642, Code: '3122', Name: 'Coordinator, CAD' },
  { Id: 4643, Code: '3122', Name: 'Corrector, chart, Trinity House' },
  { Id: 4644, Code: '3122', Name: 'Designer, aided, computer' },
  { Id: 4645, Code: '3122', Name: 'Designer, CAD' },
  { Id: 4646, Code: '3122', Name: 'Designer, chief' },
  { Id: 4647, Code: '3122', Name: 'Designer, design, aided, computer' },
  { Id: 4648, Code: '3122', Name: 'Designer, engineering' },
  { Id: 4649, Code: '3122', Name: 'Designer, mechanical' },
  { Id: 4650, Code: '3122', Name: 'Designer, piping' },
  { Id: 4651, Code: '3122', Name: 'Designer, tool' },
  { Id: 4652, Code: '3122', Name: 'Designer-detailer' },
  { Id: 4653, Code: '3122', Name: 'Designer-draughtsman' },
  { Id: 4654, Code: '3122', Name: 'Detailer, concrete' },
  { Id: 4655, Code: '3122', Name: 'Detailer, structural' },
  { Id: 4656, Code: '3122', Name: 'Digitiser' },
  { Id: 4657, Code: '3122', Name: 'Draughtsman' },
  { Id: 4658, Code: '3122', Name: 'Draughtsman, autocad' },
  { Id: 4659, Code: '3122', Name: 'Draughtsman, CAD' },
  { Id: 4660, Code: '3122', Name: 'Draughtsman, cartographical' },
  { Id: 4661, Code: '3122', Name: 'Draughtsman, chief' },
  { Id: 4662, Code: '3122', Name: 'Draughtsman, design' },
  { Id: 4663, Code: '3122', Name: 'Draughtsman, design, aided, computer' },
  { Id: 4664, Code: '3122', Name: 'Draughtsman, engineering' },
  { Id: 4665, Code: '3122', Name: 'Draughtsman-engineer' },
  { Id: 4666, Code: '3122', Name: 'Draughtsman-surveyor' },
  { Id: 4667, Code: '3122', Name: 'Engineer, autocad' },
  { Id: 4668, Code: '3122', Name: 'Engineer, CAD' },
  { Id: 4669, Code: '3122', Name: 'Engineer, control, weight' },
  { Id: 4670, Code: '3122', Name: 'Engineer, design, aided, computer' },
  { Id: 4671, Code: '3122', Name: 'Engineer, design, assisted, computer' },
  { Id: 4672, Code: '3122', Name: 'Engineer, stress' },
  { Id: 4673, Code: '3122', Name: 'Engineer-draughtsman' },
  { Id: 4674, Code: '3122', Name: 'Estimator-draughtsman' },
  { Id: 4675, Code: '3122', Name: 'Examiner, boundary, Ordnance Survey' },
  { Id: 4676, Code: '3122', Name: 'Examiner, map, Ordnance Survey' },
  { Id: 4677, Code: '3122', Name: 'Leader, section, DRAWING OFFICE' },
  { Id: 4678, Code: '3122', Name: 'Loftsman, engineering' },
  { Id: 4679, Code: '3122', Name: 'Manager, office, drawing' },
  { Id: 4680, Code: '3122', Name: 'Officer, mapping and charting' },
  { Id: 4681, Code: '3122', Name: 'Operator, CAD' },
  { Id: 4682, Code: '3122', Name: 'Painter, design' },
  { Id: 4683, Code: '3122', Name: 'Photogrammetrist' },
  { Id: 4684, Code: '3122', Name: 'Planner, space' },
  { Id: 4685, Code: '3122', Name: 'Reviser, field, Ordnance Survey' },
  { Id: 4686, Code: '3122', Name: 'Sketcher, design' },
  { Id: 4687, Code: '3122', Name: 'Technician, autocad' },
  { Id: 4688, Code: '3122', Name: 'Technician, BIM' },
  { Id: 4689, Code: '3122', Name: 'Technician, CAD' },
  { Id: 4690, Code: '3122', Name: 'Technician, design' },
  { Id: 4691, Code: '3122', Name: 'Technician, draughting' },
  { Id: 4692, Code: '3122', Name: 'Technician, printing, 3D' },
  { Id: 4693, Code: '3122', Name: 'Tracer' },
  { Id: 4694, Code: '3122', Name: 'Weightsman' },
  { Id: 4695, Code: '3131', Name: 'Administrator, computer' },
  { Id: 4696, Code: '3131', Name: 'Administrator, database' },
  { Id: 4697, Code: '3131', Name: 'Administrator, digital' },
  { Id: 4698, Code: '3131', Name: 'Administrator, Hadoop' },
  { Id: 4699, Code: '3131', Name: 'Administrator, internet' },
  { Id: 4700, Code: '3131', Name: 'Administrator, IT' },
  { Id: 4701, Code: '3131', Name: 'Administrator, network' },
  { Id: 4702, Code: '3131', Name: 'Administrator, system' },
  { Id: 4703, Code: '3131', Name: 'Administrator, system, Hadoop' },
  { Id: 4704, Code: '3131', Name: 'Administrator, systems' },
  { Id: 4705, Code: '3131', Name: 'Administrator, systems, Hadoop' },
  { Id: 4706, Code: '3131', Name: 'Administrator, website' },
  { Id: 4707, Code: '3131', Name: 'Analyst, content' },
  { Id: 4708, Code: '3131', Name: 'Analyst, maintenance, COMPUTING' },
  { Id: 4709, Code: '3131', Name: 'Assistant, content, digital' },
  { Id: 4710, Code: '3131', Name: 'Assistant, content, e-commerce' },
  { Id: 4711, Code: '3131', Name: 'Assistant, content, web' },
  { Id: 4712, Code: '3131', Name: 'Assistant, IT' },
  { Id: 4713, Code: '3131', Name: 'Assistant, management, content' },
  { Id: 4714, Code: '3131', Name: 'Assistant, production, online' },
  { Id: 4715, Code: '3131', Name: 'Assistant, website' },
  { Id: 4716, Code: '3131', Name: 'Auditor, computer, TECHNICAL' },
  { Id: 4717, Code: '3131', Name: 'Controller, computer' },
  { Id: 4718, Code: '3131', Name: 'Controller, COMPUTING' },
  { Id: 4719, Code: '3131', Name: 'Controller, IT' },
  { Id: 4720, Code: '3131', Name: 'Controller, network' },
  { Id: 4721, Code: '3131', Name: 'Controller, operations, computer' },
  { Id: 4722, Code: '3131', Name: 'Controller, systems, COMPUTING' },
  { Id: 4723, Code: '3131', Name: 'Controller, telecommunications' },
  { Id: 4724, Code: '3131', Name: 'Coordinator, computer' },
  { Id: 4725, Code: '3131', Name: 'Coordinator, content' },
  { Id: 4726, Code: '3131', Name: 'Coordinator, intranet' },
  { Id: 4727, Code: '3131', Name: 'Coordinator, IT' },
  { Id: 4728, Code: '3131', Name: 'Coordinator, project, computer' },
  { Id: 4729, Code: '3131', Name: 'Coordinator, project, COMPUTING' },
  { Id: 4730, Code: '3131', Name: 'Coordinator, project, IT' },
  { Id: 4731, Code: '3131', Name: 'Coordinator, project, software' },
  { Id: 4732, Code: '3131', Name: 'Coordinator, systems, COMPUTING' },
  { Id: 4733, Code: '3131', Name: 'Coordinator, systems, information' },
  { Id: 4734, Code: '3131', Name: 'Coordinator, technology, information' },
  { Id: 4735, Code: '3131', Name: 'Executive, systems, COMPUTING' },
  { Id: 4736, Code: '3131', Name: 'Leader, COMPUTING' },
  { Id: 4737, Code: '3131', Name: 'Leader, team, COMPUTER OPERATIONS' },
  { Id: 4738, Code: '3131', Name: 'Leader, team, operations, computer' },
  { Id: 4739, Code: '3131', Name: 'Leader, team, operations, network' },
  { Id: 4740, Code: '3131', Name: 'Manager, base, data' },
  { Id: 4741, Code: '3131', Name: 'Manager, configuration, COMPUTING' },
  { Id: 4742, Code: '3131', Name: 'Manager, data' },
  { Id: 4743, Code: '3131', Name: 'Manager, data, clinical' },
  { Id: 4744, Code: '3131', Name: 'Manager, database' },
  { Id: 4745, Code: '3131', Name: 'Networker, COMPUTING' },
  { Id: 4746, Code: '3131', Name: 'Officer, network, computer services' },
  { Id: 4747, Code: '3131', Name: 'Officer, support, web' },
  { Id: 4748, Code: '3131', Name: 'Officer, system' },
  { Id: 4749, Code: '3131', Name: 'Officer, system, information' },
  { Id: 4750, Code: '3131', Name: 'Officer, systems' },
  { Id: 4751, Code: '3131', Name: 'Officer, systems, information' },
  { Id: 4752, Code: '3131', Name: 'Specialist, IT' },
  { Id: 4753, Code: '3131', Name: 'Specialist, networking, data' },
  { Id: 4754, Code: '3131', Name: 'Specialist, technology, information' },
  { Id: 4755, Code: '3131', Name: 'Supervisor, web' },
  { Id: 4756, Code: '3131', Name: 'Technician, computer' },
  { Id: 4757, Code: '3131', Name: 'Technician, computing' },
  { Id: 4758, Code: '3131', Name: 'Technician, ICT' },
  { Id: 4759, Code: '3131', Name: 'Technician, IS' },
  { Id: 4760, Code: '3131', Name: 'Technician, IT' },
  { Id: 4761, Code: '3131', Name: 'Technician, network' },
  { Id: 4762, Code: '3131', Name: 'Technician, software' },
  { Id: 4763, Code: '3131', Name: 'Technician, systems' },
  { Id: 4764, Code: '3131', Name: 'Technician, systems, information' },
  { Id: 4765, Code: '3131', Name: 'Tester, assurance, quality, COMPUTING' },
  { Id: 4766, Code: '3131', Name: 'Tester, games, computer' },
  { Id: 4767, Code: '3132', Name: 'Administrator, desk, help' },
  { Id: 4768, Code: '3132', Name: 'Administrator, helpdesk' },
  { Id: 4769, Code: '3132', Name: 'Adviser, technical, COMPUTING' },
  {
    Id: 4770,
    Code: '3132',
    Name: 'Agent, intelligence, counter, retail, TELECOMMUNICATIONS'
  },
  { Id: 4771, Code: '3132', Name: 'Analyst, desk, service' },
  { Id: 4772, Code: '3132', Name: 'Analyst, helpdesk' },
  { Id: 4773, Code: '3132', Name: 'Analyst, support, applications' },
  { Id: 4774, Code: '3132', Name: 'Analyst, support, COMPUTING' },
  { Id: 4775, Code: '3132', Name: 'Analyst, support, customer' },
  { Id: 4776, Code: '3132', Name: 'Analyst, support, desktop' },
  { Id: 4777, Code: '3132', Name: 'Analyst, support, IT' },
  { Id: 4778, Code: '3132', Name: 'Analyst, support, PC' },
  { Id: 4779, Code: '3132', Name: 'Analyst, support, software' },
  { Id: 4780, Code: '3132', Name: 'Analyst, support, systems' },
  { Id: 4781, Code: '3132', Name: 'Analyst, support, technical' },
  { Id: 4782, Code: '3132', Name: 'Assistant, helpdesk' },
  { Id: 4783, Code: '3132', Name: 'Assistant, helpdesk, IT' },
  { Id: 4784, Code: '3132', Name: 'Assistant, support, computer' },
  { Id: 4785, Code: '3132', Name: 'Assistant, support, IT' },
  { Id: 4786, Code: '3132', Name: 'Associate, IT' },
  { Id: 4787, Code: '3132', Name: 'Associate, services, client, COMPUTING' },
  { Id: 4788, Code: '3132', Name: 'Consultant, support, COMPUTING' },
  { Id: 4789, Code: '3132', Name: 'Controller, services, data' },
  { Id: 4790, Code: '3132', Name: 'Engineer, support, COMPUTER' },
  { Id: 4791, Code: '3132', Name: 'Engineer, support, technical, COMPUTING' },
  { Id: 4792, Code: '3132', Name: 'Engineer, technical, COMPUTING' },
  { Id: 4793, Code: '3132', Name: 'Executive, support, COMPUTING' },
  { Id: 4794, Code: '3132', Name: 'Genius, retail, COMPUTING' },
  { Id: 4795, Code: '3132', Name: 'Guru, retail, TELECOMMUNICATIONS' },
  { Id: 4796, Code: '3132', Name: 'Leader, team, desk, help, COMPUTING' },
  { Id: 4797, Code: '3132', Name: 'Leader, team, IT' },
  { Id: 4798, Code: '3132', Name: 'Leader, team, technology, information' },
  { Id: 4799, Code: '3132', Name: 'Officer, computer' },
  { Id: 4800, Code: '3132', Name: 'Officer, computing' },
  { Id: 4801, Code: '3132', Name: 'Officer, IT' },
  { Id: 4802, Code: '3132', Name: 'Officer, support, COMPUTING' },
  { Id: 4803, Code: '3132', Name: 'Officer, support, helpline, IT' },
  { Id: 4804, Code: '3132', Name: 'Officer, support, IT' },
  { Id: 4805, Code: '3132', Name: 'Officer, support, system' },
  { Id: 4806, Code: '3132', Name: 'Officer, support, systems' },
  { Id: 4807, Code: '3132', Name: 'Operator, desk, help' },
  { Id: 4808, Code: '3132', Name: 'Operator, helpline, COMPUTING' },
  { Id: 4809, Code: '3132', Name: 'Operator, support, IT' },
  { Id: 4810, Code: '3132', Name: 'Specialist, support, COMPUTING' },
  { Id: 4811, Code: '3132', Name: 'Specialist, support, IT' },
  { Id: 4812, Code: '3132', Name: 'Specialist, support, system' },
  { Id: 4813, Code: '3132', Name: 'Specialist, support, technical, COMPUTING' },
  { Id: 4814, Code: '3132', Name: 'Supervisor, desk, help' },
  { Id: 4815, Code: '3132', Name: 'Supervisor, helpdesk, IT' },
  { Id: 4816, Code: '3132', Name: 'Supervisor, IT' },
  { Id: 4817, Code: '3132', Name: 'Supervisor, network, COMPUTING' },
  { Id: 4818, Code: '3132', Name: 'Supervisor, support, technical' },
  { Id: 4819, Code: '3132', Name: 'Supervisor, technology, information' },
  { Id: 4820, Code: '3132', Name: 'Technician, support, computer' },
  { Id: 4821, Code: '3132', Name: 'Technician, support, computer services' },
  { Id: 4822, Code: '3132', Name: 'Technician, support, COMPUTING' },
  { Id: 4823, Code: '3132', Name: 'Technician, support, IT' },
  { Id: 4824, Code: '3132', Name: 'Worker, desk, help, computer' },
  { Id: 4825, Code: '3213', Name: 'Paramedic' },
  { Id: 4826, Code: '3213', Name: 'Paramedic, ambulance' },
  { Id: 4827, Code: '3213', Name: 'Paramedic-ECP' },
  { Id: 4828, Code: '3213', Name: 'Practitioner, care, emergency' },
  { Id: 4829, Code: '3213', Name: 'Technician, paramedic' },
  { Id: 4830, Code: '3216', Name: 'Dispenser, optical' },
  { Id: 4831, Code: '3216', Name: 'Optician, dispensing' },
  { Id: 4832, Code: '3216', Name: 'Optician, lens, contact' },
  { Id: 4833, Code: '3217', Name: 'Technician, checking, accuracy' },
  { Id: 4834, Code: '3217', Name: 'Technician, checking, pharmacy' },
  { Id: 4835, Code: '3217', Name: 'Technician, dispensing' },
  { Id: 4836, Code: '3217', Name: 'Technician, management, medicines' },
  { Id: 4837, Code: '3217', Name: 'Technician, pharmaceutical' },
  { Id: 4838, Code: '3217', Name: 'Technician, pharmacy' },
  { Id: 4839, Code: '3218', Name: 'Audiometrician' },
  { Id: 4840, Code: '3218', Name: 'Cardiographer' },
  { Id: 4841, Code: '3218', Name: 'Ceramist, dental' },
  { Id: 4842, Code: '3218', Name: 'Consultant, aid, hearing' },
  { Id: 4843, Code: '3218', Name: 'Coordinator, implantology' },
  { Id: 4844, Code: '3218', Name: 'Curator, instrument' },
  { Id: 4845, Code: '3218', Name: 'Dentist, equine' },
  { Id: 4846, Code: '3218', Name: 'Dispenser, aid, hearing' },
  { Id: 4847, Code: '3218', Name: 'Educator, health, dental' },
  { Id: 4848, Code: '3218', Name: 'Electro-encephalographer' },
  { Id: 4849, Code: '3218', Name: 'Engineer, dental' },
  { Id: 4850, Code: '3218', Name: 'Engineer, medical' },
  { Id: 4851, Code: '3218', Name: 'Fitter, appliance, surgical' },
  { Id: 4852, Code: '3218', Name: 'Fitter, dental' },
  { Id: 4853, Code: '3218', Name: 'Fitter, limb' },
  { Id: 4854, Code: '3218', Name: 'Foreman, denture mfr' },
  { Id: 4855, Code: '3218', Name: 'Hygienist, dental' },
  { Id: 4856, Code: '3218', Name: 'Hygienist, oral' },
  { Id: 4857, Code: '3218', Name: 'Maker, denture' },
  { Id: 4858, Code: '3218', Name: 'Mechanic, dental' },
  { Id: 4859, Code: '3218', Name: "Mechanic, dentist's" },
  { Id: 4860, Code: '3218', Name: 'Ocularist' },
  { Id: 4861, Code: '3218', Name: 'Officer, appliance' },
  { Id: 4862, Code: '3218', Name: 'Officer, technical, medical' },
  { Id: 4863, Code: '3218', Name: 'Orthotist, equine' },
  { Id: 4864, Code: '3218', Name: 'Physiologist, cardiac' },
  { Id: 4865, Code: '3218', Name: 'Physiologist, gastroenterology' },
  { Id: 4866, Code: '3218', Name: 'Physiologist, respiratory' },
  { Id: 4867, Code: '3218', Name: 'Practitioner, assistant, radiography' },
  { Id: 4868, Code: '3218', Name: 'Practitioner, assistant, RADIOLOGY' },
  { Id: 4869, Code: '3218', Name: 'Radiographer, dental' },
  { Id: 4870, Code: '3218', Name: 'Recordist, electroencephalographic' },
  { Id: 4871, Code: '3218', Name: 'Screener, hearing, newborn' },
  { Id: 4872, Code: '3218', Name: 'Technician, audiologist' },
  { Id: 4873, Code: '3218', Name: 'Technician, cardiac' },
  { Id: 4874, Code: '3218', Name: 'Technician, cardiological' },
  { Id: 4875, Code: '3218', Name: 'Technician, cephalographic' },
  { Id: 4876, Code: '3218', Name: 'Technician, clinical' },
  { Id: 4877, Code: '3218', Name: 'Technician, dental' },
  { Id: 4878, Code: '3218', Name: 'Technician, dental practice' },
  { Id: 4879, Code: '3218', Name: 'Technician, dental, equine' },
  { Id: 4880, Code: '3218', Name: 'Technician, ECG' },
  { Id: 4881, Code: '3218', Name: 'Technician, EEG' },
  { Id: 4882, Code: '3218', Name: 'Technician, gastroenterology' },
  { Id: 4883, Code: '3218', Name: 'Technician, hospital, AUDIOLOGY' },
  { Id: 4884, Code: '3218', Name: 'Technician, hospital, CARDIOGRAPHY' },
  { Id: 4885, Code: '3218', Name: 'Technician, hospital, ENCEPHALOGRAPHY' },
  { Id: 4886, Code: '3218', Name: 'Technician, LABORATORY, DENTAL' },
  { Id: 4887, Code: '3218', Name: 'Technician, limb, artificial' },
  { Id: 4888, Code: '3218', Name: 'Technician, maxillo-facial' },
  { Id: 4889, Code: '3218', Name: 'Technician, medical' },
  { Id: 4890, Code: '3218', Name: 'Technician, orthodontic' },
  { Id: 4891, Code: '3218', Name: 'Technician, orthopaedic' },
  { Id: 4892, Code: '3218', Name: 'Technician, orthotic' },
  { Id: 4893, Code: '3218', Name: 'Technician, pathology' },
  { Id: 4894, Code: '3218', Name: 'Technician, pathology, anatomical' },
  { Id: 4895, Code: '3218', Name: 'Technician, physics, medical' },
  { Id: 4896, Code: '3218', Name: 'Technician, plaster, hospital service' },
  { Id: 4897, Code: '3218', Name: 'Technician, prosthetic' },
  { Id: 4898, Code: '3218', Name: 'Technician, support, clinical' },
  {
    Id: 4899,
    Code: '3218',
    Name: 'Technician, support, life, hospital service'
  },
  { Id: 4900, Code: '3218', Name: 'Technician, surgical' },
  { Id: 4901, Code: '3218', Name: 'Technician, SURGICAL, DENTAL APPLIANCES' },
  { Id: 4902, Code: '3218', Name: 'Technician, theatre, hospital' },
  { Id: 4903, Code: '3218', Name: 'Technician, theatre, operating' },
  { Id: 4904, Code: '3218', Name: 'Technician, trauma' },
  { Id: 4905, Code: '3218', Name: 'Technologist, dental' },
  { Id: 4906, Code: '3218', Name: 'Therapist, dental' },
  { Id: 4907, Code: '3218', Name: 'Therapist, hearing' },
  { Id: 4908, Code: '3218', Name: 'Trichologist' },
  { Id: 4909, Code: '3219', Name: 'Acupuncturist' },
  { Id: 4910, Code: '3219', Name: 'Adviser, diet' },
  { Id: 4911, Code: '3219', Name: 'Apothecary' },
  { Id: 4912, Code: '3219', Name: 'Aromatherapist' },
  { Id: 4913, Code: '3219', Name: 'Assistant, dietetic' },
  { Id: 4914, Code: '3219', Name: 'Counsellor, genetic' },
  { Id: 4915, Code: '3219', Name: 'Economist, home' },
  { Id: 4916, Code: '3219', Name: 'Herbalist' },
  { Id: 4917, Code: '3219', Name: 'Homeopath' },
  { Id: 4918, Code: '3219', Name: 'Homoeopath' },
  { Id: 4919, Code: '3219', Name: 'Hydrotherapist' },
  { Id: 4920, Code: '3219', Name: 'Hypnotherapist' },
  { Id: 4921, Code: '3219', Name: 'Masseur' },
  { Id: 4922, Code: '3219', Name: 'Naturopath' },
  { Id: 4923, Code: '3219', Name: 'Physiologist, clinical' },
  { Id: 4924, Code: '3219', Name: 'Practitioner, health, complimentary' },
  { Id: 4925, Code: '3219', Name: 'Practitioner, health, foot' },
  { Id: 4926, Code: '3219', Name: 'Practitioner, homeopathic' },
  { Id: 4927, Code: '3219', Name: 'Practitioner, homoeopathic' },
  { Id: 4928, Code: '3219', Name: 'Practitioner, hydropathic' },
  { Id: 4929, Code: '3219', Name: 'Practitioner, massage' },
  { Id: 4930, Code: '3219', Name: 'Practitioner, medicine, complimentary' },
  { Id: 4931, Code: '3219', Name: 'Practitioner, radionic' },
  { Id: 4932, Code: '3219', Name: 'Practitioner, shiatsu' },
  { Id: 4933, Code: '3219', Name: 'Practitioner, technique, alexander' },
  { Id: 4934, Code: '3219', Name: 'Professional, health, foot' },
  { Id: 4935, Code: '3219', Name: 'Reflexologist' },
  { Id: 4936, Code: '3219', Name: 'Specialist, play, hospital' },
  { Id: 4937, Code: '3219', Name: 'Teacher, technique, Alexander' },
  { Id: 4938, Code: '3219', Name: 'Technologist, care, critical' },
  { Id: 4939, Code: '3219', Name: 'Therapist' },
  { Id: 4940, Code: '3219', Name: 'Therapist, Bowen' },
  { Id: 4941, Code: '3219', Name: 'Therapist, colour' },
  { Id: 4942, Code: '3219', Name: 'Therapist, complimentary' },
  { Id: 4943, Code: '3219', Name: 'Therapist, diversional' },
  { Id: 4944, Code: '3219', Name: 'Therapist, holistic' },
  { Id: 4945, Code: '3219', Name: 'Therapist, horticultural' },
  { Id: 4946, Code: '3219', Name: 'Therapist, massage' },
  { Id: 4947, Code: '3219', Name: 'Therapist, nutritional' },
  { Id: 4948, Code: '3219', Name: 'Therapist, rehabilitation' },
  { Id: 4949, Code: '3219', Name: 'Therapist, sports' },
  { Id: 4950, Code: '3219', Name: 'Therapist, stress' },
  { Id: 4951, Code: '3219', Name: 'Trainer, health' },
  { Id: 4952, Code: '3219', Name: 'Trainer, health, senior' },
  { Id: 4953, Code: '3231', Name: 'Assessor, care, community' },
  { Id: 4954, Code: '3231', Name: 'Coordinator, education and community' },
  { Id: 4955, Code: '3231', Name: 'Coordinator, project, community' },
  {
    Id: 4956,
    Code: '3231',
    Name: 'Coordinator, project, community, youth work'
  },
  { Id: 4957, Code: '3231', Name: 'Coordinator, project, youth' },
  {
    Id: 4958,
    Code: '3231',
    Name: 'Coordinator, projects, community, youth work'
  },
  {
    Id: 4959,
    Code: '3231',
    Name: 'Coordinator, schools, extended, educational establishments'
  },
  { Id: 4960, Code: '3231', Name: 'Coordinator, support, family' },
  { Id: 4961, Code: '3231', Name: 'Leader, club' },
  {
    Id: 4962,
    Code: '3231',
    Name: 'Leader, team, district, local government: youth service'
  },
  { Id: 4963, Code: '3231', Name: 'Leader, youth' },
  { Id: 4964, Code: '3231', Name: 'Manager, service, youth' },
  { Id: 4965, Code: '3231', Name: 'Manager, team, community care' },
  { Id: 4966, Code: '3231', Name: 'Officer, behaviour, anti-social' },
  { Id: 4967, Code: '3231', Name: 'Officer, community' },
  { Id: 4968, Code: '3231', Name: 'Officer, development, community' },
  { Id: 4969, Code: '3231', Name: 'Officer, engagement, community' },
  { Id: 4970, Code: '3231', Name: 'Officer, inclusion, social' },
  { Id: 4971, Code: '3231', Name: 'Officer, involvement, community' },
  { Id: 4972, Code: '3231', Name: 'Officer, neighbourhood' },
  { Id: 4973, Code: '3231', Name: 'Officer, relations, community' },
  { Id: 4974, Code: '3231', Name: 'Officer, youth' },
  { Id: 4975, Code: '3231', Name: 'Organiser, youth' },
  { Id: 4976, Code: '3231', Name: 'Ranger, play, senior' },
  { Id: 4977, Code: '3231', Name: 'Warden, centre, community' },
  { Id: 4978, Code: '3231', Name: 'Warden, club, youth' },
  { Id: 4979, Code: '3231', Name: 'Warden, youth club' },
  { Id: 4980, Code: '3231', Name: 'Worker, arts, community' },
  { Id: 4981, Code: '3231', Name: 'Worker, centre, family' },
  { Id: 4982, Code: '3231', Name: 'Worker, community' },
  { Id: 4983, Code: '3231', Name: 'Worker, development, community' },
  { Id: 4984, Code: '3231', Name: 'Worker, development, youth' },
  { Id: 4985, Code: '3231', Name: 'Worker, education, community' },
  { Id: 4986, Code: '3231', Name: 'Worker, outreach' },
  { Id: 4987, Code: '3231', Name: 'Worker, outreach, community' },
  { Id: 4988, Code: '3231', Name: 'Worker, parish' },
  { Id: 4989, Code: '3231', Name: 'Worker, parochial' },
  { Id: 4990, Code: '3231', Name: 'Worker, resource' },
  { Id: 4991, Code: '3231', Name: 'Worker, support, family' },
  { Id: 4992, Code: '3231', Name: 'Worker, support, youth' },
  { Id: 4993, Code: '3231', Name: 'Worker, youth' },
  { Id: 4994, Code: '3231', Name: 'Worker, youth and community' },
  { Id: 4995, Code: '3233', Name: 'Adviser, support, parent' },
  { Id: 4996, Code: '3233', Name: 'Manager, behaviour' },
  { Id: 4997, Code: '3233', Name: 'Manager, support, behaviour' },
  { Id: 4998, Code: '3233', Name: 'Mentor, behaviour' },
  { Id: 4999, Code: '3233', Name: 'Mentor, learning' },
  {
    Id: 5000,
    Code: '3233',
    Name: 'Officer, access, education, local government'
  },
  {
    Id: 5001,
    Code: '3233',
    Name: 'Officer, access, local government, EDUCATION'
  },
  { Id: 5002, Code: '3233', Name: 'Officer, attendance, school' },
  { Id: 5003, Code: '3233', Name: 'Officer, care, child' },
  { Id: 5004, Code: '3233', Name: 'Officer, care, child, residential' },
  { Id: 5005, Code: '3233', Name: 'Officer, enquiry, school' },
  {
    Id: 5006,
    Code: '3233',
    Name: 'Officer, liaison, family, educational establishments'
  },
  { Id: 5007, Code: '3233', Name: 'Officer, liaison, school' },
  { Id: 5008, Code: '3233', Name: 'Officer, protection, child' },
  { Id: 5009, Code: '3233', Name: 'Officer, schools' },
  { Id: 5010, Code: '3233', Name: 'Officer, support, behaviour' },
  { Id: 5011, Code: '3233', Name: 'Officer, support, child' },
  { Id: 5012, Code: '3233', Name: 'Officer, support, pupil' },
  { Id: 5013, Code: '3233', Name: 'Officer, welfare, education' },
  { Id: 5014, Code: '3233', Name: 'Practitioner, parenting, NHS' },
  { Id: 5015, Code: '3233', Name: 'Supervisor, contact, local government' },
  { Id: 5016, Code: '3233', Name: 'Visitor, home, portage' },
  { Id: 5017, Code: '3233', Name: 'Visitor, welfare' },
  {
    Id: 5018,
    Code: '3233',
    Name: "Worker, development, outreach, children's services"
  },
  { Id: 5019, Code: '3233', Name: 'Worker, link, home, schools' },
  {
    Id: 5020,
    Code: '3233',
    Name: 'Worker, portage, educational establishments'
  },
  { Id: 5021, Code: '3233', Name: 'Worker, support, pupil' },
  { Id: 5022, Code: '3234', Name: 'Adviser, housing' },
  { Id: 5023, Code: '3234', Name: 'Coordinator, project, housing' },
  { Id: 5024, Code: '3234', Name: 'Coordinator, project, housing, welfare' },
  { Id: 5025, Code: '3234', Name: 'Enabler, housing, rural' },
  {
    Id: 5026,
    Code: '3234',
    Name: 'Factor, housing, local government, SCOTLAND'
  },
  { Id: 5027, Code: '3234', Name: 'Officer, accommodations' },
  { Id: 5028, Code: '3234', Name: 'Officer, advisory, HOUSING' },
  {
    Id: 5029,
    Code: '3234',
    Name: 'Officer, development, housing, local government'
  },
  { Id: 5030, Code: '3234', Name: 'Officer, enforcement, tenancy' },
  { Id: 5031, Code: '3234', Name: 'Officer, hostel' },
  { Id: 5032, Code: '3234', Name: 'Officer, hostel, senior' },
  { Id: 5033, Code: '3234', Name: 'Officer, housing' },
  { Id: 5034, Code: '3234', Name: 'Officer, management, housing' },
  { Id: 5035, Code: '3234', Name: 'Officer, needs, housing' },
  { Id: 5036, Code: '3234', Name: 'Officer, participation, tenants' },
  { Id: 5037, Code: '3234', Name: 'Officer, persons, homeless' },
  { Id: 5038, Code: '3234', Name: 'Officer, prevention, homeless' },
  { Id: 5039, Code: '3234', Name: 'Officer, relations, tenancy, HOUSING' },
  { Id: 5040, Code: '3234', Name: 'Officer, support, housing' },
  { Id: 5041, Code: '3234', Name: 'Officer, support, housing services' },
  { Id: 5042, Code: '3234', Name: 'Worker, support, housing' },
  { Id: 5043, Code: '3234', Name: 'Worker, support, housing services' },
  { Id: 5044, Code: '3234', Name: 'Worker, support, tenancy' },
  { Id: 5045, Code: '3235', Name: 'Adviser, debt' },
  { Id: 5046, Code: '3235', Name: 'Coach, life' },
  { Id: 5047, Code: '3235', Name: 'Counsellor, care, primary' },
  { Id: 5048, Code: '3235', Name: 'Counsellor, cessation, smoking' },
  { Id: 5049, Code: '3235', Name: 'Counsellor, debt' },
  { Id: 5050, Code: '3235', Name: 'Counsellor, drugs and alcohol' },
  { Id: 5051, Code: '3235', Name: 'Counsellor, further education' },
  { Id: 5052, Code: '3235', Name: 'Counsellor, higher education, university' },
  { Id: 5053, Code: '3235', Name: 'Counsellor, Relate' },
  { Id: 5054, Code: '3235', Name: 'Counsellor, school' },
  { Id: 5055, Code: '3235', Name: 'Counsellor, smoking, stop' },
  { Id: 5056, Code: '3235', Name: 'Counsellor, student' },
  { Id: 5057, Code: '3235', Name: 'Counsellor, welfare services' },
  {
    Id: 5058,
    Code: '3235',
    Name: 'Facilitator, interventions, prison service'
  },
  { Id: 5059, Code: '3239', Name: 'Administrator, circuit' },
  { Id: 5060, Code: '3239', Name: 'Adviser, benefits' },
  { Id: 5061, Code: '3239', Name: 'Adviser, cessation, smoking' },
  { Id: 5062, Code: '3239', Name: 'Adviser, Citizens Advice Bureau' },
  { Id: 5063, Code: '3239', Name: 'Adviser, health' },
  { Id: 5064, Code: '3239', Name: 'Adviser, promotion, health' },
  { Id: 5065, Code: '3239', Name: 'Adviser, rights, welfare' },
  { Id: 5066, Code: '3239', Name: 'Adviser, services, student' },
  { Id: 5067, Code: '3239', Name: 'Adviser, smoking, stop' },
  { Id: 5068, Code: '3239', Name: 'Adviser, student' },
  { Id: 5069, Code: '3239', Name: 'Adviser, welfare' },
  { Id: 5070, Code: '3239', Name: 'Advocate, carers' },
  { Id: 5071, Code: '3239', Name: 'Advocate, health, mental' },
  { Id: 5072, Code: '3239', Name: 'Advocate, home, care' },
  { Id: 5073, Code: '3239', Name: 'Advocate, IMCA' },
  { Id: 5074, Code: '3239', Name: 'Advocate, student' },
  { Id: 5075, Code: '3239', Name: 'Advocate, welfare services' },
  { Id: 5076, Code: '3239', Name: 'Ancillary, service, probation' },
  { Id: 5077, Code: '3239', Name: 'Arranger, care, local government' },
  { Id: 5078, Code: '3239', Name: 'Assessor, care, home' },
  {
    Id: 5079,
    Code: '3239',
    Name: 'Assessor, case, local government: social services'
  },
  { Id: 5080, Code: '3239', Name: 'Assistant, justice, criminal, SCOTLAND' },
  { Id: 5081, Code: '3239', Name: 'Assistant, pastoral' },
  { Id: 5082, Code: '3239', Name: 'Assistant, probation' },
  { Id: 5083, Code: '3239', Name: 'Assistant, service, probation' },
  { Id: 5084, Code: '3239', Name: 'Assistant, services, probation' },
  { Id: 5085, Code: '3239', Name: 'Assistant, welfare' },
  { Id: 5086, Code: '3239', Name: 'Assistant, work, social' },
  { Id: 5087, Code: '3239', Name: 'Attendant, welfare' },
  { Id: 5088, Code: '3239', Name: 'Broker, care' },
  {
    Id: 5089,
    Code: '3239',
    Name: 'Broker, independent, social, welfare services'
  },
  {
    Id: 5090,
    Code: '3239',
    Name: 'Broker, personal, social, welfare services'
  },
  { Id: 5091, Code: '3239', Name: 'Broker, social, welfare services' },
  { Id: 5092, Code: '3239', Name: 'Broker, support, care' },
  { Id: 5093, Code: '3239', Name: 'Broker, support, social, welfare services' },
  { Id: 5094, Code: '3239', Name: 'Campaigner, charity' },
  { Id: 5095, Code: '3239', Name: 'Captain, religious organisation' },
  { Id: 5096, Code: '3239', Name: 'Captain, Salvation Army' },
  { Id: 5097, Code: '3239', Name: 'Caseworker, advice' },
  {
    Id: 5098,
    Code: '3239',
    Name: 'Caseworker, local government: social services'
  },
  { Id: 5099, Code: '3239', Name: 'Caseworker, social, welfare services' },
  { Id: 5100, Code: '3239', Name: 'Celebrant' },
  { Id: 5101, Code: '3239', Name: 'Consultant, broker, care' },
  { Id: 5102, Code: '3239', Name: 'Coordinator, CHARITABLE, WELFARE SERVICES' },
  { Id: 5103, Code: '3239', Name: 'Coordinator, charity' },
  { Id: 5104, Code: '3239', Name: 'Coordinator, health' },
  { Id: 5105, Code: '3239', Name: 'Coordinator, justice, restorative' },
  {
    Id: 5106,
    Code: '3239',
    Name: 'Coordinator, project, CHARITABLE, WELFARE SERVICES'
  },
  { Id: 5107, Code: '3239', Name: 'Coordinator, project, welfare' },
  {
    Id: 5108,
    Code: '3239',
    Name: 'Coordinator, projects, CHARITABLE, WELFARE SERVICES'
  },
  { Id: 5109, Code: '3239', Name: 'Coordinator, smoking, stop' },
  { Id: 5110, Code: '3239', Name: 'Coordinator, volunteer' },
  { Id: 5111, Code: '3239', Name: 'Elder, church' },
  { Id: 5112, Code: '3239', Name: 'Enabler, mission' },
  { Id: 5113, Code: '3239', Name: 'Evangelist' },
  { Id: 5114, Code: '3239', Name: 'Facilitator, programme, prison service' },
  { Id: 5115, Code: '3239', Name: 'Healer' },
  { Id: 5116, Code: '3239', Name: 'Manager, services, IMCA' },
  { Id: 5117, Code: '3239', Name: 'Mediator' },
  { Id: 5118, Code: '3239', Name: 'Missionary' },
  { Id: 5119, Code: '3239', Name: 'Missioner' },
  { Id: 5120, Code: '3239', Name: 'Navigator, care' },
  {
    Id: 5121,
    Code: '3239',
    Name: 'Officer, access, disability, local government'
  },
  {
    Id: 5122,
    Code: '3239',
    Name: 'Officer, access, local government, DISABILITY'
  },
  { Id: 5123, Code: '3239', Name: 'Officer, advisory, WELFARE' },
  {
    Id: 5124,
    Code: '3239',
    Name: 'Officer, brokerage, social, welfare services'
  },
  { Id: 5125, Code: '3239', Name: 'Officer, care' },
  { Id: 5126, Code: '3239', Name: 'Officer, care, day' },
  { Id: 5127, Code: '3239', Name: 'Officer, care, secure' },
  { Id: 5128, Code: '3239', Name: 'Officer, care, social' },
  { Id: 5129, Code: '3239', Name: 'Officer, care, victim' },
  { Id: 5130, Code: '3239', Name: 'Officer, centre, day' },
  { Id: 5131, Code: '3239', Name: 'Officer, development, welfare services' },
  { Id: 5132, Code: '3239', Name: 'Officer, disability' },
  { Id: 5133, Code: '3239', Name: 'Officer, homelessness' },
  { Id: 5134, Code: '3239', Name: 'Officer, information and advice' },
  { Id: 5135, Code: '3239', Name: 'Officer, justice, restorative' },
  { Id: 5136, Code: '3239', Name: 'Officer, liaison, student' },
  {
    Id: 5137,
    Code: '3239',
    Name: 'Officer, placement, local government: social services'
  },
  { Id: 5138, Code: '3239', Name: 'Officer, relieving' },
  { Id: 5139, Code: '3239', Name: 'Officer, resettlement' },
  { Id: 5140, Code: '3239', Name: 'Officer, rights, welfare' },
  { Id: 5141, Code: '3239', Name: 'Officer, service, probation' },
  { Id: 5142, Code: '3239', Name: 'Officer, services, day' },
  { Id: 5143, Code: '3239', Name: 'Officer, services, probation' },
  { Id: 5144, Code: '3239', Name: 'Officer, support, bail' },
  { Id: 5145, Code: '3239', Name: 'Officer, support, student' },
  { Id: 5146, Code: '3239', Name: 'Officer, support, welfare services' },
  { Id: 5147, Code: '3239', Name: 'Officer, welfare' },
  { Id: 5148, Code: '3239', Name: 'Officer, welfare, chief' },
  { Id: 5149, Code: '3239', Name: 'Officer, welfare, student' },
  { Id: 5150, Code: '3239', Name: 'Officiant' },
  { Id: 5151, Code: '3239', Name: 'Organiser, care' },
  { Id: 5152, Code: '3239', Name: 'Organiser, care, day' },
  { Id: 5153, Code: '3239', Name: 'Organiser, care, home' },
  { Id: 5154, Code: '3239', Name: 'Organiser, centre, day' },
  { Id: 5155, Code: '3239', Name: 'Organiser, help, home' },
  { Id: 5156, Code: '3239', Name: 'Organiser, project, welfare services' },
  { Id: 5157, Code: '3239', Name: 'Organiser, welfare' },
  { Id: 5158, Code: '3239', Name: 'Organiser, welfare services' },
  { Id: 5159, Code: '3239', Name: 'Organiser, work, volunteer' },
  { Id: 5160, Code: '3239', Name: 'Practitioner, misuse, substance' },
  { Id: 5161, Code: '3239', Name: 'Preacher, lay' },
  { Id: 5162, Code: '3239', Name: 'Preacher, local' },
  { Id: 5163, Code: '3239', Name: 'Reader, lay' },
  { Id: 5164, Code: '3239', Name: 'Specialist, cessation, smoking' },
  { Id: 5165, Code: '3239', Name: 'Superintendent, welfare' },
  { Id: 5166, Code: '3239', Name: 'Supervisor, care, day' },
  { Id: 5167, Code: '3239', Name: 'Supervisor, centre, day' },
  { Id: 5168, Code: '3239', Name: 'Supervisor, welfare' },
  { Id: 5169, Code: '3239', Name: 'Worker, advice' },
  { Id: 5170, Code: '3239', Name: 'Worker, advocacy' },
  { Id: 5171, Code: '3239', Name: 'Worker, aid, family' },
  { Id: 5172, Code: '3239', Name: 'Worker, ancillary, probation service' },
  { Id: 5173, Code: '3239', Name: 'Worker, army, Church' },
  { Id: 5174, Code: '3239', Name: 'Worker, care, leaving' },
  { Id: 5175, Code: '3239', Name: 'Worker, case, advice' },
  {
    Id: 5176,
    Code: '3239',
    Name: 'Worker, case, local government: social services'
  },
  { Id: 5177, Code: '3239', Name: 'Worker, case, social, welfare services' },
  { Id: 5178, Code: '3239', Name: 'Worker, cessation, smoking' },
  { Id: 5179, Code: '3239', Name: 'Worker, church' },
  { Id: 5180, Code: '3239', Name: 'Worker, development, outreach' },
  { Id: 5181, Code: '3239', Name: 'Worker, development, welfare services' },
  {
    Id: 5182,
    Code: '3239',
    Name: 'Worker, field, CHARITABLE, WELFARE SERVICES'
  },
  {
    Id: 5183,
    Code: '3239',
    Name: 'Worker, group, local government: social services'
  },
  { Id: 5184, Code: '3239', Name: 'Worker, justice, restorative' },
  { Id: 5185, Code: '3239', Name: 'Worker, key, welfare services' },
  { Id: 5186, Code: '3239', Name: 'Worker, lay' },
  { Id: 5187, Code: '3239', Name: 'Worker, misuse, substance' },
  { Id: 5188, Code: '3239', Name: 'Worker, outreach, welfare services' },
  { Id: 5189, Code: '3239', Name: 'Worker, participation and rights' },
  { Id: 5190, Code: '3239', Name: 'Worker, project, welfare services' },
  {
    Id: 5191,
    Code: '3239',
    Name: 'Worker, recovery, time, support, social, welfare services'
  },
  { Id: 5192, Code: '3239', Name: 'Worker, refuge, welfare services' },
  { Id: 5193, Code: '3239', Name: 'Worker, rehabilitation' },
  { Id: 5194, Code: '3239', Name: 'Worker, rescue' },
  { Id: 5195, Code: '3239', Name: 'Worker, resettlement, welfare services' },
  { Id: 5196, Code: '3239', Name: 'Worker, sessional' },
  { Id: 5197, Code: '3239', Name: 'Worker, settlement' },
  { Id: 5198, Code: '3239', Name: 'Worker, STR, social, welfare services' },
  { Id: 5199, Code: '3239', Name: 'Worker, support, crisis' },
  { Id: 5200, Code: '3239', Name: 'Worker, support, drug and alcohol' },
  { Id: 5201, Code: '3239', Name: 'Worker, welfare' },
  { Id: 5202, Code: '3311', Name: 'AB, armed forces' },
  { Id: 5203, Code: '3311', Name: 'Aircraftman' },
  { Id: 5204, Code: '3311', Name: 'Aircraftwoman' },
  { Id: 5205, Code: '3311', Name: 'Aircrew, master, armed forces' },
  { Id: 5206, Code: '3311', Name: 'Aircrewman' },
  { Id: 5207, Code: '3311', Name: 'Airman, armed forces' },
  { Id: 5208, Code: '3311', Name: 'Airman, Force, Air, Royal' },
  { Id: 5209, Code: '3311', Name: 'Airwoman, Force, Air, Royal' },
  { Id: 5210, Code: '3311', Name: 'Bombardier' },
  { Id: 5211, Code: '3311', Name: 'Commando, Marines, Royal' },
  { Id: 5212, Code: '3311', Name: 'Corporal' },
  { Id: 5213, Code: '3311', Name: 'Engineer, disposal, bomb, armed forces' },
  { Id: 5214, Code: '3311', Name: 'Engineer, systems, weapons, armed forces' },
  { Id: 5215, Code: '3311', Name: 'Engineer, weapons, armed forces' },
  { Id: 5216, Code: '3311', Name: 'Fitter, equipment, survival, armed forces' },
  { Id: 5217, Code: '3311', Name: 'Flight-Sergeant' },
  { Id: 5218, Code: '3311', Name: 'Fusilier' },
  { Id: 5219, Code: '3311', Name: 'GI' },
  { Id: 5220, Code: '3311', Name: 'Guardsman' },
  { Id: 5221, Code: '3311', Name: 'Gunner' },
  { Id: 5222, Code: '3311', Name: 'Infantryman' },
  { Id: 5223, Code: '3311', Name: 'Lance-Bombardier' },
  { Id: 5224, Code: '3311', Name: 'Lance-Corporal' },
  { Id: 5225, Code: '3311', Name: 'Lance-Sergeant' },
  { Id: 5226, Code: '3311', Name: 'Loadmaster, armed forces' },
  { Id: 5227, Code: '3311', Name: 'Marine' },
  { Id: 5228, Code: '3311', Name: 'Master at Arms, armed forces' },
  { Id: 5229, Code: '3311', Name: 'Master, quarter, armed forces' },
  { Id: 5230, Code: '3311', Name: 'Officer, non-commissioned' },
  { Id: 5231, Code: '3311', Name: 'Officer, petty, armed forces' },
  { Id: 5232, Code: '3311', Name: 'Officer, warrant, armed forces' },
  { Id: 5233, Code: '3311', Name: 'Paratrooper' },
  { Id: 5234, Code: '3311', Name: 'Private, armed forces' },
  { Id: 5235, Code: '3311', Name: 'Quartermaster, armed forces' },
  { Id: 5236, Code: '3311', Name: 'Quartermaster-Corporal' },
  { Id: 5237, Code: '3311', Name: 'Quartermaster-Sergeant' },
  { Id: 5238, Code: '3311', Name: 'Rating' },
  { Id: 5239, Code: '3311', Name: 'Rating, Navy, Royal' },
  { Id: 5240, Code: '3311', Name: 'Rifleman' },
  { Id: 5241, Code: '3311', Name: 'SAC, armed forces' },
  { Id: 5242, Code: '3311', Name: 'Sailor, armed forces' },
  { Id: 5243, Code: '3311', Name: 'Sapper' },
  { Id: 5244, Code: '3311', Name: 'Seaman, armed forces' },
  { Id: 5245, Code: '3311', Name: 'Sergeant, armed forces' },
  { Id: 5246, Code: '3311', Name: 'Sergeant-Major' },
  { Id: 5247, Code: '3311', Name: 'Soldier' },
  { Id: 5248, Code: '3311', Name: 'Technician, aircraft, armed forces' },
  { Id: 5249, Code: '3311', Name: 'Technician, armed forces' },
  { Id: 5250, Code: '3311', Name: 'Technician, weapons, armed forces' },
  { Id: 5251, Code: '3311', Name: 'Wren' },
  { Id: 5252, Code: '3312', Name: 'Armourer, force' },
  { Id: 5253, Code: '3312', Name: 'Cadet, POLICE' },
  { Id: 5254, Code: '3312', Name: 'Constable' },
  { Id: 5255, Code: '3312', Name: 'Constable, government' },
  { Id: 5256, Code: '3312', Name: 'Constable, MOD' },
  { Id: 5257, Code: '3312', Name: 'Constable, police' },
  { Id: 5258, Code: '3312', Name: 'Constable, police service' },
  { Id: 5259, Code: '3312', Name: 'Detective, airport' },
  { Id: 5260, Code: '3312', Name: 'Detective, docks' },
  { Id: 5261, Code: '3312', Name: 'Detective, government' },
  { Id: 5262, Code: '3312', Name: 'Detective, police service' },
  { Id: 5263, Code: '3312', Name: 'Detective, railways' },
  { Id: 5264, Code: '3312', Name: 'Handler, dog, police' },
  { Id: 5265, Code: '3312', Name: 'Manager, beat, community' },
  { Id: 5266, Code: '3312', Name: 'MP, armed forces' },
  { Id: 5267, Code: '3312', Name: 'Officer, fingerprint' },
  { Id: 5268, Code: '3312', Name: 'Officer, neighbourhood, police service' },
  { Id: 5269, Code: '3312', Name: 'Officer, police' },
  { Id: 5270, Code: '3312', Name: 'Officer, police service' },
  { Id: 5271, Code: '3312', Name: 'Officer, police, transport' },
  { Id: 5272, Code: '3312', Name: 'Officer, traffic, police service' },
  { Id: 5273, Code: '3312', Name: 'Officer, uniformed, police service' },
  { Id: 5274, Code: '3312', Name: 'Officer, warrant, police service' },
  { Id: 5275, Code: '3312', Name: 'PC' },
  { Id: 5276, Code: '3312', Name: 'Policeman' },
  { Id: 5277, Code: '3312', Name: 'Sergeant' },
  { Id: 5278, Code: '3312', Name: 'Sergeant, detective' },
  { Id: 5279, Code: '3312', Name: 'Sergeant, police' },
  { Id: 5280, Code: '3312', Name: 'WPC' },
  { Id: 5281, Code: '3313', Name: 'Adviser, prevention, fire' },
  { Id: 5282, Code: '3313', Name: "Assistant, fireman's" },
  { Id: 5283, Code: '3313', Name: 'Engineer, fire' },
  { Id: 5284, Code: '3313', Name: 'Fighter, fire' },
  { Id: 5285, Code: '3313', Name: 'Firefighter' },
  { Id: 5286, Code: '3313', Name: 'Fireman' },
  { Id: 5287, Code: '3313', Name: 'Fireman, airport' },
  { Id: 5288, Code: '3313', Name: 'Fireman, industrial' },
  { Id: 5289, Code: '3313', Name: 'Fireman, private' },
  { Id: 5290, Code: '3313', Name: 'Fireman, security' },
  { Id: 5291, Code: '3313', Name: 'Fireman, works, nos' },
  { Id: 5292, Code: '3313', Name: 'Guard, fire' },
  { Id: 5293, Code: '3313', Name: 'Leader, section, fire service' },
  { Id: 5294, Code: '3313', Name: 'Man, corps, salvage' },
  { Id: 5295, Code: '3313', Name: 'Man, prevention, fire' },
  { Id: 5296, Code: '3313', Name: 'Manager, crew, fire service' },
  { Id: 5297, Code: '3313', Name: 'Manager, watch, fire service' },
  { Id: 5298, Code: '3313', Name: 'Officer, fire' },
  { Id: 5299, Code: '3313', Name: 'Officer, fire, coal mine' },
  { Id: 5300, Code: '3313', Name: 'Officer, fire, leading' },
  { Id: 5301, Code: '3313', Name: 'Officer, prevention, fire' },
  { Id: 5302, Code: '3313', Name: 'Officer, safety, fire' },
  { Id: 5303, Code: '3313', Name: 'Officer, security, fire' },
  { Id: 5304, Code: '3313', Name: 'Officer, sub, fire service' },
  { Id: 5305, Code: '3313', Name: 'Officer, watch, fire service' },
  { Id: 5306, Code: '3313', Name: 'Sub-officer, fire service' },
  { Id: 5307, Code: '3314', Name: 'Auxiliary, prison' },
  { Id: 5308, Code: '3314', Name: 'Officer, auxiliary, prison service' },
  { Id: 5309, Code: '3314', Name: 'Officer, borstal' },
  { Id: 5310, Code: '3314', Name: 'Officer, custodial, prison' },
  { Id: 5311, Code: '3314', Name: 'Officer, custody' },
  { Id: 5312, Code: '3314', Name: 'Officer, discipline, BORSTAL' },
  { Id: 5313, Code: '3314', Name: 'Officer, discipline, prison service' },
  { Id: 5314, Code: '3314', Name: 'Officer, escort, prison' },
  { Id: 5315, Code: '3314', Name: 'Officer, grade, support, operational' },
  { Id: 5316, Code: '3314', Name: 'Officer, prison' },
  { Id: 5317, Code: '3314', Name: 'Supervisor, custody, prison' },
  { Id: 5318, Code: '3314', Name: 'Turnkey' },
  { Id: 5319, Code: '3314', Name: 'Warden, prison' },
  { Id: 5320, Code: '3314', Name: 'Warder, prison service' },
  { Id: 5321, Code: '3315', Name: 'Officer, community, rail' },
  { Id: 5322, Code: '3315', Name: 'Officer, protection, community, police' },
  {
    Id: 5323,
    Code: '3315',
    Name: 'Officer, protection, community, police service'
  },
  {
    Id: 5324,
    Code: '3315',
    Name: 'Officer, support, civilian, police service'
  },
  { Id: 5325, Code: '3315', Name: 'Officer, support, community, police' },
  {
    Id: 5326,
    Code: '3315',
    Name: 'Officer, support, community, police service'
  },
  { Id: 5327, Code: '3315', Name: 'PCSO' },
  { Id: 5328, Code: '3319', Name: 'Analyst, forensic' },
  { Id: 5329, Code: '3319', Name: 'Analyst, fraud' },
  { Id: 5330, Code: '3319', Name: 'Analyst, handwriting' },
  { Id: 5331, Code: '3319', Name: 'Analyst, strategy, fraud' },
  { Id: 5332, Code: '3319', Name: 'Assistant, watch, coastguard' },
  { Id: 5333, Code: '3319', Name: 'Assistant, watch, coastguards' },
  { Id: 5334, Code: '3319', Name: 'Coastguard' },
  { Id: 5335, Code: '3319', Name: 'Consultant, security, maritime' },
  { Id: 5336, Code: '3319', Name: 'Coordinator, security' },
  { Id: 5337, Code: '3319', Name: 'Examiner, handwriting, forensic' },
  { Id: 5338, Code: '3319', Name: 'Examiner, scene' },
  { Id: 5339, Code: '3319', Name: 'Executive, security' },
  { Id: 5340, Code: '3319', Name: 'Expert, fingerprint' },
  { Id: 5341, Code: '3319', Name: 'Expert, print, finger' },
  { Id: 5342, Code: '3319', Name: 'Guard, coast' },
  { Id: 5343, Code: '3319', Name: 'Guard, water, HM Revenue and Customs' },
  { Id: 5344, Code: '3319', Name: 'Head of security' },
  { Id: 5345, Code: '3319', Name: 'Instructor, defence, civil' },
  { Id: 5346, Code: '3319', Name: 'Investigator, fraud, bank' },
  { Id: 5347, Code: '3319', Name: 'Investigator, fraud, building society' },
  { Id: 5348, Code: '3319', Name: 'Investigator, fraud, insurance' },
  { Id: 5349, Code: '3319', Name: 'Investigator, scene, crime' },
  { Id: 5350, Code: '3319', Name: 'Manager, branch, security services' },
  { Id: 5351, Code: '3319', Name: 'Manager, CCTV' },
  { Id: 5352, Code: '3319', Name: 'Manager, investigations' },
  { Id: 5353, Code: '3319', Name: 'Manager, operations, CCTV' },
  { Id: 5354, Code: '3319', Name: 'Manager, operations, security services' },
  { Id: 5355, Code: '3319', Name: 'Manager, prevention, loss' },
  { Id: 5356, Code: '3319', Name: 'Manager, private detective agency' },
  { Id: 5357, Code: '3319', Name: 'Manager, security' },
  { Id: 5358, Code: '3319', Name: 'Manager, security services' },
  { Id: 5359, Code: '3319', Name: 'Officer, CCTV' },
  { Id: 5360, Code: '3319', Name: 'Officer, coastguard' },
  { Id: 5361, Code: '3319', Name: 'Officer, control, port' },
  { Id: 5362, Code: '3319', Name: 'Officer, customs' },
  { Id: 5363, Code: '3319', Name: 'Officer, Customs and Excise' },
  { Id: 5364, Code: '3319', Name: 'Officer, defence, civil' },
  { Id: 5365, Code: '3319', Name: 'Officer, district, coastguard service' },
  { Id: 5366, Code: '3319', Name: 'Officer, enforcement, law' },
  { Id: 5367, Code: '3319', Name: 'Officer, excise, HM Revenue and Customs' },
  { Id: 5368, Code: '3319', Name: 'Officer, force, border' },
  { Id: 5369, Code: '3319', Name: 'Officer, fraud, bank' },
  { Id: 5370, Code: '3319', Name: 'Officer, fraud, building society' },
  { Id: 5371, Code: '3319', Name: 'Officer, immigration' },
  {
    Id: 5372,
    Code: '3319',
    Name: 'Officer, investigating, fraud, HM Revenue and Customs'
  },
  {
    Id: 5373,
    Code: '3319',
    Name: 'Officer, investigating, HM Revenue and Customs'
  },
  {
    Id: 5374,
    Code: '3319',
    Name: 'Officer, investigation, HM Revenue and Customs'
  },
  { Id: 5375, Code: '3319', Name: 'Officer, planning, emergency' },
  { Id: 5376, Code: '3319', Name: 'Officer, prevention, crime' },
  { Id: 5377, Code: '3319', Name: 'Officer, preventive' },
  { Id: 5378, Code: '3319', Name: 'Officer, scenes of crime' },
  { Id: 5379, Code: '3319', Name: 'Officer, security, maritime' },
  { Id: 5380, Code: '3319', Name: 'Officer, station, coastguard service' },
  { Id: 5381, Code: '3319', Name: 'Officer, submissions, forensic' },
  { Id: 5382, Code: '3319', Name: 'Officer, traffic, Highways Agency' },
  { Id: 5383, Code: '3319', Name: 'Officer, watch, coastguard' },
  { Id: 5384, Code: '3319', Name: 'Owner, agency, detective' },
  { Id: 5385, Code: '3319', Name: 'Owner, detective agency' },
  { Id: 5386, Code: '3319', Name: 'Owner, security services' },
  { Id: 5387, Code: '3319', Name: 'Security, maritime' },
  { Id: 5388, Code: '3319', Name: 'Specialist, fraud, counter' },
  { Id: 5389, Code: '3319', Name: 'Steward, route, HIGHWAYS' },
  { Id: 5390, Code: '3319', Name: 'Superintendent, rescue, coal mine' },
  { Id: 5391, Code: '3319', Name: 'Superintendent, station, rescue' },
  {
    Id: 5392,
    Code: '3319',
    Name: 'Supervisor, payback, community, probation service'
  },
  { Id: 5393, Code: '3319', Name: 'Waterguard, HM Revenue and Customs' },
  { Id: 5394, Code: '3411', Name: 'Animator' },
  { Id: 5395, Code: '3411', Name: 'Artist' },
  { Id: 5396, Code: '3411', Name: 'Artist and designer, fashion' },
  { Id: 5397, Code: '3411', Name: 'Artist, fashion' },
  { Id: 5398, Code: '3411', Name: 'Artist, medical, hospital service' },
  { Id: 5399, Code: '3411', Name: 'Artist, press' },
  { Id: 5400, Code: '3411', Name: 'Artist, scenic' },
  { Id: 5401, Code: '3411', Name: 'Artist, visual' },
  { Id: 5402, Code: '3411', Name: 'Artist-designer' },
  { Id: 5403, Code: '3411', Name: "Assistant, sculptor's" },
  { Id: 5404, Code: '3411', Name: 'Calligrapher' },
  { Id: 5405, Code: '3411', Name: 'Cartoonist' },
  { Id: 5406, Code: '3411', Name: 'Cleaner, picture' },
  { Id: 5407, Code: '3411', Name: 'Engraver, portrait' },
  { Id: 5408, Code: '3411', Name: 'Etcher, black and white' },
  { Id: 5409, Code: '3411', Name: 'Illustrator' },
  { Id: 5410, Code: '3411', Name: 'Illustrator, book' },
  { Id: 5411, Code: '3411', Name: 'Illustrator, chief' },
  { Id: 5412, Code: '3411', Name: 'Illustrator, fashion' },
  { Id: 5413, Code: '3411', Name: 'Illustrator, leading' },
  { Id: 5414, Code: '3411', Name: 'Illustrator, medical' },
  { Id: 5415, Code: '3411', Name: 'Illustrator, senior' },
  { Id: 5416, Code: '3411', Name: 'Modeller, artistic' },
  { Id: 5417, Code: '3411', Name: 'Painter, artistic' },
  { Id: 5418, Code: '3411', Name: 'Painter, colour, water' },
  { Id: 5419, Code: '3411', Name: 'Painter, landscape' },
  { Id: 5420, Code: '3411', Name: 'Painter, marine' },
  { Id: 5421, Code: '3411', Name: 'Painter, miniature' },
  { Id: 5422, Code: '3411', Name: 'Painter, portrait' },
  { Id: 5423, Code: '3411', Name: 'Restorer, art' },
  { Id: 5424, Code: '3411', Name: 'Restorer, picture' },
  { Id: 5425, Code: '3411', Name: 'Sculptor' },
  { Id: 5426, Code: '3411', Name: 'Technician, art' },
  { Id: 5427, Code: '3412', Name: 'Adviser, literary' },
  { Id: 5428, Code: '3412', Name: 'Agent, literary' },
  { Id: 5429, Code: '3412', Name: 'Assistant, editorial' },
  { Id: 5430, Code: '3412', Name: 'Author' },
  { Id: 5431, Code: '3412', Name: 'Author, technical' },
  { Id: 5432, Code: '3412', Name: 'Bibliographer' },
  { Id: 5433, Code: '3412', Name: 'Biographer' },
  { Id: 5434, Code: '3412', Name: 'Blogger' },
  { Id: 5435, Code: '3412', Name: 'Communicator for the deaf' },
  { Id: 5436, Code: '3412', Name: 'Compiler, technical' },
  { Id: 5437, Code: '3412', Name: 'Consultant, editorial' },
  { Id: 5438, Code: '3412', Name: 'Consultant, language' },
  { Id: 5439, Code: '3412', Name: 'Consultant, publishing' },
  { Id: 5440, Code: '3412', Name: 'Copywriter' },
  { Id: 5441, Code: '3412', Name: 'Describer, audio' },
  { Id: 5442, Code: '3412', Name: 'Dramatist' },
  { Id: 5443, Code: '3412', Name: 'Editor, BOOKS' },
  { Id: 5444, Code: '3412', Name: 'Editor, cartographic' },
  { Id: 5445, Code: '3412', Name: 'Editor, managing, BOOKS' },
  { Id: 5446, Code: '3412', Name: 'Editor, sales' },
  { Id: 5447, Code: '3412', Name: 'Editor, technical' },
  { Id: 5448, Code: '3412', Name: 'Editor-in-chief' },
  { Id: 5449, Code: '3412', Name: 'Interpreter' },
  { Id: 5450, Code: '3412', Name: 'Interpreter and translator' },
  { Id: 5451, Code: '3412', Name: 'Lexicographer' },
  { Id: 5452, Code: '3412', Name: 'Linguist' },
  { Id: 5453, Code: '3412', Name: 'Manager, publications, technical' },
  { Id: 5454, Code: '3412', Name: 'Novelist' },
  { Id: 5455, Code: '3412', Name: 'Playwright' },
  { Id: 5456, Code: '3412', Name: 'Poet' },
  { Id: 5457, Code: '3412', Name: 'Reader, lip' },
  { Id: 5458, Code: '3412', Name: 'Reader, literary' },
  { Id: 5459, Code: '3412', Name: "Reader, publisher's" },
  { Id: 5460, Code: '3412', Name: 'Screenwriter' },
  { Id: 5461, Code: '3412', Name: 'Speechwriter' },
  { Id: 5462, Code: '3412', Name: 'Translator' },
  { Id: 5463, Code: '3412', Name: 'Translator and interpreter' },
  { Id: 5464, Code: '3412', Name: 'Writer' },
  { Id: 5465, Code: '3412', Name: 'Writer and creator' },
  { Id: 5466, Code: '3412', Name: 'Writer, communications, corporate' },
  { Id: 5467, Code: '3412', Name: 'Writer, copy' },
  { Id: 5468, Code: '3412', Name: 'Writer, creative' },
  { Id: 5469, Code: '3412', Name: 'Writer, freelance' },
  { Id: 5470, Code: '3412', Name: 'Writer, lyric' },
  { Id: 5471, Code: '3412', Name: 'Writer, medical' },
  { Id: 5472, Code: '3412', Name: 'Writer, report' },
  { Id: 5473, Code: '3412', Name: 'Writer, reports, senior, broadcasting' },
  { Id: 5474, Code: '3412', Name: 'Writer, screen' },
  { Id: 5475, Code: '3412', Name: 'Writer, script' },
  { Id: 5476, Code: '3412', Name: 'Writer, specialist' },
  { Id: 5477, Code: '3412', Name: 'Writer, specifications' },
  { Id: 5478, Code: '3412', Name: 'Writer, technical' },
  { Id: 5479, Code: '3412', Name: 'Writer, technical, PATENTS' },
  { Id: 5480, Code: '3412', Name: 'Writer, technical, senior' },
  { Id: 5481, Code: '3412', Name: 'Writer, travel' },
  { Id: 5482, Code: '3413', Name: 'Acrobat' },
  { Id: 5483, Code: '3413', Name: 'Actor' },
  { Id: 5484, Code: '3413', Name: 'Actor-manager' },
  { Id: 5485, Code: '3413', Name: 'Aerialist' },
  { Id: 5486, Code: '3413', Name: 'Announcer, broadcasting' },
  { Id: 5487, Code: '3413', Name: 'Announcer, ENTERTAINMENT' },
  { Id: 5488, Code: '3413', Name: 'Artist, ENTERTAINMENT' },
  { Id: 5489, Code: '3413', Name: 'Artist, film' },
  { Id: 5490, Code: '3413', Name: 'Artist, over, voice' },
  { Id: 5491, Code: '3413', Name: 'Artist, recording' },
  { Id: 5492, Code: '3413', Name: 'Artiste' },
  { Id: 5493, Code: '3413', Name: "Assistant, showman's" },
  { Id: 5494, Code: '3413', Name: 'Choirboy' },
  { Id: 5495, Code: '3413', Name: 'Chorister' },
  { Id: 5496, Code: '3413', Name: 'Clerk, lay' },
  { Id: 5497, Code: '3413', Name: 'Clown' },
  { Id: 5498, Code: '3413', Name: 'Coach, singing' },
  { Id: 5499, Code: '3413', Name: 'Comedian' },
  { Id: 5500, Code: '3413', Name: 'Commentator, broadcasting' },
  { Id: 5501, Code: '3413', Name: 'Compere' },
  { Id: 5502, Code: '3413', Name: 'Conjurer' },
  { Id: 5503, Code: '3413', Name: 'Correspondent, broadcasting' },
  { Id: 5504, Code: '3413', Name: 'DJ, ENTERTAINMENT' },
  { Id: 5505, Code: '3413', Name: 'Entertainer' },
  { Id: 5506, Code: '3413', Name: 'Extra, ENTERTAINMENT' },
  { Id: 5507, Code: '3413', Name: 'Girl, chorus' },
  { Id: 5508, Code: '3413', Name: 'Girl, show' },
  { Id: 5509, Code: '3413', Name: 'Hypnotist, ENTERTAINMENT' },
  { Id: 5510, Code: '3413', Name: 'Illusionist' },
  { Id: 5511, Code: '3413', Name: 'Impresario' },
  { Id: 5512, Code: '3413', Name: 'Impressionist' },
  { Id: 5513, Code: '3413', Name: 'Interpreter, costumed' },
  { Id: 5514, Code: '3413', Name: 'Interviewer, television' },
  { Id: 5515, Code: '3413', Name: 'Jockey, disc' },
  { Id: 5516, Code: '3413', Name: 'Jockey, disc, broadcasting' },
  { Id: 5517, Code: '3413', Name: 'Judge, ENTERTAINMENT' },
  { Id: 5518, Code: '3413', Name: 'Juggler' },
  { Id: 5519, Code: '3413', Name: 'Livestreamer' },
  { Id: 5520, Code: '3413', Name: 'Magician' },
  { Id: 5521, Code: '3413', Name: 'Man, stunt' },
  { Id: 5522, Code: '3413', Name: 'Mannequin' },
  { Id: 5523, Code: '3413', Name: 'Model' },
  { Id: 5524, Code: '3413', Name: 'Newscaster, broadcasting' },
  { Id: 5525, Code: '3413', Name: 'Newsreader' },
  { Id: 5526, Code: '3413', Name: 'Officer, entertainments' },
  { Id: 5527, Code: '3413', Name: 'Performer, circus' },
  { Id: 5528, Code: '3413', Name: 'Presenter, broadcasting' },
  { Id: 5529, Code: '3413', Name: 'Presenter, radio' },
  { Id: 5530, Code: '3413', Name: 'Presenter, television' },
  { Id: 5531, Code: '3413', Name: 'Puppeteer' },
  { Id: 5532, Code: '3413', Name: 'Reader, news, broadcasting' },
  { Id: 5533, Code: '3413', Name: 'Reporter, broadcasting' },
  { Id: 5534, Code: '3413', Name: 'Rider, ENTERTAINMENT' },
  { Id: 5535, Code: '3413', Name: 'Showman' },
  { Id: 5536, Code: '3413', Name: 'Singer' },
  { Id: 5537, Code: '3413', Name: 'Skater, ice' },
  { Id: 5538, Code: '3413', Name: 'Tamer, ANIMAL' },
  { Id: 5539, Code: '3413', Name: 'Trainer, animal, circus' },
  { Id: 5540, Code: '3413', Name: 'Trainer, animal, PERFORMING ANIMALS' },
  { Id: 5541, Code: '3413', Name: 'Understudy' },
  { Id: 5542, Code: '3413', Name: 'Ventriloquist' },
  { Id: 5543, Code: '3413', Name: 'Vlogger' },
  { Id: 5544, Code: '3413', Name: 'Vocalist' },
  { Id: 5545, Code: '3413', Name: 'YouTuber' },
  { Id: 5546, Code: '3414', Name: 'Artist, dance' },
  { Id: 5547, Code: '3414', Name: 'Ballerina' },
  { Id: 5548, Code: '3414', Name: 'Choreographer' },
  { Id: 5549, Code: '3414', Name: 'Coach, dance' },
  { Id: 5550, Code: '3414', Name: 'Dancer' },
  { Id: 5551, Code: '3414', Name: 'Dancer, ballet' },
  { Id: 5552, Code: '3414', Name: 'Instructor, dance' },
  { Id: 5553, Code: '3414', Name: 'Master, ballet' },
  { Id: 5554, Code: '3414', Name: 'Partner, dancing' },
  { Id: 5555, Code: '3414', Name: 'Teacher, ballet' },
  { Id: 5556, Code: '3414', Name: 'Teacher, dance' },
  { Id: 5557, Code: '3414', Name: 'Teacher, dancing' },
  { Id: 5558, Code: '3415', Name: 'Accompanist' },
  { Id: 5559, Code: '3415', Name: 'Arranger, music' },
  { Id: 5560, Code: '3415', Name: 'Bandmaster' },
  { Id: 5561, Code: '3415', Name: 'Bandsman' },
  { Id: 5562, Code: '3415', Name: 'Bassoonist' },
  { Id: 5563, Code: '3415', Name: 'Campanologist' },
  { Id: 5564, Code: '3415', Name: 'Cellist' },
  { Id: 5565, Code: '3415', Name: 'Clarinettist' },
  { Id: 5566, Code: '3415', Name: 'Composer' },
  { Id: 5567, Code: '3415', Name: 'Conductor, ENTERTAINMENT' },
  { Id: 5568, Code: '3415', Name: 'Conductor, music' },
  { Id: 5569, Code: '3415', Name: 'Conductor, musical' },
  { Id: 5570, Code: '3415', Name: 'Copyist, music' },
  { Id: 5571, Code: '3415', Name: 'Cornetist' },
  { Id: 5572, Code: '3415', Name: 'Director of music, ENTERTAINMENT' },
  { Id: 5573, Code: '3415', Name: 'Director, musical' },
  { Id: 5574, Code: '3415', Name: 'Drummer, ENTERTAINMENT' },
  { Id: 5575, Code: '3415', Name: 'Flautist' },
  { Id: 5576, Code: '3415', Name: 'Guitarist' },
  { Id: 5577, Code: '3415', Name: 'Harpist' },
  { Id: 5578, Code: '3415', Name: 'Instrumentalist' },
  { Id: 5579, Code: '3415', Name: 'Leader, band' },
  { Id: 5580, Code: '3415', Name: 'Leader, orchestra' },
  { Id: 5581, Code: '3415', Name: 'Major, pipe' },
  { Id: 5582, Code: '3415', Name: 'Master, band' },
  { Id: 5583, Code: '3415', Name: 'Musician' },
  { Id: 5584, Code: '3415', Name: 'Oboist' },
  { Id: 5585, Code: '3415', Name: 'Orchestrator' },
  { Id: 5586, Code: '3415', Name: 'Organiser, music' },
  { Id: 5587, Code: '3415', Name: 'Organist' },
  { Id: 5588, Code: '3415', Name: 'Performer' },
  { Id: 5589, Code: '3415', Name: 'Pianist' },
  { Id: 5590, Code: '3415', Name: 'Player, MUSICAL INSTRUMENTS' },
  { Id: 5591, Code: '3415', Name: 'Ringer, bell' },
  { Id: 5592, Code: '3415', Name: 'Transcriber, music' },
  { Id: 5593, Code: '3415', Name: 'Trombonist' },
  { Id: 5594, Code: '3415', Name: 'Tympanist' },
  { Id: 5595, Code: '3415', Name: 'Violinist' },
  { Id: 5596, Code: '3415', Name: 'Violoncellist' },
  { Id: 5597, Code: '3415', Name: 'Writer, music' },
  { Id: 5598, Code: '3415', Name: 'Writer, song' },
  { Id: 5599, Code: '3416', Name: 'Adviser, art' },
  { Id: 5600, Code: '3416', Name: 'Adviser, arts' },
  { Id: 5601, Code: '3416', Name: 'Agent, concert' },
  { Id: 5602, Code: '3416', Name: 'Agent, entertainment' },
  { Id: 5603, Code: '3416', Name: 'Agent, film' },
  { Id: 5604, Code: '3416', Name: 'Agent, model' },
  { Id: 5605, Code: '3416', Name: "Agent, model's" },
  { Id: 5606, Code: '3416', Name: 'Agent, theatrical' },
  { Id: 5607, Code: '3416', Name: 'Agent, variety' },
  { Id: 5608, Code: '3416', Name: 'Arranger, fight' },
  { Id: 5609, Code: '3416', Name: 'Assistant, broadcast, radio' },
  { Id: 5610, Code: '3416', Name: "Assistant, producer's, broadcasting" },
  { Id: 5611, Code: '3416', Name: "Assistant, producer's, ENTERTAINMENT" },
  { Id: 5612, Code: '3416', Name: 'Assistant, production, broadcasting' },
  { Id: 5613, Code: '3416', Name: 'Assistant, production, television' },
  { Id: 5614, Code: '3416', Name: 'Assistant, programme, broadcasting' },
  { Id: 5615, Code: '3416', Name: 'Broadcaster' },
  { Id: 5616, Code: '3416', Name: 'Consultant, art' },
  { Id: 5617, Code: '3416', Name: 'Consultant, creative' },
  { Id: 5618, Code: '3416', Name: 'Consultant, entertainment' },
  { Id: 5619, Code: '3416', Name: 'Coordinator, facilities, editing' },
  { Id: 5620, Code: '3416', Name: 'Coordinator, production, broadcasting' },
  { Id: 5621, Code: '3416', Name: 'Director of production, broadcasting' },
  { Id: 5622, Code: '3416', Name: 'Director of production, ENTERTAINMENT' },
  { Id: 5623, Code: '3416', Name: 'Director of programmes' },
  { Id: 5624, Code: '3416', Name: 'Director, artistic' },
  { Id: 5625, Code: '3416', Name: 'Director, broadcasting' },
  { Id: 5626, Code: '3416', Name: 'Director, casting, broadcasting' },
  { Id: 5627, Code: '3416', Name: 'Director, casting, ENTERTAINMENT' },
  { Id: 5628, Code: '3416', Name: 'Director, film' },
  { Id: 5629, Code: '3416', Name: 'Director, network, broadcasting' },
  { Id: 5630, Code: '3416', Name: 'Director, presentation, broadcasting' },
  { Id: 5631, Code: '3416', Name: 'Director, programme, television' },
  { Id: 5632, Code: '3416', Name: 'Director, stage' },
  { Id: 5633, Code: '3416', Name: 'Director, technical, broadcasting' },
  { Id: 5634, Code: '3416', Name: 'Director, television' },
  { Id: 5635, Code: '3416', Name: 'Director, theatre' },
  { Id: 5636, Code: '3416', Name: 'Director, video' },
  { Id: 5637, Code: '3416', Name: 'Director, visual artist and creative' },
  { Id: 5638, Code: '3416', Name: 'Editor, film' },
  { Id: 5639, Code: '3416', Name: 'Editor, tape, video' },
  { Id: 5640, Code: '3416', Name: 'Editor, video' },
  { Id: 5641, Code: '3416', Name: 'Editor-in-charge, broadcasting' },
  { Id: 5642, Code: '3416', Name: 'Engineer, production, post' },
  { Id: 5643, Code: '3416', Name: 'Filmmaker, documentary' },
  { Id: 5644, Code: '3416', Name: 'Maker, film' },
  { Id: 5645, Code: '3416', Name: 'Manager, artist' },
  { Id: 5646, Code: '3416', Name: "Manager, boxer's" },
  { Id: 5647, Code: '3416', Name: 'Manager, floor, broadcasting' },
  { Id: 5648, Code: '3416', Name: 'Manager, floor, ENTERTAINMENT' },
  { Id: 5649, Code: '3416', Name: 'Manager, location' },
  { Id: 5650, Code: '3416', Name: 'Manager, personal, ENTERTAINMENT' },
  { Id: 5651, Code: '3416', Name: 'Manager, stage, broadcasting' },
  { Id: 5652, Code: '3416', Name: 'Manager, stage, ENTERTAINMENT' },
  { Id: 5653, Code: '3416', Name: 'Manager, studio' },
  { Id: 5654, Code: '3416', Name: 'Manager, studio, broadcasting' },
  { Id: 5655, Code: '3416', Name: 'Manager, studio, ENTERTAINMENT' },
  { Id: 5656, Code: '3416', Name: 'Master, choir' },
  { Id: 5657, Code: '3416', Name: 'Monitor, broadcasting' },
  { Id: 5658, Code: '3416', Name: 'Officer, arts' },
  { Id: 5659, Code: '3416', Name: 'Officer, development, arts' },
  { Id: 5660, Code: '3416', Name: 'Operator, autocue' },
  { Id: 5661, Code: '3416', Name: 'Operator, television, broadcasting' },
  { Id: 5662, Code: '3416', Name: 'Organiser, drama' },
  { Id: 5663, Code: '3416', Name: 'Organiser, programme, broadcasting' },
  { Id: 5664, Code: '3416', Name: 'Producer, animation' },
  { Id: 5665, Code: '3416', Name: 'Producer, broadcasting' },
  { Id: 5666, Code: '3416', Name: 'Producer, content, broadcasting' },
  { Id: 5667, Code: '3416', Name: 'Producer, ENTERTAINMENT' },
  { Id: 5668, Code: '3416', Name: 'Producer, film' },
  { Id: 5669, Code: '3416', Name: 'Producer, multimedia' },
  { Id: 5670, Code: '3416', Name: 'Producer, music' },
  { Id: 5671, Code: '3416', Name: 'Producer, radio' },
  { Id: 5672, Code: '3416', Name: 'Producer, record' },
  { Id: 5673, Code: '3416', Name: 'Producer, television' },
  { Id: 5674, Code: '3416', Name: 'Producer, theatre' },
  { Id: 5675, Code: '3416', Name: 'Producer, video' },
  { Id: 5676, Code: '3416', Name: 'Promoter, club' },
  { Id: 5677, Code: '3416', Name: 'Promoter, ENTERTAINMENT' },
  { Id: 5678, Code: '3416', Name: 'Promoter, sports activities' },
  { Id: 5679, Code: '3416', Name: 'Prompter' },
  { Id: 5680, Code: '3417', Name: 'Artist, photographic' },
  { Id: 5681, Code: '3417', Name: 'Assistant, camera, first' },
  { Id: 5682, Code: '3417', Name: 'Assistant, camera, second' },
  { Id: 5683, Code: '3417', Name: "Assistant, cameraman's" },
  { Id: 5684, Code: '3417', Name: 'Assistant, film' },
  { Id: 5685, Code: '3417', Name: "Assistant, photographer's" },
  { Id: 5686, Code: '3417', Name: 'Assistant, photographic' },
  { Id: 5687, Code: '3417', Name: 'Assistant, sound' },
  { Id: 5688, Code: '3417', Name: 'Assistant, studio' },
  { Id: 5689, Code: '3417', Name: 'Balancer, sound' },
  { Id: 5690, Code: '3417', Name: 'Cameraman' },
  { Id: 5691, Code: '3417', Name: 'Cameraman, chief, FILMS' },
  { Id: 5692, Code: '3417', Name: 'Cinematographer' },
  { Id: 5693, Code: '3417', Name: 'Controller, sound' },
  { Id: 5694, Code: '3417', Name: 'Designer, lighting' },
  { Id: 5695, Code: '3417', Name: 'Designer, sound' },
  { Id: 5696, Code: '3417', Name: 'Director of photography' },
  { Id: 5697, Code: '3417', Name: 'Director, lighting, technical' },
  { Id: 5698, Code: '3417', Name: 'Director, lighting, theatre' },
  { Id: 5699, Code: '3417', Name: 'Editor, camera, video' },
  { Id: 5700, Code: '3417', Name: 'Editor, dubbing' },
  { Id: 5701, Code: '3417', Name: 'Editor, sound' },
  { Id: 5702, Code: '3417', Name: 'Engineer, audio, RECORDING' },
  { Id: 5703, Code: '3417', Name: 'Engineer, camera, video' },
  { Id: 5704, Code: '3417', Name: 'Engineer, control, vision' },
  { Id: 5705, Code: '3417', Name: 'Engineer, recording' },
  { Id: 5706, Code: '3417', Name: 'Engineer, sound' },
  { Id: 5707, Code: '3417', Name: 'Engineer, studio, MUSIC' },
  { Id: 5708, Code: '3417', Name: 'Engineer, vision, broadcasting' },
  { Id: 5709, Code: '3417', Name: 'Manager, audio-visual' },
  {
    Id: 5710,
    Code: '3417',
    Name: 'Manager, lighting, film, television production'
  },
  { Id: 5711, Code: '3417', Name: 'Manager, lighting, television service' },
  { Id: 5712, Code: '3417', Name: 'Manager, sound, theatre' },
  { Id: 5713, Code: '3417', Name: 'Manager, technical, LIGHTING' },
  { Id: 5714, Code: '3417', Name: 'Manager, visual, audio' },
  { Id: 5715, Code: '3417', Name: 'Mixer, dubbing' },
  { Id: 5716, Code: '3417', Name: 'Mixer, film, television production' },
  { Id: 5717, Code: '3417', Name: 'Mixer, sound' },
  { Id: 5718, Code: '3417', Name: 'Mixer, vision, television service' },
  { Id: 5719, Code: '3417', Name: 'Officer, aids, visual' },
  { Id: 5720, Code: '3417', Name: 'Officer, resources, media' },
  { Id: 5721, Code: '3417', Name: 'Operator, aids, audio-visual' },
  {
    Id: 5722,
    Code: '3417',
    Name: 'Operator, boom, film, television production'
  },
  {
    Id: 5723,
    Code: '3417',
    Name: 'Operator, camera, film, television production'
  },
  { Id: 5724, Code: '3417', Name: 'Operator, camera, printing' },
  { Id: 5725, Code: '3417', Name: 'Operator, camera, process engraving' },
  { Id: 5726, Code: '3417', Name: 'Operator, camera, television service' },
  { Id: 5727, Code: '3417', Name: 'Operator, camera, video' },
  { Id: 5728, Code: '3417', Name: 'Operator, cinema' },
  { Id: 5729, Code: '3417', Name: 'Operator, cinematograph' },
  { Id: 5730, Code: '3417', Name: 'Operator, control, sound' },
  { Id: 5731, Code: '3417', Name: 'Operator, equipment, video' },
  { Id: 5732, Code: '3417', Name: 'Operator, limelight' },
  { Id: 5733, Code: '3417', Name: 'Operator, photo, lithography' },
  { Id: 5734, Code: '3417', Name: 'Operator, photographic' },
  { Id: 5735, Code: '3417', Name: 'Operator, photo-litho' },
  { Id: 5736, Code: '3417', Name: 'Operator, recorder, film' },
  { Id: 5737, Code: '3417', Name: 'Operator, recorder, videotape' },
  { Id: 5738, Code: '3417', Name: 'Operator, studio, photogravure' },
  { Id: 5739, Code: '3417', Name: 'Operator, tape' },
  { Id: 5740, Code: '3417', Name: 'Operator, technical, broadcasting' },
  { Id: 5741, Code: '3417', Name: 'Operator, telecine' },
  { Id: 5742, Code: '3417', Name: 'Operator, video' },
  { Id: 5743, Code: '3417', Name: 'Operator, VT' },
  { Id: 5744, Code: '3417', Name: 'Photographer' },
  { Id: 5745, Code: '3417', Name: 'Pilot, drone, commercial' },
  { Id: 5746, Code: '3417', Name: 'Projectionist' },
  { Id: 5747, Code: '3417', Name: 'Puller, focus' },
  { Id: 5748, Code: '3417', Name: 'Recorder, sound recording' },
  { Id: 5749, Code: '3417', Name: 'Recordist, sound' },
  { Id: 5750, Code: '3417', Name: 'Rigger, ANIMATION' },
  { Id: 5751, Code: '3417', Name: 'Soundman' },
  { Id: 5752, Code: '3417', Name: 'Supervisor, vision, broadcasting' },
  { Id: 5753, Code: '3417', Name: 'Technician, aids, visual' },
  { Id: 5754, Code: '3417', Name: 'Technician, backline' },
  { Id: 5755, Code: '3417', Name: 'Technician, camera, video' },
  { Id: 5756, Code: '3417', Name: 'Technician, cine' },
  { Id: 5757, Code: '3417', Name: 'Technician, film' },
  { Id: 5758, Code: '3417', Name: 'Technician, film studio' },
  { Id: 5759, Code: '3417', Name: 'Technician, imaging, digital' },
  { Id: 5760, Code: '3417', Name: 'Technician, media' },
  { Id: 5761, Code: '3417', Name: 'Technician, photographic' },
  { Id: 5762, Code: '3417', Name: 'Technician, sound' },
  { Id: 5763, Code: '3417', Name: 'Technician, stage, theatre' },
  { Id: 5764, Code: '3417', Name: 'Technician, theatre, ENTERTAINMENT' },
  { Id: 5765, Code: '3417', Name: 'Technician, visual, audio' },
  { Id: 5766, Code: '3417', Name: 'Videographer' },
  { Id: 5767, Code: '3417', Name: 'Writer, subtitle' },
  { Id: 5768, Code: '3421', Name: 'Animator, 2D' },
  { Id: 5769, Code: '3421', Name: 'Animator, 2D, computer' },
  { Id: 5770, Code: '3421', Name: 'Animator, 3D' },
  { Id: 5771, Code: '3421', Name: 'Animator, 3D, computer' },
  { Id: 5772, Code: '3421', Name: 'Animator, computer' },
  { Id: 5773, Code: '3421', Name: 'Animator, effects, visual' },
  { Id: 5774, Code: '3421', Name: 'Animator, motion, stop' },
  { Id: 5775, Code: '3421', Name: 'Animator, VFX' },
  { Id: 5776, Code: '3421', Name: 'Artist, 3D' },
  { Id: 5777, Code: '3421', Name: 'Artist, commercial' },
  { Id: 5778, Code: '3421', Name: 'Artist, computer' },
  { Id: 5779, Code: '3421', Name: 'Artist, digital' },
  { Id: 5780, Code: '3421', Name: 'Artist, effects, digital' },
  { Id: 5781, Code: '3421', Name: 'Artist, effects, visual' },
  { Id: 5782, Code: '3421', Name: 'Artist, environment' },
  { Id: 5783, Code: '3421', Name: 'Artist, games, computer' },
  { Id: 5784, Code: '3421', Name: 'Artist, graphic' },
  { Id: 5785, Code: '3421', Name: 'Artist, layout' },
  { Id: 5786, Code: '3421', Name: 'Artist, lettering' },
  { Id: 5787, Code: '3421', Name: 'Artist, litho' },
  { Id: 5788, Code: '3421', Name: 'Artist, lithographic' },
  { Id: 5789, Code: '3421', Name: 'Artist, Mac' },
  { Id: 5790, Code: '3421', Name: 'Artist, stereo' },
  { Id: 5791, Code: '3421', Name: 'Artist, technical' },
  { Id: 5792, Code: '3421', Name: 'Artist, texture' },
  { Id: 5793, Code: '3421', Name: 'Artist, VFX' },
  { Id: 5794, Code: '3421', Name: 'Artworker' },
  { Id: 5795, Code: '3421', Name: 'Artworker, digital' },
  { Id: 5796, Code: '3421', Name: 'Artworker, senior' },
  { Id: 5797, Code: '3421', Name: 'Assistant, graphics' },
  {
    Id: 5798,
    Code: '3421',
    Name: 'Assistant, publications, DESK TOP PUBLISHING'
  },
  {
    Id: 5799,
    Code: '3421',
    Name: 'Assistant, publishing, DESK TOP PUBLISHING'
  },
  { Id: 5800, Code: '3421', Name: 'Consultant, design, graphic' },
  { Id: 5801, Code: '3421', Name: 'Controller, display' },
  { Id: 5802, Code: '3421', Name: 'Coordinator, design, graphic' },
  { Id: 5803, Code: '3421', Name: 'Copier, design' },
  { Id: 5804, Code: '3421', Name: 'Copyist, design' },
  { Id: 5805, Code: '3421', Name: "Copyist, designer's" },
  { Id: 5806, Code: '3421', Name: 'Copyist, textile printing' },
  { Id: 5807, Code: '3421', Name: 'Decorator, display' },
  { Id: 5808, Code: '3421', Name: 'Designer, 3D' },
  { Id: 5809, Code: '3421', Name: 'Designer, advert' },
  { Id: 5810, Code: '3421', Name: 'Designer, advertising' },
  { Id: 5811, Code: '3421', Name: 'Designer, art' },
  { Id: 5812, Code: '3421', Name: 'Designer, digital' },
  { Id: 5813, Code: '3421', Name: 'Designer, display' },
  { Id: 5814, Code: '3421', Name: 'Designer, exhibition' },
  { Id: 5815, Code: '3421', Name: 'Designer, graphic' },
  { Id: 5816, Code: '3421', Name: 'Designer, graphic and web' },
  { Id: 5817, Code: '3421', Name: 'Designer, graphics, motion' },
  { Id: 5818, Code: '3421', Name: 'Designer, infographic' },
  { Id: 5819, Code: '3421', Name: 'Designer, multi-media' },
  { Id: 5820, Code: '3421', Name: 'Designer, visualisation, data' },
  { Id: 5821, Code: '3421', Name: 'Illuminator' },
  { Id: 5822, Code: '3421', Name: 'Illustrator, technical' },
  { Id: 5823, Code: '3421', Name: 'Modeller, environment' },
  { Id: 5824, Code: '3421', Name: 'Officer, graphics' },
  { Id: 5825, Code: '3421', Name: 'Officer, services, creative' },
  { Id: 5826, Code: '3421', Name: 'Operator, DTP' },
  { Id: 5827, Code: '3421', Name: 'Operator, Mac' },
  { Id: 5828, Code: '3421', Name: 'Operator, Mac, Apple' },
  { Id: 5829, Code: '3421', Name: 'Operator, publishing, top, desk' },
  { Id: 5830, Code: '3421', Name: 'Setter-out, TECHNICAL DRAWINGS' },
  { Id: 5831, Code: '3421', Name: 'Supervisor, effects, visual' },
  { Id: 5832, Code: '3421', Name: 'Supervisor, VFX' },
  { Id: 5833, Code: '3421', Name: 'Technician, graphics' },
  { Id: 5834, Code: '3421', Name: 'Visualiser, 3D' },
  { Id: 5835, Code: '3421', Name: 'Visualiser, advertising' },
  { Id: 5836, Code: '3422', Name: 'Adviser, fashion' },
  { Id: 5837, Code: '3422', Name: 'Artist, boot and shoe' },
  { Id: 5838, Code: '3422', Name: 'Artist, shoe' },
  { Id: 5839, Code: '3422', Name: 'Consultant, bridal' },
  { Id: 5840, Code: '3422', Name: 'Consultant, design' },
  { Id: 5841, Code: '3422', Name: 'Consultant, design, interior' },
  { Id: 5842, Code: '3422', Name: 'Consultant, fashion' },
  { Id: 5843, Code: '3422', Name: 'Consultant, packaging' },
  { Id: 5844, Code: '3422', Name: 'Couturier' },
  { Id: 5845, Code: '3422', Name: 'Decorator, interior' },
  { Id: 5846, Code: '3422', Name: 'Designer, apparel' },
  { Id: 5847, Code: '3422', Name: 'Designer, applications' },
  { Id: 5848, Code: '3422', Name: 'Designer, bathroom' },
  { Id: 5849, Code: '3422', Name: 'Designer, body, vehicle mfr' },
  { Id: 5850, Code: '3422', Name: 'Designer, book' },
  { Id: 5851, Code: '3422', Name: 'Designer, bridalwear' },
  { Id: 5852, Code: '3422', Name: 'Designer, broadcasting' },
  { Id: 5853, Code: '3422', Name: 'Designer, ceramic' },
  { Id: 5854, Code: '3422', Name: 'Designer, CERAMICS' },
  { Id: 5855, Code: '3422', Name: 'Designer, cloth' },
  { Id: 5856, Code: '3422', Name: 'Designer, clothes' },
  { Id: 5857, Code: '3422', Name: 'Designer, clothing' },
  { Id: 5858, Code: '3422', Name: 'Designer, commercial' },
  { Id: 5859, Code: '3422', Name: 'Designer, costume' },
  { Id: 5860, Code: '3422', Name: 'Designer, craft' },
  { Id: 5861, Code: '3422', Name: 'Designer, decor' },
  { Id: 5862, Code: '3422', Name: 'Designer, dress' },
  { Id: 5863, Code: '3422', Name: 'Designer, embroidery' },
  { Id: 5864, Code: '3422', Name: 'Designer, fashion' },
  { Id: 5865, Code: '3422', Name: 'Designer, footwear' },
  { Id: 5866, Code: '3422', Name: 'Designer, fur goods mfr' },
  { Id: 5867, Code: '3422', Name: 'Designer, furnishing, soft' },
  { Id: 5868, Code: '3422', Name: 'Designer, furniture' },
  { Id: 5869, Code: '3422', Name: 'Designer, games' },
  { Id: 5870, Code: '3422', Name: 'Designer, gem' },
  { Id: 5871, Code: '3422', Name: 'Designer, glass' },
  { Id: 5872, Code: '3422', Name: 'Designer, GLASSWARE' },
  { Id: 5873, Code: '3422', Name: 'Designer, handbag' },
  { Id: 5874, Code: '3422', Name: 'Designer, hat' },
  { Id: 5875, Code: '3422', Name: 'Designer, industrial' },
  { Id: 5876, Code: '3422', Name: 'Designer, instrument' },
  { Id: 5877, Code: '3422', Name: 'Designer, instrumentation' },
  { Id: 5878, Code: '3422', Name: 'Designer, interior' },
  { Id: 5879, Code: '3422', Name: 'Designer, interior and spatial' },
  { Id: 5880, Code: '3422', Name: 'Designer, interior design' },
  { Id: 5881, Code: '3422', Name: 'Designer, jewellery' },
  { Id: 5882, Code: '3422', Name: 'Designer, kitchen' },
  { Id: 5883, Code: '3422', Name: 'Designer, knitwear' },
  { Id: 5884, Code: '3422', Name: 'Designer, LEATHER GOODS' },
  { Id: 5885, Code: '3422', Name: 'Designer, lithographic' },
  { Id: 5886, Code: '3422', Name: 'Designer, metalwork' },
  { Id: 5887, Code: '3422', Name: 'Designer, millinery mfr' },
  { Id: 5888, Code: '3422', Name: 'Designer, MOTOR VEHICLES' },
  { Id: 5889, Code: '3422', Name: 'Designer, packaging' },
  { Id: 5890, Code: '3422', Name: 'Designer, pattern, textile printing' },
  { Id: 5891, Code: '3422', Name: 'Designer, PLASTICS GOODS' },
  { Id: 5892, Code: '3422', Name: 'Designer, pottery' },
  { Id: 5893, Code: '3422', Name: "Designer, printer's" },
  { Id: 5894, Code: '3422', Name: 'Designer, product' },
  { Id: 5895, Code: '3422', Name: 'Designer, publishing' },
  { Id: 5896, Code: '3422', Name: 'Designer, rubber goods mfr' },
  { Id: 5897, Code: '3422', Name: 'Designer, SCENERY' },
  { Id: 5898, Code: '3422', Name: 'Designer, set' },
  { Id: 5899, Code: '3422', Name: 'Designer, shopfitting' },
  { Id: 5900, Code: '3422', Name: 'Designer, silversmith' },
  { Id: 5901, Code: '3422', Name: 'Designer, soft furnishings mfr' },
  { Id: 5902, Code: '3422', Name: 'Designer, soft toy mfr' },
  { Id: 5903, Code: '3422', Name: 'Designer, stage' },
  { Id: 5904, Code: '3422', Name: 'Designer, textile' },
  { Id: 5905, Code: '3422', Name: 'Designer, textile mfr' },
  { Id: 5906, Code: '3422', Name: 'Designer, toy' },
  { Id: 5907, Code: '3422', Name: 'Designer, wallpaper' },
  { Id: 5908, Code: '3422', Name: 'Designer, WOOD PRODUCTS' },
  { Id: 5909, Code: '3422', Name: 'Developer, product, DESIGN' },
  { Id: 5910, Code: '3422', Name: 'Dresser, set' },
  { Id: 5911, Code: '3422', Name: 'Leader, team, interior design' },
  { Id: 5912, Code: '3422', Name: 'Manager, display, visual' },
  { Id: 5913, Code: '3422', Name: 'Planner, footwear' },
  { Id: 5914, Code: '3422', Name: 'Planner, kitchen' },
  { Id: 5915, Code: '3422', Name: 'Predictor, fashion' },
  { Id: 5916, Code: '3422', Name: 'Stylist, colour, vehicle mfr' },
  { Id: 5917, Code: '3422', Name: 'Stylist, interior' },
  { Id: 5918, Code: '3422', Name: 'Stylist, vehicle mfr' },
  { Id: 5919, Code: '3422', Name: 'Technologist, packaging' },
  { Id: 5920, Code: '3441', Name: 'Athlete' },
  { Id: 5921, Code: '3441', Name: 'Boxer, SPORTS' },
  { Id: 5922, Code: '3441', Name: 'Cricketer' },
  { Id: 5923, Code: '3441', Name: 'Cyclist' },
  { Id: 5924, Code: '3441', Name: 'Driver, racing' },
  { Id: 5925, Code: '3441', Name: 'Equestrian' },
  { Id: 5926, Code: '3441', Name: 'Equestrienne' },
  { Id: 5927, Code: '3441', Name: 'Footballer' },
  { Id: 5928, Code: '3441', Name: 'Freerunner' },
  { Id: 5929, Code: '3441', Name: 'Golfer' },
  { Id: 5930, Code: '3441', Name: 'Gymnast' },
  { Id: 5931, Code: '3441', Name: 'Jockey' },
  { Id: 5932, Code: '3441', Name: 'Jumper, show' },
  { Id: 5933, Code: '3441', Name: 'Player, SPORTS' },
  { Id: 5934, Code: '3441', Name: 'Professional, SPORTS' },
  { Id: 5935, Code: '3441', Name: 'Rider, event' },
  { Id: 5936, Code: '3441', Name: 'Rider, scramble' },
  { Id: 5937, Code: '3441', Name: 'Rider, speedway' },
  { Id: 5938, Code: '3441', Name: 'Sportsman, professional' },
  { Id: 5939, Code: '3441', Name: 'Wrestler' },
  { Id: 5940, Code: '3442', Name: 'Agent, sports' },
  { Id: 5941, Code: '3442', Name: 'Coach, shooting' },
  { Id: 5942, Code: '3442', Name: 'Coach, sports' },
  { Id: 5943, Code: '3442', Name: 'Coordinator, sports' },
  { Id: 5944, Code: '3442', Name: 'Handicapper' },
  { Id: 5945, Code: '3442', Name: 'Instructor, activities, outdoor' },
  { Id: 5946, Code: '3442', Name: 'Instructor, arts, martial' },
  { Id: 5947, Code: '3442', Name: 'Instructor, driving, carriage' },
  { Id: 5948, Code: '3442', Name: 'Instructor, education, outdoor' },
  { Id: 5949, Code: '3442', Name: 'Instructor, gliding' },
  { Id: 5950, Code: '3442', Name: 'Instructor, outdoor' },
  { Id: 5951, Code: '3442', Name: 'Instructor, outdoor pursuits' },
  { Id: 5952, Code: '3442', Name: 'Instructor, pursuits, outdoor' },
  { Id: 5953, Code: '3442', Name: 'Instructor, riding' },
  { Id: 5954, Code: '3442', Name: 'Instructor, sailing' },
  { Id: 5955, Code: '3442', Name: 'Instructor, scuba' },
  { Id: 5956, Code: '3442', Name: 'Instructor, shooting' },
  { Id: 5957, Code: '3442', Name: 'Instructor, SPORTS' },
  { Id: 5958, Code: '3442', Name: 'Instructor, swimming' },
  { Id: 5959, Code: '3442', Name: 'Judge, SPORTS' },
  { Id: 5960, Code: '3442', Name: 'Leader, pursuits, outdoor' },
  { Id: 5961, Code: '3442', Name: 'Manager, SPORTS' },
  { Id: 5962, Code: '3442', Name: 'Manager, team, SPORTS' },
  { Id: 5963, Code: '3442', Name: 'Master, riding' },
  { Id: 5964, Code: '3442', Name: 'Officer, development, sport' },
  { Id: 5965, Code: '3442', Name: 'Officer, development, sports' },
  { Id: 5966, Code: '3442', Name: 'Officer, leisure' },
  { Id: 5967, Code: '3442', Name: 'Officer, recreation' },
  { Id: 5968, Code: '3442', Name: 'Official, racecourse' },
  { Id: 5969, Code: '3442', Name: 'Official, sports' },
  { Id: 5970, Code: '3442', Name: 'Pilot, balloon' },
  { Id: 5971, Code: '3442', Name: 'Referee' },
  { Id: 5972, Code: '3442', Name: 'Scorer, cricket' },
  { Id: 5973, Code: '3442', Name: 'Scout, football' },
  { Id: 5974, Code: '3442', Name: 'Signalman, yacht club' },
  { Id: 5975, Code: '3442', Name: 'Steward, HORSE RACING' },
  { Id: 5976, Code: '3442', Name: 'Steward, race course' },
  { Id: 5977, Code: '3442', Name: 'Teacher, outdoor recreational activities' },
  { Id: 5978, Code: '3442', Name: 'Teacher, riding' },
  { Id: 5979, Code: '3442', Name: 'Teacher, swimming' },
  { Id: 5980, Code: '3442', Name: 'Trainer, development, outdoors' },
  { Id: 5981, Code: '3442', Name: 'Trainer, SPORTS' },
  { Id: 5982, Code: '3442', Name: 'Tutor, bound, outward' },
  { Id: 5983, Code: '3442', Name: 'Umpire, SPORTS' },
  { Id: 5984, Code: '3442', Name: 'Worker, education, outdoor' },
  { Id: 5985, Code: '3443', Name: 'Biokineticist' },
  { Id: 5986, Code: '3443', Name: 'Coach, fitness' },
  { Id: 5987, Code: '3443', Name: 'Consultant, fitness' },
  { Id: 5988, Code: '3443', Name: 'Consultant, lifestyle' },
  { Id: 5989, Code: '3443', Name: 'Instructor, aerobics' },
  { Id: 5990, Code: '3443', Name: 'Instructor, chi, tai' },
  { Id: 5991, Code: '3443', Name: 'Instructor, fit, keep' },
  { Id: 5992, Code: '3443', Name: 'Instructor, fitness' },
  { Id: 5993, Code: '3443', Name: 'Instructor, gym' },
  { Id: 5994, Code: '3443', Name: 'Instructor, health and fitness' },
  { Id: 5995, Code: '3443', Name: 'Instructor, PHYSICAL TRAINING' },
  { Id: 5996, Code: '3443', Name: 'Instructor, pilates' },
  { Id: 5997, Code: '3443', Name: 'Instructor, training, physical' },
  { Id: 5998, Code: '3443', Name: 'Instructor, zumba' },
  { Id: 5999, Code: '3443', Name: 'Leader, fit, keep' },
  { Id: 6000, Code: '3443', Name: 'Supervisor, health and fitness' },
  { Id: 6001, Code: '3443', Name: 'Teacher, aerobics' },
  { Id: 6002, Code: '3443', Name: 'Teacher, fit, keep' },
  { Id: 6003, Code: '3443', Name: 'Teacher, pilates' },
  { Id: 6004, Code: '3443', Name: 'Teacher, yoga' },
  { Id: 6005, Code: '3443', Name: 'Therapist, yoga' },
  { Id: 6006, Code: '3443', Name: 'Trainer, fitness' },
  { Id: 6007, Code: '3443', Name: 'Trainer, personal' },
  { Id: 6008, Code: '3443', Name: 'Tutor, yoga' },
  { Id: 6009, Code: '3511', Name: 'Assistant, service, traffic, air' },
  { Id: 6010, Code: '3511', Name: 'Assistant, services, traffic, air' },
  { Id: 6011, Code: '3511', Name: 'Controller of aircraft' },
  { Id: 6012, Code: '3511', Name: 'Controller, movement, ground, airport' },
  { Id: 6013, Code: '3511', Name: 'Controller, radar, area' },
  { Id: 6014, Code: '3511', Name: 'Controller, traffic, air' },
  { Id: 6015, Code: '3511', Name: 'Officer, briefing, CIVIL AVIATION' },
  { Id: 6016, Code: '3511', Name: 'Officer, control, space, airport' },
  { Id: 6017, Code: '3511', Name: 'Officer, control, traffic, air' },
  { Id: 6018, Code: '3511', Name: 'Planner, flight' },
  { Id: 6019, Code: '3511', Name: 'Planner, traffic, air' },
  { Id: 6020, Code: '3512', Name: 'Aviator' },
  { Id: 6021, Code: '3512', Name: 'Captain, airline' },
  { Id: 6022, Code: '3512', Name: 'Captain, airlines' },
  { Id: 6023, Code: '3512', Name: 'Engineer, flight' },
  { Id: 6024, Code: '3512', Name: 'Engineer, nos, airlines' },
  { Id: 6025, Code: '3512', Name: 'Instructor, flying' },
  { Id: 6026, Code: '3512', Name: 'Instructor, flying school' },
  { Id: 6027, Code: '3512', Name: 'Instructor, simulation, flight' },
  { Id: 6028, Code: '3512', Name: 'Navigator, AIRCRAFT' },
  { Id: 6029, Code: '3512', Name: 'Officer, airlines' },
  { Id: 6030, Code: '3512', Name: 'Officer, deck, flight, aircraft' },
  { Id: 6031, Code: '3512', Name: 'Officer, engineer, airlines' },
  { Id: 6032, Code: '3512', Name: 'Officer, first, airlines' },
  { Id: 6033, Code: '3512', Name: 'Officer, navigating, airlines' },
  { Id: 6034, Code: '3512', Name: 'Officer, second, airlines' },
  { Id: 6035, Code: '3512', Name: 'Pilot, aeroplane' },
  { Id: 6036, Code: '3512', Name: 'Pilot, AIRCRAFT' },
  { Id: 6037, Code: '3512', Name: 'Pilot, airline' },
  { Id: 6038, Code: '3512', Name: 'Pilot, airlines' },
  { Id: 6039, Code: '3512', Name: 'Pilot, armed forces' },
  { Id: 6040, Code: '3512', Name: 'Pilot, commercial' },
  { Id: 6041, Code: '3512', Name: 'Pilot, helicopter' },
  { Id: 6042, Code: '3512', Name: 'Pilot, test' },
  { Id: 6043, Code: '3512', Name: 'Trainer, flight' },
  { Id: 6044, Code: '3513', Name: 'Cadet, shipping' },
  { Id: 6045, Code: '3513', Name: 'Captain, barge' },
  { Id: 6046, Code: '3513', Name: 'Captain, BOAT, BARGE' },
  { Id: 6047, Code: '3513', Name: 'Captain, dredger' },
  { Id: 6048, Code: '3513', Name: 'Captain, ferry' },
  { Id: 6049, Code: '3513', Name: 'Captain, HOVERCRAFT' },
  { Id: 6050, Code: '3513', Name: 'Captain, lighter' },
  { Id: 6051, Code: '3513', Name: 'Captain, shipping' },
  { Id: 6052, Code: '3513', Name: "Captain, ship's" },
  { Id: 6053, Code: '3513', Name: 'Captain, tug, shipping' },
  {
    Id: 6054,
    Code: '3513',
    Name: 'Commander, CATAMARAN, HOVERCRAFT, HYDROFOIL'
  },
  { Id: 6055, Code: '3513', Name: 'Commander, shipping' },
  { Id: 6056, Code: '3513', Name: 'Commodore, shipping' },
  { Id: 6057, Code: '3513', Name: 'Coxswain' },
  { Id: 6058, Code: '3513', Name: 'Engineer, assistant, shipping' },
  {
    Id: 6059,
    Code: '3513',
    Name: 'Engineer, chief, CATAMARAN, HOVERCRAFT, HYDROFOIL'
  },
  { Id: 6060, Code: '3513', Name: 'Engineer, chief, shipping' },
  { Id: 6061, Code: '3513', Name: 'Engineer, electrical, nos, shipping' },
  {
    Id: 6062,
    Code: '3513',
    Name: 'Engineer, electrical, nos, telecommunications: cable ship'
  },
  { Id: 6063, Code: '3513', Name: 'Engineer, first' },
  { Id: 6064, Code: '3513', Name: 'Engineer, flight, HOVERCRAFT' },
  { Id: 6065, Code: '3513', Name: 'Engineer, hydraulic, shipping' },
  { Id: 6066, Code: '3513', Name: 'Engineer, marine, chief, shipping' },
  { Id: 6067, Code: '3513', Name: 'Engineer, marine, shipping' },
  { Id: 6068, Code: '3513', Name: 'Engineer, nos, shipping' },
  { Id: 6069, Code: '3513', Name: 'Engineer, refrigerating, shipping' },
  { Id: 6070, Code: '3513', Name: 'Engineer, refrigeration, shipping' },
  { Id: 6071, Code: '3513', Name: 'Engineer, sea-going' },
  { Id: 6072, Code: '3513', Name: 'Engineer, second' },
  { Id: 6073, Code: '3513', Name: "Engineer, ship's" },
  { Id: 6074, Code: '3513', Name: "Engineer, ship's, fishing" },
  { Id: 6075, Code: '3513', Name: 'Engineer, third' },
  { Id: 6076, Code: '3513', Name: 'Engineer, tug' },
  { Id: 6077, Code: '3513', Name: 'Mariner, master' },
  { Id: 6078, Code: '3513', Name: 'Master of lightship' },
  { Id: 6079, Code: '3513', Name: 'Master, barge' },
  { Id: 6080, Code: '3513', Name: 'Master, ship' },
  { Id: 6081, Code: '3513', Name: 'Master, shipping' },
  { Id: 6082, Code: '3513', Name: 'Master, ships' },
  { Id: 6083, Code: '3513', Name: 'Master, Trinity House' },
  { Id: 6084, Code: '3513', Name: 'Master, tug' },
  { Id: 6085, Code: '3513', Name: 'Master, yacht' },
  { Id: 6086, Code: '3513', Name: 'Mate, chief' },
  { Id: 6087, Code: '3513', Name: 'Mate, first' },
  { Id: 6088, Code: '3513', Name: 'Mate, fourth' },
  { Id: 6089, Code: '3513', Name: 'Mate, second' },
  { Id: 6090, Code: '3513', Name: 'Mate, shipping' },
  { Id: 6091, Code: '3513', Name: 'Mate, third' },
  { Id: 6092, Code: '3513', Name: 'Mate, tug' },
  { Id: 6093, Code: '3513', Name: 'Midshipman, shipping' },
  { Id: 6094, Code: '3513', Name: 'Navigator, HOVERCRAFT' },
  { Id: 6095, Code: '3513', Name: 'Navigator, SHIP' },
  { Id: 6096, Code: '3513', Name: 'Officer, chief, shipping' },
  { Id: 6097, Code: '3513', Name: 'Officer, deck' },
  { Id: 6098, Code: '3513', Name: 'Officer, deck, Navy, Merchant' },
  { Id: 6099, Code: '3513', Name: 'Officer, electrical, shipping' },
  { Id: 6100, Code: '3513', Name: 'Officer, electronics, shipping' },
  { Id: 6101, Code: '3513', Name: 'Officer, engineer, HOVERCRAFT' },
  { Id: 6102, Code: '3513', Name: 'Officer, engineer, shipping' },
  { Id: 6103, Code: '3513', Name: 'Officer, engineering, shipping' },
  { Id: 6104, Code: '3513', Name: 'Officer, first, HOVERCRAFT' },
  { Id: 6105, Code: '3513', Name: 'Officer, first, shipping' },
  { Id: 6106, Code: '3513', Name: 'Officer, House, Trinity' },
  { Id: 6107, Code: '3513', Name: 'Officer, HOVERCRAFT' },
  { Id: 6108, Code: '3513', Name: 'Officer, navigating, shipping' },
  { Id: 6109, Code: '3513', Name: 'Officer, navy, merchant' },
  { Id: 6110, Code: '3513', Name: 'Officer, petty' },
  { Id: 6111, Code: '3513', Name: 'Officer, radio' },
  { Id: 6112, Code: '3513', Name: 'Officer, refrigeration, shipping' },
  { Id: 6113, Code: '3513', Name: 'Officer, relieving, shipping' },
  { Id: 6114, Code: '3513', Name: 'Officer, salvage, marine' },
  { Id: 6115, Code: '3513', Name: 'Officer, second, HOVERCRAFT' },
  { Id: 6116, Code: '3513', Name: 'Officer, second, shipping' },
  { Id: 6117, Code: '3513', Name: 'Officer, shipping' },
  { Id: 6118, Code: '3513', Name: "Officer, ship's" },
  { Id: 6119, Code: '3513', Name: 'Officer, third, shipping' },
  { Id: 6120, Code: '3513', Name: 'Operator, positioning, dynamic' },
  { Id: 6121, Code: '3513', Name: 'Pilot, canal' },
  { Id: 6122, Code: '3513', Name: 'Pilot, dock' },
  { Id: 6123, Code: '3513', Name: 'Pilot, harbour' },
  { Id: 6124, Code: '3513', Name: 'Pilot, hovercraft' },
  { Id: 6125, Code: '3513', Name: 'Pilot, marine' },
  { Id: 6126, Code: '3513', Name: 'Pilot, river' },
  { Id: 6127, Code: '3513', Name: 'Pilot, shipping' },
  { Id: 6128, Code: '3513', Name: 'Purser, shipping' },
  { Id: 6129, Code: '3513', Name: "Purser, ship's" },
  { Id: 6130, Code: '3513', Name: 'Skipper, BOAT, BARGE' },
  { Id: 6131, Code: '3513', Name: 'Skipper, rig, oil' },
  { Id: 6132, Code: '3513', Name: 'Skipper, shipping' },
  { Id: 6133, Code: '3513', Name: 'Skipper, tug, shipping' },
  { Id: 6134, Code: '3513', Name: 'Skipper, yacht' },
  { Id: 6135, Code: '3513', Name: 'Superintendent of pilots' },
  { Id: 6136, Code: '3513', Name: 'Superintendent, pilot' },
  { Id: 6137, Code: '3520', Name: 'Adjudicator, Home Office' },
  { Id: 6138, Code: '3520', Name: 'Agent, precognition' },
  { Id: 6139, Code: '3520', Name: 'Assistant, legal' },
  { Id: 6140, Code: '3520', Name: 'Caseworker, legal' },
  { Id: 6141, Code: '3520', Name: "Clerk, advocates'" },
  { Id: 6142, Code: '3520', Name: "Clerk, barrister's" },
  { Id: 6143, Code: '3520', Name: "Clerk, judge's" },
  { Id: 6144, Code: '3520', Name: 'Clerk, managing' },
  { Id: 6145, Code: '3520', Name: 'Consultant, cost, law' },
  { Id: 6146, Code: '3520', Name: 'Consultant, cost, legal' },
  { Id: 6147, Code: '3520', Name: 'Consultant, costs, law' },
  { Id: 6148, Code: '3520', Name: 'Consultant, costs, legal' },
  { Id: 6149, Code: '3520', Name: 'Conveyancer' },
  { Id: 6150, Code: '3520', Name: 'Draftsman, cost' },
  { Id: 6151, Code: '3520', Name: 'Draftsman, cost, law' },
  { Id: 6152, Code: '3520', Name: 'Draftsman, costs' },
  { Id: 6153, Code: '3520', Name: 'Draftsman, costs, law' },
  { Id: 6154, Code: '3520', Name: 'Draftsman, costs, legal' },
  { Id: 6155, Code: '3520', Name: 'Draughtsman, costs, law' },
  { Id: 6156, Code: '3520', Name: 'Earner, fee, legal services' },
  { Id: 6157, Code: '3520', Name: 'Examiner, legal services' },
  { Id: 6158, Code: '3520', Name: 'Executive, conveyancing' },
  { Id: 6159, Code: '3520', Name: 'Executive, legal' },
  { Id: 6160, Code: '3520', Name: 'Executive, litigation' },
  { Id: 6161, Code: '3520', Name: 'Lawyer, costs' },
  { Id: 6162, Code: '3520', Name: 'Litigator' },
  { Id: 6163, Code: '3520', Name: 'Manager, conveyancing' },
  { Id: 6164, Code: '3520', Name: 'Manager, litigation' },
  { Id: 6165, Code: '3520', Name: 'Manager, probate' },
  { Id: 6166, Code: '3520', Name: 'Manager, services, legal' },
  { Id: 6167, Code: '3520', Name: "Manager, solicitor's" },
  { Id: 6168, Code: '3520', Name: 'Member, APPEALS TRIBUNAL, INQUIRY' },
  { Id: 6169, Code: '3520', Name: 'Member, panel, INDUSTRIAL TRIBUNAL' },
  { Id: 6170, Code: '3520', Name: 'Member, tribunal' },
  { Id: 6171, Code: '3520', Name: 'Officer, compliance' },
  { Id: 6172, Code: '3520', Name: "Officer, coroner's" },
  { Id: 6173, Code: '3520', Name: 'Officer, legal' },
  { Id: 6174, Code: '3520', Name: 'Officer, litigation' },
  { Id: 6175, Code: '3520', Name: 'Officer, precognition' },
  { Id: 6176, Code: '3520', Name: 'Officer, protection, data' },
  { Id: 6177, Code: '3520', Name: 'Paralegal' },
  { Id: 6178, Code: '3520', Name: 'Registrar, land' },
  { Id: 6179, Code: '3520', Name: 'Translator, legal' },
  { Id: 6180, Code: '3520', Name: 'Tribunalist' },
  { Id: 6181, Code: '3520', Name: 'Writer, will' },
  { Id: 6182, Code: '3531', Name: 'Adjudicator, claims' },
  { Id: 6183, Code: '3531', Name: 'Adjudicator, insurance' },
  { Id: 6184, Code: '3531', Name: 'Adjuster, average' },
  { Id: 6185, Code: '3531', Name: 'Adjuster, claims' },
  { Id: 6186, Code: '3531', Name: 'Adjuster, loss' },
  { Id: 6187, Code: '3531', Name: 'Adviser, energy' },
  { Id: 6188, Code: '3531', Name: 'Analyst, scoring, credit' },
  { Id: 6189, Code: '3531', Name: 'Appraiser and valuer' },
  { Id: 6190, Code: '3531', Name: 'Arbitrator' },
  { Id: 6191, Code: '3531', Name: 'Assessor' },
  { Id: 6192, Code: '3531', Name: 'Assessor, claims' },
  { Id: 6193, Code: '3531', Name: 'Assessor, damage, vehicle' },
  { Id: 6194, Code: '3531', Name: 'Assessor, energy' },
  { Id: 6195, Code: '3531', Name: 'Assessor, HIP' },
  { Id: 6196, Code: '3531', Name: 'Assessor, insurance' },
  { Id: 6197, Code: '3531', Name: 'Consultant, claims, insurance' },
  { Id: 6198, Code: '3531', Name: 'Controller, claims, insurance' },
  { Id: 6199, Code: '3531', Name: 'Designer, systems, fire' },
  { Id: 6200, Code: '3531', Name: 'Engineer, assessing, insurance' },
  { Id: 6201, Code: '3531', Name: 'Engineer, cost' },
  { Id: 6202, Code: '3531', Name: 'Engineer, costing' },
  { Id: 6203, Code: '3531', Name: 'Engineer, estimating' },
  { Id: 6204, Code: '3531', Name: 'Engineer, inspection, insurance' },
  { Id: 6205, Code: '3531', Name: 'Engineer, insurance' },
  { Id: 6206, Code: '3531', Name: 'Engineer, staff, insurance' },
  { Id: 6207, Code: '3531', Name: 'Engineer-estimator' },
  { Id: 6208, Code: '3531', Name: 'Engineer-surveyor, insurance' },
  { Id: 6209, Code: '3531', Name: 'Estimator' },
  { Id: 6210, Code: '3531', Name: 'Estimator, building' },
  { Id: 6211, Code: '3531', Name: 'Estimator, chief' },
  { Id: 6212, Code: '3531', Name: 'Estimator, cost' },
  { Id: 6213, Code: '3531', Name: 'Estimator, mechanical' },
  { Id: 6214, Code: '3531', Name: 'Estimator, planning' },
  { Id: 6215, Code: '3531', Name: 'Estimator, print' },
  { Id: 6216, Code: '3531', Name: 'Estimator, printing' },
  { Id: 6217, Code: '3531', Name: 'Estimator, sales' },
  { Id: 6218, Code: '3531', Name: 'Estimator, technical' },
  { Id: 6219, Code: '3531', Name: 'Estimator-engineer' },
  { Id: 6220, Code: '3531', Name: 'Examiner, HM Revenue and Customs' },
  { Id: 6221, Code: '3531', Name: 'Executive, claims' },
  { Id: 6222, Code: '3531', Name: 'Expert, art' },
  { Id: 6223, Code: '3531', Name: 'Handler, case, PPI' },
  { Id: 6224, Code: '3531', Name: 'Inspector, claims, insurance' },
  { Id: 6225, Code: '3531', Name: 'Inspector, home' },
  { Id: 6226, Code: '3531', Name: 'Investigator, claims' },
  { Id: 6227, Code: '3531', Name: 'Investigator, insurance' },
  { Id: 6228, Code: '3531', Name: 'Manager, cost' },
  { Id: 6229, Code: '3531', Name: 'Manager, costing' },
  { Id: 6230, Code: '3531', Name: 'Manager, valuation' },
  { Id: 6231, Code: '3531', Name: 'Negotiator, claims' },
  { Id: 6232, Code: '3531', Name: 'Negotiator, insurance' },
  { Id: 6233, Code: '3531', Name: 'Officer, claims' },
  { Id: 6234, Code: '3531', Name: 'Officer, costs, technical' },
  { Id: 6235, Code: '3531', Name: 'Officer, rates and charges, transport' },
  { Id: 6236, Code: '3531', Name: 'Officer, rating' },
  { Id: 6237, Code: '3531', Name: 'Officer, rating and valuation' },
  { Id: 6238, Code: '3531', Name: 'Officer, rent' },
  { Id: 6239, Code: '3531', Name: 'Officer, valuation' },
  { Id: 6240, Code: '3531', Name: 'Official, claims, marine' },
  { Id: 6241, Code: '3531', Name: 'Planner-estimator' },
  { Id: 6242, Code: '3531', Name: 'Referencer, valuation' },
  { Id: 6243, Code: '3531', Name: 'Representative, claims, insurance' },
  { Id: 6244, Code: '3531', Name: 'Settler, claims, insurance' },
  { Id: 6245, Code: '3531', Name: 'Superintendent, branch, insurance' },
  { Id: 6246, Code: '3531', Name: 'Superintendent, claims' },
  { Id: 6247, Code: '3531', Name: 'Superintendent, district, insurance' },
  { Id: 6248, Code: '3531', Name: 'Superintendent, fire, insurance' },
  { Id: 6249, Code: '3531', Name: 'Superintendent, insurance' },
  { Id: 6250, Code: '3531', Name: 'Superintendent, motor, insurance' },
  { Id: 6251, Code: '3531', Name: 'Surveyor and valuer' },
  { Id: 6252, Code: '3531', Name: 'Surveyor, boiler and engine, insurance' },
  { Id: 6253, Code: '3531', Name: 'Surveyor, DOUBLE GLAZING' },
  { Id: 6254, Code: '3531', Name: 'Surveyor, engineering' },
  { Id: 6255, Code: '3531', Name: 'Surveyor, fire' },
  { Id: 6256, Code: '3531', Name: 'Surveyor, insulation' },
  { Id: 6257, Code: '3531', Name: 'Surveyor, insurance' },
  { Id: 6258, Code: '3531', Name: 'Surveyor, marine' },
  { Id: 6259, Code: '3531', Name: 'Surveyor, nautical' },
  { Id: 6260, Code: '3531', Name: 'Surveyor, naval' },
  { Id: 6261, Code: '3531', Name: 'Surveyor, rating' },
  { Id: 6262, Code: '3531', Name: 'Surveyor, valuation' },
  { Id: 6263, Code: '3531', Name: 'Surveyor, window' },
  { Id: 6264, Code: '3531', Name: 'Technician, valuation' },
  { Id: 6265, Code: '3531', Name: 'Valuer' },
  { Id: 6266, Code: '3531', Name: 'Valuer and auctioneer' },
  { Id: 6267, Code: '3532', Name: 'Administrator, investment' },
  { Id: 6268, Code: '3532', Name: 'Administrator, swaps' },
  { Id: 6269, Code: '3532', Name: "Agent, Lloyd's" },
  { Id: 6270, Code: '3532', Name: 'Agent, metal' },
  { Id: 6271, Code: '3532', Name: 'Arbitragist' },
  { Id: 6272, Code: '3532', Name: 'Associate, securities' },
  { Id: 6273, Code: '3532', Name: 'Broker, air' },
  { Id: 6274, Code: '3532', Name: 'Broker, bill' },
  { Id: 6275, Code: '3532', Name: 'Broker, bullion' },
  { Id: 6276, Code: '3532', Name: 'Broker, business' },
  { Id: 6277, Code: '3532', Name: 'Broker, commodities' },
  { Id: 6278, Code: '3532', Name: 'Broker, commodity' },
  { Id: 6279, Code: '3532', Name: 'Broker, diamond' },
  { Id: 6280, Code: '3532', Name: 'Broker, discount' },
  { Id: 6281, Code: '3532', Name: 'Broker, exchange' },
  { Id: 6282, Code: '3532', Name: 'Broker, exchange, foreign' },
  { Id: 6283, Code: '3532', Name: 'Broker, FINANCE' },
  { Id: 6284, Code: '3532', Name: 'Broker, financial' },
  { Id: 6285, Code: '3532', Name: 'Broker, insurance' },
  { Id: 6286, Code: '3532', Name: 'Broker, investment' },
  { Id: 6287, Code: '3532', Name: 'Broker, jewel' },
  { Id: 6288, Code: '3532', Name: 'Broker, licensed' },
  { Id: 6289, Code: '3532', Name: "Broker, Lloyd's" },
  { Id: 6290, Code: '3532', Name: 'Broker, marine' },
  { Id: 6291, Code: '3532', Name: 'Broker, money' },
  { Id: 6292, Code: '3532', Name: 'Broker, mortgage' },
  { Id: 6293, Code: '3532', Name: 'Broker, produce' },
  { Id: 6294, Code: '3532', Name: 'Broker, share' },
  { Id: 6295, Code: '3532', Name: 'Broker, ship' },
  { Id: 6296, Code: '3532', Name: 'Broker, stock' },
  { Id: 6297, Code: '3532', Name: 'Broker, stock and share' },
  { Id: 6298, Code: '3532', Name: 'Broker, tea' },
  { Id: 6299, Code: '3532', Name: 'Broker, TRANSPORT' },
  { Id: 6300, Code: '3532', Name: 'Broker, wholesale, retail trade' },
  { Id: 6301, Code: '3532', Name: 'Broker, yacht' },
  { Id: 6302, Code: '3532', Name: 'Consultant, commodity' },
  { Id: 6303, Code: '3532', Name: 'Dealer, bond' },
  { Id: 6304, Code: '3532', Name: 'Dealer, exchange, foreign' },
  { Id: 6305, Code: '3532', Name: 'Dealer, FINANCE' },
  { Id: 6306, Code: '3532', Name: 'Dealer, investment' },
  { Id: 6307, Code: '3532', Name: 'Dealer, money' },
  { Id: 6308, Code: '3532', Name: 'Dealer, share' },
  { Id: 6309, Code: '3532', Name: 'Dealer, stock and share' },
  { Id: 6310, Code: '3532', Name: 'Executive, trading, commodities' },
  { Id: 6311, Code: '3532', Name: 'Jobber, stock' },
  { Id: 6312, Code: '3532', Name: 'Jobber, stock and share' },
  { Id: 6313, Code: '3532', Name: 'Keeper, position' },
  { Id: 6314, Code: '3532', Name: 'Member of the Stock Exchange' },
  { Id: 6315, Code: '3532', Name: 'Salesman, investment broking' },
  { Id: 6316, Code: '3532', Name: 'Shipbroker' },
  { Id: 6317, Code: '3532', Name: 'Stockbroker' },
  { Id: 6318, Code: '3532', Name: 'Supervisor, swaps' },
  { Id: 6319, Code: '3532', Name: 'Trader, bond' },
  { Id: 6320, Code: '3532', Name: 'Trader, commodity' },
  { Id: 6321, Code: '3532', Name: 'Trader, derivatives' },
  { Id: 6322, Code: '3532', Name: 'Trader, equity' },
  { Id: 6323, Code: '3532', Name: 'Trader, exchange, foreign' },
  { Id: 6324, Code: '3532', Name: 'Trader, financial' },
  { Id: 6325, Code: '3532', Name: 'Trader, futures' },
  { Id: 6326, Code: '3532', Name: 'Trader, market, money, banking' },
  { Id: 6327, Code: '3532', Name: 'Trader, options' },
  { Id: 6328, Code: '3532', Name: 'Trader, securities' },
  { Id: 6329, Code: '3532', Name: 'Trader, stock' },
  { Id: 6330, Code: '3532', Name: 'Trader, stock exchange' },
  { Id: 6331, Code: '3532', Name: 'Trader, stockbrokers' },
  { Id: 6332, Code: '3533', Name: "Agent, underwriter's" },
  { Id: 6333, Code: '3533', Name: 'Controller, underwriting, insurance' },
  { Id: 6334, Code: '3533', Name: 'Executive, underwriting' },
  { Id: 6335, Code: '3533', Name: 'Handler, account, insurance' },
  { Id: 6336, Code: '3533', Name: 'Handler, accounts, insurance' },
  { Id: 6337, Code: '3533', Name: 'Handler, insurance' },
  { Id: 6338, Code: '3533', Name: 'Inspector, business, new, insurance' },
  { Id: 6339, Code: '3533', Name: 'Inspector, chief, insurance' },
  { Id: 6340, Code: '3533', Name: 'Inspector, district, insurance' },
  { Id: 6341, Code: '3533', Name: 'Inspector, insurance' },
  { Id: 6342, Code: '3533', Name: 'Inspector, life, insurance' },
  { Id: 6343, Code: '3533', Name: 'Leader, team, insurance' },
  { Id: 6344, Code: '3533', Name: 'Member of Lloyds' },
  { Id: 6345, Code: '3533', Name: 'Underwriter' },
  { Id: 6346, Code: '3533', Name: 'Underwriter, commercial' },
  { Id: 6347, Code: '3533', Name: 'Underwriter, insurance' },
  { Id: 6348, Code: '3533', Name: 'Underwriter, medical' },
  { Id: 6349, Code: '3533', Name: 'Underwriter, mortgage' },
  { Id: 6350, Code: '3534', Name: 'Adviser, financial' },
  { Id: 6351, Code: '3534', Name: 'Adviser, investment' },
  { Id: 6352, Code: '3534', Name: 'Adviser, loans, financial' },
  { Id: 6353, Code: '3534', Name: 'Adviser, mortgage' },
  { Id: 6354, Code: '3534', Name: 'Adviser, pension' },
  { Id: 6355, Code: '3534', Name: 'Adviser, pensions' },
  { Id: 6356, Code: '3534', Name: 'Adviser, planning, financial' },
  { Id: 6357, Code: '3534', Name: 'Adviser, services, financial' },
  { Id: 6358, Code: '3534', Name: 'Adviser, technical, insurance' },
  { Id: 6359, Code: '3534', Name: 'Agent, financial' },
  { Id: 6360, Code: '3534', Name: 'Agent, money' },
  { Id: 6361, Code: '3534', Name: 'Agent, mortgage' },
  { Id: 6362, Code: '3534', Name: 'Analyst, actions, corporate, bank' },
  { Id: 6363, Code: '3534', Name: 'Analyst, collateral' },
  { Id: 6364, Code: '3534', Name: 'Analyst, credit' },
  { Id: 6365, Code: '3534', Name: 'Analyst, finance' },
  { Id: 6366, Code: '3534', Name: 'Analyst, financial' },
  { Id: 6367, Code: '3534', Name: 'Analyst, financial services' },
  { Id: 6368, Code: '3534', Name: 'Analyst, investment' },
  { Id: 6369, Code: '3534', Name: 'Analyst, pricing' },
  { Id: 6370, Code: '3534', Name: 'Analyst, risk, credit' },
  { Id: 6371, Code: '3534', Name: 'Arranger, mortgage' },
  { Id: 6372, Code: '3534', Name: 'Associate, banking' },
  { Id: 6373, Code: '3534', Name: 'Associate, finance' },
  { Id: 6374, Code: '3534', Name: 'Associate, financial services' },
  { Id: 6375, Code: '3534', Name: 'Associate, insurance' },
  { Id: 6376, Code: '3534', Name: 'Associate, sales, insurance' },
  { Id: 6377, Code: '3534', Name: 'Capitalist, venture' },
  { Id: 6378, Code: '3534', Name: 'Consultant, banking' },
  { Id: 6379, Code: '3534', Name: 'Consultant, benefit, employee' },
  { Id: 6380, Code: '3534', Name: 'Consultant, care, health, insurance' },
  { Id: 6381, Code: '3534', Name: 'Consultant, financial' },
  { Id: 6382, Code: '3534', Name: 'Consultant, financial services' },
  { Id: 6383, Code: '3534', Name: 'Consultant, insurance' },
  { Id: 6384, Code: '3534', Name: 'Consultant, investment' },
  { Id: 6385, Code: '3534', Name: 'Consultant, mortgage' },
  { Id: 6386, Code: '3534', Name: 'Consultant, pension' },
  { Id: 6387, Code: '3534', Name: 'Consultant, pensions' },
  { Id: 6388, Code: '3534', Name: 'Consultant, planning, financial' },
  { Id: 6389, Code: '3534', Name: 'Consultant, services, financial' },
  { Id: 6390, Code: '3534', Name: 'Consultant, technical, insurance' },
  { Id: 6391, Code: '3534', Name: 'Consultant, technical, pensions' },
  { Id: 6392, Code: '3534', Name: 'Counsellor, investment' },
  { Id: 6393, Code: '3534', Name: 'Executive, account, insurance' },
  { Id: 6394, Code: '3534', Name: 'Financier' },
  { Id: 6395, Code: '3534', Name: 'Inspector, pensions' },
  { Id: 6396, Code: '3534', Name: 'Interviewer, bank' },
  { Id: 6397, Code: '3534', Name: 'Interviewer, building society' },
  { Id: 6398, Code: '3534', Name: 'Investor, private' },
  { Id: 6399, Code: '3534', Name: 'Manager, pensions' },
  { Id: 6400, Code: '3534', Name: 'Officer, investment, banking' },
  { Id: 6401, Code: '3534', Name: 'Officer, investment, finance' },
  { Id: 6402, Code: '3534', Name: 'Officer, planning, financial' },
  { Id: 6403, Code: '3534', Name: 'Officer, schemes, insurance' },
  { Id: 6404, Code: '3534', Name: 'Paraplanner' },
  { Id: 6405, Code: '3534', Name: 'Planner, financial' },
  { Id: 6406, Code: '3535', Name: 'Accountant, tax' },
  { Id: 6407, Code: '3535', Name: 'Accountant, taxation' },
  { Id: 6408, Code: '3535', Name: 'Adviser, tax' },
  { Id: 6409, Code: '3535', Name: 'Adviser, taxation' },
  { Id: 6410, Code: '3535', Name: 'Adviser, VAT' },
  { Id: 6411, Code: '3535', Name: 'Analyst, tax' },
  { Id: 6412, Code: '3535', Name: 'Consultant, tax' },
  { Id: 6413, Code: '3535', Name: 'Consultant, taxation' },
  { Id: 6414, Code: '3535', Name: 'Consultant, VAT' },
  { Id: 6415, Code: '3535', Name: 'Inspector of taxes' },
  {
    Id: 6416,
    Code: '3535',
    Name: 'Inspector, district, HM Revenue and Customs'
  },
  { Id: 6417, Code: '3535', Name: 'Inspector, HM Revenue and Customs' },
  { Id: 6418, Code: '3535', Name: 'Inspector, tax' },
  { Id: 6419, Code: '3535', Name: 'Inspector, VAT, HM Revenue and Customs' },
  { Id: 6420, Code: '3535', Name: 'Investigator, HM Revenue and Customs' },
  { Id: 6421, Code: '3535', Name: 'Manager, tax' },
  { Id: 6422, Code: '3535', Name: 'Manager, taxation' },
  { Id: 6423, Code: '3535', Name: 'Manager, VAT' },
  {
    Id: 6424,
    Code: '3535',
    Name: 'Officer, compliance, HM Revenue and Customs'
  },
  { Id: 6425, Code: '3535', Name: 'Partner, tax' },
  { Id: 6426, Code: '3535', Name: 'Practitioner, tax' },
  { Id: 6427, Code: '3535', Name: 'Practitioner, taxation' },
  { Id: 6428, Code: '3535', Name: 'Senior, tax' },
  { Id: 6429, Code: '3535', Name: 'Senior, taxation' },
  { Id: 6430, Code: '3535', Name: 'Specialist, taxation' },
  { Id: 6431, Code: '3535', Name: 'Supervisor, tax' },
  { Id: 6432, Code: '3535', Name: 'Supervisor, taxation' },
  { Id: 6433, Code: '3535', Name: 'Technician, tax' },
  { Id: 6434, Code: '3535', Name: 'Technician, taxation' },
  { Id: 6435, Code: '3536', Name: 'Agent, export' },
  { Id: 6436, Code: '3536', Name: 'Agent, import' },
  { Id: 6437, Code: '3536', Name: 'Agent, import-export' },
  { Id: 6438, Code: '3536', Name: 'Agent, shipping' },
  { Id: 6439, Code: '3536', Name: "Agent, ship's" },
  { Id: 6440, Code: '3536', Name: 'Consultant, export' },
  { Id: 6441, Code: '3536', Name: 'Controller, export' },
  { Id: 6442, Code: '3536', Name: 'Coordinator, export' },
  { Id: 6443, Code: '3536', Name: 'Executive, import' },
  { Id: 6444, Code: '3536', Name: 'Exporter' },
  { Id: 6445, Code: '3536', Name: 'Importer' },
  { Id: 6446, Code: '3536', Name: 'Importer-exporter' },
  { Id: 6447, Code: '3536', Name: 'Officer, export' },
  { Id: 6448, Code: '3536', Name: 'Officer, export and import' },
  { Id: 6449, Code: '3536', Name: 'Shipper, wholesale trade' },
  { Id: 6450, Code: '3536', Name: 'Supervisor, export-import' },
  { Id: 6451, Code: '3537', Name: 'Administrator, fund' },
  { Id: 6452, Code: '3537', Name: 'Administrator, insolvency' },
  { Id: 6453, Code: '3537', Name: 'Administrator, trust, banking' },
  { Id: 6454, Code: '3537', Name: 'Associate, business, banking' },
  { Id: 6455, Code: '3537', Name: 'Clerk, managing, accountancy' },
  { Id: 6456, Code: '3537', Name: 'Controller, banking' },
  { Id: 6457, Code: '3537', Name: 'Controller, financial' },
  { Id: 6458, Code: '3537', Name: 'Officer, trust' },
  { Id: 6459, Code: '3537', Name: 'Senior, audit' },
  { Id: 6460, Code: '3537', Name: 'Senior, trust' },
  { Id: 6461, Code: '3537', Name: 'Technician, account' },
  { Id: 6462, Code: '3537', Name: 'Technician, accountancy' },
  { Id: 6463, Code: '3537', Name: 'Technician, accounting' },
  { Id: 6464, Code: '3537', Name: 'Technician, audit' },
  { Id: 6465, Code: '3538', Name: 'Banker, private' },
  { Id: 6466, Code: '3538', Name: 'Executive, banking, private' },
  { Id: 6467, Code: '3538', Name: 'Manager, account, bank' },
  { Id: 6468, Code: '3538', Name: 'Manager, account, building society' },
  {
    Id: 6469,
    Code: '3538',
    Name: 'Manager, account, client, financial services'
  },
  {
    Id: 6470,
    Code: '3538',
    Name: 'Manager, account, customer, financial services'
  },
  { Id: 6471, Code: '3538', Name: 'Manager, account, insurance' },
  { Id: 6472, Code: '3538', Name: 'Manager, accounts' },
  { Id: 6473, Code: '3538', Name: 'Manager, accounts, financial' },
  { Id: 6474, Code: '3538', Name: 'Manager, asset, financial services' },
  { Id: 6475, Code: '3538', Name: 'Manager, audit' },
  { Id: 6476, Code: '3538', Name: 'Manager, banking, personal' },
  { Id: 6477, Code: '3538', Name: 'Manager, banking, private' },
  { Id: 6478, Code: '3538', Name: 'Manager, business, bank' },
  { Id: 6479, Code: '3538', Name: 'Manager, claims' },
  { Id: 6480, Code: '3538', Name: 'Manager, client, bank' },
  { Id: 6481, Code: '3538', Name: 'Manager, client, financial services' },
  { Id: 6482, Code: '3538', Name: 'Manager, control, credit' },
  { Id: 6483, Code: '3538', Name: 'Manager, credit' },
  { Id: 6484, Code: '3538', Name: 'Manager, customer, bank' },
  { Id: 6485, Code: '3538', Name: 'Manager, customer, building society' },
  { Id: 6486, Code: '3538', Name: 'Manager, customer, financial services' },
  { Id: 6487, Code: '3538', Name: 'Manager, department, bank' },
  { Id: 6488, Code: '3538', Name: 'Manager, department, building society' },
  { Id: 6489, Code: '3538', Name: 'Manager, department, insurance' },
  { Id: 6490, Code: '3538', Name: 'Manager, departmental, bank' },
  { Id: 6491, Code: '3538', Name: 'Manager, departmental, building society' },
  { Id: 6492, Code: '3538', Name: 'Manager, departmental, insurance' },
  { Id: 6493, Code: '3538', Name: 'Manager, derivatives' },
  { Id: 6494, Code: '3538', Name: 'Manager, fund' },
  { Id: 6495, Code: '3538', Name: 'Manager, house, acceptance' },
  { Id: 6496, Code: '3538', Name: 'Manager, incident, personal' },
  { Id: 6497, Code: '3538', Name: 'Manager, investment' },
  { Id: 6498, Code: '3538', Name: 'Manager, line, front, bank' },
  { Id: 6499, Code: '3538', Name: 'Manager, payable, accounts' },
  { Id: 6500, Code: '3538', Name: 'Manager, planning, commercial' },
  { Id: 6501, Code: '3538', Name: 'Manager, planning, financial' },
  {
    Id: 6502,
    Code: '3538',
    Name: 'Manager, relations, client, financial services'
  },
  { Id: 6503, Code: '3538', Name: 'Manager, relationship, bank' },
  {
    Id: 6504,
    Code: '3538',
    Name: 'Manager, relationship, client, financial services'
  },
  {
    Id: 6505,
    Code: '3538',
    Name: 'Manager, service, client, financial services'
  },
  {
    Id: 6506,
    Code: '3538',
    Name: 'Manager, services, client, financial services'
  },
  { Id: 6507, Code: '3538', Name: 'Manager, support, finance' },
  { Id: 6508, Code: '3538', Name: 'Manager, team, banking' },
  { Id: 6509, Code: '3538', Name: 'Manager, team, claims' },
  { Id: 6510, Code: '3538', Name: 'Manager, team, insurance' },
  { Id: 6511, Code: '3538', Name: 'Manager, underwriting' },
  { Id: 6512, Code: '3538', Name: 'Officer, vetting, credit' },
  { Id: 6513, Code: '3539', Name: 'Administrator, committee' },
  { Id: 6514, Code: '3539', Name: 'Administrator, PMO' },
  { Id: 6515, Code: '3539', Name: 'Administrator, project' },
  { Id: 6516, Code: '3539', Name: 'Administrator, trial, clinical' },
  { Id: 6517, Code: '3539', Name: 'Administrator, trials, clinical' },
  { Id: 6518, Code: '3539', Name: 'Adviser, ballot' },
  { Id: 6519, Code: '3539', Name: 'Adviser, cellar, catering' },
  { Id: 6520, Code: '3539', Name: 'Adviser, contract' },
  { Id: 6521, Code: '3539', Name: 'Adviser, marine' },
  { Id: 6522, Code: '3539', Name: 'Adviser, political' },
  { Id: 6523, Code: '3539', Name: 'Adviser, traffic' },
  { Id: 6524, Code: '3539', Name: 'Adviser, transport' },
  { Id: 6525, Code: '3539', Name: 'Agent, bloodstock' },
  { Id: 6526, Code: '3539', Name: 'Agent, commercial' },
  { Id: 6527, Code: '3539', Name: 'Agent, commission, FARM PRODUCE' },
  { Id: 6528, Code: '3539', Name: 'Agent, commission, RAW MATERIALS' },
  { Id: 6529, Code: '3539', Name: 'Agent, election' },
  { Id: 6530, Code: '3539', Name: 'Agent, parliamentary' },
  { Id: 6531, Code: '3539', Name: 'Agent, party, political party' },
  { Id: 6532, Code: '3539', Name: 'Agent, political' },
  { Id: 6533, Code: '3539', Name: 'Agent, trademark' },
  { Id: 6534, Code: '3539', Name: 'Analyst, coding, clinical' },
  { Id: 6535, Code: '3539', Name: 'Analyst, data' },
  {
    Id: 6536,
    Code: '3539',
    Name: 'Analyst, Defence Science and Technology Laboratory'
  },
  { Id: 6537, Code: '3539', Name: 'Analyst, forecast' },
  { Id: 6538, Code: '3539', Name: 'Analyst, GIS' },
  { Id: 6539, Code: '3539', Name: 'Analyst, information, management' },
  { Id: 6540, Code: '3539', Name: 'Analyst, logistics' },
  { Id: 6541, Code: '3539', Name: 'Analyst, management' },
  { Id: 6542, Code: '3539', Name: 'Analyst, management, data' },
  { Id: 6543, Code: '3539', Name: 'Analyst, market' },
  { Id: 6544, Code: '3539', Name: 'Analyst, methods' },
  { Id: 6545, Code: '3539', Name: 'Analyst, O&M' },
  { Id: 6546, Code: '3539', Name: 'Analyst, organisation and methods' },
  { Id: 6547, Code: '3539', Name: 'Analyst, performance' },
  { Id: 6548, Code: '3539', Name: 'Analyst, quality, data' },
  { Id: 6549, Code: '3539', Name: 'Analyst, research, operational' },
  { Id: 6550, Code: '3539', Name: 'Analyst, study, work' },
  { Id: 6551, Code: '3539', Name: 'Analyst, system, business' },
  { Id: 6552, Code: '3539', Name: 'Analyst, systems, business' },
  {
    Id: 6553,
    Code: '3539',
    Name: 'Analyst, systems, information, geographical'
  },
  { Id: 6554, Code: '3539', Name: 'Analyst, systems, office' },
  { Id: 6555, Code: '3539', Name: 'Analyst, tachograph' },
  { Id: 6556, Code: '3539', Name: 'Analyst, time and motion' },
  { Id: 6557, Code: '3539', Name: 'Analyst, value' },
  { Id: 6558, Code: '3539', Name: 'Analyst, WORK STUDY' },
  { Id: 6559, Code: '3539', Name: 'Appraiser' },
  { Id: 6560, Code: '3539', Name: 'Assessor, study, work' },
  { Id: 6561, Code: '3539', Name: 'Assessor, WORK, TIME AND MOTION' },
  { Id: 6562, Code: '3539', Name: 'Assistant, compliance, banking' },
  { Id: 6563, Code: '3539', Name: 'Assistant, GIS' },
  { Id: 6564, Code: '3539', Name: 'Assistant, measurement, work' },
  { Id: 6565, Code: '3539', Name: 'Assistant, methods' },
  { Id: 6566, Code: '3539', Name: 'Assistant, planning' },
  { Id: 6567, Code: '3539', Name: 'Assistant, project, research' },
  {
    Id: 6568,
    Code: '3539',
    Name: 'Assistant, systems, information, geographical'
  },
  { Id: 6569, Code: '3539', Name: 'Assistant, trial, clinical' },
  { Id: 6570, Code: '3539', Name: 'Assistant, trials, clinical' },
  { Id: 6571, Code: '3539', Name: 'Associate, licensing' },
  { Id: 6572, Code: '3539', Name: 'Attache' },
  { Id: 6573, Code: '3539', Name: 'Coder, clinical' },
  { Id: 6574, Code: '3539', Name: 'Consultant, aviation' },
  { Id: 6575, Code: '3539', Name: 'Consultant, bloodstock' },
  { Id: 6576, Code: '3539', Name: 'Consultant, chain, supply' },
  { Id: 6577, Code: '3539', Name: 'Consultant, contracts' },
  { Id: 6578, Code: '3539', Name: 'Consultant, defence' },
  { Id: 6579, Code: '3539', Name: 'Consultant, leisure' },
  { Id: 6580, Code: '3539', Name: 'Consultant, logistics' },
  { Id: 6581, Code: '3539', Name: 'Consultant, marine' },
  { Id: 6582, Code: '3539', Name: 'Consultant, O&M' },
  { Id: 6583, Code: '3539', Name: 'Consultant, organisation and methods' },
  { Id: 6584, Code: '3539', Name: 'Consultant, political' },
  { Id: 6585, Code: '3539', Name: 'Consultant, printing' },
  { Id: 6586, Code: '3539', Name: 'Consultant, research, operational' },
  { Id: 6587, Code: '3539', Name: 'Consultant, study, works' },
  { Id: 6588, Code: '3539', Name: 'Consultant, transport' },
  { Id: 6589, Code: '3539', Name: 'Consultant, transportation' },
  { Id: 6590, Code: '3539', Name: 'Controller, site' },
  {
    Id: 6591,
    Code: '3539',
    Name: 'Controller, sub-contracts, building construction'
  },
  { Id: 6592, Code: '3539', Name: 'Controller, sub-contracts, PRODUCTION' },
  { Id: 6593, Code: '3539', Name: 'Coordinator, contracts' },
  { Id: 6594, Code: '3539', Name: 'Coordinator, improvement, continuous' },
  { Id: 6595, Code: '3539', Name: 'Coordinator, licensing' },
  { Id: 6596, Code: '3539', Name: 'Coordinator, project' },
  { Id: 6597, Code: '3539', Name: 'Coordinator, projects' },
  { Id: 6598, Code: '3539', Name: 'Coordinator, quality, data' },
  { Id: 6599, Code: '3539', Name: 'Coordinator, relations, trade' },
  { Id: 6600, Code: '3539', Name: 'Coordinator, research' },
  { Id: 6601, Code: '3539', Name: 'Coordinator, translation' },
  { Id: 6602, Code: '3539', Name: 'Counsellor, business' },
  { Id: 6603, Code: '3539', Name: 'Engineer, measurement, work' },
  { Id: 6604, Code: '3539', Name: 'Engineer, methods' },
  { Id: 6605, Code: '3539', Name: 'Engineer, standards' },
  { Id: 6606, Code: '3539', Name: 'Engineer, study, method' },
  { Id: 6607, Code: '3539', Name: 'Engineer, study, time' },
  { Id: 6608, Code: '3539', Name: 'Engineer, study, work' },
  { Id: 6609, Code: '3539', Name: 'Engineer, time and study' },
  { Id: 6610, Code: '3539', Name: 'Engineer, value' },
  { Id: 6611, Code: '3539', Name: 'Evaluator, job' },
  { Id: 6612, Code: '3539', Name: 'Executive, company, oil' },
  { Id: 6613, Code: '3539', Name: 'Executive, management, yield' },
  { Id: 6614, Code: '3539', Name: 'Executive, registration' },
  { Id: 6615, Code: '3539', Name: 'Expert, efficiency, business' },
  { Id: 6616, Code: '3539', Name: 'Expert, study, time' },
  { Id: 6617, Code: '3539', Name: 'Fixer, price' },
  { Id: 6618, Code: '3539', Name: 'Fixer, rate' },
  { Id: 6619, Code: '3539', Name: 'Forecaster, network' },
  { Id: 6620, Code: '3539', Name: 'Forecaster, sales' },
  { Id: 6621, Code: '3539', Name: 'Graphologist' },
  { Id: 6622, Code: '3539', Name: 'Inspector, practice, standard' },
  { Id: 6623, Code: '3539', Name: 'Inspector, room, standards' },
  { Id: 6624, Code: '3539', Name: 'Investigator, O&M' },
  { Id: 6625, Code: '3539', Name: 'Investigator, organisation and method' },
  { Id: 6626, Code: '3539', Name: 'Investigator, study, work' },
  { Id: 6627, Code: '3539', Name: 'Leader, team, PROJECT MANAGEMENT' },
  { Id: 6628, Code: '3539', Name: 'Man, study, work' },
  { Id: 6629, Code: '3539', Name: 'Manager, package, work' },
  { Id: 6630, Code: '3539', Name: 'Manager, services, corporate' },
  { Id: 6631, Code: '3539', Name: 'Officer, coding, clinical' },
  { Id: 6632, Code: '3539', Name: 'Officer, contract, building construction' },
  { Id: 6633, Code: '3539', Name: 'Officer, contract, manufacturing' },
  { Id: 6634, Code: '3539', Name: 'Officer, contracts, building construction' },
  { Id: 6635, Code: '3539', Name: 'Officer, contracts, manufacturing' },
  { Id: 6636, Code: '3539', Name: 'Officer, data' },
  { Id: 6637, Code: '3539', Name: 'Officer, data, GIS' },
  { Id: 6638, Code: '3539', Name: 'Officer, evaluation, job' },
  { Id: 6639, Code: '3539', Name: 'Officer, GIS, assistant' },
  { Id: 6640, Code: '3539', Name: 'Officer, governance, clinical' },
  { Id: 6641, Code: '3539', Name: 'Officer, improvement, continuous' },
  { Id: 6642, Code: '3539', Name: 'Officer, information, management' },
  { Id: 6643, Code: '3539', Name: 'Officer, investment, local government' },
  { Id: 6644, Code: '3539', Name: 'Officer, knowledge' },
  { Id: 6645, Code: '3539', Name: 'Officer, liaison, manufacturing' },
  { Id: 6646, Code: '3539', Name: 'Officer, lottery' },
  { Id: 6647, Code: '3539', Name: 'Officer, management, knowledge' },
  { Id: 6648, Code: '3539', Name: 'Officer, management, time' },
  { Id: 6649, Code: '3539', Name: 'Officer, measurement, work' },
  { Id: 6650, Code: '3539', Name: 'Officer, methods' },
  { Id: 6651, Code: '3539', Name: 'Officer, O&M' },
  { Id: 6652, Code: '3539', Name: 'Officer, organisation and methods' },
  { Id: 6653, Code: '3539', Name: 'Officer, organisation, government' },
  { Id: 6654, Code: '3539', Name: 'Officer, patents' },
  { Id: 6655, Code: '3539', Name: 'Officer, planning, strategic' },
  { Id: 6656, Code: '3539', Name: 'Officer, production, technical' },
  { Id: 6657, Code: '3539', Name: 'Officer, project' },
  { Id: 6658, Code: '3539', Name: 'Officer, quality, data' },
  { Id: 6659, Code: '3539', Name: 'Officer, research, operational' },
  { Id: 6660, Code: '3539', Name: 'Officer, review, performance' },
  {
    Id: 6661,
    Code: '3539',
    Name: 'Officer, services, productivity, gas supplier'
  },
  { Id: 6662, Code: '3539', Name: 'Officer, studies, clinical' },
  { Id: 6663, Code: '3539', Name: 'Officer, study, work' },
  { Id: 6664, Code: '3539', Name: 'Officer, support, business' },
  { Id: 6665, Code: '3539', Name: 'Officer, support, project' },
  { Id: 6666, Code: '3539', Name: 'Officer, support, risk' },
  { Id: 6667, Code: '3539', Name: 'Officer, technical, WORK STUDY' },
  { Id: 6668, Code: '3539', Name: 'Officer, trial, clinical' },
  { Id: 6669, Code: '3539', Name: 'Officer, trials, clinical' },
  { Id: 6670, Code: '3539', Name: 'Officer, workshops, MOD' },
  { Id: 6671, Code: '3539', Name: 'Planner, business' },
  { Id: 6672, Code: '3539', Name: 'Planner, strategic' },
  { Id: 6673, Code: '3539', Name: 'Practitioner, study, works' },
  { Id: 6674, Code: '3539', Name: 'Setter, rate' },
  { Id: 6675, Code: '3539', Name: 'Specialist, O&M' },
  { Id: 6676, Code: '3539', Name: 'Specialist, organisation and methods' },
  { Id: 6677, Code: '3539', Name: 'Strategist' },
  { Id: 6678, Code: '3541', Name: 'Administrator, contracts, purchasing' },
  { Id: 6679, Code: '3541', Name: 'Agent, buying' },
  { Id: 6680, Code: '3541', Name: 'Agent, purchasing' },
  { Id: 6681, Code: '3541', Name: 'Assistant, buying, wholesale trade' },
  { Id: 6682, Code: '3541', Name: 'Assistant, purchasing, wholesale trade' },
  { Id: 6683, Code: '3541', Name: 'Assistant, trading, retail trade' },
  { Id: 6684, Code: '3541', Name: 'Buyer' },
  { Id: 6685, Code: '3541', Name: 'Buyer and estimator' },
  { Id: 6686, Code: '3541', Name: 'Buyer, advertising' },
  { Id: 6687, Code: '3541', Name: 'Buyer, fashion' },
  { Id: 6688, Code: '3541', Name: 'Buyer, media' },
  { Id: 6689, Code: '3541', Name: 'Buyer, print' },
  { Id: 6690, Code: '3541', Name: 'Buyer, space' },
  { Id: 6691, Code: '3541', Name: 'Buyer, store' },
  { Id: 6692, Code: '3541', Name: 'Consultant, purchasing' },
  { Id: 6693, Code: '3541', Name: 'Consultant, wine' },
  { Id: 6694, Code: '3541', Name: 'Controller, buying' },
  { Id: 6695, Code: '3541', Name: 'Controller, purchasing' },
  { Id: 6696, Code: '3541', Name: 'Coordinator, purchasing' },
  { Id: 6697, Code: '3541', Name: 'Coordinator, supplies' },
  { Id: 6698, Code: '3541', Name: 'Engineer, procurement' },
  { Id: 6699, Code: '3541', Name: 'Engineer, purchasing' },
  { Id: 6700, Code: '3541', Name: 'Estimator and buyer, retail trade' },
  { Id: 6701, Code: '3541', Name: 'Executive, purchasing' },
  { Id: 6702, Code: '3541', Name: 'Manciple' },
  { Id: 6703, Code: '3541', Name: 'Oenologist' },
  { Id: 6704, Code: '3541', Name: 'Officer, contract, government' },
  { Id: 6705, Code: '3541', Name: 'Officer, contract, PURCHASING' },
  { Id: 6706, Code: '3541', Name: 'Officer, contracts, government' },
  { Id: 6707, Code: '3541', Name: 'Officer, contracts, PURCHASING' },
  { Id: 6708, Code: '3541', Name: 'Officer, procurement' },
  { Id: 6709, Code: '3541', Name: 'Officer, purchasing' },
  { Id: 6710, Code: '3541', Name: 'Officer, supplies' },
  { Id: 6711, Code: '3541', Name: 'Officer, supplies, chief' },
  { Id: 6712, Code: '3541', Name: 'Officer, supply, armament' },
  { Id: 6713, Code: '3541', Name: 'Officer, supply, MOD' },
  { Id: 6714, Code: '3541', Name: 'Planner, demand, photographic' },
  { Id: 6715, Code: '3541', Name: 'Planner, merchandise' },
  { Id: 6716, Code: '3541', Name: 'Planner, supply' },
  { Id: 6717, Code: '3541', Name: 'Purchaser' },
  { Id: 6718, Code: '3541', Name: 'Salesman-buyer' },
  { Id: 6719, Code: '3541', Name: 'Selector, range, retail trade' },
  { Id: 6720, Code: '3541', Name: 'Specialist, chain, supply' },
  { Id: 6721, Code: '3542', Name: 'Administrator, e-commerce' },
  { Id: 6722, Code: '3542', Name: 'Adviser, sales, technical' },
  { Id: 6723, Code: '3542', Name: 'Adviser, service, home, gas supplier' },
  { Id: 6724, Code: '3542', Name: 'Adviser, technical, SALES' },
  { Id: 6725, Code: '3542', Name: 'Agent, agricultural' },
  { Id: 6726, Code: '3542', Name: 'Agent, business' },
  { Id: 6727, Code: '3542', Name: 'Agent, commission, commission agents' },
  { Id: 6728, Code: '3542', Name: 'Agent, commission, MANUFACTURED GOODS' },
  { Id: 6729, Code: '3542', Name: 'Agent, commission, manufacturing' },
  {
    Id: 6730,
    Code: '3542',
    Name: 'Agent, commission, wholesale, retail trade'
  },
  { Id: 6731, Code: '3542', Name: "Agent, engineer's" },
  { Id: 6732, Code: '3542', Name: "Agent, manufacturer's" },
  { Id: 6733, Code: '3542', Name: 'Agent, manufacturing' },
  { Id: 6734, Code: '3542', Name: "Agent, publisher's" },
  { Id: 6735, Code: '3542', Name: 'Agent, sales' },
  { Id: 6736, Code: '3542', Name: 'Agent, textile' },
  { Id: 6737, Code: '3542', Name: 'Agent, wholesale, retail trade' },
  { Id: 6738, Code: '3542', Name: 'Assistant, gallery, art, retail trade' },
  { Id: 6739, Code: '3542', Name: 'Consultant, leasing' },
  { Id: 6740, Code: '3542', Name: 'Consultant, sales' },
  { Id: 6741, Code: '3542', Name: 'Consultant, sales and marketing' },
  { Id: 6742, Code: '3542', Name: 'Consultant, shipping' },
  { Id: 6743, Code: '3542', Name: 'Consultant, technical, SALES' },
  { Id: 6744, Code: '3542', Name: 'Controller, area, retail trade' },
  {
    Id: 6745,
    Code: '3542',
    Name: 'Controller, divisional, wholesale, retail trade'
  },
  { Id: 6746, Code: '3542', Name: 'Controller, retail' },
  { Id: 6747, Code: '3542', Name: 'Controller, sales' },
  { Id: 6748, Code: '3542', Name: 'Developer, business, SALES' },
  { Id: 6749, Code: '3542', Name: 'Engineer, commercial' },
  { Id: 6750, Code: '3542', Name: 'Engineer, consulting, SALES' },
  { Id: 6751, Code: '3542', Name: 'Engineer, consumers, electricity supplier' },
  { Id: 6752, Code: '3542', Name: 'Engineer, development, business' },
  { Id: 6753, Code: '3542', Name: 'Engineer, marketing, product' },
  { Id: 6754, Code: '3542', Name: 'Engineer, sales' },
  { Id: 6755, Code: '3542', Name: 'Engineer, service, sales' },
  { Id: 6756, Code: '3542', Name: 'Engineer, support, sales' },
  { Id: 6757, Code: '3542', Name: 'Executive, account, corporate' },
  { Id: 6758, Code: '3542', Name: 'Executive, account, enterprise' },
  { Id: 6759, Code: '3542', Name: 'Executive, account, SALES' },
  { Id: 6760, Code: '3542', Name: 'Executive, accounts, national' },
  { Id: 6761, Code: '3542', Name: 'Executive, e-commerce' },
  { Id: 6762, Code: '3542', Name: 'Executive, sales' },
  { Id: 6763, Code: '3542', Name: 'Executive, sales and marketing' },
  { Id: 6764, Code: '3542', Name: 'Handler, account, sales' },
  { Id: 6765, Code: '3542', Name: 'Manager, territory' },
  { Id: 6766, Code: '3542', Name: 'Merchant, manufacturing' },
  { Id: 6767, Code: '3542', Name: 'Merchant, wholesale, retail trade' },
  { Id: 6768, Code: '3542', Name: 'Officer, liaison, medical' },
  { Id: 6769, Code: '3542', Name: 'Officer, sales' },
  { Id: 6770, Code: '3542', Name: 'Officer, support, sales' },
  { Id: 6771, Code: '3542', Name: 'Organiser, sales' },
  { Id: 6772, Code: '3542', Name: 'Planner, sales' },
  { Id: 6773, Code: '3542', Name: 'Representative, agricultural' },
  { Id: 6774, Code: '3542', Name: 'Representative, architectural' },
  { Id: 6775, Code: '3542', Name: 'Representative, company' },
  { Id: 6776, Code: '3542', Name: 'Representative, educational' },
  { Id: 6777, Code: '3542', Name: 'Representative, electricity supplier' },
  { Id: 6778, Code: '3542', Name: 'Representative, gas supplier' },
  { Id: 6779, Code: '3542', Name: 'Representative, heating' },
  { Id: 6780, Code: '3542', Name: 'Representative, liaison' },
  { Id: 6781, Code: '3542', Name: 'Representative, manufacturing' },
  { Id: 6782, Code: '3542', Name: 'Representative, medical' },
  { Id: 6783, Code: '3542', Name: "Representative, publisher's" },
  { Id: 6784, Code: '3542', Name: 'Representative, sales' },
  { Id: 6785, Code: '3542', Name: 'Representative, sales, technical' },
  { Id: 6786, Code: '3542', Name: 'Representative, technical' },
  { Id: 6787, Code: '3542', Name: 'Representative, wholesale trade' },
  { Id: 6788, Code: '3542', Name: 'Salesman, cattle' },
  { Id: 6789, Code: '3542', Name: 'Salesman, commercial' },
  { Id: 6790, Code: '3542', Name: 'Salesman, commission' },
  { Id: 6791, Code: '3542', Name: 'Salesman, computer' },
  { Id: 6792, Code: '3542', Name: 'Salesman, delivery' },
  { Id: 6793, Code: '3542', Name: 'Salesman, DOUBLE GLAZING' },
  { Id: 6794, Code: '3542', Name: 'Salesman, export' },
  { Id: 6795, Code: '3542', Name: 'Salesman, fish, wholesale trade' },
  { Id: 6796, Code: '3542', Name: 'Salesman, glazing, double' },
  { Id: 6797, Code: '3542', Name: "Salesman, manufacturers' agents" },
  { Id: 6798, Code: '3542', Name: 'Salesman, manufacturing' },
  { Id: 6799, Code: '3542', Name: 'Salesman, meat, wholesale trade' },
  { Id: 6800, Code: '3542', Name: 'Salesman, outside' },
  { Id: 6801, Code: '3542', Name: 'Salesman, tea' },
  { Id: 6802, Code: '3542', Name: 'Salesman, technical' },
  { Id: 6803, Code: '3542', Name: 'Salesman, travelling' },
  { Id: 6804, Code: '3542', Name: 'Salesman, tyre' },
  { Id: 6805, Code: '3542', Name: 'Salesman, van, manufacturing' },
  { Id: 6806, Code: '3542', Name: 'Salesman, van, wholesale trade' },
  { Id: 6807, Code: '3542', Name: 'Salesman, water, mineral' },
  { Id: 6808, Code: '3542', Name: 'Salesman, wholesale trade' },
  { Id: 6809, Code: '3542', Name: 'Salesman, wool, scrap merchants, breakers' },
  { Id: 6810, Code: '3542', Name: 'Salesman-mechanic' },
  { Id: 6811, Code: '3542', Name: 'Specialist, animal feeds mfr' },
  { Id: 6812, Code: '3542', Name: 'Specialist, computer, SALES' },
  { Id: 6813, Code: '3542', Name: 'Specialist, product' },
  { Id: 6814, Code: '3542', Name: 'Specialist, products' },
  { Id: 6815, Code: '3542', Name: 'Specialist, sales' },
  { Id: 6816, Code: '3542', Name: 'Superintendent, sales' },
  { Id: 6817, Code: '3542', Name: 'Superintendent, SALES FORCE' },
  { Id: 6818, Code: '3542', Name: 'Supervisor, area, retail trade' },
  { Id: 6819, Code: '3542', Name: 'Supervisor, sales' },
  { Id: 6820, Code: '3542', Name: 'Trader, grain' },
  { Id: 6821, Code: '3542', Name: 'Traveller' },
  { Id: 6822, Code: '3542', Name: 'Traveller, commercial' },
  { Id: 6823, Code: '3543', Name: 'Advertiser' },
  { Id: 6824, Code: '3543', Name: 'Adviser, marketing' },
  { Id: 6825, Code: '3543', Name: 'Agent, advertising' },
  { Id: 6826, Code: '3543', Name: 'Agent, leasing, CAR HIRE' },
  { Id: 6827, Code: '3543', Name: 'Agent, marketing' },
  { Id: 6828, Code: '3543', Name: 'Analyst, chain, supply' },
  { Id: 6829, Code: '3543', Name: 'Analyst, MARKET RESEARCH' },
  { Id: 6830, Code: '3543', Name: 'Analyst, marketing' },
  { Id: 6831, Code: '3543', Name: 'Analyst, marketing, digital' },
  { Id: 6832, Code: '3543', Name: 'Analyst, media' },
  { Id: 6833, Code: '3543', Name: 'Analyst, media, social' },
  { Id: 6834, Code: '3543', Name: 'Analyst, research, market' },
  { Id: 6835, Code: '3543', Name: 'Analyst, support, sales' },
  { Id: 6836, Code: '3543', Name: 'Assistant, development, business' },
  { Id: 6837, Code: '3543', Name: 'Assistant, marketing, digital' },
  { Id: 6838, Code: '3543', Name: 'Assistant, marketing, online' },
  { Id: 6839, Code: '3543', Name: 'Assistant, marketing, sales' },
  { Id: 6840, Code: '3543', Name: 'Assistant, media, digital' },
  { Id: 6841, Code: '3543', Name: 'Assistant, media, social' },
  { Id: 6842, Code: '3543', Name: 'Assistant, sales and marketing' },
  { Id: 6843, Code: '3543', Name: 'Associate, marketing' },
  { Id: 6844, Code: '3543', Name: 'Campaigner' },
  { Id: 6845, Code: '3543', Name: 'Consultant, advertising' },
  { Id: 6846, Code: '3543', Name: 'Consultant, client, MARKET RESEARCH' },
  { Id: 6847, Code: '3543', Name: 'Consultant, fundraising' },
  { Id: 6848, Code: '3543', Name: 'Consultant, marketing' },
  { Id: 6849, Code: '3543', Name: 'Consultant, marketing, digital' },
  { Id: 6850, Code: '3543', Name: 'Consultant, marketing, internet' },
  { Id: 6851, Code: '3543', Name: 'Consultant, research, market' },
  { Id: 6852, Code: '3543', Name: 'Consultant, telemarketing' },
  { Id: 6853, Code: '3543', Name: 'Consultant, tourism' },
  { Id: 6854, Code: '3543', Name: 'Contractor, advertisement' },
  { Id: 6855, Code: '3543', Name: 'Contractor, advertising' },
  { Id: 6856, Code: '3543', Name: 'Controller, information, market' },
  { Id: 6857, Code: '3543', Name: 'Controller, marketing' },
  { Id: 6858, Code: '3543', Name: 'Controller, merchandise' },
  { Id: 6859, Code: '3543', Name: 'Controller, promotions' },
  { Id: 6860, Code: '3543', Name: 'Coordinator, account, advertising' },
  { Id: 6861, Code: '3543', Name: 'Coordinator, appeals' },
  { Id: 6862, Code: '3543', Name: 'Coordinator, digital' },
  { Id: 6863, Code: '3543', Name: 'Coordinator, marketing' },
  { Id: 6864, Code: '3543', Name: 'Coordinator, marketing and sales' },
  { Id: 6865, Code: '3543', Name: 'Coordinator, marketing, digital' },
  { Id: 6866, Code: '3543', Name: 'Coordinator, national, appeals, telephone' },
  { Id: 6867, Code: '3543', Name: 'Coordinator, promotion' },
  { Id: 6868, Code: '3543', Name: 'Coordinator, promotions' },
  { Id: 6869, Code: '3543', Name: 'Copywriter, creative, advertising' },
  { Id: 6870, Code: '3543', Name: 'Designer, service, MARKETING' },
  { Id: 6871, Code: '3543', Name: 'Developer, product, MARKETING' },
  { Id: 6872, Code: '3543', Name: 'Executive, account, advertising' },
  { Id: 6873, Code: '3543', Name: 'Executive, account, digital, MARKETING' },
  { Id: 6874, Code: '3543', Name: 'Executive, account, MARKETING' },
  { Id: 6875, Code: '3543', Name: 'Executive, accounts, advertising' },
  { Id: 6876, Code: '3543', Name: 'Executive, advertising' },
  { Id: 6877, Code: '3543', Name: 'Executive, client' },
  { Id: 6878, Code: '3543', Name: 'Executive, commercial' },
  { Id: 6879, Code: '3543', Name: 'Executive, development, business' },
  { Id: 6880, Code: '3543', Name: 'Executive, insights, consumer' },
  { Id: 6881, Code: '3543', Name: 'Executive, liaison, client' },
  { Id: 6882, Code: '3543', Name: 'Executive, marketing' },
  { Id: 6883, Code: '3543', Name: 'Executive, marketing, content' },
  { Id: 6884, Code: '3543', Name: 'Executive, marketing, digital' },
  { Id: 6885, Code: '3543', Name: 'Executive, marketing, online' },
  { Id: 6886, Code: '3543', Name: 'Executive, media' },
  { Id: 6887, Code: '3543', Name: 'Executive, media, digital' },
  { Id: 6888, Code: '3543', Name: 'Executive, project, advertising' },
  { Id: 6889, Code: '3543', Name: 'Executive, promotion, sales' },
  { Id: 6890, Code: '3543', Name: 'Executive, promotions' },
  { Id: 6891, Code: '3543', Name: 'Executive, relationship, client' },
  { Id: 6892, Code: '3543', Name: 'Executive, research, market' },
  { Id: 6893, Code: '3543', Name: 'Executive, research, market research' },
  { Id: 6894, Code: '3543', Name: 'Fundraiser' },
  { Id: 6895, Code: '3543', Name: 'Hacker, growth, MARKETING' },
  { Id: 6896, Code: '3543', Name: 'Handler, account' },
  { Id: 6897, Code: '3543', Name: 'Marketer, digital' },
  { Id: 6898, Code: '3543', Name: 'Marketer, internet' },
  { Id: 6899, Code: '3543', Name: 'Marketer, music, digital' },
  { Id: 6900, Code: '3543', Name: 'Marketer, network' },
  { Id: 6901, Code: '3543', Name: 'Marketer, online' },
  { Id: 6902, Code: '3543', Name: 'Officer, appeal' },
  { Id: 6903, Code: '3543', Name: 'Officer, appeals' },
  { Id: 6904, Code: '3543', Name: 'Officer, commercial, railways' },
  { Id: 6905, Code: '3543', Name: 'Officer, contract' },
  { Id: 6906, Code: '3543', Name: 'Officer, contracts' },
  {
    Id: 6907,
    Code: '3543',
    Name: 'Officer, development, donor, CHARITABLE ORGANISATION'
  },
  { Id: 6908, Code: '3543', Name: 'Officer, development, marketing' },
  { Id: 6909, Code: '3543', Name: 'Officer, development, tourism' },
  { Id: 6910, Code: '3543', Name: 'Officer, development, tourist' },
  { Id: 6911, Code: '3543', Name: 'Officer, fundraising' },
  { Id: 6912, Code: '3543', Name: 'Officer, marketing' },
  { Id: 6913, Code: '3543', Name: 'Officer, marketing, digital' },
  { Id: 6914, Code: '3543', Name: 'Officer, promotion, sales' },
  { Id: 6915, Code: '3543', Name: 'Officer, research, market' },
  { Id: 6916, Code: '3543', Name: 'Organiser, appeals' },
  { Id: 6917, Code: '3543', Name: 'Planner, account, advertising' },
  { Id: 6918, Code: '3543', Name: 'Planner, media' },
  { Id: 6919, Code: '3543', Name: 'Raiser, fund' },
  { Id: 6920, Code: '3543', Name: 'Representative, account, advertising' },
  { Id: 6921, Code: '3543', Name: 'Researcher, market' },
  { Id: 6922, Code: '3543', Name: 'Specialist, marketing' },
  { Id: 6923, Code: '3543', Name: 'Specialist, product, MARKETING' },
  { Id: 6924, Code: '3543', Name: 'Specialist, products, MARKETING' },
  { Id: 6925, Code: '3543', Name: 'Storyteller, brand' },
  { Id: 6926, Code: '3543', Name: 'Storyteller, MARKETING' },
  { Id: 6927, Code: '3543', Name: 'Strategist, brand' },
  { Id: 6928, Code: '3543', Name: 'Strategist, marketing' },
  { Id: 6929, Code: '3543', Name: 'Superintendent, marketing' },
  { Id: 6930, Code: '3543', Name: 'Supervisor, account, advertising' },
  { Id: 6931, Code: '3543', Name: 'Supervisor, development, sales' },
  { Id: 6932, Code: '3543', Name: 'Supervisor, research, market' },
  { Id: 6933, Code: '3543', Name: 'Writer, advertisement' },
  { Id: 6934, Code: '3543', Name: 'Writer, copy, creative, advertising' },
  { Id: 6935, Code: '3543', Name: 'Writer, publicity' },
  { Id: 6936, Code: '3544', Name: 'Agent and valuer, land' },
  { Id: 6937, Code: '3544', Name: 'Agent, AGRICULTURAL ESTATE' },
  { Id: 6938, Code: '3544', Name: 'Agent, estate' },
  { Id: 6939, Code: '3544', Name: 'Agent, house' },
  { Id: 6940, Code: '3544', Name: 'Agent, land' },
  { Id: 6941, Code: '3544', Name: 'Agent, land and estate' },
  { Id: 6942, Code: '3544', Name: 'Agent, letting' },
  { Id: 6943, Code: '3544', Name: 'Agent, property' },
  { Id: 6944, Code: '3544', Name: 'Agent, relocation' },
  { Id: 6945, Code: '3544', Name: 'Agent, site, AGRICULTURAL ESTATE' },
  { Id: 6946, Code: '3544', Name: 'Agent, transfer, business' },
  { Id: 6947, Code: '3544', Name: 'Agent, viewing' },
  { Id: 6948, Code: '3544', Name: "Assistant, agent's, estate" },
  { Id: 6949, Code: '3544', Name: 'Assistant, sales, estate agents' },
  { Id: 6950, Code: '3544', Name: 'Auctioneer' },
  { Id: 6951, Code: '3544', Name: 'Auctioneer and valuer' },
  { Id: 6952, Code: '3544', Name: 'Consultant, letting' },
  { Id: 6953, Code: '3544', Name: 'Consultant, property' },
  { Id: 6954, Code: '3544', Name: 'Consultant, relocation' },
  { Id: 6955, Code: '3544', Name: 'Consultant, sales, estate agents' },
  { Id: 6956, Code: '3544', Name: 'Inspector, property' },
  { Id: 6957, Code: '3544', Name: 'Negotiator, estate agents' },
  { Id: 6958, Code: '3544', Name: 'Negotiator, lettings' },
  { Id: 6959, Code: '3544', Name: 'Negotiator, sales, estate agents' },
  { Id: 6960, Code: '3545', Name: 'Controller, account, national' },
  { Id: 6961, Code: '3545', Name: 'Manager, account, digital' },
  { Id: 6962, Code: '3545', Name: 'Manager, account, MARKETING' },
  { Id: 6963, Code: '3545', Name: 'Manager, account, national' },
  { Id: 6964, Code: '3545', Name: 'Manager, account, sales' },
  { Id: 6965, Code: '3545', Name: 'Manager, account, telesales' },
  { Id: 6966, Code: '3545', Name: 'Manager, agents, commission' },
  { Id: 6967, Code: '3545', Name: 'Manager, area, market research' },
  { Id: 6968, Code: '3545', Name: 'Manager, area, POOLS PROMOTERS' },
  { Id: 6969, Code: '3545', Name: 'Manager, area, sales' },
  { Id: 6970, Code: '3545', Name: 'Manager, area, SALES FORCE' },
  { Id: 6971, Code: '3545', Name: 'Manager, brand' },
  { Id: 6972, Code: '3545', Name: 'Manager, category, MARKETING' },
  { Id: 6973, Code: '3545', Name: 'Manager, channel' },
  { Id: 6974, Code: '3545', Name: 'Manager, client, MARKETING' },
  { Id: 6975, Code: '3545', Name: 'Manager, commercial' },
  { Id: 6976, Code: '3545', Name: 'Manager, commission agents' },
  { Id: 6977, Code: '3545', Name: 'Manager, communications' },
  { Id: 6978, Code: '3545', Name: 'Manager, community, MARKETING' },
  { Id: 6979, Code: '3545', Name: 'Manager, contract, MARKETING' },
  { Id: 6980, Code: '3545', Name: 'Manager, contracts' },
  { Id: 6981, Code: '3545', Name: 'Manager, development, business' },
  { Id: 6982, Code: '3545', Name: 'Manager, development, corporate' },
  { Id: 6983, Code: '3545', Name: 'Manager, development, market' },
  { Id: 6984, Code: '3545', Name: 'Manager, development, marketing' },
  { Id: 6985, Code: '3545', Name: 'Manager, development, product' },
  { Id: 6986, Code: '3545', Name: 'Manager, development, sales' },
  { Id: 6987, Code: '3545', Name: 'Manager, district, assurance company' },
  { Id: 6988, Code: '3545', Name: 'Manager, district, insurance' },
  { Id: 6989, Code: '3545', Name: 'Manager, e-commerce' },
  { Id: 6990, Code: '3545', Name: 'Manager, export' },
  { Id: 6991, Code: '3545', Name: 'Manager, export agency' },
  { Id: 6992, Code: '3545', Name: 'Manager, field' },
  { Id: 6993, Code: '3545', Name: 'Manager, force, sales' },
  { Id: 6994, Code: '3545', Name: 'Manager, import' },
  { Id: 6995, Code: '3545', Name: 'Manager, import agency' },
  { Id: 6996, Code: '3545', Name: 'Manager, label, music publishing' },
  { Id: 6997, Code: '3545', Name: 'Manager, licensing' },
  { Id: 6998, Code: '3545', Name: 'Manager, market research' },
  { Id: 6999, Code: '3545', Name: 'Manager, market, MARKETING' },
  { Id: 7000, Code: '3545', Name: 'Manager, marketing' },
  { Id: 7001, Code: '3545', Name: 'Manager, marketing, digital' },
  { Id: 7002, Code: '3545', Name: 'Manager, marketing, online' },
  { Id: 7003, Code: '3545', Name: 'Manager, marketing, product' },
  { Id: 7004, Code: '3545', Name: 'Manager, merchandise' },
  { Id: 7005, Code: '3545', Name: 'Manager, merchandising' },
  { Id: 7006, Code: '3545', Name: 'Manager, operations, commercial' },
  { Id: 7007, Code: '3545', Name: 'Manager, pay-per-click, MARKETING' },
  { Id: 7008, Code: '3545', Name: 'Manager, PPC, MARKETING' },
  { Id: 7009, Code: '3545', Name: 'Manager, pricing' },
  { Id: 7010, Code: '3545', Name: 'Manager, product' },
  { Id: 7011, Code: '3545', Name: 'Manager, product, SALES' },
  { Id: 7012, Code: '3545', Name: 'Manager, production, import agency' },
  { Id: 7013, Code: '3545', Name: 'Manager, production, MARKETING' },
  { Id: 7014, Code: '3545', Name: 'Manager, products' },
  { Id: 7015, Code: '3545', Name: 'Manager, promotion, music' },
  { Id: 7016, Code: '3545', Name: 'Manager, promotion, sales' },
  { Id: 7017, Code: '3545', Name: 'Manager, promotions, MARKETING' },
  { Id: 7018, Code: '3545', Name: 'Manager, regional, SALES FORCE' },
  { Id: 7019, Code: '3545', Name: 'Manager, revenue, onboard' },
  { Id: 7020, Code: '3545', Name: 'Manager, rights, publishing company' },
  { Id: 7021, Code: '3545', Name: 'Manager, sales' },
  { Id: 7022, Code: '3545', Name: 'Manager, sales and advertising' },
  { Id: 7023, Code: '3545', Name: 'Manager, sales and commercial' },
  { Id: 7024, Code: '3545', Name: 'Manager, sales and marketing' },
  { Id: 7025, Code: '3545', Name: 'Manager, sales and service' },
  { Id: 7026, Code: '3545', Name: 'Manager, sales, advertisement' },
  { Id: 7027, Code: '3545', Name: 'Manager, sales, advertising' },
  { Id: 7028, Code: '3545', Name: 'Manager, sales, area' },
  { Id: 7029, Code: '3545', Name: 'Manager, sales, district' },
  { Id: 7030, Code: '3545', Name: 'Manager, sales, export' },
  { Id: 7031, Code: '3545', Name: 'Manager, sales, field' },
  { Id: 7032, Code: '3545', Name: 'Manager, sales, regional' },
  { Id: 7033, Code: '3545', Name: 'Manager, sales, technical' },
  { Id: 7034, Code: '3545', Name: 'Manager, sales, telephone' },
  { Id: 7035, Code: '3545', Name: 'Manager, sales, territory' },
  { Id: 7036, Code: '3545', Name: 'Manager, service, client, MARKETING' },
  { Id: 7037, Code: '3545', Name: 'Manager, services, client, advertising' },
  { Id: 7038, Code: '3545', Name: 'Manager, services, client, MARKETING' },
  { Id: 7039, Code: '3545', Name: 'Manager, services, marketing' },
  { Id: 7040, Code: '3545', Name: 'Manager, ship brokers' },
  { Id: 7041, Code: '3545', Name: 'Manager, strategy, business' },
  { Id: 7042, Code: '3545', Name: 'Manager, support, marketing' },
  { Id: 7043, Code: '3545', Name: 'Manager, support, sales' },
  { Id: 7044, Code: '3545', Name: 'Manager, team, SALES FORCE' },
  { Id: 7045, Code: '3545', Name: 'Manager, telemarketing' },
  { Id: 7046, Code: '3545', Name: 'Manager, tele-marketing' },
  { Id: 7047, Code: '3545', Name: 'Manager, TELEPHONE SALES' },
  { Id: 7048, Code: '3545', Name: 'Manager, telesales' },
  { Id: 7049, Code: '3546', Name: 'Assistant, events' },
  { Id: 7050, Code: '3546', Name: 'Consultant, conference' },
  { Id: 7051, Code: '3546', Name: 'Consultant, exhibition' },
  { Id: 7052, Code: '3546', Name: 'Consultant, exhibitions' },
  { Id: 7053, Code: '3546', Name: 'Coordinator, banqueting' },
  { Id: 7054, Code: '3546', Name: 'Coordinator, call, conference' },
  { Id: 7055, Code: '3546', Name: 'Coordinator, conference' },
  { Id: 7056, Code: '3546', Name: 'Coordinator, events' },
  { Id: 7057, Code: '3546', Name: 'Coordinator, exhibition' },
  { Id: 7058, Code: '3546', Name: 'Coordinator, function' },
  { Id: 7059, Code: '3546', Name: 'Coordinator, seminars' },
  { Id: 7060, Code: '3546', Name: 'Coordinator, wedding' },
  { Id: 7061, Code: '3546', Name: 'Manager, banqueting' },
  { Id: 7062, Code: '3546', Name: 'Manager, centre, conference' },
  { Id: 7063, Code: '3546', Name: 'Manager, conference' },
  { Id: 7064, Code: '3546', Name: 'Manager, conference organisers' },
  { Id: 7065, Code: '3546', Name: 'Manager, corporate hospitality' },
  { Id: 7066, Code: '3546', Name: 'Manager, event' },
  { Id: 7067, Code: '3546', Name: 'Manager, events' },
  { Id: 7068, Code: '3546', Name: 'Manager, exhibition' },
  { Id: 7069, Code: '3546', Name: 'Manager, hospitality' },
  {
    Id: 7070,
    Code: '3546',
    Name: 'Manager, production, conference organisers'
  },
  {
    Id: 7071,
    Code: '3546',
    Name: 'Manager, production, corporate hospitality'
  },
  {
    Id: 7072,
    Code: '3546',
    Name: 'Manager, production, exhibition contracting'
  },
  { Id: 7073, Code: '3546', Name: 'Manager, seminar' },
  { Id: 7074, Code: '3546', Name: 'Officer, conference' },
  { Id: 7075, Code: '3546', Name: 'Officer, events' },
  { Id: 7076, Code: '3546', Name: 'Officer, exhibition' },
  { Id: 7077, Code: '3546', Name: 'Organiser, catering' },
  { Id: 7078, Code: '3546', Name: 'Organiser, conference' },
  { Id: 7079, Code: '3546', Name: 'Organiser, conference and exhibition' },
  { Id: 7080, Code: '3546', Name: 'Organiser, entertainment' },
  { Id: 7081, Code: '3546', Name: 'Organiser, entertainments' },
  { Id: 7082, Code: '3546', Name: 'Organiser, event' },
  { Id: 7083, Code: '3546', Name: 'Organiser, events' },
  { Id: 7084, Code: '3546', Name: 'Organiser, exhibition' },
  { Id: 7085, Code: '3546', Name: 'Organiser, exhibitions' },
  { Id: 7086, Code: '3546', Name: 'Organiser, festival' },
  { Id: 7087, Code: '3546', Name: 'Owner, exhibition contracting' },
  { Id: 7088, Code: '3546', Name: 'Planner, event' },
  { Id: 7089, Code: '3546', Name: 'Planner, events' },
  { Id: 7090, Code: '3546', Name: 'Planner, wedding' },
  { Id: 7091, Code: '3546', Name: 'Producer, conference' },
  { Id: 7092, Code: '3546', Name: 'Representative, conference' },
  { Id: 7093, Code: '3546', Name: 'Supervisor, banqueting' },
  { Id: 7094, Code: '3550', Name: 'Campaigner, environmental' },
  { Id: 7095, Code: '3550', Name: 'Inspector, waste, water company' },
  { Id: 7096, Code: '3550', Name: 'Officer, amenities' },
  { Id: 7097, Code: '3550', Name: 'Officer, estate, forestry' },
  { Id: 7098, Code: '3550', Name: 'Officer, estates, forestry' },
  { Id: 7099, Code: '3550', Name: 'Officer, park, chief' },
  { Id: 7100, Code: '3550', Name: 'Officer, park, country' },
  { Id: 7101, Code: '3550', Name: 'Officer, parks' },
  { Id: 7102, Code: '3550', Name: 'Officer, woodland' },
  { Id: 7103, Code: '3550', Name: 'Ranger' },
  { Id: 7104, Code: '3550', Name: 'Ranger, countryside' },
  { Id: 7105, Code: '3550', Name: 'Ranger, education' },
  { Id: 7106, Code: '3550', Name: 'Ranger, estate' },
  { Id: 7107, Code: '3550', Name: 'Ranger, park' },
  { Id: 7108, Code: '3550', Name: 'Ranger, park, national' },
  { Id: 7109, Code: '3550', Name: 'Ranger, recreation' },
  { Id: 7110, Code: '3550', Name: 'Technician, environmental' },
  { Id: 7111, Code: '3550', Name: 'Warden, countryside' },
  { Id: 7112, Code: '3550', Name: 'Warden, environmental' },
  { Id: 7113, Code: '3550', Name: 'Warden, estate' },
  { Id: 7114, Code: '3550', Name: 'Warden, park, national' },
  { Id: 7115, Code: '3550', Name: 'Warden, range' },
  { Id: 7116, Code: '3550', Name: 'Warden, reserve, nature' },
  { Id: 7117, Code: '3550', Name: 'Worker, conservation' },
  { Id: 7118, Code: '3561', Name: 'A3, Dept for International Development' },
  { Id: 7119, Code: '3561', Name: 'Administrator, hospital' },
  { Id: 7120, Code: '3561', Name: 'Administrator, hospital service' },
  { Id: 7121, Code: '3561', Name: 'AP(T), local government, GRADE 4,5' },
  { Id: 7122, Code: '3561', Name: 'Assistant, services, democratic' },
  { Id: 7123, Code: '3561', Name: 'B1, Dept for International Development' },
  { Id: 7124, Code: '3561', Name: 'B1, Northern Ireland Office' },
  { Id: 7125, Code: '3561', Name: 'B2, Cabinet Office' },
  { Id: 7126, Code: '3561', Name: 'B2, Northern Ireland Office' },
  { Id: 7127, Code: '3561', Name: 'B2, Scottish Government' },
  { Id: 7128, Code: '3561', Name: 'B3, Scottish Government' },
  { Id: 7129, Code: '3561', Name: 'Band 3, Health and Safety Executive' },
  { Id: 7130, Code: '3561', Name: 'Band 3A, Meteorological Office' },
  { Id: 7131, Code: '3561', Name: 'Band 3B, Meteorological Office' },
  { Id: 7132, Code: '3561', Name: 'Band 3C, Meteorological Office' },
  { Id: 7133, Code: '3561', Name: 'Band 4, Health and Safety Executive' },
  { Id: 7134, Code: '3561', Name: 'Band D, Ministry of Justice' },
  { Id: 7135, Code: '3561', Name: 'Band D, National Assembly for Wales' },
  { Id: 7136, Code: '3561', Name: 'Band E, Ministry of Justice' },
  { Id: 7137, Code: '3561', Name: 'Band E, National Assembly for Wales' },
  { Id: 7138, Code: '3561', Name: 'C1, Office for National Statistics' },
  { Id: 7139, Code: '3561', Name: 'C2, Jobcentre Plus' },
  { Id: 7140, Code: '3561', Name: 'C2, Office for National Statistics' },
  { Id: 7141, Code: '3561', Name: 'C3, Jobcentre Plus' },
  { Id: 7142, Code: '3561', Name: 'C3, Office for National Statistics' },
  { Id: 7143, Code: '3561', Name: 'C4, Jobcentre Plus' },
  { Id: 7144, Code: '3561', Name: 'C4, Office for National Statistics' },
  { Id: 7145, Code: '3561', Name: 'Cashier, chief, government' },
  { Id: 7146, Code: '3561', Name: 'Clerk, chief, government' },
  { Id: 7147, Code: '3561', Name: 'Clerk, principal, government' },
  { Id: 7148, Code: '3561', Name: 'Collector, chief, HM Revenue and Customs' },
  {
    Id: 7149,
    Code: '3561',
    Name: 'Collector, grade, higher, HM Revenue and Customs'
  },
  { Id: 7150, Code: '3561', Name: 'Collector, HM Revenue and Customs' },
  { Id: 7151, Code: '3561', Name: 'Collector, regional' },
  { Id: 7152, Code: '3561', Name: 'Collector, senior, government' },
  { Id: 7153, Code: '3561', Name: 'Controller, government' },
  { Id: 7154, Code: '3561', Name: 'Controller, regional, government' },
  { Id: 7155, Code: '3561', Name: 'Coordinator, experience, work' },
  { Id: 7156, Code: '3561', Name: 'Coordinator, project, local government' },
  { Id: 7157, Code: '3561', Name: 'Counsellor, government' },
  { Id: 7158, Code: '3561', Name: 'Executive, postal, PO, GRADE A' },
  { Id: 7159, Code: '3561', Name: 'Executive, postal, PO, GRADE B' },
  { Id: 7160, Code: '3561', Name: 'Grade 10, Foreign and Commonwealth Office' },
  { Id: 7161, Code: '3561', Name: 'Grade 8, Foreign and Commonwealth Office' },
  { Id: 7162, Code: '3561', Name: 'Grade 9, Foreign and Commonwealth Office' },
  { Id: 7163, Code: '3561', Name: 'Grade B, DCMS' },
  { Id: 7164, Code: '3561', Name: 'HEO, government' },
  { Id: 7165, Code: '3561', Name: 'Inspector, staff, government' },
  { Id: 7166, Code: '3561', Name: 'Leader, team, benefit, local government' },
  { Id: 7167, Code: '3561', Name: 'Leader, team, local government' },
  { Id: 7168, Code: '3561', Name: 'LOI, Jobcentre Plus' },
  { Id: 7169, Code: '3561', Name: 'Manager, amenity, area' },
  { Id: 7170, Code: '3561', Name: 'Manager, bed' },
  { Id: 7171, Code: '3561', Name: 'Manager, branch, government' },
  { Id: 7172, Code: '3561', Name: 'Manager, elections' },
  { Id: 7173, Code: '3561', Name: 'Manager, Jobcentre Plus' },
  {
    Id: 7174,
    Code: '3561',
    Name: 'Manager, office, insurance, Jobcentre Plus'
  },
  { Id: 7175, Code: '3561', Name: 'Manager, protection, environmental' },
  { Id: 7176, Code: '3561', Name: 'Manager, services, electoral' },
  { Id: 7177, Code: '3561', Name: 'Manager, tax, council' },
  { Id: 7178, Code: '3561', Name: 'Manager, team, HM Revenue and Customs' },
  { Id: 7179, Code: '3561', Name: 'Officer, agricultural, government' },
  { Id: 7180, Code: '3561', Name: 'Officer, appointments, government' },
  { Id: 7181, Code: '3561', Name: 'Officer, area, government' },
  { Id: 7182, Code: '3561', Name: 'Officer, assurance, VAT, government' },
  { Id: 7183, Code: '3561', Name: 'Officer, awareness, waste' },
  { Id: 7184, Code: '3561', Name: 'Officer, chief, community health' },
  { Id: 7185, Code: '3561', Name: 'Officer, clerical, higher, government' },
  { Id: 7186, Code: '3561', Name: 'Officer, cohesion, community' },
  {
    Id: 7187,
    Code: '3561',
    Name: 'Officer, control, senior, HM Revenue and Customs'
  },
  { Id: 7188, Code: '3561', Name: 'Officer, determination, local government' },
  {
    Id: 7189,
    Code: '3561',
    Name: 'Officer, development, policy, local government'
  },
  { Id: 7190, Code: '3561', Name: 'Officer, division, Ordnance Survey' },
  { Id: 7191, Code: '3561', Name: 'Officer, equipment, local government' },
  { Id: 7192, Code: '3561', Name: 'Officer, establishment, government' },
  { Id: 7193, Code: '3561', Name: 'Officer, European, local government' },
  {
    Id: 7194,
    Code: '3561',
    Name: 'Officer, executive, higher (D), government, FAST STREAM'
  },
  { Id: 7195, Code: '3561', Name: 'Officer, executive, higher, government' },
  { Id: 7196, Code: '3561', Name: 'Officer, executive, higher, PO' },
  { Id: 7197, Code: '3561', Name: 'Officer, executive, senior, government' },
  { Id: 7198, Code: '3561', Name: 'Officer, executive, senior, PO' },
  { Id: 7199, Code: '3561', Name: 'Officer, finance, regional' },
  { Id: 7200, Code: '3561', Name: 'Officer, freedom of information' },
  { Id: 7201, Code: '3561', Name: 'Officer, group, senior, MOD' },
  { Id: 7202, Code: '3561', Name: 'Officer, higher, HM Revenue and Customs' },
  { Id: 7203, Code: '3561', Name: 'Officer, information' },
  { Id: 7204, Code: '3561', Name: 'Officer, local I, Jobcentre Plus' },
  { Id: 7205, Code: '3561', Name: 'Officer, management, waste' },
  { Id: 7206, Code: '3561', Name: 'Officer, monitoring, environmental' },
  { Id: 7207, Code: '3561', Name: 'Officer, PALS' },
  {
    Id: 7208,
    Code: '3561',
    Name: 'Officer, patient advice and liaison service'
  },
  { Id: 7209, Code: '3561', Name: 'Officer, presenting, government' },
  { Id: 7210, Code: '3561', Name: 'Officer, principal, local government' },
  {
    Id: 7211,
    Code: '3561',
    Name: 'Officer, project, regeneration, local government'
  },
  { Id: 7212, Code: '3561', Name: 'Officer, recycling, local government' },
  { Id: 7213, Code: '3561', Name: 'Officer, regeneration, local government' },
  { Id: 7214, Code: '3561', Name: 'Officer, regional, government' },
  {
    Id: 7215,
    Code: '3561',
    Name: 'Officer, revenue, principal, local government'
  },
  {
    Id: 7216,
    Code: '3561',
    Name: 'Officer, revenue, senior, local government'
  },
  { Id: 7217, Code: '3561', Name: 'Officer, securities, government' },
  {
    Id: 7218,
    Code: '3561',
    Name: 'Officer, service, foreign, GRADE A1-A8, B1-B4'
  },
  { Id: 7219, Code: '3561', Name: 'Officer, services, democratic' },
  { Id: 7220, Code: '3561', Name: 'Officer, staff, government' },
  { Id: 7221, Code: '3561', Name: 'Officer, substitution, grade I, MOD' },
  { Id: 7222, Code: '3561', Name: 'Officer, support, budget' },
  { Id: 7223, Code: '3561', Name: 'Officer, value, best, local government' },
  { Id: 7224, Code: '3561', Name: 'Officer, VAT, government' },
  { Id: 7225, Code: '3561', Name: 'Officer-in-charge, HM Revenue and Customs' },
  { Id: 7226, Code: '3561', Name: 'Payband 3, Dept of Health' },
  { Id: 7227, Code: '3561', Name: 'PO, nos, local government' },
  { Id: 7228, Code: '3561', Name: 'RE1, Land Registry' },
  { Id: 7229, Code: '3561', Name: 'SEO, government' },
  { Id: 7230, Code: '3561', Name: 'Servant, civil, HEO, SEO' },
  { Id: 7231, Code: '3561', Name: 'SO, nos, local government' },
  { Id: 7232, Code: '3561', Name: 'Superintendent of Stamping, 1ST CLASS' },
  { Id: 7233, Code: '3561', Name: 'Superintendent, grain, DEFRA' },
  {
    Id: 7234,
    Code: '3561',
    Name: 'Superintendent, highways, local government'
  },
  { Id: 7235, Code: '3561', Name: 'Superintendent, Land Registry' },
  { Id: 7236, Code: '3561', Name: 'Superintendent, rents, local government' },
  { Id: 7237, Code: '3561', Name: 'Supervisor, Jobcentre Plus' },
  { Id: 7238, Code: '3561', Name: 'Supervisor, local government' },
  { Id: 7239, Code: '3562', Name: 'Adviser, diversity and equality' },
  { Id: 7240, Code: '3562', Name: 'Adviser, employment' },
  { Id: 7241, Code: '3562', Name: 'Adviser, equality' },
  { Id: 7242, Code: '3562', Name: 'Adviser, personnel' },
  { Id: 7243, Code: '3562', Name: 'Adviser, policy, equalities' },
  { Id: 7244, Code: '3562', Name: 'Adviser, recruitment' },
  { Id: 7245, Code: '3562', Name: 'Adviser, relations, industrial' },
  { Id: 7246, Code: '3562', Name: 'Adviser, resources, human' },
  { Id: 7247, Code: '3562', Name: 'Analyst, evaluation, job' },
  { Id: 7248, Code: '3562', Name: 'Analyst, HR' },
  { Id: 7249, Code: '3562', Name: 'Analyst, personnel' },
  { Id: 7250, Code: '3562', Name: 'Analyst, resources, human' },
  { Id: 7251, Code: '3562', Name: 'Analyst, reward' },
  { Id: 7252, Code: '3562', Name: 'Assistant, evaluation, job' },
  { Id: 7253, Code: '3562', Name: 'Assistant, personnel' },
  { Id: 7254, Code: '3562', Name: 'Assistant, relations, employee' },
  { Id: 7255, Code: '3562', Name: 'Assistant, resource, human' },
  { Id: 7256, Code: '3562', Name: 'Assistant, resources, human' },
  { Id: 7257, Code: '3562', Name: 'Assistant, staff, railways' },
  { Id: 7258, Code: '3562', Name: 'Conciliator, ACAS' },
  { Id: 7259, Code: '3562', Name: 'Consultant, acquisition, talent' },
  { Id: 7260, Code: '3562', Name: 'Consultant, attraction, talent' },
  { Id: 7261, Code: '3562', Name: 'Consultant, employment' },
  { Id: 7262, Code: '3562', Name: 'Consultant, personnel' },
  { Id: 7263, Code: '3562', Name: 'Consultant, recruitment' },
  { Id: 7264, Code: '3562', Name: 'Consultant, relations, employer' },
  { Id: 7265, Code: '3562', Name: 'Consultant, resource, human' },
  { Id: 7266, Code: '3562', Name: 'Consultant, resources, human' },
  { Id: 7267, Code: '3562', Name: 'Controller, staff' },
  { Id: 7268, Code: '3562', Name: 'Coordinator, recruitment' },
  { Id: 7269, Code: '3562', Name: 'Executive, development, client' },
  { Id: 7270, Code: '3562', Name: 'Executive, personnel' },
  { Id: 7271, Code: '3562', Name: 'Executive, recruitment' },
  { Id: 7272, Code: '3562', Name: 'Generalist, resources, human' },
  { Id: 7273, Code: '3562', Name: 'Headhunter' },
  { Id: 7274, Code: '3562', Name: 'Hunter, head' },
  { Id: 7275, Code: '3562', Name: 'Inspector, employment, railway' },
  { Id: 7276, Code: '3562', Name: 'Manager, account, recruitment agency' },
  { Id: 7277, Code: '3562', Name: 'Officer, conciliation' },
  { Id: 7278, Code: '3562', Name: 'Officer, consultation, coal mine' },
  { Id: 7279, Code: '3562', Name: 'Officer, development, career' },
  { Id: 7280, Code: '3562', Name: 'Officer, employment' },
  { Id: 7281, Code: '3562', Name: 'Officer, equalities' },
  { Id: 7282, Code: '3562', Name: 'Officer, equality' },
  { Id: 7283, Code: '3562', Name: 'Officer, establishment' },
  { Id: 7284, Code: '3562', Name: 'Officer, labour' },
  { Id: 7285, Code: '3562', Name: 'Officer, liaison, labour' },
  { Id: 7286, Code: '3562', Name: 'Officer, management, labour' },
  { Id: 7287, Code: '3562', Name: 'Officer, manpower, coal mine' },
  { Id: 7288, Code: '3562', Name: 'Officer, opportunities, equal' },
  { Id: 7289, Code: '3562', Name: 'Officer, personnel' },
  { Id: 7290, Code: '3562', Name: 'Officer, recruiting' },
  { Id: 7291, Code: '3562', Name: 'Officer, recruitment' },
  { Id: 7292, Code: '3562', Name: 'Officer, relations, employee' },
  { Id: 7293, Code: '3562', Name: 'Officer, relations, industrial' },
  { Id: 7294, Code: '3562', Name: 'Officer, relations, labour' },
  { Id: 7295, Code: '3562', Name: 'Officer, resource, human' },
  { Id: 7296, Code: '3562', Name: 'Officer, resources, human' },
  { Id: 7297, Code: '3562', Name: 'Officer, services, personnel' },
  { Id: 7298, Code: '3562', Name: 'Officer, staff, gas supplier' },
  { Id: 7299, Code: '3562', Name: 'Officer, staff, local government' },
  { Id: 7300, Code: '3562', Name: 'Officer, staff, railways' },
  { Id: 7301, Code: '3562', Name: 'Officer, staffing' },
  { Id: 7302, Code: '3562', Name: 'Officer, vetting, positive' },
  { Id: 7303, Code: '3562', Name: 'Recruiter, staff' },
  { Id: 7304, Code: '3562', Name: 'Researcher, recruitment' },
  { Id: 7305, Code: '3562', Name: 'Searcher, job' },
  { Id: 7306, Code: '3562', Name: 'Specialist, attraction, talent' },
  { Id: 7307, Code: '3562', Name: 'Superintendent, staff' },
  { Id: 7308, Code: '3563', Name: 'Adviser, development, employee' },
  { Id: 7309, Code: '3563', Name: 'Adviser, development, training' },
  { Id: 7310, Code: '3563', Name: 'Adviser, training' },
  { Id: 7311, Code: '3563', Name: 'Advocate, learning' },
  { Id: 7312, Code: '3563', Name: 'Agent, training' },
  { Id: 7313, Code: '3563', Name: 'Analyst, training' },
  { Id: 7314, Code: '3563', Name: 'Assessor, competency' },
  { Id: 7315, Code: '3563', Name: 'Assessor, driving' },
  { Id: 7316, Code: '3563', Name: 'Assessor, NVQ' },
  { Id: 7317, Code: '3563', Name: 'Assessor, training' },
  { Id: 7318, Code: '3563', Name: 'Associate, training' },
  { Id: 7319, Code: '3563', Name: 'Coach, executive' },
  { Id: 7320, Code: '3563', Name: 'Coach, technical' },
  { Id: 7321, Code: '3563', Name: 'Consultant, development, training' },
  { Id: 7322, Code: '3563', Name: 'Consultant, scheme, TRAINING' },
  { Id: 7323, Code: '3563', Name: 'Consultant, training' },
  { Id: 7324, Code: '3563', Name: 'Coordinator, course, training' },
  { Id: 7325, Code: '3563', Name: 'Coordinator, e-learning' },
  { Id: 7326, Code: '3563', Name: 'Coordinator, TEC' },
  { Id: 7327, Code: '3563', Name: 'Coordinator, training' },
  { Id: 7328, Code: '3563', Name: 'Instructor, apprentice' },
  { Id: 7329, Code: '3563', Name: 'Instructor, apprentice school' },
  { Id: 7330, Code: '3563', Name: 'Instructor, civilian, government' },
  { Id: 7331, Code: '3563', Name: 'Instructor, coal mine' },
  { Id: 7332, Code: '3563', Name: 'Instructor, computer' },
  { Id: 7333, Code: '3563', Name: 'Instructor, craft' },
  {
    Id: 7334,
    Code: '3563',
    Name: 'Instructor, craftsman, apprentice, coal mine'
  },
  { Id: 7335, Code: '3563', Name: 'Instructor, cycling' },
  { Id: 7336, Code: '3563', Name: 'Instructor, handicraft' },
  { Id: 7337, Code: '3563', Name: 'Instructor, handicrafts' },
  {
    Id: 7338,
    Code: '3563',
    Name: 'Instructor, learning disabilities adult training'
  },
  { Id: 7339, Code: '3563', Name: 'Instructor, manual' },
  { Id: 7340, Code: '3563', Name: 'Instructor, prison' },
  { Id: 7341, Code: '3563', Name: 'Instructor, staff, school' },
  { Id: 7342, Code: '3563', Name: 'Instructor, supervising' },
  { Id: 7343, Code: '3563', Name: 'Instructor, technical' },
  { Id: 7344, Code: '3563', Name: 'Instructor, trainee' },
  { Id: 7345, Code: '3563', Name: 'Instructor, training' },
  { Id: 7346, Code: '3563', Name: 'Instructor, training establishment' },
  { Id: 7347, Code: '3563', Name: 'Instructor, training provider' },
  { Id: 7348, Code: '3563', Name: 'Lecturer, INDUSTRIAL TRAINING' },
  { Id: 7349, Code: '3563', Name: 'Manager, delivery, training' },
  { Id: 7350, Code: '3563', Name: 'Manager, e-learning' },
  { Id: 7351, Code: '3563', Name: 'Manager, learning, digital' },
  { Id: 7352, Code: '3563', Name: 'Manager, NVQ' },
  { Id: 7353, Code: '3563', Name: 'Manager, training' },
  { Id: 7354, Code: '3563', Name: 'Manager, training and development' },
  { Id: 7355, Code: '3563', Name: 'Officer, development, staff' },
  { Id: 7356, Code: '3563', Name: 'Officer, development, training' },
  { Id: 7357, Code: '3563', Name: 'Officer, development, youth' },
  { Id: 7358, Code: '3563', Name: 'Officer, instructional' },
  {
    Id: 7359,
    Code: '3563',
    Name: 'Officer, safety and training, coal mine: above ground'
  },
  { Id: 7360, Code: '3563', Name: 'Officer, service, training' },
  {
    Id: 7361,
    Code: '3563',
    Name: 'Officer, support, web, further, higher education'
  },
  { Id: 7362, Code: '3563', Name: 'Officer, training' },
  { Id: 7363, Code: '3563', Name: 'Officer, training and compliance' },
  { Id: 7364, Code: '3563', Name: 'Officer, training and development' },
  { Id: 7365, Code: '3563', Name: 'Officer, training and education' },
  { Id: 7366, Code: '3563', Name: 'Officer, training, colliery' },
  { Id: 7367, Code: '3563', Name: 'Officer, training, sales' },
  { Id: 7368, Code: '3563', Name: 'Practitioner, NLP' },
  { Id: 7369, Code: '3563', Name: 'Provider, training' },
  { Id: 7370, Code: '3563', Name: 'Specialist, training' },
  { Id: 7371, Code: '3563', Name: 'Superintendent, APPRENTICESHIP' },
  { Id: 7372, Code: '3563', Name: 'Supervisor, apprenticeship' },
  { Id: 7373, Code: '3563', Name: 'Supervisor, trainee, coal mine' },
  { Id: 7374, Code: '3563', Name: 'Supervisor, training' },
  { Id: 7375, Code: '3563', Name: 'Supervisor-instructor, government' },
  { Id: 7376, Code: '3563', Name: 'Teacher, sales' },
  { Id: 7377, Code: '3563', Name: 'Trainer' },
  { Id: 7378, Code: '3563', Name: 'Trainer, computer' },
  { Id: 7379, Code: '3563', Name: 'Trainer, industrial' },
  { Id: 7380, Code: '3563', Name: 'Trainer, IT' },
  { Id: 7381, Code: '3563', Name: 'Trainer, software' },
  { Id: 7382, Code: '3563', Name: 'Trainer, staff' },
  { Id: 7383, Code: '3563', Name: 'Trainer, technical' },
  { Id: 7384, Code: '3563', Name: 'Trainer, technology, information' },
  { Id: 7385, Code: '3563', Name: 'Trainer-assessor' },
  { Id: 7386, Code: '3564', Name: 'Adviser, careers' },
  { Id: 7387, Code: '3564', Name: 'Adviser, personal, careers service' },
  { Id: 7388, Code: '3564', Name: 'Assistant, careers' },
  { Id: 7389, Code: '3564', Name: 'Broker, employment' },
  { Id: 7390, Code: '3564', Name: 'Coach, career' },
  { Id: 7391, Code: '3564', Name: 'Coach, employment' },
  { Id: 7392, Code: '3564', Name: 'Coach, job' },
  { Id: 7393, Code: '3564', Name: 'Consultant, careers' },
  {
    Id: 7394,
    Code: '3564',
    Name: 'Consultant, engagement, employer, EDUCATION'
  },
  { Id: 7395, Code: '3564', Name: 'Consultant, outplacement' },
  { Id: 7396, Code: '3564', Name: 'Coordinator, education, careers' },
  { Id: 7397, Code: '3564', Name: 'Coordinator, placement' },
  { Id: 7398, Code: '3564', Name: 'Counsellor, outplacement' },
  { Id: 7399, Code: '3564', Name: 'Counsellor, redundancy' },
  { Id: 7400, Code: '3564', Name: 'Officer, abroad, year' },
  { Id: 7401, Code: '3564', Name: 'Officer, advisory, careers' },
  { Id: 7402, Code: '3564', Name: 'Officer, careers' },
  { Id: 7403, Code: '3564', Name: 'Officer, employment, careers service' },
  { Id: 7404, Code: '3564', Name: 'Officer, employment, youth' },
  { Id: 7405, Code: '3564', Name: 'Officer, placement' },
  { Id: 7406, Code: '3564', Name: 'Supervisor, placement' },
  { Id: 7407, Code: '3564', Name: 'Teacher, careers' },
  { Id: 7408, Code: '3564', Name: 'Tutor, guidance, graduate' },
  {
    Id: 7409,
    Code: '3565',
    Name: 'Adviser, consumer, local government: trading standards'
  },
  { Id: 7410, Code: '3565', Name: 'Adviser, relations, race' },
  { Id: 7411, Code: '3565', Name: 'Bailiff, river' },
  { Id: 7412, Code: '3565', Name: 'Bailiff, water' },
  { Id: 7413, Code: '3565', Name: 'Controller, building, local government' },
  { Id: 7414, Code: '3565', Name: 'Examiner, driving' },
  { Id: 7415, Code: '3565', Name: 'Examiner, flight' },
  { Id: 7416, Code: '3565', Name: 'Examiner, traffic and driving, DETR' },
  { Id: 7417, Code: '3565', Name: 'Examiner, traffic, DETR' },
  { Id: 7418, Code: '3565', Name: 'Examiner, vehicle, DETR' },
  { Id: 7419, Code: '3565', Name: 'HMFI' },
  { Id: 7420, Code: '3565', Name: 'Inspector of accidents, Board of Trade' },
  { Id: 7421, Code: '3565', Name: 'Inspector of factories, government' },
  { Id: 7422, Code: '3565', Name: 'Inspector of fair trading' },
  { Id: 7423, Code: '3565', Name: 'Inspector of health and safety' },
  {
    Id: 7424,
    Code: '3565',
    Name: 'Inspector of mines, Dept for Business Innovation and Skills'
  },
  { Id: 7425, Code: '3565', Name: 'Inspector of postal services' },
  { Id: 7426, Code: '3565', Name: 'Inspector of shipping' },
  { Id: 7427, Code: '3565', Name: 'Inspector of special subjects' },
  { Id: 7428, Code: '3565', Name: 'Inspector of trading standards' },
  { Id: 7429, Code: '3565', Name: 'Inspector of weights and measures' },
  { Id: 7430, Code: '3565', Name: 'Inspector of works' },
  { Id: 7431, Code: '3565', Name: 'Inspector, agricultural' },
  { Id: 7432, Code: '3565', Name: 'Inspector, alkali' },
  { Id: 7433, Code: '3565', Name: 'Inspector, bank' },
  { Id: 7434, Code: '3565', Name: 'Inspector, banking' },
  { Id: 7435, Code: '3565', Name: 'Inspector, brewery' },
  { Id: 7436, Code: '3565', Name: 'Inspector, building' },
  { Id: 7437, Code: '3565', Name: 'Inspector, casino' },
  { Id: 7438, Code: '3565', Name: 'Inspector, chemical' },
  { Id: 7439, Code: '3565', Name: 'Inspector, chief, banking' },
  { Id: 7440, Code: '3565', Name: 'Inspector, chief, local government' },
  { Id: 7441, Code: '3565', Name: 'Inspector, chief, railways' },
  { Id: 7442, Code: '3565', Name: 'Inspector, chief, water company' },
  { Id: 7443, Code: '3565', Name: 'Inspector, coal mine: opencast' },
  { Id: 7444, Code: '3565', Name: 'Inspector, coal, coal mine: opencast' },
  { Id: 7445, Code: '3565', Name: 'Inspector, control, building' },
  {
    Id: 7446,
    Code: '3565',
    Name: 'Inspector, control, quality, river, water authority'
  },
  { Id: 7447, Code: '3565', Name: 'Inspector, crane' },
  { Id: 7448, Code: '3565', Name: 'Inspector, depot, chief, transport' },
  {
    Id: 7449,
    Code: '3565',
    Name: 'Inspector, Dept for Business Innovation and Skills'
  },
  { Id: 7450, Code: '3565', Name: 'Inspector, district, gas supplier' },
  { Id: 7451, Code: '3565', Name: 'Inspector, divisional, local government' },
  { Id: 7452, Code: '3565', Name: 'Inspector, divisional, transport' },
  { Id: 7453, Code: '3565', Name: 'Inspector, drug' },
  { Id: 7454, Code: '3565', Name: 'Inspector, effluent' },
  {
    Id: 7455,
    Code: '3565',
    Name: 'Inspector, electrical and mechanical, coal mine'
  },
  { Id: 7456, Code: '3565', Name: 'Inspector, electrical, coal mine' },
  { Id: 7457, Code: '3565', Name: 'Inspector, electrical, government' },
  { Id: 7458, Code: '3565', Name: 'Inspector, electricity supplier' },
  { Id: 7459, Code: '3565', Name: 'Inspector, Environment Agency' },
  { Id: 7460, Code: '3565', Name: 'Inspector, explosives' },
  { Id: 7461, Code: '3565', Name: 'Inspector, fire' },
  { Id: 7462, Code: '3565', Name: 'Inspector, fisheries, DEFRA' },
  { Id: 7463, Code: '3565', Name: 'Inspector, fisheries, Environment Agency' },
  { Id: 7464, Code: '3565', Name: 'Inspector, fishery' },
  { Id: 7465, Code: '3565', Name: 'Inspector, fitting, gas supplier' },
  { Id: 7466, Code: '3565', Name: 'Inspector, fitting, water company' },
  { Id: 7467, Code: '3565', Name: 'Inspector, fittings, gas supplier' },
  { Id: 7468, Code: '3565', Name: 'Inspector, fittings, water company' },
  { Id: 7469, Code: '3565', Name: 'Inspector, fuel, local government' },
  { Id: 7470, Code: '3565', Name: 'Inspector, gaming, gaming club' },
  { Id: 7471, Code: '3565', Name: 'Inspector, gas' },
  { Id: 7472, Code: '3565', Name: 'Inspector, gas supplier' },
  {
    Id: 7473,
    Code: '3565',
    Name: 'Inspector, government: Accident Investigation Branch'
  },
  { Id: 7474, Code: '3565', Name: 'Inspector, government: DETR' },
  {
    Id: 7475,
    Code: '3565',
    Name: 'Inspector, government: Maritime and Coastguard Agency'
  },
  {
    Id: 7476,
    Code: '3565',
    Name: 'Inspector, government: Planning Inspectorate'
  },
  {
    Id: 7477,
    Code: '3565',
    Name: 'Inspector, government: Railway Inspectorate'
  },
  { Id: 7478, Code: '3565', Name: 'Inspector, health and safety' },
  { Id: 7479, Code: '3565', Name: 'Inspector, health, animal' },
  { Id: 7480, Code: '3565', Name: 'Inspector, hotel' },
  { Id: 7481, Code: '3565', Name: 'Inspector, houses, public' },
  { Id: 7482, Code: '3565', Name: 'Inspector, housing' },
  { Id: 7483, Code: '3565', Name: 'Inspector, housing and planning' },
  { Id: 7484, Code: '3565', Name: 'Inspector, hygiene, meat' },
  { Id: 7485, Code: '3565', Name: 'Inspector, industrial, gas supplier' },
  {
    Id: 7486,
    Code: '3565',
    Name: 'Inspector, installation, electricity supplier'
  },
  { Id: 7487, Code: '3565', Name: 'Inspector, installation, gas supplier' },
  { Id: 7488, Code: '3565', Name: 'Inspector, insurance, national' },
  { Id: 7489, Code: '3565', Name: 'Inspector, lift, DETR' },
  { Id: 7490, Code: '3565', Name: 'Inspector, lighting, public' },
  { Id: 7491, Code: '3565', Name: 'Inspector, livestock, area' },
  { Id: 7492, Code: '3565', Name: 'Inspector, local government' },
  { Id: 7493, Code: '3565', Name: 'Inspector, local government: works dept' },
  {
    Id: 7494,
    Code: '3565',
    Name: 'Inspector, machine, docks, WEIGHING MACHINES'
  },
  {
    Id: 7495,
    Code: '3565',
    Name: 'Inspector, machine, railways, WEIGHING MACHINES'
  },
  { Id: 7496, Code: '3565', Name: 'Inspector, maintenance, gas supplier' },
  { Id: 7497, Code: '3565', Name: 'Inspector, market, local government' },
  { Id: 7498, Code: '3565', Name: 'Inspector, markets, local government' },
  { Id: 7499, Code: '3565', Name: 'Inspector, material' },
  { Id: 7500, Code: '3565', Name: 'Inspector, materials' },
  { Id: 7501, Code: '3565', Name: 'Inspector, meat' },
  { Id: 7502, Code: '3565', Name: 'Inspector, meat, authorised' },
  { Id: 7503, Code: '3565', Name: 'Inspector, mine: not coal' },
  { Id: 7504, Code: '3565', Name: 'Inspector, mines' },
  { Id: 7505, Code: '3565', Name: 'Inspector, NHBRC' },
  { Id: 7506, Code: '3565', Name: 'Inspector, NRSWA' },
  { Id: 7507, Code: '3565', Name: 'Inspector, NSPCC' },
  { Id: 7508, Code: '3565', Name: 'Inspector, operations, flight' },
  { Id: 7509, Code: '3565', Name: 'Inspector, planning' },
  { Id: 7510, Code: '3565', Name: 'Inspector, plumbing' },
  {
    Id: 7511,
    Code: '3565',
    Name: 'Inspector, pollution, river, water authority'
  },
  { Id: 7512, Code: '3565', Name: 'Inspector, portal, DARD' },
  { Id: 7513, Code: '3565', Name: 'Inspector, refuse' },
  { Id: 7514, Code: '3565', Name: 'Inspector, reinstatement, gas supplier' },
  { Id: 7515, Code: '3565', Name: 'Inspector, river, river, water authority' },
  { Id: 7516, Code: '3565', Name: 'Inspector, RSPCA' },
  { Id: 7517, Code: '3565', Name: 'Inspector, safety, coal mine' },
  { Id: 7518, Code: '3565', Name: 'Inspector, service, gas supplier' },
  { Id: 7519, Code: '3565', Name: 'Inspector, site, construction' },
  { Id: 7520, Code: '3565', Name: 'Inspector, SSPCA' },
  { Id: 7521, Code: '3565', Name: 'Inspector, standards, nuclear' },
  { Id: 7522, Code: '3565', Name: 'Inspector, street, electricity supplier' },
  { Id: 7523, Code: '3565', Name: 'Inspector, telecommunications' },
  { Id: 7524, Code: '3565', Name: 'Inspector, traffic, chief, PO' },
  {
    Id: 7525,
    Code: '3565',
    Name: 'Inspector, traffic, chief, telecommunications'
  },
  { Id: 7526, Code: '3565', Name: 'Inspector, value, best, local government' },
  { Id: 7527, Code: '3565', Name: 'Inspector, vehicle, government' },
  { Id: 7528, Code: '3565', Name: 'Inspector, VOSA' },
  { Id: 7529, Code: '3565', Name: 'Inspector, wages' },
  { Id: 7530, Code: '3565', Name: 'Inspector, water' },
  { Id: 7531, Code: '3565', Name: 'Inspector, water company' },
  { Id: 7532, Code: '3565', Name: 'Inspector, water, chief' },
  {
    Id: 7533,
    Code: '3565',
    Name: 'Inspector, works, public, local government'
  },
  { Id: 7534, Code: '3565', Name: 'Inspector, works, street' },
  { Id: 7535, Code: '3565', Name: 'Investigator, licence, television' },
  { Id: 7536, Code: '3565', Name: 'Manager, standards, trading' },
  { Id: 7537, Code: '3565', Name: 'Officer, authorised, MEAT INSPECTION' },
  {
    Id: 7538,
    Code: '3565',
    Name: 'Officer, chief, local government: weights and measures dept'
  },
  { Id: 7539, Code: '3565', Name: 'Officer, control, building' },
  { Id: 7540, Code: '3565', Name: 'Officer, control, pollution' },
  { Id: 7541, Code: '3565', Name: 'Officer, district, river, water authority' },
  {
    Id: 7542,
    Code: '3565',
    Name: 'Officer, effluent, water company, sewage works'
  },
  { Id: 7543, Code: '3565', Name: 'Officer, employment, child' },
  {
    Id: 7544,
    Code: '3565',
    Name: 'Officer, enforcement, local government: trading standards'
  },
  {
    Id: 7545,
    Code: '3565',
    Name: 'Officer, enforcement, local government: transport'
  },
  { Id: 7546, Code: '3565', Name: 'Officer, enforcement, standards, trading' },
  { Id: 7547, Code: '3565', Name: 'Officer, field, DEFRA' },
  { Id: 7548, Code: '3565', Name: 'Officer, fisheries' },
  { Id: 7549, Code: '3565', Name: 'Officer, fishery' },
  { Id: 7550, Code: '3565', Name: 'Officer, health, animal' },
  { Id: 7551, Code: '3565', Name: 'Officer, improvements, local government' },
  { Id: 7552, Code: '3565', Name: 'Officer, inspecting, DETR' },
  { Id: 7553, Code: '3565', Name: 'Officer, investigating, DETR' },
  { Id: 7554, Code: '3565', Name: 'Officer, investigation, DETR' },
  { Id: 7555, Code: '3565', Name: 'Officer, Licensing, local government' },
  { Id: 7556, Code: '3565', Name: 'Officer, livestock' },
  { Id: 7557, Code: '3565', Name: 'Officer, markets, DEFRA' },
  { Id: 7558, Code: '3565', Name: 'Officer, pollution, water company' },
  { Id: 7559, Code: '3565', Name: 'Officer, protection, child, NSPCC' },
  { Id: 7560, Code: '3565', Name: 'Officer, protection, consumer' },
  { Id: 7561, Code: '3565', Name: 'Officer, quality, water' },
  { Id: 7562, Code: '3565', Name: 'Officer, regional, RSPCA' },
  { Id: 7563, Code: '3565', Name: 'Officer, relations, race' },
  { Id: 7564, Code: '3565', Name: 'Officer, repairs, local government' },
  { Id: 7565, Code: '3565', Name: 'Officer, RSPCA' },
  { Id: 7566, Code: '3565', Name: 'Officer, service, carcass' },
  { Id: 7567, Code: '3565', Name: 'Officer, standards, driving' },
  { Id: 7568, Code: '3565', Name: 'Officer, standards, trading' },
  { Id: 7569, Code: '3565', Name: 'Officer, trading, fair' },
  { Id: 7570, Code: '3565', Name: 'Superintendent, RSPCA' },
  { Id: 7571, Code: '3565', Name: 'Supervisor, planning, construction' },
  { Id: 7572, Code: '3565', Name: 'Surveyor, control, building' },
  { Id: 7573, Code: '3565', Name: "Surveyor, Lloyd's" },
  { Id: 7574, Code: '3565', Name: 'Surveyor, ship' },
  { Id: 7575, Code: '3565', Name: 'Technician, health, environmental' },
  { Id: 7576, Code: '3565', Name: 'Tester, gear, chain and suspension' },
  { Id: 7577, Code: '3565', Name: 'Tester, vehicle, DETR' },
  { Id: 7578, Code: '3565', Name: 'Worker, advice, rights, consumer' },
  { Id: 7579, Code: '3567', Name: 'Adviser, EHS' },
  { Id: 7580, Code: '3567', Name: 'Adviser, environmental, health and safety' },
  { Id: 7581, Code: '3567', Name: 'Adviser, health and safety' },
  { Id: 7582, Code: '3567', Name: 'Adviser, safety' },
  { Id: 7583, Code: '3567', Name: 'Adviser, toxicology' },
  { Id: 7584, Code: '3567', Name: 'Attendant, safety, chemical works' },
  { Id: 7585, Code: '3567', Name: 'Consultant, asbestos' },
  { Id: 7586, Code: '3567', Name: 'Consultant, fire' },
  { Id: 7587, Code: '3567', Name: 'Consultant, health and safety' },
  { Id: 7588, Code: '3567', Name: 'Consultant, protection, fire' },
  { Id: 7589, Code: '3567', Name: 'Consultant, safety' },
  { Id: 7590, Code: '3567', Name: 'Coordinator, health and safety' },
  { Id: 7591, Code: '3567', Name: 'Engineer, protection, fire, PROFESSIONAL' },
  { Id: 7592, Code: '3567', Name: 'Engineer, safety' },
  { Id: 7593, Code: '3567', Name: 'Hygienist, occupational' },
  { Id: 7594, Code: '3567', Name: 'Inspector, catering' },
  { Id: 7595, Code: '3567', Name: 'Inspector, government: HSE' },
  { Id: 7596, Code: '3567', Name: 'Inspector, safety' },
  { Id: 7597, Code: '3567', Name: 'Investigator, accident' },
  { Id: 7598, Code: '3567', Name: 'Investigator, fire, forensic' },
  { Id: 7599, Code: '3567', Name: 'Man, safety, steel mfr' },
  { Id: 7600, Code: '3567', Name: 'Manager, health and environment, safety' },
  { Id: 7601, Code: '3567', Name: 'Manager, health, occupational' },
  { Id: 7602, Code: '3567', Name: 'Officer, control, dust, coal mine' },
  { Id: 7603, Code: '3567', Name: 'Officer, environmental, health and safety' },
  { Id: 7604, Code: '3567', Name: 'Officer, health and safety' },
  { Id: 7605, Code: '3567', Name: 'Officer, health and safety, environmental' },
  { Id: 7606, Code: '3567', Name: 'Officer, prevention, accident' },
  { Id: 7607, Code: '3567', Name: 'Officer, safety' },
  { Id: 7608, Code: '3567', Name: 'Officer, safety and training, coal mine' },
  { Id: 7609, Code: '3567', Name: 'Officer, safety, road' },
  { Id: 7610, Code: '3567', Name: 'Officer, suppression, dust, coal mine' },
  { Id: 7611, Code: '3567', Name: 'Officer, ventilation, coal mine' },
  { Id: 7612, Code: '3567', Name: 'Organiser, safety, road' },
  { Id: 7613, Code: '3567', Name: 'Practitioner, health and safety' },
  { Id: 7614, Code: '3567', Name: 'Supervisor, safety' },
  { Id: 7615, Code: '3567', Name: 'Surveyor, asbestos' },
  { Id: 7616, Code: '3567', Name: 'Surveyor, physics, health' },
  { Id: 7617, Code: '3567', Name: 'Worker, safety, UKAEA' },
  { Id: 7618, Code: '4112', Name: 'A1, Jobcentre Plus' },
  { Id: 7619, Code: '4112', Name: 'A1, Office for National Statistics' },
  { Id: 7620, Code: '4112', Name: 'A2, Jobcentre Plus' },
  { Id: 7621, Code: '4112', Name: 'A2, Office for National Statistics' },
  { Id: 7622, Code: '4112', Name: 'A3, Jobcentre Plus' },
  { Id: 7623, Code: '4112', Name: 'A3, Office for National Statistics' },
  { Id: 7624, Code: '4112', Name: 'A3, Scottish Government' },
  { Id: 7625, Code: '4112', Name: 'A4, Jobcentre Plus' },
  { Id: 7626, Code: '4112', Name: 'A4, Scottish Government' },
  { Id: 7627, Code: '4112', Name: 'A5, Jobcentre Plus' },
  { Id: 7628, Code: '4112', Name: 'A6, Jobcentre Plus' },
  { Id: 7629, Code: '4112', Name: 'A7, Jobcentre Plus' },
  { Id: 7630, Code: '4112', Name: 'AA, government' },
  { Id: 7631, Code: '4112', Name: 'Administrator, armed forces' },
  { Id: 7632, Code: '4112', Name: 'Administrator, case, probation service' },
  { Id: 7633, Code: '4112', Name: 'Administrator, government' },
  { Id: 7634, Code: '4112', Name: 'Adviser, benefits, government' },
  { Id: 7635, Code: '4112', Name: 'Adviser, claimant' },
  { Id: 7636, Code: '4112', Name: 'Adviser, claims, government' },
  { Id: 7637, Code: '4112', Name: 'Adviser, deal, new' },
  { Id: 7638, Code: '4112', Name: 'Adviser, employment, government' },
  { Id: 7639, Code: '4112', Name: 'Adviser, Jobcentre Plus' },
  { Id: 7640, Code: '4112', Name: 'Adviser, personal, deal, new' },
  { Id: 7641, Code: '4112', Name: 'Adviser, security, social' },
  { Id: 7642, Code: '4112', Name: 'AO, government' },
  { Id: 7643, Code: '4112', Name: 'Assessor, benefits, government' },
  { Id: 7644, Code: '4112', Name: 'Assessor, claims, Jobcentre Plus' },
  { Id: 7645, Code: '4112', Name: 'Assistant, admin, armed forces' },
  { Id: 7646, Code: '4112', Name: 'Assistant, admin, courts of justice' },
  { Id: 7647, Code: '4112', Name: 'Assistant, admin, government' },
  { Id: 7648, Code: '4112', Name: 'Assistant, administration, armed forces' },
  {
    Id: 7649,
    Code: '4112',
    Name: 'Assistant, administration, courts of justice'
  },
  { Id: 7650, Code: '4112', Name: 'Assistant, administration, government' },
  { Id: 7651, Code: '4112', Name: 'Assistant, administrative, armed forces' },
  {
    Id: 7652,
    Code: '4112',
    Name: 'Assistant, administrative, courts of justice'
  },
  { Id: 7653, Code: '4112', Name: 'Assistant, administrative, government' },
  { Id: 7654, Code: '4112', Name: 'Assistant, benefit, government' },
  { Id: 7655, Code: '4112', Name: 'Assistant, benefits, government' },
  { Id: 7656, Code: '4112', Name: 'Assistant, clerical, government' },
  { Id: 7657, Code: '4112', Name: 'Assistant, court' },
  { Id: 7658, Code: '4112', Name: 'Assistant, registration, Land Registry' },
  { Id: 7659, Code: '4112', Name: 'Assistant, revenue, government' },
  { Id: 7660, Code: '4112', Name: 'Assistant, Revenue, Inland' },
  { Id: 7661, Code: '4112', Name: 'Assistant, tax, government' },
  { Id: 7662, Code: '4112', Name: 'Assistant, taxation, government' },
  { Id: 7663, Code: '4112', Name: 'B1, Cabinet Office' },
  { Id: 7664, Code: '4112', Name: 'B1, Jobcentre Plus' },
  { Id: 7665, Code: '4112', Name: 'B1, Office for National Statistics' },
  { Id: 7666, Code: '4112', Name: 'B1, Scottish Government' },
  { Id: 7667, Code: '4112', Name: 'B2, Dept for International Development' },
  { Id: 7668, Code: '4112', Name: 'B2, Jobcentre Plus' },
  { Id: 7669, Code: '4112', Name: 'B2, Office for National Statistics' },
  { Id: 7670, Code: '4112', Name: 'B3, Jobcentre Plus' },
  { Id: 7671, Code: '4112', Name: 'B4, Jobcentre Plus' },
  { Id: 7672, Code: '4112', Name: 'B5, Jobcentre Plus' },
  { Id: 7673, Code: '4112', Name: 'Band 3D, Meteorological Office' },
  { Id: 7674, Code: '4112', Name: 'Band 4A, Meteorological Office' },
  { Id: 7675, Code: '4112', Name: 'Band 4B, Meteorological Office' },
  { Id: 7676, Code: '4112', Name: 'Band 4C, Meteorological Office' },
  { Id: 7677, Code: '4112', Name: 'Band 5, Health and Safety Executive' },
  { Id: 7678, Code: '4112', Name: 'Band 6, Health and Safety Executive' },
  { Id: 7679, Code: '4112', Name: 'Band A, Ministry of Justice' },
  { Id: 7680, Code: '4112', Name: 'Band B, Ministry of Justice' },
  { Id: 7681, Code: '4112', Name: 'Band C, Ministry of Justice' },
  { Id: 7682, Code: '4112', Name: 'Band C, National Assembly for Wales' },
  { Id: 7683, Code: '4112', Name: 'Broker, job, Jobcentre Plus' },
  { Id: 7684, Code: '4112', Name: 'C, Northern Ireland Office' },
  { Id: 7685, Code: '4112', Name: 'C1, Cabinet Office' },
  { Id: 7686, Code: '4112', Name: 'C1, Dept for International Development' },
  { Id: 7687, Code: '4112', Name: 'C2, Cabinet Office' },
  { Id: 7688, Code: '4112', Name: 'C2, Dept for International Development' },
  { Id: 7689, Code: '4112', Name: 'Caseworker, government' },
  { Id: 7690, Code: '4112', Name: 'Clerk, court' },
  { Id: 7691, Code: '4112', Name: 'Clerk, government' },
  { Id: 7692, Code: '4112', Name: 'Clerk, law courts' },
  { Id: 7693, Code: '4112', Name: 'Clerk, prison service' },
  {
    Id: 7694,
    Code: '4112',
    Name: 'Clerk, valuation, grade, higher, HM Revenue and Customs'
  },
  { Id: 7695, Code: '4112', Name: 'Clerk-typist, government' },
  { Id: 7696, Code: '4112', Name: 'Collector of taxes' },
  { Id: 7697, Code: '4112', Name: 'Collector of taxes, assistant' },
  {
    Id: 7698,
    Code: '4112',
    Name: 'Collector, assistant, HM Revenue and Customs'
  },
  { Id: 7699, Code: '4112', Name: 'Collector, tax' },
  { Id: 7700, Code: '4112', Name: 'Collector, tax, assistant' },
  { Id: 7701, Code: '4112', Name: 'Coordinator, deal, new' },
  { Id: 7702, Code: '4112', Name: 'D1, Northern Ireland Office' },
  { Id: 7703, Code: '4112', Name: 'D2, Northern Ireland Office' },
  { Id: 7704, Code: '4112', Name: 'EO, government' },
  { Id: 7705, Code: '4112', Name: 'Examiner, assistant, government' },
  { Id: 7706, Code: '4112', Name: 'Executive, registration, Land Registry' },
  { Id: 7707, Code: '4112', Name: 'Executive, revenue, government' },
  { Id: 7708, Code: '4112', Name: 'Executive, Revenue, Inland' },
  { Id: 7709, Code: '4112', Name: 'Grade 11, Foreign and Commonwealth Office' },
  { Id: 7710, Code: '4112', Name: 'Grade 12, Foreign and Commonwealth Office' },
  { Id: 7711, Code: '4112', Name: 'Grade 13, Foreign and Commonwealth Office' },
  { Id: 7712, Code: '4112', Name: 'Grade C, DCMS' },
  { Id: 7713, Code: '4112', Name: 'Grade D, DCMS' },
  { Id: 7714, Code: '4112', Name: 'Inspector, assistant, government' },
  { Id: 7715, Code: '4112', Name: 'Inspector, fraud, government' },
  { Id: 7716, Code: '4112', Name: 'Inspector, fund, social, government' },
  { Id: 7717, Code: '4112', Name: 'Investigator, fraud, benefit' },
  { Id: 7718, Code: '4112', Name: 'Investigator, fraud, government' },
  { Id: 7719, Code: '4112', Name: 'Investigator, Jobcentre Plus' },
  { Id: 7720, Code: '4112', Name: 'LOII, Jobcentre Plus' },
  { Id: 7721, Code: '4112', Name: 'Maker, decision, Jobcentre Plus' },
  { Id: 7722, Code: '4112', Name: 'Maker, decision, sector' },
  { Id: 7723, Code: '4112', Name: 'Officer, adjudication, government' },
  { Id: 7724, Code: '4112', Name: 'Officer, admin, armed forces' },
  { Id: 7725, Code: '4112', Name: 'Officer, admin, courts of justice' },
  { Id: 7726, Code: '4112', Name: 'Officer, admin, government' },
  { Id: 7727, Code: '4112', Name: 'Officer, administration, armed forces' },
  {
    Id: 7728,
    Code: '4112',
    Name: 'Officer, administration, courts of justice'
  },
  { Id: 7729, Code: '4112', Name: 'Officer, administration, government' },
  { Id: 7730, Code: '4112', Name: 'Officer, administrative, armed forces' },
  { Id: 7731, Code: '4112', Name: 'Officer, administrative, court' },
  {
    Id: 7732,
    Code: '4112',
    Name: 'Officer, administrative, courts of justice'
  },
  { Id: 7733, Code: '4112', Name: 'Officer, administrative, government' },
  { Id: 7734, Code: '4112', Name: 'Officer, appeals, government' },
  { Id: 7735, Code: '4112', Name: 'Officer, assessment' },
  { Id: 7736, Code: '4112', Name: 'Officer, benefit, government' },
  { Id: 7737, Code: '4112', Name: 'Officer, benefits, government' },
  { Id: 7738, Code: '4112', Name: 'Officer, care, witness' },
  { Id: 7739, Code: '4112', Name: 'Officer, clerical, government' },
  { Id: 7740, Code: '4112', Name: 'Officer, clerical, prison service' },
  {
    Id: 7741,
    Code: '4112',
    Name: 'Officer, cypher, Foreign and Commonwealth Office'
  },
  { Id: 7742, Code: '4112', Name: 'Officer, determining, court service' },
  { Id: 7743, Code: '4112', Name: 'Officer, disposals, government' },
  { Id: 7744, Code: '4112', Name: 'Officer, executive, government' },
  { Id: 7745, Code: '4112', Name: 'Officer, fraud, government' },
  { Id: 7746, Code: '4112', Name: 'Officer, fund, social' },
  { Id: 7747, Code: '4112', Name: 'Officer, government, nos' },
  { Id: 7748, Code: '4112', Name: 'Officer, group, MOD' },
  { Id: 7749, Code: '4112', Name: 'Officer, investigating, fraud, government' },
  { Id: 7750, Code: '4112', Name: 'Officer, investigating, government' },
  { Id: 7751, Code: '4112', Name: 'Officer, investigation, fraud, government' },
  { Id: 7752, Code: '4112', Name: 'Officer, investigation, government' },
  { Id: 7753, Code: '4112', Name: 'Officer, liaison, government' },
  { Id: 7754, Code: '4112', Name: 'Officer, local II, Jobcentre Plus' },
  { Id: 7755, Code: '4112', Name: 'Officer, presenting, appeals, government' },
  { Id: 7756, Code: '4112', Name: 'Officer, processing, benefits, government' },
  { Id: 7757, Code: '4112', Name: 'Officer, registration, Land Registry' },
  { Id: 7758, Code: '4112', Name: 'Officer, revenue, government' },
  { Id: 7759, Code: '4112', Name: 'Officer, Revenue, Inland' },
  { Id: 7760, Code: '4112', Name: 'Officer, security, social, Jobcentre Plus' },
  { Id: 7761, Code: '4112', Name: 'Officer, service, foreign' },
  { Id: 7762, Code: '4112', Name: 'Officer, service, foreign, GRADE B5' },
  { Id: 7763, Code: '4112', Name: 'Officer, statistical, grade D, MOD' },
  { Id: 7764, Code: '4112', Name: 'Officer, substitution, MOD' },
  { Id: 7765, Code: '4112', Name: 'Officer, supply, assistant, MOD' },
  { Id: 7766, Code: '4112', Name: 'Officer, supply, chart, MOD' },
  { Id: 7767, Code: '4112', Name: 'Officer, support, income, government' },
  {
    Id: 7768,
    Code: '4112',
    Name: 'Officer, tax, grade, higher, HM Revenue and Customs'
  },
  { Id: 7769, Code: '4112', Name: 'Officer, tax, HM Revenue and Customs' },
  {
    Id: 7770,
    Code: '4112',
    Name: 'Officer, taxation, grade, higher, HM Revenue and Customs'
  },
  { Id: 7771, Code: '4112', Name: 'Officer, taxation, HM Revenue and Customs' },
  { Id: 7772, Code: '4112', Name: 'Officer, visiting, Jobcentre Plus' },
  { Id: 7773, Code: '4112', Name: 'Official, government' },
  { Id: 7774, Code: '4112', Name: 'Official, tax, HM Revenue and Customs' },
  { Id: 7775, Code: '4112', Name: 'Payband 1, Dept of Health' },
  { Id: 7776, Code: '4112', Name: 'Payband 2, Dept of Health' },
  { Id: 7777, Code: '4112', Name: 'Processor, claims, Jobcentre Plus' },
  { Id: 7778, Code: '4112', Name: 'RA1, Land Registry' },
  { Id: 7779, Code: '4112', Name: 'RA2, Land Registry' },
  { Id: 7780, Code: '4112', Name: 'RE2, Land Registry' },
  { Id: 7781, Code: '4112', Name: 'RO, Land Registry' },
  { Id: 7782, Code: '4112', Name: 'Servant, civil, AA' },
  { Id: 7783, Code: '4112', Name: 'Servant, civil, AO' },
  { Id: 7784, Code: '4112', Name: 'Servant, civil, EO' },
  { Id: 7785, Code: '4112', Name: 'Servant, civil, MUSEUM SERVICE' },
  { Id: 7786, Code: '4112', Name: 'Servant, civil, nos' },
  { Id: 7787, Code: '4112', Name: 'SGB, DEFRA' },
  { Id: 7788, Code: '4112', Name: 'Stamper, HM Revenue and Customs' },
  { Id: 7789, Code: '4112', Name: 'Transcriber, communications, government' },
  { Id: 7790, Code: '4112', Name: 'Watcher, customs' },
  { Id: 7791, Code: '4112', Name: 'Watcher, HM Revenue and Customs' },
  { Id: 7792, Code: '4112', Name: 'Worker, case, government' },
  { Id: 7793, Code: '4112', Name: 'Worker, office, government' },
  { Id: 7794, Code: '4112', Name: 'Writer, armed forces' },
  { Id: 7795, Code: '4112', Name: 'Writer, MOD' },
  { Id: 7796, Code: '4113', Name: 'Administrator, electoral' },
  { Id: 7797, Code: '4113', Name: 'Administrator, local government' },
  { Id: 7798, Code: '4113', Name: 'Administrator, MAPPA' },
  { Id: 7799, Code: '4113', Name: 'Administrator, police service' },
  { Id: 7800, Code: '4113', Name: 'Adviser, benefit, local government' },
  { Id: 7801, Code: '4113', Name: 'Adviser, benefits, local government' },
  { Id: 7802, Code: '4113', Name: 'AP(T), local government, GRADE 1,2,3' },
  { Id: 7803, Code: '4113', Name: 'Assessor, benefit, housing' },
  { Id: 7804, Code: '4113', Name: 'Assessor, benefits, local government' },
  { Id: 7805, Code: '4113', Name: 'Assistant, admin, local government' },
  { Id: 7806, Code: '4113', Name: 'Assistant, admin, police service' },
  {
    Id: 7807,
    Code: '4113',
    Name: 'Assistant, administration, local government'
  },
  { Id: 7808, Code: '4113', Name: 'Assistant, administration, police service' },
  {
    Id: 7809,
    Code: '4113',
    Name: 'Assistant, administrative, local government'
  },
  { Id: 7810, Code: '4113', Name: 'Assistant, administrative, police service' },
  { Id: 7811, Code: '4113', Name: 'Assistant, benefit, local government' },
  { Id: 7812, Code: '4113', Name: 'Assistant, benefits, local government' },
  { Id: 7813, Code: '4113', Name: 'Assistant, chief, local government' },
  { Id: 7814, Code: '4113', Name: 'Assistant, civic' },
  { Id: 7815, Code: '4113', Name: 'Assistant, clerical, local government' },
  { Id: 7816, Code: '4113', Name: 'Assistant, clerical, police service' },
  { Id: 7817, Code: '4113', Name: "Assistant, collector's, rate" },
  { Id: 7818, Code: '4113', Name: 'Assistant, fixing, rate' },
  { Id: 7819, Code: '4113', Name: 'Assistant, housing' },
  { Id: 7820, Code: '4113', Name: "Assistant, inspector's, local government" },
  { Id: 7821, Code: '4113', Name: 'Assistant, lettings, local government' },
  { Id: 7822, Code: '4113', Name: 'Assistant, principal, local government' },
  { Id: 7823, Code: '4113', Name: 'Assistant, rating' },
  { Id: 7824, Code: '4113', Name: 'Assistant, recovery, local government' },
  { Id: 7825, Code: '4113', Name: 'Assistant, senior, CSSD' },
  { Id: 7826, Code: '4113', Name: 'Assistant, senior, local government' },
  { Id: 7827, Code: '4113', Name: 'Assistant, standards, trading' },
  {
    Id: 7828,
    Code: '4113',
    Name: 'Assistant, support, business, local government'
  },
  { Id: 7829, Code: '4113', Name: 'Assistant, survey, local government' },
  { Id: 7830, Code: '4113', Name: 'Assistant, tax, local government' },
  { Id: 7831, Code: '4113', Name: 'Assistant, taxation, local government' },
  { Id: 7832, Code: '4113', Name: 'Attendant, health, local government' },
  { Id: 7833, Code: '4113', Name: 'Clerk, charge, community' },
  { Id: 7834, Code: '4113', Name: 'Clerk, control, local government' },
  { Id: 7835, Code: '4113', Name: 'Clerk, council, parish' },
  { Id: 7836, Code: '4113', Name: 'Clerk, health authority' },
  { Id: 7837, Code: '4113', Name: 'Clerk, local government' },
  { Id: 7838, Code: '4113', Name: 'Clerk, parish' },
  { Id: 7839, Code: '4113', Name: 'Clerk, police service' },
  { Id: 7840, Code: '4113', Name: 'Clerk, property' },
  { Id: 7841, Code: '4113', Name: 'Clerk, rating' },
  { Id: 7842, Code: '4113', Name: 'Clerk, senior, local government' },
  { Id: 7843, Code: '4113', Name: 'Clerk, tax, poll' },
  { Id: 7844, Code: '4113', Name: 'Clerk, team, local authority' },
  { Id: 7845, Code: '4113', Name: 'Clerk-typist, health authority' },
  { Id: 7846, Code: '4113', Name: 'Clerk-typist, local government' },
  { Id: 7847, Code: '4113', Name: 'Clerk-typist, police service' },
  { Id: 7848, Code: '4113', Name: 'Collector, local government' },
  { Id: 7849, Code: '4113', Name: 'Collector, rate' },
  { Id: 7850, Code: '4113', Name: 'Collector, senior, local government' },
  { Id: 7851, Code: '4113', Name: 'Collector-driver, local government' },
  { Id: 7852, Code: '4113', Name: 'Controller, watch, neighbourhood' },
  { Id: 7853, Code: '4113', Name: 'Inspector of rates and rentals' },
  { Id: 7854, Code: '4113', Name: 'Inspector, employment' },
  { Id: 7855, Code: '4113', Name: 'Inspector, fraud, local government' },
  {
    Id: 7856,
    Code: '4113',
    Name: 'Leader, team, services, data, local government'
  },
  { Id: 7857, Code: '4113', Name: 'Officer, admin, local government' },
  { Id: 7858, Code: '4113', Name: 'Officer, admin, police service' },
  { Id: 7859, Code: '4113', Name: 'Officer, administration, local government' },
  { Id: 7860, Code: '4113', Name: 'Officer, administration, police service' },
  { Id: 7861, Code: '4113', Name: 'Officer, administrative, local government' },
  { Id: 7862, Code: '4113', Name: 'Officer, administrative, police service' },
  { Id: 7863, Code: '4113', Name: 'Officer, amenity, area' },
  { Id: 7864, Code: '4113', Name: 'Officer, arrears, local government' },
  { Id: 7865, Code: '4113', Name: 'Officer, assessment, local government' },
  { Id: 7866, Code: '4113', Name: 'Officer, authorised, local government' },
  { Id: 7867, Code: '4113', Name: 'Officer, benefit, housing' },
  { Id: 7868, Code: '4113', Name: 'Officer, benefit, local government' },
  {
    Id: 7869,
    Code: '4113',
    Name: 'Officer, benefit, senior, local government'
  },
  { Id: 7870, Code: '4113', Name: 'Officer, benefits, housing' },
  { Id: 7871, Code: '4113', Name: 'Officer, benefits, local government' },
  {
    Id: 7872,
    Code: '4113',
    Name: 'Officer, benefits, senior, local government'
  },
  { Id: 7873, Code: '4113', Name: 'Officer, billing, local government' },
  { Id: 7874, Code: '4113', Name: 'Officer, charges, land' },
  { Id: 7875, Code: '4113', Name: 'Officer, clerical, health authority' },
  {
    Id: 7876,
    Code: '4113',
    Name: 'Officer, clerical, higher, local government'
  },
  { Id: 7877, Code: '4113', Name: 'Officer, clerical, local government' },
  { Id: 7878, Code: '4113', Name: 'Officer, clerical, police service' },
  { Id: 7879, Code: '4113', Name: 'Officer, collection, local government' },
  { Id: 7880, Code: '4113', Name: 'Officer, communications, local government' },
  { Id: 7881, Code: '4113', Name: 'Officer, elections' },
  { Id: 7882, Code: '4113', Name: 'Officer, executive, local government' },
  { Id: 7883, Code: '4113', Name: 'Officer, fraud, local government' },
  { Id: 7884, Code: '4113', Name: 'Officer, government, local, nos' },
  {
    Id: 7885,
    Code: '4113',
    Name: 'Officer, investigation, fraud, local government'
  },
  {
    Id: 7886,
    Code: '4113',
    Name: 'Officer, lettings, local government: housing dept'
  },
  { Id: 7887, Code: '4113', Name: 'Officer, memorials' },
  { Id: 7888, Code: '4113', Name: 'Officer, monitoring, local government' },
  { Id: 7889, Code: '4113', Name: 'Officer, rates' },
  { Id: 7890, Code: '4113', Name: 'Officer, rebate, local government' },
  { Id: 7891, Code: '4113', Name: 'Officer, revenue, local government' },
  { Id: 7892, Code: '4113', Name: 'Officer, revenues, local government' },
  { Id: 7893, Code: '4113', Name: 'Officer, rights of way' },
  { Id: 7894, Code: '4113', Name: 'Officer, services, electoral' },
  { Id: 7895, Code: '4113', Name: 'Officer, support, local government' },
  { Id: 7896, Code: '4113', Name: 'Officer, tax, council' },
  { Id: 7897, Code: '4113', Name: 'Officer, technical, local government' },
  { Id: 7898, Code: '4113', Name: 'Official, government, local' },
  { Id: 7899, Code: '4113', Name: 'Operator, bureau, reporting, crime' },
  { Id: 7900, Code: '4113', Name: 'Planner, duties, police service' },
  { Id: 7901, Code: '4113', Name: 'Worker, case, police service' },
  { Id: 7902, Code: '4113', Name: 'Worker, clerical, local government' },
  { Id: 7903, Code: '4114', Name: 'Administrator, CHARITABLE ORGANISATION' },
  { Id: 7904, Code: '4114', Name: 'Administrator, charity' },
  { Id: 7905, Code: '4114', Name: 'Administrator, covenant' },
  { Id: 7906, Code: '4114', Name: 'Administrator, faculty' },
  { Id: 7907, Code: '4114', Name: 'Administrator, trade union' },
  { Id: 7908, Code: '4114', Name: 'Assistant, membership' },
  { Id: 7909, Code: '4114', Name: 'Clerk to the governors' },
  { Id: 7910, Code: '4114', Name: 'Convenor, works' },
  { Id: 7911, Code: '4114', Name: 'Delegate, union, trade' },
  { Id: 7912, Code: '4114', Name: 'Officer, British Council' },
  { Id: 7913, Code: '4114', Name: 'Officer, Council, British' },
  { Id: 7914, Code: '4114', Name: 'Officer, development, branch, trade union' },
  {
    Id: 7915,
    Code: '4114',
    Name: 'Officer, development, CHARITABLE ORGANISATION'
  },
  { Id: 7916, Code: '4114', Name: 'Officer, regional, PUBLIC BOARDS' },
  { Id: 7917, Code: '4114', Name: 'Officer, sabbatical' },
  { Id: 7918, Code: '4114', Name: 'Officer, union' },
  { Id: 7919, Code: '4114', Name: 'Officer, union, trade' },
  { Id: 7920, Code: '4114', Name: 'Official, CHARITABLE ORGANISATION' },
  { Id: 7921, Code: '4114', Name: "Official, employers' association" },
  { Id: 7922, Code: '4114', Name: 'Official, professional organisation' },
  { Id: 7923, Code: '4114', Name: 'Official, trade union' },
  { Id: 7924, Code: '4114', Name: 'Official, union' },
  { Id: 7925, Code: '4114', Name: 'Official, union, trade' },
  { Id: 7926, Code: '4114', Name: 'Organiser, constituency' },
  { Id: 7927, Code: '4114', Name: 'Organiser, political' },
  { Id: 7928, Code: '4114', Name: 'Organiser, political party' },
  { Id: 7929, Code: '4114', Name: 'Organiser, trade union' },
  { Id: 7930, Code: '4114', Name: 'President, vice, STUDENT UNION' },
  { Id: 7931, Code: '4114', Name: 'Representative, trade union' },
  { Id: 7932, Code: '4114', Name: 'Secretary of charitable organisation' },
  { Id: 7933, Code: '4114', Name: 'Secretary of political association' },
  { Id: 7934, Code: '4114', Name: 'Secretary of professional association' },
  { Id: 7935, Code: '4114', Name: 'Secretary of trade association' },
  { Id: 7936, Code: '4114', Name: 'Secretary of trade union' },
  { Id: 7937, Code: '4114', Name: 'Secretary, appeals, hospital' },
  { Id: 7938, Code: '4114', Name: 'Secretary, area, coal mine' },
  {
    Id: 7939,
    Code: '4114',
    Name: 'Secretary, assistant, CHARITABLE ORGANISATION'
  },
  {
    Id: 7940,
    Code: '4114',
    Name: 'Secretary, assistant, professional association'
  },
  { Id: 7941, Code: '4114', Name: 'Secretary, assistant, trade association' },
  { Id: 7942, Code: '4114', Name: 'Secretary, assistant, trade union' },
  { Id: 7943, Code: '4114', Name: 'Secretary, association' },
  {
    Id: 7944,
    Code: '4114',
    Name: 'Secretary, branch, CHARITABLE ORGANISATION'
  },
  { Id: 7945, Code: '4114', Name: 'Secretary, branch, insurance' },
  { Id: 7946, Code: '4114', Name: 'Secretary, branch, trade union' },
  { Id: 7947, Code: '4114', Name: 'Secretary, chamber of commerce' },
  { Id: 7948, Code: '4114', Name: 'Secretary, district, insurance' },
  { Id: 7949, Code: '4114', Name: 'Secretary, financial' },
  { Id: 7950, Code: '4114', Name: 'Secretary, fund' },
  { Id: 7951, Code: '4114', Name: 'Secretary, group, trade union' },
  { Id: 7952, Code: '4114', Name: 'Secretary, joint industrial council' },
  {
    Id: 7953,
    Code: '4114',
    Name: 'Secretary, organising, CHARITABLE ORGANISATION'
  },
  {
    Id: 7954,
    Code: '4114',
    Name: 'Secretary, organising, professional association'
  },
  { Id: 7955, Code: '4114', Name: 'Secretary, organising, trade association' },
  { Id: 7956, Code: '4114', Name: 'Secretary, organising, trade union' },
  { Id: 7957, Code: '4114', Name: 'Secretary, organising, welfare services' },
  { Id: 7958, Code: '4114', Name: 'Secretary, political' },
  { Id: 7959, Code: '4114', Name: 'Secretary, research association' },
  { Id: 7960, Code: '4114', Name: 'Steward, shop' },
  { Id: 7961, Code: '4121', Name: 'Administrator, control, credit' },
  { Id: 7962, Code: '4121', Name: 'Administrator, loans' },
  { Id: 7963, Code: '4121', Name: 'Adviser, difficulties, financial' },
  { Id: 7964, Code: '4121', Name: 'Adviser, management, debt' },
  { Id: 7965, Code: '4121', Name: 'Assistant, control, credit' },
  { Id: 7966, Code: '4121', Name: 'Associate, management, debt' },
  { Id: 7967, Code: '4121', Name: 'Clerk, authorisation, bank' },
  { Id: 7968, Code: '4121', Name: 'Clerk, authorisation, building society' },
  { Id: 7969, Code: '4121', Name: 'Clerk, control, credit' },
  { Id: 7970, Code: '4121', Name: 'Clerk, recovery, debt' },
  { Id: 7971, Code: '4121', Name: 'Controller, credit' },
  { Id: 7972, Code: '4121', Name: 'Coordinator, claim, credit' },
  { Id: 7973, Code: '4121', Name: 'Officer, collections, bank' },
  { Id: 7974, Code: '4121', Name: 'Officer, collections, building society' },
  { Id: 7975, Code: '4121', Name: 'Officer, control, credit' },
  { Id: 7976, Code: '4121', Name: 'Officer, recovery, DEBT' },
  { Id: 7977, Code: '4122', Name: 'Accountant' },
  { Id: 7978, Code: '4122', Name: 'Accountant and auditor' },
  { Id: 7979, Code: '4122', Name: 'Accountant, barrack' },
  { Id: 7980, Code: '4122', Name: 'Accountant, barracks' },
  { Id: 7981, Code: '4122', Name: 'Accountant, cost' },
  { Id: 7982, Code: '4122', Name: 'Accountant, cost and works' },
  { Id: 7983, Code: '4122', Name: 'Accountant, financial, coal mine' },
  { Id: 7984, Code: '4122', Name: 'Accountant, group' },
  { Id: 7985, Code: '4122', Name: 'Accountant, management' },
  { Id: 7986, Code: '4122', Name: 'Accountant, works' },
  { Id: 7987, Code: '4122', Name: 'Administrator, accounts' },
  { Id: 7988, Code: '4122', Name: 'Administrator, holding, fund' },
  { Id: 7989, Code: '4122', Name: 'Administrator, ledger' },
  { Id: 7990, Code: '4122', Name: 'Administrator, pay' },
  { Id: 7991, Code: '4122', Name: 'Administrator, payroll' },
  { Id: 7992, Code: '4122', Name: 'Administrator, wages' },
  { Id: 7993, Code: '4122', Name: 'Assistant, account' },
  { Id: 7994, Code: '4122', Name: 'Assistant, accountancy' },
  { Id: 7995, Code: '4122', Name: "Assistant, accountant's" },
  { Id: 7996, Code: '4122', Name: 'Assistant, accounts' },
  { Id: 7997, Code: '4122', Name: 'Assistant, clerical, accounts' },
  { Id: 7998, Code: '4122', Name: 'Assistant, ledger' },
  { Id: 7999, Code: '4122', Name: 'Assistant, payroll' },
  { Id: 8000, Code: '4122', Name: 'Assistant, salaries' },
  { Id: 8001, Code: '4122', Name: 'Assistant, services, payroll' },
  { Id: 8002, Code: '4122', Name: 'Assistant, wages' },
  { Id: 8003, Code: '4122', Name: 'Associate, accounts' },
  { Id: 8004, Code: '4122', Name: 'Auditor' },
  { Id: 8005, Code: '4122', Name: 'Auditor, internal' },
  { Id: 8006, Code: '4122', Name: 'Auditor, night' },
  { Id: 8007, Code: '4122', Name: 'Bookkeeper' },
  { Id: 8008, Code: '4122', Name: 'Bookkeeper, chief' },
  { Id: 8009, Code: '4122', Name: 'Bookkeeper-cashier' },
  { Id: 8010, Code: '4122', Name: 'Bookkeeper-typist' },
  { Id: 8011, Code: '4122', Name: 'Checker, invoice' },
  { Id: 8012, Code: '4122', Name: 'Clerk, account' },
  { Id: 8013, Code: '4122', Name: 'Clerk, accountancy' },
  { Id: 8014, Code: '4122', Name: "Clerk, accountant's" },
  { Id: 8015, Code: '4122', Name: 'Clerk, accounts' },
  { Id: 8016, Code: '4122', Name: 'Clerk, audit' },
  { Id: 8017, Code: '4122', Name: 'Clerk, book-keeping' },
  { Id: 8018, Code: '4122', Name: 'Clerk, control, accounts' },
  { Id: 8019, Code: '4122', Name: 'Clerk, invoice' },
  { Id: 8020, Code: '4122', Name: 'Clerk, invoicing' },
  { Id: 8021, Code: '4122', Name: 'Clerk, keeping, book' },
  { Id: 8022, Code: '4122', Name: 'Clerk, ledger' },
  { Id: 8023, Code: '4122', Name: 'Clerk, pay' },
  { Id: 8024, Code: '4122', Name: 'Clerk, payable, accounts' },
  { Id: 8025, Code: '4122', Name: 'Clerk, payroll' },
  { Id: 8026, Code: '4122', Name: 'Clerk, salaries' },
  { Id: 8027, Code: '4122', Name: 'Clerk, wage' },
  { Id: 8028, Code: '4122', Name: 'Clerk, wages' },
  { Id: 8029, Code: '4122', Name: 'Clerk, wages and accounts' },
  { Id: 8030, Code: '4122', Name: 'Clerk-bookkeeper' },
  { Id: 8031, Code: '4122', Name: 'Controller, account' },
  { Id: 8032, Code: '4122', Name: 'Controller, accounts' },
  { Id: 8033, Code: '4122', Name: 'Controller, invoice' },
  { Id: 8034, Code: '4122', Name: 'Controller, ledger' },
  { Id: 8035, Code: '4122', Name: 'Controller, ledger, bought' },
  { Id: 8036, Code: '4122', Name: 'Controller, ledger, purchase' },
  { Id: 8037, Code: '4122', Name: 'Controller, payroll' },
  { Id: 8038, Code: '4122', Name: 'Controller, wages' },
  { Id: 8039, Code: '4122', Name: 'Inspector, accounts' },
  { Id: 8040, Code: '4122', Name: 'Investigator, accounts' },
  { Id: 8041, Code: '4122', Name: 'Invoicer' },
  { Id: 8042, Code: '4122', Name: 'Keeper, book' },
  { Id: 8043, Code: '4122', Name: 'Keeper, book, chief' },
  { Id: 8044, Code: '4122', Name: 'Keeper, ledger' },
  { Id: 8045, Code: '4122', Name: 'Manager, invoice' },
  { Id: 8046, Code: '4122', Name: 'Manager, ledger, purchase' },
  { Id: 8047, Code: '4122', Name: 'Manager, payroll' },
  { Id: 8048, Code: '4122', Name: 'Manager, payroll and pensions' },
  { Id: 8049, Code: '4122', Name: 'Manager, wages' },
  { Id: 8050, Code: '4122', Name: 'Master, pay' },
  { Id: 8051, Code: '4122', Name: 'Officer, accounts' },
  { Id: 8052, Code: '4122', Name: 'Officer, control, payroll' },
  { Id: 8053, Code: '4122', Name: 'Officer, payroll' },
  { Id: 8054, Code: '4122', Name: 'Officer, salaries' },
  { Id: 8055, Code: '4122', Name: 'Officer, wages' },
  { Id: 8056, Code: '4122', Name: 'Officer, wages and control, coal mine' },
  { Id: 8057, Code: '4122', Name: 'Paymaster' },
  { Id: 8058, Code: '4122', Name: 'Supervisor, ACCOUNTANCY' },
  { Id: 8059, Code: '4122', Name: 'Supervisor, accounting' },
  { Id: 8060, Code: '4122', Name: 'Supervisor, accounts' },
  { Id: 8061, Code: '4122', Name: 'Supervisor, audit' },
  { Id: 8062, Code: '4122', Name: 'Supervisor, ledger' },
  { Id: 8063, Code: '4122', Name: 'Supervisor, payable, accounts' },
  { Id: 8064, Code: '4122', Name: 'Supervisor, payroll' },
  { Id: 8065, Code: '4122', Name: 'Supervisor, wages' },
  { Id: 8066, Code: '4123', Name: 'Adviser, bank' },
  { Id: 8067, Code: '4123', Name: 'Adviser, banking' },
  { Id: 8068, Code: '4123', Name: 'Adviser, banking, telephone' },
  { Id: 8069, Code: '4123', Name: 'Adviser, branch, bank' },
  { Id: 8070, Code: '4123', Name: 'Adviser, branch, building society' },
  { Id: 8071, Code: '4123', Name: 'Adviser, customer, bank' },
  { Id: 8072, Code: '4123', Name: 'Adviser, customer, building society' },
  { Id: 8073, Code: '4123', Name: 'Adviser, phonebank' },
  { Id: 8074, Code: '4123', Name: 'Adviser, sales, bank' },
  { Id: 8075, Code: '4123', Name: 'Adviser, sales, building society' },
  { Id: 8076, Code: '4123', Name: 'Adviser, service, customer, bank' },
  {
    Id: 8077,
    Code: '4123',
    Name: 'Adviser, service, customer, building society'
  },
  { Id: 8078, Code: '4123', Name: 'Adviser, services, customer, bank' },
  {
    Id: 8079,
    Code: '4123',
    Name: 'Adviser, services, customer, building society'
  },
  { Id: 8080, Code: '4123', Name: 'Adviser, telebanking' },
  { Id: 8081, Code: '4123', Name: 'Assistant, bank' },
  { Id: 8082, Code: '4123', Name: 'Assistant, branch, bank' },
  { Id: 8083, Code: '4123', Name: 'Assistant, branch, building society' },
  { Id: 8084, Code: '4123', Name: 'Assistant, clerical, bank' },
  { Id: 8085, Code: '4123', Name: 'Assistant, clerical, building society' },
  { Id: 8086, Code: '4123', Name: 'Assistant, controller, banking' },
  { Id: 8087, Code: '4123', Name: 'Assistant, counter, PO' },
  { Id: 8088, Code: '4123', Name: 'Assistant, customer, bank' },
  { Id: 8089, Code: '4123', Name: 'Assistant, customer, building society' },
  { Id: 8090, Code: '4123', Name: "Assistant, inspector's, banking" },
  { Id: 8091, Code: '4123', Name: 'Assistant, office, post' },
  { Id: 8092, Code: '4123', Name: 'Assistant, postal, PO' },
  { Id: 8093, Code: '4123', Name: 'Assistant, principal, bank' },
  { Id: 8094, Code: '4123', Name: 'Assistant, principal, building society' },
  { Id: 8095, Code: '4123', Name: 'Assistant, service, customer, bank' },
  {
    Id: 8096,
    Code: '4123',
    Name: 'Assistant, service, customer, building society'
  },
  { Id: 8097, Code: '4123', Name: 'Assistant, services, client, bank' },
  {
    Id: 8098,
    Code: '4123',
    Name: 'Assistant, services, client, building society'
  },
  { Id: 8099, Code: '4123', Name: 'Assistant, services, customer, bank' },
  {
    Id: 8100,
    Code: '4123',
    Name: 'Assistant, services, customer, building society'
  },
  { Id: 8101, Code: '4123', Name: 'Assistant, services, financial, bank' },
  {
    Id: 8102,
    Code: '4123',
    Name: 'Assistant, services, financial, building society'
  },
  { Id: 8103, Code: '4123', Name: 'Associate, bank' },
  { Id: 8104, Code: '4123', Name: 'Associate, sales, banking' },
  { Id: 8105, Code: '4123', Name: 'Associate, service, customer, banking' },
  { Id: 8106, Code: '4123', Name: 'Banker, personal' },
  { Id: 8107, Code: '4123', Name: 'Banker, telephone' },
  { Id: 8108, Code: '4123', Name: 'Cashier, bank' },
  { Id: 8109, Code: '4123', Name: 'Cashier, building society' },
  { Id: 8110, Code: '4123', Name: 'Cashier, chief' },
  { Id: 8111, Code: '4123', Name: 'Cashier, office, bank' },
  { Id: 8112, Code: '4123', Name: 'Cashier, office, building society' },
  { Id: 8113, Code: '4123', Name: 'Cashier, office, PO' },
  { Id: 8114, Code: '4123', Name: 'Cashier, PO' },
  { Id: 8115, Code: '4123', Name: 'Cashier, society, building' },
  { Id: 8116, Code: '4123', Name: 'Clerk, bank' },
  { Id: 8117, Code: '4123', Name: 'Clerk, banking, bank' },
  { Id: 8118, Code: '4123', Name: 'Clerk, banking, building society' },
  { Id: 8119, Code: '4123', Name: 'Clerk, building society' },
  { Id: 8120, Code: '4123', Name: 'Clerk, chief, bank' },
  { Id: 8121, Code: '4123', Name: 'Clerk, chief, building society' },
  { Id: 8122, Code: '4123', Name: 'Clerk, counter, bank' },
  { Id: 8123, Code: '4123', Name: 'Clerk, counter, building society' },
  { Id: 8124, Code: '4123', Name: 'Clerk, counter, office, post' },
  { Id: 8125, Code: '4123', Name: 'Clerk, counter, post office' },
  {
    Id: 8126,
    Code: '4123',
    Name: 'Clerk, exchange, foreign, financial services'
  },
  { Id: 8127, Code: '4123', Name: 'Clerk, office, post' },
  { Id: 8128, Code: '4123', Name: 'Clerk, PO' },
  { Id: 8129, Code: '4123', Name: 'Clerk, service, customer, bank' },
  {
    Id: 8130,
    Code: '4123',
    Name: 'Clerk, service, customer, building society'
  },
  { Id: 8131, Code: '4123', Name: 'Clerk, services, customer, bank' },
  {
    Id: 8132,
    Code: '4123',
    Name: 'Clerk, services, customer, building society'
  },
  { Id: 8133, Code: '4123', Name: 'Clerk, society, building' },
  { Id: 8134, Code: '4123', Name: 'Controller, lending, bank' },
  { Id: 8135, Code: '4123', Name: 'Controller, lending, building society' },
  { Id: 8136, Code: '4123', Name: 'Counsellor, bank' },
  { Id: 8137, Code: '4123', Name: 'Counsellor, building society' },
  { Id: 8138, Code: '4123', Name: 'Employee, bank' },
  { Id: 8139, Code: '4123', Name: 'Leader, team, bank' },
  { Id: 8140, Code: '4123', Name: 'Leader, team, building society' },
  { Id: 8141, Code: '4123', Name: 'Manager, counter, bank' },
  { Id: 8142, Code: '4123', Name: 'Manager, counter, post office' },
  { Id: 8143, Code: '4123', Name: 'Master, post' },
  { Id: 8144, Code: '4123', Name: 'Master, post, sub' },
  { Id: 8145, Code: '4123', Name: 'Master, sub-post' },
  { Id: 8146, Code: '4123', Name: 'Officer, bank' },
  { Id: 8147, Code: '4123', Name: 'Officer, clerical, bank' },
  { Id: 8148, Code: '4123', Name: 'Officer, clerical, building society' },
  { Id: 8149, Code: '4123', Name: 'Officer, commercial, bank' },
  { Id: 8150, Code: '4123', Name: 'Officer, commercial, building society' },
  { Id: 8151, Code: '4123', Name: 'Officer, lending, bank' },
  { Id: 8152, Code: '4123', Name: 'Officer, lending, building society' },
  { Id: 8153, Code: '4123', Name: 'Officer, loans, bank' },
  { Id: 8154, Code: '4123', Name: 'Officer, loans, building society' },
  { Id: 8155, Code: '4123', Name: 'Officer, postal, PO' },
  { Id: 8156, Code: '4123', Name: 'Officer, relations, customer, bank' },
  {
    Id: 8157,
    Code: '4123',
    Name: 'Officer, relations, customer, building society'
  },
  { Id: 8158, Code: '4123', Name: 'Officer, second, banking' },
  { Id: 8159, Code: '4123', Name: 'Officer, securities, banking' },
  { Id: 8160, Code: '4123', Name: 'Officer, service, customer, bank' },
  {
    Id: 8161,
    Code: '4123',
    Name: 'Officer, service, customer, building society'
  },
  { Id: 8162, Code: '4123', Name: 'Officer, services, customer, bank' },
  {
    Id: 8163,
    Code: '4123',
    Name: 'Officer, services, customer, building society'
  },
  { Id: 8164, Code: '4123', Name: 'Officer-in-charge, sub-post office' },
  { Id: 8165, Code: '4123', Name: 'Official, bank' },
  { Id: 8166, Code: '4123', Name: 'Postmaster' },
  { Id: 8167, Code: '4123', Name: 'Postmistress' },
  { Id: 8168, Code: '4123', Name: 'Processor, cash' },
  { Id: 8169, Code: '4123', Name: 'Processor, claims' },
  { Id: 8170, Code: '4123', Name: 'Representative, service, customer, bank' },
  {
    Id: 8171,
    Code: '4123',
    Name: 'Representative, service, customer, building society'
  },
  { Id: 8172, Code: '4123', Name: 'Representative, services, customer, bank' },
  {
    Id: 8173,
    Code: '4123',
    Name: 'Representative, services, customer, building society'
  },
  { Id: 8174, Code: '4123', Name: 'Sub-postmaster' },
  { Id: 8175, Code: '4123', Name: 'Superintendent, banking' },
  { Id: 8176, Code: '4123', Name: 'Superintendent, PO' },
  { Id: 8177, Code: '4123', Name: 'Supervisor, bank' },
  { Id: 8178, Code: '4123', Name: 'Supervisor, banking' },
  { Id: 8179, Code: '4123', Name: 'Supervisor, building society' },
  { Id: 8180, Code: '4123', Name: 'Supervisor, claims' },
  { Id: 8181, Code: '4123', Name: 'Supervisor, service, customer, bank' },
  {
    Id: 8182,
    Code: '4123',
    Name: 'Supervisor, service, customer, building society'
  },
  { Id: 8183, Code: '4123', Name: 'Supervisor, services, customer, bank' },
  {
    Id: 8184,
    Code: '4123',
    Name: 'Supervisor, services, customer, building society'
  },
  { Id: 8185, Code: '4123', Name: 'Teller, banking' },
  { Id: 8186, Code: '4124', Name: 'Officer, finance' },
  { Id: 8187, Code: '4124', Name: 'Officer, finance, deputy' },
  { Id: 8188, Code: '4124', Name: 'Officer, finance, regional, PO' },
  { Id: 8189, Code: '4124', Name: 'Officer, financial, local government' },
  { Id: 8190, Code: '4129', Name: 'Administrator, account' },
  { Id: 8191, Code: '4129', Name: 'Administrator, bonus' },
  { Id: 8192, Code: '4129', Name: 'Administrator, cost' },
  { Id: 8193, Code: '4129', Name: 'Administrator, finance' },
  { Id: 8194, Code: '4129', Name: 'Administrator, financial' },
  { Id: 8195, Code: '4129', Name: 'Administrator, financial services' },
  { Id: 8196, Code: '4129', Name: 'Administrator, mortgage' },
  { Id: 8197, Code: '4129', Name: 'Administrator, services, financial' },
  { Id: 8198, Code: '4129', Name: 'Administrator, wrap' },
  { Id: 8199, Code: '4129', Name: 'Analyst, billing' },
  { Id: 8200, Code: '4129', Name: 'Analyst, cost' },
  { Id: 8201, Code: '4129', Name: 'Assessor, bonus' },
  { Id: 8202, Code: '4129', Name: "Assistant, accountant's, turf" },
  { Id: 8203, Code: '4129', Name: "Assistant, agent's, commission" },
  { Id: 8204, Code: '4129', Name: 'Assistant, audit' },
  { Id: 8205, Code: '4129', Name: 'Assistant, budget' },
  { Id: 8206, Code: '4129', Name: "Assistant, bursar's" },
  { Id: 8207, Code: '4129', Name: "Assistant, cashier's" },
  { Id: 8208, Code: '4129', Name: 'Assistant, control, investment company' },
  { Id: 8209, Code: '4129', Name: 'Assistant, cost' },
  { Id: 8210, Code: '4129', Name: 'Assistant, costing' },
  {
    Id: 8211,
    Code: '4129',
    Name: 'Assistant, counter, bookmakers, turf accountants'
  },
  { Id: 8212, Code: '4129', Name: 'Assistant, dealer�s, financial services' },
  { Id: 8213, Code: '4129', Name: 'Assistant, estimating' },
  { Id: 8214, Code: '4129', Name: "Assistant, estimator's" },
  { Id: 8215, Code: '4129', Name: 'Assistant, exchequer' },
  { Id: 8216, Code: '4129', Name: 'Assistant, finance' },
  { Id: 8217, Code: '4129', Name: 'Assistant, financial' },
  { Id: 8218, Code: '4129', Name: 'Assistant, fund' },
  { Id: 8219, Code: '4129', Name: 'Assistant, holding, fund' },
  { Id: 8220, Code: '4129', Name: 'Assistant, income' },
  { Id: 8221, Code: '4129', Name: "Assistant, manager's, betting shop" },
  { Id: 8222, Code: '4129', Name: 'Assistant, mortgage' },
  { Id: 8223, Code: '4129', Name: 'Assistant, office, box' },
  { Id: 8224, Code: '4129', Name: 'Assistant, office, cash' },
  { Id: 8225, Code: '4129', Name: 'Assistant, office, ticket' },
  { Id: 8226, Code: '4129', Name: 'Assistant, revenue' },
  { Id: 8227, Code: '4129', Name: 'Assistant, rights, broadcasting' },
  { Id: 8228, Code: '4129', Name: 'Assistant, rights, publishing' },
  { Id: 8229, Code: '4129', Name: 'Assistant, settlement' },
  { Id: 8230, Code: '4129', Name: 'Assistant, tax' },
  { Id: 8231, Code: '4129', Name: 'Assistant, taxation' },
  { Id: 8232, Code: '4129', Name: "Assistant, timekeeper's" },
  { Id: 8233, Code: '4129', Name: "Assistant, treasurer's" },
  { Id: 8234, Code: '4129', Name: 'Assistant, treasury' },
  { Id: 8235, Code: '4129', Name: 'Assistant, valuation' },
  { Id: 8236, Code: '4129', Name: 'Cashier' },
  { Id: 8237, Code: '4129', Name: 'Cashier, bookmakers, turf accountants' },
  { Id: 8238, Code: '4129', Name: 'Cashier, estate agents' },
  { Id: 8239, Code: '4129', Name: 'Cashier, exchange, foreign' },
  { Id: 8240, Code: '4129', Name: 'Cashier, government' },
  { Id: 8241, Code: '4129', Name: 'Cashier, hospital' },
  { Id: 8242, Code: '4129', Name: 'Cashier, hospital service' },
  { Id: 8243, Code: '4129', Name: 'Cashier, insurance' },
  { Id: 8244, Code: '4129', Name: 'Cashier, legal' },
  { Id: 8245, Code: '4129', Name: 'Cashier, legal services' },
  { Id: 8246, Code: '4129', Name: 'Cashier, local government' },
  { Id: 8247, Code: '4129', Name: 'Cashier, office' },
  {
    Id: 8248,
    Code: '4129',
    Name: 'Cashier, office, bookmakers, turf accountants'
  },
  { Id: 8249, Code: '4129', Name: 'Cashier, office, local government' },
  { Id: 8250, Code: '4129', Name: 'Cashier, school' },
  { Id: 8251, Code: '4129', Name: 'Cashier, shop, betting' },
  { Id: 8252, Code: '4129', Name: 'Cashier-receptionist' },
  { Id: 8253, Code: '4129', Name: 'Checker, time' },
  { Id: 8254, Code: '4129', Name: "Clerk, accountant's, turf" },
  { Id: 8255, Code: '4129', Name: 'Clerk, allocation, cash' },
  { Id: 8256, Code: '4129', Name: 'Clerk, banking' },
  { Id: 8257, Code: '4129', Name: 'Clerk, bill' },
  { Id: 8258, Code: '4129', Name: 'Clerk, billing' },
  { Id: 8259, Code: '4129', Name: 'Clerk, bonus' },
  { Id: 8260, Code: '4129', Name: "Clerk, bookmaker's" },
  { Id: 8261, Code: '4129', Name: 'Clerk, cash' },
  { Id: 8262, Code: '4129', Name: "Clerk, cashier's" },
  { Id: 8263, Code: '4129', Name: 'Clerk, claims' },
  { Id: 8264, Code: '4129', Name: 'Clerk, cost' },
  { Id: 8265, Code: '4129', Name: 'Clerk, costing' },
  { Id: 8266, Code: '4129', Name: 'Clerk, counter' },
  { Id: 8267, Code: '4129', Name: 'Clerk, credit card company' },
  { Id: 8268, Code: '4129', Name: 'Clerk, finance' },
  { Id: 8269, Code: '4129', Name: 'Clerk, financial' },
  { Id: 8270, Code: '4129', Name: 'Clerk, mortgage' },
  { Id: 8271, Code: '4129', Name: 'Clerk, office, box' },
  { Id: 8272, Code: '4129', Name: 'Clerk, office, cash' },
  { Id: 8273, Code: '4129', Name: 'Clerk, office, ticket' },
  { Id: 8274, Code: '4129', Name: 'Clerk, payments' },
  { Id: 8275, Code: '4129', Name: 'Clerk, reconciliation, bank' },
  { Id: 8276, Code: '4129', Name: 'Clerk, security' },
  { Id: 8277, Code: '4129', Name: 'Clerk, settlements' },
  { Id: 8278, Code: '4129', Name: 'Clerk, settlements, exchange, foreign' },
  { Id: 8279, Code: '4129', Name: 'Clerk, stockbrokers' },
  { Id: 8280, Code: '4129', Name: "Clerk, stockbroker's" },
  { Id: 8281, Code: '4129', Name: 'Clerk, time' },
  { Id: 8282, Code: '4129', Name: 'Clerk, valuation' },
  { Id: 8283, Code: '4129', Name: 'Clerk-cashier' },
  { Id: 8284, Code: '4129', Name: 'Collector, ENTERTAINMENT' },
  { Id: 8285, Code: '4129', Name: 'Consultant, cost' },
  { Id: 8286, Code: '4129', Name: 'Consultant, sales, bureau de change' },
  { Id: 8287, Code: '4129', Name: 'Consultant, sales, exchange, foreign' },
  { Id: 8288, Code: '4129', Name: 'Controller, budget' },
  { Id: 8289, Code: '4129', Name: 'Controller, cash' },
  { Id: 8290, Code: '4129', Name: 'Controller, cost' },
  { Id: 8291, Code: '4129', Name: 'Controller, mortgage' },
  { Id: 8292, Code: '4129', Name: 'Controller, price' },
  { Id: 8293, Code: '4129', Name: 'Coordinator, account' },
  { Id: 8294, Code: '4129', Name: 'Counterhand, bookmakers, turf accountants' },
  { Id: 8295, Code: '4129', Name: 'Counterman, turf accountants' },
  { Id: 8296, Code: '4129', Name: 'Handler, cash' },
  { Id: 8297, Code: '4129', Name: 'Inspector, revenue, water company' },
  { Id: 8298, Code: '4129', Name: 'Keeper, time' },
  { Id: 8299, Code: '4129', Name: 'Measurer, coal mine' },
  { Id: 8300, Code: '4129', Name: 'Officer, administration, finance' },
  { Id: 8301, Code: '4129', Name: 'Officer, banking, corporate' },
  { Id: 8302, Code: '4129', Name: 'Officer, budget' },
  { Id: 8303, Code: '4129', Name: 'Officer, compensation, coal mine' },
  { Id: 8304, Code: '4129', Name: 'Officer, control, budget' },
  { Id: 8305, Code: '4129', Name: 'Officer, grants' },
  { Id: 8306, Code: '4129', Name: 'Officer, loans, student' },
  { Id: 8307, Code: '4129', Name: 'Officer, mortgage' },
  { Id: 8308, Code: '4129', Name: 'Officer, pricing' },
  { Id: 8309, Code: '4129', Name: 'Officer, recovery, local government' },
  { Id: 8310, Code: '4129', Name: 'Officer, revenue' },
  { Id: 8311, Code: '4129', Name: 'Officer, rights, broadcasting' },
  { Id: 8312, Code: '4129', Name: 'Officer, rights, publishing' },
  { Id: 8313, Code: '4129', Name: 'Officer, stamping' },
  { Id: 8314, Code: '4129', Name: 'Operator, totalisator' },
  { Id: 8315, Code: '4129', Name: 'Operator, tote' },
  { Id: 8316, Code: '4129', Name: 'Processor, mortgage' },
  { Id: 8317, Code: '4129', Name: 'Receptionist-cashier' },
  { Id: 8318, Code: '4129', Name: 'Recorder, time' },
  { Id: 8319, Code: '4129', Name: "Representative, banker's" },
  { Id: 8320, Code: '4129', Name: 'Representative, banking' },
  { Id: 8321, Code: '4129', Name: 'Salesman, ticket' },
  { Id: 8322, Code: '4129', Name: 'Seller, betting and gaming, TOTALISATOR' },
  { Id: 8323, Code: '4129', Name: 'Seller, ticket' },
  { Id: 8324, Code: '4129', Name: 'Settler, BETTING' },
  { Id: 8325, Code: '4129', Name: 'Timekeeper' },
  { Id: 8326, Code: '4129', Name: 'Treasurer' },
  { Id: 8327, Code: '4129', Name: 'Verifier, stock' },
  { Id: 8328, Code: '4129', Name: 'Worker, shop, turf accountants' },
  { Id: 8329, Code: '4131', Name: 'Administrator, conference' },
  { Id: 8330, Code: '4131', Name: 'Administrator, contract' },
  { Id: 8331, Code: '4131', Name: 'Administrator, court' },
  { Id: 8332, Code: '4131', Name: 'Administrator, courts' },
  { Id: 8333, Code: '4131', Name: 'Administrator, events' },
  { Id: 8334, Code: '4131', Name: 'Administrator, examinations' },
  { Id: 8335, Code: '4131', Name: 'Administrator, export' },
  { Id: 8336, Code: '4131', Name: 'Administrator, letter, PO' },
  { Id: 8337, Code: '4131', Name: 'Administrator, practice' },
  { Id: 8338, Code: '4131', Name: 'Administrator, procurement' },
  { Id: 8339, Code: '4131', Name: 'Administrator, purchase' },
  { Id: 8340, Code: '4131', Name: 'Administrator, purchasing' },
  { Id: 8341, Code: '4131', Name: 'Administrator, technical' },
  { Id: 8342, Code: '4131', Name: 'Administrator, ward' },
  { Id: 8343, Code: '4131', Name: 'Allocator' },
  { Id: 8344, Code: '4131', Name: 'Assistant, admissions, college' },
  { Id: 8345, Code: '4131', Name: "Assistant, buyer's" },
  { Id: 8346, Code: '4131', Name: 'Assistant, buying' },
  { Id: 8347, Code: '4131', Name: "Assistant, clerk's, justices" },
  { Id: 8348, Code: '4131', Name: 'Assistant, contracts' },
  { Id: 8349, Code: '4131', Name: 'Assistant, control, production' },
  { Id: 8350, Code: '4131', Name: 'Assistant, control, quality, CLERICAL' },
  { Id: 8351, Code: '4131', Name: 'Assistant, conveyancing' },
  { Id: 8352, Code: '4131', Name: 'Assistant, correspondence' },
  { Id: 8353, Code: '4131', Name: 'Assistant, employment' },
  { Id: 8354, Code: '4131', Name: 'Assistant, establishment' },
  { Id: 8355, Code: '4131', Name: 'Assistant, export' },
  { Id: 8356, Code: '4131', Name: 'Assistant, fingerprint' },
  { Id: 8357, Code: '4131', Name: 'Assistant, litigation' },
  { Id: 8358, Code: '4131', Name: 'Assistant, lottery' },
  { Id: 8359, Code: '4131', Name: 'Assistant, production, publishing' },
  { Id: 8360, Code: '4131', Name: 'Assistant, progress' },
  { Id: 8361, Code: '4131', Name: 'Assistant, purchasing' },
  { Id: 8362, Code: '4131', Name: 'Assistant, records' },
  { Id: 8363, Code: '4131', Name: "Assistant, registrar's" },
  { Id: 8364, Code: '4131', Name: 'Assistant, room, publishing' },
  { Id: 8365, Code: '4131', Name: "Assistant, solicitor's" },
  { Id: 8366, Code: '4131', Name: 'Assistant, staff' },
  { Id: 8367, Code: '4131', Name: 'Assistant, study, time' },
  { Id: 8368, Code: '4131', Name: 'Assistant, study, work' },
  { Id: 8369, Code: '4131', Name: 'Canvasser, elections' },
  { Id: 8370, Code: '4131', Name: 'Cataloguer' },
  { Id: 8371, Code: '4131', Name: 'Chartist' },
  { Id: 8372, Code: '4131', Name: 'Checker, building construction' },
  { Id: 8373, Code: '4131', Name: 'Checker, coupon, COMPETITIONS' },
  { Id: 8374, Code: '4131', Name: 'Checker, docks' },
  { Id: 8375, Code: '4131', Name: 'Checker, oil refining' },
  { Id: 8376, Code: '4131', Name: 'Checker, progress' },
  { Id: 8377, Code: '4131', Name: 'Checker, wholesale, retail trade' },
  { Id: 8378, Code: '4131', Name: 'Clerk, actuarial' },
  { Id: 8379, Code: '4131', Name: 'Clerk, admissions' },
  { Id: 8380, Code: '4131', Name: 'Clerk, advertising' },
  { Id: 8381, Code: '4131', Name: 'Clerk, advertising, publicity' },
  { Id: 8382, Code: '4131', Name: 'Clerk, allocator' },
  { Id: 8383, Code: '4131', Name: 'Clerk, appointments, hospital service' },
  { Id: 8384, Code: '4131', Name: 'Clerk, assurance, quality' },
  { Id: 8385, Code: '4131', Name: 'Clerk, booking' },
  { Id: 8386, Code: '4131', Name: 'Clerk, charge' },
  { Id: 8387, Code: '4131', Name: 'Clerk, clearance' },
  { Id: 8388, Code: '4131', Name: 'Clerk, clinic' },
  { Id: 8389, Code: '4131', Name: 'Clerk, coding' },
  { Id: 8390, Code: '4131', Name: 'Clerk, college' },
  { Id: 8391, Code: '4131', Name: 'Clerk, compilation' },
  { Id: 8392, Code: '4131', Name: 'Clerk, control, production' },
  { Id: 8393, Code: '4131', Name: 'Clerk, control, quality' },
  { Id: 8394, Code: '4131', Name: 'Clerk, conveyancing' },
  { Id: 8395, Code: '4131', Name: 'Clerk, correspondence' },
  { Id: 8396, Code: '4131', Name: 'Clerk, course, betting' },
  { Id: 8397, Code: '4131', Name: 'Clerk, filing' },
  { Id: 8398, Code: '4131', Name: 'Clerk, fingerprint' },
  { Id: 8399, Code: '4131', Name: 'Clerk, hire' },
  { Id: 8400, Code: '4131', Name: 'Clerk, hospital' },
  { Id: 8401, Code: '4131', Name: 'Clerk, hospital service' },
  { Id: 8402, Code: '4131', Name: 'Clerk, intake' },
  { Id: 8403, Code: '4131', Name: 'Clerk, law' },
  { Id: 8404, Code: '4131', Name: 'Clerk, list, waiting, hospital service' },
  { Id: 8405, Code: '4131', Name: 'Clerk, litigation' },
  { Id: 8406, Code: '4131', Name: 'Clerk, manifest' },
  { Id: 8407, Code: '4131', Name: 'Clerk, office, booking' },
  { Id: 8408, Code: '4131', Name: 'Clerk, office, buying' },
  { Id: 8409, Code: '4131', Name: 'Clerk, planning' },
  { Id: 8410, Code: '4131', Name: 'Clerk, pool' },
  { Id: 8411, Code: '4131', Name: 'Clerk, pools' },
  { Id: 8412, Code: '4131', Name: 'Clerk, prescription' },
  { Id: 8413, Code: '4131', Name: 'Clerk, purchase' },
  { Id: 8414, Code: '4131', Name: 'Clerk, purchasing' },
  { Id: 8415, Code: '4131', Name: 'Clerk, records' },
  { Id: 8416, Code: '4131', Name: 'Clerk, schedule' },
  { Id: 8417, Code: '4131', Name: "Clerk, solicitor's" },
  { Id: 8418, Code: '4131', Name: 'Clerk, staff' },
  { Id: 8419, Code: '4131', Name: 'Clerk, statistical' },
  { Id: 8420, Code: '4131', Name: 'Clerk, statistics' },
  { Id: 8421, Code: '4131', Name: 'Clerk, tally' },
  { Id: 8422, Code: '4131', Name: 'Clerk, technical' },
  { Id: 8423, Code: '4131', Name: 'Clerk, university' },
  { Id: 8424, Code: '4131', Name: 'Clerk, ward' },
  { Id: 8425, Code: '4131', Name: 'Clerk, warranty' },
  { Id: 8426, Code: '4131', Name: 'Clerk-buyer' },
  { Id: 8427, Code: '4131', Name: 'Clerk-typist, college' },
  { Id: 8428, Code: '4131', Name: 'Clerk-typist, university' },
  { Id: 8429, Code: '4131', Name: 'Coder' },
  { Id: 8430, Code: '4131', Name: 'Compiler, catalogue' },
  { Id: 8431, Code: '4131', Name: 'Compiler, directory' },
  { Id: 8432, Code: '4131', Name: 'Compiler, index' },
  { Id: 8433, Code: '4131', Name: 'Controller, contract' },
  { Id: 8434, Code: '4131', Name: 'Controller, contracts' },
  { Id: 8435, Code: '4131', Name: 'Controller, document' },
  { Id: 8436, Code: '4131', Name: 'Controller, documentation' },
  { Id: 8437, Code: '4131', Name: 'Controller, progress' },
  { Id: 8438, Code: '4131', Name: 'Controller, schedule' },
  { Id: 8439, Code: '4131', Name: 'Controller, waste' },
  { Id: 8440, Code: '4131', Name: 'Correspondent, banking' },
  { Id: 8441, Code: '4131', Name: 'Detailer, duty' },
  { Id: 8442, Code: '4131', Name: 'Detailer, staff' },
  { Id: 8443, Code: '4131', Name: 'Enumerator, census' },
  { Id: 8444, Code: '4131', Name: 'Expeditor' },
  { Id: 8445, Code: '4131', Name: 'Indexer' },
  { Id: 8446, Code: '4131', Name: 'Interviewer, agency, employment' },
  { Id: 8447, Code: '4131', Name: 'Linesman, progress' },
  { Id: 8448, Code: '4131', Name: 'Logger, court' },
  { Id: 8449, Code: '4131', Name: 'Measurer, docks' },
  { Id: 8450, Code: '4131', Name: 'Officer, admission, hospital service' },
  { Id: 8451, Code: '4131', Name: 'Officer, admissions' },
  { Id: 8452, Code: '4131', Name: 'Officer, allocation' },
  { Id: 8453, Code: '4131', Name: 'Officer, clerical, hospital service' },
  { Id: 8454, Code: '4131', Name: 'Officer, control, production' },
  { Id: 8455, Code: '4131', Name: 'Officer, court' },
  { Id: 8456, Code: '4131', Name: 'Officer, examinations, EDUCATION' },
  { Id: 8457, Code: '4131', Name: 'Officer, fingerprint, civilian' },
  { Id: 8458, Code: '4131', Name: 'Officer, lettings' },
  { Id: 8459, Code: '4131', Name: 'Officer, magisterial' },
  { Id: 8460, Code: '4131', Name: 'Officer, progress' },
  { Id: 8461, Code: '4131', Name: 'Officer, properties, police service' },
  { Id: 8462, Code: '4131', Name: 'Officer, property, police service' },
  { Id: 8463, Code: '4131', Name: 'Officer, records' },
  { Id: 8464, Code: '4131', Name: 'Officer, records, medical' },
  { Id: 8465, Code: '4131', Name: 'Officer, search' },
  {
    Id: 8466,
    Code: '4131',
    Name: 'Officer, statistical, electricity supplier'
  },
  { Id: 8467, Code: '4131', Name: 'Officer, statistical, gas supplier' },
  { Id: 8468, Code: '4131', Name: 'Officer, warranty' },
  { Id: 8469, Code: '4131', Name: 'Official, court' },
  { Id: 8470, Code: '4131', Name: 'Official, PO' },
  { Id: 8471, Code: '4131', Name: 'Planner, work' },
  { Id: 8472, Code: '4131', Name: 'Planner, works' },
  { Id: 8473, Code: '4131', Name: 'Progressor' },
  { Id: 8474, Code: '4131', Name: 'Receptionist, ward' },
  { Id: 8475, Code: '4131', Name: 'Recorder of work' },
  { Id: 8476, Code: '4131', Name: 'Recorder, milk' },
  { Id: 8477, Code: '4131', Name: 'Recorder, MILK TESTING' },
  { Id: 8478, Code: '4131', Name: 'Recorder, progress' },
  { Id: 8479, Code: '4131', Name: 'Representative, dock' },
  { Id: 8480, Code: '4131', Name: 'Scheduler' },
  { Id: 8481, Code: '4131', Name: 'Scheduler, cell' },
  { Id: 8482, Code: '4131', Name: 'Scheduler, production' },
  { Id: 8483, Code: '4131', Name: 'Summariser, tape, police service' },
  { Id: 8484, Code: '4131', Name: 'Supporter, court' },
  { Id: 8485, Code: '4132', Name: 'Administrator, claim, insurance' },
  { Id: 8486, Code: '4132', Name: 'Administrator, insurance' },
  { Id: 8487, Code: '4132', Name: 'Administrator, life, insurance' },
  { Id: 8488, Code: '4132', Name: 'Administrator, pensions' },
  { Id: 8489, Code: '4132', Name: 'Administrator, SIPP' },
  { Id: 8490, Code: '4132', Name: 'Administrator, surrenders, insurance' },
  { Id: 8491, Code: '4132', Name: 'Adviser, claim, insurance' },
  { Id: 8492, Code: '4132', Name: 'Adviser, claims, personal' },
  { Id: 8493, Code: '4132', Name: 'Adviser, insurance' },
  { Id: 8494, Code: '4132', Name: 'Assistant, admin, insurance' },
  { Id: 8495, Code: '4132', Name: 'Assistant, administration, insurance' },
  { Id: 8496, Code: '4132', Name: 'Assistant, administrative, insurance' },
  { Id: 8497, Code: '4132', Name: 'Assistant, claims' },
  { Id: 8498, Code: '4132', Name: 'Assistant, clerical, insurance' },
  { Id: 8499, Code: '4132', Name: 'Assistant, divisional, insurance' },
  { Id: 8500, Code: '4132', Name: 'Assistant, executive, insurance' },
  { Id: 8501, Code: '4132', Name: 'Assistant, insurance' },
  { Id: 8502, Code: '4132', Name: 'Assistant, pensions' },
  { Id: 8503, Code: '4132', Name: 'Assistant, registration, insurance' },
  { Id: 8504, Code: '4132', Name: "Assistant, underwriter's" },
  { Id: 8505, Code: '4132', Name: 'Assistant, underwriting' },
  { Id: 8506, Code: '4132', Name: 'Auditor, insurance' },
  { Id: 8507, Code: '4132', Name: "Clerk, adjuster's, average" },
  { Id: 8508, Code: '4132', Name: 'Clerk, administration, insurance' },
  { Id: 8509, Code: '4132', Name: 'Clerk, assurance' },
  { Id: 8510, Code: '4132', Name: 'Clerk, assurance company' },
  { Id: 8511, Code: '4132', Name: 'Clerk, chief, insurance' },
  { Id: 8512, Code: '4132', Name: 'Clerk, claims, insurance' },
  { Id: 8513, Code: '4132', Name: 'Clerk, estimating, insurance' },
  { Id: 8514, Code: '4132', Name: 'Clerk, insurance' },
  { Id: 8515, Code: '4132', Name: 'Clerk, office, insurance' },
  { Id: 8516, Code: '4132', Name: 'Clerk, pensions' },
  { Id: 8517, Code: '4132', Name: 'Correspondent, claims' },
  { Id: 8518, Code: '4132', Name: 'Examiner, policy, insurance' },
  { Id: 8519, Code: '4132', Name: 'Handler, claims' },
  { Id: 8520, Code: '4132', Name: 'Negotiator, pensions' },
  { Id: 8521, Code: '4132', Name: 'Officer, admin, insurance' },
  { Id: 8522, Code: '4132', Name: 'Officer, administration, insurance' },
  { Id: 8523, Code: '4132', Name: 'Officer, administrative, insurance' },
  { Id: 8524, Code: '4132', Name: 'Officer, clerical, insurance' },
  { Id: 8525, Code: '4132', Name: 'Officer, executive, insurance' },
  { Id: 8526, Code: '4132', Name: 'Officer, insurance' },
  { Id: 8527, Code: '4132', Name: 'Officer, pensions' },
  { Id: 8528, Code: '4132', Name: 'Officer, superannuation' },
  { Id: 8529, Code: '4132', Name: 'Official, insurance' },
  { Id: 8530, Code: '4132', Name: 'Processor, claims, insurance' },
  { Id: 8531, Code: '4132', Name: 'Superintendent, administrative, insurance' },
  { Id: 8532, Code: '4132', Name: 'Supervisor, insurance' },
  { Id: 8533, Code: '4132', Name: 'Technician, claims' },
  { Id: 8534, Code: '4132', Name: 'Technician, insurance' },
  { Id: 8535, Code: '4132', Name: 'Technician, motor, insurance' },
  { Id: 8536, Code: '4132', Name: 'Technician, pensions' },
  { Id: 8537, Code: '4132', Name: 'Technician, review, pensions' },
  { Id: 8538, Code: '4132', Name: 'Worker, clerical, insurance' },
  { Id: 8539, Code: '4133', Name: 'Administrator, inventory' },
  { Id: 8540, Code: '4133', Name: 'Administrator, parts' },
  { Id: 8541, Code: '4133', Name: 'Administrator, production' },
  { Id: 8542, Code: '4133', Name: 'Administrator, stock' },
  { Id: 8543, Code: '4133', Name: 'Administrator, stores' },
  { Id: 8544, Code: '4133', Name: 'Administrator, warehouse' },
  { Id: 8545, Code: '4133', Name: 'Analyst, inventory' },
  { Id: 8546, Code: '4133', Name: 'Assistant, chain, supply' },
  { Id: 8547, Code: '4133', Name: 'Assistant, control, stock' },
  { Id: 8548, Code: '4133', Name: 'Assistant, materials' },
  { Id: 8549, Code: '4133', Name: 'Assistant, merchandise' },
  { Id: 8550, Code: '4133', Name: 'Assistant, operations, STOCK CONTROL' },
  { Id: 8551, Code: '4133', Name: 'Assistant, pharmacy, hospital service' },
  { Id: 8552, Code: '4133', Name: "Assistant, publisher's" },
  { Id: 8553, Code: '4133', Name: 'Assistant, room, stock' },
  { Id: 8554, Code: '4133', Name: 'Assistant, room, store' },
  { Id: 8555, Code: '4133', Name: 'Assistant, stock' },
  { Id: 8556, Code: '4133', Name: 'Auditor, stock' },
  { Id: 8557, Code: '4133', Name: 'Chaser, manufacturing' },
  { Id: 8558, Code: '4133', Name: 'Chaser, production' },
  { Id: 8559, Code: '4133', Name: 'Chaser, progress' },
  { Id: 8560, Code: '4133', Name: 'Chaser, stock' },
  { Id: 8561, Code: '4133', Name: 'Clerk, booking, stores' },
  { Id: 8562, Code: '4133', Name: 'Clerk, booking, warehouse' },
  { Id: 8563, Code: '4133', Name: 'Clerk, control, inventory' },
  { Id: 8564, Code: '4133', Name: 'Clerk, control, material' },
  { Id: 8565, Code: '4133', Name: 'Clerk, control, stock' },
  { Id: 8566, Code: '4133', Name: 'Clerk, depot' },
  { Id: 8567, Code: '4133', Name: 'Clerk, despatch' },
  { Id: 8568, Code: '4133', Name: 'Clerk, goods-in' },
  { Id: 8569, Code: '4133', Name: 'Clerk, in, goods' },
  { Id: 8570, Code: '4133', Name: 'Clerk, inventory' },
  { Id: 8571, Code: '4133', Name: 'Clerk, inward, goods' },
  { Id: 8572, Code: '4133', Name: 'Clerk, inwards, goods' },
  { Id: 8573, Code: '4133', Name: 'Clerk, order' },
  { Id: 8574, Code: '4133', Name: 'Clerk, pricing' },
  { Id: 8575, Code: '4133', Name: 'Clerk, processing, order' },
  { Id: 8576, Code: '4133', Name: 'Clerk, production' },
  { Id: 8577, Code: '4133', Name: 'Clerk, progress' },
  { Id: 8578, Code: '4133', Name: 'Clerk, records, stock' },
  { Id: 8579, Code: '4133', Name: 'Clerk, records, stores' },
  { Id: 8580, Code: '4133', Name: 'Clerk, stock' },
  { Id: 8581, Code: '4133', Name: 'Clerk, stockroom' },
  { Id: 8582, Code: '4133', Name: "Clerk, storekeeper's" },
  { Id: 8583, Code: '4133', Name: 'Clerk, stores' },
  { Id: 8584, Code: '4133', Name: 'Clerk, tally, timber' },
  { Id: 8585, Code: '4133', Name: 'Clerk, warehouse' },
  { Id: 8586, Code: '4133', Name: 'Clerk-storekeeper' },
  { Id: 8587, Code: '4133', Name: 'Clerk-storeman' },
  { Id: 8588, Code: '4133', Name: 'Compiler, order' },
  { Id: 8589, Code: '4133', Name: 'Controller, accounting, stock' },
  { Id: 8590, Code: '4133', Name: 'Controller, depot' },
  { Id: 8591, Code: '4133', Name: 'Controller, despatch' },
  { Id: 8592, Code: '4133', Name: 'Controller, goods' },
  { Id: 8593, Code: '4133', Name: 'Controller, inventory' },
  { Id: 8594, Code: '4133', Name: 'Controller, manufacturing' },
  { Id: 8595, Code: '4133', Name: 'Controller, material' },
  { Id: 8596, Code: '4133', Name: 'Controller, materials' },
  { Id: 8597, Code: '4133', Name: 'Controller, order' },
  { Id: 8598, Code: '4133', Name: 'Controller, pallet' },
  { Id: 8599, Code: '4133', Name: 'Controller, parts' },
  { Id: 8600, Code: '4133', Name: 'Controller, planning' },
  { Id: 8601, Code: '4133', Name: 'Controller, programme' },
  { Id: 8602, Code: '4133', Name: 'Controller, room, stock' },
  { Id: 8603, Code: '4133', Name: 'Controller, sabre' },
  { Id: 8604, Code: '4133', Name: 'Controller, shift' },
  { Id: 8605, Code: '4133', Name: 'Controller, shop, metal trades' },
  { Id: 8606, Code: '4133', Name: 'Controller, spares' },
  { Id: 8607, Code: '4133', Name: 'Controller, stock' },
  { Id: 8608, Code: '4133', Name: 'Controller, stores' },
  { Id: 8609, Code: '4133', Name: 'Controller, supplies' },
  { Id: 8610, Code: '4133', Name: 'Controller, supply' },
  { Id: 8611, Code: '4133', Name: 'Controller, warehouse' },
  { Id: 8612, Code: '4133', Name: 'Controller, works' },
  { Id: 8613, Code: '4133', Name: 'Coordinator, chain, supply' },
  { Id: 8614, Code: '4133', Name: 'Coordinator, goods-in' },
  { Id: 8615, Code: '4133', Name: 'Coordinator, materials' },
  { Id: 8616, Code: '4133', Name: 'Coordinator, spares' },
  { Id: 8617, Code: '4133', Name: 'Coordinator, stores' },
  { Id: 8618, Code: '4133', Name: 'Coordinator, warehouse' },
  { Id: 8619, Code: '4133', Name: 'Engineer, spares' },
  { Id: 8620, Code: '4133', Name: 'Inspector of storehousemen' },
  { Id: 8621, Code: '4133', Name: 'Inspector, cargo' },
  { Id: 8622, Code: '4133', Name: 'Inspector, depot' },
  { Id: 8623, Code: '4133', Name: 'Inspector, despatch' },
  { Id: 8624, Code: '4133', Name: 'Inspector, inwards, goods' },
  { Id: 8625, Code: '4133', Name: 'Inspector, receiving, goods' },
  { Id: 8626, Code: '4133', Name: 'Inspector, stores' },
  { Id: 8627, Code: '4133', Name: 'Inspector, stores, chief' },
  { Id: 8628, Code: '4133', Name: 'Keeper, store, chief' },
  { Id: 8629, Code: '4133', Name: 'Leader, team, control, stock' },
  { Id: 8630, Code: '4133', Name: 'Man, sample' },
  { Id: 8631, Code: '4133', Name: 'Manager, line, front, warehousing' },
  { Id: 8632, Code: '4133', Name: 'Manager, room, pattern' },
  { Id: 8633, Code: '4133', Name: 'Manager, room, stock' },
  { Id: 8634, Code: '4133', Name: 'Manager, spares' },
  { Id: 8635, Code: '4133', Name: 'Master, quarter' },
  { Id: 8636, Code: '4133', Name: 'Officer, control, materials, coal mine' },
  { Id: 8637, Code: '4133', Name: 'Officer, control, stock' },
  { Id: 8638, Code: '4133', Name: 'Officer, distribution, coal mine' },
  { Id: 8639, Code: '4133', Name: 'Officer, fuel' },
  { Id: 8640, Code: '4133', Name: 'Officer, loans, medical' },
  { Id: 8641, Code: '4133', Name: 'Officer, materials' },
  { Id: 8642, Code: '4133', Name: 'Officer, store, assistant, MOD' },
  { Id: 8643, Code: '4133', Name: 'Officer, store, MOD' },
  { Id: 8644, Code: '4133', Name: 'Officer, stores' },
  { Id: 8645, Code: '4133', Name: 'Officer, stores, grade I, MOD' },
  { Id: 8646, Code: '4133', Name: 'Officer, stores, MOD' },
  { Id: 8647, Code: '4133', Name: 'Officer, supply, chemical mfr' },
  { Id: 8648, Code: '4133', Name: 'Officer, supply, engineering' },
  { Id: 8649, Code: '4133', Name: 'Officer, supply, fuel' },
  { Id: 8650, Code: '4133', Name: 'Operator, depot' },
  { Id: 8651, Code: '4133', Name: 'Operator, entry, order' },
  { Id: 8652, Code: '4133', Name: 'Planner, materials' },
  { Id: 8653, Code: '4133', Name: 'Planner, progress' },
  { Id: 8654, Code: '4133', Name: 'Processor, order' },
  { Id: 8655, Code: '4133', Name: 'Processor, stock' },
  { Id: 8656, Code: '4133', Name: 'Quartermaster' },
  { Id: 8657, Code: '4133', Name: 'Recorder, stock' },
  { Id: 8658, Code: '4133', Name: 'Salesman, powder, mining' },
  { Id: 8659, Code: '4133', Name: 'Scheduler, materials' },
  { Id: 8660, Code: '4133', Name: 'Stocktaker' },
  { Id: 8661, Code: '4133', Name: 'Stocktaker, chief, steelworks' },
  { Id: 8662, Code: '4133', Name: 'Superintendent, cargo' },
  { Id: 8663, Code: '4133', Name: 'Superintendent, depot' },
  { Id: 8664, Code: '4133', Name: 'Superintendent, progress' },
  { Id: 8665, Code: '4133', Name: 'Superintendent, storage' },
  { Id: 8666, Code: '4133', Name: 'Superintendent, store' },
  { Id: 8667, Code: '4133', Name: 'Superintendent, stores' },
  { Id: 8668, Code: '4133', Name: 'Superintendent, supplies, chief, PO' },
  { Id: 8669, Code: '4133', Name: 'Superintendent, supplies, PO' },
  { Id: 8670, Code: '4133', Name: 'Superintendent, warehouse' },
  { Id: 8671, Code: '4133', Name: 'Supervisor, inwards, goods' },
  { Id: 8672, Code: '4133', Name: 'Supervisor, order' },
  { Id: 8673, Code: '4133', Name: 'Supervisor, parts' },
  { Id: 8674, Code: '4133', Name: 'Supervisor, room, stock' },
  { Id: 8675, Code: '4133', Name: 'Supervisor, stock' },
  { Id: 8676, Code: '4133', Name: 'Supervisor, tool' },
  { Id: 8677, Code: '4133', Name: 'Supplier, material' },
  { Id: 8678, Code: '4133', Name: 'Supplier, parts' },
  { Id: 8679, Code: '4133', Name: 'Taker, stock' },
  { Id: 8680, Code: '4133', Name: 'Taker, stock, chief, steelworks' },
  { Id: 8681, Code: '4134', Name: 'Administrator, distribution' },
  { Id: 8682, Code: '4134', Name: 'Administrator, fleet' },
  { Id: 8683, Code: '4134', Name: 'Administrator, fleet, car' },
  { Id: 8684, Code: '4134', Name: 'Administrator, freight' },
  { Id: 8685, Code: '4134', Name: 'Administrator, logistics' },
  { Id: 8686, Code: '4134', Name: 'Administrator, services, fleet' },
  { Id: 8687, Code: '4134', Name: 'Administrator, shipping' },
  { Id: 8688, Code: '4134', Name: 'Administrator, transport' },
  { Id: 8689, Code: '4134', Name: 'Agent, cargo' },
  { Id: 8690, Code: '4134', Name: 'Agent, cargo, airport' },
  { Id: 8691, Code: '4134', Name: 'Agent, operations, cargo' },
  { Id: 8692, Code: '4134', Name: 'Assistant, logistics' },
  { Id: 8693, Code: '4134', Name: 'Assistant, shipping' },
  { Id: 8694, Code: '4134', Name: 'Assistant, traffic' },
  { Id: 8695, Code: '4134', Name: 'Assistant, transport' },
  { Id: 8696, Code: '4134', Name: 'Checker, petroleum distribution' },
  { Id: 8697, Code: '4134', Name: 'Checker, transport' },
  { Id: 8698, Code: '4134', Name: 'Checker-loader' },
  { Id: 8699, Code: '4134', Name: 'Clerk, agency, ships' },
  { Id: 8700, Code: '4134', Name: 'Clerk, cargo' },
  { Id: 8701, Code: '4134', Name: 'Clerk, chartering, sea transport' },
  { Id: 8702, Code: '4134', Name: 'Clerk, control, load, AIRCRAFT' },
  { Id: 8703, Code: '4134', Name: 'Clerk, distribution' },
  { Id: 8704, Code: '4134', Name: 'Clerk, documentation' },
  { Id: 8705, Code: '4134', Name: 'Clerk, export' },
  { Id: 8706, Code: '4134', Name: 'Clerk, forwarding' },
  { Id: 8707, Code: '4134', Name: 'Clerk, freight' },
  { Id: 8708, Code: '4134', Name: 'Clerk, import' },
  { Id: 8709, Code: '4134', Name: 'Clerk, logistics' },
  { Id: 8710, Code: '4134', Name: 'Clerk, office, traffic' },
  {
    Id: 8711,
    Code: '4134',
    Name: 'Clerk, planning, route, transport, distribution'
  },
  { Id: 8712, Code: '4134', Name: 'Clerk, principal, PLA' },
  { Id: 8713, Code: '4134', Name: 'Clerk, routeing' },
  { Id: 8714, Code: '4134', Name: 'Clerk, schedules, transport services' },
  { Id: 8715, Code: '4134', Name: 'Clerk, shipping' },
  { Id: 8716, Code: '4134', Name: 'Clerk, traffic' },
  { Id: 8717, Code: '4134', Name: 'Clerk, transport' },
  { Id: 8718, Code: '4134', Name: 'Clerk, transportation' },
  { Id: 8719, Code: '4134', Name: 'Controller of distribution' },
  { Id: 8720, Code: '4134', Name: 'Controller, cargo' },
  { Id: 8721, Code: '4134', Name: 'Controller, container' },
  { Id: 8722, Code: '4134', Name: 'Controller, distribution' },
  { Id: 8723, Code: '4134', Name: 'Controller, fleet' },
  { Id: 8724, Code: '4134', Name: 'Controller, freight' },
  { Id: 8725, Code: '4134', Name: 'Controller, load, AIRCRAFT' },
  { Id: 8726, Code: '4134', Name: 'Controller, logistics' },
  { Id: 8727, Code: '4134', Name: 'Controller, operations, transport' },
  { Id: 8728, Code: '4134', Name: 'Controller, ship' },
  { Id: 8729, Code: '4134', Name: 'Controller, shipping' },
  { Id: 8730, Code: '4134', Name: 'Controller, traffic' },
  { Id: 8731, Code: '4134', Name: 'Controller, transport' },
  { Id: 8732, Code: '4134', Name: 'Controller, truck' },
  { Id: 8733, Code: '4134', Name: 'Coordinator, despatch' },
  { Id: 8734, Code: '4134', Name: 'Coordinator, distribution' },
  { Id: 8735, Code: '4134', Name: 'Coordinator, logistics' },
  { Id: 8736, Code: '4134', Name: 'Coordinator, shipping' },
  { Id: 8737, Code: '4134', Name: 'Coordinator, traffic' },
  { Id: 8738, Code: '4134', Name: 'Coordinator, transport' },
  { Id: 8739, Code: '4134', Name: 'Dispatcher, traffic' },
  { Id: 8740, Code: '4134', Name: 'Foreman, distribution, warehousing' },
  { Id: 8741, Code: '4134', Name: 'Forwarder, freight' },
  { Id: 8742, Code: '4134', Name: 'Handler, traffic' },
  { Id: 8743, Code: '4134', Name: 'Officer, cargo' },
  { Id: 8744, Code: '4134', Name: 'Officer, chief, railways' },
  { Id: 8745, Code: '4134', Name: 'Officer, control, traffic' },
  { Id: 8746, Code: '4134', Name: 'Officer, crewing' },
  { Id: 8747, Code: '4134', Name: 'Officer, distribution' },
  { Id: 8748, Code: '4134', Name: 'Officer, duty, airport' },
  { Id: 8749, Code: '4134', Name: 'Officer, duty, cargo' },
  { Id: 8750, Code: '4134', Name: 'Officer, executive, PLA' },
  { Id: 8751, Code: '4134', Name: 'Officer, freight, air transport' },
  { Id: 8752, Code: '4134', Name: 'Officer, logistics' },
  { Id: 8753, Code: '4134', Name: 'Officer, planning, route, airlines' },
  { Id: 8754, Code: '4134', Name: 'Officer, port' },
  { Id: 8755, Code: '4134', Name: 'Officer, regional, railways' },
  { Id: 8756, Code: '4134', Name: 'Officer, shipping, coal mine' },
  { Id: 8757, Code: '4134', Name: 'Officer, station, airport' },
  { Id: 8758, Code: '4134', Name: 'Officer, traffic, airlines' },
  { Id: 8759, Code: '4134', Name: 'Officer, traffic, port authority' },
  { Id: 8760, Code: '4134', Name: 'Officer, traffic, road haulage' },
  { Id: 8761, Code: '4134', Name: 'Officer, transport' },
  { Id: 8762, Code: '4134', Name: 'Operator, traffic' },
  { Id: 8763, Code: '4134', Name: 'Organiser, meals-on-wheels' },
  { Id: 8764, Code: '4134', Name: 'Organiser, transport' },
  { Id: 8765, Code: '4134', Name: 'Planner, load, haulage, road' },
  { Id: 8766, Code: '4134', Name: 'Planner, route, transport, distribution' },
  { Id: 8767, Code: '4134', Name: 'Planner, ship' },
  { Id: 8768, Code: '4134', Name: 'Router, bus, public transport' },
  { Id: 8769, Code: '4134', Name: 'Router, freight transport' },
  { Id: 8770, Code: '4134', Name: 'Scheduler, transport' },
  { Id: 8771, Code: '4134', Name: 'Superintendent, flight' },
  { Id: 8772, Code: '4134', Name: 'Superintendent, shipping' },
  { Id: 8773, Code: '4134', Name: 'Supervisor, cargo' },
  { Id: 8774, Code: '4134', Name: 'Supervisor, freight' },
  { Id: 8775, Code: '4134', Name: 'Supervisor, logistics' },
  { Id: 8776, Code: '4134', Name: 'Supervisor, meals-on-wheels' },
  { Id: 8777, Code: '4134', Name: 'Supervisor, traffic' },
  { Id: 8778, Code: '4135', Name: 'Abstractor, PRESS CUTTINGS' },
  { Id: 8779, Code: '4135', Name: 'Agent, cutting, press' },
  { Id: 8780, Code: '4135', Name: 'Assistant, archives' },
  { Id: 8781, Code: '4135', Name: 'Assistant, bibliographic' },
  { Id: 8782, Code: '4135', Name: 'Assistant, chief, library' },
  { Id: 8783, Code: '4135', Name: 'Assistant, clerical, library' },
  { Id: 8784, Code: '4135', Name: 'Assistant, counter, library' },
  {
    Id: 8785,
    Code: '4135',
    Name: 'Assistant, information, educational establishments'
  },
  { Id: 8786, Code: '4135', Name: 'Assistant, information, library' },
  { Id: 8787, Code: '4135', Name: 'Assistant, library' },
  { Id: 8788, Code: '4135', Name: 'Assistant, resource, learning' },
  { Id: 8789, Code: '4135', Name: 'Assistant, services, bibliographic' },
  { Id: 8790, Code: '4135', Name: 'Attendant, library' },
  { Id: 8791, Code: '4135', Name: 'Clerk, cuttings, press' },
  { Id: 8792, Code: '4135', Name: 'Clerk, library' },
  { Id: 8793, Code: '4135', Name: 'Clipper, press, press cutting agency' },
  { Id: 8794, Code: '4135', Name: 'Looker-out, book' },
  { Id: 8795, Code: '4135', Name: 'Reader, press cutting agency' },
  { Id: 8796, Code: '4135', Name: 'Supervisor, library' },
  { Id: 8797, Code: '4138', Name: 'Administrator, course' },
  { Id: 8798, Code: '4138', Name: 'Administrator, education, health service' },
  { Id: 8799, Code: '4138', Name: 'Administrator, personnel' },
  { Id: 8800, Code: '4138', Name: 'Administrator, recruitment' },
  { Id: 8801, Code: '4138', Name: 'Administrator, recruitment agency' },
  { Id: 8802, Code: '4138', Name: 'Administrator, resources, human' },
  { Id: 8803, Code: '4138', Name: 'Administrator, training' },
  { Id: 8804, Code: '4138', Name: 'Administrator, training agency' },
  { Id: 8805, Code: '4138', Name: 'Assistant, admin, recruitment agency' },
  { Id: 8806, Code: '4138', Name: 'Assistant, admin, training agency' },
  {
    Id: 8807,
    Code: '4138',
    Name: 'Assistant, administration, recruitment agency'
  },
  {
    Id: 8808,
    Code: '4138',
    Name: 'Assistant, administration, training agency'
  },
  {
    Id: 8809,
    Code: '4138',
    Name: 'Assistant, administrative, recruitment agency'
  },
  {
    Id: 8810,
    Code: '4138',
    Name: 'Assistant, administrative, training agency'
  },
  { Id: 8811, Code: '4138', Name: 'Assistant, training' },
  { Id: 8812, Code: '4138', Name: 'Clerk, office, personnel' },
  { Id: 8813, Code: '4138', Name: 'Clerk, people' },
  { Id: 8814, Code: '4138', Name: 'Clerk, personnel' },
  { Id: 8815, Code: '4138', Name: 'Officer, admin, recruitment agency' },
  { Id: 8816, Code: '4138', Name: 'Officer, admin, training agency' },
  {
    Id: 8817,
    Code: '4138',
    Name: 'Officer, administration, recruitment agency'
  },
  { Id: 8818, Code: '4138', Name: 'Officer, administration, staff' },
  { Id: 8819, Code: '4138', Name: 'Officer, administration, training agency' },
  {
    Id: 8820,
    Code: '4138',
    Name: 'Officer, administrative, recruitment agency'
  },
  { Id: 8821, Code: '4138', Name: 'Officer, administrative, training agency' },
  { Id: 8822, Code: '4138', Name: 'Organiser, VOCATIONAL TRAINING' },
  { Id: 8823, Code: '4151', Name: 'Administrator, contracts, sales' },
  { Id: 8824, Code: '4151', Name: 'Administrator, marketing' },
  { Id: 8825, Code: '4151', Name: 'Administrator, office, sales' },
  { Id: 8826, Code: '4151', Name: 'Administrator, order, sales' },
  { Id: 8827, Code: '4151', Name: 'Administrator, retail' },
  { Id: 8828, Code: '4151', Name: 'Administrator, sales' },
  { Id: 8829, Code: '4151', Name: 'Administrator, support, sales' },
  { Id: 8830, Code: '4151', Name: 'Assistant, administration, sales' },
  { Id: 8831, Code: '4151', Name: 'Assistant, commercial, SALES' },
  { Id: 8832, Code: '4151', Name: 'Assistant, marketing' },
  { Id: 8833, Code: '4151', Name: 'Assistant, operations, CLERICAL' },
  { Id: 8834, Code: '4151', Name: 'Assistant, sales, online' },
  { Id: 8835, Code: '4151', Name: 'Assistant, trading, eBay' },
  { Id: 8836, Code: '4151', Name: 'Clerk, administration, sales' },
  { Id: 8837, Code: '4151', Name: 'Clerk, office, sales' },
  { Id: 8838, Code: '4151', Name: 'Clerk, order, sales' },
  { Id: 8839, Code: '4151', Name: 'Clerk, sales' },
  { Id: 8840, Code: '4151', Name: 'Coordinator, sales' },
  { Id: 8841, Code: '4151', Name: 'Coordinator, sales and marketing' },
  { Id: 8842, Code: '4159', Name: 'Administrator' },
  { Id: 8843, Code: '4159', Name: 'Administrator, business' },
  { Id: 8844, Code: '4159', Name: 'Administrator, catering' },
  { Id: 8845, Code: '4159', Name: 'Administrator, church' },
  { Id: 8846, Code: '4159', Name: 'Administrator, clerical' },
  { Id: 8847, Code: '4159', Name: 'Administrator, commercial' },
  { Id: 8848, Code: '4159', Name: 'Administrator, dental' },
  { Id: 8849, Code: '4159', Name: 'Administrator, office' },
  { Id: 8850, Code: '4159', Name: 'Administrator, parish' },
  { Id: 8851, Code: '4159', Name: 'Administrator, QA' },
  { Id: 8852, Code: '4159', Name: 'Administrator, quality' },
  { Id: 8853, Code: '4159', Name: 'Administrator, service' },
  { Id: 8854, Code: '4159', Name: 'Administrator, services' },
  { Id: 8855, Code: '4159', Name: 'Administrator, warranty' },
  { Id: 8856, Code: '4159', Name: 'Administrator-receptionist' },
  { Id: 8857, Code: '4159', Name: 'Allocator, chalet' },
  { Id: 8858, Code: '4159', Name: 'Apprentice, commercial' },
  { Id: 8859, Code: '4159', Name: 'Assistant, admin' },
  { Id: 8860, Code: '4159', Name: 'Assistant, admin, hospital service' },
  { Id: 8861, Code: '4159', Name: 'Assistant, administration' },
  {
    Id: 8862,
    Code: '4159',
    Name: 'Assistant, administration, hospital service'
  },
  { Id: 8863, Code: '4159', Name: 'Assistant, administrative' },
  {
    Id: 8864,
    Code: '4159',
    Name: 'Assistant, administrative, hospital service'
  },
  { Id: 8865, Code: '4159', Name: 'Assistant, advertising' },
  { Id: 8866, Code: '4159', Name: 'Assistant, arts' },
  { Id: 8867, Code: '4159', Name: 'Assistant, bureau' },
  { Id: 8868, Code: '4159', Name: 'Assistant, circulation' },
  { Id: 8869, Code: '4159', Name: 'Assistant, civil' },
  { Id: 8870, Code: '4159', Name: 'Assistant, civilian, police service' },
  { Id: 8871, Code: '4159', Name: 'Assistant, clerical' },
  { Id: 8872, Code: '4159', Name: "Assistant, clerk's" },
  { Id: 8873, Code: '4159', Name: 'Assistant, commercial' },
  { Id: 8874, Code: '4159', Name: 'Assistant, conference' },
  { Id: 8875, Code: '4159', Name: 'Assistant, consular' },
  { Id: 8876, Code: '4159', Name: "Assistant, director's" },
  { Id: 8877, Code: '4159', Name: 'Assistant, e-business' },
  { Id: 8878, Code: '4159', Name: "Assistant, editor's, newspaper" },
  { Id: 8879, Code: '4159', Name: 'Assistant, estates' },
  { Id: 8880, Code: '4159', Name: 'Assistant, information' },
  { Id: 8881, Code: '4159', Name: 'Assistant, management, facilities' },
  { Id: 8882, Code: '4159', Name: 'Assistant, management, housing' },
  { Id: 8883, Code: '4159', Name: "Assistant, manager's" },
  { Id: 8884, Code: '4159', Name: 'Assistant, office' },
  { Id: 8885, Code: '4159', Name: 'Assistant, office, general' },
  { Id: 8886, Code: '4159', Name: 'Assistant, office, receiving' },
  { Id: 8887, Code: '4159', Name: 'Assistant, project' },
  { Id: 8888, Code: '4159', Name: "Assistant, reader's" },
  { Id: 8889, Code: '4159', Name: "Assistant, secretary's" },
  { Id: 8890, Code: '4159', Name: 'Assistant, services, business' },
  { Id: 8891, Code: '4159', Name: 'Assistant, support, business' },
  { Id: 8892, Code: '4159', Name: 'Assistant, support, clerical' },
  { Id: 8893, Code: '4159', Name: 'Clerk' },
  { Id: 8894, Code: '4159', Name: 'Clerk, committee' },
  {
    Id: 8895,
    Code: '4159',
    Name: 'Clerk, continuity, film, television production'
  },
  { Id: 8896, Code: '4159', Name: 'Clerk, office' },
  { Id: 8897, Code: '4159', Name: 'Clerk, office, receiving' },
  { Id: 8898, Code: '4159', Name: 'Clerk, registration' },
  { Id: 8899, Code: '4159', Name: 'Clerk-telephonist' },
  { Id: 8900, Code: '4159', Name: 'Clerk-typist' },
  { Id: 8901, Code: '4159', Name: 'Controller, proof, newspaper' },
  { Id: 8902, Code: '4159', Name: 'Coordinator, estate' },
  { Id: 8903, Code: '4159', Name: 'Coordinator, estates' },
  { Id: 8904, Code: '4159', Name: 'Coordinator, facilities' },
  { Id: 8905, Code: '4159', Name: 'Coordinator, NVQ' },
  { Id: 8906, Code: '4159', Name: 'Copyholder' },
  { Id: 8907, Code: '4159', Name: 'Corrector, press' },
  { Id: 8908, Code: '4159', Name: 'Corrector, proof, newspaper' },
  { Id: 8909, Code: '4159', Name: 'Holder, copy' },
  { Id: 8910, Code: '4159', Name: 'Inspector, fraud' },
  { Id: 8911, Code: '4159', Name: 'Inspector, office, nos' },
  { Id: 8912, Code: '4159', Name: 'Invigilator' },
  { Id: 8913, Code: '4159', Name: 'Loggist' },
  { Id: 8914, Code: '4159', Name: 'Officer, admin' },
  { Id: 8915, Code: '4159', Name: 'Officer, admin, hospital service' },
  { Id: 8916, Code: '4159', Name: 'Officer, administration' },
  { Id: 8917, Code: '4159', Name: 'Officer, administration, hospital service' },
  { Id: 8918, Code: '4159', Name: 'Officer, administrative' },
  { Id: 8919, Code: '4159', Name: 'Officer, administrative, hospital service' },
  { Id: 8920, Code: '4159', Name: 'Officer, church' },
  { Id: 8921, Code: '4159', Name: 'Officer, clerical' },
  { Id: 8922, Code: '4159', Name: 'Officer, clerical, higher' },
  { Id: 8923, Code: '4159', Name: 'Officer, distribution, gas supplier' },
  { Id: 8924, Code: '4159', Name: 'Officer, enquiry' },
  { Id: 8925, Code: '4159', Name: 'Officer, facilities' },
  { Id: 8926, Code: '4159', Name: 'Officer, liaison' },
  { Id: 8927, Code: '4159', Name: 'Officer, mining' },
  { Id: 8928, Code: '4159', Name: 'Officer, operations' },
  { Id: 8929, Code: '4159', Name: 'Officer, registration' },
  { Id: 8930, Code: '4159', Name: 'Officer, section' },
  { Id: 8931, Code: '4159', Name: 'Officer, service, health' },
  { Id: 8932, Code: '4159', Name: 'Officer, settlement' },
  { Id: 8933, Code: '4159', Name: 'Official, board, water' },
  { Id: 8934, Code: '4159', Name: 'Official, brewery' },
  { Id: 8935, Code: '4159', Name: 'Official, dock board' },
  { Id: 8936, Code: '4159', Name: 'Pressureman, gas supplier' },
  { Id: 8937, Code: '4159', Name: 'Pricer, prescription' },
  { Id: 8938, Code: '4159', Name: 'Reader, newspaper' },
  { Id: 8939, Code: '4159', Name: 'Reader, printing' },
  { Id: 8940, Code: '4159', Name: 'Reader, proof' },
  { Id: 8941, Code: '4159', Name: 'Recorder, HM Dockyard' },
  { Id: 8942, Code: '4159', Name: 'Recorder, steelworks' },
  { Id: 8943, Code: '4159', Name: 'Recorder, temperature' },
  { Id: 8944, Code: '4159', Name: 'Recorder, wagon' },
  { Id: 8945, Code: '4159', Name: 'Reporter, train' },
  { Id: 8946, Code: '4159', Name: 'Reviser, printing' },
  { Id: 8947, Code: '4159', Name: 'Scrutineer' },
  { Id: 8948, Code: '4159', Name: 'Secretary, committee' },
  { Id: 8949, Code: '4159', Name: 'Secretary-receptionist' },
  { Id: 8950, Code: '4159', Name: 'Worker, admin' },
  { Id: 8951, Code: '4159', Name: 'Worker, administration' },
  { Id: 8952, Code: '4159', Name: 'Worker, administrative' },
  { Id: 8953, Code: '4159', Name: 'Worker, clerical' },
  { Id: 8954, Code: '4161', Name: 'Manager, admin' },
  { Id: 8955, Code: '4161', Name: 'Manager, administration' },
  { Id: 8956, Code: '4161', Name: 'Manager, administration, sales' },
  { Id: 8957, Code: '4161', Name: 'Manager, agency, ticket' },
  { Id: 8958, Code: '4161', Name: 'Manager, booking' },
  {
    Id: 8959,
    Code: '4161',
    Name: 'Manager, branch, entertainment ticket agency'
  },
  {
    Id: 8960,
    Code: '4161',
    Name: 'Manager, branch, private employment agency'
  },
  { Id: 8961, Code: '4161', Name: 'Manager, copyright' },
  { Id: 8962, Code: '4161', Name: 'Manager, court' },
  { Id: 8963, Code: '4161', Name: 'Manager, crews, train' },
  {
    Id: 8964,
    Code: '4161',
    Name: 'Manager, delivery, postal distribution services'
  },
  { Id: 8965, Code: '4161', Name: 'Manager, district, census' },
  { Id: 8966, Code: '4161', Name: 'Manager, district, electricity supplier' },
  { Id: 8967, Code: '4161', Name: 'Manager, district, gas supplier' },
  { Id: 8968, Code: '4161', Name: 'Manager, document' },
  { Id: 8969, Code: '4161', Name: 'Manager, documentation' },
  { Id: 8970, Code: '4161', Name: 'Manager, employment agency: private' },
  { Id: 8971, Code: '4161', Name: 'Manager, hall, church' },
  { Id: 8972, Code: '4161', Name: 'Manager, office' },
  { Id: 8973, Code: '4161', Name: 'Manager, office, box' },
  { Id: 8974, Code: '4161', Name: 'Manager, office, CREDIT CONTROL' },
  { Id: 8975, Code: '4161', Name: 'Manager, office, delivery' },
  {
    Id: 8976,
    Code: '4161',
    Name: 'Manager, office, delivery, postal distribution services'
  },
  { Id: 8977, Code: '4161', Name: 'Manager, office, insurance' },
  { Id: 8978, Code: '4161', Name: 'Manager, office, printing, PO' },
  { Id: 8979, Code: '4161', Name: 'Manager, office, sales' },
  { Id: 8980, Code: '4161', Name: 'Manager, office, sorting' },
  { Id: 8981, Code: '4161', Name: 'Manager, practice' },
  {
    Id: 8982,
    Code: '4161',
    Name: 'Manager, processing, shift, postal distribution services'
  },
  { Id: 8983, Code: '4161', Name: 'Manager, records' },
  { Id: 8984, Code: '4161', Name: 'Manager, registry' },
  { Id: 8985, Code: '4161', Name: 'Manager, reprographics' },
  { Id: 8986, Code: '4161', Name: 'Manager, service, witness' },
  { Id: 8987, Code: '4161', Name: 'Manager, services, office' },
  { Id: 8988, Code: '4161', Name: 'Manager, support, business' },
  { Id: 8989, Code: '4161', Name: 'Manager, telephone' },
  { Id: 8990, Code: '4161', Name: 'Manager, typing' },
  { Id: 8991, Code: '4161', Name: 'Manager, warranty' },
  { Id: 8992, Code: '4162', Name: 'Assistant, senior' },
  { Id: 8993, Code: '4162', Name: 'Clerk, chief' },
  { Id: 8994, Code: '4162', Name: 'Clerk, grade, higher' },
  { Id: 8995, Code: '4162', Name: 'Clerk, head' },
  { Id: 8996, Code: '4162', Name: 'Clerk, supervising' },
  { Id: 8997, Code: '4162', Name: 'Clerk-in-charge' },
  { Id: 8998, Code: '4162', Name: 'Controller of typists' },
  { Id: 8999, Code: '4162', Name: 'Controller, office' },
  { Id: 9000, Code: '4162', Name: 'Executive, postal, PO, GRADE C' },
  { Id: 9001, Code: '4162', Name: 'Executive, support, sales' },
  { Id: 9002, Code: '4162', Name: 'Foreman, progress' },
  { Id: 9003, Code: '4162', Name: 'Head, section, CLERICAL' },
  { Id: 9004, Code: '4162', Name: 'Leader, section, CLERICAL OFFICE' },
  { Id: 9005, Code: '4162', Name: 'Leader, section, PROGRESS' },
  { Id: 9006, Code: '4162', Name: 'Leader, section, SENIOR' },
  { Id: 9007, Code: '4162', Name: 'Leader, shift, computer' },
  { Id: 9008, Code: '4162', Name: 'Leader, team, administration, sales' },
  { Id: 9009, Code: '4162', Name: 'Leader, team, administrator, sales' },
  { Id: 9010, Code: '4162', Name: 'Leader, team, census' },
  { Id: 9011, Code: '4162', Name: 'Leader, team, payable, accounts' },
  { Id: 9012, Code: '4162', Name: 'Leader, team, preparation, data' },
  { Id: 9013, Code: '4162', Name: 'Leader, team, receivable, accounts' },
  { Id: 9014, Code: '4162', Name: 'Manager, route' },
  { Id: 9015, Code: '4162', Name: 'Officer, executive, PO' },
  { Id: 9016, Code: '4162', Name: 'Overseer, PO' },
  { Id: 9017, Code: '4162', Name: 'Superintendent, motoring organisation' },
  { Id: 9018, Code: '4162', Name: 'Superintendent, office' },
  { Id: 9019, Code: '4162', Name: 'Supervisor, administration' },
  { Id: 9020, Code: '4162', Name: 'Supervisor, betting' },
  { Id: 9021, Code: '4162', Name: 'Supervisor, clerical' },
  { Id: 9022, Code: '4162', Name: 'Supervisor, coding, clinical' },
  { Id: 9023, Code: '4162', Name: 'Supervisor, commercial' },
  { Id: 9024, Code: '4162', Name: 'Supervisor, control, credit' },
  { Id: 9025, Code: '4162', Name: 'Supervisor, credit' },
  { Id: 9026, Code: '4162', Name: 'Supervisor, distribution' },
  { Id: 9027, Code: '4162', Name: 'Supervisor, district, betting' },
  { Id: 9028, Code: '4162', Name: 'Supervisor, facilities' },
  { Id: 9029, Code: '4162', Name: 'Supervisor, import' },
  { Id: 9030, Code: '4162', Name: 'Supervisor, office' },
  { Id: 9031, Code: '4162', Name: 'Supervisor, pools, football' },
  { Id: 9032, Code: '4162', Name: 'Supervisor, pricing' },
  { Id: 9033, Code: '4162', Name: 'Supervisor, progress' },
  { Id: 9034, Code: '4162', Name: 'Supervisor, purchasing' },
  { Id: 9035, Code: '4162', Name: 'Supervisor, QA' },
  { Id: 9036, Code: '4162', Name: 'Supervisor, team, preparation, data' },
  { Id: 9037, Code: '4162', Name: 'Supervisor, warranty' },
  { Id: 9038, Code: '4211', Name: 'Administrator, clinic' },
  { Id: 9039, Code: '4211', Name: 'Administrator, medical' },
  { Id: 9040, Code: '4211', Name: 'Administrator, surgery, GP' },
  { Id: 9041, Code: '4211', Name: 'Clerk, medical practice' },
  { Id: 9042, Code: '4211', Name: 'Coordinator, clinic' },
  { Id: 9043, Code: '4211', Name: 'Officer, clerical, medical practice' },
  { Id: 9044, Code: '4211', Name: "Secretary, doctor's" },
  { Id: 9045, Code: '4211', Name: 'Secretary, hospital' },
  { Id: 9046, Code: '4211', Name: 'Secretary, hospital service' },
  { Id: 9047, Code: '4211', Name: 'Secretary, medical' },
  { Id: 9048, Code: '4211', Name: 'Secretary, medical practice' },
  {
    Id: 9049,
    Code: '4211',
    Name: 'Summariser, notes, medical, medical practice'
  },
  { Id: 9050, Code: '4211', Name: 'Worker, clerical, medical practice' },
  { Id: 9051, Code: '4212', Name: 'Administrator, legal' },
  { Id: 9052, Code: '4212', Name: 'Clerk, aid, legal' },
  { Id: 9053, Code: '4212', Name: 'Clerk, legal' },
  { Id: 9054, Code: '4212', Name: 'Clerk, probate' },
  { Id: 9055, Code: '4212', Name: 'Secretary, conveyancing' },
  { Id: 9056, Code: '4212', Name: 'Secretary, legal' },
  { Id: 9057, Code: '4212', Name: 'Secretary, legal services' },
  { Id: 9058, Code: '4212', Name: 'Secretary, litigation' },
  { Id: 9059, Code: '4213', Name: 'Administrator, office, school' },
  { Id: 9060, Code: '4213', Name: 'Administrator, school' },
  { Id: 9061, Code: '4213', Name: 'Administrator, schools' },
  { Id: 9062, Code: '4213', Name: 'Assistant, admin, schools' },
  { Id: 9063, Code: '4213', Name: 'Assistant, administration, schools' },
  { Id: 9064, Code: '4213', Name: 'Assistant, administrative, schools' },
  { Id: 9065, Code: '4213', Name: 'Assistant, clerical, schools' },
  { Id: 9066, Code: '4213', Name: 'Assistant, registry, schools' },
  { Id: 9067, Code: '4213', Name: 'Clerk, school' },
  { Id: 9068, Code: '4213', Name: 'Clerk, schools' },
  { Id: 9069, Code: '4213', Name: 'Clerk-typist, schools' },
  { Id: 9070, Code: '4213', Name: 'Officer, admin, schools' },
  { Id: 9071, Code: '4213', Name: 'Officer, administration, schools' },
  { Id: 9072, Code: '4213', Name: 'Officer, administrative, schools' },
  { Id: 9073, Code: '4213', Name: 'Officer, clerical, schools' },
  { Id: 9074, Code: '4213', Name: 'Officer, support, school' },
  { Id: 9075, Code: '4213', Name: 'Secretary, primary school' },
  { Id: 9076, Code: '4213', Name: 'Secretary, school' },
  { Id: 9077, Code: '4213', Name: 'Secretary, SCHOOLS' },
  { Id: 9078, Code: '4213', Name: 'Secretary, secondary school' },
  { Id: 9079, Code: '4214', Name: 'Administrator, company' },
  { Id: 9080, Code: '4214', Name: 'Secretary, assistant' },
  { Id: 9081, Code: '4214', Name: 'Secretary, club' },
  { Id: 9082, Code: '4214', Name: 'Secretary, company' },
  { Id: 9083, Code: '4214', Name: 'Secretary, corporation' },
  { Id: 9084, Code: '4214', Name: 'Secretary, resident' },
  { Id: 9085, Code: '4214', Name: 'Secretary, sports' },
  { Id: 9086, Code: '4215', Name: 'Assistant, executive' },
  { Id: 9087, Code: '4215', Name: 'Assistant, personal' },
  { Id: 9088, Code: '4215', Name: "Assistant, personal, manager's" },
  { Id: 9089, Code: '4215', Name: 'Assistant, personal, virtual' },
  { Id: 9090, Code: '4215', Name: 'Assistant, secretarial' },
  { Id: 9091, Code: '4215', Name: 'Assistant, virtual' },
  { Id: 9092, Code: '4215', Name: 'Clerk, confidential' },
  { Id: 9093, Code: '4215', Name: 'PA' },
  { Id: 9094, Code: '4215', Name: 'PA, virtual' },
  { Id: 9095, Code: '4215', Name: 'PA-Secretary' },
  { Id: 9096, Code: '4215', Name: 'Secretary' },
  { Id: 9097, Code: '4215', Name: 'Secretary, assistant, hospital service' },
  { Id: 9098, Code: '4215', Name: 'Secretary, bilingual' },
  { Id: 9099, Code: '4215', Name: 'Secretary, branch, bank' },
  { Id: 9100, Code: '4215', Name: 'Secretary, branch, building society' },
  { Id: 9101, Code: '4215', Name: 'Secretary, club, youth club' },
  { Id: 9102, Code: '4215', Name: 'Secretary, commercial' },
  { Id: 9103, Code: '4215', Name: 'Secretary, confidential' },
  { Id: 9104, Code: '4215', Name: 'Secretary, county, youth club' },
  { Id: 9105, Code: '4215', Name: 'Secretary, farm' },
  { Id: 9106, Code: '4215', Name: 'Secretary, general, welfare services' },
  { Id: 9107, Code: '4215', Name: 'Secretary, group, hospital service' },
  { Id: 9108, Code: '4215', Name: 'Secretary, membership, football club' },
  { Id: 9109, Code: '4215', Name: 'Secretary, personal' },
  { Id: 9110, Code: '4215', Name: 'Secretary, private' },
  { Id: 9111, Code: '4215', Name: 'Secretary, technical' },
  { Id: 9112, Code: '4215', Name: 'Secretary-typist' },
  { Id: 9113, Code: '4216', Name: 'Adviser, service, garage' },
  {
    Id: 9114,
    Code: '4216',
    Name: 'Assistant, surgery, general medical service'
  },
  { Id: 9115, Code: '4216', Name: 'Manager, front of house, hotel' },
  { Id: 9116, Code: '4216', Name: 'Manager, reception' },
  { Id: 9117, Code: '4216', Name: 'Officer, reception' },
  { Id: 9118, Code: '4216', Name: 'Receptionist' },
  { Id: 9119, Code: '4216', Name: 'Receptionist, dental' },
  { Id: 9120, Code: '4216', Name: "Receptionist, doctor's" },
  { Id: 9121, Code: '4216', Name: 'Receptionist, hospital' },
  { Id: 9122, Code: '4216', Name: 'Receptionist, hotel' },
  { Id: 9123, Code: '4216', Name: 'Receptionist, legal' },
  { Id: 9124, Code: '4216', Name: 'Receptionist, medical' },
  { Id: 9125, Code: '4216', Name: 'Receptionist, optical' },
  { Id: 9126, Code: '4216', Name: 'Receptionist, school' },
  { Id: 9127, Code: '4216', Name: 'Receptionist, veterinary' },
  { Id: 9128, Code: '4216', Name: 'Receptionist-administrator' },
  { Id: 9129, Code: '4216', Name: 'Receptionist-bookkeeper' },
  { Id: 9130, Code: '4216', Name: 'Receptionist-clerk' },
  { Id: 9131, Code: '4216', Name: 'Receptionist-secretary' },
  { Id: 9132, Code: '4216', Name: 'Receptionist-telephonist' },
  { Id: 9133, Code: '4216', Name: 'Receptionist-typist' },
  { Id: 9134, Code: '4216', Name: 'Supervisor, reception' },
  { Id: 9135, Code: '4217', Name: 'Administrator, data' },
  { Id: 9136, Code: '4217', Name: 'Administrator, entry, data' },
  { Id: 9137, Code: '4217', Name: 'Assistant, computer' },
  { Id: 9138, Code: '4217', Name: 'Assistant, data' },
  { Id: 9139, Code: '4217', Name: 'Assistant, database' },
  { Id: 9140, Code: '4217', Name: 'Assistant, entry, data' },
  { Id: 9141, Code: '4217', Name: 'Assistant, input' },
  { Id: 9142, Code: '4217', Name: 'Clerk, computer' },
  { Id: 9143, Code: '4217', Name: 'Clerk, control, data' },
  { Id: 9144, Code: '4217', Name: 'Clerk, entry, data' },
  { Id: 9145, Code: '4217', Name: 'Clerk, entry, order' },
  { Id: 9146, Code: '4217', Name: 'Clerk, input' },
  { Id: 9147, Code: '4217', Name: 'Clerk, processing, data' },
  { Id: 9148, Code: '4217', Name: 'Clerk, secretarial' },
  { Id: 9149, Code: '4217', Name: 'Clerk, VDU' },
  { Id: 9150, Code: '4217', Name: 'Clerk-VDU operator' },
  { Id: 9151, Code: '4217', Name: 'Controller, data, computer' },
  { Id: 9152, Code: '4217', Name: 'Coordinator, data' },
  { Id: 9153, Code: '4217', Name: 'Inputter, computer' },
  { Id: 9154, Code: '4217', Name: 'Inputter, copy' },
  { Id: 9155, Code: '4217', Name: 'Inputter, data' },
  { Id: 9156, Code: '4217', Name: 'Inputter, database' },
  { Id: 9157, Code: '4217', Name: 'Inputter, text' },
  { Id: 9158, Code: '4217', Name: 'Machinist, accounting' },
  { Id: 9159, Code: '4217', Name: 'Machinist, accounts' },
  { Id: 9160, Code: '4217', Name: 'Machinist, adding' },
  { Id: 9161, Code: '4217', Name: 'Machinist, addressing' },
  { Id: 9162, Code: '4217', Name: 'Machinist, book-keeping' },
  { Id: 9163, Code: '4217', Name: 'Machinist, calculating' },
  { Id: 9164, Code: '4217', Name: 'Officer, data, clinical' },
  { Id: 9165, Code: '4217', Name: 'Officer, entry, data' },
  { Id: 9166, Code: '4217', Name: 'Officer, preparation, data' },
  { Id: 9167, Code: '4217', Name: 'Officer, processing, data' },
  { Id: 9168, Code: '4217', Name: 'Operative, computer' },
  { Id: 9169, Code: '4217', Name: 'Operator, addressograph' },
  { Id: 9170, Code: '4217', Name: 'Operator, banda' },
  { Id: 9171, Code: '4217', Name: 'Operator, burster' },
  { Id: 9172, Code: '4217', Name: 'Operator, capture, data' },
  { Id: 9173, Code: '4217', Name: 'Operator, card, punch' },
  { Id: 9174, Code: '4217', Name: 'Operator, comptometer' },
  { Id: 9175, Code: '4217', Name: 'Operator, computer' },
  { Id: 9176, Code: '4217', Name: 'Operator, dictaphone' },
  { Id: 9177, Code: '4217', Name: 'Operator, display, visual' },
  { Id: 9178, Code: '4217', Name: 'Operator, edit, tape' },
  { Id: 9179, Code: '4217', Name: 'Operator, entry, data' },
  { Id: 9180, Code: '4217', Name: 'Operator, flexowriter' },
  { Id: 9181, Code: '4217', Name: 'Operator, IBM' },
  { Id: 9182, Code: '4217', Name: 'Operator, ICL' },
  { Id: 9183, Code: '4217', Name: 'Operator, input, data' },
  { Id: 9184, Code: '4217', Name: 'Operator, kardex' },
  { Id: 9185, Code: '4217', Name: 'Operator, key, punch' },
  { Id: 9186, Code: '4217', Name: 'Operator, keyboard' },
  { Id: 9187, Code: '4217', Name: 'Operator, key-punch' },
  { Id: 9188, Code: '4217', Name: 'Operator, key-time' },
  { Id: 9189, Code: '4217', Name: 'Operator, key-to-disc' },
  { Id: 9190, Code: '4217', Name: 'Operator, NCR' },
  { Id: 9191, Code: '4217', Name: 'Operator, preparation, data' },
  { Id: 9192, Code: '4217', Name: 'Operator, processor, word' },
  { Id: 9193, Code: '4217', Name: 'Operator, punch' },
  { Id: 9194, Code: '4217', Name: 'Operator, punch, key' },
  { Id: 9195, Code: '4217', Name: 'Operator, rotaprint' },
  { Id: 9196, Code: '4217', Name: 'Operator, screen' },
  { Id: 9197, Code: '4217', Name: 'Operator, tabulator' },
  { Id: 9198, Code: '4217', Name: 'Operator, VDU' },
  { Id: 9199, Code: '4217', Name: 'Operator, WP' },
  { Id: 9200, Code: '4217', Name: 'Processor, data' },
  { Id: 9201, Code: '4217', Name: 'Processor, text' },
  { Id: 9202, Code: '4217', Name: 'Processor, word' },
  { Id: 9203, Code: '4217', Name: 'Reporter, court' },
  { Id: 9204, Code: '4217', Name: 'Reporter, price, financial services' },
  { Id: 9205, Code: '4217', Name: 'Reporter, verbatim' },
  { Id: 9206, Code: '4217', Name: 'Stenographer' },
  { Id: 9207, Code: '4217', Name: 'Superintendent, typing' },
  { Id: 9208, Code: '4217', Name: "Superintendent, typist's" },
  { Id: 9209, Code: '4217', Name: 'Supervisor, control, computer' },
  { Id: 9210, Code: '4217', Name: 'Supervisor, processing, data' },
  { Id: 9211, Code: '4217', Name: 'Supervisor, processing, word' },
  { Id: 9212, Code: '4217', Name: 'Supervisor, typing' },
  { Id: 9213, Code: '4217', Name: 'Transcriber' },
  { Id: 9214, Code: '4217', Name: 'Typist' },
  { Id: 9215, Code: '4217', Name: 'Typist, audio' },
  { Id: 9216, Code: '4217', Name: 'Typist, copy' },
  { Id: 9217, Code: '4217', Name: 'Typist, shorthand' },
  { Id: 9218, Code: '4217', Name: 'Typist, superintendent' },
  { Id: 9219, Code: '4217', Name: 'Typist-clerk' },
  { Id: 9220, Code: '4217', Name: 'Typist-receptionist' },
  { Id: 9221, Code: '4217', Name: 'Writer, shorthand' },
  { Id: 9222, Code: '5111', Name: 'Agent, farm' },
  { Id: 9223, Code: '5111', Name: 'Agent, livestock' },
  { Id: 9224, Code: '5111', Name: 'Agriculturist' },
  { Id: 9225, Code: '5111', Name: 'Bailiff, estate' },
  { Id: 9226, Code: '5111', Name: 'Bailiff, farm' },
  { Id: 9227, Code: '5111', Name: 'Bailiff, land' },
  { Id: 9228, Code: '5111', Name: 'Breeder, LIVESTOCK' },
  { Id: 9229, Code: '5111', Name: 'Breeder, PIG' },
  { Id: 9230, Code: '5111', Name: 'Contractor, agricultural' },
  { Id: 9231, Code: '5111', Name: 'Contractor, agricultural contracting' },
  { Id: 9232, Code: '5111', Name: 'Contractor, farm' },
  { Id: 9233, Code: '5111', Name: 'Contractor, ploughing' },
  { Id: 9234, Code: '5111', Name: 'Crofter, farming' },
  { Id: 9235, Code: '5111', Name: 'Farmer' },
  { Id: 9236, Code: '5111', Name: 'Farmer, fish' },
  { Id: 9237, Code: '5111', Name: 'Farmer, fish farm, hatchery' },
  { Id: 9238, Code: '5111', Name: 'Farmer, game' },
  { Id: 9239, Code: '5111', Name: 'Farmer, salmon' },
  { Id: 9240, Code: '5111', Name: 'Farmer, trout' },
  { Id: 9241, Code: '5111', Name: 'Fieldman' },
  { Id: 9242, Code: '5111', Name: 'Fieldsman' },
  { Id: 9243, Code: '5111', Name: 'Foreman, agriculture' },
  { Id: 9244, Code: '5111', Name: 'Foreman, farm' },
  { Id: 9245, Code: '5111', Name: 'Foreman-ganger, agriculture' },
  { Id: 9246, Code: '5111', Name: 'Grieve' },
  { Id: 9247, Code: '5111', Name: 'Holder, small' },
  { Id: 9248, Code: '5111', Name: 'Hwsmyn' },
  { Id: 9249, Code: '5111', Name: 'Keeper, pig' },
  { Id: 9250, Code: '5111', Name: 'Keeper, poultry' },
  { Id: 9251, Code: '5111', Name: 'Landholder' },
  { Id: 9252, Code: '5111', Name: 'Manager, dairy, farming' },
  { Id: 9253, Code: '5111', Name: 'Manager, herd' },
  { Id: 9254, Code: '5111', Name: 'Manager, livestock' },
  { Id: 9255, Code: '5111', Name: 'Manager, pig' },
  { Id: 9256, Code: '5111', Name: 'Manager, poultry' },
  { Id: 9257, Code: '5111', Name: 'Manager, unit, pig' },
  { Id: 9258, Code: '5111', Name: 'Producer, egg' },
  { Id: 9259, Code: '5111', Name: 'Rearer, calf' },
  { Id: 9260, Code: '5111', Name: 'Rearer, cattle' },
  { Id: 9261, Code: '5111', Name: 'Rearer, poultry' },
  { Id: 9262, Code: '5111', Name: 'Smallholder' },
  { Id: 9263, Code: '5111', Name: 'Sprayer, crop' },
  { Id: 9264, Code: '5111', Name: 'Steward, estate' },
  { Id: 9265, Code: '5111', Name: 'Steward, farm' },
  { Id: 9266, Code: '5111', Name: 'Technician, agricultural' },
  { Id: 9267, Code: '5111', Name: 'Technician, farm' },
  {
    Id: 9268,
    Code: '5112',
    Name: "Assistant, gardener's, horticultural nursery"
  },
  { Id: 9269, Code: '5112', Name: "Assistant, gardener's, market gardening" },
  { Id: 9270, Code: '5112', Name: 'Assistant, horticultural' },
  { Id: 9271, Code: '5112', Name: 'Assistant, house, green' },
  { Id: 9272, Code: '5112', Name: 'Assistant, nursery, agriculture' },
  { Id: 9273, Code: '5112', Name: 'Assistant, nursery, garden' },
  { Id: 9274, Code: '5112', Name: 'Assistant, nursery, horticultural nursery' },
  { Id: 9275, Code: '5112', Name: 'Cultivator, watercress' },
  { Id: 9276, Code: '5112', Name: 'Foreman, horticultural nursery' },
  { Id: 9277, Code: '5112', Name: 'Foreman, horticulture' },
  { Id: 9278, Code: '5112', Name: 'Foreman, house, glass' },
  { Id: 9279, Code: '5112', Name: 'Fruitman' },
  { Id: 9280, Code: '5112', Name: 'Fruitman, head' },
  { Id: 9281, Code: '5112', Name: 'Gardener, fruit' },
  { Id: 9282, Code: '5112', Name: 'Gardener, fruit growing' },
  { Id: 9283, Code: '5112', Name: 'Gardener, horticultural nursery' },
  { Id: 9284, Code: '5112', Name: 'Gardener, market' },
  { Id: 9285, Code: '5112', Name: 'Gardener, market gardening' },
  { Id: 9286, Code: '5112', Name: 'Gardener, nursery' },
  { Id: 9287, Code: '5112', Name: 'Grafter, agriculture' },
  { Id: 9288, Code: '5112', Name: 'Grower' },
  { Id: 9289, Code: '5112', Name: 'Grower, FRUIT TREES' },
  { Id: 9290, Code: '5112', Name: 'Grower, ornamental tree nursery' },
  { Id: 9291, Code: '5112', Name: 'Horticulturalist' },
  { Id: 9292, Code: '5112', Name: 'Horticulturist' },
  { Id: 9293, Code: '5112', Name: 'Landscaper, interior' },
  { Id: 9294, Code: '5112', Name: 'Manufacturer, seed' },
  { Id: 9295, Code: '5112', Name: 'Nurseryman' },
  { Id: 9296, Code: '5112', Name: 'Planter, coffee' },
  { Id: 9297, Code: '5112', Name: 'Planter, rubber' },
  { Id: 9298, Code: '5112', Name: 'Planter, tea' },
  { Id: 9299, Code: '5112', Name: 'Planter, tobacco' },
  { Id: 9300, Code: '5112', Name: 'Planter, tree' },
  { Id: 9301, Code: '5112', Name: 'Propagator' },
  { Id: 9302, Code: '5112', Name: 'Pruner, fruit growing' },
  { Id: 9303, Code: '5112', Name: 'Pruner, horticultural nursery' },
  { Id: 9304, Code: '5112', Name: 'Pruner, tree, fruit growing' },
  { Id: 9305, Code: '5112', Name: 'Supervisor, nursery, HORTICULTURAL' },
  { Id: 9306, Code: '5112', Name: 'Technician, horticultural' },
  { Id: 9307, Code: '5112', Name: 'Worker, seed' },
  { Id: 9308, Code: '5113', Name: "Assistant, gardener's" },
  { Id: 9309, Code: '5113', Name: 'Contractor, gardening' },
  { Id: 9310, Code: '5113', Name: 'Contractor, landscape' },
  { Id: 9311, Code: '5113', Name: 'Designer, garden' },
  { Id: 9312, Code: '5113', Name: 'Foreman, landscape' },
  { Id: 9313, Code: '5113', Name: 'Foreman, local government: parks dept' },
  { Id: 9314, Code: '5113', Name: 'Gardener' },
  { Id: 9315, Code: '5113', Name: 'Gardener, landscape' },
  { Id: 9316, Code: '5113', Name: 'Gardener-caretaker' },
  { Id: 9317, Code: '5113', Name: 'Gardener-groundsman' },
  { Id: 9318, Code: '5113', Name: 'Gardener-handyman' },
  { Id: 9319, Code: '5113', Name: 'Handyman-gardener' },
  { Id: 9320, Code: '5113', Name: 'Landscaper' },
  { Id: 9321, Code: '5113', Name: 'Pruner, park' },
  { Id: 9322, Code: '5113', Name: 'Pruner, tree, local government' },
  { Id: 9323, Code: '5113', Name: 'Technician, care, lawn' },
  { Id: 9324, Code: '5114', Name: 'Attendant, ground' },
  { Id: 9325, Code: '5114', Name: 'Contractor, turf' },
  { Id: 9326, Code: '5114', Name: 'Cutter, turf' },
  { Id: 9327, Code: '5114', Name: 'Digger, turf' },
  { Id: 9328, Code: '5114', Name: 'Greenkeeper' },
  { Id: 9329, Code: '5114', Name: 'Greensman' },
  { Id: 9330, Code: '5114', Name: 'Groundsman' },
  { Id: 9331, Code: '5114', Name: 'Groundsperson' },
  { Id: 9332, Code: '5114', Name: 'Improver, green' },
  { Id: 9333, Code: '5114', Name: 'Keeper, grass' },
  { Id: 9334, Code: '5114', Name: 'Keeper, green' },
  { Id: 9335, Code: '5114', Name: 'Keeper, ground' },
  { Id: 9336, Code: '5114', Name: 'Keeper, grounds' },
  { Id: 9337, Code: '5114', Name: 'Layer, turf' },
  { Id: 9338, Code: '5114', Name: 'Man, course, golf' },
  { Id: 9339, Code: '5114', Name: 'Supplier, turf' },
  { Id: 9340, Code: '5119', Name: 'Apiarist' },
  { Id: 9341, Code: '5119', Name: 'Arboriculturist' },
  { Id: 9342, Code: '5119', Name: 'Arborist' },
  { Id: 9343, Code: '5119', Name: 'Assistant, arboricultural' },
  { Id: 9344, Code: '5119', Name: 'Beekeeper' },
  { Id: 9345, Code: '5119', Name: 'Breeder, GAME' },
  { Id: 9346, Code: '5119', Name: 'Captain, fishing' },
  { Id: 9347, Code: '5119', Name: 'Consultant, arboricultural' },
  { Id: 9348, Code: '5119', Name: 'Contractor, timber' },
  { Id: 9349, Code: '5119', Name: 'Falconer' },
  { Id: 9350, Code: '5119', Name: 'Farmer, bee' },
  { Id: 9351, Code: '5119', Name: 'Fisherman' },
  { Id: 9352, Code: '5119', Name: 'Fisherman, share' },
  { Id: 9353, Code: '5119', Name: 'Fisherman-crofter' },
  { Id: 9354, Code: '5119', Name: 'Foreman, lairage' },
  { Id: 9355, Code: '5119', Name: 'Gamekeeper' },
  { Id: 9356, Code: '5119', Name: 'Ghillie' },
  { Id: 9357, Code: '5119', Name: 'Gillie' },
  { Id: 9358, Code: '5119', Name: 'Guide, angling' },
  { Id: 9359, Code: '5119', Name: 'Keeper, bee' },
  { Id: 9360, Code: '5119', Name: 'Keeper, fish' },
  { Id: 9361, Code: '5119', Name: 'Keeper, game' },
  { Id: 9362, Code: '5119', Name: 'Keeper, river' },
  { Id: 9363, Code: '5119', Name: 'Manager, piggery' },
  { Id: 9364, Code: '5119', Name: 'Officer, acquisition, forestry' },
  { Id: 9365, Code: '5119', Name: 'Officer, arboricultural' },
  { Id: 9366, Code: '5119', Name: 'Officer, forest' },
  { Id: 9367, Code: '5119', Name: 'Officer, forestry' },
  { Id: 9368, Code: '5119', Name: 'Officer, tree' },
  { Id: 9369, Code: '5119', Name: 'Skipper, fishing' },
  { Id: 9370, Code: '5119', Name: 'Skipper, trawler' },
  { Id: 9371, Code: '5119', Name: 'Stalker, deer' },
  { Id: 9372, Code: '5119', Name: 'Surgeon, tree' },
  { Id: 9373, Code: '5119', Name: 'Warden, game' },
  { Id: 9374, Code: '5119', Name: 'Worker, arboricultural' },
  { Id: 9375, Code: '5211', Name: 'Bender, iron' },
  { Id: 9376, Code: '5211', Name: 'Bender, spring, spring mfr' },
  { Id: 9377, Code: '5211', Name: 'Blacksmith' },
  { Id: 9378, Code: '5211', Name: 'Blacksmith-engineer' },
  { Id: 9379, Code: '5211', Name: 'Drawer, forging' },
  { Id: 9380, Code: '5211', Name: 'Drawer, pick' },
  { Id: 9381, Code: '5211', Name: 'Farrier' },
  { Id: 9382, Code: '5211', Name: 'Fitter, gate and railings, IRON' },
  { Id: 9383, Code: '5211', Name: 'Fitter, gate, IRON' },
  { Id: 9384, Code: '5211', Name: 'Fitter, spring, forging' },
  { Id: 9385, Code: '5211', Name: 'Forger' },
  { Id: 9386, Code: '5211', Name: 'Hammerman' },
  { Id: 9387, Code: '5211', Name: 'Hooper, wheel' },
  { Id: 9388, Code: '5211', Name: 'Legger, flyer' },
  { Id: 9389, Code: '5211', Name: 'Machinist, spring, coach' },
  { Id: 9390, Code: '5211', Name: 'Maker, bolster, CUTLERY' },
  { Id: 9391, Code: '5211', Name: 'Maker, bolt, FORGED' },
  { Id: 9392, Code: '5211', Name: 'Maker, cable, chain' },
  { Id: 9393, Code: '5211', Name: 'Maker, chain, forging' },
  { Id: 9394, Code: '5211', Name: 'Maker, coupling' },
  { Id: 9395, Code: '5211', Name: 'Maker, file' },
  { Id: 9396, Code: '5211', Name: 'Maker, knife' },
  { Id: 9397, Code: '5211', Name: 'Maker, pick' },
  { Id: 9398, Code: '5211', Name: 'Maker, scythe' },
  { Id: 9399, Code: '5211', Name: 'Maker, shoe, horse' },
  { Id: 9400, Code: '5211', Name: 'Maker, shovel, STEEL' },
  { Id: 9401, Code: '5211', Name: 'Maker, spade' },
  { Id: 9402, Code: '5211', Name: 'Maker, spring, carriage, wagon mfr' },
  { Id: 9403, Code: '5211', Name: 'Maker, spring, laminated' },
  { Id: 9404, Code: '5211', Name: 'Maker, spring, leaf' },
  { Id: 9405, Code: '5211', Name: 'Maker, spring, railway' },
  { Id: 9406, Code: '5211', Name: 'Maker, spring, railway locomotive mfr' },
  { Id: 9407, Code: '5211', Name: 'Maker, tool, chasing' },
  { Id: 9408, Code: '5211', Name: 'Maker, tool, edge' },
  { Id: 9409, Code: '5211', Name: 'Maker, tool, hand' },
  { Id: 9410, Code: '5211', Name: 'Maker, wedge' },
  { Id: 9411, Code: '5211', Name: 'Necker, fly' },
  { Id: 9412, Code: '5211', Name: 'Necker, flyer' },
  { Id: 9413, Code: '5211', Name: 'Pewtersmith' },
  { Id: 9414, Code: '5211', Name: 'Plater, edge tool mfr' },
  { Id: 9415, Code: '5211', Name: 'Plater, hoe' },
  { Id: 9416, Code: '5211', Name: 'Plater, metal cutlery mfr' },
  { Id: 9417, Code: '5211', Name: 'Plater, shovel' },
  { Id: 9418, Code: '5211', Name: 'Plater, tool, edge' },
  { Id: 9419, Code: '5211', Name: 'Presser, fly, forging' },
  { Id: 9420, Code: '5211', Name: 'Presser, forge' },
  { Id: 9421, Code: '5211', Name: 'Presser, forging' },
  { Id: 9422, Code: '5211', Name: 'Presser, hot, metal trades' },
  { Id: 9423, Code: '5211', Name: 'Presser, hydraulic, forging' },
  { Id: 9424, Code: '5211', Name: 'Presser, rolling mill' },
  { Id: 9425, Code: '5211', Name: 'Presser, steel' },
  { Id: 9426, Code: '5211', Name: 'Repairer, chain' },
  { Id: 9427, Code: '5211', Name: 'Repairer, propeller' },
  { Id: 9428, Code: '5211', Name: 'Repairer, spring' },
  { Id: 9429, Code: '5211', Name: 'Setter, axle' },
  { Id: 9430, Code: '5211', Name: 'Setter, metal cutlery mfr' },
  { Id: 9431, Code: '5211', Name: 'Shaper, clog iron mfr' },
  { Id: 9432, Code: '5211', Name: 'Sharpener, gear, mining' },
  { Id: 9433, Code: '5211', Name: 'Shoer, horse' },
  { Id: 9434, Code: '5211', Name: 'Smith' },
  { Id: 9435, Code: '5211', Name: 'Smith, coach' },
  { Id: 9436, Code: '5211', Name: 'Smith, coil, spring mfr' },
  { Id: 9437, Code: '5211', Name: 'Smith, engineering' },
  { Id: 9438, Code: '5211', Name: 'Smith, pan, salt' },
  { Id: 9439, Code: '5211', Name: 'Smith, pan, salt mfr' },
  { Id: 9440, Code: '5211', Name: 'Smith, tool' },
  { Id: 9441, Code: '5211', Name: 'Smither' },
  { Id: 9442, Code: '5211', Name: 'Splitter, fork, digging fork mfr' },
  { Id: 9443, Code: '5211', Name: 'Stamper, brass, hot' },
  { Id: 9444, Code: '5211', Name: 'Stamper, drop, forging' },
  { Id: 9445, Code: '5211', Name: 'Stamper, drop, hot' },
  { Id: 9446, Code: '5211', Name: 'Stamper, forging' },
  { Id: 9447, Code: '5211', Name: 'Stamper, galvanised sheet mfr' },
  { Id: 9448, Code: '5211', Name: 'Stamper, hammer' },
  { Id: 9449, Code: '5211', Name: 'Stamper, hot' },
  { Id: 9450, Code: '5211', Name: 'Stamper, metal tube fittings mfr' },
  { Id: 9451, Code: '5211', Name: 'Stamper, metal, forging' },
  { Id: 9452, Code: '5211', Name: 'Stamper, metal, hot' },
  { Id: 9453, Code: '5211', Name: 'Stamper, rolling mill' },
  { Id: 9454, Code: '5211', Name: 'Stamper, tool, edge' },
  { Id: 9455, Code: '5211', Name: 'Straightener, axle' },
  { Id: 9456, Code: '5211', Name: 'Tyreman, railways' },
  { Id: 9457, Code: '5211', Name: 'Worker, iron, ornamental' },
  { Id: 9458, Code: '5211', Name: 'Worker, iron, wrought' },
  { Id: 9459, Code: '5212', Name: 'Assembler, core, foundry' },
  { Id: 9460, Code: '5212', Name: 'Blower, core' },
  { Id: 9461, Code: '5212', Name: 'Caster at machine' },
  { Id: 9462, Code: '5212', Name: 'Caster, die' },
  { Id: 9463, Code: '5212', Name: 'Corer, foundry' },
  { Id: 9464, Code: '5212', Name: 'Filer, core' },
  { Id: 9465, Code: '5212', Name: 'Hand, tub, foundry' },
  { Id: 9466, Code: '5212', Name: 'Machinist, casting, die' },
  { Id: 9467, Code: '5212', Name: 'Maker, chair, foundry' },
  { Id: 9468, Code: '5212', Name: 'Maker, core, coal mine' },
  { Id: 9469, Code: '5212', Name: 'Maker, core, foundry' },
  { Id: 9470, Code: '5212', Name: 'Maker, core, metal trades' },
  { Id: 9471, Code: '5212', Name: 'Maker, mould, foundry' },
  { Id: 9472, Code: '5212', Name: 'Maker, pattern, foundry' },
  { Id: 9473, Code: '5212', Name: 'Maker, pipe, foundry' },
  { Id: 9474, Code: '5212', Name: 'Man, mould, steelworks' },
  { Id: 9475, Code: '5212', Name: 'Moulder and coremaker, foundry' },
  { Id: 9476, Code: '5212', Name: 'Moulder, aluminium' },
  { Id: 9477, Code: '5212', Name: 'Moulder, bench' },
  { Id: 9478, Code: '5212', Name: 'Moulder, brass' },
  { Id: 9479, Code: '5212', Name: 'Moulder, butyl' },
  { Id: 9480, Code: '5212', Name: 'Moulder, chemical mfr' },
  { Id: 9481, Code: '5212', Name: 'Moulder, coal mine' },
  { Id: 9482, Code: '5212', Name: 'Moulder, connection' },
  { Id: 9483, Code: '5212', Name: 'Moulder, copper' },
  { Id: 9484, Code: '5212', Name: 'Moulder, core' },
  { Id: 9485, Code: '5212', Name: 'Moulder, cylinder' },
  { Id: 9486, Code: '5212', Name: 'Moulder, floor' },
  { Id: 9487, Code: '5212', Name: "Moulder, founder's, pipe" },
  { Id: 9488, Code: '5212', Name: 'Moulder, foundry' },
  { Id: 9489, Code: '5212', Name: 'Moulder, grate, stove' },
  { Id: 9490, Code: '5212', Name: 'Moulder, gutter' },
  { Id: 9491, Code: '5212', Name: 'Moulder, hand, metal trades' },
  { Id: 9492, Code: '5212', Name: 'Moulder, iron' },
  { Id: 9493, Code: '5212', Name: 'Moulder, loam' },
  { Id: 9494, Code: '5212', Name: 'Moulder, machine' },
  { Id: 9495, Code: '5212', Name: 'Moulder, metal trades' },
  { Id: 9496, Code: '5212', Name: 'Moulder, metal, gun' },
  { Id: 9497, Code: '5212', Name: 'Moulder, pattern' },
  { Id: 9498, Code: '5212', Name: 'Moulder, pipe, iron' },
  { Id: 9499, Code: '5212', Name: 'Moulder, pipe, METAL' },
  { Id: 9500, Code: '5212', Name: 'Moulder, plate, METAL' },
  { Id: 9501, Code: '5212', Name: 'Moulder, roll' },
  { Id: 9502, Code: '5212', Name: 'Moulder, sand' },
  { Id: 9503, Code: '5212', Name: 'Moulder, shell' },
  { Id: 9504, Code: '5212', Name: 'Moulder, spray' },
  { Id: 9505, Code: '5212', Name: 'Moulder, steel' },
  { Id: 9506, Code: '5212', Name: 'Moulder, stove' },
  { Id: 9507, Code: '5212', Name: 'Moulder, wheel, metal trades' },
  { Id: 9508, Code: '5212', Name: 'Operator, cast, die' },
  { Id: 9509, Code: '5212', Name: 'Potman, metal mfr: die casting' },
  { Id: 9510, Code: '5212', Name: 'Rammer, chair' },
  { Id: 9511, Code: '5212', Name: 'Rammer, foundry' },
  { Id: 9512, Code: '5212', Name: 'Rammer-up' },
  { Id: 9513, Code: '5212', Name: 'Riddler, sand, foundry' },
  { Id: 9514, Code: '5212', Name: 'Worker, shop, core, metal trades' },
  { Id: 9515, Code: '5213', Name: 'Beater, metal trades' },
  { Id: 9516, Code: '5213', Name: 'Beater, panel, metal trades' },
  { Id: 9517, Code: '5213', Name: 'Bruiser, enamel sign mfr' },
  { Id: 9518, Code: '5213', Name: 'Builder, radiator, car' },
  { Id: 9519, Code: '5213', Name: 'Coppersmith' },
  { Id: 9520, Code: '5213', Name: 'Cutter, stencil, metal trades' },
  { Id: 9521, Code: '5213', Name: 'Dingman' },
  { Id: 9522, Code: '5213', Name: 'Fabricator, metal, sheet' },
  { Id: 9523, Code: '5213', Name: 'Finisher, metal' },
  { Id: 9524, Code: '5213', Name: 'Maker, box, METAL' },
  { Id: 9525, Code: '5213', Name: 'Maker, box, tin' },
  { Id: 9526, Code: '5213', Name: 'Maker, bucket, METAL' },
  { Id: 9527, Code: '5213', Name: 'Maker, canister' },
  { Id: 9528, Code: '5213', Name: 'Maker, case, metal' },
  { Id: 9529, Code: '5213', Name: 'Maker, case, meter' },
  { Id: 9530, Code: '5213', Name: 'Maker, drawer, SAFES' },
  { Id: 9531, Code: '5213', Name: 'Maker, drum and keg' },
  { Id: 9532, Code: '5213', Name: 'Maker, drum, METAL' },
  { Id: 9533, Code: '5213', Name: 'Maker, hollow-ware' },
  { Id: 9534, Code: '5213', Name: 'Maker, keg, METAL' },
  { Id: 9535, Code: '5213', Name: 'Maker, kettle' },
  { Id: 9536, Code: '5213', Name: 'Maker, lamp, oil' },
  { Id: 9537, Code: '5213', Name: 'Maker, pail' },
  { Id: 9538, Code: '5213', Name: 'Maker, panel' },
  { Id: 9539, Code: '5213', Name: 'Maker, pattern, metal, footwear mfr' },
  { Id: 9540, Code: '5213', Name: 'Maker, pipe, organ' },
  { Id: 9541, Code: '5213', Name: 'Maker, plate, stencil' },
  { Id: 9542, Code: '5213', Name: 'Maker, pot, METAL' },
  { Id: 9543, Code: '5213', Name: 'Maker, shim' },
  { Id: 9544, Code: '5213', Name: 'Maker, tray, METAL' },
  { Id: 9545, Code: '5213', Name: 'Maker, trunk, METAL' },
  { Id: 9546, Code: '5213', Name: 'Maker, wing, MOTOR CARS' },
  { Id: 9547, Code: '5213', Name: 'Man, radiator, VEHICLE' },
  { Id: 9548, Code: '5213', Name: 'Man, tin, sheet metal working' },
  { Id: 9549, Code: '5213', Name: 'Manufacturer, sheet metal goods mfr' },
  { Id: 9550, Code: '5213', Name: 'Marker, sheet metal working' },
  { Id: 9551, Code: '5213', Name: 'Mender, tank' },
  { Id: 9552, Code: '5213', Name: 'Pewterer' },
  { Id: 9553, Code: '5213', Name: 'Planisher, iron' },
  { Id: 9554, Code: '5213', Name: 'Planisher, sheet metal goods mfr' },
  { Id: 9555, Code: '5213', Name: 'Plater, last' },
  { Id: 9556, Code: '5213', Name: 'Plater, metal boot last mfr' },
  { Id: 9557, Code: '5213', Name: 'Repairer, container, freight' },
  { Id: 9558, Code: '5213', Name: 'Repairer, radiator, VEHICLE' },
  { Id: 9559, Code: '5213', Name: 'Smith, copper' },
  { Id: 9560, Code: '5213', Name: 'Smith, iron, sheet' },
  { Id: 9561, Code: '5213', Name: 'Smith, metal, gas meter mfr' },
  { Id: 9562, Code: '5213', Name: 'Smith, pan, copper' },
  { Id: 9563, Code: '5213', Name: 'Smith, tin' },
  { Id: 9564, Code: '5213', Name: 'Smith, white' },
  { Id: 9565, Code: '5213', Name: 'Snagger' },
  { Id: 9566, Code: '5213', Name: 'Straightener, sheet metal working' },
  { Id: 9567, Code: '5213', Name: 'Straightener, vehicle mfr' },
  { Id: 9568, Code: '5213', Name: 'Tinker' },
  { Id: 9569, Code: '5213', Name: 'Tinsmith' },
  { Id: 9570, Code: '5213', Name: 'Wheeler, sheet metal working' },
  { Id: 9571, Code: '5213', Name: 'Whitesmith' },
  { Id: 9572, Code: '5213', Name: 'Worker, iron, sheet' },
  { Id: 9573, Code: '5213', Name: 'Worker, metal, sheet' },
  { Id: 9574, Code: '5213', Name: 'Worker, plate, tin' },
  { Id: 9575, Code: '5213', Name: 'Worker, plate, zinc' },
  { Id: 9576, Code: '5213', Name: 'Worker, sheet, METAL' },
  { Id: 9577, Code: '5213', Name: 'Worker, tin, sheet metal working' },
  { Id: 9578, Code: '5213', Name: 'Worker, zinc' },
  { Id: 9579, Code: '5214', Name: 'Beamer, shipbuilding' },
  { Id: 9580, Code: '5214', Name: 'Bender, frame, SHIPYARD' },
  { Id: 9581, Code: '5214', Name: 'Bender, plate' },
  { Id: 9582, Code: '5214', Name: 'Bolter, metal trades' },
  { Id: 9583, Code: '5214', Name: 'Borer, shipbuilding' },
  { Id: 9584, Code: '5214', Name: 'Caulker' },
  { Id: 9585, Code: '5214', Name: 'Caulker-burner' },
  { Id: 9586, Code: '5214', Name: 'Chipper and scraper' },
  { Id: 9587, Code: '5214', Name: 'Chipper, shipbuilding' },
  { Id: 9588, Code: '5214', Name: 'Constructor, rig, oil' },
  { Id: 9589, Code: '5214', Name: 'Driller, boiler mfr' },
  {
    Id: 9590,
    Code: '5214',
    Name: 'Driller, constructional engineering, METAL'
  },
  { Id: 9591, Code: '5214', Name: 'Driller, hand' },
  { Id: 9592, Code: '5214', Name: 'Driller, hydraulic' },
  { Id: 9593, Code: '5214', Name: 'Driller, plate' },
  { Id: 9594, Code: '5214', Name: 'Driller, portable' },
  { Id: 9595, Code: '5214', Name: 'Driller, sample, steelworks' },
  { Id: 9596, Code: '5214', Name: 'Driller, test, STEEL' },
  { Id: 9597, Code: '5214', Name: 'Erector, beam and frame' },
  { Id: 9598, Code: '5214', Name: 'Erector, boiler' },
  { Id: 9599, Code: '5214', Name: 'Erector, frame, shipbuilding' },
  { Id: 9600, Code: '5214', Name: 'Expander, boiler mfr' },
  { Id: 9601, Code: '5214', Name: 'Fitter, gasholder' },
  { Id: 9602, Code: '5214', Name: 'Fitter, plate' },
  { Id: 9603, Code: '5214', Name: 'Fitter, tube, boiler mfr' },
  { Id: 9604, Code: '5214', Name: 'Fixer, ship-door and collar' },
  { Id: 9605, Code: '5214', Name: 'Gouger' },
  { Id: 9606, Code: '5214', Name: 'Machinist, constructional' },
  { Id: 9607, Code: '5214', Name: 'Maker, boiler' },
  { Id: 9608, Code: '5214', Name: 'Maker, spar, METAL' },
  { Id: 9609, Code: '5214', Name: 'Maker, tank' },
  { Id: 9610, Code: '5214', Name: 'Marker, boiler mfr' },
  { Id: 9611, Code: '5214', Name: 'Marker, shipbuilding' },
  { Id: 9612, Code: '5214', Name: 'Marker-off, boiler mfr' },
  { Id: 9613, Code: '5214', Name: 'Marker-off, shipbuilding' },
  { Id: 9614, Code: '5214', Name: 'Plater' },
  { Id: 9615, Code: '5214', Name: 'Plater, boiler' },
  { Id: 9616, Code: '5214', Name: 'Plater, bridge' },
  { Id: 9617, Code: '5214', Name: 'Plater, chemical mfr' },
  { Id: 9618, Code: '5214', Name: 'Plater, coal mine' },
  { Id: 9619, Code: '5214', Name: 'Plater, construction' },
  { Id: 9620, Code: '5214', Name: 'Plater, constructional' },
  { Id: 9621, Code: '5214', Name: "Plater, engineer's, gas" },
  { Id: 9622, Code: '5214', Name: 'Plater, framing' },
  { Id: 9623, Code: '5214', Name: 'Plater, heavy' },
  { Id: 9624, Code: '5214', Name: 'Plater, iron' },
  { Id: 9625, Code: '5214', Name: 'Plater, light' },
  { Id: 9626, Code: '5214', Name: 'Plater, metal trades' },
  { Id: 9627, Code: '5214', Name: 'Plater, roof' },
  { Id: 9628, Code: '5214', Name: 'Plater, shell' },
  { Id: 9629, Code: '5214', Name: 'Plater, steel' },
  { Id: 9630, Code: '5214', Name: 'Plater, stem' },
  { Id: 9631, Code: '5214', Name: 'Plater, structural' },
  { Id: 9632, Code: '5214', Name: 'Plater, tank' },
  { Id: 9633, Code: '5214', Name: 'Plater-welder' },
  { Id: 9634, Code: '5214', Name: 'Puncher, boiler mfr' },
  { Id: 9635, Code: '5214', Name: 'Puncher, shipbuilding' },
  { Id: 9636, Code: '5214', Name: 'Repairer, boiler' },
  { Id: 9637, Code: '5214', Name: 'Repairer, tube, boiler mfr' },
  { Id: 9638, Code: '5214', Name: 'Riveter' },
  { Id: 9639, Code: '5214', Name: 'Sheerer, METAL' },
  { Id: 9640, Code: '5214', Name: 'Sheeter, steel mfr' },
  { Id: 9641, Code: '5214', Name: 'Smith, boiler' },
  { Id: 9642, Code: '5214', Name: 'Smith, plate' },
  { Id: 9643, Code: '5214', Name: 'Tapper, stay' },
  { Id: 9644, Code: '5214', Name: 'Tuber, boiler mfr' },
  { Id: 9645, Code: '5214', Name: 'Worker, plate, iron' },
  { Id: 9646, Code: '5214', Name: 'Worker, plate, metal' },
  { Id: 9647, Code: '5214', Name: 'Worker, repair, coal mine: above ground' },
  { Id: 9648, Code: '5214', Name: 'Worker, rig, oil, OIL RIG CONSTRUCTION' },
  { Id: 9649, Code: '5215', Name: "Assistant, welder's" },
  { Id: 9650, Code: '5215', Name: 'Attendant, plant, welding' },
  { Id: 9651, Code: '5215', Name: 'Brazer' },
  { Id: 9652, Code: '5215', Name: 'Brazier' },
  { Id: 9653, Code: '5215', Name: 'Builder, chassis' },
  { Id: 9654, Code: '5215', Name: 'Burner, acetylene' },
  { Id: 9655, Code: '5215', Name: 'Burner, coal mine' },
  { Id: 9656, Code: '5215', Name: 'Burner, demolition' },
  { Id: 9657, Code: '5215', Name: 'Burner, gas, building construction' },
  { Id: 9658, Code: '5215', Name: 'Burner, lead' },
  { Id: 9659, Code: '5215', Name: 'Burner, metal trades' },
  { Id: 9660, Code: '5215', Name: 'Burner, oxy-acetylene' },
  { Id: 9661, Code: '5215', Name: 'Burner, profile' },
  { Id: 9662, Code: '5215', Name: 'Burner, railways' },
  { Id: 9663, Code: '5215', Name: 'Burner, SCRAP METAL' },
  { Id: 9664, Code: '5215', Name: 'Burner, scrap, steelworks' },
  { Id: 9665, Code: '5215', Name: 'Cutter, acetylene' },
  { Id: 9666, Code: '5215', Name: 'Cutter, boiler mfr' },
  { Id: 9667, Code: '5215', Name: 'Cutter, flame' },
  { Id: 9668, Code: '5215', Name: 'Cutter, gas' },
  { Id: 9669, Code: '5215', Name: 'Cutter, metal, scrap' },
  { Id: 9670, Code: '5215', Name: 'Cutter, oxy-acetylene' },
  { Id: 9671, Code: '5215', Name: 'Cutter, scrap' },
  { Id: 9672, Code: '5215', Name: 'Cutter, shipbuilding' },
  { Id: 9673, Code: '5215', Name: 'Cutter, steel' },
  { Id: 9674, Code: '5215', Name: 'Deseamer, steelworks' },
  { Id: 9675, Code: '5215', Name: 'Dresser, flame, rolling mill' },
  { Id: 9676, Code: '5215', Name: 'Engineer, welding' },
  { Id: 9677, Code: '5215', Name: 'Fabricator-welder' },
  { Id: 9678, Code: '5215', Name: 'Fitter-welder' },
  { Id: 9679, Code: '5215', Name: 'Jointer, chain' },
  { Id: 9680, Code: '5215', Name: 'Lancer, thermic' },
  { Id: 9681, Code: '5215', Name: 'Machinist, brazing' },
  { Id: 9682, Code: '5215', Name: 'Machinist, soldering' },
  { Id: 9683, Code: '5215', Name: 'Operator, deseaming, STEEL' },
  { Id: 9684, Code: '5215', Name: 'Scarfer, steel mfr' },
  { Id: 9685, Code: '5215', Name: 'Solderer and jointer, case' },
  { Id: 9686, Code: '5215', Name: 'Solderer, jewellery, plate mfr' },
  { Id: 9687, Code: '5215', Name: 'Solderer, metal trades' },
  { Id: 9688, Code: '5215', Name: 'Sweater, metal trades' },
  { Id: 9689, Code: '5215', Name: 'Technician, welding' },
  { Id: 9690, Code: '5215', Name: 'Welder' },
  { Id: 9691, Code: '5215', Name: 'Welder and cutter' },
  { Id: 9692, Code: '5215', Name: 'Welder, arc' },
  { Id: 9693, Code: '5215', Name: 'Welder, chain' },
  { Id: 9694, Code: '5215', Name: 'Welder, CO2' },
  { Id: 9695, Code: '5215', Name: 'Welder, coded' },
  { Id: 9696, Code: '5215', Name: 'Welder, electric' },
  { Id: 9697, Code: '5215', Name: 'Welder, fabrication' },
  { Id: 9698, Code: '5215', Name: 'Welder, fabricator' },
  { Id: 9699, Code: '5215', Name: 'Welder, maintenance' },
  { Id: 9700, Code: '5215', Name: 'Welder, mig' },
  { Id: 9701, Code: '5215', Name: 'Welder, spot, METAL' },
  { Id: 9702, Code: '5215', Name: 'Welder-fabricator' },
  { Id: 9703, Code: '5215', Name: 'Welder-fitter' },
  { Id: 9704, Code: '5216', Name: 'Engineer, pipe' },
  { Id: 9705, Code: '5216', Name: 'Engineer, pipefitting' },
  { Id: 9706, Code: '5216', Name: 'Erector, pipe' },
  { Id: 9707, Code: '5216', Name: 'Fitter, pipe' },
  { Id: 9708, Code: '5216', Name: 'Fitter, pipe, boiler' },
  { Id: 9709, Code: '5216', Name: 'Fitter, tube' },
  { Id: 9710, Code: '5216', Name: 'Fitter-welder, pipe' },
  { Id: 9711, Code: '5216', Name: 'Layer, pipe, coal mine' },
  { Id: 9712, Code: '5216', Name: 'Lineman, pump' },
  { Id: 9713, Code: '5216', Name: 'Linesman, pump' },
  { Id: 9714, Code: '5216', Name: 'Man, pipe, brine' },
  { Id: 9715, Code: '5216', Name: 'Man, pipe, coal mine' },
  { Id: 9716, Code: '5216', Name: 'Turner, pipe' },
  { Id: 9717, Code: '5216', Name: 'Welder-fitter, pipe' },
  { Id: 9718, Code: '5221', Name: 'Auto-setter, metal trades' },
  { Id: 9719, Code: '5221', Name: 'Borer, cylinder' },
  { Id: 9720, Code: '5221', Name: 'Borer, fine, barrel' },
  { Id: 9721, Code: '5221', Name: 'Borer, horizontal' },
  { Id: 9722, Code: '5221', Name: 'Borer, iron' },
  { Id: 9723, Code: '5221', Name: 'Borer, jig' },
  { Id: 9724, Code: '5221', Name: 'Borer, metal trades' },
  { Id: 9725, Code: '5221', Name: 'Borer, room, tool' },
  { Id: 9726, Code: '5221', Name: 'Borer, scissors' },
  { Id: 9727, Code: '5221', Name: 'Borer, spill, BARREL, SMALL ARMS' },
  { Id: 9728, Code: '5221', Name: 'Borer, tong, TUBES' },
  { Id: 9729, Code: '5221', Name: 'Borer, tyre' },
  { Id: 9730, Code: '5221', Name: 'Borer, universal' },
  { Id: 9731, Code: '5221', Name: 'Borer, vertical' },
  { Id: 9732, Code: '5221', Name: 'Borer, wheel' },
  { Id: 9733, Code: '5221', Name: 'Cutter and grinder, tool' },
  { Id: 9734, Code: '5221', Name: 'Cutter, flyer' },
  { Id: 9735, Code: '5221', Name: 'Cutter, gear' },
  { Id: 9736, Code: '5221', Name: 'Cutter, pin, vice' },
  { Id: 9737, Code: '5221', Name: 'Cutter, profile' },
  { Id: 9738, Code: '5221', Name: 'Cutter, screw' },
  { Id: 9739, Code: '5221', Name: 'Cutter, worm' },
  { Id: 9740, Code: '5221', Name: 'Cutter-grinder, metal trades' },
  { Id: 9741, Code: '5221', Name: 'Driller, air' },
  { Id: 9742, Code: '5221', Name: 'Driller, arm, radial' },
  { Id: 9743, Code: '5221', Name: 'Driller, barrel' },
  { Id: 9744, Code: '5221', Name: 'Driller, box, axle' },
  { Id: 9745, Code: '5221', Name: 'Driller, burner, gas' },
  { Id: 9746, Code: '5221', Name: 'Driller, casement, METAL' },
  { Id: 9747, Code: '5221', Name: 'Driller, circle, textile machinery mfr' },
  { Id: 9748, Code: '5221', Name: 'Driller, coal mine: workshops' },
  { Id: 9749, Code: '5221', Name: 'Driller, faller' },
  { Id: 9750, Code: '5221', Name: 'Driller, frame' },
  { Id: 9751, Code: '5221', Name: 'Driller, hackle' },
  { Id: 9752, Code: '5221', Name: 'Driller, machine, metal trades' },
  { Id: 9753, Code: '5221', Name: 'Driller, metal trades' },
  { Id: 9754, Code: '5221', Name: 'Driller, pin' },
  { Id: 9755, Code: '5221', Name: 'Driller, radial' },
  { Id: 9756, Code: '5221', Name: 'Driller, rail' },
  { Id: 9757, Code: '5221', Name: 'Driller, rim' },
  { Id: 9758, Code: '5221', Name: 'Driller, ring, gas' },
  { Id: 9759, Code: '5221', Name: 'Driller, room, tool' },
  { Id: 9760, Code: '5221', Name: 'Driller, tip' },
  { Id: 9761, Code: '5221', Name: 'Driller, vertical' },
  { Id: 9762, Code: '5221', Name: 'Driller, wheel' },
  { Id: 9763, Code: '5221', Name: 'Engineer, capstan' },
  { Id: 9764, Code: '5221', Name: 'Engineer, CNC' },
  { Id: 9765, Code: '5221', Name: 'Engineer, control, numerically, computer' },
  { Id: 9766, Code: '5221', Name: 'Engineer, grinding' },
  { Id: 9767, Code: '5221', Name: 'Engineer, lathe' },
  { Id: 9768, Code: '5221', Name: 'Engineer, milling' },
  { Id: 9769, Code: '5221', Name: 'Engineer, planer, steel' },
  { Id: 9770, Code: '5221', Name: 'Engineer, planing' },
  { Id: 9771, Code: '5221', Name: 'Engineer, turner' },
  { Id: 9772, Code: '5221', Name: 'Engineer, turner, lathe' },
  { Id: 9773, Code: '5221', Name: 'Engineer-driller' },
  { Id: 9774, Code: '5221', Name: 'Engineer-machinist' },
  { Id: 9775, Code: '5221', Name: 'Facer, metal trades' },
  { Id: 9776, Code: '5221', Name: 'Finisher, bush, axle' },
  { Id: 9777, Code: '5221', Name: 'Finisher, disc, wheel' },
  { Id: 9778, Code: '5221', Name: 'Grinder' },
  { Id: 9779, Code: '5221', Name: 'Grinder and finisher, spring' },
  { Id: 9780, Code: '5221', Name: 'Grinder, axle' },
  { Id: 9781, Code: '5221', Name: 'Grinder, bits, drill' },
  { Id: 9782, Code: '5221', Name: 'Grinder, bolster' },
  { Id: 9783, Code: '5221', Name: 'Grinder, cam-bowl' },
  { Id: 9784, Code: '5221', Name: 'Grinder, camshaft' },
  { Id: 9785, Code: '5221', Name: 'Grinder, centreless' },
  { Id: 9786, Code: '5221', Name: 'Grinder, crankshaft' },
  { Id: 9787, Code: '5221', Name: 'Grinder, cutlery' },
  { Id: 9788, Code: '5221', Name: 'Grinder, cutter, metal trades' },
  { Id: 9789, Code: '5221', Name: 'Grinder, cylinder, metal trades' },
  { Id: 9790, Code: '5221', Name: 'Grinder, cylindrical' },
  { Id: 9791, Code: '5221', Name: 'Grinder, disc, metal trades' },
  { Id: 9792, Code: '5221', Name: 'Grinder, drill' },
  { Id: 9793, Code: '5221', Name: 'Grinder, engineering' },
  { Id: 9794, Code: '5221', Name: 'Grinder, external' },
  { Id: 9795, Code: '5221', Name: 'Grinder, face' },
  { Id: 9796, Code: '5221', Name: 'Grinder, file' },
  { Id: 9797, Code: '5221', Name: 'Grinder, gear' },
  { Id: 9798, Code: '5221', Name: 'Grinder, hob' },
  { Id: 9799, Code: '5221', Name: 'Grinder, instrument' },
  { Id: 9800, Code: '5221', Name: 'Grinder, internal' },
  { Id: 9801, Code: '5221', Name: 'Grinder, jig' },
  { Id: 9802, Code: '5221', Name: 'Grinder, knife' },
  { Id: 9803, Code: '5221', Name: 'Grinder, machine, metal trades' },
  { Id: 9804, Code: '5221', Name: 'Grinder, metal trades' },
  { Id: 9805, Code: '5221', Name: 'Grinder, mower, lawn' },
  { Id: 9806, Code: '5221', Name: 'Grinder, precision' },
  { Id: 9807, Code: '5221', Name: 'Grinder, profile, optical' },
  { Id: 9808, Code: '5221', Name: 'Grinder, race' },
  { Id: 9809, Code: '5221', Name: 'Grinder, razor' },
  { Id: 9810, Code: '5221', Name: 'Grinder, roll' },
  { Id: 9811, Code: '5221', Name: 'Grinder, roller' },
  { Id: 9812, Code: '5221', Name: 'Grinder, room, tool' },
  { Id: 9813, Code: '5221', Name: 'Grinder, saw' },
  { Id: 9814, Code: '5221', Name: 'Grinder, segmental' },
  { Id: 9815, Code: '5221', Name: 'Grinder, shaft' },
  { Id: 9816, Code: '5221', Name: 'Grinder, shears' },
  { Id: 9817, Code: '5221', Name: 'Grinder, spindle' },
  { Id: 9818, Code: '5221', Name: 'Grinder, spline' },
  { Id: 9819, Code: '5221', Name: 'Grinder, spring' },
  { Id: 9820, Code: '5221', Name: 'Grinder, surface' },
  { Id: 9821, Code: '5221', Name: 'Grinder, tool' },
  { Id: 9822, Code: '5221', Name: 'Grinder, tool and cutter' },
  { Id: 9823, Code: '5221', Name: 'Grinder, tool, universal' },
  { Id: 9824, Code: '5221', Name: 'Grinder, universal' },
  { Id: 9825, Code: '5221', Name: 'Grinder, valve' },
  { Id: 9826, Code: '5221', Name: 'Grinder-setter, metal trades' },
  { Id: 9827, Code: '5221', Name: 'Machinist, boring, METAL' },
  { Id: 9828, Code: '5221', Name: 'Machinist, CNC' },
  { Id: 9829, Code: '5221', Name: 'Machinist, facing' },
  { Id: 9830, Code: '5221', Name: 'Machinist, grinding, metal trades' },
  { Id: 9831, Code: '5221', Name: 'Machinist, lathe, metal trades' },
  { Id: 9832, Code: '5221', Name: 'Machinist, milling, metal trades' },
  { Id: 9833, Code: '5221', Name: 'Maker, bush' },
  { Id: 9834, Code: '5221', Name: 'Maker, case, clock' },
  { Id: 9835, Code: '5221', Name: 'Maker, case, watch' },
  { Id: 9836, Code: '5221', Name: 'Maker, case, watch, clock mfr' },
  { Id: 9837, Code: '5221', Name: 'Maker, ferrule, BOILER FERRULES' },
  { Id: 9838, Code: '5221', Name: 'Miller and turner' },
  { Id: 9839, Code: '5221', Name: 'Miller, band, space' },
  { Id: 9840, Code: '5221', Name: 'Miller, bayonet' },
  { Id: 9841, Code: '5221', Name: 'Miller, broaching' },
  { Id: 9842, Code: '5221', Name: 'Miller, CNC' },
  { Id: 9843, Code: '5221', Name: 'Miller, concave, NEEDLES' },
  { Id: 9844, Code: '5221', Name: 'Miller, die' },
  { Id: 9845, Code: '5221', Name: 'Miller, engineer' },
  { Id: 9846, Code: '5221', Name: 'Miller, engineering' },
  { Id: 9847, Code: '5221', Name: "Miller, engineer's" },
  { Id: 9848, Code: '5221', Name: 'Miller, horizontal' },
  { Id: 9849, Code: '5221', Name: 'Miller, machine' },
  { Id: 9850, Code: '5221', Name: 'Miller, metal' },
  { Id: 9851, Code: '5221', Name: 'Miller, metal trades' },
  { Id: 9852, Code: '5221', Name: 'Miller, NC' },
  { Id: 9853, Code: '5221', Name: 'Miller, profile' },
  { Id: 9854, Code: '5221', Name: 'Miller, room, tool' },
  { Id: 9855, Code: '5221', Name: 'Miller, tool' },
  { Id: 9856, Code: '5221', Name: 'Miller, universal' },
  { Id: 9857, Code: '5221', Name: 'Miller, vertical' },
  { Id: 9858, Code: '5221', Name: 'Operator, brake, press' },
  { Id: 9859, Code: '5221', Name: 'Operator, CNC' },
  { Id: 9860, Code: '5221', Name: 'Operator, lathe, coal mine' },
  { Id: 9861, Code: '5221', Name: 'Operator, robot, engineering' },
  { Id: 9862, Code: '5221', Name: 'Planer and slotter, wall' },
  { Id: 9863, Code: '5221', Name: 'Planer, coal mine' },
  { Id: 9864, Code: '5221', Name: 'Planer, die' },
  { Id: 9865, Code: '5221', Name: 'Planer, METAL' },
  { Id: 9866, Code: '5221', Name: 'Planer, metal trades' },
  { Id: 9867, Code: '5221', Name: 'Programmer, CNC' },
  { Id: 9868, Code: '5221', Name: 'Programmer, control, numerical' },
  { Id: 9869, Code: '5221', Name: 'Programmer, machine, metal trades' },
  { Id: 9870, Code: '5221', Name: 'Programmer, NC' },
  { Id: 9871, Code: '5221', Name: 'Programmer, robot' },
  { Id: 9872, Code: '5221', Name: 'Setter and turner, lathe' },
  { Id: 9873, Code: '5221', Name: 'Setter, auto, metal trades' },
  { Id: 9874, Code: '5221', Name: 'Setter, automatic, metal trades' },
  { Id: 9875, Code: '5221', Name: 'Setter, capstan' },
  { Id: 9876, Code: '5221', Name: 'Setter, cast, die' },
  { Id: 9877, Code: '5221', Name: 'Setter, CNC' },
  { Id: 9878, Code: '5221', Name: 'Setter, die' },
  { Id: 9879, Code: '5221', Name: 'Setter, drill' },
  { Id: 9880, Code: '5221', Name: 'Setter, driller' },
  { Id: 9881, Code: '5221', Name: "Setter, engineer's" },
  { Id: 9882, Code: '5221', Name: 'Setter, jig' },
  { Id: 9883, Code: '5221', Name: 'Setter, lathe' },
  { Id: 9884, Code: '5221', Name: 'Setter, lathe, capstan' },
  { Id: 9885, Code: '5221', Name: 'Setter, lathe, centre' },
  { Id: 9886, Code: '5221', Name: 'Setter, lathe, turret' },
  { Id: 9887, Code: '5221', Name: 'Setter, machine, automatic' },
  { Id: 9888, Code: '5221', Name: 'Setter, machine, CNC' },
  { Id: 9889, Code: '5221', Name: 'Setter, machine, coiling' },
  { Id: 9890, Code: '5221', Name: 'Setter, machine, heading, BOLTS, RIVETS' },
  { Id: 9891, Code: '5221', Name: 'Setter, machine, man-made fibre mfr' },
  { Id: 9892, Code: '5221', Name: 'Setter, machine, metal trades' },
  { Id: 9893, Code: '5221', Name: 'Setter, metal trades' },
  { Id: 9894, Code: '5221', Name: 'Setter, milling' },
  { Id: 9895, Code: '5221', Name: 'Setter, press, CNC' },
  { Id: 9896, Code: '5221', Name: 'Setter, press, metal trades' },
  { Id: 9897, Code: '5221', Name: 'Setter, press, NC' },
  { Id: 9898, Code: '5221', Name: 'Setter, press, power' },
  { Id: 9899, Code: '5221', Name: 'Setter, roll, steelworks' },
  { Id: 9900, Code: '5221', Name: 'Setter, spindle' },
  { Id: 9901, Code: '5221', Name: 'Setter, tool' },
  { Id: 9902, Code: '5221', Name: 'Setter, tool, machine' },
  { Id: 9903, Code: '5221', Name: 'Setter, tool, press' },
  { Id: 9904, Code: '5221', Name: 'Setter-operator, capstan' },
  { Id: 9905, Code: '5221', Name: 'Setter-operator, CNC' },
  { Id: 9906, Code: '5221', Name: 'Setter-operator, drill' },
  { Id: 9907, Code: '5221', Name: 'Setter-operator, lathe' },
  { Id: 9908, Code: '5221', Name: 'Setter-operator, lathe, capstan' },
  { Id: 9909, Code: '5221', Name: 'Setter-operator, lathe, centre' },
  { Id: 9910, Code: '5221', Name: 'Setter-operator, lathe, turning, roll' },
  { Id: 9911, Code: '5221', Name: 'Setter-operator, lathe, turret' },
  { Id: 9912, Code: '5221', Name: 'Setter-operator, machine, CNC' },
  { Id: 9913, Code: '5221', Name: 'Setter-operator, machine, die-sinking' },
  { Id: 9914, Code: '5221', Name: 'Setter-operator, machine, metal trades' },
  { Id: 9915, Code: '5221', Name: 'Setter-operator, machine, NC' },
  { Id: 9916, Code: '5221', Name: 'Setter-operator, metal trades' },
  { Id: 9917, Code: '5221', Name: 'Setter-operator, press, brake' },
  { Id: 9918, Code: '5221', Name: 'Setter-operator, press, metal trades' },
  { Id: 9919, Code: '5221', Name: 'Setter-operator, tool' },
  { Id: 9920, Code: '5221', Name: 'Setter-operator, tool, machine' },
  { Id: 9921, Code: '5221', Name: 'Setter-operator, tool, press' },
  { Id: 9922, Code: '5221', Name: 'Setter-operator, turret' },
  { Id: 9923, Code: '5221', Name: 'Setter-up, metal trades' },
  { Id: 9924, Code: '5221', Name: 'Shaper, room, tool' },
  { Id: 9925, Code: '5221', Name: 'Shaper, tool, machine' },
  { Id: 9926, Code: '5221', Name: 'Slider, metal trades' },
  { Id: 9927, Code: '5221', Name: 'Spinner, METAL' },
  { Id: 9928, Code: '5221', Name: 'Tackler, WIRE WEAVING' },
  { Id: 9929, Code: '5221', Name: 'Technician, CNC' },
  { Id: 9930, Code: '5221', Name: 'Toolsetter' },
  { Id: 9931, Code: '5221', Name: 'Turner' },
  { Id: 9932, Code: '5221', Name: 'Turner, axle' },
  { Id: 9933, Code: '5221', Name: 'Turner, band, copper' },
  { Id: 9934, Code: '5221', Name: 'Turner, bobbin, METAL' },
  { Id: 9935, Code: '5221', Name: 'Turner, boss, centre' },
  { Id: 9936, Code: '5221', Name: 'Turner, bush, axle' },
  { Id: 9937, Code: '5221', Name: 'Turner, capstan' },
  { Id: 9938, Code: '5221', Name: 'Turner, commutator' },
  { Id: 9939, Code: '5221', Name: 'Turner, crank' },
  { Id: 9940, Code: '5221', Name: 'Turner, crankshaft' },
  { Id: 9941, Code: '5221', Name: 'Turner, cutter' },
  { Id: 9942, Code: '5221', Name: 'Turner, die' },
  { Id: 9943, Code: '5221', Name: 'Turner, engine' },
  { Id: 9944, Code: '5221', Name: "Turner, engraver's" },
  { Id: 9945, Code: '5221', Name: 'Turner, general' },
  { Id: 9946, Code: '5221', Name: 'Turner, gun' },
  { Id: 9947, Code: '5221', Name: 'Turner, hand, metal trades' },
  { Id: 9948, Code: '5221', Name: 'Turner, lathe, capstan' },
  { Id: 9949, Code: '5221', Name: 'Turner, lathe, centre' },
  { Id: 9950, Code: '5221', Name: 'Turner, lathe, CNC' },
  { Id: 9951, Code: '5221', Name: 'Turner, lathe, metal trades' },
  { Id: 9952, Code: '5221', Name: 'Turner, lathe, NC' },
  { Id: 9953, Code: '5221', Name: 'Turner, lathe, turret' },
  { Id: 9954, Code: '5221', Name: 'Turner, locomotive' },
  { Id: 9955, Code: '5221', Name: 'Turner, loom' },
  { Id: 9956, Code: '5221', Name: 'Turner, maintenance' },
  { Id: 9957, Code: '5221', Name: 'Turner, marine' },
  { Id: 9958, Code: '5221', Name: 'Turner, METAL' },
  { Id: 9959, Code: '5221', Name: 'Turner, mould, glass mfr' },
  { Id: 9960, Code: '5221', Name: 'Turner, ring' },
  { Id: 9961, Code: '5221', Name: 'Turner, roll' },
  { Id: 9962, Code: '5221', Name: 'Turner, roller, METAL' },
  { Id: 9963, Code: '5221', Name: 'Turner, roller, textile machinery mfr' },
  { Id: 9964, Code: '5221', Name: 'Turner, room, tool' },
  { Id: 9965, Code: '5221', Name: 'Turner, rough' },
  { Id: 9966, Code: '5221', Name: 'Turner, textile' },
  { Id: 9967, Code: '5221', Name: 'Turner, tool' },
  { Id: 9968, Code: '5221', Name: 'Turner, tube, steel' },
  { Id: 9969, Code: '5221', Name: 'Turner, tyre, METAL' },
  { Id: 9970, Code: '5221', Name: 'Turner, valve' },
  { Id: 9971, Code: '5221', Name: 'Turner, wheel, metal trades' },
  { Id: 9972, Code: '5221', Name: 'Turner-engineer' },
  { Id: 9973, Code: '5222', Name: 'Corrector, die' },
  { Id: 9974, Code: '5222', Name: 'Cutter, mould' },
  { Id: 9975, Code: '5222', Name: 'Cutter, pattern, metal' },
  { Id: 9976, Code: '5222', Name: 'Cutter, tool, metal trades' },
  { Id: 9977, Code: '5222', Name: 'Doctor, tool' },
  { Id: 9978, Code: '5222', Name: 'Engineer, making, tool' },
  { Id: 9979, Code: '5222', Name: 'Engineer, room, tool' },
  { Id: 9980, Code: '5222', Name: 'Engineer, tool' },
  { Id: 9981, Code: '5222', Name: 'Engineer-toolmaker' },
  { Id: 9982, Code: '5222', Name: 'Fettler, tool' },
  { Id: 9983, Code: '5222', Name: 'Fitter and marker-off' },
  { Id: 9984, Code: '5222', Name: 'Fitter, die' },
  { Id: 9985, Code: '5222', Name: 'Fitter, jig and tool' },
  { Id: 9986, Code: '5222', Name: 'Fitter, pattern, engineering' },
  { Id: 9987, Code: '5222', Name: 'Fitter, room, tool' },
  { Id: 9988, Code: '5222', Name: 'Fitter, tool' },
  { Id: 9989, Code: '5222', Name: 'Fitter, tool, edge' },
  { Id: 9990, Code: '5222', Name: 'Fitter, tool, press' },
  { Id: 9991, Code: '5222', Name: 'Liner, safe mfr' },
  { Id: 9992, Code: '5222', Name: 'Liner-off, engineering' },
  { Id: 9993, Code: '5222', Name: 'Liner-out, engineering' },
  { Id: 9994, Code: '5222', Name: 'Liner-up, engineering' },
  { Id: 9995, Code: '5222', Name: 'Maker, die' },
  { Id: 9996, Code: '5222', Name: 'Maker, die and tool' },
  { Id: 9997, Code: '5222', Name: 'Maker, forme, paper box mfr' },
  { Id: 9998, Code: '5222', Name: 'Maker, gauge, metal trades' },
  { Id: 9999, Code: '5222', Name: 'Maker, jig' },
  { Id: 10000, Code: '5222', Name: 'Maker, jig and gauge' },
  { Id: 10001, Code: '5222', Name: 'Maker, mould, fibre glass mfr' },
  { Id: 10002, Code: '5222', Name: 'Maker, mould, plastics goods mfr' },
  { Id: 10003, Code: '5222', Name: 'Maker, mould, tool, press' },
  { Id: 10004, Code: '5222', Name: 'Maker, template' },
  { Id: 10005, Code: '5222', Name: 'Maker, tool' },
  { Id: 10006, Code: '5222', Name: 'Maker, tool, diamond' },
  { Id: 10007, Code: '5222', Name: 'Maker, tool, machine' },
  { Id: 10008, Code: '5222', Name: 'Maker, tool, press' },
  { Id: 10009, Code: '5222', Name: 'Man, table, surface' },
  { Id: 10010, Code: '5222', Name: 'Marker, metal scales mfr' },
  { Id: 10011, Code: '5222', Name: 'Marker, metal trades: engineering' },
  { Id: 10012, Code: '5222', Name: 'Marker, part' },
  { Id: 10013, Code: '5222', Name: 'Marker-off, engineering' },
  { Id: 10014, Code: '5222', Name: 'Marker-out, engineering' },
  { Id: 10015, Code: '5222', Name: 'Repairer, die' },
  { Id: 10016, Code: '5222', Name: 'Repairer, tool' },
  { Id: 10017, Code: '5222', Name: 'Scriber' },
  { Id: 10018, Code: '5222', Name: 'Setter, gauge' },
  { Id: 10019, Code: '5222', Name: 'Setter-out, ENGINEERING' },
  { Id: 10020, Code: '5222', Name: 'Setter-out, metal trades' },
  { Id: 10021, Code: '5222', Name: 'Sinker, die, metal trades' },
  { Id: 10022, Code: '5222', Name: 'Templater' },
  { Id: 10023, Code: '5223', Name: 'Adjuster, brake' },
  { Id: 10024, Code: '5223', Name: 'Adjuster, machine, textile mfr' },
  { Id: 10025, Code: '5223', Name: 'Adjuster, SCALES' },
  { Id: 10026, Code: '5223', Name: 'Adjuster, spring, set' },
  { Id: 10027, Code: '5223', Name: 'Adjuster, WEIGHING MACHINES' },
  { Id: 10028, Code: '5223', Name: 'Aligner, TYPEWRITERS' },
  { Id: 10029, Code: '5223', Name: 'Armourer' },
  { Id: 10030, Code: '5223', Name: 'Balancer, flyer' },
  { Id: 10031, Code: '5223', Name: 'Blader, TURBINES' },
  { Id: 10032, Code: '5223', Name: 'Blocker, lifting tackle mfr' },
  { Id: 10033, Code: '5223', Name: 'Builder, conveyor' },
  { Id: 10034, Code: '5223', Name: 'Builder, cycle' },
  { Id: 10035, Code: '5223', Name: 'Builder, engine' },
  { Id: 10036, Code: '5223', Name: 'Builder, frame, cycle mfr' },
  { Id: 10037, Code: '5223', Name: 'Builder, lathe' },
  { Id: 10038, Code: '5223', Name: 'Builder, loom' },
  { Id: 10039, Code: '5223', Name: 'Builder, machine' },
  { Id: 10040, Code: '5223', Name: 'Builder, motor' },
  { Id: 10041, Code: '5223', Name: 'Builder, table, SEWING MACHINE' },
  { Id: 10042, Code: '5223', Name: 'Builder, tool, machine' },
  { Id: 10043, Code: '5223', Name: 'Cleaner and balancer, WEIGHING MACHINE' },
  { Id: 10044, Code: '5223', Name: 'Controller, maintenance, planned' },
  { Id: 10045, Code: '5223', Name: 'Controller, maintenance, plant' },
  { Id: 10046, Code: '5223', Name: 'Controller, plant' },
  { Id: 10047, Code: '5223', Name: 'Corrector, spring, vehicle mfr' },
  { Id: 10048, Code: '5223', Name: 'Craftsman, engineering' },
  { Id: 10049, Code: '5223', Name: 'Craftsman, enhanced, power station' },
  { Id: 10050, Code: '5223', Name: 'Craftsman, maintenance, MECHANICAL' },
  { Id: 10051, Code: '5223', Name: 'Craftsman, mechanical' },
  { Id: 10052, Code: '5223', Name: 'Craftsman, underground, coal mine' },
  { Id: 10053, Code: '5223', Name: 'Engineer, agricultural' },
  { Id: 10054, Code: '5223', Name: 'Engineer, assembly, vehicle mfr' },
  { Id: 10055, Code: '5223', Name: 'Engineer, assistant, coal mine, UNIT' },
  { Id: 10056, Code: '5223', Name: 'Engineer, assistant, MECHANICAL' },
  { Id: 10057, Code: '5223', Name: 'Engineer, bakery' },
  { Id: 10058, Code: '5223', Name: "Engineer, brewer's" },
  { Id: 10059, Code: '5223', Name: 'Engineer, carding' },
  { Id: 10060, Code: '5223', Name: 'Engineer, catering' },
  { Id: 10061, Code: '5223', Name: 'Engineer, corrosion' },
  { Id: 10062, Code: '5223', Name: 'Engineer, crane' },
  { Id: 10063, Code: '5223', Name: 'Engineer, cycle' },
  { Id: 10064, Code: '5223', Name: 'Engineer, dairy' },
  { Id: 10065, Code: '5223', Name: 'Engineer, depot, transport' },
  { Id: 10066, Code: '5223', Name: 'Engineer, district' },
  { Id: 10067, Code: '5223', Name: 'Engineer, dockyard' },
  { Id: 10068, Code: '5223', Name: 'Engineer, elevators, grain' },
  { Id: 10069, Code: '5223', Name: 'Engineer, equipment, catering' },
  { Id: 10070, Code: '5223', Name: 'Engineer, erection' },
  { Id: 10071, Code: '5223', Name: 'Engineer, estate' },
  { Id: 10072, Code: '5223', Name: 'Engineer, excavator' },
  { Id: 10073, Code: '5223', Name: 'Engineer, extinguisher, fire' },
  { Id: 10074, Code: '5223', Name: 'Engineer, fabrication' },
  { Id: 10075, Code: '5223', Name: 'Engineer, field, MECHANICAL' },
  { Id: 10076, Code: '5223', Name: 'Engineer, filter' },
  { Id: 10077, Code: '5223', Name: 'Engineer, forklift' },
  { Id: 10078, Code: '5223', Name: 'Engineer, foundry' },
  { Id: 10079, Code: '5223', Name: 'Engineer, furnace' },
  { Id: 10080, Code: '5223', Name: 'Engineer, ground' },
  { Id: 10081, Code: '5223', Name: 'Engineer, heavy' },
  { Id: 10082, Code: '5223', Name: 'Engineer, horticultural' },
  { Id: 10083, Code: '5223', Name: 'Engineer, hosiery' },
  { Id: 10084, Code: '5223', Name: 'Engineer, hospital' },
  { Id: 10085, Code: '5223', Name: 'Engineer, House, Trinity' },
  { Id: 10086, Code: '5223', Name: 'Engineer, hovercraft' },
  { Id: 10087, Code: '5223', Name: 'Engineer, hydraulic' },
  { Id: 10088, Code: '5223', Name: 'Engineer, installation' },
  { Id: 10089, Code: '5223', Name: 'Engineer, investigation, defect' },
  { Id: 10090, Code: '5223', Name: 'Engineer, laundry' },
  { Id: 10091, Code: '5223', Name: 'Engineer, lift' },
  { Id: 10092, Code: '5223', Name: 'Engineer, light' },
  { Id: 10093, Code: '5223', Name: 'Engineer, line, oil refining' },
  { Id: 10094, Code: '5223', Name: 'Engineer, machine' },
  { Id: 10095, Code: '5223', Name: 'Engineer, machinery' },
  { Id: 10096, Code: '5223', Name: 'Engineer, machines' },
  { Id: 10097, Code: '5223', Name: 'Engineer, maintenance' },
  { Id: 10098, Code: '5223', Name: 'Engineer, maintenance, mechanical' },
  { Id: 10099, Code: '5223', Name: 'Engineer, maintenance, plant' },
  { Id: 10100, Code: '5223', Name: 'Engineer, mechanical' },
  { Id: 10101, Code: '5223', Name: 'Engineer, mechanical and electrical' },
  { Id: 10102, Code: '5223', Name: 'Engineer, mill' },
  { Id: 10103, Code: '5223', Name: 'Engineer, model' },
  { Id: 10104, Code: '5223', Name: 'Engineer, monotype' },
  { Id: 10105, Code: '5223', Name: 'Engineer, mower' },
  { Id: 10106, Code: '5223', Name: 'Engineer, nos, coal mine: below ground' },
  { Id: 10107, Code: '5223', Name: 'Engineer, paper' },
  { Id: 10108, Code: '5223', Name: 'Engineer, plant' },
  { Id: 10109, Code: '5223', Name: 'Engineer, pneumatic' },
  { Id: 10110, Code: '5223', Name: "Engineer, potter's" },
  { Id: 10111, Code: '5223', Name: 'Engineer, press, rotary' },
  { Id: 10112, Code: '5223', Name: "Engineer, printer's" },
  { Id: 10113, Code: '5223', Name: 'Engineer, protection, fire' },
  { Id: 10114, Code: '5223', Name: 'Engineer, prototype' },
  { Id: 10115, Code: '5223', Name: 'Engineer, pump' },
  { Id: 10116, Code: '5223', Name: 'Engineer, retort' },
  { Id: 10117, Code: '5223', Name: 'Engineer, safe' },
  { Id: 10118, Code: '5223', Name: 'Engineer, scribbling' },
  { Id: 10119, Code: '5223', Name: 'Engineer, second, textile mfr' },
  { Id: 10120, Code: '5223', Name: 'Engineer, service, engineering' },
  { Id: 10121, Code: '5223', Name: 'Engineer, service, extinguisher, fire' },
  { Id: 10122, Code: '5223', Name: 'Engineer, service, hydraulic' },
  { Id: 10123, Code: '5223', Name: 'Engineer, service, lift' },
  { Id: 10124, Code: '5223', Name: 'Engineer, service, mechanical' },
  { Id: 10125, Code: '5223', Name: 'Engineer, service, oil company' },
  { Id: 10126, Code: '5223', Name: 'Engineer, service, protection, fire' },
  { Id: 10127, Code: '5223', Name: 'Engineer, service, truck, lift, fork' },
  { Id: 10128, Code: '5223', Name: 'Engineer, services, mechanical' },
  { Id: 10129, Code: '5223', Name: 'Engineer, shafting' },
  { Id: 10130, Code: '5223', Name: 'Engineer, shift' },
  { Id: 10131, Code: '5223', Name: 'Engineer, shop, machine' },
  { Id: 10132, Code: '5223', Name: 'Engineer, station, oil refining' },
  { Id: 10133, Code: '5223', Name: 'Engineer, station, pumping' },
  { Id: 10134, Code: '5223', Name: 'Engineer, textile' },
  { Id: 10135, Code: '5223', Name: 'Engineer, tool, machine' },
  { Id: 10136, Code: '5223', Name: 'Engineer, traffic, air' },
  { Id: 10137, Code: '5223', Name: 'Engineer, truck, lift, fork' },
  { Id: 10138, Code: '5223', Name: 'Engineer, works, sewage' },
  { Id: 10139, Code: '5223', Name: 'Engineer, works, water' },
  { Id: 10140, Code: '5223', Name: 'Engineer, workshop' },
  { Id: 10141, Code: '5223', Name: 'Engineer-fitter' },
  { Id: 10142, Code: '5223', Name: 'Enginewright' },
  { Id: 10143, Code: '5223', Name: 'Erector, chassis' },
  { Id: 10144, Code: '5223', Name: 'Erector, coal mine' },
  { Id: 10145, Code: '5223', Name: 'Erector, conveyor' },
  { Id: 10146, Code: '5223', Name: 'Erector, engine' },
  { Id: 10147, Code: '5223', Name: 'Erector, engineering' },
  { Id: 10148, Code: '5223', Name: "Erector, engineer's" },
  { Id: 10149, Code: '5223', Name: 'Erector, frame, vehicle mfr' },
  { Id: 10150, Code: '5223', Name: 'Erector, lift' },
  { Id: 10151, Code: '5223', Name: 'Erector, locomotive' },
  { Id: 10152, Code: '5223', Name: 'Erector, loom' },
  { Id: 10153, Code: '5223', Name: 'Erector, machine' },
  { Id: 10154, Code: '5223', Name: 'Erector, MACHINERY' },
  { Id: 10155, Code: '5223', Name: 'Erector, plant' },
  { Id: 10156, Code: '5223', Name: 'Erector, pump' },
  { Id: 10157, Code: '5223', Name: 'Erector-fitter' },
  { Id: 10158, Code: '5223', Name: 'Fabricator' },
  { Id: 10159, Code: '5223', Name: 'Fabricator, aluminium' },
  { Id: 10160, Code: '5223', Name: 'Finisher, chassis' },
  { Id: 10161, Code: '5223', Name: 'Finisher, cycle' },
  { Id: 10162, Code: '5223', Name: 'Finisher, spring, car, motor' },
  { Id: 10163, Code: '5223', Name: 'Finisher, spring, coach' },
  { Id: 10164, Code: '5223', Name: 'Fitter and assembler' },
  { Id: 10165, Code: '5223', Name: 'Fitter and erector' },
  { Id: 10166, Code: '5223', Name: 'Fitter and examiner' },
  { Id: 10167, Code: '5223', Name: 'Fitter and tester' },
  { Id: 10168, Code: '5223', Name: 'Fitter and turner' },
  { Id: 10169, Code: '5223', Name: 'Fitter, agricultural' },
  { Id: 10170, Code: '5223', Name: 'Fitter, anchor' },
  { Id: 10171, Code: '5223', Name: 'Fitter, armament' },
  { Id: 10172, Code: '5223', Name: 'Fitter, assembly' },
  { Id: 10173, Code: '5223', Name: 'Fitter, axle' },
  { Id: 10174, Code: '5223', Name: 'Fitter, battery' },
  { Id: 10175, Code: '5223', Name: 'Fitter, beam' },
  { Id: 10176, Code: '5223', Name: 'Fitter, bearing, brass' },
  { Id: 10177, Code: '5223', Name: 'Fitter, bench' },
  { Id: 10178, Code: '5223', Name: 'Fitter, blade, TURBINES' },
  { Id: 10179, Code: '5223', Name: 'Fitter, boiler' },
  { Id: 10180, Code: '5223', Name: 'Fitter, box, axle' },
  { Id: 10181, Code: '5223', Name: 'Fitter, box, cam' },
  { Id: 10182, Code: '5223', Name: 'Fitter, box, gear' },
  { Id: 10183, Code: '5223', Name: 'Fitter, box, iron' },
  { Id: 10184, Code: '5223', Name: 'Fitter, brake' },
  { Id: 10185, Code: '5223', Name: 'Fitter, brake, vacuum' },
  { Id: 10186, Code: '5223', Name: 'Fitter, brake, Westinghouse' },
  { Id: 10187, Code: '5223', Name: 'Fitter, brass' },
  { Id: 10188, Code: '5223', Name: 'Fitter, break-off' },
  { Id: 10189, Code: '5223', Name: 'Fitter, cabinet, iron' },
  { Id: 10190, Code: '5223', Name: 'Fitter, car, vehicle mfr' },
  { Id: 10191, Code: '5223', Name: 'Fitter, casement, METAL' },
  { Id: 10192, Code: '5223', Name: 'Fitter, chain' },
  { Id: 10193, Code: '5223', Name: 'Fitter, chassis' },
  { Id: 10194, Code: '5223', Name: 'Fitter, coach' },
  { Id: 10195, Code: '5223', Name: 'Fitter, cock' },
  { Id: 10196, Code: '5223', Name: 'Fitter, colliery' },
  { Id: 10197, Code: '5223', Name: 'Fitter, component, cycle' },
  { Id: 10198, Code: '5223', Name: 'Fitter, constructional' },
  { Id: 10199, Code: '5223', Name: 'Fitter, conveyor' },
  { Id: 10200, Code: '5223', Name: 'Fitter, crane' },
  { Id: 10201, Code: '5223', Name: 'Fitter, cycle' },
  { Id: 10202, Code: '5223', Name: 'Fitter, detail' },
  { Id: 10203, Code: '5223', Name: 'Fitter, development' },
  { Id: 10204, Code: '5223', Name: 'Fitter, dock' },
  { Id: 10205, Code: '5223', Name: 'Fitter, door, car' },
  { Id: 10206, Code: '5223', Name: 'Fitter, door, industrial' },
  { Id: 10207, Code: '5223', Name: 'Fitter, door, steel' },
  { Id: 10208, Code: '5223', Name: 'Fitter, engine' },
  { Id: 10209, Code: '5223', Name: 'Fitter, engineering' },
  { Id: 10210, Code: '5223', Name: "Fitter, engineer's" },
  { Id: 10211, Code: '5223', Name: 'Fitter, erection' },
  { Id: 10212, Code: '5223', Name: 'Fitter, excavator' },
  { Id: 10213, Code: '5223', Name: 'Fitter, experimental' },
  { Id: 10214, Code: '5223', Name: 'Fitter, fabrication' },
  { Id: 10215, Code: '5223', Name: 'Fitter, frame, cycle mfr' },
  { Id: 10216, Code: '5223', Name: 'Fitter, frame, door, metal' },
  { Id: 10217, Code: '5223', Name: 'Fitter, frame, ring' },
  { Id: 10218, Code: '5223', Name: 'Fitter, frame, textile machinery mfr' },
  { Id: 10219, Code: '5223', Name: 'Fitter, furnace' },
  { Id: 10220, Code: '5223', Name: 'Fitter, furniture, METAL' },
  { Id: 10221, Code: '5223', Name: 'Fitter, gear' },
  { Id: 10222, Code: '5223', Name: 'Fitter, general' },
  { Id: 10223, Code: '5223', Name: 'Fitter, grate' },
  { Id: 10224, Code: '5223', Name: 'Fitter, grindstone' },
  { Id: 10225, Code: '5223', Name: 'Fitter, ground, below, coal mine' },
  { Id: 10226, Code: '5223', Name: 'Fitter, gun' },
  { Id: 10227, Code: '5223', Name: 'Fitter, house, light' },
  { Id: 10228, Code: '5223', Name: 'Fitter, house, power' },
  { Id: 10229, Code: '5223', Name: 'Fitter, hydraulic' },
  { Id: 10230, Code: '5223', Name: 'Fitter, industrial' },
  { Id: 10231, Code: '5223', Name: 'Fitter, injection, fuel' },
  { Id: 10232, Code: '5223', Name: 'Fitter, inspector' },
  { Id: 10233, Code: '5223', Name: 'Fitter, installation' },
  { Id: 10234, Code: '5223', Name: 'Fitter, iron foundry' },
  { Id: 10235, Code: '5223', Name: "Fitter, ironmonger's" },
  { Id: 10236, Code: '5223', Name: 'Fitter, ironmongery' },
  { Id: 10237, Code: '5223', Name: 'Fitter, ironwork' },
  { Id: 10238, Code: '5223', Name: 'Fitter, keg' },
  { Id: 10239, Code: '5223', Name: 'Fitter, knife' },
  { Id: 10240, Code: '5223', Name: 'Fitter, laboratory' },
  { Id: 10241, Code: '5223', Name: 'Fitter, lift' },
  { Id: 10242, Code: '5223', Name: 'Fitter, lino, LINOTYPE MACHINE' },
  { Id: 10243, Code: '5223', Name: 'Fitter, linotype' },
  { Id: 10244, Code: '5223', Name: 'Fitter, loom' },
  { Id: 10245, Code: '5223', Name: 'Fitter, machine' },
  { Id: 10246, Code: '5223', Name: 'Fitter, MACHINERY' },
  { Id: 10247, Code: '5223', Name: 'Fitter, maintenance' },
  { Id: 10248, Code: '5223', Name: 'Fitter, mattress, WIRE' },
  { Id: 10249, Code: '5223', Name: 'Fitter, mechanical' },
  { Id: 10250, Code: '5223', Name: 'Fitter, metal' },
  { Id: 10251, Code: '5223', Name: 'Fitter, metal trades' },
  { Id: 10252, Code: '5223', Name: 'Fitter, mining' },
  { Id: 10253, Code: '5223', Name: 'Fitter, mould, bottle' },
  { Id: 10254, Code: '5223', Name: 'Fitter, mount, boiler' },
  { Id: 10255, Code: '5223', Name: 'Fitter, mule' },
  { Id: 10256, Code: '5223', Name: "Fitter, nicker and turner's" },
  { Id: 10257, Code: '5223', Name: 'Fitter, nos' },
  { Id: 10258, Code: '5223', Name: 'Fitter, ordnance' },
  { Id: 10259, Code: '5223', Name: 'Fitter, oven' },
  { Id: 10260, Code: '5223', Name: 'Fitter, plant' },
  { Id: 10261, Code: '5223', Name: "Fitter, potter's" },
  { Id: 10262, Code: '5223', Name: 'Fitter, precision' },
  { Id: 10263, Code: '5223', Name: 'Fitter, printing' },
  { Id: 10264, Code: '5223', Name: 'Fitter, production, MECHANICAL' },
  { Id: 10265, Code: '5223', Name: 'Fitter, pump' },
  { Id: 10266, Code: '5223', Name: 'Fitter, range' },
  { Id: 10267, Code: '5223', Name: 'Fitter, rectification' },
  { Id: 10268, Code: '5223', Name: 'Fitter, repair, engine' },
  { Id: 10269, Code: '5223', Name: 'Fitter, research' },
  { Id: 10270, Code: '5223', Name: 'Fitter, retort' },
  { Id: 10271, Code: '5223', Name: 'Fitter, rough' },
  { Id: 10272, Code: '5223', Name: 'Fitter, safe' },
  { Id: 10273, Code: '5223', Name: 'Fitter, scissors' },
  { Id: 10274, Code: '5223', Name: 'Fitter, screen, coal mine' },
  { Id: 10275, Code: '5223', Name: 'Fitter, service' },
  { Id: 10276, Code: '5223', Name: 'Fitter, shop, machine' },
  { Id: 10277, Code: '5223', Name: 'Fitter, shop, metal trades' },
  { Id: 10278, Code: '5223', Name: 'Fitter, skilled' },
  { Id: 10279, Code: '5223', Name: 'Fitter, skip, coal mine' },
  { Id: 10280, Code: '5223', Name: 'Fitter, speed' },
  { Id: 10281, Code: '5223', Name: 'Fitter, spring' },
  { Id: 10282, Code: '5223', Name: 'Fitter, steel' },
  { Id: 10283, Code: '5223', Name: 'Fitter, steelyard' },
  { Id: 10284, Code: '5223', Name: 'Fitter, structural' },
  { Id: 10285, Code: '5223', Name: 'Fitter, surface' },
  { Id: 10286, Code: '5223', Name: 'Fitter, tank' },
  { Id: 10287, Code: '5223', Name: 'Fitter, tender' },
  { Id: 10288, Code: '5223', Name: 'Fitter, textile' },
  { Id: 10289, Code: '5223', Name: 'Fitter, tool, machine' },
  { Id: 10290, Code: '5223', Name: 'Fitter, torpedo' },
  { Id: 10291, Code: '5223', Name: 'Fitter, tractor' },
  { Id: 10292, Code: '5223', Name: 'Fitter, truck, lift, fork' },
  { Id: 10293, Code: '5223', Name: 'Fitter, try-out' },
  { Id: 10294, Code: '5223', Name: 'Fitter, turbine' },
  { Id: 10295, Code: '5223', Name: 'Fitter, turning' },
  { Id: 10296, Code: '5223', Name: 'Fitter, valve, engineering' },
  { Id: 10297, Code: '5223', Name: 'Fitter, vehicles, vehicle mfr' },
  { Id: 10298, Code: '5223', Name: 'Fitter, weighbridge' },
  { Id: 10299, Code: '5223', Name: 'Fitter, wheel' },
  { Id: 10300, Code: '5223', Name: 'Fitter-assembler' },
  { Id: 10301, Code: '5223', Name: 'Fitter-driver' },
  { Id: 10302, Code: '5223', Name: 'Fitter-engineer' },
  { Id: 10303, Code: '5223', Name: 'Fitter-erector' },
  { Id: 10304, Code: '5223', Name: 'Fitter-inspector' },
  { Id: 10305, Code: '5223', Name: 'Fitter-machinist' },
  { Id: 10306, Code: '5223', Name: 'Fitter-mechanic' },
  { Id: 10307, Code: '5223', Name: 'Fitter-operator, capstan' },
  { Id: 10308, Code: '5223', Name: 'Fitter-operator, tool, machine' },
  { Id: 10309, Code: '5223', Name: 'Fitter-tester' },
  { Id: 10310, Code: '5223', Name: 'Fitter-turner' },
  { Id: 10311, Code: '5223', Name: 'Fitter-up, foundry' },
  { Id: 10312, Code: '5223', Name: 'Fixer, motor' },
  { Id: 10313, Code: '5223', Name: 'Flanger, beam' },
  { Id: 10314, Code: '5223', Name: 'Framer, aluminium' },
  { Id: 10315, Code: '5223', Name: 'Gaiter, harness' },
  { Id: 10316, Code: '5223', Name: 'Gaiter, textile weaving' },
  { Id: 10317, Code: '5223', Name: 'Gunsmith' },
  { Id: 10318, Code: '5223', Name: 'Hand, maintenance, machine, textile mfr' },
  { Id: 10319, Code: '5223', Name: 'Installer, lift' },
  { Id: 10320, Code: '5223', Name: 'Jobber, back' },
  { Id: 10321, Code: '5223', Name: 'Jobber, comb' },
  { Id: 10322, Code: '5223', Name: 'Jobber, combing' },
  { Id: 10323, Code: '5223', Name: 'Jobber, doubling, ring' },
  { Id: 10324, Code: '5223', Name: 'Jobber, loom' },
  { Id: 10325, Code: '5223', Name: 'Jobber, ring' },
  { Id: 10326, Code: '5223', Name: 'Jobber, textile mfr' },
  { Id: 10327, Code: '5223', Name: 'Jobber, twisting' },
  { Id: 10328, Code: '5223', Name: 'Locksmith' },
  { Id: 10329, Code: '5223', Name: 'Machinist, electrochemical' },
  { Id: 10330, Code: '5223', Name: 'Machinist, engineering' },
  { Id: 10331, Code: '5223', Name: 'Machinist, maintenance' },
  { Id: 10332, Code: '5223', Name: 'Maker, barrel, gun mfr' },
  { Id: 10333, Code: '5223', Name: 'Maker, beam, textile machinery mfr' },
  { Id: 10334, Code: '5223', Name: 'Maker, bedstead' },
  { Id: 10335, Code: '5223', Name: 'Maker, body, safe, strong room mfr' },
  { Id: 10336, Code: '5223', Name: 'Maker, bridge, weigh' },
  { Id: 10337, Code: '5223', Name: 'Maker, cabinet, metal' },
  { Id: 10338, Code: '5223', Name: 'Maker, case, safe, strong room mfr' },
  { Id: 10339, Code: '5223', Name: 'Maker, casement, METAL' },
  { Id: 10340, Code: '5223', Name: 'Maker, comb, textile machinery mfr' },
  { Id: 10341, Code: '5223', Name: 'Maker, door, safe, strong room mfr' },
  { Id: 10342, Code: '5223', Name: 'Maker, door, steel' },
  { Id: 10343, Code: '5223', Name: 'Maker, fittings, safe, strong room mfr' },
  { Id: 10344, Code: '5223', Name: 'Maker, fly, textile machinery mfr' },
  { Id: 10345, Code: '5223', Name: 'Maker, frame, cycle mfr' },
  { Id: 10346, Code: '5223', Name: 'Maker, furniture, metal' },
  { Id: 10347, Code: '5223', Name: 'Maker, gear, sighting, gun' },
  { Id: 10348, Code: '5223', Name: 'Maker, gig' },
  { Id: 10349, Code: '5223', Name: 'Maker, gun' },
  { Id: 10350, Code: '5223', Name: 'Maker, ladder, METAL' },
  { Id: 10351, Code: '5223', Name: 'Maker, lock' },
  { Id: 10352, Code: '5223', Name: 'Maker, loom' },
  { Id: 10353, Code: '5223', Name: 'Maker, machine, weighing' },
  { Id: 10354, Code: '5223', Name: 'Maker, machinery, textile' },
  { Id: 10355, Code: '5223', Name: 'Maker, mould, glass mfr' },
  { Id: 10356, Code: '5223', Name: 'Maker, pump' },
  { Id: 10357, Code: '5223', Name: 'Maker, rifle' },
  { Id: 10358, Code: '5223', Name: 'Maker, safe' },
  { Id: 10359, Code: '5223', Name: 'Maker, scale' },
  { Id: 10360, Code: '5223', Name: 'Maker, scale and balance' },
  { Id: 10361, Code: '5223', Name: 'Maker, spindle and flyer' },
  { Id: 10362, Code: '5223', Name: 'Maker, spindle, textile machinery mfr' },
  { Id: 10363, Code: '5223', Name: 'Maker, spring, balance' },
  { Id: 10364, Code: '5223', Name: 'Maker, steelyard' },
  { Id: 10365, Code: '5223', Name: 'Maker, stove' },
  { Id: 10366, Code: '5223', Name: 'Maker, temple' },
  { Id: 10367, Code: '5223', Name: 'Maker, typewriter' },
  { Id: 10368, Code: '5223', Name: 'Maker-up, metal trades' },
  {
    Id: 10369,
    Code: '5223',
    Name: 'Man, maintenance, appliances, mechanical, coal mine'
  },
  { Id: 10370, Code: '5223', Name: 'Man, maintenance, battery' },
  { Id: 10371, Code: '5223', Name: 'Man, maintenance, conveyor, coal mine' },
  { Id: 10372, Code: '5223', Name: 'Man, maintenance, MACHINERY' },
  { Id: 10373, Code: '5223', Name: 'Man, maintenance, WEIGHING MACHINES' },
  { Id: 10374, Code: '5223', Name: 'Man, rectifying, metal trades' },
  { Id: 10375, Code: '5223', Name: 'Man, service, ground' },
  { Id: 10376, Code: '5223', Name: 'Man, service, metal trades' },
  { Id: 10377, Code: '5223', Name: 'Man, test, final, vehicle mfr' },
  { Id: 10378, Code: '5223', Name: 'Manager, maintenance' },
  { Id: 10379, Code: '5223', Name: 'Manufacturer, car' },
  { Id: 10380, Code: '5223', Name: 'Manufacturer, machinery mfr' },
  { Id: 10381, Code: '5223', Name: 'Manufacturer, vehicle mfr' },
  { Id: 10382, Code: '5223', Name: 'Mechanic' },
  { Id: 10383, Code: '5223', Name: 'Mechanic of the mine' },
  { Id: 10384, Code: '5223', Name: 'Mechanic, agricultural' },
  { Id: 10385, Code: '5223', Name: 'Mechanic, battery' },
  { Id: 10386, Code: '5223', Name: 'Mechanic, bicycle' },
  { Id: 10387, Code: '5223', Name: 'Mechanic, bike' },
  { Id: 10388, Code: '5223', Name: "Mechanic, builder's" },
  { Id: 10389, Code: '5223', Name: 'Mechanic, colliery' },
  { Id: 10390, Code: '5223', Name: 'Mechanic, experimental' },
  { Id: 10391, Code: '5223', Name: 'Mechanic, farm' },
  { Id: 10392, Code: '5223', Name: 'Mechanic, hosiery' },
  { Id: 10393, Code: '5223', Name: 'Mechanic, laboratory' },
  { Id: 10394, Code: '5223', Name: 'Mechanic, lift' },
  { Id: 10395, Code: '5223', Name: 'Mechanic, loom' },
  { Id: 10396, Code: '5223', Name: 'Mechanic, machine' },
  { Id: 10397, Code: '5223', Name: 'Mechanic, machine, hosiery' },
  { Id: 10398, Code: '5223', Name: 'Mechanic, machine, knitting' },
  { Id: 10399, Code: '5223', Name: 'Mechanic, machine, sewing' },
  { Id: 10400, Code: '5223', Name: 'Mechanic, machine, vending' },
  { Id: 10401, Code: '5223', Name: 'Mechanic, machine, weighing' },
  { Id: 10402, Code: '5223', Name: 'Mechanic, mower' },
  { Id: 10403, Code: '5223', Name: 'Mechanic, plant' },
  { Id: 10404, Code: '5223', Name: 'Mechanic, research and experimental' },
  { Id: 10405, Code: '5223', Name: 'Mechanic, technical' },
  { Id: 10406, Code: '5223', Name: 'Mechanic-examiner' },
  { Id: 10407, Code: '5223', Name: 'Mechanic-fitter' },
  { Id: 10408, Code: '5223', Name: 'Mender, comb' },
  { Id: 10409, Code: '5223', Name: 'Millwright' },
  { Id: 10410, Code: '5223', Name: 'Mounter, boiler' },
  { Id: 10411, Code: '5223', Name: 'Mounter, engine' },
  { Id: 10412, Code: '5223', Name: 'Overlooker, frame, MAINTENANCE' },
  { Id: 10413, Code: '5223', Name: 'Overlooker, loom, MAINTENANCE' },
  { Id: 10414, Code: '5223', Name: 'Overlooker, textile mfr, MAINTENANCE' },
  { Id: 10415, Code: '5223', Name: 'Overlooker, weaving, MAINTENANCE' },
  { Id: 10416, Code: '5223', Name: 'Pumpwright' },
  { Id: 10417, Code: '5223', Name: 'Railer, bedstead mfr' },
  { Id: 10418, Code: '5223', Name: 'Reconditioner, machine' },
  { Id: 10419, Code: '5223', Name: 'Rectifier, metal trades' },
  { Id: 10420, Code: '5223', Name: 'Repairer, battery' },
  { Id: 10421, Code: '5223', Name: 'Repairer, beam, textile mfr' },
  { Id: 10422, Code: '5223', Name: 'Repairer, bobbin' },
  { Id: 10423, Code: '5223', Name: 'Repairer, brake' },
  { Id: 10424, Code: '5223', Name: 'Repairer, conveyor' },
  { Id: 10425, Code: '5223', Name: 'Repairer, cycle' },
  { Id: 10426, Code: '5223', Name: 'Repairer, hydraulic' },
  { Id: 10427, Code: '5223', Name: 'Repairer, implements, farm' },
  { Id: 10428, Code: '5223', Name: 'Repairer, lamp' },
  { Id: 10429, Code: '5223', Name: 'Repairer, machine' },
  { Id: 10430, Code: '5223', Name: 'Repairer, MACHINERY' },
  { Id: 10431, Code: '5223', Name: 'Repairer, mower' },
  { Id: 10432, Code: '5223', Name: 'Repairer, revolver' },
  { Id: 10433, Code: '5223', Name: 'Repairer, syphon' },
  { Id: 10434, Code: '5223', Name: 'Repairer, tube, carpet, rug mfr' },
  { Id: 10435, Code: '5223', Name: 'Repairer, wagon' },
  { Id: 10436, Code: '5223', Name: 'Rigger, rolling mill' },
  { Id: 10437, Code: '5223', Name: 'Setter, card, textile accessories mfr' },
  { Id: 10438, Code: '5223', Name: 'Setter, handle, textile mfr' },
  { Id: 10439, Code: '5223', Name: 'Setter, loom' },
  { Id: 10440, Code: '5223', Name: 'Setter, pattern' },
  { Id: 10441, Code: '5223', Name: 'Smith, gun' },
  { Id: 10442, Code: '5223', Name: 'Smith, lock' },
  { Id: 10443, Code: '5223', Name: 'Smith, padlock' },
  {
    Id: 10444,
    Code: '5223',
    Name: 'Specialist, manufacturing, motor vehicle mfr'
  },
  { Id: 10445, Code: '5223', Name: 'Springer, carriage' },
  {
    Id: 10446,
    Code: '5223',
    Name: 'Straightener, carriage, textile machinery mfr'
  },
  { Id: 10447, Code: '5223', Name: 'Straightener, textile machinery mfr' },
  { Id: 10448, Code: '5223', Name: 'Stripper, brake' },
  { Id: 10449, Code: '5223', Name: 'Stripper, engine' },
  { Id: 10450, Code: '5223', Name: 'Stripper, spring' },
  { Id: 10451, Code: '5223', Name: 'Tackler, dobby' },
  { Id: 10452, Code: '5223', Name: 'Tackler, jacquard' },
  { Id: 10453, Code: '5223', Name: 'Tackler, loom' },
  { Id: 10454, Code: '5223', Name: 'Tackler, machine, braid' },
  { Id: 10455, Code: '5223', Name: 'Tackler, textile weaving' },
  { Id: 10456, Code: '5223', Name: 'Technician, cycle' },
  { Id: 10457, Code: '5223', Name: 'Technician, hydraulic' },
  { Id: 10458, Code: '5223', Name: 'Technician, maintenance' },
  { Id: 10459, Code: '5223', Name: 'Tenter, carpet, rug mfr' },
  { Id: 10460, Code: '5223', Name: 'Tenter, conant' },
  { Id: 10461, Code: '5223', Name: 'Tenter, frame, jacquard' },
  { Id: 10462, Code: '5223', Name: 'Tenter, hosepipe' },
  { Id: 10463, Code: '5223', Name: 'Tenter, lace mfr' },
  { Id: 10464, Code: '5223', Name: 'Tenter, textile weaving' },
  { Id: 10465, Code: '5223', Name: "Tenter, weaver's" },
  { Id: 10466, Code: '5223', Name: 'Tester-fitter' },
  { Id: 10467, Code: '5223', Name: 'Tester-mechanic' },
  {
    Id: 10468,
    Code: '5223',
    Name: 'Tester-rectifier, engine, combustion, internal'
  },
  { Id: 10469, Code: '5223', Name: 'Tuner, loom' },
  { Id: 10470, Code: '5223', Name: 'Tuner, LOOMS' },
  { Id: 10471, Code: '5223', Name: 'Tuner, textile mfr' },
  { Id: 10472, Code: '5223', Name: 'Turner and fitter' },
  { Id: 10473, Code: '5223', Name: 'Turner-fitter' },
  { Id: 10474, Code: '5223', Name: 'Valveman, hydraulic' },
  { Id: 10475, Code: '5223', Name: 'Valveman, steelworks' },
  { Id: 10476, Code: '5223', Name: 'Worker, metal, hospital service' },
  { Id: 10477, Code: '5224', Name: 'Adjuster, compass' },
  { Id: 10478, Code: '5224', Name: 'Adjuster, INSTRUMENTS' },
  { Id: 10479, Code: '5224', Name: 'Adjuster, WATCHES, CLOCKS' },
  { Id: 10480, Code: '5224', Name: 'Boxer-in' },
  { Id: 10481, Code: '5224', Name: 'Calibrator, INSTRUMENTS' },
  { Id: 10482, Code: '5224', Name: 'Cleaner, clock' },
  { Id: 10483, Code: '5224', Name: 'Cleaner, meter' },
  { Id: 10484, Code: '5224', Name: 'Cleaner, watch' },
  { Id: 10485, Code: '5224', Name: 'Craftsman, instrument' },
  { Id: 10486, Code: '5224', Name: 'Craftsman, instrument mfr' },
  { Id: 10487, Code: '5224', Name: 'Divider, hand' },
  { Id: 10488, Code: '5224', Name: 'Divider, instrument, mathematical' },
  { Id: 10489, Code: '5224', Name: 'Divider, thermometer' },
  { Id: 10490, Code: '5224', Name: 'Engineer, calibration' },
  { Id: 10491, Code: '5224', Name: 'Engineer, horological' },
  { Id: 10492, Code: '5224', Name: 'Engineer, instrument' },
  { Id: 10493, Code: '5224', Name: 'Engineer, maintenance, INSTRUMENTS' },
  { Id: 10494, Code: '5224', Name: 'Engineer, meter' },
  { Id: 10495, Code: '5224', Name: 'Engineer, photographic' },
  { Id: 10496, Code: '5224', Name: 'Engineer, precision' },
  { Id: 10497, Code: '5224', Name: 'Engineer, tachograph' },
  { Id: 10498, Code: '5224', Name: 'Finisher, camera' },
  { Id: 10499, Code: '5224', Name: 'Fitter, camera' },
  { Id: 10500, Code: '5224', Name: 'Fitter, clock' },
  { Id: 10501, Code: '5224', Name: 'Fitter, gauge' },
  { Id: 10502, Code: '5224', Name: 'Fitter, instrument' },
  { Id: 10503, Code: '5224', Name: 'Fitter, metal instrument mfr' },
  { Id: 10504, Code: '5224', Name: 'Fitter, meter' },
  { Id: 10505, Code: '5224', Name: 'Fitter, optical' },
  { Id: 10506, Code: '5224', Name: 'Fitter, precision, instrument mfr' },
  { Id: 10507, Code: '5224', Name: 'Fitter, scale' },
  { Id: 10508, Code: '5224', Name: 'Fitter, service, INSTRUMENTS' },
  { Id: 10509, Code: '5224', Name: 'Fitter, speedometer' },
  { Id: 10510, Code: '5224', Name: 'Fitter, taximeter' },
  { Id: 10511, Code: '5224', Name: "Fitter, telegraph, ship's" },
  { Id: 10512, Code: '5224', Name: 'Framer, binocular' },
  { Id: 10513, Code: '5224', Name: 'Graduator, thermometer' },
  { Id: 10514, Code: '5224', Name: 'Hand, bench, metal instrument mfr' },
  { Id: 10515, Code: '5224', Name: 'Hand, meter' },
  { Id: 10516, Code: '5224', Name: 'Horologist' },
  { Id: 10517, Code: '5224', Name: 'Jeweller and watch repairer' },
  { Id: 10518, Code: '5224', Name: 'Jeweller and watchmaker' },
  { Id: 10519, Code: '5224', Name: 'Maker, apparatus, photographic' },
  { Id: 10520, Code: '5224', Name: 'Maker, balance, scales mfr' },
  { Id: 10521, Code: '5224', Name: 'Maker, barometer' },
  { Id: 10522, Code: '5224', Name: 'Maker, camera' },
  { Id: 10523, Code: '5224', Name: 'Maker, chronometer' },
  { Id: 10524, Code: '5224', Name: 'Maker, clock' },
  { Id: 10525, Code: '5224', Name: 'Maker, cog, clock mfr' },
  { Id: 10526, Code: '5224', Name: 'Maker, compass' },
  { Id: 10527, Code: '5224', Name: 'Maker, dial' },
  { Id: 10528, Code: '5224', Name: 'Maker, frame, watch mfr' },
  { Id: 10529, Code: '5224', Name: 'Maker, galvanometer' },
  { Id: 10530, Code: '5224', Name: 'Maker, gauge, pressure' },
  { Id: 10531, Code: '5224', Name: 'Maker, gauge, steam' },
  { Id: 10532, Code: '5224', Name: 'Maker, glass, field' },
  { Id: 10533, Code: '5224', Name: 'Maker, glass, opera' },
  { Id: 10534, Code: '5224', Name: 'Maker, gong, CLOCK' },
  { Id: 10535, Code: '5224', Name: 'Maker, hydrometer' },
  { Id: 10536, Code: '5224', Name: 'Maker, instrument' },
  { Id: 10537, Code: '5224', Name: 'Maker, level, spirit' },
  { Id: 10538, Code: '5224', Name: 'Maker, meter' },
  { Id: 10539, Code: '5224', Name: 'Maker, micrometer' },
  { Id: 10540, Code: '5224', Name: 'Maker, microscope' },
  { Id: 10541, Code: '5224', Name: 'Maker, movement' },
  { Id: 10542, Code: '5224', Name: 'Maker, pedometer' },
  { Id: 10543, Code: '5224', Name: 'Maker, rule, instrument mfr' },
  { Id: 10544, Code: '5224', Name: 'Maker, rule, mathematical' },
  { Id: 10545, Code: '5224', Name: 'Maker, screw, balance' },
  { Id: 10546, Code: '5224', Name: 'Maker, speedometer' },
  { Id: 10547, Code: '5224', Name: 'Maker, taximeter' },
  { Id: 10548, Code: '5224', Name: 'Maker, telescope' },
  { Id: 10549, Code: '5224', Name: 'Maker, watch' },
  { Id: 10550, Code: '5224', Name: 'Maker, watch and clock' },
  { Id: 10551, Code: '5224', Name: 'Maker, wheel, clock mfr' },
  { Id: 10552, Code: '5224', Name: 'Man, maintenance, SCIENTIFIC INSTRUMENTS' },
  { Id: 10553, Code: '5224', Name: 'Manufacturer, instrument' },
  { Id: 10554, Code: '5224', Name: 'Manufacturer, INSTRUMENTS' },
  { Id: 10555, Code: '5224', Name: 'Manufacturer, watch, clock mfr' },
  { Id: 10556, Code: '5224', Name: 'Mechanic, camera' },
  { Id: 10557, Code: '5224', Name: 'Mechanic, clock' },
  { Id: 10558, Code: '5224', Name: 'Mechanic, compass' },
  { Id: 10559, Code: '5224', Name: 'Mechanic, instrument' },
  { Id: 10560, Code: '5224', Name: 'Mechanic, instrument, optical' },
  { Id: 10561, Code: '5224', Name: 'Mechanic, INSTRUMENTS' },
  { Id: 10562, Code: '5224', Name: 'Mechanic, meter, electricity supplier' },
  { Id: 10563, Code: '5224', Name: 'Mechanic, recorder, time' },
  { Id: 10564, Code: '5224', Name: 'Mechanic, service, INSTRUMENTS' },
  { Id: 10565, Code: '5224', Name: 'Mounter, barometer' },
  { Id: 10566, Code: '5224', Name: 'Mounter, instrument mfr' },
  { Id: 10567, Code: '5224', Name: 'Mounter, thermometer' },
  { Id: 10568, Code: '5224', Name: 'Mounter, wheel' },
  { Id: 10569, Code: '5224', Name: 'Polisher, optical' },
  { Id: 10570, Code: '5224', Name: 'Repairer and jeweller, watch' },
  { Id: 10571, Code: '5224', Name: 'Repairer, camera' },
  { Id: 10572, Code: '5224', Name: 'Repairer, chronometer' },
  { Id: 10573, Code: '5224', Name: 'Repairer, clock' },
  { Id: 10574, Code: '5224', Name: 'Repairer, clockwork' },
  {
    Id: 10575,
    Code: '5224',
    Name: 'Repairer, DENTAL AND SURGICAL INSTRUMENTS'
  },
  { Id: 10576, Code: '5224', Name: 'Repairer, instrument' },
  { Id: 10577, Code: '5224', Name: 'Repairer, INSTRUMENTS' },
  { Id: 10578, Code: '5224', Name: 'Repairer, meter' },
  { Id: 10579, Code: '5224', Name: 'Repairer, scale' },
  { Id: 10580, Code: '5224', Name: 'Repairer, watch' },
  { Id: 10581, Code: '5224', Name: 'Repairer, watch and clock' },
  { Id: 10582, Code: '5224', Name: 'Repairer, WATCHES, CLOCKS' },
  { Id: 10583, Code: '5224', Name: 'Setter, watch, clock mfr' },
  { Id: 10584, Code: '5224', Name: 'Technician, calibration' },
  { Id: 10585, Code: '5224', Name: 'Technician, camera' },
  { Id: 10586, Code: '5224', Name: 'Technician, instrument' },
  { Id: 10587, Code: '5224', Name: 'Technician, measurement and control' },
  { Id: 10588, Code: '5224', Name: 'Technician, optical' },
  { Id: 10589, Code: '5224', Name: 'Tester, metal trades: balance mfr' },
  { Id: 10590, Code: '5224', Name: 'Tester, weight, balance mfr' },
  { Id: 10591, Code: '5224', Name: 'Tester-rectifier, instrument, precision' },
  { Id: 10592, Code: '5224', Name: 'Timer' },
  { Id: 10593, Code: '5224', Name: 'Watchmaker' },
  { Id: 10594, Code: '5224', Name: 'Watchmaker and jeweller' },
  { Id: 10595, Code: '5224', Name: 'Worker, bench, instrument mfr' },
  { Id: 10596, Code: '5225', Name: 'Engineer, conditioning, air' },
  { Id: 10597, Code: '5225', Name: 'Engineer, refrigerating' },
  { Id: 10598, Code: '5225', Name: 'Engineer, refrigeration' },
  { Id: 10599, Code: '5225', Name: 'Engineer, repair, refrigeration' },
  { Id: 10600, Code: '5225', Name: 'Engineer, service, refrigeration' },
  { Id: 10601, Code: '5225', Name: 'Fitter, conditioning, air' },
  { Id: 10602, Code: '5225', Name: 'Installer, conditioning, air' },
  { Id: 10603, Code: '5225', Name: 'Maker, refrigerator' },
  { Id: 10604, Code: '5225', Name: 'Mechanic, refrigeration' },
  { Id: 10605, Code: '5225', Name: 'Technician, conditioning, air' },
  { Id: 10606, Code: '5225', Name: 'Technician, refrigeration' },
  { Id: 10607, Code: '5231', Name: 'Adjuster, MOTOR VEHICLES' },
  { Id: 10608, Code: '5231', Name: 'Adjuster, unit' },
  { Id: 10609, Code: '5231', Name: 'Adviser, technical, garage' },
  { Id: 10610, Code: '5231', Name: 'Attendant, bay, lubrication' },
  { Id: 10611, Code: '5231', Name: 'Attendant, garage' },
  { Id: 10612, Code: '5231', Name: 'Attendant, lubrication, MOTOR VEHICLES' },
  { Id: 10613, Code: '5231', Name: 'Bayman, service' },
  { Id: 10614, Code: '5231', Name: 'Electrician, auto' },
  { Id: 10615, Code: '5231', Name: 'Electrician, automobile' },
  {
    Id: 10616,
    Code: '5231',
    Name: 'Electrician, maintenance, MOTOR VEHICLE REPAIR'
  },
  { Id: 10617, Code: '5231', Name: 'Electrician, vehicle' },
  { Id: 10618, Code: '5231', Name: 'Engineer, automobile' },
  { Id: 10619, Code: '5231', Name: 'Engineer, automotive' },
  { Id: 10620, Code: '5231', Name: 'Engineer, breakdown, vehicle' },
  { Id: 10621, Code: '5231', Name: 'Engineer, bus' },
  { Id: 10622, Code: '5231', Name: 'Engineer, diesel' },
  { Id: 10623, Code: '5231', Name: 'Engineer, diesel, vehicle mfr, VEHICLES' },
  { Id: 10624, Code: '5231', Name: 'Engineer, diesel, VEHICLES' },
  { Id: 10625, Code: '5231', Name: 'Engineer, electrical, auto' },
  { Id: 10626, Code: '5231', Name: 'Engineer, fleet' },
  { Id: 10627, Code: '5231', Name: 'Engineer, garage' },
  { Id: 10628, Code: '5231', Name: 'Engineer, HGV' },
  { Id: 10629, Code: '5231', Name: 'Engineer, injection, fuel' },
  { Id: 10630, Code: '5231', Name: 'Engineer, LGV' },
  { Id: 10631, Code: '5231', Name: 'Engineer, maintenance, VEHICLES' },
  { Id: 10632, Code: '5231', Name: 'Engineer, motor' },
  { Id: 10633, Code: '5231', Name: 'Engineer, motor, staff' },
  { Id: 10634, Code: '5231', Name: 'Engineer, motorcycle' },
  { Id: 10635, Code: '5231', Name: 'Engineer, reception, garage' },
  { Id: 10636, Code: '5231', Name: 'Engineer, repair, motor' },
  { Id: 10637, Code: '5231', Name: 'Engineer, service, MOTOR VEHICLES' },
  { Id: 10638, Code: '5231', Name: 'Engineer, service, vehicle' },
  { Id: 10639, Code: '5231', Name: 'Engineer, staff' },
  { Id: 10640, Code: '5231', Name: 'Engineer, transmission, MOTOR VEHICLES' },
  { Id: 10641, Code: '5231', Name: 'Engineer, transport' },
  { Id: 10642, Code: '5231', Name: 'Engineer, vehicle' },
  { Id: 10643, Code: '5231', Name: 'Examiner, MOT' },
  { Id: 10644, Code: '5231', Name: 'Fitter, automobile' },
  { Id: 10645, Code: '5231', Name: 'Fitter, car' },
  { Id: 10646, Code: '5231', Name: 'Fitter, diesel' },
  { Id: 10647, Code: '5231', Name: 'Fitter, diesel, vehicle mfr, VEHICLES' },
  { Id: 10648, Code: '5231', Name: 'Fitter, diesel, VEHICLES' },
  { Id: 10649, Code: '5231', Name: 'Fitter, engine, diesel' },
  { Id: 10650, Code: '5231', Name: 'Fitter, garage' },
  { Id: 10651, Code: '5231', Name: 'Fitter, HGV' },
  { Id: 10652, Code: '5231', Name: 'Fitter, LGV' },
  { Id: 10653, Code: '5231', Name: 'Fitter, lining, brake' },
  { Id: 10654, Code: '5231', Name: 'Fitter, maintenance, VEHICLE SERVICING' },
  {
    Id: 10655,
    Code: '5231',
    Name: 'Fitter, mechanical, vehicle mfr, VEHICLES'
  },
  { Id: 10656, Code: '5231', Name: 'Fitter, mechanical, VEHICLES' },
  { Id: 10657, Code: '5231', Name: 'Fitter, motor' },
  { Id: 10658, Code: '5231', Name: 'Fitter, motor, vehicle mfr' },
  { Id: 10659, Code: '5231', Name: 'Fitter, PSV' },
  { Id: 10660, Code: '5231', Name: 'Fitter, trailer' },
  { Id: 10661, Code: '5231', Name: 'Fitter, transport' },
  { Id: 10662, Code: '5231', Name: 'Fitter, truck, ELECTRIC' },
  { Id: 10663, Code: '5231', Name: 'Fitter, vehicle mfr, VEHICLES' },
  { Id: 10664, Code: '5231', Name: 'Fitter, vehicle, motor' },
  { Id: 10665, Code: '5231', Name: 'Fitter, vehicles' },
  { Id: 10666, Code: '5231', Name: 'Fitter-mechanic, garage' },
  { Id: 10667, Code: '5231', Name: 'Greaser, MOTOR VEHICLES' },
  { Id: 10668, Code: '5231', Name: 'Hand, maintenance, transport' },
  { Id: 10669, Code: '5231', Name: 'Hand, maintenance, VEHICLES' },
  { Id: 10670, Code: '5231', Name: 'Hand, service, garage' },
  { Id: 10671, Code: '5231', Name: 'Handyman, garage' },
  { Id: 10672, Code: '5231', Name: 'Man, maintenance, transport' },
  { Id: 10673, Code: '5231', Name: 'Man, maintenance, VEHICLES' },
  { Id: 10674, Code: '5231', Name: 'Man, service, garage' },
  { Id: 10675, Code: '5231', Name: 'Mechanic and driver, motor' },
  { Id: 10676, Code: '5231', Name: 'Mechanic, AUTO-ENGINES' },
  { Id: 10677, Code: '5231', Name: 'Mechanic, bus' },
  { Id: 10678, Code: '5231', Name: 'Mechanic, car' },
  { Id: 10679, Code: '5231', Name: 'Mechanic, cycle, motor' },
  { Id: 10680, Code: '5231', Name: 'Mechanic, diesel' },
  { Id: 10681, Code: '5231', Name: 'Mechanic, garage' },
  { Id: 10682, Code: '5231', Name: 'Mechanic, HGV' },
  { Id: 10683, Code: '5231', Name: 'Mechanic, motor' },
  { Id: 10684, Code: '5231', Name: 'Mechanic, patrol, vehicle' },
  { Id: 10685, Code: '5231', Name: 'Mechanic, reception' },
  { Id: 10686, Code: '5231', Name: 'Mechanic, semi-skilled' },
  { Id: 10687, Code: '5231', Name: 'Mechanic, service, GARAGE' },
  { Id: 10688, Code: '5231', Name: 'Mechanic, vehicle' },
  { Id: 10689, Code: '5231', Name: 'Mechanic, VEHICLES' },
  { Id: 10690, Code: '5231', Name: 'Mechanic-fitter, motor' },
  { Id: 10691, Code: '5231', Name: 'Oiler and greaser, MOTOR VEHICLES' },
  { Id: 10692, Code: '5231', Name: 'Operator, assistance, roadside' },
  { Id: 10693, Code: '5231', Name: 'Operator, bay, garage' },
  { Id: 10694, Code: '5231', Name: 'Overhauler, VEHICLES' },
  { Id: 10695, Code: '5231', Name: 'Patrol, motoring organisation' },
  { Id: 10696, Code: '5231', Name: 'Patrol, road, motoring organisation' },
  { Id: 10697, Code: '5231', Name: 'Patrolman, motoring organisation' },
  { Id: 10698, Code: '5231', Name: 'Patrolman, road, motoring organisation' },
  { Id: 10699, Code: '5231', Name: 'Reconditioner, engine' },
  { Id: 10700, Code: '5231', Name: 'Reconditioner, gearbox' },
  { Id: 10701, Code: '5231', Name: 'Repairer, cycle, MOTOR' },
  { Id: 10702, Code: '5231', Name: 'Repairer, motor' },
  { Id: 10703, Code: '5231', Name: 'Repairer, MOTOR VEHICLES' },
  { Id: 10704, Code: '5231', Name: 'Repairer, tractor' },
  { Id: 10705, Code: '5231', Name: 'Scout, motoring organisation' },
  { Id: 10706, Code: '5231', Name: 'Servicer, motor mfr' },
  { Id: 10707, Code: '5231', Name: 'Stripper, car' },
  { Id: 10708, Code: '5231', Name: 'Stripper, motor' },
  { Id: 10709, Code: '5231', Name: 'Technician, diagnostic, MOTOR VEHICLES' },
  { Id: 10710, Code: '5231', Name: 'Technician, electrical, auto' },
  { Id: 10711, Code: '5231', Name: 'Technician, HGV' },
  {
    Id: 10712,
    Code: '5231',
    Name: 'Technician, installation, electrical, MOTOR VEHICLES'
  },
  { Id: 10713, Code: '5231', Name: 'Technician, maintenance, vehicle' },
  { Id: 10714, Code: '5231', Name: 'Technician, maintenance, VEHICLES' },
  { Id: 10715, Code: '5231', Name: 'Technician, mobile, MOTOR VEHICLES' },
  { Id: 10716, Code: '5231', Name: 'Technician, motor' },
  { Id: 10717, Code: '5231', Name: 'Technician, MOTOR VEHICLES' },
  { Id: 10718, Code: '5231', Name: 'Technician, motorcycle' },
  { Id: 10719, Code: '5231', Name: 'Technician, motorsport, MOTOR VEHICLES' },
  { Id: 10720, Code: '5231', Name: 'Technician, service, MOTOR VEHICLES' },
  { Id: 10721, Code: '5231', Name: 'Technician, vehicle' },
  { Id: 10722, Code: '5231', Name: 'Technician, vehicle, motor' },
  { Id: 10723, Code: '5231', Name: 'Technician-mechanic, MOTOR VEHICLES' },
  { Id: 10724, Code: '5231', Name: 'Tester, car' },
  { Id: 10725, Code: '5231', Name: 'Tester, MOT' },
  { Id: 10726, Code: '5231', Name: 'Tuner, VEHICLES' },
  { Id: 10727, Code: '5232', Name: 'Beader, coach building' },
  { Id: 10728, Code: '5232', Name: 'Beater and sprayer, paint' },
  { Id: 10729, Code: '5232', Name: 'Beater, panel' },
  { Id: 10730, Code: '5232', Name: 'Bodyworker, garage' },
  { Id: 10731, Code: '5232', Name: 'Builder, ambulance' },
  { Id: 10732, Code: '5232', Name: 'Builder, body, VEHICLE' },
  { Id: 10733, Code: '5232', Name: 'Builder, caravan' },
  { Id: 10734, Code: '5232', Name: 'Builder, carriage' },
  { Id: 10735, Code: '5232', Name: 'Builder, coach' },
  { Id: 10736, Code: '5232', Name: 'Builder, frame, vehicle mfr' },
  { Id: 10737, Code: '5232', Name: 'Builder, vehicle' },
  { Id: 10738, Code: '5232', Name: 'Builder, wagon' },
  { Id: 10739, Code: '5232', Name: 'Finisher, body, vehicle mfr' },
  { Id: 10740, Code: '5232', Name: 'Finisher, car' },
  { Id: 10741, Code: '5232', Name: 'Finisher, coach' },
  { Id: 10742, Code: '5232', Name: 'Finisher, coach building' },
  { Id: 10743, Code: '5232', Name: 'Finisher, vehicle mfr' },
  { Id: 10744, Code: '5232', Name: 'Fitter, body, VEHICLE' },
  { Id: 10745, Code: '5232', Name: 'Fitter, caravan' },
  { Id: 10746, Code: '5232', Name: 'Joiner, coach' },
  { Id: 10747, Code: '5232', Name: 'Maker and repairer, body' },
  { Id: 10748, Code: '5232', Name: 'Maker, body, carriage' },
  { Id: 10749, Code: '5232', Name: 'Maker, body, coach' },
  { Id: 10750, Code: '5232', Name: 'Maker, body, motor' },
  { Id: 10751, Code: '5232', Name: 'Maker, body, motor vehicle mfr' },
  { Id: 10752, Code: '5232', Name: 'Maker, body, railways' },
  { Id: 10753, Code: '5232', Name: 'Maker, body, VEHICLES' },
  { Id: 10754, Code: '5232', Name: 'Maker, coach' },
  { Id: 10755, Code: '5232', Name: 'Maker, wagon' },
  { Id: 10756, Code: '5232', Name: 'Man, timber, vehicle mfr' },
  { Id: 10757, Code: '5232', Name: 'Renovator, car' },
  { Id: 10758, Code: '5232', Name: 'Repairer, body, car' },
  { Id: 10759, Code: '5232', Name: 'Repairer, body, VEHICLE' },
  { Id: 10760, Code: '5232', Name: 'Repairer, body, vehicle, METAL' },
  { Id: 10761, Code: '5232', Name: 'Repairer, box, horse' },
  { Id: 10762, Code: '5232', Name: 'Repairer, coach' },
  { Id: 10763, Code: '5232', Name: 'Repairer, van' },
  { Id: 10764, Code: '5232', Name: 'Restorer, car' },
  { Id: 10765, Code: '5232', Name: 'Restorer, motorcycle' },
  { Id: 10766, Code: '5232', Name: 'Restorer, vehicle' },
  { Id: 10767, Code: '5232', Name: 'Technician, bodyshop' },
  { Id: 10768, Code: '5232', Name: 'Technician, panel, MOTOR VEHICLES' },
  { Id: 10769, Code: '5232', Name: 'Technician, restoration, MOTOR VEHICLES' },
  {
    Id: 10770,
    Code: '5232',
    Name: 'Technician, trim, mechanical and electrical'
  },
  { Id: 10771, Code: '5232', Name: 'Wagonwright' },
  { Id: 10772, Code: '5234', Name: 'Applicator, graphic, VEHICLES' },
  { Id: 10773, Code: '5234', Name: 'Operator, shop, paint, vehicle mfr' },
  { Id: 10774, Code: '5234', Name: 'Painter, aircraft' },
  { Id: 10775, Code: '5234', Name: 'Painter, car' },
  { Id: 10776, Code: '5234', Name: 'Painter, coach' },
  { Id: 10777, Code: '5234', Name: 'Painter, garage' },
  { Id: 10778, Code: '5234', Name: 'Painter, spray, vehicle trades' },
  { Id: 10779, Code: '5234', Name: 'Painter, vehicle mfr' },
  { Id: 10780, Code: '5234', Name: 'Painter, wagon' },
  { Id: 10781, Code: '5234', Name: 'Refinisher' },
  { Id: 10782, Code: '5234', Name: 'Refinisher, vehicle' },
  { Id: 10783, Code: '5234', Name: 'Repairer, paint-work, vehicle mfr' },
  { Id: 10784, Code: '5234', Name: 'Sprayer, body' },
  { Id: 10785, Code: '5234', Name: 'Sprayer, cellulose, vehicle trades' },
  { Id: 10786, Code: '5234', Name: 'Sprayer, paint, car' },
  { Id: 10787, Code: '5234', Name: 'Sprayer, paint, vehicle' },
  { Id: 10788, Code: '5234', Name: 'Sprayer, paint, vehicle trades' },
  { Id: 10789, Code: '5234', Name: 'Sprayer, vehicle trades' },
  { Id: 10790, Code: '5234', Name: 'Technician, paint, MOTOR VEHICLES' },
  { Id: 10791, Code: '5234', Name: 'Technician, refinishing, MOTOR VEHICLES' },
  { Id: 10792, Code: '5234', Name: 'Undersealer, VEHICLES' },
  { Id: 10793, Code: '5234', Name: 'Worker, paint, vehicle mfr' },
  { Id: 10794, Code: '5234', Name: 'Worker, shop, paint, vehicle mfr' },
  { Id: 10795, Code: '5235', Name: 'Dismantler, engine, aircraft' },
  { Id: 10796, Code: '5235', Name: 'Electrician, aircraft' },
  { Id: 10797, Code: '5235', Name: 'Engineer, aero' },
  { Id: 10798, Code: '5235', Name: 'Engineer, aeronautical' },
  { Id: 10799, Code: '5235', Name: 'Engineer, aircraft' },
  { Id: 10800, Code: '5235', Name: 'Engineer, airline' },
  { Id: 10801, Code: '5235', Name: 'Engineer, aviation' },
  { Id: 10802, Code: '5235', Name: 'Engineer, helicopter' },
  { Id: 10803, Code: '5235', Name: 'Engineer, maintenance, aircraft' },
  { Id: 10804, Code: '5235', Name: 'Engineer, nos, AIRCRAFT MAINTENANCE' },
  { Id: 10805, Code: '5235', Name: 'Engineer, service, AIRCRAFT' },
  { Id: 10806, Code: '5235', Name: 'Etcher, aircraft mfr' },
  { Id: 10807, Code: '5235', Name: 'Finisher, aircraft mfr' },
  { Id: 10808, Code: '5235', Name: 'Fitter, aircraft' },
  { Id: 10809, Code: '5235', Name: 'Fitter, airframe' },
  { Id: 10810, Code: '5235', Name: 'Fitter, engine, aero' },
  { Id: 10811, Code: '5235', Name: 'Fitter, engine, aircraft, MAINTENANCE' },
  { Id: 10812, Code: '5235', Name: 'Fitter, frame, air' },
  { Id: 10813, Code: '5235', Name: 'Fitter, instrument, aircraft' },
  { Id: 10814, Code: '5235', Name: 'Fitter, maintenance, AIRCRAFT ENGINES' },
  { Id: 10815, Code: '5235', Name: 'Fitter, mechanical, AIRCRAFT' },
  { Id: 10816, Code: '5235', Name: 'Fitter, service, AIRCRAFT' },
  {
    Id: 10817,
    Code: '5235',
    Name: 'Fitter, service, AIRCRAFT, ELECTRONIC AND RELATED EQUIPMENT'
  },
  { Id: 10818, Code: '5235', Name: 'Fitter, support, aircraft' },
  { Id: 10819, Code: '5235', Name: 'Hand, maintenance, AIRCRAFT' },
  { Id: 10820, Code: '5235', Name: 'Man, maintenance, AIRCRAFT' },
  { Id: 10821, Code: '5235', Name: 'Mechanic, aircraft' },
  { Id: 10822, Code: '5235', Name: 'Refurbisher, aircraft' },
  { Id: 10823, Code: '5235', Name: 'Tester-rectifier, engine, jet' },
  { Id: 10824, Code: '5235', Name: 'TTO, Civil Aviation Authority' },
  { Id: 10825, Code: '5236', Name: 'Boatwright' },
  { Id: 10826, Code: '5236', Name: 'Builder and repairer, boat' },
  { Id: 10827, Code: '5236', Name: 'Builder, barge' },
  { Id: 10828, Code: '5236', Name: 'Builder, boat' },
  { Id: 10829, Code: '5236', Name: 'Builder, ship' },
  { Id: 10830, Code: '5236', Name: 'Builder, yacht' },
  { Id: 10831, Code: '5236', Name: 'Cutter and caulker, shipbuilding' },
  { Id: 10832, Code: '5236', Name: 'Driller, shipbuilding' },
  { Id: 10833, Code: '5236', Name: "Driller, shipwright's" },
  { Id: 10834, Code: '5236', Name: 'Engineer, barge' },
  { Id: 10835, Code: '5236', Name: 'Engineer, chief, BOAT, BARGE' },
  { Id: 10836, Code: '5236', Name: 'Engineer, first, fishing' },
  { Id: 10837, Code: '5236', Name: 'Engineer, marine' },
  { Id: 10838, Code: '5236', Name: 'Engineer, marine, BOAT, BARGE' },
  { Id: 10839, Code: '5236', Name: 'Engineer, nos, boatbuilding' },
  { Id: 10840, Code: '5236', Name: 'Engineer, nos, shipbuilding' },
  { Id: 10841, Code: '5236', Name: 'Engineer, trawler' },
  { Id: 10842, Code: '5236', Name: 'Engineer-mechanic, shipping' },
  { Id: 10843, Code: '5236', Name: 'Erector, beam, shipbuilding' },
  { Id: 10844, Code: '5236', Name: 'Fitter, boatbuilding' },
  { Id: 10845, Code: '5236', Name: 'Fitter, deck' },
  { Id: 10846, Code: '5236', Name: 'Fitter, marine' },
  { Id: 10847, Code: '5236', Name: 'Fitter, ship' },
  { Id: 10848, Code: '5236', Name: 'Fitter, shipbuilding' },
  { Id: 10849, Code: '5236', Name: 'Framer, shipbuilding' },
  { Id: 10850, Code: '5236', Name: 'Holder-up, shipbuilding' },
  { Id: 10851, Code: '5236', Name: "Joiner, ship's" },
  { Id: 10852, Code: '5236', Name: 'Laminator, boat building and repairing' },
  { Id: 10853, Code: '5236', Name: 'Loftsman, shipbuilding' },
  { Id: 10854, Code: '5236', Name: 'Machinist, metal, shipyard' },
  { Id: 10855, Code: '5236', Name: 'Machinist, shipbuilding' },
  { Id: 10856, Code: '5236', Name: 'Maker, boat' },
  { Id: 10857, Code: '5236', Name: 'Maker, mast, shipbuilding' },
  { Id: 10858, Code: '5236', Name: 'Maker, thimble, shipbuilding' },
  { Id: 10859, Code: '5236', Name: 'Packer, shipbuilding' },
  { Id: 10860, Code: '5236', Name: 'Plater, ship' },
  { Id: 10861, Code: '5236', Name: "Plater, ship's" },
  { Id: 10862, Code: '5236', Name: 'Repairer and builder, boat' },
  { Id: 10863, Code: '5236', Name: 'Repairer, barge' },
  { Id: 10864, Code: '5236', Name: 'Repairer, boat' },
  { Id: 10865, Code: '5236', Name: 'Repairer, ship' },
  { Id: 10866, Code: '5236', Name: 'Shipbuilder' },
  { Id: 10867, Code: '5236', Name: 'Shipsmith' },
  { Id: 10868, Code: '5236', Name: 'Shipwright' },
  { Id: 10869, Code: '5236', Name: 'Shipwright-liner' },
  { Id: 10870, Code: '5236', Name: 'Steelworker, shipbuilding' },
  { Id: 10871, Code: '5236', Name: 'Technician, boat' },
  { Id: 10872, Code: '5236', Name: 'Turner, frame, shipbuilding' },
  { Id: 10873, Code: '5236', Name: 'Worker, blowlamp, shipbuilding' },
  { Id: 10874, Code: '5236', Name: 'Worker, steel, shipbuilding' },
  { Id: 10875, Code: '5237', Name: 'Engineer, locomotive' },
  {
    Id: 10876,
    Code: '5237',
    Name: 'Engineer, nos, railway, rolling stock maintenance'
  },
  { Id: 10877, Code: '5237', Name: 'Engineer, railway' },
  { Id: 10878, Code: '5237', Name: 'Engineer, stock, rolling' },
  { Id: 10879, Code: '5237', Name: 'Examiner, brake, railways' },
  { Id: 10880, Code: '5237', Name: 'Examiner, carriage and wagon' },
  { Id: 10881, Code: '5237', Name: 'Examiner, carriage, railways' },
  { Id: 10882, Code: '5237', Name: 'Examiner, coach, railways' },
  { Id: 10883, Code: '5237', Name: 'Examiner, wagon' },
  { Id: 10884, Code: '5237', Name: 'Finisher, railway workshops' },
  { Id: 10885, Code: '5237', Name: 'Fitter, carriage' },
  { Id: 10886, Code: '5237', Name: 'Fitter, carriage and wagon' },
  {
    Id: 10887,
    Code: '5237',
    Name: 'Fitter, frame, loco and rolling stock mfr'
  },
  { Id: 10888, Code: '5237', Name: 'Fitter, locking, SIGNALS' },
  { Id: 10889, Code: '5237', Name: 'Fitter, locomotive' },
  {
    Id: 10890,
    Code: '5237',
    Name: 'Fitter, maintenance, RAILWAY AND ROLLING STOCK'
  },
  {
    Id: 10891,
    Code: '5237',
    Name: 'Fitter, mechanical, RAILWAY AND ROLLING STOCK'
  },
  { Id: 10892, Code: '5237', Name: 'Fitter, railways' },
  { Id: 10893, Code: '5237', Name: 'Fitter, tube, locomotive mfr' },
  { Id: 10894, Code: '5237', Name: 'Fitter, wagon, railway workshops' },
  { Id: 10895, Code: '5237', Name: 'Fixer, railways' },
  { Id: 10896, Code: '5237', Name: 'Lifter, railway workshops' },
  { Id: 10897, Code: '5237', Name: 'Mounter, wheel and axle' },
  { Id: 10898, Code: '5237', Name: 'Repairer, carriage' },
  { Id: 10899, Code: '5237', Name: 'Repairer, coach, railways' },
  { Id: 10900, Code: '5237', Name: 'Stripper, boiler, locomotive' },
  { Id: 10901, Code: '5237', Name: 'Stripper, carriage and wagon' },
  { Id: 10902, Code: '5237', Name: 'Stripper, locomotive' },
  { Id: 10903, Code: '5237', Name: 'Stripper, railway workshops' },
  { Id: 10904, Code: '5237', Name: 'Technician, stock, rolling' },
  { Id: 10905, Code: '5237', Name: 'Tuber, engine, railways' },
  { Id: 10906, Code: '5237', Name: 'Tuber, locomotive mfr' },
  { Id: 10907, Code: '5237', Name: 'Wheeler, railway rolling stock mfr' },
  { Id: 10908, Code: '5241', Name: 'Attendant, light and bell' },
  { Id: 10909, Code: '5241', Name: 'Attendant, light and power' },
  { Id: 10910, Code: '5241', Name: 'Balancer, armature' },
  { Id: 10911, Code: '5241', Name: 'Builder, armature' },
  { Id: 10912, Code: '5241', Name: 'Builder, commutator' },
  { Id: 10913, Code: '5241', Name: 'Builder, transformer' },
  { Id: 10914, Code: '5241', Name: 'Constructor, switchboard' },
  { Id: 10915, Code: '5241', Name: 'Consultant, electrical' },
  { Id: 10916, Code: '5241', Name: 'Contractor, electrical' },
  { Id: 10917, Code: '5241', Name: 'Controller, electrical' },
  { Id: 10918, Code: '5241', Name: 'Craftsman, electrical' },
  { Id: 10919, Code: '5241', Name: 'Craftsman, maintenance, ELECTRICAL' },
  {
    Id: 10920,
    Code: '5241',
    Name: 'Craftsman, transmission, electricity supplier'
  },
  { Id: 10921, Code: '5241', Name: 'Electrician' },
  {
    Id: 10922,
    Code: '5241',
    Name: 'Electrician, maintenance, OFFICE MACHINERY, ELECTRICAL'
  },
  { Id: 10923, Code: '5241', Name: 'Engineer, appliance, DOMESTIC ELECTRICAL' },
  { Id: 10924, Code: '5241', Name: 'Engineer, cinema' },
  { Id: 10925, Code: '5241', Name: 'Engineer, circuit, cinema' },
  {
    Id: 10926,
    Code: '5241',
    Name: 'Engineer, domestic, domestic appliances repairing'
  },
  { Id: 10927, Code: '5241', Name: 'Engineer, electrical' },
  { Id: 10928, Code: '5241', Name: 'Engineer, electrical and mechanical' },
  { Id: 10929, Code: '5241', Name: 'Engineer, electrical, assistant' },
  { Id: 10930, Code: '5241', Name: 'Engineer, electrical, maintenance' },
  { Id: 10931, Code: '5241', Name: 'Engineer, electrical, nos, coal mine' },
  { Id: 10932, Code: '5241', Name: 'Engineer, electrical, unit' },
  { Id: 10933, Code: '5241', Name: 'Engineer, electro-mechanical' },
  { Id: 10934, Code: '5241', Name: 'Engineer, film' },
  { Id: 10935, Code: '5241', Name: 'Engineer, illuminating' },
  {
    Id: 10936,
    Code: '5241',
    Name: 'Engineer, installation, electrical contracting'
  },
  { Id: 10937, Code: '5241', Name: 'Engineer, lighting' },
  { Id: 10938, Code: '5241', Name: 'Engineer, lighting, street' },
  {
    Id: 10939,
    Code: '5241',
    Name: 'Engineer, machine, DOMESTIC ELECTRICAL APPLIANCES'
  },
  { Id: 10940, Code: '5241', Name: 'Engineer, maintenance, electrical' },
  { Id: 10941, Code: '5241', Name: 'Engineer, office, post' },
  { Id: 10942, Code: '5241', Name: 'Engineer, powerhouse' },
  { Id: 10943, Code: '5241', Name: 'Engineer, relay' },
  {
    Id: 10944,
    Code: '5241',
    Name: 'Engineer, service, DOMESTIC ELECTRICAL APPLIANCES'
  },
  { Id: 10945, Code: '5241', Name: 'Engineer, service, electrical' },
  { Id: 10946, Code: '5241', Name: 'Engineer, switchboard' },
  { Id: 10947, Code: '5241', Name: 'Engineer, switchgear' },
  { Id: 10948, Code: '5241', Name: 'Engineer, wiring' },
  { Id: 10949, Code: '5241', Name: 'Erector, MACHINERY, ELECTRICAL' },
  { Id: 10950, Code: '5241', Name: 'Erector, switchgear' },
  { Id: 10951, Code: '5241', Name: 'Erector, transformer' },
  { Id: 10952, Code: '5241', Name: 'Expert, energy, smart' },
  { Id: 10953, Code: '5241', Name: 'Expert, metering, smart' },
  { Id: 10954, Code: '5241', Name: 'Faultman, electricity supplier' },
  { Id: 10955, Code: '5241', Name: 'Finisher, armature' },
  { Id: 10956, Code: '5241', Name: 'Fitter, alternator' },
  { Id: 10957, Code: '5241', Name: 'Fitter, bench, electrical' },
  { Id: 10958, Code: '5241', Name: 'Fitter, controller' },
  { Id: 10959, Code: '5241', Name: 'Fitter, DOMESTIC APPLIANCES' },
  { Id: 10960, Code: '5241', Name: 'Fitter, dynamo' },
  { Id: 10961, Code: '5241', Name: 'Fitter, electrical' },
  { Id: 10962, Code: '5241', Name: 'Fitter, electricity supplier' },
  { Id: 10963, Code: '5241', Name: 'Fitter, electronic' },
  { Id: 10964, Code: '5241', Name: 'Fitter, engineering, electrical' },
  { Id: 10965, Code: '5241', Name: "Fitter, engineer's, electrical" },
  { Id: 10966, Code: '5241', Name: 'Fitter, fan' },
  { Id: 10967, Code: '5241', Name: 'Fitter, installation, electrical' },
  { Id: 10968, Code: '5241', Name: 'Fitter, lamp, arc' },
  { Id: 10969, Code: '5241', Name: 'Fitter, light, ELECTRIC' },
  { Id: 10970, Code: '5241', Name: 'Fitter, machine, ELECTRICAL MACHINES' },
  { Id: 10971, Code: '5241', Name: 'Fitter, MACHINERY, ELECTRICAL MACHINES' },
  { Id: 10972, Code: '5241', Name: 'Fitter, mains, electricity supplier' },
  { Id: 10973, Code: '5241', Name: 'Fitter, maintenance, electrical' },
  { Id: 10974, Code: '5241', Name: 'Fitter, motor, ELECTRIC' },
  { Id: 10975, Code: '5241', Name: 'Fitter, motor, starter' },
  { Id: 10976, Code: '5241', Name: 'Fitter, panel, solar' },
  { Id: 10977, Code: '5241', Name: 'Fitter, pillar' },
  {
    Id: 10978,
    Code: '5241',
    Name: 'Fitter, production, ELECTRICAL, ELECTRONIC'
  },
  { Id: 10979, Code: '5241', Name: 'Fitter, sign, ELECTRIC SIGNS' },
  { Id: 10980, Code: '5241', Name: 'Fitter, switch' },
  { Id: 10981, Code: '5241', Name: 'Fitter, switchboard' },
  { Id: 10982, Code: '5241', Name: 'Fitter, switchgear' },
  { Id: 10983, Code: '5241', Name: 'Fitter, transformer' },
  { Id: 10984, Code: '5241', Name: 'Fixer, meter, ELECTRICITY' },
  { Id: 10985, Code: '5241', Name: 'Fixer, motor, ELECTRIC' },
  { Id: 10986, Code: '5241', Name: 'Fixer, sign, ELECTRIC' },
  { Id: 10987, Code: '5241', Name: 'Hand, bench, electrical engineering' },
  { Id: 10988, Code: '5241', Name: 'Hand, commutator' },
  { Id: 10989, Code: '5241', Name: 'Hand, maintenance, electricity supplier' },
  { Id: 10990, Code: '5241', Name: 'Inspector, box, electricity supplier' },
  { Id: 10991, Code: '5241', Name: 'Installer, electrical contracting' },
  { Id: 10992, Code: '5241', Name: 'Installer, meter, ELECTRICITY' },
  { Id: 10993, Code: '5241', Name: 'Installer, panel, solar' },
  { Id: 10994, Code: '5241', Name: 'Maker, components, telephone mfr' },
  { Id: 10995, Code: '5241', Name: 'Maker, magneto' },
  { Id: 10996, Code: '5241', Name: 'Maker, part, commutator' },
  { Id: 10997, Code: '5241', Name: 'Maker, rotor' },
  { Id: 10998, Code: '5241', Name: 'Maker, switchboard' },
  { Id: 10999, Code: '5241', Name: 'Maker, switchgear' },
  { Id: 11000, Code: '5241', Name: 'Maker, transformer' },
  { Id: 11001, Code: '5241', Name: 'Man, bell, mining' },
  { Id: 11002, Code: '5241', Name: 'Man, circuit, light, electric' },
  { Id: 11003, Code: '5241', Name: 'Man, maintenance, electrical' },
  { Id: 11004, Code: '5241', Name: 'Man, maintenance, electricity supplier' },
  {
    Id: 11005,
    Code: '5241',
    Name: 'Man, maintenance, MACHINERY, ELECTRICAL MACHINES'
  },
  { Id: 11006, Code: '5241', Name: 'Man, service, DOMESTIC APPLIANCES' },
  { Id: 11007, Code: '5241', Name: 'Mechanic, electrical' },
  { Id: 11008, Code: '5241', Name: 'Mechanic, electro' },
  {
    Id: 11009,
    Code: '5241',
    Name: 'Mechanic, service, DOMESTIC ELECTRICAL APPLIANCES'
  },
  { Id: 11010, Code: '5241', Name: 'Mender, DOMESTIC APPLIANCES' },
  { Id: 11011, Code: '5241', Name: 'Repairer, appliance, domestic' },
  { Id: 11012, Code: '5241', Name: 'Repairer, blanket, electric' },
  { Id: 11013, Code: '5241', Name: 'Repairer, controller' },
  { Id: 11014, Code: '5241', Name: 'Repairer, DOMESTIC APPLIANCES' },
  { Id: 11015, Code: '5241', Name: 'Repairer, ELECTRICAL MACHINERY' },
  { Id: 11016, Code: '5241', Name: 'Repairer, magneto' },
  { Id: 11017, Code: '5241', Name: 'Technician, lighting, stage' },
  { Id: 11018, Code: '5241', Name: 'Technician, meter, ELECTRICITY' },
  {
    Id: 11019,
    Code: '5241',
    Name: 'Technician, service, domestic appliances repairing'
  },
  { Id: 11020, Code: '5241', Name: 'Tester, electrical contracting' },
  { Id: 11021, Code: '5241', Name: 'Tester, installation, ELECTRICAL' },
  { Id: 11022, Code: '5241', Name: 'Wireman, electrical' },
  { Id: 11023, Code: '5241', Name: 'Wirer, electrical' },
  { Id: 11024, Code: '5241', Name: 'Wirer, electrical engineering' },
  { Id: 11025, Code: '5242', Name: 'Connector, cable' },
  { Id: 11026, Code: '5242', Name: 'Controller, network, telecommunications' },
  { Id: 11027, Code: '5242', Name: 'Engineer, BSC, telecommunications' },
  { Id: 11028, Code: '5242', Name: 'Engineer, BSS, telecommunications' },
  { Id: 11029, Code: '5242', Name: 'Engineer, cable' },
  { Id: 11030, Code: '5242', Name: 'Engineer, data' },
  { Id: 11031, Code: '5242', Name: 'Engineer, exchange, telephone' },
  {
    Id: 11032,
    Code: '5242',
    Name: 'Engineer, field, service, home, BROADBAND'
  },
  { Id: 11033, Code: '5242', Name: 'Engineer, field, telecommunications' },
  { Id: 11034, Code: '5242', Name: 'Engineer, IN' },
  { Id: 11035, Code: '5242', Name: 'Engineer, installation, cable' },
  {
    Id: 11036,
    Code: '5242',
    Name: 'Engineer, installation, telecommunications'
  },
  { Id: 11037, Code: '5242', Name: 'Engineer, installation, TELEPHONES' },
  { Id: 11038, Code: '5242', Name: 'Engineer, internet' },
  { Id: 11039, Code: '5242', Name: 'Engineer, line, telecommunications' },
  {
    Id: 11040,
    Code: '5242',
    Name: 'Engineer, maintenance, telecommunications'
  },
  { Id: 11041, Code: '5242', Name: 'Engineer, network, core' },
  { Id: 11042, Code: '5242', Name: 'Engineer, NOC, telecommunications' },
  { Id: 11043, Code: '5242', Name: 'Engineer, nos, telecommunications' },
  { Id: 11044, Code: '5242', Name: 'Engineer, NSS, telecommunications' },
  { Id: 11045, Code: '5242', Name: 'Engineer, optic, fibre' },
  {
    Id: 11046,
    Code: '5242',
    Name: 'Engineer, provisioning, telecommunications'
  },
  {
    Id: 11047,
    Code: '5242',
    Name: 'Engineer, service, customer, telecommunications'
  },
  { Id: 11048, Code: '5242', Name: 'Engineer, service, TELEPHONE' },
  { Id: 11049, Code: '5242', Name: 'Engineer, Sky' },
  { Id: 11050, Code: '5242', Name: 'Engineer, support, telecommunications' },
  { Id: 11051, Code: '5242', Name: 'Engineer, switch, telecommunications' },
  { Id: 11052, Code: '5242', Name: 'Engineer, technical, telecommunications' },
  { Id: 11053, Code: '5242', Name: 'Engineer, telecom' },
  { Id: 11054, Code: '5242', Name: 'Engineer, telecommunications' },
  { Id: 11055, Code: '5242', Name: 'Engineer, telegraph' },
  { Id: 11056, Code: '5242', Name: 'Engineer, telephone' },
  {
    Id: 11057,
    Code: '5242',
    Name: 'Engineer, telephony and IT communications'
  },
  {
    Id: 11058,
    Code: '5242',
    Name: 'Engineer, transmission, telecommunications'
  },
  { Id: 11059, Code: '5242', Name: 'Engineer, VAS' },
  { Id: 11060, Code: '5242', Name: 'Faultsman, telecommunications' },
  { Id: 11061, Code: '5242', Name: 'Fitter, cable' },
  { Id: 11062, Code: '5242', Name: 'Fitter, installation, telecommunications' },
  { Id: 11063, Code: '5242', Name: 'Fitter, maintenance, telecommunications' },
  { Id: 11064, Code: '5242', Name: 'Fitter, telephone' },
  { Id: 11065, Code: '5242', Name: 'Hand, maintenance, telecommunications' },
  { Id: 11066, Code: '5242', Name: 'Inspector, flight, airport, RADIO' },
  { Id: 11067, Code: '5242', Name: 'Installer, cable' },
  { Id: 11068, Code: '5242', Name: 'Installer, telecommunications' },
  { Id: 11069, Code: '5242', Name: 'Installer, telephone' },
  { Id: 11070, Code: '5242', Name: 'Installer, telephone service' },
  { Id: 11071, Code: '5242', Name: 'Jointer, cable' },
  { Id: 11072, Code: '5242', Name: 'Jointer, cable laying' },
  { Id: 11073, Code: '5242', Name: 'Jointer, conduit, electric' },
  { Id: 11074, Code: '5242', Name: 'Jointer, electric' },
  { Id: 11075, Code: '5242', Name: 'Jointer, electrical' },
  { Id: 11076, Code: '5242', Name: 'Jointer, electricity supplier' },
  { Id: 11077, Code: '5242', Name: 'Jointer, telecommunications' },
  { Id: 11078, Code: '5242', Name: 'Jointer, wire' },
  { Id: 11079, Code: '5242', Name: 'Lineman, traction' },
  { Id: 11080, Code: '5242', Name: 'Linesman, radio relay service' },
  { Id: 11081, Code: '5242', Name: 'Linesman, telecommunications' },
  { Id: 11082, Code: '5242', Name: 'Linesman, telegraph' },
  { Id: 11083, Code: '5242', Name: 'Linesman, telephone' },
  { Id: 11084, Code: '5242', Name: 'Mechanic, telecommunications' },
  { Id: 11085, Code: '5242', Name: 'Mechanic, telephone' },
  { Id: 11086, Code: '5242', Name: 'Mechanic, TELEPHONE, TELEGRAPH APPARATUS' },
  { Id: 11087, Code: '5242', Name: 'Officer, engineering, PO' },
  { Id: 11088, Code: '5242', Name: 'Officer, maintenance, telecommunications' },
  { Id: 11089, Code: '5242', Name: 'Officer, network, telecommunications' },
  { Id: 11090, Code: '5242', Name: 'Officer, repeater, telecommunications' },
  { Id: 11091, Code: '5242', Name: 'Officer, survey, telecommunications' },
  {
    Id: 11092,
    Code: '5242',
    Name: 'Officer, technical, telecommunication, Civil Aviation Authority'
  },
  { Id: 11093, Code: '5242', Name: 'Officer, technical, telecommunications' },
  { Id: 11094, Code: '5242', Name: 'Officer, testing, diagnostic' },
  { Id: 11095, Code: '5242', Name: 'Planner, band, wide, telecommunications' },
  {
    Id: 11096,
    Code: '5242',
    Name: 'Planner, transmission, telecommunications'
  },
  { Id: 11097, Code: '5242', Name: 'Plumber and jointer' },
  { Id: 11098, Code: '5242', Name: 'Probationer, railways' },
  { Id: 11099, Code: '5242', Name: 'Puller, cable' },
  { Id: 11100, Code: '5242', Name: 'Repairer, cable, ELECTRIC' },
  { Id: 11101, Code: '5242', Name: 'Repairer, cord, TELEPHONES' },
  { Id: 11102, Code: '5242', Name: 'Repairer, TELEPHONE APPARATUS' },
  { Id: 11103, Code: '5242', Name: 'Repairer, wire' },
  { Id: 11104, Code: '5242', Name: 'T1, telecommunications' },
  { Id: 11105, Code: '5242', Name: 'T2A, telecommunications' },
  { Id: 11106, Code: '5242', Name: 'T2B, telecommunications' },
  { Id: 11107, Code: '5242', Name: 'Technician, 1, telecommunications' },
  { Id: 11108, Code: '5242', Name: 'Technician, 2A, telecommunications' },
  { Id: 11109, Code: '5242', Name: 'Technician, 2B, telecommunications' },
  {
    Id: 11110,
    Code: '5242',
    Name: 'Technician, electrical, telecommunications'
  },
  { Id: 11111, Code: '5242', Name: 'Technician, factory, telecommunications' },
  { Id: 11112, Code: '5242', Name: 'Technician, telecommunications' },
  { Id: 11113, Code: '5242', Name: 'Technician, telecommunications, 2B' },
  {
    Id: 11114,
    Code: '5242',
    Name: 'Technician, TELEGRAPH, TELEPHONE EQUIPMENT'
  },
  { Id: 11115, Code: '5242', Name: 'Technician, telephone' },
  { Id: 11116, Code: '5242', Name: 'Wireman, overhead' },
  { Id: 11117, Code: '5242', Name: 'Wireman, railways' },
  { Id: 11118, Code: '5242', Name: 'Wireman, telecommunications' },
  { Id: 11119, Code: '5242', Name: 'Wireman, telephone' },
  { Id: 11120, Code: '5242', Name: 'Wirer, metal trades: telephone mfr' },
  { Id: 11121, Code: '5242', Name: 'Workman, skilled, telecommunications' },
  { Id: 11122, Code: '5244', Name: 'Electrician, radio' },
  { Id: 11123, Code: '5244', Name: 'Engineer, audio, SERVICING' },
  {
    Id: 11124,
    Code: '5244',
    Name: 'Engineer, electronics, television, video, audio repairing'
  },
  {
    Id: 11125,
    Code: '5244',
    Name: 'Engineer, field, RADIO, TELEVISION AND VIDEO SERVICING'
  },
  {
    Id: 11126,
    Code: '5244',
    Name: 'Engineer, installation, RADIO, TELEVISION AND VIDEO'
  },
  { Id: 11127, Code: '5244', Name: 'Engineer, installation, satellite' },
  {
    Id: 11128,
    Code: '5244',
    Name: 'Engineer, maintenance, RADIO, TELEVISION AND VIDEO'
  },
  { Id: 11129, Code: '5244', Name: 'Engineer, radio' },
  { Id: 11130, Code: '5244', Name: 'Engineer, radio and television' },
  { Id: 11131, Code: '5244', Name: 'Engineer, satellite' },
  {
    Id: 11132,
    Code: '5244',
    Name: 'Engineer, service, RADIO, TELEVISION AND VIDEO'
  },
  { Id: 11133, Code: '5244', Name: 'Engineer, television' },
  { Id: 11134, Code: '5244', Name: 'Engineer, video' },
  { Id: 11135, Code: '5244', Name: 'Engineer, visual, audio' },
  {
    Id: 11136,
    Code: '5244',
    Name: 'Engineer, workshop, RADIO, TELEVISION AND VIDEO SERVICING'
  },
  {
    Id: 11137,
    Code: '5244',
    Name: 'Fitter, maintenance, RADIO, TELEVISION AND VIDEO SERVICING'
  },
  { Id: 11138, Code: '5244', Name: 'Fitter, radio' },
  { Id: 11139, Code: '5244', Name: 'Fitter, television' },
  { Id: 11140, Code: '5244', Name: 'Installer, satellite' },
  { Id: 11141, Code: '5244', Name: 'Installer, television' },
  {
    Id: 11142,
    Code: '5244',
    Name: 'Man, service, RADIO, TELEVISION AND VIDEO'
  },
  {
    Id: 11143,
    Code: '5244',
    Name: 'Man, service, sales, RADIO, TELEVISION AND VIDEO'
  },
  { Id: 11144, Code: '5244', Name: 'Mechanic, radio' },
  { Id: 11145, Code: '5244', Name: 'Mechanic, RADIO, TELEVISION AND VIDEO' },
  {
    Id: 11146,
    Code: '5244',
    Name: 'Mechanic, service, RADIO, TELEVISION AND VIDEO'
  },
  { Id: 11147, Code: '5244', Name: 'Mechanic, television' },
  { Id: 11148, Code: '5244', Name: 'Mender, RADIO, TELEVISION, VIDEO' },
  { Id: 11149, Code: '5244', Name: 'Repairer, RADIO, TELEVISION AND VIDEO' },
  { Id: 11150, Code: '5244', Name: 'Retuner, TELEVISION' },
  { Id: 11151, Code: '5244', Name: 'Technician, radio' },
  {
    Id: 11152,
    Code: '5244',
    Name: 'Technician, RADIO, TELEVISION AND VIDEO SERVICING'
  },
  {
    Id: 11153,
    Code: '5244',
    Name: 'Technician, service, radio, television, video servicing'
  },
  { Id: 11154, Code: '5244', Name: 'Technician, television' },
  { Id: 11155, Code: '5244', Name: 'Technician, television and video' },
  { Id: 11156, Code: '5244', Name: 'Technician, video' },
  { Id: 11157, Code: '5244', Name: 'Tester, radio' },
  { Id: 11158, Code: '5244', Name: 'Tuner, TELEVISION' },
  { Id: 11159, Code: '5245', Name: 'Engineer, computer' },
  { Id: 11160, Code: '5245', Name: 'Engineer, configuration' },
  { Id: 11161, Code: '5245', Name: 'Engineer, customer, COMPUTING' },
  { Id: 11162, Code: '5245', Name: 'Engineer, field, COMPUTER SERVICING' },
  { Id: 11163, Code: '5245', Name: 'Engineer, hardware, COMPUTER' },
  { Id: 11164, Code: '5245', Name: 'Engineer, installation, COMPUTER' },
  { Id: 11165, Code: '5245', Name: 'Engineer, IT' },
  { Id: 11166, Code: '5245', Name: 'Engineer, maintenance, computer' },
  {
    Id: 11167,
    Code: '5245',
    Name: 'Engineer, maintenance, COMPUTER SERVICING'
  },
  { Id: 11168, Code: '5245', Name: 'Engineer, service, computer' },
  { Id: 11169, Code: '5245', Name: 'Engineer, service, COMPUTER EQUIPMENT' },
  { Id: 11170, Code: '5245', Name: 'Fitter, maintenance, computer' },
  { Id: 11171, Code: '5245', Name: 'Installer, systems, computer' },
  { Id: 11172, Code: '5245', Name: 'Repairer, computer' },
  { Id: 11173, Code: '5245', Name: 'Technician, service, computer' },
  { Id: 11174, Code: '5249', Name: 'Adjuster, relay' },
  { Id: 11175, Code: '5249', Name: 'Craftsman, electricity supplier' },
  { Id: 11176, Code: '5249', Name: 'Diagnostician, HM Dockyard, ELECTRICAL' },
  { Id: 11177, Code: '5249', Name: 'Engineer, alarm' },
  { Id: 11178, Code: '5249', Name: 'Engineer, broadcast' },
  { Id: 11179, Code: '5249', Name: 'Engineer, broadcasting' },
  { Id: 11180, Code: '5249', Name: 'Engineer, CCTV' },
  { Id: 11181, Code: '5249', Name: 'Engineer, communication' },
  { Id: 11182, Code: '5249', Name: 'Engineer, communications' },
  { Id: 11183, Code: '5249', Name: 'Engineer, copier' },
  { Id: 11184, Code: '5249', Name: 'Engineer, customer, OFFICE MACHINERY' },
  { Id: 11185, Code: '5249', Name: 'Engineer, electronic' },
  { Id: 11186, Code: '5249', Name: 'Engineer, electronics' },
  { Id: 11187, Code: '5249', Name: 'Engineer, fax' },
  { Id: 11188, Code: '5249', Name: 'Engineer, field' },
  { Id: 11189, Code: '5249', Name: 'Engineer, installation, ALARMS' },
  { Id: 11190, Code: '5249', Name: 'Engineer, light, traffic' },
  { Id: 11191, Code: '5249', Name: 'Engineer, machine, OFFICE MACHINES' },
  { Id: 11192, Code: '5249', Name: 'Engineer, mains, electrical' },
  { Id: 11193, Code: '5249', Name: 'Engineer, mains, electricity supplier' },
  { Id: 11194, Code: '5249', Name: 'Engineer, maintenance, ALARMS' },
  {
    Id: 11195,
    Code: '5249',
    Name: 'Engineer, maintenance, electricity supplier'
  },
  { Id: 11196, Code: '5249', Name: 'Engineer, maintenance, ELECTRONICS' },
  { Id: 11197, Code: '5249', Name: 'Engineer, maintenance, OFFICE MACHINES' },
  { Id: 11198, Code: '5249', Name: 'Engineer, nos, broadcasting' },
  { Id: 11199, Code: '5249', Name: 'Engineer, photocopier' },
  { Id: 11200, Code: '5249', Name: 'Engineer, prevention, crime' },
  { Id: 11201, Code: '5249', Name: 'Engineer, radar' },
  { Id: 11202, Code: '5249', Name: 'Engineer, security' },
  { Id: 11203, Code: '5249', Name: 'Engineer, service' },
  { Id: 11204, Code: '5249', Name: 'Engineer, service, electronic' },
  { Id: 11205, Code: '5249', Name: 'Engineer, signal' },
  { Id: 11206, Code: '5249', Name: 'Engineer, signal, traffic' },
  {
    Id: 11207,
    Code: '5249',
    Name: 'Engineer, support, electrical, electronic equipment'
  },
  { Id: 11208, Code: '5249', Name: 'Engineer, systems, security' },
  { Id: 11209, Code: '5249', Name: 'Engineer, transmitter, radio' },
  { Id: 11210, Code: '5249', Name: 'Engineer, typewriter' },
  { Id: 11211, Code: '5249', Name: 'Engineer, x-ray' },
  { Id: 11212, Code: '5249', Name: 'Fitter, alarm' },
  { Id: 11213, Code: '5249', Name: 'Fitter, interlocking' },
  {
    Id: 11214,
    Code: '5249',
    Name: 'Fitter, maintenance, OFFICE MACHINERY SERVICING'
  },
  { Id: 11215, Code: '5249', Name: 'Fitter, radar' },
  { Id: 11216, Code: '5249', Name: 'Fitter, service, OFFICE MACHINES' },
  { Id: 11217, Code: '5249', Name: 'Fitter, signal, railway' },
  { Id: 11218, Code: '5249', Name: 'Fitter, telegraph' },
  { Id: 11219, Code: '5249', Name: 'Hand, maintenance, typewriter' },
  { Id: 11220, Code: '5249', Name: 'Inspector, cable' },
  { Id: 11221, Code: '5249', Name: 'Inspector, machine, OFFICE MACHINES' },
  { Id: 11222, Code: '5249', Name: 'Installer, alarm' },
  { Id: 11223, Code: '5249', Name: 'Installer, equipment, x-ray' },
  { Id: 11224, Code: '5249', Name: 'Installer, railways' },
  {
    Id: 11225,
    Code: '5249',
    Name: 'Installer, signal and telecommunications, railways'
  },
  { Id: 11226, Code: '5249', Name: 'Installer, signal, railways' },
  { Id: 11227, Code: '5249', Name: 'Installer, systems, security' },
  { Id: 11228, Code: '5249', Name: 'Lineman' },
  { Id: 11229, Code: '5249', Name: 'Lineman, power' },
  { Id: 11230, Code: '5249', Name: 'Lineman, signal, power' },
  { Id: 11231, Code: '5249', Name: 'Linesman' },
  { Id: 11232, Code: '5249', Name: 'Linesman, coal mine: above ground' },
  { Id: 11233, Code: '5249', Name: 'Linesman, electrical' },
  { Id: 11234, Code: '5249', Name: 'Linesman, instrument, railways' },
  { Id: 11235, Code: '5249', Name: 'Linesman, overhead' },
  { Id: 11236, Code: '5249', Name: 'Linesman, power' },
  { Id: 11237, Code: '5249', Name: 'Linesman, railway' },
  { Id: 11238, Code: '5249', Name: 'Linesman, signal' },
  { Id: 11239, Code: '5249', Name: 'Linesman-erector' },
  { Id: 11240, Code: '5249', Name: 'Man, maintenance, OFFICE MACHINES' },
  { Id: 11241, Code: '5249', Name: 'Man, service, AUTOMATIC VENDING MACHINES' },
  { Id: 11242, Code: '5249', Name: 'Man, service, gas supplier' },
  { Id: 11243, Code: '5249', Name: 'Man, service, OFFICE MACHINERY' },
  { Id: 11244, Code: '5249', Name: 'Man, service, radio relay service' },
  { Id: 11245, Code: '5249', Name: 'Man, service, sales, DOMESTIC APPLIANCES' },
  { Id: 11246, Code: '5249', Name: 'Man, service, sales, OFFICE MACHINERY' },
  { Id: 11247, Code: '5249', Name: 'Mechanic, electronic' },
  { Id: 11248, Code: '5249', Name: 'Mechanic, instrument, electronic' },
  { Id: 11249, Code: '5249', Name: 'Mechanic, machine, adding' },
  { Id: 11250, Code: '5249', Name: 'Mechanic, machine, calculating' },
  { Id: 11251, Code: '5249', Name: 'Mechanic, machine, OFFICE MACHINERY' },
  { Id: 11252, Code: '5249', Name: 'Mechanic, OFFICE MACHINERY' },
  { Id: 11253, Code: '5249', Name: 'Mechanic, radar' },
  { Id: 11254, Code: '5249', Name: 'Mechanic, service' },
  { Id: 11255, Code: '5249', Name: 'Mechanic, service, OFFICE MACHINERY' },
  { Id: 11256, Code: '5249', Name: 'Mechanic, teleprinter' },
  { Id: 11257, Code: '5249', Name: 'Mechanic, totalisator' },
  { Id: 11258, Code: '5249', Name: 'Mechanic, typewriter' },
  { Id: 11259, Code: '5249', Name: 'Operator, board, test' },
  { Id: 11260, Code: '5249', Name: 'Repairer, machine, OFFICE MACHINERY' },
  { Id: 11261, Code: '5249', Name: 'Repairer, OFFICE MACHINERY' },
  { Id: 11262, Code: '5249', Name: 'Repairer, typewriter' },
  { Id: 11263, Code: '5249', Name: 'Technician, alarm, fire' },
  {
    Id: 11264,
    Code: '5249',
    Name: 'Technician, assistant, RAILWAY SIGNALLING'
  },
  {
    Id: 11265,
    Code: '5249',
    Name: 'Technician, ELECTRONIC EQUIPMENT, MAINTENANCE'
  },
  {
    Id: 11266,
    Code: '5249',
    Name: 'Technician, installation, ELECTRICAL, ELECTRONICS'
  },
  { Id: 11267, Code: '5249', Name: 'Technician, radar' },
  { Id: 11268, Code: '5249', Name: 'Technician, service, OFFICE MACHINES' },
  { Id: 11269, Code: '5249', Name: 'Technician, signal, railways' },
  { Id: 11270, Code: '5249', Name: 'Technician, signalling' },
  { Id: 11271, Code: '5249', Name: 'Wireman, electronic' },
  { Id: 11272, Code: '5250', Name: 'Controller, workshop, garage' },
  { Id: 11273, Code: '5250', Name: 'Electrician, chief' },
  { Id: 11274, Code: '5250', Name: 'Engineer, assistant, telecommunications' },
  { Id: 11275, Code: '5250', Name: 'Engineer, charge, coal mine' },
  { Id: 11276, Code: '5250', Name: 'Engineer, electrical, colliery' },
  { Id: 11277, Code: '5250', Name: 'Engineer, mechanical, colliery' },
  { Id: 11278, Code: '5250', Name: 'Engineer, superintendent' },
  { Id: 11279, Code: '5250', Name: 'Fitter, superintendent' },
  { Id: 11280, Code: '5250', Name: 'Fitter-in-charge' },
  { Id: 11281, Code: '5250', Name: 'Foreman, aero-engine mfr' },
  { Id: 11282, Code: '5250', Name: 'Foreman, bodyshop, MOTOR VEHICLES' },
  { Id: 11283, Code: '5250', Name: 'Foreman, building, coach' },
  { Id: 11284, Code: '5250', Name: 'Foreman, cable' },
  { Id: 11285, Code: '5250', Name: 'Foreman, coach building' },
  { Id: 11286, Code: '5250', Name: 'Foreman, electrical' },
  { Id: 11287, Code: '5250', Name: 'Foreman, electrical contracting' },
  { Id: 11288, Code: '5250', Name: 'Foreman, electricity supplier' },
  { Id: 11289, Code: '5250', Name: 'Foreman, electronic equipment mfr' },
  { Id: 11290, Code: '5250', Name: 'Foreman, electronics' },
  { Id: 11291, Code: '5250', Name: 'Foreman, engineering' },
  { Id: 11292, Code: '5250', Name: 'Foreman, factory, telecommunications' },
  { Id: 11293, Code: '5250', Name: 'Foreman, forging' },
  { Id: 11294, Code: '5250', Name: 'Foreman, foundry' },
  { Id: 11295, Code: '5250', Name: 'Foreman, garage' },
  { Id: 11296, Code: '5250', Name: 'Foreman, general, manufacturing' },
  { Id: 11297, Code: '5250', Name: 'Foreman, hydraulic pump mfr' },
  { Id: 11298, Code: '5250', Name: 'Foreman, installation, electrical' },
  { Id: 11299, Code: '5250', Name: 'Foreman, instrument' },
  { Id: 11300, Code: '5250', Name: 'Foreman, internal combustion engine mfr' },
  { Id: 11301, Code: '5250', Name: 'Foreman, lamp, valve mfr' },
  { Id: 11302, Code: '5250', Name: 'Foreman, lime, foundry' },
  { Id: 11303, Code: '5250', Name: 'Foreman, lines, overhead' },
  { Id: 11304, Code: '5250', Name: 'Foreman, machine shop' },
  { Id: 11305, Code: '5250', Name: 'Foreman, mains, electricity supplier' },
  { Id: 11306, Code: '5250', Name: 'Foreman, maintenance, electrical' },
  {
    Id: 11307,
    Code: '5250',
    Name: 'Foreman, maintenance, electricity supplier'
  },
  { Id: 11308, Code: '5250', Name: 'Foreman, maintenance, loco' },
  { Id: 11309, Code: '5250', Name: 'Foreman, maintenance, manufacturing' },
  { Id: 11310, Code: '5250', Name: 'Foreman, maintenance, telecommunications' },
  { Id: 11311, Code: '5250', Name: 'Foreman, maintenance, transport' },
  { Id: 11312, Code: '5250', Name: 'Foreman, mechanical' },
  { Id: 11313, Code: '5250', Name: 'Foreman, metal box mfr' },
  { Id: 11314, Code: '5250', Name: 'Foreman, metal instrument mfr' },
  { Id: 11315, Code: '5250', Name: 'Foreman, metal pressing' },
  { Id: 11316, Code: '5250', Name: 'Foreman, metal smallwares' },
  { Id: 11317, Code: '5250', Name: 'Foreman, metal stamping, piercing' },
  { Id: 11318, Code: '5250', Name: 'Foreman, metal trades: assembling' },
  { Id: 11319, Code: '5250', Name: 'Foreman, office machinery mfr' },
  { Id: 11320, Code: '5250', Name: 'Foreman, overhauling, vehicle' },
  { Id: 11321, Code: '5250', Name: 'Foreman, piercing' },
  { Id: 11322, Code: '5250', Name: 'Foreman, power tools mfr' },
  { Id: 11323, Code: '5250', Name: 'Foreman, press tool mfr' },
  { Id: 11324, Code: '5250', Name: 'Foreman, press, metal trades' },
  { Id: 11325, Code: '5250', Name: 'Foreman, radio, television, video mfr' },
  { Id: 11326, Code: '5250', Name: 'Foreman, railways: carriage, wagon dept' },
  { Id: 11327, Code: '5250', Name: 'Foreman, railways: locomotive shop' },
  { Id: 11328, Code: '5250', Name: 'Foreman, railways: motive power dept' },
  {
    Id: 11329,
    Code: '5250',
    Name: 'Foreman, railways: signal and telecommunications'
  },
  { Id: 11330, Code: '5250', Name: 'Foreman, room, tool' },
  { Id: 11331, Code: '5250', Name: 'Foreman, senior, manufacturing' },
  { Id: 11332, Code: '5250', Name: 'Foreman, sheet metal working' },
  { Id: 11333, Code: '5250', Name: 'Foreman, shipbuilding' },
  { Id: 11334, Code: '5250', Name: 'Foreman, shop, auto' },
  { Id: 11335, Code: '5250', Name: 'Foreman, shop, body, MOTOR VEHICLES' },
  { Id: 11336, Code: '5250', Name: 'Foreman, shop, casting' },
  { Id: 11337, Code: '5250', Name: 'Foreman, shop, coal mine' },
  { Id: 11338, Code: '5250', Name: 'Foreman, shop, engineering' },
  { Id: 11339, Code: '5250', Name: 'Foreman, shop, erecting, engineering' },
  { Id: 11340, Code: '5250', Name: 'Foreman, shop, machine' },
  { Id: 11341, Code: '5250', Name: 'Foreman, shop, panel' },
  { Id: 11342, Code: '5250', Name: 'Foreman, shop, press, metal trades' },
  { Id: 11343, Code: '5250', Name: 'Foreman, signalling equipment' },
  { Id: 11344, Code: '5250', Name: 'Foreman, small tools mfr' },
  { Id: 11345, Code: '5250', Name: 'Foreman, stamping' },
  { Id: 11346, Code: '5250', Name: 'Foreman, steel fabrication' },
  { Id: 11347, Code: '5250', Name: 'Foreman, telecommunications' },
  { Id: 11348, Code: '5250', Name: 'Foreman, tool room' },
  { Id: 11349, Code: '5250', Name: 'Foreman, tool, press' },
  { Id: 11350, Code: '5250', Name: 'Foreman, transformers and switchgear mfr' },
  { Id: 11351, Code: '5250', Name: 'Foreman, welding' },
  { Id: 11352, Code: '5250', Name: 'Foreman, works, manufacturing' },
  { Id: 11353, Code: '5250', Name: 'Foreman, workshop, MOTOR VEHICLES' },
  { Id: 11354, Code: '5250', Name: 'Foreman, yard, ship' },
  { Id: 11355, Code: '5250', Name: 'Foreman-ganger, telecommunications' },
  { Id: 11356, Code: '5250', Name: 'Gaffer, film, television production' },
  { Id: 11357, Code: '5250', Name: 'Inspector of electrical fitters' },
  { Id: 11358, Code: '5250', Name: 'Inspector of electricians' },
  { Id: 11359, Code: '5250', Name: 'Inspector of fitters' },
  { Id: 11360, Code: '5250', Name: 'Inspector of mechanics' },
  { Id: 11361, Code: '5250', Name: 'Inspector of shipwrights' },
  { Id: 11362, Code: '5250', Name: 'Inspector, civils, telecommunications' },
  {
    Id: 11363,
    Code: '5250',
    Name: 'Inspector, engineering, telecommunications'
  },
  { Id: 11364, Code: '5250', Name: 'Inspector, field, telecommunications' },
  {
    Id: 11365,
    Code: '5250',
    Name: 'Inspector, installation, telecommunications'
  },
  {
    Id: 11366,
    Code: '5250',
    Name: 'Inspector, maintenance, electricity supplier'
  },
  {
    Id: 11367,
    Code: '5250',
    Name: 'Inspector, signal and telecommunications, railways'
  },
  { Id: 11368, Code: '5250', Name: 'Inspector, signal, railways' },
  {
    Id: 11369,
    Code: '5250',
    Name: 'Inspector, telecommunications: engineering dept'
  },
  {
    Id: 11370,
    Code: '5250',
    Name: 'Inspector, telecommunications: telephone dept'
  },
  { Id: 11371, Code: '5250', Name: 'Inspector, telephones' },
  { Id: 11372, Code: '5250', Name: 'Leader, team, electrical' },
  { Id: 11373, Code: '5250', Name: 'Manager, installation, security systems' },
  { Id: 11374, Code: '5250', Name: 'Manager, room, tool' },
  { Id: 11375, Code: '5250', Name: 'Manager, service, garage' },
  { Id: 11376, Code: '5250', Name: 'Manager, workshop' },
  { Id: 11377, Code: '5250', Name: 'Manager, workshops' },
  { Id: 11378, Code: '5250', Name: 'Mechanic, electro, chief' },
  { Id: 11379, Code: '5250', Name: 'Mechanic, electro, district' },
  { Id: 11380, Code: '5250', Name: 'Mechanic-in-charge' },
  { Id: 11381, Code: '5250', Name: 'Observer, teeming' },
  { Id: 11382, Code: '5250', Name: 'Superintendent, electrical' },
  { Id: 11383, Code: '5250', Name: 'Superintendent, engineering' },
  { Id: 11384, Code: '5250', Name: 'Superintendent, garage' },
  {
    Id: 11385,
    Code: '5250',
    Name: 'Superintendent, maintenance, manufacturing'
  },
  { Id: 11386, Code: '5250', Name: 'Superintendent, metal trades' },
  {
    Id: 11387,
    Code: '5250',
    Name: 'Supervisor, chief, senior, telecommunications'
  },
  { Id: 11388, Code: '5250', Name: 'Supervisor, craft, oil refining' },
  { Id: 11389, Code: '5250', Name: 'Supervisor, electrical' },
  { Id: 11390, Code: '5250', Name: 'Supervisor, maintenance, manufacturing' },
  {
    Id: 11391,
    Code: '5250',
    Name: 'Supervisor, service, electrical engineering'
  },
  { Id: 11392, Code: '5250', Name: 'Supervisor, service, INSTRUMENTS' },
  { Id: 11393, Code: '5250', Name: 'Tuner, head' },
  { Id: 11394, Code: '5311', Name: 'Climber, constructional engineering' },
  { Id: 11395, Code: '5311', Name: 'Constructor, steel' },
  { Id: 11396, Code: '5311', Name: 'Engineer, constructional' },
  { Id: 11397, Code: '5311', Name: 'Erector, chimney, metal' },
  { Id: 11398, Code: '5311', Name: 'Erector, coal mine: above ground' },
  { Id: 11399, Code: '5311', Name: 'Erector, engineering: structural' },
  { Id: 11400, Code: '5311', Name: 'Erector, furnace' },
  { Id: 11401, Code: '5311', Name: 'Erector, girder' },
  { Id: 11402, Code: '5311', Name: 'Erector, ironwork' },
  { Id: 11403, Code: '5311', Name: 'Erector, plate, steel' },
  { Id: 11404, Code: '5311', Name: 'Erector, sheeter' },
  { Id: 11405, Code: '5311', Name: 'Erector, staircase, iron' },
  { Id: 11406, Code: '5311', Name: 'Erector, steel' },
  { Id: 11407, Code: '5311', Name: 'Erector, steelwork' },
  { Id: 11408, Code: '5311', Name: 'Erector, structural' },
  { Id: 11409, Code: '5311', Name: 'Erector, tower' },
  { Id: 11410, Code: '5311', Name: 'Fabricator, steel' },
  { Id: 11411, Code: '5311', Name: 'Fighter, iron' },
  {
    Id: 11412,
    Code: '5311',
    Name: 'Fitter and erector, constructional engineering'
  },
  { Id: 11413, Code: '5311', Name: 'Spiderman' },
  { Id: 11414, Code: '5311', Name: 'Steelworker, structural engineering' },
  { Id: 11415, Code: '5311', Name: 'Worker, iron, constructional engineering' },
  { Id: 11416, Code: '5311', Name: 'Worker, steel, structural engineering' },
  { Id: 11417, Code: '5312', Name: 'Archer, brick' },
  { Id: 11418, Code: '5312', Name: 'Archman, BRICK' },
  { Id: 11419, Code: '5312', Name: 'Banker, STONE WORKING' },
  { Id: 11420, Code: '5312', Name: 'Blaster, sand, monumental masons' },
  { Id: 11421, Code: '5312', Name: 'Blockman, mine: not coal' },
  { Id: 11422, Code: '5312', Name: 'Bricker, ladles, iron works' },
  { Id: 11423, Code: '5312', Name: 'Bricker, mould' },
  { Id: 11424, Code: '5312', Name: 'Bricklayer' },
  { Id: 11425, Code: '5312', Name: 'Bricky' },
  { Id: 11426, Code: '5312', Name: 'Builder, arch, brick' },
  { Id: 11427, Code: '5312', Name: 'Builder, box, PO' },
  { Id: 11428, Code: '5312', Name: 'Builder, chimney' },
  { Id: 11429, Code: '5312', Name: 'Builder, cupola' },
  { Id: 11430, Code: '5312', Name: 'Builder, furnace' },
  { Id: 11431, Code: '5312', Name: 'Builder, kiln, brick' },
  { Id: 11432, Code: '5312', Name: 'Builder, manhole' },
  { Id: 11433, Code: '5312', Name: 'Builder, millstone' },
  { Id: 11434, Code: '5312', Name: 'Builder, oven' },
  { Id: 11435, Code: '5312', Name: 'Builder, retort' },
  { Id: 11436, Code: '5312', Name: 'Builder, sewer' },
  { Id: 11437, Code: '5312', Name: 'Builder, stove' },
  { Id: 11438, Code: '5312', Name: 'Carver, architectural' },
  { Id: 11439, Code: '5312', Name: 'Carver, monumental' },
  { Id: 11440, Code: '5312', Name: 'Carver, stone' },
  { Id: 11441, Code: '5312', Name: 'Contractor, bricklaying' },
  { Id: 11442, Code: '5312', Name: 'Cutter, letter, monumental masons' },
  { Id: 11443, Code: '5312', Name: 'Cutter, sett' },
  { Id: 11444, Code: '5312', Name: 'Cutter, slate' },
  { Id: 11445, Code: '5312', Name: 'Cutter, stone' },
  { Id: 11446, Code: '5312', Name: 'Ditcher, stone' },
  { Id: 11447, Code: '5312', Name: 'Drafter, stone' },
  { Id: 11448, Code: '5312', Name: 'Dresser, kerb' },
  { Id: 11449, Code: '5312', Name: 'Dresser, mine: not coal' },
  { Id: 11450, Code: '5312', Name: 'Dresser, sett' },
  { Id: 11451, Code: '5312', Name: 'Dresser, slate' },
  { Id: 11452, Code: '5312', Name: 'Dresser, stone' },
  { Id: 11453, Code: '5312', Name: 'Dresser, stone dressing' },
  { Id: 11454, Code: '5312', Name: 'Dresser, wallstone' },
  { Id: 11455, Code: '5312', Name: 'Dyker, drystone' },
  { Id: 11456, Code: '5312', Name: 'Engineer, refractory' },
  { Id: 11457, Code: '5312', Name: 'Engraver, letter' },
  { Id: 11458, Code: '5312', Name: 'Engraver, monumental' },
  { Id: 11459, Code: '5312', Name: 'Engraver, monumental masons' },
  { Id: 11460, Code: '5312', Name: 'Engraver, stone' },
  { Id: 11461, Code: '5312', Name: 'Facer, stone dressing' },
  { Id: 11462, Code: '5312', Name: 'Fettler, cupola' },
  { Id: 11463, Code: '5312', Name: 'Fixer, boiler' },
  { Id: 11464, Code: '5312', Name: 'Fixer, marble' },
  { Id: 11465, Code: '5312', Name: "Fixer, mason's, stone" },
  { Id: 11466, Code: '5312', Name: 'Fixer, range' },
  { Id: 11467, Code: '5312', Name: 'Fixer-mason' },
  { Id: 11468, Code: '5312', Name: 'Grinder, stone' },
  { Id: 11469, Code: '5312', Name: 'Grinder, stone, lithographic' },
  { Id: 11470, Code: '5312', Name: 'Hand, banker' },
  { Id: 11471, Code: '5312', Name: 'Hand, mason' },
  { Id: 11472, Code: '5312', Name: 'Hedger, stone' },
  { Id: 11473, Code: '5312', Name: 'Installer, refractory' },
  { Id: 11474, Code: '5312', Name: 'Layer, block, blast furnace' },
  { Id: 11475, Code: '5312', Name: 'Layer, brick' },
  { Id: 11476, Code: '5312', Name: 'Layer, floor, stone' },
  { Id: 11477, Code: '5312', Name: 'Liner, furnace' },
  { Id: 11478, Code: '5312', Name: 'Liner, ladle' },
  { Id: 11479, Code: '5312', Name: 'Machinist, splitting, stone' },
  { Id: 11480, Code: '5312', Name: 'Machinist, stone' },
  { Id: 11481, Code: '5312', Name: 'Maker, gravestone' },
  { Id: 11482, Code: '5312', Name: 'Maker, kerb' },
  { Id: 11483, Code: '5312', Name: 'Maker, segment' },
  { Id: 11484, Code: '5312', Name: 'Maker, sett' },
  { Id: 11485, Code: '5312', Name: 'Maker, slab, mine: not coal' },
  { Id: 11486, Code: '5312', Name: 'Maker, slate' },
  { Id: 11487, Code: '5312', Name: 'Maker, stone, grave' },
  { Id: 11488, Code: '5312', Name: 'Maker, stone, kerb, mine: not coal' },
  { Id: 11489, Code: '5312', Name: 'Maker, stone, oil' },
  { Id: 11490, Code: '5312', Name: 'Maker, stone, pulp' },
  { Id: 11491, Code: '5312', Name: 'Maker, tank, slate' },
  { Id: 11492, Code: '5312', Name: 'Maker, trumpet, steelworks' },
  { Id: 11493, Code: '5312', Name: 'Man, saw, STONE' },
  { Id: 11494, Code: '5312', Name: 'Man, slab' },
  { Id: 11495, Code: '5312', Name: 'Man, trumpet' },
  { Id: 11496, Code: '5312', Name: 'Mason' },
  { Id: 11497, Code: '5312', Name: 'Mason, banker' },
  { Id: 11498, Code: '5312', Name: 'Mason, coal mine' },
  { Id: 11499, Code: '5312', Name: 'Mason, fixer' },
  { Id: 11500, Code: '5312', Name: 'Mason, monumental' },
  { Id: 11501, Code: '5312', Name: 'Mason, quarry' },
  { Id: 11502, Code: '5312', Name: 'Mason, stone' },
  { Id: 11503, Code: '5312', Name: 'Mason, walling' },
  { Id: 11504, Code: '5312', Name: 'Miner, wall' },
  { Id: 11505, Code: '5312', Name: 'Patcher, cupola, steelworks' },
  { Id: 11506, Code: '5312', Name: 'Patcher, oven' },
  { Id: 11507, Code: '5312', Name: 'Patcher, vessel' },
  { Id: 11508, Code: '5312', Name: 'Planer, slate' },
  { Id: 11509, Code: '5312', Name: 'Planer, stone' },
  { Id: 11510, Code: '5312', Name: 'Pointer, brick' },
  { Id: 11511, Code: '5312', Name: 'Pointer, building construction' },
  { Id: 11512, Code: '5312', Name: 'Polisher, granite' },
  { Id: 11513, Code: '5312', Name: 'Polisher, lathe, stone dressing' },
  { Id: 11514, Code: '5312', Name: 'Polisher, marble' },
  { Id: 11515, Code: '5312', Name: 'Polisher, slate' },
  { Id: 11516, Code: '5312', Name: 'Polisher, stone' },
  { Id: 11517, Code: '5312', Name: 'Polisher, stone dressing' },
  { Id: 11518, Code: '5312', Name: 'Reliner, steelworks' },
  { Id: 11519, Code: '5312', Name: 'Repairer, chimney' },
  { Id: 11520, Code: '5312', Name: 'Repairer, cupola' },
  { Id: 11521, Code: '5312', Name: 'Repairer, furnace' },
  { Id: 11522, Code: '5312', Name: 'Repairer, kiln' },
  { Id: 11523, Code: '5312', Name: 'Repairer, oven' },
  { Id: 11524, Code: '5312', Name: 'River, mine: not coal' },
  { Id: 11525, Code: '5312', Name: 'Sawyer, slate' },
  { Id: 11526, Code: '5312', Name: 'Sawyer, STONE' },
  { Id: 11527, Code: '5312', Name: 'Scabbler, STONE' },
  { Id: 11528, Code: '5312', Name: 'Scapler' },
  { Id: 11529, Code: '5312', Name: 'Scappler' },
  { Id: 11530, Code: '5312', Name: 'Setter, block' },
  { Id: 11531, Code: '5312', Name: 'Setter, brick' },
  { Id: 11532, Code: '5312', Name: 'Setter, fixture' },
  { Id: 11533, Code: '5312', Name: 'Setter, retort' },
  { Id: 11534, Code: '5312', Name: 'Splitter, mine: not coal: above ground' },
  { Id: 11535, Code: '5312', Name: 'Splitter, slate' },
  { Id: 11536, Code: '5312', Name: 'Stoneman, stone dressing' },
  { Id: 11537, Code: '5312', Name: 'Tooler, stone' },
  { Id: 11538, Code: '5312', Name: 'Trimmer, mine: not coal' },
  { Id: 11539, Code: '5312', Name: 'Turner, STONE' },
  { Id: 11540, Code: '5312', Name: 'Turner, stone dressing' },
  { Id: 11541, Code: '5312', Name: 'Waller' },
  { Id: 11542, Code: '5312', Name: 'Waller, dry' },
  { Id: 11543, Code: '5312', Name: 'Waller, stone, dry' },
  { Id: 11544, Code: '5312', Name: 'Worker, marble' },
  { Id: 11545, Code: '5312', Name: 'Worker, mill, slate' },
  { Id: 11546, Code: '5312', Name: 'Worker, serpentine' },
  { Id: 11547, Code: '5312', Name: 'Worker, slate, enamelled' },
  { Id: 11548, Code: '5312', Name: 'Worker, trowel' },
  { Id: 11549, Code: '5313', Name: 'Caster, parchester' },
  { Id: 11550, Code: '5313', Name: 'Cladder' },
  { Id: 11551, Code: '5313', Name: 'Constructor, roof, building construction' },
  { Id: 11552, Code: '5313', Name: 'Constructor, roofing' },
  { Id: 11553, Code: '5313', Name: 'Contractor, roofing' },
  { Id: 11554, Code: '5313', Name: 'Coverer, roof' },
  { Id: 11555, Code: '5313', Name: 'Engineer, roofing' },
  { Id: 11556, Code: '5313', Name: 'Erector, roof' },
  { Id: 11557, Code: '5313', Name: 'Erector, roofing, galvanised' },
  { Id: 11558, Code: '5313', Name: 'Felter, building construction' },
  { Id: 11559, Code: '5313', Name: 'Fitter, asbestos' },
  { Id: 11560, Code: '5313', Name: 'Fixer, asbestos' },
  { Id: 11561, Code: '5313', Name: 'Fixer, felt' },
  { Id: 11562, Code: '5313', Name: 'Fixer, roof, building construction' },
  { Id: 11563, Code: '5313', Name: 'Fixer, roofing' },
  { Id: 11564, Code: '5313', Name: 'Layer, felt, ROOFING' },
  { Id: 11565, Code: '5313', Name: 'Liner, roof' },
  { Id: 11566, Code: '5313', Name: 'Mechanic, roof' },
  { Id: 11567, Code: '5313', Name: 'Repairer, roof' },
  { Id: 11568, Code: '5313', Name: 'Roofer' },
  { Id: 11569, Code: '5313', Name: 'Roofer, felt' },
  { Id: 11570, Code: '5313', Name: 'Sheeter, asbestos' },
  { Id: 11571, Code: '5313', Name: 'Sheeter, building construction' },
  { Id: 11572, Code: '5313', Name: 'Sheeter, cement, asbestos' },
  { Id: 11573, Code: '5313', Name: 'Sheeter, chemical mfr' },
  { Id: 11574, Code: '5313', Name: 'Sheeter, constructional' },
  { Id: 11575, Code: '5313', Name: 'Sheeter, iron, corrugated' },
  { Id: 11576, Code: '5313', Name: 'Sheeter, roof' },
  { Id: 11577, Code: '5313', Name: 'Slater' },
  { Id: 11578, Code: '5313', Name: 'Slater and tiler' },
  { Id: 11579, Code: '5313', Name: 'Spreader, asphalt, mastic' },
  { Id: 11580, Code: '5313', Name: 'Thatcher' },
  { Id: 11581, Code: '5313', Name: 'Tiler' },
  { Id: 11582, Code: '5313', Name: 'Tiler, roof' },
  { Id: 11583, Code: '5314', Name: 'Adviser, heating' },
  { Id: 11584, Code: '5314', Name: 'Connector, coupling' },
  { Id: 11585, Code: '5314', Name: 'Consultant, heating' },
  { Id: 11586, Code: '5314', Name: 'Contractor, plumbing' },
  { Id: 11587, Code: '5314', Name: 'Converter, gas supplier' },
  { Id: 11588, Code: '5314', Name: 'Craftsman, distribution, gas supplier' },
  { Id: 11589, Code: '5314', Name: 'Craftsman, gas supplier' },
  { Id: 11590, Code: '5314', Name: 'Craftsman, governor, gas supplier' },
  { Id: 11591, Code: '5314', Name: 'Craftsman, transmission, gas supplier' },
  { Id: 11592, Code: '5314', Name: 'Engineer, biomass' },
  { Id: 11593, Code: '5314', Name: 'Engineer, domestic, plumbing' },
  { Id: 11594, Code: '5314', Name: 'Engineer, field, heating and ventilating' },
  { Id: 11595, Code: '5314', Name: 'Engineer, gas' },
  { Id: 11596, Code: '5314', Name: 'Engineer, gas and heating' },
  { Id: 11597, Code: '5314', Name: 'Engineer, gas and water' },
  { Id: 11598, Code: '5314', Name: 'Engineer, Gas, British' },
  { Id: 11599, Code: '5314', Name: 'Engineer, gas, emergency' },
  { Id: 11600, Code: '5314', Name: 'Engineer, gas, technical' },
  { Id: 11601, Code: '5314', Name: 'Engineer, heat and domestic' },
  { Id: 11602, Code: '5314', Name: 'Engineer, heating' },
  { Id: 11603, Code: '5314', Name: 'Engineer, heating and lighting' },
  { Id: 11604, Code: '5314', Name: 'Engineer, heating and plumbing' },
  { Id: 11605, Code: '5314', Name: 'Engineer, heating and ventilating' },
  { Id: 11606, Code: '5314', Name: 'Engineer, heating, central' },
  { Id: 11607, Code: '5314', Name: 'Engineer, heating, gas' },
  { Id: 11608, Code: '5314', Name: 'Engineer, installation, gas' },
  {
    Id: 11609,
    Code: '5314',
    Name: 'Engineer, installation, HEATING AND VENTILATING'
  },
  { Id: 11610, Code: '5314', Name: 'Engineer, mains, gas supplier' },
  {
    Id: 11611,
    Code: '5314',
    Name: 'Engineer, maintenance, HEATING AND VENTILATING'
  },
  { Id: 11612, Code: '5314', Name: 'Engineer, plumber and gas' },
  { Id: 11613, Code: '5314', Name: 'Engineer, plumbing' },
  { Id: 11614, Code: '5314', Name: 'Engineer, plumbing and heating' },
  { Id: 11615, Code: '5314', Name: 'Engineer, safe, gas' },
  { Id: 11616, Code: '5314', Name: 'Engineer, sanitary' },
  { Id: 11617, Code: '5314', Name: 'Engineer, service, gas' },
  {
    Id: 11618,
    Code: '5314',
    Name: 'Engineer, service, HEATING AND VENTILATING'
  },
  { Id: 11619, Code: '5314', Name: 'Engineer, service, heating engineering' },
  { Id: 11620, Code: '5314', Name: 'Engineer, technical, gas' },
  { Id: 11621, Code: '5314', Name: 'Engineer, technical, gas supplier' },
  { Id: 11622, Code: '5314', Name: 'Engineer, thermal' },
  { Id: 11623, Code: '5314', Name: 'Engineer, thermal and acoustic' },
  { Id: 11624, Code: '5314', Name: 'Engineer, ventilating' },
  { Id: 11625, Code: '5314', Name: 'Engineer, ventilation' },
  { Id: 11626, Code: '5314', Name: 'Engineer, ventilation, coal mine' },
  { Id: 11627, Code: '5314', Name: 'Engineer, water, hot' },
  { Id: 11628, Code: '5314', Name: 'Erector, mains, gas' },
  { Id: 11629, Code: '5314', Name: 'Fitter, bathroom' },
  { Id: 11630, Code: '5314', Name: 'Fitter, burner, gas works' },
  { Id: 11631, Code: '5314', Name: 'Fitter, district, gas supplier' },
  {
    Id: 11632,
    Code: '5314',
    Name: 'Fitter, DOMESTIC APPLIANCES, GAS APPLIANCES'
  },
  { Id: 11633, Code: '5314', Name: "Fitter, engineer's, heating" },
  { Id: 11634, Code: '5314', Name: "Fitter, engineer's, sanitary" },
  { Id: 11635, Code: '5314', Name: 'Fitter, fire, gas' },
  { Id: 11636, Code: '5314', Name: 'Fitter, gas' },
  { Id: 11637, Code: '5314', Name: 'Fitter, gas supplier' },
  { Id: 11638, Code: '5314', Name: 'Fitter, gas supplier: gas works' },
  { Id: 11639, Code: '5314', Name: 'Fitter, governor, gas supplier' },
  { Id: 11640, Code: '5314', Name: 'Fitter, heating' },
  { Id: 11641, Code: '5314', Name: 'Fitter, heating and ventilation' },
  { Id: 11642, Code: '5314', Name: 'Fitter, heating contracting' },
  { Id: 11643, Code: '5314', Name: 'Fitter, kitchen and bathroom' },
  { Id: 11644, Code: '5314', Name: 'Fitter, maintenance, gas supplier' },
  {
    Id: 11645,
    Code: '5314',
    Name: 'Fitter, maintenance, gas supplier: gas works'
  },
  {
    Id: 11646,
    Code: '5314',
    Name: 'Fitter, maintenance, heating, ventilating'
  },
  { Id: 11647, Code: '5314', Name: 'Fitter, sanitary' },
  { Id: 11648, Code: '5314', Name: 'Fitter, sprinkler' },
  { Id: 11649, Code: '5314', Name: 'Fitter, steam' },
  { Id: 11650, Code: '5314', Name: 'Fitter, steam and hot water' },
  { Id: 11651, Code: '5314', Name: 'Fitter, stove, building construction' },
  { Id: 11652, Code: '5314', Name: 'Fitter, ventilation' },
  { Id: 11653, Code: '5314', Name: 'Fitter, water' },
  { Id: 11654, Code: '5314', Name: 'Fitter-welder, HEATING AND VENTILATING' },
  { Id: 11655, Code: '5314', Name: 'Fixer, appliances, gas supplier' },
  { Id: 11656, Code: '5314', Name: 'Fixer, meter, GAS' },
  { Id: 11657, Code: '5314', Name: 'Fixer, meter, WATER' },
  { Id: 11658, Code: '5314', Name: 'Fixer, ventilator' },
  { Id: 11659, Code: '5314', Name: 'Inserter, ferrule, water works' },
  { Id: 11660, Code: '5314', Name: 'Installer, bathroom' },
  { Id: 11661, Code: '5314', Name: 'Installer, heating' },
  { Id: 11662, Code: '5314', Name: 'Installer, heating contracting' },
  { Id: 11663, Code: '5314', Name: 'Installer, meter, GAS' },
  { Id: 11664, Code: '5314', Name: 'Installer, meter, WATER' },
  { Id: 11665, Code: '5314', Name: 'Jointer, pipe, sprinkler' },
  { Id: 11666, Code: '5314', Name: 'Linesman, gas' },
  { Id: 11667, Code: '5314', Name: 'Man, maintenance, gas supplier' },
  {
    Id: 11668,
    Code: '5314',
    Name: 'Man, service, DOMESTIC APPLIANCES, GAS APPLIANCES'
  },
  {
    Id: 11669,
    Code: '5314',
    Name: 'Man, service, sales, DOMESTIC APPLIANCES, GAS APPLIANCES'
  },
  {
    Id: 11670,
    Code: '5314',
    Name: 'Mender, DOMESTIC APPLIANCES, GAS APPLIANCE'
  },
  { Id: 11671, Code: '5314', Name: 'Pewterer, brewery' },
  { Id: 11672, Code: '5314', Name: 'Plumber' },
  { Id: 11673, Code: '5314', Name: 'Plumber and decorator' },
  { Id: 11674, Code: '5314', Name: 'Plumber and gasfitter' },
  { Id: 11675, Code: '5314', Name: 'Plumber-welder' },
  {
    Id: 11676,
    Code: '5314',
    Name: 'Repairer, DOMESTIC APPLIANCES, GAS APPLIANCES'
  },
  { Id: 11677, Code: '5314', Name: 'Repairer, stove, GAS STOVES' },
  { Id: 11678, Code: '5314', Name: 'Technician, gas' },
  { Id: 11679, Code: '5314', Name: 'Technician, network, gas supplier' },
  { Id: 11680, Code: '5314', Name: 'Technician, network, water company' },
  { Id: 11681, Code: '5314', Name: 'Technician, plumbing' },
  { Id: 11682, Code: '5314', Name: 'Technician, service, gas supplier' },
  { Id: 11683, Code: '5314', Name: 'Worker, gas, maintenance' },
  { Id: 11684, Code: '5315', Name: 'Builder, staircase' },
  { Id: 11685, Code: '5315', Name: 'Carpenter' },
  { Id: 11686, Code: '5315', Name: 'Carpenter and joiner' },
  { Id: 11687, Code: '5315', Name: 'Carpenter-diver' },
  { Id: 11688, Code: '5315', Name: 'Contractor, carpentry' },
  { Id: 11689, Code: '5315', Name: 'Erector, exhibition' },
  { Id: 11690, Code: '5315', Name: 'Erector, hoarding' },
  { Id: 11691, Code: '5315', Name: 'Erector, partitioning, office' },
  { Id: 11692, Code: '5315', Name: 'Erector, shuttering' },
  { Id: 11693, Code: '5315', Name: 'Fitter, bank' },
  {
    Id: 11694,
    Code: '5315',
    Name: 'Fitter, bar, hotels, public houses fitting'
  },
  { Id: 11695, Code: '5315', Name: 'Fitter, bedroom' },
  { Id: 11696, Code: '5315', Name: 'Fitter, exhibition' },
  { Id: 11697, Code: '5315', Name: 'Fitter, kitchen' },
  { Id: 11698, Code: '5315', Name: 'Fitter, office' },
  { Id: 11699, Code: '5315', Name: 'Fitter, shop' },
  { Id: 11700, Code: '5315', Name: 'Fitter, shop and office' },
  { Id: 11701, Code: '5315', Name: 'Fitter, stand, EXHIBITION STAND' },
  { Id: 11702, Code: '5315', Name: 'Fixer, carpentry and joinery' },
  { Id: 11703, Code: '5315', Name: 'Hand, bench, joinery mfr' },
  { Id: 11704, Code: '5315', Name: 'Installer, bedroom' },
  { Id: 11705, Code: '5315', Name: 'Installer, kitchen' },
  { Id: 11706, Code: '5315', Name: 'Joiner' },
  { Id: 11707, Code: '5315', Name: 'Joiner, aircraft' },
  { Id: 11708, Code: '5315', Name: 'Joiner, bench' },
  { Id: 11709, Code: '5315', Name: "Joiner, builder's" },
  { Id: 11710, Code: '5315', Name: "Joiner, fitter's, shop" },
  { Id: 11711, Code: '5315', Name: 'Joiner-builder' },
  { Id: 11712, Code: '5315', Name: 'Joiner-machinist' },
  { Id: 11713, Code: '5315', Name: 'Loftsman and scriever' },
  { Id: 11714, Code: '5315', Name: 'Maker, casement, WOOD' },
  { Id: 11715, Code: '5315', Name: 'Maker, door' },
  { Id: 11716, Code: '5315', Name: 'Maker, frame, bed' },
  { Id: 11717, Code: '5315', Name: 'Maker, frame, box spring mattress mfr' },
  { Id: 11718, Code: '5315', Name: 'Maker, frame, window, WOOD' },
  { Id: 11719, Code: '5315', Name: 'Maker, model, architectural' },
  { Id: 11720, Code: '5315', Name: 'Maker, model, exhibition' },
  { Id: 11721, Code: '5315', Name: "Maker, model, ship's" },
  { Id: 11722, Code: '5315', Name: 'Maker, model, toy mfr' },
  { Id: 11723, Code: '5315', Name: 'Maker, model, wood' },
  { Id: 11724, Code: '5315', Name: 'Maker, pattern, plastics goods mfr' },
  { Id: 11725, Code: '5315', Name: 'Maker, shutter, WOOD' },
  { Id: 11726, Code: '5315', Name: 'Maker, step' },
  { Id: 11727, Code: '5315', Name: 'Maker, template, WOOD' },
  { Id: 11728, Code: '5315', Name: 'Maker, template, wooden' },
  { Id: 11729, Code: '5315', Name: 'Maker, truss, joinery mfr' },
  { Id: 11730, Code: '5315', Name: 'Man, bench, joinery mfr' },
  { Id: 11731, Code: '5315', Name: 'Manufacturer, bedroom' },
  { Id: 11732, Code: '5315', Name: 'Manufacturer, door' },
  { Id: 11733, Code: '5315', Name: 'Manufacturer, joinery' },
  { Id: 11734, Code: '5315', Name: 'Manufacturer, joinery mfr' },
  { Id: 11735, Code: '5315', Name: 'Manufacturer, kitchen' },
  { Id: 11736, Code: '5315', Name: 'Manufacturer, model' },
  { Id: 11737, Code: '5315', Name: 'Modeller, architectural' },
  { Id: 11738, Code: '5315', Name: 'Scriever' },
  { Id: 11739, Code: '5315', Name: 'Scriever-in' },
  { Id: 11740, Code: '5315', Name: 'Shutterer, building construction' },
  { Id: 11741, Code: '5315', Name: 'Woodworker, AIRCRAFT' },
  { Id: 11742, Code: '5316', Name: 'Beader, DOUBLE GLAZING' },
  { Id: 11743, Code: '5316', Name: 'Contractor, DOUBLE GLAZING' },
  { Id: 11744, Code: '5316', Name: 'Contractor, glazing, double' },
  { Id: 11745, Code: '5316', Name: 'Craftsman, light, leaded' },
  { Id: 11746, Code: '5316', Name: 'Cutter, glass' },
  { Id: 11747, Code: '5316', Name: 'Engineer, installation, WINDOWS' },
  { Id: 11748, Code: '5316', Name: 'Erector, conservatory' },
  { Id: 11749, Code: '5316', Name: 'Fabricator, DOUBLE GLAZING' },
  { Id: 11750, Code: '5316', Name: 'Fabricator, glass' },
  { Id: 11751, Code: '5316', Name: 'Fabricator, glazing, double' },
  { Id: 11752, Code: '5316', Name: 'Fabricator, UPVC' },
  { Id: 11753, Code: '5316', Name: 'Fabricator, window' },
  { Id: 11754, Code: '5316', Name: 'Fabricator, window and door' },
  { Id: 11755, Code: '5316', Name: 'Fitter, DOUBLE GLAZING' },
  { Id: 11756, Code: '5316', Name: 'Fitter, gasket, window mfr' },
  { Id: 11757, Code: '5316', Name: 'Fitter, glass' },
  { Id: 11758, Code: '5316', Name: 'Fitter, glazing, double' },
  { Id: 11759, Code: '5316', Name: 'Fitter, window' },
  { Id: 11760, Code: '5316', Name: 'Fitter, window, UPVC' },
  { Id: 11761, Code: '5316', Name: 'Fixer, glazing, patent' },
  { Id: 11762, Code: '5316', Name: 'Fixer, light, lead' },
  { Id: 11763, Code: '5316', Name: 'Fixer, vitrolite' },
  { Id: 11764, Code: '5316', Name: 'Fixer, window' },
  { Id: 11765, Code: '5316', Name: 'Fixer, window and door' },
  { Id: 11766, Code: '5316', Name: 'Glazer, double' },
  { Id: 11767, Code: '5316', Name: 'Glazer, patent' },
  { Id: 11768, Code: '5316', Name: 'Glazer, WINDOWS' },
  { Id: 11769, Code: '5316', Name: 'Glazier' },
  { Id: 11770, Code: '5316', Name: 'Glazier and decorator' },
  { Id: 11771, Code: '5316', Name: 'Installer, DOUBLE GLAZING' },
  { Id: 11772, Code: '5316', Name: 'Installer, glazing, double' },
  { Id: 11773, Code: '5316', Name: 'Installer, window' },
  { Id: 11774, Code: '5316', Name: 'Leader, window' },
  { Id: 11775, Code: '5316', Name: 'Maker, frame, window, METAL' },
  { Id: 11776, Code: '5316', Name: 'Maker, light, lead' },
  { Id: 11777, Code: '5316', Name: 'Maker, light, leaded' },
  { Id: 11778, Code: '5316', Name: 'Maker, unit, window mfr' },
  { Id: 11779, Code: '5316', Name: 'Maker, window' },
  { Id: 11780, Code: '5316', Name: 'Maker, window, glass, stained' },
  { Id: 11781, Code: '5316', Name: 'Maker, window, lead' },
  { Id: 11782, Code: '5316', Name: 'Manufacturer, glazing, double' },
  { Id: 11783, Code: '5316', Name: 'Manufacturer, window' },
  { Id: 11784, Code: '5316', Name: 'Manufacturer, window mfr' },
  { Id: 11785, Code: '5316', Name: 'Sealer, DOUBLE GLAZING' },
  { Id: 11786, Code: '5316', Name: 'Setter-out, light, lead' },
  { Id: 11787, Code: '5316', Name: 'Welder, DOUBLE GLAZING UNITS' },
  { Id: 11788, Code: '5316', Name: 'Worker, glass, decorative' },
  { Id: 11789, Code: '5316', Name: 'Worker, lead, STAINED GLASS' },
  { Id: 11790, Code: '5319', Name: 'Abseiler' },
  { Id: 11791, Code: '5319', Name: 'Acoustician' },
  { Id: 11792, Code: '5319', Name: 'Bender and fixer, bar' },
  { Id: 11793, Code: '5319', Name: 'Bender, bar' },
  { Id: 11794, Code: '5319', Name: 'Bender, steel' },
  { Id: 11795, Code: '5319', Name: 'Builder' },
  { Id: 11796, Code: '5319', Name: 'Builder and contractor' },
  { Id: 11797, Code: '5319', Name: 'Builder and decorator' },
  { Id: 11798, Code: '5319', Name: 'Builder, box, building construction' },
  { Id: 11799, Code: '5319', Name: 'Builder, contractor' },
  { Id: 11800, Code: '5319', Name: 'Builder, garage' },
  { Id: 11801, Code: '5319', Name: 'Builder, general' },
  { Id: 11802, Code: '5319', Name: 'Builder, house' },
  { Id: 11803, Code: '5319', Name: 'Builder, jobbing' },
  { Id: 11804, Code: '5319', Name: 'Builder, maintenance' },
  { Id: 11805, Code: '5319', Name: 'Builder, master' },
  { Id: 11806, Code: '5319', Name: "Contractor, builder's" },
  { Id: 11807, Code: '5319', Name: 'Contractor, building' },
  { Id: 11808, Code: '5319', Name: 'Contractor, fencing' },
  {
    Id: 11809,
    Code: '5319',
    Name: 'Craftsman, general, building construction'
  },
  { Id: 11810, Code: '5319', Name: 'Dealer, estate' },
  { Id: 11811, Code: '5319', Name: 'Dealer, property' },
  { Id: 11812, Code: '5319', Name: 'Developer, estate' },
  {
    Id: 11813,
    Code: '5319',
    Name: 'Developer, property, building construction'
  },
  { Id: 11814, Code: '5319', Name: 'Diver, commercial' },
  { Id: 11815, Code: '5319', Name: 'Diver, construction' },
  { Id: 11816, Code: '5319', Name: 'Driller, diamond, construction' },
  { Id: 11817, Code: '5319', Name: 'Engineer, acoustics' },
  { Id: 11818, Code: '5319', Name: 'Engineer, civil' },
  { Id: 11819, Code: '5319', Name: 'Engineer, conductor, lightning' },
  { Id: 11820, Code: '5319', Name: 'Engineer, construction' },
  { Id: 11821, Code: '5319', Name: 'Engineer, control, noise' },
  { Id: 11822, Code: '5319', Name: 'Engineer, insulation' },
  { Id: 11823, Code: '5319', Name: 'Engineer, irrigation' },
  {
    Id: 11824,
    Code: '5319',
    Name: 'Engineer, maintenance, BUILDINGS AND OTHER STRUCTURES'
  },
  { Id: 11825, Code: '5319', Name: 'Erector, fence' },
  { Id: 11826, Code: '5319', Name: 'Erector, fencing' },
  { Id: 11827, Code: '5319', Name: 'Fencer' },
  {
    Id: 11828,
    Code: '5319',
    Name: 'Fitter, maintenance, BUILDINGS AND OTHER STRUCTURES'
  },
  { Id: 11829, Code: '5319', Name: 'Fixer and bender, steel' },
  {
    Id: 11830,
    Code: '5319',
    Name: 'Fixer, reinforcement, building construction'
  },
  { Id: 11831, Code: '5319', Name: 'Fixer, steel' },
  { Id: 11832, Code: '5319', Name: 'Frogman, construction' },
  { Id: 11833, Code: '5319', Name: 'Hand, chimney, building construction' },
  { Id: 11834, Code: '5319', Name: 'Installer, fence' },
  { Id: 11835, Code: '5319', Name: 'Installer, playground' },
  { Id: 11836, Code: '5319', Name: 'Jack, steeple' },
  {
    Id: 11837,
    Code: '5319',
    Name: 'Manager, maintenance, BUILDINGS AND OTHER STRUCTURES'
  },
  { Id: 11838, Code: '5319', Name: 'Manager, maintenance, property' },
  { Id: 11839, Code: '5319', Name: 'Officer, building' },
  { Id: 11840, Code: '5319', Name: 'Officer, buildings' },
  { Id: 11841, Code: '5319', Name: 'Operator, submersible, construction' },
  { Id: 11842, Code: '5319', Name: 'Peter, steeple' },
  { Id: 11843, Code: '5319', Name: 'Pilot, ROV' },
  { Id: 11844, Code: '5319', Name: 'Pilot, submersible' },
  { Id: 11845, Code: '5319', Name: 'Proofer, sound' },
  { Id: 11846, Code: '5319', Name: 'Renovator, property' },
  { Id: 11847, Code: '5319', Name: 'Steeplejack' },
  { Id: 11848, Code: '5319', Name: 'Steeplepeter' },
  { Id: 11849, Code: '5319', Name: 'Superintendent, estate' },
  { Id: 11850, Code: '5319', Name: 'Supervisor, ROV' },
  { Id: 11851, Code: '5319', Name: 'Supervisor, vehicle, operated, remotely' },
  { Id: 11852, Code: '5319', Name: 'Surveyor, drainage' },
  { Id: 11853, Code: '5319', Name: 'Technician, access, rope' },
  { Id: 11854, Code: '5319', Name: 'Technician, concrete' },
  { Id: 11855, Code: '5319', Name: 'Technician, maintenance, BUILDINGS' },
  {
    Id: 11856,
    Code: '5319',
    Name: 'Technician, support, life, construction, DIVING'
  },
  { Id: 11857, Code: '5319', Name: 'Underpinner' },
  { Id: 11858, Code: '5319', Name: 'Waller, curtain' },
  { Id: 11859, Code: '5321', Name: 'Caster, rough, building construction' },
  { Id: 11860, Code: '5321', Name: 'Contractor, plastering' },
  { Id: 11861, Code: '5321', Name: 'Fixer, fibrous' },
  { Id: 11862, Code: '5321', Name: 'Plasterer' },
  { Id: 11863, Code: '5321', Name: 'Plasterer, fibrous' },
  { Id: 11864, Code: '5321', Name: 'Renderer' },
  { Id: 11865, Code: '5322', Name: 'Builder, fireplace' },
  { Id: 11866, Code: '5322', Name: 'Cementer, ship' },
  { Id: 11867, Code: '5322', Name: 'Concreter, granolithic' },
  { Id: 11868, Code: '5322', Name: 'Contractor, flooring' },
  { Id: 11869, Code: '5322', Name: 'Coverer, floor' },
  { Id: 11870, Code: '5322', Name: 'Cutter, carpet' },
  { Id: 11871, Code: '5322', Name: 'Estimator, carpet' },
  { Id: 11872, Code: '5322', Name: 'Estimator, flooring' },
  { Id: 11873, Code: '5322', Name: 'Fitter, carpet' },
  { Id: 11874, Code: '5322', Name: 'Fitter, fireplace' },
  { Id: 11875, Code: '5322', Name: 'Fitter, floor' },
  { Id: 11876, Code: '5322', Name: 'Fitter, lino' },
  { Id: 11877, Code: '5322', Name: 'Fitter, linoleum' },
  { Id: 11878, Code: '5322', Name: 'Fitter, tile' },
  { Id: 11879, Code: '5322', Name: 'Fixer, carpet' },
  { Id: 11880, Code: '5322', Name: 'Fixer, faience and mosaic' },
  { Id: 11881, Code: '5322', Name: 'Fixer, fireplace' },
  { Id: 11882, Code: '5322', Name: 'Fixer, grate' },
  { Id: 11883, Code: '5322', Name: 'Fixer, mosaic' },
  { Id: 11884, Code: '5322', Name: 'Fixer, terracotta' },
  { Id: 11885, Code: '5322', Name: 'Fixer, tile' },
  { Id: 11886, Code: '5322', Name: 'Floorer' },
  { Id: 11887, Code: '5322', Name: 'Hand, terrazzo' },
  { Id: 11888, Code: '5322', Name: 'Layer, carpet' },
  { Id: 11889, Code: '5322', Name: 'Layer, covering, floor' },
  { Id: 11890, Code: '5322', Name: 'Layer, felt, FLOORING' },
  { Id: 11891, Code: '5322', Name: 'Layer, floor, block' },
  { Id: 11892, Code: '5322', Name: 'Layer, floor, composition' },
  { Id: 11893, Code: '5322', Name: 'Layer, floor, decorative' },
  { Id: 11894, Code: '5322', Name: 'Layer, floor, granolithic' },
  { Id: 11895, Code: '5322', Name: 'Layer, floor, jointless' },
  { Id: 11896, Code: '5322', Name: 'Layer, floor, mosaic' },
  { Id: 11897, Code: '5322', Name: 'Layer, floor, nos' },
  { Id: 11898, Code: '5322', Name: 'Layer, floor, parquet' },
  { Id: 11899, Code: '5322', Name: 'Layer, floor, patent' },
  { Id: 11900, Code: '5322', Name: 'Layer, floor, plastic' },
  { Id: 11901, Code: '5322', Name: 'Layer, floor, rubber' },
  { Id: 11902, Code: '5322', Name: 'Layer, floor, terrazzo' },
  { Id: 11903, Code: '5322', Name: 'Layer, floor, tile' },
  { Id: 11904, Code: '5322', Name: 'Layer, floor, wood' },
  { Id: 11905, Code: '5322', Name: 'Layer, lino' },
  { Id: 11906, Code: '5322', Name: 'Layer, linoleum' },
  { Id: 11907, Code: '5322', Name: 'Layer, mosaic' },
  { Id: 11908, Code: '5322', Name: 'Layer, terrazzo' },
  { Id: 11909, Code: '5322', Name: 'Layer, tile' },
  { Id: 11910, Code: '5322', Name: 'Maker, fireplace, TILED' },
  { Id: 11911, Code: '5322', Name: 'Maker, grate, tile' },
  { Id: 11912, Code: '5322', Name: 'Manufacturer, fireplace' },
  { Id: 11913, Code: '5322', Name: 'Manufacturer, fireplace mfr' },
  { Id: 11914, Code: '5322', Name: 'Paver, tile' },
  { Id: 11915, Code: '5322', Name: 'Pavior, tile' },
  { Id: 11916, Code: '5322', Name: 'Paviour, tile' },
  { Id: 11917, Code: '5322', Name: 'Planner, carpet' },
  { Id: 11918, Code: '5322', Name: 'Planner, lino' },
  { Id: 11919, Code: '5322', Name: 'Planner, linoleum' },
  { Id: 11920, Code: '5322', Name: 'Polisher, terrazzo' },
  { Id: 11921, Code: '5322', Name: 'Polisher, terrazzo floor laying' },
  { Id: 11922, Code: '5322', Name: 'Screeder, floor' },
  { Id: 11923, Code: '5322', Name: 'Setter, mosaic' },
  { Id: 11924, Code: '5322', Name: 'Setter, tile, building construction' },
  { Id: 11925, Code: '5322', Name: "Slabber, builders' merchants" },
  { Id: 11926, Code: '5322', Name: 'Slabber, fireplace mfr' },
  { Id: 11927, Code: '5322', Name: 'Slabber, tile' },
  { Id: 11928, Code: '5322', Name: 'Specialist, flooring' },
  { Id: 11929, Code: '5322', Name: 'Spreader, lay, cold' },
  { Id: 11930, Code: '5322', Name: 'Technician, carpet' },
  { Id: 11931, Code: '5322', Name: 'Technician, flooring' },
  { Id: 11932, Code: '5322', Name: 'Technician, laying, floor' },
  { Id: 11933, Code: '5322', Name: 'Tiler and plasterer' },
  { Id: 11934, Code: '5322', Name: 'Tiler, ceramic' },
  { Id: 11935, Code: '5322', Name: 'Tiler, cork' },
  { Id: 11936, Code: '5322', Name: 'Tiler, floor' },
  { Id: 11937, Code: '5322', Name: 'Tiler, glaze' },
  { Id: 11938, Code: '5322', Name: 'Tiler, range' },
  { Id: 11939, Code: '5322', Name: 'Tiler, wall' },
  { Id: 11940, Code: '5322', Name: 'Worker, faience' },
  { Id: 11941, Code: '5322', Name: 'Worker, mosaic' },
  { Id: 11942, Code: '5322', Name: 'Worker, terrazzo' },
  { Id: 11943, Code: '5323', Name: 'Artexer' },
  { Id: 11944, Code: '5323', Name: 'Chipper and painter' },
  { Id: 11945, Code: '5323', Name: 'Coater, prime' },
  { Id: 11946, Code: '5323', Name: 'Contractor, decorating' },
  { Id: 11947, Code: '5323', Name: 'Contractor, painting' },
  { Id: 11948, Code: '5323', Name: 'Contractor, painting, decorating' },
  { Id: 11949, Code: '5323', Name: 'Decorator' },
  { Id: 11950, Code: '5323', Name: 'Decorator, house' },
  {
    Id: 11951,
    Code: '5323',
    Name: 'Decorator, interior, building construction'
  },
  { Id: 11952, Code: '5323', Name: 'Finisher, briar pipe mfr' },
  { Id: 11953, Code: '5323', Name: 'Gilder, painting, decorating' },
  { Id: 11954, Code: '5323', Name: 'Grainer and marbler' },
  { Id: 11955, Code: '5323', Name: 'Grainer, painting, decorating' },
  { Id: 11956, Code: '5323', Name: 'Hand, brush' },
  { Id: 11957, Code: '5323', Name: 'Hanger, paper' },
  { Id: 11958, Code: '5323', Name: 'Leader, red' },
  { Id: 11959, Code: '5323', Name: 'Letterer, signwriting' },
  { Id: 11960, Code: '5323', Name: 'Painter' },
  { Id: 11961, Code: '5323', Name: 'Painter and decorator' },
  { Id: 11962, Code: '5323', Name: 'Painter and glazier' },
  { Id: 11963, Code: '5323', Name: 'Painter, bridge' },
  { Id: 11964, Code: '5323', Name: 'Painter, buildings' },
  { Id: 11965, Code: '5323', Name: 'Painter, house' },
  { Id: 11966, Code: '5323', Name: 'Painter, industrial' },
  {
    Id: 11967,
    Code: '5323',
    Name: 'Painter, marine, boat building and repairing'
  },
  { Id: 11968, Code: '5323', Name: 'Painter, scenic' },
  { Id: 11969, Code: '5323', Name: 'Painter, ship' },
  { Id: 11970, Code: '5323', Name: 'Painter, spray, painting, decorating' },
  { Id: 11971, Code: '5323', Name: 'Painter-decorator' },
  { Id: 11972, Code: '5323', Name: 'Paperhanger' },
  { Id: 11973, Code: '5323', Name: 'Polisher, french' },
  { Id: 11974, Code: '5323', Name: 'Polisher, furniture' },
  { Id: 11975, Code: '5323', Name: 'Polisher, furniture mfr' },
  { Id: 11976, Code: '5323', Name: 'Polisher, piano' },
  { Id: 11977, Code: '5323', Name: 'Polisher, wood' },
  { Id: 11978, Code: '5323', Name: 'Specialist, ceiling' },
  { Id: 11979, Code: '5323', Name: 'Sprayer, bridge' },
  { Id: 11980, Code: '5323', Name: 'Sprayer, buildings' },
  { Id: 11981, Code: '5323', Name: 'Sprayer, ship' },
  { Id: 11982, Code: '5323', Name: 'Stainer, furniture mfr' },
  { Id: 11983, Code: '5323', Name: 'Stainer, tobacco pipe mfr' },
  { Id: 11984, Code: '5323', Name: 'Stainer, wood' },
  { Id: 11985, Code: '5323', Name: 'Texturer, ceiling' },
  { Id: 11986, Code: '5323', Name: 'Worker, artex' },
  {
    Id: 11987,
    Code: '5330',
    Name: 'Chargeman, track, railway maintenance and repair'
  },
  { Id: 11988, Code: '5330', Name: 'Chargeman, track, railways' },
  { Id: 11989, Code: '5330', Name: 'Foreman of works' },
  { Id: 11990, Code: '5330', Name: 'Foreman, bridge' },
  { Id: 11991, Code: '5330', Name: "Foreman, builder's" },
  { Id: 11992, Code: '5330', Name: 'Foreman, building' },
  { Id: 11993, Code: '5330', Name: 'Foreman, building construction' },
  { Id: 11994, Code: '5330', Name: 'Foreman, civil engineering' },
  { Id: 11995, Code: '5330', Name: 'Foreman, civilian, government' },
  { Id: 11996, Code: '5330', Name: 'Foreman, concrete' },
  { Id: 11997, Code: '5330', Name: 'Foreman, construction' },
  {
    Id: 11998,
    Code: '5330',
    Name: 'Foreman, constructional engineering, METAL'
  },
  { Id: 11999, Code: '5330', Name: "Foreman, contractor's" },
  { Id: 12000, Code: '5330', Name: 'Foreman, demolition' },
  { Id: 12001, Code: '5330', Name: 'Foreman, DETR' },
  { Id: 12002, Code: '5330', Name: 'Foreman, distribution, gas supplier' },
  { Id: 12003, Code: '5330', Name: 'Foreman, district' },
  { Id: 12004, Code: '5330', Name: 'Foreman, drainage' },
  { Id: 12005, Code: '5330', Name: 'Foreman, engineering, civil' },
  { Id: 12006, Code: '5330', Name: 'Foreman, engineering, constructional' },
  { Id: 12007, Code: '5330', Name: 'Foreman, estate' },
  { Id: 12008, Code: '5330', Name: 'Foreman, estates' },
  { Id: 12009, Code: '5330', Name: 'Foreman, fittings, gas supplier' },
  { Id: 12010, Code: '5330', Name: 'Foreman, flooring contracting' },
  { Id: 12011, Code: '5330', Name: 'Foreman, gas supplier' },
  { Id: 12012, Code: '5330', Name: 'Foreman, general, building construction' },
  { Id: 12013, Code: '5330', Name: 'Foreman, heating engineering' },
  { Id: 12014, Code: '5330', Name: 'Foreman, highways' },
  { Id: 12015, Code: '5330', Name: 'Foreman, joinery mfr' },
  { Id: 12016, Code: '5330', Name: 'Foreman, local government' },
  {
    Id: 12017,
    Code: '5330',
    Name: "Foreman, local government: engineer's dept"
  },
  { Id: 12018, Code: '5330', Name: 'Foreman, local government: highways dept' },
  { Id: 12019, Code: '5330', Name: 'Foreman, local government: housing dept' },
  { Id: 12020, Code: '5330', Name: 'Foreman, local government: public works' },
  {
    Id: 12021,
    Code: '5330',
    Name: "Foreman, local government: surveyor's dept"
  },
  { Id: 12022, Code: '5330', Name: 'Foreman, maintenance' },
  { Id: 12023, Code: '5330', Name: 'Foreman, maintenance, gas supplier' },
  { Id: 12024, Code: '5330', Name: 'Foreman, painting' },
  { Id: 12025, Code: '5330', Name: 'Foreman, pipe, main' },
  { Id: 12026, Code: '5330', Name: 'Foreman, plumbing' },
  { Id: 12027, Code: '5330', Name: 'Foreman, railway maintenance and repair' },
  {
    Id: 12028,
    Code: '5330',
    Name: "Foreman, railways: district engineer's dept"
  },
  { Id: 12029, Code: '5330', Name: 'Foreman, road' },
  { Id: 12030, Code: '5330', Name: 'Foreman, scaffolding' },
  { Id: 12031, Code: '5330', Name: 'Foreman, site' },
  { Id: 12032, Code: '5330', Name: 'Foreman, way, permanent' },
  { Id: 12033, Code: '5330', Name: 'Foreman, woodware mfr' },
  { Id: 12034, Code: '5330', Name: 'Foreman, woodwork' },
  { Id: 12035, Code: '5330', Name: 'Foreman-ganger, building construction' },
  { Id: 12036, Code: '5330', Name: 'Foreman-ganger, cable laying' },
  { Id: 12037, Code: '5330', Name: 'Foreman-ganger, electricity supplier' },
  { Id: 12038, Code: '5330', Name: 'Foreman-ganger, gas supplier' },
  { Id: 12039, Code: '5330', Name: 'Foreman-ganger, local government' },
  { Id: 12040, Code: '5330', Name: 'Foreman-ganger, MAINTENANCE' },
  {
    Id: 12041,
    Code: '5330',
    Name: 'Foreman-ganger, railway maintenance and repair'
  },
  { Id: 12042, Code: '5330', Name: 'Foreman-ganger, railways' },
  { Id: 12043, Code: '5330', Name: 'Foreman-ganger, water works' },
  { Id: 12044, Code: '5330', Name: 'Ganger, electricity supplier' },
  { Id: 12045, Code: '5330', Name: 'Ganger, gas supplier' },
  { Id: 12046, Code: '5330', Name: 'Ganger, highways' },
  { Id: 12047, Code: '5330', Name: 'Ganger, local government' },
  { Id: 12048, Code: '5330', Name: 'Ganger, navvy' },
  { Id: 12049, Code: '5330', Name: 'Ganger, railway maintenance and repair' },
  { Id: 12050, Code: '5330', Name: 'Ganger, railways' },
  { Id: 12051, Code: '5330', Name: 'Ganger, water works' },
  { Id: 12052, Code: '5330', Name: 'Hand, leading, building construction' },
  { Id: 12053, Code: '5330', Name: 'Hand, maintenance, transport: railways' },
  { Id: 12054, Code: '5330', Name: 'Inspector, ballast' },
  { Id: 12055, Code: '5330', Name: 'Inspector, bridge' },
  {
    Id: 12056,
    Code: '5330',
    Name: 'Inspector, district, building construction'
  },
  { Id: 12057, Code: '5330', Name: 'Inspector, drain' },
  { Id: 12058, Code: '5330', Name: 'Inspector, drainage' },
  {
    Id: 12059,
    Code: '5330',
    Name: 'Inspector, excavating, electricity supplier'
  },
  { Id: 12060, Code: '5330', Name: 'Inspector, highways' },
  { Id: 12061, Code: '5330', Name: 'Inspector, line, pipe' },
  {
    Id: 12062,
    Code: '5330',
    Name: 'Inspector, line, railway maintenance and repair'
  },
  {
    Id: 12063,
    Code: '5330',
    Name: 'Inspector, local government: highways dept'
  },
  { Id: 12064, Code: '5330', Name: 'Inspector, mains' },
  {
    Id: 12065,
    Code: '5330',
    Name: 'Inspector, maintenance, local government: housing dept'
  },
  { Id: 12066, Code: '5330', Name: 'Inspector, paving and extension' },
  { Id: 12067, Code: '5330', Name: 'Inspector, trench' },
  { Id: 12068, Code: '5330', Name: 'Inspector, tunnel' },
  { Id: 12069, Code: '5330', Name: 'Inspector, way, permanent' },
  {
    Id: 12070,
    Code: '5330',
    Name: 'Inspector, works, district, railway maintenance and repair'
  },
  { Id: 12071, Code: '5330', Name: 'Inspector, works, district, railways' },
  {
    Id: 12072,
    Code: '5330',
    Name: 'Inspector, works, new, railway maintenance and repair'
  },
  { Id: 12073, Code: '5330', Name: 'Inspector, works, new, railways' },
  {
    Id: 12074,
    Code: '5330',
    Name: 'Inspector, works, railway maintenance and repair'
  },
  { Id: 12075, Code: '5330', Name: 'Inspector, works, railways' },
  { Id: 12076, Code: '5330', Name: 'Master, gang, drainage board' },
  { Id: 12077, Code: '5330', Name: 'Officer, maintenance, local government' },
  { Id: 12078, Code: '5330', Name: 'Skipper, building construction' },
  { Id: 12079, Code: '5330', Name: 'Superintendent of works' },
  { Id: 12080, Code: '5330', Name: 'Superintendent, board, gas' },
  { Id: 12081, Code: '5330', Name: 'Superintendent, building construction' },
  {
    Id: 12082,
    Code: '5330',
    Name: 'Superintendent, distribution, gas supplier'
  },
  { Id: 12083, Code: '5330', Name: 'Superintendent, district, gas supplier' },
  { Id: 12084, Code: '5330', Name: 'Superintendent, engineering: civil' },
  { Id: 12085, Code: '5330', Name: 'Superintendent, engineering: structural' },
  {
    Id: 12086,
    Code: '5330',
    Name: 'Superintendent, local government: highways dept'
  },
  { Id: 12087, Code: '5330', Name: 'Superintendent, mains' },
  { Id: 12088, Code: '5330', Name: 'Superintendent, maintenance' },
  { Id: 12089, Code: '5330', Name: 'Superintendent, repairs' },
  {
    Id: 12090,
    Code: '5330',
    Name: 'Superintendent, works, building construction'
  },
  { Id: 12091, Code: '5330', Name: 'Superintendent, works, local government' },
  { Id: 12092, Code: '5330', Name: 'Supervisor, area, building construction' },
  { Id: 12093, Code: '5330', Name: 'Supervisor, area, gas supplier' },
  { Id: 12094, Code: '5330', Name: 'Supervisor, building' },
  { Id: 12095, Code: '5330', Name: 'Supervisor, construction' },
  {
    Id: 12096,
    Code: '5330',
    Name: 'Supervisor, contract, building construction'
  },
  {
    Id: 12097,
    Code: '5330',
    Name: 'Supervisor, contracts, building construction'
  },
  { Id: 12098, Code: '5330', Name: 'Supervisor, distribution, water company' },
  {
    Id: 12099,
    Code: '5330',
    Name: 'Supervisor, erection, building construction'
  },
  { Id: 12100, Code: '5330', Name: 'Supervisor, gas supplier' },
  { Id: 12101, Code: '5330', Name: 'Supervisor, maintenance' },
  { Id: 12102, Code: '5330', Name: 'Supervisor, plumbing' },
  { Id: 12103, Code: '5330', Name: 'Supervisor, service, gas supplier' },
  {
    Id: 12104,
    Code: '5330',
    Name: 'Supervisor, services, building, BUILDING MAINTENANCE'
  },
  { Id: 12105, Code: '5411', Name: 'Backer, carpet' },
  { Id: 12106, Code: '5411', Name: 'Footer, hosiery, knitwear mfr' },
  { Id: 12107, Code: '5411', Name: 'Foreman, bookcloth mfr' },
  { Id: 12108, Code: '5411', Name: 'Foreman, loom' },
  { Id: 12109, Code: '5411', Name: 'Foreman, shed, weaving' },
  { Id: 12110, Code: '5411', Name: 'Foreman, textile mfr: hosiery mfr' },
  { Id: 12111, Code: '5411', Name: 'Foreman, textile mfr: textile weaving' },
  { Id: 12112, Code: '5411', Name: 'Foreman, weaving' },
  { Id: 12113, Code: '5411', Name: 'Fringer' },
  { Id: 12114, Code: '5411', Name: 'Gaiter, loom' },
  { Id: 12115, Code: '5411', Name: 'Hand, circular' },
  { Id: 12116, Code: '5411', Name: 'Hand, lace' },
  { Id: 12117, Code: '5411', Name: 'Hand, loom, warp' },
  { Id: 12118, Code: '5411', Name: 'Hand, needle, latch' },
  { Id: 12119, Code: '5411', Name: 'Hand, seamless' },
  { Id: 12120, Code: '5411', Name: 'Hand, smash' },
  { Id: 12121, Code: '5411', Name: 'Hand, twist' },
  { Id: 12122, Code: '5411', Name: 'Hand, twist, net, plain' },
  { Id: 12123, Code: '5411', Name: 'Hand, warp' },
  { Id: 12124, Code: '5411', Name: 'Hosier, elastic' },
  { Id: 12125, Code: '5411', Name: 'Hosier, surgical' },
  { Id: 12126, Code: '5411', Name: 'Joiner, textile mfr: hosiery mfr' },
  { Id: 12127, Code: '5411', Name: 'Knitter' },
  { Id: 12128, Code: '5411', Name: 'Maker, band, textile smallwares mfr' },
  { Id: 12129, Code: '5411', Name: 'Maker, coat, KNITTED COATS' },
  { Id: 12130, Code: '5411', Name: 'Maker, fabric, glove' },
  { Id: 12131, Code: '5411', Name: 'Maker, fringe, metallic' },
  { Id: 12132, Code: '5411', Name: 'Maker, heald, yarn' },
  { Id: 12133, Code: '5411', Name: 'Maker, hosiery' },
  { Id: 12134, Code: '5411', Name: 'Maker, lace' },
  {
    Id: 12135,
    Code: '5411',
    Name: 'Maker, lace, boot, textile smallwares mfr'
  },
  { Id: 12136, Code: '5411', Name: 'Maker, lace, warp' },
  { Id: 12137, Code: '5411', Name: 'Maker, mat' },
  { Id: 12138, Code: '5411', Name: 'Maker, net' },
  { Id: 12139, Code: '5411', Name: 'Maker, rug' },
  { Id: 12140, Code: '5411', Name: 'Maker, sock, hosiery, knitwear mfr' },
  { Id: 12141, Code: '5411', Name: 'Maker, tassel' },
  { Id: 12142, Code: '5411', Name: 'Man, lace' },
  { Id: 12143, Code: '5411', Name: 'Manufacturer, knitwear' },
  { Id: 12144, Code: '5411', Name: 'Manufacturer, knitwear mfr' },
  { Id: 12145, Code: '5411', Name: 'Milliner, hosiery' },
  { Id: 12146, Code: '5411', Name: 'Mistress, weaving' },
  { Id: 12147, Code: '5411', Name: 'Mounter, textile weaving' },
  {
    Id: 12148,
    Code: '5411',
    Name: 'Overlooker, loom, textile mfr textile weaving, MAINTENANCE'
  },
  { Id: 12149, Code: '5411', Name: 'Plier, needle, hosiery, knitwear mfr' },
  { Id: 12150, Code: '5411', Name: 'Putter-up, textile mfr: textile weaving' },
  { Id: 12151, Code: '5411', Name: 'Runner-off, hosiery, knitwear mfr' },
  { Id: 12152, Code: '5411', Name: 'Runner-on, hosiery, knitwear mfr' },
  { Id: 12153, Code: '5411', Name: 'Spinner, gimp' },
  { Id: 12154, Code: '5411', Name: 'Stockinger' },
  { Id: 12155, Code: '5411', Name: 'Tailer, BERET' },
  { Id: 12156, Code: '5411', Name: 'Tender, loom' },
  { Id: 12157, Code: '5411', Name: 'Tier, smash' },
  { Id: 12158, Code: '5411', Name: 'Topper and tailer' },
  { Id: 12159, Code: '5411', Name: 'Topper, hosiery, knitwear mfr' },
  { Id: 12160, Code: '5411', Name: 'Transferer, hosiery' },
  { Id: 12161, Code: '5411', Name: 'Weaver' },
  { Id: 12162, Code: '5411', Name: 'Weaver, asbestos' },
  { Id: 12163, Code: '5411', Name: 'Weaver, carpet' },
  { Id: 12164, Code: '5411', Name: 'Weaver, contour' },
  { Id: 12165, Code: '5411', Name: 'Weaver, lace' },
  { Id: 12166, Code: '5411', Name: 'Weaver, loom, pattern' },
  { Id: 12167, Code: '5411', Name: 'Weaver, pattern' },
  { Id: 12168, Code: '5411', Name: 'Weaver, textile' },
  { Id: 12169, Code: '5411', Name: 'Whaler, hosiery' },
  { Id: 12170, Code: '5411', Name: 'Worker, carpet' },
  { Id: 12171, Code: '5411', Name: 'Worker, crochet' },
  { Id: 12172, Code: '5411', Name: 'Worker, lace' },
  { Id: 12173, Code: '5411', Name: 'Worker, loom, hand' },
  { Id: 12174, Code: '5412', Name: 'Coverer, Rexine' },
  { Id: 12175, Code: '5412', Name: 'Edger, mattress mfr' },
  { Id: 12176, Code: '5412', Name: 'Filler, mattress, upholstery mfr' },
  { Id: 12177, Code: '5412', Name: 'Finisher, coach trimming' },
  { Id: 12178, Code: '5412', Name: 'Finisher, quilt' },
  { Id: 12179, Code: '5412', Name: 'Fitter, curtain' },
  { Id: 12180, Code: '5412', Name: 'Fitter, furnishing, SOFT' },
  { Id: 12181, Code: '5412', Name: "Fitter, upholsterer's" },
  { Id: 12182, Code: '5412', Name: 'Foreman, mattress mfr' },
  { Id: 12183, Code: '5412', Name: 'Foreman, shop, trim' },
  { Id: 12184, Code: '5412', Name: 'Foreman, upholstering' },
  { Id: 12185, Code: '5412', Name: 'Furnisher, soft' },
  { Id: 12186, Code: '5412', Name: 'Hand, bench, coach trimming' },
  { Id: 12187, Code: '5412', Name: 'Maker, bed, mattress mfr' },
  { Id: 12188, Code: '5412', Name: 'Maker, case, mattress, upholstery mfr' },
  { Id: 12189, Code: '5412', Name: 'Maker, cover, mattress' },
  { Id: 12190, Code: '5412', Name: 'Maker, curtain' },
  { Id: 12191, Code: '5412', Name: 'Maker, cushion' },
  { Id: 12192, Code: '5412', Name: 'Maker, eiderdown' },
  { Id: 12193, Code: '5412', Name: 'Maker, hassock' },
  { Id: 12194, Code: '5412', Name: 'Maker, mattress' },
  { Id: 12195, Code: '5412', Name: 'Maker, mattress, interior, spring' },
  { Id: 12196, Code: '5412', Name: 'Maker, pad, upholstery mfr' },
  { Id: 12197, Code: '5412', Name: 'Manufacturer, mattress mfr' },
  { Id: 12198, Code: '5412', Name: 'Packer, flock, mattress mfr' },
  { Id: 12199, Code: '5412', Name: 'Stitcher, mattress' },
  { Id: 12200, Code: '5412', Name: 'Trimmer, aircraft mfr' },
  { Id: 12201, Code: '5412', Name: 'Trimmer, board, floor, coach trimming' },
  { Id: 12202, Code: '5412', Name: 'Trimmer, body, car' },
  { Id: 12203, Code: '5412', Name: 'Trimmer, car' },
  { Id: 12204, Code: '5412', Name: 'Trimmer, carriage' },
  { Id: 12205, Code: '5412', Name: 'Trimmer, coach' },
  { Id: 12206, Code: '5412', Name: 'Trimmer, furniture mfr' },
  { Id: 12207, Code: '5412', Name: 'Trimmer, leather, vehicle mfr' },
  { Id: 12208, Code: '5412', Name: 'Trimmer, motor' },
  { Id: 12209, Code: '5412', Name: 'Trimmer, railway workshops' },
  { Id: 12210, Code: '5412', Name: 'Trimmer, seat, car' },
  { Id: 12211, Code: '5412', Name: "Trimmer, upholsterer's" },
  { Id: 12212, Code: '5412', Name: 'Trimmer, vehicle mfr' },
  { Id: 12213, Code: '5412', Name: 'Upholsterer' },
  { Id: 12214, Code: '5413', Name: 'Assembler, footwear' },
  { Id: 12215, Code: '5413', Name: 'Assembler, leather goods mfr' },
  { Id: 12216, Code: '5413', Name: "Assistant, maker's, shoe" },
  { Id: 12217, Code: '5413', Name: 'Barrer, footwear mfr' },
  { Id: 12218, Code: '5413', Name: 'Beader, footwear mfr' },
  { Id: 12219, Code: '5413', Name: 'Beater, footwear mfr' },
  { Id: 12220, Code: '5413', Name: 'Beltman, engineering' },
  { Id: 12221, Code: '5413', Name: 'Beltman, machine' },
  { Id: 12222, Code: '5413', Name: 'Beveller, footwear mfr' },
  { Id: 12223, Code: '5413', Name: 'Binder, footwear mfr' },
  { Id: 12224, Code: '5413', Name: 'Binder, slipper' },
  { Id: 12225, Code: '5413', Name: 'Blocker, footwear mfr' },
  { Id: 12226, Code: '5413', Name: 'Blocker, leather goods mfr' },
  { Id: 12227, Code: '5413', Name: 'Bottomer, surgical footwear mfr' },
  { Id: 12228, Code: '5413', Name: 'Breaster, heel' },
  { Id: 12229, Code: '5413', Name: 'Brusher, footwear mfr' },
  { Id: 12230, Code: '5413', Name: 'Brusher-up, footwear mfr' },
  { Id: 12231, Code: '5413', Name: 'Builder-up, last' },
  { Id: 12232, Code: '5413', Name: 'Burnisher, footwear mfr' },
  { Id: 12233, Code: '5413', Name: 'Butter and tacker, welt' },
  { Id: 12234, Code: '5413', Name: 'Cementer, footwear mfr' },
  { Id: 12235, Code: '5413', Name: 'Cementer, outsole' },
  { Id: 12236, Code: '5413', Name: 'Cementer, upper' },
  { Id: 12237, Code: '5413', Name: 'Channeller, footwear mfr' },
  { Id: 12238, Code: '5413', Name: 'Cleaner, footwear mfr' },
  { Id: 12239, Code: '5413', Name: 'Clicker, footwear mfr' },
  { Id: 12240, Code: '5413', Name: 'Clicker, leather goods mfr' },
  { Id: 12241, Code: '5413', Name: 'Clicker, machine' },
  { Id: 12242, Code: '5413', Name: 'Clicker, press' },
  { Id: 12243, Code: '5413', Name: 'Clogger' },
  { Id: 12244, Code: '5413', Name: 'Closer, footwear mfr' },
  { Id: 12245, Code: '5413', Name: 'Closer, repairs' },
  { Id: 12246, Code: '5413', Name: 'Closer, toy mfr' },
  { Id: 12247, Code: '5413', Name: 'Cobbler' },
  { Id: 12248, Code: '5413', Name: 'Compressor, heel' },
  { Id: 12249, Code: '5413', Name: 'Corder, footwear mfr' },
  { Id: 12250, Code: '5413', Name: 'Cordwinder' },
  { Id: 12251, Code: '5413', Name: 'Coverer, leather goods mfr' },
  { Id: 12252, Code: '5413', Name: 'Coverer, roller, textile mfr' },
  { Id: 12253, Code: '5413', Name: 'Creaser, footwear mfr' },
  { Id: 12254, Code: '5413', Name: 'Creaser, vamp' },
  { Id: 12255, Code: '5413', Name: 'Crimper, footwear mfr' },
  { Id: 12256, Code: '5413', Name: 'Crimper, vamp' },
  { Id: 12257, Code: '5413', Name: 'Crowner' },
  { Id: 12258, Code: '5413', Name: 'Cutter, bag' },
  { Id: 12259, Code: '5413', Name: 'Cutter, belt' },
  { Id: 12260, Code: '5413', Name: 'Cutter, brace' },
  { Id: 12261, Code: '5413', Name: 'Cutter, bridle' },
  { Id: 12262, Code: '5413', Name: 'Cutter, die, footwear mfr' },
  { Id: 12263, Code: '5413', Name: 'Cutter, fittings' },
  { Id: 12264, Code: '5413', Name: 'Cutter, footwear mfr' },
  { Id: 12265, Code: '5413', Name: 'Cutter, glove, boxing' },
  { Id: 12266, Code: '5413', Name: 'Cutter, guillotine, leather goods mfr' },
  { Id: 12267, Code: '5413', Name: 'Cutter, leather' },
  { Id: 12268, Code: '5413', Name: 'Cutter, leather goods mfr' },
  { Id: 12269, Code: '5413', Name: 'Cutter, lining, footwear mfr' },
  { Id: 12270, Code: '5413', Name: 'Cutter, machine, leather goods mfr' },
  { Id: 12271, Code: '5413', Name: 'Cutter, pattern, footwear mfr' },
  { Id: 12272, Code: '5413', Name: 'Cutter, pattern, iron, footwear mfr' },
  { Id: 12273, Code: '5413', Name: 'Cutter, pattern, leather goods mfr' },
  { Id: 12274, Code: '5413', Name: 'Cutter, powder puff mfr' },
  { Id: 12275, Code: '5413', Name: 'Cutter, press, footwear mfr' },
  { Id: 12276, Code: '5413', Name: 'Cutter, press, leather goods mfr' },
  { Id: 12277, Code: '5413', Name: 'Cutter, profile, footwear mfr' },
  { Id: 12278, Code: '5413', Name: 'Cutter, puff' },
  { Id: 12279, Code: '5413', Name: 'Cutter, saddle' },
  { Id: 12280, Code: '5413', Name: 'Cutter, sample, footwear mfr' },
  { Id: 12281, Code: '5413', Name: 'Cutter, shoe' },
  { Id: 12282, Code: '5413', Name: 'Cutter, slipper' },
  { Id: 12283, Code: '5413', Name: 'Cutter, sole' },
  { Id: 12284, Code: '5413', Name: 'Cutter, stiffening' },
  { Id: 12285, Code: '5413', Name: 'Cutter, strap, leather goods mfr' },
  { Id: 12286, Code: '5413', Name: 'Dresser, footwear mfr' },
  { Id: 12287, Code: '5413', Name: 'Eyeletter, footwear mfr' },
  { Id: 12288, Code: '5413', Name: 'Eyeletter, leather goods mfr' },
  { Id: 12289, Code: '5413', Name: 'Filler, bottom, boot mfr' },
  { Id: 12290, Code: '5413', Name: 'Finisher, boot and shoe' },
  { Id: 12291, Code: '5413', Name: 'Finisher, bottom' },
  { Id: 12292, Code: '5413', Name: 'Finisher, footwear mfr' },
  { Id: 12293, Code: '5413', Name: 'Finisher, leather goods mfr' },
  { Id: 12294, Code: '5413', Name: 'Finisher, shoe' },
  { Id: 12295, Code: '5413', Name: 'Fitter, belt, coal mine' },
  { Id: 12296, Code: '5413', Name: 'Fitter, belting' },
  { Id: 12297, Code: '5413', Name: 'Fitter, footwear mfr' },
  { Id: 12298, Code: '5413', Name: 'Fitter, heel' },
  { Id: 12299, Code: '5413', Name: 'Fitter, last, bespoke' },
  { Id: 12300, Code: '5413', Name: 'Fitter, leather goods mfr' },
  { Id: 12301, Code: '5413', Name: 'Fitter, spring, elastic' },
  { Id: 12302, Code: '5413', Name: 'Fitter-up, footwear mfr' },
  { Id: 12303, Code: '5413', Name: 'Folder, footwear mfr' },
  { Id: 12304, Code: '5413', Name: 'Foreman, footwear mfr' },
  { Id: 12305, Code: '5413', Name: 'Foreman, leather goods mfr' },
  { Id: 12306, Code: '5413', Name: 'Framer, leather goods mfr' },
  { Id: 12307, Code: '5413', Name: 'Glosser, footwear mfr' },
  { Id: 12308, Code: '5413', Name: 'Hand, alteration, FOOTWEAR' },
  { Id: 12309, Code: '5413', Name: 'Hand, bench, footwear mfr' },
  { Id: 12310, Code: '5413', Name: 'Hand, bench, leather goods mfr' },
  { Id: 12311, Code: '5413', Name: 'Hand, room, footwear mfr' },
  { Id: 12312, Code: '5413', Name: 'Hand, sample, footwear mfr' },
  { Id: 12313, Code: '5413', Name: 'Hand, shoe' },
  { Id: 12314, Code: '5413', Name: 'Heeler, FOOTWEAR' },
  { Id: 12315, Code: '5413', Name: 'Holer, eyelet' },
  { Id: 12316, Code: '5413', Name: 'Inker, footwear mfr' },
  { Id: 12317, Code: '5413', Name: 'Interlacer, shoe' },
  { Id: 12318, Code: '5413', Name: 'Ironer, boot' },
  { Id: 12319, Code: '5413', Name: 'Ironer, footwear mfr' },
  { Id: 12320, Code: '5413', Name: 'Lacer, footwear mfr' },
  { Id: 12321, Code: '5413', Name: 'Laster' },
  { Id: 12322, Code: '5413', Name: 'Layer, sole' },
  { Id: 12323, Code: '5413', Name: 'Leveller, footwear mfr' },
  { Id: 12324, Code: '5413', Name: 'Looker-over, footwear mfr' },
  { Id: 12325, Code: '5413', Name: 'Machinist, bag, leather goods mfr' },
  { Id: 12326, Code: '5413', Name: 'Machinist, bagging' },
  { Id: 12327, Code: '5413', Name: 'Machinist, beading' },
  { Id: 12328, Code: '5413', Name: 'Machinist, boot' },
  { Id: 12329, Code: '5413', Name: 'Machinist, brush' },
  { Id: 12330, Code: '5413', Name: 'Machinist, closing, footwear mfr' },
  { Id: 12331, Code: '5413', Name: 'Machinist, flat, footwear mfr' },
  { Id: 12332, Code: '5413', Name: 'Machinist, footwear mfr' },
  { Id: 12333, Code: '5413', Name: 'Machinist, lasting' },
  { Id: 12334, Code: '5413', Name: 'Machinist, leather' },
  { Id: 12335, Code: '5413', Name: 'Machinist, leather goods mfr' },
  { Id: 12336, Code: '5413', Name: 'Machinist, levelling' },
  { Id: 12337, Code: '5413', Name: 'Machinist, lining, footwear mfr' },
  { Id: 12338, Code: '5413', Name: 'Machinist, perforating, footwear mfr' },
  { Id: 12339, Code: '5413', Name: 'Machinist, post, footwear mfr' },
  { Id: 12340, Code: '5413', Name: 'Machinist, pressure' },
  { Id: 12341, Code: '5413', Name: 'Machinist, riveting, leather goods mfr' },
  { Id: 12342, Code: '5413', Name: 'Machinist, shoe' },
  { Id: 12343, Code: '5413', Name: 'Machinist, skiver' },
  { Id: 12344, Code: '5413', Name: 'Machinist, skiving, footwear mfr' },
  { Id: 12345, Code: '5413', Name: 'Machinist, slipper' },
  { Id: 12346, Code: '5413', Name: 'Machinist, stapling, footwear mfr' },
  { Id: 12347, Code: '5413', Name: 'Machinist, taping, footwear mfr' },
  { Id: 12348, Code: '5413', Name: 'Machinist, welt' },
  { Id: 12349, Code: '5413', Name: 'Machinist, zigzag, footwear mfr' },
  { Id: 12350, Code: '5413', Name: 'Maker and repairer, shoe' },
  { Id: 12351, Code: '5413', Name: 'Maker, bag, hand' },
  { Id: 12352, Code: '5413', Name: "Maker, bag, lady's" },
  { Id: 12353, Code: '5413', Name: 'Maker, bag, leather goods mfr' },
  { Id: 12354, Code: '5413', Name: 'Maker, bag, travelling' },
  { Id: 12355, Code: '5413', Name: 'Maker, ball, cricket' },
  { Id: 12356, Code: '5413', Name: 'Maker, ball, foot' },
  { Id: 12357, Code: '5413', Name: 'Maker, bellows, PIPE ORGAN' },
  { Id: 12358, Code: '5413', Name: 'Maker, belt, LEATHER' },
  { Id: 12359, Code: '5413', Name: 'Maker, belting, LEATHER' },
  { Id: 12360, Code: '5413', Name: 'Maker, boot' },
  { Id: 12361, Code: '5413', Name: 'Maker, boot and shoe' },
  { Id: 12362, Code: '5413', Name: 'Maker, boot, surgical' },
  { Id: 12363, Code: '5413', Name: 'Maker, braces' },
  { Id: 12364, Code: '5413', Name: 'Maker, bridle' },
  { Id: 12365, Code: '5413', Name: 'Maker, buffer' },
  { Id: 12366, Code: '5413', Name: 'Maker, case, attache' },
  { Id: 12367, Code: '5413', Name: 'Maker, case, brush' },
  { Id: 12368, Code: '5413', Name: 'Maker, case, dressing' },
  { Id: 12369, Code: '5413', Name: 'Maker, case, hat' },
  { Id: 12370, Code: '5413', Name: 'Maker, case, leather' },
  { Id: 12371, Code: '5413', Name: 'Maker, case, small' },
  { Id: 12372, Code: '5413', Name: 'Maker, case, suit' },
  { Id: 12373, Code: '5413', Name: 'Maker, clog' },
  { Id: 12374, Code: '5413', Name: 'Maker, collar, horse' },
  { Id: 12375, Code: '5413', Name: 'Maker, football' },
  { Id: 12376, Code: '5413', Name: 'Maker, footwear' },
  { Id: 12377, Code: '5413', Name: 'Maker, frame, photo, LEATHER' },
  { Id: 12378, Code: '5413', Name: 'Maker, glove, BOXING' },
  { Id: 12379, Code: '5413', Name: 'Maker, glove, CRICKET' },
  { Id: 12380, Code: '5413', Name: 'Maker, goods, leather' },
  { Id: 12381, Code: '5413', Name: 'Maker, handle, leather goods mfr' },
  { Id: 12382, Code: '5413', Name: 'Maker, harness' },
  { Id: 12383, Code: '5413', Name: 'Maker, heel' },
  { Id: 12384, Code: '5413', Name: 'Maker, hose, leather' },
  { Id: 12385, Code: '5413', Name: 'Maker, lace, boot, leather goods mfr' },
  { Id: 12386, Code: '5413', Name: 'Maker, leather, fancy' },
  { Id: 12387, Code: '5413', Name: 'Maker, lining, footwear mfr' },
  { Id: 12388, Code: '5413', Name: 'Maker, pattern, footwear mfr' },
  { Id: 12389, Code: '5413', Name: 'Maker, picker' },
  { Id: 12390, Code: '5413', Name: 'Maker, pouffe' },
  { Id: 12391, Code: '5413', Name: 'Maker, purse, LEATHER' },
  { Id: 12392, Code: '5413', Name: 'Maker, rosette, LEATHER' },
  { Id: 12393, Code: '5413', Name: 'Maker, saddle' },
  { Id: 12394, Code: '5413', Name: 'Maker, scabbard' },
  { Id: 12395, Code: '5413', Name: 'Maker, shoe' },
  { Id: 12396, Code: '5413', Name: 'Maker, slipper' },
  { Id: 12397, Code: '5413', Name: 'Maker, sock, BOOTS AND SHOES' },
  { Id: 12398, Code: '5413', Name: 'Maker, strap' },
  { Id: 12399, Code: '5413', Name: 'Maker, trimming, slipper mfr' },
  { Id: 12400, Code: '5413', Name: 'Maker, trimmings, slipper mfr' },
  { Id: 12401, Code: '5413', Name: 'Maker, trunk, LEATHER' },
  { Id: 12402, Code: '5413', Name: 'Maker, washer, LEATHER' },
  { Id: 12403, Code: '5413', Name: 'Maker, welt' },
  { Id: 12404, Code: '5413', Name: 'Maker, whip' },
  { Id: 12405, Code: '5413', Name: 'Maker-up, leather goods mfr' },
  { Id: 12406, Code: '5413', Name: 'Man, bench, footwear mfr' },
  {
    Id: 12407,
    Code: '5413',
    Name: 'Man, lathe, textile machinery roller covering'
  },
  { Id: 12408, Code: '5413', Name: 'Man, maintenance, BELT' },
  { Id: 12409, Code: '5413', Name: 'Man, strap' },
  { Id: 12410, Code: '5413', Name: 'Man, table, footwear mfr' },
  { Id: 12411, Code: '5413', Name: 'Manufacturer, bag, leather goods mfr' },
  { Id: 12412, Code: '5413', Name: 'Manufacturer, footwear mfr' },
  { Id: 12413, Code: '5413', Name: 'Manufacturer, goods, leather' },
  { Id: 12414, Code: '5413', Name: 'Manufacturer, leather goods mfr' },
  { Id: 12415, Code: '5413', Name: 'Manufacturer, shoe' },
  { Id: 12416, Code: '5413', Name: 'Marker, footwear mfr' },
  { Id: 12417, Code: '5413', Name: 'Marker, stitch, footwear mfr' },
  { Id: 12418, Code: '5413', Name: 'Marker, strip' },
  { Id: 12419, Code: '5413', Name: 'Marker, upper, footwear mfr' },
  { Id: 12420, Code: '5413', Name: 'Mender, belt' },
  { Id: 12421, Code: '5413', Name: 'Mender, shoe' },
  { Id: 12422, Code: '5413', Name: 'Mender, strap' },
  { Id: 12423, Code: '5413', Name: 'Moulder, footwear mfr' },
  { Id: 12424, Code: '5413', Name: 'Moulder, injection, footwear mfr' },
  { Id: 12425, Code: '5413', Name: 'Moulder, insole' },
  { Id: 12426, Code: '5413', Name: 'Moulder, leather' },
  { Id: 12427, Code: '5413', Name: 'Moulder, sole' },
  { Id: 12428, Code: '5413', Name: 'Moulder, stiffener' },
  { Id: 12429, Code: '5413', Name: 'Nailer, footwear mfr' },
  { Id: 12430, Code: '5413', Name: 'Operative, boot and shoe' },
  { Id: 12431, Code: '5413', Name: 'Operative, manufacturing, footwear' },
  { Id: 12432, Code: '5413', Name: 'Operative, shoe' },
  { Id: 12433, Code: '5413', Name: 'Operative, slipper' },
  { Id: 12434, Code: '5413', Name: 'Operator, bar, heel' },
  { Id: 12435, Code: '5413', Name: 'Operator, boot' },
  { Id: 12436, Code: '5413', Name: 'Operator, consol, footwear mfr' },
  { Id: 12437, Code: '5413', Name: 'Operator, console, footwear mfr' },
  { Id: 12438, Code: '5413', Name: 'Operator, last, seat' },
  { Id: 12439, Code: '5413', Name: 'Operator, pullover, footwear mfr' },
  { Id: 12440, Code: '5413', Name: 'Operator, room, closing, footwear mfr' },
  { Id: 12441, Code: '5413', Name: 'Operator, shoe' },
  { Id: 12442, Code: '5413', Name: 'Parer, footwear mfr' },
  { Id: 12443, Code: '5413', Name: 'Paster, footwear mfr' },
  { Id: 12444, Code: '5413', Name: 'Paster, leather goods mfr' },
  { Id: 12445, Code: '5413', Name: 'Paster, sock' },
  { Id: 12446, Code: '5413', Name: 'Pegger, footwear mfr' },
  { Id: 12447, Code: '5413', Name: 'Perforator, footwear mfr' },
  { Id: 12448, Code: '5413', Name: 'Piecer, belt' },
  { Id: 12449, Code: '5413', Name: 'Piercer, strap' },
  { Id: 12450, Code: '5413', Name: 'Planner, die, footwear mfr' },
  { Id: 12451, Code: '5413', Name: 'Polisher, boot' },
  { Id: 12452, Code: '5413', Name: 'Polisher, clog mfr' },
  { Id: 12453, Code: '5413', Name: 'Polisher, footwear mfr' },
  { Id: 12454, Code: '5413', Name: 'Pounder' },
  { Id: 12455, Code: '5413', Name: 'Preparer, case' },
  { Id: 12456, Code: '5413', Name: 'Preparer, footwear mfr' },
  { Id: 12457, Code: '5413', Name: 'Preparer, leather goods mfr' },
  { Id: 12458, Code: '5413', Name: 'Presser, blanking' },
  { Id: 12459, Code: '5413', Name: 'Presser, clicking' },
  { Id: 12460, Code: '5413', Name: 'Presser, footwear mfr' },
  { Id: 12461, Code: '5413', Name: 'Presser, heel, footwear mfr' },
  { Id: 12462, Code: '5413', Name: 'Presser, leather goods mfr' },
  { Id: 12463, Code: '5413', Name: 'Presser, lining, footwear mfr' },
  { Id: 12464, Code: '5413', Name: 'Presser, shoe' },
  { Id: 12465, Code: '5413', Name: 'Pricker, leather goods mfr' },
  { Id: 12466, Code: '5413', Name: 'Pricker-up' },
  { Id: 12467, Code: '5413', Name: 'Puffer, footwear mfr' },
  { Id: 12468, Code: '5413', Name: 'Puller, tack' },
  { Id: 12469, Code: '5413', Name: 'Puller-on, footwear mfr' },
  { Id: 12470, Code: '5413', Name: 'Puller-over, footwear mfr' },
  { Id: 12471, Code: '5413', Name: 'Puncher, footwear mfr' },
  { Id: 12472, Code: '5413', Name: 'Putter-on, clog mfr' },
  { Id: 12473, Code: '5413', Name: 'Ranger, footwear mfr' },
  { Id: 12474, Code: '5413', Name: 'Rectifier, footwear mfr' },
  { Id: 12475, Code: '5413', Name: 'Re-laster' },
  { Id: 12476, Code: '5413', Name: 'Repairer, bag, HAND BAGS' },
  { Id: 12477, Code: '5413', Name: 'Repairer, belt' },
  { Id: 12478, Code: '5413', Name: 'Repairer, belting' },
  { Id: 12479, Code: '5413', Name: 'Repairer, boot and shoe' },
  { Id: 12480, Code: '5413', Name: 'Repairer, FOOTWEAR' },
  { Id: 12481, Code: '5413', Name: 'Repairer, LEATHER GOODS' },
  { Id: 12482, Code: '5413', Name: 'Repairer, shoe' },
  { Id: 12483, Code: '5413', Name: 'Riveter, FOOTWEAR' },
  { Id: 12484, Code: '5413', Name: 'Riveter, LEATHER GOODS' },
  { Id: 12485, Code: '5413', Name: 'Rougher, outsole' },
  { Id: 12486, Code: '5413', Name: 'Rougher, upper, footwear mfr' },
  { Id: 12487, Code: '5413', Name: 'Rounder, footwear mfr' },
  { Id: 12488, Code: '5413', Name: 'Rubber-down, footwear mfr' },
  { Id: 12489, Code: '5413', Name: 'Rubber-off, footwear mfr' },
  { Id: 12490, Code: '5413', Name: 'Saddler' },
  { Id: 12491, Code: '5413', Name: 'Scourer, footwear mfr' },
  { Id: 12492, Code: '5413', Name: 'Scraper, heel, footwear mfr' },
  { Id: 12493, Code: '5413', Name: 'Separator, stitch' },
  { Id: 12494, Code: '5413', Name: 'Sewer, belting' },
  { Id: 12495, Code: '5413', Name: 'Sewer, footwear mfr' },
  { Id: 12496, Code: '5413', Name: 'Sewer, leather goods mfr' },
  { Id: 12497, Code: '5413', Name: 'Sewer, rubber footwear mfr' },
  { Id: 12498, Code: '5413', Name: 'Sewer, rug, skin' },
  { Id: 12499, Code: '5413', Name: 'Sewer, sole' },
  { Id: 12500, Code: '5413', Name: 'Sewer, welt' },
  { Id: 12501, Code: '5413', Name: 'Sewer, wire' },
  { Id: 12502, Code: '5413', Name: 'Shanker, footwear mfr' },
  { Id: 12503, Code: '5413', Name: 'Sinker, seat' },
  { Id: 12504, Code: '5413', Name: 'Skiver, footwear mfr' },
  { Id: 12505, Code: '5413', Name: 'Slipper, last' },
  { Id: 12506, Code: '5413', Name: 'Slipper, shoe' },
  { Id: 12507, Code: '5413', Name: 'Slugger, footwear mfr' },
  { Id: 12508, Code: '5413', Name: 'Socker' },
  { Id: 12509, Code: '5413', Name: 'Solutioner, footwear mfr' },
  { Id: 12510, Code: '5413', Name: 'Spotter, footwear mfr' },
  { Id: 12511, Code: '5413', Name: 'Squarer-up' },
  { Id: 12512, Code: '5413', Name: 'Stainer, boot' },
  { Id: 12513, Code: '5413', Name: 'Stainer, edge, footwear mfr' },
  { Id: 12514, Code: '5413', Name: 'Stainer, leather' },
  { Id: 12515, Code: '5413', Name: 'Stainer, leather goods mfr' },
  { Id: 12516, Code: '5413', Name: 'Stainer, shoe' },
  { Id: 12517, Code: '5413', Name: 'Stapler, footwear mfr' },
  { Id: 12518, Code: '5413', Name: 'Stapler, leather goods mfr' },
  { Id: 12519, Code: '5413', Name: 'Stapler, slipper' },
  { Id: 12520, Code: '5413', Name: 'Sticker, footwear mfr' },
  { Id: 12521, Code: '5413', Name: 'Sticker, sole' },
  { Id: 12522, Code: '5413', Name: 'Stiffener, footwear mfr' },
  { Id: 12523, Code: '5413', Name: 'Stitcher, ball, cricket' },
  { Id: 12524, Code: '5413', Name: 'Stitcher, collar' },
  { Id: 12525, Code: '5413', Name: 'Stitcher, football' },
  { Id: 12526, Code: '5413', Name: 'Stitcher, footwear mfr' },
  { Id: 12527, Code: '5413', Name: 'Stitcher, glove, boxing' },
  { Id: 12528, Code: '5413', Name: 'Stitcher, leather' },
  { Id: 12529, Code: '5413', Name: 'Stitcher, leather goods mfr' },
  { Id: 12530, Code: '5413', Name: 'Stitcher, lock, slipper mfr' },
  { Id: 12531, Code: '5413', Name: 'Stitcher, rapid' },
  { Id: 12532, Code: '5413', Name: 'Stitcher, wire, leather goods mfr' },
  { Id: 12533, Code: '5413', Name: 'Stocker, whip' },
  { Id: 12534, Code: '5413', Name: 'Stringer, footwear mfr' },
  { Id: 12535, Code: '5413', Name: 'Stripper, lace, leather' },
  { Id: 12536, Code: '5413', Name: 'Tacker, footwear mfr' },
  { Id: 12537, Code: '5413', Name: 'Taper, footwear mfr' },
  { Id: 12538, Code: '5413', Name: 'Tapper, footwear mfr' },
  { Id: 12539, Code: '5413', Name: 'Tracer, footwear mfr' },
  { Id: 12540, Code: '5413', Name: 'Treer' },
  { Id: 12541, Code: '5413', Name: 'Trimmer, boot' },
  { Id: 12542, Code: '5413', Name: 'Trimmer, footwear mfr' },
  { Id: 12543, Code: '5413', Name: 'Trimmer, heel' },
  { Id: 12544, Code: '5413', Name: 'Trimmer, leather goods mfr' },
  { Id: 12545, Code: '5413', Name: 'Turner, footwear mfr' },
  { Id: 12546, Code: '5413', Name: 'Turner, slipper' },
  { Id: 12547, Code: '5413', Name: 'Vamper' },
  { Id: 12548, Code: '5413', Name: 'Welder, footwear mfr' },
  { Id: 12549, Code: '5413', Name: 'Welter, footwear mfr' },
  { Id: 12550, Code: '5413', Name: 'Wetter, footwear mfr' },
  { Id: 12551, Code: '5413', Name: 'Wheeler, footwear mfr' },
  { Id: 12552, Code: '5413', Name: 'Wheeler, welt' },
  { Id: 12553, Code: '5413', Name: 'Whipper, footwear mfr' },
  { Id: 12554, Code: '5413', Name: 'Worker, bench, footwear mfr' },
  { Id: 12555, Code: '5413', Name: 'Worker, bench, leather goods mfr' },
  { Id: 12556, Code: '5413', Name: 'Worker, boot and shoe' },
  { Id: 12557, Code: '5413', Name: 'Worker, craft, leather' },
  { Id: 12558, Code: '5413', Name: 'Worker, factory, footwear mfr' },
  { Id: 12559, Code: '5413', Name: 'Worker, goods, leather' },
  { Id: 12560, Code: '5413', Name: 'Worker, leather, artificial limb mfr' },
  { Id: 12561, Code: '5413', Name: 'Worker, leather, fancy' },
  {
    Id: 12562,
    Code: '5413',
    Name: 'Worker, leather, hydraulic and mechanical'
  },
  { Id: 12563, Code: '5413', Name: 'Worker, leather, leather goods mfr' },
  { Id: 12564, Code: '5413', Name: 'Worker, leather, orthopaedic' },
  { Id: 12565, Code: '5413', Name: 'Worker, leather, railways' },
  { Id: 12566, Code: '5413', Name: 'Worker, leather, vehicle mfr' },
  { Id: 12567, Code: '5413', Name: 'Worker, room, shoe' },
  { Id: 12568, Code: '5413', Name: 'Worker, saddle' },
  { Id: 12569, Code: '5413', Name: 'Worker, shoe' },
  { Id: 12570, Code: '5413', Name: 'Worker, shoeroom' },
  { Id: 12571, Code: '5413', Name: 'Worker, slipper' },
  { Id: 12572, Code: '5414', Name: 'Assistant, alteration' },
  { Id: 12573, Code: '5414', Name: "Assistant, cutter's, clothing mfr" },
  { Id: 12574, Code: '5414', Name: "Assistant, milliner's" },
  { Id: 12575, Code: '5414', Name: 'Assistant, room, cutting, clothing mfr' },
  { Id: 12576, Code: '5414', Name: 'Assistant, tailoring' },
  { Id: 12577, Code: '5414', Name: "Assistant, tailor's" },
  { Id: 12578, Code: '5414', Name: 'Baister' },
  { Id: 12579, Code: '5414', Name: 'Baster' },
  { Id: 12580, Code: '5414', Name: 'Blocker, fur' },
  { Id: 12581, Code: '5414', Name: "Copyist, milliner's" },
  { Id: 12582, Code: '5414', Name: 'Copyist, millinery mfr' },
  { Id: 12583, Code: '5414', Name: 'Costumier' },
  { Id: 12584, Code: '5414', Name: 'Cutter and fitter' },
  { Id: 12585, Code: '5414', Name: 'Cutter, alteration' },
  { Id: 12586, Code: '5414', Name: 'Cutter, blouse' },
  { Id: 12587, Code: '5414', Name: 'Cutter, cap' },
  { Id: 12588, Code: '5414', Name: 'Cutter, cloth, clothing mfr' },
  { Id: 12589, Code: '5414', Name: 'Cutter, cloth, made-up textiles mfr' },
  { Id: 12590, Code: '5414', Name: "Cutter, clothier's" },
  { Id: 12591, Code: '5414', Name: 'Cutter, clothing' },
  { Id: 12592, Code: '5414', Name: 'Cutter, clothing mfr' },
  { Id: 12593, Code: '5414', Name: 'Cutter, collar' },
  { Id: 12594, Code: '5414', Name: 'Cutter, costume' },
  { Id: 12595, Code: '5414', Name: 'Cutter, embroidering' },
  { Id: 12596, Code: '5414', Name: 'Cutter, fabric' },
  { Id: 12597, Code: '5414', Name: 'Cutter, fur' },
  { Id: 12598, Code: '5414', Name: 'Cutter, garment' },
  { Id: 12599, Code: '5414', Name: 'Cutter, glove' },
  { Id: 12600, Code: '5414', Name: 'Cutter, glove mfr' },
  { Id: 12601, Code: '5414', Name: 'Cutter, haberdashery mfr' },
  { Id: 12602, Code: '5414', Name: 'Cutter, hand, clothing mfr' },
  { Id: 12603, Code: '5414', Name: 'Cutter, hat' },
  { Id: 12604, Code: '5414', Name: 'Cutter, hat mfr' },
  { Id: 12605, Code: '5414', Name: 'Cutter, hosiery' },
  { Id: 12606, Code: '5414', Name: 'Cutter, hosiery, knitwear mfr' },
  { Id: 12607, Code: '5414', Name: 'Cutter, knife, band' },
  { Id: 12608, Code: '5414', Name: 'Cutter, knife, hand' },
  { Id: 12609, Code: '5414', Name: 'Cutter, knife, leather glove mfr' },
  { Id: 12610, Code: '5414', Name: 'Cutter, knife, machine' },
  { Id: 12611, Code: '5414', Name: 'Cutter, leather, clothing mfr' },
  { Id: 12612, Code: '5414', Name: 'Cutter, linen, button mfr' },
  { Id: 12613, Code: '5414', Name: 'Cutter, lingerie' },
  { Id: 12614, Code: '5414', Name: 'Cutter, lining, clothing mfr' },
  { Id: 12615, Code: '5414', Name: 'Cutter, machine, clothing mfr' },
  { Id: 12616, Code: '5414', Name: 'Cutter, mantle' },
  { Id: 12617, Code: '5414', Name: 'Cutter, material' },
  { Id: 12618, Code: '5414', Name: 'Cutter, measure' },
  { Id: 12619, Code: '5414', Name: 'Cutter, pattern, clothing' },
  { Id: 12620, Code: '5414', Name: 'Cutter, pattern, clothing mfr' },
  { Id: 12621, Code: '5414', Name: 'Cutter, press, made-up textiles mfr' },
  { Id: 12622, Code: '5414', Name: 'Cutter, shirt' },
  { Id: 12623, Code: '5414', Name: 'Cutter, skin, clothing mfr' },
  { Id: 12624, Code: '5414', Name: 'Cutter, stock' },
  { Id: 12625, Code: '5414', Name: 'Cutter, table, glove mfr' },
  { Id: 12626, Code: '5414', Name: "Cutter, tailor's" },
  { Id: 12627, Code: '5414', Name: 'Cutter, tie' },
  { Id: 12628, Code: '5414', Name: 'Cutter, trimming' },
  { Id: 12629, Code: '5414', Name: 'Cutter, under' },
  { Id: 12630, Code: '5414', Name: 'Dressmaker' },
  { Id: 12631, Code: '5414', Name: 'Fitter and trimmer' },
  { Id: 12632, Code: '5414', Name: 'Fitter, alteration' },
  { Id: 12633, Code: '5414', Name: 'Fitter, clothing mfr' },
  { Id: 12634, Code: '5414', Name: 'Fitter, clothing, retail trade' },
  { Id: 12635, Code: '5414', Name: 'Fitter, coat' },
  { Id: 12636, Code: '5414', Name: 'Fitter, dress' },
  { Id: 12637, Code: '5414', Name: "Fitter, tailor's" },
  { Id: 12638, Code: '5414', Name: 'Fitter-up, clothing mfr' },
  { Id: 12639, Code: '5414', Name: 'Fixer, clothing mfr' },
  { Id: 12640, Code: '5414', Name: 'Fixer, trimmer' },
  { Id: 12641, Code: '5414', Name: 'Foreman, tailoring' },
  { Id: 12642, Code: '5414', Name: 'Furrier, fur goods mfr' },
  { Id: 12643, Code: '5414', Name: 'Hand, alteration' },
  { Id: 12644, Code: '5414', Name: 'Hand, first, clothing mfr' },
  { Id: 12645, Code: '5414', Name: 'Hand, general, dressmaking' },
  { Id: 12646, Code: '5414', Name: 'Hand, second, clothing mfr' },
  { Id: 12647, Code: '5414', Name: 'Improver, cutter' },
  { Id: 12648, Code: '5414', Name: 'Improver, millinery' },
  { Id: 12649, Code: '5414', Name: 'Layer-up, clothing mfr' },
  { Id: 12650, Code: '5414', Name: 'Machinist, cutting, clothing mfr' },
  { Id: 12651, Code: '5414', Name: 'Maker, blouse' },
  { Id: 12652, Code: '5414', Name: 'Maker, cap, clothing mfr' },
  { Id: 12653, Code: '5414', Name: 'Maker, coat' },
  { Id: 12654, Code: '5414', Name: 'Maker, costume' },
  { Id: 12655, Code: '5414', Name: 'Maker, dress' },
  { Id: 12656, Code: '5414', Name: 'Maker, garment' },
  { Id: 12657, Code: '5414', Name: 'Maker, kilt' },
  { Id: 12658, Code: '5414', Name: 'Maker, mantle, fur' },
  { Id: 12659, Code: '5414', Name: 'Maker, pattern, clothing mfr' },
  { Id: 12660, Code: '5414', Name: 'Maker, shirt' },
  { Id: 12661, Code: '5414', Name: 'Maker, skirt' },
  { Id: 12662, Code: '5414', Name: 'Maker, trouser' },
  { Id: 12663, Code: '5414', Name: 'Maker, waistcoat' },
  { Id: 12664, Code: '5414', Name: 'Marker, clothing mfr' },
  { Id: 12665, Code: '5414', Name: 'Marker-up, clothing mfr' },
  { Id: 12666, Code: '5414', Name: 'Measurer, clothing mfr' },
  { Id: 12667, Code: '5414', Name: 'Milliner' },
  { Id: 12668, Code: '5414', Name: 'Nailer, fur goods mfr' },
  { Id: 12669, Code: '5414', Name: 'Picker, clothing mfr' },
  { Id: 12670, Code: '5414', Name: 'Pleater, clothing mfr' },
  { Id: 12671, Code: '5414', Name: 'Preparer, clothing mfr' },
  { Id: 12672, Code: '5414', Name: 'Puncher, glove mfr' },
  { Id: 12673, Code: '5414', Name: 'Shaper, clothing mfr' },
  { Id: 12674, Code: '5414', Name: 'Shaper, collar' },
  { Id: 12675, Code: '5414', Name: 'Tacker, tailoring' },
  { Id: 12676, Code: '5414', Name: 'Tailor' },
  { Id: 12677, Code: '5414', Name: 'Tailor and outfitter' },
  { Id: 12678, Code: '5414', Name: 'Tailoress' },
  { Id: 12679, Code: '5414', Name: 'Webber' },
  { Id: 12680, Code: '5414', Name: 'Worker, glove' },
  { Id: 12681, Code: '5414', Name: 'Worker, hat' },
  { Id: 12682, Code: '5419', Name: 'Assistant, dressmaking' },
  { Id: 12683, Code: '5419', Name: "Assistant, maker's, dress" },
  { Id: 12684, Code: '5419', Name: "Assistant, trimmer's, upholstering" },
  { Id: 12685, Code: '5419', Name: 'Binder, chair' },
  { Id: 12686, Code: '5419', Name: 'Binder, umbrella' },
  { Id: 12687, Code: '5419', Name: 'Blocker, hat' },
  { Id: 12688, Code: '5419', Name: 'Blocker, hat mfr' },
  { Id: 12689, Code: '5419', Name: 'Bonder, garment, rainproof' },
  { Id: 12690, Code: '5419', Name: 'Braider, VEHICLE BUILDING' },
  { Id: 12691, Code: '5419', Name: 'Buttoner, clothing mfr' },
  { Id: 12692, Code: '5419', Name: 'Cleaner, clothing mfr' },
  { Id: 12693, Code: '5419', Name: 'Cleaner, hat' },
  { Id: 12694, Code: '5419', Name: 'Clearer, embroidering' },
  { Id: 12695, Code: '5419', Name: 'Coner, felt hood mfr' },
  { Id: 12696, Code: '5419', Name: 'Conservationist, tapestry' },
  { Id: 12697, Code: '5419', Name: 'Conservationist, textile' },
  { Id: 12698, Code: '5419', Name: 'Consultant, corsetry' },
  { Id: 12699, Code: '5419', Name: 'Corder, upholstering' },
  { Id: 12700, Code: '5419', Name: 'Corsetiere' },
  { Id: 12701, Code: '5419', Name: 'Coverer, coat hanger mfr' },
  { Id: 12702, Code: '5419', Name: 'Curler, hat mfr' },
  { Id: 12703, Code: '5419', Name: 'Cutter, artificial flower mfr' },
  {
    Id: 12704,
    Code: '5419',
    Name: 'Cutter, asbestos, mattress, upholstery mfr'
  },
  { Id: 12705, Code: '5419', Name: 'Cutter, bag, CANVAS' },
  { Id: 12706, Code: '5419', Name: 'Cutter, bandage' },
  { Id: 12707, Code: '5419', Name: 'Cutter, bias' },
  { Id: 12708, Code: '5419', Name: 'Cutter, canvas goods mfr' },
  { Id: 12709, Code: '5419', Name: 'Cutter, cloth, umbrella' },
  { Id: 12710, Code: '5419', Name: 'Cutter, coach trimming' },
  { Id: 12711, Code: '5419', Name: 'Cutter, corset' },
  { Id: 12712, Code: '5419', Name: 'Cutter, design, clothing mfr' },
  { Id: 12713, Code: '5419', Name: 'Cutter, designer' },
  { Id: 12714, Code: '5419', Name: 'Cutter, felt, textile mfr' },
  { Id: 12715, Code: '5419', Name: 'Cutter, flag' },
  { Id: 12716, Code: '5419', Name: 'Cutter, laces' },
  { Id: 12717, Code: '5419', Name: 'Cutter, pattern, fur goods mfr' },
  { Id: 12718, Code: '5419', Name: 'Cutter, pattern, textile mfr' },
  { Id: 12719, Code: '5419', Name: 'Cutter, press, textile mfr' },
  { Id: 12720, Code: '5419', Name: 'Cutter, rib, hosiery, knitwear mfr' },
  { Id: 12721, Code: '5419', Name: 'Cutter, ribbon, TYPEWRITER RIBBONS' },
  { Id: 12722, Code: '5419', Name: 'Cutter, roll' },
  { Id: 12723, Code: '5419', Name: 'Cutter, sack' },
  { Id: 12724, Code: '5419', Name: 'Cutter, soft toy mfr' },
  { Id: 12725, Code: '5419', Name: 'Cutter, thread' },
  { Id: 12726, Code: '5419', Name: 'Cutter, upholstering' },
  { Id: 12727, Code: '5419', Name: 'Cutter, upholstery' },
  { Id: 12728, Code: '5419', Name: 'Darner, hotels, catering, public houses' },
  { Id: 12729, Code: '5419', Name: 'Darner, textile mfr' },
  { Id: 12730, Code: '5419', Name: 'Designer-cutter, clothing mfr' },
  { Id: 12731, Code: '5419', Name: 'Drawthreader' },
  { Id: 12732, Code: '5419', Name: 'Embroiderer' },
  { Id: 12733, Code: '5419', Name: 'Eyeletter, clothing mfr' },
  { Id: 12734, Code: '5419', Name: 'Filler, cushion' },
  { Id: 12735, Code: '5419', Name: 'Filler, hand, upholstery mfr' },
  { Id: 12736, Code: '5419', Name: 'Finisher, canvas goods mfr' },
  { Id: 12737, Code: '5419', Name: 'Finisher, hand, clothing mfr' },
  { Id: 12738, Code: '5419', Name: 'Finisher, hand, felt hat mfr' },
  { Id: 12739, Code: '5419', Name: 'Finisher, hand, knitted goods mfr' },
  { Id: 12740, Code: '5419', Name: 'Finisher, hat' },
  { Id: 12741, Code: '5419', Name: 'Finisher, hat mfr' },
  { Id: 12742, Code: '5419', Name: 'Finisher, tent' },
  { Id: 12743, Code: '5419', Name: 'Fitter, corset, surgical' },
  { Id: 12744, Code: '5419', Name: 'Fitter, corsetry' },
  { Id: 12745, Code: '5419', Name: 'Flanger, hat mfr' },
  { Id: 12746, Code: '5419', Name: 'Foreman, canvas goods mfr' },
  { Id: 12747, Code: '5419', Name: 'Foreman, clothing mfr' },
  { Id: 12748, Code: '5419', Name: 'Framer, calico' },
  { Id: 12749, Code: '5419', Name: 'Grader, paper pattern mfr' },
  { Id: 12750, Code: '5419', Name: 'Grader, pattern, clothing' },
  { Id: 12751, Code: '5419', Name: 'Grader, pattern, clothing mfr' },
  { Id: 12752, Code: '5419', Name: 'Hand, bag' },
  { Id: 12753, Code: '5419', Name: 'Hand, front, clothing mfr' },
  { Id: 12754, Code: '5419', Name: 'Hand, pattern, lace mfr' },
  { Id: 12755, Code: '5419', Name: 'Hand, stripping, hosiery, knitwear mfr' },
  { Id: 12756, Code: '5419', Name: 'Hatter, hat mfr' },
  { Id: 12757, Code: '5419', Name: 'Layer-down, textile finishing' },
  { Id: 12758, Code: '5419', Name: 'Layer-out, glove mfr' },
  { Id: 12759, Code: '5419', Name: 'Lurer' },
  { Id: 12760, Code: '5419', Name: 'Machinist, blocking' },
  { Id: 12761, Code: '5419', Name: 'Machinist, eyelet-hole' },
  { Id: 12762, Code: '5419', Name: 'Machinist, guillotine, textile mfr' },
  { Id: 12763, Code: '5419', Name: 'Machinist, sanitary towel mfr' },
  { Id: 12764, Code: '5419', Name: 'Machinist, slitting and winding' },
  { Id: 12765, Code: '5419', Name: 'Machinist, slitting, fabric mfr' },
  { Id: 12766, Code: '5419', Name: 'Machinist, stitching, wire' },
  {
    Id: 12767,
    Code: '5419',
    Name: 'Machinist, tufting, mattress, upholstery mfr'
  },
  { Id: 12768, Code: '5419', Name: 'Maker, bag, jute' },
  { Id: 12769, Code: '5419', Name: 'Maker, bag, nail' },
  { Id: 12770, Code: '5419', Name: 'Maker, bandage' },
  { Id: 12771, Code: '5419', Name: 'Maker, body, hat mfr' },
  { Id: 12772, Code: '5419', Name: 'Maker, colour, flag mfr' },
  { Id: 12773, Code: '5419', Name: 'Maker, corset' },
  { Id: 12774, Code: '5419', Name: 'Maker, hat' },
  { Id: 12775, Code: '5419', Name: 'Maker, helmet' },
  { Id: 12776, Code: '5419', Name: 'Maker, marker' },
  { Id: 12777, Code: '5419', Name: 'Maker, mount, wig' },
  { Id: 12778, Code: '5419', Name: 'Maker, pattern, artificial flower mfr' },
  { Id: 12779, Code: '5419', Name: 'Maker, pattern, textile mfr' },
  { Id: 12780, Code: '5419', Name: 'Maker, puff, powder' },
  { Id: 12781, Code: '5419', Name: 'Maker, sail' },
  { Id: 12782, Code: '5419', Name: 'Maker, sample, textile mfr' },
  { Id: 12783, Code: '5419', Name: 'Maker, tent' },
  { Id: 12784, Code: '5419', Name: 'Maker, towel' },
  { Id: 12785, Code: '5419', Name: 'Maker, umbrella' },
  { Id: 12786, Code: '5419', Name: 'Maker, waterproof' },
  { Id: 12787, Code: '5419', Name: 'Maker-up, needlework kit mfr' },
  { Id: 12788, Code: '5419', Name: 'Man, table, clothing mfr' },
  { Id: 12789, Code: '5419', Name: 'Manufacturer, canvas goods mfr' },
  { Id: 12790, Code: '5419', Name: 'Manufacturer, clothing' },
  { Id: 12791, Code: '5419', Name: 'Manufacturer, clothing mfr' },
  { Id: 12792, Code: '5419', Name: 'Manufacturer, garment' },
  { Id: 12793, Code: '5419', Name: 'Manufacturer, goods, canvas' },
  { Id: 12794, Code: '5419', Name: 'Manufacturer, hat' },
  { Id: 12795, Code: '5419', Name: 'Manufacturer, hat mfr' },
  { Id: 12796, Code: '5419', Name: 'Manufacturer, umbrella, parasol mfr' },
  { Id: 12797, Code: '5419', Name: 'Marker, button' },
  { Id: 12798, Code: '5419', Name: 'Marker, buttonhole' },
  { Id: 12799, Code: '5419', Name: 'Marker, embroidery mfr' },
  { Id: 12800, Code: '5419', Name: 'Marker, hosiery, knitwear mfr' },
  { Id: 12801, Code: '5419', Name: 'Marker, lining' },
  { Id: 12802, Code: '5419', Name: 'Marker, pattern, DOWN QUILT' },
  { Id: 12803, Code: '5419', Name: 'Marker, piece, textile mfr' },
  { Id: 12804, Code: '5419', Name: 'Marker, textile mfr' },
  { Id: 12805, Code: '5419', Name: 'Marker-off, textile mfr' },
  { Id: 12806, Code: '5419', Name: 'Matcher, clothing mfr' },
  { Id: 12807, Code: '5419', Name: 'Matcher, hat mfr' },
  { Id: 12808, Code: '5419', Name: 'Modeller, pattern, paper' },
  { Id: 12809, Code: '5419', Name: 'Operator, guillotine, coach trimming' },
  { Id: 12810, Code: '5419', Name: 'Painter, textile designing' },
  { Id: 12811, Code: '5419', Name: 'Pantographer, lace mfr' },
  { Id: 12812, Code: '5419', Name: 'Parer, clothing mfr' },
  { Id: 12813, Code: '5419', Name: 'Patcher, wool' },
  { Id: 12814, Code: '5419', Name: 'Penciller, clothing mfr' },
  { Id: 12815, Code: '5419', Name: 'Perforator, embroidery mfr' },
  { Id: 12816, Code: '5419', Name: 'Perforator, glove mfr' },
  { Id: 12817, Code: '5419', Name: "Planner, clothier's" },
  { Id: 12818, Code: '5419', Name: 'Planner, clothing mfr' },
  { Id: 12819, Code: '5419', Name: 'Planner, lay, clothing mfr' },
  { Id: 12820, Code: '5419', Name: 'Polisher, hat' },
  { Id: 12821, Code: '5419', Name: 'Polisher, hat mfr' },
  { Id: 12822, Code: '5419', Name: 'Pouncer' },
  { Id: 12823, Code: '5419', Name: 'Preparer, design, textiles' },
  { Id: 12824, Code: '5419', Name: 'Preparer, embroidery mfr' },
  { Id: 12825, Code: '5419', Name: 'Puller, base, clothing mfr' },
  { Id: 12826, Code: '5419', Name: 'Puller, baste' },
  { Id: 12827, Code: '5419', Name: 'Putter-on, textile printing' },
  { Id: 12828, Code: '5419', Name: 'Repairer, embroidery mfr' },
  { Id: 12829, Code: '5419', Name: 'Repairer, umbrella' },
  { Id: 12830, Code: '5419', Name: 'Restorer, tapestry' },
  { Id: 12831, Code: '5419', Name: 'Restorer, TEXTILES' },
  { Id: 12832, Code: '5419', Name: 'Rounder, hat mfr' },
  { Id: 12833, Code: '5419', Name: 'Rounder-off' },
  { Id: 12834, Code: '5419', Name: 'Sewer, hand' },
  { Id: 12835, Code: '5419', Name: 'Sewer, powder puff mfr' },
  { Id: 12836, Code: '5419', Name: 'Shaper, brim' },
  { Id: 12837, Code: '5419', Name: 'Shaper, millinery mfr' },
  { Id: 12838, Code: '5419', Name: 'Shaver, hat mfr' },
  { Id: 12839, Code: '5419', Name: 'Shaver, hood' },
  { Id: 12840, Code: '5419', Name: 'Shearer, glove mfr' },
  { Id: 12841, Code: '5419', Name: 'Shearman, clothing mfr' },
  { Id: 12842, Code: '5419', Name: 'Shuttler' },
  { Id: 12843, Code: '5419', Name: 'Slitter, fabrics' },
  { Id: 12844, Code: '5419', Name: 'Smearer, waterproof garment mfr' },
  { Id: 12845, Code: '5419', Name: 'Smocker' },
  { Id: 12846, Code: '5419', Name: 'Starrer' },
  { Id: 12847, Code: '5419', Name: 'Steamer, felt hat mfr' },
  { Id: 12848, Code: '5419', Name: 'Steamer, hat' },
  { Id: 12849, Code: '5419', Name: 'Stenciller, needlework kit mfr' },
  { Id: 12850, Code: '5419', Name: 'Sticker, clothing mfr' },
  { Id: 12851, Code: '5419', Name: 'Stuffer, chair' },
  { Id: 12852, Code: '5419', Name: 'Stuffer, cushion' },
  { Id: 12853, Code: '5419', Name: 'Stuffer, mattress, upholstery mfr' },
  { Id: 12854, Code: '5419', Name: 'Stuffer, toy mfr' },
  { Id: 12855, Code: '5419', Name: 'Swabber' },
  { Id: 12856, Code: '5419', Name: 'Sweater, hat mfr' },
  { Id: 12857, Code: '5419', Name: 'Tacker, corset mfr' },
  { Id: 12858, Code: '5419', Name: 'Taker-off, clothing mfr' },
  { Id: 12859, Code: '5419', Name: 'Tambourer, textile making-up' },
  { Id: 12860, Code: '5419', Name: 'Threader, draw' },
  { Id: 12861, Code: '5419', Name: 'Threader, embroidery mfr' },
  { Id: 12862, Code: '5419', Name: 'Tracer, embroidery mfr' },
  { Id: 12863, Code: '5419', Name: 'Trimmer, coffin' },
  { Id: 12864, Code: '5419', Name: 'Trimmer, embroidery mfr' },
  { Id: 12865, Code: '5419', Name: 'Trimmer, glove mfr' },
  { Id: 12866, Code: '5419', Name: 'Trimmer, hat mfr' },
  { Id: 12867, Code: '5419', Name: 'Trimmer, knitwear mfr' },
  { Id: 12868, Code: '5419', Name: 'Tucker, clothing mfr' },
  { Id: 12869, Code: '5419', Name: 'Tufter, mattress, upholstery mfr' },
  { Id: 12870, Code: '5419', Name: 'Tufter, soft furnishings mfr' },
  { Id: 12871, Code: '5419', Name: 'Turner, canvas goods mfr' },
  { Id: 12872, Code: '5419', Name: 'Turner, clothing mfr' },
  { Id: 12873, Code: '5419', Name: 'Turner, collar' },
  { Id: 12874, Code: '5419', Name: 'Turner, glove' },
  { Id: 12875, Code: '5419', Name: 'Turner, hosiery, knitwear mfr' },
  { Id: 12876, Code: '5419', Name: 'Turner-down, glove mfr' },
  { Id: 12877, Code: '5419', Name: 'Velourer' },
  { Id: 12878, Code: '5419', Name: 'Worker, straw, hat mfr' },
  { Id: 12879, Code: '5419', Name: 'Worker, table, textile mfr' },
  { Id: 12880, Code: '5419', Name: 'Worker, tapestry' },
  { Id: 12881, Code: '5421', Name: 'Artist, paste-up' },
  { Id: 12882, Code: '5421', Name: 'Assembler, mould, MONOTYPE' },
  { Id: 12883, Code: '5421', Name: 'Assembler, photo-lithographic plates mfr' },
  { Id: 12884, Code: '5421', Name: 'Assistant, lithographic' },
  { Id: 12885, Code: '5421', Name: "Assistant, stereotyper's" },
  { Id: 12886, Code: '5421', Name: 'Attendant, caster, monotype' },
  { Id: 12887, Code: '5421', Name: 'Caster, monotype' },
  { Id: 12888, Code: '5421', Name: 'Caster, printing' },
  { Id: 12889, Code: '5421', Name: 'Clicker, printing' },
  { Id: 12890, Code: '5421', Name: 'Compositor' },
  { Id: 12891, Code: '5421', Name: 'Designer, typographical' },
  { Id: 12892, Code: '5421', Name: 'Displayman, printing' },
  { Id: 12893, Code: '5421', Name: 'Draughtsman, lithographic' },
  { Id: 12894, Code: '5421', Name: "Draughtsman, printer's" },
  { Id: 12895, Code: '5421', Name: 'Electrotyper' },
  { Id: 12896, Code: '5421', Name: 'Engraver, bank-note' },
  { Id: 12897, Code: '5421', Name: 'Engraver, block, process' },
  { Id: 12898, Code: '5421', Name: 'Engraver, chemical' },
  { Id: 12899, Code: '5421', Name: 'Engraver, copper' },
  { Id: 12900, Code: '5421', Name: 'Engraver, die' },
  { Id: 12901, Code: '5421', Name: 'Engraver, hand, textile mfr' },
  { Id: 12902, Code: '5421', Name: 'Engraver, heraldic' },
  { Id: 12903, Code: '5421', Name: 'Engraver, line' },
  { Id: 12904, Code: '5421', Name: 'Engraver, machine' },
  { Id: 12905, Code: '5421', Name: 'Engraver, map' },
  { Id: 12906, Code: '5421', Name: 'Engraver, mark, stamp' },
  { Id: 12907, Code: '5421', Name: 'Engraver, music' },
  { Id: 12908, Code: '5421', Name: 'Engraver, Ordnance Survey' },
  { Id: 12909, Code: '5421', Name: 'Engraver, pantograph, roller engraving' },
  { Id: 12910, Code: '5421', Name: 'Engraver, photo' },
  { Id: 12911, Code: '5421', Name: 'Engraver, photographic' },
  { Id: 12912, Code: '5421', Name: 'Engraver, photogravure' },
  { Id: 12913, Code: '5421', Name: 'Engraver, plate, copper' },
  { Id: 12914, Code: '5421', Name: "Engraver, potter's" },
  { Id: 12915, Code: '5421', Name: 'Engraver, printing' },
  { Id: 12916, Code: '5421', Name: 'Engraver, process' },
  { Id: 12917, Code: '5421', Name: 'Engraver, punch' },
  { Id: 12918, Code: '5421', Name: 'Engraver, relief' },
  { Id: 12919, Code: '5421', Name: 'Engraver, roller' },
  { Id: 12920, Code: '5421', Name: 'Engraver, seal' },
  { Id: 12921, Code: '5421', Name: 'Engraver, textile printing' },
  { Id: 12922, Code: '5421', Name: 'Engraver, transfer' },
  { Id: 12923, Code: '5421', Name: 'Etcher, block, process' },
  { Id: 12924, Code: '5421', Name: 'Etcher, colour' },
  { Id: 12925, Code: '5421', Name: 'Etcher, copper, printing' },
  { Id: 12926, Code: '5421', Name: 'Etcher, deep' },
  { Id: 12927, Code: '5421', Name: 'Etcher, fine' },
  { Id: 12928, Code: '5421', Name: 'Etcher, line' },
  { Id: 12929, Code: '5421', Name: 'Etcher, photogravure' },
  { Id: 12930, Code: '5421', Name: 'Etcher, printing' },
  { Id: 12931, Code: '5421', Name: 'Etcher, roller' },
  { Id: 12932, Code: '5421', Name: 'Etcher, rough' },
  { Id: 12933, Code: '5421', Name: 'Etcher, tone, half' },
  { Id: 12934, Code: '5421', Name: 'Hand, box, printing' },
  { Id: 12935, Code: '5421', Name: 'Hand, imposition' },
  { Id: 12936, Code: '5421', Name: 'Hand, make-up' },
  { Id: 12937, Code: '5421', Name: 'Hand, plate, printing' },
  { Id: 12938, Code: '5421', Name: 'Hand, poster' },
  { Id: 12939, Code: '5421', Name: 'Hand, stone' },
  { Id: 12940, Code: '5421', Name: 'Hand, yardage' },
  { Id: 12941, Code: '5421', Name: "Impressioner, ENGRAVER'S" },
  { Id: 12942, Code: '5421', Name: 'Layer, plate, printing' },
  { Id: 12943, Code: '5421', Name: "Layer, printer's, textile printing" },
  { Id: 12944, Code: '5421', Name: 'Lithographer, photo' },
  { Id: 12945, Code: '5421', Name: 'Machinist, casting, monotype' },
  { Id: 12946, Code: '5421', Name: 'Machinist, linotype' },
  {
    Id: 12947,
    Code: '5421',
    Name: 'Machinist, plate-backing, photographic plate mfr'
  },
  { Id: 12948, Code: '5421', Name: 'Maker, block, printing' },
  { Id: 12949, Code: '5421', Name: 'Maker, plate' },
  { Id: 12950, Code: '5421', Name: 'Maker, plate, lithographic' },
  { Id: 12951, Code: '5421', Name: 'Maker, print' },
  { Id: 12952, Code: '5421', Name: 'Maker-up, printing' },
  { Id: 12953, Code: '5421', Name: 'Man, lay-out, printing' },
  { Id: 12954, Code: '5421', Name: 'Manager, pre-press' },
  { Id: 12955, Code: '5421', Name: 'Measurer, paper pattern mfr' },
  { Id: 12956, Code: '5421', Name: 'Metaller, bronzing' },
  { Id: 12957, Code: '5421', Name: 'Moulder, printing' },
  { Id: 12958, Code: '5421', Name: 'Moulder, wax' },
  { Id: 12959, Code: '5421', Name: 'Operator, caster, monotype' },
  { Id: 12960, Code: '5421', Name: 'Operator, composer, IBM' },
  { Id: 12961, Code: '5421', Name: 'Operator, intertype' },
  { Id: 12962, Code: '5421', Name: 'Operator, keyboard, TYPESETTING' },
  { Id: 12963, Code: '5421', Name: 'Operator, linotype' },
  { Id: 12964, Code: '5421', Name: 'Operator, ludlow' },
  { Id: 12965, Code: '5421', Name: 'Operator, make-up, display' },
  { Id: 12966, Code: '5421', Name: 'Operator, monotype' },
  { Id: 12967, Code: '5421', Name: 'Operator, pre-press, printing' },
  { Id: 12968, Code: '5421', Name: 'Operator, typographical' },
  { Id: 12969, Code: '5421', Name: 'Originator, print' },
  { Id: 12970, Code: '5421', Name: 'Originator, printing' },
  { Id: 12971, Code: '5421', Name: 'Planer, STEREOTYPES' },
  { Id: 12972, Code: '5421', Name: 'Planner, gravure' },
  { Id: 12973, Code: '5421', Name: 'Planner, litho' },
  { Id: 12974, Code: '5421', Name: 'Planner, lithographic' },
  { Id: 12975, Code: '5421', Name: 'Planner, page, publishing' },
  { Id: 12976, Code: '5421', Name: 'Planner, printing' },
  { Id: 12977, Code: '5421', Name: 'Preparer, lithographic, printing' },
  { Id: 12978, Code: '5421', Name: 'Preparer, lithography' },
  { Id: 12979, Code: '5421', Name: 'Preparer, paper' },
  { Id: 12980, Code: '5421', Name: 'Printer, carbon' },
  { Id: 12981, Code: '5421', Name: 'Printer, metal, process engraving' },
  { Id: 12982, Code: '5421', Name: 'Printer, originator' },
  { Id: 12983, Code: '5421', Name: 'Printer-compositor' },
  { Id: 12984, Code: '5421', Name: 'Printer-down' },
  { Id: 12985, Code: '5421', Name: 'Printer-to-metal' },
  { Id: 12986, Code: '5421', Name: 'Puller, proof' },
  { Id: 12987, Code: '5421', Name: 'Raiser, printing' },
  { Id: 12988, Code: '5421', Name: 'Reader-compositor' },
  { Id: 12989, Code: '5421', Name: 'Repairer, plate, printing' },
  { Id: 12990, Code: '5421', Name: "Reproducer, plan, PRINTER'S" },
  { Id: 12991, Code: '5421', Name: 'Setter, type' },
  { Id: 12992, Code: '5421', Name: 'Stereographer' },
  { Id: 12993, Code: '5421', Name: 'Stereotyper' },
  { Id: 12994, Code: '5421', Name: 'Stripper, process engraving' },
  { Id: 12995, Code: '5421', Name: 'Supervisor, typesetting' },
  { Id: 12996, Code: '5421', Name: 'Technician, pre-press' },
  { Id: 12997, Code: '5421', Name: 'Transferer, lithograph, printing' },
  { Id: 12998, Code: '5421', Name: 'Turner, process engraving' },
  { Id: 12999, Code: '5421', Name: 'Typist, vari' },
  { Id: 13000, Code: '5421', Name: 'Typographer' },
  { Id: 13001, Code: '5421', Name: 'Worker, photogravure' },
  { Id: 13002, Code: '5422', Name: 'Blocker, foil' },
  { Id: 13003, Code: '5422', Name: 'Blocker, gold' },
  { Id: 13004, Code: '5422', Name: "Bronzer, printer's" },
  { Id: 13005, Code: '5422', Name: 'Bronzer, printing' },
  { Id: 13006, Code: '5422', Name: 'Coordinator, print' },
  { Id: 13007, Code: '5422', Name: 'Decorator, wallpaper mfr' },
  { Id: 13008, Code: '5422', Name: 'Foreman, printing' },
  { Id: 13009, Code: '5422', Name: 'Foreman, publishing' },
  { Id: 13010, Code: '5422', Name: 'Foreman, room, machine, printing' },
  { Id: 13011, Code: '5422', Name: 'Foreman, shop, print' },
  { Id: 13012, Code: '5422', Name: 'Guider, textile printing' },
  { Id: 13013, Code: '5422', Name: 'Machinist, bronzing' },
  { Id: 13014, Code: '5422', Name: 'Machinist, screen, silk' },
  { Id: 13015, Code: '5422', Name: 'Maker, mark' },
  { Id: 13016, Code: '5422', Name: 'Maker, screen, silk' },
  { Id: 13017, Code: '5422', Name: 'Maker, screen, textile printing' },
  { Id: 13018, Code: '5422', Name: 'Maker, stamp' },
  { Id: 13019, Code: '5422', Name: 'Maker, ticket' },
  { Id: 13020, Code: '5422', Name: 'Manager, machine, printing' },
  { Id: 13021, Code: '5422', Name: 'Manager, print' },
  { Id: 13022, Code: '5422', Name: 'Manager, print, screen' },
  { Id: 13023, Code: '5422', Name: 'Manager, printing' },
  { Id: 13024, Code: '5422', Name: 'Manager, room, print' },
  { Id: 13025, Code: '5422', Name: 'Minder, machine, litho' },
  { Id: 13026, Code: '5422', Name: 'Minder, machine, printing' },
  { Id: 13027, Code: '5422', Name: 'Minder, platen, printing' },
  { Id: 13028, Code: '5422', Name: 'Operator, litho, offset' },
  { Id: 13029, Code: '5422', Name: 'Operator, lithographic' },
  { Id: 13030, Code: '5422', Name: 'Operator, Multilith' },
  { Id: 13031, Code: '5422', Name: 'Operator, offset' },
  { Id: 13032, Code: '5422', Name: 'Operator, process, printing' },
  { Id: 13033, Code: '5422', Name: 'Operator, screen, silk' },
  { Id: 13034, Code: '5422', Name: 'Pantographer, roller engraving' },
  { Id: 13035, Code: '5422', Name: 'Printer and stationer' },
  { Id: 13036, Code: '5422', Name: 'Printer, block' },
  { Id: 13037, Code: '5422', Name: 'Printer, bromide' },
  { Id: 13038, Code: '5422', Name: 'Printer, calico' },
  { Id: 13039, Code: '5422', Name: 'Printer, cloth' },
  { Id: 13040, Code: '5422', Name: 'Printer, colour' },
  { Id: 13041, Code: '5422', Name: 'Printer, contact' },
  { Id: 13042, Code: '5422', Name: 'Printer, dial' },
  { Id: 13043, Code: '5422', Name: 'Printer, digital' },
  { Id: 13044, Code: '5422', Name: 'Printer, embroidery' },
  { Id: 13045, Code: '5422', Name: 'Printer, fabric' },
  { Id: 13046, Code: '5422', Name: 'Printer, film' },
  { Id: 13047, Code: '5422', Name: 'Printer, flexographic' },
  { Id: 13048, Code: '5422', Name: 'Printer, general' },
  { Id: 13049, Code: '5422', Name: 'Printer, glass' },
  { Id: 13050, Code: '5422', Name: 'Printer, gold' },
  { Id: 13051, Code: '5422', Name: 'Printer, gravure' },
  { Id: 13052, Code: '5422', Name: 'Printer, hand' },
  { Id: 13053, Code: '5422', Name: 'Printer, hat' },
  { Id: 13054, Code: '5422', Name: 'Printer, label' },
  { Id: 13055, Code: '5422', Name: 'Printer, letter, bronze' },
  { Id: 13056, Code: '5422', Name: 'Printer, letterpress' },
  { Id: 13057, Code: '5422', Name: 'Printer, litho' },
  { Id: 13058, Code: '5422', Name: 'Printer, litho, offset' },
  { Id: 13059, Code: '5422', Name: 'Printer, lithographic' },
  { Id: 13060, Code: '5422', Name: 'Printer, lithographic, offset' },
  { Id: 13061, Code: '5422', Name: 'Printer, map' },
  { Id: 13062, Code: '5422', Name: 'Printer, master' },
  { Id: 13063, Code: '5422', Name: 'Printer, mat' },
  { Id: 13064, Code: '5422', Name: 'Printer, Multilith' },
  { Id: 13065, Code: '5422', Name: 'Printer, nos' },
  { Id: 13066, Code: '5422', Name: 'Printer, nos, screen printing' },
  { Id: 13067, Code: '5422', Name: 'Printer, offset' },
  { Id: 13068, Code: '5422', Name: 'Printer, photographic' },
  { Id: 13069, Code: '5422', Name: 'Printer, plate, ceramics mfr' },
  { Id: 13070, Code: '5422', Name: 'Printer, press' },
  { Id: 13071, Code: '5422', Name: 'Printer, process' },
  { Id: 13072, Code: '5422', Name: 'Printer, sack' },
  { Id: 13073, Code: '5422', Name: 'Printer, screen' },
  { Id: 13074, Code: '5422', Name: 'Printer, screen, silk' },
  { Id: 13075, Code: '5422', Name: 'Printer, silver' },
  { Id: 13076, Code: '5422', Name: 'Printer, textile' },
  { Id: 13077, Code: '5422', Name: 'Printer, ticket' },
  { Id: 13078, Code: '5422', Name: 'Printer, ticket, leaf, metal' },
  { Id: 13079, Code: '5422', Name: 'Printer, title, film processing' },
  { Id: 13080, Code: '5422', Name: 'Printer, transfer' },
  { Id: 13081, Code: '5422', Name: 'Printer, wallpaper' },
  { Id: 13082, Code: '5422', Name: 'Screener, silk' },
  { Id: 13083, Code: '5422', Name: 'Specialist, document, digital' },
  { Id: 13084, Code: '5422', Name: 'Stationer and printer' },
  { Id: 13085, Code: '5422', Name: 'Stationer, paper goods mfr' },
  { Id: 13086, Code: '5422', Name: 'Supervisor, print' },
  { Id: 13087, Code: '5422', Name: 'Technician, print' },
  { Id: 13088, Code: '5422', Name: 'Worker, colour, printing' },
  { Id: 13089, Code: '5423', Name: 'Assembler, film' },
  { Id: 13090, Code: '5423', Name: 'Assembler, negative, FILMS' },
  { Id: 13091, Code: '5423', Name: "Assistant, binder's" },
  { Id: 13092, Code: '5423', Name: 'Assistant, bindery' },
  { Id: 13093, Code: '5423', Name: "Assistant, bookbinder's" },
  { Id: 13094, Code: '5423', Name: 'Assistant, control, sensitometric' },
  { Id: 13095, Code: '5423', Name: 'Assistant, presentation, printing' },
  { Id: 13096, Code: '5423', Name: 'Assistant, process, printing' },
  { Id: 13097, Code: '5423', Name: 'Assistant, room, dark' },
  { Id: 13098, Code: '5423', Name: 'Assistant, warehouse, printing' },
  { Id: 13099, Code: '5423', Name: 'Binder, book' },
  { Id: 13100, Code: '5423', Name: 'Binder, bookbinding' },
  { Id: 13101, Code: '5423', Name: 'Binder, leather' },
  { Id: 13102, Code: '5423', Name: "Binder, printer's" },
  { Id: 13103, Code: '5423', Name: 'Binder, printing' },
  { Id: 13104, Code: '5423', Name: "Binder, publisher's" },
  { Id: 13105, Code: '5423', Name: "Binder, stationer's" },
  { Id: 13106, Code: '5423', Name: 'Binder, vellum' },
  { Id: 13107, Code: '5423', Name: 'Blocker, bookbinding' },
  { Id: 13108, Code: '5423', Name: 'Bookbinder' },
  { Id: 13109, Code: '5423', Name: 'Calculator, sensitometric' },
  { Id: 13110, Code: '5423', Name: 'Collator, printing' },
  { Id: 13111, Code: '5423', Name: 'Colourer, hand, PICTURE POSTCARD' },
  { Id: 13112, Code: '5423', Name: 'Colourer, print' },
  { Id: 13113, Code: '5423', Name: 'Colourer, wallpaper printing' },
  { Id: 13114, Code: '5423', Name: 'Colourist, photographic' },
  { Id: 13115, Code: '5423', Name: 'Colourist, postcard' },
  { Id: 13116, Code: '5423', Name: 'Copyist, braille' },
  { Id: 13117, Code: '5423', Name: 'Coverer, bookbinding' },
  { Id: 13118, Code: '5423', Name: 'Cutter, bookbinding' },
  { Id: 13119, Code: '5423', Name: 'Cutter, cloth, bookbinding' },
  { Id: 13120, Code: '5423', Name: 'Cutter, leather, bookbinding' },
  { Id: 13121, Code: '5423', Name: 'Developer, film' },
  { Id: 13122, Code: '5423', Name: 'Developer, photographic film processing' },
  { Id: 13123, Code: '5423', Name: 'Dryer, photographic film mfr' },
  { Id: 13124, Code: '5423', Name: 'Dryer, photographic film processing' },
  { Id: 13125, Code: '5423', Name: 'Duster, printing' },
  { Id: 13126, Code: '5423', Name: 'Edger, gilt' },
  { Id: 13127, Code: '5423', Name: 'Embosser, leather, bookbinding' },
  { Id: 13128, Code: '5423', Name: 'Embosser, printing' },
  { Id: 13129, Code: '5423', Name: 'Enlarger, FILMS' },
  { Id: 13130, Code: '5423', Name: 'Finisher, book, printing' },
  { Id: 13131, Code: '5423', Name: 'Finisher, bookbinding' },
  { Id: 13132, Code: '5423', Name: 'Finisher, gold' },
  { Id: 13133, Code: '5423', Name: 'Finisher, photo' },
  { Id: 13134, Code: '5423', Name: 'Finisher, photographic' },
  { Id: 13135, Code: '5423', Name: 'Finisher, print' },
  { Id: 13136, Code: '5423', Name: "Finisher, printer's" },
  { Id: 13137, Code: '5423', Name: 'Finisher, printing' },
  { Id: 13138, Code: '5423', Name: 'Finisher, process engraving' },
  { Id: 13139, Code: '5423', Name: 'Folder, book' },
  { Id: 13140, Code: '5423', Name: 'Folder, bookbinding' },
  { Id: 13141, Code: '5423', Name: 'Folder, printing' },
  { Id: 13142, Code: '5423', Name: 'Folder, rag book mfr' },
  { Id: 13143, Code: '5423', Name: 'Foreman, calico printers' },
  { Id: 13144, Code: '5423', Name: 'Foreman, textile mfr: printing dept' },
  { Id: 13145, Code: '5423', Name: 'Forwarder, bookbinding' },
  { Id: 13146, Code: '5423', Name: 'Gatherer, bookbinding' },
  { Id: 13147, Code: '5423', Name: 'Gilder, bookbinding' },
  { Id: 13148, Code: '5423', Name: 'Gilder, printing' },
  { Id: 13149, Code: '5423', Name: 'Gilder, wallpaper mfr' },
  { Id: 13150, Code: '5423', Name: 'Grader, photographic film processing' },
  { Id: 13151, Code: '5423', Name: 'Grinder, printing' },
  { Id: 13152, Code: '5423', Name: 'Hand, dis' },
  { Id: 13153, Code: '5423', Name: 'Hand, general, bookbinding' },
  { Id: 13154, Code: '5423', Name: 'Handler, photographic film mfr' },
  { Id: 13155, Code: '5423', Name: 'Indexer, bookbinding' },
  { Id: 13156, Code: '5423', Name: 'Joiner, film' },
  {
    Id: 13157,
    Code: '5423',
    Name: 'Loader, cassette, cartridge, photographic film mfr'
  },
  { Id: 13158, Code: '5423', Name: 'Loader, film' },
  { Id: 13159, Code: '5423', Name: 'Machinist, binding, perfect' },
  { Id: 13160, Code: '5423', Name: 'Machinist, bookbinding' },
  { Id: 13161, Code: '5423', Name: 'Machinist, collotype' },
  { Id: 13162, Code: '5423', Name: 'Machinist, combining, textile printing' },
  { Id: 13163, Code: '5423', Name: 'Machinist, developing, film' },
  { Id: 13164, Code: '5423', Name: 'Machinist, duplicating, offset' },
  { Id: 13165, Code: '5423', Name: 'Machinist, embossing' },
  { Id: 13166, Code: '5423', Name: 'Machinist, photographic film processing' },
  { Id: 13167, Code: '5423', Name: 'Machinist, printing, film' },
  { Id: 13168, Code: '5423', Name: 'Machinist, sewing, bookbinding' },
  { Id: 13169, Code: '5423', Name: 'Maker, book, pattern' },
  { Id: 13170, Code: '5423', Name: 'Maker, book, pocket' },
  { Id: 13171, Code: '5423', Name: 'Maker, case, leather, bookbinding' },
  { Id: 13172, Code: '5423', Name: 'Man, table, bookbinding' },
  { Id: 13173, Code: '5423', Name: 'Manager, finishing, printing' },
  { Id: 13174, Code: '5423', Name: 'Marbler, bookbinding' },
  { Id: 13175, Code: '5423', Name: 'Marbler, paper' },
  { Id: 13176, Code: '5423', Name: "Mounter, photographer's" },
  { Id: 13177, Code: '5423', Name: 'Mounter, printing' },
  { Id: 13178, Code: '5423', Name: 'Mounter, process' },
  { Id: 13179, Code: '5423', Name: 'Numberer, bookbinding' },
  { Id: 13180, Code: '5423', Name: 'Pager, bookbinding' },
  { Id: 13181, Code: '5423', Name: 'Passer, proof, lithography' },
  { Id: 13182, Code: '5423', Name: 'Patcher, lithography' },
  { Id: 13183, Code: '5423', Name: 'Perforator, bookbinding' },
  { Id: 13184, Code: '5423', Name: 'Perforator, card, stationery mfr' },
  {
    Id: 13185,
    Code: '5423',
    Name: 'Perforator, pattern, paper dress pattern mfr'
  },
  { Id: 13186, Code: '5423', Name: 'Perforator, stamp' },
  { Id: 13187, Code: '5423', Name: 'Plater, bookbinding' },
  { Id: 13188, Code: '5423', Name: 'Polisher, spray' },
  { Id: 13189, Code: '5423', Name: 'Presser, bookbinding' },
  { Id: 13190, Code: '5423', Name: 'Processor, film' },
  { Id: 13191, Code: '5423', Name: 'Processor, photographic' },
  { Id: 13192, Code: '5423', Name: 'Prover, colour, printing' },
  { Id: 13193, Code: '5423', Name: 'Prover, lithography' },
  { Id: 13194, Code: '5423', Name: 'Prover, Ordnance Survey' },
  { Id: 13195, Code: '5423', Name: 'Prover, process, printing' },
  { Id: 13196, Code: '5423', Name: 'Reeler, photographic film mfr' },
  { Id: 13197, Code: '5423', Name: 'Repairer, book' },
  { Id: 13198, Code: '5423', Name: 'Restorer, book' },
  { Id: 13199, Code: '5423', Name: 'Retoucher, colour' },
  { Id: 13200, Code: '5423', Name: 'Retoucher, film processing' },
  { Id: 13201, Code: '5423', Name: 'Retoucher, photographic' },
  { Id: 13202, Code: '5423', Name: 'Retoucher, photolitho' },
  { Id: 13203, Code: '5423', Name: 'Retoucher, printing' },
  { Id: 13204, Code: '5423', Name: 'Roller, bookbinding' },
  { Id: 13205, Code: '5423', Name: 'Sewer, bookbinding' },
  { Id: 13206, Code: '5423', Name: 'Sewer, vellum' },
  { Id: 13207, Code: '5423', Name: 'Splicer, film' },
  { Id: 13208, Code: '5423', Name: 'Spooler, photographic film mfr' },
  { Id: 13209, Code: '5423', Name: 'Spotter, film processing' },
  { Id: 13210, Code: '5423', Name: 'Spotter, printing' },
  { Id: 13211, Code: '5423', Name: 'Stitcher, bookbinding' },
  { Id: 13212, Code: '5423', Name: 'Stitcher, printing' },
  { Id: 13213, Code: '5423', Name: 'Supervisor, finishing, printing' },
  {
    Id: 13214,
    Code: '5423',
    Name: 'Synchroniser, film, television production'
  },
  { Id: 13215, Code: '5423', Name: 'Technician, darkroom' },
  { Id: 13216, Code: '5423', Name: 'Tinter, film processing' },
  { Id: 13217, Code: '5423', Name: 'Trimmer, bookbinding' },
  { Id: 13218, Code: '5423', Name: 'Washer, photographic film processing' },
  { Id: 13219, Code: '5423', Name: 'Worker, bindery' },
  {
    Id: 13220,
    Code: '5423',
    Name: 'Worker, copper, electro, textile printing'
  },
  { Id: 13221, Code: '5423', Name: 'Worker, film, colour' },
  { Id: 13222, Code: '5423', Name: 'Worker, process, film processing' },
  { Id: 13223, Code: '5431', Name: "Assistant, butcher's" },
  { Id: 13224, Code: '5431', Name: 'Blockman, butchers' },
  { Id: 13225, Code: '5431', Name: 'Boner, MEAT' },
  { Id: 13226, Code: '5431', Name: 'Butcher' },
  { Id: 13227, Code: '5431', Name: 'Butcher, master' },
  { Id: 13228, Code: '5431', Name: 'Butcher, retail' },
  { Id: 13229, Code: '5431', Name: 'Butcher-driver' },
  { Id: 13230, Code: '5431', Name: 'Butcher-manager' },
  { Id: 13231, Code: '5431', Name: 'Cleaner, meat' },
  { Id: 13232, Code: '5431', Name: 'Collector, blood' },
  { Id: 13233, Code: '5431', Name: 'Cutter, bacon' },
  { Id: 13234, Code: '5431', Name: "Cutter, butcher's" },
  { Id: 13235, Code: '5431', Name: "Cutter, butcher's shop" },
  { Id: 13236, Code: '5431', Name: 'Cutter, meat' },
  { Id: 13237, Code: '5431', Name: "Cutter, shopman, BUTCHER'S" },
  { Id: 13238, Code: '5431', Name: 'Dehairer, pig' },
  { Id: 13239, Code: '5431', Name: 'Dresser, meat' },
  { Id: 13240, Code: '5431', Name: 'Foreman, abattoir' },
  { Id: 13241, Code: '5431', Name: 'Gutman' },
  { Id: 13242, Code: '5431', Name: 'Hand, first, retail trade: butchers' },
  { Id: 13243, Code: '5431', Name: 'Killer' },
  { Id: 13244, Code: '5431', Name: 'Knacker' },
  { Id: 13245, Code: '5431', Name: 'Knackerman' },
  { Id: 13246, Code: '5431', Name: "Machinist, butcher's" },
  { Id: 13247, Code: '5431', Name: 'Machinist, chopping, MEAT' },
  { Id: 13248, Code: '5431', Name: 'Machinist, mincing, MEAT' },
  { Id: 13249, Code: '5431', Name: 'Manager, butchery' },
  { Id: 13250, Code: '5431', Name: 'Mincer, meat, sausage' },
  { Id: 13251, Code: '5431', Name: 'Operative, abattoir' },
  { Id: 13252, Code: '5431', Name: 'Operator, saw, circular, MEAT' },
  { Id: 13253, Code: '5431', Name: 'Roller, bacon' },
  { Id: 13254, Code: '5431', Name: 'Sawyer, MEAT' },
  { Id: 13255, Code: '5431', Name: 'Shecheta' },
  { Id: 13256, Code: '5431', Name: 'Shocket' },
  { Id: 13257, Code: '5431', Name: "Shopman, butcher's" },
  { Id: 13258, Code: '5431', Name: 'Shopman, meat' },
  { Id: 13259, Code: '5431', Name: "Shopman-cutter, BUTCHER'S" },
  { Id: 13260, Code: '5431', Name: 'Skinner, food products mfr' },
  { Id: 13261, Code: '5431', Name: 'Skinner, slaughterhouse' },
  { Id: 13262, Code: '5431', Name: 'Slaughterer' },
  { Id: 13263, Code: '5431', Name: 'Slaughterman' },
  { Id: 13264, Code: '5431', Name: 'Sticker, slaughterhouse' },
  { Id: 13265, Code: '5431', Name: 'Stunner' },
  { Id: 13266, Code: '5431', Name: 'Supervisor, butchery' },
  { Id: 13267, Code: '5431', Name: 'Supervisor, meat, abattoir' },
  { Id: 13268, Code: '5431', Name: 'Trimmer, meat' },
  { Id: 13269, Code: '5431', Name: 'Worker, abattoir' },
  { Id: 13270, Code: '5431', Name: 'Worker, house, slaughter' },
  { Id: 13271, Code: '5431', Name: 'Worker, process, slaughterhouse' },
  { Id: 13272, Code: '5431', Name: 'Worker, slaughterhouse' },
  { Id: 13273, Code: '5432', Name: 'Artist, cake' },
  { Id: 13274, Code: '5432', Name: "Assistant, baker's" },
  {
    Id: 13275,
    Code: '5432',
    Name: "Assistant, confectioner's, flour confectionery mfr"
  },
  { Id: 13276, Code: '5432', Name: 'Baker' },
  { Id: 13277, Code: '5432', Name: 'Baker and confectioner' },
  { Id: 13278, Code: '5432', Name: 'Baker, master' },
  { Id: 13279, Code: '5432', Name: 'Boardman, bakery' },
  { Id: 13280, Code: '5432', Name: 'Confectioner' },
  { Id: 13281, Code: '5432', Name: 'Confectioner, flour' },
  { Id: 13282, Code: '5432', Name: 'Cook, pastry, bakery' },
  { Id: 13283, Code: '5432', Name: 'Decorator, cake' },
  { Id: 13284, Code: '5432', Name: 'Decorator, FLOUR CONFECTIONERY' },
  { Id: 13285, Code: '5432', Name: 'Designer, cake' },
  { Id: 13286, Code: '5432', Name: 'Designer, flour confectionery mfr' },
  { Id: 13287, Code: '5432', Name: 'Foreman, bakery' },
  { Id: 13288, Code: '5432', Name: 'Foreman, flour confectionery mfr' },
  { Id: 13289, Code: '5432', Name: 'Foreman, plant, bakery' },
  { Id: 13290, Code: '5432', Name: 'Hand, first, bakery' },
  { Id: 13291, Code: '5432', Name: 'Hand, pastry' },
  { Id: 13292, Code: '5432', Name: 'Maker, cake, flour confectionery mfr' },
  {
    Id: 13293,
    Code: '5432',
    Name: 'Maker, confectionery, FLOUR CONFECTIONERY'
  },
  { Id: 13294, Code: '5432', Name: 'Maker, flour confectionery mfr' },
  { Id: 13295, Code: '5432', Name: 'Man, table, bakery' },
  { Id: 13296, Code: '5432', Name: "Manager, baker's" },
  { Id: 13297, Code: '5432', Name: 'Manager, bakery' },
  { Id: 13298, Code: '5432', Name: 'Manufacturer, flour confectionery mfr' },
  { Id: 13299, Code: '5432', Name: 'Pastrycook, bakery' },
  { Id: 13300, Code: '5432', Name: 'Specialist, sugarcraft' },
  { Id: 13301, Code: '5432', Name: 'Superintendent, bakery' },
  { Id: 13302, Code: '5432', Name: 'Superintendent, biscuit mfr' },
  { Id: 13303, Code: '5432', Name: 'Superintendent, flour confectionery mfr' },
  { Id: 13304, Code: '5433', Name: "Assistant, fishmonger's" },
  { Id: 13305, Code: '5433', Name: 'Blockman, fishmongers' },
  { Id: 13306, Code: '5433', Name: 'Boner, FISH' },
  { Id: 13307, Code: '5433', Name: 'Butcher, FISH, POULTRY' },
  { Id: 13308, Code: '5433', Name: 'Cleaner, fish' },
  { Id: 13309, Code: '5433', Name: 'Dealer, fish' },
  { Id: 13310, Code: '5433', Name: 'Dresser, bird, game' },
  { Id: 13311, Code: '5433', Name: 'Dresser, crab' },
  { Id: 13312, Code: '5433', Name: 'Dresser, poultry' },
  { Id: 13313, Code: '5433', Name: 'Eviscerator' },
  { Id: 13314, Code: '5433', Name: 'Filleter, FISH' },
  { Id: 13315, Code: '5433', Name: 'Fishmonger' },
  { Id: 13316, Code: '5433', Name: 'Gutter, fish' },
  { Id: 13317, Code: '5433', Name: 'Hand, poultry, food processing' },
  { Id: 13318, Code: '5433', Name: 'Header, fish' },
  { Id: 13319, Code: '5433', Name: 'Jointer, chicken' },
  { Id: 13320, Code: '5433', Name: 'Killer and plucker' },
  { Id: 13321, Code: '5433', Name: 'Nobber, fish' },
  { Id: 13322, Code: '5433', Name: 'Plucker, chicken' },
  { Id: 13323, Code: '5433', Name: 'Plucker, poultry dressing' },
  { Id: 13324, Code: '5433', Name: 'Poulterer' },
  { Id: 13325, Code: '5433', Name: 'Preparer, fish' },
  { Id: 13326, Code: '5433', Name: 'Processor, fish' },
  { Id: 13327, Code: '5433', Name: 'Processor, poultry' },
  { Id: 13328, Code: '5433', Name: 'Splitter, fish' },
  { Id: 13329, Code: '5433', Name: 'Sticker, poultry' },
  { Id: 13330, Code: '5433', Name: 'Trimmer, salmon' },
  { Id: 13331, Code: '5433', Name: 'Trusser, fowl' },
  { Id: 13332, Code: '5433', Name: 'Trusser, poultry dressing' },
  { Id: 13333, Code: '5433', Name: 'Worker, fish, food processing' },
  { Id: 13334, Code: '5433', Name: 'Worker, herring' },
  { Id: 13335, Code: '5433', Name: 'Worker, house, fish' },
  { Id: 13336, Code: '5434', Name: 'Bhandary' },
  { Id: 13337, Code: '5434', Name: 'Chef' },
  { Id: 13338, Code: '5434', Name: 'Chef de cuisine' },
  { Id: 13339, Code: '5434', Name: 'Chef de partie' },
  { Id: 13340, Code: '5434', Name: 'Chef, development' },
  { Id: 13341, Code: '5434', Name: 'Chef, head' },
  { Id: 13342, Code: '5434', Name: 'Chef, pastry' },
  { Id: 13343, Code: '5434', Name: 'Chef-manager' },
  { Id: 13344, Code: '5434', Name: 'Garde-manger' },
  { Id: 13345, Code: '5434', Name: 'Owner-chef' },
  { Id: 13346, Code: '5435', Name: 'Controller, meals, school' },
  { Id: 13347, Code: '5435', Name: 'Cook' },
  { Id: 13348, Code: '5435', Name: 'Cook in charge' },
  { Id: 13349, Code: '5435', Name: 'Cook, chief' },
  { Id: 13350, Code: '5435', Name: 'Cook, head' },
  { Id: 13351, Code: '5435', Name: 'Cook, pastry' },
  { Id: 13352, Code: '5435', Name: 'Cook-cleaner' },
  { Id: 13353, Code: '5435', Name: 'Cook-companion' },
  { Id: 13354, Code: '5435', Name: 'Cook-general' },
  { Id: 13355, Code: '5435', Name: 'Cook-manager' },
  { Id: 13356, Code: '5435', Name: 'Cook-steward' },
  { Id: 13357, Code: '5435', Name: 'Cook-supervisor' },
  { Id: 13358, Code: '5435', Name: 'Fryer, catering' },
  { Id: 13359, Code: '5435', Name: 'Fryer, fish' },
  { Id: 13360, Code: '5435', Name: 'Griddler, catering' },
  { Id: 13361, Code: '5435', Name: 'Hand, grill' },
  { Id: 13362, Code: '5435', Name: 'Pastrycook' },
  { Id: 13363, Code: '5436', Name: 'Adviser, catering' },
  { Id: 13364, Code: '5436', Name: 'Caterer' },
  { Id: 13365, Code: '5436', Name: 'Caterer, airline' },
  { Id: 13366, Code: '5436', Name: 'Consultant, catering' },
  { Id: 13367, Code: '5436', Name: 'Contractor, catering' },
  { Id: 13368, Code: '5436', Name: 'Controller, catering' },
  { Id: 13369, Code: '5436', Name: 'Franchisee, catering' },
  { Id: 13370, Code: '5436', Name: 'Keeper, stall, coffee' },
  { Id: 13371, Code: '5436', Name: 'Manager, bar' },
  { Id: 13372, Code: '5436', Name: 'Manager, canteen' },
  { Id: 13373, Code: '5436', Name: 'Manager, catering' },
  { Id: 13374, Code: '5436', Name: 'Manager, conference and banqueting' },
  { Id: 13375, Code: '5436', Name: 'Manager, floor, restaurant' },
  { Id: 13376, Code: '5436', Name: 'Manager, food and beverage' },
  { Id: 13377, Code: '5436', Name: 'Manager, kitchen' },
  { Id: 13378, Code: '5436', Name: 'Manager, room, dining' },
  {
    Id: 13379,
    Code: '5436',
    Name: 'Manager, running, shift, fast food outlet'
  },
  { Id: 13380, Code: '5436', Name: 'Manager, unit, CATERING' },
  { Id: 13381, Code: '5436', Name: 'Officer, catering' },
  { Id: 13382, Code: '5436', Name: 'Steward, chief, club' },
  { Id: 13383, Code: '5436', Name: 'Steward, club' },
  { Id: 13384, Code: '5436', Name: 'Steward, community centre' },
  { Id: 13385, Code: '5436', Name: "Steward, working men's institute" },
  { Id: 13386, Code: '5436', Name: 'Superintendent, catering' },
  {
    Id: 13387,
    Code: '5436',
    Name: 'Superintendent, kitchen, hospital service'
  },
  { Id: 13388, Code: '5441', Name: 'Aerographer' },
  { Id: 13389, Code: '5441', Name: 'Archman, GLASS' },
  { Id: 13390, Code: '5441', Name: 'Artist, ceramic' },
  { Id: 13391, Code: '5441', Name: 'Artist, ceramics decorating' },
  { Id: 13392, Code: '5441', Name: 'Artist, glass decorating' },
  { Id: 13393, Code: '5441', Name: 'Artist, glass, stained' },
  { Id: 13394, Code: '5441', Name: 'Artist, pottery' },
  { Id: 13395, Code: '5441', Name: "Assistant, maker's, crucible" },
  { Id: 13396, Code: '5441', Name: "Assistant, moulder's, abrasives mfr" },
  { Id: 13397, Code: '5441', Name: "Attendant, polisher's, glass" },
  { Id: 13398, Code: '5441', Name: 'Badger' },
  { Id: 13399, Code: '5441', Name: 'Bander, ceramics mfr' },
  { Id: 13400, Code: '5441', Name: 'Bander, clay' },
  { Id: 13401, Code: '5441', Name: 'Bander, glass' },
  { Id: 13402, Code: '5441', Name: 'Bander, wash' },
  { Id: 13403, Code: '5441', Name: 'Batter, pottery mfr' },
  { Id: 13404, Code: '5441', Name: 'Bender, glass' },
  { Id: 13405, Code: '5441', Name: 'Bender, glass mfr' },
  { Id: 13406, Code: '5441', Name: 'Bender, tube, GLASS' },
  { Id: 13407, Code: '5441', Name: 'Beveller, glass mfr' },
  { Id: 13408, Code: '5441', Name: 'Blacker, ceramics mfr' },
  { Id: 13409, Code: '5441', Name: 'Blaster, sand, ceramics mfr' },
  { Id: 13410, Code: '5441', Name: 'Blocker, brick mfr' },
  { Id: 13411, Code: '5441', Name: 'Blocker, lens' },
  { Id: 13412, Code: '5441', Name: 'Blocker, lens mfr' },
  { Id: 13413, Code: '5441', Name: 'Blower, bottle' },
  { Id: 13414, Code: '5441', Name: 'Blower, bulb, LAMP, VALVE' },
  { Id: 13415, Code: '5441', Name: 'Blower, ceramics mfr' },
  { Id: 13416, Code: '5441', Name: 'Blower, glass' },
  { Id: 13417, Code: '5441', Name: 'Blower, glass mfr' },
  { Id: 13418, Code: '5441', Name: 'Blower, glaze' },
  { Id: 13419, Code: '5441', Name: 'Blower, thermometer' },
  { Id: 13420, Code: '5441', Name: 'Boy, post, glass mfr' },
  { Id: 13421, Code: '5441', Name: 'Boy, wire' },
  { Id: 13422, Code: '5441', Name: 'Breaker-off, glass mfr' },
  { Id: 13423, Code: '5441', Name: 'Brusher, ceramics mfr' },
  { Id: 13424, Code: '5441', Name: 'Brusher, glaze' },
  { Id: 13425, Code: '5441', Name: 'Brusher, sanitary' },
  { Id: 13426, Code: '5441', Name: 'Bumper, ceramics mfr' },
  { Id: 13427, Code: '5441', Name: 'Burner-off, glass mfr' },
  { Id: 13428, Code: '5441', Name: 'Burnisher, ceramics mfr' },
  { Id: 13429, Code: '5441', Name: 'Burnisher, gold, ceramics mfr' },
  { Id: 13430, Code: '5441', Name: 'Carboniser, nickel, valve mfr' },
  { Id: 13431, Code: '5441', Name: 'Caster, ceramics mfr' },
  { Id: 13432, Code: '5441', Name: 'Caster, china' },
  { Id: 13433, Code: '5441', Name: 'Caster, glass mfr' },
  { Id: 13434, Code: '5441', Name: 'Caster, hollow-ware, ceramics mfr' },
  { Id: 13435, Code: '5441', Name: 'Caster, PLASTER' },
  { Id: 13436, Code: '5441', Name: 'Caster, sanitary' },
  { Id: 13437, Code: '5441', Name: 'Caster, statue' },
  { Id: 13438, Code: '5441', Name: 'Cementer, ceramics mfr' },
  { Id: 13439, Code: '5441', Name: 'Centerer and edger, lens mfr' },
  { Id: 13440, Code: '5441', Name: 'Centerer, lens mfr' },
  { Id: 13441, Code: '5441', Name: 'Chairman, glass mfr' },
  { Id: 13442, Code: '5441', Name: 'Chequerer' },
  { Id: 13443, Code: '5441', Name: 'Chipper, ceramics mfr' },
  { Id: 13444, Code: '5441', Name: 'Chummer-in, ceramics mfr' },
  { Id: 13445, Code: '5441', Name: 'Cleaner, ceramics mfr' },
  { Id: 13446, Code: '5441', Name: 'Coater, ceramics' },
  { Id: 13447, Code: '5441', Name: 'Cracker-off' },
  { Id: 13448, Code: '5441', Name: 'Cutter, brick' },
  { Id: 13449, Code: '5441', Name: 'Cutter, brilliant, glass mfr' },
  { Id: 13450, Code: '5441', Name: 'Cutter, ceramics mfr' },
  { Id: 13451, Code: '5441', Name: 'Cutter, diamond, glass mfr' },
  { Id: 13452, Code: '5441', Name: 'Cutter, glass mfr' },
  { Id: 13453, Code: '5441', Name: 'Cutter, glass, optical' },
  { Id: 13454, Code: '5441', Name: 'Cutter, lens' },
  { Id: 13455, Code: '5441', Name: 'Cutter, lens mfr' },
  { Id: 13456, Code: '5441', Name: 'Cutter, letter, glass' },
  { Id: 13457, Code: '5441', Name: 'Cutter, lustre, glass' },
  { Id: 13458, Code: '5441', Name: 'Cutter, plate, photographic film mfr' },
  { Id: 13459, Code: '5441', Name: 'Cutter, ring' },
  { Id: 13460, Code: '5441', Name: 'Cutter, stilt' },
  { Id: 13461, Code: '5441', Name: 'Cutter, tube, GLASS' },
  { Id: 13462, Code: '5441', Name: 'Decorator, aerograph' },
  { Id: 13463, Code: '5441', Name: 'Decorator, aerographing, ceramics mfr' },
  { Id: 13464, Code: '5441', Name: 'Decorator, ceramic' },
  { Id: 13465, Code: '5441', Name: 'Decorator, CERAMICS' },
  { Id: 13466, Code: '5441', Name: 'Decorator, glass' },
  { Id: 13467, Code: '5441', Name: 'Decorator, slip' },
  { Id: 13468, Code: '5441', Name: 'Dipper, automatic, ceramics mfr' },
  { Id: 13469, Code: '5441', Name: 'Dipper, ceramics mfr' },
  { Id: 13470, Code: '5441', Name: 'Dipper, machine, ceramics mfr' },
  { Id: 13471, Code: '5441', Name: 'Drawer, GLASS' },
  { Id: 13472, Code: '5441', Name: 'Drawer, glass mfr' },
  { Id: 13473, Code: '5441', Name: 'Dresser, brick, brick mfr' },
  { Id: 13474, Code: '5441', Name: 'Driller, ceramic' },
  { Id: 13475, Code: '5441', Name: 'Driller, glass' },
  { Id: 13476, Code: '5441', Name: 'Driller, porcelain' },
  { Id: 13477, Code: '5441', Name: 'Driller, pottery' },
  { Id: 13478, Code: '5441', Name: 'Duster, ceramics mfr' },
  { Id: 13479, Code: '5441', Name: 'Duster, colour' },
  { Id: 13480, Code: '5441', Name: 'Edger, ceramics mfr' },
  { Id: 13481, Code: '5441', Name: 'Edger, lens mfr' },
  { Id: 13482, Code: '5441', Name: 'Embosser, glass mfr' },
  { Id: 13483, Code: '5441', Name: 'Emptier, press, ceramics mfr' },
  { Id: 13484, Code: '5441', Name: 'Emptier, press, electrical' },
  { Id: 13485, Code: '5441', Name: 'Enameller, ceramics mfr' },
  { Id: 13486, Code: '5441', Name: 'Engineer, optical' },
  { Id: 13487, Code: '5441', Name: 'Engraver, ceramics mfr' },
  { Id: 13488, Code: '5441', Name: 'Engraver, glass' },
  { Id: 13489, Code: '5441', Name: 'Engraver, glass mfr' },
  { Id: 13490, Code: '5441', Name: 'Engraver, pattern, pottery' },
  { Id: 13491, Code: '5441', Name: 'Engraver, pottery' },
  { Id: 13492, Code: '5441', Name: 'Etcher, ceramics mfr' },
  { Id: 13493, Code: '5441', Name: 'Etcher, glass mfr' },
  { Id: 13494, Code: '5441', Name: 'Etcher, hand, GLASS' },
  { Id: 13495, Code: '5441', Name: 'Etcher, machine' },
  { Id: 13496, Code: '5441', Name: 'Extruder, CERAMICS' },
  { Id: 13497, Code: '5441', Name: 'Fettler, ceramics mfr' },
  { Id: 13498, Code: '5441', Name: 'Fettler, pipe, sanitary' },
  { Id: 13499, Code: '5441', Name: 'Filler, ceramics mfr' },
  { Id: 13500, Code: '5441', Name: 'Finisher, ceramics mfr' },
  { Id: 13501, Code: '5441', Name: 'Finisher, crucible, PLUMBAGO' },
  { Id: 13502, Code: '5441', Name: 'Finisher, glass' },
  { Id: 13503, Code: '5441', Name: 'Finisher, glass mfr' },
  { Id: 13504, Code: '5441', Name: 'Finisher, lens' },
  { Id: 13505, Code: '5441', Name: 'Finisher, pipe, ceramics mfr' },
  { Id: 13506, Code: '5441', Name: 'Finisher, pipe, clay tobacco pipe mfr' },
  { Id: 13507, Code: '5441', Name: 'Finisher, plaster cast mfr' },
  { Id: 13508, Code: '5441', Name: 'Finisher, refractory goods mfr' },
  { Id: 13509, Code: '5441', Name: 'Finisher, sanitary' },
  { Id: 13510, Code: '5441', Name: 'Flasher' },
  { Id: 13511, Code: '5441', Name: 'Flattener, glass mfr' },
  { Id: 13512, Code: '5441', Name: 'Flatter, glass mfr' },
  { Id: 13513, Code: '5441', Name: 'Foreman, batch' },
  { Id: 13514, Code: '5441', Name: 'Foreman, brick mfr' },
  { Id: 13515, Code: '5441', Name: 'Foreman, ceramics mfr' },
  { Id: 13516, Code: '5441', Name: 'Foreman, glass mfr' },
  { Id: 13517, Code: '5441', Name: 'Foreman, house, glass, glass mfr' },
  { Id: 13518, Code: '5441', Name: 'Foreman, shed, press, brick mfr' },
  { Id: 13519, Code: '5441', Name: 'Foreman, shop, ceramics mfr' },
  { Id: 13520, Code: '5441', Name: 'Foreman, shop, cutting, glass mfr' },
  { Id: 13521, Code: '5441', Name: 'Foreman, yard, brick' },
  { Id: 13522, Code: '5441', Name: 'Former, glass' },
  { Id: 13523, Code: '5441', Name: 'Froster, electric lamp mfr' },
  { Id: 13524, Code: '5441', Name: 'Froster, glass mfr' },
  { Id: 13525, Code: '5441', Name: 'Gaffer, glass mfr' },
  { Id: 13526, Code: '5441', Name: 'Gatherer, glass mfr' },
  { Id: 13527, Code: '5441', Name: 'Gauger, glass mfr' },
  { Id: 13528, Code: '5441', Name: 'Gilder, ceramics mfr' },
  { Id: 13529, Code: '5441', Name: 'Glazer, button' },
  { Id: 13530, Code: '5441', Name: 'Glazer, ceramics mfr' },
  { Id: 13531, Code: '5441', Name: 'Glazer, glass mfr' },
  { Id: 13532, Code: '5441', Name: 'Glazer, lens' },
  { Id: 13533, Code: '5441', Name: 'Glazer, optical' },
  { Id: 13534, Code: '5441', Name: 'Glazer, pottery' },
  { Id: 13535, Code: '5441', Name: 'Glazier, lens' },
  { Id: 13536, Code: '5441', Name: 'Glazier, optical' },
  { Id: 13537, Code: '5441', Name: 'Grinder and polisher, optical goods mfr' },
  { Id: 13538, Code: '5441', Name: 'Grinder, ceramics mfr' },
  { Id: 13539, Code: '5441', Name: 'Grinder, flat, GLASS' },
  { Id: 13540, Code: '5441', Name: 'Grinder, glass' },
  { Id: 13541, Code: '5441', Name: 'Grinder, glass mfr' },
  { Id: 13542, Code: '5441', Name: 'Grinder, lens' },
  { Id: 13543, Code: '5441', Name: 'Grinder, optical' },
  { Id: 13544, Code: '5441', Name: 'Grinder, slab, optical' },
  { Id: 13545, Code: '5441', Name: 'Grinder, stopper, glass' },
  { Id: 13546, Code: '5441', Name: 'Grinder, surface, glass mfr' },
  { Id: 13547, Code: '5441', Name: 'Grinder, wheel, emery, glass mfr' },
  { Id: 13548, Code: '5441', Name: 'Hand, bottle' },
  { Id: 13549, Code: '5441', Name: 'Hand, prism' },
  { Id: 13550, Code: '5441', Name: 'Jiggerer, ceramics mfr' },
  { Id: 13551, Code: '5441', Name: 'Jointer, ceramics mfr' },
  { Id: 13552, Code: '5441', Name: 'Jointer, pipe, stoneware pipe mfr' },
  { Id: 13553, Code: '5441', Name: 'Jointer, stanford' },
  { Id: 13554, Code: '5441', Name: 'Jointer, stoneware pipe mfr' },
  { Id: 13555, Code: '5441', Name: 'Jollier' },
  { Id: 13556, Code: '5441', Name: 'Knocker, ceramics mfr' },
  { Id: 13557, Code: '5441', Name: 'Knocker-off, glass mfr' },
  { Id: 13558, Code: '5441', Name: 'Knocker-out, glass mfr' },
  { Id: 13559, Code: '5441', Name: 'Labeller, colour, GLASSWARE' },
  { Id: 13560, Code: '5441', Name: 'Ladler, glass mfr' },
  { Id: 13561, Code: '5441', Name: 'Laminator, safety glass mfr' },
  { Id: 13562, Code: '5441', Name: 'Lather, ceramics mfr' },
  { Id: 13563, Code: '5441', Name: 'Layer, ground' },
  { Id: 13564, Code: '5441', Name: 'Liner, ceramics mfr' },
  { Id: 13565, Code: '5441', Name: 'Liner, gold, ceramics mfr' },
  { Id: 13566, Code: '5441', Name: 'Liner, LENSES' },
  { Id: 13567, Code: '5441', Name: 'Liner, tube' },
  { Id: 13568, Code: '5441', Name: 'Lipper, glass' },
  { Id: 13569, Code: '5441', Name: 'Lithographer, ceramics mfr' },
  { Id: 13570, Code: '5441', Name: 'Lustrer' },
  { Id: 13571, Code: '5441', Name: 'Maker, appliance, sanitary' },
  { Id: 13572, Code: '5441', Name: 'Maker, ball, glass mfr' },
  { Id: 13573, Code: '5441', Name: 'Maker, basin' },
  { Id: 13574, Code: '5441', Name: 'Maker, basket, ornamental, CERAMICS' },
  { Id: 13575, Code: '5441', Name: 'Maker, bat, CLAY' },
  { Id: 13576, Code: '5441', Name: 'Maker, block, ceramics mfr' },
  { Id: 13577, Code: '5441', Name: 'Maker, bottle' },
  { Id: 13578, Code: '5441', Name: 'Maker, bottle, STONEWARE' },
  { Id: 13579, Code: '5441', Name: 'Maker, bowl, ceramics mfr' },
  { Id: 13580, Code: '5441', Name: 'Maker, brick' },
  { Id: 13581, Code: '5441', Name: 'Maker, cast, plaster' },
  { Id: 13582, Code: '5441', Name: 'Maker, ceramic' },
  { Id: 13583, Code: '5441', Name: 'Maker, cover, ceramics mfr' },
  { Id: 13584, Code: '5441', Name: 'Maker, crank' },
  { Id: 13585, Code: '5441', Name: 'Maker, crucible' },
  { Id: 13586, Code: '5441', Name: 'Maker, cup' },
  { Id: 13587, Code: '5441', Name: 'Maker, dish' },
  { Id: 13588, Code: '5441', Name: 'Maker, eye, artificial, GLASS' },
  { Id: 13589, Code: '5441', Name: 'Maker, figure' },
  { Id: 13590, Code: '5441', Name: 'Maker, flange, electric lamp mfr' },
  { Id: 13591, Code: '5441', Name: 'Maker, flower, ceramics mfr' },
  { Id: 13592, Code: '5441', Name: 'Maker, foot' },
  { Id: 13593, Code: '5441', Name: 'Maker, form, ceramics mfr' },
  { Id: 13594, Code: '5441', Name: 'Maker, glass' },
  { Id: 13595, Code: '5441', Name: 'Maker, handle, ceramics mfr' },
  { Id: 13596, Code: '5441', Name: 'Maker, hollow-ware, ceramics mfr' },
  { Id: 13597, Code: '5441', Name: 'Maker, insulator, CERAMICS' },
  { Id: 13598, Code: '5441', Name: 'Maker, jar, CERAMICS' },
  { Id: 13599, Code: '5441', Name: 'Maker, lens' },
  { Id: 13600, Code: '5441', Name: 'Maker, mirror' },
  { Id: 13601, Code: '5441', Name: 'Maker, model, ceramics mfr' },
  { Id: 13602, Code: '5441', Name: 'Maker, model, plaster' },
  { Id: 13603, Code: '5441', Name: 'Maker, mould, ceramics mfr' },
  {
    Id: 13604,
    Code: '5441',
    Name: 'Maker, mould, plaster, plumbago crucible mfr'
  },
  { Id: 13605, Code: '5441', Name: 'Maker, oddstuff' },
  { Id: 13606, Code: '5441', Name: 'Maker, pantile' },
  { Id: 13607, Code: '5441', Name: 'Maker, pattern, ceramics mfr' },
  { Id: 13608, Code: '5441', Name: 'Maker, pin, ceramics mfr' },
  { Id: 13609, Code: '5441', Name: 'Maker, pipe, ceramics mfr' },
  { Id: 13610, Code: '5441', Name: 'Maker, pipe, clay' },
  { Id: 13611, Code: '5441', Name: 'Maker, pipe, drain' },
  { Id: 13612, Code: '5441', Name: 'Maker, pipe, sanitary' },
  { Id: 13613, Code: '5441', Name: 'Maker, pipe, zinc refining' },
  { Id: 13614, Code: '5441', Name: 'Maker, plaque' },
  { Id: 13615, Code: '5441', Name: 'Maker, plate, ceramics mfr' },
  { Id: 13616, Code: '5441', Name: 'Maker, pot' },
  { Id: 13617, Code: '5441', Name: 'Maker, pottery' },
  { Id: 13618, Code: '5441', Name: 'Maker, prism' },
  { Id: 13619, Code: '5441', Name: 'Maker, radiant, fire, gas' },
  { Id: 13620, Code: '5441', Name: 'Maker, ridge' },
  { Id: 13621, Code: '5441', Name: 'Maker, saddle, ceramics mfr' },
  { Id: 13622, Code: '5441', Name: 'Maker, saggar' },
  { Id: 13623, Code: '5441', Name: 'Maker, sagger' },
  { Id: 13624, Code: '5441', Name: 'Maker, saucer' },
  { Id: 13625, Code: '5441', Name: 'Maker, screen, wind' },
  { Id: 13626, Code: '5441', Name: 'Maker, sink, CERAMICS' },
  { Id: 13627, Code: '5441', Name: 'Maker, slab, ceramics mfr' },
  { Id: 13628, Code: '5441', Name: 'Maker, stopper, glass mfr' },
  { Id: 13629, Code: '5441', Name: 'Maker, stopper, steelworks' },
  { Id: 13630, Code: '5441', Name: 'Maker, teapot' },
  { Id: 13631, Code: '5441', Name: 'Maker, thermometer' },
  { Id: 13632, Code: '5441', Name: 'Maker, thimble, ceramics mfr' },
  { Id: 13633, Code: '5441', Name: 'Maker, tile' },
  { Id: 13634, Code: '5441', Name: 'Maker, tube, GLASS' },
  { Id: 13635, Code: '5441', Name: 'Maker, tubing, GLASS' },
  { Id: 13636, Code: '5441', Name: 'Man, balcony' },
  { Id: 13637, Code: '5441', Name: 'Man, cut-off, glass mfr' },
  { Id: 13638, Code: '5441', Name: 'Man, disc' },
  { Id: 13639, Code: '5441', Name: 'Man, muffle, glass mfr' },
  { Id: 13640, Code: '5441', Name: 'Manager, prescription, glass mfr' },
  { Id: 13641, Code: '5441', Name: 'Manipulator, glass' },
  { Id: 13642, Code: '5441', Name: 'Manufacturer, ceramics' },
  { Id: 13643, Code: '5441', Name: 'Manufacturer, ceramics mfr' },
  { Id: 13644, Code: '5441', Name: 'Manufacturer, glass' },
  { Id: 13645, Code: '5441', Name: 'Manufacturer, glass mfr' },
  { Id: 13646, Code: '5441', Name: 'Marker-out, glass mfr' },
  { Id: 13647, Code: '5441', Name: 'Marker-up, lens' },
  { Id: 13648, Code: '5441', Name: 'Mason, fireclay' },
  { Id: 13649, Code: '5441', Name: 'Mason, ware, sanitary' },
  { Id: 13650, Code: '5441', Name: 'Mechanic, optical' },
  { Id: 13651, Code: '5441', Name: 'Melter, glass mfr' },
  { Id: 13652, Code: '5441', Name: 'Miller, optical' },
  { Id: 13653, Code: '5441', Name: 'Modeller, ceramics mfr' },
  { Id: 13654, Code: '5441', Name: 'Modeller, clay' },
  { Id: 13655, Code: '5441', Name: 'Modeller, glass' },
  { Id: 13656, Code: '5441', Name: 'Modeller, plaster' },
  { Id: 13657, Code: '5441', Name: 'Mottler, ceramics mfr' },
  { Id: 13658, Code: '5441', Name: 'Moulder, bottle' },
  { Id: 13659, Code: '5441', Name: 'Moulder, brick' },
  { Id: 13660, Code: '5441', Name: 'Moulder, ceramics mfr' },
  { Id: 13661, Code: '5441', Name: 'Moulder, clay' },
  { Id: 13662, Code: '5441', Name: 'Moulder, faience' },
  { Id: 13663, Code: '5441', Name: 'Moulder, fireclay' },
  { Id: 13664, Code: '5441', Name: 'Moulder, furnace' },
  { Id: 13665, Code: '5441', Name: 'Moulder, glass mfr' },
  { Id: 13666, Code: '5441', Name: 'Moulder, hand, ceramics mfr' },
  { Id: 13667, Code: '5441', Name: 'Moulder, hand, plumbago crucible mfr' },
  { Id: 13668, Code: '5441', Name: 'Moulder, lens' },
  { Id: 13669, Code: '5441', Name: 'Moulder, pipe, clay' },
  { Id: 13670, Code: '5441', Name: 'Moulder, plaster' },
  { Id: 13671, Code: '5441', Name: 'Moulder, tile, hand, CERAMICS' },
  { Id: 13672, Code: '5441', Name: 'Notcher, GLASSWARE' },
  { Id: 13673, Code: '5441', Name: 'Opener, piece, glass mfr' },
  { Id: 13674, Code: '5441', Name: 'Optician, manufacturing' },
  { Id: 13675, Code: '5441', Name: 'Ornamenter, ceramics mfr' },
  { Id: 13676, Code: '5441', Name: 'Painter, ceramics mfr' },
  { Id: 13677, Code: '5441', Name: 'Painter, craft' },
  { Id: 13678, Code: '5441', Name: 'Painter, enamel' },
  { Id: 13679, Code: '5441', Name: 'Painter, flower' },
  { Id: 13680, Code: '5441', Name: 'Painter, freehand' },
  { Id: 13681, Code: '5441', Name: 'Painter, glass etching' },
  { Id: 13682, Code: '5441', Name: 'Painter, glaze' },
  { Id: 13683, Code: '5441', Name: 'Painter, pottery' },
  { Id: 13684, Code: '5441', Name: 'Painter, rough, glass mfr' },
  { Id: 13685, Code: '5441', Name: 'Painter, slip' },
  { Id: 13686, Code: '5441', Name: 'Painter, spray, ceramics mfr' },
  { Id: 13687, Code: '5441', Name: 'Painter, underglaze' },
  { Id: 13688, Code: '5441', Name: 'Pantographer, glass mfr' },
  { Id: 13689, Code: '5441', Name: 'Piercer, ceramics mfr' },
  { Id: 13690, Code: '5441', Name: 'Pinner, ceramics mfr' },
  { Id: 13691, Code: '5441', Name: 'Pitcher and malletter' },
  { Id: 13692, Code: '5441', Name: 'Plasterer, plaster cast mfr' },
  { Id: 13693, Code: '5441', Name: 'Polisher, ceramics mfr' },
  { Id: 13694, Code: '5441', Name: 'Polisher, edge' },
  { Id: 13695, Code: '5441', Name: 'Polisher, enamel' },
  { Id: 13696, Code: '5441', Name: 'Polisher, fine, GLASS' },
  { Id: 13697, Code: '5441', Name: 'Polisher, glass' },
  { Id: 13698, Code: '5441', Name: 'Polisher, glass mfr' },
  { Id: 13699, Code: '5441', Name: 'Polisher, glost' },
  { Id: 13700, Code: '5441', Name: 'Polisher, gold, ceramics mfr' },
  { Id: 13701, Code: '5441', Name: 'Polisher, hand, glass mfr' },
  { Id: 13702, Code: '5441', Name: 'Polisher, lens' },
  { Id: 13703, Code: '5441', Name: 'Polisher, slab, optical' },
  { Id: 13704, Code: '5441', Name: 'Polisher, tile, ceramics mfr' },
  { Id: 13705, Code: '5441', Name: 'Potter, ceramics mfr' },
  { Id: 13706, Code: '5441', Name: 'Potter, clay' },
  { Id: 13707, Code: '5441', Name: 'Potter, zinc refining' },
  { Id: 13708, Code: '5441', Name: 'Preparer, ceramics mfr' },
  { Id: 13709, Code: '5441', Name: 'Preparer, glass mfr' },
  { Id: 13710, Code: '5441', Name: 'Presser, brick' },
  { Id: 13711, Code: '5441', Name: 'Presser, ceramics mfr' },
  { Id: 13712, Code: '5441', Name: 'Presser, die' },
  { Id: 13713, Code: '5441', Name: 'Presser, dust, ceramics mfr' },
  { Id: 13714, Code: '5441', Name: 'Presser, flat, ceramics mfr' },
  { Id: 13715, Code: '5441', Name: 'Presser, glass' },
  { Id: 13716, Code: '5441', Name: 'Presser, glass mfr' },
  { Id: 13717, Code: '5441', Name: 'Presser, hollow-ware' },
  { Id: 13718, Code: '5441', Name: 'Presser, hot, ceramics mfr' },
  { Id: 13719, Code: '5441', Name: 'Presser, pottery' },
  { Id: 13720, Code: '5441', Name: 'Presser, sagger' },
  { Id: 13721, Code: '5441', Name: 'Presser, tile, ceramics mfr' },
  { Id: 13722, Code: '5441', Name: 'Puncher, zinc smelting' },
  { Id: 13723, Code: '5441', Name: 'Remoulder, glass mfr' },
  { Id: 13724, Code: '5441', Name: 'Repairer, ceramics mfr' },
  { Id: 13725, Code: '5441', Name: 'Repairer, china' },
  { Id: 13726, Code: '5441', Name: 'Repairer, glass' },
  { Id: 13727, Code: '5441', Name: 'Restorer, ceramics mfr' },
  { Id: 13728, Code: '5441', Name: 'Restorer, PORCELAIN' },
  { Id: 13729, Code: '5441', Name: 'Riveter, CHINA' },
  { Id: 13730, Code: '5441', Name: 'Riveter, GLASS' },
  { Id: 13731, Code: '5441', Name: 'Rougher, glass mfr' },
  { Id: 13732, Code: '5441', Name: 'Rumbler, CERAMICS' },
  { Id: 13733, Code: '5441', Name: 'Runner, mould, ceramics mfr' },
  { Id: 13734, Code: '5441', Name: 'Scolloper, ceramics mfr' },
  { Id: 13735, Code: '5441', Name: 'Scourer, ceramics mfr' },
  { Id: 13736, Code: '5441', Name: 'Selector, glass' },
  { Id: 13737, Code: '5441', Name: 'Servitor, glass mfr' },
  { Id: 13738, Code: '5441', Name: 'Setter, glass mfr' },
  { Id: 13739, Code: '5441', Name: 'Setter, lens' },
  { Id: 13740, Code: '5441', Name: 'Setter, machine, optical' },
  { Id: 13741, Code: '5441', Name: 'Shaper, glass mfr' },
  { Id: 13742, Code: '5441', Name: 'Sider, glass mfr' },
  { Id: 13743, Code: '5441', Name: 'Slitter, glass, optical' },
  { Id: 13744, Code: '5441', Name: 'Smoother, lens mfr' },
  { Id: 13745, Code: '5441', Name: 'Spinner, silk, glass' },
  { Id: 13746, Code: '5441', Name: 'Sponger of clayware' },
  { Id: 13747, Code: '5441', Name: 'Sponger, ceramics mfr' },
  { Id: 13748, Code: '5441', Name: 'Sprayer, aerograph, CERAMICS' },
  { Id: 13749, Code: '5441', Name: 'Sprayer, cellulose, ceramics mfr' },
  { Id: 13750, Code: '5441', Name: 'Sprayer, ceramics mfr' },
  { Id: 13751, Code: '5441', Name: 'Sprayer, colour, ceramics mfr' },
  { Id: 13752, Code: '5441', Name: 'Sprayer, colour, glass mfr' },
  { Id: 13753, Code: '5441', Name: 'Sprayer, enamel, ceramics mfr' },
  { Id: 13754, Code: '5441', Name: 'Sprayer, glaze' },
  { Id: 13755, Code: '5441', Name: 'Sprayer, lamp, valve mfr' },
  { Id: 13756, Code: '5441', Name: 'Stainer, glass mfr' },
  { Id: 13757, Code: '5441', Name: 'Stenciller, aerographing, ceramics mfr' },
  { Id: 13758, Code: '5441', Name: 'Sticker, junction, ceramics mfr' },
  { Id: 13759, Code: '5441', Name: 'Sticker, punty' },
  { Id: 13760, Code: '5441', Name: 'Stopper, ceramics mfr' },
  { Id: 13761, Code: '5441', Name: 'Stopper, glaze' },
  { Id: 13762, Code: '5441', Name: 'Stripper, cast stone products mfr' },
  { Id: 13763, Code: '5441', Name: 'Surfacer, lens, optical' },
  { Id: 13764, Code: '5441', Name: 'Surfacer, optical' },
  { Id: 13765, Code: '5441', Name: 'Taker-off, glass mfr' },
  { Id: 13766, Code: '5441', Name: 'Technician, lens, contact' },
  { Id: 13767, Code: '5441', Name: 'Thrower, ceramics mfr' },
  { Id: 13768, Code: '5441', Name: 'Tooler, glass' },
  { Id: 13769, Code: '5441', Name: 'Toucher-up, ceramics decorating' },
  { Id: 13770, Code: '5441', Name: 'Tower, ceramics mfr' },
  { Id: 13771, Code: '5441', Name: 'Transferer, ceramics mfr' },
  { Id: 13772, Code: '5441', Name: 'Transferer, glass mfr' },
  { Id: 13773, Code: '5441', Name: 'Transferer, lithograph, ceramics mfr' },
  { Id: 13774, Code: '5441', Name: 'Trimmer, glass mfr' },
  { Id: 13775, Code: '5441', Name: 'Turner, ceramics mfr' },
  { Id: 13776, Code: '5441', Name: 'Turner, machine, pottery' },
  { Id: 13777, Code: '5441', Name: 'Turner, porcelain, electric' },
  { Id: 13778, Code: '5441', Name: 'Turner-out, glass mfr' },
  { Id: 13779, Code: '5441', Name: 'Washer, ceramics mfr' },
  { Id: 13780, Code: '5441', Name: 'Wedger, clay' },
  { Id: 13781, Code: '5441', Name: 'Worker, acid, ceramics mfr' },
  { Id: 13782, Code: '5441', Name: 'Worker, blowpipe, QUARTZ GLASS' },
  { Id: 13783, Code: '5441', Name: 'Worker, field, brick' },
  { Id: 13784, Code: '5441', Name: 'Worker, gold' },
  { Id: 13785, Code: '5441', Name: 'Worker, lamp, glass mfr' },
  { Id: 13786, Code: '5441', Name: 'Worker, lathe, glass mfr' },
  { Id: 13787, Code: '5441', Name: 'Worker, lens' },
  { Id: 13788, Code: '5441', Name: 'Worker, optical' },
  { Id: 13789, Code: '5441', Name: 'Worker, pottery' },
  { Id: 13790, Code: '5441', Name: 'Worker, prism' },
  { Id: 13791, Code: '5441', Name: 'Worker, tile, ceramics mfr' },
  { Id: 13792, Code: '5442', Name: 'Bender, stick making' },
  { Id: 13793, Code: '5442', Name: 'Bender, timber' },
  { Id: 13794, Code: '5442', Name: 'Bender, wood products mfr' },
  { Id: 13795, Code: '5442', Name: 'Blaster, sand, briar pipe mfr' },
  { Id: 13796, Code: '5442', Name: 'Bobber, WOOD PRODUCTS' },
  { Id: 13797, Code: '5442', Name: 'Bodger, chair' },
  { Id: 13798, Code: '5442', Name: 'Borer, wide, TOBACCO PIPES' },
  { Id: 13799, Code: '5442', Name: 'Bowyer' },
  { Id: 13800, Code: '5442', Name: 'Builder, barrow' },
  { Id: 13801, Code: '5442', Name: 'Builder, cart' },
  { Id: 13802, Code: '5442', Name: 'Builder, shed' },
  { Id: 13803, Code: '5442', Name: 'Builder, wheel, WOOD' },
  { Id: 13804, Code: '5442', Name: 'Carver, frame' },
  { Id: 13805, Code: '5442', Name: 'Carver, FURNITURE' },
  { Id: 13806, Code: '5442', Name: 'Carver, letter, WOOD' },
  { Id: 13807, Code: '5442', Name: 'Carver, stock, GUN' },
  { Id: 13808, Code: '5442', Name: 'Carver, wood' },
  { Id: 13809, Code: '5442', Name: 'Caseworker, piano, organ mfr' },
  { Id: 13810, Code: '5442', Name: 'Cleaver, cricket bat mfr' },
  { Id: 13811, Code: '5442', Name: 'Cleaver, lath' },
  { Id: 13812, Code: '5442', Name: 'Cleaver, wood' },
  { Id: 13813, Code: '5442', Name: 'Cooper' },
  { Id: 13814, Code: '5442', Name: 'Craftsman, museum' },
  { Id: 13815, Code: '5442', Name: 'Dresser, stick' },
  { Id: 13816, Code: '5442', Name: 'Dresser, wood' },
  { Id: 13817, Code: '5442', Name: 'Embosser, wood products mfr' },
  { Id: 13818, Code: '5442', Name: 'Engraver, marquetry' },
  { Id: 13819, Code: '5442', Name: 'Engraver, parquetry' },
  { Id: 13820, Code: '5442', Name: 'Filer, tobacco pipe mfr' },
  { Id: 13821, Code: '5442', Name: 'Finisher, coffin' },
  { Id: 13822, Code: '5442', Name: 'Finisher, peg, shuttle' },
  { Id: 13823, Code: '5442', Name: 'Finisher, spray, furniture mfr' },
  { Id: 13824, Code: '5442', Name: 'Finisher, stick mfr' },
  { Id: 13825, Code: '5442', Name: 'Fitter, cabinet' },
  { Id: 13826, Code: '5442', Name: 'Fitter, cabinet making' },
  { Id: 13827, Code: '5442', Name: 'Fitter, case, cabinet' },
  { Id: 13828, Code: '5442', Name: 'Fitter, case, piano' },
  { Id: 13829, Code: '5442', Name: 'Fitter, fittings and furniture' },
  { Id: 13830, Code: '5442', Name: 'Fitter, frame, picture frame mfr' },
  { Id: 13831, Code: '5442', Name: 'Fitter, furniture' },
  { Id: 13832, Code: '5442', Name: 'Fitter, gymnastics' },
  { Id: 13833, Code: '5442', Name: 'Fitter, overmantel' },
  {
    Id: 13834,
    Code: '5442',
    Name: 'Fitter-up, musical instruments mfr, piano case mfr'
  },
  { Id: 13835, Code: '5442', Name: 'Foreman, furniture mfr' },
  { Id: 13836, Code: '5442', Name: 'Foreman, tobacco pipe mfr' },
  { Id: 13837, Code: '5442', Name: 'Framer, chair mfr' },
  { Id: 13838, Code: '5442', Name: 'Framer, picture' },
  { Id: 13839, Code: '5442', Name: 'Framer, seat' },
  { Id: 13840, Code: '5442', Name: 'Furnisher, coffin' },
  { Id: 13841, Code: '5442', Name: 'Hand, bench, cabinet making' },
  { Id: 13842, Code: '5442', Name: 'Hinger, last' },
  { Id: 13843, Code: '5442', Name: 'Joiner, cabinet making' },
  { Id: 13844, Code: '5442', Name: 'Jointer, edge, veneer' },
  { Id: 13845, Code: '5442', Name: 'Jointer, tapeless' },
  { Id: 13846, Code: '5442', Name: 'Maker and joiner, cabinet' },
  { Id: 13847, Code: '5442', Name: 'Maker, arm, furniture mfr' },
  { Id: 13848, Code: '5442', Name: 'Maker, barrel and cask' },
  { Id: 13849, Code: '5442', Name: 'Maker, barrow' },
  { Id: 13850, Code: '5442', Name: 'Maker, bat, cricket' },
  { Id: 13851, Code: '5442', Name: 'Maker, bed, furniture mfr' },
  { Id: 13852, Code: '5442', Name: 'Maker, block, clog' },
  { Id: 13853, Code: '5442', Name: 'Maker, block, hat' },
  { Id: 13854, Code: '5442', Name: 'Maker, block, wood products mfr' },
  { Id: 13855, Code: '5442', Name: 'Maker, bobbin, WOOD' },
  { Id: 13856, Code: '5442', Name: 'Maker, bow, sports goods mfr' },
  { Id: 13857, Code: '5442', Name: 'Maker, bowl, sports goods mfr' },
  { Id: 13858, Code: '5442', Name: 'Maker, bowl, textile machinery mfr' },
  { Id: 13859, Code: '5442', Name: 'Maker, cabinet' },
  { Id: 13860, Code: '5442', Name: 'Maker, cart' },
  { Id: 13861, Code: '5442', Name: 'Maker, case, book' },
  { Id: 13862, Code: '5442', Name: 'Maker, case, cabinet' },
  { Id: 13863, Code: '5442', Name: 'Maker, case, clock, WOOD' },
  { Id: 13864, Code: '5442', Name: 'Maker, case, cutlery' },
  { Id: 13865, Code: '5442', Name: 'Maker, case, instrument' },
  { Id: 13866, Code: '5442', Name: 'Maker, case, jewel' },
  { Id: 13867, Code: '5442', Name: 'Maker, case, musical instruments mfr' },
  { Id: 13868, Code: '5442', Name: 'Maker, case, show' },
  { Id: 13869, Code: '5442', Name: 'Maker, case, television' },
  { Id: 13870, Code: '5442', Name: 'Maker, chair' },
  { Id: 13871, Code: '5442', Name: 'Maker, club, golf' },
  { Id: 13872, Code: '5442', Name: 'Maker, coffin' },
  { Id: 13873, Code: '5442', Name: 'Maker, coffin and casket' },
  { Id: 13874, Code: '5442', Name: 'Maker, cue, billiard' },
  { Id: 13875, Code: '5442', Name: 'Maker, desk' },
  { Id: 13876, Code: '5442', Name: 'Maker, drawers' },
  { Id: 13877, Code: '5442', Name: 'Maker, fence, timber' },
  { Id: 13878, Code: '5442', Name: 'Maker, float, sports goods mfr' },
  { Id: 13879, Code: '5442', Name: 'Maker, form' },
  { Id: 13880, Code: '5442', Name: 'Maker, frame, chair' },
  { Id: 13881, Code: '5442', Name: 'Maker, frame, concrete mfr' },
  { Id: 13882, Code: '5442', Name: 'Maker, frame, furniture mfr' },
  { Id: 13883, Code: '5442', Name: 'Maker, frame, mirror' },
  { Id: 13884, Code: '5442', Name: 'Maker, frame, oxon' },
  { Id: 13885, Code: '5442', Name: 'Maker, frame, photo' },
  { Id: 13886, Code: '5442', Name: 'Maker, frame, picture' },
  { Id: 13887, Code: '5442', Name: 'Maker, frame, racquet' },
  { Id: 13888, Code: '5442', Name: 'Maker, furniture' },
  { Id: 13889, Code: '5442', Name: 'Maker, furniture, bamboo' },
  { Id: 13890, Code: '5442', Name: 'Maker, furniture, cane' },
  { Id: 13891, Code: '5442', Name: 'Maker, furniture, garden' },
  { Id: 13892, Code: '5442', Name: 'Maker, furniture, wicker' },
  { Id: 13893, Code: '5442', Name: 'Maker, gate' },
  { Id: 13894, Code: '5442', Name: 'Maker, grid, wood products mfr' },
  { Id: 13895, Code: '5442', Name: 'Maker, heel, WOOD' },
  { Id: 13896, Code: '5442', Name: 'Maker, hurdle' },
  { Id: 13897, Code: '5442', Name: 'Maker, keg, WOOD' },
  { Id: 13898, Code: '5442', Name: 'Maker, ladder' },
  { Id: 13899, Code: '5442', Name: 'Maker, last' },
  { Id: 13900, Code: '5442', Name: 'Maker, letter, WOOD' },
  { Id: 13901, Code: '5442', Name: 'Maker, mould, cast concrete products mfr' },
  { Id: 13902, Code: '5442', Name: 'Maker, oar' },
  { Id: 13903, Code: '5442', Name: 'Maker, part, piano, organ mfr' },
  {
    Id: 13904,
    Code: '5442',
    Name: 'Maker, pattern, cast concrete products mfr'
  },
  { Id: 13905, Code: '5442', Name: 'Maker, peg' },
  { Id: 13906, Code: '5442', Name: 'Maker, pipe, tobacco pipe mfr' },
  { Id: 13907, Code: '5442', Name: 'Maker, racquet' },
  { Id: 13908, Code: '5442', Name: 'Maker, rod, fishing' },
  { Id: 13909, Code: '5442', Name: 'Maker, saddletree, WOOD' },
  { Id: 13910, Code: '5442', Name: 'Maker, screen, WOOD' },
  { Id: 13911, Code: '5442', Name: 'Maker, scull' },
  { Id: 13912, Code: '5442', Name: 'Maker, seat' },
  { Id: 13913, Code: '5442', Name: 'Maker, seat, cane' },
  { Id: 13914, Code: '5442', Name: 'Maker, seat, garden' },
  { Id: 13915, Code: '5442', Name: 'Maker, shed' },
  { Id: 13916, Code: '5442', Name: 'Maker, shovel, WOOD' },
  { Id: 13917, Code: '5442', Name: 'Maker, shuttle' },
  { Id: 13918, Code: '5442', Name: 'Maker, skewer, WOOD' },
  { Id: 13919, Code: '5442', Name: 'Maker, sley' },
  { Id: 13920, Code: '5442', Name: 'Maker, spar, WOOD' },
  { Id: 13921, Code: '5442', Name: 'Maker, staging, shipbuilding' },
  { Id: 13922, Code: '5442', Name: "Maker, stamp, bleacher's, WOOD" },
  { Id: 13923, Code: '5442', Name: 'Maker, stave' },
  { Id: 13924, Code: '5442', Name: 'Maker, stick, hockey' },
  { Id: 13925, Code: '5442', Name: 'Maker, stick, walking' },
  { Id: 13926, Code: '5442', Name: 'Maker, stock, gun mfr' },
  { Id: 13927, Code: '5442', Name: 'Maker, table, furniture mfr' },
  { Id: 13928, Code: '5442', Name: 'Maker, tray, WOOD' },
  { Id: 13929, Code: '5442', Name: 'Maker, vat' },
  { Id: 13930, Code: '5442', Name: 'Maker, wagon, timber' },
  { Id: 13931, Code: '5442', Name: 'Maker, wardrobe' },
  { Id: 13932, Code: '5442', Name: 'Maker, wattle' },
  { Id: 13933, Code: '5442', Name: 'Man, bench, cabinet making' },
  { Id: 13934, Code: '5442', Name: 'Manufacturer, furniture' },
  { Id: 13935, Code: '5442', Name: 'Manufacturer, furniture mfr' },
  { Id: 13936, Code: '5442', Name: 'Manufacturer, furniture, cane' },
  { Id: 13937, Code: '5442', Name: 'Manufacturer, wood products mfr' },
  { Id: 13938, Code: '5442', Name: 'Marker-off, wood products mfr' },
  { Id: 13939, Code: '5442', Name: 'Marker-out, wood products mfr' },
  { Id: 13940, Code: '5442', Name: 'Matcher, veneer' },
  { Id: 13941, Code: '5442', Name: 'Operator, jointer' },
  { Id: 13942, Code: '5442', Name: 'Painter, spray, furniture mfr' },
  { Id: 13943, Code: '5442', Name: 'Polisher, bobbin, wood' },
  { Id: 13944, Code: '5442', Name: 'Polisher, heald' },
  { Id: 13945, Code: '5442', Name: 'Polisher, pipe, WOOD' },
  { Id: 13946, Code: '5442', Name: 'Preparer, veneer' },
  { Id: 13947, Code: '5442', Name: 'Pumicer, TOBACCO PIPES' },
  { Id: 13948, Code: '5442', Name: 'Pumicer, WOOD' },
  { Id: 13949, Code: '5442', Name: 'Renovator, antiques' },
  { Id: 13950, Code: '5442', Name: 'Renovator, FURNITURE' },
  { Id: 13951, Code: '5442', Name: 'Repairer, antiques' },
  { Id: 13952, Code: '5442', Name: 'Repairer, chair' },
  { Id: 13953, Code: '5442', Name: 'Repairer, furniture' },
  { Id: 13954, Code: '5442', Name: 'Repairer, pipe, TOBACCO PIPES' },
  { Id: 13955, Code: '5442', Name: 'Restorer, antiques' },
  { Id: 13956, Code: '5442', Name: 'Restorer, furniture' },
  { Id: 13957, Code: '5442', Name: 'Seater, chair' },
  { Id: 13958, Code: '5442', Name: 'Splicer, veneer' },
  { Id: 13959, Code: '5442', Name: 'Sprayer, cellulose, furniture mfr' },
  { Id: 13960, Code: '5442', Name: 'Sprayer, furniture mfr' },
  { Id: 13961, Code: '5442', Name: 'Sprayer, paint, furniture mfr' },
  { Id: 13962, Code: '5442', Name: 'Stocker, gun mfr' },
  { Id: 13963, Code: '5442', Name: 'Weighter, GOLF CLUB HEADS' },
  { Id: 13964, Code: '5442', Name: 'Wheeler, wheelwrights' },
  { Id: 13965, Code: '5442', Name: 'Wheelwright' },
  { Id: 13966, Code: '5442', Name: 'Woodworker' },
  { Id: 13967, Code: '5442', Name: 'Woodwright' },
  { Id: 13968, Code: '5442', Name: 'Worker, bench, cabinet making' },
  { Id: 13969, Code: '5442', Name: 'Worker, cabinet' },
  { Id: 13970, Code: '5442', Name: 'Worker, case, piano, organ mfr' },
  { Id: 13971, Code: '5443', Name: 'Arranger, floral' },
  { Id: 13972, Code: '5443', Name: 'Arranger, flower' },
  { Id: 13973, Code: '5443', Name: 'Artist, floral' },
  { Id: 13974, Code: '5443', Name: 'Assistant, floral' },
  { Id: 13975, Code: '5443', Name: 'Decorator, floral' },
  { Id: 13976, Code: '5443', Name: 'Designer, floral' },
  { Id: 13977, Code: '5443', Name: 'Florist' },
  { Id: 13978, Code: '5443', Name: 'Florist, artificial' },
  { Id: 13979, Code: '5443', Name: 'Maker, wreath, artificial' },
  { Id: 13980, Code: '5443', Name: 'Technician, floral' },
  { Id: 13981, Code: '5449', Name: 'Artist, mask mfr' },
  { Id: 13982, Code: '5449', Name: 'Assembler, action, piano' },
  { Id: 13983, Code: '5449', Name: 'Assembler, pianoforte' },
  { Id: 13984, Code: '5449', Name: 'Assembler, silver goods mfr' },
  { Id: 13985, Code: '5449', Name: 'Assembler, wax, aircraft mfr' },
  { Id: 13986, Code: '5449', Name: 'Balancer, abrasives mfr' },
  { Id: 13987, Code: '5449', Name: 'Balancer, wheel, abrasives mfr' },
  { Id: 13988, Code: '5449', Name: 'Beater, gold' },
  { Id: 13989, Code: '5449', Name: 'Beater, silver' },
  { Id: 13990, Code: '5449', Name: 'Bellyman, piano' },
  { Id: 13991, Code: '5449', Name: 'Binder, broom, brush mfr' },
  { Id: 13992, Code: '5449', Name: 'Borer, broom, brush mfr' },
  { Id: 13993, Code: '5449', Name: 'Borer, brush' },
  { Id: 13994, Code: '5449', Name: 'Braider, basket mfr' },
  { Id: 13995, Code: '5449', Name: 'Braider, whip' },
  { Id: 13996, Code: '5449', Name: 'Builder, organ' },
  { Id: 13997, Code: '5449', Name: 'Caner' },
  { Id: 13998, Code: '5449', Name: 'Capper, cartridge mfr' },
  { Id: 13999, Code: '5449', Name: 'Carver, gold' },
  { Id: 14000, Code: '5449', Name: 'Carver, ivory' },
  { Id: 14001, Code: '5449', Name: 'Carver, letter, BRASS' },
  { Id: 14002, Code: '5449', Name: 'Charger, cartridge' },
  { Id: 14003, Code: '5449', Name: 'Charger, cordite' },
  { Id: 14004, Code: '5449', Name: 'Charger, fireworks mfr' },
  { Id: 14005, Code: '5449', Name: 'Chaser, gold' },
  {
    Id: 14006,
    Code: '5449',
    Name: 'Chaser, manufacturing: jewellery, plate mfr'
  },
  { Id: 14007, Code: '5449', Name: 'Chaser, METAL, PRECIOUS METAL' },
  { Id: 14008, Code: '5449', Name: 'Chaser, platework' },
  { Id: 14009, Code: '5449', Name: 'Chaser, silver' },
  { Id: 14010, Code: '5449', Name: 'Checker, piano, organ mfr' },
  { Id: 14011, Code: '5449', Name: 'Cleaver, diamond' },
  { Id: 14012, Code: '5449', Name: 'Cleaver, PRECIOUS STONES' },
  { Id: 14013, Code: '5449', Name: 'Colourman, artist' },
  { Id: 14014, Code: '5449', Name: 'Craftsman, government' },
  { Id: 14015, Code: '5449', Name: 'Craftsman, research and development' },
  { Id: 14016, Code: '5449', Name: 'Cutler, silver' },
  { Id: 14017, Code: '5449', Name: 'Cutter and booker' },
  { Id: 14018, Code: '5449', Name: 'Cutter, BONE' },
  { Id: 14019, Code: '5449', Name: 'Cutter, candle mfr' },
  { Id: 14020, Code: '5449', Name: 'Cutter, cork' },
  { Id: 14021, Code: '5449', Name: 'Cutter, cotton' },
  { Id: 14022, Code: '5449', Name: 'Cutter, diamond' },
  { Id: 14023, Code: '5449', Name: 'Cutter, fancy goods mfr' },
  { Id: 14024, Code: '5449', Name: 'Cutter, leaf, PRECIOUS METALS' },
  { Id: 14025, Code: '5449', Name: 'Cutter, plate, ENGRAVING' },
  { Id: 14026, Code: '5449', Name: 'Cutter, PRECIOUS STONES' },
  { Id: 14027, Code: '5449', Name: 'Docker, cork' },
  { Id: 14028, Code: '5449', Name: 'Drawer, brush' },
  { Id: 14029, Code: '5449', Name: 'Dresser, diamond' },
  { Id: 14030, Code: '5449', Name: 'Dresser, doll' },
  { Id: 14031, Code: '5449', Name: 'Dresser, fly' },
  { Id: 14032, Code: '5449', Name: 'Driller, brush' },
  { Id: 14033, Code: '5449', Name: 'Driller, die, diamond' },
  { Id: 14034, Code: '5449', Name: 'Driller, wire, diamond' },
  { Id: 14035, Code: '5449', Name: 'Embosser, hilt, SWORD' },
  { Id: 14036, Code: '5449', Name: 'Embosser, jewellery, plate mfr' },
  { Id: 14037, Code: '5449', Name: 'Engraver' },
  { Id: 14038, Code: '5449', Name: 'Engraver, brass' },
  { Id: 14039, Code: '5449', Name: 'Engraver, gold' },
  { Id: 14040, Code: '5449', Name: 'Engraver, hand' },
  { Id: 14041, Code: '5449', Name: 'Engraver, instrument' },
  { Id: 14042, Code: '5449', Name: 'Engraver, machine, instrument mfr' },
  { Id: 14043, Code: '5449', Name: 'Engraver, machine, jewellery, plate mfr' },
  { Id: 14044, Code: '5449', Name: 'Engraver, metal' },
  { Id: 14045, Code: '5449', Name: 'Engraver, micrometer' },
  { Id: 14046, Code: '5449', Name: 'Engraver, plate, PRECIOUS METALS' },
  { Id: 14047, Code: '5449', Name: 'Engraver, silver' },
  { Id: 14048, Code: '5449', Name: 'Engraver-etcher' },
  { Id: 14049, Code: '5449', Name: 'Etcher, INTEGRATED, PRINTED CIRCUITS' },
  { Id: 14050, Code: '5449', Name: 'Etcher, jewellery, plate mfr' },
  { Id: 14051, Code: '5449', Name: 'Exhauster, lamp, valve mfr' },
  { Id: 14052, Code: '5449', Name: 'Fabricator, sign' },
  { Id: 14053, Code: '5449', Name: 'Feeder, pallet' },
  { Id: 14054, Code: '5449', Name: 'Felter, PRINTING ROLLERS' },
  { Id: 14055, Code: '5449', Name: 'Filler, brush' },
  { Id: 14056, Code: '5449', Name: 'Filler, cartridge' },
  { Id: 14057, Code: '5449', Name: 'Filler, detonator' },
  { Id: 14058, Code: '5449', Name: 'Filler, explosives mfr' },
  { Id: 14059, Code: '5449', Name: 'Filler, fireworks mfr' },
  { Id: 14060, Code: '5449', Name: 'Filler, machine, broom, brush mfr' },
  { Id: 14061, Code: '5449', Name: 'Filler, ordnance factory' },
  { Id: 14062, Code: '5449', Name: 'Filler, rocket' },
  { Id: 14063, Code: '5449', Name: 'Finisher, artificial teeth mfr' },
  { Id: 14064, Code: '5449', Name: 'Finisher, cord, TELEPHONE' },
  { Id: 14065, Code: '5449', Name: 'Finisher, fishing rod mfr' },
  { Id: 14066, Code: '5449', Name: 'Finisher, incandescent mantle mfr' },
  { Id: 14067, Code: '5449', Name: 'Finisher, musical instruments mfr' },
  { Id: 14068, Code: '5449', Name: 'Finisher, piano key mfr' },
  { Id: 14069, Code: '5449', Name: 'Finisher, piano, organ mfr' },
  { Id: 14070, Code: '5449', Name: 'Finisher, precious metal, plate mfr' },
  { Id: 14071, Code: '5449', Name: 'Finisher, racquet' },
  { Id: 14072, Code: '5449', Name: 'Finisher, reed' },
  { Id: 14073, Code: '5449', Name: 'Finisher, rod, fishing' },
  { Id: 14074, Code: '5449', Name: 'Finisher, silver' },
  { Id: 14075, Code: '5449', Name: 'Finisher, spoon and fork' },
  { Id: 14076, Code: '5449', Name: 'Finisher, tooth brush mfr' },
  { Id: 14077, Code: '5449', Name: "Fitter, depositor's" },
  { Id: 14078, Code: '5449', Name: 'Fitter, fishing rod mfr' },
  { Id: 14079, Code: '5449', Name: 'Fitter, instrument, musical' },
  { Id: 14080, Code: '5449', Name: 'Fitter, jewellery, plate mfr' },
  { Id: 14081, Code: '5449', Name: 'Fitter, musical instruments mfr' },
  { Id: 14082, Code: '5449', Name: 'Fitter, organ' },
  { Id: 14083, Code: '5449', Name: 'Fitter, piano' },
  { Id: 14084, Code: '5449', Name: 'Fitter, table, billiard' },
  { Id: 14085, Code: '5449', Name: 'Fitter-up, musical instruments mfr' },
  { Id: 14086, Code: '5449', Name: 'Fixer, lens' },
  { Id: 14087, Code: '5449', Name: 'Flatter, gold' },
  { Id: 14088, Code: '5449', Name: 'Flatter, silver' },
  { Id: 14089, Code: '5449', Name: 'Fluter, SILVER, PLATE' },
  { Id: 14090, Code: '5449', Name: 'Foreman of factory, government' },
  { Id: 14091, Code: '5449', Name: 'Foreman, broom, brush mfr' },
  { Id: 14092, Code: '5449', Name: 'Foreman, cork mfr' },
  { Id: 14093, Code: '5449', Name: 'Foreman, fancy goods mfr' },
  { Id: 14094, Code: '5449', Name: 'Foreman, jewellery, plate mfr' },
  { Id: 14095, Code: '5449', Name: 'Foreman, match mfr' },
  { Id: 14096, Code: '5449', Name: 'Foreman, ordnance factory: shell filling' },
  { Id: 14097, Code: '5449', Name: 'Foreman, pen mfr' },
  { Id: 14098, Code: '5449', Name: 'Foreman, pencil, crayon mfr' },
  { Id: 14099, Code: '5449', Name: 'Foreman, shop, pattern' },
  { Id: 14100, Code: '5449', Name: 'Foreman, sports goods mfr' },
  { Id: 14101, Code: '5449', Name: 'Foreman, toy mfr' },
  { Id: 14102, Code: '5449', Name: 'Furnisher, house' },
  { Id: 14103, Code: '5449', Name: 'Girl, taper' },
  { Id: 14104, Code: '5449', Name: 'Goldsmith' },
  { Id: 14105, Code: '5449', Name: 'Grinder, disc, abrasive wheel mfr' },
  { Id: 14106, Code: '5449', Name: 'Grinder, edge, abrasive wheel mfr' },
  { Id: 14107, Code: '5449', Name: 'Hammerer, precious metal, plate mfr' },
  { Id: 14108, Code: '5449', Name: 'Hammerman, precious metal, plate mfr' },
  { Id: 14109, Code: '5449', Name: 'Hand, brush, broom, brush mfr' },
  { Id: 14110, Code: '5449', Name: 'Hand, display, fireworks mfr' },
  { Id: 14111, Code: '5449', Name: 'Hand, loom, loom furniture mfr' },
  { Id: 14112, Code: '5449', Name: 'Hand, pan, broom, brush mfr' },
  { Id: 14113, Code: '5449', Name: 'Jeweller, jobbing' },
  { Id: 14114, Code: '5449', Name: 'Jeweller, manufacturing' },
  { Id: 14115, Code: '5449', Name: 'Jeweller, MANUFACTURING, REPAIRING' },
  { Id: 14116, Code: '5449', Name: 'Jeweller, masonic' },
  { Id: 14117, Code: '5449', Name: 'Jobber, jewellery, plate mfr' },
  { Id: 14118, Code: '5449', Name: 'Knotter, wig mfr' },
  { Id: 14119, Code: '5449', Name: 'Lacquerer, spray' },
  { Id: 14120, Code: '5449', Name: 'Lapidary' },
  { Id: 14121, Code: '5449', Name: 'Linker, chain' },
  { Id: 14122, Code: '5449', Name: 'Loader, ammunition mfr' },
  { Id: 14123, Code: '5449', Name: 'Loader, cartridge' },
  { Id: 14124, Code: '5449', Name: 'Luthier' },
  { Id: 14125, Code: '5449', Name: 'Machinist, musical instruments mfr' },
  { Id: 14126, Code: '5449', Name: 'Maker, action, piano, organ mfr' },
  { Id: 14127, Code: '5449', Name: 'Maker, appliance, orthopaedic' },
  { Id: 14128, Code: '5449', Name: 'Maker, appliance, surgical' },
  { Id: 14129, Code: '5449', Name: 'Maker, badge' },
  { Id: 14130, Code: '5449', Name: 'Maker, bait' },
  { Id: 14131, Code: '5449', Name: 'Maker, basket' },
  { Id: 14132, Code: '5449', Name: 'Maker, bear, teddy' },
  { Id: 14133, Code: '5449', Name: 'Maker, belly, PIANO' },
  { Id: 14134, Code: '5449', Name: 'Maker, belt, life' },
  { Id: 14135, Code: '5449', Name: 'Maker, belt, surgical' },
  { Id: 14136, Code: '5449', Name: 'Maker, besom' },
  { Id: 14137, Code: '5449', Name: 'Maker, blind' },
  { Id: 14138, Code: '5449', Name: 'Maker, bow, musical instruments mfr' },
  { Id: 14139, Code: '5449', Name: 'Maker, bracelet' },
  { Id: 14140, Code: '5449', Name: 'Maker, brooch' },
  { Id: 14141, Code: '5449', Name: 'Maker, broom' },
  { Id: 14142, Code: '5449', Name: 'Maker, brush' },
  { Id: 14143, Code: '5449', Name: 'Maker, candle' },
  { Id: 14144, Code: '5449', Name: 'Maker, cartridge' },
  { Id: 14145, Code: '5449', Name: 'Maker, case, cigarette, PRECIOUS METALS' },
  { Id: 14146, Code: '5449', Name: 'Maker, case, spectacle' },
  { Id: 14147, Code: '5449', Name: 'Maker, chain, METAL, PRECIOUS METAL' },
  { Id: 14148, Code: '5449', Name: 'Maker, detonator' },
  { Id: 14149, Code: '5449', Name: 'Maker, door, fireproof' },
  { Id: 14150, Code: '5449', Name: 'Maker, drum, musical instruments mfr' },
  { Id: 14151, Code: '5449', Name: 'Maker, felt, piano, organ mfr' },
  { Id: 14152, Code: '5449', Name: 'Maker, figure, wax' },
  { Id: 14153, Code: '5449', Name: 'Maker, firework' },
  { Id: 14154, Code: '5449', Name: 'Maker, fireworks' },
  { Id: 14155, Code: '5449', Name: 'Maker, flower, artificial' },
  { Id: 14156, Code: '5449', Name: 'Maker, fly, sports goods mfr' },
  { Id: 14157, Code: '5449', Name: 'Maker, gasket' },
  { Id: 14158, Code: '5449', Name: 'Maker, glockenspiel' },
  { Id: 14159, Code: '5449', Name: 'Maker, goods, fancy' },
  { Id: 14160, Code: '5449', Name: 'Maker, hammer, piano, organ mfr' },
  { Id: 14161, Code: '5449', Name: 'Maker, handle, precious metal, plate mfr' },
  { Id: 14162, Code: '5449', Name: 'Maker, harp' },
  {
    Id: 14163,
    Code: '5449',
    Name: 'Maker, hollow-ware, precious metal, plate mfr'
  },
  { Id: 14164, Code: '5449', Name: 'Maker, hone' },
  { Id: 14165, Code: '5449', Name: 'Maker, instrument, musical' },
  { Id: 14166, Code: '5449', Name: 'Maker, instrument, MUSICAL INSTRUMENTS' },
  { Id: 14167, Code: '5449', Name: 'Maker, jewellery' },
  { Id: 14168, Code: '5449', Name: 'Maker, key, musical instruments mfr' },
  { Id: 14169, Code: '5449', Name: 'Maker, limb, artificial' },
  { Id: 14170, Code: '5449', Name: 'Maker, locket, PRECIOUS METALS' },
  { Id: 14171, Code: '5449', Name: 'Maker, mask' },
  { Id: 14172, Code: '5449', Name: 'Maker, mat, sinnet' },
  { Id: 14173, Code: '5449', Name: 'Maker, mattress, asbestos' },
  { Id: 14174, Code: '5449', Name: 'Maker, model, ANIMATION' },
  { Id: 14175, Code: '5449', Name: 'Maker, model, display' },
  { Id: 14176, Code: '5449', Name: 'Maker, model, engineering' },
  {
    Id: 14177,
    Code: '5449',
    Name: 'Maker, model, film, television production'
  },
  { Id: 14178, Code: '5449', Name: 'Maker, model, jewellery' },
  { Id: 14179, Code: '5449', Name: 'Maker, mop' },
  { Id: 14180, Code: '5449', Name: 'Maker, mould, asbestos-cement goods mfr' },
  { Id: 14181, Code: '5449', Name: 'Maker, musical instruments mfr' },
  { Id: 14182, Code: '5449', Name: 'Maker, pad, basket mfr' },
  { Id: 14183, Code: '5449', Name: 'Maker, pad, stamping' },
  { Id: 14184, Code: '5449', Name: 'Maker, pattern, coal mine' },
  { Id: 14185, Code: '5449', Name: "Maker, pattern, engineer's" },
  { Id: 14186, Code: '5449', Name: 'Maker, pattern, jewellery, plate mfr' },
  { Id: 14187, Code: '5449', Name: 'Maker, pattern, metal trades' },
  { Id: 14188, Code: '5449', Name: 'Maker, pattern, wood' },
  { Id: 14189, Code: '5449', Name: 'Maker, piano' },
  { Id: 14190, Code: '5449', Name: 'Maker, plate, precious metal, plate mfr' },
  { Id: 14191, Code: '5449', Name: 'Maker, poppy' },
  { Id: 14192, Code: '5449', Name: 'Maker, prop' },
  { Id: 14193, Code: '5449', Name: 'Maker, props' },
  { Id: 14194, Code: '5449', Name: 'Maker, reed, musical instruments mfr' },
  { Id: 14195, Code: '5449', Name: 'Maker, reed, textile machinery mfr' },
  { Id: 14196, Code: '5449', Name: 'Maker, reel, fishing' },
  { Id: 14197, Code: '5449', Name: 'Maker, ring, jump' },
  { Id: 14198, Code: '5449', Name: 'Maker, ring, precious metal, plate mfr' },
  { Id: 14199, Code: '5449', Name: 'Maker, ring, wax' },
  { Id: 14200, Code: '5449', Name: 'Maker, rocket' },
  { Id: 14201, Code: '5449', Name: 'Maker, roller, piano, organ mfr' },
  { Id: 14202, Code: '5449', Name: 'Maker, shade, lamp' },
  { Id: 14203, Code: '5449', Name: 'Maker, shuttlecock' },
  { Id: 14204, Code: '5449', Name: 'Maker, sign' },
  { Id: 14205, Code: '5449', Name: 'Maker, sign, ELECTRIC' },
  { Id: 14206, Code: '5449', Name: 'Maker, sign, METAL' },
  { Id: 14207, Code: '5449', Name: 'Maker, sign, NEON' },
  { Id: 14208, Code: '5449', Name: 'Maker, sign, PERSPEX' },
  { Id: 14209, Code: '5449', Name: 'Maker, sign, WOOD' },
  { Id: 14210, Code: '5449', Name: 'Maker, skep' },
  { Id: 14211, Code: '5449', Name: 'Maker, skip' },
  { Id: 14212, Code: '5449', Name: 'Maker, sports goods mfr' },
  { Id: 14213, Code: '5449', Name: 'Maker, stone, artificial' },
  { Id: 14214, Code: '5449', Name: 'Maker, stone, patent' },
  { Id: 14215, Code: '5449', Name: 'Maker, stone, rubbing' },
  { Id: 14216, Code: '5449', Name: 'Maker, string, gut mfr' },
  { Id: 14217, Code: '5449', Name: 'Maker, string, METAL' },
  { Id: 14218, Code: '5449', Name: 'Maker, table, billiard' },
  {
    Id: 14219,
    Code: '5449',
    Name: 'Maker, thimble, precious metal, plate mfr'
  },
  { Id: 14220, Code: '5449', Name: 'Maker, toy' },
  { Id: 14221, Code: '5449', Name: 'Maker, tray, WICKER' },
  { Id: 14222, Code: '5449', Name: 'Maker, trophy' },
  { Id: 14223, Code: '5449', Name: 'Maker, truss, surgical goods mfr' },
  { Id: 14224, Code: '5449', Name: 'Maker, violin' },
  { Id: 14225, Code: '5449', Name: 'Maker, wig' },
  { Id: 14226, Code: '5449', Name: "Maker-up, jeweller's" },
  { Id: 14227, Code: '5449', Name: 'Maker-up, silver, plate mfr' },
  { Id: 14228, Code: '5449', Name: 'Man, jobbing' },
  { Id: 14229, Code: '5449', Name: 'Man, mould, cast concrete products mfr' },
  { Id: 14230, Code: '5449', Name: 'Manufacturer, badge' },
  { Id: 14231, Code: '5449', Name: 'Manufacturer, basketry mfr' },
  { Id: 14232, Code: '5449', Name: 'Manufacturer, broom' },
  { Id: 14233, Code: '5449', Name: 'Manufacturer, broom, brush mfr' },
  { Id: 14234, Code: '5449', Name: 'Manufacturer, brush' },
  { Id: 14235, Code: '5449', Name: 'Manufacturer, fancy goods mfr' },
  { Id: 14236, Code: '5449', Name: 'Manufacturer, instrument, musical' },
  { Id: 14237, Code: '5449', Name: 'Manufacturer, jewellery' },
  { Id: 14238, Code: '5449', Name: 'Manufacturer, jewellery, plate mfr' },
  { Id: 14239, Code: '5449', Name: 'Manufacturer, musical instruments mfr' },
  { Id: 14240, Code: '5449', Name: 'Manufacturer, rosette' },
  { Id: 14241, Code: '5449', Name: 'Manufacturer, sports goods mfr' },
  { Id: 14242, Code: '5449', Name: 'Manufacturer, surgical goods mfr' },
  { Id: 14243, Code: '5449', Name: 'Manufacturer, toy' },
  { Id: 14244, Code: '5449', Name: 'Manufacturer, toy mfr' },
  { Id: 14245, Code: '5449', Name: 'Marker, metal file mfr' },
  { Id: 14246, Code: '5449', Name: 'Marker-off, piano' },
  { Id: 14247, Code: '5449', Name: 'Matcher, hair' },
  { Id: 14248, Code: '5449', Name: 'Mechanic, MUSICAL INSTRUMENTS' },
  { Id: 14249, Code: '5449', Name: 'Mechanic, orthopaedic' },
  { Id: 14250, Code: '5449', Name: 'Mechanic, pen' },
  { Id: 14251, Code: '5449', Name: 'Miller, diamond, jewellery, plate mfr' },
  { Id: 14252, Code: '5449', Name: 'Modeller' },
  { Id: 14253, Code: '5449', Name: 'Modeller, styling, MOTOR VEHICLES' },
  { Id: 14254, Code: '5449', Name: 'Modeller, wax' },
  { Id: 14255, Code: '5449', Name: 'Moulder, abrasive' },
  { Id: 14256, Code: '5449', Name: 'Moulder, abrasives mfr' },
  { Id: 14257, Code: '5449', Name: 'Moulder, brake linings mfr' },
  { Id: 14258, Code: '5449', Name: 'Moulder, candle mfr' },
  { Id: 14259, Code: '5449', Name: 'Moulder, carborundum' },
  { Id: 14260, Code: '5449', Name: 'Moulder, lead pencil, chalk, crayon mfr' },
  { Id: 14261, Code: '5449', Name: 'Moulder, stone, patent' },
  { Id: 14262, Code: '5449', Name: 'Moulder, tooth' },
  { Id: 14263, Code: '5449', Name: 'Moulder, wheel, abrasive' },
  { Id: 14264, Code: '5449', Name: 'Moulder, wheel, abrasive wheel mfr' },
  { Id: 14265, Code: '5449', Name: 'Mounter, diamond' },
  { Id: 14266, Code: '5449', Name: 'Mounter, gold' },
  { Id: 14267, Code: '5449', Name: 'Mounter, jewellery mfr' },
  { Id: 14268, Code: '5449', Name: 'Mounter, metal' },
  { Id: 14269, Code: '5449', Name: 'Mounter, picture' },
  { Id: 14270, Code: '5449', Name: 'Mounter, precious metal, plate mfr' },
  { Id: 14271, Code: '5449', Name: 'Mounter, silver' },
  { Id: 14272, Code: '5449', Name: 'Operator, sheathing, EXPLOSIVES' },
  { Id: 14273, Code: '5449', Name: 'Overlooker, wire' },
  { Id: 14274, Code: '5449', Name: 'Packer, parachute' },
  { Id: 14275, Code: '5449', Name: "Painter, engraver's, textile printing" },
  { Id: 14276, Code: '5449', Name: 'Painter, roller engraving' },
  { Id: 14277, Code: '5449', Name: 'Painter, roller, textile printing' },
  { Id: 14278, Code: '5449', Name: 'Painter, spray' },
  { Id: 14279, Code: '5449', Name: 'Painter, tin' },
  { Id: 14280, Code: '5449', Name: 'Paperer, sand, mask mfr' },
  { Id: 14281, Code: '5449', Name: 'Piercer, jewellery, plate mfr' },
  { Id: 14282, Code: '5449', Name: 'Piercer, saw' },
  { Id: 14283, Code: '5449', Name: 'Pleater, incandescent mantle mfr' },
  { Id: 14284, Code: '5449', Name: 'Polisher, diamond' },
  { Id: 14285, Code: '5449', Name: 'Polisher, ivory' },
  { Id: 14286, Code: '5449', Name: 'Polisher, key, piano, organ mfr' },
  { Id: 14287, Code: '5449', Name: 'Posticheur' },
  { Id: 14288, Code: '5449', Name: 'Preparer, hair, wig mfr' },
  { Id: 14289, Code: '5449', Name: 'Presser, hand, sports goods mfr' },
  { Id: 14290, Code: '5449', Name: 'Presser, stone, artificial' },
  { Id: 14291, Code: '5449', Name: 'Primer, cap' },
  { Id: 14292, Code: '5449', Name: 'Primer, cartridge mfr' },
  { Id: 14293, Code: '5449', Name: 'Printer, spray' },
  { Id: 14294, Code: '5449', Name: 'Puller, pallet' },
  { Id: 14295, Code: '5449', Name: 'Pumicer, HORN' },
  { Id: 14296, Code: '5449', Name: 'Puncher, shade, lamp' },
  { Id: 14297, Code: '5449', Name: 'Pyrotechnician' },
  { Id: 14298, Code: '5449', Name: 'Pyrotechnist' },
  { Id: 14299, Code: '5449', Name: 'Regulator, piano, organ mfr' },
  { Id: 14300, Code: '5449', Name: 'Repairer, blind' },
  { Id: 14301, Code: '5449', Name: 'Repairer, case, WATCH' },
  { Id: 14302, Code: '5449', Name: 'Repairer, film' },
  { Id: 14303, Code: '5449', Name: 'Repairer, instrument, musical' },
  {
    Id: 14304,
    Code: '5449',
    Name: 'Repairer, instrument, MUSICAL INSTRUMENTS'
  },
  { Id: 14305, Code: '5449', Name: 'Repairer, JEWELLERY' },
  { Id: 14306, Code: '5449', Name: 'Repairer, jewellery and plate' },
  { Id: 14307, Code: '5449', Name: 'Repairer, MUSICAL INSTRUMENTS' },
  { Id: 14308, Code: '5449', Name: 'Repairer, pen, fountain' },
  { Id: 14309, Code: '5449', Name: 'Repairer, PRECIOUS METAL, PLATE' },
  { Id: 14310, Code: '5449', Name: 'Repairer, reed' },
  { Id: 14311, Code: '5449', Name: 'Repairer, SPORTS GOODS' },
  { Id: 14312, Code: '5449', Name: 'Repairer, toy' },
  { Id: 14313, Code: '5449', Name: 'Restorer, horse, rocking' },
  { Id: 14314, Code: '5449', Name: 'Restorer, MUSICAL INSTRUMENTS' },
  { Id: 14315, Code: '5449', Name: 'Roller, cross' },
  { Id: 14316, Code: '5449', Name: 'Sawyer, BONE, IVORY' },
  { Id: 14317, Code: '5449', Name: 'Sawyer, diamond' },
  { Id: 14318, Code: '5449', Name: 'Sawyer, ivory' },
  { Id: 14319, Code: '5449', Name: 'Setter, arc welding electrode mfr' },
  { Id: 14320, Code: '5449', Name: 'Setter, broom, brush mfr' },
  { Id: 14321, Code: '5449', Name: 'Setter, diamond' },
  { Id: 14322, Code: '5449', Name: 'Setter, gem' },
  { Id: 14323, Code: '5449', Name: 'Setter, jewel' },
  { Id: 14324, Code: '5449', Name: 'Setter, jewellery, plate mfr' },
  { Id: 14325, Code: '5449', Name: 'Setter, machine, broom, brush mfr' },
  { Id: 14326, Code: '5449', Name: 'Setter, machine, button mfr' },
  { Id: 14327, Code: '5449', Name: 'Setter, machine, electrode' },
  { Id: 14328, Code: '5449', Name: 'Setter, machine, grid' },
  { Id: 14329, Code: '5449', Name: 'Setter, machine, metalising' },
  { Id: 14330, Code: '5449', Name: 'Setter, machine, sealing' },
  { Id: 14331, Code: '5449', Name: 'Setter, reed' },
  { Id: 14332, Code: '5449', Name: 'Setter, stone, JEWELLERY' },
  { Id: 14333, Code: '5449', Name: 'Setter-operator, engraving, pantograph' },
  { Id: 14334, Code: '5449', Name: 'Setter-up, diamond polishing' },
  { Id: 14335, Code: '5449', Name: 'Shaper, incandescent mantle mfr' },
  { Id: 14336, Code: '5449', Name: 'Silversmith' },
  { Id: 14337, Code: '5449', Name: 'Smith, bright' },
  { Id: 14338, Code: '5449', Name: 'Smith, gold' },
  { Id: 14339, Code: '5449', Name: 'Smith, platinum' },
  { Id: 14340, Code: '5449', Name: 'Smith, silver' },
  { Id: 14341, Code: '5449', Name: 'Sprayer, paint' },
  { Id: 14342, Code: '5449', Name: 'Stringer, piano, organ mfr' },
  { Id: 14343, Code: '5449', Name: 'Stringer, racquet' },
  { Id: 14344, Code: '5449', Name: 'Stringer, sports goods mfr' },
  { Id: 14345, Code: '5449', Name: 'Superintendent, Royal Mint' },
  { Id: 14346, Code: '5449', Name: 'Surfacer, shot, steel' },
  { Id: 14347, Code: '5449', Name: 'Taper, coil' },
  { Id: 14348, Code: '5449', Name: 'Technician, instrument, musical' },
  { Id: 14349, Code: '5449', Name: 'Technician, piano' },
  { Id: 14350, Code: '5449', Name: 'Tester, musical instruments mfr' },
  { Id: 14351, Code: '5449', Name: 'Tester-rectifier, instrument, musical' },
  { Id: 14352, Code: '5449', Name: 'Trimmer, artificial teeth mfr' },
  { Id: 14353, Code: '5449', Name: 'Trimmer, broom, brush mfr' },
  { Id: 14354, Code: '5449', Name: 'Trimmer, powder puff mfr' },
  { Id: 14355, Code: '5449', Name: 'Trimmer, soap' },
  { Id: 14356, Code: '5449', Name: 'Trimmer, tooth' },
  { Id: 14357, Code: '5449', Name: 'Tuner, MUSICAL INSTRUMENTS' },
  { Id: 14358, Code: '5449', Name: 'Tuner, piano' },
  { Id: 14359, Code: '5449', Name: 'Turner, asbestos' },
  { Id: 14360, Code: '5449', Name: 'Turner, cork' },
  { Id: 14361, Code: '5449', Name: 'Turner, engine, jewellery, plate mfr' },
  { Id: 14362, Code: '5449', Name: 'Turner, engine, rose' },
  { Id: 14363, Code: '5449', Name: 'Turner, ivory' },
  { Id: 14364, Code: '5449', Name: 'Turner, precious metal, plate mfr' },
  { Id: 14365, Code: '5449', Name: 'Turner, wheel, abrasives mfr' },
  { Id: 14366, Code: '5449', Name: 'Varnisher, spray' },
  { Id: 14367, Code: '5449', Name: 'Voicer, organ' },
  { Id: 14368, Code: '5449', Name: 'Weaver, basketry mfr' },
  { Id: 14369, Code: '5449', Name: 'Weaver, cane' },
  { Id: 14370, Code: '5449', Name: 'Weaver, hair, wig mfr' },
  { Id: 14371, Code: '5449', Name: 'Weaver, wig mfr' },
  { Id: 14372, Code: '5449', Name: 'Weaver, withy' },
  { Id: 14373, Code: '5449', Name: 'Wirer, artificial flower mfr' },
  { Id: 14374, Code: '5449', Name: 'Worker, basket' },
  { Id: 14375, Code: '5449', Name: 'Worker, brush' },
  { Id: 14376, Code: '5449', Name: 'Worker, cane' },
  { Id: 14377, Code: '5449', Name: 'Worker, carbon' },
  { Id: 14378, Code: '5449', Name: 'Worker, craft' },
  { Id: 14379, Code: '5449', Name: 'Worker, crystal, quartz' },
  { Id: 14380, Code: '5449', Name: 'Worker, detonator, chemical mfr' },
  { Id: 14381, Code: '5449', Name: 'Worker, jet' },
  { Id: 14382, Code: '5449', Name: 'Worker, metal, precious' },
  { Id: 14383, Code: '5449', Name: 'Worker, metal, white' },
  { Id: 14384, Code: '5449', Name: 'Worker, ornament, black' },
  { Id: 14385, Code: '5449', Name: 'Worker, pierce' },
  { Id: 14386, Code: '5449', Name: 'Worker, silver' },
  { Id: 14387, Code: '5449', Name: 'Worker, stone, artificial' },
  { Id: 14388, Code: '5449', Name: 'Worker, stone, precious' },
  { Id: 14389, Code: '5449', Name: 'Worker, willow' },
  { Id: 14390, Code: '5449', Name: 'Worker, wire, silver, plate mfr' },
  { Id: 14391, Code: '5449', Name: 'Writer to the trade' },
  { Id: 14392, Code: '5449', Name: 'Writer, coach building' },
  { Id: 14393, Code: '5449', Name: 'Writer, glass' },
  { Id: 14394, Code: '5449', Name: 'Writer, letter, signwriting' },
  { Id: 14395, Code: '5449', Name: 'Writer, poster' },
  { Id: 14396, Code: '5449', Name: 'Writer, sign' },
  { Id: 14397, Code: '5449', Name: 'Writer, signwriting' },
  { Id: 14398, Code: '5449', Name: 'Writer, ticket' },
  {
    Id: 14399,
    Code: '6121',
    Name: "Assistant, care, child, children's nursery"
  },
  { Id: 14400, Code: '6121', Name: 'Assistant, cr�che' },
  { Id: 14401, Code: '6121', Name: 'Assistant, general, children�s nursery' },
  { Id: 14402, Code: '6121', Name: 'Assistant, kindergarten' },
  { Id: 14403, Code: '6121', Name: 'Assistant, nursery' },
  { Id: 14404, Code: '6121', Name: 'Assistant, pre-school' },
  { Id: 14405, Code: '6121', Name: 'Assistant, years, early' },
  { Id: 14406, Code: '6121', Name: 'Attendant, cr�che' },
  { Id: 14407, Code: '6121', Name: 'Attendant, nursery' },
  { Id: 14408, Code: '6121', Name: 'Educator, years, early' },
  { Id: 14409, Code: '6121', Name: 'Governess, nursery' },
  { Id: 14410, Code: '6121', Name: 'Helper, cr�che' },
  { Id: 14411, Code: '6121', Name: 'Helper, nursery' },
  { Id: 14412, Code: '6121', Name: 'Helper, pre-school' },
  { Id: 14413, Code: '6121', Name: 'Leader, cr�che' },
  { Id: 14414, Code: '6121', Name: 'Manager, cr�che' },
  { Id: 14415, Code: '6121', Name: 'Manager, group, play' },
  { Id: 14416, Code: '6121', Name: 'Manager, playgroup' },
  { Id: 14417, Code: '6121', Name: 'NNEB' },
  { Id: 14418, Code: '6121', Name: 'Nurse, childcare' },
  { Id: 14419, Code: '6121', Name: 'Nurse, nursery' },
  { Id: 14420, Code: '6121', Name: 'Officer, nursery' },
  { Id: 14421, Code: '6121', Name: 'Practitioner, care, child' },
  { Id: 14422, Code: '6121', Name: 'Practitioner, childcare' },
  { Id: 14423, Code: '6121', Name: 'Practitioner, nurse, nursery' },
  { Id: 14424, Code: '6121', Name: 'Practitioner, nursery' },
  { Id: 14425, Code: '6121', Name: 'Practitioner, pre-school' },
  { Id: 14426, Code: '6121', Name: 'Practitioner, school, nursery' },
  { Id: 14427, Code: '6121', Name: 'Practitioner, years, early' },
  { Id: 14428, Code: '6121', Name: "Supervisor, children's nursery" },
  { Id: 14429, Code: '6121', Name: 'Supervisor, cr�che' },
  { Id: 14430, Code: '6121', Name: 'Supervisor, nursery' },
  { Id: 14431, Code: '6121', Name: 'Supervisor, pre-school' },
  { Id: 14432, Code: '6121', Name: 'Teacher, kindergarten' },
  { Id: 14433, Code: '6121', Name: 'Teacher, nursery' },
  { Id: 14434, Code: '6121', Name: 'Teacher, pre-school' },
  { Id: 14435, Code: '6121', Name: 'Warden, day nursery' },
  { Id: 14436, Code: '6121', Name: 'Worker, cr�che' },
  { Id: 14437, Code: '6121', Name: 'Worker, nursery, day nursery' },
  { Id: 14438, Code: '6121', Name: 'Worker, support, nursery' },
  { Id: 14439, Code: '6121', Name: 'Worker, support, years, early' },
  { Id: 14440, Code: '6121', Name: 'Worker, years, early' },
  { Id: 14441, Code: '6122', Name: 'Assistant, care, child' },
  { Id: 14442, Code: '6122', Name: 'Au pair' },
  { Id: 14443, Code: '6122', Name: 'Carer, child' },
  { Id: 14444, Code: '6122', Name: 'Childminder' },
  { Id: 14445, Code: '6122', Name: "Help, mother's" },
  { Id: 14446, Code: '6122', Name: 'Maid, nurse' },
  { Id: 14447, Code: '6122', Name: 'Minder, child' },
  { Id: 14448, Code: '6122', Name: 'Nannie' },
  { Id: 14449, Code: '6122', Name: 'Nanny' },
  { Id: 14450, Code: '6122', Name: "Nurse, children's, domestic service" },
  { Id: 14451, Code: '6122', Name: 'Nursemaid' },
  { Id: 14452, Code: '6122', Name: 'Sitter, baby' },
  { Id: 14453, Code: '6123', Name: 'Assistant, centre, play' },
  { Id: 14454, Code: '6123', Name: 'Assistant, club, out of school' },
  { Id: 14455, Code: '6123', Name: "Assistant, leader's, play" },
  { Id: 14456, Code: '6123', Name: 'Assistant, play' },
  { Id: 14457, Code: '6123', Name: 'Assistant, playgroup' },
  { Id: 14458, Code: '6123', Name: 'Assistant, playschool' },
  { Id: 14459, Code: '6123', Name: 'Assistant, school, play' },
  { Id: 14460, Code: '6123', Name: 'Assistant, schools, play' },
  {
    Id: 14461,
    Code: '6123',
    Name: 'Coordinator, activities, club, school, after'
  },
  { Id: 14462, Code: '6123', Name: 'Coordinator, club, out of school' },
  { Id: 14463, Code: '6123', Name: 'Helper, infant' },
  { Id: 14464, Code: '6123', Name: 'Helper, playgroup' },
  { Id: 14465, Code: '6123', Name: 'Helper, playschool' },
  { Id: 14466, Code: '6123', Name: 'Leader, play' },
  { Id: 14467, Code: '6123', Name: 'Leader, playgroup' },
  { Id: 14468, Code: '6123', Name: 'Organiser, playgroup' },
  { Id: 14469, Code: '6123', Name: 'Playworker' },
  { Id: 14470, Code: '6123', Name: 'Ranger, play' },
  { Id: 14471, Code: '6123', Name: 'Supervisor, club, afterschool' },
  { Id: 14472, Code: '6123', Name: 'Supervisor, playgroup' },
  { Id: 14473, Code: '6123', Name: 'Supervisor, school, play' },
  { Id: 14474, Code: '6123', Name: 'Teacher, school, play' },
  { Id: 14475, Code: '6123', Name: 'Worker, play' },
  { Id: 14476, Code: '6123', Name: 'Worker, playgroup' },
  { Id: 14477, Code: '6123', Name: 'Worker, playscheme' },
  { Id: 14478, Code: '6123', Name: 'Worker, playschool' },
  { Id: 14479, Code: '6125', Name: "Aid, teacher's" },
  { Id: 14480, Code: '6125', Name: 'Aid, teaching' },
  { Id: 14481, Code: '6125', Name: "Aide, teacher's" },
  { Id: 14482, Code: '6125', Name: 'Aide, teaching' },
  { Id: 14483, Code: '6125', Name: 'Ancillary, classroom' },
  { Id: 14484, Code: '6125', Name: 'Ancillary, educational establishments' },
  { Id: 14485, Code: '6125', Name: 'Ancillary, teaching, schools' },
  {
    Id: 14486,
    Code: '6125',
    Name: 'Assistant, ancillary, educational establishments'
  },
  {
    Id: 14487,
    Code: '6125',
    Name: 'Assistant, auxiliary, educational establishments'
  },
  { Id: 14488, Code: '6125', Name: 'Assistant, care, schools' },
  { Id: 14489, Code: '6125', Name: 'Assistant, class, nursery' },
  { Id: 14490, Code: '6125', Name: 'Assistant, classroom' },
  { Id: 14491, Code: '6125', Name: 'Assistant, education' },
  { Id: 14492, Code: '6125', Name: 'Assistant, educational' },
  {
    Id: 14493,
    Code: '6125',
    Name: 'Assistant, general, educational establishments'
  },
  { Id: 14494, Code: '6125', Name: 'Assistant, school' },
  { Id: 14495, Code: '6125', Name: 'Assistant, school, nursery' },
  { Id: 14496, Code: '6125', Name: 'Assistant, schools' },
  { Id: 14497, Code: '6125', Name: 'Assistant, schools, nursery' },
  { Id: 14498, Code: '6125', Name: 'Assistant, support, classroom' },
  { Id: 14499, Code: '6125', Name: 'Assistant, support, curriculum' },
  { Id: 14500, Code: '6125', Name: "Assistant, teacher's" },
  { Id: 14501, Code: '6125', Name: 'Assistant, teaching' },
  { Id: 14502, Code: '6125', Name: 'Attendant, class' },
  { Id: 14503, Code: '6125', Name: 'Auxiliary, educational establishments' },
  { Id: 14504, Code: '6125', Name: 'Auxiliary, school' },
  { Id: 14505, Code: '6125', Name: 'Auxiliary, teaching' },
  { Id: 14506, Code: '6125', Name: 'Help, school' },
  { Id: 14507, Code: '6125', Name: "Help, teacher's" },
  { Id: 14508, Code: '6125', Name: 'Helper, ancillary, schools' },
  { Id: 14509, Code: '6125', Name: 'Helper, classroom' },
  { Id: 14510, Code: '6125', Name: 'Helper, primary' },
  { Id: 14511, Code: '6125', Name: 'Helper, school' },
  { Id: 14512, Code: '6125', Name: 'Supervisor, cover, education' },
  {
    Id: 14513,
    Code: '6125',
    Name: 'Supervisor, cover, educational establishments'
  },
  {
    Id: 14514,
    Code: '6125',
    Name: 'Technician, food, educational establishments'
  },
  { Id: 14515, Code: '6125', Name: "Worker, support, teacher's" },
  { Id: 14516, Code: '6126', Name: 'Assistant, integration, schools' },
  {
    Id: 14517,
    Code: '6126',
    Name: 'Assistant, needs, special, educational establishments'
  },
  { Id: 14518, Code: '6126', Name: 'Assistant, needs, special, schools' },
  { Id: 14519, Code: '6126', Name: 'Assistant, non-teaching, schools' },
  { Id: 14520, Code: '6126', Name: 'Assistant, school, special' },
  { Id: 14521, Code: '6126', Name: 'Assistant, schools, special' },
  { Id: 14522, Code: '6126', Name: 'Assistant, support for learning' },
  { Id: 14523, Code: '6126', Name: 'Assistant, support, behaviour' },
  { Id: 14524, Code: '6126', Name: 'Assistant, support, education' },
  {
    Id: 14525,
    Code: '6126',
    Name: 'Assistant, support, educational establishments'
  },
  { Id: 14526, Code: '6126', Name: 'Assistant, support, individual' },
  { Id: 14527, Code: '6126', Name: 'Assistant, support, learning' },
  { Id: 14528, Code: '6126', Name: 'Assistant, support, pupil' },
  { Id: 14529, Code: '6126', Name: 'Assistant, support, special, schools' },
  { Id: 14530, Code: '6126', Name: 'Assistant, teaching, needs, special' },
  { Id: 14531, Code: '6126', Name: 'Assistant, teaching, SEN' },
  { Id: 14532, Code: '6126', Name: 'Assistant, teaching, SPECIAL NEEDS' },
  { Id: 14533, Code: '6126', Name: 'Assistant, welfare, school' },
  { Id: 14534, Code: '6126', Name: 'Assistant, welfare, schools' },
  { Id: 14535, Code: '6126', Name: 'Auxiliary, needs, special' },
  {
    Id: 14536,
    Code: '6126',
    Name: 'Helper, needs, special, educational establishments'
  },
  { Id: 14537, Code: '6126', Name: 'Officer, care, education' },
  { Id: 14538, Code: '6126', Name: 'Taker, note, educational establishments' },
  {
    Id: 14539,
    Code: '6126',
    Name: 'Worker, ancillary, educational establishments'
  },
  { Id: 14540, Code: '6126', Name: 'Worker, support, behaviour' },
  {
    Id: 14541,
    Code: '6126',
    Name: 'Worker, support, communication, educational establishments'
  },
  {
    Id: 14542,
    Code: '6126',
    Name: 'Worker, support, educational establishments'
  },
  { Id: 14543, Code: '6126', Name: 'Worker, support, learning' },
  { Id: 14544, Code: '6126', Name: 'Worker, support, pastoral' },
  { Id: 14545, Code: '6126', Name: 'Worker, welfare, schools' },
  { Id: 14546, Code: '6131', Name: 'Nurse, animal' },
  { Id: 14547, Code: '6131', Name: 'Nurse, canine' },
  { Id: 14548, Code: '6131', Name: 'Nurse, veterinary' },
  { Id: 14549, Code: '6132', Name: 'Catcher, mole' },
  { Id: 14550, Code: '6132', Name: 'Catcher, rat' },
  { Id: 14551, Code: '6132', Name: 'Controller, pest' },
  { Id: 14552, Code: '6132', Name: 'Destroyer, PEST' },
  { Id: 14553, Code: '6132', Name: 'Disinfector' },
  { Id: 14554, Code: '6132', Name: 'Exterminator, PEST' },
  { Id: 14555, Code: '6132', Name: 'Fumigator' },
  { Id: 14556, Code: '6132', Name: 'Inspector, control, pest' },
  { Id: 14557, Code: '6132', Name: 'Inspector, infestation' },
  { Id: 14558, Code: '6132', Name: 'Inspector, rodent' },
  { Id: 14559, Code: '6132', Name: 'Officer, control, pest' },
  { Id: 14560, Code: '6132', Name: 'Officer, control, rodent' },
  { Id: 14561, Code: '6132', Name: 'Officer, disinfecting' },
  { Id: 14562, Code: '6132', Name: 'Officer, pest' },
  { Id: 14563, Code: '6132', Name: 'Officer, rodent' },
  { Id: 14564, Code: '6132', Name: 'Operative, rodent' },
  { Id: 14565, Code: '6132', Name: 'Operator, beetle, colorado' },
  { Id: 14566, Code: '6132', Name: 'Operator, control, pest' },
  { Id: 14567, Code: '6132', Name: 'Operator, rodent' },
  { Id: 14568, Code: '6132', Name: 'Specialist, woodworm' },
  { Id: 14569, Code: '6132', Name: 'Sprayer, wood preservation service' },
  { Id: 14570, Code: '6132', Name: 'Surveyor, control, pest' },
  { Id: 14571, Code: '6132', Name: 'Surveyor, infestation, timber' },
  { Id: 14572, Code: '6132', Name: 'Surveyor, pest control' },
  { Id: 14573, Code: '6132', Name: 'Technician, control, pest' },
  { Id: 14574, Code: '6132', Name: 'Technician, service, prevention, pest' },
  { Id: 14575, Code: '6139', Name: 'Aquarist' },
  { Id: 14576, Code: '6139', Name: 'Assistant, care, animal' },
  { Id: 14577, Code: '6139', Name: 'Assistant, cattery' },
  { Id: 14578, Code: '6139', Name: 'Assistant, clinic, animal' },
  { Id: 14579, Code: '6139', Name: 'Assistant, kennel' },
  { Id: 14580, Code: '6139', Name: 'Assistant, livestock, retail trade' },
  { Id: 14581, Code: '6139', Name: 'Assistant, nursing, veterinary' },
  { Id: 14582, Code: '6139', Name: "Assistant, surgeon's, veterinary" },
  { Id: 14583, Code: '6139', Name: "Assistant, technical, veterinarian's" },
  { Id: 14584, Code: '6139', Name: 'Assistant, technical, veterinary surgery' },
  {
    Id: 14585,
    Code: '6139',
    Name: 'Assistant, trek, equestrian trekking centre'
  },
  { Id: 14586, Code: '6139', Name: 'Assistant, veterinary' },
  { Id: 14587, Code: '6139', Name: 'Assistant, welfare, animal' },
  { Id: 14588, Code: '6139', Name: 'Attendant, animal' },
  { Id: 14589, Code: '6139', Name: 'Attendant, health, animal' },
  { Id: 14590, Code: '6139', Name: 'Attendant, kennel' },
  { Id: 14591, Code: '6139', Name: 'Attendant, racing stables' },
  { Id: 14592, Code: '6139', Name: 'Auxiliary, nursing, animal' },
  { Id: 14593, Code: '6139', Name: 'Beautician, canine' },
  { Id: 14594, Code: '6139', Name: 'Boy, stable' },
  { Id: 14595, Code: '6139', Name: 'Breaker, horse' },
  { Id: 14596, Code: '6139', Name: 'Breeder, BLOODSTOCK' },
  { Id: 14597, Code: '6139', Name: 'Breeder, CAT' },
  { Id: 14598, Code: '6139', Name: 'Breeder, DOG' },
  { Id: 14599, Code: '6139', Name: 'Breeder, HORSE' },
  { Id: 14600, Code: '6139', Name: 'Carer, animal' },
  { Id: 14601, Code: '6139', Name: 'Carer, canine' },
  { Id: 14602, Code: '6139', Name: 'Carer, cat' },
  { Id: 14603, Code: '6139', Name: 'Carer, pet' },
  { Id: 14604, Code: '6139', Name: 'Careworker, animal' },
  { Id: 14605, Code: '6139', Name: 'Clipper, dog' },
  { Id: 14606, Code: '6139', Name: 'Clipper, horse' },
  { Id: 14607, Code: '6139', Name: 'Foreman, stable' },
  { Id: 14608, Code: '6139', Name: 'Foreman, zoological gardens' },
  { Id: 14609, Code: '6139', Name: 'Girl, stable' },
  { Id: 14610, Code: '6139', Name: 'Groom' },
  { Id: 14611, Code: '6139', Name: 'Groomer, dog' },
  { Id: 14612, Code: '6139', Name: 'Groom-gardener' },
  { Id: 14613, Code: '6139', Name: 'Groomsman' },
  { Id: 14614, Code: '6139', Name: 'Hand, kennel' },
  { Id: 14615, Code: '6139', Name: 'Hand, stable' },
  { Id: 14616, Code: '6139', Name: 'Hand, stud' },
  { Id: 14617, Code: '6139', Name: 'Handler, animal' },
  { Id: 14618, Code: '6139', Name: 'Handler, dog' },
  { Id: 14619, Code: '6139', Name: 'Headman, racing stables' },
  { Id: 14620, Code: '6139', Name: 'Helper, stable' },
  { Id: 14621, Code: '6139', Name: 'Horseman' },
  { Id: 14622, Code: '6139', Name: 'Hostler' },
  { Id: 14623, Code: '6139', Name: 'Hunter' },
  { Id: 14624, Code: '6139', Name: 'Huntsman' },
  { Id: 14625, Code: '6139', Name: 'Keeper, animal' },
  { Id: 14626, Code: '6139', Name: 'Keeper, head, zoological gardens' },
  { Id: 14627, Code: '6139', Name: 'Keeper, horse' },
  { Id: 14628, Code: '6139', Name: 'Keeper, horse, head' },
  { Id: 14629, Code: '6139', Name: 'Keeper, kennel' },
  { Id: 14630, Code: '6139', Name: 'Keeper, menagerie' },
  { Id: 14631, Code: '6139', Name: 'Keeper, stable' },
  { Id: 14632, Code: '6139', Name: 'Keeper, stable, livery' },
  { Id: 14633, Code: '6139', Name: 'Keeper, zoo' },
  { Id: 14634, Code: '6139', Name: 'Keeper, zoological gardens' },
  { Id: 14635, Code: '6139', Name: 'Lad, head, racing stables' },
  { Id: 14636, Code: '6139', Name: 'Lad, stable' },
  { Id: 14637, Code: '6139', Name: 'Leader, trek, equestrian trekking centre' },
  { Id: 14638, Code: '6139', Name: 'Maid, kennel' },
  { Id: 14639, Code: '6139', Name: 'Man, yard, livery stable' },
  { Id: 14640, Code: '6139', Name: 'Manager, stable' },
  { Id: 14641, Code: '6139', Name: 'Minder, animal' },
  { Id: 14642, Code: '6139', Name: 'Officer, collection, animal' },
  { Id: 14643, Code: '6139', Name: 'Officer, welfare, animal' },
  { Id: 14644, Code: '6139', Name: 'Ostler' },
  { Id: 14645, Code: '6139', Name: 'Servant, hunt' },
  { Id: 14646, Code: '6139', Name: 'Stableman' },
  { Id: 14647, Code: '6139', Name: 'Stripper, dog' },
  { Id: 14648, Code: '6139', Name: 'Studhand' },
  { Id: 14649, Code: '6139', Name: 'Technician, animal' },
  { Id: 14650, Code: '6139', Name: 'Technician, LABORATORY, ANIMAL CARE' },
  { Id: 14651, Code: '6139', Name: 'Technician, laboratory, care, animal' },
  { Id: 14652, Code: '6139', Name: 'Trainer, animal' },
  { Id: 14653, Code: '6139', Name: 'Trainer, dog' },
  { Id: 14654, Code: '6139', Name: 'Trainer, greyhound' },
  { Id: 14655, Code: '6139', Name: 'Trainer, horse' },
  { Id: 14656, Code: '6139', Name: 'Trainer, pony' },
  { Id: 14657, Code: '6139', Name: 'Trimmer, barefoot, equine' },
  { Id: 14658, Code: '6139', Name: 'Walker, dog' },
  { Id: 14659, Code: '6139', Name: 'Warden, dog' },
  { Id: 14660, Code: '6139', Name: 'Whip, hunting' },
  { Id: 14661, Code: '6139', Name: 'Whipper-in, hunting' },
  { Id: 14662, Code: '6139', Name: 'Worker, care, animal' },
  { Id: 14663, Code: '6139', Name: 'Worker, kennel' },
  { Id: 14664, Code: '6139', Name: 'Yardman, livery stable' },
  { Id: 14665, Code: '6139', Name: 'Yardsman, livery stable' },
  { Id: 14666, Code: '6141', Name: 'Aid, hospital service' },
  { Id: 14667, Code: '6141', Name: "Aid, nurse's" },
  { Id: 14668, Code: '6141', Name: 'Aide, hospital service' },
  { Id: 14669, Code: '6141', Name: "Aide, nurse's" },
  { Id: 14670, Code: '6141', Name: 'Aide, ward' },
  { Id: 14671, Code: '6141', Name: 'Assistant, anaesthetic' },
  { Id: 14672, Code: '6141', Name: 'Assistant, care, foot' },
  {
    Id: 14673,
    Code: '6141',
    Name: 'Assistant, care, health, hospital service'
  },
  { Id: 14674, Code: '6141', Name: 'Assistant, care, hospital service' },
  { Id: 14675, Code: '6141', Name: 'Assistant, chiropody' },
  { Id: 14676, Code: '6141', Name: 'Assistant, clinic' },
  { Id: 14677, Code: '6141', Name: 'Assistant, clinical' },
  { Id: 14678, Code: '6141', Name: 'Assistant, CSSD' },
  { Id: 14679, Code: '6141', Name: 'Assistant, department, operating' },
  { Id: 14680, Code: '6141', Name: 'Assistant, dialysis' },
  { Id: 14681, Code: '6141', Name: 'Assistant, donor, blood' },
  { Id: 14682, Code: '6141', Name: 'Assistant, healthcare, hospital service' },
  { Id: 14683, Code: '6141', Name: 'Assistant, HSDU' },
  { Id: 14684, Code: '6141', Name: 'Assistant, medical, armed forces' },
  { Id: 14685, Code: '6141', Name: 'Assistant, medical, hospital service' },
  { Id: 14686, Code: '6141', Name: 'Assistant, nursing' },
  { Id: 14687, Code: '6141', Name: 'Assistant, physiotherapy' },
  { Id: 14688, Code: '6141', Name: "Assistant, radiographer's" },
  { Id: 14689, Code: '6141', Name: 'Assistant, radiography' },
  {
    Id: 14690,
    Code: '6141',
    Name: 'Assistant, rehabilitation, hospital service'
  },
  { Id: 14691, Code: '6141', Name: 'Assistant, service, sterile' },
  { Id: 14692, Code: '6141', Name: 'Assistant, services, sterile' },
  { Id: 14693, Code: '6141', Name: 'Assistant, sterilising' },
  { Id: 14694, Code: '6141', Name: 'Assistant, surgery' },
  { Id: 14695, Code: '6141', Name: 'Assistant, team, clinical' },
  { Id: 14696, Code: '6141', Name: 'Assistant, theatre, hospital service' },
  { Id: 14697, Code: '6141', Name: 'Assistant, theatre, operating' },
  { Id: 14698, Code: '6141', Name: 'Assistant, therapy, occupational' },
  { Id: 14699, Code: '6141', Name: 'Assistant, therapy, speech and language' },
  { Id: 14700, Code: '6141', Name: 'Assistant, TSSU' },
  { Id: 14701, Code: '6141', Name: 'Assistant, ward' },
  { Id: 14702, Code: '6141', Name: 'Assistant, x-ray' },
  { Id: 14703, Code: '6141', Name: 'Attendant, aid, first' },
  { Id: 14704, Code: '6141', Name: 'Attendant, bay, sick' },
  { Id: 14705, Code: '6141', Name: 'Attendant, clinic' },
  { Id: 14706, Code: '6141', Name: 'Attendant, donor, blood' },
  { Id: 14707, Code: '6141', Name: 'Attendant, hospital' },
  { Id: 14708, Code: '6141', Name: 'Attendant, hospital service' },
  { Id: 14709, Code: '6141', Name: 'Attendant, hydrotherapy' },
  { Id: 14710, Code: '6141', Name: 'Attendant, medical' },
  { Id: 14711, Code: '6141', Name: 'Attendant, night, hospital service' },
  { Id: 14712, Code: '6141', Name: 'Attendant, nursing' },
  { Id: 14713, Code: '6141', Name: 'Attendant, room, aid, first' },
  { Id: 14714, Code: '6141', Name: 'Attendant, room, medical' },
  { Id: 14715, Code: '6141', Name: 'Attendant, room, sick' },
  { Id: 14716, Code: '6141', Name: 'Attendant, sterilizer, medical services' },
  { Id: 14717, Code: '6141', Name: 'Attendant, surgery' },
  { Id: 14718, Code: '6141', Name: 'Attendant, theatre, hospital service' },
  { Id: 14719, Code: '6141', Name: 'Attendant, ward' },
  { Id: 14720, Code: '6141', Name: 'Auxiliary, nursing' },
  { Id: 14721, Code: '6141', Name: 'Carer, donor, blood' },
  { Id: 14722, Code: '6141', Name: 'Carer, donor, National Blood Service' },
  { Id: 14723, Code: '6141', Name: 'Doula' },
  { Id: 14724, Code: '6141', Name: 'Help, ward' },
  { Id: 14725, Code: '6141', Name: 'Helper, physiotherapy' },
  { Id: 14726, Code: '6141', Name: "Helper, radiographer's" },
  { Id: 14727, Code: '6141', Name: 'Helper, therapy, occupational' },
  { Id: 14728, Code: '6141', Name: 'Helper, ward' },
  { Id: 14729, Code: '6141', Name: 'Hostess, ward' },
  { Id: 14730, Code: '6141', Name: 'Instructor, aid, first' },
  {
    Id: 14731,
    Code: '6141',
    Name: 'Instructor, technical, OCCUPATIONAL THERAPY'
  },
  {
    Id: 14732,
    Code: '6141',
    Name: 'Instructor, technical, therapy, occupational'
  },
  { Id: 14733, Code: '6141', Name: 'Nurse, ancillary' },
  { Id: 14734, Code: '6141', Name: 'Nurse, assistant' },
  { Id: 14735, Code: '6141', Name: 'Nurse, auxiliary' },
  { Id: 14736, Code: '6141', Name: 'Nurse, GRADE A, B' },
  { Id: 14737, Code: '6141', Name: 'Nurse, orderly' },
  { Id: 14738, Code: '6141', Name: 'Officer, aid, first' },
  { Id: 14739, Code: '6141', Name: 'Officer, hospital, prison service' },
  { Id: 14740, Code: '6141', Name: 'Operator, sterilizer, hospital service' },
  { Id: 14741, Code: '6141', Name: 'Orderly, hospital' },
  { Id: 14742, Code: '6141', Name: 'Orderly, hospital service' },
  { Id: 14743, Code: '6141', Name: 'Orderly, medical' },
  { Id: 14744, Code: '6141', Name: 'Orderly, nursing' },
  { Id: 14745, Code: '6141', Name: 'Orderly, theatre, hospital service' },
  { Id: 14746, Code: '6141', Name: 'Orderly, theatre, operating' },
  { Id: 14747, Code: '6141', Name: 'Orderly, ward' },
  { Id: 14748, Code: '6141', Name: 'Phlebotomist' },
  { Id: 14749, Code: '6141', Name: 'Responder, first' },
  { Id: 14750, Code: '6141', Name: 'Setter, tray, HOSPITAL STERILE SUPPLIES' },
  { Id: 14751, Code: '6141', Name: 'Steriliser, hospital service' },
  { Id: 14752, Code: '6141', Name: 'Steward, ward' },
  { Id: 14753, Code: '6141', Name: 'Technician, HSDU' },
  { Id: 14754, Code: '6141', Name: 'Technician, services, sterile' },
  { Id: 14755, Code: '6141', Name: 'Technician, therapy, occupational' },
  { Id: 14756, Code: '6141', Name: 'Trainer, aid, first' },
  { Id: 14757, Code: '6141', Name: 'Venepuncturist' },
  { Id: 14758, Code: '6141', Name: 'Venesectionist' },
  { Id: 14759, Code: '6141', Name: 'Venesector' },
  { Id: 14760, Code: '6141', Name: 'Worker, ancillary, hospital service' },
  { Id: 14761, Code: '6141', Name: 'Worker, care, hospital service' },
  { Id: 14762, Code: '6141', Name: 'Worker, healthcare, hospital service' },
  { Id: 14763, Code: '6141', Name: 'Worker, hospital' },
  { Id: 14764, Code: '6141', Name: 'Worker, support, care' },
  { Id: 14765, Code: '6141', Name: 'Worker, support, care, health' },
  { Id: 14766, Code: '6141', Name: 'Worker, support, clinical' },
  {
    Id: 14767,
    Code: '6141',
    Name: 'Worker, support, health, hospital service'
  },
  { Id: 14768, Code: '6141', Name: 'Worker, support, hospital service' },
  { Id: 14769, Code: '6141', Name: 'Worker, support, maternity' },
  { Id: 14770, Code: '6141', Name: 'Worker, support, therapy, occupational' },
  { Id: 14771, Code: '6142', Name: 'Ambulanceman' },
  { Id: 14772, Code: '6142', Name: 'Assistant, care, ambulance' },
  { Id: 14773, Code: '6142', Name: 'Assistant, care, emergency' },
  { Id: 14774, Code: '6142', Name: 'Attendant, ambulance' },
  { Id: 14775, Code: '6142', Name: 'Attendant, ambulance service' },
  { Id: 14776, Code: '6142', Name: 'Attendant, room, ambulance' },
  { Id: 14777, Code: '6142', Name: 'Attendant, station, ambulance' },
  { Id: 14778, Code: '6142', Name: 'Cadet, ambulance service' },
  { Id: 14779, Code: '6142', Name: 'Carer, patient, ambulance' },
  { Id: 14780, Code: '6142', Name: 'Crew, ambulance' },
  { Id: 14781, Code: '6142', Name: 'Driver, ambulance' },
  { Id: 14782, Code: '6142', Name: 'Driver, transport, patient' },
  { Id: 14783, Code: '6142', Name: 'Driver-attendant, ambulance' },
  { Id: 14784, Code: '6142', Name: 'Leader, section, ambulance service' },
  { Id: 14785, Code: '6142', Name: 'Leader, shift, ambulance service' },
  { Id: 14786, Code: '6142', Name: 'Leader, team, ambulance service' },
  { Id: 14787, Code: '6142', Name: 'Medic, offshore' },
  { Id: 14788, Code: '6142', Name: 'Medic, OIL RIG' },
  { Id: 14789, Code: '6142', Name: 'Officer, ambulance' },
  { Id: 14790, Code: '6142', Name: 'Supervisor, ambulance service' },
  { Id: 14791, Code: '6142', Name: 'Technician, ambulance' },
  { Id: 14792, Code: '6142', Name: 'Technician, medical, emergency' },
  { Id: 14793, Code: '6142', Name: 'Worker, support, care, emergency' },
  { Id: 14794, Code: '6143', Name: 'Assistant, chairside, orthodontic' },
  { Id: 14795, Code: '6143', Name: 'Assistant, dental' },
  { Id: 14796, Code: '6143', Name: "Assistant, surgeon's, dental" },
  { Id: 14797, Code: '6143', Name: 'Assistant, surgery, dental' },
  { Id: 14798, Code: '6143', Name: 'Attendant, dental' },
  { Id: 14799, Code: '6143', Name: 'Auxiliary, dental' },
  { Id: 14800, Code: '6143', Name: 'Nurse, dental' },
  { Id: 14801, Code: '6143', Name: 'Nurse, dental practice' },
  { Id: 14802, Code: '6143', Name: 'Nurse, surgery, dental' },
  { Id: 14803, Code: '6143', Name: 'Nurse-receptionist, dental' },
  { Id: 14804, Code: '6143', Name: 'Receptionist-nurse, dental' },
  {
    Id: 14805,
    Code: '6144',
    Name: 'Assistant, community, housing association'
  },
  { Id: 14806, Code: '6144', Name: 'Carer, foster' },
  { Id: 14807, Code: '6144', Name: 'Careworker, child' },
  { Id: 14808, Code: '6144', Name: 'Father, house' },
  {
    Id: 14809,
    Code: '6144',
    Name: 'Housemaster, local government: social services'
  },
  { Id: 14810, Code: '6144', Name: 'Houseparent' },
  { Id: 14811, Code: '6144', Name: "Leader, children's home" },
  { Id: 14812, Code: '6144', Name: 'Leader, home, nursing home' },
  { Id: 14813, Code: '6144', Name: 'Leader, home, residential home' },
  { Id: 14814, Code: '6144', Name: 'Leader, residential home' },
  {
    Id: 14815,
    Code: '6144',
    Name: 'Leader, team, local government social services, RESIDENTIAL CARE'
  },
  { Id: 14816, Code: '6144', Name: 'Leader, team, residential care home' },
  { Id: 14817, Code: '6144', Name: 'Leader, team, support, home' },
  { Id: 14818, Code: '6144', Name: 'Manager, scheme, sheltered housing' },
  {
    Id: 14819,
    Code: '6144',
    Name: 'Master, house, local government: social services dept'
  },
  { Id: 14820, Code: '6144', Name: 'Matron, day nursery' },
  { Id: 14821, Code: '6144', Name: 'Matron, educational establishments' },
  { Id: 14822, Code: '6144', Name: 'Matron, home' },
  { Id: 14823, Code: '6144', Name: 'Matron, hostel' },
  { Id: 14824, Code: '6144', Name: 'Matron, nursing home' },
  { Id: 14825, Code: '6144', Name: 'Matron, residential home' },
  { Id: 14826, Code: '6144', Name: 'Matron, school' },
  { Id: 14827, Code: '6144', Name: 'Mother, foster' },
  { Id: 14828, Code: '6144', Name: 'Mother, house' },
  { Id: 14829, Code: '6144', Name: 'Officer, care, residential' },
  { Id: 14830, Code: '6144', Name: 'Parent, foster' },
  { Id: 14831, Code: '6144', Name: 'Parent, house' },
  { Id: 14832, Code: '6144', Name: 'Sister, home, NURSES HOME' },
  { Id: 14833, Code: '6144', Name: "Superintendent, children's home" },
  { Id: 14834, Code: '6144', Name: "Superintendent, old people's home" },
  { Id: 14835, Code: '6144', Name: 'Supervisor, home, nursing' },
  { Id: 14836, Code: '6144', Name: 'Supervisor, hostel' },
  { Id: 14837, Code: '6144', Name: 'Supervisor, INSTITUTIONS' },
  { Id: 14838, Code: '6144', Name: 'Tenant, responsible' },
  { Id: 14839, Code: '6144', Name: 'Warden, accommodation' },
  { Id: 14840, Code: '6144', Name: 'Warden, community, SHELTERED HOUSING' },
  { Id: 14841, Code: '6144', Name: 'Warden, housing association' },
  { Id: 14842, Code: '6144', Name: 'Warden, housing, local government' },
  { Id: 14843, Code: '6144', Name: 'Warden, local government: housing dept' },
  {
    Id: 14844,
    Code: '6144',
    Name: 'Warden, local government: social services'
  },
  { Id: 14845, Code: '6144', Name: 'Warden, mobile' },
  { Id: 14846, Code: '6144', Name: "Warden, old people's home" },
  { Id: 14847, Code: '6144', Name: 'Warden, resident' },
  { Id: 14848, Code: '6144', Name: 'Warden, SHELTERED HOUSING' },
  { Id: 14849, Code: '6144', Name: 'Worker, care, child' },
  { Id: 14850, Code: '6145', Name: 'Aid, care' },
  { Id: 14851, Code: '6145', Name: 'Aid, home' },
  { Id: 14852, Code: '6145', Name: 'Aide, care' },
  { Id: 14853, Code: '6145', Name: 'Aide, home' },
  { Id: 14854, Code: '6145', Name: 'Assistant, care' },
  { Id: 14855, Code: '6145', Name: 'Assistant, care, day' },
  { Id: 14856, Code: '6145', Name: 'Assistant, care, domiciliary' },
  { Id: 14857, Code: '6145', Name: 'Assistant, care, health, nursing home' },
  { Id: 14858, Code: '6145', Name: 'Assistant, care, home' },
  {
    Id: 14859,
    Code: '6145',
    Name: 'Assistant, general, home for the disabled'
  },
  { Id: 14860, Code: '6145', Name: "Assistant, general, old people's home" },
  { Id: 14861, Code: '6145', Name: 'Assistant, health, mental, community' },
  { Id: 14862, Code: '6145', Name: 'Assistant, personal, welfare services' },
  { Id: 14863, Code: '6145', Name: 'Assistant, reablement, local government' },
  { Id: 14864, Code: '6145', Name: 'Assistant, support, welfare services' },
  { Id: 14865, Code: '6145', Name: 'Attendant, care' },
  { Id: 14866, Code: '6145', Name: 'Attendant, charge' },
  { Id: 14867, Code: '6145', Name: 'Attendant, home for the disabled' },
  { Id: 14868, Code: '6145', Name: 'Attendant, invalid' },
  { Id: 14869, Code: '6145', Name: 'Attendant, night, home for the disabled' },
  { Id: 14870, Code: '6145', Name: "Attendant, night, old people's home" },
  { Id: 14871, Code: '6145', Name: "Attendant, old people's home" },
  {
    Id: 14872,
    Code: '6145',
    Name: 'Befriender, local government: social services'
  },
  { Id: 14873, Code: '6145', Name: 'Carer' },
  { Id: 14874, Code: '6145', Name: 'Carer, domiciliary' },
  { Id: 14875, Code: '6145', Name: 'Carer, home' },
  { Id: 14876, Code: '6145', Name: 'Carer, personal' },
  { Id: 14877, Code: '6145', Name: 'Carer, reablement, local government' },
  { Id: 14878, Code: '6145', Name: 'Carer, welfare services' },
  { Id: 14879, Code: '6145', Name: 'Careworker, welfare services' },
  { Id: 14880, Code: '6145', Name: 'Coordinator, activities, nursing home' },
  {
    Id: 14881,
    Code: '6145',
    Name: "Coordinator, activities, old people's home"
  },
  {
    Id: 14882,
    Code: '6145',
    Name: 'Coordinator, activities, residential home'
  },
  { Id: 14883, Code: '6145', Name: 'Enabler, care' },
  { Id: 14884, Code: '6145', Name: "Helper, people's, old" },
  { Id: 14885, Code: '6145', Name: 'Nurse, care' },
  { Id: 14886, Code: '6145', Name: 'Officer, care, night' },
  { Id: 14887, Code: '6145', Name: 'Officer, residential, welfare services' },
  { Id: 14888, Code: '6145', Name: 'Orderly, nursing home' },
  { Id: 14889, Code: '6145', Name: 'Organiser, activity, nursing home' },
  { Id: 14890, Code: '6145', Name: "Organiser, activity, old people's home" },
  { Id: 14891, Code: '6145', Name: 'Sitter, welfare services' },
  { Id: 14892, Code: '6145', Name: 'Supervisor, care' },
  { Id: 14893, Code: '6145', Name: 'Therapist, hobby' },
  { Id: 14894, Code: '6145', Name: 'Visitor, care' },
  { Id: 14895, Code: '6145', Name: 'Worker, care' },
  { Id: 14896, Code: '6145', Name: 'Worker, care, community' },
  { Id: 14897, Code: '6145', Name: 'Worker, care, residential' },
  { Id: 14898, Code: '6145', Name: 'Worker, care, social, residential' },
  { Id: 14899, Code: '6145', Name: 'Worker, enablement' },
  { Id: 14900, Code: '6145', Name: 'Worker, health, mental, community' },
  { Id: 14901, Code: '6145', Name: 'Worker, healthcare, nursing home' },
  { Id: 14902, Code: '6145', Name: 'Worker, healthcare, welfare services' },
  { Id: 14903, Code: '6145', Name: 'Worker, reablement, local government' },
  { Id: 14904, Code: '6145', Name: 'Worker, residential, welfare services' },
  { Id: 14905, Code: '6145', Name: 'Worker, shelter, welfare services' },
  { Id: 14906, Code: '6145', Name: 'Worker, social, resident' },
  { Id: 14907, Code: '6145', Name: 'Worker, social, residential' },
  { Id: 14908, Code: '6145', Name: 'Worker, support, autism' },
  { Id: 14909, Code: '6145', Name: 'Worker, support, care, home' },
  { Id: 14910, Code: '6145', Name: 'Worker, support, carers' },
  { Id: 14911, Code: '6145', Name: 'Worker, support, community' },
  { Id: 14912, Code: '6145', Name: 'Worker, support, disabilities, learning' },
  { Id: 14913, Code: '6145', Name: 'Worker, support, health, mental' },
  { Id: 14914, Code: '6145', Name: 'Worker, support, health, nursing home' },
  { Id: 14915, Code: '6145', Name: 'Worker, support, home' },
  { Id: 14916, Code: '6145', Name: 'Worker, support, integrated' },
  { Id: 14917, Code: '6145', Name: 'Worker, support, night' },
  { Id: 14918, Code: '6145', Name: 'Worker, support, nursing home' },
  { Id: 14919, Code: '6145', Name: 'Worker, support, rehabilitation' },
  { Id: 14920, Code: '6145', Name: 'Worker, support, welfare services' },
  { Id: 14921, Code: '6146', Name: 'Assistant, care, community, senior' },
  {
    Id: 14922,
    Code: '6146',
    Name: 'Assistant, care, health, senior, nursing home'
  },
  { Id: 14923, Code: '6146', Name: 'Assistant, care, home, senior' },
  { Id: 14924, Code: '6146', Name: 'Assistant, care, senior' },
  { Id: 14925, Code: '6146', Name: 'Carer, night, senior' },
  { Id: 14926, Code: '6146', Name: 'Carer, senior' },
  { Id: 14927, Code: '6146', Name: 'Coordinator, care, nursing home' },
  { Id: 14928, Code: '6146', Name: 'Coordinator, care, residential home' },
  {
    Id: 14929,
    Code: '6146',
    Name: 'Coordinator, care, social, welfare services'
  },
  {
    Id: 14930,
    Code: '6146',
    Name: 'Leader, team, care, home, local government: social services'
  },
  {
    Id: 14931,
    Code: '6146',
    Name: 'Leader, team, local government social services, HOME CARE'
  },
  { Id: 14932, Code: '6146', Name: 'Leader, team, nursing home' },
  { Id: 14933, Code: '6146', Name: 'Worker, care, senior' },
  { Id: 14934, Code: '6146', Name: 'Worker, social, resident, senior' },
  {
    Id: 14935,
    Code: '6146',
    Name: 'Worker, support, senior, welfare services'
  },
  { Id: 14936, Code: '6147', Name: "Attendant, children's" },
  { Id: 14937, Code: '6147', Name: 'Chaperone, ENTERTAINMENT' },
  { Id: 14938, Code: '6147', Name: 'Driver-carer' },
  { Id: 14939, Code: '6147', Name: 'Driver-escort' },
  { Id: 14940, Code: '6147', Name: 'Escort' },
  { Id: 14941, Code: '6147', Name: 'Escort, bus' },
  { Id: 14942, Code: '6147', Name: 'Escort, bus, school' },
  { Id: 14943, Code: '6147', Name: 'Escort, school' },
  { Id: 14944, Code: '6147', Name: 'Escort-driver' },
  { Id: 14945, Code: '6148', Name: 'Administrator, funeral' },
  { Id: 14946, Code: '6148', Name: 'Arranger, funeral' },
  { Id: 14947, Code: '6148', Name: 'Assistant, crematorium' },
  { Id: 14948, Code: '6148', Name: "Assistant, director's, funeral" },
  { Id: 14949, Code: '6148', Name: 'Assistant, funeral' },
  { Id: 14950, Code: '6148', Name: 'Assistant, mortuary' },
  { Id: 14951, Code: '6148', Name: "Assistant, undertaker's" },
  { Id: 14952, Code: '6148', Name: 'Attendant, cemetery' },
  { Id: 14953, Code: '6148', Name: 'Attendant, crematorium' },
  { Id: 14954, Code: '6148', Name: 'Attendant, furnace, crematorium' },
  { Id: 14955, Code: '6148', Name: 'Attendant, mortuary' },
  { Id: 14956, Code: '6148', Name: 'Bearer, funeral directors' },
  { Id: 14957, Code: '6148', Name: 'Bearer, pall' },
  { Id: 14958, Code: '6148', Name: 'Director, funeral' },
  { Id: 14959, Code: '6148', Name: 'Driver-bearer' },
  { Id: 14960, Code: '6148', Name: 'Embalmer' },
  { Id: 14961, Code: '6148', Name: 'Foreman, cemetery, crematorium' },
  { Id: 14962, Code: '6148', Name: 'Furnaceman, crematorium' },
  { Id: 14963, Code: '6148', Name: 'Mortician' },
  { Id: 14964, Code: '6148', Name: 'Operator, crematorium' },
  {
    Id: 14965,
    Code: '6148',
    Name: 'Superintendent, assistant, cemetery, crematorium'
  },
  { Id: 14966, Code: '6148', Name: 'Superintendent, cemetery, crematorium' },
  { Id: 14967, Code: '6148', Name: 'Taxidermist' },
  { Id: 14968, Code: '6148', Name: 'Technician, crematorium' },
  { Id: 14969, Code: '6148', Name: 'Technician, mortuary' },
  { Id: 14970, Code: '6148', Name: 'Undertaker' },
  { Id: 14971, Code: '6148', Name: 'Worker, cemetery' },
  { Id: 14972, Code: '6211', Name: 'Accountant, turf' },
  { Id: 14973, Code: '6211', Name: 'Agent, commission, turf accountants' },
  { Id: 14974, Code: '6211', Name: 'Assistant, centre, leisure' },
  { Id: 14975, Code: '6211', Name: 'Assistant, education, museum' },
  { Id: 14976, Code: '6211', Name: 'Assistant, gym' },
  { Id: 14977, Code: '6211', Name: 'Assistant, heritage' },
  { Id: 14978, Code: '6211', Name: 'Assistant, leisure' },
  { Id: 14979, Code: '6211', Name: "Assistant, maker's, book, BETTING" },
  { Id: 14980, Code: '6211', Name: 'Assistant, museum' },
  { Id: 14981, Code: '6211', Name: 'Assistant, recreation' },
  { Id: 14982, Code: '6211', Name: 'Assistant, services, visitor, museum' },
  { Id: 14983, Code: '6211', Name: 'Assistant, sports' },
  { Id: 14984, Code: '6211', Name: 'Assistant, wardrobe' },
  { Id: 14985, Code: '6211', Name: 'Attendant, baths: swimming' },
  { Id: 14986, Code: '6211', Name: 'Attendant, centre, leisure centre' },
  { Id: 14987, Code: '6211', Name: 'Attendant, centre, sports' },
  { Id: 14988, Code: '6211', Name: 'Attendant, games' },
  { Id: 14989, Code: '6211', Name: 'Attendant, green, bowling' },
  { Id: 14990, Code: '6211', Name: 'Attendant, gymnasium' },
  { Id: 14991, Code: '6211', Name: 'Attendant, leisure' },
  { Id: 14992, Code: '6211', Name: 'Attendant, leisure centre' },
  { Id: 14993, Code: '6211', Name: 'Attendant, pool' },
  { Id: 14994, Code: '6211', Name: 'Attendant, pool, swimming' },
  { Id: 14995, Code: '6211', Name: 'Attendant, services, visitor' },
  { Id: 14996, Code: '6211', Name: 'Boardman, bookmakers, turf accountants' },
  { Id: 14997, Code: '6211', Name: 'Bookmaker' },
  { Id: 14998, Code: '6211', Name: 'Boss, pit, casino' },
  { Id: 14999, Code: '6211', Name: 'Boule de table' },
  { Id: 15000, Code: '6211', Name: 'Caddie' },
  { Id: 15001, Code: '6211', Name: 'Controller, operations, leisure centre' },
  { Id: 15002, Code: '6211', Name: 'Croupier' },
  { Id: 15003, Code: '6211', Name: 'Dealer, CASINO' },
  { Id: 15004, Code: '6211', Name: 'Dresser, ENTERTAINMENT' },
  { Id: 15005, Code: '6211', Name: 'Gambler, professional' },
  { Id: 15006, Code: '6211', Name: 'Guard, life' },
  { Id: 15007, Code: '6211', Name: 'Guide, museum' },
  { Id: 15008, Code: '6211', Name: 'Keeper, wardrobe' },
  { Id: 15009, Code: '6211', Name: 'Lifeguard' },
  { Id: 15010, Code: '6211', Name: 'Lifeguard, pool' },
  { Id: 15011, Code: '6211', Name: 'Linkman, ENTERTAINMENT' },
  { Id: 15012, Code: '6211', Name: 'Maker, book, betting' },
  { Id: 15013, Code: '6211', Name: 'Man, tic-tac' },
  { Id: 15014, Code: '6211', Name: 'Manager, wardrobe' },
  { Id: 15015, Code: '6211', Name: 'Master of ceremonies, ENTERTAINMENT' },
  { Id: 15016, Code: '6211', Name: 'Master, caddy' },
  { Id: 15017, Code: '6211', Name: 'Master, property' },
  { Id: 15018, Code: '6211', Name: 'Master, toast' },
  { Id: 15019, Code: '6211', Name: 'Master, wardrobe' },
  { Id: 15020, Code: '6211', Name: 'Mistress, wardrobe' },
  { Id: 15021, Code: '6211', Name: 'Officer, duty, leisure centre' },
  { Id: 15022, Code: '6211', Name: 'Steward, monument' },
  { Id: 15023, Code: '6211', Name: 'Supervisor, centre, leisure' },
  { Id: 15024, Code: '6211', Name: 'Supervisor, centre, sports' },
  { Id: 15025, Code: '6211', Name: 'Supervisor, gaming' },
  { Id: 15026, Code: '6211', Name: 'Supervisor, pool, swimming' },
  { Id: 15027, Code: '6211', Name: 'Supervisor, services, visitor, museum' },
  { Id: 15028, Code: '6211', Name: 'Tipster' },
  { Id: 15029, Code: '6211', Name: 'Woman, wardrobe, theatre' },
  { Id: 15030, Code: '6212', Name: 'Administrator, travel' },
  { Id: 15031, Code: '6212', Name: 'Adviser, sales, travel' },
  { Id: 15032, Code: '6212', Name: 'Adviser, travel' },
  { Id: 15033, Code: '6212', Name: 'Agent, booking, travel agents' },
  { Id: 15034, Code: '6212', Name: 'Agent, reservation' },
  { Id: 15035, Code: '6212', Name: 'Agent, reservations' },
  { Id: 15036, Code: '6212', Name: 'Agent, tourist' },
  { Id: 15037, Code: '6212', Name: 'Agent, travel' },
  { Id: 15038, Code: '6212', Name: 'Agent, travel, business' },
  { Id: 15039, Code: '6212', Name: 'Assistant, travel' },
  { Id: 15040, Code: '6212', Name: 'Clerk, agency, travel' },
  { Id: 15041, Code: '6212', Name: 'Clerk, booking, travel agents' },
  { Id: 15042, Code: '6212', Name: 'Clerk, reservations, TRAVEL' },
  { Id: 15043, Code: '6212', Name: 'Clerk, travel' },
  { Id: 15044, Code: '6212', Name: 'Clerk, voyages' },
  { Id: 15045, Code: '6212', Name: 'Consultant, holiday' },
  { Id: 15046, Code: '6212', Name: 'Consultant, leisure, travel agents' },
  { Id: 15047, Code: '6212', Name: 'Consultant, reservations, travel agents' },
  { Id: 15048, Code: '6212', Name: 'Consultant, sales, travel' },
  { Id: 15049, Code: '6212', Name: 'Consultant, sales, travel agents' },
  { Id: 15050, Code: '6212', Name: 'Consultant, travel' },
  { Id: 15051, Code: '6212', Name: 'Controller, operations, travel agents' },
  { Id: 15052, Code: '6212', Name: 'Controller, reservation, airlines' },
  { Id: 15053, Code: '6212', Name: 'Coordinator, tour' },
  { Id: 15054, Code: '6212', Name: 'Executive, operations, travel agents' },
  { Id: 15055, Code: '6212', Name: 'Executive, sales, travel' },
  { Id: 15056, Code: '6212', Name: 'Manager, reservations' },
  {
    Id: 15057,
    Code: '6212',
    Name: 'Officer, control, reservations, air transport'
  },
  { Id: 15058, Code: '6212', Name: 'Officer, reservations, air transport' },
  { Id: 15059, Code: '6212', Name: 'Officer, tourism' },
  { Id: 15060, Code: '6212', Name: 'Officer, tourist' },
  { Id: 15061, Code: '6212', Name: 'Official, airline' },
  { Id: 15062, Code: '6212', Name: 'Operator, tour' },
  { Id: 15063, Code: '6212', Name: 'Organiser, tour' },
  { Id: 15064, Code: '6212', Name: 'Organiser, travel' },
  { Id: 15065, Code: '6212', Name: 'Supervisor, centre, travel, airline' },
  { Id: 15066, Code: '6212', Name: 'Supervisor, reservations, air transport' },
  { Id: 15067, Code: '6212', Name: 'Supervisor, travel' },
  { Id: 15068, Code: '6214', Name: 'Agent, handling, passenger' },
  { Id: 15069, Code: '6214', Name: 'Agent, passenger' },
  { Id: 15070, Code: '6214', Name: 'Agent, reservation, airline' },
  { Id: 15071, Code: '6214', Name: 'Agent, service, airlines' },
  { Id: 15072, Code: '6214', Name: 'Agent, service, customer, airline' },
  { Id: 15073, Code: '6214', Name: 'Agent, service, customer, TRAVEL' },
  { Id: 15074, Code: '6214', Name: 'Agent, service, passenger' },
  { Id: 15075, Code: '6214', Name: 'Agent, services, customer, TRAVEL' },
  { Id: 15076, Code: '6214', Name: 'Agent, services, passenger' },
  { Id: 15077, Code: '6214', Name: 'Agent, ticket, airline' },
  {
    Id: 15078,
    Code: '6214',
    Name: 'Assistant, control, passenger, air transport'
  },
  { Id: 15079, Code: '6214', Name: 'Assistant, information, airport' },
  { Id: 15080, Code: '6214', Name: 'Assistant, service, passenger' },
  { Id: 15081, Code: '6214', Name: 'Assistant, services, passenger' },
  {
    Id: 15082,
    Code: '6214',
    Name: 'Assistant, traffic, passenger, air transport'
  },
  { Id: 15083, Code: '6214', Name: 'Attendant, cabin' },
  { Id: 15084, Code: '6214', Name: 'Attendant, flight' },
  { Id: 15085, Code: '6214', Name: 'Clerk, checking-in' },
  { Id: 15086, Code: '6214', Name: 'Crew, cabin' },
  { Id: 15087, Code: '6214', Name: 'Director, service, cabin' },
  { Id: 15088, Code: '6214', Name: 'Director, services, cabin' },
  { Id: 15089, Code: '6214', Name: 'Hostess, air' },
  { Id: 15090, Code: '6214', Name: 'Hostess, ground' },
  { Id: 15091, Code: '6214', Name: 'Hostess, TRAVEL' },
  { Id: 15092, Code: '6214', Name: 'Manager, cabin, airlines' },
  { Id: 15093, Code: '6214', Name: 'Manager, service, flight, airline' },
  { Id: 15094, Code: '6214', Name: 'Manager, service, in-flight' },
  { Id: 15095, Code: '6214', Name: 'Officer, in, check, passenger' },
  { Id: 15096, Code: '6214', Name: 'Officer, services, cabin, airlines' },
  { Id: 15097, Code: '6214', Name: 'Purser, aircraft' },
  { Id: 15098, Code: '6214', Name: 'Purser, airlines' },
  { Id: 15099, Code: '6214', Name: 'Steward, air' },
  { Id: 15100, Code: '6214', Name: 'Steward, aircraft' },
  { Id: 15101, Code: '6214', Name: 'Steward, airline' },
  { Id: 15102, Code: '6214', Name: 'Steward, airlines' },
  { Id: 15103, Code: '6214', Name: 'Steward, cabin, airlines' },
  { Id: 15104, Code: '6214', Name: 'Steward, chief, airlines' },
  { Id: 15105, Code: '6214', Name: 'Steward, flight' },
  { Id: 15106, Code: '6214', Name: 'Steward, ground' },
  { Id: 15107, Code: '6214', Name: "Steward, officer's" },
  { Id: 15108, Code: '6214', Name: 'Superintendent, station, AIRLINE' },
  { Id: 15109, Code: '6214', Name: 'Supervisor, in-flight' },
  { Id: 15110, Code: '6214', Name: 'Supervisor, services, flight' },
  { Id: 15111, Code: '6214', Name: 'Supervisor, services, passenger' },
  { Id: 15112, Code: '6215', Name: 'Assistant, line, gate, railways' },
  { Id: 15113, Code: '6215', Name: 'Assistant, passenger, railways' },
  { Id: 15114, Code: '6215', Name: 'Assistant, station, railway' },
  { Id: 15115, Code: '6215', Name: 'Assistant, station, underground railway' },
  { Id: 15116, Code: '6215', Name: 'Assistant, travel, railways' },
  { Id: 15117, Code: '6215', Name: 'Attendant, car, sleeping' },
  { Id: 15118, Code: '6215', Name: 'Attendant, carriage, railways' },
  { Id: 15119, Code: '6215', Name: 'Attendant, train' },
  { Id: 15120, Code: '6215', Name: 'Boy, call, railways' },
  { Id: 15121, Code: '6215', Name: 'Captain, railways' },
  { Id: 15122, Code: '6215', Name: 'Collector, luggage, excess' },
  { Id: 15123, Code: '6215', Name: 'Collector, ticket, railways' },
  { Id: 15124, Code: '6215', Name: 'Conductor, railways' },
  { Id: 15125, Code: '6215', Name: 'Conductor, train' },
  { Id: 15126, Code: '6215', Name: 'Crew, train' },
  { Id: 15127, Code: '6215', Name: 'Examiner, ticket, railways' },
  { Id: 15128, Code: '6215', Name: 'Foreman, platform, railways' },
  { Id: 15129, Code: '6215', Name: 'Foreman, station, railways' },
  { Id: 15130, Code: '6215', Name: 'Guard, commercial, railways' },
  { Id: 15131, Code: '6215', Name: 'Guard, passenger' },
  { Id: 15132, Code: '6215', Name: 'Guard, railway' },
  { Id: 15133, Code: '6215', Name: 'Guard, railways' },
  { Id: 15134, Code: '6215', Name: 'Guard, train' },
  { Id: 15135, Code: '6215', Name: 'Inspector, platform, railways' },
  { Id: 15136, Code: '6215', Name: 'Inspector, protection, revenue, railways' },
  { Id: 15137, Code: '6215', Name: 'Inspector, station, railways' },
  { Id: 15138, Code: '6215', Name: 'Inspector, ticket, railways' },
  { Id: 15139, Code: '6215', Name: 'Manager, train' },
  { Id: 15140, Code: '6215', Name: 'Officer, protection, revenue, railways' },
  { Id: 15141, Code: '6215', Name: 'Porter, goods, railways' },
  { Id: 15142, Code: '6215', Name: 'Porter, mail' },
  { Id: 15143, Code: '6215', Name: 'Porter, parcel, railways' },
  { Id: 15144, Code: '6215', Name: 'Porter, parcels, railways' },
  { Id: 15145, Code: '6215', Name: 'Porter, transport: railways' },
  { Id: 15146, Code: '6215', Name: 'Porter-guard' },
  { Id: 15147, Code: '6215', Name: 'Porter-signalman' },
  { Id: 15148, Code: '6215', Name: 'Purser, railways' },
  { Id: 15149, Code: '6215', Name: 'Stationman, railway' },
  { Id: 15150, Code: '6215', Name: 'Supervisor, station' },
  { Id: 15151, Code: '6219', Name: 'Assistant, centre, information, tourist' },
  { Id: 15152, Code: '6219', Name: 'Assistant, information, TOURISM' },
  { Id: 15153, Code: '6219', Name: 'Assistant, information, tourist' },
  { Id: 15154, Code: '6219', Name: 'Assistant, information, travel' },
  { Id: 15155, Code: '6219', Name: "Assistant, steward's" },
  { Id: 15156, Code: '6219', Name: 'Assistant, theatre, ENTERTAINMENT' },
  { Id: 15157, Code: '6219', Name: 'Attendant, bus, school' },
  { Id: 15158, Code: '6219', Name: 'Attendant, travel' },
  { Id: 15159, Code: '6219', Name: 'Boy, cabin, shipping' },
  { Id: 15160, Code: '6219', Name: 'Caterer, amusement' },
  { Id: 15161, Code: '6219', Name: 'Chauffeur, punt' },
  { Id: 15162, Code: '6219', Name: 'Clerk, enquiry, travel' },
  { Id: 15163, Code: '6219', Name: 'Clerk, information, tourist' },
  { Id: 15164, Code: '6219', Name: 'Clerk, information, travel' },
  { Id: 15165, Code: '6219', Name: 'Conductor, bus' },
  { Id: 15166, Code: '6219', Name: 'Conductor, PSV' },
  { Id: 15167, Code: '6219', Name: 'Conductor, road transport' },
  { Id: 15168, Code: '6219', Name: 'Courier, tour operator' },
  { Id: 15169, Code: '6219', Name: 'Crew, cabin, shipping' },
  { Id: 15170, Code: '6219', Name: 'Director, tour' },
  { Id: 15171, Code: '6219', Name: 'Guard, bus' },
  { Id: 15172, Code: '6219', Name: 'Guard, road passenger transport' },
  { Id: 15173, Code: '6219', Name: 'Guide' },
  { Id: 15174, Code: '6219', Name: 'Guide, coach' },
  { Id: 15175, Code: '6219', Name: 'Guide, tour' },
  { Id: 15176, Code: '6219', Name: 'Guide, tourist' },
  { Id: 15177, Code: '6219', Name: 'Leader, tour' },
  { Id: 15178, Code: '6219', Name: 'Manager, cabin, ferry' },
  { Id: 15179, Code: '6219', Name: 'Manager, tour' },
  { Id: 15180, Code: '6219', Name: 'Officer, catering, shipping' },
  { Id: 15181, Code: '6219', Name: 'Officer, information, tourist' },
  { Id: 15182, Code: '6219', Name: 'Officer, passenger, HOVERCRAFT' },
  { Id: 15183, Code: '6219', Name: 'Purser' },
  { Id: 15184, Code: '6219', Name: 'Purserette, HOVERCRAFT' },
  { Id: 15185, Code: '6219', Name: 'Representative, holiday' },
  { Id: 15186, Code: '6219', Name: 'Representative, resort' },
  { Id: 15187, Code: '6219', Name: 'Representative, tour operator' },
  { Id: 15188, Code: '6219', Name: 'Steward' },
  { Id: 15189, Code: '6219', Name: 'Steward, cabin, shipping' },
  { Id: 15190, Code: '6219', Name: 'Steward, chief, shipping' },
  { Id: 15191, Code: '6219', Name: 'Steward, saloon, shipping' },
  { Id: 15192, Code: '6219', Name: 'Steward, ship' },
  { Id: 15193, Code: '6219', Name: 'Steward, shipping' },
  { Id: 15194, Code: '6221', Name: 'Artist, tonsorial' },
  { Id: 15195, Code: '6221', Name: 'Assistant, general, hairdressing' },
  { Id: 15196, Code: '6221', Name: "Assistant, hairdresser's" },
  { Id: 15197, Code: '6221', Name: 'Assistant, hairdressing' },
  { Id: 15198, Code: '6221', Name: 'Assistant, salon, hairdressing' },
  { Id: 15199, Code: '6221', Name: 'Barber' },
  { Id: 15200, Code: '6221', Name: 'Colourist, hairdressing' },
  { Id: 15201, Code: '6221', Name: 'Controller, salon, hairdressing' },
  { Id: 15202, Code: '6221', Name: 'Designer, hair' },
  { Id: 15203, Code: '6221', Name: 'Director, art, hairdressing' },
  { Id: 15204, Code: '6221', Name: 'Director, artistic, hairdressing' },
  { Id: 15205, Code: '6221', Name: 'Director, style, hairdressing' },
  { Id: 15206, Code: '6221', Name: 'Dresser, hair' },
  { Id: 15207, Code: '6221', Name: 'Hairdresser' },
  { Id: 15208, Code: '6221', Name: 'Hairstylist' },
  { Id: 15209, Code: '6221', Name: 'Shampooer' },
  { Id: 15210, Code: '6221', Name: 'Shampooist' },
  { Id: 15211, Code: '6221', Name: 'Stylist, hair' },
  { Id: 15212, Code: '6221', Name: 'Stylist, hairdressing' },
  { Id: 15213, Code: '6221', Name: 'Stylist, junior' },
  { Id: 15214, Code: '6221', Name: 'Technician, colour, hairdressing' },
  { Id: 15215, Code: '6221', Name: 'Technician, hair and beauty' },
  { Id: 15216, Code: '6221', Name: 'Technician, hairdressing' },
  { Id: 15217, Code: '6222', Name: 'Adviser, beauty' },
  { Id: 15218, Code: '6222', Name: 'Aesthetician, medical' },
  { Id: 15219, Code: '6222', Name: 'Analyst, colour' },
  { Id: 15220, Code: '6222', Name: 'Artist, body' },
  { Id: 15221, Code: '6222', Name: 'Artist, make-up' },
  { Id: 15222, Code: '6222', Name: 'Artist, tattoo' },
  { Id: 15223, Code: '6222', Name: 'Beautician' },
  { Id: 15224, Code: '6222', Name: 'Consultant, beauty' },
  { Id: 15225, Code: '6222', Name: 'Consultant, image, beautician' },
  { Id: 15226, Code: '6222', Name: 'Consultant, piercing, ear' },
  { Id: 15227, Code: '6222', Name: 'Consultant, slimming' },
  { Id: 15228, Code: '6222', Name: 'Consultant, wig' },
  { Id: 15229, Code: '6222', Name: 'Cosmetologist' },
  { Id: 15230, Code: '6222', Name: 'Counsellor, loss, weight' },
  { Id: 15231, Code: '6222', Name: 'Counsellor, slimming club' },
  { Id: 15232, Code: '6222', Name: 'Dresser, wig' },
  { Id: 15233, Code: '6222', Name: 'Electrologist' },
  { Id: 15234, Code: '6222', Name: 'Electrolysist' },
  { Id: 15235, Code: '6222', Name: 'Leader, slimming club' },
  { Id: 15236, Code: '6222', Name: 'Lecturer, watchers, weight' },
  { Id: 15237, Code: '6222', Name: 'Maker, mask, beautician' },
  { Id: 15238, Code: '6222', Name: 'Manicurist' },
  { Id: 15239, Code: '6222', Name: 'Pedicurist' },
  { Id: 15240, Code: '6222', Name: 'Piercer, body' },
  { Id: 15241, Code: '6222', Name: 'Practitioner, sugaring' },
  { Id: 15242, Code: '6222', Name: 'Specialist, beauty' },
  { Id: 15243, Code: '6222', Name: 'Tattooist' },
  { Id: 15244, Code: '6222', Name: 'Technician, nail' },
  { Id: 15245, Code: '6222', Name: 'Therapist, beauty' },
  { Id: 15246, Code: '6222', Name: 'Therapist, spa' },
  { Id: 15247, Code: '6231', Name: 'Attendant, domestic service' },
  { Id: 15248, Code: '6231', Name: 'Batman, CIVILIAN' },
  { Id: 15249, Code: '6231', Name: 'Companion' },
  { Id: 15250, Code: '6231', Name: 'Companion-help' },
  { Id: 15251, Code: '6231', Name: 'Companion-housekeeper' },
  { Id: 15252, Code: '6231', Name: 'Cook-housekeeper' },
  { Id: 15253, Code: '6231', Name: 'Footman, domestic service' },
  { Id: 15254, Code: '6231', Name: 'Keeper, house' },
  { Id: 15255, Code: '6231', Name: 'Keeper, house, daily' },
  { Id: 15256, Code: '6231', Name: 'Keeper-companion, house' },
  { Id: 15257, Code: '6231', Name: "Maid, lady's" },
  { Id: 15258, Code: '6231', Name: 'Manager, lifestyle' },
  { Id: 15259, Code: '6231', Name: 'Orderly, civilian' },
  { Id: 15260, Code: '6231', Name: 'Steward, flats' },
  { Id: 15261, Code: '6231', Name: 'Steward, service flats' },
  { Id: 15262, Code: '6231', Name: 'Steward, university' },
  { Id: 15263, Code: '6231', Name: 'Valet' },
  { Id: 15264, Code: '6232', Name: "Assistant, caretaker's" },
  {
    Id: 15265,
    Code: '6232',
    Name: "Assistant, keeper's, hall, local government"
  },
  { Id: 15266, Code: '6232', Name: "Assistant, keeper's, school" },
  { Id: 15267, Code: '6232', Name: 'Assistant, premises' },
  { Id: 15268, Code: '6232', Name: 'Attendant, building' },
  { Id: 15269, Code: '6232', Name: 'Attendant, hall, town' },
  { Id: 15270, Code: '6232', Name: 'Attendant, synagogue' },
  { Id: 15271, Code: '6232', Name: 'Caretaker' },
  { Id: 15272, Code: '6232', Name: 'Concierge' },
  { Id: 15273, Code: '6232', Name: 'Custodian, castle' },
  { Id: 15274, Code: '6232', Name: 'Janitor' },
  { Id: 15275, Code: '6232', Name: 'Keeper, cemetery' },
  { Id: 15276, Code: '6232', Name: 'Keeper, chapel' },
  { Id: 15277, Code: '6232', Name: 'Keeper, church' },
  { Id: 15278, Code: '6232', Name: 'Keeper, court' },
  { Id: 15279, Code: '6232', Name: 'Keeper, door, SYNAGOGUE' },
  { Id: 15280, Code: '6232', Name: 'Keeper, ground, burial' },
  { Id: 15281, Code: '6232', Name: 'Keeper, hall' },
  { Id: 15282, Code: '6232', Name: 'Keeper, house, OFFICES' },
  { Id: 15283, Code: '6232', Name: 'Keeper, house, property management' },
  { Id: 15284, Code: '6232', Name: 'Keeper, house, resident, OFFICES' },
  { Id: 15285, Code: '6232', Name: 'Keeper, mortuary' },
  { Id: 15286, Code: '6232', Name: 'Keeper, office' },
  { Id: 15287, Code: '6232', Name: 'Keeper, school' },
  { Id: 15288, Code: '6232', Name: 'Manager, premises, school' },
  {
    Id: 15289,
    Code: '6232',
    Name: 'Manager, site, educational establishments'
  },
  { Id: 15290, Code: '6232', Name: 'Manager, site, school' },
  { Id: 15291, Code: '6232', Name: 'Officer, premises' },
  {
    Id: 15292,
    Code: '6232',
    Name: 'Officer, services, site, educational establishments'
  },
  {
    Id: 15293,
    Code: '6232',
    Name: 'Officer, site, educational establishments'
  },
  { Id: 15294, Code: '6232', Name: 'Porter, college' },
  { Id: 15295, Code: '6232', Name: 'Porter, schools' },
  { Id: 15296, Code: '6232', Name: 'Porter-caretaker' },
  { Id: 15297, Code: '6232', Name: 'Sacristan' },
  { Id: 15298, Code: '6232', Name: 'Sexton' },
  { Id: 15299, Code: '6232', Name: 'Superintendent, building' },
  { Id: 15300, Code: '6232', Name: 'Superintendent, hall, town' },
  { Id: 15301, Code: '6232', Name: 'Superintendent, house, nos' },
  { Id: 15302, Code: '6232', Name: 'Superintendent, precinct' },
  { Id: 15303, Code: '6232', Name: 'Superintendent, RESIDENTIAL BUILDINGS' },
  { Id: 15304, Code: '6232', Name: 'Superintendent, school' },
  {
    Id: 15305,
    Code: '6232',
    Name: 'Supervisor, services, building, educational establishment'
  },
  {
    Id: 15306,
    Code: '6232',
    Name: 'Supervisor, site, educational establishments'
  },
  { Id: 15307, Code: '6232', Name: 'Taker, care' },
  { Id: 15308, Code: '6232', Name: 'Taker, care, CEMETERY' },
  { Id: 15309, Code: '6232', Name: 'Verger' },
  { Id: 15310, Code: '6232', Name: 'Warden, barrack' },
  { Id: 15311, Code: '6232', Name: 'Warden, camp' },
  { Id: 15312, Code: '6232', Name: 'Warden, caravan site' },
  { Id: 15313, Code: '6232', Name: 'Warden, castle' },
  { Id: 15314, Code: '6232', Name: 'Warden, government' },
  { Id: 15315, Code: '6232', Name: 'Warden, hostel' },
  { Id: 15316, Code: '6232', Name: 'Warden, lodging house' },
  { Id: 15317, Code: '6232', Name: "Warden, nurse's home" },
  { Id: 15318, Code: '6232', Name: 'Warden, park, caravan' },
  { Id: 15319, Code: '6232', Name: 'Warden, police service' },
  { Id: 15320, Code: '6232', Name: 'Warden, site, caravan' },
  { Id: 15321, Code: '6232', Name: 'Warden, station' },
  { Id: 15322, Code: '6240', Name: 'Butler' },
  { Id: 15323, Code: '6240', Name: 'Cleaner-in-charge' },
  { Id: 15324, Code: '6240', Name: 'Deputy, lodging house' },
  { Id: 15325, Code: '6240', Name: 'Forewoman, government' },
  { Id: 15326, Code: '6240', Name: 'Inspector, office cleaning services' },
  { Id: 15327, Code: '6240', Name: 'Keeper, house, head' },
  { Id: 15328, Code: '6240', Name: 'Keeper, house, hotel' },
  { Id: 15329, Code: '6240', Name: 'Keeper, house, schools' },
  { Id: 15330, Code: '6240', Name: 'Leader, team, cleaning' },
  { Id: 15331, Code: '6240', Name: 'Leader, team, services, housekeeping' },
  { Id: 15332, Code: '6240', Name: 'Manager, cleaning' },
  { Id: 15333, Code: '6240', Name: 'Manager, cleaning services' },
  { Id: 15334, Code: '6240', Name: 'Manager, domestic' },
  { Id: 15335, Code: '6240', Name: 'Manager, housekeeping' },
  { Id: 15336, Code: '6240', Name: 'Manager, room, linen, hospital service' },
  { Id: 15337, Code: '6240', Name: 'Manager, service, guest' },
  { Id: 15338, Code: '6240', Name: 'Manager, services, domestic' },
  { Id: 15339, Code: '6240', Name: 'Manager, services, linen' },
  { Id: 15340, Code: '6240', Name: 'Sister, housekeeping, HOSPITAL' },
  { Id: 15341, Code: '6240', Name: 'Superintendent, domestic services' },
  { Id: 15342, Code: '6240', Name: 'Supervisor, bedroom' },
  { Id: 15343, Code: '6240', Name: 'Supervisor, cleaning' },
  { Id: 15344, Code: '6240', Name: 'Supervisor, domestic' },
  { Id: 15345, Code: '6240', Name: 'Supervisor, domestic, hospital service' },
  { Id: 15346, Code: '6240', Name: 'Supervisor, floor, hotel' },
  { Id: 15347, Code: '6240', Name: 'Supervisor, housekeeping' },
  {
    Id: 15348,
    Code: '6240',
    Name: 'Supervisor, room, linen, hospital service'
  },
  { Id: 15349, Code: '6240', Name: 'Supervisor, services, hotel' },
  { Id: 15350, Code: '7111', Name: 'Adviser, customer, retail trade' },
  { Id: 15351, Code: '7111', Name: 'Adviser, fashion, retail trade' },
  { Id: 15352, Code: '7111', Name: 'Adviser, sales' },
  {
    Id: 15353,
    Code: '7111',
    Name: 'Adviser, service, customer, retail, wholesale trade'
  },
  { Id: 15354, Code: '7111', Name: "Agent, cleaner's, dry" },
  { Id: 15355, Code: '7111', Name: "Agent, dyer's" },
  { Id: 15356, Code: '7111', Name: 'Agent, laundry' },
  { Id: 15357, Code: '7111', Name: 'Agent, receiving, laundry' },
  { Id: 15358, Code: '7111', Name: 'Assistant, away, take' },
  { Id: 15359, Code: '7111', Name: 'Assistant, bakery, retail trade' },
  { Id: 15360, Code: '7111', Name: "Assistant, bookseller's" },
  { Id: 15361, Code: '7111', Name: 'Assistant, bookstall' },
  { Id: 15362, Code: '7111', Name: 'Assistant, centre, garden' },
  { Id: 15363, Code: '7111', Name: 'Assistant, confectioner and tobacconist' },
  { Id: 15364, Code: '7111', Name: "Assistant, confectioner's" },
  { Id: 15365, Code: '7111', Name: 'Assistant, counter' },
  { Id: 15366, Code: '7111', Name: 'Assistant, customer, retail trade' },
  { Id: 15367, Code: '7111', Name: 'Assistant, dairy, retail trade' },
  { Id: 15368, Code: '7111', Name: "Assistant, dairyman's, retail trade" },
  {
    Id: 15369,
    Code: '7111',
    Name: "Assistant, dealer's, wholesale, retail trade"
  },
  { Id: 15370, Code: '7111', Name: 'Assistant, delicatessen' },
  { Id: 15371, Code: '7111', Name: "Assistant, draper's" },
  { Id: 15372, Code: '7111', Name: 'Assistant, floor, shop, retail trade' },
  { Id: 15373, Code: '7111', Name: "Assistant, florist's" },
  { Id: 15374, Code: '7111', Name: "Assistant, fruiterer's" },
  { Id: 15375, Code: '7111', Name: "Assistant, furrier's" },
  { Id: 15376, Code: '7111', Name: "Assistant, grocer's" },
  { Id: 15377, Code: '7111', Name: 'Assistant, haberdashery' },
  { Id: 15378, Code: '7111', Name: "Assistant, jeweller's" },
  { Id: 15379, Code: '7111', Name: 'Assistant, kiosk' },
  { Id: 15380, Code: '7111', Name: "Assistant, mercer's" },
  { Id: 15381, Code: '7111', Name: "Assistant, merchant's" },
  { Id: 15382, Code: '7111', Name: 'Assistant, NAAFI' },
  { Id: 15383, Code: '7111', Name: "Assistant, newsagent's" },
  { Id: 15384, Code: '7111', Name: 'Assistant, office, post, SUB-POST OFFICE' },
  { Id: 15385, Code: '7111', Name: 'Assistant, office, sub-post' },
  { Id: 15386, Code: '7111', Name: 'Assistant, off-licence' },
  { Id: 15387, Code: '7111', Name: 'Assistant, operations, retail trade' },
  { Id: 15388, Code: '7111', Name: "Assistant, pawnbroker's" },
  { Id: 15389, Code: '7111', Name: "Assistant, perfumer's" },
  { Id: 15390, Code: '7111', Name: "Assistant, poulterer's" },
  { Id: 15391, Code: '7111', Name: 'Assistant, retail' },
  { Id: 15392, Code: '7111', Name: 'Assistant, retail trade' },
  {
    Id: 15393,
    Code: '7111',
    Name: 'Assistant, room, sale, wholesale, retail trade'
  },
  { Id: 15394, Code: '7111', Name: 'Assistant, room, show' },
  { Id: 15395, Code: '7111', Name: 'Assistant, sales' },
  { Id: 15396, Code: '7111', Name: "Assistant, seedsman's" },
  {
    Id: 15397,
    Code: '7111',
    Name: 'Assistant, service, customer, retail trade'
  },
  {
    Id: 15398,
    Code: '7111',
    Name: 'Assistant, service, customer, wholesale trade'
  },
  {
    Id: 15399,
    Code: '7111',
    Name: 'Assistant, services, customer, retail trade'
  },
  { Id: 15400, Code: '7111', Name: 'Assistant, shop' },
  { Id: 15401, Code: '7111', Name: 'Assistant, stall, book' },
  { Id: 15402, Code: '7111', Name: "Assistant, stationer's" },
  { Id: 15403, Code: '7111', Name: 'Assistant, store, retail trade' },
  { Id: 15404, Code: '7111', Name: 'Assistant, stores, retail trade' },
  { Id: 15405, Code: '7111', Name: 'Assistant, takeaway' },
  { Id: 15406, Code: '7111', Name: 'Assistant, take-away food shop' },
  { Id: 15407, Code: '7111', Name: 'Associate, customer, retail trade' },
  { Id: 15408, Code: '7111', Name: 'Associate, retail' },
  { Id: 15409, Code: '7111', Name: 'Associate, sales, retail trade' },
  { Id: 15410, Code: '7111', Name: 'Athlete, retail trade' },
  { Id: 15411, Code: '7111', Name: 'Attendant, kiosk' },
  { Id: 15412, Code: '7111', Name: 'Attendant, room, show' },
  { Id: 15413, Code: '7111', Name: 'Attendant, stores, retail trade' },
  { Id: 15414, Code: '7111', Name: 'Bookseller' },
  { Id: 15415, Code: '7111', Name: 'Boy, programme' },
  { Id: 15416, Code: '7111', Name: 'Colleague, Asda' },
  { Id: 15417, Code: '7111', Name: 'Consultant, beauty, retail trade' },
  { Id: 15418, Code: '7111', Name: 'Consultant, bridal, retail trade' },
  { Id: 15419, Code: '7111', Name: 'Consultant, carpet, retail trade' },
  { Id: 15420, Code: '7111', Name: 'Consultant, cosmetics' },
  { Id: 15421, Code: '7111', Name: 'Consultant, fashion, retail trade' },
  { Id: 15422, Code: '7111', Name: 'Consultant, food, retail trade' },
  { Id: 15423, Code: '7111', Name: 'Consultant, furniture' },
  { Id: 15424, Code: '7111', Name: 'Consultant, perfumery' },
  { Id: 15425, Code: '7111', Name: 'Consultant, sales, retail trade' },
  { Id: 15426, Code: '7111', Name: 'Counsellor, beauty, retail trade' },
  { Id: 15427, Code: '7111', Name: 'Counterhand, take-away food shop' },
  { Id: 15428, Code: '7111', Name: 'Counterhand, wholesale, retail trade' },
  { Id: 15429, Code: '7111', Name: 'Counterman, retail trade' },
  { Id: 15430, Code: '7111', Name: 'Counterman, take-away food shop' },
  { Id: 15431, Code: '7111', Name: 'Cutter, cheese' },
  { Id: 15432, Code: '7111', Name: 'Dairyman, retail trade' },
  { Id: 15433, Code: '7111', Name: 'Executive, sales, retail' },
  { Id: 15434, Code: '7111', Name: 'Fitter, shoe, retail trade' },
  { Id: 15435, Code: '7111', Name: 'Hand, bacon' },
  { Id: 15436, Code: '7111', Name: 'Hand, first, retail trade' },
  { Id: 15437, Code: '7111', Name: 'Hand, provision' },
  { Id: 15438, Code: '7111', Name: 'Help, part-time, retail trade' },
  { Id: 15439, Code: '7111', Name: 'Helper, part-time, retail trade' },
  { Id: 15440, Code: '7111', Name: 'Inspector, NAAFI' },
  { Id: 15441, Code: '7111', Name: 'Member, cast, retail trade' },
  { Id: 15442, Code: '7111', Name: 'Member, team, customer' },
  { Id: 15443, Code: '7111', Name: 'Member, team, retail trade' },
  { Id: 15444, Code: '7111', Name: 'Newsboy, bookstall' },
  { Id: 15445, Code: '7111', Name: 'Operative, kiosk, retail trade' },
  { Id: 15446, Code: '7111', Name: 'Operator, kiosk, retail trade' },
  { Id: 15447, Code: '7111', Name: 'Player, team, retail trade' },
  {
    Id: 15448,
    Code: '7111',
    Name: 'Receiver, laundry, launderette, dry cleaning'
  },
  { Id: 15449, Code: '7111', Name: 'Salesman, bread, retail trade' },
  { Id: 15450, Code: '7111', Name: 'Salesman, building construction' },
  { Id: 15451, Code: '7111', Name: "Salesman, butcher's" },
  { Id: 15452, Code: '7111', Name: 'Salesman, counter' },
  { Id: 15453, Code: '7111', Name: 'Salesman, fish' },
  { Id: 15454, Code: '7111', Name: 'Salesman, fish and fruit' },
  { Id: 15455, Code: '7111', Name: 'Salesman, fish and poultry' },
  { Id: 15456, Code: '7111', Name: "Salesman, fishmonger's" },
  { Id: 15457, Code: '7111', Name: 'Salesman, indoor' },
  { Id: 15458, Code: '7111', Name: 'Salesman, market, wholesale trade' },
  { Id: 15459, Code: '7111', Name: 'Salesman, meat' },
  { Id: 15460, Code: '7111', Name: 'Salesman, milk, retail trade' },
  { Id: 15461, Code: '7111', Name: 'Salesman, motor' },
  { Id: 15462, Code: '7111', Name: 'Salesman, retail' },
  { Id: 15463, Code: '7111', Name: 'Salesman, retail trade' },
  { Id: 15464, Code: '7111', Name: 'Salesman, shop' },
  { Id: 15465, Code: '7111', Name: 'Salesman, showroom' },
  { Id: 15466, Code: '7111', Name: 'Salesman, television' },
  { Id: 15467, Code: '7111', Name: 'Salesman, TV' },
  { Id: 15468, Code: '7111', Name: 'Salesman, warehouse' },
  { Id: 15469, Code: '7111', Name: 'Seller, book' },
  { Id: 15470, Code: '7111', Name: 'Seller, book, Stationery Office' },
  { Id: 15471, Code: '7111', Name: 'Seller, fish and chips' },
  { Id: 15472, Code: '7111', Name: 'Seller, programme' },
  { Id: 15473, Code: '7111', Name: 'Server, take-away food shop' },
  { Id: 15474, Code: '7111', Name: 'Shopper, personal' },
  { Id: 15475, Code: '7111', Name: 'Stylist, personal, retail trade' },
  { Id: 15476, Code: '7111', Name: 'Worker, retail' },
  { Id: 15477, Code: '7111', Name: 'Worker, shop, FRIED FISH' },
  { Id: 15478, Code: '7111', Name: 'Worker, shop, retail trade' },
  { Id: 15479, Code: '7111', Name: 'Worker, shop, take-away food shop' },
  { Id: 15480, Code: '7112', Name: 'Adviser, customer, CHECKOUT' },
  { Id: 15481, Code: '7112', Name: 'Assistant, cash and wrap' },
  { Id: 15482, Code: '7112', Name: 'Assistant, check-out' },
  { Id: 15483, Code: '7112', Name: 'Assistant, forecourt' },
  { Id: 15484, Code: '7112', Name: 'Assistant, garage, petrol station' },
  {
    Id: 15485,
    Code: '7112',
    Name: 'Assistant, general, retail trade, CHECK-OUT'
  },
  { Id: 15486, Code: '7112', Name: 'Assistant, petrol' },
  { Id: 15487, Code: '7112', Name: 'Assistant, sales, forecourt' },
  { Id: 15488, Code: '7112', Name: 'Assistant, sales, petrol' },
  { Id: 15489, Code: '7112', Name: 'Assistant, sales, supermarket' },
  { Id: 15490, Code: '7112', Name: 'Assistant, scanner, retail trade' },
  { Id: 15491, Code: '7112', Name: 'Assistant, scanning, retail trade' },
  { Id: 15492, Code: '7112', Name: 'Assistant, shop, forecourt' },
  { Id: 15493, Code: '7112', Name: 'Assistant, station, petrol' },
  { Id: 15494, Code: '7112', Name: 'Assistant, supermarket' },
  { Id: 15495, Code: '7112', Name: 'Assistant, till' },
  { Id: 15496, Code: '7112', Name: 'Attendant, forecourt' },
  { Id: 15497, Code: '7112', Name: 'Attendant, garage, petrol station' },
  { Id: 15498, Code: '7112', Name: 'Attendant, petrol' },
  { Id: 15499, Code: '7112', Name: 'Attendant, pump, garage' },
  { Id: 15500, Code: '7112', Name: 'Attendant, pump, petrol' },
  { Id: 15501, Code: '7112', Name: 'Attendant, station, filling' },
  { Id: 15502, Code: '7112', Name: 'Attendant, station, petrol' },
  { Id: 15503, Code: '7112', Name: 'Attendant, station, service' },
  { Id: 15504, Code: '7112', Name: 'Cashier, CAFe' },
  { Id: 15505, Code: '7112', Name: 'Cashier, CANTEEN' },
  { Id: 15506, Code: '7112', Name: 'Cashier, check-out' },
  { Id: 15507, Code: '7112', Name: 'Cashier, forecourt' },
  { Id: 15508, Code: '7112', Name: 'Cashier, RESTAURANT' },
  { Id: 15509, Code: '7112', Name: 'Cashier, retail trade' },
  { Id: 15510, Code: '7112', Name: 'Cashier, sales' },
  { Id: 15511, Code: '7112', Name: 'Cashier, shop' },
  { Id: 15512, Code: '7112', Name: 'Cashier, station, petrol' },
  { Id: 15513, Code: '7112', Name: 'Cashier, supermarket' },
  { Id: 15514, Code: '7112', Name: 'Cashier, wholesale trade' },
  { Id: 15515, Code: '7112', Name: 'Checker-out' },
  { Id: 15516, Code: '7112', Name: 'Clerk, check-out' },
  { Id: 15517, Code: '7112', Name: 'Clerk, EPOS' },
  { Id: 15518, Code: '7112', Name: 'Clerk, scanner, retail trade' },
  { Id: 15519, Code: '7112', Name: 'Clerk, scanning, retail trade' },
  { Id: 15520, Code: '7112', Name: 'Controller, EPOS' },
  { Id: 15521, Code: '7112', Name: 'Member, team, customer, retail, CHECKOUT' },
  { Id: 15522, Code: '7112', Name: 'Operative, check-out' },
  { Id: 15523, Code: '7112', Name: 'Operator, cash and wrap' },
  { Id: 15524, Code: '7112', Name: 'Operator, check-out' },
  { Id: 15525, Code: '7112', Name: 'Operator, consol, petrol station' },
  { Id: 15526, Code: '7112', Name: 'Operator, console, petrol station' },
  { Id: 15527, Code: '7112', Name: 'Operator, EPOS' },
  { Id: 15528, Code: '7112', Name: 'Operator, scanner, retail trade' },
  { Id: 15529, Code: '7112', Name: 'Operator, scanning, retail trade' },
  { Id: 15530, Code: '7112', Name: 'Operator, till' },
  { Id: 15531, Code: '7112', Name: 'Pumpman, petrol station' },
  { Id: 15532, Code: '7112', Name: 'Refueller, VEHICLES' },
  { Id: 15533, Code: '7112', Name: 'Salesman, forecourt, garage' },
  { Id: 15534, Code: '7112', Name: 'Salesman, petrol, garage' },
  { Id: 15535, Code: '7113', Name: 'Adviser, sales, inbound' },
  { Id: 15536, Code: '7113', Name: 'Adviser, sales, media' },
  { Id: 15537, Code: '7113', Name: 'Adviser, sales, TELEPHONE SALES' },
  { Id: 15538, Code: '7113', Name: 'Adviser, telemarketing' },
  { Id: 15539, Code: '7113', Name: 'Adviser, telesales' },
  { Id: 15540, Code: '7113', Name: 'Agent, sales, inbound' },
  { Id: 15541, Code: '7113', Name: 'Agent, sales, telephone' },
  { Id: 15542, Code: '7113', Name: 'Agent, telemarketing' },
  { Id: 15543, Code: '7113', Name: 'Agent, telesales' },
  { Id: 15544, Code: '7113', Name: 'Assistant, marketing, telephone' },
  { Id: 15545, Code: '7113', Name: 'Assistant, sales, telephone' },
  { Id: 15546, Code: '7113', Name: 'Assistant, telemarketing' },
  { Id: 15547, Code: '7113', Name: 'Assistant, telesales' },
  { Id: 15548, Code: '7113', Name: 'Canvasser, tele-ad' },
  { Id: 15549, Code: '7113', Name: 'Canvasser, telephone' },
  { Id: 15550, Code: '7113', Name: 'Canvasser, telesales' },
  { Id: 15551, Code: '7113', Name: 'Clerk, order, telephone' },
  { Id: 15552, Code: '7113', Name: 'Clerk, sales, telephone' },
  { Id: 15553, Code: '7113', Name: 'Clerk, tele-ad' },
  { Id: 15554, Code: '7113', Name: 'Clerk, telesales' },
  { Id: 15555, Code: '7113', Name: 'Consultant, sales, tele' },
  { Id: 15556, Code: '7113', Name: 'Consultant, telesales' },
  { Id: 15557, Code: '7113', Name: 'Correspondent, sales' },
  { Id: 15558, Code: '7113', Name: 'Executive, sales, commercial, TELEPHONE' },
  { Id: 15559, Code: '7113', Name: 'Executive, sales, telephone' },
  { Id: 15560, Code: '7113', Name: 'Executive, sales, TELEPHONE SALES' },
  { Id: 15561, Code: '7113', Name: 'Executive, telemarketing' },
  { Id: 15562, Code: '7113', Name: 'Executive, telesales' },
  { Id: 15563, Code: '7113', Name: 'Girl, tele-ad' },
  { Id: 15564, Code: '7113', Name: 'Operative, telesales' },
  { Id: 15565, Code: '7113', Name: 'Operator, line, order' },
  { Id: 15566, Code: '7113', Name: 'Operator, sales, telephone' },
  { Id: 15567, Code: '7113', Name: 'Operator, telemarketing' },
  { Id: 15568, Code: '7113', Name: 'Operator, telesales' },
  { Id: 15569, Code: '7113', Name: 'Representative, sales, telephone' },
  { Id: 15570, Code: '7113', Name: 'Representative, tele-ad' },
  { Id: 15571, Code: '7113', Name: 'Representative, telemarketing' },
  { Id: 15572, Code: '7113', Name: 'Representative, telesales' },
  { Id: 15573, Code: '7113', Name: 'Salesman, telephone' },
  { Id: 15574, Code: '7113', Name: 'Telecanvasser' },
  { Id: 15575, Code: '7113', Name: 'Telemarketer' },
  { Id: 15576, Code: '7113', Name: 'Telephonist, marketing' },
  { Id: 15577, Code: '7113', Name: 'Telephonist, sales' },
  { Id: 15578, Code: '7114', Name: 'Adviser, healthcare, retail chemist' },
  { Id: 15579, Code: '7114', Name: 'Adviser, optical' },
  { Id: 15580, Code: '7114', Name: 'Adviser, sales, optical' },
  { Id: 15581, Code: '7114', Name: 'Assistant, care, health, retail chemist' },
  { Id: 15582, Code: '7114', Name: "Assistant, chemist's" },
  { Id: 15583, Code: '7114', Name: "Assistant, chemist's, retail trade" },
  { Id: 15584, Code: '7114', Name: 'Assistant, counter, chemist' },
  { Id: 15585, Code: '7114', Name: 'Assistant, counter, medicines' },
  { Id: 15586, Code: '7114', Name: 'Assistant, counter, pharmacy' },
  { Id: 15587, Code: '7114', Name: 'Assistant, dispensary' },
  { Id: 15588, Code: '7114', Name: "Assistant, dispenser's" },
  { Id: 15589, Code: '7114', Name: 'Assistant, dispensing' },
  { Id: 15590, Code: '7114', Name: 'Assistant, dispensing, optical' },
  { Id: 15591, Code: '7114', Name: 'Assistant, dispensing, opticians' },
  { Id: 15592, Code: '7114', Name: 'Assistant, dispensing, pharmacy' },
  { Id: 15593, Code: '7114', Name: 'Assistant, healthcare, retail chemist' },
  { Id: 15594, Code: '7114', Name: 'Assistant, lens, contact' },
  { Id: 15595, Code: '7114', Name: 'Assistant, optical' },
  { Id: 15596, Code: '7114', Name: "Assistant, optician's" },
  { Id: 15597, Code: '7114', Name: 'Assistant, optometrist�s' },
  {
    Id: 15598,
    Code: '7114',
    Name: 'Assistant, pharmaceutical, retail chemists'
  },
  { Id: 15599, Code: '7114', Name: "Assistant, pharmacist's" },
  { Id: 15600, Code: '7114', Name: 'Assistant, pharmacy' },
  { Id: 15601, Code: '7114', Name: 'Assistant, sales, retail pharmacy' },
  { Id: 15602, Code: '7114', Name: 'Assistant, shop, chemist�s' },
  { Id: 15603, Code: '7114', Name: 'Consultant, optical' },
  { Id: 15604, Code: '7114', Name: 'Dispenser' },
  { Id: 15605, Code: '7114', Name: 'Dispenser, chemist' },
  { Id: 15606, Code: '7114', Name: 'Dispenser, pharmacy' },
  { Id: 15607, Code: '7114', Name: 'Officer, technical, assistant, pharmacy' },
  { Id: 15608, Code: '7115', Name: 'Adviser, parts, retail trade' },
  { Id: 15609, Code: '7115', Name: 'Adviser, sales, car' },
  { Id: 15610, Code: '7115', Name: 'Adviser, sales, vehicle' },
  { Id: 15611, Code: '7115', Name: 'Assistant, parts' },
  { Id: 15612, Code: '7115', Name: 'Assistant, sales, vehicle' },
  { Id: 15613, Code: '7115', Name: 'Consultant, sales, GARAGE' },
  { Id: 15614, Code: '7115', Name: 'Coordinator, parts' },
  { Id: 15615, Code: '7115', Name: 'Executive, sales, car' },
  { Id: 15616, Code: '7115', Name: 'Executive, sales, fleet' },
  { Id: 15617, Code: '7115', Name: 'Executive, sales, vehicle' },
  { Id: 15618, Code: '7115', Name: 'Man, parts, vehicle, motor' },
  { Id: 15619, Code: '7115', Name: 'Operative, parts, vehicle' },
  { Id: 15620, Code: '7115', Name: 'Partsman, retail trade' },
  { Id: 15621, Code: '7115', Name: 'Representative, motor factors' },
  { Id: 15622, Code: '7115', Name: 'Representative, sales, parts' },
  { Id: 15623, Code: '7115', Name: 'Salesman, car' },
  { Id: 15624, Code: '7115', Name: 'Salesman, parts, motor vehicle repair' },
  { Id: 15625, Code: '7115', Name: 'Seller, car' },
  { Id: 15626, Code: '7115', Name: 'Technician, parts' },
  { Id: 15627, Code: '7121', Name: 'Agent and collector, insurance' },
  { Id: 15628, Code: '7121', Name: 'Agent, assurance' },
  { Id: 15629, Code: '7121', Name: 'Agent, club' },
  { Id: 15630, Code: '7121', Name: 'Agent, credit' },
  { Id: 15631, Code: '7121', Name: 'Agent, district, insurance' },
  { Id: 15632, Code: '7121', Name: 'Agent, door-to-door sales' },
  { Id: 15633, Code: '7121', Name: 'Agent, insurance' },
  { Id: 15634, Code: '7121', Name: 'Agent, mail order house' },
  { Id: 15635, Code: '7121', Name: 'Agent, sales, insurance' },
  {
    Id: 15636,
    Code: '7121',
    Name: 'Agent, wholesale, retail trade: credit trade'
  },
  { Id: 15637, Code: '7121', Name: 'Assistant, canvassing, insurance' },
  { Id: 15638, Code: '7121', Name: 'Canvasser' },
  { Id: 15639, Code: '7121', Name: 'Canvasser and collector' },
  { Id: 15640, Code: '7121', Name: 'Collector and salesman' },
  { Id: 15641, Code: '7121', Name: 'Collector, insurance' },
  { Id: 15642, Code: '7121', Name: 'Collector-agent, insurance' },
  { Id: 15643, Code: '7121', Name: 'Collector-salesman' },
  {
    Id: 15644,
    Code: '7121',
    Name: 'Consultant, beauty, retail trade: door-to-door sales'
  },
  { Id: 15645, Code: '7121', Name: 'Distributor, door-to-door sales' },
  { Id: 15646, Code: '7121', Name: 'Draper, credit' },
  { Id: 15647, Code: '7121', Name: 'Lender, money' },
  { Id: 15648, Code: '7121', Name: 'Manager, plan, party' },
  { Id: 15649, Code: '7121', Name: 'Manager, retail trade: party plan sales' },
  { Id: 15650, Code: '7121', Name: 'Merchant, door-to-door sales' },
  { Id: 15651, Code: '7121', Name: 'Merchant, party plan sales' },
  {
    Id: 15652,
    Code: '7121',
    Name: 'Merchant, wholesale, retail trade: credit trade'
  },
  { Id: 15653, Code: '7121', Name: 'Representative, credit' },
  { Id: 15654, Code: '7121', Name: 'Representative, insurance' },
  { Id: 15655, Code: '7121', Name: 'Representative, mail order house' },
  {
    Id: 15656,
    Code: '7121',
    Name: 'Representative, retail trade: credit trade'
  },
  {
    Id: 15657,
    Code: '7121',
    Name: 'Representative, retail trade: door-to-door sales'
  },
  { Id: 15658, Code: '7121', Name: 'Representative, sales, mail order house' },
  {
    Id: 15659,
    Code: '7121',
    Name: 'Representative, sales, retail trade: door-to-door sales'
  },
  { Id: 15660, Code: '7121', Name: 'Salesman, credit' },
  { Id: 15661, Code: '7121', Name: 'Salesman, mail order house' },
  { Id: 15662, Code: '7121', Name: 'Salesman, retail trade: credit trade' },
  {
    Id: 15663,
    Code: '7121',
    Name: 'Salesman, retail trade: door-to-door sales'
  },
  { Id: 15664, Code: '7121', Name: 'Salesman, travelling, retail trade' },
  { Id: 15665, Code: '7121', Name: 'Salesman-collector' },
  { Id: 15666, Code: '7121', Name: 'Superintendent of canvassers' },
  { Id: 15667, Code: '7121', Name: 'Tallyman' },
  { Id: 15668, Code: '7121', Name: 'Trader, credit' },
  { Id: 15669, Code: '7121', Name: 'Traveller, commercial, DRAPERS, CREDIT' },
  { Id: 15670, Code: '7121', Name: 'Traveller, drapers, credit' },
  { Id: 15671, Code: '7121', Name: 'Traveller, retail trade' },
  { Id: 15672, Code: '7121', Name: 'Traveller-salesman, credit trade' },
  { Id: 15673, Code: '7122', Name: 'Agent, collecting' },
  { Id: 15674, Code: '7122', Name: 'Agent, collection, data' },
  { Id: 15675, Code: '7122', Name: 'Agent, pools, football' },
  { Id: 15676, Code: '7122', Name: 'Agent-collector' },
  { Id: 15677, Code: '7122', Name: 'Assistant, collection' },
  { Id: 15678, Code: '7122', Name: 'Assistant, vending' },
  { Id: 15679, Code: '7122', Name: 'Attendant, booth, toll' },
  { Id: 15680, Code: '7122', Name: 'Attendant, gate, toll' },
  { Id: 15681, Code: '7122', Name: 'Attendant, machine, vending' },
  { Id: 15682, Code: '7122', Name: 'Broker, pawn' },
  { Id: 15683, Code: '7122', Name: 'Checker, meter' },
  { Id: 15684, Code: '7122', Name: 'Clearer, credit' },
  { Id: 15685, Code: '7122', Name: "Clerk, officer's, sheriff" },
  { Id: 15686, Code: '7122', Name: 'Collector, arrears' },
  { Id: 15687, Code: '7122', Name: 'Collector, cash' },
  { Id: 15688, Code: '7122', Name: 'Collector, club' },
  { Id: 15689, Code: '7122', Name: 'Collector, credit' },
  { Id: 15690, Code: '7122', Name: 'Collector, credit trade' },
  { Id: 15691, Code: '7122', Name: 'Collector, debt' },
  { Id: 15692, Code: '7122', Name: 'Collector, electricity supplier' },
  { Id: 15693, Code: '7122', Name: 'Collector, fee, parking' },
  { Id: 15694, Code: '7122', Name: 'Collector, finance company' },
  { Id: 15695, Code: '7122', Name: 'Collector, football pools' },
  { Id: 15696, Code: '7122', Name: 'Collector, GAMING MACHINES' },
  { Id: 15697, Code: '7122', Name: 'Collector, gas supplier' },
  { Id: 15698, Code: '7122', Name: 'Collector, meter' },
  { Id: 15699, Code: '7122', Name: 'Collector, mutuality' },
  { Id: 15700, Code: '7122', Name: 'Collector, pools' },
  { Id: 15701, Code: '7122', Name: 'Collector, rent' },
  { Id: 15702, Code: '7122', Name: 'Collector, retail trade' },
  { Id: 15703, Code: '7122', Name: 'Collector, senior, gas supplier' },
  { Id: 15704, Code: '7122', Name: 'Collector, subscription' },
  { Id: 15705, Code: '7122', Name: 'Collector, toll' },
  { Id: 15706, Code: '7122', Name: 'Collector-agent' },
  { Id: 15707, Code: '7122', Name: 'Controller, collection' },
  { Id: 15708, Code: '7122', Name: 'Controller, collections' },
  { Id: 15709, Code: '7122', Name: 'Controller, debt' },
  { Id: 15710, Code: '7122', Name: 'Converter, cash' },
  { Id: 15711, Code: '7122', Name: 'Driver and collector' },
  { Id: 15712, Code: '7122', Name: 'Engineer, machine, vending' },
  { Id: 15713, Code: '7122', Name: 'Engineer, vending' },
  { Id: 15714, Code: '7122', Name: 'Inspector, meter' },
  { Id: 15715, Code: '7122', Name: 'Inspector, reader, meter' },
  { Id: 15716, Code: '7122', Name: 'Inspector, rent' },
  { Id: 15717, Code: '7122', Name: 'Keeper, bridge, toll' },
  { Id: 15718, Code: '7122', Name: 'Keeper, gate, pier' },
  { Id: 15719, Code: '7122', Name: 'Leader, team, recoveries' },
  { Id: 15720, Code: '7122', Name: 'Officer, collecting, authorised' },
  { Id: 15721, Code: '7122', Name: 'Officer, collection' },
  { Id: 15722, Code: '7122', Name: 'Officer, collections' },
  { Id: 15723, Code: '7122', Name: 'Operative, machine, vending' },
  { Id: 15724, Code: '7122', Name: 'Operative, meter' },
  { Id: 15725, Code: '7122', Name: 'Operator, booth, toll' },
  { Id: 15726, Code: '7122', Name: 'Operator, machine, vending' },
  { Id: 15727, Code: '7122', Name: 'Operator, vending' },
  { Id: 15728, Code: '7122', Name: 'Pawnbroker' },
  { Id: 15729, Code: '7122', Name: 'Purser, pier' },
  { Id: 15730, Code: '7122', Name: 'Reader, meter' },
  { Id: 15731, Code: '7122', Name: 'Recoverer, debt' },
  { Id: 15732, Code: '7122', Name: 'Representative, accounts' },
  { Id: 15733, Code: '7122', Name: 'Representative, default' },
  { Id: 15734, Code: '7123', Name: "Assistant, grocer's, MOBILE" },
  { Id: 15735, Code: '7123', Name: 'Boy, delivery, bakery' },
  { Id: 15736, Code: '7123', Name: 'Boy, delivery, dairy' },
  { Id: 15737, Code: '7123', Name: 'Dairyman, retail trade: delivery round' },
  { Id: 15738, Code: '7123', Name: 'Deliverer, milk' },
  { Id: 15739, Code: '7123', Name: "Deliveryman, baker's" },
  { Id: 15740, Code: '7123', Name: 'Deliveryman, retail milk trade' },
  {
    Id: 15741,
    Code: '7123',
    Name: 'Deliveryman, retail trade: delivery round'
  },
  {
    Id: 15742,
    Code: '7123',
    Name: 'Driver and collector, laundry, launderette, dry cleaning'
  },
  { Id: 15743, Code: '7123', Name: 'Driver-salesman' },
  { Id: 15744, Code: '7123', Name: 'Greengrocer, mobile shop' },
  { Id: 15745, Code: '7123', Name: 'Grocer, TRAVELLING' },
  { Id: 15746, Code: '7123', Name: 'Man, van' },
  { Id: 15747, Code: '7123', Name: 'Manager, shop, mobile' },
  { Id: 15748, Code: '7123', Name: 'Manager, shop, retail trade: mobile shop' },
  { Id: 15749, Code: '7123', Name: 'Milkman, milk retailing' },
  { Id: 15750, Code: '7123', Name: 'Milkman, ROUNDSMAN' },
  {
    Id: 15751,
    Code: '7123',
    Name: 'Retailer, milk, retail trade: delivery round'
  },
  { Id: 15752, Code: '7123', Name: 'Retailer, mobile shop' },
  { Id: 15753, Code: '7123', Name: 'Roundsman' },
  {
    Id: 15754,
    Code: '7123',
    Name: 'Salesman, bread, retail trade: delivery round'
  },
  { Id: 15755, Code: '7123', Name: 'Salesman, cream, ice' },
  { Id: 15756, Code: '7123', Name: 'Salesman, drinks, soft' },
  { Id: 15757, Code: '7123', Name: 'Salesman, ICE CREAM' },
  { Id: 15758, Code: '7123', Name: 'Salesman, ice-cream' },
  {
    Id: 15759,
    Code: '7123',
    Name: 'Salesman, milk, retail trade: delivery round'
  },
  { Id: 15760, Code: '7123', Name: 'Salesman, retail trade: mobile shop' },
  { Id: 15761, Code: '7123', Name: 'Salesman, shop, MOBILE SHOP' },
  { Id: 15762, Code: '7123', Name: 'Salesman, van' },
  { Id: 15763, Code: '7123', Name: 'Salesman-driver' },
  { Id: 15764, Code: '7123', Name: 'Seller, FAST FOOD' },
  { Id: 15765, Code: '7123', Name: 'Shopkeeper, mobile shop' },
  {
    Id: 15766,
    Code: '7123',
    Name: 'Supervisor, round, retail trade: delivery round'
  },
  {
    Id: 15767,
    Code: '7123',
    Name: 'Supervisor, rounds, retail trade: delivery round'
  },
  { Id: 15768, Code: '7123', Name: 'Trader, MOBILE SHOP' },
  { Id: 15769, Code: '7123', Name: 'Traveller, grocers' },
  { Id: 15770, Code: '7123', Name: 'Traveller, van' },
  { Id: 15771, Code: '7123', Name: 'Vendor, cream, ice' },
  { Id: 15772, Code: '7123', Name: 'Vendor, ice-cream' },
  { Id: 15773, Code: '7123', Name: 'Vendor, milk' },
  { Id: 15774, Code: '7124', Name: 'Assistant, barrow' },
  { Id: 15775, Code: '7124', Name: 'Assistant, market' },
  { Id: 15776, Code: '7124', Name: 'Assistant, stall' },
  { Id: 15777, Code: '7124', Name: "Assistant, trader's, market" },
  { Id: 15778, Code: '7124', Name: "Assistant, trader's, street" },
  { Id: 15779, Code: '7124', Name: 'Attendant, stall, market' },
  { Id: 15780, Code: '7124', Name: 'Boy, barrow, retail trade' },
  { Id: 15781, Code: '7124', Name: 'Boy, trolley, street trading' },
  { Id: 15782, Code: '7124', Name: 'Coster' },
  { Id: 15783, Code: '7124', Name: 'Costermonger' },
  { Id: 15784, Code: '7124', Name: 'Dealer, firewood' },
  { Id: 15785, Code: '7124', Name: 'Dealer, log, fire' },
  { Id: 15786, Code: '7124', Name: 'Dealer, market trading' },
  { Id: 15787, Code: '7124', Name: 'Fruiterer, market trading' },
  { Id: 15788, Code: '7124', Name: 'Greengrocer, market trading' },
  { Id: 15789, Code: '7124', Name: 'Haberdasher, market trading' },
  { Id: 15790, Code: '7124', Name: 'Hawker' },
  { Id: 15791, Code: '7124', Name: 'Holder, stall' },
  { Id: 15792, Code: '7124', Name: 'Keeper, stall' },
  { Id: 15793, Code: '7124', Name: 'Man, barrow, retail trade' },
  { Id: 15794, Code: '7124', Name: 'Man, market' },
  { Id: 15795, Code: '7124', Name: 'Manager, retail trade: market stall' },
  { Id: 15796, Code: '7124', Name: 'Manager, stall, retail trade' },
  { Id: 15797, Code: '7124', Name: 'Merchant, firewood' },
  { Id: 15798, Code: '7124', Name: 'Merchant, log' },
  { Id: 15799, Code: '7124', Name: 'Orderly, market' },
  { Id: 15800, Code: '7124', Name: 'Organiser, market' },
  { Id: 15801, Code: '7124', Name: 'Owner, market stall' },
  { Id: 15802, Code: '7124', Name: 'Pedlar' },
  { Id: 15803, Code: '7124', Name: 'Retailer, market trading' },
  { Id: 15804, Code: '7124', Name: 'Salesman, HAWKING' },
  { Id: 15805, Code: '7124', Name: 'Salesman, market' },
  { Id: 15806, Code: '7124', Name: 'Salesman, market trading' },
  { Id: 15807, Code: '7124', Name: 'Seller, FLOWERS' },
  { Id: 15808, Code: '7124', Name: 'Seller, FRUIT, VEGETABLES' },
  { Id: 15809, Code: '7124', Name: 'Seller, newspaper' },
  { Id: 15810, Code: '7124', Name: 'Seller, paper' },
  { Id: 15811, Code: '7124', Name: 'Trader, market' },
  { Id: 15812, Code: '7124', Name: 'Trader, market trading' },
  { Id: 15813, Code: '7124', Name: 'Trader, street' },
  { Id: 15814, Code: '7124', Name: 'Vendor, market' },
  { Id: 15815, Code: '7124', Name: 'Vendor, news' },
  { Id: 15816, Code: '7124', Name: 'Vendor, newspaper' },
  { Id: 15817, Code: '7124', Name: 'Vendor, street' },
  { Id: 15818, Code: '7125', Name: 'Artist, display' },
  {
    Id: 15819,
    Code: '7125',
    Name: 'Assistant, display, retail trade, MERCHANDISING'
  },
  { Id: 15820, Code: '7125', Name: 'Assistant, merchandising' },
  { Id: 15821, Code: '7125', Name: 'Assistant, merchandising, visual' },
  { Id: 15822, Code: '7125', Name: 'Associate, merchandise' },
  { Id: 15823, Code: '7125', Name: 'Associate, visual, retail trade' },
  { Id: 15824, Code: '7125', Name: 'Displayman, retail trade' },
  { Id: 15825, Code: '7125', Name: 'Displayman, window' },
  { Id: 15826, Code: '7125', Name: 'Dresser, window' },
  { Id: 15827, Code: '7125', Name: 'Executive, merchandising' },
  { Id: 15828, Code: '7125', Name: 'Hand, display, retail trade' },
  { Id: 15829, Code: '7125', Name: 'Inspector, window dressing' },
  { Id: 15830, Code: '7125', Name: 'Leader, team, MERCHANDISING' },
  { Id: 15831, Code: '7125', Name: 'Manager, display' },
  { Id: 15832, Code: '7125', Name: 'Manager, merchandise, visual' },
  { Id: 15833, Code: '7125', Name: 'Manager, merchandising, visual' },
  { Id: 15834, Code: '7125', Name: 'Merchandiser' },
  { Id: 15835, Code: '7125', Name: 'Merchandiser, retail' },
  { Id: 15836, Code: '7125', Name: 'Merchandiser, sales' },
  { Id: 15837, Code: '7125', Name: 'Merchandiser, visual' },
  { Id: 15838, Code: '7125', Name: 'Stylist, fashion' },
  { Id: 15839, Code: '7125', Name: 'Stylist, film' },
  { Id: 15840, Code: '7125', Name: 'Stylist, photographic' },
  { Id: 15841, Code: '7125', Name: 'Supervisor, display' },
  { Id: 15842, Code: '7125', Name: 'Trimmer, window' },
  { Id: 15843, Code: '7129', Name: 'Adviser, membership' },
  { Id: 15844, Code: '7129', Name: 'Adviser, sales, inbound, insurance' },
  { Id: 15845, Code: '7129', Name: 'Adviser, sales, insurance' },
  { Id: 15846, Code: '7129', Name: 'Agent, bank' },
  { Id: 15847, Code: '7129', Name: "Agent, brewer's" },
  { Id: 15848, Code: '7129', Name: 'Agent, brewery' },
  { Id: 15849, Code: '7129', Name: 'Agent, commission, insurance' },
  { Id: 15850, Code: '7129', Name: "Agent, company's, tug" },
  { Id: 15851, Code: '7129', Name: 'Agent, emigration' },
  { Id: 15852, Code: '7129', Name: 'Agent, foreign' },
  { Id: 15853, Code: '7129', Name: 'Agent, forwarding' },
  { Id: 15854, Code: '7129', Name: 'Agent, general' },
  { Id: 15855, Code: '7129', Name: 'Agent, hire, car' },
  { Id: 15856, Code: '7129', Name: 'Agent, hiring, film' },
  { Id: 15857, Code: '7129', Name: 'Agent, mercantile' },
  { Id: 15858, Code: '7129', Name: 'Agent, naturalisation' },
  { Id: 15859, Code: '7129', Name: 'Agent, party plan sales' },
  { Id: 15860, Code: '7129', Name: 'Agent, passport' },
  { Id: 15861, Code: '7129', Name: 'Agent, railway' },
  { Id: 15862, Code: '7129', Name: 'Agent, rental, car' },
  { Id: 15863, Code: '7129', Name: 'Agent, traffic, canals' },
  { Id: 15864, Code: '7129', Name: "Broker, printer's" },
  { Id: 15865, Code: '7129', Name: 'Buyer, job' },
  { Id: 15866, Code: '7129', Name: 'Canvasser, advertisement' },
  { Id: 15867, Code: '7129', Name: 'Canvasser, advertising' },
  { Id: 15868, Code: '7129', Name: 'Canvasser, freight' },
  { Id: 15869, Code: '7129', Name: 'Canvasser, insurance' },
  { Id: 15870, Code: '7129', Name: 'Canvasser, traffic' },
  { Id: 15871, Code: '7129', Name: 'Canvasser, transport' },
  {
    Id: 15872,
    Code: '7129',
    Name: 'Consultant, energy, electricity, gas suppliers'
  },
  { Id: 15873, Code: '7129', Name: 'Consultant, food' },
  { Id: 15874, Code: '7129', Name: 'Consultant, sales, insurance' },
  { Id: 15875, Code: '7129', Name: 'Contractor, hire, plant' },
  { Id: 15876, Code: '7129', Name: 'Contractor, posting, bill' },
  { Id: 15877, Code: '7129', Name: 'Controller, hire' },
  { Id: 15878, Code: '7129', Name: 'Controller, hire and sales' },
  { Id: 15879, Code: '7129', Name: 'Controller, hire, plant' },
  { Id: 15880, Code: '7129', Name: 'Coordinator, hire' },
  { Id: 15881, Code: '7129', Name: 'Dealer, party plan sales' },
  { Id: 15882, Code: '7129', Name: 'Demonstrator' },
  { Id: 15883, Code: '7129', Name: 'Demonstrator, technical' },
  { Id: 15884, Code: '7129', Name: 'Demonstrator-consultant' },
  { Id: 15885, Code: '7129', Name: 'Demonstrator-salesman' },
  { Id: 15886, Code: '7129', Name: 'Distributor, film' },
  { Id: 15887, Code: '7129', Name: 'Escort, estate agents' },
  { Id: 15888, Code: '7129', Name: 'Executive, sales, inbound, insurance' },
  { Id: 15889, Code: '7129', Name: 'Finder, land' },
  { Id: 15890, Code: '7129', Name: 'Hirer' },
  { Id: 15891, Code: '7129', Name: 'Hirer, car' },
  { Id: 15892, Code: '7129', Name: 'Manager, service, retail, railways' },
  { Id: 15893, Code: '7129', Name: 'Negotiator' },
  { Id: 15894, Code: '7129', Name: 'Officer, wayleave' },
  { Id: 15895, Code: '7129', Name: 'Operator, hire, skip' },
  { Id: 15896, Code: '7129', Name: 'Operator, rental' },
  {
    Id: 15897,
    Code: '7129',
    Name: 'Organiser, party, retail trade: party plan sales'
  },
  { Id: 15898, Code: '7129', Name: 'Planner, party' },
  { Id: 15899, Code: '7129', Name: 'Planner, sales, party plan' },
  { Id: 15900, Code: '7129', Name: 'Promoter, sales' },
  { Id: 15901, Code: '7129', Name: 'Promoter, wholesale, retail trade' },
  { Id: 15902, Code: '7129', Name: 'Recruiter, CHARITABLE ORGANISATION' },
  { Id: 15903, Code: '7129', Name: 'Recruiter, membership' },
  { Id: 15904, Code: '7129', Name: 'Renter, film' },
  { Id: 15905, Code: '7129', Name: 'Representative' },
  { Id: 15906, Code: '7129', Name: 'Representative, advertisement' },
  { Id: 15907, Code: '7129', Name: 'Representative, advertising' },
  { Id: 15908, Code: '7129', Name: 'Representative, catering' },
  { Id: 15909, Code: '7129', Name: 'Representative, commercial' },
  { Id: 15910, Code: '7129', Name: 'Representative, display' },
  { Id: 15911, Code: '7129', Name: 'Representative, finance' },
  { Id: 15912, Code: '7129', Name: 'Representative, financial' },
  { Id: 15913, Code: '7129', Name: 'Representative, freight' },
  { Id: 15914, Code: '7129', Name: 'Representative, newspaper' },
  { Id: 15915, Code: '7129', Name: 'Representative, publicity' },
  { Id: 15916, Code: '7129', Name: 'Representative, retail trade' },
  {
    Id: 15917,
    Code: '7129',
    Name: 'Representative, retail trade: party plan sales'
  },
  { Id: 15918, Code: '7129', Name: 'Representative, sales, PROPERTY' },
  { Id: 15919, Code: '7129', Name: 'Representative, sales, retail trade' },
  {
    Id: 15920,
    Code: '7129',
    Name: 'Representative, sales, retail trade: party plan sales'
  },
  { Id: 15921, Code: '7129', Name: 'Representative, sales, SERVICES' },
  {
    Id: 15922,
    Code: '7129',
    Name: 'Representative, sales, telecommunications'
  },
  { Id: 15923, Code: '7129', Name: 'Representative, service, railway' },
  { Id: 15924, Code: '7129', Name: 'Representative, shipping' },
  { Id: 15925, Code: '7129', Name: 'Representative, space, printing' },
  { Id: 15926, Code: '7129', Name: 'Representative, traffic, air transport' },
  { Id: 15927, Code: '7129', Name: 'Salesman, advertising' },
  { Id: 15928, Code: '7129', Name: 'Salesman, insurance' },
  { Id: 15929, Code: '7129', Name: 'Salesman, land, estate agents' },
  { Id: 15930, Code: '7129', Name: 'Salesman, property' },
  { Id: 15931, Code: '7129', Name: 'Salesman, retail trade: party plan sales' },
  { Id: 15932, Code: '7129', Name: 'Salesman, SERVICES' },
  { Id: 15933, Code: '7129', Name: 'Salesman, space, advertising' },
  { Id: 15934, Code: '7129', Name: 'Secretary, club, BURIAL CLUB' },
  { Id: 15935, Code: '7129', Name: 'Seller, space, advertising' },
  { Id: 15936, Code: '7129', Name: 'Supervisor, hire, car' },
  { Id: 15937, Code: '7129', Name: 'Supervisor, hire, plant' },
  { Id: 15938, Code: '7129', Name: 'Townsman' },
  { Id: 15939, Code: '7129', Name: 'Traveller, advertisement' },
  { Id: 15940, Code: '7129', Name: 'Traveller, advertising' },
  { Id: 15941, Code: '7129', Name: 'Traveller, commercial, SERVICES' },
  { Id: 15942, Code: '7129', Name: 'Traveller, insurance' },
  { Id: 15943, Code: '7129', Name: 'Viewer, estate agents' },
  { Id: 15944, Code: '7129', Name: 'Viewer, house' },
  { Id: 15945, Code: '7130', Name: 'Assistant, senior, retail trade' },
  { Id: 15946, Code: '7130', Name: 'Checker, dairy' },
  { Id: 15947, Code: '7130', Name: 'Controller, deli' },
  { Id: 15948, Code: '7130', Name: 'Controller, till' },
  { Id: 15949, Code: '7130', Name: 'Foreman, dairy, retail trade' },
  { Id: 15950, Code: '7130', Name: 'Foreman, dairy: retail trade' },
  { Id: 15951, Code: '7130', Name: 'Foreman, district, retail trade' },
  { Id: 15952, Code: '7130', Name: 'Foreman, forecourt' },
  { Id: 15953, Code: '7130', Name: 'Foreman, petrol station' },
  { Id: 15954, Code: '7130', Name: 'Foreman, provision merchants' },
  { Id: 15955, Code: '7130', Name: 'Foreman, retail trade' },
  { Id: 15956, Code: '7130', Name: 'Foreman, rounds' },
  { Id: 15957, Code: '7130', Name: 'Foreman, shop, retail trade' },
  {
    Id: 15958,
    Code: '7130',
    Name: 'Inspector, round, wholesale, retail trade'
  },
  {
    Id: 15959,
    Code: '7130',
    Name: 'Inspector, rounds, wholesale, retail trade'
  },
  { Id: 15960, Code: '7130', Name: 'Leader, section, retail trade' },
  {
    Id: 15961,
    Code: '7130',
    Name: 'Leader, team, operation, sales, retail trade'
  },
  { Id: 15962, Code: '7130', Name: 'Leader, team, retail trade' },
  { Id: 15963, Code: '7130', Name: 'Leader, team, telesales' },
  { Id: 15964, Code: '7130', Name: 'Manager, bakery, retail trade' },
  { Id: 15965, Code: '7130', Name: 'Manager, check-out' },
  { Id: 15966, Code: '7130', Name: 'Manager, clothing, retail trade' },
  { Id: 15967, Code: '7130', Name: 'Manager, concession' },
  { Id: 15968, Code: '7130', Name: 'Manager, concessions' },
  { Id: 15969, Code: '7130', Name: 'Manager, counter, retail trade' },
  { Id: 15970, Code: '7130', Name: 'Manager, counter, trade' },
  { Id: 15971, Code: '7130', Name: 'Manager, department, retail trade' },
  { Id: 15972, Code: '7130', Name: 'Manager, depot, wholesale, retail trade' },
  { Id: 15973, Code: '7130', Name: 'Manager, floor, retail, wholesale trade' },
  { Id: 15974, Code: '7130', Name: 'Manager, food, fresh, retail trade' },
  { Id: 15975, Code: '7130', Name: 'Manager, forecourt' },
  { Id: 15976, Code: '7130', Name: 'Manager, goods, dry' },
  { Id: 15977, Code: '7130', Name: 'Manager, grocery' },
  { Id: 15978, Code: '7130', Name: 'Manager, line, retail trade' },
  { Id: 15979, Code: '7130', Name: 'Manager, parts' },
  { Id: 15980, Code: '7130', Name: 'Manager, produce, retail trade' },
  { Id: 15981, Code: '7130', Name: 'Manager, product, retail trade' },
  { Id: 15982, Code: '7130', Name: 'Manager, provisions' },
  { Id: 15983, Code: '7130', Name: 'Manager, replenishment' },
  { Id: 15984, Code: '7130', Name: 'Manager, section, retail trade' },
  { Id: 15985, Code: '7130', Name: 'Manager, trading, retail trade' },
  { Id: 15986, Code: '7130', Name: 'Superintendent, floor, department store' },
  { Id: 15987, Code: '7130', Name: 'Superintendent, retail trade' },
  { Id: 15988, Code: '7130', Name: 'Supervisor, cash, retail trade' },
  {
    Id: 15989,
    Code: '7130',
    Name: 'Supervisor, centre, service, electricity supplier'
  },
  { Id: 15990, Code: '7130', Name: 'Supervisor, check-out' },
  {
    Id: 15991,
    Code: '7130',
    Name: 'Supervisor, counter, wholesale, retail trade'
  },
  { Id: 15992, Code: '7130', Name: 'Supervisor, kiosk, retail trade' },
  { Id: 15993, Code: '7130', Name: 'Supervisor, market' },
  { Id: 15994, Code: '7130', Name: 'Supervisor, produce' },
  { Id: 15995, Code: '7130', Name: 'Supervisor, retail' },
  { Id: 15996, Code: '7130', Name: 'Supervisor, retail, wholesale trade' },
  { Id: 15997, Code: '7130', Name: 'Supervisor, room, show' },
  { Id: 15998, Code: '7130', Name: 'Supervisor, sales, retail trade' },
  {
    Id: 15999,
    Code: '7130',
    Name: 'Supervisor, sales, retail trade: delivery round'
  },
  { Id: 16000, Code: '7130', Name: 'Supervisor, shop, retail trade' },
  { Id: 16001, Code: '7130', Name: 'Supervisor, store, retail trade' },
  { Id: 16002, Code: '7130', Name: 'Supervisor, supermarket' },
  { Id: 16003, Code: '7130', Name: 'Supervisor, telesales' },
  { Id: 16004, Code: '7130', Name: 'Supervisor, trade, retail trade' },
  { Id: 16005, Code: '7130', Name: 'Walker, floor' },
  { Id: 16006, Code: '7130', Name: 'Walker, shop' },
  { Id: 16007, Code: '7211', Name: 'Adviser, centre, call' },
  { Id: 16008, Code: '7211', Name: 'Adviser, centre, contact' },
  {
    Id: 16009,
    Code: '7211',
    Name: 'Adviser, communications, telecommunications'
  },
  { Id: 16010, Code: '7211', Name: 'Adviser, customer, TELEPHONE' },
  { Id: 16011, Code: '7211', Name: 'Adviser, lines, personal' },
  { Id: 16012, Code: '7211', Name: 'Adviser, management, customer' },
  { Id: 16013, Code: '7211', Name: 'Adviser, retention, sales' },
  { Id: 16014, Code: '7211', Name: 'Adviser, service, customer, call centre' },
  { Id: 16015, Code: '7211', Name: 'Adviser, telephone' },
  { Id: 16016, Code: '7211', Name: 'Agent, centre, call' },
  { Id: 16017, Code: '7211', Name: 'Agent, centre, contact' },
  { Id: 16018, Code: '7211', Name: 'Agent, enquiry' },
  { Id: 16019, Code: '7211', Name: 'Associate, collections' },
  { Id: 16020, Code: '7211', Name: 'Clerk, lines, personal' },
  { Id: 16021, Code: '7211', Name: 'Clerk, order, mail' },
  { Id: 16022, Code: '7211', Name: 'Clerk, telephone' },
  { Id: 16023, Code: '7211', Name: 'Handler, call' },
  { Id: 16024, Code: '7211', Name: 'Operative, centre, call' },
  { Id: 16025, Code: '7211', Name: 'Operative, service, customer' },
  { Id: 16026, Code: '7211', Name: 'Operator, callcentre' },
  { Id: 16027, Code: '7211', Name: 'Operator, centre, call' },
  { Id: 16028, Code: '7211', Name: 'Operator, centre, contact' },
  { Id: 16029, Code: '7211', Name: 'Operator, helpline' },
  { Id: 16030, Code: '7211', Name: 'Operator, line, answer' },
  { Id: 16031, Code: '7211', Name: 'Operator, response, serviceline' },
  { Id: 16032, Code: '7211', Name: 'Operator, response, tele' },
  { Id: 16033, Code: '7211', Name: 'Operator, service, customer' },
  { Id: 16034, Code: '7211', Name: 'Operator, services, customer' },
  { Id: 16035, Code: '7211', Name: 'Telephonist, centre, call' },
  { Id: 16036, Code: '7213', Name: 'Attendant, board, switch, TELEPHONES' },
  { Id: 16037, Code: '7213', Name: 'Attendant, telephone' },
  { Id: 16038, Code: '7213', Name: 'Handler, call, motoring organisation' },
  { Id: 16039, Code: '7213', Name: 'Operative, telephone' },
  { Id: 16040, Code: '7213', Name: 'Operator, enquiry, directory' },
  { Id: 16041, Code: '7213', Name: 'Operator, night, telephone service' },
  { Id: 16042, Code: '7213', Name: 'Operator, switchboard, TELEPHONE' },
  { Id: 16043, Code: '7213', Name: 'Operator, telephone' },
  { Id: 16044, Code: '7213', Name: 'Telephonist' },
  { Id: 16045, Code: '7213', Name: 'Telephonist, shorthand' },
  { Id: 16046, Code: '7213', Name: 'Telephonist-clerk' },
  { Id: 16047, Code: '7213', Name: 'Telephonist-receptionist' },
  { Id: 16048, Code: '7213', Name: 'Telephonist-secretary' },
  { Id: 16049, Code: '7213', Name: 'Telephonist-typist' },
  { Id: 16050, Code: '7214', Name: 'Announcer, transport' },
  { Id: 16051, Code: '7214', Name: 'Assistant, communications' },
  { Id: 16052, Code: '7214', Name: 'Assistant, control, ambulance' },
  { Id: 16053, Code: '7214', Name: 'Assistant, control, emergency services' },
  { Id: 16054, Code: '7214', Name: 'Assistant, control, traffic, air' },
  {
    Id: 16055,
    Code: '7214',
    Name: 'Assistant, room, control, emergency services'
  },
  { Id: 16056, Code: '7214', Name: 'Assistant, traffic, telecommunications' },
  { Id: 16057, Code: '7214', Name: 'Clerk, control, traffic, air' },
  { Id: 16058, Code: '7214', Name: 'Controller, ambulance' },
  { Id: 16059, Code: '7214', Name: 'Controller, cab' },
  { Id: 16060, Code: '7214', Name: 'Controller, communications' },
  { Id: 16061, Code: '7214', Name: 'Controller, divisional, police service' },
  { Id: 16062, Code: '7214', Name: 'Controller, emergency services, RADIO' },
  { Id: 16063, Code: '7214', Name: 'Controller, office, taxi' },
  { Id: 16064, Code: '7214', Name: 'Controller, radio' },
  { Id: 16065, Code: '7214', Name: 'Controller, service, transport, patient' },
  { Id: 16066, Code: '7214', Name: 'Controller, taxi' },
  { Id: 16067, Code: '7214', Name: 'Controller, taxi service' },
  { Id: 16068, Code: '7214', Name: 'Despatcher, radio' },
  {
    Id: 16069,
    Code: '7214',
    Name: 'Despatcher, room, control, emergency services'
  },
  { Id: 16070, Code: '7214', Name: 'Dispatcher, medical, emergency' },
  { Id: 16071, Code: '7214', Name: 'Dispatcher, motoring organisation' },
  { Id: 16072, Code: '7214', Name: 'Dispatcher, radio' },
  { Id: 16073, Code: '7214', Name: 'Dispatcher, taxi' },
  { Id: 16074, Code: '7214', Name: 'Handler, call, emergency services' },
  { Id: 16075, Code: '7214', Name: 'Observer, radar, MARINE' },
  { Id: 16076, Code: '7214', Name: 'Officer, communications' },
  { Id: 16077, Code: '7214', Name: 'Officer, control, ambulance service' },
  { Id: 16078, Code: '7214', Name: 'Officer, control, fire service' },
  { Id: 16079, Code: '7214', Name: 'Officer, control, fire, fire service' },
  {
    Id: 16080,
    Code: '7214',
    Name: 'Officer, deployment, motoring organisation'
  },
  {
    Id: 16081,
    Code: '7214',
    Name: 'Officer, duty, emergency, welfare services'
  },
  { Id: 16082, Code: '7214', Name: 'Officer, radio, AIRCRAFT' },
  { Id: 16083, Code: '7214', Name: 'Officer, radio, government' },
  { Id: 16084, Code: '7214', Name: 'Officer, radio, police' },
  { Id: 16085, Code: '7214', Name: 'Officer, radio, telecommunications' },
  {
    Id: 16086,
    Code: '7214',
    Name: 'Officer, room, control, emergency services'
  },
  { Id: 16087, Code: '7214', Name: 'Officer, telecommunications' },
  { Id: 16088, Code: '7214', Name: 'Officer, traffic, telecommunications' },
  { Id: 16089, Code: '7214', Name: 'Operator, bureau, paging service' },
  { Id: 16090, Code: '7214', Name: 'Operator, cable' },
  {
    Id: 16091,
    Code: '7214',
    Name: 'Operator, centre, control, sheltered housing'
  },
  { Id: 16092, Code: '7214', Name: 'Operator, communications' },
  { Id: 16093, Code: '7214', Name: 'Operator, control, fire, fire service' },
  {
    Id: 16094,
    Code: '7214',
    Name: 'Operator, despatch, aided, computer, emergency services'
  },
  { Id: 16095, Code: '7214', Name: 'Operator, homecall' },
  { Id: 16096, Code: '7214', Name: 'Operator, lifeline' },
  { Id: 16097, Code: '7214', Name: 'Operator, radar' },
  { Id: 16098, Code: '7214', Name: 'Operator, radio' },
  { Id: 16099, Code: '7214', Name: 'Operator, radio relay service' },
  {
    Id: 16100,
    Code: '7214',
    Name: 'Operator, room, control, emergency services'
  },
  { Id: 16101, Code: '7214', Name: 'Operator, telecommunications' },
  { Id: 16102, Code: '7214', Name: 'Operator, telephone, radio' },
  { Id: 16103, Code: '7214', Name: 'Operator, teleprinter' },
  { Id: 16104, Code: '7214', Name: 'Operator, teletype' },
  { Id: 16105, Code: '7214', Name: 'Operator, telex' },
  { Id: 16106, Code: '7214', Name: 'Overseer, radio' },
  { Id: 16107, Code: '7214', Name: 'Signalman, civilian, MOD' },
  { Id: 16108, Code: '7214', Name: 'Signalman, marine' },
  { Id: 16109, Code: '7214', Name: 'Signalman, port' },
  { Id: 16110, Code: '7214', Name: 'Telegraphist' },
  { Id: 16111, Code: '7214', Name: 'TTO, telecommunications' },
  { Id: 16112, Code: '7215', Name: 'Assistant, research, marketing' },
  { Id: 16113, Code: '7215', Name: 'Canvasser, political' },
  { Id: 16114, Code: '7215', Name: 'Collector, data, INTERVIEWING' },
  { Id: 16115, Code: '7215', Name: 'Enumerator, traffic' },
  { Id: 16116, Code: '7215', Name: 'Interviewer, commercial' },
  { Id: 16117, Code: '7215', Name: 'Interviewer, market research' },
  { Id: 16118, Code: '7215', Name: 'Interviewer, research, market' },
  { Id: 16119, Code: '7215', Name: 'Interviewer, SURVEYS' },
  { Id: 16120, Code: '7215', Name: 'Interviewer, telephone' },
  { Id: 16121, Code: '7215', Name: 'Investigator, research, market' },
  { Id: 16122, Code: '7215', Name: 'Representative, telesurveys' },
  { Id: 16123, Code: '7215', Name: 'Researcher, MARKET RESEARCH' },
  { Id: 16124, Code: '7215', Name: 'Researcher, market, INTERVIEWING' },
  { Id: 16125, Code: '7215', Name: 'Researcher, telephone' },
  { Id: 16126, Code: '7215', Name: 'Shopper, mystery' },
  { Id: 16127, Code: '7219', Name: 'Administrator, service, customer' },
  { Id: 16128, Code: '7219', Name: 'Administrator, services, customer' },
  { Id: 16129, Code: '7219', Name: 'Adviser, care, customer' },
  { Id: 16130, Code: '7219', Name: 'Adviser, consumer' },
  { Id: 16131, Code: '7219', Name: 'Adviser, customer' },
  { Id: 16132, Code: '7219', Name: 'Adviser, relations, customer' },
  { Id: 16133, Code: '7219', Name: 'Adviser, service' },
  { Id: 16134, Code: '7219', Name: 'Adviser, service, customer' },
  { Id: 16135, Code: '7219', Name: 'Adviser, services, customer' },
  { Id: 16136, Code: '7219', Name: 'Advocate, customer' },
  { Id: 16137, Code: '7219', Name: 'Agent, reservations, hotel' },
  { Id: 16138, Code: '7219', Name: 'Agent, services, customer' },
  { Id: 16139, Code: '7219', Name: 'Ambassador, student' },
  { Id: 16140, Code: '7219', Name: 'Assistant, care, customer' },
  { Id: 16141, Code: '7219', Name: 'Assistant, centre, service' },
  { Id: 16142, Code: '7219', Name: 'Assistant, liaison, customer' },
  { Id: 16143, Code: '7219', Name: 'Assistant, relations, customer' },
  { Id: 16144, Code: '7219', Name: 'Assistant, reservations, hotel' },
  { Id: 16145, Code: '7219', Name: 'Assistant, service, customer' },
  { Id: 16146, Code: '7219', Name: 'Assistant, services, customer' },
  { Id: 16147, Code: '7219', Name: 'Associate, care, customer' },
  { Id: 16148, Code: '7219', Name: 'Clerk, consumer' },
  { Id: 16149, Code: '7219', Name: 'Clerk, liaison, customer' },
  { Id: 16150, Code: '7219', Name: 'Clerk, query, sales' },
  { Id: 16151, Code: '7219', Name: 'Clerk, reservations' },
  { Id: 16152, Code: '7219', Name: 'Clerk, service, customer' },
  { Id: 16153, Code: '7219', Name: 'Clerk, services, consumer' },
  { Id: 16154, Code: '7219', Name: 'Clerk, services, customer' },
  { Id: 16155, Code: '7219', Name: 'Consultant, care, customer' },
  { Id: 16156, Code: '7219', Name: 'Consultant, sales, after' },
  { Id: 16157, Code: '7219', Name: 'Consultant, service, customer' },
  { Id: 16158, Code: '7219', Name: 'Controller, consumer' },
  { Id: 16159, Code: '7219', Name: 'Coordinator, care, customer' },
  { Id: 16160, Code: '7219', Name: 'Coordinator, service, customer' },
  { Id: 16161, Code: '7219', Name: 'Executive, relations, customer' },
  { Id: 16162, Code: '7219', Name: 'Executive, relations, guest' },
  { Id: 16163, Code: '7219', Name: 'Executive, services, customer' },
  { Id: 16164, Code: '7219', Name: 'Father Christmas' },
  { Id: 16165, Code: '7219', Name: 'Guide, store' },
  { Id: 16166, Code: '7219', Name: 'Handler, complaint' },
  { Id: 16167, Code: '7219', Name: 'Officer, care, customer' },
  { Id: 16168, Code: '7219', Name: 'Officer, commercial, telecommunications' },
  { Id: 16169, Code: '7219', Name: 'Officer, complaints' },
  { Id: 16170, Code: '7219', Name: 'Officer, liaison, customer' },
  { Id: 16171, Code: '7219', Name: 'Officer, liaison, sales' },
  { Id: 16172, Code: '7219', Name: 'Officer, relations, customer' },
  { Id: 16173, Code: '7219', Name: 'Officer, service, customer' },
  { Id: 16174, Code: '7219', Name: 'Officer, services, customer' },
  { Id: 16175, Code: '7219', Name: 'Representative, relations, customer' },
  { Id: 16176, Code: '7219', Name: 'Representative, service, customer' },
  { Id: 16177, Code: '7219', Name: 'Representative, services, customer' },
  { Id: 16178, Code: '7219', Name: 'Reservationist, hotel' },
  { Id: 16179, Code: '7219', Name: 'Specialist, service, customer' },
  { Id: 16180, Code: '7220', Name: 'Adviser, media, social' },
  { Id: 16181, Code: '7220', Name: 'Head of customer quality' },
  { Id: 16182, Code: '7220', Name: 'Head of customer services' },
  { Id: 16183, Code: '7220', Name: 'Leader, team, call centre' },
  { Id: 16184, Code: '7220', Name: 'Leader, team, centre, call' },
  { Id: 16185, Code: '7220', Name: 'Leader, team, CUSTOMER CARE' },
  { Id: 16186, Code: '7220', Name: 'Leader, team, services, customer' },
  { Id: 16187, Code: '7220', Name: 'Manager of product support' },
  { Id: 16188, Code: '7220', Name: 'Manager, aftersales' },
  { Id: 16189, Code: '7220', Name: 'Manager, area, telephone' },
  { Id: 16190, Code: '7220', Name: 'Manager, business, CUSTOMER SERVICE' },
  { Id: 16191, Code: '7220', Name: 'Manager, care, customer' },
  { Id: 16192, Code: '7220', Name: 'Manager, centre, call' },
  { Id: 16193, Code: '7220', Name: 'Manager, complaints' },
  { Id: 16194, Code: '7220', Name: 'Manager, desk, help' },
  { Id: 16195, Code: '7220', Name: 'Manager, development, services, customer' },
  { Id: 16196, Code: '7220', Name: 'Manager, helpdesk' },
  { Id: 16197, Code: '7220', Name: 'Manager, hotline, regional' },
  { Id: 16198, Code: '7220', Name: 'Manager, liaison, customer' },
  { Id: 16199, Code: '7220', Name: 'Manager, relations, customer' },
  { Id: 16200, Code: '7220', Name: 'Manager, reservations, hotel' },
  { Id: 16201, Code: '7220', Name: 'Manager, sales, after' },
  { Id: 16202, Code: '7220', Name: 'Manager, service, customer' },
  { Id: 16203, Code: '7220', Name: 'Manager, service, passenger' },
  { Id: 16204, Code: '7220', Name: 'Manager, services, customer' },
  { Id: 16205, Code: '7220', Name: 'Manager, services, passenger' },
  { Id: 16206, Code: '7220', Name: 'Manager, support, customer' },
  { Id: 16207, Code: '7220', Name: 'Manager, team, call centre' },
  { Id: 16208, Code: '7220', Name: 'Manager, team, service, customer' },
  { Id: 16209, Code: '7220', Name: 'Moderator' },
  { Id: 16210, Code: '7220', Name: 'Moderator, chat' },
  { Id: 16211, Code: '7220', Name: 'Moderator, content, online' },
  { Id: 16212, Code: '7220', Name: 'Moderator, content, on-line' },
  { Id: 16213, Code: '7220', Name: 'Moderator, web' },
  { Id: 16214, Code: '7220', Name: 'Moderator, website' },
  { Id: 16215, Code: '7220', Name: 'Superintendent, radio, PO' },
  { Id: 16216, Code: '7220', Name: 'Superintendent, room, ambulance' },
  { Id: 16217, Code: '7220', Name: 'Superintendent, services, customer' },
  { Id: 16218, Code: '7220', Name: 'Superintendent, telecommunications' },
  {
    Id: 16219,
    Code: '7220',
    Name: 'Superintendent, traffic, telecommunications'
  },
  { Id: 16220, Code: '7220', Name: 'Supervisor, centre, call' },
  { Id: 16221, Code: '7220', Name: 'Supervisor, chief, PO' },
  { Id: 16222, Code: '7220', Name: 'Supervisor, chief, telecommunications' },
  {
    Id: 16223,
    Code: '7220',
    Name: 'Supervisor, communications, air transport'
  },
  { Id: 16224, Code: '7220', Name: 'Supervisor, field, market research' },
  {
    Id: 16225,
    Code: '7220',
    Name: 'Supervisor, grade, higher, telecommunications'
  },
  { Id: 16226, Code: '7220', Name: 'Supervisor, liaison, customer' },
  {
    Id: 16227,
    Code: '7220',
    Name: 'Supervisor, room, control, emergency services'
  },
  { Id: 16228, Code: '7220', Name: 'Supervisor, service, customer' },
  { Id: 16229, Code: '7220', Name: 'Supervisor, services, customer' },
  { Id: 16230, Code: '7220', Name: 'Supervisor, support, customer' },
  { Id: 16231, Code: '7220', Name: 'Supervisor, switchboard' },
  { Id: 16232, Code: '7220', Name: 'Supervisor, telecommunications' },
  { Id: 16233, Code: '7220', Name: 'Supervisor, telephone service' },
  { Id: 16234, Code: '8111', Name: 'Assistant, bakehouse' },
  { Id: 16235, Code: '8111', Name: 'Assistant, bakery' },
  { Id: 16236, Code: '8111', Name: "Assistant, blender's, MARGARINE" },
  { Id: 16237, Code: '8111', Name: "Assistant, boiler's, sugar" },
  { Id: 16238, Code: '8111', Name: 'Assistant, brewery' },
  { Id: 16239, Code: '8111', Name: "Assistant, churner's" },
  {
    Id: 16240,
    Code: '8111',
    Name: "Assistant, confectioner's, sugar confectionery mfr"
  },
  { Id: 16241, Code: '8111', Name: "Assistant, dairyman's, milk processing" },
  { Id: 16242, Code: '8111', Name: 'Assistant, general, food products mfr' },
  { Id: 16243, Code: '8111', Name: 'Assistant, house, char, SUGAR' },
  { Id: 16244, Code: '8111', Name: "Assistant, maker's, cheese" },
  { Id: 16245, Code: '8111', Name: 'Assistant, mill, offal, tobacco mfr' },
  { Id: 16246, Code: '8111', Name: "Assistant, miller's, FOOD" },
  { Id: 16247, Code: '8111', Name: 'Assistant, oven, bakery' },
  { Id: 16248, Code: '8111', Name: 'Assistant, production, food processing' },
  {
    Id: 16249,
    Code: '8111',
    Name: 'Assistant, room, retort, food products mfr'
  },
  { Id: 16250, Code: '8111', Name: 'Assistant, sample, chocolate mfr' },
  { Id: 16251, Code: '8111', Name: "Assistant, spinner's, tobacco mfr" },
  { Id: 16252, Code: '8111', Name: 'Attendant, basin, outflow' },
  { Id: 16253, Code: '8111', Name: 'Attendant, boiler, temper, MARGARINE' },
  { Id: 16254, Code: '8111', Name: 'Attendant, boiler, vacuum, MARGARINE' },
  {
    Id: 16255,
    Code: '8111',
    Name: 'Attendant, centrifugal, food products mfr'
  },
  { Id: 16256, Code: '8111', Name: 'Attendant, conditioner, tobacco mfr' },
  { Id: 16257, Code: '8111', Name: 'Attendant, cooler' },
  { Id: 16258, Code: '8111', Name: 'Attendant, cylinder, tobacco mfr' },
  { Id: 16259, Code: '8111', Name: 'Attendant, diffuser' },
  { Id: 16260, Code: '8111', Name: "Attendant, earth, fuller's, MARGARINE" },
  {
    Id: 16261,
    Code: '8111',
    Name: 'Attendant, equipment, automatic, food products mfr'
  },
  { Id: 16262, Code: '8111', Name: 'Attendant, evaporator, food products mfr' },
  { Id: 16263, Code: '8111', Name: 'Attendant, evaporator, steepwater' },
  { Id: 16264, Code: '8111', Name: 'Attendant, expeller, oil, EDIBLE OILS' },
  { Id: 16265, Code: '8111', Name: 'Attendant, filter, starch mfr' },
  { Id: 16266, Code: '8111', Name: 'Attendant, granary' },
  { Id: 16267, Code: '8111', Name: 'Attendant, inversion' },
  { Id: 16268, Code: '8111', Name: 'Attendant, mixer, food products mfr' },
  { Id: 16269, Code: '8111', Name: 'Attendant, molasses' },
  { Id: 16270, Code: '8111', Name: 'Attendant, montejuice' },
  { Id: 16271, Code: '8111', Name: 'Attendant, multiplex, MARGARINE' },
  { Id: 16272, Code: '8111', Name: 'Attendant, neutraliser' },
  { Id: 16273, Code: '8111', Name: 'Attendant, oven, bakery' },
  { Id: 16274, Code: '8111', Name: 'Attendant, oven, food products mfr' },
  { Id: 16275, Code: '8111', Name: 'Attendant, pan, food products mfr' },
  { Id: 16276, Code: '8111', Name: 'Attendant, plodder, MARGARINE' },
  { Id: 16277, Code: '8111', Name: 'Attendant, press, sugar refining' },
  { Id: 16278, Code: '8111', Name: 'Attendant, pump, air, sugar refining' },
  { Id: 16279, Code: '8111', Name: 'Attendant, room, cold, brewery' },
  { Id: 16280, Code: '8111', Name: 'Attendant, room, ice' },
  { Id: 16281, Code: '8111', Name: 'Attendant, sieve, rotary, tobacco mfr' },
  { Id: 16282, Code: '8111', Name: 'Attendant, sterilizer, distillery' },
  { Id: 16283, Code: '8111', Name: 'Attendant, store, liquor' },
  { Id: 16284, Code: '8111', Name: 'Attendant, stove, starch' },
  { Id: 16285, Code: '8111', Name: 'Attendant, tank, sugar refining' },
  { Id: 16286, Code: '8111', Name: 'Attendant, washer, beet' },
  { Id: 16287, Code: '8111', Name: "Attendant, washer's" },
  { Id: 16288, Code: '8111', Name: 'Baker, food products mfr' },
  { Id: 16289, Code: '8111', Name: 'Baker, oven, hand' },
  { Id: 16290, Code: '8111', Name: 'Bandyman, provender milling' },
  { Id: 16291, Code: '8111', Name: 'Barrelman, RICE STARCH' },
  { Id: 16292, Code: '8111', Name: 'Bathman, bacon, ham, meat curing' },
  { Id: 16293, Code: '8111', Name: 'Beater-up, tobacco mfr' },
  { Id: 16294, Code: '8111', Name: 'Binman, tempering' },
  { Id: 16295, Code: '8111', Name: 'Blancher, FRUIT, VEGETABLES' },
  { Id: 16296, Code: '8111', Name: 'Bleacher, FLOUR' },
  { Id: 16297, Code: '8111', Name: 'Blender, animal feeds mfr' },
  { Id: 16298, Code: '8111', Name: 'Blender, butter' },
  { Id: 16299, Code: '8111', Name: 'Blender, cocoa' },
  { Id: 16300, Code: '8111', Name: 'Blender, coffee' },
  { Id: 16301, Code: '8111', Name: 'Blender, flour' },
  { Id: 16302, Code: '8111', Name: 'Blender, food products mfr' },
  { Id: 16303, Code: '8111', Name: 'Blender, MARGARINE' },
  { Id: 16304, Code: '8111', Name: 'Blender, mineral water mfr' },
  { Id: 16305, Code: '8111', Name: 'Blender, spice' },
  { Id: 16306, Code: '8111', Name: 'Blender, SPIRITS' },
  { Id: 16307, Code: '8111', Name: 'Blender, tea' },
  { Id: 16308, Code: '8111', Name: 'Blender, tobacco mfr' },
  { Id: 16309, Code: '8111', Name: 'Blender, whisky' },
  { Id: 16310, Code: '8111', Name: 'Blender, WINES' },
  { Id: 16311, Code: '8111', Name: 'Boiler, biscuit' },
  { Id: 16312, Code: '8111', Name: 'Boiler, FOOD PRODUCTS' },
  { Id: 16313, Code: '8111', Name: 'Boiler, fruit' },
  { Id: 16314, Code: '8111', Name: 'Boiler, gum, sugar confectionery mfr' },
  { Id: 16315, Code: '8111', Name: 'Boiler, jelly' },
  { Id: 16316, Code: '8111', Name: 'Boiler, liquorice' },
  { Id: 16317, Code: '8111', Name: 'Boiler, pan, sugar refining' },
  { Id: 16318, Code: '8111', Name: 'Boiler, sauce' },
  { Id: 16319, Code: '8111', Name: 'Boiler, sugar' },
  { Id: 16320, Code: '8111', Name: 'Boiler, SUGAR CONFECTIONERY' },
  { Id: 16321, Code: '8111', Name: 'Boilerman, food products mfr' },
  { Id: 16322, Code: '8111', Name: 'Brakesman, biscuit mfr' },
  { Id: 16323, Code: '8111', Name: 'Breaker, cake' },
  { Id: 16324, Code: '8111', Name: 'Breaker, egg' },
  { Id: 16325, Code: '8111', Name: 'Brewer' },
  { Id: 16326, Code: '8111', Name: 'Brewer, beer, ginger' },
  { Id: 16327, Code: '8111', Name: 'Brineman' },
  { Id: 16328, Code: '8111', Name: 'Briner' },
  { Id: 16329, Code: '8111', Name: 'Brusher, flour' },
  { Id: 16330, Code: '8111', Name: 'Bulker, tobacco mfr' },
  { Id: 16331, Code: '8111', Name: 'Buncher, cigar mfr' },
  { Id: 16332, Code: '8111', Name: 'Caker, LIQUORICE' },
  { Id: 16333, Code: '8111', Name: 'Calciner, dextrin' },
  { Id: 16334, Code: '8111', Name: 'Carbonator, brewery' },
  { Id: 16335, Code: '8111', Name: 'Cellarer' },
  { Id: 16336, Code: '8111', Name: 'Cellarman, brewery' },
  { Id: 16337, Code: '8111', Name: 'Cheeser, biscuit mfr' },
  { Id: 16338, Code: '8111', Name: 'Chocolatier' },
  { Id: 16339, Code: '8111', Name: 'Chopper, sugar' },
  { Id: 16340, Code: '8111', Name: 'Churner' },
  { Id: 16341, Code: '8111', Name: 'Cleaner, food products mfr' },
  { Id: 16342, Code: '8111', Name: 'Cleaner, fruit' },
  { Id: 16343, Code: '8111', Name: 'Cleaner, rice' },
  { Id: 16344, Code: '8111', Name: 'Cleaner, scrap, tobacco mfr' },
  { Id: 16345, Code: '8111', Name: 'Cleaner, seed' },
  { Id: 16346, Code: '8111', Name: 'Cleaner, skin, sausage' },
  { Id: 16347, Code: '8111', Name: 'Cleaner, tripe' },
  { Id: 16348, Code: '8111', Name: 'Clearer, oven, bakery' },
  { Id: 16349, Code: '8111', Name: 'Coater, chocolate' },
  { Id: 16350, Code: '8111', Name: 'Coater, sugar, confectionery mfr' },
  { Id: 16351, Code: '8111', Name: 'Cobberer' },
  { Id: 16352, Code: '8111', Name: 'Compounder, food products mfr' },
  { Id: 16353, Code: '8111', Name: 'Compounder, mineral water mfr' },
  { Id: 16354, Code: '8111', Name: 'Concher' },
  { Id: 16355, Code: '8111', Name: 'Condenser, milk processing' },
  { Id: 16356, Code: '8111', Name: 'Conditioner, food products mfr' },
  { Id: 16357, Code: '8111', Name: 'Conditioner, leaf' },
  { Id: 16358, Code: '8111', Name: 'Confectioner, sugar confectionery mfr' },
  { Id: 16359, Code: '8111', Name: 'Controller, temperature, tobacco mfr' },
  { Id: 16360, Code: '8111', Name: 'Controlman, margarine mfr' },
  { Id: 16361, Code: '8111', Name: 'Cook, bakery' },
  { Id: 16362, Code: '8111', Name: 'Cook, food products mfr' },
  { Id: 16363, Code: '8111', Name: 'Cook, tripe dressing' },
  { Id: 16364, Code: '8111', Name: 'Cooker, crisp, potato' },
  { Id: 16365, Code: '8111', Name: 'Cooker, food products mfr' },
  { Id: 16366, Code: '8111', Name: 'Cookerman, cereal foods mfr' },
  { Id: 16367, Code: '8111', Name: 'Cooler, brewery' },
  { Id: 16368, Code: '8111', Name: 'Cooler, food products mfr' },
  { Id: 16369, Code: '8111', Name: 'Coppersidesman' },
  { Id: 16370, Code: '8111', Name: 'Corder, tobacco mfr' },
  { Id: 16371, Code: '8111', Name: 'Coverer, biscuit' },
  { Id: 16372, Code: '8111', Name: 'Coverer, chocolate' },
  { Id: 16373, Code: '8111', Name: 'Cracker, egg' },
  { Id: 16374, Code: '8111', Name: 'Creamer, biscuit mfr' },
  { Id: 16375, Code: '8111', Name: 'Crimper, pasty' },
  { Id: 16376, Code: '8111', Name: 'Crusher, malt' },
  { Id: 16377, Code: '8111', Name: 'Crusher, seed' },
  { Id: 16378, Code: '8111', Name: 'Crusher, seed crushing' },
  { Id: 16379, Code: '8111', Name: 'Cuber, seed crushing' },
  { Id: 16380, Code: '8111', Name: 'Curer, FOOD PRODUCTS' },
  { Id: 16381, Code: '8111', Name: 'Cutter, bakery' },
  { Id: 16382, Code: '8111', Name: 'Cutter, biscuit' },
  { Id: 16383, Code: '8111', Name: 'Cutter, bread, bakery' },
  { Id: 16384, Code: '8111', Name: 'Cutter, food products mfr' },
  { Id: 16385, Code: '8111', Name: 'Cutter, leaf, TOBACCO' },
  { Id: 16386, Code: '8111', Name: 'Cutter, lemon' },
  { Id: 16387, Code: '8111', Name: 'Cutter, lozenge' },
  { Id: 16388, Code: '8111', Name: 'Cutter, peel' },
  { Id: 16389, Code: '8111', Name: 'Cutter, sugar' },
  { Id: 16390, Code: '8111', Name: 'Cutter, sweet' },
  { Id: 16391, Code: '8111', Name: 'Cutter, tobacco' },
  { Id: 16392, Code: '8111', Name: 'Cutter, tobacco mfr' },
  { Id: 16393, Code: '8111', Name: 'Cutter, wafer' },
  { Id: 16394, Code: '8111', Name: 'Dairymaid, dairy products mfr' },
  { Id: 16395, Code: '8111', Name: 'Dairymaid, milk processing' },
  { Id: 16396, Code: '8111', Name: 'Dairyman, dairy products mfr' },
  { Id: 16397, Code: '8111', Name: 'Dairyman, milk processing' },
  { Id: 16398, Code: '8111', Name: 'Decorator, SUGAR CONFECTIONERY' },
  { Id: 16399, Code: '8111', Name: 'Dipper, chocolate' },
  { Id: 16400, Code: '8111', Name: 'Dipper, fondant' },
  { Id: 16401, Code: '8111', Name: 'Dipper, sugar confectionery mfr' },
  { Id: 16402, Code: '8111', Name: 'Dipper, toffee' },
  { Id: 16403, Code: '8111', Name: 'Disintegrator, food products mfr' },
  { Id: 16404, Code: '8111', Name: 'Divider, hand, bakery' },
  { Id: 16405, Code: '8111', Name: 'Draffman, WHISKY' },
  { Id: 16406, Code: '8111', Name: 'Dresser, flour' },
  { Id: 16407, Code: '8111', Name: 'Dresser, seed' },
  { Id: 16408, Code: '8111', Name: 'Dresser, skin, sausage mfr' },
  { Id: 16409, Code: '8111', Name: 'Dresser, tripe' },
  { Id: 16410, Code: '8111', Name: 'Dropper, bacon, ham, meat curing' },
  { Id: 16411, Code: '8111', Name: 'Dropper, sugar confectionery mfr' },
  { Id: 16412, Code: '8111', Name: 'Dropper, sugar refining' },
  { Id: 16413, Code: '8111', Name: 'Dryer, bacon' },
  { Id: 16414, Code: '8111', Name: 'Dryer, brewery' },
  { Id: 16415, Code: '8111', Name: 'Dryer, cereal foods mfr' },
  { Id: 16416, Code: '8111', Name: 'Dryer, grain, malting' },
  { Id: 16417, Code: '8111', Name: 'Dryer, pulp' },
  { Id: 16418, Code: '8111', Name: 'Dryer, tobacco' },
  { Id: 16419, Code: '8111', Name: 'Engineman, malt' },
  { Id: 16420, Code: '8111', Name: 'Enrober, sugar confectionery mfr' },
  { Id: 16421, Code: '8111', Name: 'Expeller, oil seed crushing' },
  { Id: 16422, Code: '8111', Name: 'Extractor, oil' },
  { Id: 16423, Code: '8111', Name: 'Feeder, biscuit' },
  { Id: 16424, Code: '8111', Name: 'Feeder, food products mfr' },
  { Id: 16425, Code: '8111', Name: 'Feeder, hopper, cigarette mfr' },
  { Id: 16426, Code: '8111', Name: 'Feeder-up, tobacco mfr' },
  { Id: 16427, Code: '8111', Name: 'Fermenter' },
  { Id: 16428, Code: '8111', Name: 'Filler, chocolate' },
  { Id: 16429, Code: '8111', Name: 'Filler, pie' },
  { Id: 16430, Code: '8111', Name: 'Filler, sausage' },
  { Id: 16431, Code: '8111', Name: 'Filler, tobacco mfr' },
  { Id: 16432, Code: '8111', Name: 'Filterer, alcoholic drink mfr' },
  { Id: 16433, Code: '8111', Name: 'Filterer, food products mfr' },
  { Id: 16434, Code: '8111', Name: 'Finer, beer' },
  { Id: 16435, Code: '8111', Name: 'Finingsman' },
  { Id: 16436, Code: '8111', Name: 'Finisher, cigar mfr' },
  { Id: 16437, Code: '8111', Name: 'Finisher, flour confectionery mfr' },
  { Id: 16438, Code: '8111', Name: 'Finisher, sugar confectionery mfr' },
  { Id: 16439, Code: '8111', Name: 'Fireman, bakery' },
  { Id: 16440, Code: '8111', Name: 'Fireman, food products mfr' },
  { Id: 16441, Code: '8111', Name: 'Fireman, kiln, food products mfr' },
  { Id: 16442, Code: '8111', Name: 'Fireman, malting' },
  { Id: 16443, Code: '8111', Name: 'Firer, malting' },
  { Id: 16444, Code: '8111', Name: 'Flusher, starch' },
  { Id: 16445, Code: '8111', Name: 'Foreman, animal feeds mfr' },
  { Id: 16446, Code: '8111', Name: 'Foreman, brewer' },
  { Id: 16447, Code: '8111', Name: 'Foreman, brewery' },
  { Id: 16448, Code: '8111', Name: 'Foreman, cattle food mfr' },
  { Id: 16449, Code: '8111', Name: 'Foreman, chocolate mfr' },
  { Id: 16450, Code: '8111', Name: 'Foreman, cigarette mfr' },
  { Id: 16451, Code: '8111', Name: 'Foreman, dairy' },
  { Id: 16452, Code: '8111', Name: 'Foreman, food products mfr' },
  { Id: 16453, Code: '8111', Name: 'Foreman, grain milling' },
  { Id: 16454, Code: '8111', Name: 'Foreman, milk, dairy' },
  { Id: 16455, Code: '8111', Name: 'Foreman, mill, food products mfr' },
  { Id: 16456, Code: '8111', Name: 'Foreman, mineral water mfr' },
  { Id: 16457, Code: '8111', Name: 'Foreman, oil seed crushing' },
  { Id: 16458, Code: '8111', Name: 'Foreman, process, food products mfr' },
  { Id: 16459, Code: '8111', Name: 'Foreman, sugar confectionery mfr' },
  { Id: 16460, Code: '8111', Name: 'Foreman, sugar refining' },
  { Id: 16461, Code: '8111', Name: 'Foreman, tobacco mfr' },
  { Id: 16462, Code: '8111', Name: 'Freezer' },
  { Id: 16463, Code: '8111', Name: 'Fridgeman, ice cream mfr' },
  { Id: 16464, Code: '8111', Name: 'Fryer, food products mfr' },
  { Id: 16465, Code: '8111', Name: 'Gasman, cider mfr' },
  { Id: 16466, Code: '8111', Name: 'Glazer, sugar confectionery mfr' },
  { Id: 16467, Code: '8111', Name: 'Greaser, bakery' },
  { Id: 16468, Code: '8111', Name: 'Greaser, tin, bakery' },
  { Id: 16469, Code: '8111', Name: 'Grinder, coffee' },
  { Id: 16470, Code: '8111', Name: 'Grinder, corn' },
  { Id: 16471, Code: '8111', Name: 'Grinder, food products mfr' },
  { Id: 16472, Code: '8111', Name: 'Grinder, snuff' },
  { Id: 16473, Code: '8111', Name: 'Grinder, sugar' },
  { Id: 16474, Code: '8111', Name: 'Grinderman, grain milling' },
  { Id: 16475, Code: '8111', Name: 'Gristman, brewery' },
  { Id: 16476, Code: '8111', Name: 'Hammerman, tobacco mfr' },
  { Id: 16477, Code: '8111', Name: 'Hand, bakery' },
  { Id: 16478, Code: '8111', Name: 'Hand, bench, sugar confectionery mfr' },
  { Id: 16479, Code: '8111', Name: 'Hand, carbonating' },
  { Id: 16480, Code: '8111', Name: 'Hand, carbonation, SUGAR' },
  { Id: 16481, Code: '8111', Name: 'Hand, chocolate' },
  { Id: 16482, Code: '8111', Name: 'Hand, confectionery' },
  { Id: 16483, Code: '8111', Name: 'Hand, cooler, sugar refining' },
  { Id: 16484, Code: '8111', Name: 'Hand, cream, LIQUORICE' },
  { Id: 16485, Code: '8111', Name: 'Hand, creamery' },
  { Id: 16486, Code: '8111', Name: 'Hand, dairy, milk processing' },
  { Id: 16487, Code: '8111', Name: 'Hand, deck, milk processing' },
  {
    Id: 16488,
    Code: '8111',
    Name: 'Hand, depository, sugar confectionery mfr'
  },
  { Id: 16489, Code: '8111', Name: 'Hand, divider, bakery' },
  { Id: 16490, Code: '8111', Name: 'Hand, essence' },
  { Id: 16491, Code: '8111', Name: 'Hand, filtration, alcoholic drink mfr' },
  { Id: 16492, Code: '8111', Name: 'Hand, frame, mustard' },
  { Id: 16493, Code: '8111', Name: 'Hand, frame, sugar confectionery mfr' },
  { Id: 16494, Code: '8111', Name: 'Hand, general, bacon, ham, meat curing' },
  { Id: 16495, Code: '8111', Name: 'Hand, general, bakery' },
  { Id: 16496, Code: '8111', Name: 'Hand, kitchen, food products mfr' },
  { Id: 16497, Code: '8111', Name: 'Hand, leading, food products mfr' },
  { Id: 16498, Code: '8111', Name: 'Hand, liquorice' },
  { Id: 16499, Code: '8111', Name: 'Hand, mill, animal feeds mfr' },
  { Id: 16500, Code: '8111', Name: 'Hand, mill, flour' },
  { Id: 16501, Code: '8111', Name: 'Hand, mill, food processing' },
  { Id: 16502, Code: '8111', Name: 'Hand, mill, grain' },
  { Id: 16503, Code: '8111', Name: 'Hand, mill, provender' },
  { Id: 16504, Code: '8111', Name: 'Hand, oven, bakery' },
  { Id: 16505, Code: '8111', Name: 'Hand, plant, bakery' },
  { Id: 16506, Code: '8111', Name: 'Hand, rock, sugar confectionery mfr' },
  { Id: 16507, Code: '8111', Name: 'Hand, slab' },
  { Id: 16508, Code: '8111', Name: 'Handyman, nos, grist milling' },
  { Id: 16509, Code: '8111', Name: 'Hearthman, brewery' },
  { Id: 16510, Code: '8111', Name: "Help, baker's" },
  { Id: 16511, Code: '8111', Name: 'Help, general, bakery' },
  { Id: 16512, Code: '8111', Name: 'Helper, bakehouse' },
  { Id: 16513, Code: '8111', Name: 'Homogeniser' },
  { Id: 16514, Code: '8111', Name: 'Hopperman, bakery' },
  { Id: 16515, Code: '8111', Name: 'Houseman, steep, starch mfr' },
  { Id: 16516, Code: '8111', Name: 'Houseman, tun, brewery' },
  { Id: 16517, Code: '8111', Name: 'Icer' },
  { Id: 16518, Code: '8111', Name: 'Inspector, cellar' },
  { Id: 16519, Code: '8111', Name: 'Juiceman' },
  { Id: 16520, Code: '8111', Name: 'Kibbler, food products mfr' },
  { Id: 16521, Code: '8111', Name: 'Kipperer' },
  { Id: 16522, Code: '8111', Name: 'Kneader, bakery' },
  { Id: 16523, Code: '8111', Name: 'Knocker-out, chocolate mfr' },
  { Id: 16524, Code: '8111', Name: 'Layer-out, tobacco' },
  { Id: 16525, Code: '8111', Name: 'Leader, line, food products mfr' },
  { Id: 16526, Code: '8111', Name: 'Leaser, spinning, machine' },
  { Id: 16527, Code: '8111', Name: 'Liner, tin, bakery' },
  { Id: 16528, Code: '8111', Name: 'Lineworker, food products mfr' },
  { Id: 16529, Code: '8111', Name: 'Linker, sausage' },
  { Id: 16530, Code: '8111', Name: 'Liquefier, butter' },
  { Id: 16531, Code: '8111', Name: 'Machinist, animal feeds mfr' },
  { Id: 16532, Code: '8111', Name: 'Machinist, assembly, plug' },
  { Id: 16533, Code: '8111', Name: 'Machinist, bakery' },
  { Id: 16534, Code: '8111', Name: 'Machinist, banding' },
  { Id: 16535, Code: '8111', Name: 'Machinist, biscuit' },
  { Id: 16536, Code: '8111', Name: 'Machinist, bread, flour confectionery' },
  { Id: 16537, Code: '8111', Name: 'Machinist, brewery' },
  { Id: 16538, Code: '8111', Name: 'Machinist, centrifugal, SUGAR' },
  { Id: 16539, Code: '8111', Name: 'Machinist, cider mfr' },
  { Id: 16540, Code: '8111', Name: 'Machinist, cigar' },
  { Id: 16541, Code: '8111', Name: 'Machinist, cigarette' },
  { Id: 16542, Code: '8111', Name: 'Machinist, cream, ice' },
  { Id: 16543, Code: '8111', Name: 'Machinist, cutting and wrapping, bakery' },
  {
    Id: 16544,
    Code: '8111',
    Name: 'Machinist, cutting, sugar confectionery mfr'
  },
  { Id: 16545, Code: '8111', Name: 'Machinist, cutting, tobacco mfr' },
  { Id: 16546, Code: '8111', Name: 'Machinist, dairy' },
  { Id: 16547, Code: '8111', Name: 'Machinist, distillery' },
  { Id: 16548, Code: '8111', Name: 'Machinist, dough' },
  { Id: 16549, Code: '8111', Name: 'Machinist, drying, food products mfr' },
  { Id: 16550, Code: '8111', Name: 'Machinist, drying, tobacco mfr' },
  { Id: 16551, Code: '8111', Name: 'Machinist, enrobing' },
  { Id: 16552, Code: '8111', Name: 'Machinist, filling, skin, SAUSAGE' },
  { Id: 16553, Code: '8111', Name: 'Machinist, fondant' },
  { Id: 16554, Code: '8111', Name: 'Machinist, food products mfr' },
  { Id: 16555, Code: '8111', Name: 'Machinist, grading, SUGAR' },
  { Id: 16556, Code: '8111', Name: 'Machinist, grain milling' },
  { Id: 16557, Code: '8111', Name: 'Machinist, ice-cream' },
  { Id: 16558, Code: '8111', Name: 'Machinist, making, cigarette' },
  { Id: 16559, Code: '8111', Name: 'Machinist, making, sausage' },
  { Id: 16560, Code: '8111', Name: 'Machinist, malting' },
  { Id: 16561, Code: '8111', Name: 'Machinist, milk, dried' },
  { Id: 16562, Code: '8111', Name: 'Machinist, milling, food products mfr' },
  { Id: 16563, Code: '8111', Name: 'Machinist, mixing, bakery' },
  { Id: 16564, Code: '8111', Name: 'Machinist, mixing, food products mfr' },
  { Id: 16565, Code: '8111', Name: 'Machinist, pie' },
  { Id: 16566, Code: '8111', Name: 'Machinist, rubbing, food products mfr' },
  { Id: 16567, Code: '8111', Name: 'Machinist, slicing, bread' },
  { Id: 16568, Code: '8111', Name: 'Machinist, soft drinks mfr' },
  { Id: 16569, Code: '8111', Name: 'Machinist, starch' },
  { Id: 16570, Code: '8111', Name: 'Machinist, stemming' },
  { Id: 16571, Code: '8111', Name: 'Machinist, stoving, TOBACCO' },
  { Id: 16572, Code: '8111', Name: 'Machinist, sugar refining' },
  { Id: 16573, Code: '8111', Name: 'Machinist, tempering, CHOCOLATE' },
  { Id: 16574, Code: '8111', Name: 'Machinist, threshing, tobacco' },
  { Id: 16575, Code: '8111', Name: 'Machinist, tobacco' },
  { Id: 16576, Code: '8111', Name: 'Machinist, tobacco mfr' },
  { Id: 16577, Code: '8111', Name: 'Machinist, toffee' },
  { Id: 16578, Code: '8111', Name: 'Machinist, vinery' },
  { Id: 16579, Code: '8111', Name: 'Machinist, washing, food products mfr' },
  { Id: 16580, Code: '8111', Name: 'Maker, base, custard powder mfr' },
  { Id: 16581, Code: '8111', Name: 'Maker, biscuit' },
  { Id: 16582, Code: '8111', Name: 'Maker, biscuit, dog' },
  { Id: 16583, Code: '8111', Name: 'Maker, bon-bon, sugar confectionery mfr' },
  { Id: 16584, Code: '8111', Name: 'Maker, brine, preserves mfr' },
  { Id: 16585, Code: '8111', Name: 'Maker, bunch' },
  { Id: 16586, Code: '8111', Name: 'Maker, butter' },
  { Id: 16587, Code: '8111', Name: 'Maker, cake, fish' },
  { Id: 16588, Code: '8111', Name: 'Maker, cake, pontefract' },
  { Id: 16589, Code: '8111', Name: 'Maker, cake, sugar confectionery mfr' },
  { Id: 16590, Code: '8111', Name: 'Maker, caramel, sugar refining' },
  { Id: 16591, Code: '8111', Name: 'Maker, cheese' },
  { Id: 16592, Code: '8111', Name: 'Maker, chocolate' },
  { Id: 16593, Code: '8111', Name: 'Maker, cider' },
  { Id: 16594, Code: '8111', Name: 'Maker, cigar' },
  { Id: 16595, Code: '8111', Name: 'Maker, cigarette' },
  {
    Id: 16596,
    Code: '8111',
    Name: 'Maker, confectionery, SUGAR CONFECTIONERY'
  },
  { Id: 16597, Code: '8111', Name: 'Maker, cream' },
  { Id: 16598, Code: '8111', Name: 'Maker, cream, ice' },
  { Id: 16599, Code: '8111', Name: 'Maker, dough, flour confectionery mfr' },
  { Id: 16600, Code: '8111', Name: 'Maker, essence, FOOD' },
  { Id: 16601, Code: '8111', Name: 'Maker, finings' },
  { Id: 16602, Code: '8111', Name: 'Maker, flake' },
  { Id: 16603, Code: '8111', Name: 'Maker, glucose' },
  { Id: 16604, Code: '8111', Name: 'Maker, ice-cream' },
  { Id: 16605, Code: '8111', Name: 'Maker, jam' },
  { Id: 16606, Code: '8111', Name: 'Maker, jelly' },
  { Id: 16607, Code: '8111', Name: 'Maker, lard' },
  { Id: 16608, Code: '8111', Name: 'Maker, lozenge' },
  { Id: 16609, Code: '8111', Name: 'Maker, malt' },
  { Id: 16610, Code: '8111', Name: 'Maker, margarine' },
  { Id: 16611, Code: '8111', Name: 'Maker, marzipan' },
  { Id: 16612, Code: '8111', Name: 'Maker, meat, potted' },
  { Id: 16613, Code: '8111', Name: 'Maker, mould, sugar confectionery mfr' },
  { Id: 16614, Code: '8111', Name: 'Maker, paste, food products mfr' },
  { Id: 16615, Code: '8111', Name: 'Maker, pepper' },
  { Id: 16616, Code: '8111', Name: 'Maker, pickle' },
  { Id: 16617, Code: '8111', Name: 'Maker, pie' },
  { Id: 16618, Code: '8111', Name: 'Maker, plug, tobacco mfr' },
  { Id: 16619, Code: '8111', Name: 'Maker, powder, FOOD' },
  { Id: 16620, Code: '8111', Name: 'Maker, pudding' },
  { Id: 16621, Code: '8111', Name: 'Maker, sauce' },
  { Id: 16622, Code: '8111', Name: 'Maker, sausage' },
  { Id: 16623, Code: '8111', Name: 'Maker, skin, sausage' },
  { Id: 16624, Code: '8111', Name: 'Maker, snuff' },
  { Id: 16625, Code: '8111', Name: 'Maker, soup' },
  { Id: 16626, Code: '8111', Name: 'Maker, starch' },
  { Id: 16627, Code: '8111', Name: 'Maker, sugar' },
  { Id: 16628, Code: '8111', Name: 'Maker, sweet' },
  { Id: 16629, Code: '8111', Name: 'Maker, syrup' },
  { Id: 16630, Code: '8111', Name: 'Maker, wafer' },
  { Id: 16631, Code: '8111', Name: 'Maker, water, mineral' },
  { Id: 16632, Code: '8111', Name: 'Maker, water, soda' },
  { Id: 16633, Code: '8111', Name: 'Maltster' },
  { Id: 16634, Code: '8111', Name: 'Man, acid, sugar refining' },
  { Id: 16635, Code: '8111', Name: 'Man, autolysis' },
  { Id: 16636, Code: '8111', Name: 'Man, battery, food products mfr' },
  { Id: 16637, Code: '8111', Name: 'Man, cake, linseed' },
  { Id: 16638, Code: '8111', Name: 'Man, carbonation, SUGAR' },
  { Id: 16639, Code: '8111', Name: 'Man, chiller, brewery' },
  { Id: 16640, Code: '8111', Name: 'Man, clarifier' },
  { Id: 16641, Code: '8111', Name: 'Man, conche' },
  { Id: 16642, Code: '8111', Name: 'Man, converter, sugar, glucose mfr' },
  { Id: 16643, Code: '8111', Name: 'Man, dough' },
  { Id: 16644, Code: '8111', Name: 'Man, evaporator, food products mfr' },
  {
    Id: 16645,
    Code: '8111',
    Name: 'Man, evaporator, multiple, sugar, glucose mfr'
  },
  { Id: 16646, Code: '8111', Name: 'Man, fermenting, distillery' },
  { Id: 16647, Code: '8111', Name: 'Man, filter, alcoholic drink mfr' },
  { Id: 16648, Code: '8111', Name: 'Man, filter, vinegar mfr' },
  { Id: 16649, Code: '8111', Name: 'Man, flavouring, CEREALS' },
  { Id: 16650, Code: '8111', Name: 'Man, floor and kiln, malting' },
  { Id: 16651, Code: '8111', Name: 'Man, floor, malting' },
  { Id: 16652, Code: '8111', Name: 'Man, granary' },
  { Id: 16653, Code: '8111', Name: 'Man, granulator, SUGAR' },
  { Id: 16654, Code: '8111', Name: 'Man, kiln, distillery' },
  { Id: 16655, Code: '8111', Name: 'Man, liquor, sugar refining' },
  { Id: 16656, Code: '8111', Name: 'Man, machinery, grain' },
  { Id: 16657, Code: '8111', Name: 'Man, malt' },
  { Id: 16658, Code: '8111', Name: 'Man, melter, food products mfr' },
  { Id: 16659, Code: '8111', Name: 'Man, mill, malt' },
  { Id: 16660, Code: '8111', Name: 'Man, mill, salt mfr' },
  { Id: 16661, Code: '8111', Name: 'Man, mixer, animal feeds mfr' },
  { Id: 16662, Code: '8111', Name: 'Man, pan, boiling, FOODS' },
  { Id: 16663, Code: '8111', Name: 'Man, pan, food products mfr' },
  { Id: 16664, Code: '8111', Name: 'Man, pan, sugar refining' },
  { Id: 16665, Code: '8111', Name: 'Man, pan, vacuum, food products mfr' },
  { Id: 16666, Code: '8111', Name: 'Man, paraflow, BREWING' },
  {
    Id: 16667,
    Code: '8111',
    Name: 'Man, plant, dehydration, food products mfr'
  },
  { Id: 16668, Code: '8111', Name: 'Man, purifier, food products mfr' },
  { Id: 16669, Code: '8111', Name: 'Man, refrigerator, brewery' },
  { Id: 16670, Code: '8111', Name: 'Man, retort, canned foods mfr' },
  { Id: 16671, Code: '8111', Name: 'Man, room, back, distillery' },
  { Id: 16672, Code: '8111', Name: 'Man, room, malt' },
  { Id: 16673, Code: '8111', Name: 'Man, room, mash' },
  { Id: 16674, Code: '8111', Name: 'Man, room, tun' },
  { Id: 16675, Code: '8111', Name: 'Man, safe, sugar refining' },
  { Id: 16676, Code: '8111', Name: 'Man, service, chocolate mfr' },
  { Id: 16677, Code: '8111', Name: 'Man, side, brewery' },
  { Id: 16678, Code: '8111', Name: 'Man, sulphitation' },
  { Id: 16679, Code: '8111', Name: 'Man, table, food products mfr' },
  { Id: 16680, Code: '8111', Name: 'Man, toffee' },
  { Id: 16681, Code: '8111', Name: 'Man, tunnel, ICE CREAM' },
  { Id: 16682, Code: '8111', Name: 'Man, yeast' },
  { Id: 16683, Code: '8111', Name: 'Manufacturer, food' },
  { Id: 16684, Code: '8111', Name: 'Manufacturer, food products mfr' },
  { Id: 16685, Code: '8111', Name: 'Manufacturer, soft drinks mfr' },
  { Id: 16686, Code: '8111', Name: 'Manufacturer, tobacco mfr' },
  { Id: 16687, Code: '8111', Name: 'Marker, cask' },
  { Id: 16688, Code: '8111', Name: 'Marker, sugar confectionery mfr' },
  { Id: 16689, Code: '8111', Name: 'Mashman' },
  { Id: 16690, Code: '8111', Name: "Mate, liquorman's, SUGAR" },
  { Id: 16691, Code: '8111', Name: 'Melter, sugar' },
  { Id: 16692, Code: '8111', Name: 'Melter, sugar refining' },
  { Id: 16693, Code: '8111', Name: 'Miller, animal feeds mfr' },
  { Id: 16694, Code: '8111', Name: 'Miller, brewery' },
  { Id: 16695, Code: '8111', Name: 'Miller, corn' },
  { Id: 16696, Code: '8111', Name: 'Miller, flour' },
  { Id: 16697, Code: '8111', Name: 'Miller, grain' },
  { Id: 16698, Code: '8111', Name: 'Miller, grain milling' },
  { Id: 16699, Code: '8111', Name: 'Miller, malt' },
  { Id: 16700, Code: '8111', Name: 'Miller, mustard' },
  { Id: 16701, Code: '8111', Name: 'Miller, oil' },
  { Id: 16702, Code: '8111', Name: 'Miller, provender' },
  { Id: 16703, Code: '8111', Name: 'Miller, rice' },
  { Id: 16704, Code: '8111', Name: 'Miller, spice' },
  { Id: 16705, Code: '8111', Name: 'Miller, sugar refining' },
  { Id: 16706, Code: '8111', Name: 'Miller, whisky distilling' },
  { Id: 16707, Code: '8111', Name: 'Minder, oven, bakery' },
  { Id: 16708, Code: '8111', Name: 'Minder, retort, food canning' },
  { Id: 16709, Code: '8111', Name: 'Mixer, animal feeds mfr' },
  { Id: 16710, Code: '8111', Name: 'Mixer, batch' },
  { Id: 16711, Code: '8111', Name: 'Mixer, cake' },
  { Id: 16712, Code: '8111', Name: 'Mixer, chocolate' },
  { Id: 16713, Code: '8111', Name: 'Mixer, colour, custard powder mfr' },
  { Id: 16714, Code: '8111', Name: 'Mixer, compound, animal feeds mfr' },
  { Id: 16715, Code: '8111', Name: 'Mixer, cream, ice' },
  { Id: 16716, Code: '8111', Name: 'Mixer, dough, flour confectionery mfr' },
  { Id: 16717, Code: '8111', Name: 'Mixer, flour' },
  { Id: 16718, Code: '8111', Name: 'Mixer, flour confectionery mfr' },
  { Id: 16719, Code: '8111', Name: 'Mixer, food' },
  { Id: 16720, Code: '8111', Name: 'Mixer, food products mfr' },
  { Id: 16721, Code: '8111', Name: 'Mixer, ice-cream' },
  { Id: 16722, Code: '8111', Name: 'Mixer, recipe, food products mfr' },
  { Id: 16723, Code: '8111', Name: 'Mixer, soft drinks mfr' },
  { Id: 16724, Code: '8111', Name: 'Mixer, spice' },
  { Id: 16725, Code: '8111', Name: 'Mixer, sponge, bakery' },
  { Id: 16726, Code: '8111', Name: 'Mixer, sugar, condensed milk mfr' },
  { Id: 16727, Code: '8111', Name: 'Mixer, syrup, mineral water mfr' },
  { Id: 16728, Code: '8111', Name: 'Mixer, tobacco mfr' },
  { Id: 16729, Code: '8111', Name: 'Moulder, bakery' },
  { Id: 16730, Code: '8111', Name: 'Moulder, chocolate' },
  { Id: 16731, Code: '8111', Name: 'Moulder, cigar' },
  { Id: 16732, Code: '8111', Name: 'Moulder, machine, CHOCOLATE' },
  { Id: 16733, Code: '8111', Name: 'Moulder, marzipan' },
  { Id: 16734, Code: '8111', Name: 'Moulder, sugar confectionery mfr' },
  { Id: 16735, Code: '8111', Name: 'Moulder, tobacco mfr' },
  { Id: 16736, Code: '8111', Name: 'Nibber, cocoa mfr' },
  { Id: 16737, Code: '8111', Name: 'Operative, bakery' },
  { Id: 16738, Code: '8111', Name: 'Operative, food' },
  { Id: 16739, Code: '8111', Name: 'Operative, line, food products mfr' },
  { Id: 16740, Code: '8111', Name: 'Operative, powder, baking' },
  { Id: 16741, Code: '8111', Name: 'Operative, processing, food' },
  { Id: 16742, Code: '8111', Name: 'Operative, tobacco' },
  { Id: 16743, Code: '8111', Name: 'Operator, acidifier' },
  { Id: 16744, Code: '8111', Name: 'Operator, autoclave, food products mfr' },
  { Id: 16745, Code: '8111', Name: 'Operator, billet, bakery' },
  { Id: 16746, Code: '8111', Name: 'Operator, blending, custard powder mfr' },
  { Id: 16747, Code: '8111', Name: 'Operator, boiler, sugar' },
  { Id: 16748, Code: '8111', Name: 'Operator, brake, bakery' },
  { Id: 16749, Code: '8111', Name: 'Operator, brewery' },
  { Id: 16750, Code: '8111', Name: 'Operator, centrifugal, STARCH' },
  { Id: 16751, Code: '8111', Name: 'Operator, cooker, CANNED FOODS' },
  { Id: 16752, Code: '8111', Name: 'Operator, cooler, brine, MILK' },
  { Id: 16753, Code: '8111', Name: 'Operator, cuber' },
  { Id: 16754, Code: '8111', Name: 'Operator, distillery' },
  { Id: 16755, Code: '8111', Name: "Operator, drier's, grain, MILK FOODS" },
  { Id: 16756, Code: '8111', Name: 'Operator, evaporator, food products mfr' },
  { Id: 16757, Code: '8111', Name: 'Operator, filter, whisky distilling' },
  { Id: 16758, Code: '8111', Name: 'Operator, food products mfr' },
  {
    Id: 16759,
    Code: '8111',
    Name: 'Operator, freezer, fruit, vegetable preserving'
  },
  { Id: 16760, Code: '8111', Name: 'Operator, freezer, ice cream making' },
  {
    Id: 16761,
    Code: '8111',
    Name: 'Operator, froster, fruit, vegetable preserving'
  },
  { Id: 16762, Code: '8111', Name: 'Operator, froster, ice cream making' },
  {
    Id: 16763,
    Code: '8111',
    Name: 'Operator, grinder and roller, cheese processing'
  },
  { Id: 16764, Code: '8111', Name: 'Operator, homogeniser, ICE CREAM' },
  { Id: 16765, Code: '8111', Name: 'Operator, line, food products mfr' },
  { Id: 16766, Code: '8111', Name: 'Operator, mill, grain milling' },
  { Id: 16767, Code: '8111', Name: 'Operator, mixer, sugar confectionery mfr' },
  { Id: 16768, Code: '8111', Name: 'Operator, moulder, CHOCOLATE' },
  {
    Id: 16769,
    Code: '8111',
    Name: 'Operator, oven, vacuum, food products mfr'
  },
  { Id: 16770, Code: '8111', Name: 'Operator, pan, food products mfr' },
  { Id: 16771, Code: '8111', Name: 'Operator, pan, vacuum, food products mfr' },
  { Id: 16772, Code: '8111', Name: 'Operator, plant, bakery' },
  {
    Id: 16773,
    Code: '8111',
    Name: 'Operator, plant, dehydration, fruit, vegetable preserving'
  },
  {
    Id: 16774,
    Code: '8111',
    Name: 'Operator, plant, drying, food products mfr'
  },
  { Id: 16775, Code: '8111', Name: 'Operator, plant, food processing' },
  { Id: 16776, Code: '8111', Name: 'Operator, plant, spray, milk processing' },
  { Id: 16777, Code: '8111', Name: 'Operator, preserving, FRUIT PULP' },
  {
    Id: 16778,
    Code: '8111',
    Name: 'Operator, process, bakery, flour confectionery mfr'
  },
  { Id: 16779, Code: '8111', Name: 'Operator, process, brewery' },
  { Id: 16780, Code: '8111', Name: 'Operator, process, food products mfr' },
  { Id: 16781, Code: '8111', Name: 'Operator, retort, food products mfr' },
  { Id: 16782, Code: '8111', Name: 'Operator, room, sifting' },
  { Id: 16783, Code: '8111', Name: 'Operator, saw, band, food products mfr' },
  { Id: 16784, Code: '8111', Name: 'Operator, separator, food processing' },
  { Id: 16785, Code: '8111', Name: 'Operator, sieve, food products mfr' },
  { Id: 16786, Code: '8111', Name: 'Operator, sieve, rotex, tobacco mfr' },
  { Id: 16787, Code: '8111', Name: 'Operator, silo, tobacco mfr' },
  { Id: 16788, Code: '8111', Name: 'Operator, sterilizer, milk' },
  { Id: 16789, Code: '8111', Name: 'Operator, tandem, CHOCOLATE' },
  { Id: 16790, Code: '8111', Name: 'Operator, triples' },
  { Id: 16791, Code: '8111', Name: 'Operator, viscoliser, ICE CREAM' },
  { Id: 16792, Code: '8111', Name: 'Operator, votator' },
  { Id: 16793, Code: '8111', Name: 'Ovenman, bakery' },
  { Id: 16794, Code: '8111', Name: 'Ovenman, food products mfr' },
  { Id: 16795, Code: '8111', Name: 'Ovensman, bakery' },
  { Id: 16796, Code: '8111', Name: 'Panner, tobacco mfr' },
  { Id: 16797, Code: '8111', Name: 'Pansman, sugar refining' },
  { Id: 16798, Code: '8111', Name: 'Paperer, tin, bakery' },
  { Id: 16799, Code: '8111', Name: 'Paster, biscuit' },
  { Id: 16800, Code: '8111', Name: 'Paster, biscuit mfr' },
  { Id: 16801, Code: '8111', Name: 'Pasteuriser' },
  { Id: 16802, Code: '8111', Name: 'Peeler, food processing' },
  { Id: 16803, Code: '8111', Name: 'Peeler, lemon' },
  { Id: 16804, Code: '8111', Name: 'Peeler, orange' },
  { Id: 16805, Code: '8111', Name: 'Peeler, potato' },
  { Id: 16806, Code: '8111', Name: 'Perryman' },
  { Id: 16807, Code: '8111', Name: 'Picker, food processing' },
  { Id: 16808, Code: '8111', Name: 'Picker, fruit, food processing' },
  { Id: 16809, Code: '8111', Name: 'Picker-up, tobacco mfr' },
  { Id: 16810, Code: '8111', Name: 'Pickler, beef' },
  { Id: 16811, Code: '8111', Name: 'Pickler, food products mfr' },
  { Id: 16812, Code: '8111', Name: 'Piper, sugar' },
  { Id: 16813, Code: '8111', Name: 'Piper, sugar confectionery mfr' },
  { Id: 16814, Code: '8111', Name: 'Preparer, food preserving' },
  { Id: 16815, Code: '8111', Name: 'Preparer, food products mfr' },
  { Id: 16816, Code: '8111', Name: 'Preparer, fruit, preserved' },
  { Id: 16817, Code: '8111', Name: 'Preparer, gelatine' },
  {
    Id: 16818,
    Code: '8111',
    Name: 'Preparer, ingredient, raw, flour confectionery mfr'
  },
  { Id: 16819, Code: '8111', Name: 'Preserver, food products mfr' },
  { Id: 16820, Code: '8111', Name: 'Presser, cider mfr' },
  { Id: 16821, Code: '8111', Name: 'Presser, cocoa' },
  { Id: 16822, Code: '8111', Name: 'Presser, distillery' },
  { Id: 16823, Code: '8111', Name: 'Presser, filter, food products mfr' },
  { Id: 16824, Code: '8111', Name: 'Presser, food products mfr' },
  { Id: 16825, Code: '8111', Name: 'Presser, hop' },
  { Id: 16826, Code: '8111', Name: 'Presser, oil seed crushing' },
  { Id: 16827, Code: '8111', Name: 'Presser, oil, oil seed crushing' },
  { Id: 16828, Code: '8111', Name: 'Presser, tobacco mfr' },
  { Id: 16829, Code: '8111', Name: 'Presser, yeast' },
  { Id: 16830, Code: '8111', Name: 'Primer, brewery' },
  { Id: 16831, Code: '8111', Name: 'Processor, meat' },
  { Id: 16832, Code: '8111', Name: 'Processor, milk' },
  { Id: 16833, Code: '8111', Name: 'Pulper, food products mfr' },
  { Id: 16834, Code: '8111', Name: 'Purifier, food products mfr' },
  { Id: 16835, Code: '8111', Name: 'Racker, alcoholic drink mfr' },
  { Id: 16836, Code: '8111', Name: 'Racker, vinegar mfr' },
  { Id: 16837, Code: '8111', Name: 'Refiner, chocolate mfr' },
  { Id: 16838, Code: '8111', Name: 'Refiner, dripping' },
  { Id: 16839, Code: '8111', Name: 'Refiner, fat' },
  { Id: 16840, Code: '8111', Name: 'Refiner, food products mfr' },
  { Id: 16841, Code: '8111', Name: 'Refiner, lard' },
  { Id: 16842, Code: '8111', Name: 'Refiner, oil seed crushing' },
  { Id: 16843, Code: '8111', Name: 'Refiner, sugar refining' },
  { Id: 16844, Code: '8111', Name: 'Remoistener, DEXTRIN' },
  { Id: 16845, Code: '8111', Name: 'Renderer, lard' },
  { Id: 16846, Code: '8111', Name: 'Roaster, barley' },
  { Id: 16847, Code: '8111', Name: 'Roaster, FOOD PRODUCTS' },
  { Id: 16848, Code: '8111', Name: 'Roaster, malt' },
  { Id: 16849, Code: '8111', Name: 'Rodder, fish curing' },
  { Id: 16850, Code: '8111', Name: 'Roller, ball' },
  { Id: 16851, Code: '8111', Name: 'Roller, cigar mfr' },
  { Id: 16852, Code: '8111', Name: 'Roller, flour confectionery mfr' },
  { Id: 16853, Code: '8111', Name: 'Roller, flour milling' },
  { Id: 16854, Code: '8111', Name: 'Roller, food products mfr' },
  { Id: 16855, Code: '8111', Name: 'Roller, oil seed crushing' },
  { Id: 16856, Code: '8111', Name: 'Roller, pastry' },
  { Id: 16857, Code: '8111', Name: 'Roller, slab' },
  { Id: 16858, Code: '8111', Name: 'Rouser' },
  { Id: 16859, Code: '8111', Name: 'Runner, wort' },
  { Id: 16860, Code: '8111', Name: 'Salter, bacon, ham, meat curing' },
  { Id: 16861, Code: '8111', Name: 'Salter, dry' },
  { Id: 16862, Code: '8111', Name: 'Salter, fish' },
  { Id: 16863, Code: '8111', Name: 'Scalder, tripe dressing' },
  { Id: 16864, Code: '8111', Name: 'Screener, grain milling' },
  { Id: 16865, Code: '8111', Name: 'Screener, seed' },
  { Id: 16866, Code: '8111', Name: 'Screensman, seed' },
  { Id: 16867, Code: '8111', Name: 'Sealer, meat market' },
  { Id: 16868, Code: '8111', Name: 'Selector, skin, sausage' },
  { Id: 16869, Code: '8111', Name: 'Separator, milk' },
  { Id: 16870, Code: '8111', Name: 'Separator, skin, sausage' },
  { Id: 16871, Code: '8111', Name: 'Server, confectionery mfr' },
  { Id: 16872, Code: '8111', Name: 'Servicer, line, food products mfr' },
  { Id: 16873, Code: '8111', Name: 'Setter, sugar confectionery mfr' },
  { Id: 16874, Code: '8111', Name: 'Shellerman' },
  { Id: 16875, Code: '8111', Name: 'Shredder, food products mfr' },
  { Id: 16876, Code: '8111', Name: 'Sidesman, copper, brewery' },
  { Id: 16877, Code: '8111', Name: 'Siever, food products mfr' },
  { Id: 16878, Code: '8111', Name: 'Sifter, flour' },
  { Id: 16879, Code: '8111', Name: 'Sifter, food products mfr' },
  { Id: 16880, Code: '8111', Name: 'Silksman' },
  { Id: 16881, Code: '8111', Name: 'Siloman, seed crushing' },
  { Id: 16882, Code: '8111', Name: 'Slabber, toffee' },
  { Id: 16883, Code: '8111', Name: 'Slicer, bakery' },
  { Id: 16884, Code: '8111', Name: 'Slicer, food processing' },
  { Id: 16885, Code: '8111', Name: 'Slitter, tobacco mfr' },
  { Id: 16886, Code: '8111', Name: 'Smoker, food products mfr' },
  { Id: 16887, Code: '8111', Name: 'Sorter, bean, cocoa' },
  { Id: 16888, Code: '8111', Name: 'Sorter, seed, MUSTARD' },
  { Id: 16889, Code: '8111', Name: 'Speeter, fish curing' },
  { Id: 16890, Code: '8111', Name: 'Spinner, sugar' },
  { Id: 16891, Code: '8111', Name: 'Spinner, sugar, glucose mfr' },
  { Id: 16892, Code: '8111', Name: 'Spinner, tobacco mfr' },
  { Id: 16893, Code: '8111', Name: 'Splitter, tobacco mfr' },
  { Id: 16894, Code: '8111', Name: 'Sprayer, food products mfr' },
  { Id: 16895, Code: '8111', Name: 'Spreader, food products mfr' },
  { Id: 16896, Code: '8111', Name: 'Spreader, tobacco mfr' },
  { Id: 16897, Code: '8111', Name: 'Steephouseman, starch mfr' },
  { Id: 16898, Code: '8111', Name: 'Steepsman, starch mfr' },
  { Id: 16899, Code: '8111', Name: 'Stemmer, leaf' },
  { Id: 16900, Code: '8111', Name: 'Stemmer, tobacco mfr' },
  { Id: 16901, Code: '8111', Name: 'Steriliser, canned foods mfr' },
  { Id: 16902, Code: '8111', Name: 'Steriliser, milk' },
  { Id: 16903, Code: '8111', Name: 'Stover, bacon, ham, meat curing' },
  { Id: 16904, Code: '8111', Name: 'Stover, food products mfr' },
  { Id: 16905, Code: '8111', Name: 'Stover, starch mfr' },
  { Id: 16906, Code: '8111', Name: 'Stover, tobacco mfr' },
  { Id: 16907, Code: '8111', Name: 'Stripper, biscuit' },
  { Id: 16908, Code: '8111', Name: 'Stripper, leaf' },
  { Id: 16909, Code: '8111', Name: 'Stripper, liquorice' },
  { Id: 16910, Code: '8111', Name: 'Stripper, tin, biscuit mfr' },
  { Id: 16911, Code: '8111', Name: 'Stripper, tobacco mfr' },
  { Id: 16912, Code: '8111', Name: 'Supervisor, animal feeds mfr' },
  { Id: 16913, Code: '8111', Name: 'Supplier, leaf' },
  { Id: 16914, Code: '8111', Name: 'Tankerman, whisky distilling' },
  { Id: 16915, Code: '8111', Name: 'Tankman, seed, YEAST' },
  { Id: 16916, Code: '8111', Name: 'Teamer, tobacco mfr' },
  { Id: 16917, Code: '8111', Name: 'Tier, sausage' },
  { Id: 16918, Code: '8111', Name: 'Topman, bacon, ham, meat curing' },
  { Id: 16919, Code: '8111', Name: 'Topper, beet, sugar mfr' },
  { Id: 16920, Code: '8111', Name: 'Tracer, chocolate mfr' },
  { Id: 16921, Code: '8111', Name: 'Trimmer, cake' },
  { Id: 16922, Code: '8111', Name: 'Trimmer, vegetable' },
  { Id: 16923, Code: '8111', Name: 'Turner, biscuit' },
  { Id: 16924, Code: '8111', Name: 'Turner, brewery' },
  { Id: 16925, Code: '8111', Name: 'Twister, sugar, barley' },
  { Id: 16926, Code: '8111', Name: 'Vatman, brewery' },
  { Id: 16927, Code: '8111', Name: 'Vatman, cider mfr' },
  { Id: 16928, Code: '8111', Name: 'Vatman, soft drinks mfr' },
  { Id: 16929, Code: '8111', Name: 'Vatman, vinegar mfr' },
  { Id: 16930, Code: '8111', Name: 'Washer, beet, sugar' },
  { Id: 16931, Code: '8111', Name: 'Washer, fish curing' },
  { Id: 16932, Code: '8111', Name: 'Washer, fruit' },
  { Id: 16933, Code: '8111', Name: 'Washer, grain milling' },
  { Id: 16934, Code: '8111', Name: 'Washer, meat' },
  { Id: 16935, Code: '8111', Name: 'Washerman, grain milling' },
  {
    Id: 16936,
    Code: '8111',
    Name: 'Weigher and mixer, sugar confectionery mfr'
  },
  { Id: 16937, Code: '8111', Name: 'Worker, bakehouse' },
  { Id: 16938, Code: '8111', Name: 'Worker, bakery' },
  { Id: 16939, Code: '8111', Name: 'Worker, brewery' },
  { Id: 16940, Code: '8111', Name: 'Worker, butter' },
  { Id: 16941, Code: '8111', Name: 'Worker, casein, food products mfr' },
  { Id: 16942, Code: '8111', Name: 'Worker, cheese' },
  { Id: 16943, Code: '8111', Name: 'Worker, chocolate' },
  { Id: 16944, Code: '8111', Name: 'Worker, cider' },
  { Id: 16945, Code: '8111', Name: 'Worker, confectionery, bakery' },
  {
    Id: 16946,
    Code: '8111',
    Name: 'Worker, confectionery, sugar confectionery mfr'
  },
  { Id: 16947, Code: '8111', Name: 'Worker, copperhead' },
  { Id: 16948, Code: '8111', Name: 'Worker, creamery' },
  { Id: 16949, Code: '8111', Name: 'Worker, distillery' },
  { Id: 16950, Code: '8111', Name: 'Worker, factory, brewery' },
  { Id: 16951, Code: '8111', Name: 'Worker, factory, distillery' },
  { Id: 16952, Code: '8111', Name: 'Worker, factory, food products mfr' },
  { Id: 16953, Code: '8111', Name: 'Worker, factory, soft drinks mfr' },
  { Id: 16954, Code: '8111', Name: 'Worker, factory, tobacco mfr' },
  { Id: 16955, Code: '8111', Name: 'Worker, food' },
  { Id: 16956, Code: '8111', Name: 'Worker, gelatine' },
  { Id: 16957, Code: '8111', Name: 'Worker, general, food products mfr' },
  { Id: 16958, Code: '8111', Name: 'Worker, margarine' },
  { Id: 16959, Code: '8111', Name: 'Worker, marzipan' },
  { Id: 16960, Code: '8111', Name: 'Worker, mill, animal feeds mfr' },
  {
    Id: 16961,
    Code: '8111',
    Name: 'Worker, pan, revolving, sugar confectionery mfr'
  },
  { Id: 16962, Code: '8111', Name: 'Worker, pickle' },
  { Id: 16963, Code: '8111', Name: 'Worker, plant, bakery' },
  { Id: 16964, Code: '8111', Name: 'Worker, process, animal feeds mfr' },
  { Id: 16965, Code: '8111', Name: 'Worker, process, bakery' },
  { Id: 16966, Code: '8111', Name: 'Worker, process, brewery' },
  { Id: 16967, Code: '8111', Name: 'Worker, process, chocolate mfr' },
  { Id: 16968, Code: '8111', Name: 'Worker, process, dairy' },
  { Id: 16969, Code: '8111', Name: 'Worker, process, distillery' },
  { Id: 16970, Code: '8111', Name: 'Worker, process, flour confectionery mfr' },
  { Id: 16971, Code: '8111', Name: 'Worker, process, food products mfr' },
  { Id: 16972, Code: '8111', Name: 'Worker, process, meat products mfr' },
  {
    Id: 16973,
    Code: '8111',
    Name: 'Worker, process, organic oil and fat processing'
  },
  { Id: 16974, Code: '8111', Name: 'Worker, process, soft drinks mfr' },
  { Id: 16975, Code: '8111', Name: 'Worker, process, starch mfr' },
  { Id: 16976, Code: '8111', Name: 'Worker, process, tobacco mfr' },
  { Id: 16977, Code: '8111', Name: 'Worker, process, vinery' },
  { Id: 16978, Code: '8111', Name: 'Worker, process, yeast mfr' },
  { Id: 16979, Code: '8111', Name: 'Worker, pudding' },
  { Id: 16980, Code: '8111', Name: 'Worker, room, icing' },
  { Id: 16981, Code: '8111', Name: 'Worker, room, nut' },
  { Id: 16982, Code: '8111', Name: 'Worker, room, sausage' },
  { Id: 16983, Code: '8111', Name: 'Worker, room, starch' },
  { Id: 16984, Code: '8111', Name: 'Worker, sediment, whisky distilling' },
  { Id: 16985, Code: '8111', Name: 'Worker, sugar' },
  { Id: 16986, Code: '8111', Name: 'Worker, table, cigar mfr' },
  { Id: 16987, Code: '8111', Name: 'Worker, tobacco' },
  { Id: 16988, Code: '8111', Name: 'Worker-off, sugar confectionery mfr' },
  { Id: 16989, Code: '8112', Name: 'Annealer, CERAMICS' },
  { Id: 16990, Code: '8112', Name: 'Annealer, GLASS' },
  { Id: 16991, Code: '8112', Name: 'Annealer, pot' },
  { Id: 16992, Code: '8112', Name: "Assistant, blower's, glass" },
  { Id: 16993, Code: '8112', Name: "Assistant, embosser's" },
  { Id: 16994, Code: '8112', Name: "Assistant, etcher's" },
  { Id: 16995, Code: '8112', Name: "Assistant, grinder's, PLATE GLASS" },
  { Id: 16996, Code: '8112', Name: "Assistant, ladler's, GLASS" },
  { Id: 16997, Code: '8112', Name: "Assistant, polisher's, glass" },
  { Id: 16998, Code: '8112', Name: "Assistant, potter's" },
  { Id: 16999, Code: '8112', Name: "Assistant, thrower's" },
  { Id: 17000, Code: '8112', Name: 'Attendant, belt, casting' },
  { Id: 17001, Code: '8112', Name: 'Attendant, box, dod' },
  { Id: 17002, Code: '8112', Name: 'Attendant, furnace, glass mfr' },
  { Id: 17003, Code: '8112', Name: 'Attendant, kiln, brick mfr' },
  { Id: 17004, Code: '8112', Name: 'Attendant, kiln, ceramics mfr' },
  { Id: 17005, Code: '8112', Name: 'Attendant, kiln, GLASS' },
  { Id: 17006, Code: '8112', Name: 'Attendant, lehr' },
  { Id: 17007, Code: '8112', Name: 'Attendant, mixer, ceramics mfr' },
  { Id: 17008, Code: '8112', Name: 'Attendant, oven, ceramics mfr' },
  { Id: 17009, Code: '8112', Name: "Attendant, potter's" },
  { Id: 17010, Code: '8112', Name: 'Attendant, press, ceramics mfr' },
  { Id: 17011, Code: '8112', Name: 'Attendant, roller, edge' },
  { Id: 17012, Code: '8112', Name: 'Attendant, screen, ceramics mfr' },
  { Id: 17013, Code: '8112', Name: "Attendant, thrower's" },
  { Id: 17014, Code: '8112', Name: 'Baller, ceramics mfr' },
  { Id: 17015, Code: '8112', Name: 'Batchman, glass mfr' },
  { Id: 17016, Code: '8112', Name: 'Bedder, ceramics mfr' },
  { Id: 17017, Code: '8112', Name: 'Builder, micanite' },
  { Id: 17018, Code: '8112', Name: 'Builder, plate, MICA, MICANITE' },
  { Id: 17019, Code: '8112', Name: 'Burner, brick' },
  { Id: 17020, Code: '8112', Name: 'Burner, ceramics mfr' },
  { Id: 17021, Code: '8112', Name: 'Burner, glass mfr' },
  { Id: 17022, Code: '8112', Name: 'Burner, head, ceramics mfr' },
  { Id: 17023, Code: '8112', Name: 'Burner, kiln, brick mfr' },
  { Id: 17024, Code: '8112', Name: 'Burner, kiln, ceramics mfr' },
  { Id: 17025, Code: '8112', Name: 'Burner, kiln, glass mfr' },
  { Id: 17026, Code: '8112', Name: 'Burner, sand' },
  { Id: 17027, Code: '8112', Name: 'Burner, tile' },
  { Id: 17028, Code: '8112', Name: 'Carrier-in, glass mfr' },
  { Id: 17029, Code: '8112', Name: 'Cranker, ceramics mfr' },
  { Id: 17030, Code: '8112', Name: 'Cranker-up, ceramics mfr' },
  { Id: 17031, Code: '8112', Name: 'Crowder' },
  { Id: 17032, Code: '8112', Name: 'Crusher, ceramics mfr' },
  { Id: 17033, Code: '8112', Name: 'Cutter, mica' },
  { Id: 17034, Code: '8112', Name: 'Drawer and setter, brick mfr' },
  { Id: 17035, Code: '8112', Name: 'Dryer, refractory goods mfr' },
  { Id: 17036, Code: '8112', Name: 'Feeder, hopper, ceramics mfr' },
  { Id: 17037, Code: '8112', Name: 'Filler, kiln' },
  { Id: 17038, Code: '8112', Name: 'Filler, oven, ceramics mfr' },
  { Id: 17039, Code: '8112', Name: 'Filler-in, ceramics mfr' },
  { Id: 17040, Code: '8112', Name: 'Fireman, biscuit' },
  { Id: 17041, Code: '8112', Name: 'Fireman, ceramics mfr' },
  { Id: 17042, Code: '8112', Name: 'Fireman, kiln, ceramics mfr' },
  { Id: 17043, Code: '8112', Name: 'Fireman, kiln, glass mfr' },
  { Id: 17044, Code: '8112', Name: 'Fireman, oven, ceramics mfr' },
  { Id: 17045, Code: '8112', Name: 'Firer, ceramics mfr' },
  { Id: 17046, Code: '8112', Name: 'Firer, foundry, glass mfr' },
  { Id: 17047, Code: '8112', Name: 'Firer, stove, ceramics mfr' },
  { Id: 17048, Code: '8112', Name: 'Foreman, furnace, glass mfr' },
  { Id: 17049, Code: '8112', Name: 'Foreman, kiln, ceramics mfr' },
  { Id: 17050, Code: '8112', Name: 'Foreman, tank, glass mfr' },
  { Id: 17051, Code: '8112', Name: 'Forker, glass mfr' },
  { Id: 17052, Code: '8112', Name: 'Founder, glass mfr' },
  { Id: 17053, Code: '8112', Name: 'Furnaceman, ceramics mfr' },
  { Id: 17054, Code: '8112', Name: 'Furnaceman, electric bulb mfr' },
  { Id: 17055, Code: '8112', Name: 'Furnaceman, glass mfr' },
  { Id: 17056, Code: '8112', Name: 'Fuser, bifocal' },
  { Id: 17057, Code: '8112', Name: 'Fuser, glass mfr' },
  { Id: 17058, Code: '8112', Name: 'Gauger, mica' },
  { Id: 17059, Code: '8112', Name: 'Grinder, clay, ceramics mfr' },
  { Id: 17060, Code: '8112', Name: 'Grinder, composition, ceramics mfr' },
  { Id: 17061, Code: '8112', Name: 'Grinder, flint, ceramics mfr' },
  { Id: 17062, Code: '8112', Name: 'Grinder, glaze, ceramics mfr' },
  { Id: 17063, Code: '8112', Name: 'Hand, brick' },
  { Id: 17064, Code: '8112', Name: 'Hand, kiln, ceramics mfr' },
  { Id: 17065, Code: '8112', Name: 'Hand, oven, ceramics mfr' },
  { Id: 17066, Code: '8112', Name: 'Hand, oven, mica, micanite goods mfr' },
  { Id: 17067, Code: '8112', Name: 'Keeper, furnace, glass mfr' },
  { Id: 17068, Code: '8112', Name: 'Layer, plate, mica, micanite mfr' },
  { Id: 17069, Code: '8112', Name: 'Loader, ceramics mfr' },
  { Id: 17070, Code: '8112', Name: 'Loader, kiln' },
  { Id: 17071, Code: '8112', Name: 'Loader, lehr' },
  { Id: 17072, Code: '8112', Name: 'Machinist, bottle' },
  { Id: 17073, Code: '8112', Name: 'Machinist, brick' },
  { Id: 17074, Code: '8112', Name: 'Machinist, brick mfr' },
  { Id: 17075, Code: '8112', Name: 'Machinist, building, micanite' },
  { Id: 17076, Code: '8112', Name: 'Machinist, ceramics mfr' },
  {
    Id: 17077,
    Code: '8112',
    Name: 'Machinist, coating, glass, bulb, valve mfr'
  },
  { Id: 17078, Code: '8112', Name: 'Machinist, cutting, glass' },
  { Id: 17079, Code: '8112', Name: 'Machinist, frosting, glass mfr' },
  { Id: 17080, Code: '8112', Name: 'Machinist, glass mfr' },
  { Id: 17081, Code: '8112', Name: 'Machinist, grinding, glass mfr' },
  { Id: 17082, Code: '8112', Name: 'Machinist, jigger' },
  { Id: 17083, Code: '8112', Name: 'Machinist, jolley' },
  { Id: 17084, Code: '8112', Name: 'Machinist, making, bottle' },
  { Id: 17085, Code: '8112', Name: 'Machinist, making, brick' },
  { Id: 17086, Code: '8112', Name: 'Machinist, mica' },
  { Id: 17087, Code: '8112', Name: 'Machinist, optical goods mfr' },
  { Id: 17088, Code: '8112', Name: 'Machinist, painting, slip' },
  { Id: 17089, Code: '8112', Name: 'Machinist, pipe, sanitary' },
  { Id: 17090, Code: '8112', Name: 'Machinist, tapping, ceramics mfr' },
  { Id: 17091, Code: '8112', Name: 'Machinist, tile' },
  { Id: 17092, Code: '8112', Name: 'Maker, dust, ceramics mfr' },
  { Id: 17093, Code: '8112', Name: 'Maker, flow, ceramics mfr' },
  { Id: 17094, Code: '8112', Name: 'Maker, glaze, ceramics mfr' },
  { Id: 17095, Code: '8112', Name: 'Maker, micanite' },
  { Id: 17096, Code: '8112', Name: 'Maker, slip, ceramics mfr' },
  { Id: 17097, Code: '8112', Name: 'Man, clay, ceramics mfr' },
  { Id: 17098, Code: '8112', Name: 'Man, kiln, ceramics mfr' },
  { Id: 17099, Code: '8112', Name: 'Man, kiln, enamel' },
  { Id: 17100, Code: '8112', Name: 'Man, kiln, frit' },
  { Id: 17101, Code: '8112', Name: 'Man, kiln, glass mfr' },
  { Id: 17102, Code: '8112', Name: 'Man, kiln, glost' },
  { Id: 17103, Code: '8112', Name: 'Man, lehr' },
  { Id: 17104, Code: '8112', Name: 'Man, mill, ceramics mfr' },
  { Id: 17105, Code: '8112', Name: 'Man, pan, ceramics mfr' },
  { Id: 17106, Code: '8112', Name: 'Metaler, GLASS' },
  { Id: 17107, Code: '8112', Name: 'Miller, ceramics mfr' },
  { Id: 17108, Code: '8112', Name: 'Miller, dust, ceramics mfr' },
  { Id: 17109, Code: '8112', Name: 'Miller, flint, ceramics mfr' },
  { Id: 17110, Code: '8112', Name: 'Miller, glaze, ceramics mfr' },
  { Id: 17111, Code: '8112', Name: 'Mixer, batch, glass mfr' },
  { Id: 17112, Code: '8112', Name: 'Mixer, ceramics mfr' },
  { Id: 17113, Code: '8112', Name: 'Mixer, clay, ceramics mfr' },
  { Id: 17114, Code: '8112', Name: 'Mixer, dust, ceramics mfr' },
  { Id: 17115, Code: '8112', Name: 'Mixer, glass mfr' },
  { Id: 17116, Code: '8112', Name: 'Mixer, glaze, ceramics mfr' },
  { Id: 17117, Code: '8112', Name: 'Mixer, grog, ceramics mfr' },
  { Id: 17118, Code: '8112', Name: 'Moulder, mica' },
  { Id: 17119, Code: '8112', Name: 'Moulder, micanite' },
  { Id: 17120, Code: '8112', Name: 'Oddman, biscuit' },
  { Id: 17121, Code: '8112', Name: 'Oddman, glost' },
  { Id: 17122, Code: '8112', Name: 'Oddman, kiln' },
  { Id: 17123, Code: '8112', Name: 'Oddman, oven' },
  { Id: 17124, Code: '8112', Name: 'Operative, clay, brick mfr' },
  { Id: 17125, Code: '8112', Name: 'Operative, oven, tunnel' },
  { Id: 17126, Code: '8112', Name: 'Operator, autoclave, glass mfr' },
  { Id: 17127, Code: '8112', Name: 'Operator, cathedral' },
  { Id: 17128, Code: '8112', Name: 'Operator, end, cold' },
  { Id: 17129, Code: '8112', Name: 'Operator, furnace, ceramics mfr' },
  { Id: 17130, Code: '8112', Name: 'Operator, furnace, glass' },
  { Id: 17131, Code: '8112', Name: 'Operator, furnace, glass mfr' },
  { Id: 17132, Code: '8112', Name: 'Operator, glass, fibre' },
  { Id: 17133, Code: '8112', Name: 'Operator, kiln, ceramics mfr' },
  { Id: 17134, Code: '8112', Name: 'Operator, mill, ball, ceramics mfr' },
  { Id: 17135, Code: '8112', Name: 'Operator, pipe, blow, QUARTZ' },
  { Id: 17136, Code: '8112', Name: 'Operator, plant, brick' },
  { Id: 17137, Code: '8112', Name: 'Operator, plant, brick mfr' },
  { Id: 17138, Code: '8112', Name: 'Operator, process, glass mfr' },
  { Id: 17139, Code: '8112', Name: 'Operator, tank, glass' },
  { Id: 17140, Code: '8112', Name: 'Operator, tumbler, ceramics mfr' },
  { Id: 17141, Code: '8112', Name: 'Ovenman, biscuit' },
  { Id: 17142, Code: '8112', Name: 'Ovenman, ceramics mfr' },
  { Id: 17143, Code: '8112', Name: 'Ovenman, glost' },
  { Id: 17144, Code: '8112', Name: 'Packer, kiln' },
  { Id: 17145, Code: '8112', Name: "Packer, potter's" },
  { Id: 17146, Code: '8112', Name: 'Picker, bone, ceramics mfr' },
  { Id: 17147, Code: '8112', Name: 'Picker, ceramics mfr' },
  { Id: 17148, Code: '8112', Name: 'Pitcher, ceramics mfr' },
  { Id: 17149, Code: '8112', Name: 'Placer, biscuit' },
  { Id: 17150, Code: '8112', Name: 'Placer, ceramics mfr' },
  { Id: 17151, Code: '8112', Name: 'Placer, glost' },
  { Id: 17152, Code: '8112', Name: 'Placer, kiln' },
  { Id: 17153, Code: '8112', Name: 'Placer, tile' },
  { Id: 17154, Code: '8112', Name: 'Placer, ware, sanitary' },
  { Id: 17155, Code: '8112', Name: 'Preparer, colour, ceramics mfr' },
  { Id: 17156, Code: '8112', Name: 'Preparer, glaze, ceramics mfr' },
  { Id: 17157, Code: '8112', Name: 'Presser, mica' },
  { Id: 17158, Code: '8112', Name: 'Presser, mineral products mfr' },
  { Id: 17159, Code: '8112', Name: 'Roller, tube, MICANITE' },
  { Id: 17160, Code: '8112', Name: 'Setter and drawer, ceramics mfr' },
  { Id: 17161, Code: '8112', Name: 'Setter, brick, brick mfr' },
  { Id: 17162, Code: '8112', Name: 'Setter, ceramics mfr' },
  { Id: 17163, Code: '8112', Name: 'Setter, clamp, ceramics mfr' },
  { Id: 17164, Code: '8112', Name: 'Setter, kiln' },
  { Id: 17165, Code: '8112', Name: 'Setter, oven, ceramics mfr' },
  { Id: 17166, Code: '8112', Name: 'Setter, pipe' },
  { Id: 17167, Code: '8112', Name: 'Setter, tile' },
  { Id: 17168, Code: '8112', Name: 'Setter-in, ceramics mfr' },
  { Id: 17169, Code: '8112', Name: 'Shader, ceramics mfr' },
  { Id: 17170, Code: '8112', Name: 'Sifter, ceramics mfr' },
  { Id: 17171, Code: '8112', Name: 'Sifter, dust, ceramics mfr' },
  { Id: 17172, Code: '8112', Name: 'Skimmer, glass mfr' },
  { Id: 17173, Code: '8112', Name: 'Slaker, ceramics mfr' },
  { Id: 17174, Code: '8112', Name: 'Smelter, glass mfr' },
  { Id: 17175, Code: '8112', Name: 'Stoker, furnace, ceramics mfr' },
  { Id: 17176, Code: '8112', Name: 'Stoker, glass mfr' },
  { Id: 17177, Code: '8112', Name: 'Stoker, kiln, brick' },
  { Id: 17178, Code: '8112', Name: 'Taker-in, glass mfr' },
  { Id: 17179, Code: '8112', Name: 'Tankman, glass mfr' },
  { Id: 17180, Code: '8112', Name: 'Teazer, glass mfr' },
  { Id: 17181, Code: '8112', Name: 'Temperer, ceramics mfr' },
  { Id: 17182, Code: '8112', Name: 'Toughener, GLASS' },
  { Id: 17183, Code: '8112', Name: 'Vitrifier, artificial teeth mfr' },
  { Id: 17184, Code: '8112', Name: 'Wadder' },
  { Id: 17185, Code: '8112', Name: 'Worker, bench, glass mfr' },
  { Id: 17186, Code: '8112', Name: 'Worker, brick' },
  { Id: 17187, Code: '8112', Name: 'Worker, clay, ceramics mfr' },
  { Id: 17188, Code: '8112', Name: 'Worker, crucible' },
  { Id: 17189, Code: '8112', Name: 'Worker, factory, glass mfr' },
  { Id: 17190, Code: '8112', Name: 'Worker, fibreglass' },
  { Id: 17191, Code: '8112', Name: 'Worker, glass' },
  { Id: 17192, Code: '8112', Name: 'Worker, glass, fibre' },
  { Id: 17193, Code: '8112', Name: 'Worker, kiln, ceramics mfr' },
  { Id: 17194, Code: '8112', Name: 'Worker, mica' },
  { Id: 17195, Code: '8112', Name: 'Worker, micanite' },
  { Id: 17196, Code: '8112', Name: 'Worker, process, ceramics mfr' },
  { Id: 17197, Code: '8112', Name: 'Worker, process, fibre glass mfr' },
  { Id: 17198, Code: '8112', Name: 'Worker, process, glass mfr' },
  {
    Id: 17199,
    Code: '8112',
    Name: 'Worker, process, mica, micanite goods mfr'
  },
  { Id: 17200, Code: '8113', Name: 'Alterer, loom' },
  { Id: 17201, Code: '8113', Name: 'Alterer, pattern, carpet, rug mfr' },
  { Id: 17202, Code: '8113', Name: 'Alterer, textile mfr' },
  { Id: 17203, Code: '8113', Name: 'Analyst, colour, textile mfr' },
  { Id: 17204, Code: '8113', Name: 'Assembler, harness, jacquard' },
  { Id: 17205, Code: '8113', Name: 'Assembler, textile mfr' },
  { Id: 17206, Code: '8113', Name: 'Assembler, yarn' },
  { Id: 17207, Code: '8113', Name: 'Assistant, calender, textile mfr' },
  { Id: 17208, Code: '8113', Name: "Assistant, dyer's, textile mfr" },
  { Id: 17209, Code: '8113', Name: 'Assistant, house, dye' },
  { Id: 17210, Code: '8113', Name: "Assistant, maker's, rope" },
  { Id: 17211, Code: '8113', Name: "Assistant, matcher's, colour" },
  { Id: 17212, Code: '8113', Name: "Assistant, minder's, cotton mfr" },
  { Id: 17213, Code: '8113', Name: 'Assistant, room, blowing' },
  { Id: 17214, Code: '8113', Name: "Assistant, spinner's, textile mfr" },
  { Id: 17215, Code: '8113', Name: 'Assistant, stenter' },
  { Id: 17216, Code: '8113', Name: "Assistant, stenterer's" },
  { Id: 17217, Code: '8113', Name: 'Assistant, stretcher' },
  { Id: 17218, Code: '8113', Name: "Assistant, weaver's" },
  { Id: 17219, Code: '8113', Name: 'Attendant, backwash' },
  { Id: 17220, Code: '8113', Name: 'Attendant, board, spread' },
  { Id: 17221, Code: '8113', Name: 'Attendant, box, drawing' },
  { Id: 17222, Code: '8113', Name: 'Attendant, box, gill' },
  { Id: 17223, Code: '8113', Name: 'Attendant, calender, TEXTILES' },
  { Id: 17224, Code: '8113', Name: 'Attendant, card, textile mfr' },
  { Id: 17225, Code: '8113', Name: 'Attendant, centrifugal, textile mfr' },
  { Id: 17226, Code: '8113', Name: 'Attendant, converter, tow-to-top' },
  { Id: 17227, Code: '8113', Name: 'Attendant, frame, textile mfr' },
  { Id: 17228, Code: '8113', Name: 'Attendant, frame, twist' },
  { Id: 17229, Code: '8113', Name: 'Attendant, jigger, asbestos opening' },
  { Id: 17230, Code: '8113', Name: 'Attendant, loom' },
  { Id: 17231, Code: '8113', Name: 'Attendant, picker, waste' },
  { Id: 17232, Code: '8113', Name: 'Attendant, roll, milling, ASBESTOS' },
  { Id: 17233, Code: '8113', Name: 'Attendant, room, blowing' },
  { Id: 17234, Code: '8113', Name: 'Attendant, rotary, ASBESTOS' },
  { Id: 17235, Code: '8113', Name: 'Attendant, scutcher' },
  { Id: 17236, Code: '8113', Name: 'Attendant, teaser' },
  { Id: 17237, Code: '8113', Name: 'Backer, fustian, velvet mfr' },
  { Id: 17238, Code: '8113', Name: 'Backwasher' },
  { Id: 17239, Code: '8113', Name: 'Baker, textile mfr' },
  { Id: 17240, Code: '8113', Name: 'Baller, cross' },
  { Id: 17241, Code: '8113', Name: 'Baller, TWINE' },
  { Id: 17242, Code: '8113', Name: 'Baller, wool' },
  { Id: 17243, Code: '8113', Name: 'Baller, wool combing' },
  { Id: 17244, Code: '8113', Name: 'Baller, YARN' },
  { Id: 17245, Code: '8113', Name: 'Banker, yarn warping' },
  { Id: 17246, Code: '8113', Name: 'Barker, rope, twine mfr' },
  { Id: 17247, Code: '8113', Name: 'Batcher, textile finishing' },
  { Id: 17248, Code: '8113', Name: 'Batter, felt' },
  { Id: 17249, Code: '8113', Name: 'Beamer, textile mfr' },
  { Id: 17250, Code: '8113', Name: 'Beater, feather' },
  { Id: 17251, Code: '8113', Name: 'Beater, feather dressing' },
  { Id: 17252, Code: '8113', Name: 'Beater, textile finishing' },
  { Id: 17253, Code: '8113', Name: 'Beetler' },
  { Id: 17254, Code: '8113', Name: 'Blender, asbestos composition goods mfr' },
  { Id: 17255, Code: '8113', Name: 'Blender, carpet, rug mfr' },
  { Id: 17256, Code: '8113', Name: 'Blender, colour, textile spinning' },
  { Id: 17257, Code: '8113', Name: 'Blender, fur fibre mfr' },
  { Id: 17258, Code: '8113', Name: 'Blender, oilskin mfr' },
  { Id: 17259, Code: '8113', Name: 'Blender, rag' },
  { Id: 17260, Code: '8113', Name: 'Blender, shade, WOOL' },
  { Id: 17261, Code: '8113', Name: 'Blender, WOOL' },
  { Id: 17262, Code: '8113', Name: 'Blender, wool blending' },
  { Id: 17263, Code: '8113', Name: 'Blocker, textile mfr' },
  { Id: 17264, Code: '8113', Name: 'Blower, cotton mfr' },
  { Id: 17265, Code: '8113', Name: 'Blower, dry' },
  { Id: 17266, Code: '8113', Name: 'Blower, fur' },
  { Id: 17267, Code: '8113', Name: 'Blower, textile finishing' },
  { Id: 17268, Code: '8113', Name: 'Blower-up, textile mfr' },
  { Id: 17269, Code: '8113', Name: 'Bluer, textile mfr' },
  { Id: 17270, Code: '8113', Name: 'Boarder, hosiery, knitwear mfr' },
  { Id: 17271, Code: '8113', Name: 'Bobbiner' },
  { Id: 17272, Code: '8113', Name: 'Boiler, FLAX' },
  { Id: 17273, Code: '8113', Name: 'Boiler, oil, oilskin mfr' },
  { Id: 17274, Code: '8113', Name: 'Boiler, textile finishing' },
  { Id: 17275, Code: '8113', Name: 'Boilerman, textile waste merchants' },
  { Id: 17276, Code: '8113', Name: 'Bouker' },
  { Id: 17277, Code: '8113', Name: 'Bowker' },
  { Id: 17278, Code: '8113', Name: 'Braider, asbestos' },
  { Id: 17279, Code: '8113', Name: 'Braider, cordage mfr' },
  { Id: 17280, Code: '8113', Name: 'Braider, fishing net mfr' },
  { Id: 17281, Code: '8113', Name: 'Braider, net' },
  { Id: 17282, Code: '8113', Name: 'Braider, textile smallwares mfr' },
  { Id: 17283, Code: '8113', Name: 'Braider, twine, fishing net mfr' },
  { Id: 17284, Code: '8113', Name: 'Breaker, can' },
  { Id: 17285, Code: '8113', Name: 'Breaker, cotton' },
  { Id: 17286, Code: '8113', Name: 'Breaker, textile finishing' },
  { Id: 17287, Code: '8113', Name: 'Breaker, textile spinning' },
  { Id: 17288, Code: '8113', Name: 'Breaker, waste' },
  { Id: 17289, Code: '8113', Name: 'Breeder, fishing net mfr' },
  { Id: 17290, Code: '8113', Name: 'Brusher, carpet, rug mfr' },
  { Id: 17291, Code: '8113', Name: 'Brusher, cloth, textile finishing' },
  { Id: 17292, Code: '8113', Name: 'Brusher, flannelette' },
  { Id: 17293, Code: '8113', Name: 'Brusher, hosiery, knitwear mfr' },
  { Id: 17294, Code: '8113', Name: 'Brusher, roller' },
  { Id: 17295, Code: '8113', Name: 'Brusher, textile mfr' },
  { Id: 17296, Code: '8113', Name: 'Brusher, wool spinning' },
  { Id: 17297, Code: '8113', Name: 'Builder, harness, textile mfr' },
  { Id: 17298, Code: '8113', Name: 'Bumper, hat mfr' },
  { Id: 17299, Code: '8113', Name: 'Bumper, textile mfr' },
  { Id: 17300, Code: '8113', Name: 'Buncher, hank' },
  { Id: 17301, Code: '8113', Name: 'Buncher, textile mfr' },
  { Id: 17302, Code: '8113', Name: 'Bundler, flax' },
  { Id: 17303, Code: '8113', Name: 'Calculator, colour' },
  { Id: 17304, Code: '8113', Name: 'Calenderer, asbestos' },
  { Id: 17305, Code: '8113', Name: 'Calenderer, canvas hosepipe mfr' },
  { Id: 17306, Code: '8113', Name: 'Calenderer, textile mfr' },
  { Id: 17307, Code: '8113', Name: 'Calenderman, textile mfr' },
  { Id: 17308, Code: '8113', Name: 'Carboniser, cloth' },
  { Id: 17309, Code: '8113', Name: 'Carboniser, piece' },
  { Id: 17310, Code: '8113', Name: 'Carboniser, rag' },
  { Id: 17311, Code: '8113', Name: 'Carboniser, textile mfr' },
  { Id: 17312, Code: '8113', Name: 'Carboniser, wool' },
  { Id: 17313, Code: '8113', Name: 'Carder, asbestos' },
  { Id: 17314, Code: '8113', Name: 'Carder, cotton' },
  { Id: 17315, Code: '8113', Name: 'Carder, fibre' },
  { Id: 17316, Code: '8113', Name: 'Carder, hair' },
  { Id: 17317, Code: '8113', Name: 'Carder, head' },
  { Id: 17318, Code: '8113', Name: 'Carder, speed' },
  { Id: 17319, Code: '8113', Name: 'Carder, textile mfr' },
  { Id: 17320, Code: '8113', Name: 'Carder, under' },
  { Id: 17321, Code: '8113', Name: 'Chalkman' },
  { Id: 17322, Code: '8113', Name: 'Changer, card' },
  { Id: 17323, Code: '8113', Name: 'Changer, flax, hemp mfr' },
  { Id: 17324, Code: '8113', Name: 'Changer, frame, CARPETS' },
  { Id: 17325, Code: '8113', Name: 'Changer, textile printing' },
  { Id: 17326, Code: '8113', Name: 'Cheeser' },
  { Id: 17327, Code: '8113', Name: 'Chromer' },
  { Id: 17328, Code: '8113', Name: 'Clamper, textile finishing' },
  { Id: 17329, Code: '8113', Name: 'Cleaner, bobbin' },
  { Id: 17330, Code: '8113', Name: 'Cleaner, bristle' },
  { Id: 17331, Code: '8113', Name: 'Cleaner, cardroom' },
  { Id: 17332, Code: '8113', Name: 'Cleaner, cloth' },
  { Id: 17333, Code: '8113', Name: 'Cleaner, feather' },
  { Id: 17334, Code: '8113', Name: 'Cleaner, lace' },
  { Id: 17335, Code: '8113', Name: 'Cleaner, loom' },
  { Id: 17336, Code: '8113', Name: 'Cleaner, machine, textile mfr' },
  { Id: 17337, Code: '8113', Name: 'Cleaner, piece' },
  { Id: 17338, Code: '8113', Name: 'Cleaner, pirn' },
  { Id: 17339, Code: '8113', Name: 'Cleaner, rag' },
  { Id: 17340, Code: '8113', Name: 'Cleaner, ramie' },
  { Id: 17341, Code: '8113', Name: 'Cleaner, roller' },
  { Id: 17342, Code: '8113', Name: 'Cleaner, silk throwing' },
  { Id: 17343, Code: '8113', Name: 'Cleaner, table, textile mfr' },
  { Id: 17344, Code: '8113', Name: 'Cleaner, textile finishing' },
  { Id: 17345, Code: '8113', Name: 'Cleaner, warp, textile mfr' },
  { Id: 17346, Code: '8113', Name: 'Cleaner, waste' },
  { Id: 17347, Code: '8113', Name: 'Clearer, cotton doubling' },
  { Id: 17348, Code: '8113', Name: 'Clearer, textile finishing' },
  { Id: 17349, Code: '8113', Name: 'Clearer, warp' },
  { Id: 17350, Code: '8113', Name: 'Clipper, cloth' },
  { Id: 17351, Code: '8113', Name: 'Clipper, knot' },
  { Id: 17352, Code: '8113', Name: 'Clipper, rope, twine mfr' },
  { Id: 17353, Code: '8113', Name: 'Clipper, textile finishing' },
  { Id: 17354, Code: '8113', Name: 'Coater, hand, oilskin mfr' },
  { Id: 17355, Code: '8113', Name: 'Coiler, rope' },
  { Id: 17356, Code: '8113', Name: 'Coiler, rope, twine mfr' },
  { Id: 17357, Code: '8113', Name: 'Coiler, tape' },
  { Id: 17358, Code: '8113', Name: 'Collector, tow, FLAX' },
  { Id: 17359, Code: '8113', Name: 'Colourer, CARPETS' },
  { Id: 17360, Code: '8113', Name: 'Colourist' },
  { Id: 17361, Code: '8113', Name: 'Comber, textile mfr' },
  { Id: 17362, Code: '8113', Name: 'Combiner, canvas goods mfr' },
  { Id: 17363, Code: '8113', Name: 'Condenser, textile mfr' },
  { Id: 17364, Code: '8113', Name: 'Conditioner, textile mfr' },
  { Id: 17365, Code: '8113', Name: 'Conditioner, yarn' },
  { Id: 17366, Code: '8113', Name: 'Coner, textile mfr' },
  { Id: 17367, Code: '8113', Name: 'Coner, wood, SILK' },
  { Id: 17368, Code: '8113', Name: 'Controller, humidity' },
  { Id: 17369, Code: '8113', Name: 'Copperman, textile mfr' },
  { Id: 17370, Code: '8113', Name: 'Corrector, hosiery, knitwear mfr' },
  { Id: 17371, Code: '8113', Name: 'Coverer, elastic, textile mfr' },
  { Id: 17372, Code: '8113', Name: 'Coverer, rubber, surgical dressing mfr' },
  { Id: 17373, Code: '8113', Name: 'Crabber, french' },
  { Id: 17374, Code: '8113', Name: 'Crabber, textile mfr' },
  { Id: 17375, Code: '8113', Name: 'Crabber, yorkshire' },
  { Id: 17376, Code: '8113', Name: 'Creaser and lapper' },
  { Id: 17377, Code: '8113', Name: 'Creaser, textile mfr' },
  { Id: 17378, Code: '8113', Name: 'Creeler' },
  { Id: 17379, Code: '8113', Name: 'Creper' },
  { Id: 17380, Code: '8113', Name: 'Creper, silk' },
  { Id: 17381, Code: '8113', Name: 'Crimper, flax, hemp mfr' },
  { Id: 17382, Code: '8113', Name: 'Crimper, textile mfr' },
  { Id: 17383, Code: '8113', Name: 'Crofter, textile mfr' },
  { Id: 17384, Code: '8113', Name: 'Cropper, textile mfr' },
  { Id: 17385, Code: '8113', Name: 'Crozier' },
  { Id: 17386, Code: '8113', Name: 'Crusher, burr' },
  { Id: 17387, Code: '8113', Name: 'Curler, feather' },
  { Id: 17388, Code: '8113', Name: 'Curler, soft' },
  { Id: 17389, Code: '8113', Name: 'Curler, yarn' },
  { Id: 17390, Code: '8113', Name: 'Cutler, cloth' },
  { Id: 17391, Code: '8113', Name: 'Cutter, bass' },
  { Id: 17392, Code: '8113', Name: 'Cutter, bobbin' },
  { Id: 17393, Code: '8113', Name: 'Cutter, card, textile mfr' },
  { Id: 17394, Code: '8113', Name: 'Cutter, cloth, textile mfr' },
  { Id: 17395, Code: '8113', Name: 'Cutter, dress, FIBRE' },
  { Id: 17396, Code: '8113', Name: 'Cutter, fibre' },
  { Id: 17397, Code: '8113', Name: 'Cutter, fustian' },
  { Id: 17398, Code: '8113', Name: 'Cutter, hemp' },
  { Id: 17399, Code: '8113', Name: 'Cutter, manilla' },
  { Id: 17400, Code: '8113', Name: 'Cutter, pattern, jacquard card cutting' },
  { Id: 17401, Code: '8113', Name: 'Cutter, pile' },
  { Id: 17402, Code: '8113', Name: 'Cutter, rag' },
  { Id: 17403, Code: '8113', Name: 'Cutter, scallop' },
  { Id: 17404, Code: '8113', Name: 'Cutter, textile mfr' },
  { Id: 17405, Code: '8113', Name: 'Cutter, thrum' },
  { Id: 17406, Code: '8113', Name: 'Cutter, velvet' },
  { Id: 17407, Code: '8113', Name: 'Cutter, waste, textile mfr' },
  { Id: 17408, Code: '8113', Name: 'Cutter, wiper' },
  { Id: 17409, Code: '8113', Name: 'Damper, textile mfr' },
  { Id: 17410, Code: '8113', Name: 'Decatiser' },
  { Id: 17411, Code: '8113', Name: 'Decorator, LEATHER CLOTH' },
  { Id: 17412, Code: '8113', Name: 'Degger' },
  { Id: 17413, Code: '8113', Name: 'Desizer' },
  { Id: 17414, Code: '8113', Name: 'Developer, textile mfr' },
  { Id: 17415, Code: '8113', Name: 'Deviller' },
  { Id: 17416, Code: '8113', Name: 'Dewer' },
  { Id: 17417, Code: '8113', Name: 'Dipper, textile mfr' },
  {
    Id: 17418,
    Code: '8113',
    Name: 'Disintegrator, asbestos composition goods mfr'
  },
  { Id: 17419, Code: '8113', Name: 'Dodger, can' },
  { Id: 17420, Code: '8113', Name: 'Dodger, textile finishing' },
  { Id: 17421, Code: '8113', Name: 'Doffer' },
  { Id: 17422, Code: '8113', Name: 'Doffer and setter' },
  { Id: 17423, Code: '8113', Name: 'Doffer, ring' },
  { Id: 17424, Code: '8113', Name: 'Dollier, textile mfr' },
  { Id: 17425, Code: '8113', Name: 'Dollyer' },
  { Id: 17426, Code: '8113', Name: 'Doubler, asbestos' },
  { Id: 17427, Code: '8113', Name: 'Doubler, cloth' },
  { Id: 17428, Code: '8113', Name: 'Doubler, ring' },
  { Id: 17429, Code: '8113', Name: 'Doubler, textile mfr' },
  { Id: 17430, Code: '8113', Name: 'Doubler, warp' },
  { Id: 17431, Code: '8113', Name: 'Drafter, broom, brush mfr' },
  { Id: 17432, Code: '8113', Name: 'Drafter, fibre' },
  { Id: 17433, Code: '8113', Name: 'Drafter, slipper' },
  { Id: 17434, Code: '8113', Name: 'Drawer, card' },
  { Id: 17435, Code: '8113', Name: 'Drawer, cloth, textile finishing' },
  { Id: 17436, Code: '8113', Name: 'Drawer, cotton' },
  { Id: 17437, Code: '8113', Name: 'Drawer, hair' },
  { Id: 17438, Code: '8113', Name: 'Drawer, textile mfr' },
  { Id: 17439, Code: '8113', Name: 'Drawer, wool' },
  { Id: 17440, Code: '8113', Name: 'Drawer, worsted' },
  { Id: 17441, Code: '8113', Name: 'Drawer, yarn' },
  { Id: 17442, Code: '8113', Name: 'Drawer-in, textile mfr' },
  { Id: 17443, Code: '8113', Name: 'Drawer-off, textile mfr' },
  { Id: 17444, Code: '8113', Name: 'Dresser, bag' },
  { Id: 17445, Code: '8113', Name: 'Dresser, bass' },
  { Id: 17446, Code: '8113', Name: 'Dresser, bristle' },
  { Id: 17447, Code: '8113', Name: 'Dresser, cloth' },
  { Id: 17448, Code: '8113', Name: 'Dresser, fibre' },
  { Id: 17449, Code: '8113', Name: 'Dresser, sack' },
  { Id: 17450, Code: '8113', Name: 'Dresser, silk' },
  { Id: 17451, Code: '8113', Name: 'Dresser, textile mfr' },
  { Id: 17452, Code: '8113', Name: 'Dresser, warp' },
  { Id: 17453, Code: '8113', Name: 'Dresser, woollen' },
  { Id: 17454, Code: '8113', Name: 'Dresser, yarn' },
  { Id: 17455, Code: '8113', Name: 'Driver, assistant, textile spinning' },
  { Id: 17456, Code: '8113', Name: 'Driver, gig' },
  { Id: 17457, Code: '8113', Name: 'Driver, stenter' },
  { Id: 17458, Code: '8113', Name: 'Dryer, can, textile mfr' },
  { Id: 17459, Code: '8113', Name: 'Dryer, clip, textile mfr' },
  { Id: 17460, Code: '8113', Name: 'Dryer, cloth' },
  { Id: 17461, Code: '8113', Name: 'Dryer, cylinder, textile mfr' },
  { Id: 17462, Code: '8113', Name: 'Dryer, dyed, textile mfr' },
  { Id: 17463, Code: '8113', Name: 'Dryer, felt' },
  { Id: 17464, Code: '8113', Name: 'Dryer, hair' },
  { Id: 17465, Code: '8113', Name: 'Dryer, machine, textile mfr' },
  { Id: 17466, Code: '8113', Name: 'Dryer, rag' },
  { Id: 17467, Code: '8113', Name: 'Dryer, textile mfr' },
  { Id: 17468, Code: '8113', Name: 'Dryer, warp' },
  { Id: 17469, Code: '8113', Name: 'Dryer, wool' },
  { Id: 17470, Code: '8113', Name: 'Dryer, yarn' },
  { Id: 17471, Code: '8113', Name: 'Dubber, textile mfr' },
  { Id: 17472, Code: '8113', Name: 'Duler, WOOL' },
  { Id: 17473, Code: '8113', Name: 'Dumper, textile mfr' },
  { Id: 17474, Code: '8113', Name: 'Embosser, cloth' },
  { Id: 17475, Code: '8113', Name: 'Embosser, leathercloth mfr' },
  { Id: 17476, Code: '8113', Name: 'Embosser, textile mfr' },
  { Id: 17477, Code: '8113', Name: 'Ender, textile mfr' },
  { Id: 17478, Code: '8113', Name: 'Enterer, textile mfr' },
  { Id: 17479, Code: '8113', Name: 'Extractor, hydro' },
  { Id: 17480, Code: '8113', Name: 'Extractor, textile mfr' },
  { Id: 17481, Code: '8113', Name: 'Feeder, bowl' },
  { Id: 17482, Code: '8113', Name: 'Feeder, card' },
  { Id: 17483, Code: '8113', Name: 'Feeder, carder' },
  { Id: 17484, Code: '8113', Name: 'Feeder, cotton' },
  { Id: 17485, Code: '8113', Name: 'Feeder, engine, textile mfr' },
  { Id: 17486, Code: '8113', Name: 'Feeder, felt hat mfr' },
  { Id: 17487, Code: '8113', Name: 'Feeder, scutcher' },
  { Id: 17488, Code: '8113', Name: 'Feeder, stenter' },
  { Id: 17489, Code: '8113', Name: 'Feeder, textile mfr' },
  { Id: 17490, Code: '8113', Name: 'Feeder, wool' },
  { Id: 17491, Code: '8113', Name: 'Feeder, woollen' },
  { Id: 17492, Code: '8113', Name: 'Feeder-in, textile mfr' },
  { Id: 17493, Code: '8113', Name: 'Felter, textile mfr' },
  { Id: 17494, Code: '8113', Name: 'Feltman, roofing felt mfr' },
  { Id: 17495, Code: '8113', Name: 'Feltman, textile mfr' },
  { Id: 17496, Code: '8113', Name: 'Fenter' },
  { Id: 17497, Code: '8113', Name: 'Filler, back' },
  { Id: 17498, Code: '8113', Name: 'Filler, bank, textile mfr' },
  { Id: 17499, Code: '8113', Name: 'Filler, battery, textile mfr' },
  { Id: 17500, Code: '8113', Name: 'Filler, bobbin' },
  { Id: 17501, Code: '8113', Name: 'Filler, box, textile mfr' },
  { Id: 17502, Code: '8113', Name: 'Filler, braid, SILK' },
  { Id: 17503, Code: '8113', Name: 'Filler, card' },
  { Id: 17504, Code: '8113', Name: "Filler, dresser's" },
  { Id: 17505, Code: '8113', Name: 'Filler, hand, silk weaving' },
  { Id: 17506, Code: '8113', Name: 'Filler, hopper, textile mfr' },
  { Id: 17507, Code: '8113', Name: 'Filler, machine, textile mfr' },
  { Id: 17508, Code: '8113', Name: 'Filler, magazine, LOOMS' },
  { Id: 17509, Code: '8113', Name: 'Filler, rag' },
  { Id: 17510, Code: '8113', Name: 'Filler, scribble' },
  { Id: 17511, Code: '8113', Name: 'Filler, shuttle' },
  { Id: 17512, Code: '8113', Name: 'Filler, silk' },
  { Id: 17513, Code: '8113', Name: 'Filler, textile mfr' },
  { Id: 17514, Code: '8113', Name: 'Filler, tin, textile mfr' },
  { Id: 17515, Code: '8113', Name: "Filler, weaver's" },
  { Id: 17516, Code: '8113', Name: 'Finder, worsted, carpet, rug mfr' },
  { Id: 17517, Code: '8113', Name: 'Finisher, belt, textile mfr' },
  { Id: 17518, Code: '8113', Name: 'Finisher, blanket' },
  { Id: 17519, Code: '8113', Name: 'Finisher, bleach, textile mfr' },
  { Id: 17520, Code: '8113', Name: 'Finisher, can, worsted mfr' },
  { Id: 17521, Code: '8113', Name: 'Finisher, carpet' },
  { Id: 17522, Code: '8113', Name: 'Finisher, cloth' },
  { Id: 17523, Code: '8113', Name: 'Finisher, combing' },
  { Id: 17524, Code: '8113', Name: 'Finisher, fabric' },
  { Id: 17525, Code: '8113', Name: 'Finisher, fuse, safety' },
  { Id: 17526, Code: '8113', Name: 'Finisher, hosiery' },
  { Id: 17527, Code: '8113', Name: 'Finisher, lace' },
  { Id: 17528, Code: '8113', Name: 'Finisher, piece' },
  { Id: 17529, Code: '8113', Name: 'Finisher, plush' },
  { Id: 17530, Code: '8113', Name: 'Finisher, rug' },
  { Id: 17531, Code: '8113', Name: 'Finisher, silk' },
  { Id: 17532, Code: '8113', Name: 'Finisher, stove' },
  { Id: 17533, Code: '8113', Name: "Finisher, taper's" },
  { Id: 17534, Code: '8113', Name: 'Finisher, textile mfr' },
  { Id: 17535, Code: '8113', Name: 'Finisher, velvet' },
  { Id: 17536, Code: '8113', Name: 'Finisher, woollen' },
  { Id: 17537, Code: '8113', Name: 'Fitter, card, textile mfr' },
  { Id: 17538, Code: '8113', Name: 'Fitter, net' },
  { Id: 17539, Code: '8113', Name: 'Fitter, net, rope mfr' },
  { Id: 17540, Code: '8113', Name: 'Fixer, net' },
  { Id: 17541, Code: '8113', Name: 'Fixer, net, rope mfr' },
  { Id: 17542, Code: '8113', Name: 'Fixer, pattern, LACE MACHINE' },
  { Id: 17543, Code: '8113', Name: 'Flagger, FIBRE PREPARATION' },
  { Id: 17544, Code: '8113', Name: 'Folder, cloth' },
  { Id: 17545, Code: '8113', Name: 'Folder, textile mfr' },
  { Id: 17546, Code: '8113', Name: 'Folder, towel, textile mfr' },
  { Id: 17547, Code: '8113', Name: 'Folder-up, textile mfr' },
  { Id: 17548, Code: '8113', Name: 'Forehand, rope, twine mfr' },
  { Id: 17549, Code: '8113', Name: 'Foreman, asbestos mfr' },
  { Id: 17550, Code: '8113', Name: 'Foreman, blowing' },
  { Id: 17551, Code: '8113', Name: 'Foreman, card' },
  { Id: 17552, Code: '8113', Name: 'Foreman, carding' },
  { Id: 17553, Code: '8113', Name: 'Foreman, carpet, rug mfr' },
  { Id: 17554, Code: '8113', Name: 'Foreman, colour, carpet, rug mfr' },
  { Id: 17555, Code: '8113', Name: 'Foreman, comber' },
  { Id: 17556, Code: '8113', Name: 'Foreman, combing' },
  { Id: 17557, Code: '8113', Name: 'Foreman, cotton waste merchants' },
  { Id: 17558, Code: '8113', Name: 'Foreman, doubler' },
  { Id: 17559, Code: '8113', Name: 'Foreman, drawing, textile mfr' },
  { Id: 17560, Code: '8113', Name: 'Foreman, frame, CARDING' },
  { Id: 17561, Code: '8113', Name: 'Foreman, leathercloth mfr' },
  { Id: 17562, Code: '8113', Name: 'Foreman, mule' },
  { Id: 17563, Code: '8113', Name: 'Foreman, ring' },
  { Id: 17564, Code: '8113', Name: 'Foreman, room, blowing' },
  { Id: 17565, Code: '8113', Name: 'Foreman, room, card' },
  { Id: 17566, Code: '8113', Name: 'Foreman, scribbling' },
  { Id: 17567, Code: '8113', Name: 'Foreman, spinning' },
  { Id: 17568, Code: '8113', Name: 'Foreman, surgical dressing mfr' },
  { Id: 17569, Code: '8113', Name: 'Foreman, textile' },
  { Id: 17570, Code: '8113', Name: 'Foreman, textile mfr' },
  { Id: 17571, Code: '8113', Name: 'Foreman, twisting' },
  { Id: 17572, Code: '8113', Name: 'Foreman, typewriter ribbon mfr' },
  { Id: 17573, Code: '8113', Name: 'Foreman, winding' },
  { Id: 17574, Code: '8113', Name: 'Foreman, wool' },
  { Id: 17575, Code: '8113', Name: 'Former, felt hat mfr' },
  { Id: 17576, Code: '8113', Name: 'Former, hat' },
  { Id: 17577, Code: '8113', Name: 'Former, lap' },
  { Id: 17578, Code: '8113', Name: 'Former, rope' },
  { Id: 17579, Code: '8113', Name: 'Former, strand' },
  { Id: 17580, Code: '8113', Name: 'Framer, ring' },
  { Id: 17581, Code: '8113', Name: 'Framer, textile mfr' },
  { Id: 17582, Code: '8113', Name: 'Fuller' },
  { Id: 17583, Code: '8113', Name: 'Gabler and corder, NET' },
  { Id: 17584, Code: '8113', Name: 'Gaiter, beam' },
  { Id: 17585, Code: '8113', Name: 'Gaiter, textile spinning' },
  { Id: 17586, Code: '8113', Name: 'Gaiter, warp' },
  { Id: 17587, Code: '8113', Name: 'Garnetter' },
  { Id: 17588, Code: '8113', Name: 'Gasser' },
  { Id: 17589, Code: '8113', Name: 'Gigger, textile finishing' },
  { Id: 17590, Code: '8113', Name: 'Giller, textile mfr' },
  { Id: 17591, Code: '8113', Name: 'Gimper' },
  { Id: 17592, Code: '8113', Name: 'Glazer, textile mfr' },
  { Id: 17593, Code: '8113', Name: 'Glosser, silk' },
  { Id: 17594, Code: '8113', Name: 'Grinder, asbestos' },
  { Id: 17595, Code: '8113', Name: 'Grinder, cylinder, textile mfr' },
  { Id: 17596, Code: '8113', Name: 'Grinder, flat' },
  { Id: 17597, Code: '8113', Name: 'Grinder, flock' },
  { Id: 17598, Code: '8113', Name: 'Grinder, jute' },
  { Id: 17599, Code: '8113', Name: 'Grinder, rag' },
  { Id: 17600, Code: '8113', Name: 'Grinder, shoddy' },
  { Id: 17601, Code: '8113', Name: 'Grinder, textile mfr' },
  { Id: 17602, Code: '8113', Name: 'Guarder, net, rope mfr' },
  { Id: 17603, Code: '8113', Name: 'Guider-in' },
  { Id: 17604, Code: '8113', Name: 'Hackler' },
  { Id: 17605, Code: '8113', Name: 'Halsher, WOOL' },
  { Id: 17606, Code: '8113', Name: 'Hand, back, leathercloth mfr' },
  { Id: 17607, Code: '8113', Name: 'Hand, bank, rope, twine mfr' },
  { Id: 17608, Code: '8113', Name: 'Hand, calender, fabric' },
  { Id: 17609, Code: '8113', Name: 'Hand, calender, leathercloth mfr' },
  { Id: 17610, Code: '8113', Name: 'Hand, card and drawing, JUTE' },
  { Id: 17611, Code: '8113', Name: 'Hand, card, FLAX, HEMP' },
  { Id: 17612, Code: '8113', Name: 'Hand, croft' },
  { Id: 17613, Code: '8113', Name: 'Hand, dolly' },
  { Id: 17614, Code: '8113', Name: 'Hand, drawing, textile mfr' },
  { Id: 17615, Code: '8113', Name: 'Hand, frame, hosiery, knitwear mfr' },
  { Id: 17616, Code: '8113', Name: 'Hand, glove, KNITTED GLOVES' },
  { Id: 17617, Code: '8113', Name: 'Hand, hosiery' },
  { Id: 17618, Code: '8113', Name: 'Hand, jobbing, rope, twine mfr' },
  { Id: 17619, Code: '8113', Name: 'Hand, leading, textile mfr' },
  { Id: 17620, Code: '8113', Name: 'Hand, mill, hair, fibre dressing' },
  { Id: 17621, Code: '8113', Name: 'Hand, mill, textile mfr' },
  { Id: 17622, Code: '8113', Name: 'Hand, net, braiding' },
  { Id: 17623, Code: '8113', Name: 'Hand, plate, rope, twine mfr' },
  { Id: 17624, Code: '8113', Name: 'Hand, room, blowing' },
  { Id: 17625, Code: '8113', Name: 'Hand, room, card' },
  { Id: 17626, Code: '8113', Name: 'Hand, stenter' },
  { Id: 17627, Code: '8113', Name: 'Handler, stone, broom, brush mfr' },
  { Id: 17628, Code: '8113', Name: 'Hanger, leathercloth mfr' },
  { Id: 17629, Code: '8113', Name: 'Hanker, textile mfr' },
  { Id: 17630, Code: '8113', Name: 'Hardener, felt' },
  { Id: 17631, Code: '8113', Name: 'Hardener, HATS' },
  { Id: 17632, Code: '8113', Name: 'Hardener, wool, HATS' },
  { Id: 17633, Code: '8113', Name: 'Healder' },
  { Id: 17634, Code: '8113', Name: "Helper, examiner's, net, rope mfr" },
  { Id: 17635, Code: '8113', Name: 'Helper, textile mfr: textile weaving' },
  { Id: 17636, Code: '8113', Name: "Helper, weaver's" },
  { Id: 17637, Code: '8113', Name: 'Hooker, textile mfr' },
  { Id: 17638, Code: '8113', Name: 'Hydro-extractor' },
  { Id: 17639, Code: '8113', Name: 'Improver, wool spinning' },
  { Id: 17640, Code: '8113', Name: 'Ingiver' },
  { Id: 17641, Code: '8113', Name: 'Inker, ribbon, typewriter' },
  { Id: 17642, Code: '8113', Name: 'Interlacer, HAIR AND FIBRE' },
  { Id: 17643, Code: '8113', Name: 'Jobber, card' },
  { Id: 17644, Code: '8113', Name: 'Jobber, carding' },
  { Id: 17645, Code: '8113', Name: 'Joiner, textile' },
  { Id: 17646, Code: '8113', Name: 'Joiner, textile mfr' },
  { Id: 17647, Code: '8113', Name: 'Jumper, FIBRE' },
  { Id: 17648, Code: '8113', Name: 'Knotter, reel' },
  { Id: 17649, Code: '8113', Name: 'Knotter, textile mfr' },
  { Id: 17650, Code: '8113', Name: 'Knotter, warp' },
  { Id: 17651, Code: '8113', Name: 'Lacer, card' },
  { Id: 17652, Code: '8113', Name: 'Lacer, jacquard' },
  { Id: 17653, Code: '8113', Name: 'Lacer, textile weaving' },
  { Id: 17654, Code: '8113', Name: 'Laminator, textile mfr' },
  { Id: 17655, Code: '8113', Name: 'Lapman' },
  { Id: 17656, Code: '8113', Name: 'Lapper, cotton' },
  { Id: 17657, Code: '8113', Name: 'Lapper, tape' },
  { Id: 17658, Code: '8113', Name: 'Lapper, textile mfr' },
  { Id: 17659, Code: '8113', Name: 'Lasher' },
  { Id: 17660, Code: '8113', Name: 'Layer, bobbin' },
  { Id: 17661, Code: '8113', Name: 'Layer, drum' },
  { Id: 17662, Code: '8113', Name: 'Layer, rope, twine mfr' },
  { Id: 17663, Code: '8113', Name: 'Layer, textile spinning' },
  { Id: 17664, Code: '8113', Name: 'Layer-on, textile mfr' },
  { Id: 17665, Code: '8113', Name: 'Layer-out, yarn' },
  { Id: 17666, Code: '8113', Name: 'Leaser, textile mfr' },
  { Id: 17667, Code: '8113', Name: 'Legger, hosiery finishing' },
  { Id: 17668, Code: '8113', Name: 'Lengthener' },
  { Id: 17669, Code: '8113', Name: 'Lifter, beam' },
  { Id: 17670, Code: '8113', Name: 'Lifter, textile mfr' },
  { Id: 17671, Code: '8113', Name: 'Ligger' },
  { Id: 17672, Code: '8113', Name: 'Ligger-on, WOOL' },
  { Id: 17673, Code: '8113', Name: 'Linger' },
  { Id: 17674, Code: '8113', Name: 'Linker, textile mfr' },
  { Id: 17675, Code: '8113', Name: 'Loomer' },
  { Id: 17676, Code: '8113', Name: 'Loomer and twister' },
  { Id: 17677, Code: '8113', Name: 'Looper, wool spinning' },
  { Id: 17678, Code: '8113', Name: 'Machinist, ageing' },
  { Id: 17679, Code: '8113', Name: 'Machinist, ball' },
  { Id: 17680, Code: '8113', Name: 'Machinist, balling, textile mfr' },
  { Id: 17681, Code: '8113', Name: 'Machinist, beaming, textile weaving' },
  { Id: 17682, Code: '8113', Name: 'Machinist, blowing' },
  { Id: 17683, Code: '8113', Name: 'Machinist, bobbin' },
  { Id: 17684, Code: '8113', Name: 'Machinist, braid' },
  { Id: 17685, Code: '8113', Name: 'Machinist, braiding, asbestos rope mfr' },
  { Id: 17686, Code: '8113', Name: 'Machinist, breadthening' },
  { Id: 17687, Code: '8113', Name: 'Machinist, brushing, cross' },
  { Id: 17688, Code: '8113', Name: 'Machinist, brushing, textile mfr' },
  { Id: 17689, Code: '8113', Name: 'Machinist, buffing, leathercloth mfr' },
  { Id: 17690, Code: '8113', Name: 'Machinist, bullion' },
  { Id: 17691, Code: '8113', Name: 'Machinist, bumping' },
  { Id: 17692, Code: '8113', Name: 'Machinist, cabling, rope, twine mfr' },
  { Id: 17693, Code: '8113', Name: 'Machinist, carding' },
  { Id: 17694, Code: '8113', Name: 'Machinist, closing, rope, twine mfr' },
  { Id: 17695, Code: '8113', Name: 'Machinist, coating, carpet, rug mfr' },
  { Id: 17696, Code: '8113', Name: 'Machinist, coating, textile mfr' },
  { Id: 17697, Code: '8113', Name: 'Machinist, combing, textile spinning' },
  { Id: 17698, Code: '8113', Name: 'Machinist, condenser, textile mfr' },
  { Id: 17699, Code: '8113', Name: 'Machinist, cropping' },
  { Id: 17700, Code: '8113', Name: 'Machinist, cutting, textile mfr' },
  { Id: 17701, Code: '8113', Name: 'Machinist, cuttling' },
  { Id: 17702, Code: '8113', Name: 'Machinist, damping, textile mfr' },
  { Id: 17703, Code: '8113', Name: 'Machinist, doubling' },
  { Id: 17704, Code: '8113', Name: 'Machinist, drawing, textile mfr' },
  { Id: 17705, Code: '8113', Name: 'Machinist, dressing, fibre' },
  { Id: 17706, Code: '8113', Name: 'Machinist, drying, cloth' },
  { Id: 17707, Code: '8113', Name: 'Machinist, drying, textile mfr' },
  { Id: 17708, Code: '8113', Name: 'Machinist, felt, needleloom' },
  { Id: 17709, Code: '8113', Name: 'Machinist, finisher, textile mfr' },
  { Id: 17710, Code: '8113', Name: 'Machinist, flock' },
  { Id: 17711, Code: '8113', Name: 'Machinist, folding, textile mfr' },
  { Id: 17712, Code: '8113', Name: 'Machinist, forming, twine mfr' },
  { Id: 17713, Code: '8113', Name: 'Machinist, garnet' },
  { Id: 17714, Code: '8113', Name: 'Machinist, gilling' },
  { Id: 17715, Code: '8113', Name: 'Machinist, gimping, PATTERN CARDS' },
  { Id: 17716, Code: '8113', Name: 'Machinist, hair, horse' },
  { Id: 17717, Code: '8113', Name: 'Machinist, hanking' },
  { Id: 17718, Code: '8113', Name: 'Machinist, hardening' },
  { Id: 17719, Code: '8113', Name: "Machinist, hatters' fur mfr" },
  { Id: 17720, Code: '8113', Name: 'Machinist, healding' },
  { Id: 17721, Code: '8113', Name: 'Machinist, hopper' },
  { Id: 17722, Code: '8113', Name: 'Machinist, house, twine mfr' },
  { Id: 17723, Code: '8113', Name: 'Machinist, knotting, textile mfr' },
  { Id: 17724, Code: '8113', Name: 'Machinist, knotting, warp' },
  { Id: 17725, Code: '8113', Name: 'Machinist, lap' },
  { Id: 17726, Code: '8113', Name: 'Machinist, lapping, textile mfr' },
  { Id: 17727, Code: '8113', Name: 'Machinist, leathercloth mfr' },
  { Id: 17728, Code: '8113', Name: 'Machinist, looming' },
  {
    Id: 17729,
    Code: '8113',
    Name: 'Machinist, medicating, SURGICAL DRESSINGS'
  },
  { Id: 17730, Code: '8113', Name: 'Machinist, opening, asbestos opening' },
  { Id: 17731, Code: '8113', Name: 'Machinist, perpetual' },
  { Id: 17732, Code: '8113', Name: 'Machinist, pickering, textile mfr' },
  {
    Id: 17733,
    Code: '8113',
    Name: 'Machinist, piping, textile smallwares mfr'
  },
  { Id: 17734, Code: '8113', Name: 'Machinist, plaiting, rope, twine mfr' },
  { Id: 17735, Code: '8113', Name: 'Machinist, polishing, velvet mfr' },
  { Id: 17736, Code: '8113', Name: 'Machinist, preparing' },
  { Id: 17737, Code: '8113', Name: 'Machinist, pressing, textile mfr' },
  { Id: 17738, Code: '8113', Name: 'Machinist, pulling, rag' },
  { Id: 17739, Code: '8113', Name: 'Machinist, ragging, COTTON RAG' },
  { Id: 17740, Code: '8113', Name: 'Machinist, raising' },
  { Id: 17741, Code: '8113', Name: 'Machinist, randing' },
  { Id: 17742, Code: '8113', Name: 'Machinist, ribbon, typewriter' },
  { Id: 17743, Code: '8113', Name: 'Machinist, ring' },
  { Id: 17744, Code: '8113', Name: 'Machinist, roller, textile mfr' },
  { Id: 17745, Code: '8113', Name: 'Machinist, rolling, textile mfr' },
  { Id: 17746, Code: '8113', Name: 'Machinist, rope, TEXTILE' },
  { Id: 17747, Code: '8113', Name: 'Machinist, rope, twine mfr' },
  { Id: 17748, Code: '8113', Name: 'Machinist, running-down' },
  { Id: 17749, Code: '8113', Name: 'Machinist, scouring, textile mfr' },
  { Id: 17750, Code: '8113', Name: 'Machinist, scutcher' },
  { Id: 17751, Code: '8113', Name: 'Machinist, shearing, textile mfr' },
  { Id: 17752, Code: '8113', Name: 'Machinist, shrinking' },
  { Id: 17753, Code: '8113', Name: 'Machinist, shrinking, hood, felt' },
  { Id: 17754, Code: '8113', Name: 'Machinist, singeing' },
  { Id: 17755, Code: '8113', Name: 'Machinist, sizing, textile mfr' },
  {
    Id: 17756,
    Code: '8113',
    Name: 'Machinist, slitting and winding, textile mfr'
  },
  { Id: 17757, Code: '8113', Name: 'Machinist, souring' },
  { Id: 17758, Code: '8113', Name: 'Machinist, spanishing, leathercloth mfr' },
  { Id: 17759, Code: '8113', Name: 'Machinist, spinning, TEXTILES' },
  { Id: 17760, Code: '8113', Name: 'Machinist, spooling, YARN' },
  { Id: 17761, Code: '8113', Name: 'Machinist, spreader' },
  { Id: 17762, Code: '8113', Name: 'Machinist, stentering' },
  { Id: 17763, Code: '8113', Name: 'Machinist, stiffening' },
  { Id: 17764, Code: '8113', Name: 'Machinist, stranding, rope, twine mfr' },
  { Id: 17765, Code: '8113', Name: 'Machinist, stretching' },
  { Id: 17766, Code: '8113', Name: 'Machinist, tenterer' },
  { Id: 17767, Code: '8113', Name: 'Machinist, textile' },
  { Id: 17768, Code: '8113', Name: 'Machinist, textile mfr' },
  { Id: 17769, Code: '8113', Name: 'Machinist, tube, silk mfr' },
  { Id: 17770, Code: '8113', Name: 'Machinist, tufting, carpet, rug mfr' },
  { Id: 17771, Code: '8113', Name: 'Machinist, twisting, textile mfr' },
  { Id: 17772, Code: '8113', Name: 'Machinist, tying, warp' },
  { Id: 17773, Code: '8113', Name: 'Machinist, velvet' },
  { Id: 17774, Code: '8113', Name: 'Machinist, warping' },
  { Id: 17775, Code: '8113', Name: 'Machinist, washing, textile mfr' },
  { Id: 17776, Code: '8113', Name: 'Machinist, winding, textile mfr' },
  { Id: 17777, Code: '8113', Name: 'Major, blow' },
  { Id: 17778, Code: '8113', Name: 'Major, blower' },
  { Id: 17779, Code: '8113', Name: 'Major, room, blow' },
  { Id: 17780, Code: '8113', Name: 'Maker, brayle' },
  { Id: 17781, Code: '8113', Name: 'Maker, cable, rope' },
  { Id: 17782, Code: '8113', Name: 'Maker, candlewick' },
  { Id: 17783, Code: '8113', Name: 'Maker, chain, textile mfr' },
  { Id: 17784, Code: '8113', Name: 'Maker, chenille' },
  { Id: 17785, Code: '8113', Name: 'Maker, cord' },
  { Id: 17786, Code: '8113', Name: 'Maker, core, rope, twine mfr' },
  { Id: 17787, Code: '8113', Name: 'Maker, felt' },
  { Id: 17788, Code: '8113', Name: "Maker, fender, ship's" },
  { Id: 17789, Code: '8113', Name: 'Maker, gimp' },
  { Id: 17790, Code: '8113', Name: 'Maker, gromet' },
  { Id: 17791, Code: '8113', Name: 'Maker, grommet' },
  { Id: 17792, Code: '8113', Name: 'Maker, halter, ROPE' },
  { Id: 17793, Code: '8113', Name: 'Maker, jacquard' },
  { Id: 17794, Code: '8113', Name: 'Maker, lanyard' },
  { Id: 17795, Code: '8113', Name: 'Maker, line' },
  { Id: 17796, Code: '8113', Name: 'Maker, mantlet, ROPE' },
  { Id: 17797, Code: '8113', Name: 'Maker, pattern, jacquard card cutting' },
  { Id: 17798, Code: '8113', Name: 'Maker, ribbon, typewriter' },
  { Id: 17799, Code: '8113', Name: 'Maker, rope' },
  { Id: 17800, Code: '8113', Name: 'Maker, string' },
  { Id: 17801, Code: '8113', Name: 'Maker, thread' },
  { Id: 17802, Code: '8113', Name: 'Maker, top, textile mfr' },
  { Id: 17803, Code: '8113', Name: 'Maker, trimming, tinsel mfr' },
  { Id: 17804, Code: '8113', Name: 'Maker, trimmings, tinsel mfr' },
  { Id: 17805, Code: '8113', Name: 'Maker, twine' },
  { Id: 17806, Code: '8113', Name: 'Maker, wadding' },
  { Id: 17807, Code: '8113', Name: 'Maker-up, textile mfr' },
  { Id: 17808, Code: '8113', Name: 'Malter' },
  { Id: 17809, Code: '8113', Name: 'Man, back, textile mfr' },
  { Id: 17810, Code: '8113', Name: 'Man, boiling-off' },
  { Id: 17811, Code: '8113', Name: 'Man, burden' },
  { Id: 17812, Code: '8113', Name: 'Man, burr' },
  { Id: 17813, Code: '8113', Name: 'Man, coating, roofing felt mfr' },
  { Id: 17814, Code: '8113', Name: 'Man, end, back, textile mfr' },
  { Id: 17815, Code: '8113', Name: 'Man, extractor, textile mfr' },
  { Id: 17816, Code: '8113', Name: 'Man, fibre, asbestos-cement goods mfr' },
  { Id: 17817, Code: '8113', Name: 'Man, frame, rope, twine mfr' },
  { Id: 17818, Code: '8113', Name: 'Man, heald' },
  { Id: 17819, Code: '8113', Name: 'Man, hydro, textile mfr' },
  { Id: 17820, Code: '8113', Name: 'Man, mill, textile finishing' },
  { Id: 17821, Code: '8113', Name: 'Man, mule' },
  { Id: 17822, Code: '8113', Name: 'Man, room, card' },
  { Id: 17823, Code: '8113', Name: 'Man, room, carding' },
  { Id: 17824, Code: '8113', Name: 'Man, shoddy' },
  { Id: 17825, Code: '8113', Name: 'Man, stenter' },
  { Id: 17826, Code: '8113', Name: 'Man, tool, fustian, velvet mfr' },
  { Id: 17827, Code: '8113', Name: 'Man, vessel, textile bleaching, dyeing' },
  { Id: 17828, Code: '8113', Name: 'Man, yarn' },
  { Id: 17829, Code: '8113', Name: 'Manager, drawing, textile mfr' },
  { Id: 17830, Code: '8113', Name: 'Manufacturer, rope, twine mfr' },
  { Id: 17831, Code: '8113', Name: 'Manufacturer, textile' },
  { Id: 17832, Code: '8113', Name: 'Manufacturer, textile mfr' },
  { Id: 17833, Code: '8113', Name: 'Master, card' },
  { Id: 17834, Code: '8113', Name: 'Master, spinning' },
  { Id: 17835, Code: '8113', Name: 'Master, winding' },
  { Id: 17836, Code: '8113', Name: 'Matcher, colour, leathercloth mfr' },
  { Id: 17837, Code: '8113', Name: 'Matcher, colour, textile mfr' },
  { Id: 17838, Code: '8113', Name: 'Matcher, yarn' },
  { Id: 17839, Code: '8113', Name: "Mate, splicer's, rope" },
  { Id: 17840, Code: '8113', Name: "Mate, weaver's" },
  { Id: 17841, Code: '8113', Name: 'Merceriser' },
  { Id: 17842, Code: '8113', Name: 'Miller, blanket' },
  { Id: 17843, Code: '8113', Name: 'Miller, cloth' },
  { Id: 17844, Code: '8113', Name: 'Miller, dry, textile mfr' },
  { Id: 17845, Code: '8113', Name: 'Miller, felt' },
  { Id: 17846, Code: '8113', Name: 'Miller, hair, fibre dressing' },
  { Id: 17847, Code: '8113', Name: 'Miller, textile mfr' },
  { Id: 17848, Code: '8113', Name: 'Miller, woollen' },
  { Id: 17849, Code: '8113', Name: 'Milner, textile finishing' },
  { Id: 17850, Code: '8113', Name: 'Minder, back, textile mfr' },
  { Id: 17851, Code: '8113', Name: 'Minder, backwash' },
  { Id: 17852, Code: '8113', Name: 'Minder, bowl, scouring' },
  { Id: 17853, Code: '8113', Name: 'Minder, bowl, wash, textile mfr' },
  { Id: 17854, Code: '8113', Name: 'Minder, box, textile mfr' },
  { Id: 17855, Code: '8113', Name: 'Minder, can' },
  { Id: 17856, Code: '8113', Name: 'Minder, card' },
  { Id: 17857, Code: '8113', Name: 'Minder, carding' },
  { Id: 17858, Code: '8113', Name: 'Minder, comb' },
  { Id: 17859, Code: '8113', Name: 'Minder, condenser' },
  { Id: 17860, Code: '8113', Name: 'Minder, copper, STRAW PLAIT' },
  { Id: 17861, Code: '8113', Name: 'Minder, finisher, BLOWING ROOM' },
  { Id: 17862, Code: '8113', Name: 'Minder, frame' },
  { Id: 17863, Code: '8113', Name: 'Minder, frame, cheesing' },
  { Id: 17864, Code: '8113', Name: 'Minder, frame, copping' },
  { Id: 17865, Code: '8113', Name: 'Minder, frame, lap, SILK' },
  { Id: 17866, Code: '8113', Name: 'Minder, frame, roving, JUTE' },
  { Id: 17867, Code: '8113', Name: 'Minder, frame, slubbing' },
  { Id: 17868, Code: '8113', Name: 'Minder, front' },
  { Id: 17869, Code: '8113', Name: 'Minder, head' },
  { Id: 17870, Code: '8113', Name: 'Minder, head, balling' },
  { Id: 17871, Code: '8113', Name: 'Minder, jack' },
  { Id: 17872, Code: '8113', Name: 'Minder, joiner' },
  { Id: 17873, Code: '8113', Name: 'Minder, motion' },
  { Id: 17874, Code: '8113', Name: 'Minder, mule' },
  { Id: 17875, Code: '8113', Name: 'Minder, punch' },
  { Id: 17876, Code: '8113', Name: 'Minder, reducer, wool drawing' },
  { Id: 17877, Code: '8113', Name: 'Minder, rover' },
  { Id: 17878, Code: '8113', Name: 'Minder, roving' },
  { Id: 17879, Code: '8113', Name: 'Minder, scribbling' },
  { Id: 17880, Code: '8113', Name: 'Minder, scutcher' },
  { Id: 17881, Code: '8113', Name: 'Minder, side' },
  { Id: 17882, Code: '8113', Name: 'Minder, stenter' },
  { Id: 17883, Code: '8113', Name: 'Minder, twister, WOOL' },
  { Id: 17884, Code: '8113', Name: 'Mistress, doffing' },
  { Id: 17885, Code: '8113', Name: 'Mistress, shifting' },
  { Id: 17886, Code: '8113', Name: 'Mixer, asbestos composition goods mfr' },
  { Id: 17887, Code: '8113', Name: 'Mixer, bristle' },
  { Id: 17888, Code: '8113', Name: 'Mixer, cotton' },
  { Id: 17889, Code: '8113', Name: 'Mixer, felt hood mfr' },
  { Id: 17890, Code: '8113', Name: 'Mixer, fibre, fur' },
  { Id: 17891, Code: '8113', Name: 'Mixer, size, textile mfr' },
  { Id: 17892, Code: '8113', Name: 'Mixer, textile mfr' },
  { Id: 17893, Code: '8113', Name: 'Mixer, wool' },
  { Id: 17894, Code: '8113', Name: 'Moulder, felt lining mfr' },
  { Id: 17895, Code: '8113', Name: 'Mounter, card, pattern' },
  { Id: 17896, Code: '8113', Name: 'Mounter, net, rope mfr' },
  { Id: 17897, Code: '8113', Name: 'Needler, textile making-up' },
  { Id: 17898, Code: '8113', Name: 'Neutraliser, textile mfr' },
  { Id: 17899, Code: '8113', Name: 'Norseller, NET' },
  { Id: 17900, Code: '8113', Name: 'Nosseller, NET' },
  { Id: 17901, Code: '8113', Name: 'Oiler and cleaner, textile mfr' },
  { Id: 17902, Code: '8113', Name: 'Oiler, canvas goods mfr' },
  { Id: 17903, Code: '8113', Name: 'Oiler, silk' },
  { Id: 17904, Code: '8113', Name: 'Oiler, wool' },
  { Id: 17905, Code: '8113', Name: 'Opener, asbestos' },
  { Id: 17906, Code: '8113', Name: 'Opener, bale, textile mfr, OPENING DEPT' },
  { Id: 17907, Code: '8113', Name: 'Opener, fibre' },
  { Id: 17908, Code: '8113', Name: 'Opener, piece, textile mfr' },
  { Id: 17909, Code: '8113', Name: 'Opener, textile mfr' },
  { Id: 17910, Code: '8113', Name: 'Operative, carding, TEXTILE' },
  { Id: 17911, Code: '8113', Name: 'Operative, combing, textile mfr' },
  { Id: 17912, Code: '8113', Name: 'Operative, drawtwist, nylon' },
  { Id: 17913, Code: '8113', Name: 'Operative, hosiery' },
  { Id: 17914, Code: '8113', Name: 'Operative, room, blowing' },
  { Id: 17915, Code: '8113', Name: 'Operative, room, card' },
  { Id: 17916, Code: '8113', Name: 'Operative, weaving' },
  { Id: 17917, Code: '8113', Name: 'Operator, calender, textile mfr' },
  { Id: 17918, Code: '8113', Name: 'Operator, card, cotton' },
  { Id: 17919, Code: '8113', Name: 'Operator, drawtwist' },
  { Id: 17920, Code: '8113', Name: 'Operator, frame, spinning' },
  { Id: 17921, Code: '8113', Name: 'Operator, garnett' },
  {
    Id: 17922,
    Code: '8113',
    Name: 'Operator, guillotine, pressed woollen felt mfr'
  },
  { Id: 17923, Code: '8113', Name: 'Operator, hydro, textile finishing' },
  { Id: 17924, Code: '8113', Name: 'Operator, hydro-extractor' },
  { Id: 17925, Code: '8113', Name: 'Operator, jig, textile mfr' },
  { Id: 17926, Code: '8113', Name: 'Operator, knife, band, textile mfr' },
  { Id: 17927, Code: '8113', Name: 'Operator, lathe, industrial felt mfr' },
  { Id: 17928, Code: '8113', Name: 'Operator, loom' },
  { Id: 17929, Code: '8113', Name: 'Operator, multi-roller, HATS' },
  { Id: 17930, Code: '8113', Name: 'Operator, ring' },
  { Id: 17931, Code: '8113', Name: 'Operator, stenter' },
  { Id: 17932, Code: '8113', Name: 'Operator, twisting' },
  { Id: 17933, Code: '8113', Name: 'Operator, unit, textile finishing' },
  { Id: 17934, Code: '8113', Name: 'Operator, willey, WOOL' },
  { Id: 17935, Code: '8113', Name: 'Operator, winder, fibreglass' },
  { Id: 17936, Code: '8113', Name: 'Orseller, NET' },
  { Id: 17937, Code: '8113', Name: 'Osseller, NET' },
  { Id: 17938, Code: '8113', Name: 'Overhauler, rag sorting' },
  { Id: 17939, Code: '8113', Name: 'Overlooker, textile mfr: textile warping' },
  { Id: 17940, Code: '8113', Name: 'Packer, shoddy' },
  { Id: 17941, Code: '8113', Name: 'Padder, textile mfr' },
  { Id: 17942, Code: '8113', Name: 'Pegger, barrel, textile mfr' },
  { Id: 17943, Code: '8113', Name: 'Pegger, bobbin' },
  { Id: 17944, Code: '8113', Name: 'Pegger, card' },
  { Id: 17945, Code: '8113', Name: 'Pegger, dobby' },
  { Id: 17946, Code: '8113', Name: 'Pegger, textile weaving' },
  { Id: 17947, Code: '8113', Name: 'Penciller, textile mfr' },
  { Id: 17948, Code: '8113', Name: 'Perforator, card, jacquard card cutting' },
  { Id: 17949, Code: '8113', Name: 'Perforator, jacquard' },
  { Id: 17950, Code: '8113', Name: 'Perforator, jacquard card cutting' },
  { Id: 17951, Code: '8113', Name: 'Picker, roller' },
  { Id: 17952, Code: '8113', Name: 'Picker, silk' },
  { Id: 17953, Code: '8113', Name: 'Picker, textile mfr: silk throwing' },
  { Id: 17954, Code: '8113', Name: 'Picker-up, textile mfr' },
  { Id: 17955, Code: '8113', Name: 'Pickler, cloth' },
  { Id: 17956, Code: '8113', Name: 'Pickler, textile mfr' },
  { Id: 17957, Code: '8113', Name: 'Piecener' },
  { Id: 17958, Code: '8113', Name: 'Piecer, cotton' },
  { Id: 17959, Code: '8113', Name: 'Piecer, cross' },
  { Id: 17960, Code: '8113', Name: 'Piecer, mule' },
  { Id: 17961, Code: '8113', Name: 'Piecer, ring' },
  { Id: 17962, Code: '8113', Name: 'Piecer, side' },
  { Id: 17963, Code: '8113', Name: 'Piecer, silk' },
  { Id: 17964, Code: '8113', Name: 'Piecer, textile mfr' },
  { Id: 17965, Code: '8113', Name: 'Piecer, twiner' },
  { Id: 17966, Code: '8113', Name: 'Piecer, waste' },
  { Id: 17967, Code: '8113', Name: 'Piecer-out, flax, hemp mfr' },
  { Id: 17968, Code: '8113', Name: 'Piler, bobbin' },
  { Id: 17969, Code: '8113', Name: 'Piler, roving' },
  { Id: 17970, Code: '8113', Name: 'Pinner, textile mfr' },
  { Id: 17971, Code: '8113', Name: 'Pinner-on, textile mfr' },
  { Id: 17972, Code: '8113', Name: 'Plaiter, CORDAGE' },
  { Id: 17973, Code: '8113', Name: 'Plaiter, textile mfr' },
  { Id: 17974, Code: '8113', Name: 'Planker' },
  { Id: 17975, Code: '8113', Name: 'Plater, hot' },
  { Id: 17976, Code: '8113', Name: 'Plater, textile mfr' },
  { Id: 17977, Code: '8113', Name: 'Plater-down, textile making-up' },
  { Id: 17978, Code: '8113', Name: 'Pleater, accordion' },
  { Id: 17979, Code: '8113', Name: 'Pleater, cloth' },
  { Id: 17980, Code: '8113', Name: 'Pleater, textile mfr' },
  { Id: 17981, Code: '8113', Name: 'Polisher, fibre' },
  { Id: 17982, Code: '8113', Name: 'Polisher, straw hat mfr' },
  { Id: 17983, Code: '8113', Name: 'Polisher, textile mfr' },
  { Id: 17984, Code: '8113', Name: 'Polisher, twine' },
  { Id: 17985, Code: '8113', Name: 'Polisher, yarn' },
  { Id: 17986, Code: '8113', Name: 'Pre-boarder, hosiery, knitwear mfr' },
  { Id: 17987, Code: '8113', Name: 'Preparer, hair' },
  { Id: 17988, Code: '8113', Name: 'Preparer, jute' },
  { Id: 17989, Code: '8113', Name: 'Preparer, textile mfr' },
  { Id: 17990, Code: '8113', Name: 'Preparer, warp' },
  { Id: 17991, Code: '8113', Name: 'Preparer, yarn' },
  { Id: 17992, Code: '8113', Name: 'Presser and threader' },
  { Id: 17993, Code: '8113', Name: 'Presser, cloth' },
  { Id: 17994, Code: '8113', Name: 'Presser, flat, pressed woollen felt mfr' },
  { Id: 17995, Code: '8113', Name: 'Presser, hosiery' },
  { Id: 17996, Code: '8113', Name: 'Presser, hot, textile finishing' },
  { Id: 17997, Code: '8113', Name: 'Presser, hydraulic, textile mfr' },
  { Id: 17998, Code: '8113', Name: 'Presser, machine, textile mfr' },
  { Id: 17999, Code: '8113', Name: 'Presser, rotary, textile mfr' },
  { Id: 18000, Code: '8113', Name: 'Presser, stuff' },
  { Id: 18001, Code: '8113', Name: 'Presser, textile mfr' },
  { Id: 18002, Code: '8113', Name: 'Presser, yarn' },
  { Id: 18003, Code: '8113', Name: 'Printer, wax, textile mfr' },
  { Id: 18004, Code: '8113', Name: 'Processor, textile' },
  { Id: 18005, Code: '8113', Name: 'Proofer, rubber goods mfr' },
  { Id: 18006, Code: '8113', Name: 'Proofer, water, rubber goods mfr' },
  { Id: 18007, Code: '8113', Name: 'Puller, rag' },
  { Id: 18008, Code: '8113', Name: 'Puller, silk' },
  { Id: 18009, Code: '8113', Name: 'Puller, textile mfr' },
  { Id: 18010, Code: '8113', Name: 'Puller, waste, textile mfr' },
  { Id: 18011, Code: '8113', Name: 'Puller, wool' },
  { Id: 18012, Code: '8113', Name: 'Puncher, card, jacquard card cutting' },
  { Id: 18013, Code: '8113', Name: 'Puncher, card, textile mfr' },
  { Id: 18014, Code: '8113', Name: 'Puncher, jacquard' },
  { Id: 18015, Code: '8113', Name: 'Puncher, piano, jacquard card cutting' },
  { Id: 18016, Code: '8113', Name: 'Puncher, wool combing' },
  { Id: 18017, Code: '8113', Name: 'Purifier, flock merchants' },
  { Id: 18018, Code: '8113', Name: 'Putter-in, textile mfr' },
  { Id: 18019, Code: '8113', Name: 'Putter-on, band' },
  { Id: 18020, Code: '8113', Name: 'Putter-on, tape, silk spinning' },
  { Id: 18021, Code: '8113', Name: 'Putter-on, textile spinning' },
  { Id: 18022, Code: '8113', Name: 'Quiller, textile mfr' },
  { Id: 18023, Code: '8113', Name: 'Racker, textile finishing' },
  { Id: 18024, Code: '8113', Name: 'Raiser, blanket' },
  { Id: 18025, Code: '8113', Name: 'Raiser, cloth' },
  { Id: 18026, Code: '8113', Name: 'Raiser, flannelette' },
  { Id: 18027, Code: '8113', Name: 'Raiser, textile finishing' },
  { Id: 18028, Code: '8113', Name: 'Rander, twine mfr' },
  { Id: 18029, Code: '8113', Name: 'Ratliner' },
  { Id: 18030, Code: '8113', Name: 'Reacher, textile mfr' },
  { Id: 18031, Code: '8113', Name: 'Reacher-in, textile mfr' },
  { Id: 18032, Code: '8113', Name: 'Reader, lace mfr' },
  { Id: 18033, Code: '8113', Name: 'Reader-in' },
  { Id: 18034, Code: '8113', Name: 'Reader-off' },
  { Id: 18035, Code: '8113', Name: 'Re-beamer' },
  { Id: 18036, Code: '8113', Name: 'Receiver, leathercloth mfr' },
  { Id: 18037, Code: '8113', Name: 'Re-drawer, SILK' },
  { Id: 18038, Code: '8113', Name: 'Reducer, textile printing' },
  { Id: 18039, Code: '8113', Name: 'Reducer, wool drawing' },
  { Id: 18040, Code: '8113', Name: 'Reeder' },
  { Id: 18041, Code: '8113', Name: 'Reeler and lacer' },
  { Id: 18042, Code: '8113', Name: 'Reeler, bobbin' },
  { Id: 18043, Code: '8113', Name: 'Reeler, cop' },
  { Id: 18044, Code: '8113', Name: 'Reeler, rope' },
  { Id: 18045, Code: '8113', Name: 'Reeler, textile mfr' },
  { Id: 18046, Code: '8113', Name: 'Reeler, twine' },
  { Id: 18047, Code: '8113', Name: 'Reeler, twist' },
  { Id: 18048, Code: '8113', Name: 'Reeler, yarn' },
  { Id: 18049, Code: '8113', Name: 'Reelerman, roofing felt mfr' },
  { Id: 18050, Code: '8113', Name: 'Respreader, silk mfr' },
  { Id: 18051, Code: '8113', Name: 'Rewinder, textile mfr' },
  { Id: 18052, Code: '8113', Name: 'Rigger and plaiter' },
  { Id: 18053, Code: '8113', Name: 'Rigger and roller' },
  { Id: 18054, Code: '8113', Name: 'Rigger, net' },
  { Id: 18055, Code: '8113', Name: 'Rigger, textile mfr' },
  { Id: 18056, Code: '8113', Name: 'Rigger-up, textile mfr' },
  { Id: 18057, Code: '8113', Name: 'Ripper, muslin' },
  { Id: 18058, Code: '8113', Name: 'Ripper, rag' },
  { Id: 18059, Code: '8113', Name: 'Ripper, shoddy mfr' },
  { Id: 18060, Code: '8113', Name: 'Roller, bandage' },
  { Id: 18061, Code: '8113', Name: 'Roller, piece' },
  { Id: 18062, Code: '8113', Name: 'Roller, textile mfr' },
  { Id: 18063, Code: '8113', Name: 'Roller-up, textile mfr' },
  { Id: 18064, Code: '8113', Name: 'Rover, asbestos' },
  { Id: 18065, Code: '8113', Name: 'Rover, cone' },
  { Id: 18066, Code: '8113', Name: 'Rover, dandy, WOOL' },
  { Id: 18067, Code: '8113', Name: 'Rover, textile mfr' },
  { Id: 18068, Code: '8113', Name: 'Rubber, textile finishing' },
  { Id: 18069, Code: '8113', Name: 'Rubberiser, CARPETS' },
  { Id: 18070, Code: '8113', Name: 'Sanforizer' },
  { Id: 18071, Code: '8113', Name: 'Scalloper, textile mfr' },
  { Id: 18072, Code: '8113', Name: 'Scolloper, lace mfr' },
  { Id: 18073, Code: '8113', Name: 'Scourer, cloth' },
  { Id: 18074, Code: '8113', Name: 'Scourer, piece' },
  { Id: 18075, Code: '8113', Name: 'Scourer, textile mfr' },
  { Id: 18076, Code: '8113', Name: 'Scourer, wool' },
  { Id: 18077, Code: '8113', Name: 'Scrapper, textile mfr' },
  { Id: 18078, Code: '8113', Name: 'Screener, textile mfr' },
  { Id: 18079, Code: '8113', Name: 'Scribbler' },
  { Id: 18080, Code: '8113', Name: 'Scutcher, textile mfr' },
  { Id: 18081, Code: '8113', Name: 'Separator, textile mfr' },
  { Id: 18082, Code: '8113', Name: 'Server, textile mfr' },
  { Id: 18083, Code: '8113', Name: 'Setter, beam' },
  { Id: 18084, Code: '8113', Name: 'Setter, bobbin' },
  { Id: 18085, Code: '8113', Name: 'Setter, card, textile mfr' },
  { Id: 18086, Code: '8113', Name: 'Setter, carpet' },
  { Id: 18087, Code: '8113', Name: 'Setter, carpet, rug mfr' },
  { Id: 18088, Code: '8113', Name: 'Setter, chain' },
  { Id: 18089, Code: '8113', Name: 'Setter, crepe, textile mfr' },
  { Id: 18090, Code: '8113', Name: 'Setter, press, textile finishing' },
  { Id: 18091, Code: '8113', Name: 'Setter, spool' },
  { Id: 18092, Code: '8113', Name: 'Setter, teasel' },
  { Id: 18093, Code: '8113', Name: 'Setter, teazle' },
  { Id: 18094, Code: '8113', Name: 'Setter, textile mfr' },
  { Id: 18095, Code: '8113', Name: 'Setter, tube, textile mfr' },
  { Id: 18096, Code: '8113', Name: 'Setter, yarn' },
  { Id: 18097, Code: '8113', Name: 'Settler, hat mfr' },
  { Id: 18098, Code: '8113', Name: 'Sewer, harness, wool weaving' },
  { Id: 18099, Code: '8113', Name: 'Shader, textile mfr' },
  { Id: 18100, Code: '8113', Name: 'Shaker, rag' },
  { Id: 18101, Code: '8113', Name: 'Shaker, textile mfr' },
  { Id: 18102, Code: '8113', Name: 'Shaker, waste' },
  { Id: 18103, Code: '8113', Name: 'Shaper, hosiery, knitwear mfr' },
  { Id: 18104, Code: '8113', Name: 'Shearer, carpet, rug mfr' },
  { Id: 18105, Code: '8113', Name: 'Shearer, cloth' },
  { Id: 18106, Code: '8113', Name: 'Shearer, mat' },
  { Id: 18107, Code: '8113', Name: 'Shearer, textile finishing' },
  { Id: 18108, Code: '8113', Name: 'Shifter, jute spinning' },
  { Id: 18109, Code: '8113', Name: 'Shrinker, London' },
  { Id: 18110, Code: '8113', Name: 'Shrinker, textile mfr' },
  { Id: 18111, Code: '8113', Name: 'Sizer and dryer, back' },
  { Id: 18112, Code: '8113', Name: 'Sizer, tape' },
  { Id: 18113, Code: '8113', Name: 'Sizer, textile mfr' },
  { Id: 18114, Code: '8113', Name: 'Sizer, warp' },
  { Id: 18115, Code: '8113', Name: 'Sizer, yarn' },
  { Id: 18116, Code: '8113', Name: 'Skeiner, textile mfr' },
  { Id: 18117, Code: '8113', Name: 'Slabber, textile mfr' },
  { Id: 18118, Code: '8113', Name: 'Slinger, textile mfr' },
  { Id: 18119, Code: '8113', Name: 'Sliverer' },
  { Id: 18120, Code: '8113', Name: 'Slubber, textile mfr' },
  { Id: 18121, Code: '8113', Name: 'Snipper' },
  { Id: 18122, Code: '8113', Name: 'Soaker, silk' },
  { Id: 18123, Code: '8113', Name: 'Soaper, rope' },
  { Id: 18124, Code: '8113', Name: 'Soapstoner, roofing felt mfr' },
  { Id: 18125, Code: '8113', Name: 'Softener, flax, hemp mfr' },
  { Id: 18126, Code: '8113', Name: 'Sorter, tube, textile spinning' },
  { Id: 18127, Code: '8113', Name: 'Sorter, weft' },
  { Id: 18128, Code: '8113', Name: 'Speeder, machine, braiding' },
  { Id: 18129, Code: '8113', Name: 'Spindler, ribbon' },
  { Id: 18130, Code: '8113', Name: 'Spinner, asbestos' },
  { Id: 18131, Code: '8113', Name: 'Spinner, cap' },
  { Id: 18132, Code: '8113', Name: 'Spinner, cord' },
  { Id: 18133, Code: '8113', Name: 'Spinner, crimp' },
  { Id: 18134, Code: '8113', Name: 'Spinner, doffer, self' },
  { Id: 18135, Code: '8113', Name: 'Spinner, fibreglass' },
  { Id: 18136, Code: '8113', Name: 'Spinner, fly' },
  { Id: 18137, Code: '8113', Name: 'Spinner, frame' },
  { Id: 18138, Code: '8113', Name: 'Spinner, fuse, safety' },
  { Id: 18139, Code: '8113', Name: 'Spinner, gill' },
  { Id: 18140, Code: '8113', Name: 'Spinner, mining, safety fuse mfr' },
  { Id: 18141, Code: '8113', Name: 'Spinner, mule' },
  { Id: 18142, Code: '8113', Name: 'Spinner, paper twine mfr' },
  { Id: 18143, Code: '8113', Name: 'Spinner, paper, cellulose film mfr' },
  { Id: 18144, Code: '8113', Name: 'Spinner, ring' },
  { Id: 18145, Code: '8113', Name: 'Spinner, rope' },
  { Id: 18146, Code: '8113', Name: 'Spinner, silk' },
  { Id: 18147, Code: '8113', Name: 'Spinner, textile mfr' },
  { Id: 18148, Code: '8113', Name: 'Spinner, TEXTILES' },
  { Id: 18149, Code: '8113', Name: 'Spinner, thread, METAL' },
  { Id: 18150, Code: '8113', Name: 'Spinner, twine' },
  { Id: 18151, Code: '8113', Name: 'Splicer, rope' },
  { Id: 18152, Code: '8113', Name: 'Splicer, TEXTILE CORDS' },
  { Id: 18153, Code: '8113', Name: 'Splicer, textile mfr' },
  { Id: 18154, Code: '8113', Name: 'Splicer, yarn' },
  { Id: 18155, Code: '8113', Name: 'Splitter, cloth' },
  { Id: 18156, Code: '8113', Name: 'Splitter, textile mfr' },
  { Id: 18157, Code: '8113', Name: 'Spooler, textile mfr' },
  { Id: 18158, Code: '8113', Name: 'Spotter, lace mfr' },
  { Id: 18159, Code: '8113', Name: 'Sprayer, steam' },
  { Id: 18160, Code: '8113', Name: 'Sprayer, textile finishing' },
  { Id: 18161, Code: '8113', Name: 'Spreader, leathercloth mfr' },
  { Id: 18162, Code: '8113', Name: 'Spreader, plaster' },
  { Id: 18163, Code: '8113', Name: 'Spreader, rubber, textile mfr' },
  { Id: 18164, Code: '8113', Name: 'Spreader, surgical dressing mfr' },
  { Id: 18165, Code: '8113', Name: 'Spreader, textile mfr' },
  { Id: 18166, Code: '8113', Name: 'Springer, heald' },
  { Id: 18167, Code: '8113', Name: 'Springer, textile mfr' },
  { Id: 18168, Code: '8113', Name: 'Stamper, card, textile mfr' },
  { Id: 18169, Code: '8113', Name: 'Stapler, wool, textile mfr' },
  { Id: 18170, Code: '8113', Name: 'Steamer, silk' },
  { Id: 18171, Code: '8113', Name: 'Steamer, straw hat mfr' },
  { Id: 18172, Code: '8113', Name: 'Steamer, textile finishing' },
  { Id: 18173, Code: '8113', Name: 'Stenter' },
  { Id: 18174, Code: '8113', Name: 'Stenterer' },
  { Id: 18175, Code: '8113', Name: 'Steriliser, surgical dressing mfr' },
  { Id: 18176, Code: '8113', Name: 'Steriliser, textile mfr' },
  { Id: 18177, Code: '8113', Name: 'Stiffener, hat, straw' },
  { Id: 18178, Code: '8113', Name: 'Stover, hat mfr' },
  { Id: 18179, Code: '8113', Name: 'Stover, textile mfr' },
  {
    Id: 18180,
    Code: '8113',
    Name: 'Straightener, textile mfr: flax, hemp mfr'
  },
  { Id: 18181, Code: '8113', Name: 'Straightener, textile mfr: hosiery mfr' },
  { Id: 18182, Code: '8113', Name: 'Strander, rope, twine mfr' },
  { Id: 18183, Code: '8113', Name: 'Stretcher, clip, textile mfr' },
  { Id: 18184, Code: '8113', Name: 'Stretcher, dry, textile mfr' },
  { Id: 18185, Code: '8113', Name: 'Stretcher, textile mfr' },
  { Id: 18186, Code: '8113', Name: 'Stretcher, yarn' },
  { Id: 18187, Code: '8113', Name: 'Stripper and buncher' },
  { Id: 18188, Code: '8113', Name: 'Stripper, bobbin' },
  { Id: 18189, Code: '8113', Name: 'Stripper, cloth' },
  { Id: 18190, Code: '8113', Name: 'Stripper, cop' },
  { Id: 18191, Code: '8113', Name: 'Stripper, pirn' },
  { Id: 18192, Code: '8113', Name: 'Stripper, rag' },
  { Id: 18193, Code: '8113', Name: 'Stripper, textile mfr' },
  { Id: 18194, Code: '8113', Name: 'Stripper, wool' },
  { Id: 18195, Code: '8113', Name: 'Stripper, yarn' },
  { Id: 18196, Code: '8113', Name: 'Stumper' },
  { Id: 18197, Code: '8113', Name: 'Sweeper, loom' },
  { Id: 18198, Code: '8113', Name: 'Swinger' },
  { Id: 18199, Code: '8113', Name: 'Taker-in, textile weaving' },
  { Id: 18200, Code: '8113', Name: 'Taker-off, bobbin' },
  { Id: 18201, Code: '8113', Name: 'Taker-off, textile mfr' },
  { Id: 18202, Code: '8113', Name: 'Taker-out, can, textile mfr' },
  { Id: 18203, Code: '8113', Name: 'Tankman, textile mfr' },
  { Id: 18204, Code: '8113', Name: 'Tanner, net, rope mfr' },
  { Id: 18205, Code: '8113', Name: 'Taper, textile mfr' },
  { Id: 18206, Code: '8113', Name: 'Targer, flax, hemp mfr' },
  { Id: 18207, Code: '8113', Name: 'Tarrer, bag' },
  { Id: 18208, Code: '8113', Name: 'Tarrer, sack' },
  { Id: 18209, Code: '8113', Name: 'Teazer, shoddy' },
  { Id: 18210, Code: '8113', Name: 'Teazer, textile mfr' },
  { Id: 18211, Code: '8113', Name: 'Teazler' },
  { Id: 18212, Code: '8113', Name: 'Tenter, back, frame, textile mfr' },
  { Id: 18213, Code: '8113', Name: 'Tenter, back, roving' },
  { Id: 18214, Code: '8113', Name: 'Tenter, back, textile mfr' },
  { Id: 18215, Code: '8113', Name: 'Tenter, box' },
  { Id: 18216, Code: '8113', Name: 'Tenter, braid, silk mfr' },
  { Id: 18217, Code: '8113', Name: 'Tenter, can' },
  { Id: 18218, Code: '8113', Name: 'Tenter, card' },
  { Id: 18219, Code: '8113', Name: 'Tenter, comb' },
  { Id: 18220, Code: '8113', Name: 'Tenter, comber' },
  { Id: 18221, Code: '8113', Name: 'Tenter, condenser' },
  { Id: 18222, Code: '8113', Name: 'Tenter, cotton' },
  { Id: 18223, Code: '8113', Name: 'Tenter, derby' },
  { Id: 18224, Code: '8113', Name: 'Tenter, devil' },
  { Id: 18225, Code: '8113', Name: 'Tenter, double' },
  { Id: 18226, Code: '8113', Name: 'Tenter, draw' },
  { Id: 18227, Code: '8113', Name: 'Tenter, drawing' },
  { Id: 18228, Code: '8113', Name: 'Tenter, engine, textile mfr' },
  { Id: 18229, Code: '8113', Name: 'Tenter, fly' },
  { Id: 18230, Code: '8113', Name: 'Tenter, frame' },
  { Id: 18231, Code: '8113', Name: 'Tenter, frame, cheesing' },
  { Id: 18232, Code: '8113', Name: 'Tenter, frame, clearing' },
  { Id: 18233, Code: '8113', Name: 'Tenter, frame, copping' },
  { Id: 18234, Code: '8113', Name: 'Tenter, frame, doubling' },
  { Id: 18235, Code: '8113', Name: 'Tenter, frame, draw' },
  { Id: 18236, Code: '8113', Name: 'Tenter, frame, flyer' },
  { Id: 18237, Code: '8113', Name: 'Tenter, frame, ring' },
  { Id: 18238, Code: '8113', Name: 'Tenter, frame, twisting' },
  { Id: 18239, Code: '8113', Name: 'Tenter, front' },
  { Id: 18240, Code: '8113', Name: 'Tenter, gill' },
  { Id: 18241, Code: '8113', Name: 'Tenter, hardener, flat' },
  { Id: 18242, Code: '8113', Name: 'Tenter, hardener, roller' },
  { Id: 18243, Code: '8113', Name: 'Tenter, head, engine' },
  { Id: 18244, Code: '8113', Name: 'Tenter, inter' },
  { Id: 18245, Code: '8113', Name: 'Tenter, jack' },
  { Id: 18246, Code: '8113', Name: 'Tenter, joiner' },
  { Id: 18247, Code: '8113', Name: 'Tenter, lap' },
  { Id: 18248, Code: '8113', Name: 'Tenter, mule' },
  { Id: 18249, Code: '8113', Name: 'Tenter, opener' },
  { Id: 18250, Code: '8113', Name: 'Tenter, padding' },
  { Id: 18251, Code: '8113', Name: 'Tenter, picker' },
  { Id: 18252, Code: '8113', Name: 'Tenter, ribbon' },
  { Id: 18253, Code: '8113', Name: 'Tenter, rover' },
  { Id: 18254, Code: '8113', Name: 'Tenter, roving' },
  { Id: 18255, Code: '8113', Name: 'Tenter, scutcher' },
  { Id: 18256, Code: '8113', Name: 'Tenter, slub' },
  { Id: 18257, Code: '8113', Name: 'Tenter, slubber' },
  { Id: 18258, Code: '8113', Name: 'Tenter, slubbing' },
  { Id: 18259, Code: '8113', Name: 'Tenter, spare' },
  { Id: 18260, Code: '8113', Name: 'Tenter, speed' },
  { Id: 18261, Code: '8113', Name: 'Tenter, textile finishing' },
  { Id: 18262, Code: '8113', Name: 'Tenter, throstle' },
  { Id: 18263, Code: '8113', Name: 'Tenter, waste, hard' },
  { Id: 18264, Code: '8113', Name: 'Tenter, weilds' },
  { Id: 18265, Code: '8113', Name: 'Tenterer, textile mfr' },
  { Id: 18266, Code: '8113', Name: 'Tenterer, woollen' },
  { Id: 18267, Code: '8113', Name: 'Texturer, yarn' },
  { Id: 18268, Code: '8113', Name: 'Threader, bobbin' },
  { Id: 18269, Code: '8113', Name: 'Threader, brass, lace mfr' },
  { Id: 18270, Code: '8113', Name: 'Threader, carpet, rug mfr' },
  { Id: 18271, Code: '8113', Name: 'Threader, frame' },
  { Id: 18272, Code: '8113', Name: 'Threader, heald' },
  { Id: 18273, Code: '8113', Name: 'Threader, lace mfr' },
  { Id: 18274, Code: '8113', Name: 'Threader, warp, hosiery, knitwear mfr' },
  { Id: 18275, Code: '8113', Name: 'Thrower, rayon' },
  { Id: 18276, Code: '8113', Name: 'Throwster, textile mfr' },
  { Id: 18277, Code: '8113', Name: 'Tier, warp' },
  { Id: 18278, Code: '8113', Name: 'Tier-in, textile mfr' },
  { Id: 18279, Code: '8113', Name: 'Tier-on, textile mfr' },
  { Id: 18280, Code: '8113', Name: 'Tier-on, warp' },
  { Id: 18281, Code: '8113', Name: 'Tinsman, drying, textile mfr' },
  { Id: 18282, Code: '8113', Name: 'Tinter, textile mfr' },
  { Id: 18283, Code: '8113', Name: 'Tippler, flax, hemp mfr' },
  { Id: 18284, Code: '8113', Name: 'Tooler, fustian, velvet mfr' },
  { Id: 18285, Code: '8113', Name: 'Topper, cord mfr' },
  { Id: 18286, Code: '8113', Name: 'Trapper, textile mfr' },
  { Id: 18287, Code: '8113', Name: 'Trapper, wool' },
  {
    Id: 18288,
    Code: '8113',
    Name: 'Trimmer and finisher, hosiery, knitwear mfr'
  },
  { Id: 18289, Code: '8113', Name: 'Trimmer, bristle' },
  { Id: 18290, Code: '8113', Name: 'Trimmer, cloth' },
  { Id: 18291, Code: '8113', Name: 'Trimmer, fancy, broom, brush mfr' },
  { Id: 18292, Code: '8113', Name: 'Trimmer, fibre' },
  { Id: 18293, Code: '8113', Name: 'Trimmer, hosiery, textile finishing' },
  { Id: 18294, Code: '8113', Name: 'Trimmer, textile mfr' },
  { Id: 18295, Code: '8113', Name: 'Tuber, mule' },
  { Id: 18296, Code: '8113', Name: 'Tuber, rope, twine mfr' },
  { Id: 18297, Code: '8113', Name: 'Tuber, textile mfr' },
  { Id: 18298, Code: '8113', Name: 'Tucker, blanket mfr' },
  { Id: 18299, Code: '8113', Name: 'Tucker, textile mfr' },
  { Id: 18300, Code: '8113', Name: 'Tufter, carpet, rug mfr' },
  { Id: 18301, Code: '8113', Name: 'Twinder' },
  { Id: 18302, Code: '8113', Name: 'Twiner, textile mfr' },
  { Id: 18303, Code: '8113', Name: 'Twiner-joiner-minder' },
  { Id: 18304, Code: '8113', Name: 'Twister and drawer' },
  { Id: 18305, Code: '8113', Name: 'Twister, cap, textile mfr' },
  { Id: 18306, Code: '8113', Name: 'Twister, cop' },
  { Id: 18307, Code: '8113', Name: 'Twister, cotton' },
  { Id: 18308, Code: '8113', Name: 'Twister, doubling' },
  { Id: 18309, Code: '8113', Name: 'Twister, false' },
  { Id: 18310, Code: '8113', Name: 'Twister, fly' },
  { Id: 18311, Code: '8113', Name: 'Twister, machine' },
  { Id: 18312, Code: '8113', Name: 'Twister, patent' },
  { Id: 18313, Code: '8113', Name: 'Twister, ring' },
  { Id: 18314, Code: '8113', Name: 'Twister, silk' },
  { Id: 18315, Code: '8113', Name: 'Twister, single' },
  { Id: 18316, Code: '8113', Name: 'Twister, spinning' },
  { Id: 18317, Code: '8113', Name: 'Twister, sprig' },
  { Id: 18318, Code: '8113', Name: 'Twister, textile mfr' },
  { Id: 18319, Code: '8113', Name: 'Twister, twine' },
  { Id: 18320, Code: '8113', Name: 'Twister, warp' },
  { Id: 18321, Code: '8113', Name: 'Twister, wool' },
  { Id: 18322, Code: '8113', Name: 'Twister, worsted' },
  { Id: 18323, Code: '8113', Name: 'Twister, yarn' },
  { Id: 18324, Code: '8113', Name: 'Twister-in, textile mfr' },
  { Id: 18325, Code: '8113', Name: 'Twister-on, textile mfr' },
  { Id: 18326, Code: '8113', Name: 'Twister-up, textile mfr' },
  { Id: 18327, Code: '8113', Name: 'Uncurler, textile mfr' },
  { Id: 18328, Code: '8113', Name: 'Unwinder' },
  { Id: 18329, Code: '8113', Name: 'Warper' },
  { Id: 18330, Code: '8113', Name: 'Washer, back, textile mfr' },
  { Id: 18331, Code: '8113', Name: 'Washer, blanket, blanket mfr' },
  { Id: 18332, Code: '8113', Name: 'Washer, body, hat mfr' },
  { Id: 18333, Code: '8113', Name: 'Washer, brush' },
  { Id: 18334, Code: '8113', Name: 'Washer, cloth, textile mfr' },
  { Id: 18335, Code: '8113', Name: 'Washer, feather' },
  { Id: 18336, Code: '8113', Name: 'Washer, felt' },
  { Id: 18337, Code: '8113', Name: 'Washer, hair, horse' },
  { Id: 18338, Code: '8113', Name: 'Washer, hat mfr' },
  { Id: 18339, Code: '8113', Name: 'Washer, rag, textile mfr' },
  { Id: 18340, Code: '8113', Name: 'Washer, silk' },
  { Id: 18341, Code: '8113', Name: 'Washer, textile mfr' },
  { Id: 18342, Code: '8113', Name: 'Washer, wool' },
  { Id: 18343, Code: '8113', Name: 'Washer, yarn' },
  { Id: 18344, Code: '8113', Name: 'Washerman, textile mfr' },
  { Id: 18345, Code: '8113', Name: 'Washhouseman, raw silk processing' },
  { Id: 18346, Code: '8113', Name: 'Washhouseman, wool' },
  { Id: 18347, Code: '8113', Name: 'Waterman, textile mfr' },
  { Id: 18348, Code: '8113', Name: 'Waxer, cord' },
  {
    Id: 18349,
    Code: '8113',
    Name: 'Weigher and finisher, coil, asbestos rope mfr'
  },
  { Id: 18350, Code: '8113', Name: 'Weigher, coiler' },
  { Id: 18351, Code: '8113', Name: 'Whimseyer' },
  { Id: 18352, Code: '8113', Name: 'Willeyer' },
  { Id: 18353, Code: '8113', Name: 'Willier' },
  { Id: 18354, Code: '8113', Name: 'Willower, textile mfr' },
  { Id: 18355, Code: '8113', Name: 'Willowyer' },
  { Id: 18356, Code: '8113', Name: 'Willyer' },
  { Id: 18357, Code: '8113', Name: 'Winchman, textile mfr' },
  { Id: 18358, Code: '8113', Name: 'Winder, ball, textile mfr' },
  { Id: 18359, Code: '8113', Name: 'Winder, beam' },
  { Id: 18360, Code: '8113', Name: 'Winder, bit, textile mfr' },
  { Id: 18361, Code: '8113', Name: 'Winder, bobbin, brass' },
  { Id: 18362, Code: '8113', Name: 'Winder, bobbin, ring' },
  { Id: 18363, Code: '8113', Name: 'Winder, bobbin, textile mfr' },
  { Id: 18364, Code: '8113', Name: 'Winder, card' },
  { Id: 18365, Code: '8113', Name: 'Winder, cheese' },
  { Id: 18366, Code: '8113', Name: 'Winder, clear' },
  { Id: 18367, Code: '8113', Name: 'Winder, cloth, oil cloth mfr' },
  { Id: 18368, Code: '8113', Name: 'Winder, coloured' },
  { Id: 18369, Code: '8113', Name: 'Winder, cone' },
  { Id: 18370, Code: '8113', Name: 'Winder, cop' },
  { Id: 18371, Code: '8113', Name: 'Winder, cotton' },
  { Id: 18372, Code: '8113', Name: 'Winder, double, textile mfr' },
  { Id: 18373, Code: '8113', Name: 'Winder, doubler, textile mfr' },
  { Id: 18374, Code: '8113', Name: 'Winder, doubling' },
  { Id: 18375, Code: '8113', Name: 'Winder, drum' },
  { Id: 18376, Code: '8113', Name: 'Winder, engine, textile mfr' },
  { Id: 18377, Code: '8113', Name: 'Winder, gas' },
  { Id: 18378, Code: '8113', Name: 'Winder, hank' },
  { Id: 18379, Code: '8113', Name: 'Winder, jute' },
  { Id: 18380, Code: '8113', Name: 'Winder, machine, surgical dressing mfr' },
  { Id: 18381, Code: '8113', Name: 'Winder, mohair' },
  { Id: 18382, Code: '8113', Name: 'Winder, pin' },
  { Id: 18383, Code: '8113', Name: 'Winder, pirn' },
  { Id: 18384, Code: '8113', Name: 'Winder, rayon' },
  { Id: 18385, Code: '8113', Name: 'Winder, reel' },
  { Id: 18386, Code: '8113', Name: 'Winder, ribbon' },
  { Id: 18387, Code: '8113', Name: 'Winder, ring' },
  { Id: 18388, Code: '8113', Name: 'Winder, rubber' },
  { Id: 18389, Code: '8113', Name: 'Winder, silk, raw' },
  { Id: 18390, Code: '8113', Name: 'Winder, slip' },
  { Id: 18391, Code: '8113', Name: 'Winder, spool, textile mfr' },
  { Id: 18392, Code: '8113', Name: 'Winder, tape, electrical engineering' },
  { Id: 18393, Code: '8113', Name: 'Winder, textile mfr' },
  { Id: 18394, Code: '8113', Name: 'Winder, textile smallwares mfr' },
  { Id: 18395, Code: '8113', Name: 'Winder, thread, textile mfr' },
  { Id: 18396, Code: '8113', Name: 'Winder, tube, textile mfr' },
  { Id: 18397, Code: '8113', Name: 'Winder, twist' },
  { Id: 18398, Code: '8113', Name: 'Winder, typewriter ribbon mfr' },
  { Id: 18399, Code: '8113', Name: 'Winder, warp' },
  { Id: 18400, Code: '8113', Name: 'Winder, weft' },
  { Id: 18401, Code: '8113', Name: 'Winder, weight' },
  { Id: 18402, Code: '8113', Name: 'Winder, wheel, lace mfr' },
  { Id: 18403, Code: '8113', Name: 'Winder, wool' },
  { Id: 18404, Code: '8113', Name: 'Winder, yarn' },
  { Id: 18405, Code: '8113', Name: 'Wiper, bobbin' },
  { Id: 18406, Code: '8113', Name: 'Wirer, card, carpet, rug mfr' },
  { Id: 18407, Code: '8113', Name: 'Woolleyer' },
  { Id: 18408, Code: '8113', Name: 'Worker, bobbin, bottle' },
  { Id: 18409, Code: '8113', Name: 'Worker, cloth, textile finishing' },
  { Id: 18410, Code: '8113', Name: 'Worker, factory, clothing mfr' },
  { Id: 18411, Code: '8113', Name: 'Worker, factory, textile mfr' },
  { Id: 18412, Code: '8113', Name: 'Worker, feather' },
  { Id: 18413, Code: '8113', Name: 'Worker, felt' },
  { Id: 18414, Code: '8113', Name: 'Worker, frame, hand' },
  { Id: 18415, Code: '8113', Name: 'Worker, frame, knitting' },
  { Id: 18416, Code: '8113', Name: 'Worker, frame, rope, twine mfr' },
  { Id: 18417, Code: '8113', Name: 'Worker, garnet' },
  { Id: 18418, Code: '8113', Name: 'Worker, hair' },
  { Id: 18419, Code: '8113', Name: 'Worker, hosiery' },
  { Id: 18420, Code: '8113', Name: 'Worker, mill, asbestos' },
  { Id: 18421, Code: '8113', Name: 'Worker, process, asbestos mfr' },
  { Id: 18422, Code: '8113', Name: 'Worker, process, felt mfr' },
  { Id: 18423, Code: '8113', Name: 'Worker, process, leathercloth mfr' },
  { Id: 18424, Code: '8113', Name: 'Worker, process, textile mfr' },
  { Id: 18425, Code: '8113', Name: 'Worker, room, card' },
  { Id: 18426, Code: '8113', Name: 'Worker, rope, rope, twine mfr' },
  { Id: 18427, Code: '8113', Name: 'Wringman' },
  { Id: 18428, Code: '8114', Name: 'Acidifier' },
  { Id: 18429, Code: '8114', Name: 'Annealer, CHEMICALS' },
  { Id: 18430, Code: '8114', Name: 'Assembler, cell, chemical mfr' },
  { Id: 18431, Code: '8114', Name: 'Assistant, craft, chemical works' },
  { Id: 18432, Code: '8114', Name: "Assistant, mixer's, colour" },
  { Id: 18433, Code: '8114', Name: "Assistant, refiner's" },
  { Id: 18434, Code: '8114', Name: 'Attendant, burner, COALITE' },
  { Id: 18435, Code: '8114', Name: 'Attendant, centrifugal, chemical mfr' },
  { Id: 18436, Code: '8114', Name: 'Attendant, chlorination' },
  { Id: 18437, Code: '8114', Name: 'Attendant, composition, MATCHES' },
  { Id: 18438, Code: '8114', Name: 'Attendant, condenser' },
  { Id: 18439, Code: '8114', Name: 'Attendant, evaporator' },
  { Id: 18440, Code: '8114', Name: 'Attendant, filtration' },
  { Id: 18441, Code: '8114', Name: 'Attendant, furnace, chemical mfr' },
  { Id: 18442, Code: '8114', Name: 'Attendant, gas works' },
  { Id: 18443, Code: '8114', Name: 'Attendant, gear, extractor, gas works' },
  { Id: 18444, Code: '8114', Name: 'Attendant, heat' },
  { Id: 18445, Code: '8114', Name: 'Attendant, heats' },
  { Id: 18446, Code: '8114', Name: 'Attendant, house, boiling' },
  { Id: 18447, Code: '8114', Name: 'Attendant, house, filter' },
  { Id: 18448, Code: '8114', Name: 'Attendant, house, retort' },
  { Id: 18449, Code: '8114', Name: 'Attendant, instrument, chemical mfr' },
  { Id: 18450, Code: '8114', Name: 'Attendant, kiln, glaze and colour mfr' },
  { Id: 18451, Code: '8114', Name: 'Attendant, mill, wash' },
  { Id: 18452, Code: '8114', Name: 'Attendant, oven, chemical mfr' },
  { Id: 18453, Code: '8114', Name: 'Attendant, oven, coke ovens' },
  { Id: 18454, Code: '8114', Name: 'Attendant, oven, drying, cylinder' },
  { Id: 18455, Code: '8114', Name: 'Attendant, oven, gas' },
  { Id: 18456, Code: '8114', Name: 'Attendant, paraffin' },
  { Id: 18457, Code: '8114', Name: 'Attendant, plant, acid' },
  { Id: 18458, Code: '8114', Name: 'Attendant, plant, ammonia' },
  { Id: 18459, Code: '8114', Name: 'Attendant, plant, breeze' },
  { Id: 18460, Code: '8114', Name: 'Attendant, plant, chemical mfr' },
  { Id: 18461, Code: '8114', Name: 'Attendant, plant, chlorination' },
  { Id: 18462, Code: '8114', Name: 'Attendant, plant, cooling, dry' },
  { Id: 18463, Code: '8114', Name: 'Attendant, plant, gas' },
  { Id: 18464, Code: '8114', Name: 'Attendant, plant, gas works' },
  { Id: 18465, Code: '8114', Name: 'Attendant, plant, grading, coke' },
  { Id: 18466, Code: '8114', Name: 'Attendant, plant, retort' },
  { Id: 18467, Code: '8114', Name: 'Attendant, plant, sulphate' },
  { Id: 18468, Code: '8114', Name: 'Attendant, plant, tar' },
  { Id: 18469, Code: '8114', Name: 'Attendant, plant, water' },
  { Id: 18470, Code: '8114', Name: 'Attendant, press, chemical mfr' },
  { Id: 18471, Code: '8114', Name: 'Attendant, producer, gas' },
  { Id: 18472, Code: '8114', Name: 'Attendant, purifier' },
  { Id: 18473, Code: '8114', Name: 'Attendant, retort, coal gas, coke ovens' },
  { Id: 18474, Code: '8114', Name: 'Attendant, screen, gas works' },
  { Id: 18475, Code: '8114', Name: 'Attendant, still' },
  { Id: 18476, Code: '8114', Name: 'Attendant, storage, liquor' },
  { Id: 18477, Code: '8114', Name: 'Attendant, tar and liquor' },
  {
    Id: 18478,
    Code: '8114',
    Name: 'Attendant, vat, textile bleaching, dyeing'
  },
  { Id: 18479, Code: '8114', Name: 'Attendant, washer' },
  {
    Id: 18480,
    Code: '8114',
    Name: 'Bander, textile mfr: textile bleaching, dyeing'
  },
  { Id: 18481, Code: '8114', Name: 'Bater' },
  { Id: 18482, Code: '8114', Name: 'Beaterman, celluloid mfr' },
  { Id: 18483, Code: '8114', Name: 'Bleacher and dyer' },
  { Id: 18484, Code: '8114', Name: 'Bleacher, FEATHER' },
  { Id: 18485, Code: '8114', Name: 'Bleacher, OIL' },
  { Id: 18486, Code: '8114', Name: 'Bleacher, TEXTILES' },
  { Id: 18487, Code: '8114', Name: 'Bleacher, yarn' },
  { Id: 18488, Code: '8114', Name: 'Blender' },
  { Id: 18489, Code: '8114', Name: 'Blender, batch' },
  { Id: 18490, Code: '8114', Name: 'Blender, coal, coke ovens' },
  { Id: 18491, Code: '8114', Name: 'Blender, coal, steel mfr' },
  { Id: 18492, Code: '8114', Name: 'Blender, colour, chemical mfr' },
  { Id: 18493, Code: '8114', Name: 'Blender, grease, MINERAL OIL' },
  { Id: 18494, Code: '8114', Name: 'Blender, liquor' },
  { Id: 18495, Code: '8114', Name: 'Blender, oil' },
  { Id: 18496, Code: '8114', Name: 'Blender, pigment, CHEMICALS' },
  { Id: 18497, Code: '8114', Name: 'Blender, varnish' },
  { Id: 18498, Code: '8114', Name: 'Boiler, acid' },
  { Id: 18499, Code: '8114', Name: 'Boiler, CHEMICALS' },
  { Id: 18500, Code: '8114', Name: 'Boiler, fat' },
  { Id: 18501, Code: '8114', Name: 'Boiler, glue' },
  { Id: 18502, Code: '8114', Name: 'Boiler, grease' },
  { Id: 18503, Code: '8114', Name: 'Boiler, gum, textile bleaching, dyeing' },
  { Id: 18504, Code: '8114', Name: 'Boiler, oil' },
  { Id: 18505, Code: '8114', Name: 'Boiler, salt' },
  { Id: 18506, Code: '8114', Name: 'Boiler, size' },
  { Id: 18507, Code: '8114', Name: 'Boiler, soap' },
  { Id: 18508, Code: '8114', Name: 'Boiler, starch' },
  { Id: 18509, Code: '8114', Name: 'Boiler, tar, gas works' },
  { Id: 18510, Code: '8114', Name: 'Boilerman, GELATINE, GLUE, SIZE' },
  { Id: 18511, Code: '8114', Name: 'Breaker, slag' },
  { Id: 18512, Code: '8114', Name: 'Brightener, oil' },
  { Id: 18513, Code: '8114', Name: 'Bruiser, leather dressing' },
  { Id: 18514, Code: '8114', Name: 'Brusher, glove' },
  { Id: 18515, Code: '8114', Name: 'Brusher, leather finishing' },
  { Id: 18516, Code: '8114', Name: 'Bunkerman, chemical mfr' },
  { Id: 18517, Code: '8114', Name: 'Bunkerman, kiln, chemical mfr' },
  { Id: 18518, Code: '8114', Name: 'Bunkerman, kiln, lime burning' },
  { Id: 18519, Code: '8114', Name: 'Burner, chalk' },
  { Id: 18520, Code: '8114', Name: 'Burner, charcoal mfr' },
  { Id: 18521, Code: '8114', Name: 'Burner, chemical mfr' },
  {
    Id: 18522,
    Code: '8114',
    Name: 'Burner, furnace, rotary, aluminium refining'
  },
  { Id: 18523, Code: '8114', Name: 'Burner, gas, coal gas, coke ovens' },
  { Id: 18524, Code: '8114', Name: 'Burner, gypsum' },
  { Id: 18525, Code: '8114', Name: 'Burner, kiln, carbon goods mfr' },
  { Id: 18526, Code: '8114', Name: 'Burnerman' },
  { Id: 18527, Code: '8114', Name: 'Burnerman, acid' },
  { Id: 18528, Code: '8114', Name: 'Burner-off, INCANDESCENT MANTLES' },
  { Id: 18529, Code: '8114', Name: 'Burrer' },
  { Id: 18530, Code: '8114', Name: 'Cager' },
  { Id: 18531, Code: '8114', Name: 'Calciner' },
  { Id: 18532, Code: '8114', Name: 'Calenderman, asbestos-cement goods mfr' },
  { Id: 18533, Code: '8114', Name: 'Carboniser, gas works' },
  { Id: 18534, Code: '8114', Name: 'Carrotter' },
  { Id: 18535, Code: '8114', Name: 'Causticizer' },
  { Id: 18536, Code: '8114', Name: 'Chamberman, ACIDS' },
  { Id: 18537, Code: '8114', Name: 'Chamberman, chemical mfr' },
  { Id: 18538, Code: '8114', Name: 'Charger, kiln' },
  { Id: 18539, Code: '8114', Name: 'Cleaner, bone' },
  { Id: 18540, Code: '8114', Name: 'Cleaner, fellmongering' },
  { Id: 18541, Code: '8114', Name: 'Cleaner, hide' },
  { Id: 18542, Code: '8114', Name: 'Cleaner, sponge' },
  { Id: 18543, Code: '8114', Name: 'Cleanser, soap, detergent mfr' },
  { Id: 18544, Code: '8114', Name: 'Clipper, tannery' },
  { Id: 18545, Code: '8114', Name: 'Coater, celluloid, film mfr' },
  { Id: 18546, Code: '8114', Name: 'Coater, emulsion' },
  { Id: 18547, Code: '8114', Name: 'Coater, paper, PHOTOGRAPHIC' },
  { Id: 18548, Code: '8114', Name: 'Coater, photographic film mfr' },
  { Id: 18549, Code: '8114', Name: 'Coater, plate, dry' },
  { Id: 18550, Code: '8114', Name: 'Coater, sugar, pharmaceutical mfr' },
  { Id: 18551, Code: '8114', Name: 'Coater, tablet' },
  { Id: 18552, Code: '8114', Name: 'Coater, tar, coal gas, coke ovens' },
  { Id: 18553, Code: '8114', Name: 'Coker, coal gas, coke ovens' },
  { Id: 18554, Code: '8114', Name: 'Colourman' },
  { Id: 18555, Code: '8114', Name: 'Comber, fur dressing' },
  { Id: 18556, Code: '8114', Name: 'Compounder' },
  { Id: 18557, Code: '8114', Name: 'Compressor, tablet' },
  { Id: 18558, Code: '8114', Name: 'Concentrator' },
  { Id: 18559, Code: '8114', Name: 'Conditioner, tannery' },
  { Id: 18560, Code: '8114', Name: 'Controlman' },
  { Id: 18561, Code: '8114', Name: 'Converter, polythene' },
  { Id: 18562, Code: '8114', Name: 'Cooler, chemical mfr' },
  { Id: 18563, Code: '8114', Name: 'Corrugator, asbestos-cement goods mfr' },
  { Id: 18564, Code: '8114', Name: 'Crusher, calamine' },
  { Id: 18565, Code: '8114', Name: 'Crusher, CHEMICALS' },
  { Id: 18566, Code: '8114', Name: 'Cureman' },
  { Id: 18567, Code: '8114', Name: 'Curer, SKINS' },
  { Id: 18568, Code: '8114', Name: 'Currier' },
  { Id: 18569, Code: '8114', Name: 'Cutter, gimson, brake linings mfr' },
  { Id: 18570, Code: '8114', Name: 'Cutter, glue mfr' },
  { Id: 18571, Code: '8114', Name: 'Cutter, leather, tannery' },
  { Id: 18572, Code: '8114', Name: 'Cutter, sheet, asbestos' },
  { Id: 18573, Code: '8114', Name: 'Cutter, skin, tannery' },
  { Id: 18574, Code: '8114', Name: 'Cutter, soap' },
  { Id: 18575, Code: '8114', Name: 'Cutter, sponge' },
  { Id: 18576, Code: '8114', Name: 'Cutter, tannery' },
  { Id: 18577, Code: '8114', Name: 'Degreaser, tannery' },
  { Id: 18578, Code: '8114', Name: 'Delimer' },
  { Id: 18579, Code: '8114', Name: 'Denierer, man-made fibre mfr' },
  { Id: 18580, Code: '8114', Name: 'Digester' },
  { Id: 18581, Code: '8114', Name: 'Dipper, leather dressing' },
  { Id: 18582, Code: '8114', Name: 'Distiller' },
  { Id: 18583, Code: '8114', Name: 'Doper, leather dressing' },
  {
    Id: 18584,
    Code: '8114',
    Name: 'Doubler, textile mfr: textile bleaching, dyeing'
  },
  { Id: 18585, Code: '8114', Name: 'Drawer, kiln, chemical mfr' },
  { Id: 18586, Code: '8114', Name: 'Drencher' },
  { Id: 18587, Code: '8114', Name: 'Dresser, fur' },
  { Id: 18588, Code: '8114', Name: 'Dresser, fur dressing' },
  { Id: 18589, Code: '8114', Name: 'Dresser, leather' },
  { Id: 18590, Code: '8114', Name: 'Dresser, leather dressing' },
  { Id: 18591, Code: '8114', Name: 'Dresser, skin' },
  {
    Id: 18592,
    Code: '8114',
    Name: 'Driver, machine, asbestos-cement goods mfr'
  },
  { Id: 18593, Code: '8114', Name: 'Dropper, oil refining' },
  { Id: 18594, Code: '8114', Name: 'Drum and cagehand, tannery' },
  { Id: 18595, Code: '8114', Name: 'Drumhand' },
  { Id: 18596, Code: '8114', Name: 'Drummer, tannery' },
  { Id: 18597, Code: '8114', Name: 'Dryer, chemical mfr' },
  { Id: 18598, Code: '8114', Name: 'Dryer, colour, dyestuffs mfr' },
  { Id: 18599, Code: '8114', Name: 'Dryer, gelatine' },
  { Id: 18600, Code: '8114', Name: 'Dryer, glue' },
  { Id: 18601, Code: '8114', Name: 'Dryer, soap, detergent mfr' },
  { Id: 18602, Code: '8114', Name: 'Dryer, tannery' },
  { Id: 18603, Code: '8114', Name: 'Dyer' },
  { Id: 18604, Code: '8114', Name: 'Dyer, beam' },
  { Id: 18605, Code: '8114', Name: 'Dyer, black, textile mfr' },
  { Id: 18606, Code: '8114', Name: 'Dyer, brush, leather dressing' },
  { Id: 18607, Code: '8114', Name: 'Dyer, calico' },
  { Id: 18608, Code: '8114', Name: 'Dyer, carpet' },
  { Id: 18609, Code: '8114', Name: 'Dyer, clothes' },
  { Id: 18610, Code: '8114', Name: 'Dyer, colour' },
  { Id: 18611, Code: '8114', Name: 'Dyer, cop' },
  { Id: 18612, Code: '8114', Name: 'Dyer, cord' },
  { Id: 18613, Code: '8114', Name: 'Dyer, fibre' },
  { Id: 18614, Code: '8114', Name: 'Dyer, fur' },
  { Id: 18615, Code: '8114', Name: 'Dyer, fustian' },
  { Id: 18616, Code: '8114', Name: 'Dyer, garment' },
  { Id: 18617, Code: '8114', Name: 'Dyer, glove' },
  { Id: 18618, Code: '8114', Name: 'Dyer, hair' },
  { Id: 18619, Code: '8114', Name: 'Dyer, hank' },
  { Id: 18620, Code: '8114', Name: 'Dyer, hat' },
  { Id: 18621, Code: '8114', Name: 'Dyer, head' },
  { Id: 18622, Code: '8114', Name: 'Dyer, jig' },
  { Id: 18623, Code: '8114', Name: 'Dyer, job' },
  { Id: 18624, Code: '8114', Name: 'Dyer, master' },
  { Id: 18625, Code: '8114', Name: 'Dyer, operative' },
  { Id: 18626, Code: '8114', Name: 'Dyer, piece' },
  { Id: 18627, Code: '8114', Name: 'Dyer, skein' },
  { Id: 18628, Code: '8114', Name: 'Dyer, skin' },
  { Id: 18629, Code: '8114', Name: 'Dyer, vat' },
  { Id: 18630, Code: '8114', Name: 'Dyer, vessel' },
  { Id: 18631, Code: '8114', Name: 'Dyer, warp' },
  { Id: 18632, Code: '8114', Name: 'Dyer, winch' },
  { Id: 18633, Code: '8114', Name: 'Dyer, yarn' },
  { Id: 18634, Code: '8114', Name: 'Egger and washer' },
  { Id: 18635, Code: '8114', Name: 'Embosser, leather dressing' },
  { Id: 18636, Code: '8114', Name: 'Emptier, charcoal mfr' },
  { Id: 18637, Code: '8114', Name: 'Extractor, chemical mfr' },
  { Id: 18638, Code: '8114', Name: 'Fellmonger' },
  { Id: 18639, Code: '8114', Name: 'Filterer, chemical mfr' },
  { Id: 18640, Code: '8114', Name: 'Finisher, caustic' },
  { Id: 18641, Code: '8114', Name: 'Finisher, dyers' },
  { Id: 18642, Code: '8114', Name: 'Finisher, fur' },
  { Id: 18643, Code: '8114', Name: 'Finisher, leather' },
  { Id: 18644, Code: '8114', Name: 'Finisher, leather dressing' },
  { Id: 18645, Code: '8114', Name: 'Finisher, photographic film mfr' },
  { Id: 18646, Code: '8114', Name: 'Fireman, chemical mfr' },
  { Id: 18647, Code: '8114', Name: 'Fireman, coal gas, coke ovens' },
  { Id: 18648, Code: '8114', Name: 'Fireman, composition die mfr' },
  { Id: 18649, Code: '8114', Name: 'Fireman, gas' },
  { Id: 18650, Code: '8114', Name: 'Fireman, oil refining' },
  { Id: 18651, Code: '8114', Name: 'Fireman, pencil, crayon mfr' },
  { Id: 18652, Code: '8114', Name: 'Fireman, pot' },
  { Id: 18653, Code: '8114', Name: 'Fireman, retort' },
  { Id: 18654, Code: '8114', Name: 'Fireman, salt mfr' },
  { Id: 18655, Code: '8114', Name: 'Firer, chemical mfr' },
  { Id: 18656, Code: '8114', Name: 'Flesher' },
  { Id: 18657, Code: '8114', Name: 'Fluffer' },
  { Id: 18658, Code: '8114', Name: 'Foreman, battery, coke ovens' },
  { Id: 18659, Code: '8114', Name: 'Foreman, blue and starch mfr' },
  {
    Id: 18660,
    Code: '8114',
    Name: 'Foreman, carbonising, coal gas, coke ovens'
  },
  { Id: 18661, Code: '8114', Name: 'Foreman, chemical' },
  { Id: 18662, Code: '8114', Name: 'Foreman, chemical mfr' },
  { Id: 18663, Code: '8114', Name: 'Foreman, coal gas by-products mfr' },
  { Id: 18664, Code: '8114', Name: 'Foreman, coke ovens' },
  { Id: 18665, Code: '8114', Name: 'Foreman, dyeworks' },
  { Id: 18666, Code: '8114', Name: 'Foreman, gas supplier: gas works' },
  { Id: 18667, Code: '8114', Name: 'Foreman, GELATINE, GLUE, SIZE' },
  { Id: 18668, Code: '8114', Name: 'Foreman, hearth, soak' },
  { Id: 18669, Code: '8114', Name: 'Foreman, heat, gas works' },
  { Id: 18670, Code: '8114', Name: 'Foreman, house, gas' },
  { Id: 18671, Code: '8114', Name: 'Foreman, house, retort' },
  { Id: 18672, Code: '8114', Name: 'Foreman, kiln, carbon goods mfr' },
  { Id: 18673, Code: '8114', Name: 'Foreman, leather dressing' },
  { Id: 18674, Code: '8114', Name: 'Foreman, lime' },
  { Id: 18675, Code: '8114', Name: 'Foreman, mill, blue' },
  { Id: 18676, Code: '8114', Name: 'Foreman, oil refining' },
  { Id: 18677, Code: '8114', Name: 'Foreman, ordnance factory: explosive mfr' },
  { Id: 18678, Code: '8114', Name: 'Foreman, oven' },
  { Id: 18679, Code: '8114', Name: 'Foreman, paint mfr' },
  { Id: 18680, Code: '8114', Name: 'Foreman, patent fuel mfr' },
  { Id: 18681, Code: '8114', Name: 'Foreman, plant, carbonisation' },
  { Id: 18682, Code: '8114', Name: 'Foreman, plant, crushing' },
  { Id: 18683, Code: '8114', Name: 'Foreman, plant, gas' },
  { Id: 18684, Code: '8114', Name: 'Foreman, plant, reforming' },
  { Id: 18685, Code: '8114', Name: 'Foreman, plastics mfr' },
  { Id: 18686, Code: '8114', Name: 'Foreman, polish' },
  { Id: 18687, Code: '8114', Name: 'Foreman, process' },
  { Id: 18688, Code: '8114', Name: 'Foreman, purification, gas supplier' },
  { Id: 18689, Code: '8114', Name: 'Foreman, purifier, gas' },
  { Id: 18690, Code: '8114', Name: 'Foreman, retort, gas works' },
  { Id: 18691, Code: '8114', Name: 'Foreman, room, mill, fur dressing' },
  { Id: 18692, Code: '8114', Name: 'Foreman, screen, gas works: coke ovens' },
  { Id: 18693, Code: '8114', Name: 'Foreman, screens, gas works: coke ovens' },
  { Id: 18694, Code: '8114', Name: 'Foreman, shed, tannery' },
  { Id: 18695, Code: '8114', Name: 'Foreman, slag wool mfr' },
  { Id: 18696, Code: '8114', Name: 'Foreman, soap, detergent mfr' },
  { Id: 18697, Code: '8114', Name: 'Foreman, spinning, man-made fibre mfr' },
  { Id: 18698, Code: '8114', Name: 'Foreman, spirit distilling' },
  { Id: 18699, Code: '8114', Name: 'Foreman, stage, gas works' },
  { Id: 18700, Code: '8114', Name: 'Foreman, tannery' },
  { Id: 18701, Code: '8114', Name: 'Foreman, textile mfr: bleaching, dyeing' },
  { Id: 18702, Code: '8114', Name: 'Foreman, textile mfr: man-made fibre mfr' },
  { Id: 18703, Code: '8114', Name: 'Foreman, toilet preparations mfr' },
  { Id: 18704, Code: '8114', Name: 'Foreman, yard, tan' },
  { Id: 18705, Code: '8114', Name: 'Fractionator, chemical mfr' },
  { Id: 18706, Code: '8114', Name: 'Furnaceman, barium' },
  { Id: 18707, Code: '8114', Name: 'Furnaceman, calcining' },
  { Id: 18708, Code: '8114', Name: 'Furnaceman, chemical mfr' },
  { Id: 18709, Code: '8114', Name: 'Furnaceman, gas works' },
  { Id: 18710, Code: '8114', Name: 'Furnaceman, graphitising' },
  {
    Id: 18711,
    Code: '8114',
    Name: 'Furnaceman, lead pencil, chalk, crayon mfr'
  },
  { Id: 18712, Code: '8114', Name: 'Furnaceman, oil refining' },
  { Id: 18713, Code: '8114', Name: 'Furnaceman, red lead mfr' },
  { Id: 18714, Code: '8114', Name: 'Gasman, COAL GAS' },
  { Id: 18715, Code: '8114', Name: 'Gasman, gas works' },
  { Id: 18716, Code: '8114', Name: 'Gasman, WATER GAS' },
  { Id: 18717, Code: '8114', Name: 'Generator, acetylene' },
  { Id: 18718, Code: '8114', Name: 'Gilder, leather dressing' },
  { Id: 18719, Code: '8114', Name: 'Glazer, leather dressing' },
  { Id: 18720, Code: '8114', Name: 'Grader, chemical mfr' },
  { Id: 18721, Code: '8114', Name: 'Grader, coke' },
  { Id: 18722, Code: '8114', Name: 'Grainer, leather dressing' },
  { Id: 18723, Code: '8114', Name: 'Granulator, chemical mfr' },
  { Id: 18724, Code: '8114', Name: 'Grinder, bone' },
  { Id: 18725, Code: '8114', Name: 'Grinder, carbide' },
  { Id: 18726, Code: '8114', Name: 'Grinder, carbon, crucible mfr' },
  { Id: 18727, Code: '8114', Name: 'Grinder, chemical mfr' },
  { Id: 18728, Code: '8114', Name: 'Grinder, colour' },
  { Id: 18729, Code: '8114', Name: 'Grinder, enamel' },
  { Id: 18730, Code: '8114', Name: 'Grinder, gelatine' },
  { Id: 18731, Code: '8114', Name: 'Grinder, ink' },
  { Id: 18732, Code: '8114', Name: 'Grinder, paint' },
  { Id: 18733, Code: '8114', Name: 'Grinder, patent fuel mfr' },
  { Id: 18734, Code: '8114', Name: 'Grinder, resin' },
  { Id: 18735, Code: '8114', Name: 'Grinder, slag' },
  { Id: 18736, Code: '8114', Name: 'Grinder, soap, dry' },
  { Id: 18737, Code: '8114', Name: 'Grinder, wet, CHEMICALS' },
  { Id: 18738, Code: '8114', Name: 'Hammerman, leather dressing' },
  { Id: 18739, Code: '8114', Name: 'Hand, bench, chemical mfr' },
  { Id: 18740, Code: '8114', Name: 'Hand, bleach, textile mfr' },
  { Id: 18741, Code: '8114', Name: 'Hand, boiling, soap' },
  { Id: 18742, Code: '8114', Name: 'Hand, explosive' },
  { Id: 18743, Code: '8114', Name: 'Hand, filtration, chemical mfr' },
  { Id: 18744, Code: '8114', Name: 'Hand, furnace, charcoal mfr' },
  { Id: 18745, Code: '8114', Name: 'Hand, installation, oil refining' },
  { Id: 18746, Code: '8114', Name: 'Hand, mill, chemical mfr' },
  { Id: 18747, Code: '8114', Name: 'Hand, mill, finishing' },
  { Id: 18748, Code: '8114', Name: 'Hand, mill, grinding' },
  { Id: 18749, Code: '8114', Name: 'Hand, mill, ink' },
  { Id: 18750, Code: '8114', Name: 'Hand, mill, tints' },
  { Id: 18751, Code: '8114', Name: 'Hand, milling, soap, detergent mfr' },
  { Id: 18752, Code: '8114', Name: 'Hand, plant, acid' },
  { Id: 18753, Code: '8114', Name: 'Hand, screen, coal gas, coke ovens' },
  { Id: 18754, Code: '8114', Name: 'Hand, set' },
  { Id: 18755, Code: '8114', Name: 'Hand, trowel, cement mfr' },
  { Id: 18756, Code: '8114', Name: 'Handler, mud, press, red' },
  { Id: 18757, Code: '8114', Name: 'Handler, tannery' },
  { Id: 18758, Code: '8114', Name: 'Haulier, butt' },
  { Id: 18759, Code: '8114', Name: 'Heater, coal gas, coke ovens' },
  { Id: 18760, Code: '8114', Name: 'Heater, oven, coke' },
  { Id: 18761, Code: '8114', Name: 'Heater, retort, chemical mfr' },
  { Id: 18762, Code: '8114', Name: 'Heaterman, patent fuel mfr' },
  { Id: 18763, Code: '8114', Name: 'Helper, salt mfr' },
  { Id: 18764, Code: '8114', Name: "Helper, worker's, process" },
  { Id: 18765, Code: '8114', Name: 'Hollanderman' },
  { Id: 18766, Code: '8114', Name: 'Houseman, cylinder' },
  { Id: 18767, Code: '8114', Name: 'Impregnator, armature' },
  {
    Id: 18768,
    Code: '8114',
    Name: 'Impregnator, asbestos composition goods mfr'
  },
  { Id: 18769, Code: '8114', Name: 'Inspector, coke, coke ovens' },
  { Id: 18770, Code: '8114', Name: 'Ironer, leather dressing' },
  { Id: 18771, Code: '8114', Name: 'Japanner, leather dressing' },
  { Id: 18772, Code: '8114', Name: 'Jigger, leather dressing' },
  { Id: 18773, Code: '8114', Name: 'Jigger, textile bleaching, dyeing' },
  { Id: 18774, Code: '8114', Name: 'Jiggerman' },
  { Id: 18775, Code: '8114', Name: 'Jobber, leather dressing' },
  {
    Id: 18776,
    Code: '8114',
    Name: 'Jobber, textile mfr: textile bleaching, dyeing'
  },
  { Id: 18777, Code: '8114', Name: "Labourer, dyer's" },
  { Id: 18778, Code: '8114', Name: 'Labourer, house, dye' },
  { Id: 18779, Code: '8114', Name: 'Layer-up, tannery' },
  { Id: 18780, Code: '8114', Name: 'Lifter, butt' },
  { Id: 18781, Code: '8114', Name: 'Limer, fellmongering' },
  { Id: 18782, Code: '8114', Name: 'Loader, charcoal mfr' },
  { Id: 18783, Code: '8114', Name: 'Lofter, SALT' },
  { Id: 18784, Code: '8114', Name: 'Machinist, brushing, leather dressing' },
  { Id: 18785, Code: '8114', Name: 'Machinist, buffing, leather dressing' },
  { Id: 18786, Code: '8114', Name: 'Machinist, carding, fur dressing' },
  { Id: 18787, Code: '8114', Name: 'Machinist, casting, film' },
  {
    Id: 18788,
    Code: '8114',
    Name: 'Machinist, casting, transparent cellulose wrappings mfr'
  },
  { Id: 18789, Code: '8114', Name: 'Machinist, chemical mfr' },
  { Id: 18790, Code: '8114', Name: 'Machinist, coating, PHOTOGRAPHIC FILMS' },
  { Id: 18791, Code: '8114', Name: 'Machinist, compressing, TABLETS, PILLS' },
  { Id: 18792, Code: '8114', Name: 'Machinist, cutting, ASBESTOS' },
  { Id: 18793, Code: '8114', Name: 'Machinist, cutting, soap, detergent mfr' },
  { Id: 18794, Code: '8114', Name: 'Machinist, dressing, pelt' },
  { Id: 18795, Code: '8114', Name: 'Machinist, embossing, leather dressing' },
  { Id: 18796, Code: '8114', Name: 'Machinist, film, cellulose' },
  { Id: 18797, Code: '8114', Name: 'Machinist, filter, CELLULOID' },
  { Id: 18798, Code: '8114', Name: 'Machinist, fleshing' },
  { Id: 18799, Code: '8114', Name: 'Machinist, frizing, tannery' },
  { Id: 18800, Code: '8114', Name: 'Machinist, graphite' },
  { Id: 18801, Code: '8114', Name: 'Machinist, grinding, paint mfr' },
  { Id: 18802, Code: '8114', Name: 'Machinist, hide, leather merchants' },
  {
    Id: 18803,
    Code: '8114',
    Name: 'Machinist, impregnating, plastics goods mfr'
  },
  { Id: 18804, Code: '8114', Name: 'Machinist, jigging' },
  { Id: 18805, Code: '8114', Name: 'Machinist, leather dressing' },
  { Id: 18806, Code: '8114', Name: 'Machinist, paint, paint mfr' },
  { Id: 18807, Code: '8114', Name: 'Machinist, photographic film mfr' },
  { Id: 18808, Code: '8114', Name: 'Machinist, plastics mfr' },
  { Id: 18809, Code: '8114', Name: 'Machinist, powder, soap' },
  {
    Id: 18810,
    Code: '8114',
    Name: 'Machinist, rotary, textile bleaching, dyeing'
  },
  { Id: 18811, Code: '8114', Name: 'Machinist, salt mfr' },
  { Id: 18812, Code: '8114', Name: 'Machinist, setting, leather dressing' },
  { Id: 18813, Code: '8114', Name: 'Machinist, soap, detergent mfr' },
  { Id: 18814, Code: '8114', Name: 'Machinist, splitting, tannery' },
  { Id: 18815, Code: '8114', Name: 'Machinist, staking' },
  { Id: 18816, Code: '8114', Name: 'Machinist, synthetics mfr' },
  { Id: 18817, Code: '8114', Name: 'Machinist, tableting, TABLETS, PILLS' },
  { Id: 18818, Code: '8114', Name: 'Machinist, tannery' },
  {
    Id: 18819,
    Code: '8114',
    Name: 'Machinist, textile mfr: man-made fibre mfr'
  },
  {
    Id: 18820,
    Code: '8114',
    Name: 'Machinist, textile mfr: textile bleaching, dyeing'
  },
  { Id: 18821, Code: '8114', Name: 'Maker, acetate' },
  { Id: 18822, Code: '8114', Name: 'Maker, acid' },
  { Id: 18823, Code: '8114', Name: 'Maker, ammunition' },
  { Id: 18824, Code: '8114', Name: 'Maker, block, fuel, PATENT FUEL' },
  { Id: 18825, Code: '8114', Name: 'Maker, briquette' },
  { Id: 18826, Code: '8114', Name: 'Maker, capsule, drug mfr' },
  { Id: 18827, Code: '8114', Name: 'Maker, carbon, carbon goods mfr' },
  { Id: 18828, Code: '8114', Name: 'Maker, carmine' },
  { Id: 18829, Code: '8114', Name: 'Maker, cellulose' },
  { Id: 18830, Code: '8114', Name: 'Maker, chemical, fine' },
  { Id: 18831, Code: '8114', Name: 'Maker, chemicals, fine' },
  { Id: 18832, Code: '8114', Name: 'Maker, chloride, ammonium' },
  { Id: 18833, Code: '8114', Name: 'Maker, chloroform' },
  { Id: 18834, Code: '8114', Name: 'Maker, colour' },
  { Id: 18835, Code: '8114', Name: 'Maker, composition' },
  { Id: 18836, Code: '8114', Name: 'Maker, corticine' },
  { Id: 18837, Code: '8114', Name: 'Maker, cosmetic' },
  { Id: 18838, Code: '8114', Name: 'Maker, cream, cosmetic mfr' },
  { Id: 18839, Code: '8114', Name: 'Maker, crystal' },
  { Id: 18840, Code: '8114', Name: 'Maker, dioxide, carbon' },
  { Id: 18841, Code: '8114', Name: 'Maker, dye' },
  { Id: 18842, Code: '8114', Name: 'Maker, emulsion' },
  { Id: 18843, Code: '8114', Name: 'Maker, enamel' },
  { Id: 18844, Code: '8114', Name: 'Maker, fertilizer' },
  { Id: 18845, Code: '8114', Name: 'Maker, fibre, man-made' },
  { Id: 18846, Code: '8114', Name: 'Maker, fibre, synthetic' },
  { Id: 18847, Code: '8114', Name: 'Maker, filament, continuous' },
  { Id: 18848, Code: '8114', Name: 'Maker, gas' },
  { Id: 18849, Code: '8114', Name: 'Maker, glue' },
  { Id: 18850, Code: '8114', Name: 'Maker, grease' },
  { Id: 18851, Code: '8114', Name: 'Maker, ink' },
  { Id: 18852, Code: '8114', Name: 'Maker, lacquer' },
  { Id: 18853, Code: '8114', Name: 'Maker, lead, red' },
  { Id: 18854, Code: '8114', Name: 'Maker, lead, white' },
  { Id: 18855, Code: '8114', Name: 'Maker, lime' },
  { Id: 18856, Code: '8114', Name: 'Maker, mat, sheepskin' },
  { Id: 18857, Code: '8114', Name: 'Maker, oil' },
  { Id: 18858, Code: '8114', Name: 'Maker, ointment' },
  { Id: 18859, Code: '8114', Name: 'Maker, oxide, lead' },
  { Id: 18860, Code: '8114', Name: 'Maker, paint' },
  { Id: 18861, Code: '8114', Name: 'Maker, paste, chemical mfr' },
  { Id: 18862, Code: '8114', Name: 'Maker, paste, paper goods mfr' },
  { Id: 18863, Code: '8114', Name: 'Maker, perfumery' },
  { Id: 18864, Code: '8114', Name: 'Maker, petroleum' },
  { Id: 18865, Code: '8114', Name: 'Maker, pigment' },
  { Id: 18866, Code: '8114', Name: 'Maker, pill' },
  { Id: 18867, Code: '8114', Name: 'Maker, pipe, asbestos' },
  { Id: 18868, Code: '8114', Name: 'Maker, polish' },
  { Id: 18869, Code: '8114', Name: 'Maker, powder, CHEMICAL' },
  { Id: 18870, Code: '8114', Name: 'Maker, resin' },
  { Id: 18871, Code: '8114', Name: 'Maker, saline' },
  { Id: 18872, Code: '8114', Name: 'Maker, salt' },
  { Id: 18873, Code: '8114', Name: 'Maker, size' },
  { Id: 18874, Code: '8114', Name: 'Maker, slip, pencil, crayon mfr' },
  { Id: 18875, Code: '8114', Name: 'Maker, soap' },
  { Id: 18876, Code: '8114', Name: 'Maker, solution, CELLULOID' },
  { Id: 18877, Code: '8114', Name: 'Maker, spirit' },
  { Id: 18878, Code: '8114', Name: 'Maker, starch, textile mfr' },
  { Id: 18879, Code: '8114', Name: 'Maker, sulphate' },
  { Id: 18880, Code: '8114', Name: 'Maker, tablet, pharmaceutical mfr' },
  { Id: 18881, Code: '8114', Name: 'Maker, tallow' },
  { Id: 18882, Code: '8114', Name: 'Maker, varnish' },
  { Id: 18883, Code: '8114', Name: 'Maker, varnish, head' },
  { Id: 18884, Code: '8114', Name: 'Maker, white, wallpaper mfr' },
  { Id: 18885, Code: '8114', Name: 'Man, acid' },
  { Id: 18886, Code: '8114', Name: 'Man, bench, chemical mfr' },
  { Id: 18887, Code: '8114', Name: 'Man, cake, salt' },
  { Id: 18888, Code: '8114', Name: 'Man, carbonator' },
  { Id: 18889, Code: '8114', Name: 'Man, catalyst' },
  { Id: 18890, Code: '8114', Name: 'Man, cellroom' },
  { Id: 18891, Code: '8114', Name: 'Man, chlorate of soda' },
  { Id: 18892, Code: '8114', Name: 'Man, condenser' },
  { Id: 18893, Code: '8114', Name: 'Man, converter, chemical mfr' },
  { Id: 18894, Code: '8114', Name: 'Man, dehydrator, tar' },
  { Id: 18895, Code: '8114', Name: 'Man, dreep, coal gas by-products mfr' },
  { Id: 18896, Code: '8114', Name: 'Man, drum, tannery' },
  { Id: 18897, Code: '8114', Name: 'Man, evaporator, chemical mfr' },
  { Id: 18898, Code: '8114', Name: 'Man, extractor, chemical mfr' },
  { Id: 18899, Code: '8114', Name: 'Man, extractor, fat' },
  { Id: 18900, Code: '8114', Name: 'Man, filter, chemical mfr' },
  { Id: 18901, Code: '8114', Name: 'Man, gelatine, explosives mfr' },
  { Id: 18902, Code: '8114', Name: 'Man, generator' },
  { Id: 18903, Code: '8114', Name: 'Man, grease' },
  { Id: 18904, Code: '8114', Name: 'Man, house, char' },
  { Id: 18905, Code: '8114', Name: 'Man, house, still, distillery' },
  {
    Id: 18906,
    Code: '8114',
    Name: 'Man, kiln, asbestos composition goods mfr'
  },
  { Id: 18907, Code: '8114', Name: 'Man, kiln, carbon' },
  { Id: 18908, Code: '8114', Name: 'Man, kiln, char' },
  { Id: 18909, Code: '8114', Name: 'Man, kiln, chemical mfr' },
  { Id: 18910, Code: '8114', Name: 'Man, kiln, composition die mfr' },
  { Id: 18911, Code: '8114', Name: 'Man, lime' },
  { Id: 18912, Code: '8114', Name: 'Man, liquor, leather tanning' },
  { Id: 18913, Code: '8114', Name: 'Man, lump, salt mfr' },
  {
    Id: 18914,
    Code: '8114',
    Name: 'Man, machine, asbestos composition goods mfr'
  },
  { Id: 18915, Code: '8114', Name: 'Man, mill, carbon goods mfr' },
  { Id: 18916, Code: '8114', Name: 'Man, mill, chemical mfr' },
  { Id: 18917, Code: '8114', Name: 'Man, mill, lead' },
  { Id: 18918, Code: '8114', Name: 'Man, mill, leather dressing' },
  { Id: 18919, Code: '8114', Name: 'Man, mill, paint' },
  { Id: 18920, Code: '8114', Name: 'Man, mixer, slag, tar' },
  { Id: 18921, Code: '8114', Name: 'Man, mixer, textile mfr' },
  { Id: 18922, Code: '8114', Name: 'Man, oxidiser' },
  { Id: 18923, Code: '8114', Name: 'Man, pan, acid' },
  { Id: 18924, Code: '8114', Name: 'Man, pan, chemical mfr' },
  { Id: 18925, Code: '8114', Name: 'Man, plant, benzol' },
  { Id: 18926, Code: '8114', Name: 'Man, plant, benzole' },
  { Id: 18927, Code: '8114', Name: 'Man, plant, chemical mfr' },
  { Id: 18928, Code: '8114', Name: 'Man, purification' },
  { Id: 18929, Code: '8114', Name: 'Man, purifier' },
  { Id: 18930, Code: '8114', Name: 'Man, rectifying' },
  { Id: 18931, Code: '8114', Name: 'Man, retort, charcoal mfr' },
  { Id: 18932, Code: '8114', Name: 'Man, retort, coal gas, coke ovens' },
  { Id: 18933, Code: '8114', Name: 'Man, room, still, distillery' },
  { Id: 18934, Code: '8114', Name: 'Man, still' },
  { Id: 18935, Code: '8114', Name: 'Man, stillhouse, distillery' },
  { Id: 18936, Code: '8114', Name: 'Man, stillroom, distillery' },
  { Id: 18937, Code: '8114', Name: 'Man, sulphonator' },
  { Id: 18938, Code: '8114', Name: 'Man, suspender' },
  { Id: 18939, Code: '8114', Name: 'Man, table, leather dressing' },
  { Id: 18940, Code: '8114', Name: 'Man, tar, coal gas by-products mfr' },
  { Id: 18941, Code: '8114', Name: 'Man, viscose, man-made fibre mfr' },
  { Id: 18942, Code: '8114', Name: 'Man, wax' },
  { Id: 18943, Code: '8114', Name: 'Manufacturer, chemical' },
  { Id: 18944, Code: '8114', Name: 'Manufacturer, chemical mfr' },
  { Id: 18945, Code: '8114', Name: 'Manufacturer, cosmetics mfr' },
  { Id: 18946, Code: '8114', Name: 'Manufacturer, gas' },
  { Id: 18947, Code: '8114', Name: 'Manufacturer, leather mfr' },
  { Id: 18948, Code: '8114', Name: 'Marbler, leather dressing' },
  { Id: 18949, Code: '8114', Name: 'Matcher, colour, paint mfr' },
  { Id: 18950, Code: '8114', Name: 'Matcher, colour, printing' },
  { Id: 18951, Code: '8114', Name: 'Melter, emulsion, PHOTOGRAPHIC' },
  { Id: 18952, Code: '8114', Name: 'Melter, fat' },
  { Id: 18953, Code: '8114', Name: 'Melter, grease' },
  { Id: 18954, Code: '8114', Name: 'Methylator' },
  { Id: 18955, Code: '8114', Name: 'Miller, chemical mfr' },
  { Id: 18956, Code: '8114', Name: 'Miller, dyewood' },
  { Id: 18957, Code: '8114', Name: 'Miller, madder' },
  { Id: 18958, Code: '8114', Name: 'Miller, paint' },
  { Id: 18959, Code: '8114', Name: 'Miller, soap' },
  { Id: 18960, Code: '8114', Name: 'Miller, solvent' },
  { Id: 18961, Code: '8114', Name: 'Mixer, accumulator, battery mfr' },
  { Id: 18962, Code: '8114', Name: 'Mixer, acid' },
  { Id: 18963, Code: '8114', Name: 'Mixer, batch, chemical mfr' },
  { Id: 18964, Code: '8114', Name: 'Mixer, bleach, PAPER' },
  { Id: 18965, Code: '8114', Name: 'Mixer, carbide, tungsten' },
  { Id: 18966, Code: '8114', Name: 'Mixer, carbon' },
  { Id: 18967, Code: '8114', Name: 'Mixer, chemical mfr' },
  { Id: 18968, Code: '8114', Name: 'Mixer, chemical, textile mfr' },
  { Id: 18969, Code: '8114', Name: 'Mixer, chemicals, textile mfr' },
  { Id: 18970, Code: '8114', Name: 'Mixer, clay, paper mfr' },
  { Id: 18971, Code: '8114', Name: 'Mixer, colour' },
  { Id: 18972, Code: '8114', Name: 'Mixer, composition die mfr' },
  { Id: 18973, Code: '8114', Name: 'Mixer, compound' },
  { Id: 18974, Code: '8114', Name: 'Mixer, cosmetic' },
  { Id: 18975, Code: '8114', Name: 'Mixer, depolariser' },
  { Id: 18976, Code: '8114', Name: 'Mixer, dope' },
  { Id: 18977, Code: '8114', Name: 'Mixer, dye' },
  { Id: 18978, Code: '8114', Name: 'Mixer, electrolyte, electric battery mfr' },
  { Id: 18979, Code: '8114', Name: 'Mixer, emulsion' },
  { Id: 18980, Code: '8114', Name: 'Mixer, enamel' },
  { Id: 18981, Code: '8114', Name: 'Mixer, explosives' },
  { Id: 18982, Code: '8114', Name: 'Mixer, fluid, engineering' },
  { Id: 18983, Code: '8114', Name: 'Mixer, glue' },
  { Id: 18984, Code: '8114', Name: 'Mixer, ink' },
  { Id: 18985, Code: '8114', Name: 'Mixer, lacquer' },
  { Id: 18986, Code: '8114', Name: 'Mixer, oil' },
  { Id: 18987, Code: '8114', Name: 'Mixer, paint' },
  { Id: 18988, Code: '8114', Name: 'Mixer, paste, lead' },
  { Id: 18989, Code: '8114', Name: 'Mixer, paste, paper goods mfr' },
  { Id: 18990, Code: '8114', Name: 'Mixer, pencil, crayon mfr' },
  { Id: 18991, Code: '8114', Name: 'Mixer, polish, furniture' },
  { Id: 18992, Code: '8114', Name: 'Mixer, powder, fluorescent' },
  { Id: 18993, Code: '8114', Name: 'Mixer, resin' },
  { Id: 18994, Code: '8114', Name: 'Mixer, size, paper mfr' },
  { Id: 18995, Code: '8114', Name: 'Mixer, soap' },
  { Id: 18996, Code: '8114', Name: 'Mixer, solution' },
  { Id: 18997, Code: '8114', Name: 'Mixer, starch' },
  { Id: 18998, Code: '8114', Name: 'Mixer, textile mfr: textile proofing' },
  { Id: 18999, Code: '8114', Name: 'Moulder, pipe, ASBESTOS-CEMENT' },
  { Id: 19000, Code: '8114', Name: 'Muller' },
  { Id: 19001, Code: '8114', Name: 'Nailer, tannery' },
  { Id: 19002, Code: '8114', Name: 'Neutraliser, chemical mfr' },
  { Id: 19003, Code: '8114', Name: 'Oiler, leather dressing' },
  { Id: 19004, Code: '8114', Name: 'Oiler, mould, ASBESTOS' },
  { Id: 19005, Code: '8114', Name: 'Oiler, skin, LEATHER' },
  { Id: 19006, Code: '8114', Name: 'Oiler, varnish mfr' },
  { Id: 19007, Code: '8114', Name: 'Oilman, engine' },
  { Id: 19008, Code: '8114', Name: "Operative, dyer's" },
  { Id: 19009, Code: '8114', Name: 'Operative, house, dye, textile mfr' },
  {
    Id: 19010,
    Code: '8114',
    Name: 'Operator, autoclave, asbestos composition goods mfr'
  },
  { Id: 19011, Code: '8114', Name: 'Operator, autoclave, chemical mfr' },
  { Id: 19012, Code: '8114', Name: 'Operator, benzol' },
  { Id: 19013, Code: '8114', Name: 'Operator, benzole' },
  { Id: 19014, Code: '8114', Name: 'Operator, burner, kiln, carbon goods mfr' },
  { Id: 19015, Code: '8114', Name: 'Operator, capsulation' },
  { Id: 19016, Code: '8114', Name: 'Operator, centrifuge, CHEMICALS' },
  { Id: 19017, Code: '8114', Name: 'Operator, chemical' },
  { Id: 19018, Code: '8114', Name: 'Operator, column, oxygen mfr' },
  { Id: 19019, Code: '8114', Name: 'Operator, densification, CHEMICALS' },
  { Id: 19020, Code: '8114', Name: 'Operator, disintegrator, CHEMICALS' },
  { Id: 19021, Code: '8114', Name: 'Operator, evaporator, chemical mfr' },
  { Id: 19022, Code: '8114', Name: 'Operator, extruder, chemical mfr' },
  { Id: 19023, Code: '8114', Name: 'Operator, extrusion, chemical mfr' },
  {
    Id: 19024,
    Code: '8114',
    Name: 'Operator, filter, drum, rotary, chemical mfr'
  },
  { Id: 19025, Code: '8114', Name: 'Operator, furnace, electric, enamelling' },
  { Id: 19026, Code: '8114', Name: 'Operator, gas' },
  { Id: 19027, Code: '8114', Name: 'Operator, glazing, EXPLOSIVES' },
  { Id: 19028, Code: '8114', Name: 'Operator, guide' },
  { Id: 19029, Code: '8114', Name: 'Operator, house, dye, textile mfr' },
  { Id: 19030, Code: '8114', Name: 'Operator, hydro-extractor, chemical mfr' },
  { Id: 19031, Code: '8114', Name: 'Operator, hydro-extractor, tannery' },
  { Id: 19032, Code: '8114', Name: 'Operator, kettle, chemical mfr' },
  { Id: 19033, Code: '8114', Name: 'Operator, manifold' },
  { Id: 19034, Code: '8114', Name: 'Operator, mill, ball, chemical mfr' },
  { Id: 19035, Code: '8114', Name: 'Operator, mill, pug, CHEMICALS' },
  { Id: 19036, Code: '8114', Name: 'Operator, mill, sheeting, CHEMICALS' },
  { Id: 19037, Code: '8114', Name: 'Operator, milling, soap, detergent mfr' },
  { Id: 19038, Code: '8114', Name: 'Operator, mixing' },
  { Id: 19039, Code: '8114', Name: 'Operator, oil refining' },
  { Id: 19040, Code: '8114', Name: 'Operator, oven, coke' },
  { Id: 19041, Code: '8114', Name: 'Operator, pan, vacuum, chemical mfr' },
  { Id: 19042, Code: '8114', Name: 'Operator, plant, benzol' },
  { Id: 19043, Code: '8114', Name: 'Operator, plant, benzole' },
  { Id: 19044, Code: '8114', Name: 'Operator, plant, chemical' },
  { Id: 19045, Code: '8114', Name: 'Operator, plant, chemical mfr' },
  { Id: 19046, Code: '8114', Name: 'Operator, plant, chlorination' },
  { Id: 19047, Code: '8114', Name: 'Operator, plant, coal gas, coke ovens' },
  { Id: 19048, Code: '8114', Name: 'Operator, plant, cracker, oil refining' },
  { Id: 19049, Code: '8114', Name: 'Operator, plant, distillation, CHEMICALS' },
  { Id: 19050, Code: '8114', Name: 'Operator, plant, gas' },
  { Id: 19051, Code: '8114', Name: 'Operator, plant, oil refining' },
  { Id: 19052, Code: '8114', Name: 'Operator, plant, oxygen' },
  {
    Id: 19053,
    Code: '8114',
    Name: 'Operator, plant, sedimentation, chemical mfr'
  },
  {
    Id: 19054,
    Code: '8114',
    Name: 'Operator, plant, sterilising, SURGICAL DRESSINGS'
  },
  { Id: 19055, Code: '8114', Name: 'Operator, plant, sulphur' },
  { Id: 19056, Code: '8114', Name: 'Operator, process, chemical' },
  { Id: 19057, Code: '8114', Name: 'Operator, process, chemical mfr' },
  { Id: 19058, Code: '8114', Name: 'Operator, process, man-made fibre mfr' },
  { Id: 19059, Code: '8114', Name: 'Operator, process, oil refining' },
  { Id: 19060, Code: '8114', Name: 'Operator, process, pharmaceutical mfr' },
  { Id: 19061, Code: '8114', Name: 'Operator, process, plastics mfr' },
  { Id: 19062, Code: '8114', Name: 'Operator, producer, gas' },
  {
    Id: 19063,
    Code: '8114',
    Name: 'Operator, pumphouse, vacuum, oil refining'
  },
  { Id: 19064, Code: '8114', Name: 'Operator, refinery' },
  {
    Id: 19065,
    Code: '8114',
    Name: 'Operator, reproduction, atomic energy establishment'
  },
  { Id: 19066, Code: '8114', Name: 'Operator, retort, coal gas production' },
  { Id: 19067, Code: '8114', Name: 'Operator, room, drum' },
  { Id: 19068, Code: '8114', Name: 'Operator, saw, band, asbestos goods mfr' },
  { Id: 19069, Code: '8114', Name: 'Operator, skin and hide' },
  { Id: 19070, Code: '8114', Name: 'Operator, still' },
  { Id: 19071, Code: '8114', Name: 'Operator, synthesis, chemical mfr' },
  { Id: 19072, Code: '8114', Name: 'Operator, technical, chemical mfr' },
  { Id: 19073, Code: '8114', Name: 'Operator, treater, petroleum refining' },
  { Id: 19074, Code: '8114', Name: 'Operator, treatment, heat, CARBON' },
  { Id: 19075, Code: '8114', Name: 'Operator, treatment, heat, CARBON GOODS' },
  { Id: 19076, Code: '8114', Name: 'Operator, tunnel, GELATINE, GLUE, SIZE' },
  { Id: 19077, Code: '8114', Name: 'Operator, vessel, reaction, CHEMICALS' },
  { Id: 19078, Code: '8114', Name: 'Ovenman, brake linings mfr' },
  { Id: 19079, Code: '8114', Name: 'Ovenman, coke' },
  { Id: 19080, Code: '8114', Name: 'Ovenman, japanning, enamelling' },
  { Id: 19081, Code: '8114', Name: 'Padder, leather dressing' },
  { Id: 19082, Code: '8114', Name: 'Painter, hide, tannery' },
  { Id: 19083, Code: '8114', Name: 'Painter, skin, fellmongering' },
  { Id: 19084, Code: '8114', Name: 'Painter, tannery' },
  { Id: 19085, Code: '8114', Name: 'Panelman, oil refining' },
  { Id: 19086, Code: '8114', Name: 'Parer, leather dressing' },
  { Id: 19087, Code: '8114', Name: 'Paster, leather dressing' },
  { Id: 19088, Code: '8114', Name: 'Pearler' },
  { Id: 19089, Code: '8114', Name: 'Perfumer' },
  { Id: 19090, Code: '8114', Name: 'Picker, hat mfr' },
  { Id: 19091, Code: '8114', Name: 'Pickler, fellmongering' },
  { Id: 19092, Code: '8114', Name: 'Pickler, tannery' },
  { Id: 19093, Code: '8114', Name: 'Piecer, leather dressing' },
  { Id: 19094, Code: '8114', Name: 'Plater, fertiliser mfr' },
  { Id: 19095, Code: '8114', Name: 'Plater, leather dressing' },
  { Id: 19096, Code: '8114', Name: 'Polisher, brush' },
  { Id: 19097, Code: '8114', Name: 'Polisher, leather dressing' },
  { Id: 19098, Code: '8114', Name: 'Potter, celluloid mfr' },
  { Id: 19099, Code: '8114', Name: 'Precipitator' },
  { Id: 19100, Code: '8114', Name: 'Premixer, CHEMICALS' },
  { Id: 19101, Code: '8114', Name: 'Preparer for dyeing' },
  { Id: 19102, Code: '8114', Name: 'Preparer, starch' },
  { Id: 19103, Code: '8114', Name: 'Presser, brush, carbon' },
  { Id: 19104, Code: '8114', Name: 'Presser, carbon' },
  { Id: 19105, Code: '8114', Name: 'Presser, chemical mfr' },
  { Id: 19106, Code: '8114', Name: 'Presser, leather dressing' },
  { Id: 19107, Code: '8114', Name: 'Presser, patent fuel mfr' },
  { Id: 19108, Code: '8114', Name: 'Presser, pencil, crayon mfr' },
  { Id: 19109, Code: '8114', Name: 'Presser, powder, chemical mfr' },
  { Id: 19110, Code: '8114', Name: 'Printer, nos, leather dressing' },
  { Id: 19111, Code: '8114', Name: 'Processor, pharmaceutical' },
  { Id: 19112, Code: '8114', Name: 'Producer, gas' },
  { Id: 19113, Code: '8114', Name: 'Proofer, clothing mfr' },
  { Id: 19114, Code: '8114', Name: 'Proofer, dry' },
  { Id: 19115, Code: '8114', Name: 'Proofer, rot' },
  { Id: 19116, Code: '8114', Name: 'Proofer, textile mfr' },
  { Id: 19117, Code: '8114', Name: 'Proofer, water, clothing mfr' },
  { Id: 19118, Code: '8114', Name: 'Proofer, water, textile mfr' },
  { Id: 19119, Code: '8114', Name: 'Proofer, yarn' },
  { Id: 19120, Code: '8114', Name: 'Puller, fellmongering' },
  { Id: 19121, Code: '8114', Name: 'Puller, fur dressing' },
  { Id: 19122, Code: '8114', Name: 'Puller, wool, fellmongering' },
  { Id: 19123, Code: '8114', Name: 'Pulveriser' },
  { Id: 19124, Code: '8114', Name: 'Pumpman, shale oil refining' },
  { Id: 19125, Code: '8114', Name: 'Purifier' },
  { Id: 19126, Code: '8114', Name: 'Putter-on, glue mfr' },
  { Id: 19127, Code: '8114', Name: 'Putter-on, photographic plate mfr' },
  { Id: 19128, Code: '8114', Name: 'Recoverer, acetone' },
  { Id: 19129, Code: '8114', Name: 'Recoverer, solvent' },
  { Id: 19130, Code: '8114', Name: 'Rectifier' },
  { Id: 19131, Code: '8114', Name: 'Re-dyer' },
  {
    Id: 19132,
    Code: '8114',
    Name: 'Reeler, textile mfr: textile bleaching, dyeing'
  },
  { Id: 19133, Code: '8114', Name: 'Refiner, candle mfr' },
  { Id: 19134, Code: '8114', Name: 'Refiner, chemical mfr' },
  { Id: 19135, Code: '8114', Name: 'Refiner, oil refining' },
  { Id: 19136, Code: '8114', Name: 'Refiner, paint' },
  { Id: 19137, Code: '8114', Name: 'Regenerator, oil, coal gas production' },
  { Id: 19138, Code: '8114', Name: 'Roller, belly' },
  { Id: 19139, Code: '8114', Name: 'Roller, bend, tannery' },
  { Id: 19140, Code: '8114', Name: 'Roller, leather' },
  { Id: 19141, Code: '8114', Name: 'Roller, leather dressing' },
  { Id: 19142, Code: '8114', Name: 'Salter, tannery' },
  { Id: 19143, Code: '8114', Name: 'Sawduster' },
  { Id: 19144, Code: '8114', Name: 'Scourer, grease' },
  { Id: 19145, Code: '8114', Name: 'Scourer, leather dressing' },
  { Id: 19146, Code: '8114', Name: 'Screener, chemical mfr' },
  { Id: 19147, Code: '8114', Name: 'Screener, coke, coke ovens' },
  { Id: 19148, Code: '8114', Name: 'Screener, paint' },
  { Id: 19149, Code: '8114', Name: 'Scrubberman, coke ovens' },
  { Id: 19150, Code: '8114', Name: 'Scudder' },
  {
    Id: 19151,
    Code: '8114',
    Name: 'Scutcher, textile mfr: textile bleaching, dyeing'
  },
  { Id: 19152, Code: '8114', Name: 'Sensitiser, film' },
  { Id: 19153, Code: '8114', Name: 'Setter, leather' },
  { Id: 19154, Code: '8114', Name: 'Setter, machine, tannery' },
  { Id: 19155, Code: '8114', Name: 'Setter, tannery' },
  { Id: 19156, Code: '8114', Name: 'Shaver, leather dressing' },
  { Id: 19157, Code: '8114', Name: 'Shearer, leather dressing' },
  { Id: 19158, Code: '8114', Name: 'Shedman, leather dressing' },
  { Id: 19159, Code: '8114', Name: 'Shredder, chemical mfr' },
  { Id: 19160, Code: '8114', Name: 'Siever, chemical mfr' },
  { Id: 19161, Code: '8114', Name: 'Sifter, chemical mfr' },
  { Id: 19162, Code: '8114', Name: 'Sizeman' },
  { Id: 19163, Code: '8114', Name: 'Skewerer, textile bleaching, dyeing' },
  { Id: 19164, Code: '8114', Name: 'Skinner, tannery' },
  { Id: 19165, Code: '8114', Name: 'Skiver, leather dressing' },
  { Id: 19166, Code: '8114', Name: 'Slicker, leather dressing' },
  { Id: 19167, Code: '8114', Name: 'Slipper, asbestos-cement mfr' },
  { Id: 19168, Code: '8114', Name: 'Slurryman' },
  { Id: 19169, Code: '8114', Name: 'Soaker, leather dressing' },
  { Id: 19170, Code: '8114', Name: 'Soaker, lime' },
  { Id: 19171, Code: '8114', Name: 'Softener, leather dressing' },
  { Id: 19172, Code: '8114', Name: 'Spinner, acetate' },
  { Id: 19173, Code: '8114', Name: 'Spinner, nylon' },
  { Id: 19174, Code: '8114', Name: 'Spinner, polyester' },
  { Id: 19175, Code: '8114', Name: 'Spinner, rayon' },
  { Id: 19176, Code: '8114', Name: 'Spinner, silk, artificial' },
  { Id: 19177, Code: '8114', Name: 'Spinner, textile mfr: man-made fibre mfr' },
  { Id: 19178, Code: '8114', Name: 'Spinner, viscose' },
  { Id: 19179, Code: '8114', Name: 'Splitter, hide' },
  { Id: 19180, Code: '8114', Name: 'Splitter, leather dressing' },
  { Id: 19181, Code: '8114', Name: 'Splitter, mica' },
  { Id: 19182, Code: '8114', Name: 'Splitter, skin' },
  { Id: 19183, Code: '8114', Name: 'Sprayer, leather' },
  { Id: 19184, Code: '8114', Name: 'Squeezer, textile bleaching, dyeing' },
  { Id: 19185, Code: '8114', Name: 'Staker, leather dressing' },
  { Id: 19186, Code: '8114', Name: 'Starcher, textile finishing' },
  {
    Id: 19187,
    Code: '8114',
    Name: 'Stockman, manufacturing: leather dressing'
  },
  { Id: 19188, Code: '8114', Name: 'Stocksman' },
  { Id: 19189, Code: '8114', Name: 'Stoker, coal gas, coke ovens' },
  { Id: 19190, Code: '8114', Name: 'Stoker, gas' },
  { Id: 19191, Code: '8114', Name: 'Stoker, plant, gas' },
  { Id: 19192, Code: '8114', Name: 'Stoker, retort, coal gas production' },
  { Id: 19193, Code: '8114', Name: 'Stover, leather dressing' },
  { Id: 19194, Code: '8114', Name: 'Stover, seasoning' },
  { Id: 19195, Code: '8114', Name: 'Strainer, chemical mfr' },
  { Id: 19196, Code: '8114', Name: 'Strainer, paint mfr' },
  { Id: 19197, Code: '8114', Name: 'Strainer, tannery' },
  { Id: 19198, Code: '8114', Name: 'Strainer, textile printing' },
  { Id: 19199, Code: '8114', Name: 'Stretcher, tannery' },
  { Id: 19200, Code: '8114', Name: 'Striker, colour' },
  { Id: 19201, Code: '8114', Name: 'Striper, fur dressing' },
  { Id: 19202, Code: '8114', Name: 'Stripper and setter, soap, detergent mfr' },
  { Id: 19203, Code: '8114', Name: 'Stripper, film' },
  { Id: 19204, Code: '8114', Name: 'Sueder' },
  { Id: 19205, Code: '8114', Name: 'Superintendent, gas supplier' },
  { Id: 19206, Code: '8114', Name: 'Superintendent, plant, refinery' },
  {
    Id: 19207,
    Code: '8114',
    Name: 'Superintendent, platform, coal tar distillers'
  },
  { Id: 19208, Code: '8114', Name: 'Superintendent, refinery' },
  { Id: 19209, Code: '8114', Name: 'Superintendent, works, gas' },
  { Id: 19210, Code: '8114', Name: 'Superintendent, works, gas supplier' },
  { Id: 19211, Code: '8114', Name: 'Supervisor, oil refining' },
  { Id: 19212, Code: '8114', Name: 'Supervisor, process, chemical mfr' },
  { Id: 19213, Code: '8114', Name: 'Supervisor, process, explosives mfr' },
  { Id: 19214, Code: '8114', Name: 'Supervisor, process, oil refining' },
  { Id: 19215, Code: '8114', Name: 'Suspender, butt' },
  { Id: 19216, Code: '8114', Name: 'Tanner' },
  { Id: 19217, Code: '8114', Name: 'Tapper, carbide mfr' },
  { Id: 19218, Code: '8114', Name: 'Technician, plant, chemical mfr' },
  { Id: 19219, Code: '8114', Name: 'Technician, process, chemical mfr' },
  { Id: 19220, Code: '8114', Name: 'Technician, room, control, gas supplier' },
  { Id: 19221, Code: '8114', Name: 'Tenter, jig' },
  { Id: 19222, Code: '8114', Name: 'Thinner, varnish mfr' },
  { Id: 19223, Code: '8114', Name: 'Tinter, chemical mfr' },
  { Id: 19224, Code: '8114', Name: 'Tinter, enamel, enamel mfr' },
  { Id: 19225, Code: '8114', Name: 'Tinter, paint' },
  { Id: 19226, Code: '8114', Name: 'Toggler, leather dressing' },
  { Id: 19227, Code: '8114', Name: 'Towerman' },
  { Id: 19228, Code: '8114', Name: 'Trimmer, asbestos' },
  { Id: 19229, Code: '8114', Name: 'Trimmer, leather dressing' },
  { Id: 19230, Code: '8114', Name: 'Trimmer, sponge' },
  { Id: 19231, Code: '8114', Name: 'Tubber, leather dressing' },
  { Id: 19232, Code: '8114', Name: 'Turner, leather dressing' },
  { Id: 19233, Code: '8114', Name: 'Waller, salt mfr' },
  { Id: 19234, Code: '8114', Name: 'Washer, benzol' },
  { Id: 19235, Code: '8114', Name: 'Washer, benzole' },
  { Id: 19236, Code: '8114', Name: 'Washer, cake, man-made fibre mfr' },
  { Id: 19237, Code: '8114', Name: 'Washer, chemical mfr' },
  { Id: 19238, Code: '8114', Name: 'Washer, coke' },
  { Id: 19239, Code: '8114', Name: 'Washer, fellmongering' },
  { Id: 19240, Code: '8114', Name: 'Washer, skin, fellmongering' },
  { Id: 19241, Code: '8114', Name: 'Washer, tannery' },
  { Id: 19242, Code: '8114', Name: 'Watcher, calciner' },
  { Id: 19243, Code: '8114', Name: 'Waxer, leather dressing' },
  { Id: 19244, Code: '8114', Name: 'Wheeler, leather dressing' },
  { Id: 19245, Code: '8114', Name: 'Wheeler, wet, leather dressing' },
  { Id: 19246, Code: '8114', Name: 'Whizzerman, chemical mfr' },
  { Id: 19247, Code: '8114', Name: 'Worker, acid' },
  { Id: 19248, Code: '8114', Name: 'Worker, autoclave, aluminium refining' },
  { Id: 19249, Code: '8114', Name: 'Worker, bitumen' },
  { Id: 19250, Code: '8114', Name: 'Worker, bleach, textile mfr' },
  { Id: 19251, Code: '8114', Name: 'Worker, carbide' },
  { Id: 19252, Code: '8114', Name: 'Worker, chemical' },
  { Id: 19253, Code: '8114', Name: 'Worker, chlorine, electrolytic' },
  { Id: 19254, Code: '8114', Name: 'Worker, coke, coke ovens' },
  { Id: 19255, Code: '8114', Name: 'Worker, colour, paint mfr' },
  { Id: 19256, Code: '8114', Name: 'Worker, cordite' },
  { Id: 19257, Code: '8114', Name: 'Worker, digester' },
  { Id: 19258, Code: '8114', Name: 'Worker, dye, natural' },
  { Id: 19259, Code: '8114', Name: 'Worker, explosive' },
  { Id: 19260, Code: '8114', Name: 'Worker, extract, tannery' },
  {
    Id: 19261,
    Code: '8114',
    Name: 'Worker, filtration, red, aluminium refining'
  },
  { Id: 19262, Code: '8114', Name: 'Worker, fuseroom, chemical mfr' },
  { Id: 19263, Code: '8114', Name: 'Worker, galenical' },
  { Id: 19264, Code: '8114', Name: 'Worker, gas, chlorine' },
  { Id: 19265, Code: '8114', Name: 'Worker, gas, INDUSTRIAL GAS' },
  { Id: 19266, Code: '8114', Name: 'Worker, glycerine' },
  { Id: 19267, Code: '8114', Name: 'Worker, house, dye, textile mfr' },
  {
    Id: 19268,
    Code: '8114',
    Name: 'Worker, house, retort, coal gas, coke ovens'
  },
  { Id: 19269, Code: '8114', Name: 'Worker, installation, oil refining' },
  { Id: 19270, Code: '8114', Name: 'Worker, leather, leather dressing' },
  { Id: 19271, Code: '8114', Name: 'Worker, lime' },
  { Id: 19272, Code: '8114', Name: 'Worker, magazine, EXPLOSIVES' },
  { Id: 19273, Code: '8114', Name: 'Worker, nylon, nylon mfr' },
  { Id: 19274, Code: '8114', Name: 'Worker, oven, coke' },
  { Id: 19275, Code: '8114', Name: 'Worker, paint, paint mfr' },
  { Id: 19276, Code: '8114', Name: 'Worker, pharmaceutical' },
  { Id: 19277, Code: '8114', Name: 'Worker, plant, chemical mfr' },
  { Id: 19278, Code: '8114', Name: 'Worker, plant, coke ovens' },
  { Id: 19279, Code: '8114', Name: 'Worker, plant, gas' },
  {
    Id: 19280,
    Code: '8114',
    Name: 'Worker, process, adhesive and sealants mfr'
  },
  {
    Id: 19281,
    Code: '8114',
    Name: 'Worker, process, asbestos-cement goods mfr'
  },
  { Id: 19282, Code: '8114', Name: 'Worker, process, Atomic Energy Authority' },
  { Id: 19283, Code: '8114', Name: 'Worker, process, cellulose film mfr' },
  { Id: 19284, Code: '8114', Name: 'Worker, process, cement mfr' },
  { Id: 19285, Code: '8114', Name: 'Worker, process, chemical mfr' },
  { Id: 19286, Code: '8114', Name: 'Worker, process, coal gas, coke ovens' },
  { Id: 19287, Code: '8114', Name: 'Worker, process, explosives mfr' },
  { Id: 19288, Code: '8114', Name: 'Worker, process, fat recovery' },
  { Id: 19289, Code: '8114', Name: 'Worker, process, leather mfr' },
  { Id: 19290, Code: '8114', Name: 'Worker, process, lubricating oil mfr' },
  { Id: 19291, Code: '8114', Name: 'Worker, process, nuclear fuel production' },
  { Id: 19292, Code: '8114', Name: 'Worker, process, oil refining' },
  { Id: 19293, Code: '8114', Name: 'Worker, process, ordnance factory' },
  { Id: 19294, Code: '8114', Name: 'Worker, process, paint mfr' },
  { Id: 19295, Code: '8114', Name: 'Worker, process, patent fuel mfr' },
  { Id: 19296, Code: '8114', Name: 'Worker, process, pharmaceutical mfr' },
  { Id: 19297, Code: '8114', Name: 'Worker, process, photographic film mfr' },
  { Id: 19298, Code: '8114', Name: 'Worker, process, plastics mfr' },
  { Id: 19299, Code: '8114', Name: 'Worker, process, polish mfr' },
  { Id: 19300, Code: '8114', Name: 'Worker, process, printing ink mfr' },
  { Id: 19301, Code: '8114', Name: 'Worker, process, soap, detergent mfr' },
  { Id: 19302, Code: '8114', Name: 'Worker, process, tannery' },
  {
    Id: 19303,
    Code: '8114',
    Name: 'Worker, process, textile mfr: man-made fibre mfr'
  },
  { Id: 19304, Code: '8114', Name: 'Worker, process, toilet preparations mfr' },
  { Id: 19305, Code: '8114', Name: 'Worker, products, medicinal' },
  { Id: 19306, Code: '8114', Name: 'Worker, rayon' },
  { Id: 19307, Code: '8114', Name: 'Worker, refinery, oil' },
  { Id: 19308, Code: '8114', Name: 'Worker, salt' },
  { Id: 19309, Code: '8114', Name: 'Worker, shed, tannery' },
  { Id: 19310, Code: '8114', Name: 'Worker, sheepskin' },
  { Id: 19311, Code: '8114', Name: 'Worker, soap' },
  { Id: 19312, Code: '8114', Name: 'Worker, tannery' },
  { Id: 19313, Code: '8114', Name: 'Worker, whiting' },
  { Id: 19314, Code: '8114', Name: 'Worker, yard, lime' },
  { Id: 19315, Code: '8114', Name: 'Worker, yard, tan' },
  { Id: 19316, Code: '8115', Name: 'Assistant, extrusion, RUBBER' },
  { Id: 19317, Code: '8115', Name: 'Attendant, calender, RUBBER' },
  { Id: 19318, Code: '8115', Name: 'Attendant, lathe, rubber' },
  { Id: 19319, Code: '8115', Name: 'Bander, rubber tyre mfr' },
  { Id: 19320, Code: '8115', Name: 'Braider, rubber hose mfr' },
  { Id: 19321, Code: '8115', Name: 'Builder, bead, TYRE' },
  { Id: 19322, Code: '8115', Name: 'Builder, belt, rubber goods mfr' },
  { Id: 19323, Code: '8115', Name: 'Builder, hose' },
  { Id: 19324, Code: '8115', Name: 'Builder, roller' },
  { Id: 19325, Code: '8115', Name: 'Builder, rubber' },
  { Id: 19326, Code: '8115', Name: 'Builder, tank, RUBBER LINING' },
  { Id: 19327, Code: '8115', Name: 'Builder, tread' },
  { Id: 19328, Code: '8115', Name: 'Builder, tyre' },
  { Id: 19329, Code: '8115', Name: 'Builder, wheel, RUBBER' },
  { Id: 19330, Code: '8115', Name: 'Burner, mould, rubber tyre mfr' },
  { Id: 19331, Code: '8115', Name: 'Calenderer, rubber' },
  { Id: 19332, Code: '8115', Name: 'Calenderer, rubber mfr' },
  { Id: 19333, Code: '8115', Name: 'Calenderman, rubber mfr' },
  { Id: 19334, Code: '8115', Name: 'Caster, cold, RUBBER' },
  { Id: 19335, Code: '8115', Name: 'Caster, footwear mfr' },
  { Id: 19336, Code: '8115', Name: "Caster, roller, PRINTER'S" },
  { Id: 19337, Code: '8115', Name: 'Cellarman, rubber mfr' },
  { Id: 19338, Code: '8115', Name: 'Changer, mould, rubber mfr' },
  { Id: 19339, Code: '8115', Name: 'Coiler, rubber tubing mfr' },
  { Id: 19340, Code: '8115', Name: 'Compounder, rubber mfr' },
  { Id: 19341, Code: '8115', Name: 'Coverer, roller, printing' },
  { Id: 19342, Code: '8115', Name: 'Coverer, rubber goods mfr' },
  { Id: 19343, Code: '8115', Name: 'Coverer, rubber, cable mfr' },
  { Id: 19344, Code: '8115', Name: 'Coverer, rubber, textile mfr' },
  { Id: 19345, Code: '8115', Name: 'Coverer, wheel, RUBBER' },
  { Id: 19346, Code: '8115', Name: 'Coverer, wire, insulated wire, cable mfr' },
  { Id: 19347, Code: '8115', Name: 'Curer, RUBBER' },
  { Id: 19348, Code: '8115', Name: 'Cutter, band, rubber' },
  { Id: 19349, Code: '8115', Name: 'Cutter, collar, RUBBER' },
  { Id: 19350, Code: '8115', Name: 'Cutter, disc, rubber mfr' },
  { Id: 19351, Code: '8115', Name: 'Cutter, rubber' },
  { Id: 19352, Code: '8115', Name: 'Cutter, rubber goods mfr' },
  { Id: 19353, Code: '8115', Name: 'Cutter, tread' },
  { Id: 19354, Code: '8115', Name: 'Cutter, tyre' },
  { Id: 19355, Code: '8115', Name: 'Dipper, glove' },
  { Id: 19356, Code: '8115', Name: 'Dipper, rubber' },
  { Id: 19357, Code: '8115', Name: 'Dipper, rubber mfr' },
  { Id: 19358, Code: '8115', Name: 'Extruder, RUBBER' },
  { Id: 19359, Code: '8115', Name: 'Finisher, ebonite' },
  { Id: 19360, Code: '8115', Name: 'Finisher, rubber goods mfr' },
  { Id: 19361, Code: '8115', Name: 'Finisher, tyre' },
  { Id: 19362, Code: '8115', Name: 'Fitter, bead, tyre' },
  { Id: 19363, Code: '8115', Name: 'Fitter, tread, tyre' },
  { Id: 19364, Code: '8115', Name: 'Flipper, bead' },
  { Id: 19365, Code: '8115', Name: 'Forcer, rubber' },
  { Id: 19366, Code: '8115', Name: 'Forcer, rubber goods mfr' },
  { Id: 19367, Code: '8115', Name: 'Foreman, rubber goods mfr' },
  { Id: 19368, Code: '8115', Name: 'Grinder, ebonite' },
  { Id: 19369, Code: '8115', Name: 'Grinder, rubber' },
  { Id: 19370, Code: '8115', Name: 'Hand, bench, rubber flooring mfr' },
  { Id: 19371, Code: '8115', Name: 'Hand, calender, rubber mfr' },
  { Id: 19372, Code: '8115', Name: 'Hand, leading, rubber goods mfr' },
  { Id: 19373, Code: '8115', Name: 'Hand, mechanical, rubber goods mfr' },
  { Id: 19374, Code: '8115', Name: 'Hand, mill, rubber' },
  { Id: 19375, Code: '8115', Name: 'Hand, mill, rubber goods mfr' },
  { Id: 19376, Code: '8115', Name: 'Hand, surgical, rubber goods mfr' },
  { Id: 19377, Code: '8115', Name: 'Hand, tube' },
  { Id: 19378, Code: '8115', Name: 'Heater, rubber, tyre mfr' },
  { Id: 19379, Code: '8115', Name: 'Joiner, rubber, CYCLE TUBES' },
  { Id: 19380, Code: '8115', Name: 'Latexer' },
  { Id: 19381, Code: '8115', Name: 'Liner, pipe, RUBBER LINING' },
  { Id: 19382, Code: '8115', Name: 'Liner, plant, RUBBER LINING' },
  { Id: 19383, Code: '8115', Name: 'Liner, RUBBER' },
  { Id: 19384, Code: '8115', Name: 'Liner, tank, RUBBER LINING' },
  { Id: 19385, Code: '8115', Name: 'Machinist, bias, rubber tyre mfr' },
  { Id: 19386, Code: '8115', Name: 'Machinist, covering, rubber' },
  { Id: 19387, Code: '8115', Name: 'Machinist, curing, RUBBER' },
  { Id: 19388, Code: '8115', Name: 'Machinist, cutting, rubber' },
  { Id: 19389, Code: '8115', Name: 'Machinist, cutting, tyre mfr' },
  { Id: 19390, Code: '8115', Name: 'Machinist, extruding, rubber goods mfr' },
  { Id: 19391, Code: '8115', Name: 'Machinist, forcing' },
  { Id: 19392, Code: '8115', Name: 'Machinist, guillotine, rubber goods mfr' },
  { Id: 19393, Code: '8115', Name: 'Machinist, mixing, rubber mfr' },
  { Id: 19394, Code: '8115', Name: 'Machinist, moulding, rubber goods mfr' },
  { Id: 19395, Code: '8115', Name: 'Machinist, press, rubber goods mfr' },
  { Id: 19396, Code: '8115', Name: 'Machinist, rubber goods mfr' },
  { Id: 19397, Code: '8115', Name: 'Machinist, spewing' },
  { Id: 19398, Code: '8115', Name: 'Machinist, wiring, rubber tyre mfr' },
  { Id: 19399, Code: '8115', Name: 'Maker, bag, air' },
  { Id: 19400, Code: '8115', Name: 'Maker, ball, golf' },
  { Id: 19401, Code: '8115', Name: 'Maker, ball, rubber goods mfr' },
  { Id: 19402, Code: '8115', Name: 'Maker, bed, air' },
  { Id: 19403, Code: '8115', Name: 'Maker, belt, conveyor, rubber goods mfr' },
  { Id: 19404, Code: '8115', Name: 'Maker, belt, RUBBER' },
  { Id: 19405, Code: '8115', Name: 'Maker, belt, vee' },
  { Id: 19406, Code: '8115', Name: 'Maker, belting, RUBBER' },
  { Id: 19407, Code: '8115', Name: 'Maker, block, brake' },
  { Id: 19408, Code: '8115', Name: 'Maker, block, rubber goods mfr' },
  { Id: 19409, Code: '8115', Name: 'Maker, bottle, RUBBER' },
  { Id: 19410, Code: '8115', Name: 'Maker, case, tyre mfr' },
  { Id: 19411, Code: '8115', Name: 'Maker, catheter, RUBBER' },
  { Id: 19412, Code: '8115', Name: 'Maker, cover, waterproof' },
  { Id: 19413, Code: '8115', Name: 'Maker, cushion, air' },
  { Id: 19414, Code: '8115', Name: 'Maker, cushion, table, billiard' },
  { Id: 19415, Code: '8115', Name: 'Maker, glove, RUBBER' },
  { Id: 19416, Code: '8115', Name: 'Maker, glove, SURGICAL' },
  { Id: 19417, Code: '8115', Name: 'Maker, heel, RUBBER' },
  { Id: 19418, Code: '8115', Name: 'Maker, hose' },
  { Id: 19419, Code: '8115', Name: 'Maker, mat, rubber' },
  { Id: 19420, Code: '8115', Name: 'Maker, mattress, rubber' },
  { Id: 19421, Code: '8115', Name: 'Maker, packing, rubber' },
  { Id: 19422, Code: '8115', Name: 'Maker, pipe, flexible' },
  { Id: 19423, Code: '8115', Name: 'Maker, pipe, rubber goods mfr' },
  { Id: 19424, Code: '8115', Name: 'Maker, pocket, tyre mfr' },
  { Id: 19425, Code: '8115', Name: 'Maker, ring, asbestos' },
  { Id: 19426, Code: '8115', Name: "Maker, roller, composition, printer's" },
  { Id: 19427, Code: '8115', Name: "Maker, roller, printer's" },
  { Id: 19428, Code: '8115', Name: 'Maker, roller, rubber' },
  { Id: 19429, Code: '8115', Name: 'Maker, rubber' },
  { Id: 19430, Code: '8115', Name: 'Maker, solution, RUBBER' },
  { Id: 19431, Code: '8115', Name: 'Maker, stamp, endorsing, RUBBER' },
  { Id: 19432, Code: '8115', Name: 'Maker, tile, RUBBER' },
  { Id: 19433, Code: '8115', Name: 'Maker, tread, rubber' },
  { Id: 19434, Code: '8115', Name: 'Maker, tube, flexible' },
  { Id: 19435, Code: '8115', Name: 'Maker, tube, RUBBER' },
  { Id: 19436, Code: '8115', Name: 'Maker, tubing, RUBBER' },
  { Id: 19437, Code: '8115', Name: 'Maker, tyre' },
  { Id: 19438, Code: '8115', Name: 'Maker, washer, RUBBER' },
  { Id: 19439, Code: '8115', Name: 'Man, devulcaniser, rubber reclamation' },
  { Id: 19440, Code: '8115', Name: 'Man, mill, rubber' },
  { Id: 19441, Code: '8115', Name: 'Man, mill, rubber goods mfr' },
  { Id: 19442, Code: '8115', Name: 'Man, table, rubber goods mfr' },
  { Id: 19443, Code: '8115', Name: 'Manipulator, tube, RUBBER' },
  { Id: 19444, Code: '8115', Name: 'Manufacturer, rubber goods mfr' },
  { Id: 19445, Code: '8115', Name: 'Miller, rubber' },
  { Id: 19446, Code: '8115', Name: 'Mixer, banbury' },
  { Id: 19447, Code: '8115', Name: 'Mixer, dough, rubber mfr' },
  { Id: 19448, Code: '8115', Name: 'Mixer, dry, rubber goods mfr' },
  { Id: 19449, Code: '8115', Name: 'Mixer, latex' },
  { Id: 19450, Code: '8115', Name: 'Mixer, rubber' },
  { Id: 19451, Code: '8115', Name: 'Mixer, rubber mfr' },
  { Id: 19452, Code: '8115', Name: 'Moulder, bottle cap mfr' },
  { Id: 19453, Code: '8115', Name: 'Moulder, ebonite' },
  { Id: 19454, Code: '8115', Name: 'Moulder, injection, rubber goods mfr' },
  { Id: 19455, Code: '8115', Name: 'Moulder, press, RUBBER' },
  { Id: 19456, Code: '8115', Name: 'Moulder, rubber goods mfr' },
  { Id: 19457, Code: '8115', Name: 'Moulder, rubber, MOULDS' },
  { Id: 19458, Code: '8115', Name: 'Moulder, stamp' },
  { Id: 19459, Code: '8115', Name: 'Moulder, tube, rubber' },
  { Id: 19460, Code: '8115', Name: 'Moulder, tyre' },
  { Id: 19461, Code: '8115', Name: 'Operator, banbury, rubber mfr' },
  { Id: 19462, Code: '8115', Name: 'Operator, calender, rubber mfr' },
  { Id: 19463, Code: '8115', Name: 'Operator, castings, rubber goods mfr' },
  { Id: 19464, Code: '8115', Name: 'Operator, extruder, rubber goods mfr' },
  { Id: 19465, Code: '8115', Name: 'Operator, extrusion, rubber goods mfr' },
  { Id: 19466, Code: '8115', Name: 'Operator, mill, rubber goods mfr' },
  { Id: 19467, Code: '8115', Name: 'Operator, plant, rubber goods mfr' },
  { Id: 19468, Code: '8115', Name: 'Operator, process, rubber mfr' },
  { Id: 19469, Code: '8115', Name: 'Presser, belt' },
  { Id: 19470, Code: '8115', Name: 'Presser, heel, rubber goods mfr' },
  { Id: 19471, Code: '8115', Name: 'Presser, hot, rubber goods mfr' },
  { Id: 19472, Code: '8115', Name: 'Presser, jobbing' },
  { Id: 19473, Code: '8115', Name: 'Presser, rubber' },
  { Id: 19474, Code: '8115', Name: 'Presser, rubber goods mfr' },
  { Id: 19475, Code: '8115', Name: 'Presser, steam, RUBBER' },
  { Id: 19476, Code: '8115', Name: 'Rasper, REMOULD TYRES' },
  { Id: 19477, Code: '8115', Name: 'Reclaimer, RUBBER' },
  { Id: 19478, Code: '8115', Name: 'Recoverer, rubber reclamation' },
  { Id: 19479, Code: '8115', Name: 'Refiner, rubber reclamation' },
  { Id: 19480, Code: '8115', Name: 'Remoulder, rubber goods mfr' },
  { Id: 19481, Code: '8115', Name: 'Renewer, tread' },
  { Id: 19482, Code: '8115', Name: 'Repairer, tyre' },
  { Id: 19483, Code: '8115', Name: 'Restorer, tyre' },
  { Id: 19484, Code: '8115', Name: 'Retreader, tyre' },
  { Id: 19485, Code: '8115', Name: 'Roller, rubber goods mfr' },
  { Id: 19486, Code: '8115', Name: 'Roller, tube, RUBBER' },
  { Id: 19487, Code: '8115', Name: 'Rubberer, tyre' },
  { Id: 19488, Code: '8115', Name: 'Setter, machine, moulding, RUBBER' },
  { Id: 19489, Code: '8115', Name: 'Sheeter, rubber goods mfr' },
  { Id: 19490, Code: '8115', Name: 'Shrinker, rubber goods mfr' },
  { Id: 19491, Code: '8115', Name: 'Splicer, tyre mfr' },
  { Id: 19492, Code: '8115', Name: 'Splitter, rubber reclamation' },
  { Id: 19493, Code: '8115', Name: 'Sprayer, tyre' },
  { Id: 19494, Code: '8115', Name: 'Spreader, rubber' },
  { Id: 19495, Code: '8115', Name: 'Spreader, rubber mfr' },
  { Id: 19496, Code: '8115', Name: 'Stamper, rubber, rubber goods mfr' },
  { Id: 19497, Code: '8115', Name: 'Stripper, rubber' },
  { Id: 19498, Code: '8115', Name: 'Stripper, tyre' },
  { Id: 19499, Code: '8115', Name: 'Trimmer, block, rubber goods mfr' },
  { Id: 19500, Code: '8115', Name: 'Trimmer, pad, heel' },
  { Id: 19501, Code: '8115', Name: 'Trimmer, rubber' },
  { Id: 19502, Code: '8115', Name: 'Trimmer, rubber mfr' },
  { Id: 19503, Code: '8115', Name: 'Trimmer, tyre, solid' },
  { Id: 19504, Code: '8115', Name: 'Turner, ebonite' },
  { Id: 19505, Code: '8115', Name: 'Turner, roller, RUBBER' },
  { Id: 19506, Code: '8115', Name: 'Turner, rubber' },
  { Id: 19507, Code: '8115', Name: 'Turner, rubber goods mfr' },
  { Id: 19508, Code: '8115', Name: 'Turner, vulcanite' },
  { Id: 19509, Code: '8115', Name: 'Veneerer, tyre' },
  { Id: 19510, Code: '8115', Name: 'Vulcanizer' },
  { Id: 19511, Code: '8115', Name: 'Washer, rubber' },
  { Id: 19512, Code: '8115', Name: 'Waxer, block, thread' },
  { Id: 19513, Code: '8115', Name: 'Winder, ball, golf' },
  { Id: 19514, Code: '8115', Name: 'Winder, belt, rubber' },
  { Id: 19515, Code: '8115', Name: 'Winder, core, golf ball mfr' },
  { Id: 19516, Code: '8115', Name: 'Wirer, tyre' },
  { Id: 19517, Code: '8115', Name: 'Worker, ball, golf' },
  { Id: 19518, Code: '8115', Name: 'Worker, ebonite' },
  { Id: 19519, Code: '8115', Name: 'Worker, factory, rubber mfr' },
  { Id: 19520, Code: '8115', Name: 'Worker, fibre, rubber goods mfr' },
  { Id: 19521, Code: '8115', Name: 'Worker, goods, rubber' },
  { Id: 19522, Code: '8115', Name: 'Worker, mill, rubber' },
  { Id: 19523, Code: '8115', Name: 'Worker, process, rubber goods mfr' },
  { Id: 19524, Code: '8115', Name: 'Worker, process, rubber reclamation' },
  { Id: 19525, Code: '8115', Name: 'Worker, rubber' },
  { Id: 19526, Code: '8115', Name: 'Worker, tyre' },
  { Id: 19527, Code: '8115', Name: 'Wrapper, bead, tyre mfr' },
  { Id: 19528, Code: '8115', Name: 'Wrapper, inner tube mfr' },
  { Id: 19529, Code: '8115', Name: 'Wrapper, tube, rubber goods mfr' },
  { Id: 19530, Code: '8116', Name: 'Assistant, extrusion, PLASTICS' },
  { Id: 19531, Code: '8116', Name: 'Blender, plastics goods mfr' },
  { Id: 19532, Code: '8116', Name: 'Blower, plastics goods mfr' },
  { Id: 19533, Code: '8116', Name: 'Blower, steam' },
  { Id: 19534, Code: '8116', Name: 'Clatter, CELLULOID' },
  { Id: 19535, Code: '8116', Name: 'Cleaner, button' },
  { Id: 19536, Code: '8116', Name: 'Colourist, plastics mfr' },
  { Id: 19537, Code: '8116', Name: 'Compounder, plastics goods mfr' },
  { Id: 19538, Code: '8116', Name: 'Converter, plastics goods mfr' },
  { Id: 19539, Code: '8116', Name: 'Cutter, button' },
  { Id: 19540, Code: '8116', Name: 'Cutter, plastics' },
  { Id: 19541, Code: '8116', Name: 'Cutter, plastics goods mfr' },
  { Id: 19542, Code: '8116', Name: 'Driller, button' },
  { Id: 19543, Code: '8116', Name: 'Driller, plastics goods mfr' },
  { Id: 19544, Code: '8116', Name: 'Driver, extruding' },
  { Id: 19545, Code: '8116', Name: 'Dyer, PLASTICS' },
  { Id: 19546, Code: '8116', Name: 'Embosser, plastics goods mfr' },
  { Id: 19547, Code: '8116', Name: 'Engineer, extrusion, plastics goods mfr' },
  { Id: 19548, Code: '8116', Name: 'Engineer, plastics' },
  { Id: 19549, Code: '8116', Name: 'Extruder, cable, plastics goods mfr' },
  { Id: 19550, Code: '8116', Name: 'Extruder, PLASTICS' },
  { Id: 19551, Code: '8116', Name: 'Fabricator, foam' },
  { Id: 19552, Code: '8116', Name: 'Fabricator, plastics mfr' },
  { Id: 19553, Code: '8116', Name: 'Felter, plastics mfr' },
  { Id: 19554, Code: '8116', Name: 'Filer, plastics' },
  { Id: 19555, Code: '8116', Name: 'Filer, plastics mfr' },
  { Id: 19556, Code: '8116', Name: 'Filler, hopper, plastics mfr' },
  { Id: 19557, Code: '8116', Name: 'Finisher, plastics' },
  { Id: 19558, Code: '8116', Name: 'Finisher, plastics goods mfr' },
  { Id: 19559, Code: '8116', Name: 'Fitter, composite, vehicle mfr' },
  { Id: 19560, Code: '8116', Name: 'Forcer, plastics goods mfr' },
  { Id: 19561, Code: '8116', Name: 'Foreman, plastics goods mfr' },
  { Id: 19562, Code: '8116', Name: 'Foreman, process, plastics mfr' },
  { Id: 19563, Code: '8116', Name: 'Foreman, shop, press, plastics goods mfr' },
  { Id: 19564, Code: '8116', Name: 'Former, plastics mfr' },
  { Id: 19565, Code: '8116', Name: 'Grailer, celluloid goods mfr' },
  { Id: 19566, Code: '8116', Name: 'Grinder, plastics goods mfr' },
  { Id: 19567, Code: '8116', Name: 'Hand, bench, plastics goods mfr' },
  { Id: 19568, Code: '8116', Name: 'Hand, calender, plastics goods mfr' },
  { Id: 19569, Code: '8116', Name: 'Hand, flasher, plastics mfr' },
  { Id: 19570, Code: '8116', Name: 'Injector, mould, plastics goods mfr' },
  { Id: 19571, Code: '8116', Name: 'Laminator, composite' },
  { Id: 19572, Code: '8116', Name: 'Laminator, fibreglass' },
  { Id: 19573, Code: '8116', Name: 'Laminator, grp' },
  { Id: 19574, Code: '8116', Name: 'Laminator, plastic' },
  { Id: 19575, Code: '8116', Name: 'Laminator, plastics mfr' },
  { Id: 19576, Code: '8116', Name: 'Liner, tank, GLASS FIBRE' },
  { Id: 19577, Code: '8116', Name: 'Machinist, buffing, plastics goods mfr' },
  { Id: 19578, Code: '8116', Name: 'Machinist, calender, plastics goods mfr' },
  { Id: 19579, Code: '8116', Name: 'Machinist, coating, plastics goods mfr' },
  { Id: 19580, Code: '8116', Name: 'Machinist, cutting, plastics' },
  { Id: 19581, Code: '8116', Name: 'Machinist, extruding, plastics goods mfr' },
  { Id: 19582, Code: '8116', Name: 'Machinist, foam' },
  {
    Id: 19583,
    Code: '8116',
    Name: 'Machinist, guillotine, plastics goods mfr'
  },
  { Id: 19584, Code: '8116', Name: 'Machinist, moulding, plastics goods mfr' },
  { Id: 19585, Code: '8116', Name: 'Machinist, plastic, cable mfr' },
  { Id: 19586, Code: '8116', Name: 'Machinist, plastics' },
  { Id: 19587, Code: '8116', Name: 'Machinist, plastics goods mfr' },
  { Id: 19588, Code: '8116', Name: 'Machinist, press, plastics goods mfr' },
  { Id: 19589, Code: '8116', Name: 'Machinist, roller, plastics goods mfr' },
  { Id: 19590, Code: '8116', Name: 'Machinist, slicing, CELLULOID' },
  { Id: 19591, Code: '8116', Name: 'Machinist, stamping, plastics goods mfr' },
  { Id: 19592, Code: '8116', Name: 'Maker, Bakelite' },
  { Id: 19593, Code: '8116', Name: 'Maker, ball, billiard' },
  { Id: 19594, Code: '8116', Name: 'Maker, ball, celluloid goods mfr' },
  {
    Id: 19595,
    Code: '8116',
    Name: 'Maker, belt, conveyor, plastics goods mfr'
  },
  { Id: 19596, Code: '8116', Name: 'Maker, bottle, plastic' },
  { Id: 19597, Code: '8116', Name: 'Maker, bottle, plastics mfr' },
  { Id: 19598, Code: '8116', Name: 'Maker, bucket, PLASTICS' },
  { Id: 19599, Code: '8116', Name: 'Maker, case, accumulator mfr' },
  { Id: 19600, Code: '8116', Name: 'Maker, case, battery' },
  { Id: 19601, Code: '8116', Name: 'Maker, comb' },
  { Id: 19602, Code: '8116', Name: 'Maker, eye, artificial, PLASTICS' },
  { Id: 19603, Code: '8116', Name: 'Maker, frame, optical' },
  { Id: 19604, Code: '8116', Name: 'Maker, frame, plastics goods mfr' },
  { Id: 19605, Code: '8116', Name: 'Maker, frame, spectacle' },
  { Id: 19606, Code: '8116', Name: 'Maker, plate, number' },
  { Id: 19607, Code: '8116', Name: 'Maker, sink, PLASTICS' },
  { Id: 19608, Code: '8116', Name: 'Maker, spectacle' },
  { Id: 19609, Code: '8116', Name: 'Maker, tile, PLASTICS' },
  { Id: 19610, Code: '8116', Name: 'Maker, tray, PLASTICS' },
  { Id: 19611, Code: '8116', Name: 'Maker, tube, PLASTICS' },
  { Id: 19612, Code: '8116', Name: 'Man, die, plastics goods mfr' },
  { Id: 19613, Code: '8116', Name: 'Man, mill, plastics goods mfr' },
  { Id: 19614, Code: '8116', Name: 'Manipulator, plastics goods mfr' },
  { Id: 19615, Code: '8116', Name: 'Manufacturer, plastics goods mfr' },
  { Id: 19616, Code: '8116', Name: 'Matcher, colour, plastics goods mfr' },
  { Id: 19617, Code: '8116', Name: 'Miller, plastics goods mfr' },
  { Id: 19618, Code: '8116', Name: 'Mixer, colour, plastics goods mfr' },
  { Id: 19619, Code: '8116', Name: 'Mixer, dough, plastics goods mfr' },
  { Id: 19620, Code: '8116', Name: 'Mixer, dry, plastics goods mfr' },
  { Id: 19621, Code: '8116', Name: 'Mixer, plastic' },
  { Id: 19622, Code: '8116', Name: 'Mixer, plastics' },
  { Id: 19623, Code: '8116', Name: 'Mixer, plastics goods mfr' },
  { Id: 19624, Code: '8116', Name: 'Moulder, aloe, PLASTICS' },
  { Id: 19625, Code: '8116', Name: 'Moulder, Bakelite' },
  { Id: 19626, Code: '8116', Name: 'Moulder, compression, plastics goods mfr' },
  { Id: 19627, Code: '8116', Name: 'Moulder, fibreglass' },
  { Id: 19628, Code: '8116', Name: 'Moulder, injection' },
  { Id: 19629, Code: '8116', Name: 'Moulder, injection, plastics goods mfr' },
  { Id: 19630, Code: '8116', Name: 'Moulder, plastic' },
  { Id: 19631, Code: '8116', Name: 'Moulder, plastics goods mfr' },
  { Id: 19632, Code: '8116', Name: 'Moulder, press, PLASTICS' },
  { Id: 19633, Code: '8116', Name: 'Moulder, spindle, PLASTICS' },
  { Id: 19634, Code: '8116', Name: 'Moulder, tube' },
  { Id: 19635, Code: '8116', Name: 'Operator, calender, plastics mfr' },
  {
    Id: 19636,
    Code: '8116',
    Name: 'Operator, coatings, plastic, plastics goods mfr'
  },
  {
    Id: 19637,
    Code: '8116',
    Name: 'Operator, composite, aircraft component mfr'
  },
  { Id: 19638, Code: '8116', Name: 'Operator, extruder, plastics mfr' },
  { Id: 19639, Code: '8116', Name: 'Operator, extrusion, plastics mfr' },
  { Id: 19640, Code: '8116', Name: 'Operator, guillotine, plastics goods mfr' },
  { Id: 19641, Code: '8116', Name: 'Operator, mill, plastics goods mfr' },
  { Id: 19642, Code: '8116', Name: 'Operator, plant, plastics goods mfr' },
  { Id: 19643, Code: '8116', Name: 'Operator, plastic' },
  { Id: 19644, Code: '8116', Name: 'Operator, plastics' },
  { Id: 19645, Code: '8116', Name: 'Operator, process, plastics goods mfr' },
  {
    Id: 19646,
    Code: '8116',
    Name: 'Operator, rolls, calender, plastics goods mfr'
  },
  { Id: 19647, Code: '8116', Name: 'Operator, saw, PLASTICS' },
  { Id: 19648, Code: '8116', Name: 'Operator, tube, plastics goods mfr' },
  { Id: 19649, Code: '8116', Name: 'Planer, PLASTICS' },
  { Id: 19650, Code: '8116', Name: 'Polisher, Bakelite' },
  { Id: 19651, Code: '8116', Name: 'Polisher, celluloid' },
  { Id: 19652, Code: '8116', Name: 'Polisher, frame, spectacle' },
  { Id: 19653, Code: '8116', Name: 'Polisher, plastics goods mfr' },
  { Id: 19654, Code: '8116', Name: 'Pre-former' },
  { Id: 19655, Code: '8116', Name: 'Presser, block, plastics goods mfr' },
  { Id: 19656, Code: '8116', Name: 'Presser, hydraulic, plastics goods mfr' },
  { Id: 19657, Code: '8116', Name: 'Presser, lens, contact, PLASTICS' },
  { Id: 19658, Code: '8116', Name: 'Presser, plastics' },
  { Id: 19659, Code: '8116', Name: 'Presser, plastics goods mfr' },
  { Id: 19660, Code: '8116', Name: 'Presser, polishing' },
  { Id: 19661, Code: '8116', Name: 'Quiller, comb' },
  { Id: 19662, Code: '8116', Name: 'Reeler, plastics goods mfr' },
  { Id: 19663, Code: '8116', Name: 'Roller, tube, PLASTICS' },
  { Id: 19664, Code: '8116', Name: 'Router, PLASTICS' },
  { Id: 19665, Code: '8116', Name: 'Sawyer, PLASTICS' },
  { Id: 19666, Code: '8116', Name: 'Setter, machine, plastics goods mfr' },
  { Id: 19667, Code: '8116', Name: 'Setter, plastics goods mfr' },
  { Id: 19668, Code: '8116', Name: 'Setter-up, spectacle mfr' },
  { Id: 19669, Code: '8116', Name: 'Sheeter, plastics goods mfr' },
  { Id: 19670, Code: '8116', Name: 'Slicer, celluloid sheet mfr' },
  { Id: 19671, Code: '8116', Name: 'Slitter, plastics goods mfr' },
  { Id: 19672, Code: '8116', Name: 'Spreader, laminated plastics mfr' },
  { Id: 19673, Code: '8116', Name: 'Stringer, plastics goods mfr' },
  { Id: 19674, Code: '8116', Name: 'Trimmer, Bakelite' },
  { Id: 19675, Code: '8116', Name: 'Trimmer, plastic' },
  { Id: 19676, Code: '8116', Name: 'Trimmer, plastics' },
  { Id: 19677, Code: '8116', Name: 'Trimmer, plastics mfr' },
  { Id: 19678, Code: '8116', Name: 'Turner, ball, billiard' },
  { Id: 19679, Code: '8116', Name: 'Turner, PLASTICS' },
  { Id: 19680, Code: '8116', Name: 'Turner, plastics goods mfr' },
  { Id: 19681, Code: '8116', Name: 'Twister, tube' },
  { Id: 19682, Code: '8116', Name: 'Worker, bench, laminated plastics mfr' },
  {
    Id: 19683,
    Code: '8116',
    Name: 'Worker, composite, aircraft component mfr'
  },
  { Id: 19684, Code: '8116', Name: 'Worker, factory, plastics goods mfr' },
  { Id: 19685, Code: '8116', Name: 'Worker, plastics' },
  { Id: 19686, Code: '8116', Name: 'Worker, process, plastics goods mfr' },
  { Id: 19687, Code: '8116', Name: 'Worker, tile, plastics goods mfr' },
  { Id: 19688, Code: '8117', Name: 'Airman, hot' },
  { Id: 19689, Code: '8117', Name: 'Alloyman, COPPER' },
  { Id: 19690, Code: '8117', Name: 'Anchorer' },
  { Id: 19691, Code: '8117', Name: 'Annealer' },
  { Id: 19692, Code: '8117', Name: 'Assistant, electroplating' },
  { Id: 19693, Code: '8117', Name: 'Assistant, forge' },
  { Id: 19694, Code: '8117', Name: 'Assistant, furnace, metal trades' },
  { Id: 19695, Code: '8117', Name: "Assistant, gilder's, ELECTRO-GILDING" },
  { Id: 19696, Code: '8117', Name: "Assistant, hammerman's" },
  { Id: 19697, Code: '8117', Name: "Assistant, heater's, metal trades" },
  { Id: 19698, Code: '8117', Name: 'Assistant, mill, rolling' },
  { Id: 19699, Code: '8117', Name: "Assistant, pickler's, GALVANISED SHEET" },
  { Id: 19700, Code: '8117', Name: 'Assistant, plating, electroplating' },
  { Id: 19701, Code: '8117', Name: "Assistant, roller's" },
  { Id: 19702, Code: '8117', Name: "Assistant, smith's" },
  { Id: 19703, Code: '8117', Name: "Assistant, stamper's, drop forging" },
  { Id: 19704, Code: '8117', Name: 'Attendant, bath, salt' },
  { Id: 19705, Code: '8117', Name: 'Attendant, baths, salt' },
  { Id: 19706, Code: '8117', Name: 'Attendant, cupola' },
  { Id: 19707, Code: '8117', Name: 'Attendant, furnace, annealing' },
  { Id: 19708, Code: '8117', Name: 'Attendant, furnace, blast' },
  { Id: 19709, Code: '8117', Name: 'Attendant, furnace, metal trades' },
  { Id: 19710, Code: '8117', Name: 'Attendant, instrument, steelworks' },
  { Id: 19711, Code: '8117', Name: 'Attendant, lid, carbonising' },
  { Id: 19712, Code: '8117', Name: 'Attendant, mill, rod' },
  { Id: 19713, Code: '8117', Name: 'Attendant, mill, rolling mill' },
  { Id: 19714, Code: '8117', Name: 'Attendant, oven, core' },
  { Id: 19715, Code: '8117', Name: 'Attendant, pyrometer, metal mfr' },
  { Id: 19716, Code: '8117', Name: 'Attendant, roll, cold' },
  { Id: 19717, Code: '8117', Name: 'Attendant, stove, core' },
  { Id: 19718, Code: '8117', Name: 'Attender, tinplate mfr' },
  { Id: 19719, Code: '8117', Name: 'Babbitter' },
  { Id: 19720, Code: '8117', Name: 'Backer, rolling mill' },
  { Id: 19721, Code: '8117', Name: 'Backer, spindle, rolling mill' },
  { Id: 19722, Code: '8117', Name: 'Backer-up, steelworks' },
  { Id: 19723, Code: '8117', Name: 'Baller, iron works' },
  { Id: 19724, Code: '8117', Name: 'Beater, leaf' },
  { Id: 19725, Code: '8117', Name: 'Bedman, blast furnace' },
  { Id: 19726, Code: '8117', Name: 'Behinder' },
  { Id: 19727, Code: '8117', Name: 'Bender, arch' },
  { Id: 19728, Code: '8117', Name: 'Bender, bar, handle' },
  { Id: 19729, Code: '8117', Name: 'Bender, rolling mill' },
  { Id: 19730, Code: '8117', Name: 'Bender, spoke, cycle mfr' },
  { Id: 19731, Code: '8117', Name: 'Billeter, rolling mill' },
  { Id: 19732, Code: '8117', Name: 'Blaster, FURNACE' },
  { Id: 19733, Code: '8117', Name: 'Blower, steelworks' },
  { Id: 19734, Code: '8117', Name: 'Bolter-down, metal trades' },
  { Id: 19735, Code: '8117', Name: 'Boxman, steel mfr' },
  { Id: 19736, Code: '8117', Name: 'Breaker, bear' },
  { Id: 19737, Code: '8117', Name: 'Breaker, rolling mill' },
  { Id: 19738, Code: '8117', Name: 'Breaker, skull' },
  { Id: 19739, Code: '8117', Name: 'Breaker-down, rolling mill' },
  { Id: 19740, Code: '8117', Name: 'Builder, mop, steelworks' },
  { Id: 19741, Code: '8117', Name: 'Burner, metal sinter plant' },
  { Id: 19742, Code: '8117', Name: 'Busher, lead' },
  { Id: 19743, Code: '8117', Name: 'Buttoner, rolling mill' },
  { Id: 19744, Code: '8117', Name: 'Carboniser, ball bearing mfr' },
  { Id: 19745, Code: '8117', Name: 'Carburizer' },
  { Id: 19746, Code: '8117', Name: 'Carrier, bar' },
  { Id: 19747, Code: '8117', Name: 'Caser, METAL' },
  { Id: 19748, Code: '8117', Name: 'Caster, brass' },
  { Id: 19749, Code: '8117', Name: 'Caster, furnace, blast' },
  { Id: 19750, Code: '8117', Name: 'Caster, ingot' },
  { Id: 19751, Code: '8117', Name: 'Caster, iron' },
  { Id: 19752, Code: '8117', Name: 'Caster, lead, battery mfr' },
  { Id: 19753, Code: '8117', Name: 'Caster, metal' },
  { Id: 19754, Code: '8117', Name: 'Caster, metal trades' },
  { Id: 19755, Code: '8117', Name: 'Caster, needle' },
  { Id: 19756, Code: '8117', Name: 'Caster, shot' },
  { Id: 19757, Code: '8117', Name: 'Caster, strip' },
  { Id: 19758, Code: '8117', Name: 'Caster, type' },
  { Id: 19759, Code: '8117', Name: 'Catcher and sticker, WIRE' },
  { Id: 19760, Code: '8117', Name: 'Catcher, bar' },
  { Id: 19761, Code: '8117', Name: 'Catcher, finishing' },
  { Id: 19762, Code: '8117', Name: 'Catcher, mill, sheet' },
  { Id: 19763, Code: '8117', Name: 'Catcher, roll, cold' },
  { Id: 19764, Code: '8117', Name: 'Catcher, steelworks' },
  { Id: 19765, Code: '8117', Name: 'Cellarman, steelworks' },
  { Id: 19766, Code: '8117', Name: 'Changer, roll' },
  { Id: 19767, Code: '8117', Name: 'Charger, tinplate mfr' },
  { Id: 19768, Code: '8117', Name: 'Chipper-in, rolling mill' },
  { Id: 19769, Code: '8117', Name: 'Cleaner, cartridge mfr' },
  { Id: 19770, Code: '8117', Name: 'Cleaner, core' },
  { Id: 19771, Code: '8117', Name: 'Cleaner, electroplating' },
  { Id: 19772, Code: '8117', Name: 'Cleaner, enamelling' },
  { Id: 19773, Code: '8117', Name: 'Cleaner, press, rolling mill' },
  { Id: 19774, Code: '8117', Name: 'Cleaner, wire, wire mfr' },
  { Id: 19775, Code: '8117', Name: 'Clipper, metal trades' },
  { Id: 19776, Code: '8117', Name: 'Closer, foundry' },
  { Id: 19777, Code: '8117', Name: 'Cogger, rolling mill' },
  { Id: 19778, Code: '8117', Name: 'Coiler, copper' },
  { Id: 19779, Code: '8117', Name: 'Coiler, steel mfr' },
  { Id: 19780, Code: '8117', Name: 'Coiler, wire mfr' },
  { Id: 19781, Code: '8117', Name: 'Coiler, wire rod mfr' },
  { Id: 19782, Code: '8117', Name: 'Colourer, METAL' },
  { Id: 19783, Code: '8117', Name: 'Colourer, STEEL PENS' },
  { Id: 19784, Code: '8117', Name: 'Controller, converter' },
  { Id: 19785, Code: '8117', Name: 'Controller, furnace, metal mfr' },
  { Id: 19786, Code: '8117', Name: 'Controller, furnace, sherardizing' },
  { Id: 19787, Code: '8117', Name: 'Controlman, blast furnace' },
  { Id: 19788, Code: '8117', Name: 'Converter, metal mfr' },
  { Id: 19789, Code: '8117', Name: 'Converter, steel' },
  { Id: 19790, Code: '8117', Name: 'Copperer, CARBON BRUSHES' },
  { Id: 19791, Code: '8117', Name: 'Coverer, lead' },
  { Id: 19792, Code: '8117', Name: 'Cutter, nut' },
  { Id: 19793, Code: '8117', Name: 'Cutter-down, rolling mill' },
  { Id: 19794, Code: '8117', Name: 'Cutter-out, cutlery mfr' },
  { Id: 19795, Code: '8117', Name: 'Degreaser, metal trades' },
  { Id: 19796, Code: '8117', Name: 'Depositor, welding' },
  { Id: 19797, Code: '8117', Name: 'De-ruster' },
  { Id: 19798, Code: '8117', Name: 'Descaler, steelworks' },
  { Id: 19799, Code: '8117', Name: 'Desilveriser' },
  { Id: 19800, Code: '8117', Name: 'Dipper and stripper' },
  { Id: 19801, Code: '8117', Name: 'Dipper, acid' },
  { Id: 19802, Code: '8117', Name: 'Dipper, brass' },
  { Id: 19803, Code: '8117', Name: 'Dipper, core' },
  { Id: 19804, Code: '8117', Name: 'Distiller, lead, zinc refining' },
  { Id: 19805, Code: '8117', Name: 'Distributor, paste, aluminium mfr' },
  { Id: 19806, Code: '8117', Name: 'Dogger' },
  { Id: 19807, Code: '8117', Name: 'Dogger-on' },
  { Id: 19808, Code: '8117', Name: 'Dogger-up, TUBES' },
  { Id: 19809, Code: '8117', Name: 'Doorman, forging' },
  { Id: 19810, Code: '8117', Name: 'Doorman, furnace' },
  { Id: 19811, Code: '8117', Name: 'Doubler, metal rolling' },
  { Id: 19812, Code: '8117', Name: 'Dragger, bar' },
  { Id: 19813, Code: '8117', Name: 'Dragger-down' },
  { Id: 19814, Code: '8117', Name: 'Drawer and marker, Assay Office' },
  { Id: 19815, Code: '8117', Name: 'Drawer, bar' },
  { Id: 19816, Code: '8117', Name: 'Drawer, fork' },
  { Id: 19817, Code: '8117', Name: 'Drawer, METAL' },
  { Id: 19818, Code: '8117', Name: 'Drawer, metal trades' },
  { Id: 19819, Code: '8117', Name: 'Drawer, plate, WIRE' },
  { Id: 19820, Code: '8117', Name: 'Drawer, puddling' },
  { Id: 19821, Code: '8117', Name: 'Drawer, rod, METAL' },
  { Id: 19822, Code: '8117', Name: 'Drawer, steel' },
  { Id: 19823, Code: '8117', Name: 'Drawer, strip, METAL' },
  { Id: 19824, Code: '8117', Name: 'Drawer, tape, METAL' },
  { Id: 19825, Code: '8117', Name: 'Drawer, tube, METAL' },
  { Id: 19826, Code: '8117', Name: 'Drawer, wire' },
  { Id: 19827, Code: '8117', Name: 'Drawer, zinc refining' },
  { Id: 19828, Code: '8117', Name: 'Dresser, metal fastening mfr' },
  { Id: 19829, Code: '8117', Name: 'Dresser, wire' },
  { Id: 19830, Code: '8117', Name: 'Driver, assistant, iron and steelworks' },
  { Id: 19831, Code: '8117', Name: 'Driver, caster' },
  { Id: 19832, Code: '8117', Name: 'Driver, control, steelworks' },
  { Id: 19833, Code: '8117', Name: 'Driver, gear, rolling mill' },
  { Id: 19834, Code: '8117', Name: 'Driver, hammer' },
  { Id: 19835, Code: '8117', Name: 'Driver, press' },
  { Id: 19836, Code: '8117', Name: 'Driver, rack, rolling mill' },
  { Id: 19837, Code: '8117', Name: 'Driver, rest, rolling mill' },
  { Id: 19838, Code: '8117', Name: 'Driver, roll' },
  { Id: 19839, Code: '8117', Name: 'Driver, roller, steelworks' },
  { Id: 19840, Code: '8117', Name: 'Driver, skid, rolling mill' },
  { Id: 19841, Code: '8117', Name: 'Driver, spray, water, rolling mill' },
  { Id: 19842, Code: '8117', Name: 'Driver, table, rolling mill' },
  { Id: 19843, Code: '8117', Name: 'Driver, tilter' },
  { Id: 19844, Code: '8117', Name: 'Drosser' },
  { Id: 19845, Code: '8117', Name: 'Drossman' },
  { Id: 19846, Code: '8117', Name: 'Dryer, core, foundry' },
  { Id: 19847, Code: '8117', Name: 'Engineer, treatment, heat' },
  { Id: 19848, Code: '8117', Name: 'Extruder, cable, metal trades' },
  { Id: 19849, Code: '8117', Name: 'Extruder, metal' },
  { Id: 19850, Code: '8117', Name: 'Fabricator, pipe, heavy engineering' },
  { Id: 19851, Code: '8117', Name: 'Fabricator, tube mfr' },
  { Id: 19852, Code: '8117', Name: 'Feeder, foundry' },
  { Id: 19853, Code: '8117', Name: 'Feeder, furnace' },
  { Id: 19854, Code: '8117', Name: 'Feeder, metal tube mfr' },
  { Id: 19855, Code: '8117', Name: 'Feeder, pass, skin, steelworks' },
  { Id: 19856, Code: '8117', Name: 'Feeder, roll, metal mfr' },
  { Id: 19857, Code: '8117', Name: 'Feeder, rolling mill' },
  { Id: 19858, Code: '8117', Name: 'Feeder, rolls, metal mfr' },
  { Id: 19859, Code: '8117', Name: 'Fettler, puddling' },
  { Id: 19860, Code: '8117', Name: 'Filler, pan, steelworks' },
  { Id: 19861, Code: '8117', Name: 'Filler, plug' },
  { Id: 19862, Code: '8117', Name: 'Filler, wire' },
  { Id: 19863, Code: '8117', Name: 'Finisher, metal tube mfr' },
  { Id: 19864, Code: '8117', Name: 'Finisher, rolling mill' },
  { Id: 19865, Code: '8117', Name: 'Finisher, spring' },
  { Id: 19866, Code: '8117', Name: 'Finisher, spring mfr' },
  { Id: 19867, Code: '8117', Name: 'Finisher, tube, steelworks' },
  { Id: 19868, Code: '8117', Name: 'Fireman, annealing' },
  { Id: 19869, Code: '8117', Name: 'Fireman, furnace, annealing' },
  { Id: 19870, Code: '8117', Name: 'Fireman, furnace, metal trades' },
  { Id: 19871, Code: '8117', Name: 'Fireman, metal trades' },
  { Id: 19872, Code: '8117', Name: 'Fireman, oven, annealing' },
  { Id: 19873, Code: '8117', Name: 'Fireman, retort, ZINC' },
  { Id: 19874, Code: '8117', Name: 'Fireman, shipbuilding' },
  { Id: 19875, Code: '8117', Name: 'Fireman, soaker' },
  { Id: 19876, Code: '8117', Name: 'Fireman, stove' },
  { Id: 19877, Code: '8117', Name: 'Firer, metal mfr' },
  { Id: 19878, Code: '8117', Name: 'Firer, stove, blast furnace' },
  { Id: 19879, Code: '8117', Name: 'Fisher, COPPER' },
  { Id: 19880, Code: '8117', Name: 'Fitter, mould' },
  { Id: 19881, Code: '8117', Name: 'Flaker-on, ELECTRIC CABLE' },
  { Id: 19882, Code: '8117', Name: 'Flattener, patent, GALVANISED SHEET' },
  { Id: 19883, Code: '8117', Name: 'Flattener, sheet, METAL' },
  { Id: 19884, Code: '8117', Name: 'Forehand' },
  { Id: 19885, Code: '8117', Name: 'Foreman, annealing' },
  { Id: 19886, Code: '8117', Name: 'Foreman, blast furnace' },
  { Id: 19887, Code: '8117', Name: 'Foreman, cupola' },
  { Id: 19888, Code: '8117', Name: 'Foreman, extrusion, metal' },
  { Id: 19889, Code: '8117', Name: 'Foreman, furnace, blast' },
  { Id: 19890, Code: '8117', Name: 'Foreman, furnace, metal trades' },
  { Id: 19891, Code: '8117', Name: 'Foreman, iron, steel mfr' },
  { Id: 19892, Code: '8117', Name: 'Foreman, metal cutlery mfr' },
  { Id: 19893, Code: '8117', Name: 'Foreman, metal extrusion' },
  { Id: 19894, Code: '8117', Name: 'Foreman, metal heat treatment' },
  { Id: 19895, Code: '8117', Name: 'Foreman, metal refining' },
  { Id: 19896, Code: '8117', Name: 'Foreman, metal tube mfr' },
  { Id: 19897, Code: '8117', Name: 'Foreman, mill, rolling' },
  { Id: 19898, Code: '8117', Name: 'Foreman, razor blade mfr' },
  { Id: 19899, Code: '8117', Name: 'Foreman, refining, metal' },
  { Id: 19900, Code: '8117', Name: 'Foreman, rolling mill' },
  { Id: 19901, Code: '8117', Name: 'Foreman, shop, melting' },
  { Id: 19902, Code: '8117', Name: 'Foreman, shop, steel' },
  { Id: 19903, Code: '8117', Name: 'Foreman, steel drawing' },
  { Id: 19904, Code: '8117', Name: 'Foreman, tinplate mfr' },
  { Id: 19905, Code: '8117', Name: 'Foreman, treatment, heat' },
  { Id: 19906, Code: '8117', Name: 'Foreman, wire mfr' },
  { Id: 19907, Code: '8117', Name: 'Forgeman' },
  { Id: 19908, Code: '8117', Name: 'Forker, wrought iron mfr' },
  { Id: 19909, Code: '8117', Name: 'Former, tube' },
  { Id: 19910, Code: '8117', Name: 'Former, tube mfr' },
  { Id: 19911, Code: '8117', Name: 'Founder, metal trades' },
  { Id: 19912, Code: '8117', Name: 'Founder, type' },
  { Id: 19913, Code: '8117', Name: 'Furnaceman, annealing' },
  { Id: 19914, Code: '8117', Name: 'Furnaceman, blast' },
  { Id: 19915, Code: '8117', Name: 'Furnaceman, brass' },
  { Id: 19916, Code: '8117', Name: 'Furnaceman, chrome' },
  { Id: 19917, Code: '8117', Name: 'Furnaceman, cupola' },
  { Id: 19918, Code: '8117', Name: 'Furnaceman, electric' },
  { Id: 19919, Code: '8117', Name: 'Furnaceman, hardening, case' },
  { Id: 19920, Code: '8117', Name: 'Furnaceman, hearth, open' },
  { Id: 19921, Code: '8117', Name: 'Furnaceman, metal trades' },
  { Id: 19922, Code: '8117', Name: 'Furnaceman, spring, coach' },
  { Id: 19923, Code: '8117', Name: 'Furnaceman, treatment, heat' },
  { Id: 19924, Code: '8117', Name: 'Fuser, enamel' },
  { Id: 19925, Code: '8117', Name: 'Fuser, metal trades' },
  { Id: 19926, Code: '8117', Name: 'Gauger, furnace' },
  { Id: 19927, Code: '8117', Name: 'Granulator, aluminium' },
  { Id: 19928, Code: '8117', Name: 'Grouter, mould, steelworks' },
  { Id: 19929, Code: '8117', Name: 'Guider, tube mfr' },
  { Id: 19930, Code: '8117', Name: 'Hand, bench, brass foundry' },
  { Id: 19931, Code: '8117', Name: 'Hand, cupola, metal mfr' },
  { Id: 19932, Code: '8117', Name: 'Hand, first, steelworks' },
  { Id: 19933, Code: '8117', Name: 'Hand, forge' },
  { Id: 19934, Code: '8117', Name: 'Hand, furnace, blast' },
  { Id: 19935, Code: '8117', Name: 'Hand, furnace, metal mfr' },
  { Id: 19936, Code: '8117', Name: 'Hand, leading, blast furnace' },
  { Id: 19937, Code: '8117', Name: 'Hand, leading, foundry' },
  { Id: 19938, Code: '8117', Name: 'Hand, mill, galvanised sheet mfr' },
  { Id: 19939, Code: '8117', Name: 'Hand, mill, rolling' },
  { Id: 19940, Code: '8117', Name: 'Hand, mill, tinplate mfr' },
  { Id: 19941, Code: '8117', Name: 'Hand, pit, tube mfr' },
  { Id: 19942, Code: '8117', Name: 'Hand, roll, steel mfr' },
  { Id: 19943, Code: '8117', Name: 'Hand, second, metal rolling' },
  { Id: 19944, Code: '8117', Name: "Hand, second, roller's" },
  { Id: 19945, Code: '8117', Name: 'Hand, second, steelworks' },
  { Id: 19946, Code: '8117', Name: 'Hand, spare, rolling mill' },
  { Id: 19947, Code: '8117', Name: 'Hand, stretcher, aluminium mfr' },
  { Id: 19948, Code: '8117', Name: 'Hand, third, foundry' },
  { Id: 19949, Code: '8117', Name: 'Hand, third, rolling mill' },
  { Id: 19950, Code: '8117', Name: 'Hardener, blade' },
  { Id: 19951, Code: '8117', Name: 'Hardener, case' },
  { Id: 19952, Code: '8117', Name: 'Hardener, die and mill' },
  { Id: 19953, Code: '8117', Name: 'Hardener, drill' },
  { Id: 19954, Code: '8117', Name: 'Hardener, file' },
  { Id: 19955, Code: '8117', Name: 'Hardener, METAL' },
  { Id: 19956, Code: '8117', Name: 'Hardener, ring' },
  { Id: 19957, Code: '8117', Name: 'Hardener, saw' },
  { Id: 19958, Code: '8117', Name: 'Hardener, section' },
  { Id: 19959, Code: '8117', Name: 'Hardener, tool' },
  { Id: 19960, Code: '8117', Name: 'Haulier, shop, tube mfr' },
  { Id: 19961, Code: '8117', Name: 'Heater, ingot' },
  { Id: 19962, Code: '8117', Name: 'Heater, iron, foundry' },
  { Id: 19963, Code: '8117', Name: 'Heater, metal trades' },
  { Id: 19964, Code: '8117', Name: 'Heater, mill, rolling mill' },
  { Id: 19965, Code: '8117', Name: 'Heater, pit, soaking' },
  { Id: 19966, Code: '8117', Name: "Heater, smith's" },
  { Id: 19967, Code: '8117', Name: 'Heater, tube, steel' },
  { Id: 19968, Code: '8117', Name: "Helper, blacksmith's" },
  { Id: 19969, Code: '8117', Name: 'Helper, blast furnace' },
  { Id: 19970, Code: '8117', Name: "Helper, caster's" },
  { Id: 19971, Code: '8117', Name: 'Helper, copper refining' },
  { Id: 19972, Code: '8117', Name: 'Helper, first, rolling mill' },
  { Id: 19973, Code: '8117', Name: 'Helper, first, tinplate mfr' },
  { Id: 19974, Code: '8117', Name: 'Helper, forge' },
  { Id: 19975, Code: '8117', Name: 'Helper, forging' },
  { Id: 19976, Code: '8117', Name: "Helper, furnaceman's" },
  { Id: 19977, Code: '8117', Name: "Helper, keeper's" },
  { Id: 19978, Code: '8117', Name: 'Helper, metal tube mfr' },
  { Id: 19979, Code: '8117', Name: "Helper, pickler's, metal trades" },
  { Id: 19980, Code: '8117', Name: 'Helper, pit' },
  { Id: 19981, Code: '8117', Name: "Helper, pitman's" },
  { Id: 19982, Code: '8117', Name: "Helper, potman's, NICKEL" },
  { Id: 19983, Code: '8117', Name: 'Helper, press' },
  { Id: 19984, Code: '8117', Name: "Helper, roller's" },
  { Id: 19985, Code: '8117', Name: 'Helper, rolling mill' },
  { Id: 19986, Code: '8117', Name: "Helper, smith's" },
  { Id: 19987, Code: '8117', Name: "Helper, stamper's" },
  { Id: 19988, Code: '8117', Name: "Helper, straightener's, rolling mill" },
  { Id: 19989, Code: '8117', Name: 'Holder, double, rolling mill' },
  { Id: 19990, Code: '8117', Name: 'Hooker, rolling mill' },
  { Id: 19991, Code: '8117', Name: 'Injector, wax' },
  { Id: 19992, Code: '8117', Name: 'Inspector, furnace' },
  { Id: 19993, Code: '8117', Name: 'Jigger, metal trades' },
  { Id: 19994, Code: '8117', Name: 'Keeper, blast furnace' },
  { Id: 19995, Code: '8117', Name: 'Keeper, furnace, metal mfr' },
  { Id: 19996, Code: '8117', Name: 'Kettleman' },
  { Id: 19997, Code: '8117', Name: 'Ladler, copper lead refining' },
  { Id: 19998, Code: '8117', Name: 'Lapper, metal tube mfr' },
  { Id: 19999, Code: '8117', Name: 'Layer, core' },
  { Id: 20000, Code: '8117', Name: 'Leader, abrasive wheel mfr' },
  { Id: 20001, Code: '8117', Name: 'Levelhand' },
  { Id: 20002, Code: '8117', Name: 'Leveller, plate' },
  { Id: 20003, Code: '8117', Name: 'Leveller, roller, steelworks' },
  { Id: 20004, Code: '8117', Name: 'Leverman, iron and steelworks' },
  { Id: 20005, Code: '8117', Name: 'Lifter-up, rolling mill' },
  { Id: 20006, Code: '8117', Name: 'Machinist, bending, iron works' },
  { Id: 20007, Code: '8117', Name: 'Machinist, casting, centrifugal, STEEL' },
  { Id: 20008, Code: '8117', Name: 'Machinist, casting, pig' },
  { Id: 20009, Code: '8117', Name: 'Machinist, coiling, metal tube mfr' },
  { Id: 20010, Code: '8117', Name: 'Machinist, drawing, wire' },
  { Id: 20011, Code: '8117', Name: 'Machinist, extruding, metal tube mfr' },
  { Id: 20012, Code: '8117', Name: 'Machinist, forge' },
  { Id: 20013, Code: '8117', Name: 'Machinist, forging' },
  { Id: 20014, Code: '8117', Name: 'Machinist, moulding, lead refining' },
  { Id: 20015, Code: '8117', Name: 'Machinist, rolling, metal trades' },
  { Id: 20016, Code: '8117', Name: 'Machinist, stoving, METAL GOODS' },
  { Id: 20017, Code: '8117', Name: 'Machinist, upsetting' },
  { Id: 20018, Code: '8117', Name: 'Machinist, wire' },
  { Id: 20019, Code: '8117', Name: 'Maker, block, cylinder' },
  { Id: 20020, Code: '8117', Name: 'Maker, block, radiator' },
  { Id: 20021, Code: '8117', Name: 'Maker, fittings, tube' },
  { Id: 20022, Code: '8117', Name: 'Maker, last, IRON' },
  { Id: 20023, Code: '8117', Name: "Maker, lead, printer's" },
  { Id: 20024, Code: '8117', Name: 'Maker, nail, forged' },
  { Id: 20025, Code: '8117', Name: 'Maker, nail, frost' },
  { Id: 20026, Code: '8117', Name: 'Maker, nail, wrought' },
  { Id: 20027, Code: '8117', Name: 'Maker, pipe, lead' },
  { Id: 20028, Code: '8117', Name: 'Maker, plate, accumulator' },
  { Id: 20029, Code: '8117', Name: 'Maker, punch' },
  { Id: 20030, Code: '8117', Name: 'Maker, runner' },
  { Id: 20031, Code: '8117', Name: 'Maker, screw, frost' },
  { Id: 20032, Code: '8117', Name: 'Maker, strap, fork' },
  { Id: 20033, Code: '8117', Name: 'Maker, tube, metal' },
  { Id: 20034, Code: '8117', Name: 'Maker, tube, musical instruments mfr' },
  { Id: 20035, Code: '8117', Name: 'Man, annealing' },
  { Id: 20036, Code: '8117', Name: 'Man, bell, blast furnace' },
  { Id: 20037, Code: '8117', Name: 'Man, casting, blast furnace' },
  { Id: 20038, Code: '8117', Name: 'Man, condenser, blast furnace' },
  { Id: 20039, Code: '8117', Name: 'Man, converter, metal mfr' },
  { Id: 20040, Code: '8117', Name: 'Man, crucible, METAL' },
  { Id: 20041, Code: '8117', Name: 'Man, cupel' },
  { Id: 20042, Code: '8117', Name: 'Man, cupola' },
  { Id: 20043, Code: '8117', Name: 'Man, drop, blast furnace' },
  { Id: 20044, Code: '8117', Name: 'Man, extractor, tube mfr' },
  { Id: 20045, Code: '8117', Name: 'Man, fan, lead mfr' },
  { Id: 20046, Code: '8117', Name: 'Man, foundry' },
  { Id: 20047, Code: '8117', Name: 'Man, ingot, non-ferrous metal mfr' },
  { Id: 20048, Code: '8117', Name: 'Man, ladle, direct' },
  { Id: 20049, Code: '8117', Name: 'Man, ladle, metal mfr' },
  { Id: 20050, Code: '8117', Name: 'Man, lime, steelworks' },
  { Id: 20051, Code: '8117', Name: 'Man, mill, metal mfr' },
  { Id: 20052, Code: '8117', Name: 'Man, mixer, steel mfr' },
  { Id: 20053, Code: '8117', Name: 'Man, moulds, metal mfr' },
  { Id: 20054, Code: '8117', Name: 'Man, muffle, annealing' },
  { Id: 20055, Code: '8117', Name: 'Man, muffle, foundry' },
  { Id: 20056, Code: '8117', Name: 'Man, muffle, steel mfr' },
  { Id: 20057, Code: '8117', Name: 'Man, pit, steelworks' },
  { Id: 20058, Code: '8117', Name: 'Man, platform, steelworks' },
  { Id: 20059, Code: '8117', Name: 'Man, still, metal smelting' },
  { Id: 20060, Code: '8117', Name: 'Man, vessel, steelworks' },
  { Id: 20061, Code: '8117', Name: 'Man, vice, spring' },
  { Id: 20062, Code: '8117', Name: 'Mangler, plate' },
  { Id: 20063, Code: '8117', Name: 'Mangler, steelworks' },
  { Id: 20064, Code: '8117', Name: 'Manipulator, metal mfr' },
  { Id: 20065, Code: '8117', Name: 'Manufacturer, metal mfr' },
  { Id: 20066, Code: '8117', Name: 'Marker, billet, steelworks' },
  { Id: 20067, Code: '8117', Name: 'Marker, hall, Assay Office' },
  { Id: 20068, Code: '8117', Name: 'Marker, ingot, metal mfr' },
  { Id: 20069, Code: '8117', Name: 'Marker, plate, rolling mill' },
  { Id: 20070, Code: '8117', Name: 'Marker-off, foundry' },
  { Id: 20071, Code: '8117', Name: "Mate, blacksmith's" },
  { Id: 20072, Code: '8117', Name: "Mate, forger's, drop" },
  { Id: 20073, Code: '8117', Name: "Mate, smith's" },
  { Id: 20074, Code: '8117', Name: 'Melter, bullion' },
  { Id: 20075, Code: '8117', Name: 'Melter, electric' },
  { Id: 20076, Code: '8117', Name: 'Melter, gold' },
  { Id: 20077, Code: '8117', Name: 'Melter, hand, first' },
  { Id: 20078, Code: '8117', Name: 'Melter, lead' },
  { Id: 20079, Code: '8117', Name: 'Melter, platinum' },
  { Id: 20080, Code: '8117', Name: 'Melter, Royal Mint' },
  { Id: 20081, Code: '8117', Name: 'Melter, silver' },
  { Id: 20082, Code: '8117', Name: 'Melter, steel' },
  { Id: 20083, Code: '8117', Name: 'Melter, steelworks' },
  { Id: 20084, Code: '8117', Name: 'Melter, zinc smelting' },
  { Id: 20085, Code: '8117', Name: 'Metaller' },
  { Id: 20086, Code: '8117', Name: 'Middler, rolling mill' },
  { Id: 20087, Code: '8117', Name: 'Minder, stove' },
  { Id: 20088, Code: '8117', Name: 'Mixer, metal' },
  { Id: 20089, Code: '8117', Name: 'Mixer, sand, metal mfr' },
  { Id: 20090, Code: '8117', Name: 'Mixer, steelworks' },
  { Id: 20091, Code: '8117', Name: 'Moulder, battery' },
  { Id: 20092, Code: '8117', Name: 'Moulder, fork, DIGGING, HAY' },
  { Id: 20093, Code: '8117', Name: 'Moulder, lead, battery mfr' },
  { Id: 20094, Code: '8117', Name: 'Nitrider' },
  { Id: 20095, Code: '8117', Name: 'Normaliser' },
  { Id: 20096, Code: '8117', Name: 'Oiler, tube, tube mfr' },
  { Id: 20097, Code: '8117', Name: 'Opener, hot, steel mfr' },
  { Id: 20098, Code: '8117', Name: 'Opener, plate, TINPLATE' },
  { Id: 20099, Code: '8117', Name: 'Opener, tinplate mfr' },
  { Id: 20100, Code: '8117', Name: 'Operator, bath, salt, metal goods mfr' },
  { Id: 20101, Code: '8117', Name: 'Operator, bench, draw' },
  { Id: 20102, Code: '8117', Name: 'Operator, block, bull' },
  { Id: 20103, Code: '8117', Name: 'Operator, castings, metal mfr' },
  { Id: 20104, Code: '8117', Name: 'Operator, coil, steel' },
  { Id: 20105, Code: '8117', Name: 'Operator, control, steelworks' },
  { Id: 20106, Code: '8117', Name: 'Operator, degrease' },
  { Id: 20107, Code: '8117', Name: 'Operator, degreaser' },
  { Id: 20108, Code: '8117', Name: 'Operator, depiler, metal mfr' },
  { Id: 20109, Code: '8117', Name: 'Operator, extruder, metal trades' },
  { Id: 20110, Code: '8117', Name: 'Operator, extrusion, metal trades' },
  { Id: 20111, Code: '8117', Name: 'Operator, forge' },
  { Id: 20112, Code: '8117', Name: 'Operator, furnace, annealing' },
  { Id: 20113, Code: '8117', Name: 'Operator, furnace, carburising' },
  { Id: 20114, Code: '8117', Name: 'Operator, furnace, electrical, metal mfr' },
  { Id: 20115, Code: '8117', Name: 'Operator, furnace, metal mfr' },
  { Id: 20116, Code: '8117', Name: 'Operator, furnace, slab, pusher' },
  { Id: 20117, Code: '8117', Name: 'Operator, furnace, treatment, heat' },
  { Id: 20118, Code: '8117', Name: 'Operator, hammer' },
  { Id: 20119, Code: '8117', Name: 'Operator, hot' },
  { Id: 20120, Code: '8117', Name: 'Operator, line, pickle, steel mfr' },
  { Id: 20121, Code: '8117', Name: 'Operator, mangle' },
  { Id: 20122, Code: '8117', Name: 'Operator, manipulator, steel mfr' },
  { Id: 20123, Code: '8117', Name: 'Operator, mill, foil, ALUMINIUM' },
  { Id: 20124, Code: '8117', Name: 'Operator, mill, hot, metal trades' },
  { Id: 20125, Code: '8117', Name: 'Operator, mill, rolling' },
  { Id: 20126, Code: '8117', Name: 'Operator, mill, rolling mill' },
  { Id: 20127, Code: '8117', Name: 'Operator, mill, tube' },
  { Id: 20128, Code: '8117', Name: 'Operator, pilger' },
  { Id: 20129, Code: '8117', Name: 'Operator, plant, degreasing' },
  { Id: 20130, Code: '8117', Name: 'Operator, plant, sinter' },
  { Id: 20131, Code: '8117', Name: 'Operator, pot, aluminium mfr' },
  { Id: 20132, Code: '8117', Name: 'Operator, process, aluminium mfr' },
  { Id: 20133, Code: '8117', Name: 'Operator, pulpit, steel mfr' },
  { Id: 20134, Code: '8117', Name: 'Operator, pusher, metal mfr' },
  { Id: 20135, Code: '8117', Name: 'Operator, shear, flying' },
  { Id: 20136, Code: '8117', Name: 'Operator, shears, flying' },
  { Id: 20137, Code: '8117', Name: 'Operator, sinter' },
  { Id: 20138, Code: '8117', Name: 'Operator, steel, metal mfr' },
  { Id: 20139, Code: '8117', Name: 'Operator, swaging' },
  { Id: 20140, Code: '8117', Name: 'Operator, tilter, STEEL' },
  { Id: 20141, Code: '8117', Name: 'Operator, transfer, metal mfr' },
  { Id: 20142, Code: '8117', Name: 'Operator, treatment, heat, METAL' },
  { Id: 20143, Code: '8117', Name: 'Ovenman, iron, malleable' },
  { Id: 20144, Code: '8117', Name: 'Packer, oven, foundry' },
  { Id: 20145, Code: '8117', Name: 'Passer, rolling mill' },
  { Id: 20146, Code: '8117', Name: 'Passer, sample' },
  { Id: 20147, Code: '8117', Name: 'Patenter, wire' },
  { Id: 20148, Code: '8117', Name: 'Pickler, aluminium' },
  { Id: 20149, Code: '8117', Name: 'Pickler, iron' },
  { Id: 20150, Code: '8117', Name: 'Pickler, metal trades' },
  { Id: 20151, Code: '8117', Name: 'Pickler, steel, strip' },
  { Id: 20152, Code: '8117', Name: 'Pickler, tube' },
  { Id: 20153, Code: '8117', Name: 'Pickler, underhand' },
  { Id: 20154, Code: '8117', Name: 'Pickler, wire' },
  { Id: 20155, Code: '8117', Name: 'Piercer, tube mfr' },
  { Id: 20156, Code: '8117', Name: 'Potman, cable mfr' },
  { Id: 20157, Code: '8117', Name: 'Potman, metal mfr' },
  { Id: 20158, Code: '8117', Name: 'Potter, furnace, blast' },
  { Id: 20159, Code: '8117', Name: 'Potter, lead smelting' },
  { Id: 20160, Code: '8117', Name: 'Pourer, foundry' },
  { Id: 20161, Code: '8117', Name: 'Presser, bending' },
  { Id: 20162, Code: '8117', Name: 'Presser, clipping' },
  { Id: 20163, Code: '8117', Name: 'Presser, extruding, metal trades' },
  { Id: 20164, Code: '8117', Name: "Presser, fitter's" },
  { Id: 20165, Code: '8117', Name: 'Presser, lead, cable mfr' },
  { Id: 20166, Code: '8117', Name: 'Presser, metal tube mfr' },
  { Id: 20167, Code: '8117', Name: 'Presser, pipe' },
  { Id: 20168, Code: '8117', Name: 'Puddler, METAL' },
  { Id: 20169, Code: '8117', Name: 'Puller-out, metal mfr' },
  { Id: 20170, Code: '8117', Name: 'Rackman, metal mfr' },
  { Id: 20171, Code: '8117', Name: 'Rammer, metal mfr' },
  { Id: 20172, Code: '8117', Name: 'Rammer, plug' },
  { Id: 20173, Code: '8117', Name: 'Recaster' },
  { Id: 20174, Code: '8117', Name: 'Receiver, rolling mill' },
  { Id: 20175, Code: '8117', Name: 'Recoverer, zinc' },
  { Id: 20176, Code: '8117', Name: 'Rectifier, tube' },
  { Id: 20177, Code: '8117', Name: 'Reeler, bar' },
  { Id: 20178, Code: '8117', Name: 'Reeler, rolling mill' },
  { Id: 20179, Code: '8117', Name: 'Reelerman, rolling mill' },
  { Id: 20180, Code: '8117', Name: 'Refiner, bullion' },
  { Id: 20181, Code: '8117', Name: 'Refiner, gold' },
  { Id: 20182, Code: '8117', Name: 'Refiner, metal mfr' },
  { Id: 20183, Code: '8117', Name: 'Refiner, nickel' },
  { Id: 20184, Code: '8117', Name: 'Refiner, silver' },
  { Id: 20185, Code: '8117', Name: 'Reheater' },
  { Id: 20186, Code: '8117', Name: 'Reliner, bearing' },
  { Id: 20187, Code: '8117', Name: 'Re-roller, wire mfr' },
  { Id: 20188, Code: '8117', Name: 'Riser, metal rolling' },
  { Id: 20189, Code: '8117', Name: 'Riser, mill' },
  { Id: 20190, Code: '8117', Name: 'Roller, bar, puddled' },
  { Id: 20191, Code: '8117', Name: 'Roller, cogging' },
  { Id: 20192, Code: '8117', Name: 'Roller, cold' },
  { Id: 20193, Code: '8117', Name: 'Roller, finishing' },
  { Id: 20194, Code: '8117', Name: 'Roller, forge' },
  { Id: 20195, Code: '8117', Name: 'Roller, head' },
  { Id: 20196, Code: '8117', Name: 'Roller, hot, steel mfr' },
  { Id: 20197, Code: '8117', Name: 'Roller, metal trades' },
  { Id: 20198, Code: '8117', Name: 'Roller, mill, blooming' },
  { Id: 20199, Code: '8117', Name: 'Roller, mill, iron, steel tube mfr' },
  { Id: 20200, Code: '8117', Name: 'Roller, mill, roughing' },
  { Id: 20201, Code: '8117', Name: 'Roller, mill, sheet' },
  { Id: 20202, Code: '8117', Name: 'Roller, plate' },
  { Id: 20203, Code: '8117', Name: 'Roller, rod' },
  { Id: 20204, Code: '8117', Name: 'Roller, roughing' },
  { Id: 20205, Code: '8117', Name: 'Roller, section' },
  { Id: 20206, Code: '8117', Name: 'Roller, side' },
  { Id: 20207, Code: '8117', Name: 'Roller, strip' },
  { Id: 20208, Code: '8117', Name: 'Roller, tube, METAL' },
  { Id: 20209, Code: '8117', Name: 'Roller, tyre, STEEL' },
  { Id: 20210, Code: '8117', Name: 'Roller, under' },
  { Id: 20211, Code: '8117', Name: 'Roller, wire' },
  { Id: 20212, Code: '8117', Name: 'Rollsman, copper rolling' },
  { Id: 20213, Code: '8117', Name: 'Rougher, rolling mill' },
  { Id: 20214, Code: '8117', Name: 'Rounder, tube mfr' },
  { Id: 20215, Code: '8117', Name: 'Runner, metal, WHITE' },
  { Id: 20216, Code: '8117', Name: 'Scaler, rolling mill' },
  { Id: 20217, Code: '8117', Name: 'Scaler, steel mfr' },
  { Id: 20218, Code: '8117', Name: 'Scourer, tinplate mfr' },
  { Id: 20219, Code: '8117', Name: 'Scourer, wire mfr' },
  { Id: 20220, Code: '8117', Name: 'Screwer, rolling mill' },
  { Id: 20221, Code: '8117', Name: 'Screwer-down, rolling mill' },
  { Id: 20222, Code: '8117', Name: 'Screwman, metal mfr' },
  { Id: 20223, Code: '8117', Name: 'Setter, core' },
  { Id: 20224, Code: '8117', Name: 'Setter, die, wire mfr' },
  { Id: 20225, Code: '8117', Name: 'Setter, guide, steel mfr' },
  { Id: 20226, Code: '8117', Name: 'Setter, mould, steelworks' },
  { Id: 20227, Code: '8117', Name: 'Setter, spring' },
  { Id: 20228, Code: '8117', Name: 'Setter, type foundry' },
  { Id: 20229, Code: '8117', Name: 'Sheather, cable' },
  { Id: 20230, Code: '8117', Name: 'Sherardizer' },
  { Id: 20231, Code: '8117', Name: 'Shingler, iron works' },
  { Id: 20232, Code: '8117', Name: 'Shopman, bottle, iron, steel tube mfr' },
  { Id: 20233, Code: '8117', Name: 'Sinterer, metal mfr' },
  { Id: 20234, Code: '8117', Name: 'Sinterer, mine: not coal' },
  { Id: 20235, Code: '8117', Name: 'Skimmer, metal mfr' },
  { Id: 20236, Code: '8117', Name: 'Slagger, blast furnace' },
  { Id: 20237, Code: '8117', Name: 'Slagger, steel casting' },
  { Id: 20238, Code: '8117', Name: 'Slagman, blast furnace' },
  { Id: 20239, Code: '8117', Name: 'Smelter, lead' },
  { Id: 20240, Code: '8117', Name: 'Smelter, metal mfr' },
  { Id: 20241, Code: '8117', Name: 'Smelter, steel' },
  { Id: 20242, Code: '8117', Name: 'Smith, chain' },
  { Id: 20243, Code: '8117', Name: 'Smith, coil, tube mfr' },
  { Id: 20244, Code: '8117', Name: 'Softener, lead' },
  { Id: 20245, Code: '8117', Name: 'Spinner, pipe, iron, steel tube mfr' },
  { Id: 20246, Code: '8117', Name: 'Sprayer, copper' },
  { Id: 20247, Code: '8117', Name: 'Sprayer, lime, iron and steelworks' },
  { Id: 20248, Code: '8117', Name: 'Springer, tube mfr' },
  { Id: 20249, Code: '8117', Name: 'Squirter, lead, cartridge mfr' },
  { Id: 20250, Code: '8117', Name: 'Stamper, Assay Office' },
  { Id: 20251, Code: '8117', Name: 'Stamper, ingot' },
  { Id: 20252, Code: '8117', Name: 'Staver, tube mfr' },
  { Id: 20253, Code: '8117', Name: 'Stoker, furnace, metal mfr' },
  { Id: 20254, Code: '8117', Name: 'Stoker, metal mfr' },
  { Id: 20255, Code: '8117', Name: 'Stoker, shipbuilding' },
  { Id: 20256, Code: '8117', Name: 'Stover, blast furnace' },
  { Id: 20257, Code: '8117', Name: 'Stover, iron foundry' },
  { Id: 20258, Code: '8117', Name: 'Straightener, bar' },
  { Id: 20259, Code: '8117', Name: 'Straightener, drill' },
  { Id: 20260, Code: '8117', Name: 'Straightener, iron' },
  { Id: 20261, Code: '8117', Name: 'Straightener, metal mfr' },
  { Id: 20262, Code: '8117', Name: 'Straightener, mills, rod' },
  { Id: 20263, Code: '8117', Name: 'Straightener, plate' },
  { Id: 20264, Code: '8117', Name: 'Straightener, rail' },
  { Id: 20265, Code: '8117', Name: 'Straightener, roller' },
  { Id: 20266, Code: '8117', Name: 'Straightener, section' },
  { Id: 20267, Code: '8117', Name: 'Straightener, shaft, crank' },
  { Id: 20268, Code: '8117', Name: 'Straightener, steel' },
  { Id: 20269, Code: '8117', Name: 'Straightener, tube' },
  { Id: 20270, Code: '8117', Name: 'Stretcher, metal mfr' },
  { Id: 20271, Code: '8117', Name: 'Stretcher, tube' },
  { Id: 20272, Code: '8117', Name: 'Striker, anvil' },
  { Id: 20273, Code: '8117', Name: 'Striker, chain' },
  { Id: 20274, Code: '8117', Name: 'Striker, coal mine' },
  { Id: 20275, Code: '8117', Name: 'Striker, forge' },
  { Id: 20276, Code: '8117', Name: "Striker, forger's" },
  { Id: 20277, Code: '8117', Name: 'Striker, iron' },
  { Id: 20278, Code: '8117', Name: 'Striker, metal trades' },
  { Id: 20279, Code: '8117', Name: 'Striker, railways' },
  { Id: 20280, Code: '8117', Name: "Striker, smith's" },
  { Id: 20281, Code: '8117', Name: 'Striker, wheel' },
  { Id: 20282, Code: '8117', Name: 'Stripper, gold' },
  { Id: 20283, Code: '8117', Name: 'Stripper, gold refining' },
  { Id: 20284, Code: '8117', Name: 'Stripper, ingot' },
  { Id: 20285, Code: '8117', Name: 'Stripper, silver' },
  { Id: 20286, Code: '8117', Name: 'Stripper, wire drawing' },
  { Id: 20287, Code: '8117', Name: 'Swiftman' },
  { Id: 20288, Code: '8117', Name: 'Tagger, steel mfr' },
  { Id: 20289, Code: '8117', Name: 'Tagger, tube mfr' },
  { Id: 20290, Code: '8117', Name: 'Taker, temperature' },
  { Id: 20291, Code: '8117', Name: 'Tankman, non-ferrous metal mfr' },
  { Id: 20292, Code: '8117', Name: 'Tapper, iron and steelworks' },
  { Id: 20293, Code: '8117', Name: 'Tapper-out' },
  { Id: 20294, Code: '8117', Name: 'Teazer, metal trades' },
  { Id: 20295, Code: '8117', Name: 'Teemer, ladle' },
  { Id: 20296, Code: '8117', Name: 'Teemer, steelworks' },
  { Id: 20297, Code: '8117', Name: 'Temperer, metal trades' },
  { Id: 20298, Code: '8117', Name: 'Temperer, wire' },
  { Id: 20299, Code: '8117', Name: 'Tender, furnace, metal trades' },
  { Id: 20300, Code: '8117', Name: 'Tenter, cupola' },
  { Id: 20301, Code: '8117', Name: 'Tenter, furnace' },
  { Id: 20302, Code: '8117', Name: 'Tester, furnace' },
  { Id: 20303, Code: '8117', Name: 'Threader, needle mfr' },
  { Id: 20304, Code: '8117', Name: 'Tilter' },
  { Id: 20305, Code: '8117', Name: 'Tilterman' },
  { Id: 20306, Code: '8117', Name: 'Tonger, WIRE' },
  { Id: 20307, Code: '8117', Name: 'Tongsman, back' },
  { Id: 20308, Code: '8117', Name: 'Tongsman, rolling mill' },
  { Id: 20309, Code: '8117', Name: 'Tongsman, steel hoop mill' },
  { Id: 20310, Code: '8117', Name: 'Tongsman, wrought iron mfr' },
  { Id: 20311, Code: '8117', Name: 'Tonguer' },
  { Id: 20312, Code: '8117', Name: 'Trailer-down, rolling mill' },
  { Id: 20313, Code: '8117', Name: 'Treater, heat' },
  { Id: 20314, Code: '8117', Name: 'Tuber, wire mfr' },
  { Id: 20315, Code: '8117', Name: 'Tuber, wire, wire mfr' },
  { Id: 20316, Code: '8117', Name: 'Tupper, steel mfr' },
  { Id: 20317, Code: '8117', Name: 'Turner, core' },
  { Id: 20318, Code: '8117', Name: 'Uncoiler, tinplate mfr' },
  { Id: 20319, Code: '8117', Name: 'Unreeler, steel mfr' },
  { Id: 20320, Code: '8117', Name: 'Washer, metal trades' },
  { Id: 20321, Code: '8117', Name: 'Watcher, furnace' },
  { Id: 20322, Code: '8117', Name: 'Watcher, metal mfr' },
  { Id: 20323, Code: '8117', Name: 'Wheelman, copper refining' },
  { Id: 20324, Code: '8117', Name: 'Wirer-up, electroplating' },
  { Id: 20325, Code: '8117', Name: 'Worker, aluminium' },
  { Id: 20326, Code: '8117', Name: 'Worker, forge' },
  { Id: 20327, Code: '8117', Name: 'Worker, furnace, blast' },
  { Id: 20328, Code: '8117', Name: 'Worker, furnace, metal trades' },
  { Id: 20329, Code: '8117', Name: 'Worker, hammer, power' },
  { Id: 20330, Code: '8117', Name: 'Worker, lead, tea' },
  { Id: 20331, Code: '8117', Name: 'Worker, mill, metal mfr' },
  { Id: 20332, Code: '8117', Name: 'Worker, mill, rolling' },
  { Id: 20333, Code: '8117', Name: 'Worker, mill, sheet' },
  { Id: 20334, Code: '8117', Name: 'Worker, muffle, annealing' },
  { Id: 20335, Code: '8117', Name: 'Worker, muffle, foundry' },
  { Id: 20336, Code: '8117', Name: 'Worker, plant, sinter' },
  { Id: 20337, Code: '8117', Name: 'Worker, plate, tin, tinplate mfr' },
  { Id: 20338, Code: '8117', Name: 'Worker, process, nickel mfr' },
  { Id: 20339, Code: '8117', Name: 'Worker, process, steelworks' },
  { Id: 20340, Code: '8117', Name: 'Worker, strip, copper' },
  { Id: 20341, Code: '8117', Name: 'Worker, tinplate, tinplate mfr' },
  { Id: 20342, Code: '8117', Name: 'Worker, treatment, heat, METAL' },
  { Id: 20343, Code: '8117', Name: 'Worker, treatment, heat, metal trades' },
  { Id: 20344, Code: '8117', Name: 'Worker, tube, STEEL' },
  { Id: 20345, Code: '8117', Name: 'Worker, wire, tungsten' },
  { Id: 20346, Code: '8118', Name: 'Anodiser' },
  { Id: 20347, Code: '8118', Name: 'Assistant, dip, hot' },
  { Id: 20348, Code: '8118', Name: "Assistant, silverer's" },
  { Id: 20349, Code: '8118', Name: 'Attendant, anode' },
  { Id: 20350, Code: '8118', Name: 'Attendant, bath, copper, glass mfr' },
  { Id: 20351, Code: '8118', Name: 'Attendant, baths, copper, glass mfr' },
  { Id: 20352, Code: '8118', Name: 'Attendant, electrolytic' },
  { Id: 20353, Code: '8118', Name: 'Backer, mirror' },
  { Id: 20354, Code: '8118', Name: 'Bonderiser' },
  { Id: 20355, Code: '8118', Name: 'Bronzer' },
  { Id: 20356, Code: '8118', Name: 'Bronzer, metal' },
  { Id: 20357, Code: '8118', Name: 'Browner' },
  { Id: 20358, Code: '8118', Name: 'Changer, gold' },
  { Id: 20359, Code: '8118', Name: 'Chromer, metal trades' },
  { Id: 20360, Code: '8118', Name: 'Cleaner, galvanised sheet mfr' },
  { Id: 20361, Code: '8118', Name: 'Coater, colour' },
  { Id: 20362, Code: '8118', Name: 'Coater, powder' },
  { Id: 20363, Code: '8118', Name: 'Coater, tinplate mfr' },
  { Id: 20364, Code: '8118', Name: 'Coater, wire mfr' },
  { Id: 20365, Code: '8118', Name: 'Cutter-through, steelworks' },
  { Id: 20366, Code: '8118', Name: 'Depositor, electroplating' },
  { Id: 20367, Code: '8118', Name: 'Dipper, arc welding electrode mfr' },
  { Id: 20368, Code: '8118', Name: 'Dipper, galvanising' },
  { Id: 20369, Code: '8118', Name: 'Dipper, metal' },
  { Id: 20370, Code: '8118', Name: 'Dipper, precious metal, plate mfr' },
  { Id: 20371, Code: '8118', Name: 'Dipper, wire' },
  { Id: 20372, Code: '8118', Name: 'Electro-brasser, SCREWS' },
  { Id: 20373, Code: '8118', Name: 'Electroformer' },
  { Id: 20374, Code: '8118', Name: 'Electroplater' },
  { Id: 20375, Code: '8118', Name: 'Finisher, chromium' },
  { Id: 20376, Code: '8118', Name: 'Foreman, electroplating' },
  { Id: 20377, Code: '8118', Name: 'Galvaniser' },
  { Id: 20378, Code: '8118', Name: 'Gilder and plater' },
  { Id: 20379, Code: '8118', Name: 'Hand, vat, electroplating' },
  { Id: 20380, Code: '8118', Name: 'Illuminiser' },
  { Id: 20381, Code: '8118', Name: 'Machinist, gold' },
  { Id: 20382, Code: '8118', Name: 'Machinist, plating, metal trades' },
  { Id: 20383, Code: '8118', Name: 'Machinist, plating, wire' },
  { Id: 20384, Code: '8118', Name: 'Man, tin, tinplate mfr' },
  { Id: 20385, Code: '8118', Name: 'Metalliser, lamp, valve mfr' },
  { Id: 20386, Code: '8118', Name: 'Metalliser, spray' },
  { Id: 20387, Code: '8118', Name: 'Minder, cell, galvanising' },
  { Id: 20388, Code: '8118', Name: 'Operator, dip, metal trades' },
  { Id: 20389, Code: '8118', Name: 'Operator, house, tin, TINPLATE' },
  { Id: 20390, Code: '8118', Name: 'Operator, plant, plating' },
  { Id: 20391, Code: '8118', Name: 'Operator, plant, vacuum, METALLISATION' },
  { Id: 20392, Code: '8118', Name: 'Operator, plating' },
  { Id: 20393, Code: '8118', Name: 'Operator, vat, metal mfr' },
  { Id: 20394, Code: '8118', Name: 'Oxidiser, metal trades' },
  { Id: 20395, Code: '8118', Name: 'Phosphater' },
  { Id: 20396, Code: '8118', Name: 'Plater and gilder' },
  { Id: 20397, Code: '8118', Name: 'Plater, barrel' },
  { Id: 20398, Code: '8118', Name: 'Plater, brass' },
  { Id: 20399, Code: '8118', Name: 'Plater, cadmium' },
  { Id: 20400, Code: '8118', Name: 'Plater, chrome' },
  { Id: 20401, Code: '8118', Name: 'Plater, chromium' },
  { Id: 20402, Code: '8118', Name: 'Plater, copper' },
  { Id: 20403, Code: '8118', Name: 'Plater, dip' },
  { Id: 20404, Code: '8118', Name: 'Plater, electro' },
  { Id: 20405, Code: '8118', Name: 'Plater, electroplating' },
  { Id: 20406, Code: '8118', Name: 'Plater, gold' },
  { Id: 20407, Code: '8118', Name: 'Plater, hand' },
  { Id: 20408, Code: '8118', Name: 'Plater, lock' },
  { Id: 20409, Code: '8118', Name: 'Plater, metal' },
  { Id: 20410, Code: '8118', Name: 'Plater, metal, white' },
  { Id: 20411, Code: '8118', Name: 'Plater, needle' },
  { Id: 20412, Code: '8118', Name: 'Plater, nickel' },
  { Id: 20413, Code: '8118', Name: 'Plater, silver' },
  { Id: 20414, Code: '8118', Name: 'Plater, tin' },
  {
    Id: 20415,
    Code: '8118',
    Name: 'Plater, tool, edge, surgical instrument mfr'
  },
  { Id: 20416, Code: '8118', Name: 'Plater, wire' },
  { Id: 20417, Code: '8118', Name: 'Proofer, rust' },
  { Id: 20418, Code: '8118', Name: 'Silverer, electroplating' },
  { Id: 20419, Code: '8118', Name: 'Silverer, glass mfr' },
  { Id: 20420, Code: '8118', Name: 'Sprayer, coat, powder' },
  { Id: 20421, Code: '8118', Name: 'Sprayer, electroplating' },
  { Id: 20422, Code: '8118', Name: 'Sprayer, metal' },
  { Id: 20423, Code: '8118', Name: 'Stripper, electroplating' },
  { Id: 20424, Code: '8118', Name: 'Tanker, galvanised sheet mfr' },
  { Id: 20425, Code: '8118', Name: 'Tinner, coil' },
  { Id: 20426, Code: '8118', Name: 'Tinner, copper' },
  { Id: 20427, Code: '8118', Name: 'Tinner, grease' },
  { Id: 20428, Code: '8118', Name: 'Tinner, metal trades' },
  { Id: 20429, Code: '8118', Name: 'Tinner, wire' },
  { Id: 20430, Code: '8118', Name: 'Tinplater, tinplate mfr' },
  { Id: 20431, Code: '8118', Name: 'Vatman, wire mfr' },
  { Id: 20432, Code: '8118', Name: 'Worker, nickel, electroplating' },
  { Id: 20433, Code: '8119', Name: 'Assistant, kiln' },
  { Id: 20434, Code: '8119', Name: 'Assistant, pot, melting, ELECTRIC CABLE' },
  { Id: 20435, Code: '8119', Name: 'Attendant, calender, LINOLEUM' },
  { Id: 20436, Code: '8119', Name: 'Attendant, dryer, MACADAM' },
  { Id: 20437, Code: '8119', Name: 'Attendant, dryer, PLASTERBOARD' },
  { Id: 20438, Code: '8119', Name: 'Attendant, grinder, CEMENT' },
  { Id: 20439, Code: '8119', Name: 'Attendant, house, slip' },
  { Id: 20440, Code: '8119', Name: 'Attendant, kiln, cement mfr' },
  { Id: 20441, Code: '8119', Name: 'Attendant, magazine, plasterboard' },
  { Id: 20442, Code: '8119', Name: 'Attendant, mill, cement mfr' },
  { Id: 20443, Code: '8119', Name: 'Attendant, mill, mortar' },
  { Id: 20444, Code: '8119', Name: 'Attendant, mixer, concrete' },
  { Id: 20445, Code: '8119', Name: 'Backer, linoleum mfr' },
  { Id: 20446, Code: '8119', Name: 'Batcher, concrete' },
  { Id: 20447, Code: '8119', Name: 'Beaterman, asbestos-cement mfr' },
  { Id: 20448, Code: '8119', Name: 'Bedman, asphalt mfr' },
  { Id: 20449, Code: '8119', Name: 'Blender, cast stone products mfr' },
  { Id: 20450, Code: '8119', Name: 'Blender, linoleum mfr' },
  { Id: 20451, Code: '8119', Name: 'Breaker, ore, blast furnace' },
  { Id: 20452, Code: '8119', Name: 'Breaker, stone' },
  { Id: 20453, Code: '8119', Name: 'Burner, cement mfr' },
  { Id: 20454, Code: '8119', Name: 'Burner, kiln, cement mfr' },
  { Id: 20455, Code: '8119', Name: 'Burner, lime' },
  { Id: 20456, Code: '8119', Name: 'Burner, lime burning' },
  { Id: 20457, Code: '8119', Name: 'Calciner, mine: not coal' },
  { Id: 20458, Code: '8119', Name: 'Calenderman, linoleum mfr' },
  { Id: 20459, Code: '8119', Name: 'Caster, cast stone products mfr' },
  { Id: 20460, Code: '8119', Name: 'Caster, concrete' },
  { Id: 20461, Code: '8119', Name: 'Caster, slab' },
  { Id: 20462, Code: '8119', Name: 'Caster, stone' },
  { Id: 20463, Code: '8119', Name: 'Caster, tile' },
  { Id: 20464, Code: '8119', Name: 'Catcher, pole, LINOLEUM' },
  { Id: 20465, Code: '8119', Name: 'Charger, blunger' },
  { Id: 20466, Code: '8119', Name: 'Charger, linoleum mfr' },
  { Id: 20467, Code: '8119', Name: 'Charger, slag wool mfr' },
  { Id: 20468, Code: '8119', Name: 'Clampman, fire brick mfr' },
  { Id: 20469, Code: '8119', Name: 'Cleaner, asbestos-cement mfr' },
  { Id: 20470, Code: '8119', Name: 'Cleaner, gut' },
  { Id: 20471, Code: '8119', Name: 'Coater, linoleum mfr' },
  { Id: 20472, Code: '8119', Name: 'Cook, mastic, asphalt mfr' },
  { Id: 20473, Code: '8119', Name: 'Crusher, bone' },
  { Id: 20474, Code: '8119', Name: 'Crusher, coal' },
  { Id: 20475, Code: '8119', Name: 'Crusher, MINERALS' },
  {
    Id: 20476,
    Code: '8119',
    Name: 'Cutter, guillotine, asbestos-cement goods mfr'
  },
  { Id: 20477, Code: '8119', Name: 'Cutter, linoleum mfr' },
  { Id: 20478, Code: '8119', Name: 'Drawer, lime, lime burning' },
  { Id: 20479, Code: '8119', Name: 'Dresser, concrete' },
  { Id: 20480, Code: '8119', Name: 'Dresser, gypsum' },
  { Id: 20481, Code: '8119', Name: 'Dresser, lime' },
  { Id: 20482, Code: '8119', Name: 'Dresser, stone, concrete products mfr' },
  { Id: 20483, Code: '8119', Name: 'Driller, asbestos' },
  { Id: 20484, Code: '8119', Name: 'Driller, asbestos composition goods mfr' },
  { Id: 20485, Code: '8119', Name: 'Driller, asbestos goods mfr' },
  { Id: 20486, Code: '8119', Name: 'Driver, mixer, concrete' },
  { Id: 20487, Code: '8119', Name: 'Dryer, clay' },
  { Id: 20488, Code: '8119', Name: 'Dryer, metal trades' },
  { Id: 20489, Code: '8119', Name: 'Dryer, ore' },
  { Id: 20490, Code: '8119', Name: 'Dryer, pearl' },
  { Id: 20491, Code: '8119', Name: 'Dryer, salt' },
  { Id: 20492, Code: '8119', Name: 'Dryer, sand' },
  { Id: 20493, Code: '8119', Name: 'Fabricator, cast stone products mfr' },
  { Id: 20494, Code: '8119', Name: 'Feeder, clay' },
  { Id: 20495, Code: '8119', Name: 'Feeder, mill' },
  { Id: 20496, Code: '8119', Name: 'Feeder, pan' },
  { Id: 20497, Code: '8119', Name: 'Festooner, linoleum mfr' },
  { Id: 20498, Code: '8119', Name: 'Festooner, oilskin mfr' },
  { Id: 20499, Code: '8119', Name: 'Fettler, cast concrete products mfr' },
  { Id: 20500, Code: '8119', Name: 'Filler, cast concrete products mfr' },
  { Id: 20501, Code: '8119', Name: 'Finisher, asbestos-cement goods mfr' },
  { Id: 20502, Code: '8119', Name: 'Finisher, cast concrete products mfr' },
  { Id: 20503, Code: '8119', Name: 'Finisher, concrete' },
  {
    Id: 20504,
    Code: '8119',
    Name: 'Finisher, pipe, cast concrete products mfr'
  },
  { Id: 20505, Code: '8119', Name: 'Finisher, post, CONCRETE' },
  {
    Id: 20506,
    Code: '8119',
    Name: 'Finisher, stone, cast concrete products mfr'
  },
  { Id: 20507, Code: '8119', Name: 'Fireman, abrasives mfr' },
  { Id: 20508, Code: '8119', Name: 'Firer, kiln' },
  { Id: 20509, Code: '8119', Name: 'Foiler, plasterboard mfr' },
  { Id: 20510, Code: '8119', Name: 'Foreman, asbestos-cement goods mfr' },
  { Id: 20511, Code: '8119', Name: 'Foreman, asphalt mfr' },
  { Id: 20512, Code: '8119', Name: 'Foreman, brake linings mfr' },
  { Id: 20513, Code: '8119', Name: 'Foreman, cast concrete products mfr' },
  { Id: 20514, Code: '8119', Name: 'Foreman, cement mfr' },
  { Id: 20515, Code: '8119', Name: 'Foreman, concrete mfr' },
  { Id: 20516, Code: '8119', Name: 'Foreman, linoleum mfr' },
  { Id: 20517, Code: '8119', Name: 'Foreman, plant, mixing, asphalt mfr' },
  { Id: 20518, Code: '8119', Name: 'Foreman, plasterboard mfr' },
  { Id: 20519, Code: '8119', Name: 'Fritter' },
  { Id: 20520, Code: '8119', Name: 'Furnaceman, calcining, FLINT' },
  { Id: 20521, Code: '8119', Name: 'Gauger, cast stone products mfr' },
  { Id: 20522, Code: '8119', Name: 'Gauger, gut' },
  { Id: 20523, Code: '8119', Name: 'Grinder, cast concrete products mfr' },
  { Id: 20524, Code: '8119', Name: 'Grinder, compo, metal mfr' },
  { Id: 20525, Code: '8119', Name: 'Grinder, composition, metal mfr' },
  {
    Id: 20526,
    Code: '8119',
    Name: 'Grinder, cutter, cemented carbide goods mfr'
  },
  { Id: 20527, Code: '8119', Name: 'Grinder, dust' },
  { Id: 20528, Code: '8119', Name: 'Grinder, sand' },
  { Id: 20529, Code: '8119', Name: 'Grinder, silica' },
  { Id: 20530, Code: '8119', Name: 'Hand, calender, linoleum mfr' },
  { Id: 20531, Code: '8119', Name: 'Hand, furnace, mine: not coal' },
  {
    Id: 20532,
    Code: '8119',
    Name: 'Hand, guillotine, asbestos-cement goods mfr'
  },
  { Id: 20533, Code: '8119', Name: 'Hand, house, slip' },
  { Id: 20534, Code: '8119', Name: 'Hand, mill' },
  { Id: 20535, Code: '8119', Name: 'Hand, mill, wash, CEMENT' },
  { Id: 20536, Code: '8119', Name: 'Hand, sliphouse' },
  { Id: 20537, Code: '8119', Name: 'Hanger, lino, linoleum mfr' },
  { Id: 20538, Code: '8119', Name: 'Hanger, linoleum, linoleum mfr' },
  { Id: 20539, Code: '8119', Name: 'Heatman, linoleum mfr' },
  { Id: 20540, Code: '8119', Name: 'Hopperman, cement mfr' },
  { Id: 20541, Code: '8119', Name: 'Houseman, slip' },
  { Id: 20542, Code: '8119', Name: 'Hydrator, lime' },
  {
    Id: 20543,
    Code: '8119',
    Name: 'Loader, mould, asbestos composition goods mfr'
  },
  { Id: 20544, Code: '8119', Name: 'Machinist, candle mfr' },
  { Id: 20545, Code: '8119', Name: 'Machinist, concrete mfr' },
  {
    Id: 20546,
    Code: '8119',
    Name: 'Machinist, embossing, floor and leather cloth mfr'
  },
  { Id: 20547, Code: '8119', Name: 'Machinist, grinding, cement mfr' },
  { Id: 20548, Code: '8119', Name: 'Machinist, linoleum mfr' },
  { Id: 20549, Code: '8119', Name: 'Machinist, making, plasterboard mfr' },
  { Id: 20550, Code: '8119', Name: 'Machinist, shaking' },
  {
    Id: 20551,
    Code: '8119',
    Name: 'Machinist, washing and mixing, abrasives mfr'
  },
  { Id: 20552, Code: '8119', Name: 'Machinist, winding, oilskin mfr' },
  { Id: 20553, Code: '8119', Name: 'Maker, asphalt' },
  { Id: 20554, Code: '8119', Name: 'Maker, block, asphalt' },
  { Id: 20555, Code: '8119', Name: 'Maker, block, cement' },
  { Id: 20556, Code: '8119', Name: 'Maker, block, concrete' },
  { Id: 20557, Code: '8119', Name: 'Maker, cast concrete products mfr' },
  { Id: 20558, Code: '8119', Name: 'Maker, cement' },
  { Id: 20559, Code: '8119', Name: 'Maker, clay' },
  { Id: 20560, Code: '8119', Name: 'Maker, concrete' },
  { Id: 20561, Code: '8119', Name: 'Maker, curb, cast concrete products mfr' },
  { Id: 20562, Code: '8119', Name: 'Maker, flag, cast concrete products mfr' },
  {
    Id: 20563,
    Code: '8119',
    Name: 'Maker, kerbstone, cast concrete products mfr'
  },
  { Id: 20564, Code: '8119', Name: 'Maker, lino' },
  { Id: 20565, Code: '8119', Name: 'Maker, linoleum' },
  { Id: 20566, Code: '8119', Name: 'Maker, mash, leathercloth mfr' },
  { Id: 20567, Code: '8119', Name: 'Maker, panel, PLASTER' },
  { Id: 20568, Code: '8119', Name: 'Maker, post, CONCRETE' },
  { Id: 20569, Code: '8119', Name: 'Maker, putty' },
  { Id: 20570, Code: '8119', Name: 'Maker, slab, cast concrete products mfr' },
  { Id: 20571, Code: '8119', Name: 'Maker, stone, composition' },
  { Id: 20572, Code: '8119', Name: 'Maker, stone, concrete, PRECAST' },
  {
    Id: 20573,
    Code: '8119',
    Name: 'Maker, stone, kerb, cast concrete products mfr'
  },
  { Id: 20574, Code: '8119', Name: 'Maker, tile, ASBESTOS-CEMENT' },
  { Id: 20575, Code: '8119', Name: 'Maker, tile, CONCRETE' },
  { Id: 20576, Code: '8119', Name: 'Man, back-end, cement mfr' },
  { Id: 20577, Code: '8119', Name: 'Man, boiling, tar, cable mfr' },
  { Id: 20578, Code: '8119', Name: 'Man, dolomite, iron and steelworks' },
  { Id: 20579, Code: '8119', Name: 'Man, dry, CHINA CLAY' },
  { Id: 20580, Code: '8119', Name: 'Man, floor, asphalt mfr' },
  { Id: 20581, Code: '8119', Name: 'Man, kiln, abrasive wheel mfr' },
  { Id: 20582, Code: '8119', Name: 'Man, kiln, cement mfr' },
  { Id: 20583, Code: '8119', Name: 'Man, kiln, dry' },
  { Id: 20584, Code: '8119', Name: 'Man, kiln, gypsum' },
  { Id: 20585, Code: '8119', Name: 'Man, kiln, lime' },
  { Id: 20586, Code: '8119', Name: "Man, mill, potter's" },
  { Id: 20587, Code: '8119', Name: 'Man, mill, pug' },
  { Id: 20588, Code: '8119', Name: 'Man, mill, whiting mfr' },
  { Id: 20589, Code: '8119', Name: 'Man, mixer, asphalt' },
  { Id: 20590, Code: '8119', Name: 'Man, mixer, building construction' },
  { Id: 20591, Code: '8119', Name: 'Man, paper, plasterboard mfr' },
  { Id: 20592, Code: '8119', Name: 'Man, pug' },
  { Id: 20593, Code: '8119', Name: 'Man, slaker' },
  { Id: 20594, Code: '8119', Name: 'Man, tank, cable' },
  { Id: 20595, Code: '8119', Name: 'Manufacturer, cast concrete products mfr' },
  { Id: 20596, Code: '8119', Name: 'Matcher, colour, linoleum mfr' },
  { Id: 20597, Code: '8119', Name: 'Miller, cement' },
  { Id: 20598, Code: '8119', Name: 'Miller, cement mfr' },
  { Id: 20599, Code: '8119', Name: 'Miller, coal, cement mfr' },
  { Id: 20600, Code: '8119', Name: 'Miller, gypsum' },
  { Id: 20601, Code: '8119', Name: 'Miller, lime' },
  { Id: 20602, Code: '8119', Name: 'Miller, wash, cement mfr' },
  { Id: 20603, Code: '8119', Name: 'Miller, wet, cement mfr' },
  { Id: 20604, Code: '8119', Name: 'Mixer, artificial teeth mfr' },
  { Id: 20605, Code: '8119', Name: 'Mixer, asphalt' },
  { Id: 20606, Code: '8119', Name: 'Mixer, cast concrete products mfr' },
  { Id: 20607, Code: '8119', Name: 'Mixer, cement mfr' },
  { Id: 20608, Code: '8119', Name: 'Mixer, cement, building construction' },
  { Id: 20609, Code: '8119', Name: 'Mixer, cemented carbide goods mfr' },
  { Id: 20610, Code: '8119', Name: 'Mixer, concrete' },
  { Id: 20611, Code: '8119', Name: 'Mixer, glass' },
  { Id: 20612, Code: '8119', Name: 'Mixer, lino' },
  { Id: 20613, Code: '8119', Name: 'Mixer, linoleum' },
  { Id: 20614, Code: '8119', Name: 'Mixer, macadam' },
  { Id: 20615, Code: '8119', Name: 'Mixer, marl' },
  { Id: 20616, Code: '8119', Name: 'Mixer, putty' },
  { Id: 20617, Code: '8119', Name: 'Mixer, slurry, cement mfr' },
  { Id: 20618, Code: '8119', Name: 'Mixer, tarmacadam mfr' },
  { Id: 20619, Code: '8119', Name: 'Moulder, asbestos-cement goods mfr' },
  { Id: 20620, Code: '8119', Name: 'Moulder, cast concrete products mfr' },
  { Id: 20621, Code: '8119', Name: 'Moulder, compo' },
  { Id: 20622, Code: '8119', Name: 'Moulder, concrete' },
  { Id: 20623, Code: '8119', Name: 'Moulder, hand, asbestos goods mfr' },
  {
    Id: 20624,
    Code: '8119',
    Name: 'Operative, concrete, concrete products mfr'
  },
  { Id: 20625, Code: '8119', Name: 'Operator, drier, PLASTERBOARD' },
  { Id: 20626, Code: '8119', Name: 'Operator, hydrate' },
  { Id: 20627, Code: '8119', Name: 'Operator, kiln' },
  { Id: 20628, Code: '8119', Name: 'Operator, mill, mortar' },
  {
    Id: 20629,
    Code: '8119',
    Name: 'Operator, mixer, cast concrete products mfr'
  },
  { Id: 20630, Code: '8119', Name: 'Operator, plant, concrete' },
  { Id: 20631, Code: '8119', Name: 'Operator, room, drying' },
  { Id: 20632, Code: '8119', Name: 'Packer, sagger' },
  { Id: 20633, Code: '8119', Name: 'Plasterer, cast concrete products mfr' },
  { Id: 20634, Code: '8119', Name: 'Plugger, stoneware pipe mfr' },
  {
    Id: 20635,
    Code: '8119',
    Name: 'Polisher, tile, asbestos-cement goods mfr'
  },
  { Id: 20636, Code: '8119', Name: 'Presser, cemented carbide goods mfr' },
  { Id: 20637, Code: '8119', Name: 'Presser, hot, cemented carbide goods mfr' },
  { Id: 20638, Code: '8119', Name: 'Presser, lining, brake' },
  { Id: 20639, Code: '8119', Name: 'Presser, washer' },
  { Id: 20640, Code: '8119', Name: 'Pugger' },
  { Id: 20641, Code: '8119', Name: 'Roaster, MINERALS' },
  { Id: 20642, Code: '8119', Name: 'Sawyer, asbestos-cement goods mfr' },
  { Id: 20643, Code: '8119', Name: 'Scraper, gut' },
  { Id: 20644, Code: '8119', Name: 'Scratcher, linoleum mfr' },
  { Id: 20645, Code: '8119', Name: 'Screener, iron shot and grit mfr' },
  { Id: 20646, Code: '8119', Name: 'Selector, gut' },
  {
    Id: 20647,
    Code: '8119',
    Name: 'Sheeter, asbestos, asbestos-cement goods mfr'
  },
  { Id: 20648, Code: '8119', Name: 'Slimer, gut cleaning' },
  { Id: 20649, Code: '8119', Name: 'Slugger, paste' },
  { Id: 20650, Code: '8119', Name: 'Spinner, concrete' },
  { Id: 20651, Code: '8119', Name: 'Spinner, gut' },
  { Id: 20652, Code: '8119', Name: 'Spinner, gut processing' },
  {
    Id: 20653,
    Code: '8119',
    Name: 'Spinner, pipe, cast concrete products mfr'
  },
  { Id: 20654, Code: '8119', Name: 'Spinner, pot, silicon carbide mfr' },
  { Id: 20655, Code: '8119', Name: 'Splitter, gut' },
  { Id: 20656, Code: '8119', Name: 'Splitter, gut dressing' },
  { Id: 20657, Code: '8119', Name: 'Sprayer, asbestos' },
  { Id: 20658, Code: '8119', Name: 'Spreader, linoleum mfr' },
  { Id: 20659, Code: '8119', Name: 'Steamer, pipe, spun' },
  { Id: 20660, Code: '8119', Name: 'Stoker, kiln, lime' },
  { Id: 20661, Code: '8119', Name: 'Stover' },
  { Id: 20662, Code: '8119', Name: 'Stripper, furniture' },
  { Id: 20663, Code: '8119', Name: 'Stripper, pine' },
  { Id: 20664, Code: '8119', Name: 'Taker-off, cast concrete products mfr' },
  { Id: 20665, Code: '8119', Name: 'Taker-off, plasterboard mfr' },
  { Id: 20666, Code: '8119', Name: 'Temperer, blast furnace' },
  { Id: 20667, Code: '8119', Name: 'Trimmer, cement mfr' },
  { Id: 20668, Code: '8119', Name: 'Turner, asbestos-cement goods mfr' },
  { Id: 20669, Code: '8119', Name: 'Twister, gut' },
  { Id: 20670, Code: '8119', Name: 'Worker, block, concrete' },
  { Id: 20671, Code: '8119', Name: 'Worker, cement' },
  { Id: 20672, Code: '8119', Name: 'Worker, concrete, concrete products mfr' },
  { Id: 20673, Code: '8119', Name: 'Worker, concrete, pre-cast' },
  { Id: 20674, Code: '8119', Name: 'Worker, house, slip' },
  { Id: 20675, Code: '8119', Name: 'Worker, kiln, lime burning' },
  { Id: 20676, Code: '8119', Name: 'Worker, mill, grog' },
  { Id: 20677, Code: '8119', Name: 'Worker, process, abrasives mfr' },
  { Id: 20678, Code: '8119', Name: 'Worker, process, cable mfr' },
  {
    Id: 20679,
    Code: '8119',
    Name: 'Worker, process, cast concrete products mfr'
  },
  {
    Id: 20680,
    Code: '8119',
    Name: 'Worker, process, cemented carbide goods mfr'
  },
  { Id: 20681, Code: '8119', Name: 'Worker, process, electrical engineering' },
  { Id: 20682, Code: '8119', Name: 'Worker, process, jewellery, plate mfr' },
  { Id: 20683, Code: '8119', Name: 'Worker, process, linoleum mfr' },
  {
    Id: 20684,
    Code: '8119',
    Name: 'Worker, stone, cast concrete products mfr'
  },
  { Id: 20685, Code: '8119', Name: 'Worker, tile, cast concrete products mfr' },
  { Id: 20686, Code: '8121', Name: 'Adjuster, envelope' },
  { Id: 20687, Code: '8121', Name: 'Adjuster, machine, paper goods mfr' },
  { Id: 20688, Code: '8121', Name: "Assistant, beaterman's" },
  { Id: 20689, Code: '8121', Name: "Assistant, beater's" },
  { Id: 20690, Code: '8121', Name: 'Assistant, calender, paper mfr' },
  { Id: 20691, Code: '8121', Name: "Assistant, calenderman's, paper mfr" },
  { Id: 20692, Code: '8121', Name: "Assistant, cutter's, paper" },
  { Id: 20693, Code: '8121', Name: "Assistant, cutter's, paper mfr" },
  { Id: 20694, Code: '8121', Name: "Assistant, cutter's, paper pattern mfr" },
  { Id: 20695, Code: '8121', Name: "Assistant, dryerman's, PAPER" },
  { Id: 20696, Code: '8121', Name: 'Assistant, hydropulper' },
  { Id: 20697, Code: '8121', Name: 'Assistant, laminating, paper mfr' },
  { Id: 20698, Code: '8121', Name: "Assistant, man's, machine, paper mfr" },
  { Id: 20699, Code: '8121', Name: "Assistant, reelerman's, paper mfr" },
  { Id: 20700, Code: '8121', Name: "Assistant, slitter's, FILMS" },
  { Id: 20701, Code: '8121', Name: "Assistant, tender's, machine, pasteboard" },
  { Id: 20702, Code: '8121', Name: 'Attendant, calender, PAPER' },
  { Id: 20703, Code: '8121', Name: 'Attendant, conditioner, paper mfr' },
  { Id: 20704, Code: '8121', Name: 'Attendant, creel, sisal' },
  { Id: 20705, Code: '8121', Name: 'Attendant, felt, paper mfr' },
  { Id: 20706, Code: '8121', Name: 'Attendant, grainer, paper mfr' },
  { Id: 20707, Code: '8121', Name: 'Attendant, humidifier' },
  { Id: 20708, Code: '8121', Name: 'Attendant, mill, saw' },
  { Id: 20709, Code: '8121', Name: 'Attendant, mill, wood' },
  { Id: 20710, Code: '8121', Name: 'Attendant, thickener' },
  { Id: 20711, Code: '8121', Name: 'Backer, paper mfr' },
  { Id: 20712, Code: '8121', Name: 'Backer, paper, stencil' },
  { Id: 20713, Code: '8121', Name: 'Bander, cardboard box mfr' },
  { Id: 20714, Code: '8121', Name: 'Beater, paper mill' },
  { Id: 20715, Code: '8121', Name: 'Beaterman, paper mfr' },
  { Id: 20716, Code: '8121', Name: 'Bender and slotter, CARDBOARD' },
  { Id: 20717, Code: '8121', Name: 'Bender, cardboard box mfr' },
  { Id: 20718, Code: '8121', Name: 'Binder, cardboard box mfr' },
  { Id: 20719, Code: '8121', Name: 'Bitter, cardboard box mfr' },
  { Id: 20720, Code: '8121', Name: 'Bleacher, PAPER' },
  { Id: 20721, Code: '8121', Name: 'Bleacher, WOOD PULP' },
  { Id: 20722, Code: '8121', Name: 'Blocker, wood heel mfr' },
  { Id: 20723, Code: '8121', Name: 'Boiler, paper mfr' },
  { Id: 20724, Code: '8121', Name: 'Boiler, rag' },
  { Id: 20725, Code: '8121', Name: 'Boiler, woodpulp' },
  { Id: 20726, Code: '8121', Name: 'Boilerman, paper mfr' },
  { Id: 20727, Code: '8121', Name: 'Borer and cutter, cross' },
  { Id: 20728, Code: '8121', Name: 'Borer, wood' },
  { Id: 20729, Code: '8121', Name: 'Borer, woodwind instruments mfr' },
  { Id: 20730, Code: '8121', Name: 'Bottomer, cardboard box mfr' },
  { Id: 20731, Code: '8121', Name: 'Brander' },
  { Id: 20732, Code: '8121', Name: 'Breaker, paper mfr' },
  { Id: 20733, Code: '8121', Name: 'Breaker, woodpulp' },
  { Id: 20734, Code: '8121', Name: 'Breakerman, paper mfr' },
  { Id: 20735, Code: '8121', Name: 'Breakerman, rag' },
  { Id: 20736, Code: '8121', Name: 'Breaster, heel, WOOD' },
  { Id: 20737, Code: '8121', Name: 'Brusher, paper mfr' },
  { Id: 20738, Code: '8121', Name: 'Brusher, pigment' },
  { Id: 20739, Code: '8121', Name: 'Brusher, wallpaper mfr' },
  { Id: 20740, Code: '8121', Name: 'Builder, drum, CABLES' },
  { Id: 20741, Code: '8121', Name: 'Builder, vat' },
  { Id: 20742, Code: '8121', Name: 'Calenderer, paper mfr' },
  { Id: 20743, Code: '8121', Name: 'Calenderman, paper mfr' },
  { Id: 20744, Code: '8121', Name: 'Calenderman, super' },
  { Id: 20745, Code: '8121', Name: 'Catcher, paper mfr' },
  { Id: 20746, Code: '8121', Name: 'Catcher, sheet, PAPER' },
  { Id: 20747, Code: '8121', Name: 'Cementer, paper' },
  { Id: 20748, Code: '8121', Name: 'Changer, roller' },
  { Id: 20749, Code: '8121', Name: 'Chipper, chipboard mfr' },
  { Id: 20750, Code: '8121', Name: 'Chopper, wood, sawmilling' },
  { Id: 20751, Code: '8121', Name: 'Chopperman, paper mfr' },
  { Id: 20752, Code: '8121', Name: 'Cleaner, wood' },
  { Id: 20753, Code: '8121', Name: 'Clipper, veneer' },
  { Id: 20754, Code: '8121', Name: 'Coater, paper' },
  { Id: 20755, Code: '8121', Name: 'Coater, stencil paper mfr' },
  { Id: 20756, Code: '8121', Name: 'Combiner, paper mfr' },
  { Id: 20757, Code: '8121', Name: 'Concaver, footwear mfr' },
  { Id: 20758, Code: '8121', Name: 'Concentratorman, paper mfr' },
  { Id: 20759, Code: '8121', Name: 'Conditioner, paper' },
  { Id: 20760, Code: '8121', Name: 'Conditioner, paper mfr' },
  { Id: 20761, Code: '8121', Name: 'Converter, paper' },
  { Id: 20762, Code: '8121', Name: 'Converter, timber' },
  { Id: 20763, Code: '8121', Name: 'Copier, pattern, paper' },
  { Id: 20764, Code: '8121', Name: 'Corrugator, paper mfr' },
  { Id: 20765, Code: '8121', Name: 'Coucher' },
  { Id: 20766, Code: '8121', Name: 'Creaser, printing' },
  { Id: 20767, Code: '8121', Name: 'Creosoter, timber' },
  { Id: 20768, Code: '8121', Name: 'Cutter, abrasive paper, cloth mfr' },
  { Id: 20769, Code: '8121', Name: 'Cutter, belt, abrasives mfr' },
  { Id: 20770, Code: '8121', Name: 'Cutter, box, CARDBOARD' },
  { Id: 20771, Code: '8121', Name: 'Cutter, card, paper goods mfr' },
  { Id: 20772, Code: '8121', Name: 'Cutter, film, photographic film mfr' },
  { Id: 20773, Code: '8121', Name: 'Cutter, furniture mfr' },
  { Id: 20774, Code: '8121', Name: 'Cutter, guillotine, paper goods mfr' },
  { Id: 20775, Code: '8121', Name: 'Cutter, guillotine, wood products mfr' },
  { Id: 20776, Code: '8121', Name: "Cutter, maker's, box" },
  { Id: 20777, Code: '8121', Name: 'Cutter, negative' },
  { Id: 20778, Code: '8121', Name: 'Cutter, packing case wood mfr' },
  { Id: 20779, Code: '8121', Name: 'Cutter, panel, WOOD' },
  { Id: 20780, Code: '8121', Name: 'Cutter, paper' },
  { Id: 20781, Code: '8121', Name: 'Cutter, paper goods mfr' },
  { Id: 20782, Code: '8121', Name: 'Cutter, paper mfr' },
  { Id: 20783, Code: '8121', Name: 'Cutter, paper pattern mfr' },
  { Id: 20784, Code: '8121', Name: 'Cutter, pattern, paper' },
  { Id: 20785, Code: '8121', Name: 'Cutter, press, paper goods mfr' },
  { Id: 20786, Code: '8121', Name: 'Cutter, print' },
  { Id: 20787, Code: '8121', Name: 'Cutter, printing' },
  { Id: 20788, Code: '8121', Name: 'Cutter, rotary, paper goods mfr' },
  { Id: 20789, Code: '8121', Name: 'Cutter, sole, CLOG' },
  { Id: 20790, Code: '8121', Name: 'Cutter, stencil, printing' },
  { Id: 20791, Code: '8121', Name: 'Cutter, timber' },
  { Id: 20792, Code: '8121', Name: 'Cutter, tube, PAPER' },
  { Id: 20793, Code: '8121', Name: 'Cutter, veneer' },
  { Id: 20794, Code: '8121', Name: 'Cutter, wood' },
  { Id: 20795, Code: '8121', Name: 'Cutter, woodworking' },
  { Id: 20796, Code: '8121', Name: 'Cutterman, paper mfr' },
  { Id: 20797, Code: '8121', Name: 'Cylinderman, paper mfr' },
  { Id: 20798, Code: '8121', Name: 'Damperman, paper mfr' },
  { Id: 20799, Code: '8121', Name: 'Designer-cutter, paper goods mfr' },
  { Id: 20800, Code: '8121', Name: 'Devilman, paper mfr' },
  { Id: 20801, Code: '8121', Name: 'Dipper, paper mfr' },
  { Id: 20802, Code: '8121', Name: 'Driller, wood' },
  { Id: 20803, Code: '8121', Name: 'Dropperman' },
  { Id: 20804, Code: '8121', Name: 'Dryer, abrasive paper, cloth mfr' },
  { Id: 20805, Code: '8121', Name: 'Dryer, kiln, WOOD' },
  { Id: 20806, Code: '8121', Name: 'Dryer, paper mfr' },
  { Id: 20807, Code: '8121', Name: 'Dryer, veneer' },
  { Id: 20808, Code: '8121', Name: 'Dryer, vulcanised fibre mfr' },
  { Id: 20809, Code: '8121', Name: 'Edgeman' },
  { Id: 20810, Code: '8121', Name: 'Facer, wood' },
  { Id: 20811, Code: '8121', Name: 'Feeder, card, paste board mfr' },
  { Id: 20812, Code: '8121', Name: 'Feltman, paper mfr' },
  { Id: 20813, Code: '8121', Name: 'Filler-in, paper mfr' },
  { Id: 20814, Code: '8121', Name: 'Finisher, paper' },
  { Id: 20815, Code: '8121', Name: 'Finisher, paper mfr' },
  { Id: 20816, Code: '8121', Name: 'Finisher, plate, paper mfr' },
  { Id: 20817, Code: '8121', Name: 'Finisher, stencil paper mfr' },
  { Id: 20818, Code: '8121', Name: 'Finisher, wood' },
  { Id: 20819, Code: '8121', Name: 'Fireman, retort, CHARCOAL' },
  { Id: 20820, Code: '8121', Name: 'Fixer, tape, paper pattern mfr' },
  { Id: 20821, Code: '8121', Name: 'Flusher, TOBACCO PIPES' },
  { Id: 20822, Code: '8121', Name: 'Folder, envelope' },
  { Id: 20823, Code: '8121', Name: 'Folder, map' },
  { Id: 20824, Code: '8121', Name: 'Folder, paper' },
  { Id: 20825, Code: '8121', Name: 'Foreman, abrasive paper, cloth mfr' },
  { Id: 20826, Code: '8121', Name: 'Foreman, calender, paper mfr' },
  { Id: 20827, Code: '8121', Name: 'Foreman, cardboard box mfr' },
  { Id: 20828, Code: '8121', Name: 'Foreman, paper goods mfr' },
  { Id: 20829, Code: '8121', Name: 'Foreman, paper mfr' },
  { Id: 20830, Code: '8121', Name: 'Foreman, sawmilling' },
  { Id: 20831, Code: '8121', Name: 'Foreman, shop, paper mfr' },
  { Id: 20832, Code: '8121', Name: 'Foreman, timber' },
  { Id: 20833, Code: '8121', Name: 'Framer, rule' },
  { Id: 20834, Code: '8121', Name: 'Frazer, tobacco pipe mfr' },
  { Id: 20835, Code: '8121', Name: 'Glazer, friction' },
  { Id: 20836, Code: '8121', Name: 'Glazer, paper mfr' },
  { Id: 20837, Code: '8121', Name: 'Glazer, postcard' },
  { Id: 20838, Code: '8121', Name: 'Glazer, wallpaper mfr' },
  { Id: 20839, Code: '8121', Name: 'Grinder, logwood' },
  { Id: 20840, Code: '8121', Name: 'Grinder, paper' },
  { Id: 20841, Code: '8121', Name: 'Grinder, paper mfr' },
  { Id: 20842, Code: '8121', Name: 'Grinderman, paper mfr' },
  { Id: 20843, Code: '8121', Name: 'Groover, pencil' },
  { Id: 20844, Code: '8121', Name: 'Hand, bag, paper goods mfr' },
  { Id: 20845, Code: '8121', Name: 'Hand, guillotine, paper goods mfr' },
  { Id: 20846, Code: '8121', Name: 'Hand, mill, saw' },
  { Id: 20847, Code: '8121', Name: 'Hand, paper, PIANO HAMMERS' },
  { Id: 20848, Code: '8121', Name: 'Hand, spindle' },
  { Id: 20849, Code: '8121', Name: 'Hand, tenon' },
  { Id: 20850, Code: '8121', Name: 'Handler, bag, paper' },
  { Id: 20851, Code: '8121', Name: 'Header-up, cask mfr' },
  { Id: 20852, Code: '8121', Name: 'Header-up, wooden cask mfr' },
  { Id: 20853, Code: '8121', Name: 'Helper, paper mfr' },
  { Id: 20854, Code: '8121', Name: 'Houseman, sand' },
  { Id: 20855, Code: '8121', Name: 'Hydrapulper' },
  { Id: 20856, Code: '8121', Name: 'Interleaver, PAPER' },
  { Id: 20857, Code: '8121', Name: 'Jogger, paper mfr' },
  { Id: 20858, Code: '8121', Name: 'Joiner, machine' },
  { Id: 20859, Code: '8121', Name: 'Knocker-up, printing' },
  { Id: 20860, Code: '8121', Name: 'Kollerganger' },
  { Id: 20861, Code: '8121', Name: 'Laminator, paper mfr' },
  { Id: 20862, Code: '8121', Name: 'Laminator, wood' },
  { Id: 20863, Code: '8121', Name: 'Layer, paper mfr' },
  { Id: 20864, Code: '8121', Name: 'Layer, veneer' },
  { Id: 20865, Code: '8121', Name: 'Layer-on, cardboard box mfr' },
  { Id: 20866, Code: '8121', Name: 'Lurrier' },
  { Id: 20867, Code: '8121', Name: 'Machinist, adhesive tape mfr' },
  { Id: 20868, Code: '8121', Name: 'Machinist, bag, carrier' },
  { Id: 20869, Code: '8121', Name: 'Machinist, bag, paper' },
  { Id: 20870, Code: '8121', Name: 'Machinist, barking' },
  { Id: 20871, Code: '8121', Name: 'Machinist, boring, WOOD' },
  {
    Id: 20872,
    Code: '8121',
    Name: 'Machinist, box and slide, cardboard box mfr'
  },
  { Id: 20873, Code: '8121', Name: 'Machinist, box, paper goods mfr' },
  { Id: 20874, Code: '8121', Name: 'Machinist, brushing, paper mfr' },
  { Id: 20875, Code: '8121', Name: "Machinist, builder's" },
  { Id: 20876, Code: '8121', Name: 'Machinist, burnishing' },
  { Id: 20877, Code: '8121', Name: 'Machinist, cabinet' },
  { Id: 20878, Code: '8121', Name: 'Machinist, coating, CARBON PAPER' },
  { Id: 20879, Code: '8121', Name: 'Machinist, coating, paper' },
  { Id: 20880, Code: '8121', Name: 'Machinist, combining, paper mfr' },
  { Id: 20881, Code: '8121', Name: "Machinist, cooper's" },
  { Id: 20882, Code: '8121', Name: 'Machinist, cording, paper goods mfr' },
  { Id: 20883, Code: '8121', Name: 'Machinist, corrugating' },
  { Id: 20884, Code: '8121', Name: 'Machinist, cutting, cloth, emery' },
  { Id: 20885, Code: '8121', Name: 'Machinist, cutting, paper' },
  { Id: 20886, Code: '8121', Name: 'Machinist, cutting, paper goods mfr' },
  { Id: 20887, Code: '8121', Name: 'Machinist, cutting, paper mfr' },
  { Id: 20888, Code: '8121', Name: 'Machinist, cutting, tube, CARDBOARD' },
  { Id: 20889, Code: '8121', Name: 'Machinist, cutting, wood' },
  { Id: 20890, Code: '8121', Name: 'Machinist, drilling, wood products mfr' },
  { Id: 20891, Code: '8121', Name: 'Machinist, embossing, wood products mfr' },
  { Id: 20892, Code: '8121', Name: 'Machinist, envelope' },
  { Id: 20893, Code: '8121', Name: 'Machinist, fibre, vulcanised' },
  { Id: 20894, Code: '8121', Name: 'Machinist, furniture mfr' },
  {
    Id: 20895,
    Code: '8121',
    Name: 'Machinist, gluing and winding, tube, CARDBOARD'
  },
  { Id: 20896, Code: '8121', Name: 'Machinist, grinding, paper mfr' },
  { Id: 20897, Code: '8121', Name: 'Machinist, guillotine, paper goods mfr' },
  { Id: 20898, Code: '8121', Name: 'Machinist, gumming, GUM PAPER' },
  { Id: 20899, Code: '8121', Name: 'Machinist, handle' },
  { Id: 20900, Code: '8121', Name: 'Machinist, impregnating, paper mfr' },
  { Id: 20901, Code: '8121', Name: 'Machinist, insulating, paper' },
  { Id: 20902, Code: '8121', Name: 'Machinist, joiner' },
  { Id: 20903, Code: '8121', Name: "Machinist, joiner's" },
  {
    Id: 20904,
    Code: '8121',
    Name: 'Machinist, lead pencil, chalk, crayon mfr'
  },
  { Id: 20905, Code: '8121', Name: 'Machinist, lining, paper mfr' },
  { Id: 20906, Code: '8121', Name: "Machinist, maker's, crate" },
  {
    Id: 20907,
    Code: '8121',
    Name: 'Machinist, making, abrasive paper, cloth mfr'
  },
  { Id: 20908, Code: '8121', Name: 'Machinist, making, box' },
  { Id: 20909, Code: '8121', Name: 'Machinist, match mfr' },
  { Id: 20910, Code: '8121', Name: 'Machinist, mill, moulding' },
  { Id: 20911, Code: '8121', Name: 'Machinist, mill, saw' },
  {
    Id: 20912,
    Code: '8121',
    Name: 'Machinist, mixing, abrasive paper, cloth mfr'
  },
  { Id: 20913, Code: '8121', Name: 'Machinist, moulding, wood products mfr' },
  { Id: 20914, Code: '8121', Name: 'Machinist, paper dress pattern mfr' },
  { Id: 20915, Code: '8121', Name: 'Machinist, paper goods mfr' },
  { Id: 20916, Code: '8121', Name: 'Machinist, paper mfr' },
  { Id: 20917, Code: '8121', Name: 'Machinist, paper, carbon' },
  { Id: 20918, Code: '8121', Name: 'Machinist, paper, crinkled' },
  { Id: 20919, Code: '8121', Name: 'Machinist, paper, paper goods mfr' },
  { Id: 20920, Code: '8121', Name: 'Machinist, pasteboard' },
  { Id: 20921, Code: '8121', Name: 'Machinist, pasting, PASTEBOARD' },
  { Id: 20922, Code: '8121', Name: 'Machinist, perforating, paper goods mfr' },
  { Id: 20923, Code: '8121', Name: 'Machinist, piano action, hammer mfr' },
  { Id: 20924, Code: '8121', Name: 'Machinist, planing, wood products mfr' },
  { Id: 20925, Code: '8121', Name: 'Machinist, pleating, paper' },
  { Id: 20926, Code: '8121', Name: 'Machinist, punching, paper goods mfr' },
  { Id: 20927, Code: '8121', Name: 'Machinist, RELIEF STAMPING' },
  { Id: 20928, Code: '8121', Name: 'Machinist, roll, toilet' },
  { Id: 20929, Code: '8121', Name: 'Machinist, ruling' },
  { Id: 20930, Code: '8121', Name: 'Machinist, sanding, WOOD' },
  { Id: 20931, Code: '8121', Name: 'Machinist, sandpapering' },
  { Id: 20932, Code: '8121', Name: 'Machinist, shaping, wood products mfr' },
  {
    Id: 20933,
    Code: '8121',
    Name: 'Machinist, slitting and cutting, PHOTOGRAPHIC FILMS'
  },
  { Id: 20934, Code: '8121', Name: 'Machinist, slitting, adhesive tape mfr' },
  { Id: 20935, Code: '8121', Name: 'Machinist, slitting, paper goods mfr' },
  { Id: 20936, Code: '8121', Name: 'Machinist, slitting, paper mfr' },
  { Id: 20937, Code: '8121', Name: 'Machinist, spindle' },
  { Id: 20938, Code: '8121', Name: 'Machinist, thicknessing' },
  { Id: 20939, Code: '8121', Name: 'Machinist, timber' },
  { Id: 20940, Code: '8121', Name: 'Machinist, tube, paper' },
  { Id: 20941, Code: '8121', Name: 'Machinist, turning, wood' },
  { Id: 20942, Code: '8121', Name: 'Machinist, up-taking' },
  { Id: 20943, Code: '8121', Name: 'Machinist, varnishing, lithography' },
  { Id: 20944, Code: '8121', Name: 'Machinist, veneer' },
  { Id: 20945, Code: '8121', Name: 'Machinist, wallpaper mfr' },
  { Id: 20946, Code: '8121', Name: 'Machinist, waxing' },
  { Id: 20947, Code: '8121', Name: 'Machinist, wood' },
  { Id: 20948, Code: '8121', Name: 'Machinist, wood products mfr' },
  { Id: 20949, Code: '8121', Name: 'Machinist, woodcutting' },
  { Id: 20950, Code: '8121', Name: 'Machinist, woodworking' },
  { Id: 20951, Code: '8121', Name: 'Maker, bag, paper' },
  { Id: 20952, Code: '8121', Name: 'Maker, bag, paper goods mfr' },
  { Id: 20953, Code: '8121', Name: 'Maker, barrel, paper goods mfr' },
  { Id: 20954, Code: '8121', Name: 'Maker, block, cork' },
  { Id: 20955, Code: '8121', Name: 'Maker, board, paper mfr' },
  { Id: 20956, Code: '8121', Name: 'Maker, bobbin, CARDBOARD' },
  { Id: 20957, Code: '8121', Name: 'Maker, box, card' },
  { Id: 20958, Code: '8121', Name: 'Maker, box, cardboard' },
  { Id: 20959, Code: '8121', Name: 'Maker, box, ointment' },
  { Id: 20960, Code: '8121', Name: 'Maker, box, paper' },
  { Id: 20961, Code: '8121', Name: 'Maker, box, WOOD' },
  { Id: 20962, Code: '8121', Name: 'Maker, box, wooden' },
  { Id: 20963, Code: '8121', Name: 'Maker, can, fibre' },
  { Id: 20964, Code: '8121', Name: 'Maker, card, paper mfr' },
  { Id: 20965, Code: '8121', Name: 'Maker, card, pattern' },
  { Id: 20966, Code: '8121', Name: 'Maker, card, show' },
  { Id: 20967, Code: '8121', Name: 'Maker, carton' },
  { Id: 20968, Code: '8121', Name: 'Maker, case' },
  { Id: 20969, Code: '8121', Name: 'Maker, case, cardboard' },
  { Id: 20970, Code: '8121', Name: 'Maker, case, packing' },
  { Id: 20971, Code: '8121', Name: 'Maker, churn' },
  { Id: 20972, Code: '8121', Name: 'Maker, cloth, glass' },
  { Id: 20973, Code: '8121', Name: 'Maker, crate' },
  { Id: 20974, Code: '8121', Name: 'Maker, cup, cream, paper goods mfr' },
  { Id: 20975, Code: '8121', Name: 'Maker, drum, cable' },
  { Id: 20976, Code: '8121', Name: 'Maker, drum, WOOD' },
  { Id: 20977, Code: '8121', Name: 'Maker, envelope, paper goods mfr' },
  { Id: 20978, Code: '8121', Name: 'Maker, file, box' },
  { Id: 20979, Code: '8121', Name: 'Maker, handle, WOOD' },
  { Id: 20980, Code: '8121', Name: 'Maker, hanger, coat, WOOD' },
  { Id: 20981, Code: '8121', Name: 'Maker, helve' },
  { Id: 20982, Code: '8121', Name: 'Maker, label' },
  { Id: 20983, Code: '8121', Name: 'Maker, label, wood' },
  { Id: 20984, Code: '8121', Name: 'Maker, medal' },
  { Id: 20985, Code: '8121', Name: 'Maker, pallet' },
  { Id: 20986, Code: '8121', Name: 'Maker, panel, WOOD' },
  { Id: 20987, Code: '8121', Name: 'Maker, paper' },
  { Id: 20988, Code: '8121', Name: 'Maker, paper, abrasive' },
  { Id: 20989, Code: '8121', Name: 'Maker, paper, carbon' },
  { Id: 20990, Code: '8121', Name: 'Maker, paper, emery' },
  { Id: 20991, Code: '8121', Name: 'Maker, paper, fly' },
  { Id: 20992, Code: '8121', Name: 'Maker, paper, glass' },
  { Id: 20993, Code: '8121', Name: 'Maker, paper, laced' },
  { Id: 20994, Code: '8121', Name: 'Maker, paper, photographic' },
  { Id: 20995, Code: '8121', Name: 'Maker, paper, sand' },
  { Id: 20996, Code: '8121', Name: 'Maker, plywood' },
  { Id: 20997, Code: '8121', Name: 'Maker, pole' },
  { Id: 20998, Code: '8121', Name: 'Maker, pulp, paper mfr' },
  { Id: 20999, Code: '8121', Name: 'Maker, reel' },
  { Id: 21000, Code: '8121', Name: 'Maker, sample, paper goods mfr' },
  { Id: 21001, Code: '8121', Name: 'Maker, screw, wooden' },
  { Id: 21002, Code: '8121', Name: 'Maker, sheet, VULCANISED FIBRE' },
  { Id: 21003, Code: '8121', Name: 'Maker, tape, adhesive tape mfr' },
  { Id: 21004, Code: '8121', Name: 'Maker, transfer' },
  { Id: 21005, Code: '8121', Name: 'Maker, tray, PAPER' },
  { Id: 21006, Code: '8121', Name: 'Maker, trunk, WOOD' },
  { Id: 21007, Code: '8121', Name: 'Maker, tube, PAPER' },
  { Id: 21008, Code: '8121', Name: 'Maker, wheel, abrasive paper, cloth mfr' },
  { Id: 21009, Code: '8121', Name: 'Maker, wool, wood' },
  { Id: 21010, Code: '8121', Name: 'Man, cut-off, corrugated paper mfr' },
  { Id: 21011, Code: '8121', Name: 'Man, machine, paper mfr' },
  { Id: 21012, Code: '8121', Name: 'Man, mould, paper mfr' },
  { Id: 21013, Code: '8121', Name: 'Man, pan, paper mfr' },
  { Id: 21014, Code: '8121', Name: 'Man, press-pate' },
  { Id: 21015, Code: '8121', Name: 'Man, saw, WOOD' },
  { Id: 21016, Code: '8121', Name: 'Man, table, wooden box mfr' },
  { Id: 21017, Code: '8121', Name: 'Manager, machine, paper goods mfr' },
  { Id: 21018, Code: '8121', Name: 'Manufacturer, packing case mfr' },
  { Id: 21019, Code: '8121', Name: 'Manufacturer, paper' },
  { Id: 21020, Code: '8121', Name: 'Manufacturer, paper goods mfr' },
  { Id: 21021, Code: '8121', Name: 'Manufacturer, paper mfr' },
  { Id: 21022, Code: '8121', Name: 'Mender, crate' },
  { Id: 21023, Code: '8121', Name: 'Miller, flour, wood' },
  { Id: 21024, Code: '8121', Name: 'Miller, logwood' },
  { Id: 21025, Code: '8121', Name: 'Miller, paper mfr' },
  { Id: 21026, Code: '8121', Name: 'Miller, saw' },
  { Id: 21027, Code: '8121', Name: 'Mixer, abrasive paper, cloth mfr' },
  {
    Id: 21028,
    Code: '8121',
    Name: 'Mixer, adhesive, abrasive paper, cloth mfr'
  },
  { Id: 21029, Code: '8121', Name: 'Mixer, paper mfr' },
  { Id: 21030, Code: '8121', Name: 'Moulder, spindle, WOOD' },
  { Id: 21031, Code: '8121', Name: 'Moulder, wood' },
  { Id: 21032, Code: '8121', Name: 'Nipper, paper mfr' },
  { Id: 21033, Code: '8121', Name: 'Operator, calender, paper mfr' },
  { Id: 21034, Code: '8121', Name: 'Operator, clipper, veneer' },
  { Id: 21035, Code: '8121', Name: 'Operator, coating, paper mfr' },
  {
    Id: 21036,
    Code: '8121',
    Name: 'Operator, compressor, paper, leather board mfr'
  },
  { Id: 21037, Code: '8121', Name: 'Operator, guillotine, paper goods mfr' },
  { Id: 21038, Code: '8121', Name: 'Operator, guillotine, printing' },
  { Id: 21039, Code: '8121', Name: 'Operator, kiln, wood products mfr' },
  { Id: 21040, Code: '8121', Name: 'Operator, lathe, wood products mfr' },
  { Id: 21041, Code: '8121', Name: 'Operator, mill, saw' },
  { Id: 21042, Code: '8121', Name: 'Operator, plant, paper mfr' },
  { Id: 21043, Code: '8121', Name: 'Operator, process, paper mfr' },
  { Id: 21044, Code: '8121', Name: 'Operator, punch, paper goods mfr' },
  { Id: 21045, Code: '8121', Name: 'Operator, saw, band, WOOD' },
  { Id: 21046, Code: '8121', Name: 'Operator, saw, sawmilling' },
  { Id: 21047, Code: '8121', Name: 'Operator, saw, WOOD' },
  { Id: 21048, Code: '8121', Name: 'Operator, take-down, ABRASIVE SHEET' },
  { Id: 21049, Code: '8121', Name: 'Operator, winder, paper mfr' },
  { Id: 21050, Code: '8121', Name: 'Ovenman, abrasive paper, cloth mfr' },
  { Id: 21051, Code: '8121', Name: 'Paperer, tobacco pipe mfr' },
  { Id: 21052, Code: '8121', Name: 'Parchmentiser' },
  { Id: 21053, Code: '8121', Name: 'Pickler, sleeper' },
  { Id: 21054, Code: '8121', Name: 'Planer, WOOD' },
  { Id: 21055, Code: '8121', Name: 'Plater, paper mfr' },
  { Id: 21056, Code: '8121', Name: 'Potcherman' },
  { Id: 21057, Code: '8121', Name: 'Preserver, timber' },
  { Id: 21058, Code: '8121', Name: 'Presser, hot, paper mfr' },
  { Id: 21059, Code: '8121', Name: 'Presser, hot, printing' },
  { Id: 21060, Code: '8121', Name: 'Presser, paper mfr' },
  { Id: 21061, Code: '8121', Name: 'Presser, plywood mfr' },
  { Id: 21062, Code: '8121', Name: 'Presser, veneer' },
  { Id: 21063, Code: '8121', Name: 'Presser, wood pulp mfr' },
  { Id: 21064, Code: '8121', Name: 'Proofer, moisture, transparent paper mfr' },
  { Id: 21065, Code: '8121', Name: 'Pulper, paper mfr' },
  { Id: 21066, Code: '8121', Name: 'Pulperman, paper mfr' },
  { Id: 21067, Code: '8121', Name: 'Pulpman, paper mfr' },
  { Id: 21068, Code: '8121', Name: 'Recoverer, paper mfr' },
  { Id: 21069, Code: '8121', Name: 'Reeler, paper mfr' },
  { Id: 21070, Code: '8121', Name: 'Reeler, wallpaper mfr' },
  { Id: 21071, Code: '8121', Name: 'Reelerman, paper mfr' },
  { Id: 21072, Code: '8121', Name: 'Reelman' },
  { Id: 21073, Code: '8121', Name: 'Refiner, paper mfr' },
  { Id: 21074, Code: '8121', Name: 'Repairer, box' },
  { Id: 21075, Code: '8121', Name: 'Repairer, case, WOOD' },
  { Id: 21076, Code: '8121', Name: 'Repairer, cask' },
  { Id: 21077, Code: '8121', Name: 'Repairer, crate' },
  { Id: 21078, Code: '8121', Name: 'Repairer, pallet' },
  { Id: 21079, Code: '8121', Name: 'Rincer, bobbin' },
  { Id: 21080, Code: '8121', Name: 'Ripperman, paper mfr' },
  { Id: 21081, Code: '8121', Name: 'Roller, cold, paper mfr' },
  { Id: 21082, Code: '8121', Name: 'Roller, hot' },
  { Id: 21083, Code: '8121', Name: 'Roller, paper mfr' },
  { Id: 21084, Code: '8121', Name: 'Roller, paper tube mfr' },
  { Id: 21085, Code: '8121', Name: 'Roller, tube, PAPER' },
  { Id: 21086, Code: '8121', Name: 'Roller-up, wallpaper mfr' },
  {
    Id: 21087,
    Code: '8121',
    Name: 'Rougher and borer, woodwind instruments mfr'
  },
  { Id: 21088, Code: '8121', Name: 'Router, WOOD' },
  { Id: 21089, Code: '8121', Name: 'Sander, wood products mfr' },
  { Id: 21090, Code: '8121', Name: 'Sandman, abrasive paper, cloth mfr' },
  { Id: 21091, Code: '8121', Name: 'Sawyer' },
  { Id: 21092, Code: '8121', Name: 'Sawyer, back' },
  { Id: 21093, Code: '8121', Name: 'Sawyer, band' },
  { Id: 21094, Code: '8121', Name: 'Sawyer, circular' },
  { Id: 21095, Code: '8121', Name: 'Sawyer, coal mine' },
  { Id: 21096, Code: '8121', Name: 'Sawyer, converting mill' },
  { Id: 21097, Code: '8121', Name: 'Sawyer, cut, cross' },
  { Id: 21098, Code: '8121', Name: 'Sawyer, mill' },
  { Id: 21099, Code: '8121', Name: 'Sawyer, pulp, wood' },
  { Id: 21100, Code: '8121', Name: 'Sawyer, rack' },
  { Id: 21101, Code: '8121', Name: 'Sawyer, whip' },
  { Id: 21102, Code: '8121', Name: 'Sawyer, WOOD' },
  { Id: 21103, Code: '8121', Name: 'Seasoner, paper mfr' },
  { Id: 21104, Code: '8121', Name: 'Servicer, textile printing' },
  { Id: 21105, Code: '8121', Name: 'Setter, film, textile mfr' },
  { Id: 21106, Code: '8121', Name: 'Setter, forme, paper box mfr' },
  { Id: 21107, Code: '8121', Name: 'Setter, machine, board' },
  { Id: 21108, Code: '8121', Name: 'Setter, machine, paper goods mfr' },
  { Id: 21109, Code: '8121', Name: 'Setter, machine, woodcutting' },
  { Id: 21110, Code: '8121', Name: 'Setter-operator, machine, paper' },
  { Id: 21111, Code: '8121', Name: 'Setter-out, wood products mfr' },
  { Id: 21112, Code: '8121', Name: 'Shaper, wood products mfr' },
  { Id: 21113, Code: '8121', Name: 'Sharpener, prop, pit' },
  { Id: 21114, Code: '8121', Name: 'Sheeter, paper mfr' },
  { Id: 21115, Code: '8121', Name: 'Siever, abrasive paper, cloth mfr' },
  { Id: 21116, Code: '8121', Name: 'Sizeman, paper mill' },
  { Id: 21117, Code: '8121', Name: 'Sizer, bobbin' },
  { Id: 21118, Code: '8121', Name: 'Sizer, engine, paper mfr' },
  { Id: 21119, Code: '8121', Name: 'Sizer, paper mfr' },
  { Id: 21120, Code: '8121', Name: 'Slicer, veneer' },
  { Id: 21121, Code: '8121', Name: 'Slitter, abrasive paper, cloth mfr' },
  { Id: 21122, Code: '8121', Name: 'Slitter, paper' },
  { Id: 21123, Code: '8121', Name: 'Slitter, paper and printing' },
  { Id: 21124, Code: '8121', Name: 'Slitter, photographic film mfr' },
  {
    Id: 21125,
    Code: '8121',
    Name: 'Slitter, transparent cellulose wrappings mfr'
  },
  { Id: 21126, Code: '8121', Name: 'Slitterman, paper mfr' },
  { Id: 21127, Code: '8121', Name: 'Slotter, paper goods mfr' },
  { Id: 21128, Code: '8121', Name: 'Spooler, paper goods mfr' },
  {
    Id: 21129,
    Code: '8121',
    Name: 'Spreader, glue, abrasive paper, cloth mfr'
  },
  { Id: 21130, Code: '8121', Name: 'Stainer, paper' },
  { Id: 21131, Code: '8121', Name: 'Stayer, cardboard box mfr' },
  { Id: 21132, Code: '8121', Name: 'Strainerman, paper mfr' },
  { Id: 21133, Code: '8121', Name: 'Stripper, cardboard' },
  { Id: 21134, Code: '8121', Name: 'Stripper, carton' },
  { Id: 21135, Code: '8121', Name: 'Stripper, paper' },
  { Id: 21136, Code: '8121', Name: 'Stripper, paper goods mfr' },
  { Id: 21137, Code: '8121', Name: 'Tackler, paper goods mfr' },
  { Id: 21138, Code: '8121', Name: 'Taker-off, bag, paper' },
  { Id: 21139, Code: '8121', Name: 'Tankhand, VULCANISED FIBRE' },
  { Id: 21140, Code: '8121', Name: 'Tender, machine, pasteboard' },
  { Id: 21141, Code: '8121', Name: 'Tenter, back, machine, linen' },
  { Id: 21142, Code: '8121', Name: 'Tenter, back, paper mfr' },
  { Id: 21143, Code: '8121', Name: 'Tenter, back, paper staining' },
  { Id: 21144, Code: '8121', Name: 'Treater, timber' },
  { Id: 21145, Code: '8121', Name: 'Trimmer, card' },
  { Id: 21146, Code: '8121', Name: 'Trimmer, paper, printing' },
  { Id: 21147, Code: '8121', Name: 'Trimmer, print, photographic' },
  { Id: 21148, Code: '8121', Name: 'Trimmer, sheet, paper goods mfr' },
  { Id: 21149, Code: '8121', Name: 'Trimmer, veneer' },
  { Id: 21150, Code: '8121', Name: 'Trimmer, wallpaper' },
  { Id: 21151, Code: '8121', Name: 'Turner, block, wood' },
  { Id: 21152, Code: '8121', Name: 'Turner, bobbin' },
  { Id: 21153, Code: '8121', Name: 'Turner, bowl, tobacco pipe mfr' },
  { Id: 21154, Code: '8121', Name: 'Turner, counter' },
  { Id: 21155, Code: '8121', Name: 'Turner, handle' },
  { Id: 21156, Code: '8121', Name: 'Turner, heel, WOOD HEELS' },
  { Id: 21157, Code: '8121', Name: 'Turner, mould, fringe' },
  { Id: 21158, Code: '8121', Name: 'Turner, pipe, tobacco pipe mfr' },
  { Id: 21159, Code: '8121', Name: 'Turner, pirn' },
  { Id: 21160, Code: '8121', Name: 'Turner, rod, FISHING RODS' },
  { Id: 21161, Code: '8121', Name: 'Turner, roller, WOOD' },
  { Id: 21162, Code: '8121', Name: 'Turner, shive' },
  { Id: 21163, Code: '8121', Name: 'Turner, spiral' },
  { Id: 21164, Code: '8121', Name: 'Turner, spool' },
  { Id: 21165, Code: '8121', Name: 'Turner, wood' },
  { Id: 21166, Code: '8121', Name: 'Turner, wood products mfr' },
  { Id: 21167, Code: '8121', Name: 'Turner, woodwind instruments mfr' },
  { Id: 21168, Code: '8121', Name: 'Varnisher, paper' },
  { Id: 21169, Code: '8121', Name: 'Varnisher, wallpaper mfr' },
  { Id: 21170, Code: '8121', Name: 'Vatman, paper mfr' },
  { Id: 21171, Code: '8121', Name: 'Veneerer, wood' },
  { Id: 21172, Code: '8121', Name: 'Washer, paper mfr' },
  { Id: 21173, Code: '8121', Name: 'Washerman, paper mfr' },
  { Id: 21174, Code: '8121', Name: 'Waterman, paper mfr' },
  { Id: 21175, Code: '8121', Name: 'Waxer, paper mfr' },
  { Id: 21176, Code: '8121', Name: 'Waxer, paper, stencil' },
  { Id: 21177, Code: '8121', Name: 'Willower, paper mfr' },
  { Id: 21178, Code: '8121', Name: 'Winder, paper mfr' },
  { Id: 21179, Code: '8121', Name: 'Winder, spiral, paper tube mfr' },
  { Id: 21180, Code: '8121', Name: 'Winder, tube, paper tube mfr' },
  { Id: 21181, Code: '8121', Name: 'Winder, wallpaper' },
  { Id: 21182, Code: '8121', Name: 'Winder, wallpaper mfr' },
  { Id: 21183, Code: '8121', Name: 'Winderman, paper mfr' },
  { Id: 21184, Code: '8121', Name: 'Woodcutter' },
  { Id: 21185, Code: '8121', Name: 'Worker, bark' },
  { Id: 21186, Code: '8121', Name: 'Worker, box, cardboard' },
  { Id: 21187, Code: '8121', Name: 'Worker, dry, paper mfr' },
  { Id: 21188, Code: '8121', Name: 'Worker, fibre, paper mfr' },
  { Id: 21189, Code: '8121', Name: 'Worker, house, rag' },
  { Id: 21190, Code: '8121', Name: 'Worker, kiln, furniture mfr' },
  { Id: 21191, Code: '8121', Name: 'Worker, mill, paper' },
  { Id: 21192, Code: '8121', Name: 'Worker, mill, saw' },
  { Id: 21193, Code: '8121', Name: 'Worker, mill, timber merchants' },
  { Id: 21194, Code: '8121', Name: 'Worker, paper, paper mfr' },
  {
    Id: 21195,
    Code: '8121',
    Name: 'Worker, process, paper and board products mfr'
  },
  { Id: 21196, Code: '8121', Name: 'Worker, process, paper mfr' },
  { Id: 21197, Code: '8121', Name: 'Worker, process, wood products mfr' },
  { Id: 21198, Code: '8121', Name: 'Worker, room, pattern, wallpaper mfr' },
  { Id: 21199, Code: '8121', Name: 'Worker, tank, wax, cardboard box mfr' },
  { Id: 21200, Code: '8121', Name: 'Worker, tube, PAPER' },
  { Id: 21201, Code: '8121', Name: 'Wrapper, cardboard box mfr' },
  { Id: 21202, Code: '8122', Name: "Assistant, banksman's, coal mine" },
  { Id: 21203, Code: '8122', Name: "Assistant, borer's, coal mine" },
  { Id: 21204, Code: '8122', Name: 'Assistant, collier' },
  { Id: 21205, Code: '8122', Name: "Assistant, collier's" },
  { Id: 21206, Code: '8122', Name: "Assistant, onsetter's" },
  { Id: 21207, Code: '8122', Name: "Assistant, repairer's, coal mine" },
  { Id: 21208, Code: '8122', Name: "Assistant, shotfirer's, coal mine" },
  { Id: 21209, Code: '8122', Name: 'Assistant, timberman, coal mine' },
  { Id: 21210, Code: '8122', Name: "Assistant, timberman's, coal mine" },
  { Id: 21211, Code: '8122', Name: 'Attendant, aerial, mine: above ground' },
  { Id: 21212, Code: '8122', Name: 'Attendant, car, mine' },
  { Id: 21213, Code: '8122', Name: 'Attendant, coal' },
  { Id: 21214, Code: '8122', Name: 'Attendant, creeper, coal mine' },
  { Id: 21215, Code: '8122', Name: 'Attendant, depot, coal mine' },
  { Id: 21216, Code: '8122', Name: 'Attendant, hopper, coal mine' },
  { Id: 21217, Code: '8122', Name: 'Attendant, journey, coal mine' },
  { Id: 21218, Code: '8122', Name: 'Attendant, landing, coal mine' },
  { Id: 21219, Code: '8122', Name: 'Attendant, landsale' },
  { Id: 21220, Code: '8122', Name: 'Attendant, loader, coal mine' },
  { Id: 21221, Code: '8122', Name: 'Attendant, paddy, coal mine' },
  { Id: 21222, Code: '8122', Name: 'Attendant, rope, coal mine' },
  { Id: 21223, Code: '8122', Name: 'Attendant, tipper, coal mine' },
  {
    Id: 21224,
    Code: '8122',
    Name: 'Attendant, tipper, coal mine: above ground'
  },
  { Id: 21225, Code: '8122', Name: 'Attendant, tippler, coal mine' },
  { Id: 21226, Code: '8122', Name: 'Attendant, washery, coal mine' },
  { Id: 21227, Code: '8122', Name: 'Beltman, coal mine' },
  { Id: 21228, Code: '8122', Name: 'Beltman, coal mine: above ground' },
  { Id: 21229, Code: '8122', Name: 'Bolter, roof, coal mine' },
  { Id: 21230, Code: '8122', Name: 'Borer, coal mine' },
  { Id: 21231, Code: '8122', Name: 'Borer, coal mine: above ground' },
  { Id: 21232, Code: '8122', Name: 'Borer, methane, coal mine' },
  { Id: 21233, Code: '8122', Name: 'Brakesman, coal mine' },
  { Id: 21234, Code: '8122', Name: 'Brusher, coal mine' },
  { Id: 21235, Code: '8122', Name: 'Bumper, coal mine' },
  { Id: 21236, Code: '8122', Name: 'Bunker, coal mine' },
  { Id: 21237, Code: '8122', Name: 'Bunkerman, blast furnace' },
  { Id: 21238, Code: '8122', Name: 'Bunkerman, coal mine' },
  { Id: 21239, Code: '8122', Name: 'Burster' },
  { Id: 21240, Code: '8122', Name: 'Byeworker, coal mine' },
  { Id: 21241, Code: '8122', Name: 'Canchman, coal mine' },
  { Id: 21242, Code: '8122', Name: 'Changer, rope, coal mine' },
  { Id: 21243, Code: '8122', Name: 'Chargeman, coal mine' },
  { Id: 21244, Code: '8122', Name: 'Chocker, coal mine' },
  { Id: 21245, Code: '8122', Name: 'Cleaner, belt, coal mine' },
  { Id: 21246, Code: '8122', Name: 'Cleaner, coal mine' },
  { Id: 21247, Code: '8122', Name: 'Cleaner, lamp, coal mine' },
  { Id: 21248, Code: '8122', Name: 'Cleaner, spillage' },
  { Id: 21249, Code: '8122', Name: 'Cleaner, wagon, coal mine' },
  { Id: 21250, Code: '8122', Name: 'Clipper, coal mine' },
  { Id: 21251, Code: '8122', Name: 'Collier, coal mine' },
  { Id: 21252, Code: '8122', Name: 'Companyman, coal mine' },
  { Id: 21253, Code: '8122', Name: 'Conductor, paddy' },
  { Id: 21254, Code: '8122', Name: 'Contractor, stone, coal mine' },
  { Id: 21255, Code: '8122', Name: 'Controller, conveyer, coal mine' },
  { Id: 21256, Code: '8122', Name: 'Crusher, coal, coal mine' },
  { Id: 21257, Code: '8122', Name: 'Cutter, coal mine' },
  { Id: 21258, Code: '8122', Name: 'Cutter, coal, coal mine' },
  { Id: 21259, Code: '8122', Name: 'Cutter, machine, coal mine' },
  { Id: 21260, Code: '8122', Name: 'Cutterman, coal' },
  { Id: 21261, Code: '8122', Name: 'Cutterman, coal mine' },
  { Id: 21262, Code: '8122', Name: 'Deputy, coal mine' },
  { Id: 21263, Code: '8122', Name: 'Developer, coal mine' },
  { Id: 21264, Code: '8122', Name: 'Digger, coal, coal mine' },
  { Id: 21265, Code: '8122', Name: 'Dinker, coal mine' },
  { Id: 21266, Code: '8122', Name: 'Dismantler, coal mine' },
  { Id: 21267, Code: '8122', Name: 'Drawer, chock, coal mine' },
  { Id: 21268, Code: '8122', Name: 'Drawer, coal mine' },
  { Id: 21269, Code: '8122', Name: 'Drawer, prop, coal mine' },
  { Id: 21270, Code: '8122', Name: 'Drawer, salvage, coal mine' },
  { Id: 21271, Code: '8122', Name: 'Drawer, steel, coal mine' },
  { Id: 21272, Code: '8122', Name: 'Drawer, timber, coal mine' },
  { Id: 21273, Code: '8122', Name: 'Drawer, waste, coal mine' },
  { Id: 21274, Code: '8122', Name: 'Drawer-off, coal mine' },
  { Id: 21275, Code: '8122', Name: 'Drifter, coal mine' },
  { Id: 21276, Code: '8122', Name: 'Driftman, coal mine' },
  { Id: 21277, Code: '8122', Name: 'Driller, coal mine' },
  { Id: 21278, Code: '8122', Name: 'Driver, coal mine below ground, PONY' },
  { Id: 21279, Code: '8122', Name: 'Driver, shearer, coal mine' },
  { Id: 21280, Code: '8122', Name: 'Dumper, coal mine' },
  { Id: 21281, Code: '8122', Name: 'Emptier, wagon, coal mine' },
  { Id: 21282, Code: '8122', Name: 'Examiner, coal mine' },
  { Id: 21283, Code: '8122', Name: 'Extractor, coal mine' },
  { Id: 21284, Code: '8122', Name: 'Filler, coal' },
  { Id: 21285, Code: '8122', Name: 'Filler, coal mine' },
  {
    Id: 21286,
    Code: '8122',
    Name: 'Filler, conveyor, coal mine: below ground'
  },
  { Id: 21287, Code: '8122', Name: 'Filler, truck, coal mine' },
  { Id: 21288, Code: '8122', Name: 'Filler, tub, coal mine' },
  { Id: 21289, Code: '8122', Name: 'Fireman, coal mine: below ground' },
  { Id: 21290, Code: '8122', Name: 'Firer, shot, coal mine' },
  { Id: 21291, Code: '8122', Name: 'Fitter, chock, coal mine' },
  { Id: 21292, Code: '8122', Name: 'Fixer, girder, coal mine' },
  { Id: 21293, Code: '8122', Name: 'Foreman, coal' },
  { Id: 21294, Code: '8122', Name: 'Foreman, coal mine' },
  { Id: 21295, Code: '8122', Name: 'Foreman, coal mine: opencast' },
  { Id: 21296, Code: '8122', Name: 'Foreman, plant, cleaning, dry, coal mine' },
  {
    Id: 21297,
    Code: '8122',
    Name: 'Foreman, traffic, coal mine: below ground'
  },
  { Id: 21298, Code: '8122', Name: 'Foreman, working, coal mine' },
  { Id: 21299, Code: '8122', Name: 'Ganger, coal mine' },
  { Id: 21300, Code: '8122', Name: 'Getter, coal mine' },
  { Id: 21301, Code: '8122', Name: 'Grader, coal mine' },
  { Id: 21302, Code: '8122', Name: 'Gummer, coal mine' },
  { Id: 21303, Code: '8122', Name: 'Hand, brow, mining' },
  { Id: 21304, Code: '8122', Name: 'Hand, datal, coal mine' },
  { Id: 21305, Code: '8122', Name: 'Hand, haulage, coal mine' },
  { Id: 21306, Code: '8122', Name: 'Hand, salvage, coal mine' },
  { Id: 21307, Code: '8122', Name: 'Hand, screen, coal mine' },
  { Id: 21308, Code: '8122', Name: 'Hand, surface, coal mine' },
  { Id: 21309, Code: '8122', Name: 'Hand, washery, coal mine' },
  { Id: 21310, Code: '8122', Name: 'Handyman, nos, coal mine' },
  { Id: 21311, Code: '8122', Name: 'Hanger-on, coal mine' },
  { Id: 21312, Code: '8122', Name: 'Haulier, coal mine' },
  { Id: 21313, Code: '8122', Name: 'Header, coal mine' },
  { Id: 21314, Code: '8122', Name: 'Headman, gear, coal mine' },
  { Id: 21315, Code: '8122', Name: 'Helper, collier' },
  { Id: 21316, Code: '8122', Name: "Helper, collier's" },
  { Id: 21317, Code: '8122', Name: 'Hewer, coal mine' },
  { Id: 21318, Code: '8122', Name: 'Hitcher, coal mine' },
  { Id: 21319, Code: '8122', Name: 'Holder, stall, coal mine' },
  { Id: 21320, Code: '8122', Name: 'Infuser, water, coal mine' },
  { Id: 21321, Code: '8122', Name: 'Inspector, belt, coal mine' },
  { Id: 21322, Code: '8122', Name: 'Knocker, catch, coal mine' },
  { Id: 21323, Code: '8122', Name: 'Labourer, coal mine' },
  { Id: 21324, Code: '8122', Name: 'Landsaler, coal mine' },
  { Id: 21325, Code: '8122', Name: 'Latcher, coal mine' },
  { Id: 21326, Code: '8122', Name: 'Leader, girder, coal mine' },
  { Id: 21327, Code: '8122', Name: 'Leader, timber, coal mine' },
  { Id: 21328, Code: '8122', Name: 'Lighter, shot, coal mine' },
  { Id: 21329, Code: '8122', Name: 'Linesman, coal mine' },
  { Id: 21330, Code: '8122', Name: 'Linesman, survey, coal mine' },
  { Id: 21331, Code: '8122', Name: 'Loader, coal mine' },
  { Id: 21332, Code: '8122', Name: 'Loader, conveyor, coal mine' },
  { Id: 21333, Code: '8122', Name: 'Loader, power, coal mine' },
  { Id: 21334, Code: '8122', Name: 'Loader, timber, coal mine' },
  { Id: 21335, Code: '8122', Name: 'Loaderman, power, coal mine' },
  { Id: 21336, Code: '8122', Name: 'Machinist, boring, coal mine' },
  { Id: 21337, Code: '8122', Name: 'Machinist, coal mine' },
  { Id: 21338, Code: '8122', Name: 'Machinist, cutting, coal mine' },
  { Id: 21339, Code: '8122', Name: 'Machinist, loading, power' },
  { Id: 21340, Code: '8122', Name: 'Maker, manhole' },
  { Id: 21341, Code: '8122', Name: 'Man, button, colliery' },
  { Id: 21342, Code: '8122', Name: "Man, contractor's, coal mine" },
  { Id: 21343, Code: '8122', Name: 'Man, haulage, coal mine' },
  { Id: 21344, Code: '8122', Name: 'Man, haulage, face' },
  { Id: 21345, Code: '8122', Name: 'Man, heading, hard' },
  { Id: 21346, Code: '8122', Name: 'Man, infusion, water' },
  { Id: 21347, Code: '8122', Name: 'Man, job, odd, coal mine' },
  { Id: 21348, Code: '8122', Name: 'Man, machine, coal mine' },
  { Id: 21349, Code: '8122', Name: 'Man, market, coal mine' },
  { Id: 21350, Code: '8122', Name: 'Man, pan, coal mine' },
  { Id: 21351, Code: '8122', Name: 'Man, pit, coal mine' },
  { Id: 21352, Code: '8122', Name: 'Man, salvage, coal mine' },
  { Id: 21353, Code: '8122', Name: 'Man, screen, coal mine' },
  { Id: 21354, Code: '8122', Name: 'Man, supply, coal mine' },
  { Id: 21355, Code: '8122', Name: 'Man, surface, coal mine' },
  { Id: 21356, Code: '8122', Name: 'Man, timber, coal mine' },
  { Id: 21357, Code: '8122', Name: 'Man, utility, coal mine' },
  { Id: 21358, Code: '8122', Name: 'Man, waste, assistant, coal mine' },
  { Id: 21359, Code: '8122', Name: 'Man, waste, coal mine' },
  { Id: 21360, Code: '8122', Name: 'Manipulator, tub, coal mine' },
  { Id: 21361, Code: '8122', Name: 'Minder, belt, coal mine' },
  { Id: 21362, Code: '8122', Name: 'Minder, turn, coal mine' },
  { Id: 21363, Code: '8122', Name: 'Miner, coal' },
  { Id: 21364, Code: '8122', Name: 'Miner, coal mine' },
  { Id: 21365, Code: '8122', Name: 'Miner, coal, opencast' },
  { Id: 21366, Code: '8122', Name: 'Officer, control, roof, coal mine' },
  { Id: 21367, Code: '8122', Name: 'Officer, salvage, coal mine' },
  { Id: 21368, Code: '8122', Name: 'Official, coal mine' },
  { Id: 21369, Code: '8122', Name: 'Operator, cleaner, dry, coal mine' },
  { Id: 21370, Code: '8122', Name: 'Operator, cutter, coal' },
  { Id: 21371, Code: '8122', Name: 'Operator, disc, coal mine' },
  { Id: 21372, Code: '8122', Name: 'Operator, flotation, froth, coal mine' },
  { Id: 21373, Code: '8122', Name: 'Operator, loader, power' },
  { Id: 21374, Code: '8122', Name: 'Operator, slusher, coal mine' },
  { Id: 21375, Code: '8122', Name: 'Operator, switch, coal mine' },
  { Id: 21376, Code: '8122', Name: 'Overman, deputy, coal mine' },
  { Id: 21377, Code: '8122', Name: 'Overman, mining' },
  { Id: 21378, Code: '8122', Name: 'Packer, coal mine' },
  { Id: 21379, Code: '8122', Name: 'Panner, coal mine' },
  { Id: 21380, Code: '8122', Name: 'Patrol, coal mine' },
  { Id: 21381, Code: '8122', Name: 'Patrolman, coal mine' },
  { Id: 21382, Code: '8122', Name: 'Picker, coal mine' },
  { Id: 21383, Code: '8122', Name: 'Picker, stone, coal mine' },
  { Id: 21384, Code: '8122', Name: 'Presser, filter, coal mine' },
  { Id: 21385, Code: '8122', Name: 'Propman, coal mine' },
  { Id: 21386, Code: '8122', Name: 'Propper, coal mine' },
  { Id: 21387, Code: '8122', Name: 'Putter, coal mine' },
  { Id: 21388, Code: '8122', Name: 'Repairer, airway, coal mine' },
  { Id: 21389, Code: '8122', Name: 'Repairer, coal mine' },
  { Id: 21390, Code: '8122', Name: 'Repairer, road, coal mine' },
  { Id: 21391, Code: '8122', Name: 'Rider, coal mine' },
  { Id: 21392, Code: '8122', Name: 'Ripper, coal mine' },
  { Id: 21393, Code: '8122', Name: 'Runner, belt, coal mine' },
  { Id: 21394, Code: '8122', Name: 'Runner, wagon, coal mine' },
  { Id: 21395, Code: '8122', Name: 'Scalloper, coal mine' },
  { Id: 21396, Code: '8122', Name: 'Scraper, coal mine' },
  { Id: 21397, Code: '8122', Name: 'Screener, coal' },
  { Id: 21398, Code: '8122', Name: 'Screener, coal mine' },
  { Id: 21399, Code: '8122', Name: 'Screenworker, coal mine' },
  { Id: 21400, Code: '8122', Name: 'Setter, prop, coal mine' },
  { Id: 21401, Code: '8122', Name: 'Shearer, coal mine: below ground' },
  { Id: 21402, Code: '8122', Name: 'Shifter, coal mine' },
  { Id: 21403, Code: '8122', Name: 'Shifthand, coal mine' },
  { Id: 21404, Code: '8122', Name: 'Shotsman, coal mine' },
  { Id: 21405, Code: '8122', Name: 'Sinker, coal mine' },
  { Id: 21406, Code: '8122', Name: 'Smudger' },
  { Id: 21407, Code: '8122', Name: 'Sorter, coal, coal mine' },
  { Id: 21408, Code: '8122', Name: 'Spragger, coal mine' },
  { Id: 21409, Code: '8122', Name: 'Stemmer, coal mine' },
  { Id: 21410, Code: '8122', Name: 'Stoneman, coal mine' },
  { Id: 21411, Code: '8122', Name: 'Stower, coal' },
  { Id: 21412, Code: '8122', Name: 'Stower, coal mine' },
  { Id: 21413, Code: '8122', Name: 'Stower, power' },
  { Id: 21414, Code: '8122', Name: 'Striker, catch, coal mine' },
  { Id: 21415, Code: '8122', Name: 'Stripper, coal mine' },
  { Id: 21416, Code: '8122', Name: 'Sumper' },
  { Id: 21417, Code: '8122', Name: 'Supplier, timber, coal mine' },
  { Id: 21418, Code: '8122', Name: 'Suppressor, dust, coal mine' },
  { Id: 21419, Code: '8122', Name: 'Switchman, coal mine' },
  { Id: 21420, Code: '8122', Name: 'Teemer, coal, coal mine: above ground' },
  { Id: 21421, Code: '8122', Name: 'Timberer, coal mine' },
  { Id: 21422, Code: '8122', Name: 'Tipper, coal mine' },
  { Id: 21423, Code: '8122', Name: 'Tipplerman, coal mine' },
  { Id: 21424, Code: '8122', Name: 'Topman, coal mine' },
  { Id: 21425, Code: '8122', Name: 'Tracker, coal mine' },
  { Id: 21426, Code: '8122', Name: 'Transporter, cable, coal mine' },
  { Id: 21427, Code: '8122', Name: 'Transporter, supplies, coal mine' },
  { Id: 21428, Code: '8122', Name: 'Trimmer, coal mine' },
  { Id: 21429, Code: '8122', Name: 'Trimmer, coal, coal mine' },
  { Id: 21430, Code: '8122', Name: 'Trimmer, face, coal mine' },
  { Id: 21431, Code: '8122', Name: 'Truckman, coal mine' },
  { Id: 21432, Code: '8122', Name: 'Tunneller, coal mine' },
  { Id: 21433, Code: '8122', Name: 'Wageman, coal mine' },
  { Id: 21434, Code: '8122', Name: 'Washer, coal, coal mine' },
  { Id: 21435, Code: '8122', Name: 'Washer, hand, coal mine' },
  { Id: 21436, Code: '8122', Name: 'Waterman, coal mine' },
  { Id: 21437, Code: '8122', Name: 'Worker, colliery' },
  { Id: 21438, Code: '8122', Name: 'Worker, contract, coal mine' },
  { Id: 21439, Code: '8122', Name: 'Worker, development, coal mine' },
  { Id: 21440, Code: '8122', Name: 'Worker, face, coal mine' },
  { Id: 21441, Code: '8122', Name: 'Worker, general, coal mine' },
  { Id: 21442, Code: '8122', Name: 'Worker, ground, above, coal mine' },
  { Id: 21443, Code: '8122', Name: 'Worker, ground, under, coal mine' },
  { Id: 21444, Code: '8122', Name: 'Worker, haulage, coal mine' },
  { Id: 21445, Code: '8122', Name: 'Worker, mine, coal mine' },
  { Id: 21446, Code: '8122', Name: 'Worker, odd, coal mine' },
  { Id: 21447, Code: '8122', Name: 'Worker, piece, coal mine' },
  { Id: 21448, Code: '8122', Name: 'Worker, pit, coal mine' },
  { Id: 21449, Code: '8122', Name: 'Worker, repair, coal mine' },
  { Id: 21450, Code: '8122', Name: 'Worker, salvage, coal mine' },
  { Id: 21451, Code: '8122', Name: 'Worker, screen, coal mine' },
  { Id: 21452, Code: '8122', Name: 'Worker, shift, coal mine' },
  { Id: 21453, Code: '8122', Name: 'Worker, slurry, coal mine' },
  { Id: 21454, Code: '8122', Name: 'Worker, stone, coal mine' },
  { Id: 21455, Code: '8122', Name: 'Worker, surface, coal mine' },
  { Id: 21456, Code: '8122', Name: 'Worker, task, coal mine' },
  { Id: 21457, Code: '8122', Name: 'Worker, transit, coal mine' },
  { Id: 21458, Code: '8122', Name: 'Worker, underground, coal mine' },
  { Id: 21459, Code: '8122', Name: 'Worker, waste, coal mine' },
  { Id: 21460, Code: '8123', Name: 'Airwayman, mine: not coal' },
  { Id: 21461, Code: '8123', Name: "Assistant, sinker's" },
  { Id: 21462, Code: '8123', Name: 'Attendant, crusher' },
  { Id: 21463, Code: '8123', Name: 'Attendant, frame, mine: not coal' },
  { Id: 21464, Code: '8123', Name: 'Attendant, house, filter, mine: not coal' },
  { Id: 21465, Code: '8123', Name: 'Attendant, plant, coal' },
  { Id: 21466, Code: '8123', Name: 'Attendant, plant, crushing, ore' },
  { Id: 21467, Code: '8123', Name: 'Attendant, plant, lime' },
  { Id: 21468, Code: '8123', Name: 'Attendant, plant, quarry' },
  { Id: 21469, Code: '8123', Name: 'Attendant, screen, mine' },
  { Id: 21470, Code: '8123', Name: 'Attendant, unit, cracker' },
  { Id: 21471, Code: '8123', Name: 'Blaster, mine: not coal' },
  { Id: 21472, Code: '8123', Name: 'Borer, artesian' },
  { Id: 21473, Code: '8123', Name: 'Borer, ironstone' },
  { Id: 21474, Code: '8123', Name: 'Borer, mine: not coal' },
  { Id: 21475, Code: '8123', Name: 'Borer, shot' },
  { Id: 21476, Code: '8123', Name: 'Borer, well' },
  { Id: 21477, Code: '8123', Name: 'Boss, shift' },
  { Id: 21478, Code: '8123', Name: 'Braker, mine: not coal' },
  { Id: 21479, Code: '8123', Name: 'Brakesman, mine: not coal' },
  { Id: 21480, Code: '8123', Name: 'Breaker and filler' },
  { Id: 21481, Code: '8123', Name: 'Breaker, coal' },
  { Id: 21482, Code: '8123', Name: 'Breaker, mine: not coal' },
  { Id: 21483, Code: '8123', Name: 'Breaker, rock' },
  { Id: 21484, Code: '8123', Name: 'Breaker, stone, mine: not coal' },
  { Id: 21485, Code: '8123', Name: 'Buddler' },
  { Id: 21486, Code: '8123', Name: 'Captain, mine: not coal' },
  { Id: 21487, Code: '8123', Name: 'Captain, underground' },
  { Id: 21488, Code: '8123', Name: 'Carter, mine: not coal' },
  { Id: 21489, Code: '8123', Name: 'Chainman, mine: not coal' },
  { Id: 21490, Code: '8123', Name: 'Changer, drill' },
  { Id: 21491, Code: '8123', Name: 'Channeller, mine: not coal' },
  { Id: 21492, Code: '8123', Name: 'Charger, drill' },
  { Id: 21493, Code: '8123', Name: 'Cleaner, blende' },
  { Id: 21494, Code: '8123', Name: 'Cleaner, coal' },
  { Id: 21495, Code: '8123', Name: 'Cleaner, stone, IRON' },
  { Id: 21496, Code: '8123', Name: 'Cleaner, tin, mine: not coal' },
  { Id: 21497, Code: '8123', Name: 'Contractor, mine: not coal' },
  { Id: 21498, Code: '8123', Name: 'Crusher, ABRASIVES' },
  { Id: 21499, Code: '8123', Name: 'Crusher, mine not coal, ROCK' },
  { Id: 21500, Code: '8123', Name: 'Crusher, mines and quarries, MINERALS' },
  { Id: 21501, Code: '8123', Name: 'Crusher, slag' },
  { Id: 21502, Code: '8123', Name: 'Crusherman, mine not coal, ROCK' },
  { Id: 21503, Code: '8123', Name: 'Cutter, bottom' },
  { Id: 21504, Code: '8123', Name: 'Cutter, clay' },
  { Id: 21505, Code: '8123', Name: 'Cutter, machine, mine: not coal' },
  { Id: 21506, Code: '8123', Name: 'Cutter, mine: not coal' },
  { Id: 21507, Code: '8123', Name: 'Cutter, top' },
  { Id: 21508, Code: '8123', Name: 'Delinter' },
  { Id: 21509, Code: '8123', Name: 'Delver, mine: not coal' },
  { Id: 21510, Code: '8123', Name: 'Deputy, mine: not coal' },
  { Id: 21511, Code: '8123', Name: 'Derrickman, oil wells' },
  { Id: 21512, Code: '8123', Name: 'Digger, mine: not coal' },
  { Id: 21513, Code: '8123', Name: 'Drawer, prop' },
  { Id: 21514, Code: '8123', Name: 'Drawer, timber, mine: not coal' },
  { Id: 21515, Code: '8123', Name: 'Dresser, tin' },
  { Id: 21516, Code: '8123', Name: 'Drifter, mine: not coal' },
  { Id: 21517, Code: '8123', Name: 'Driller, civil engineering construction' },
  { Id: 21518, Code: '8123', Name: 'Driller, core' },
  { Id: 21519, Code: '8123', Name: 'Driller, diamond, well sinking' },
  { Id: 21520, Code: '8123', Name: 'Driller, machine, mine: not coal' },
  { Id: 21521, Code: '8123', Name: 'Driller, marine operations' },
  { Id: 21522, Code: '8123', Name: 'Driller, mine: not coal' },
  { Id: 21523, Code: '8123', Name: 'Driller, sample, mine: not coal' },
  { Id: 21524, Code: '8123', Name: 'Driller, well' },
  { Id: 21525, Code: '8123', Name: 'Driller, well sinking' },
  { Id: 21526, Code: '8123', Name: 'Driver, crusher, mine: not coal' },
  { Id: 21527, Code: '8123', Name: 'Driver, drill, mine: not coal' },
  { Id: 21528, Code: '8123', Name: 'Driver, mine: not coal: below ground' },
  { Id: 21529, Code: '8123', Name: 'Driver, roller, oil wells' },
  { Id: 21530, Code: '8123', Name: 'Engineer, boring' },
  { Id: 21531, Code: '8123', Name: 'Excavator, mine: not coal' },
  { Id: 21532, Code: '8123', Name: 'Feeder, crusher, mine: not coal' },
  { Id: 21533, Code: '8123', Name: 'Feeder, mine: not coal' },
  { Id: 21534, Code: '8123', Name: 'Ferrier' },
  { Id: 21535, Code: '8123', Name: 'Fireman, mine: not coal' },
  { Id: 21536, Code: '8123', Name: 'Firer, shot, mine: not coal' },
  { Id: 21537, Code: '8123', Name: 'Foreman, drill, mine: not coal' },
  { Id: 21538, Code: '8123', Name: 'Foreman, explosives, mine: not coal' },
  { Id: 21539, Code: '8123', Name: 'Foreman, mine: not coal' },
  { Id: 21540, Code: '8123', Name: 'Foreman, oil wells' },
  { Id: 21541, Code: '8123', Name: 'Foreman, outside, mine: not coal' },
  {
    Id: 21542,
    Code: '8123',
    Name: 'Foreman, pit, AGGREGATES, CLAY, GRAVEL, SAND'
  },
  { Id: 21543, Code: '8123', Name: 'Foreman, quarry' },
  { Id: 21544, Code: '8123', Name: 'Foreman, rock, mine: not coal' },
  { Id: 21545, Code: '8123', Name: 'Foreman, screen' },
  { Id: 21546, Code: '8123', Name: 'Foreman, screens' },
  { Id: 21547, Code: '8123', Name: 'Foreman, surface, mine: not coal' },
  { Id: 21548, Code: '8123', Name: 'Foreman, yard, mine: not coal' },
  { Id: 21549, Code: '8123', Name: 'Gaulter' },
  { Id: 21550, Code: '8123', Name: 'Getter, clay' },
  { Id: 21551, Code: '8123', Name: 'Getter, mine: not coal' },
  { Id: 21552, Code: '8123', Name: 'Grinder, ganister' },
  { Id: 21553, Code: '8123', Name: 'Grinder, mine: not coal' },
  { Id: 21554, Code: '8123', Name: 'Grinder, stone, lime, quarry' },
  { Id: 21555, Code: '8123', Name: 'Hand, mill, mine: not coal' },
  { Id: 21556, Code: '8123', Name: 'Hand, rock, mine: not coal' },
  { Id: 21557, Code: '8123', Name: 'Hand, surface, mine: not coal' },
  { Id: 21558, Code: '8123', Name: "Helper, miner's, mine: not coal" },
  { Id: 21559, Code: '8123', Name: "Helper, operator's, wireline" },
  { Id: 21560, Code: '8123', Name: 'Holer, mine: not coal' },
  { Id: 21561, Code: '8123', Name: 'Hoseman' },
  { Id: 21562, Code: '8123', Name: 'Jigman, mine: not coal' },
  { Id: 21563, Code: '8123', Name: 'Keeker' },
  { Id: 21564, Code: '8123', Name: "Labourer, miner's, tunnel" },
  { Id: 21565, Code: '8123', Name: 'Lugger, mine: not coal' },
  { Id: 21566, Code: '8123', Name: 'Machinist, drilling, mine: not coal' },
  { Id: 21567, Code: '8123', Name: 'Machinist, mine: not coal' },
  { Id: 21568, Code: '8123', Name: 'Machinist, ratchet' },
  { Id: 21569, Code: '8123', Name: 'Magneter' },
  {
    Id: 21570,
    Code: '8123',
    Name: 'Man, bargain, mine: not coal: below ground'
  },
  { Id: 21571, Code: '8123', Name: 'Man, clay, clay pit' },
  { Id: 21572, Code: '8123', Name: 'Man, drill, mine: not coal' },
  { Id: 21573, Code: '8123', Name: 'Man, floor, derrick, oil wells' },
  { Id: 21574, Code: '8123', Name: 'Man, floor, oil wells' },
  { Id: 21575, Code: '8123', Name: 'Man, mica, mine: not coal' },
  { Id: 21576, Code: '8123', Name: 'Man, mill, mine: not coal' },
  { Id: 21577, Code: '8123', Name: 'Man, pit, mine: not coal' },
  { Id: 21578, Code: '8123', Name: 'Man, quarry' },
  {
    Id: 21579,
    Code: '8123',
    Name: 'Man, timber, building construction: tunnelling contracting'
  },
  { Id: 21580, Code: '8123', Name: 'Man, timber, mine: not coal' },
  { Id: 21581, Code: '8123', Name: 'Master, mine: metal ore' },
  { Id: 21582, Code: '8123', Name: 'Master, quarry' },
  { Id: 21583, Code: '8123', Name: 'Miller, mine: not coal' },
  { Id: 21584, Code: '8123', Name: 'Miller, stone, mine: not coal' },
  { Id: 21585, Code: '8123', Name: 'Minder, crusher, mine: not coal' },
  { Id: 21586, Code: '8123', Name: 'Miner, clay' },
  { Id: 21587, Code: '8123', Name: 'Miner, mine: not coal' },
  { Id: 21588, Code: '8123', Name: 'Miner, tin' },
  { Id: 21589, Code: '8123', Name: 'Operative, pit' },
  { Id: 21590, Code: '8123', Name: 'Operative, quarry' },
  { Id: 21591, Code: '8123', Name: 'Operator, crusher, mine' },
  { Id: 21592, Code: '8123', Name: 'Operator, plant, coal mine: coal washery' },
  { Id: 21593, Code: '8123', Name: 'Operator, plant, flotation' },
  { Id: 21594, Code: '8123', Name: 'Operator, plant, quarry' },
  { Id: 21595, Code: '8123', Name: 'Operator, process, clay extraction' },
  { Id: 21596, Code: '8123', Name: 'Operator, screen, mines: quarries' },
  { Id: 21597, Code: '8123', Name: 'Operator, separator, magnetic' },
  { Id: 21598, Code: '8123', Name: 'Operator, washery' },
  { Id: 21599, Code: '8123', Name: 'Operator, wireline' },
  { Id: 21600, Code: '8123', Name: 'Packer, mine: not coal' },
  { Id: 21601, Code: '8123', Name: 'Picker, flint' },
  { Id: 21602, Code: '8123', Name: 'Picker, metal, mine: not coal' },
  { Id: 21603, Code: '8123', Name: 'Picker, mine: not coal' },
  { Id: 21604, Code: '8123', Name: 'Popper' },
  { Id: 21605, Code: '8123', Name: 'Presser, mine: not coal' },
  { Id: 21606, Code: '8123', Name: 'Quarrier' },
  { Id: 21607, Code: '8123', Name: 'Refiner, clay, china' },
  { Id: 21608, Code: '8123', Name: 'Repairer, airway, mine: not coal' },
  { Id: 21609, Code: '8123', Name: 'Riddler, mine: not coal' },
  { Id: 21610, Code: '8123', Name: 'Riddler, sand' },
  { Id: 21611, Code: '8123', Name: 'Rockman, mine: not coal' },
  { Id: 21612, Code: '8123', Name: 'Roughneck' },
  { Id: 21613, Code: '8123', Name: 'Screener, mine: not coal' },
  { Id: 21614, Code: '8123', Name: 'Securer' },
  { Id: 21615, Code: '8123', Name: 'Separator, ore' },
  { Id: 21616, Code: '8123', Name: 'Setter, timber' },
  { Id: 21617, Code: '8123', Name: 'Shiftman, mine: not coal' },
  { Id: 21618, Code: '8123', Name: 'Shotman, mine: not coal' },
  { Id: 21619, Code: '8123', Name: 'Splitter, mine: not coal: below ground' },
  { Id: 21620, Code: '8123', Name: 'Stamper, mine: not coal' },
  { Id: 21621, Code: '8123', Name: 'Stripper, mine: not coal' },
  { Id: 21622, Code: '8123', Name: 'Supervisor, service, well, oil wells' },
  { Id: 21623, Code: '8123', Name: 'Trimmer, lime' },
  { Id: 21624, Code: '8123', Name: 'Washer, gravel' },
  { Id: 21625, Code: '8123', Name: 'Washer, grit' },
  { Id: 21626, Code: '8123', Name: 'Washer, lime' },
  { Id: 21627, Code: '8123', Name: 'Washer, mine: not coal' },
  { Id: 21628, Code: '8123', Name: 'Washer, stone' },
  { Id: 21629, Code: '8123', Name: 'Woodman, mine: not coal' },
  { Id: 21630, Code: '8123', Name: 'Worker, clay, clay pit' },
  { Id: 21631, Code: '8123', Name: 'Worker, gravel, gravel extraction' },
  { Id: 21632, Code: '8123', Name: 'Worker, ground, above, mine: not coal' },
  { Id: 21633, Code: '8123', Name: 'Worker, ground, under, mine: not coal' },
  { Id: 21634, Code: '8123', Name: 'Worker, ironstone' },
  { Id: 21635, Code: '8123', Name: 'Worker, mine, mine: not coal' },
  { Id: 21636, Code: '8123', Name: 'Worker, opencast' },
  { Id: 21637, Code: '8123', Name: 'Worker, plant, hydrating, lime' },
  { Id: 21638, Code: '8123', Name: 'Worker, plant, screening' },
  { Id: 21639, Code: '8123', Name: 'Worker, process, clay extraction' },
  { Id: 21640, Code: '8123', Name: 'Worker, quarry' },
  { Id: 21641, Code: '8123', Name: 'Worker, rig, oil' },
  { Id: 21642, Code: '8123', Name: 'Worker, rig, oil, well drilling' },
  { Id: 21643, Code: '8123', Name: 'Worker, sand, quarry' },
  { Id: 21644, Code: '8123', Name: 'Worker, stone, mine: not coal' },
  { Id: 21645, Code: '8123', Name: 'Worker, surface, mine: not coal' },
  { Id: 21646, Code: '8123', Name: 'Worker, underground, mine: not coal' },
  { Id: 21647, Code: '8124', Name: 'APA, power station' },
  { Id: 21648, Code: '8124', Name: 'Assistant, control, electricity supplies' },
  { Id: 21649, Code: '8124', Name: "Assistant, driver's, engine" },
  { Id: 21650, Code: '8124', Name: "Assistant, driver's, turbine" },
  { Id: 21651, Code: '8124', Name: 'Assistant, room, engine' },
  { Id: 21652, Code: '8124', Name: 'Assistant, station, electricity supplier' },
  { Id: 21653, Code: '8124', Name: "Assistant, stoker's" },
  { Id: 21654, Code: '8124', Name: 'Assistant, sub-station' },
  { Id: 21655, Code: '8124', Name: 'Attendant, alternator' },
  { Id: 21656, Code: '8124', Name: 'Attendant, board, control' },
  { Id: 21657, Code: '8124', Name: 'Attendant, board, switch' },
  { Id: 21658, Code: '8124', Name: 'Attendant, boiler' },
  { Id: 21659, Code: '8124', Name: 'Attendant, booster' },
  { Id: 21660, Code: '8124', Name: 'Attendant, compression, air' },
  { Id: 21661, Code: '8124', Name: 'Attendant, compressor' },
  { Id: 21662, Code: '8124', Name: 'Attendant, condenser, power station' },
  { Id: 21663, Code: '8124', Name: 'Attendant, dynamo' },
  { Id: 21664, Code: '8124', Name: 'Attendant, engine' },
  { Id: 21665, Code: '8124', Name: 'Attendant, gas, steelworks' },
  { Id: 21666, Code: '8124', Name: 'Attendant, gear, coal mine' },
  { Id: 21667, Code: '8124', Name: 'Attendant, generator' },
  { Id: 21668, Code: '8124', Name: 'Attendant, heating' },
  { Id: 21669, Code: '8124', Name: 'Attendant, house, boiler' },
  { Id: 21670, Code: '8124', Name: 'Attendant, house, booster' },
  { Id: 21671, Code: '8124', Name: 'Attendant, house, compressor' },
  { Id: 21672, Code: '8124', Name: 'Attendant, house, power' },
  { Id: 21673, Code: '8124', Name: 'Attendant, machinery' },
  { Id: 21674, Code: '8124', Name: 'Attendant, motor' },
  { Id: 21675, Code: '8124', Name: 'Attendant, plant' },
  { Id: 21676, Code: '8124', Name: 'Attendant, plant, auxiliary' },
  { Id: 21677, Code: '8124', Name: 'Attendant, plant, boiler' },
  { Id: 21678, Code: '8124', Name: 'Attendant, plant, electric' },
  { Id: 21679, Code: '8124', Name: 'Attendant, pump, air' },
  { Id: 21680, Code: '8124', Name: 'Attendant, room, boiler' },
  { Id: 21681, Code: '8124', Name: 'Attendant, room, compressor' },
  { Id: 21682, Code: '8124', Name: 'Attendant, room, power' },
  { Id: 21683, Code: '8124', Name: 'Attendant, station, power' },
  {
    Id: 21684,
    Code: '8124',
    Name: 'Attendant, station, sub, electricity supplier'
  },
  { Id: 21685, Code: '8124', Name: 'Attendant, substation' },
  { Id: 21686, Code: '8124', Name: 'Attendant, transformer' },
  { Id: 21687, Code: '8124', Name: 'Attendant, turbine' },
  { Id: 21688, Code: '8124', Name: 'Beater, fire' },
  { Id: 21689, Code: '8124', Name: 'Blower, chemical mfr' },
  { Id: 21690, Code: '8124', Name: 'Blower, soot, power station' },
  { Id: 21691, Code: '8124', Name: 'Boiler, steam' },
  { Id: 21692, Code: '8124', Name: 'Boilerman' },
  { Id: 21693, Code: '8124', Name: 'Booster, gas, steelworks' },
  { Id: 21694, Code: '8124', Name: 'Bunkerman, power station' },
  { Id: 21695, Code: '8124', Name: 'Cleaner, lamp, valve mfr' },
  { Id: 21696, Code: '8124', Name: "Compressor, engineer's" },
  { Id: 21697, Code: '8124', Name: 'Compressor, gas' },
  { Id: 21698, Code: '8124', Name: 'Controller, electrical, railways' },
  { Id: 21699, Code: '8124', Name: 'Controller, power, railways' },
  { Id: 21700, Code: '8124', Name: 'Driver, compressor' },
  { Id: 21701, Code: '8124', Name: 'Driver, engine' },
  { Id: 21702, Code: '8124', Name: 'Driver, engine, mining' },
  { Id: 21703, Code: '8124', Name: 'Driver, house, power' },
  { Id: 21704, Code: '8124', Name: 'Driver, motor, coal mine' },
  { Id: 21705, Code: '8124', Name: 'Driver, turbine' },
  { Id: 21706, Code: '8124', Name: 'Driver, turbo-blower' },
  { Id: 21707, Code: '8124', Name: 'Engineer, boiler' },
  { Id: 21708, Code: '8124', Name: 'Engineer, boilerhouse' },
  { Id: 21709, Code: '8124', Name: 'Engineer, steam' },
  { Id: 21710, Code: '8124', Name: 'Engineer, turbine' },
  { Id: 21711, Code: '8124', Name: 'Engineman' },
  { Id: 21712, Code: '8124', Name: 'Engineman, hydraulic' },
  { Id: 21713, Code: '8124', Name: 'Feeder, boiler' },
  { Id: 21714, Code: '8124', Name: 'Fireman, boiler' },
  { Id: 21715, Code: '8124', Name: 'Fireman, coal mine: above ground' },
  { Id: 21716, Code: '8124', Name: 'Fireman, electricity supply' },
  { Id: 21717, Code: '8124', Name: 'Fireman, engine' },
  { Id: 21718, Code: '8124', Name: 'Fireman, surface' },
  { Id: 21719, Code: '8124', Name: 'Firer, boiler' },
  { Id: 21720, Code: '8124', Name: 'Foreman, boiler' },
  { Id: 21721, Code: '8124', Name: 'Foreman, house, boiler' },
  { Id: 21722, Code: '8124', Name: 'Foreman, house, power' },
  {
    Id: 21723,
    Code: '8124',
    Name: 'Foreman, plant, coal, electricity supplier'
  },
  { Id: 21724, Code: '8124', Name: 'Foreman, power station' },
  { Id: 21725, Code: '8124', Name: 'Foreman, turbine' },
  { Id: 21726, Code: '8124', Name: 'Furnaceman, boiler' },
  { Id: 21727, Code: '8124', Name: 'Hand, compressor' },
  { Id: 21728, Code: '8124', Name: 'Hand, maintenance, BOILERS' },
  { Id: 21729, Code: '8124', Name: 'Hand, switch' },
  { Id: 21730, Code: '8124', Name: 'Hand, switchboard' },
  { Id: 21731, Code: '8124', Name: 'Houseman, boiler' },
  { Id: 21732, Code: '8124', Name: 'Houseman, power' },
  {
    Id: 21733,
    Code: '8124',
    Name: 'Inspector, sub-station, electricity supplier'
  },
  { Id: 21734, Code: '8124', Name: 'Machinist, stationary' },
  { Id: 21735, Code: '8124', Name: 'Man, compressor' },
  { Id: 21736, Code: '8124', Name: 'Man, gas, assistant, iron and steelworks' },
  { Id: 21737, Code: '8124', Name: 'Man, house, power' },
  { Id: 21738, Code: '8124', Name: 'Man, maintenance, BOILERS' },
  { Id: 21739, Code: '8124', Name: 'Man, motor' },
  { Id: 21740, Code: '8124', Name: "Mate, stoker's" },
  { Id: 21741, Code: '8124', Name: 'Minder, boiler' },
  { Id: 21742, Code: '8124', Name: 'Minder, engine' },
  {
    Id: 21743,
    Code: '8124',
    Name: 'Officer, decommissioning, nuclear fuel production'
  },
  {
    Id: 21744,
    Code: '8124',
    Name: 'Operative, de-commissioning, nuclear power station'
  },
  { Id: 21745, Code: '8124', Name: 'Operator, boiler' },
  { Id: 21746, Code: '8124', Name: 'Operator, coal, power station' },
  { Id: 21747, Code: '8124', Name: 'Operator, compressor' },
  { Id: 21748, Code: '8124', Name: 'Operator, control, railways' },
  {
    Id: 21749,
    Code: '8124',
    Name: 'Operator, de-commissioning, nuclear fuel production'
  },
  {
    Id: 21750,
    Code: '8124',
    Name: 'Operator, de-commissioning, nuclear power station'
  },
  { Id: 21751, Code: '8124', Name: 'Operator, engine' },
  { Id: 21752, Code: '8124', Name: 'Operator, gearhead' },
  { Id: 21753, Code: '8124', Name: 'Operator, house, power' },
  { Id: 21754, Code: '8124', Name: 'Operator, plant, electricity supplier' },
  { Id: 21755, Code: '8124', Name: 'Operator, plant, nuclear power station' },
  { Id: 21756, Code: '8124', Name: 'Operator, plant, power' },
  { Id: 21757, Code: '8124', Name: 'Operator, room, control, ELECTRIC' },
  { Id: 21758, Code: '8124', Name: 'Operator, room, control, ELECTRICAL' },
  { Id: 21759, Code: '8124', Name: 'Operator, station, power' },
  { Id: 21760, Code: '8124', Name: 'Operator, switchboard, power station' },
  { Id: 21761, Code: '8124', Name: 'Operator, turbine' },
  { Id: 21762, Code: '8124', Name: 'Operator, unit' },
  { Id: 21763, Code: '8124', Name: 'Raiser, steam' },
  { Id: 21764, Code: '8124', Name: 'Regulator, steam' },
  { Id: 21765, Code: '8124', Name: 'Riser, steam' },
  { Id: 21766, Code: '8124', Name: 'Stoker' },
  { Id: 21767, Code: '8124', Name: 'Stoker, boiler' },
  { Id: 21768, Code: '8124', Name: 'Stoker, engine' },
  { Id: 21769, Code: '8124', Name: 'Stoker, furnace' },
  { Id: 21770, Code: '8124', Name: 'Stoker, leading' },
  { Id: 21771, Code: '8124', Name: 'Stoker, pressure, high' },
  { Id: 21772, Code: '8124', Name: 'Stoker-cleaner' },
  { Id: 21773, Code: '8124', Name: 'Stoker-engineer' },
  { Id: 21774, Code: '8124', Name: 'Stoker-mechanic' },
  { Id: 21775, Code: '8124', Name: 'Stoker-porter' },
  {
    Id: 21776,
    Code: '8124',
    Name: 'Superintendent, electrical, electricity supplier'
  },
  { Id: 21777, Code: '8124', Name: 'Superintendent, electricity supplier' },
  { Id: 21778, Code: '8124', Name: 'Superintendent, house, boiler' },
  {
    Id: 21779,
    Code: '8124',
    Name: 'Superintendent, house, turbine, electricity supplier'
  },
  { Id: 21780, Code: '8124', Name: 'Superintendent, power' },
  { Id: 21781, Code: '8124', Name: 'Superintendent, station, power' },
  {
    Id: 21782,
    Code: '8124',
    Name: 'Superintendent, works, electricity supplier'
  },
  {
    Id: 21783,
    Code: '8124',
    Name: 'Supervisor, decommissioning, nuclear fuel production'
  },
  { Id: 21784, Code: '8124', Name: 'Switchman, electricity supplier' },
  { Id: 21785, Code: '8124', Name: 'Tender, engine' },
  { Id: 21786, Code: '8124', Name: 'Tenter, boiler' },
  { Id: 21787, Code: '8124', Name: 'Tenter, engine' },
  { Id: 21788, Code: '8124', Name: 'Tenter, engine, blowing, metal mfr' },
  {
    Id: 21789,
    Code: '8124',
    Name: 'Worker, de-commissioning, nuclear power station'
  },
  { Id: 21790, Code: '8124', Name: 'Worker, engine' },
  { Id: 21791, Code: '8124', Name: 'Worker, house, power' },
  { Id: 21792, Code: '8124', Name: 'Worker, power' },
  { Id: 21793, Code: '8125', Name: 'Attendant, auto' },
  { Id: 21794, Code: '8125', Name: 'Attendant, automatic' },
  { Id: 21795, Code: '8125', Name: 'Attendant, lathe' },
  { Id: 21796, Code: '8125', Name: 'Attendant, mill, metal goods mfr' },
  { Id: 21797, Code: '8125', Name: 'Attendant, plant, blast, shot' },
  { Id: 21798, Code: '8125', Name: 'Attendant, punch' },
  { Id: 21799, Code: '8125', Name: 'Barreller' },
  { Id: 21800, Code: '8125', Name: 'Batterer' },
  { Id: 21801, Code: '8125', Name: 'Beader, tin box mfr' },
  { Id: 21802, Code: '8125', Name: 'Belter, FORKS AND SPADES' },
  { Id: 21803, Code: '8125', Name: 'Blaster, grit' },
  { Id: 21804, Code: '8125', Name: 'Blaster, sand' },
  { Id: 21805, Code: '8125', Name: 'Blaster, shot' },
  { Id: 21806, Code: '8125', Name: 'Blaster, vapour' },
  { Id: 21807, Code: '8125', Name: 'Blower, sand' },
  { Id: 21808, Code: '8125', Name: 'Blower, shot' },
  { Id: 21809, Code: '8125', Name: 'Bobber and polisher, spur' },
  { Id: 21810, Code: '8125', Name: 'Bobber, arc welding electrode mfr' },
  { Id: 21811, Code: '8125', Name: 'Bobber, emery' },
  { Id: 21812, Code: '8125', Name: 'Bobber, METAL GOODS' },
  { Id: 21813, Code: '8125', Name: 'Bottomer, metal goods mfr' },
  { Id: 21814, Code: '8125', Name: 'Breaker-off, foundry' },
  { Id: 21815, Code: '8125', Name: 'Brightener' },
  { Id: 21816, Code: '8125', Name: 'Broacher' },
  { Id: 21817, Code: '8125', Name: 'Brusher, foundry' },
  { Id: 21818, Code: '8125', Name: 'Brusher, scissors mfr' },
  { Id: 21819, Code: '8125', Name: 'Brusher, scratch' },
  { Id: 21820, Code: '8125', Name: 'Brusher, wire' },
  { Id: 21821, Code: '8125', Name: 'Brusher-in, scratch' },
  { Id: 21822, Code: '8125', Name: 'Buffer' },
  { Id: 21823, Code: '8125', Name: 'Bumper, tin box mfr' },
  { Id: 21824, Code: '8125', Name: 'Burnisher, metal trades' },
  { Id: 21825, Code: '8125', Name: 'Chamferer' },
  { Id: 21826, Code: '8125', Name: 'Channeller, metal trades' },
  { Id: 21827, Code: '8125', Name: 'Chipper, fish hook mfr' },
  { Id: 21828, Code: '8125', Name: 'Chipper, tyre' },
  { Id: 21829, Code: '8125', Name: 'Cleaner, casting' },
  { Id: 21830, Code: '8125', Name: 'Cleaner, foundry' },
  { Id: 21831, Code: '8125', Name: 'Cleaner, iron' },
  { Id: 21832, Code: '8125', Name: 'Cleaner, metal' },
  { Id: 21833, Code: '8125', Name: 'Cleaner, ring' },
  { Id: 21834, Code: '8125', Name: 'Cleaner, steel, foundry' },
  { Id: 21835, Code: '8125', Name: 'Coiner' },
  { Id: 21836, Code: '8125', Name: 'Corrugator, galvanised sheet mfr' },
  { Id: 21837, Code: '8125', Name: 'Cramper, NAILS, NEEDLES' },
  { Id: 21838, Code: '8125', Name: 'Cupper, shell' },
  { Id: 21839, Code: '8125', Name: 'Cutter, bar' },
  { Id: 21840, Code: '8125', Name: 'Cutter, billet, steelworks' },
  { Id: 21841, Code: '8125', Name: 'Cutter, blank, spoon, fork mfr' },
  { Id: 21842, Code: '8125', Name: 'Cutter, cable' },
  { Id: 21843, Code: '8125', Name: 'Cutter, circle' },
  { Id: 21844, Code: '8125', Name: 'Cutter, gold' },
  { Id: 21845, Code: '8125', Name: 'Cutter, guillotine, metal trades' },
  { Id: 21846, Code: '8125', Name: 'Cutter, key' },
  { Id: 21847, Code: '8125', Name: 'Cutter, knife, machine, metal trades' },
  { Id: 21848, Code: '8125', Name: 'Cutter, machine, punching, metal trades' },
  { Id: 21849, Code: '8125', Name: 'Cutter, metal' },
  { Id: 21850, Code: '8125', Name: 'Cutter, metal cable mfr' },
  { Id: 21851, Code: '8125', Name: 'Cutter, metal cutlery mfr' },
  { Id: 21852, Code: '8125', Name: 'Cutter, metal fastening mfr' },
  { Id: 21853, Code: '8125', Name: 'Cutter, metal trades' },
  { Id: 21854, Code: '8125', Name: 'Cutter, panel, METAL' },
  { Id: 21855, Code: '8125', Name: 'Cutter, pin' },
  { Id: 21856, Code: '8125', Name: 'Cutter, rail' },
  { Id: 21857, Code: '8125', Name: 'Cutter, rasp' },
  { Id: 21858, Code: '8125', Name: 'Cutter, rotary, metal trades' },
  { Id: 21859, Code: '8125', Name: 'Cutter, silver' },
  { Id: 21860, Code: '8125', Name: 'Cutter, steel pen mfr' },
  { Id: 21861, Code: '8125', Name: 'Cutter, test, rolling mill' },
  { Id: 21862, Code: '8125', Name: 'Cutter, tube, METAL' },
  { Id: 21863, Code: '8125', Name: 'Cutter, wire' },
  { Id: 21864, Code: '8125', Name: 'Cutter-off, iron pipe mfr' },
  { Id: 21865, Code: '8125', Name: 'Cutter-off, metal trades' },
  { Id: 21866, Code: '8125', Name: 'Cutter-up, scrap' },
  { Id: 21867, Code: '8125', Name: 'Deburrer' },
  { Id: 21868, Code: '8125', Name: 'Dollier, silversmiths' },
  { Id: 21869, Code: '8125', Name: 'Dresser, box, axle' },
  { Id: 21870, Code: '8125', Name: 'Dresser, casting' },
  { Id: 21871, Code: '8125', Name: 'Dresser, core' },
  { Id: 21872, Code: '8125', Name: 'Dresser, iron' },
  { Id: 21873, Code: '8125', Name: 'Dresser, metal' },
  { Id: 21874, Code: '8125', Name: 'Dresser, metal trades' },
  { Id: 21875, Code: '8125', Name: 'Dresser, pipe' },
  { Id: 21876, Code: '8125', Name: 'Dresser, plate' },
  { Id: 21877, Code: '8125', Name: 'Dresser, scissors' },
  { Id: 21878, Code: '8125', Name: 'Dresser, steel' },
  { Id: 21879, Code: '8125', Name: 'Dresser, tube' },
  { Id: 21880, Code: '8125', Name: 'Dresser, type foundry' },
  { Id: 21881, Code: '8125', Name: 'Dresser, wheel' },
  { Id: 21882, Code: '8125', Name: 'Embosser, metal trades' },
  { Id: 21883, Code: '8125', Name: 'Engineer, nos' },
  { Id: 21884, Code: '8125', Name: 'Eyer, NEEDLES' },
  { Id: 21885, Code: '8125', Name: 'Facer, metal fastening mfr' },
  { Id: 21886, Code: '8125', Name: 'Feeder, metal fastening mfr' },
  { Id: 21887, Code: '8125', Name: 'Feeder, sheet metal working' },
  { Id: 21888, Code: '8125', Name: 'Feeder, tack' },
  { Id: 21889, Code: '8125', Name: 'Fettler' },
  { Id: 21890, Code: '8125', Name: 'Fettler, brass' },
  { Id: 21891, Code: '8125', Name: 'Fettler, castings' },
  { Id: 21892, Code: '8125', Name: 'Fettler, core' },
  { Id: 21893, Code: '8125', Name: 'Fettler, iron' },
  { Id: 21894, Code: '8125', Name: 'Fettler, metal trades' },
  { Id: 21895, Code: '8125', Name: 'Fettler, shop, machine' },
  { Id: 21896, Code: '8125', Name: 'Filer, foundry' },
  { Id: 21897, Code: '8125', Name: 'Filer, metal trades' },
  { Id: 21898, Code: '8125', Name: 'Filer, pattern' },
  { Id: 21899, Code: '8125', Name: 'Filer, spoon and fork' },
  { Id: 21900, Code: '8125', Name: 'Finisher, bobbin, brass' },
  { Id: 21901, Code: '8125', Name: 'Finisher, brass' },
  { Id: 21902, Code: '8125', Name: 'Finisher, brass foundry' },
  { Id: 21903, Code: '8125', Name: 'Finisher, cock founding' },
  { Id: 21904, Code: '8125', Name: 'Finisher, foundry' },
  { Id: 21905, Code: '8125', Name: 'Finisher, metal fastening mfr' },
  {
    Id: 21906,
    Code: '8125',
    Name: "Finisher, propeller, ships' propeller mfr"
  },
  { Id: 21907, Code: '8125', Name: 'Finisher, satin, metal trades' },
  { Id: 21908, Code: '8125', Name: "Finisher, smith's" },
  { Id: 21909, Code: '8125', Name: 'Finner' },
  { Id: 21910, Code: '8125', Name: 'Flanger, sheet metal working' },
  { Id: 21911, Code: '8125', Name: 'Flanger, tin box mfr' },
  { Id: 21912, Code: '8125', Name: 'Flattener, metal trades' },
  { Id: 21913, Code: '8125', Name: 'Floater, metal trades' },
  { Id: 21914, Code: '8125', Name: 'Floater, tube' },
  { Id: 21915, Code: '8125', Name: 'Fluter, drill' },
  { Id: 21916, Code: '8125', Name: 'Fluter, METAL' },
  { Id: 21917, Code: '8125', Name: 'Folder, box, tin box mfr' },
  { Id: 21918, Code: '8125', Name: 'Folder, drum, keg mfr' },
  { Id: 21919, Code: '8125', Name: 'Fraiser' },
  { Id: 21920, Code: '8125', Name: 'Frazer, metal trades' },
  { Id: 21921, Code: '8125', Name: 'Glazer, cutlery' },
  { Id: 21922, Code: '8125', Name: 'Glazer, metal trades' },
  { Id: 21923, Code: '8125', Name: 'Grinder, ball, BALL BEARING' },
  { Id: 21924, Code: '8125', Name: 'Grinder, cast, rough' },
  { Id: 21925, Code: '8125', Name: 'Grinder, castings' },
  { Id: 21926, Code: '8125', Name: 'Grinder, emery, steelworks' },
  { Id: 21927, Code: '8125', Name: 'Grinder, foundry' },
  { Id: 21928, Code: '8125', Name: 'Grinder, precious metal, plate mfr' },
  { Id: 21929, Code: '8125', Name: 'Groover, metal trades' },
  { Id: 21930, Code: '8125', Name: 'Hand, capstan, brass' },
  { Id: 21931, Code: '8125', Name: 'Hand, lathe' },
  { Id: 21932, Code: '8125', Name: 'Hand, turret' },
  { Id: 21933, Code: '8125', Name: 'Holer, flyer' },
  { Id: 21934, Code: '8125', Name: 'Honer' },
  { Id: 21935, Code: '8125', Name: 'Hydro-blaster' },
  { Id: 21936, Code: '8125', Name: 'Kicker, metal stamping' },
  { Id: 21937, Code: '8125', Name: 'Lapper, barrel, GUN' },
  { Id: 21938, Code: '8125', Name: 'Lapper, gear' },
  { Id: 21939, Code: '8125', Name: "Lapper, jeweller's" },
  { Id: 21940, Code: '8125', Name: 'Lapper, metal trades' },
  { Id: 21941, Code: '8125', Name: 'Latcher, needle mfr' },
  { Id: 21942, Code: '8125', Name: 'Linisher' },
  { Id: 21943, Code: '8125', Name: 'Machinist, action' },
  { Id: 21944, Code: '8125', Name: 'Machinist, anocut' },
  { Id: 21945, Code: '8125', Name: 'Machinist, atomic energy establishment' },
  { Id: 21946, Code: '8125', Name: 'Machinist, automatic' },
  { Id: 21947, Code: '8125', Name: 'Machinist, bearing, ball' },
  { Id: 21948, Code: '8125', Name: 'Machinist, bending, press' },
  { Id: 21949, Code: '8125', Name: 'Machinist, blasting, vapour' },
  { Id: 21950, Code: '8125', Name: 'Machinist, box, axle' },
  { Id: 21951, Code: '8125', Name: 'Machinist, brass' },
  { Id: 21952, Code: '8125', Name: 'Machinist, broaching' },
  { Id: 21953, Code: '8125', Name: 'Machinist, buffing, metal trades' },
  { Id: 21954, Code: '8125', Name: 'Machinist, cap, bottle' },
  { Id: 21955, Code: '8125', Name: 'Machinist, capstan' },
  { Id: 21956, Code: '8125', Name: 'Machinist, capsule, METAL' },
  { Id: 21957, Code: '8125', Name: 'Machinist, centering' },
  { Id: 21958, Code: '8125', Name: 'Machinist, coal mine: above ground' },
  { Id: 21959, Code: '8125', Name: 'Machinist, copying' },
  { Id: 21960, Code: '8125', Name: 'Machinist, cutting, gear' },
  { Id: 21961, Code: '8125', Name: 'Machinist, cycle' },
  { Id: 21962, Code: '8125', Name: 'Machinist, dressing, metal trades' },
  { Id: 21963, Code: '8125', Name: 'Machinist, drilling, metal trades' },
  { Id: 21964, Code: '8125', Name: "Machinist, engineer's" },
  { Id: 21965, Code: '8125', Name: 'Machinist, fettling, metal trades' },
  { Id: 21966, Code: '8125', Name: 'Machinist, flanging' },
  { Id: 21967, Code: '8125', Name: 'Machinist, garage' },
  { Id: 21968, Code: '8125', Name: 'Machinist, grinding, shaft' },
  { Id: 21969, Code: '8125', Name: 'Machinist, hand, metal trades' },
  { Id: 21970, Code: '8125', Name: 'Machinist, hobbing' },
  { Id: 21971, Code: '8125', Name: 'Machinist, honing' },
  { Id: 21972, Code: '8125', Name: 'Machinist, lapping, metal trades' },
  { Id: 21973, Code: '8125', Name: 'Machinist, making, chain, CYCLE CHAINS' },
  { Id: 21974, Code: '8125', Name: 'Machinist, metal' },
  { Id: 21975, Code: '8125', Name: 'Machinist, metal trades' },
  { Id: 21976, Code: '8125', Name: 'Machinist, mill, boring' },
  { Id: 21977, Code: '8125', Name: 'Machinist, nos, engineering' },
  { Id: 21978, Code: '8125', Name: 'Machinist, nosing' },
  { Id: 21979, Code: '8125', Name: 'Machinist, planing, metal trades' },
  { Id: 21980, Code: '8125', Name: 'Machinist, polishing, metal trades' },
  { Id: 21981, Code: '8125', Name: 'Machinist, press, metal trades' },
  { Id: 21982, Code: '8125', Name: 'Machinist, profiling' },
  { Id: 21983, Code: '8125', Name: 'Machinist, punching, metal trades' },
  { Id: 21984, Code: '8125', Name: 'Machinist, railway workshops' },
  { Id: 21985, Code: '8125', Name: 'Machinist, reamering' },
  { Id: 21986, Code: '8125', Name: 'Machinist, room, tool' },
  { Id: 21987, Code: '8125', Name: 'Machinist, router, metal trades' },
  { Id: 21988, Code: '8125', Name: 'Machinist, Royal Mint' },
  { Id: 21989, Code: '8125', Name: 'Machinist, sanding, METAL' },
  { Id: 21990, Code: '8125', Name: 'Machinist, screw, automatic' },
  { Id: 21991, Code: '8125', Name: 'Machinist, screwer' },
  { Id: 21992, Code: '8125', Name: 'Machinist, screwing' },
  { Id: 21993, Code: '8125', Name: 'Machinist, sealing, metal, automatic' },
  { Id: 21994, Code: '8125', Name: 'Machinist, seaming, metal trades' },
  { Id: 21995, Code: '8125', Name: 'Machinist, shaper' },
  { Id: 21996, Code: '8125', Name: 'Machinist, shaping, metal trades' },
  { Id: 21997, Code: '8125', Name: 'Machinist, sinking, die' },
  { Id: 21998, Code: '8125', Name: 'Machinist, slotter' },
  { Id: 21999, Code: '8125', Name: 'Machinist, slotting' },
  { Id: 22000, Code: '8125', Name: 'Machinist, stamping, metal trades' },
  { Id: 22001, Code: '8125', Name: 'Machinist, tapping' },
  {
    Id: 22002,
    Code: '8125',
    Name: 'Machinist, textile, textile machinery mfr'
  },
  { Id: 22003, Code: '8125', Name: 'Machinist, tool' },
  { Id: 22004, Code: '8125', Name: 'Machinist, weaving, wire' },
  { Id: 22005, Code: '8125', Name: 'Machinist, window, metal' },
  { Id: 22006, Code: '8125', Name: 'Maker, bar, steel, textile machinery mfr' },
  { Id: 22007, Code: '8125', Name: 'Maker, blade, razor' },
  { Id: 22008, Code: '8125', Name: 'Maker, bolt, clock mfr' },
  { Id: 22009, Code: '8125', Name: 'Maker, bridge' },
  { Id: 22010, Code: '8125', Name: 'Maker, can, METAL' },
  { Id: 22011, Code: '8125', Name: 'Maker, cap, butt, FISHING RODS' },
  { Id: 22012, Code: '8125', Name: 'Maker, cap, electric lamp mfr' },
  { Id: 22013, Code: '8125', Name: 'Maker, case, spring, SMALL ARMS' },
  { Id: 22014, Code: '8125', Name: 'Maker, clip, wire' },
  { Id: 22015, Code: '8125', Name: 'Maker, coin' },
  { Id: 22016, Code: '8125', Name: 'Maker, dab' },
  { Id: 22017, Code: '8125', Name: 'Maker, ferrule' },
  { Id: 22018, Code: '8125', Name: 'Maker, guard, fork' },
  { Id: 22019, Code: '8125', Name: 'Maker, key' },
  { Id: 22020, Code: '8125', Name: 'Maker, key, clock mfr' },
  { Id: 22021, Code: '8125', Name: 'Maker, nail' },
  { Id: 22022, Code: '8125', Name: 'Maker, nut' },
  { Id: 22023, Code: '8125', Name: 'Maker, roller, textile machinery mfr' },
  { Id: 22024, Code: '8125', Name: 'Maker, screen, malt' },
  { Id: 22025, Code: '8125', Name: 'Maker, sink, METAL' },
  { Id: 22026, Code: '8125', Name: 'Maker, sinker' },
  { Id: 22027, Code: '8125', Name: 'Maker, spring, carriage' },
  { Id: 22028, Code: '8125', Name: 'Maker, spring, flat' },
  { Id: 22029, Code: '8125', Name: 'Maker, spring, lock' },
  { Id: 22030, Code: '8125', Name: "Maker, stamp, bleacher's, METAL" },
  { Id: 22031, Code: '8125', Name: 'Maker, weight' },
  { Id: 22032, Code: '8125', Name: 'Man, filter, metal trades' },
  { Id: 22033, Code: '8125', Name: 'Man, lathe' },
  { Id: 22034, Code: '8125', Name: 'Man, vice' },
  { Id: 22035, Code: '8125', Name: 'Marker, fish hook mfr' },
  { Id: 22036, Code: '8125', Name: 'Marker, metal cutlery mfr' },
  { Id: 22037, Code: '8125', Name: 'Marker, needle mfr' },
  { Id: 22038, Code: '8125', Name: 'Masker, FILES' },
  { Id: 22039, Code: '8125', Name: "Mate, stamper's" },
  { Id: 22040, Code: '8125', Name: 'Medallist' },
  { Id: 22041, Code: '8125', Name: 'Modeller, art metal work mfr' },
  { Id: 22042, Code: '8125', Name: 'Mopper, metal trades' },
  { Id: 22043, Code: '8125', Name: 'Notcher, tin box mfr' },
  { Id: 22044, Code: '8125', Name: 'Operative, can, tin' },
  { Id: 22045, Code: '8125', Name: 'Operator, auto' },
  { Id: 22046, Code: '8125', Name: 'Operator, blast, sand' },
  { Id: 22047, Code: '8125', Name: 'Operator, blast, shot' },
  { Id: 22048, Code: '8125', Name: 'Operator, bullard' },
  { Id: 22049, Code: '8125', Name: 'Operator, capstan' },
  { Id: 22050, Code: '8125', Name: 'Operator, drill' },
  { Id: 22051, Code: '8125', Name: 'Operator, embosser, engineering' },
  { Id: 22052, Code: '8125', Name: 'Operator, grinder' },
  { Id: 22053, Code: '8125', Name: 'Operator, lathe, metal trades' },
  { Id: 22054, Code: '8125', Name: 'Operator, mill, boring' },
  { Id: 22055, Code: '8125', Name: 'Operator, mill, plano' },
  { Id: 22056, Code: '8125', Name: 'Operator, mill, steel foundry' },
  { Id: 22057, Code: '8125', Name: 'Operator, milling, metal trades' },
  {
    Id: 22058,
    Code: '8125',
    Name: 'Operator, press, hand, jewellery, plate mfr'
  },
  { Id: 22059, Code: '8125', Name: 'Operator, profile' },
  { Id: 22060, Code: '8125', Name: 'Operator, room, tool' },
  { Id: 22061, Code: '8125', Name: 'Operator, router' },
  { Id: 22062, Code: '8125', Name: 'Operator, Royal Mint' },
  { Id: 22063, Code: '8125', Name: 'Operator, tapping' },
  { Id: 22064, Code: '8125', Name: 'Operator, tool, machine' },
  { Id: 22065, Code: '8125', Name: 'Operator, turret' },
  { Id: 22066, Code: '8125', Name: 'Operator, wheelabrator' },
  { Id: 22067, Code: '8125', Name: 'Perforator, metal trades' },
  { Id: 22068, Code: '8125', Name: 'Piercer, pen nib mfr' },
  { Id: 22069, Code: '8125', Name: 'Pointer, hook, fish' },
  { Id: 22070, Code: '8125', Name: 'Pointer, needle mfr' },
  { Id: 22071, Code: '8125', Name: 'Polisher, barrel, GUN' },
  { Id: 22072, Code: '8125', Name: 'Polisher, bobbin' },
  { Id: 22073, Code: '8125', Name: 'Polisher, brass' },
  { Id: 22074, Code: '8125', Name: 'Polisher, brass musical instruments mfr' },
  { Id: 22075, Code: '8125', Name: 'Polisher, cutlery' },
  { Id: 22076, Code: '8125', Name: 'Polisher, cycle' },
  { Id: 22077, Code: '8125', Name: 'Polisher, die' },
  { Id: 22078, Code: '8125', Name: 'Polisher, emery' },
  { Id: 22079, Code: '8125', Name: 'Polisher, frame' },
  { Id: 22080, Code: '8125', Name: 'Polisher, gold' },
  { Id: 22081, Code: '8125', Name: 'Polisher, hame' },
  { Id: 22082, Code: '8125', Name: 'Polisher, jewellery' },
  { Id: 22083, Code: '8125', Name: 'Polisher, lathe, metal trades' },
  { Id: 22084, Code: '8125', Name: 'Polisher, lime' },
  { Id: 22085, Code: '8125', Name: 'Polisher, metal' },
  { Id: 22086, Code: '8125', Name: 'Polisher, metal trades' },
  { Id: 22087, Code: '8125', Name: 'Polisher, mirror, cutlery mfr' },
  { Id: 22088, Code: '8125', Name: 'Polisher, mould, METAL' },
  {
    Id: 22089,
    Code: '8125',
    Name: 'Polisher, plate, precious metal, plate mfr'
  },
  { Id: 22090, Code: '8125', Name: "Polisher, plater's" },
  { Id: 22091, Code: '8125', Name: 'Polisher, roll, tinplate mfr' },
  { Id: 22092, Code: '8125', Name: 'Polisher, roller' },
  { Id: 22093, Code: '8125', Name: 'Polisher, sand' },
  { Id: 22094, Code: '8125', Name: 'Polisher, silver' },
  { Id: 22095, Code: '8125', Name: 'Polisher, spoon and fork' },
  { Id: 22096, Code: '8125', Name: 'Polisher, steel, stainless' },
  { Id: 22097, Code: '8125', Name: 'Polisher, tin' },
  { Id: 22098, Code: '8125', Name: 'Presser' },
  { Id: 22099, Code: '8125', Name: 'Presser, coining' },
  { Id: 22100, Code: '8125', Name: 'Presser, component' },
  { Id: 22101, Code: '8125', Name: 'Presser, draw' },
  { Id: 22102, Code: '8125', Name: 'Presser, fly, cutlery mfr' },
  { Id: 22103, Code: '8125', Name: 'Presser, hand, metal trades' },
  { Id: 22104, Code: '8125', Name: 'Presser, hydraulic, metal trades' },
  { Id: 22105, Code: '8125', Name: 'Presser, machine, metal trades' },
  { Id: 22106, Code: '8125', Name: 'Presser, metal trades' },
  { Id: 22107, Code: '8125', Name: 'Presser, power, metal trades' },
  { Id: 22108, Code: '8125', Name: 'Presser, ring' },
  { Id: 22109, Code: '8125', Name: 'Presser, stamping' },
  { Id: 22110, Code: '8125', Name: 'Presser, steam, METAL' },
  { Id: 22111, Code: '8125', Name: 'Presser, tool' },
  { Id: 22112, Code: '8125', Name: 'Presser, tube' },
  { Id: 22113, Code: '8125', Name: 'Presser-out, textile machinery mfr' },
  { Id: 22114, Code: '8125', Name: 'Profiler, METAL' },
  { Id: 22115, Code: '8125', Name: 'Pronger, fork mfr' },
  { Id: 22116, Code: '8125', Name: 'Puller, stamp' },
  { Id: 22117, Code: '8125', Name: 'Pumicer, PRECIOUS METAL, PLATE' },
  { Id: 22118, Code: '8125', Name: 'Puncher and shearer' },
  { Id: 22119, Code: '8125', Name: 'Puncher, bar, steel' },
  { Id: 22120, Code: '8125', Name: 'Puncher, eye, NEEDLES' },
  { Id: 22121, Code: '8125', Name: 'Puncher, fishplate' },
  { Id: 22122, Code: '8125', Name: 'Puncher, metal trades' },
  { Id: 22123, Code: '8125', Name: 'Puncher, rail' },
  { Id: 22124, Code: '8125', Name: 'Puncher, tip, shoe' },
  { Id: 22125, Code: '8125', Name: 'Reamer, METAL' },
  { Id: 22126, Code: '8125', Name: 'Reamerer, barrel' },
  { Id: 22127, Code: '8125', Name: 'Reamerer, metal trades' },
  { Id: 22128, Code: '8125', Name: 'Rifler, barrel' },
  { Id: 22129, Code: '8125', Name: 'Roller, thread, SCREWS' },
  { Id: 22130, Code: '8125', Name: 'Rougher, foundry' },
  { Id: 22131, Code: '8125', Name: 'Rubber-down, jewellery, plate mfr' },
  { Id: 22132, Code: '8125', Name: 'Rubber-off, foundry' },
  { Id: 22133, Code: '8125', Name: 'Rumbler, METAL' },
  { Id: 22134, Code: '8125', Name: 'Sander, metal trades' },
  { Id: 22135, Code: '8125', Name: 'Scourer, foundry' },
  { Id: 22136, Code: '8125', Name: 'Scraper, metal' },
  { Id: 22137, Code: '8125', Name: 'Scratcher, metal trades' },
  { Id: 22138, Code: '8125', Name: 'Screwer, metal trades' },
  { Id: 22139, Code: '8125', Name: 'Screwer, tube' },
  { Id: 22140, Code: '8125', Name: 'Scrubber, chair' },
  { Id: 22141, Code: '8125', Name: 'Seamer, hollow-ware' },
  { Id: 22142, Code: '8125', Name: 'Seamer, metal trades' },
  { Id: 22143, Code: '8125', Name: 'Seater, key' },
  { Id: 22144, Code: '8125', Name: 'Settler, fish hook mfr' },
  { Id: 22145, Code: '8125', Name: 'Shaper, blades, airscrew, METAL' },
  { Id: 22146, Code: '8125', Name: 'Shaper, die' },
  { Id: 22147, Code: '8125', Name: 'Shaper, metal trades' },
  { Id: 22148, Code: '8125', Name: 'Sharpener, pick' },
  { Id: 22149, Code: '8125', Name: 'Sharpener, tool' },
  { Id: 22150, Code: '8125', Name: 'Shaver, gear' },
  { Id: 22151, Code: '8125', Name: 'Sinker, counter' },
  { Id: 22152, Code: '8125', Name: 'Sleever, electrical goods mfr' },
  { Id: 22153, Code: '8125', Name: 'Slotter, frame' },
  { Id: 22154, Code: '8125', Name: 'Slotter, metal trades' },
  { Id: 22155, Code: '8125', Name: 'Slotter, needle mfr' },
  { Id: 22156, Code: '8125', Name: 'Stamper, brass' },
  { Id: 22157, Code: '8125', Name: 'Stamper, cold' },
  { Id: 22158, Code: '8125', Name: 'Stamper, die' },
  { Id: 22159, Code: '8125', Name: 'Stamper, drop, sheet metal goods mfr' },
  { Id: 22160, Code: '8125', Name: 'Stamper, gold, jewellery, plate mfr' },
  { Id: 22161, Code: '8125', Name: 'Stamper, hollow-ware' },
  { Id: 22162, Code: '8125', Name: 'Stamper, metal' },
  { Id: 22163, Code: '8125', Name: 'Stamper, metal trades' },
  { Id: 22164, Code: '8125', Name: 'Stamper, silver' },
  { Id: 22165, Code: '8125', Name: 'Stripper, metal trades' },
  { Id: 22166, Code: '8125', Name: 'Tacker, tack mfr' },
  { Id: 22167, Code: '8125', Name: 'Taperer, metal trades' },
  {
    Id: 22168,
    Code: '8125',
    Name: 'Tapper, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 22169,
    Code: '8125',
    Name: 'Tapper, nut and socket, tube fittings mfr'
  },
  {
    Id: 22170,
    Code: '8125',
    Name: 'Threader, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22171, Code: '8125', Name: 'Tipper, metal' },
  { Id: 22172, Code: '8125', Name: 'Tracker, ball bearing mfr' },
  { Id: 22173, Code: '8125', Name: 'Trimmer, blade, aircraft mfr' },
  { Id: 22174, Code: '8125', Name: 'Trimmer, box, steelworks' },
  { Id: 22175, Code: '8125', Name: 'Trimmer, metal fastening mfr' },
  { Id: 22176, Code: '8125', Name: 'Trimmer, metal trades' },
  { Id: 22177, Code: '8125', Name: 'Trimmer, needle' },
  { Id: 22178, Code: '8125', Name: 'Trimmer, needle mfr' },
  { Id: 22179, Code: '8125', Name: 'Trimmer, steel' },
  { Id: 22180, Code: '8125', Name: 'Trimmer, tin box mfr' },
  { Id: 22181, Code: '8125', Name: 'Tumbler, metal trades' },
  { Id: 22182, Code: '8125', Name: 'Turner, brass' },
  { Id: 22183, Code: '8125', Name: 'Turner, optical' },
  { Id: 22184, Code: '8125', Name: 'Turner, shell' },
  { Id: 22185, Code: '8125', Name: 'Twister, wire, hat' },
  { Id: 22186, Code: '8125', Name: 'Weaver, cloth, wire' },
  { Id: 22187, Code: '8125', Name: 'Weaver, wire goods mfr' },
  { Id: 22188, Code: '8125', Name: 'Wheelabrator' },
  { Id: 22189, Code: '8125', Name: 'Wirer, box' },
  { Id: 22190, Code: '8125', Name: 'Worker, box, metal' },
  { Id: 22191, Code: '8125', Name: 'Worker, brass' },
  { Id: 22192, Code: '8125', Name: 'Worker, bronze, architectural' },
  { Id: 22193, Code: '8125', Name: 'Worker, bronze, ornamental' },
  { Id: 22194, Code: '8125', Name: 'Worker, iron, art' },
  { Id: 22195, Code: '8125', Name: 'Worker, lathe' },
  { Id: 22196, Code: '8125', Name: 'Worker, metal, architectural' },
  { Id: 22197, Code: '8125', Name: 'Worker, metal, art' },
  { Id: 22198, Code: '8125', Name: 'Worker, metal, ornamental' },
  { Id: 22199, Code: '8125', Name: 'Worker, press, metal trades' },
  { Id: 22200, Code: '8125', Name: 'Worker, process, metal trades' },
  { Id: 22201, Code: '8125', Name: 'Worker, spoon and fork' },
  { Id: 22202, Code: '8125', Name: 'Worker, tin, fancy' },
  { Id: 22203, Code: '8126', Name: 'Assistant, distribution, water company' },
  { Id: 22204, Code: '8126', Name: 'Assistant, mains, water company' },
  { Id: 22205, Code: '8126', Name: 'Attendant, bed, bacteria' },
  { Id: 22206, Code: '8126', Name: 'Attendant, bed, filter, water works' },
  { Id: 22207, Code: '8126', Name: 'Attendant, filter, water works' },
  { Id: 22208, Code: '8126', Name: 'Attendant, house, filter, water works' },
  { Id: 22209, Code: '8126', Name: 'Attendant, house, press' },
  { Id: 22210, Code: '8126', Name: 'Attendant, plant, purifying, water' },
  { Id: 22211, Code: '8126', Name: 'Attendant, plant, sewage' },
  { Id: 22212, Code: '8126', Name: 'Attendant, plant, softening, water' },
  { Id: 22213, Code: '8126', Name: 'Attendant, plant, treatment, water' },
  { Id: 22214, Code: '8126', Name: 'Attendant, pump' },
  { Id: 22215, Code: '8126', Name: 'Attendant, pumping' },
  { Id: 22216, Code: '8126', Name: 'Attendant, room, pump' },
  { Id: 22217, Code: '8126', Name: 'Attendant, sewage' },
  { Id: 22218, Code: '8126', Name: 'Attendant, sewage farm' },
  { Id: 22219, Code: '8126', Name: 'Attendant, sewerage' },
  { Id: 22220, Code: '8126', Name: 'Attendant, softener, water' },
  { Id: 22221, Code: '8126', Name: 'Attendant, sprinkler' },
  { Id: 22222, Code: '8126', Name: 'Attendant, station, pumping' },
  { Id: 22223, Code: '8126', Name: 'Attendant, syphon' },
  { Id: 22224, Code: '8126', Name: 'Attendant, treatment, water' },
  { Id: 22225, Code: '8126', Name: 'Attendant, water' },
  { Id: 22226, Code: '8126', Name: 'Attendant, water works' },
  { Id: 22227, Code: '8126', Name: 'Attendant, works, outfall' },
  { Id: 22228, Code: '8126', Name: 'Chlorinator, water works' },
  { Id: 22229, Code: '8126', Name: 'Collector, sample, water' },
  { Id: 22230, Code: '8126', Name: 'Controller, shift, water treatment' },
  { Id: 22231, Code: '8126', Name: 'Controller, treatment, water' },
  { Id: 22232, Code: '8126', Name: 'Controller, water treatment' },
  { Id: 22233, Code: '8126', Name: 'Engineer, treatment, water' },
  { Id: 22234, Code: '8126', Name: 'Engineer, water' },
  { Id: 22235, Code: '8126', Name: 'Filterer, water works' },
  { Id: 22236, Code: '8126', Name: 'Flusher and cleanser, sewer' },
  { Id: 22237, Code: '8126', Name: 'Foreman, local government: sewage works' },
  { Id: 22238, Code: '8126', Name: 'Foreman, sewage works' },
  { Id: 22239, Code: '8126', Name: 'Foreman, sewer' },
  { Id: 22240, Code: '8126', Name: 'Foreman, station, pumping' },
  { Id: 22241, Code: '8126', Name: 'Foreman, water works' },
  { Id: 22242, Code: '8126', Name: 'Ganger, filtration, water works' },
  { Id: 22243, Code: '8126', Name: 'Inspector, district, water company' },
  { Id: 22244, Code: '8126', Name: 'Inspector, sewer' },
  { Id: 22245, Code: '8126', Name: 'Machinist, sewage disposal' },
  { Id: 22246, Code: '8126', Name: 'Man, filter, sewage farm' },
  { Id: 22247, Code: '8126', Name: 'Man, sewerage, local government' },
  { Id: 22248, Code: '8126', Name: 'Operative, sewer' },
  { Id: 22249, Code: '8126', Name: 'Operator, distribution, water company' },
  { Id: 22250, Code: '8126', Name: 'Operator, plant, sewage works' },
  { Id: 22251, Code: '8126', Name: 'Operator, plant, softener, water' },
  { Id: 22252, Code: '8126', Name: 'Operator, plant, treatment, water' },
  { Id: 22253, Code: '8126', Name: 'Operator, plant, water works' },
  { Id: 22254, Code: '8126', Name: 'Operator, pump' },
  { Id: 22255, Code: '8126', Name: 'Operator, room, control, water company' },
  { Id: 22256, Code: '8126', Name: 'Operator, sewage' },
  { Id: 22257, Code: '8126', Name: 'Operator, sewer' },
  { Id: 22258, Code: '8126', Name: 'Operator, softener, water' },
  { Id: 22259, Code: '8126', Name: 'Operator, treatment, sewage' },
  { Id: 22260, Code: '8126', Name: 'Operator, treatment, water' },
  { Id: 22261, Code: '8126', Name: 'Operator, water company' },
  { Id: 22262, Code: '8126', Name: 'Operator, works, sewage' },
  { Id: 22263, Code: '8126', Name: 'Screener, sewage disposal' },
  { Id: 22264, Code: '8126', Name: 'Sewerman' },
  { Id: 22265, Code: '8126', Name: 'Softener, water' },
  {
    Id: 22266,
    Code: '8126',
    Name: 'Superintendent, distribution, water works'
  },
  { Id: 22267, Code: '8126', Name: 'Superintendent, water company' },
  { Id: 22268, Code: '8126', Name: 'Superintendent, works, water' },
  { Id: 22269, Code: '8126', Name: 'Superintendent, works, water company' },
  { Id: 22270, Code: '8126', Name: 'Technician, leakage' },
  { Id: 22271, Code: '8126', Name: 'Technician, service, environmental' },
  {
    Id: 22272,
    Code: '8126',
    Name: 'Technician, services, environmental, water treatment'
  },
  { Id: 22273, Code: '8126', Name: 'Turncock' },
  { Id: 22274, Code: '8126', Name: 'Valveman, water works' },
  { Id: 22275, Code: '8126', Name: 'Waterman, local government' },
  { Id: 22276, Code: '8126', Name: 'Waterman, sewage disposal' },
  { Id: 22277, Code: '8126', Name: 'Waterman, water works' },
  { Id: 22278, Code: '8126', Name: 'Worker, board, water' },
  { Id: 22279, Code: '8126', Name: 'Worker, farm, sewage' },
  { Id: 22280, Code: '8126', Name: 'Worker, sewage' },
  { Id: 22281, Code: '8126', Name: 'Worker, treatment, water' },
  { Id: 22282, Code: '8127', Name: 'Assistant, general, printing' },
  { Id: 22283, Code: '8127', Name: 'Assistant, lab, photo' },
  { Id: 22284, Code: '8127', Name: 'Assistant, letterpress' },
  { Id: 22285, Code: '8127', Name: "Assistant, minder's, machine, printing" },
  { Id: 22286, Code: '8127', Name: 'Assistant, press, printing' },
  { Id: 22287, Code: '8127', Name: 'Assistant, print' },
  { Id: 22288, Code: '8127', Name: "Assistant, printer's" },
  { Id: 22289, Code: '8127', Name: 'Assistant, printing' },
  {
    Id: 22290,
    Code: '8127',
    Name: 'Assistant, room, machine, newspaper printing'
  },
  { Id: 22291, Code: '8127', Name: 'Assistant, room, print' },
  { Id: 22292, Code: '8127', Name: 'Assistant, rotary, printing' },
  { Id: 22293, Code: '8127', Name: 'Attendant, room, print' },
  { Id: 22294, Code: '8127', Name: 'Beveller, printing' },
  { Id: 22295, Code: '8127', Name: 'Boy, machine, printing' },
  { Id: 22296, Code: '8127', Name: 'Brakesman, printing' },
  { Id: 22297, Code: '8127', Name: 'Clammer, roller engraving' },
  { Id: 22298, Code: '8127', Name: 'Clamper, roller engraving' },
  { Id: 22299, Code: '8127', Name: 'Cleaner, glass, DRY PLATES' },
  { Id: 22300, Code: '8127', Name: 'Cleaner, plate, printing' },
  { Id: 22301, Code: '8127', Name: 'Crater, manufacturing: printing' },
  { Id: 22302, Code: '8127', Name: 'Cutter, die, ENGRAVING' },
  { Id: 22303, Code: '8127', Name: 'Cutter, letter, die sinking' },
  { Id: 22304, Code: '8127', Name: 'Cutter, punch' },
  { Id: 22305, Code: '8127', Name: 'Cutter, roller' },
  { Id: 22306, Code: '8127', Name: 'Cutter, seal' },
  { Id: 22307, Code: '8127', Name: 'Decorator, plate, tin' },
  { Id: 22308, Code: '8127', Name: "Driver, printer's" },
  { Id: 22309, Code: '8127', Name: 'Embosser, hat mfr' },
  { Id: 22310, Code: '8127', Name: 'Embosser, paper goods mfr' },
  { Id: 22311, Code: '8127', Name: 'Feeder, letterpress' },
  { Id: 22312, Code: '8127', Name: 'Feeder, platen' },
  { Id: 22313, Code: '8127', Name: "Feeder, printer's" },
  { Id: 22314, Code: '8127', Name: 'Feeder, printing' },
  { Id: 22315, Code: '8127', Name: 'Hand, brake, printing' },
  { Id: 22316, Code: '8127', Name: 'Hand, controller, printing' },
  { Id: 22317, Code: '8127', Name: 'Hand, floor, printing' },
  { Id: 22318, Code: '8127', Name: 'Hand, fly' },
  { Id: 22319, Code: '8127', Name: 'Hand, news' },
  { Id: 22320, Code: '8127', Name: 'Hand, reel' },
  { Id: 22321, Code: '8127', Name: 'Hand, tension' },
  { Id: 22322, Code: '8127', Name: "Help, printer's" },
  { Id: 22323, Code: '8127', Name: "Helper, printer's" },
  { Id: 22324, Code: '8127', Name: "Jobber, printer's" },
  { Id: 22325, Code: '8127', Name: 'Layer-on, machine' },
  { Id: 22326, Code: '8127', Name: 'Leveller, lithography' },
  { Id: 22327, Code: '8127', Name: 'Lithographer, printing' },
  { Id: 22328, Code: '8127', Name: 'Loader, lithography' },
  { Id: 22329, Code: '8127', Name: 'Machinist, collating' },
  { Id: 22330, Code: '8127', Name: 'Machinist, cylinder, printing' },
  { Id: 22331, Code: '8127', Name: 'Machinist, decollating' },
  { Id: 22332, Code: '8127', Name: 'Machinist, duplicating' },
  { Id: 22333, Code: '8127', Name: 'Machinist, dyeline' },
  { Id: 22334, Code: '8127', Name: 'Machinist, folding, printing' },
  { Id: 22335, Code: '8127', Name: 'Machinist, gravure, printing' },
  { Id: 22336, Code: '8127', Name: 'Machinist, lithographic' },
  { Id: 22337, Code: '8127', Name: 'Machinist, photogravure' },
  { Id: 22338, Code: '8127', Name: 'Machinist, platen' },
  { Id: 22339, Code: '8127', Name: 'Machinist, press, letter' },
  { Id: 22340, Code: '8127', Name: 'Machinist, press, printing' },
  { Id: 22341, Code: '8127', Name: "Machinist, printer's" },
  { Id: 22342, Code: '8127', Name: 'Machinist, printing' },
  { Id: 22343, Code: '8127', Name: 'Machinist, rotary' },
  { Id: 22344, Code: '8127', Name: 'Machinist, tinplate' },
  {
    Id: 22345,
    Code: '8127',
    Name: 'Machinist, wallpaper mfr: wallpaper printing'
  },
  { Id: 22346, Code: '8127', Name: 'Maker, map' },
  { Id: 22347, Code: '8127', Name: 'Maker, ticket, reel' },
  { Id: 22348, Code: '8127', Name: 'Man, table, printing' },
  { Id: 22349, Code: '8127', Name: 'Manager, machine, collotype' },
  { Id: 22350, Code: '8127', Name: 'Manager, machine, letterpress' },
  { Id: 22351, Code: '8127', Name: 'Manager, machine, lithographic' },
  { Id: 22352, Code: '8127', Name: 'Manager, machine, photogravure' },
  { Id: 22353, Code: '8127', Name: 'Manager, machine, printing' },
  { Id: 22354, Code: '8127', Name: "Mate, printer's" },
  { Id: 22355, Code: '8127', Name: 'Numberer, parts' },
  { Id: 22356, Code: '8127', Name: 'Numberer, printing' },
  { Id: 22357, Code: '8127', Name: 'Operative, finishing, printing' },
  { Id: 22358, Code: '8127', Name: "Operative, printer's" },
  { Id: 22359, Code: '8127', Name: 'Operator, dyeline' },
  { Id: 22360, Code: '8127', Name: 'Operator, folding, printing' },
  { Id: 22361, Code: '8127', Name: "Operator, gravure, PRINTER'S" },
  { Id: 22362, Code: '8127', Name: 'Operator, lab, photo' },
  { Id: 22363, Code: '8127', Name: 'Operator, line, dye' },
  { Id: 22364, Code: '8127', Name: 'Operator, print' },
  { Id: 22365, Code: '8127', Name: 'Operator, print, photo' },
  { Id: 22366, Code: '8127', Name: "Operator, printer's" },
  { Id: 22367, Code: '8127', Name: 'Operator, rotary, printing' },
  { Id: 22368, Code: '8127', Name: 'Plater, tin, tinplate printing' },
  { Id: 22369, Code: '8127', Name: 'Presser, printing' },
  { Id: 22370, Code: '8127', Name: 'Presser, rotary, printing' },
  { Id: 22371, Code: '8127', Name: 'Printer, blue' },
  { Id: 22372, Code: '8127', Name: 'Printer, dyeline' },
  { Id: 22373, Code: '8127', Name: 'Printer, light, arc' },
  { Id: 22374, Code: '8127', Name: 'Printer, office, drawing' },
  { Id: 22375, Code: '8127', Name: 'Printer, photostat' },
  { Id: 22376, Code: '8127', Name: 'Printer, phototype' },
  { Id: 22377, Code: '8127', Name: 'Printer, plan' },
  { Id: 22378, Code: '8127', Name: 'Printer, rota' },
  { Id: 22379, Code: '8127', Name: 'Proofer, lithography' },
  { Id: 22380, Code: '8127', Name: 'Puncher, card' },
  { Id: 22381, Code: '8127', Name: 'Puncher, label' },
  { Id: 22382, Code: '8127', Name: 'Puncher, paper' },
  { Id: 22383, Code: '8127', Name: 'Puncher, paper goods mfr' },
  { Id: 22384, Code: '8127', Name: 'Puncher, pattern, paper pattern mfr' },
  { Id: 22385, Code: '8127', Name: 'Racker, lithography' },
  { Id: 22386, Code: '8127', Name: 'Reeler, printing' },
  { Id: 22387, Code: '8127', Name: "Ruler, printer's" },
  { Id: 22388, Code: '8127', Name: 'Ruler, printing' },
  { Id: 22389, Code: '8127', Name: 'Ruler, textile printing' },
  { Id: 22390, Code: '8127', Name: 'Setter, machine, printing' },
  { Id: 22391, Code: '8127', Name: 'Sinker, die, printing' },
  { Id: 22392, Code: '8127', Name: 'Stamper, box' },
  { Id: 22393, Code: '8127', Name: 'Stamper, brush' },
  { Id: 22394, Code: '8127', Name: 'Stamper, bulb, electric' },
  { Id: 22395, Code: '8127', Name: 'Stamper, ceramics mfr' },
  { Id: 22396, Code: '8127', Name: 'Stamper, cloth' },
  { Id: 22397, Code: '8127', Name: 'Stamper, collar' },
  { Id: 22398, Code: '8127', Name: 'Stamper, die, printing' },
  { Id: 22399, Code: '8127', Name: 'Stamper, footwear mfr' },
  { Id: 22400, Code: '8127', Name: 'Stamper, gold, ceramics mfr' },
  { Id: 22401, Code: '8127', Name: 'Stamper, gold, footwear mfr' },
  { Id: 22402, Code: '8127', Name: 'Stamper, gold, hat mfr' },
  { Id: 22403, Code: '8127', Name: 'Stamper, paper goods mfr' },
  { Id: 22404, Code: '8127', Name: 'Stamper, pattern, ceramics mfr' },
  { Id: 22405, Code: '8127', Name: 'Stamper, pattern, paper pattern mfr' },
  { Id: 22406, Code: '8127', Name: "Stamper, printer's" },
  { Id: 22407, Code: '8127', Name: 'Stamper, relief' },
  { Id: 22408, Code: '8127', Name: 'Stamper, size' },
  { Id: 22409, Code: '8127', Name: 'Stamper, soap' },
  { Id: 22410, Code: '8127', Name: 'Stamper, sock' },
  { Id: 22411, Code: '8127', Name: 'Stamper, tannery' },
  { Id: 22412, Code: '8127', Name: 'Stamper, textile mfr' },
  { Id: 22413, Code: '8127', Name: 'Supervisor, room, print' },
  { Id: 22414, Code: '8127', Name: 'Taker, impression, printing' },
  { Id: 22415, Code: '8127', Name: 'Taker-off, ceramic transfer mfr' },
  { Id: 22416, Code: '8127', Name: 'Taker-off, machine, printing' },
  { Id: 22417, Code: '8127', Name: 'Technician, bindery, printing' },
  { Id: 22418, Code: '8127', Name: 'Technician, laboratory, photo' },
  { Id: 22419, Code: '8127', Name: 'Teerer, textile printing' },
  {
    Id: 22420,
    Code: '8127',
    Name: 'Tenter, back, textile mfr: textile printing'
  },
  { Id: 22421, Code: '8127', Name: 'Tenter, back, wallpaper printing' },
  { Id: 22422, Code: '8127', Name: 'Tierer' },
  { Id: 22423, Code: '8127', Name: 'Transferer, tinplate mfr' },
  { Id: 22424, Code: '8127', Name: 'Valver' },
  { Id: 22425, Code: '8127', Name: 'Worker, print' },
  { Id: 22426, Code: '8127', Name: 'Worker, process, printing' },
  {
    Id: 22427,
    Code: '8127',
    Name: 'Worker, process, textile mfr: textile printing'
  },
  { Id: 22428, Code: '8127', Name: 'Worker, table, printing' },
  { Id: 22429, Code: '8129', Name: 'Adjuster, lift' },
  { Id: 22430, Code: '8129', Name: 'Adjuster, weight' },
  { Id: 22431, Code: '8129', Name: 'Armourer, cable' },
  { Id: 22432, Code: '8129', Name: 'Armourer, hose' },
  { Id: 22433, Code: '8129', Name: 'Assistant, armouring' },
  { Id: 22434, Code: '8129', Name: "Assistant, glazer's" },
  { Id: 22435, Code: '8129', Name: 'Assistant, maintenance, MACHINERY, PLANT' },
  { Id: 22436, Code: '8129', Name: 'Assistant, shop, spreading, CABLES' },
  { Id: 22437, Code: '8129', Name: "Assistant, valveman's" },
  { Id: 22438, Code: '8129', Name: 'Attendant, battery' },
  { Id: 22439, Code: '8129', Name: 'Attendant, bay, wash' },
  { Id: 22440, Code: '8129', Name: 'Attendant, lubrication' },
  { Id: 22441, Code: '8129', Name: 'Attendant, machinery, lift' },
  { Id: 22442, Code: '8129', Name: 'Attendant, plant, fume, lead mfr' },
  { Id: 22443, Code: '8129', Name: 'Backer, saw' },
  { Id: 22444, Code: '8129', Name: 'Baler, scrap' },
  { Id: 22445, Code: '8129', Name: 'Bander, textile mfr' },
  { Id: 22446, Code: '8129', Name: 'Beamer, wire weaving' },
  { Id: 22447, Code: '8129', Name: 'Bender, hook, fish' },
  { Id: 22448, Code: '8129', Name: 'Bender, knife' },
  { Id: 22449, Code: '8129', Name: 'Bender, pipe' },
  { Id: 22450, Code: '8129', Name: 'Bender, tube' },
  { Id: 22451, Code: '8129', Name: 'Bender, wire' },
  { Id: 22452, Code: '8129', Name: 'Borer, fancy comb, slide mfr' },
  { Id: 22453, Code: '8129', Name: 'Braider, cable mfr' },
  { Id: 22454, Code: '8129', Name: 'Braider, flexible tubing mfr' },
  { Id: 22455, Code: '8129', Name: 'Braider, telephone mfr' },
  { Id: 22456, Code: '8129', Name: 'Braider, wire' },
  { Id: 22457, Code: '8129', Name: 'Breaker, billet' },
  { Id: 22458, Code: '8129', Name: 'Breaker, boiler' },
  { Id: 22459, Code: '8129', Name: 'Breaker, car' },
  { Id: 22460, Code: '8129', Name: 'Breaker, engine' },
  { Id: 22461, Code: '8129', Name: 'Breaker, rail' },
  { Id: 22462, Code: '8129', Name: 'Breaker, scrap' },
  { Id: 22463, Code: '8129', Name: 'Breaker, scrap merchants, breakers' },
  { Id: 22464, Code: '8129', Name: 'Breaker, ship' },
  { Id: 22465, Code: '8129', Name: 'Breaker-off, type foundry' },
  { Id: 22466, Code: '8129', Name: 'Brusher, needle mfr' },
  { Id: 22467, Code: '8129', Name: 'Brusher, tube, railways' },
  { Id: 22468, Code: '8129', Name: 'Burner, scrap, scrap merchants, breakers' },
  { Id: 22469, Code: '8129', Name: 'Buttoner-up, BOLTS AND NUTS' },
  { Id: 22470, Code: '8129', Name: 'Capper and sealer, end' },
  { Id: 22471, Code: '8129', Name: 'Carrier, rivet' },
  { Id: 22472, Code: '8129', Name: 'Caser, die' },
  { Id: 22473, Code: '8129', Name: 'Catcher, rivet' },
  { Id: 22474, Code: '8129', Name: 'Chargeman, battery' },
  { Id: 22475, Code: '8129', Name: 'Charger, accumulator' },
  { Id: 22476, Code: '8129', Name: 'Charger, battery' },
  { Id: 22477, Code: '8129', Name: 'Chaser, METAL' },
  { Id: 22478, Code: '8129', Name: 'Chipper, metal trades' },
  { Id: 22479, Code: '8129', Name: 'Chipper, pneumatic' },
  { Id: 22480, Code: '8129', Name: 'Chipper, steel, steelworks' },
  { Id: 22481, Code: '8129', Name: 'Clipper, top, card' },
  { Id: 22482, Code: '8129', Name: 'Closer, wire rope, cable mfr' },
  { Id: 22483, Code: '8129', Name: 'Clothier, card' },
  { Id: 22484, Code: '8129', Name: 'Coater, cathode' },
  { Id: 22485, Code: '8129', Name: 'Coater, filament' },
  { Id: 22486, Code: '8129', Name: 'Coiler, cable mfr' },
  { Id: 22487, Code: '8129', Name: 'Coiler, spring mfr' },
  { Id: 22488, Code: '8129', Name: 'Coiler, wire rope, cable mfr' },
  { Id: 22489, Code: '8129', Name: 'Coverer, bar, metal' },
  { Id: 22490, Code: '8129', Name: 'Coverer, insulated wire, cable mfr' },
  { Id: 22491, Code: '8129', Name: 'Crimper, cable mfr' },
  { Id: 22492, Code: '8129', Name: 'Cropper, metal trades' },
  { Id: 22493, Code: '8129', Name: 'Cutler' },
  { Id: 22494, Code: '8129', Name: 'Cutter, block, linoleum mfr' },
  { Id: 22495, Code: '8129', Name: 'Cutter, block, wallpaper mfr' },
  { Id: 22496, Code: '8129', Name: 'Cutter, button, pearl' },
  { Id: 22497, Code: '8129', Name: 'Cutter, design, printing' },
  { Id: 22498, Code: '8129', Name: 'Cutter, file' },
  { Id: 22499, Code: '8129', Name: 'Cutter, insulation' },
  { Id: 22500, Code: '8129', Name: 'Cutter, shell, pearl' },
  { Id: 22501, Code: '8129', Name: 'Cutter, tip, cemented carbide goods mfr' },
  { Id: 22502, Code: '8129', Name: 'Dipper, match mfr' },
  { Id: 22503, Code: '8129', Name: 'Dismantler, car' },
  { Id: 22504, Code: '8129', Name: 'Dismantler, machinery' },
  { Id: 22505, Code: '8129', Name: 'Dismantler, scrap merchants, breakers' },
  { Id: 22506, Code: '8129', Name: 'Dismantler, ship' },
  { Id: 22507, Code: '8129', Name: 'Doctor, saw' },
  { Id: 22508, Code: '8129', Name: 'Dresser, bow' },
  { Id: 22509, Code: '8129', Name: 'Dresser, card' },
  { Id: 22510, Code: '8129', Name: 'Dresser, heald' },
  { Id: 22511, Code: '8129', Name: 'Dresser, weld' },
  { Id: 22512, Code: '8129', Name: 'Driller, mica' },
  { Id: 22513, Code: '8129', Name: 'Driller, micanite' },
  {
    Id: 22514,
    Code: '8129',
    Name: 'Driver, calender, insulated wire, cable mfr'
  },
  { Id: 22515, Code: '8129', Name: 'Driver, closer, wire rope, cable mfr' },
  { Id: 22516, Code: '8129', Name: 'Driver, machine, armouring' },
  { Id: 22517, Code: '8129', Name: 'Driver, machine, cable' },
  { Id: 22518, Code: '8129', Name: 'Driver, machine, cabling' },
  { Id: 22519, Code: '8129', Name: 'Driver, machine, insulating' },
  { Id: 22520, Code: '8129', Name: 'Driver, machine, lapping' },
  { Id: 22521, Code: '8129', Name: 'Driver, machine, layer-up' },
  { Id: 22522, Code: '8129', Name: 'Driver, machine, tubing' },
  { Id: 22523, Code: '8129', Name: 'Driver, saw, metal trades' },
  { Id: 22524, Code: '8129', Name: 'Driver, shear, metal trades' },
  { Id: 22525, Code: '8129', Name: 'Driver, shears, metal trades' },
  { Id: 22526, Code: '8129', Name: 'Engineer, aerial, INSTALLATION' },
  { Id: 22527, Code: '8129', Name: 'Engineer, battery' },
  { Id: 22528, Code: '8129', Name: 'Engineer, lubrication' },
  { Id: 22529, Code: '8129', Name: 'Engineer, semi-skilled' },
  { Id: 22530, Code: '8129', Name: 'Erector, aerial, television' },
  { Id: 22531, Code: '8129', Name: 'Erector, conveyor, coal mine' },
  { Id: 22532, Code: '8129', Name: 'Erector, duct, WORK' },
  { Id: 22533, Code: '8129', Name: 'Etcher, cutlery' },
  { Id: 22534, Code: '8129', Name: 'Etcher, cutlery mfr' },
  { Id: 22535, Code: '8129', Name: 'Etcher, tool mfr' },
  { Id: 22536, Code: '8129', Name: 'Examiner, shaft, coal mine' },
  { Id: 22537, Code: '8129', Name: 'Expander, tube' },
  { Id: 22538, Code: '8129', Name: 'Expander, tube mfr' },
  { Id: 22539, Code: '8129', Name: 'Extender, belt, coal mine' },
  { Id: 22540, Code: '8129', Name: 'Extender, conveyor, coal mine' },
  {
    Id: 22541,
    Code: '8129',
    Name: 'Extruder, machine, arc welding electrode mfr'
  },
  { Id: 22542, Code: '8129', Name: 'Fasher' },
  { Id: 22543, Code: '8129', Name: 'Feeder, bar' },
  { Id: 22544, Code: '8129', Name: 'Feeder, conveyor, metal trades' },
  { Id: 22545, Code: '8129', Name: 'Fettler, card' },
  { Id: 22546, Code: '8129', Name: 'Fettler, machine' },
  { Id: 22547, Code: '8129', Name: 'Fettler, textile mfr' },
  { Id: 22548, Code: '8129', Name: 'Fettler, woollen' },
  { Id: 22549, Code: '8129', Name: 'Filer, tool' },
  { Id: 22550, Code: '8129', Name: 'Filler, battery mfr' },
  { Id: 22551, Code: '8129', Name: 'Filler, battery, accumulator mfr' },
  { Id: 22552, Code: '8129', Name: 'Filler-up, card clothing mfr' },
  { Id: 22553, Code: '8129', Name: 'Finer, jewellery, plate mfr' },
  { Id: 22554, Code: '8129', Name: 'Finer, super, metal buckle mfr' },
  { Id: 22555, Code: '8129', Name: 'Finisher, metal trades' },
  { Id: 22556, Code: '8129', Name: 'Fitter, aerial, television' },
  { Id: 22557, Code: '8129', Name: 'Fitter, balustrade' },
  { Id: 22558, Code: '8129', Name: 'Fitter, box, foundry' },
  { Id: 22559, Code: '8129', Name: 'Fitter, scythe' },
  { Id: 22560, Code: '8129', Name: 'Flattener, wire mfr' },
  { Id: 22561, Code: '8129', Name: 'Flitter, coal mine' },
  { Id: 22562, Code: '8129', Name: 'Foreman, cable mfr' },
  { Id: 22563, Code: '8129', Name: 'Foreman, grinding wheel mfr' },
  { Id: 22564, Code: '8129', Name: 'Forker' },
  { Id: 22565, Code: '8129', Name: 'Former, accumulator' },
  { Id: 22566, Code: '8129', Name: 'Former, battery' },
  { Id: 22567, Code: '8129', Name: 'Former, cell, BATTERY' },
  { Id: 22568, Code: '8129', Name: 'Former, filament' },
  { Id: 22569, Code: '8129', Name: 'Former, plate, tungsten' },
  { Id: 22570, Code: '8129', Name: 'Former, wire' },
  { Id: 22571, Code: '8129', Name: 'Glazer, assistant, metal trades' },
  { Id: 22572, Code: '8129', Name: 'Grainer, plate' },
  { Id: 22573, Code: '8129', Name: 'Grainer, printing' },
  { Id: 22574, Code: '8129', Name: 'Greaser' },
  { Id: 22575, Code: '8129', Name: 'Greaser, kiln' },
  { Id: 22576, Code: '8129', Name: 'Greaser, roll, cold' },
  { Id: 22577, Code: '8129', Name: 'Greaser, sheave' },
  { Id: 22578, Code: '8129', Name: 'Grinder and polisher, metal trades' },
  { Id: 22579, Code: '8129', Name: 'Grinder, abrasive paper, cloth mfr' },
  { Id: 22580, Code: '8129', Name: 'Grinder, anvil' },
  { Id: 22581, Code: '8129', Name: 'Grinder, assistant, metal trades' },
  { Id: 22582, Code: '8129', Name: 'Grinder, billet, steelworks' },
  { Id: 22583, Code: '8129', Name: 'Grinder, bit, coal mine' },
  { Id: 22584, Code: '8129', Name: 'Grinder, blade' },
  { Id: 22585, Code: '8129', Name: 'Grinder, bow' },
  { Id: 22586, Code: '8129', Name: 'Grinder, brake linings mfr' },
  { Id: 22587, Code: '8129', Name: 'Grinder, burr' },
  { Id: 22588, Code: '8129', Name: 'Grinder, carbon goods mfr' },
  { Id: 22589, Code: '8129', Name: 'Grinder, card' },
  { Id: 22590, Code: '8129', Name: 'Grinder, cardroom' },
  { Id: 22591, Code: '8129', Name: 'Grinder, clothing, card' },
  { Id: 22592, Code: '8129', Name: 'Grinder, comb' },
  { Id: 22593, Code: '8129', Name: 'Grinder, dry, METAL' },
  { Id: 22594, Code: '8129', Name: 'Grinder, jobbing' },
  { Id: 22595, Code: '8129', Name: 'Grinder, needle' },
  { Id: 22596, Code: '8129', Name: 'Grinder, scissors' },
  { Id: 22597, Code: '8129', Name: 'Grinder, steel' },
  { Id: 22598, Code: '8129', Name: 'Grinder, stone, wet' },
  { Id: 22599, Code: '8129', Name: 'Grinder, straight' },
  { Id: 22600, Code: '8129', Name: 'Grinder, surface, carbon goods mfr' },
  { Id: 22601, Code: '8129', Name: 'Grinder, swing' },
  { Id: 22602, Code: '8129', Name: 'Grinder, wet' },
  { Id: 22603, Code: '8129', Name: 'Grinder, wheel, emery' },
  { Id: 22604, Code: '8129', Name: 'Gunner, steelworks' },
  { Id: 22605, Code: '8129', Name: 'Hafter' },
  { Id: 22606, Code: '8129', Name: 'Hammerer, saw' },
  { Id: 22607, Code: '8129', Name: 'Hand, bench, metal trades' },
  { Id: 22608, Code: '8129', Name: 'Hand, bench, saw' },
  { Id: 22609, Code: '8129', Name: 'Hand, charging, battery' },
  { Id: 22610, Code: '8129', Name: 'Hand, guillotine, metal trades' },
  { Id: 22611, Code: '8129', Name: 'Hand, knife, metal trades' },
  { Id: 22612, Code: '8129', Name: 'Hand, leading, metal trades' },
  { Id: 22613, Code: '8129', Name: 'Hand, maintenance, coal mine' },
  { Id: 22614, Code: '8129', Name: 'Hand, maintenance, electrical' },
  { Id: 22615, Code: '8129', Name: 'Hand, maintenance, machine' },
  { Id: 22616, Code: '8129', Name: 'Hand, maintenance, MACHINERY' },
  { Id: 22617, Code: '8129', Name: 'Hand, maintenance, mine: not coal' },
  { Id: 22618, Code: '8129', Name: 'Hand, wire, cable mfr' },
  { Id: 22619, Code: '8129', Name: 'Handyman, nos, gas works' },
  { Id: 22620, Code: '8129', Name: 'Handyman, nos, water works' },
  { Id: 22621, Code: '8129', Name: 'Header, bolt' },
  {
    Id: 22622,
    Code: '8129',
    Name: 'Header, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22623, Code: '8129', Name: 'Header, cold, RIVETS' },
  {
    Id: 22624,
    Code: '8129',
    Name: 'Header-up, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22625, Code: '8129', Name: 'Heater, rivet' },
  { Id: 22626, Code: '8129', Name: "Helper, cutter's" },
  { Id: 22627, Code: '8129', Name: 'Helper, frame' },
  { Id: 22628, Code: '8129', Name: "Helper, repairer's, coal mine" },
  { Id: 22629, Code: '8129', Name: 'Holer, button, button mfr' },
  { Id: 22630, Code: '8129', Name: 'Houseman, press, coal mine' },
  { Id: 22631, Code: '8129', Name: 'Inspector, steel, coal mine' },
  { Id: 22632, Code: '8129', Name: 'Installer, aerial' },
  { Id: 22633, Code: '8129', Name: 'Insulator, cable' },
  { Id: 22634, Code: '8129', Name: 'Insulator, electrical' },
  {
    Id: 22635,
    Code: '8129',
    Name: 'Insulator, thermal, electrical appliances mfr'
  },
  { Id: 22636, Code: '8129', Name: 'Joggler' },
  { Id: 22637, Code: '8129', Name: 'Jointer, cutlery mfr' },
  { Id: 22638, Code: '8129', Name: 'Kerner, type foundry' },
  { Id: 22639, Code: '8129', Name: 'Keysmith' },
  { Id: 22640, Code: '8129', Name: 'Lapper, cable mfr' },
  { Id: 22641, Code: '8129', Name: 'Lapper, paper' },
  { Id: 22642, Code: '8129', Name: 'Layer, wire rope, cable mfr' },
  { Id: 22643, Code: '8129', Name: 'Layer-up, cable mfr' },
  { Id: 22644, Code: '8129', Name: 'Lubricator' },
  { Id: 22645, Code: '8129', Name: 'Machinist, armouring' },
  { Id: 22646, Code: '8129', Name: 'Machinist, asbestos-cement goods mfr' },
  { Id: 22647, Code: '8129', Name: 'Machinist, basket mfr' },
  { Id: 22648, Code: '8129', Name: 'Machinist, battery' },
  { Id: 22649, Code: '8129', Name: 'Machinist, battery, accumulator mfr' },
  { Id: 22650, Code: '8129', Name: 'Machinist, bending, sheet metal working' },
  { Id: 22651, Code: '8129', Name: 'Machinist, bolt' },
  { Id: 22652, Code: '8129', Name: 'Machinist, braiding, cable mfr' },
  { Id: 22653, Code: '8129', Name: 'Machinist, brake linings mfr' },
  { Id: 22654, Code: '8129', Name: 'Machinist, broom, brush mfr' },
  { Id: 22655, Code: '8129', Name: 'Machinist, bullet' },
  { Id: 22656, Code: '8129', Name: 'Machinist, button' },
  { Id: 22657, Code: '8129', Name: 'Machinist, button mfr' },
  { Id: 22658, Code: '8129', Name: 'Machinist, cable' },
  { Id: 22659, Code: '8129', Name: 'Machinist, cable mfr' },
  { Id: 22660, Code: '8129', Name: 'Machinist, cabling' },
  { Id: 22661, Code: '8129', Name: 'Machinist, carbon' },
  { Id: 22662, Code: '8129', Name: 'Machinist, carbon goods mfr' },
  { Id: 22663, Code: '8129', Name: 'Machinist, cast concrete products mfr' },
  { Id: 22664, Code: '8129', Name: 'Machinist, closing, wire rope, cable mfr' },
  { Id: 22665, Code: '8129', Name: 'Machinist, cold storage' },
  { Id: 22666, Code: '8129', Name: 'Machinist, cork' },
  { Id: 22667, Code: '8129', Name: 'Machinist, cork stopper mfr' },
  { Id: 22668, Code: '8129', Name: 'Machinist, cutting, core' },
  { Id: 22669, Code: '8129', Name: 'Machinist, cutting, CORK' },
  { Id: 22670, Code: '8129', Name: 'Machinist, cutting, METAL' },
  { Id: 22671, Code: '8129', Name: 'Machinist, cutting, metal trades' },
  { Id: 22672, Code: '8129', Name: 'Machinist, cutting, plate, shipbuilding' },
  { Id: 22673, Code: '8129', Name: 'Machinist, cutting, rotary, METAL' },
  { Id: 22674, Code: '8129', Name: "Machinist, engraver's" },
  { Id: 22675, Code: '8129', Name: 'Machinist, eyelet' },
  { Id: 22676, Code: '8129', Name: 'Machinist, fishing rod mfr' },
  { Id: 22677, Code: '8129', Name: 'Machinist, grading, GARMENT PATTERN' },
  { Id: 22678, Code: '8129', Name: 'Machinist, guillotine, metal trades' },
  {
    Id: 22679,
    Code: '8129',
    Name: 'Machinist, heading, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22680, Code: '8129', Name: 'Machinist, joggling' },
  { Id: 22681, Code: '8129', Name: 'Machinist, lamp, valve mfr' },
  { Id: 22682, Code: '8129', Name: 'Machinist, making, screw' },
  { Id: 22683, Code: '8129', Name: 'Machinist, metal fastener mfr' },
  { Id: 22684, Code: '8129', Name: 'Machinist, metal smallwares mfr' },
  {
    Id: 22685,
    Code: '8129',
    Name: 'Machinist, metal trades: card clothing mfr'
  },
  { Id: 22686, Code: '8129', Name: 'Machinist, metal trades: reed mfr' },
  {
    Id: 22687,
    Code: '8129',
    Name: 'Machinist, milling, cemented carbide goods mfr'
  },
  { Id: 22688, Code: '8129', Name: 'Machinist, nail' },
  { Id: 22689, Code: '8129', Name: 'Machinist, pin' },
  { Id: 22690, Code: '8129', Name: 'Machinist, planing, plate' },
  { Id: 22691, Code: '8129', Name: 'Machinist, rivet' },
  { Id: 22692, Code: '8129', Name: 'Machinist, rolling, sheet metal working' },
  { Id: 22693, Code: '8129', Name: 'Machinist, rope, WIRE' },
  { Id: 22694, Code: '8129', Name: 'Machinist, sanding, MICANITE' },
  { Id: 22695, Code: '8129', Name: 'Machinist, sawing, metal trades' },
  { Id: 22696, Code: '8129', Name: 'Machinist, shearing, metal trades' },
  { Id: 22697, Code: '8129', Name: 'Machinist, socket' },
  { Id: 22698, Code: '8129', Name: 'Machinist, spring' },
  { Id: 22699, Code: '8129', Name: 'Machinist, stranding' },
  { Id: 22700, Code: '8129', Name: 'Machinist, surgical goods mfr' },
  { Id: 22701, Code: '8129', Name: 'Machinist, taping, cable mfr' },
  { Id: 22702, Code: '8129', Name: 'Machinist, textile mfr: textile printing' },
  { Id: 22703, Code: '8129', Name: 'Machinist, toy mfr' },
  { Id: 22704, Code: '8129', Name: 'Machinist, trim, motor vehicle mfr' },
  { Id: 22705, Code: '8129', Name: 'Machinist, trimming, BRUSHES' },
  { Id: 22706, Code: '8129', Name: 'Machinist, wire goods mfr' },
  { Id: 22707, Code: '8129', Name: 'Machinist, wire rope mfr' },
  { Id: 22708, Code: '8129', Name: 'Maker, barb, BARBED WIRE' },
  { Id: 22709, Code: '8129', Name: 'Maker, basket, wire' },
  { Id: 22710, Code: '8129', Name: 'Maker, basket, wire goods mfr' },
  { Id: 22711, Code: '8129', Name: 'Maker, battery, ELECTRIC' },
  { Id: 22712, Code: '8129', Name: 'Maker, block, breeze' },
  { Id: 22713, Code: '8129', Name: 'Maker, block, building' },
  { Id: 22714, Code: '8129', Name: 'Maker, block, carbon' },
  { Id: 22715, Code: '8129', Name: 'Maker, bobbin, electric battery mfr' },
  { Id: 22716, Code: '8129', Name: 'Maker, bobbin, METAL' },
  { Id: 22717, Code: '8129', Name: 'Maker, bolt' },
  { Id: 22718, Code: '8129', Name: 'Maker, box, match' },
  { Id: 22719, Code: '8129', Name: 'Maker, box, match mfr' },
  { Id: 22720, Code: '8129', Name: 'Maker, brush, CARBON, ELECTRIC' },
  { Id: 22721, Code: '8129', Name: 'Maker, brush, twisted-in' },
  { Id: 22722, Code: '8129', Name: 'Maker, bullet' },
  { Id: 22723, Code: '8129', Name: 'Maker, button' },
  { Id: 22724, Code: '8129', Name: 'Maker, cable, electric cable mfr' },
  { Id: 22725, Code: '8129', Name: 'Maker, cable, spring mfr' },
  { Id: 22726, Code: '8129', Name: 'Maker, cable, wire' },
  { Id: 22727, Code: '8129', Name: 'Maker, cell, accumulator, battery mfr' },
  { Id: 22728, Code: '8129', Name: 'Maker, chain, METAL' },
  { Id: 22729, Code: '8129', Name: 'Maker, chain, metal trades' },
  { Id: 22730, Code: '8129', Name: 'Maker, core, cable' },
  { Id: 22731, Code: '8129', Name: 'Maker, crate, steel' },
  { Id: 22732, Code: '8129', Name: 'Maker, crayon' },
  { Id: 22733, Code: '8129', Name: 'Maker, doctor' },
  { Id: 22734, Code: '8129', Name: 'Maker, electrode, CARBON' },
  { Id: 22735, Code: '8129', Name: 'Maker, element' },
  { Id: 22736, Code: '8129', Name: 'Maker, feed, fountain pen mfr' },
  { Id: 22737, Code: '8129', Name: 'Maker, frame, handbag' },
  { Id: 22738, Code: '8129', Name: 'Maker, frame, hood' },
  { Id: 22739, Code: '8129', Name: 'Maker, frame, wire' },
  { Id: 22740, Code: '8129', Name: 'Maker, goods, abrasive' },
  { Id: 22741, Code: '8129', Name: 'Maker, guard, wire goods mfr' },
  { Id: 22742, Code: '8129', Name: 'Maker, heald' },
  { Id: 22743, Code: '8129', Name: 'Maker, leather, comb' },
  { Id: 22744, Code: '8129', Name: 'Maker, lighter, fire' },
  { Id: 22745, Code: '8129', Name: 'Maker, mantle, gas' },
  { Id: 22746, Code: '8129', Name: 'Maker, mantle, incandescent' },
  { Id: 22747, Code: '8129', Name: 'Maker, marker, footwear mfr' },
  { Id: 22748, Code: '8129', Name: 'Maker, match' },
  { Id: 22749, Code: '8129', Name: 'Maker, matrix, type foundry' },
  { Id: 22750, Code: '8129', Name: 'Maker, mattress, spring' },
  { Id: 22751, Code: '8129', Name: 'Maker, mattress, wire' },
  { Id: 22752, Code: '8129', Name: 'Maker, nail, cut' },
  { Id: 22753, Code: '8129', Name: 'Maker, needle' },
  { Id: 22754, Code: '8129', Name: 'Maker, nib, pen' },
  { Id: 22755, Code: '8129', Name: 'Maker, pen' },
  { Id: 22756, Code: '8129', Name: 'Maker, pencil' },
  { Id: 22757, Code: '8129', Name: 'Maker, pipe, cast concrete products mfr' },
  { Id: 22758, Code: '8129', Name: 'Maker, rib, umbrella' },
  { Id: 22759, Code: '8129', Name: 'Maker, rivet' },
  { Id: 22760, Code: '8129', Name: 'Maker, roll, dandy' },
  { Id: 22761, Code: '8129', Name: 'Maker, rope, METAL' },
  { Id: 22762, Code: '8129', Name: 'Maker, saw' },
  { Id: 22763, Code: '8129', Name: 'Maker, screw' },
  { Id: 22764, Code: '8129', Name: 'Maker, seat, spring' },
  { Id: 22765, Code: '8129', Name: 'Maker, sieve, wire goods mfr' },
  { Id: 22766, Code: '8129', Name: 'Maker, spindle' },
  { Id: 22767, Code: '8129', Name: 'Maker, spring' },
  { Id: 22768, Code: '8129', Name: 'Maker, tack' },
  { Id: 22769, Code: '8129', Name: 'Maker, taper' },
  { Id: 22770, Code: '8129', Name: 'Maker, tissue, carbon' },
  { Id: 22771, Code: '8129', Name: 'Maker, washer, MICANITE' },
  { Id: 22772, Code: '8129', Name: 'Maker, wick' },
  { Id: 22773, Code: '8129', Name: 'Maker-up, small chain mfr' },
  { Id: 22774, Code: '8129', Name: 'Man, battery, iron and steelworks' },
  { Id: 22775, Code: '8129', Name: 'Man, compo' },
  { Id: 22776, Code: '8129', Name: 'Man, maintenance, coal mine' },
  { Id: 22777, Code: '8129', Name: 'Man, safety, coal mine' },
  { Id: 22778, Code: '8129', Name: 'Man, saw, METAL' },
  { Id: 22779, Code: '8129', Name: 'Man, steel, coal mine' },
  { Id: 22780, Code: '8129', Name: 'Man, turnover, coal mine' },
  { Id: 22781, Code: '8129', Name: 'Manipulator, tube, METAL' },
  { Id: 22782, Code: '8129', Name: 'Manufacturer, button' },
  { Id: 22783, Code: '8129', Name: 'Manufacturer, button mfr' },
  {
    Id: 22784,
    Code: '8129',
    Name: 'Manufacturer, furniture mfr: metal furniture'
  },
  { Id: 22785, Code: '8129', Name: 'Manufacturer, furniture, metal' },
  { Id: 22786, Code: '8129', Name: 'Manufacturer, metal goods mfr' },
  { Id: 22787, Code: '8129', Name: 'Marker, clog iron mfr' },
  { Id: 22788, Code: '8129', Name: 'Marker, pen nib mfr' },
  { Id: 22789, Code: '8129', Name: 'Mechanic, surgical' },
  { Id: 22790, Code: '8129', Name: 'Mechanic, umbrella' },
  { Id: 22791, Code: '8129', Name: 'Mender, tub, coal mine' },
  { Id: 22792, Code: '8129', Name: 'Moulder, carbon' },
  { Id: 22793, Code: '8129', Name: 'Moulder, cork goods mfr' },
  { Id: 22794, Code: '8129', Name: 'Moulder, pipe, CAST CONCRETE' },
  { Id: 22795, Code: '8129', Name: 'Mover, conveyor, coal mine' },
  { Id: 22796, Code: '8129', Name: 'Nailer, card' },
  { Id: 22797, Code: '8129', Name: 'Oiler' },
  { Id: 22798, Code: '8129', Name: 'Oiler and bander, textile mfr' },
  { Id: 22799, Code: '8129', Name: 'Oiler and beltman' },
  { Id: 22800, Code: '8129', Name: 'Oiler and cleaner' },
  { Id: 22801, Code: '8129', Name: 'Oiler and greaser' },
  { Id: 22802, Code: '8129', Name: 'Oiler, frame, textile mfr' },
  { Id: 22803, Code: '8129', Name: 'Oiler, loom' },
  { Id: 22804, Code: '8129', Name: 'Oiler, machine, printing' },
  { Id: 22805, Code: '8129', Name: 'Oiler, machine, textile mfr' },
  { Id: 22806, Code: '8129', Name: 'Oilman, coal mine' },
  { Id: 22807, Code: '8129', Name: 'Operator, brake, steelworks' },
  { Id: 22808, Code: '8129', Name: 'Operator, cable, cable mfr' },
  { Id: 22809, Code: '8129', Name: 'Operator, combine' },
  { Id: 22810, Code: '8129', Name: 'Operator, compactor' },
  { Id: 22811, Code: '8129', Name: 'Operator, cooker, DRY BATTERIES' },
  { Id: 22812, Code: '8129', Name: 'Operator, cropper' },
  { Id: 22813, Code: '8129', Name: 'Operator, guillotine, coal mine' },
  { Id: 22814, Code: '8129', Name: 'Operator, guillotine, metal trades' },
  { Id: 22815, Code: '8129', Name: 'Operator, laser' },
  { Id: 22816, Code: '8129', Name: 'Operator, lathe, carbon goods mfr' },
  { Id: 22817, Code: '8129', Name: 'Operator, mill, sand, steelworks' },
  { Id: 22818, Code: '8129', Name: 'Operator, pantograph' },
  {
    Id: 22819,
    Code: '8129',
    Name: 'Operator, process, aircraft component mfr'
  },
  { Id: 22820, Code: '8129', Name: 'Operator, refrigerator' },
  { Id: 22821, Code: '8129', Name: 'Operator, saw, band, metal trades' },
  { Id: 22822, Code: '8129', Name: 'Operator, saw, METAL' },
  { Id: 22823, Code: '8129', Name: 'Operator, saw, metal trades' },
  { Id: 22824, Code: '8129', Name: 'Operator, shear' },
  { Id: 22825, Code: '8129', Name: 'Operator, shears' },
  { Id: 22826, Code: '8129', Name: 'Operator, slitter, metal mfr' },
  { Id: 22827, Code: '8129', Name: 'Operator, spray, mechanical' },
  { Id: 22828, Code: '8129', Name: 'Operator, stretcher, metal mfr' },
  { Id: 22829, Code: '8129', Name: 'Operator, trimming, bullet' },
  { Id: 22830, Code: '8129', Name: 'Packer, gland' },
  { Id: 22831, Code: '8129', Name: 'Packer, wheel' },
  { Id: 22832, Code: '8129', Name: 'Parer, rolling mill' },
  { Id: 22833, Code: '8129', Name: 'Parer, saw mfr' },
  { Id: 22834, Code: '8129', Name: 'Parer, sheet, steelworks' },
  { Id: 22835, Code: '8129', Name: 'Paster, accumulator mfr' },
  { Id: 22836, Code: '8129', Name: 'Paster, battery' },
  { Id: 22837, Code: '8129', Name: 'Paster, lead' },
  { Id: 22838, Code: '8129', Name: 'Planer, edge, plate' },
  { Id: 22839, Code: '8129', Name: 'Pointer, bar' },
  {
    Id: 22840,
    Code: '8129',
    Name: 'Pointer, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22841, Code: '8129', Name: 'Pointer, rod, wire mfr' },
  { Id: 22842, Code: '8129', Name: 'Pointer, wire mfr' },
  { Id: 22843, Code: '8129', Name: 'Polisher, button' },
  { Id: 22844, Code: '8129', Name: 'Polisher, pen, fountain' },
  { Id: 22845, Code: '8129', Name: 'Polisher, stone, lithography' },
  { Id: 22846, Code: '8129', Name: 'Polisher, tube' },
  { Id: 22847, Code: '8129', Name: 'Polisher, wire' },
  { Id: 22848, Code: '8129', Name: 'Preparer and sealer, cable mfr' },
  { Id: 22849, Code: '8129', Name: 'Preparer, cylinder' },
  { Id: 22850, Code: '8129', Name: 'Preparer, metal trades' },
  { Id: 22851, Code: '8129', Name: 'Preparer, plate, lithographic' },
  { Id: 22852, Code: '8129', Name: 'Presser, asbestos-cement goods mfr' },
  { Id: 22853, Code: '8129', Name: 'Presser, cable mfr' },
  { Id: 22854, Code: '8129', Name: 'Presser, cast concrete products mfr' },
  { Id: 22855, Code: '8129', Name: 'Presser, electric battery mfr' },
  { Id: 22856, Code: '8129', Name: 'Presser, power, carbon goods mfr' },
  { Id: 22857, Code: '8129', Name: 'Presser, scale, knife handle mfr' },
  { Id: 22858, Code: '8129', Name: 'Puller, coal mine' },
  { Id: 22859, Code: '8129', Name: 'Puller, conveyor, coal mine' },
  { Id: 22860, Code: '8129', Name: 'Puller-up, coal mine' },
  { Id: 22861, Code: '8129', Name: 'Pulleyman, coal mine' },
  { Id: 22862, Code: '8129', Name: 'Pumper, lamp, valve mfr' },
  { Id: 22863, Code: '8129', Name: 'Pumper, mining' },
  { Id: 22864, Code: '8129', Name: 'Pumper, syphon, gas supplier' },
  { Id: 22865, Code: '8129', Name: 'Pumpman' },
  { Id: 22866, Code: '8129', Name: 'Pumpman, still, vinegar mfr' },
  { Id: 22867, Code: '8129', Name: 'Pumpman-dipper' },
  { Id: 22868, Code: '8129', Name: 'Pumpsman' },
  { Id: 22869, Code: '8129', Name: 'Reconditioner, girder' },
  { Id: 22870, Code: '8129', Name: 'Rectifier, cycle, motor' },
  { Id: 22871, Code: '8129', Name: 'Reeler, wire rope, cable mfr' },
  { Id: 22872, Code: '8129', Name: 'Regulator, gas, coal gas, coke ovens' },
  { Id: 22873, Code: '8129', Name: 'Remover, belt, coal mine' },
  { Id: 22874, Code: '8129', Name: 'Remover, conveyor, coal mine' },
  { Id: 22875, Code: '8129', Name: 'Repairer, saw' },
  { Id: 22876, Code: '8129', Name: 'Repairer, tub' },
  { Id: 22877, Code: '8129', Name: 'Reshearer, metal trades' },
  { Id: 22878, Code: '8129', Name: 'Rigger, aerial' },
  { Id: 22879, Code: '8129', Name: 'Rigger, gas works' },
  { Id: 22880, Code: '8129', Name: 'Rodder, tube mfr' },
  { Id: 22881, Code: '8129', Name: 'Roller, coal mine' },
  { Id: 22882, Code: '8129', Name: 'Router and mounter' },
  { Id: 22883, Code: '8129', Name: 'Router, PRINTING PLATES' },
  { Id: 22884, Code: '8129', Name: 'Sawyer, back, METAL' },
  { Id: 22885, Code: '8129', Name: 'Sawyer, band, METAL' },
  { Id: 22886, Code: '8129', Name: 'Sawyer, hot' },
  { Id: 22887, Code: '8129', Name: 'Sawyer, METAL' },
  { Id: 22888, Code: '8129', Name: 'Sawyer, rail' },
  { Id: 22889, Code: '8129', Name: 'Sawyer, roller' },
  { Id: 22890, Code: '8129', Name: 'Sawyer, steel tube mfr' },
  { Id: 22891, Code: '8129', Name: 'Scaler, boiler' },
  { Id: 22892, Code: '8129', Name: 'Scaler, boiler maintenance' },
  { Id: 22893, Code: '8129', Name: 'Scaler, boiler, ship' },
  { Id: 22894, Code: '8129', Name: "Scaler, boiler, ship's" },
  { Id: 22895, Code: '8129', Name: 'Scourer, needle mfr' },
  { Id: 22896, Code: '8129', Name: 'Scourer, pin' },
  { Id: 22897, Code: '8129', Name: 'Scraper, boiler' },
  {
    Id: 22898,
    Code: '8129',
    Name: 'Screwer, button, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 22899, Code: '8129', Name: 'Screwer, metal fastener mfr' },
  { Id: 22900, Code: '8129', Name: 'Scurfer, boiler' },
  { Id: 22901, Code: '8129', Name: 'Serrator, KNIVES' },
  { Id: 22902, Code: '8129', Name: 'Servicer, machinery' },
  { Id: 22903, Code: '8129', Name: 'Setter, barrel' },
  { Id: 22904, Code: '8129', Name: 'Setter, circle, textile mfr' },
  { Id: 22905, Code: '8129', Name: 'Setter, file' },
  { Id: 22906, Code: '8129', Name: 'Setter, frame' },
  { Id: 22907, Code: '8129', Name: 'Setter, fuse, damper' },
  { Id: 22908, Code: '8129', Name: 'Setter, needle, textile mfr' },
  { Id: 22909, Code: '8129', Name: 'Setter, pin' },
  { Id: 22910, Code: '8129', Name: 'Setter, saw' },
  { Id: 22911, Code: '8129', Name: 'Setter, tool, edge' },
  { Id: 22912, Code: '8129', Name: 'Setter-out, mill, sawmilling' },
  { Id: 22913, Code: '8129', Name: 'Setter-up, type foundry' },
  { Id: 22914, Code: '8129', Name: 'Sewer, tape, textile spinning' },
  { Id: 22915, Code: '8129', Name: 'Shaftman, coal mine' },
  { Id: 22916, Code: '8129', Name: 'Shaper, filament' },
  { Id: 22917, Code: '8129', Name: 'Shaper, steel pen mfr' },
  { Id: 22918, Code: '8129', Name: 'Sharpener, edge tool mfr' },
  { Id: 22919, Code: '8129', Name: 'Sharpener, saw' },
  { Id: 22920, Code: '8129', Name: 'Shearer, billet, rolling mill' },
  { Id: 22921, Code: '8129', Name: 'Shearer, bloom' },
  { Id: 22922, Code: '8129', Name: 'Shearer, coal mine' },
  { Id: 22923, Code: '8129', Name: 'Shearer, coil, metal trades' },
  { Id: 22924, Code: '8129', Name: 'Shearer, metal trades' },
  { Id: 22925, Code: '8129', Name: 'Shearer, rotary' },
  { Id: 22926, Code: '8129', Name: 'Shearman, metal trades' },
  { Id: 22927, Code: '8129', Name: 'Shearsman, scrap, metal trades' },
  { Id: 22928, Code: '8129', Name: 'Sheavesman, coal mine' },
  { Id: 22929, Code: '8129', Name: 'Shifter, conveyor, coal mine' },
  { Id: 22930, Code: '8129', Name: 'Skiver, belt, abrasive paper, cloth mfr' },
  { Id: 22931, Code: '8129', Name: 'Sleever, cable mfr' },
  { Id: 22932, Code: '8129', Name: 'Slitter, foil, tin' },
  { Id: 22933, Code: '8129', Name: 'Slitter, metal' },
  { Id: 22934, Code: '8129', Name: 'Slitter, pen nib mfr' },
  { Id: 22935, Code: '8129', Name: 'Slitter, steel' },
  { Id: 22936, Code: '8129', Name: 'Slitter, steelworks' },
  { Id: 22937, Code: '8129', Name: 'Smith, cold' },
  { Id: 22938, Code: '8129', Name: 'Smith, key' },
  { Id: 22939, Code: '8129', Name: 'Smith, saw' },
  { Id: 22940, Code: '8129', Name: 'Spinner, electric cable mfr' },
  { Id: 22941, Code: '8129', Name: 'Spinner, wire' },
  { Id: 22942, Code: '8129', Name: 'Spinner, wire rope, cable mfr' },
  { Id: 22943, Code: '8129', Name: 'Spiraller, filament' },
  { Id: 22944, Code: '8129', Name: 'Spooler, wire' },
  { Id: 22945, Code: '8129', Name: 'Springer, needle mfr' },
  { Id: 22946, Code: '8129', Name: 'Stitcher, wire goods mfr' },
  { Id: 22947, Code: '8129', Name: 'Straightener, barrel' },
  { Id: 22948, Code: '8129', Name: 'Straightener, coal mine' },
  { Id: 22949, Code: '8129', Name: 'Straightener, comb' },
  { Id: 22950, Code: '8129', Name: 'Straightener, hard, needle mfr' },
  { Id: 22951, Code: '8129', Name: 'Straightener, needle mfr' },
  { Id: 22952, Code: '8129', Name: 'Straightener, plate, iron' },
  { Id: 22953, Code: '8129', Name: 'Straightener, plate, saw' },
  { Id: 22954, Code: '8129', Name: 'Straightener, prop, coal mine' },
  { Id: 22955, Code: '8129', Name: 'Straightener, steel, coal mine' },
  { Id: 22956, Code: '8129', Name: 'Straightener, wire' },
  { Id: 22957, Code: '8129', Name: 'Straightener, wire mfr' },
  { Id: 22958, Code: '8129', Name: 'Strander, cable mfr' },
  { Id: 22959, Code: '8129', Name: 'Strander, wire' },
  { Id: 22960, Code: '8129', Name: 'Strander, wire rope, cable mfr' },
  { Id: 22961, Code: '8129', Name: 'Stripper and grinder' },
  { Id: 22962, Code: '8129', Name: 'Stripper, card' },
  { Id: 22963, Code: '8129', Name: 'Stripper, file' },
  { Id: 22964, Code: '8129', Name: 'Stubber' },
  { Id: 22965, Code: '8129', Name: 'Supervisor, steel, coal mine' },
  { Id: 22966, Code: '8129', Name: 'Supporter, lamp, valve mfr' },
  { Id: 22967, Code: '8129', Name: 'Swager, cutlery mfr' },
  { Id: 22968, Code: '8129', Name: 'Swarfer, tube mfr' },
  { Id: 22969, Code: '8129', Name: 'Taper, cable mfr' },
  { Id: 22970, Code: '8129', Name: 'Taper, electrical goods mfr' },
  { Id: 22971, Code: '8129', Name: 'Tester-rectifier, cylinder' },
  { Id: 22972, Code: '8129', Name: 'Threader, heald, wire' },
  { Id: 22973, Code: '8129', Name: 'Tier, battery' },
  { Id: 22974, Code: '8129', Name: 'Toother, saw mfr' },
  { Id: 22975, Code: '8129', Name: 'Trimmer, bullet' },
  { Id: 22976, Code: '8129', Name: 'Trimmer, cable' },
  { Id: 22977, Code: '8129', Name: 'Trimmer, gold, silver wire mfr' },
  { Id: 22978, Code: '8129', Name: 'Trimmer, plate, metal trades' },
  { Id: 22979, Code: '8129', Name: 'Trouncer, metal trades' },
  { Id: 22980, Code: '8129', Name: 'Truer, wheel, cycle mfr' },
  { Id: 22981, Code: '8129', Name: 'Truer-up, wheel, cycle mfr' },
  { Id: 22982, Code: '8129', Name: 'Turner, belt, coal mine' },
  { Id: 22983, Code: '8129', Name: 'Turner, button' },
  { Id: 22984, Code: '8129', Name: 'Turner, cemented carbide goods mfr' },
  { Id: 22985, Code: '8129', Name: 'Turner, conveyor, coal mine' },
  { Id: 22986, Code: '8129', Name: 'Turner, graphite' },
  { Id: 22987, Code: '8129', Name: 'Turner, wire' },
  { Id: 22988, Code: '8129', Name: 'Twister, broom, brush mfr' },
  { Id: 22989, Code: '8129', Name: 'Valveman, cartridge mfr' },
  { Id: 22990, Code: '8129', Name: 'Veiner' },
  { Id: 22991, Code: '8129', Name: 'Veiner and marker, artificial flower mfr' },
  { Id: 22992, Code: '8129', Name: 'Warmer, rivet' },
  { Id: 22993, Code: '8129', Name: 'Washer, boiler' },
  { Id: 22994, Code: '8129', Name: 'Waxer, battery mfr' },
  { Id: 22995, Code: '8129', Name: 'Weaver, spring' },
  { Id: 22996, Code: '8129', Name: 'Wheeler, silver, plate mfr' },
  { Id: 22997, Code: '8129', Name: 'Wheelman, coal mine' },
  { Id: 22998, Code: '8129', Name: 'Whetter, CUTLERY' },
  { Id: 22999, Code: '8129', Name: 'Winder, bobbin, wire mfr' },
  { Id: 23000, Code: '8129', Name: 'Winder, cable' },
  { Id: 23001, Code: '8129', Name: 'Winder, cable mfr' },
  { Id: 23002, Code: '8129', Name: 'Winder, copper, cable mfr' },
  { Id: 23003, Code: '8129', Name: 'Winder, core, cable mfr' },
  { Id: 23004, Code: '8129', Name: 'Winder, filament' },
  {
    Id: 23005,
    Code: '8129',
    Name: 'Winder, insulating, electrical engineering'
  },
  { Id: 23006, Code: '8129', Name: 'Winder, rope, wire' },
  { Id: 23007, Code: '8129', Name: 'Winder, wire' },
  { Id: 23008, Code: '8129', Name: 'Winder, wire goods mfr' },
  { Id: 23009, Code: '8129', Name: 'Worker, bench, engineering' },
  { Id: 23010, Code: '8129', Name: 'Worker, button' },
  { Id: 23011, Code: '8129', Name: 'Worker, cable, cable mfr' },
  { Id: 23012, Code: '8129', Name: 'Worker, cutlery' },
  { Id: 23013, Code: '8129', Name: 'Worker, lead, accumulator mfr' },
  { Id: 23014, Code: '8129', Name: 'Worker, lino' },
  { Id: 23015, Code: '8129', Name: 'Worker, linoleum' },
  { Id: 23016, Code: '8129', Name: 'Worker, odd, engineering' },
  { Id: 23017, Code: '8129', Name: 'Worker, saw, hot' },
  { Id: 23018, Code: '8129', Name: 'Worker, spring' },
  { Id: 23019, Code: '8129', Name: 'Worker, tool, edge' },
  { Id: 23020, Code: '8129', Name: 'Worker, wire' },
  { Id: 23021, Code: '8129', Name: 'Worker, wire, cable mfr' },
  { Id: 23022, Code: '8129', Name: 'Worksetter' },
  { Id: 23023, Code: '8129', Name: 'Wrapper, cable' },
  { Id: 23024, Code: '8131', Name: 'Aligner, RADIO, TELEVISION' },
  { Id: 23025, Code: '8131', Name: 'Assembler, accumulator' },
  { Id: 23026, Code: '8131', Name: 'Assembler, accumulator mfr' },
  { Id: 23027, Code: '8131', Name: 'Assembler, aerial' },
  {
    Id: 23028,
    Code: '8131',
    Name: 'Assembler, apparatus, electricity supplier'
  },
  { Id: 23029, Code: '8131', Name: 'Assembler, armature' },
  { Id: 23030, Code: '8131', Name: 'Assembler, Bakelite' },
  { Id: 23031, Code: '8131', Name: 'Assembler, battery' },
  { Id: 23032, Code: '8131', Name: 'Assembler, belt' },
  { Id: 23033, Code: '8131', Name: 'Assembler, board, circuit, printed' },
  { Id: 23034, Code: '8131', Name: 'Assembler, cable' },
  { Id: 23035, Code: '8131', Name: 'Assembler, calculating machines mfr' },
  { Id: 23036, Code: '8131', Name: 'Assembler, change, record' },
  { Id: 23037, Code: '8131', Name: 'Assembler, coil' },
  { Id: 23038, Code: '8131', Name: 'Assembler, commutator' },
  {
    Id: 23039,
    Code: '8131',
    Name: 'Assembler, component, ELECTRICAL, ELECTRONIC'
  },
  {
    Id: 23040,
    Code: '8131',
    Name: 'Assembler, components, ELECTRICAL, ELECTRONIC'
  },
  { Id: 23041, Code: '8131', Name: 'Assembler, computer' },
  { Id: 23042, Code: '8131', Name: 'Assembler, cooker, electric' },
  { Id: 23043, Code: '8131', Name: 'Assembler, core, electrical engineering' },
  { Id: 23044, Code: '8131', Name: 'Assembler, crystal, quartz' },
  { Id: 23045, Code: '8131', Name: 'Assembler, dynamo' },
  { Id: 23046, Code: '8131', Name: 'Assembler, electrical' },
  {
    Id: 23047,
    Code: '8131',
    Name: 'Assembler, electrical, electronic equipment mfr'
  },
  { Id: 23048, Code: '8131', Name: 'Assembler, electronic' },
  { Id: 23049, Code: '8131', Name: 'Assembler, electronics' },
  { Id: 23050, Code: '8131', Name: 'Assembler, equipment, video' },
  { Id: 23051, Code: '8131', Name: 'Assembler, filament' },
  { Id: 23052, Code: '8131', Name: 'Assembler, fire, electric' },
  { Id: 23053, Code: '8131', Name: 'Assembler, gun, HAND' },
  { Id: 23054, Code: '8131', Name: 'Assembler, instrument, electrical' },
  { Id: 23055, Code: '8131', Name: 'Assembler, instrument, telephone' },
  { Id: 23056, Code: '8131', Name: 'Assembler, lamp, electric' },
  { Id: 23057, Code: '8131', Name: 'Assembler, magnet' },
  { Id: 23058, Code: '8131', Name: 'Assembler, motor, ELECTRIC' },
  { Id: 23059, Code: '8131', Name: 'Assembler, PCB' },
  { Id: 23060, Code: '8131', Name: 'Assembler, radar' },
  { Id: 23061, Code: '8131', Name: 'Assembler, radio' },
  { Id: 23062, Code: '8131', Name: 'Assembler, radio, television, video mfr' },
  { Id: 23063, Code: '8131', Name: 'Assembler, recorder, video' },
  { Id: 23064, Code: '8131', Name: 'Assembler, rectifier' },
  { Id: 23065, Code: '8131', Name: 'Assembler, relay' },
  { Id: 23066, Code: '8131', Name: 'Assembler, sign, neon' },
  { Id: 23067, Code: '8131', Name: 'Assembler, stator' },
  { Id: 23068, Code: '8131', Name: 'Assembler, stove, ELECTRIC' },
  { Id: 23069, Code: '8131', Name: 'Assembler, switchboard' },
  { Id: 23070, Code: '8131', Name: 'Assembler, switchgear' },
  { Id: 23071, Code: '8131', Name: 'Assembler, system, stereo' },
  {
    Id: 23072,
    Code: '8131',
    Name: 'Assembler, telecommunications equipment mfr'
  },
  { Id: 23073, Code: '8131', Name: 'Assembler, telephone' },
  { Id: 23074, Code: '8131', Name: 'Assembler, television' },
  { Id: 23075, Code: '8131', Name: 'Assembler, temple' },
  { Id: 23076, Code: '8131', Name: 'Assembler, valve' },
  { Id: 23077, Code: '8131', Name: 'Bander, armature' },
  { Id: 23078, Code: '8131', Name: 'Bender, copper, GENERATORS' },
  { Id: 23079, Code: '8131', Name: 'Bender, element' },
  { Id: 23080, Code: '8131', Name: 'Binder, armature' },
  { Id: 23081, Code: '8131', Name: 'Bonder, ELECTRICAL' },
  { Id: 23082, Code: '8131', Name: 'Builder, condenser' },
  { Id: 23083, Code: '8131', Name: 'Builder, core' },
  { Id: 23084, Code: '8131', Name: 'Capper' },
  { Id: 23085, Code: '8131', Name: 'Cementer, electrical insulator mfr' },
  { Id: 23086, Code: '8131', Name: 'Coiler, electrical goods mfr' },
  { Id: 23087, Code: '8131', Name: 'Connector, armature' },
  { Id: 23088, Code: '8131', Name: 'Engineer, aerial' },
  { Id: 23089, Code: '8131', Name: 'Erector, battery' },
  { Id: 23090, Code: '8131', Name: 'Erector, cell, chemical mfr' },
  { Id: 23091, Code: '8131', Name: 'Filler, cap, lamp, valve mfr' },
  { Id: 23092, Code: '8131', Name: 'Finisher, coil' },
  { Id: 23093, Code: '8131', Name: 'Fixer, cap, LAMP AND VALVES' },
  {
    Id: 23094,
    Code: '8131',
    Name: 'Foreman, electrical domestic appliance mfr'
  },
  {
    Id: 23095,
    Code: '8131',
    Name: 'Foreman, electrical lighting equipment mfr'
  },
  {
    Id: 23096,
    Code: '8131',
    Name: 'Foreman, telecommunications equipment mfr'
  },
  { Id: 23097, Code: '8131', Name: 'Forewoman, factory, telecommunications' },
  { Id: 23098, Code: '8131', Name: 'Former, cable' },
  { Id: 23099, Code: '8131', Name: 'Former, coil' },
  { Id: 23100, Code: '8131', Name: 'Former, copper, GENERATORS' },
  { Id: 23101, Code: '8131', Name: 'Former, loom' },
  { Id: 23102, Code: '8131', Name: 'Hand, condenser' },
  { Id: 23103, Code: '8131', Name: 'Hand, transformer' },
  { Id: 23104, Code: '8131', Name: 'Inserter, coil' },
  {
    Id: 23105,
    Code: '8131',
    Name: 'Inserter, electrical, electronic equipment mfr'
  },
  { Id: 23106, Code: '8131', Name: 'Inserter, lamp, valve mfr' },
  { Id: 23107, Code: '8131', Name: 'Joiner, bulb, valve mfr' },
  { Id: 23108, Code: '8131', Name: 'Joiner, lamp, valve mfr' },
  {
    Id: 23109,
    Code: '8131',
    Name: 'Leader, team, electrical, electronic equipment mfr, ASSEMBLY'
  },
  {
    Id: 23110,
    Code: '8131',
    Name: 'Leader, team, production, electrical, electronic equipment mfr, ASSEMBLY'
  },
  { Id: 23111, Code: '8131', Name: 'Machinist, capping, lamp' },
  { Id: 23112, Code: '8131', Name: 'Maker, board, circuit, printed' },
  { Id: 23113, Code: '8131', Name: 'Maker, bulb, electric lamp mfr' },
  { Id: 23114, Code: '8131', Name: 'Maker, coil, ELECTRIC' },
  { Id: 23115, Code: '8131', Name: 'Maker, condenser, ELECTRIC' },
  { Id: 23116, Code: '8131', Name: 'Maker, fan, electrical goods mfr' },
  { Id: 23117, Code: '8131', Name: 'Maker, filament' },
  { Id: 23118, Code: '8131', Name: 'Maker, fire, electric' },
  { Id: 23119, Code: '8131', Name: 'Maker, form, cable mfr' },
  { Id: 23120, Code: '8131', Name: 'Maker, fuse' },
  { Id: 23121, Code: '8131', Name: 'Maker, harness, electrical' },
  { Id: 23122, Code: '8131', Name: 'Maker, lamp, electric' },
  { Id: 23123, Code: '8131', Name: 'Maker, lamp, glow' },
  { Id: 23124, Code: '8131', Name: 'Maker, pinch' },
  { Id: 23125, Code: '8131', Name: 'Maker, plug, sparking' },
  { Id: 23126, Code: '8131', Name: 'Maker, switch' },
  { Id: 23127, Code: '8131', Name: 'Maker, tube, television' },
  { Id: 23128, Code: '8131', Name: 'Maker, valve, radio valve mfr' },
  { Id: 23129, Code: '8131', Name: 'Marker, line, sub-assembly, radio mfr' },
  { Id: 23130, Code: '8131', Name: 'Mounter, filament' },
  { Id: 23131, Code: '8131', Name: 'Operator, circuit, printed' },
  { Id: 23132, Code: '8131', Name: 'Operator, line, ELECTRICAL' },
  {
    Id: 23133,
    Code: '8131',
    Name: 'Operator, manufacturing, electrical, electronic equipment mfr'
  },
  {
    Id: 23134,
    Code: '8131',
    Name: 'Operator, mount, surface, electrical, electronic equipment mfr'
  },
  { Id: 23135, Code: '8131', Name: 'Operator, PCB' },
  {
    Id: 23136,
    Code: '8131',
    Name: 'Operator, rig, electrical, electronic equipment mfr'
  },
  { Id: 23137, Code: '8131', Name: 'Operator, solder, flow' },
  { Id: 23138, Code: '8131', Name: 'Operator, technical, circuit board mfr' },
  { Id: 23139, Code: '8131', Name: 'Operator, tube, lamp, valve mfr' },
  { Id: 23140, Code: '8131', Name: 'Pinner, lamp, valve mfr' },
  { Id: 23141, Code: '8131', Name: 'Presser, coil' },
  { Id: 23142, Code: '8131', Name: 'Repairer, coil' },
  { Id: 23143, Code: '8131', Name: 'Rewinder, motor, electric' },
  { Id: 23144, Code: '8131', Name: 'Sealer, lamp, valve mfr' },
  { Id: 23145, Code: '8131', Name: 'Separator, plate, CAR BATTERY' },
  { Id: 23146, Code: '8131', Name: 'Setter, flame' },
  { Id: 23147, Code: '8131', Name: 'Setter, thermostat' },
  { Id: 23148, Code: '8131', Name: 'Sleever, radio valve mfr' },
  { Id: 23149, Code: '8131', Name: 'Solderer' },
  { Id: 23150, Code: '8131', Name: 'Solderer, flow' },
  {
    Id: 23151,
    Code: '8131',
    Name: 'Specialist, production, electrical, electronic equipment mfr'
  },
  { Id: 23152, Code: '8131', Name: 'Taper, WIRING' },
  {
    Id: 23153,
    Code: '8131',
    Name: 'Technician, SMT, electrical, electronic equipment mfr'
  },
  { Id: 23154, Code: '8131', Name: 'Tester-rectifier, equipment, electrical' },
  { Id: 23155, Code: '8131', Name: 'Tester-rectifier, equipment, electronic' },
  { Id: 23156, Code: '8131', Name: 'Threader, lamp, valve mfr' },
  { Id: 23157, Code: '8131', Name: 'Winder, armature' },
  { Id: 23158, Code: '8131', Name: 'Winder, bobbin, electrical goods mfr' },
  { Id: 23159, Code: '8131', Name: 'Winder, coil' },
  { Id: 23160, Code: '8131', Name: 'Winder, disc, armature' },
  { Id: 23161, Code: '8131', Name: 'Winder, dynamo' },
  { Id: 23162, Code: '8131', Name: 'Winder, electrical' },
  { Id: 23163, Code: '8131', Name: 'Winder, electrical goods mfr' },
  { Id: 23164, Code: '8131', Name: 'Winder, element' },
  { Id: 23165, Code: '8131', Name: 'Winder, mesh' },
  { Id: 23166, Code: '8131', Name: 'Winder, motor, induction' },
  { Id: 23167, Code: '8131', Name: 'Winder, rotor' },
  { Id: 23168, Code: '8131', Name: 'Winder, spool, electrical goods mfr' },
  { Id: 23169, Code: '8131', Name: 'Winder, stator' },
  { Id: 23170, Code: '8131', Name: 'Winder, transformer' },
  { Id: 23171, Code: '8131', Name: 'Winder, turbo' },
  { Id: 23172, Code: '8131', Name: 'Wireman' },
  { Id: 23173, Code: '8131', Name: 'Wireman, indoor' },
  { Id: 23174, Code: '8131', Name: 'Wireman, instrument' },
  { Id: 23175, Code: '8131', Name: 'Wireman, radar' },
  { Id: 23176, Code: '8131', Name: 'Wireman-assembler' },
  {
    Id: 23177,
    Code: '8131',
    Name: 'Wirer and solderer, radio, television, video mfr'
  },
  { Id: 23178, Code: '8131', Name: 'Wirer, electronic apparatus mfr' },
  { Id: 23179, Code: '8131', Name: 'Wirer, panel' },
  {
    Id: 23180,
    Code: '8131',
    Name: 'Worker, factory, electrical goods mfr: assembling, soldering'
  },
  {
    Id: 23181,
    Code: '8131',
    Name: 'Worker, process, electrical domestic appliance mfr'
  },
  {
    Id: 23182,
    Code: '8131',
    Name: 'Worker, process, electrical, electronic equipment mfr'
  },
  { Id: 23183, Code: '8131', Name: 'Worker, process, lamp, valve mfr' },
  { Id: 23184, Code: '8132', Name: 'Assembler, body, VEHICLE' },
  { Id: 23185, Code: '8132', Name: 'Assembler, brake' },
  { Id: 23186, Code: '8132', Name: 'Assembler, brass' },
  { Id: 23187, Code: '8132', Name: 'Assembler, car' },
  { Id: 23188, Code: '8132', Name: 'Assembler, component, MECHANICAL' },
  { Id: 23189, Code: '8132', Name: 'Assembler, components, MECHANICAL' },
  { Id: 23190, Code: '8132', Name: 'Assembler, cooker, gas' },
  { Id: 23191, Code: '8132', Name: 'Assembler, cycle' },
  { Id: 23192, Code: '8132', Name: 'Assembler, cycle mfr' },
  { Id: 23193, Code: '8132', Name: 'Assembler, detonator' },
  { Id: 23194, Code: '8132', Name: 'Assembler, engine' },
  { Id: 23195, Code: '8132', Name: 'Assembler, engineering' },
  { Id: 23196, Code: '8132', Name: 'Assembler, filter, machinery mfr' },
  { Id: 23197, Code: '8132', Name: 'Assembler, frame, bed' },
  { Id: 23198, Code: '8132', Name: 'Assembler, gun' },
  { Id: 23199, Code: '8132', Name: 'Assembler, gun mfr' },
  { Id: 23200, Code: '8132', Name: 'Assembler, jewellery' },
  { Id: 23201, Code: '8132', Name: 'Assembler, jewellery, plate mfr' },
  { Id: 23202, Code: '8132', Name: 'Assembler, lamp' },
  { Id: 23203, Code: '8132', Name: 'Assembler, lock' },
  { Id: 23204, Code: '8132', Name: 'Assembler, machine' },
  { Id: 23205, Code: '8132', Name: 'Assembler, metal trades' },
  { Id: 23206, Code: '8132', Name: 'Assembler, motor vehicle mfr' },
  { Id: 23207, Code: '8132', Name: 'Assembler, motor, engineering' },
  { Id: 23208, Code: '8132', Name: 'Assembler, refrigerator' },
  { Id: 23209, Code: '8132', Name: 'Assembler, rifle' },
  { Id: 23210, Code: '8132', Name: 'Assembler, seat, spring' },
  { Id: 23211, Code: '8132', Name: 'Assembler, spring' },
  { Id: 23212, Code: '8132', Name: 'Assembler, stove' },
  { Id: 23213, Code: '8132', Name: 'Assembler, tub' },
  { Id: 23214, Code: '8132', Name: "Assembler, valve, ENGINEER'S VALVES" },
  { Id: 23215, Code: '8132', Name: 'Assembler, vehicle, motor' },
  {
    Id: 23216,
    Code: '8132',
    Name: 'Assembler, window and door mfr, METAL FRAMES'
  },
  { Id: 23217, Code: '8132', Name: 'Associate, general, vehicle mfr' },
  { Id: 23218, Code: '8132', Name: 'Associate, production, vehicle mfr' },
  { Id: 23219, Code: '8132', Name: 'Associate, vehicle mfr' },
  { Id: 23220, Code: '8132', Name: 'Bolter-up, metal trades' },
  { Id: 23221, Code: '8132', Name: 'Builder, wheel, VEHICLES' },
  { Id: 23222, Code: '8132', Name: 'Capper, bobbin' },
  { Id: 23223, Code: '8132', Name: 'Cementer, metal capsule mfr' },
  { Id: 23224, Code: '8132', Name: 'Coverer, corset mfr' },
  { Id: 23225, Code: '8132', Name: 'Crimper, detonator' },
  { Id: 23226, Code: '8132', Name: 'Finisher, brush, wire' },
  { Id: 23227, Code: '8132', Name: 'Finisher, watch, clock mfr' },
  { Id: 23228, Code: '8132', Name: 'Fitter, bag frame mfr' },
  { Id: 23229, Code: '8132', Name: 'Fitter, bar, handle' },
  { Id: 23230, Code: '8132', Name: 'Fitter, bonnet, VEHICLE' },
  { Id: 23231, Code: '8132', Name: 'Fitter, brake, cycle mfr' },
  { Id: 23232, Code: '8132', Name: 'Fitter, grip, TOOLS' },
  { Id: 23233, Code: '8132', Name: 'Fitter, loose leaf book mfr' },
  { Id: 23234, Code: '8132', Name: 'Fitter, paragon, UMBRELLAS' },
  { Id: 23235, Code: '8132', Name: 'Fitter, saddle, CYCLES' },
  { Id: 23236, Code: '8132', Name: 'Fitter, semi-skilled' },
  { Id: 23237, Code: '8132', Name: 'Fitter, stove, stove mfr' },
  { Id: 23238, Code: '8132', Name: 'Fixer, panel, VEHICLE' },
  { Id: 23239, Code: '8132', Name: 'Foreman, line, metal trades' },
  { Id: 23240, Code: '8132', Name: 'Foreman, motor vehicle mfr' },
  { Id: 23241, Code: '8132', Name: 'Giller, motor radiator mfr' },
  { Id: 23242, Code: '8132', Name: 'Hand, cycle' },
  { Id: 23243, Code: '8132', Name: 'Hanger, door, COACH BODY' },
  { Id: 23244, Code: '8132', Name: 'Lacer and driller, wheel, cycle mfr' },
  { Id: 23245, Code: '8132', Name: 'Lacer, wheel' },
  {
    Id: 23246,
    Code: '8132',
    Name: 'Leader, team, motor vehicle mfr, ASSEMBLY'
  },
  {
    Id: 23247,
    Code: '8132',
    Name: 'Leader, team, production, motor vehicle mfr'
  },
  { Id: 23248, Code: '8132', Name: 'Lineworker, vehicle mfr' },
  { Id: 23249, Code: '8132', Name: 'Machinist, production, vehicle mfr' },
  { Id: 23250, Code: '8132', Name: 'Machinist, riveting, metal trades' },
  {
    Id: 23251,
    Code: '8132',
    Name: 'Machinist, stapling, mattress, upholstery mfr'
  },
  { Id: 23252, Code: '8132', Name: 'Maker, bell, CYCLE BELLS' },
  { Id: 23253, Code: '8132', Name: 'Maker, bicycle' },
  { Id: 23254, Code: '8132', Name: 'Maker, brake, car' },
  { Id: 23255, Code: '8132', Name: 'Maker, carriage, invalid' },
  { Id: 23256, Code: '8132', Name: 'Maker, chair, metal furniture mfr' },
  { Id: 23257, Code: '8132', Name: 'Maker, cycle' },
  { Id: 23258, Code: '8132', Name: 'Maker, frame, umbrella' },
  { Id: 23259, Code: '8132', Name: 'Maker, hinge' },
  { Id: 23260, Code: '8132', Name: 'Maker, mattress, link' },
  { Id: 23261, Code: '8132', Name: 'Maker, wheel, cycle mfr' },
  { Id: 23262, Code: '8132', Name: 'Mounter, body' },
  { Id: 23263, Code: '8132', Name: 'Mounter, VEHICLE BUILDING' },
  { Id: 23264, Code: '8132', Name: 'Mounter, wing, COACH BODY' },
  { Id: 23265, Code: '8132', Name: 'Nutter-up' },
  { Id: 23266, Code: '8132', Name: 'Operator, cell, vehicle mfr' },
  { Id: 23267, Code: '8132', Name: 'Operator, line, assembly, vehicle mfr' },
  { Id: 23268, Code: '8132', Name: 'Operator, line, engineering' },
  { Id: 23269, Code: '8132', Name: 'Operator, line, trim, motor vehicle mfr' },
  { Id: 23270, Code: '8132', Name: 'Operator, manufacturing, metal trades' },
  { Id: 23271, Code: '8132', Name: 'Pinner, comb, woollen' },
  { Id: 23272, Code: '8132', Name: 'Pinner, metal trades' },
  { Id: 23273, Code: '8132', Name: 'Riveter, BAG FRAMES' },
  { Id: 23274, Code: '8132', Name: 'Riveter, CORSETS' },
  { Id: 23275, Code: '8132', Name: 'Riveter, CURRY COMBS' },
  { Id: 23276, Code: '8132', Name: 'Riveter, UMBRELLA RIBS' },
  { Id: 23277, Code: '8132', Name: 'Sealer, car' },
  { Id: 23278, Code: '8132', Name: 'Setter, door, vehicle mfr' },
  { Id: 23279, Code: '8132', Name: 'Setter, jewel, watch mfr' },
  { Id: 23280, Code: '8132', Name: 'Springer, umbrella' },
  { Id: 23281, Code: '8132', Name: 'Stapler, mattress mfr' },
  { Id: 23282, Code: '8132', Name: 'Trackworker, vehicle mfr' },
  { Id: 23283, Code: '8132', Name: 'Wireman, cycle mfr' },
  { Id: 23284, Code: '8132', Name: 'Worker, car' },
  { Id: 23285, Code: '8132', Name: 'Worker, cycle' },
  { Id: 23286, Code: '8132', Name: 'Worker, process, metal trades: assembly' },
  { Id: 23287, Code: '8132', Name: 'Worker, process, vehicle mfr' },
  { Id: 23288, Code: '8133', Name: 'Adjuster, dial, telephone mfr' },
  { Id: 23289, Code: '8133', Name: 'Adjuster, TELEPHONES' },
  { Id: 23290, Code: '8133', Name: 'Assessor, quality' },
  { Id: 23291, Code: '8133', Name: "Assistant, checker's, metal trades" },
  { Id: 23292, Code: '8133', Name: 'Assistant, control, quality' },
  { Id: 23293, Code: '8133', Name: "Assistant, inspector's, metal trades" },
  { Id: 23294, Code: '8133', Name: 'Assistant, room, test, ELECTRICAL' },
  { Id: 23295, Code: '8133', Name: "Assistant, tester's, meter" },
  { Id: 23296, Code: '8133', Name: 'Assorter, GALVANISED SHEET' },
  { Id: 23297, Code: '8133', Name: 'Assorter, TINPLATE' },
  {
    Id: 23298,
    Code: '8133',
    Name: 'Attendant, room, sample, food products mfr'
  },
  { Id: 23299, Code: '8133', Name: 'Auditor, control, quality' },
  { Id: 23300, Code: '8133', Name: 'Auditor, quality' },
  { Id: 23301, Code: '8133', Name: 'Balancer, dynamic' },
  { Id: 23302, Code: '8133', Name: 'Balancer, dynamics' },
  { Id: 23303, Code: '8133', Name: 'Balancer, engineering' },
  { Id: 23304, Code: '8133', Name: 'Balancer, shaft, crank' },
  { Id: 23305, Code: '8133', Name: 'Balancer, wheel, railway workshops' },
  { Id: 23306, Code: '8133', Name: 'Brineller' },
  { Id: 23307, Code: '8133', Name: 'Candler, egg' },
  { Id: 23308, Code: '8133', Name: 'Catcher, cigarette' },
  { Id: 23309, Code: '8133', Name: 'Catcher, machine' },
  { Id: 23310, Code: '8133', Name: 'Certifier, order, money' },
  { Id: 23311, Code: '8133', Name: 'Checker, bakery' },
  { Id: 23312, Code: '8133', Name: 'Checker, Bank of England' },
  { Id: 23313, Code: '8133', Name: 'Checker, bank-note' },
  { Id: 23314, Code: '8133', Name: 'Checker, brewery' },
  { Id: 23315, Code: '8133', Name: 'Checker, ceramics mfr' },
  { Id: 23316, Code: '8133', Name: 'Checker, clothing mfr' },
  { Id: 23317, Code: '8133', Name: 'Checker, drug mfr' },
  {
    Id: 23318,
    Code: '8133',
    Name: 'Checker, electrical, electronic engineering'
  },
  {
    Id: 23319,
    Code: '8133',
    Name: 'Checker, ELECTRICAL, ELECTRONIC EQUIPMENT'
  },
  { Id: 23320, Code: '8133', Name: 'Checker, food products mfr' },
  { Id: 23321, Code: '8133', Name: 'Checker, gauge' },
  { Id: 23322, Code: '8133', Name: 'Checker, ingot, steelworks' },
  { Id: 23323, Code: '8133', Name: 'Checker, iron' },
  { Id: 23324, Code: '8133', Name: 'Checker, machine, engineering' },
  { Id: 23325, Code: '8133', Name: 'Checker, metal trades' },
  { Id: 23326, Code: '8133', Name: 'Checker, mica' },
  { Id: 23327, Code: '8133', Name: 'Checker, milk' },
  { Id: 23328, Code: '8133', Name: 'Checker, moulding' },
  { Id: 23329, Code: '8133', Name: 'Checker, nos' },
  { Id: 23330, Code: '8133', Name: 'Checker, paint mfr' },
  { Id: 23331, Code: '8133', Name: 'Checker, paper, paper mfr' },
  {
    Id: 23332,
    Code: '8133',
    Name: 'Checker, photographic, printed circuit board mfr'
  },
  { Id: 23333, Code: '8133', Name: 'Checker, plastics goods mfr' },
  { Id: 23334, Code: '8133', Name: 'Checker, printing' },
  { Id: 23335, Code: '8133', Name: 'Checker, rubber goods mfr' },
  { Id: 23336, Code: '8133', Name: 'Checker, textile materials, products mfr' },
  { Id: 23337, Code: '8133', Name: 'Checker, wood products mfr' },
  { Id: 23338, Code: '8133', Name: 'Controller, quality' },
  { Id: 23339, Code: '8133', Name: 'Coordinator, quality, MANUFACTURING' },
  { Id: 23340, Code: '8133', Name: 'Counter, paper' },
  { Id: 23341, Code: '8133', Name: 'Counter, paper mfr' },
  { Id: 23342, Code: '8133', Name: 'Detector, crack, metal mfr' },
  { Id: 23343, Code: '8133', Name: 'Drawer, cloth' },
  { Id: 23344, Code: '8133', Name: 'Drawer, textile mfr: lace mfr' },
  { Id: 23345, Code: '8133', Name: 'Engineer, inspection' },
  { Id: 23346, Code: '8133', Name: 'Engineer, inspector' },
  { Id: 23347, Code: '8133', Name: 'Engineer, maintenance, tyre' },
  { Id: 23348, Code: '8133', Name: 'Engineer, test' },
  { Id: 23349, Code: '8133', Name: 'Engineer, test, electrical' },
  { Id: 23350, Code: '8133', Name: 'Engineer, ultrasonic' },
  { Id: 23351, Code: '8133', Name: 'Engineer-examiner' },
  { Id: 23352, Code: '8133', Name: 'Engineer-inspector' },
  { Id: 23353, Code: '8133', Name: 'Examiner, ammunition' },
  { Id: 23354, Code: '8133', Name: 'Examiner, armaments' },
  { Id: 23355, Code: '8133', Name: 'Examiner, asbestos composition goods mfr' },
  { Id: 23356, Code: '8133', Name: 'Examiner, bag' },
  { Id: 23357, Code: '8133', Name: 'Examiner, bank-note' },
  { Id: 23358, Code: '8133', Name: 'Examiner, book, printing' },
  { Id: 23359, Code: '8133', Name: 'Examiner, bookbinding' },
  { Id: 23360, Code: '8133', Name: 'Examiner, brush' },
  { Id: 23361, Code: '8133', Name: 'Examiner, bulb, lamp mfr' },
  { Id: 23362, Code: '8133', Name: 'Examiner, bullet' },
  { Id: 23363, Code: '8133', Name: 'Examiner, burr, dental instrument mfr' },
  { Id: 23364, Code: '8133', Name: 'Examiner, bus' },
  { Id: 23365, Code: '8133', Name: 'Examiner, ceramics mfr' },
  { Id: 23366, Code: '8133', Name: 'Examiner, chain' },
  { Id: 23367, Code: '8133', Name: 'Examiner, chemical mfr' },
  { Id: 23368, Code: '8133', Name: 'Examiner, cheque' },
  { Id: 23369, Code: '8133', Name: 'Examiner, cigar' },
  { Id: 23370, Code: '8133', Name: 'Examiner, cloth' },
  { Id: 23371, Code: '8133', Name: 'Examiner, cloth, leather' },
  { Id: 23372, Code: '8133', Name: 'Examiner, clothing mfr' },
  { Id: 23373, Code: '8133', Name: 'Examiner, cycle' },
  { Id: 23374, Code: '8133', Name: "Examiner, decorator's, ceramics mfr" },
  { Id: 23375, Code: '8133', Name: 'Examiner, dyeing and cleaning' },
  { Id: 23376, Code: '8133', Name: 'Examiner, electrical goods mfr' },
  {
    Id: 23377,
    Code: '8133',
    Name: 'Examiner, electrical, electronic equipment mfr'
  },
  { Id: 23378, Code: '8133', Name: 'Examiner, engineering' },
  { Id: 23379, Code: '8133', Name: 'Examiner, fancy goods mfr' },
  { Id: 23380, Code: '8133', Name: 'Examiner, file' },
  { Id: 23381, Code: '8133', Name: 'Examiner, film' },
  { Id: 23382, Code: '8133', Name: 'Examiner, final, clothing mfr' },
  { Id: 23383, Code: '8133', Name: 'Examiner, food products mfr' },
  { Id: 23384, Code: '8133', Name: 'Examiner, footwear mfr' },
  { Id: 23385, Code: '8133', Name: 'Examiner, glass mfr' },
  { Id: 23386, Code: '8133', Name: 'Examiner, glass, glass mfr' },
  { Id: 23387, Code: '8133', Name: 'Examiner, hosiery' },
  { Id: 23388, Code: '8133', Name: 'Examiner, impression, Ordnance Survey' },
  { Id: 23389, Code: '8133', Name: 'Examiner, label' },
  {
    Id: 23390,
    Code: '8133',
    Name: 'Examiner, laundry, launderette, dry cleaning'
  },
  { Id: 23391, Code: '8133', Name: 'Examiner, leathercloth mfr' },
  { Id: 23392, Code: '8133', Name: 'Examiner, machine, cigarette' },
  { Id: 23393, Code: '8133', Name: 'Examiner, match mfr' },
  { Id: 23394, Code: '8133', Name: 'Examiner, mechanical' },
  { Id: 23395, Code: '8133', Name: 'Examiner, metal trades' },
  {
    Id: 23396,
    Code: '8133',
    Name: 'Examiner, meter, Dept for Business Innovation and Skills'
  },
  { Id: 23397, Code: '8133', Name: 'Examiner, mica, micanite goods mfr' },
  { Id: 23398, Code: '8133', Name: 'Examiner, MOD' },
  { Id: 23399, Code: '8133', Name: 'Examiner, motor' },
  { Id: 23400, Code: '8133', Name: 'Examiner, ordnance factory' },
  { Id: 23401, Code: '8133', Name: 'Examiner, paper mfr' },
  { Id: 23402, Code: '8133', Name: 'Examiner, pen' },
  { Id: 23403, Code: '8133', Name: 'Examiner, pencil, crayon mfr' },
  { Id: 23404, Code: '8133', Name: 'Examiner, piece' },
  { Id: 23405, Code: '8133', Name: 'Examiner, plan, Ordnance Survey' },
  { Id: 23406, Code: '8133', Name: 'Examiner, plastics goods mfr' },
  { Id: 23407, Code: '8133', Name: 'Examiner, print' },
  { Id: 23408, Code: '8133', Name: "Examiner, printer's" },
  { Id: 23409, Code: '8133', Name: 'Examiner, printing' },
  { Id: 23410, Code: '8133', Name: 'Examiner, railway maintenance and repair' },
  { Id: 23411, Code: '8133', Name: 'Examiner, railways' },
  { Id: 23412, Code: '8133', Name: 'Examiner, roller, printing' },
  { Id: 23413, Code: '8133', Name: 'Examiner, Royal Mint' },
  { Id: 23414, Code: '8133', Name: 'Examiner, rubber goods mfr' },
  { Id: 23415, Code: '8133', Name: 'Examiner, shell' },
  { Id: 23416, Code: '8133', Name: 'Examiner, shoe' },
  { Id: 23417, Code: '8133', Name: 'Examiner, spring' },
  { Id: 23418, Code: '8133', Name: 'Examiner, steel, steelworks' },
  { Id: 23419, Code: '8133', Name: 'Examiner, stem' },
  { Id: 23420, Code: '8133', Name: 'Examiner, stencil' },
  { Id: 23421, Code: '8133', Name: 'Examiner, textile mfr' },
  { Id: 23422, Code: '8133', Name: 'Examiner, thread' },
  { Id: 23423, Code: '8133', Name: 'Examiner, timber' },
  { Id: 23424, Code: '8133', Name: 'Examiner, tobacco mfr' },
  { Id: 23425, Code: '8133', Name: 'Examiner, tool, edge' },
  { Id: 23426, Code: '8133', Name: 'Examiner, toy mfr' },
  { Id: 23427, Code: '8133', Name: 'Examiner, tyre' },
  { Id: 23428, Code: '8133', Name: 'Examiner, vehicle' },
  { Id: 23429, Code: '8133', Name: 'Examiner, wallpaper mfr' },
  { Id: 23430, Code: '8133', Name: 'Examiner, wheel' },
  { Id: 23431, Code: '8133', Name: 'Examiner, wire' },
  { Id: 23432, Code: '8133', Name: 'Examiner, wood products mfr' },
  { Id: 23433, Code: '8133', Name: 'Examiner, yarn' },
  { Id: 23434, Code: '8133', Name: 'Filler-in, furniture mfr' },
  { Id: 23435, Code: '8133', Name: 'Finder, fault' },
  { Id: 23436, Code: '8133', Name: 'Finisher, cellulose' },
  { Id: 23437, Code: '8133', Name: 'Finisher, paint' },
  { Id: 23438, Code: '8133', Name: 'Finisher, pencil' },
  { Id: 23439, Code: '8133', Name: 'Finisher, surface, aircraft mfr' },
  { Id: 23440, Code: '8133', Name: 'Foreman, checking, engineering' },
  { Id: 23441, Code: '8133', Name: 'Foreman, inspection' },
  { Id: 23442, Code: '8133', Name: 'Foreman, quality, engineering' },
  { Id: 23443, Code: '8133', Name: 'Foreman, salle' },
  { Id: 23444, Code: '8133', Name: 'Foreman, test, motor' },
  { Id: 23445, Code: '8133', Name: 'Gauger, bullet' },
  { Id: 23446, Code: '8133', Name: 'Gauger, metal trades' },
  { Id: 23447, Code: '8133', Name: 'Hand, clock, ball bearing mfr' },
  { Id: 23448, Code: '8133', Name: 'Hand, test, metal trades' },
  { Id: 23449, Code: '8133', Name: 'Inflator, bed, air' },
  { Id: 23450, Code: '8133', Name: 'Inflator, cushion, air' },
  { Id: 23451, Code: '8133', Name: 'Inspector and packer, YARN' },
  { Id: 23452, Code: '8133', Name: 'Inspector of armaments, government' },
  { Id: 23453, Code: '8133', Name: 'Inspector of naval ordnance' },
  { Id: 23454, Code: '8133', Name: 'Inspector, abrasive paper, cloth mfr' },
  { Id: 23455, Code: '8133', Name: 'Inspector, aeronautical' },
  { Id: 23456, Code: '8133', Name: 'Inspector, AID, Board of Trade' },
  { Id: 23457, Code: '8133', Name: 'Inspector, aircraft' },
  { Id: 23458, Code: '8133', Name: 'Inspector, apparatus, photographic' },
  { Id: 23459, Code: '8133', Name: 'Inspector, area, AUTOMATIC MACHINES' },
  { Id: 23460, Code: '8133', Name: 'Inspector, armaments' },
  { Id: 23461, Code: '8133', Name: 'Inspector, asbestos goods mfr' },
  { Id: 23462, Code: '8133', Name: 'Inspector, asbestos-cement goods mfr' },
  { Id: 23463, Code: '8133', Name: 'Inspector, assembly' },
  { Id: 23464, Code: '8133', Name: 'Inspector, assurance, quality' },
  { Id: 23465, Code: '8133', Name: 'Inspector, bank-note' },
  { Id: 23466, Code: '8133', Name: 'Inspector, bar, rolling mill' },
  { Id: 23467, Code: '8133', Name: 'Inspector, battery' },
  { Id: 23468, Code: '8133', Name: 'Inspector, bench' },
  { Id: 23469, Code: '8133', Name: 'Inspector, billet, steelworks' },
  { Id: 23470, Code: '8133', Name: 'Inspector, boiler' },
  { Id: 23471, Code: '8133', Name: 'Inspector, broom, brush mfr' },
  { Id: 23472, Code: '8133', Name: 'Inspector, cabinet' },
  { Id: 23473, Code: '8133', Name: 'Inspector, cable, cable mfr' },
  { Id: 23474, Code: '8133', Name: 'Inspector, canvas goods mfr' },
  { Id: 23475, Code: '8133', Name: 'Inspector, car' },
  { Id: 23476, Code: '8133', Name: 'Inspector, carbon goods mfr' },
  { Id: 23477, Code: '8133', Name: 'Inspector, cardboard mfr' },
  { Id: 23478, Code: '8133', Name: 'Inspector, carpet' },
  { Id: 23479, Code: '8133', Name: 'Inspector, carpet, rug mfr' },
  { Id: 23480, Code: '8133', Name: 'Inspector, carriage and wagon' },
  { Id: 23481, Code: '8133', Name: 'Inspector, carriage, railways' },
  { Id: 23482, Code: '8133', Name: 'Inspector, cartridge mfr' },
  { Id: 23483, Code: '8133', Name: 'Inspector, cell, chemical mfr' },
  { Id: 23484, Code: '8133', Name: 'Inspector, ceramics mfr' },
  { Id: 23485, Code: '8133', Name: 'Inspector, chicken, food processing' },
  { Id: 23486, Code: '8133', Name: 'Inspector, chief, engineering' },
  { Id: 23487, Code: '8133', Name: 'Inspector, cloth' },
  { Id: 23488, Code: '8133', Name: 'Inspector, clothing' },
  { Id: 23489, Code: '8133', Name: 'Inspector, clothing mfr' },
  { Id: 23490, Code: '8133', Name: 'Inspector, coil' },
  { Id: 23491, Code: '8133', Name: 'Inspector, component, metal trades' },
  { Id: 23492, Code: '8133', Name: 'Inspector, components, metal trades' },
  { Id: 23493, Code: '8133', Name: 'Inspector, control, quality' },
  { Id: 23494, Code: '8133', Name: 'Inspector, core' },
  { Id: 23495, Code: '8133', Name: 'Inspector, crisp, potato' },
  { Id: 23496, Code: '8133', Name: 'Inspector, cylinder' },
  { Id: 23497, Code: '8133', Name: 'Inspector, diesel, railways' },
  { Id: 23498, Code: '8133', Name: 'Inspector, dyeing and cleaning' },
  { Id: 23499, Code: '8133', Name: 'Inspector, electrical' },
  { Id: 23500, Code: '8133', Name: 'Inspector, electrical goods mfr' },
  { Id: 23501, Code: '8133', Name: 'Inspector, electronics, components mfr' },
  { Id: 23502, Code: '8133', Name: 'Inspector, enamel' },
  { Id: 23503, Code: '8133', Name: 'Inspector, engine' },
  { Id: 23504, Code: '8133', Name: 'Inspector, engineering' },
  { Id: 23505, Code: '8133', Name: 'Inspector, factory, clothing mfr' },
  { Id: 23506, Code: '8133', Name: 'Inspector, factory, metal trades' },
  { Id: 23507, Code: '8133', Name: 'Inspector, fancy goods mfr' },
  { Id: 23508, Code: '8133', Name: 'Inspector, film' },
  { Id: 23509, Code: '8133', Name: 'Inspector, flight' },
  { Id: 23510, Code: '8133', Name: 'Inspector, floor, engineering' },
  { Id: 23511, Code: '8133', Name: 'Inspector, food products mfr' },
  { Id: 23512, Code: '8133', Name: 'Inspector, food, food products mfr' },
  { Id: 23513, Code: '8133', Name: 'Inspector, footwear mfr' },
  { Id: 23514, Code: '8133', Name: 'Inspector, furnace, furnace mfr' },
  { Id: 23515, Code: '8133', Name: 'Inspector, furniture mfr' },
  { Id: 23516, Code: '8133', Name: 'Inspector, garment' },
  { Id: 23517, Code: '8133', Name: 'Inspector, gauge' },
  { Id: 23518, Code: '8133', Name: 'Inspector, glass' },
  { Id: 23519, Code: '8133', Name: 'Inspector, glass mfr' },
  { Id: 23520, Code: '8133', Name: 'Inspector, government, SMALL ARMS' },
  { Id: 23521, Code: '8133', Name: 'Inspector, graphite' },
  { Id: 23522, Code: '8133', Name: 'Inspector, hosiery' },
  { Id: 23523, Code: '8133', Name: 'Inspector, instrument' },
  { Id: 23524, Code: '8133', Name: 'Inspector, instrument mfr' },
  { Id: 23525, Code: '8133', Name: 'Inspector, insulation' },
  { Id: 23526, Code: '8133', Name: 'Inspector, lamp' },
  { Id: 23527, Code: '8133', Name: 'Inspector, layout' },
  { Id: 23528, Code: '8133', Name: 'Inspector, leaf' },
  { Id: 23529, Code: '8133', Name: 'Inspector, leathercloth mfr' },
  { Id: 23530, Code: '8133', Name: 'Inspector, lens' },
  { Id: 23531, Code: '8133', Name: 'Inspector, lift' },
  { Id: 23532, Code: '8133', Name: 'Inspector, line, vehicle mfr' },
  { Id: 23533, Code: '8133', Name: 'Inspector, lining, brake linings mfr' },
  { Id: 23534, Code: '8133', Name: 'Inspector, locomotive, railways' },
  { Id: 23535, Code: '8133', Name: 'Inspector, machine' },
  {
    Id: 23536,
    Code: '8133',
    Name: 'Inspector, machine, coal mine, WEIGHING MACHINES'
  },
  { Id: 23537, Code: '8133', Name: 'Inspector, machine, WEIGHING MACHINES' },
  { Id: 23538, Code: '8133', Name: 'Inspector, machinery' },
  { Id: 23539, Code: '8133', Name: 'Inspector, man-made fibre mfr' },
  { Id: 23540, Code: '8133', Name: 'Inspector, match mfr' },
  { Id: 23541, Code: '8133', Name: 'Inspector, mechanical' },
  { Id: 23542, Code: '8133', Name: 'Inspector, metal' },
  { Id: 23543, Code: '8133', Name: 'Inspector, metal trades' },
  { Id: 23544, Code: '8133', Name: 'Inspector, mica, micanite goods mfr' },
  { Id: 23545, Code: '8133', Name: 'Inspector, micrometer' },
  { Id: 23546, Code: '8133', Name: 'Inspector, mirror' },
  { Id: 23547, Code: '8133', Name: 'Inspector, motor' },
  { Id: 23548, Code: '8133', Name: 'Inspector, mould, glass mfr' },
  { Id: 23549, Code: '8133', Name: 'Inspector, nylon' },
  { Id: 23550, Code: '8133', Name: 'Inspector, optical' },
  { Id: 23551, Code: '8133', Name: 'Inspector, paint, engineering' },
  { Id: 23552, Code: '8133', Name: 'Inspector, paper goods mfr' },
  { Id: 23553, Code: '8133', Name: 'Inspector, paper mfr' },
  { Id: 23554, Code: '8133', Name: 'Inspector, patrol, metal trades' },
  { Id: 23555, Code: '8133', Name: 'Inspector, PCB' },
  { Id: 23556, Code: '8133', Name: 'Inspector, plant, electrical' },
  { Id: 23557, Code: '8133', Name: 'Inspector, plant, gas' },
  { Id: 23558, Code: '8133', Name: 'Inspector, plant, mechanical' },
  { Id: 23559, Code: '8133', Name: 'Inspector, plant, preparation, coal mine' },
  { Id: 23560, Code: '8133', Name: 'Inspector, plant, process' },
  { Id: 23561, Code: '8133', Name: 'Inspector, plasterboard mfr' },
  { Id: 23562, Code: '8133', Name: 'Inspector, plastics' },
  { Id: 23563, Code: '8133', Name: 'Inspector, plastics goods mfr' },
  { Id: 23564, Code: '8133', Name: 'Inspector, plate, steelworks' },
  { Id: 23565, Code: '8133', Name: 'Inspector, printing' },
  { Id: 23566, Code: '8133', Name: 'Inspector, process' },
  { Id: 23567, Code: '8133', Name: 'Inspector, production' },
  { Id: 23568, Code: '8133', Name: 'Inspector, QA' },
  { Id: 23569, Code: '8133', Name: 'Inspector, quality' },
  { Id: 23570, Code: '8133', Name: 'Inspector, radio' },
  { Id: 23571, Code: '8133', Name: 'Inspector, rail' },
  { Id: 23572, Code: '8133', Name: 'Inspector, records, musical' },
  { Id: 23573, Code: '8133', Name: 'Inspector, relay' },
  { Id: 23574, Code: '8133', Name: 'Inspector, ring, engineering' },
  { Id: 23575, Code: '8133', Name: 'Inspector, roller, metal trades' },
  { Id: 23576, Code: '8133', Name: 'Inspector, room, tool' },
  { Id: 23577, Code: '8133', Name: 'Inspector, rope' },
  { Id: 23578, Code: '8133', Name: 'Inspector, rubber' },
  { Id: 23579, Code: '8133', Name: 'Inspector, rubber goods mfr' },
  { Id: 23580, Code: '8133', Name: 'Inspector, sanitary, ceramics mfr' },
  { Id: 23581, Code: '8133', Name: 'Inspector, shell' },
  { Id: 23582, Code: '8133', Name: 'Inspector, shop, fitting' },
  { Id: 23583, Code: '8133', Name: 'Inspector, shop, machine' },
  { Id: 23584, Code: '8133', Name: 'Inspector, stock, rolling' },
  { Id: 23585, Code: '8133', Name: 'Inspector, stone dressing' },
  { Id: 23586, Code: '8133', Name: 'Inspector, surgical goods mfr' },
  { Id: 23587, Code: '8133', Name: 'Inspector, system' },
  { Id: 23588, Code: '8133', Name: 'Inspector, test' },
  { Id: 23589, Code: '8133', Name: 'Inspector, textile' },
  { Id: 23590, Code: '8133', Name: 'Inspector, textile mfr' },
  { Id: 23591, Code: '8133', Name: 'Inspector, timber' },
  { Id: 23592, Code: '8133', Name: 'Inspector, tool' },
  { Id: 23593, Code: '8133', Name: 'Inspector, trimming, coach' },
  { Id: 23594, Code: '8133', Name: 'Inspector, tube' },
  { Id: 23595, Code: '8133', Name: 'Inspector, tyre' },
  { Id: 23596, Code: '8133', Name: 'Inspector, ultrasonic' },
  { Id: 23597, Code: '8133', Name: 'Inspector, upholstery' },
  { Id: 23598, Code: '8133', Name: 'Inspector, vehicle' },
  { Id: 23599, Code: '8133', Name: 'Inspector, veneer' },
  { Id: 23600, Code: '8133', Name: 'Inspector, wagon' },
  { Id: 23601, Code: '8133', Name: 'Inspector, welding' },
  { Id: 23602, Code: '8133', Name: 'Inspector, wood products mfr' },
  { Id: 23603, Code: '8133', Name: 'Inspector, works, vehicle mfr' },
  { Id: 23604, Code: '8133', Name: 'Inspector, workshops, coal mine' },
  { Id: 23605, Code: '8133', Name: 'Inspector, yarn' },
  { Id: 23606, Code: '8133', Name: 'Liner and finisher, vehicle mfr' },
  { Id: 23607, Code: '8133', Name: 'Looker, cloth' },
  { Id: 23608, Code: '8133', Name: 'Looker, piece' },
  { Id: 23609, Code: '8133', Name: 'Looker, yarn' },
  { Id: 23610, Code: '8133', Name: 'Looker-out, pen nib mfr' },
  { Id: 23611, Code: '8133', Name: 'Looker-over, ceramics mfr' },
  { Id: 23612, Code: '8133', Name: "Looker-over, decorator's" },
  { Id: 23613, Code: '8133', Name: 'Looker-over, textile finishing' },
  { Id: 23614, Code: '8133', Name: 'Machinist, testing, metal trades' },
  { Id: 23615, Code: '8133', Name: 'Maker-up, paper mfr' },
  { Id: 23616, Code: '8133', Name: 'Man, cloth, clothing mfr' },
  { Id: 23617, Code: '8133', Name: 'Measurer, rolling mill' },
  { Id: 23618, Code: '8133', Name: 'Measurer, steel' },
  { Id: 23619, Code: '8133', Name: 'Officer, control, quality' },
  { Id: 23620, Code: '8133', Name: 'Operator, assurance, quality' },
  { Id: 23621, Code: '8133', Name: 'Operator, detector, engineering' },
  { Id: 23622, Code: '8133', Name: 'Operator, gauger, CARTRIDGES' },
  { Id: 23623, Code: '8133', Name: 'Operator, lamp, electric lamp mfr' },
  { Id: 23624, Code: '8133', Name: 'Operator, photometer' },
  { Id: 23625, Code: '8133', Name: 'Operator, quality' },
  { Id: 23626, Code: '8133', Name: 'Operator, ultrasonic' },
  { Id: 23627, Code: '8133', Name: 'Overlooker, cloth' },
  { Id: 23628, Code: '8133', Name: 'Overlooker, clothing mfr' },
  { Id: 23629, Code: '8133', Name: 'Overlooker, greenhouse' },
  { Id: 23630, Code: '8133', Name: 'Overlooker, hat mfr' },
  { Id: 23631, Code: '8133', Name: 'Overlooker, lace examining' },
  { Id: 23632, Code: '8133', Name: 'Passer, broom, brush mfr' },
  { Id: 23633, Code: '8133', Name: 'Passer, canned foods mfr' },
  { Id: 23634, Code: '8133', Name: 'Passer, cardboard box mfr' },
  { Id: 23635, Code: '8133', Name: 'Passer, cigar' },
  { Id: 23636, Code: '8133', Name: 'Passer, cloth' },
  { Id: 23637, Code: '8133', Name: 'Passer, clothing mfr' },
  { Id: 23638, Code: '8133', Name: 'Passer, final, tailoring' },
  { Id: 23639, Code: '8133', Name: 'Passer, finished, textile mfr' },
  { Id: 23640, Code: '8133', Name: 'Passer, footwear mfr' },
  { Id: 23641, Code: '8133', Name: 'Passer, fur goods mfr' },
  { Id: 23642, Code: '8133', Name: 'Passer, garment' },
  { Id: 23643, Code: '8133', Name: 'Passer, glove mfr' },
  { Id: 23644, Code: '8133', Name: 'Passer, glove, finished' },
  { Id: 23645, Code: '8133', Name: 'Passer, machine, clothing mfr' },
  { Id: 23646, Code: '8133', Name: 'Passer, metal trades' },
  { Id: 23647, Code: '8133', Name: 'Passer, piece' },
  { Id: 23648, Code: '8133', Name: 'Passer, textile mfr' },
  { Id: 23649, Code: '8133', Name: 'Patrol, motor vehicle mfr' },
  { Id: 23650, Code: '8133', Name: 'Patrolman, motor vehicle mfr' },
  { Id: 23651, Code: '8133', Name: 'Percher, textile mfr' },
  { Id: 23652, Code: '8133', Name: 'Picker, confectionery' },
  { Id: 23653, Code: '8133', Name: 'Picker, nut' },
  { Id: 23654, Code: '8133', Name: 'Picker, paper mfr' },
  { Id: 23655, Code: '8133', Name: 'Picker, stilt' },
  { Id: 23656, Code: '8133', Name: 'Picker, thimble' },
  { Id: 23657, Code: '8133', Name: 'Picker-out, galvanised sheet mfr' },
  { Id: 23658, Code: '8133', Name: 'Prover and tester, metal trades' },
  { Id: 23659, Code: '8133', Name: 'Prover, die' },
  { Id: 23660, Code: '8133', Name: 'Prover, file' },
  { Id: 23661, Code: '8133', Name: 'Prover, gun' },
  { Id: 23662, Code: '8133', Name: 'Prover, meter' },
  { Id: 23663, Code: '8133', Name: 'Prover, stove, gas supplier' },
  { Id: 23664, Code: '8133', Name: 'Prover, tube mfr' },
  { Id: 23665, Code: '8133', Name: 'Ransacker, fishing net mfr' },
  { Id: 23666, Code: '8133', Name: 'Sampler' },
  { Id: 23667, Code: '8133', Name: 'Sampler, grain, grain milling' },
  { Id: 23668, Code: '8133', Name: 'Sampler, milk' },
  { Id: 23669, Code: '8133', Name: 'Sampler, tea' },
  { Id: 23670, Code: '8133', Name: 'Searcher, manufacturing: woollen mfr' },
  { Id: 23671, Code: '8133', Name: 'Shooter, gun mfr' },
  { Id: 23672, Code: '8133', Name: 'Smeller, cask' },
  { Id: 23673, Code: '8133', Name: 'Sniffer' },
  { Id: 23674, Code: '8133', Name: 'Sorter, card, playing' },
  { Id: 23675, Code: '8133', Name: 'Sorter, glass' },
  { Id: 23676, Code: '8133', Name: 'Sorter, glass mfr' },
  { Id: 23677, Code: '8133', Name: 'Sorter, glove' },
  { Id: 23678, Code: '8133', Name: 'Sorter, label' },
  { Id: 23679, Code: '8133', Name: 'Sorter, note, paper goods mfr' },
  { Id: 23680, Code: '8133', Name: 'Sorter, paper goods mfr' },
  { Id: 23681, Code: '8133', Name: 'Sorter, paper mfr' },
  { Id: 23682, Code: '8133', Name: 'Sorter, paper, paper goods mfr' },
  { Id: 23683, Code: '8133', Name: 'Sorter, paper, paper mfr' },
  { Id: 23684, Code: '8133', Name: 'Sorter, paper, wallpaper mfr' },
  { Id: 23685, Code: '8133', Name: "Sorter, printer's" },
  { Id: 23686, Code: '8133', Name: 'Sorter, printing' },
  { Id: 23687, Code: '8133', Name: 'Sorter, sheet, printing' },
  { Id: 23688, Code: '8133', Name: 'Spotter, textile finishing' },
  { Id: 23689, Code: '8133', Name: 'Straightener, textile mfr' },
  { Id: 23690, Code: '8133', Name: 'Straightener, yarn' },
  { Id: 23691, Code: '8133', Name: 'Tapper, railways' },
  { Id: 23692, Code: '8133', Name: 'Tapper, wheel, railways' },
  { Id: 23693, Code: '8133', Name: 'Tester, abrasive wheel mfr' },
  { Id: 23694, Code: '8133', Name: 'Tester, aircraft' },
  { Id: 23695, Code: '8133', Name: 'Tester, asbestos-cement goods mfr' },
  { Id: 23696, Code: '8133', Name: 'Tester, balloon' },
  { Id: 23697, Code: '8133', Name: 'Tester, bench, MOTORS, MOTOR CYCLES' },
  { Id: 23698, Code: '8133', Name: 'Tester, bobbin' },
  { Id: 23699, Code: '8133', Name: 'Tester, boiler' },
  { Id: 23700, Code: '8133', Name: 'Tester, brake' },
  { Id: 23701, Code: '8133', Name: 'Tester, cable' },
  { Id: 23702, Code: '8133', Name: 'Tester, can' },
  { Id: 23703, Code: '8133', Name: 'Tester, carburettor' },
  { Id: 23704, Code: '8133', Name: 'Tester, cask' },
  { Id: 23705, Code: '8133', Name: 'Tester, cast concrete products mfr' },
  { Id: 23706, Code: '8133', Name: 'Tester, cell, dry battery mfr' },
  { Id: 23707, Code: '8133', Name: 'Tester, cement' },
  { Id: 23708, Code: '8133', Name: 'Tester, ceramics mfr' },
  { Id: 23709, Code: '8133', Name: 'Tester, cloth, textile merchants' },
  { Id: 23710, Code: '8133', Name: 'Tester, coil' },
  { Id: 23711, Code: '8133', Name: 'Tester, coke' },
  { Id: 23712, Code: '8133', Name: 'Tester, coke, coal gas, coke ovens' },
  { Id: 23713, Code: '8133', Name: 'Tester, conditioning, air' },
  { Id: 23714, Code: '8133', Name: 'Tester, cylinder' },
  { Id: 23715, Code: '8133', Name: 'Tester, denier' },
  { Id: 23716, Code: '8133', Name: 'Tester, disc, compact' },
  { Id: 23717, Code: '8133', Name: 'Tester, dye, textile bleaching, dyeing' },
  { Id: 23718, Code: '8133', Name: 'Tester, dynamo' },
  { Id: 23719, Code: '8133', Name: 'Tester, egg' },
  { Id: 23720, Code: '8133', Name: 'Tester, electrical' },
  {
    Id: 23721,
    Code: '8133',
    Name: 'Tester, electrical, electronic equipment mfr'
  },
  { Id: 23722, Code: '8133', Name: 'Tester, engine' },
  { Id: 23723, Code: '8133', Name: 'Tester, food canning' },
  { Id: 23724, Code: '8133', Name: 'Tester, furnace, furnace mfr' },
  { Id: 23725, Code: '8133', Name: 'Tester, gear, engineering' },
  { Id: 23726, Code: '8133', Name: 'Tester, glass' },
  { Id: 23727, Code: '8133', Name: 'Tester, glass mfr' },
  { Id: 23728, Code: '8133', Name: 'Tester, hollow-ware' },
  { Id: 23729, Code: '8133', Name: 'Tester, instrument' },
  { Id: 23730, Code: '8133', Name: 'Tester, insulation' },
  { Id: 23731, Code: '8133', Name: 'Tester, lamp, arc' },
  { Id: 23732, Code: '8133', Name: 'Tester, lens mfr' },
  { Id: 23733, Code: '8133', Name: 'Tester, machine' },
  { Id: 23734, Code: '8133', Name: 'Tester, match mfr' },
  { Id: 23735, Code: '8133', Name: 'Tester, matrix, type foundry' },
  { Id: 23736, Code: '8133', Name: 'Tester, metal trades' },
  { Id: 23737, Code: '8133', Name: 'Tester, meter' },
  { Id: 23738, Code: '8133', Name: 'Tester, milk' },
  { Id: 23739, Code: '8133', Name: 'Tester, moisture' },
  { Id: 23740, Code: '8133', Name: 'Tester, motor' },
  { Id: 23741, Code: '8133', Name: 'Tester, music' },
  { Id: 23742, Code: '8133', Name: 'Tester, ndt' },
  { Id: 23743, Code: '8133', Name: 'Tester, non-destructive' },
  { Id: 23744, Code: '8133', Name: 'Tester, paint mfr' },
  { Id: 23745, Code: '8133', Name: 'Tester, paper' },
  { Id: 23746, Code: '8133', Name: 'Tester, pipe, concrete' },
  { Id: 23747, Code: '8133', Name: 'Tester, pipe, metal mfr' },
  { Id: 23748, Code: '8133', Name: 'Tester, plastics goods mfr' },
  { Id: 23749, Code: '8133', Name: 'Tester, pole, TELEPHONE' },
  { Id: 23750, Code: '8133', Name: 'Tester, pump' },
  { Id: 23751, Code: '8133', Name: 'Tester, quality' },
  { Id: 23752, Code: '8133', Name: 'Tester, record' },
  { Id: 23753, Code: '8133', Name: 'Tester, record mfr' },
  { Id: 23754, Code: '8133', Name: 'Tester, road, vehicle mfr' },
  { Id: 23755, Code: '8133', Name: 'Tester, roller' },
  { Id: 23756, Code: '8133', Name: 'Tester, rope' },
  { Id: 23757, Code: '8133', Name: 'Tester, rubber goods mfr' },
  { Id: 23758, Code: '8133', Name: 'Tester, safety fuse mfr' },
  { Id: 23759, Code: '8133', Name: 'Tester, shift, chemical mfr' },
  { Id: 23760, Code: '8133', Name: 'Tester, silk, man-made fibre mfr' },
  { Id: 23761, Code: '8133', Name: 'Tester, slow, bobbin' },
  { Id: 23762, Code: '8133', Name: 'Tester, spring' },
  { Id: 23763, Code: '8133', Name: 'Tester, stove' },
  { Id: 23764, Code: '8133', Name: 'Tester, systems, ELECTRONIC' },
  { Id: 23765, Code: '8133', Name: 'Tester, tank' },
  { Id: 23766, Code: '8133', Name: 'Tester, tractor' },
  { Id: 23767, Code: '8133', Name: 'Tester, tube, ray, cathode' },
  { Id: 23768, Code: '8133', Name: 'Tester, valve' },
  { Id: 23769, Code: '8133', Name: 'Tester, vehicle' },
  { Id: 23770, Code: '8133', Name: 'Tester, wire' },
  { Id: 23771, Code: '8133', Name: 'Tester, yarn' },
  { Id: 23772, Code: '8133', Name: 'Verifier, rubber tyre mfr' },
  { Id: 23773, Code: '8133', Name: 'Viewer of bullets' },
  { Id: 23774, Code: '8133', Name: 'Viewer, ammunition' },
  { Id: 23775, Code: '8133', Name: 'Viewer, barrel, GUN' },
  { Id: 23776, Code: '8133', Name: 'Viewer, bearings, ball' },
  { Id: 23777, Code: '8133', Name: 'Viewer, cartridge mfr' },
  { Id: 23778, Code: '8133', Name: 'Viewer, chocolate mfr' },
  { Id: 23779, Code: '8133', Name: 'Viewer, cloth' },
  { Id: 23780, Code: '8133', Name: 'Viewer, clothing mfr' },
  { Id: 23781, Code: '8133', Name: 'Viewer, component, metal trades' },
  { Id: 23782, Code: '8133', Name: 'Viewer, cycle' },
  { Id: 23783, Code: '8133', Name: 'Viewer, electrical goods mfr' },
  { Id: 23784, Code: '8133', Name: 'Viewer, film, television production' },
  { Id: 23785, Code: '8133', Name: 'Viewer, food products mfr' },
  { Id: 23786, Code: '8133', Name: 'Viewer, garment' },
  { Id: 23787, Code: '8133', Name: 'Viewer, glass' },
  { Id: 23788, Code: '8133', Name: 'Viewer, hat mfr' },
  { Id: 23789, Code: '8133', Name: 'Viewer, metal trades' },
  { Id: 23790, Code: '8133', Name: 'Viewer, patrol, metal trades' },
  { Id: 23791, Code: '8133', Name: 'Viewer, plastics' },
  { Id: 23792, Code: '8133', Name: 'Viewer, plastics goods mfr' },
  { Id: 23793, Code: '8133', Name: 'Viewer, rubber goods mfr' },
  { Id: 23794, Code: '8133', Name: 'Viewer, sports goods mfr' },
  { Id: 23795, Code: '8133', Name: 'Viewer, textile mfr' },
  { Id: 23796, Code: '8133', Name: 'Viewer, wood products mfr' },
  { Id: 23797, Code: '8133', Name: 'Worker, inspection' },
  { Id: 23798, Code: '8133', Name: 'Worker, salle' },
  { Id: 23799, Code: '8134', Name: 'Analyst, sensory, food products mfr' },
  { Id: 23800, Code: '8134', Name: "Assistant, bundler's, METAL" },
  { Id: 23801, Code: '8134', Name: "Assistant, taster's, tea" },
  { Id: 23802, Code: '8134', Name: 'Attendant, bridge, weigh' },
  { Id: 23803, Code: '8134', Name: 'Attendant, cabin, weigh' },
  { Id: 23804, Code: '8134', Name: 'Attendant, scale' },
  { Id: 23805, Code: '8134', Name: 'Attendant, weighbridge' },
  { Id: 23806, Code: '8134', Name: 'Banksman, coal mine: opencast' },
  { Id: 23807, Code: '8134', Name: 'Bridgeman, weigh' },
  { Id: 23808, Code: '8134', Name: 'Bundler, broom, brush mfr' },
  { Id: 23809, Code: '8134', Name: 'Bundler, metal trades' },
  { Id: 23810, Code: '8134', Name: 'Bundler, scrap' },
  { Id: 23811, Code: '8134', Name: 'Bundler, sheet, METAL' },
  { Id: 23812, Code: '8134', Name: 'Checker, dipper' },
  { Id: 23813, Code: '8134', Name: 'Checkweigher' },
  { Id: 23814, Code: '8134', Name: 'Checkweighman' },
  { Id: 23815, Code: '8134', Name: 'Classer' },
  { Id: 23816, Code: '8134', Name: 'Classifier' },
  { Id: 23817, Code: '8134', Name: 'Clerk of the scales' },
  { Id: 23818, Code: '8134', Name: 'Clerk, machine, weigh, coal mine' },
  { Id: 23819, Code: '8134', Name: 'Clerk, weigh' },
  { Id: 23820, Code: '8134', Name: 'Clerk, weighbridge' },
  { Id: 23821, Code: '8134', Name: 'Clerk, weighing' },
  { Id: 23822, Code: '8134', Name: 'Controller, weighbridge' },
  { Id: 23823, Code: '8134', Name: 'Counter, bank-note' },
  { Id: 23824, Code: '8134', Name: 'Counter, mine: not coal' },
  { Id: 23825, Code: '8134', Name: 'Despatcher, rail' },
  { Id: 23826, Code: '8134', Name: 'Despatcher, road' },
  { Id: 23827, Code: '8134', Name: 'Dipper, oil refining' },
  { Id: 23828, Code: '8134', Name: 'Dipper, tank, petroleum distribution' },
  { Id: 23829, Code: '8134', Name: 'Dispatcher, rail' },
  { Id: 23830, Code: '8134', Name: 'Dispatcher, road' },
  { Id: 23831, Code: '8134', Name: 'Dispenser, bakery' },
  { Id: 23832, Code: '8134', Name: 'Divider, type foundry' },
  { Id: 23833, Code: '8134', Name: 'Examiner, bottle, brewery' },
  { Id: 23834, Code: '8134', Name: 'Examiner, leather dressing' },
  { Id: 23835, Code: '8134', Name: 'Examiner, scrap, steelworks' },
  { Id: 23836, Code: '8134', Name: 'Examiner, tannery' },
  { Id: 23837, Code: '8134', Name: 'Fitter, stock, footwear mfr' },
  { Id: 23838, Code: '8134', Name: 'Gauger, cement' },
  { Id: 23839, Code: '8134', Name: 'Gauger, lamp, valve mfr' },
  { Id: 23840, Code: '8134', Name: 'Gauger, oil refining' },
  { Id: 23841, Code: '8134', Name: 'Gauger, tank, oil refining' },
  { Id: 23842, Code: '8134', Name: 'Gauger, tank, petroleum distribution' },
  { Id: 23843, Code: '8134', Name: 'Grader, abrasive paper, cloth mfr' },
  { Id: 23844, Code: '8134', Name: 'Grader, ceramics mfr' },
  { Id: 23845, Code: '8134', Name: 'Grader, egg' },
  { Id: 23846, Code: '8134', Name: 'Grader, fat' },
  { Id: 23847, Code: '8134', Name: 'Grader, food products mfr' },
  { Id: 23848, Code: '8134', Name: 'Grader, fruit' },
  { Id: 23849, Code: '8134', Name: 'Grader, glass mfr' },
  { Id: 23850, Code: '8134', Name: 'Grader, grain' },
  { Id: 23851, Code: '8134', Name: 'Grader, hosiery' },
  { Id: 23852, Code: '8134', Name: 'Grader, leather' },
  { Id: 23853, Code: '8134', Name: 'Grader, meat' },
  { Id: 23854, Code: '8134', Name: 'Grader, metal trades' },
  { Id: 23855, Code: '8134', Name: 'Grader, pelt, fellmongering' },
  { Id: 23856, Code: '8134', Name: 'Grader, plasterboard mfr' },
  { Id: 23857, Code: '8134', Name: 'Grader, poultry, retail trade' },
  { Id: 23858, Code: '8134', Name: 'Grader, rag' },
  { Id: 23859, Code: '8134', Name: 'Grader, sack' },
  { Id: 23860, Code: '8134', Name: 'Grader, skin' },
  { Id: 23861, Code: '8134', Name: 'Grader, sole' },
  { Id: 23862, Code: '8134', Name: 'Grader, textile mfr' },
  { Id: 23863, Code: '8134', Name: 'Grader, tobacco pipe mfr' },
  { Id: 23864, Code: '8134', Name: 'Grader, VEGETABLE' },
  { Id: 23865, Code: '8134', Name: 'Grader, wholesale fish trade' },
  { Id: 23866, Code: '8134', Name: 'Grader, wool' },
  { Id: 23867, Code: '8134', Name: 'Grader-packer' },
  { Id: 23868, Code: '8134', Name: 'Hand, hair, broom, brush mfr' },
  { Id: 23869, Code: '8134', Name: 'Inspector, leather dressing' },
  { Id: 23870, Code: '8134', Name: 'Inspector, ore' },
  { Id: 23871, Code: '8134', Name: 'Keeper, bridge, weigh' },
  { Id: 23872, Code: '8134', Name: 'Keeper, machine, weighing' },
  { Id: 23873, Code: '8134', Name: 'Machinist, measuring, PIECE GOODS' },
  { Id: 23874, Code: '8134', Name: 'Machinist, weighing' },
  { Id: 23875, Code: '8134', Name: 'Man, yard, coal mine' },
  { Id: 23876, Code: '8134', Name: 'Marker, galvanised sheet mfr' },
  { Id: 23877, Code: '8134', Name: 'Marker-off, tube mfr' },
  { Id: 23878, Code: '8134', Name: 'Marker-out, fustian, velvet mfr' },
  { Id: 23879, Code: '8134', Name: 'Measurer, braid' },
  { Id: 23880, Code: '8134', Name: 'Measurer, cable mfr' },
  { Id: 23881, Code: '8134', Name: 'Measurer, carpet, rug mfr' },
  { Id: 23882, Code: '8134', Name: 'Measurer, chemical mfr' },
  { Id: 23883, Code: '8134', Name: 'Measurer, cloth, textile mfr' },
  { Id: 23884, Code: '8134', Name: 'Measurer, leather dressing' },
  { Id: 23885, Code: '8134', Name: 'Measurer, piece' },
  { Id: 23886, Code: '8134', Name: 'Measurer, skin, food products mfr' },
  { Id: 23887, Code: '8134', Name: 'Measurer, tape mfr' },
  { Id: 23888, Code: '8134', Name: 'Measurer, timber' },
  { Id: 23889, Code: '8134', Name: 'Measurer, wood' },
  { Id: 23890, Code: '8134', Name: 'Operator, bridge, weigh' },
  { Id: 23891, Code: '8134', Name: 'Operator, weighbridge' },
  { Id: 23892, Code: '8134', Name: 'Packer, drum, furnace' },
  { Id: 23893, Code: '8134', Name: 'Packer-grader' },
  { Id: 23894, Code: '8134', Name: 'Pager, printing' },
  { Id: 23895, Code: '8134', Name: 'Picker, bowl' },
  { Id: 23896, Code: '8134', Name: 'Picker, building construction' },
  { Id: 23897, Code: '8134', Name: 'Picker, cotton' },
  { Id: 23898, Code: '8134', Name: 'Picker, flock' },
  { Id: 23899, Code: '8134', Name: 'Picker, lime' },
  { Id: 23900, Code: '8134', Name: 'Picker, pea, food processing' },
  { Id: 23901, Code: '8134', Name: 'Picker, prawn' },
  { Id: 23902, Code: '8134', Name: 'Picker, stone dressing' },
  { Id: 23903, Code: '8134', Name: 'Picker, stone, stone dressing' },
  { Id: 23904, Code: '8134', Name: 'Picker, textile mfr: flock mfr' },
  { Id: 23905, Code: '8134', Name: 'Picker, textile mfr: wool sorting' },
  { Id: 23906, Code: '8134', Name: 'Picker-packer, vehicle mfr' },
  {
    Id: 23907,
    Code: '8134',
    Name: 'Racker, laundry, launderette, dry cleaning'
  },
  { Id: 23908, Code: '8134', Name: 'Reckoner, tinplate mfr' },
  { Id: 23909, Code: '8134', Name: 'Scaleman' },
  { Id: 23910, Code: '8134', Name: 'Scaler, slaughterhouse' },
  { Id: 23911, Code: '8134', Name: 'Scalesman' },
  { Id: 23912, Code: '8134', Name: 'Selector and classifier, MICA' },
  { Id: 23913, Code: '8134', Name: 'Selector, biscuit, ceramics mfr' },
  { Id: 23914, Code: '8134', Name: 'Selector, canvas goods mfr' },
  { Id: 23915, Code: '8134', Name: 'Selector, ceramics mfr' },
  { Id: 23916, Code: '8134', Name: 'Selector, flax, hemp mfr' },
  { Id: 23917, Code: '8134', Name: 'Selector, mine: not coal' },
  { Id: 23918, Code: '8134', Name: 'Selector, plastics mfr' },
  { Id: 23919, Code: '8134', Name: 'Selector, sack' },
  { Id: 23920, Code: '8134', Name: 'Selector, skin' },
  { Id: 23921, Code: '8134', Name: 'Skipman' },
  { Id: 23922, Code: '8134', Name: 'Sorter and grader, canvas goods mfr' },
  { Id: 23923, Code: '8134', Name: 'Sorter and packer' },
  { Id: 23924, Code: '8134', Name: 'Sorter, biscuit' },
  { Id: 23925, Code: '8134', Name: 'Sorter, bobbin' },
  { Id: 23926, Code: '8134', Name: 'Sorter, bottle' },
  { Id: 23927, Code: '8134', Name: 'Sorter, breakage, food products mfr' },
  { Id: 23928, Code: '8134', Name: 'Sorter, broom, brush mfr' },
  { Id: 23929, Code: '8134', Name: 'Sorter, bulb, electric lamp mfr' },
  { Id: 23930, Code: '8134', Name: 'Sorter, button mfr' },
  { Id: 23931, Code: '8134', Name: 'Sorter, ceramics mfr' },
  { Id: 23932, Code: '8134', Name: 'Sorter, CHARITABLE ORGANISATION' },
  { Id: 23933, Code: '8134', Name: 'Sorter, cigar mfr' },
  { Id: 23934, Code: '8134', Name: 'Sorter, clip' },
  { Id: 23935, Code: '8134', Name: 'Sorter, cloth' },
  { Id: 23936, Code: '8134', Name: 'Sorter, clothing mfr' },
  { Id: 23937, Code: '8134', Name: 'Sorter, cork' },
  { Id: 23938, Code: '8134', Name: 'Sorter, cutlery handle mfr' },
  { Id: 23939, Code: '8134', Name: 'Sorter, diamond' },
  { Id: 23940, Code: '8134', Name: 'Sorter, dyehouse, textile mfr' },
  { Id: 23941, Code: '8134', Name: 'Sorter, dyeing and cleaning' },
  { Id: 23942, Code: '8134', Name: 'Sorter, egg' },
  { Id: 23943, Code: '8134', Name: 'Sorter, feather' },
  { Id: 23944, Code: '8134', Name: 'Sorter, fent' },
  { Id: 23945, Code: '8134', Name: 'Sorter, fibre' },
  { Id: 23946, Code: '8134', Name: 'Sorter, flock' },
  { Id: 23947, Code: '8134', Name: 'Sorter, foil, tin' },
  { Id: 23948, Code: '8134', Name: 'Sorter, food products mfr' },
  { Id: 23949, Code: '8134', Name: 'Sorter, footwear mfr' },
  { Id: 23950, Code: '8134', Name: 'Sorter, fruit' },
  { Id: 23951, Code: '8134', Name: 'Sorter, fur' },
  { Id: 23952, Code: '8134', Name: 'Sorter, fur dressing' },
  { Id: 23953, Code: '8134', Name: 'Sorter, gelatine' },
  { Id: 23954, Code: '8134', Name: 'Sorter, glass mfr: glass bottle mfr' },
  { Id: 23955, Code: '8134', Name: 'Sorter, gum' },
  { Id: 23956, Code: '8134', Name: 'Sorter, hair' },
  { Id: 23957, Code: '8134', Name: 'Sorter, head, galvanised sheet mfr' },
  { Id: 23958, Code: '8134', Name: 'Sorter, hide' },
  { Id: 23959, Code: '8134', Name: 'Sorter, hosiery' },
  { Id: 23960, Code: '8134', Name: 'Sorter, house, dye, textile mfr' },
  { Id: 23961, Code: '8134', Name: 'Sorter, incandescent mantle mfr' },
  { Id: 23962, Code: '8134', Name: 'Sorter, last' },
  {
    Id: 23963,
    Code: '8134',
    Name: 'Sorter, laundry, launderette, dry cleaning'
  },
  { Id: 23964, Code: '8134', Name: 'Sorter, leaf' },
  { Id: 23965, Code: '8134', Name: 'Sorter, leather' },
  { Id: 23966, Code: '8134', Name: 'Sorter, lime' },
  { Id: 23967, Code: '8134', Name: 'Sorter, linen, hospital service' },
  { Id: 23968, Code: '8134', Name: 'Sorter, machine, ceramics mfr' },
  { Id: 23969, Code: '8134', Name: 'Sorter, meat' },
  { Id: 23970, Code: '8134', Name: 'Sorter, metal' },
  { Id: 23971, Code: '8134', Name: 'Sorter, metal trades' },
  { Id: 23972, Code: '8134', Name: 'Sorter, mica' },
  { Id: 23973, Code: '8134', Name: 'Sorter, mine: not coal' },
  { Id: 23974, Code: '8134', Name: 'Sorter, mohair' },
  { Id: 23975, Code: '8134', Name: 'Sorter, newspaper' },
  { Id: 23976, Code: '8134', Name: 'Sorter, paper, waste paper merchants' },
  { Id: 23977, Code: '8134', Name: 'Sorter, pipe, ceramic' },
  { Id: 23978, Code: '8134', Name: 'Sorter, rag' },
  { Id: 23979, Code: '8134', Name: 'Sorter, rag and metal' },
  { Id: 23980, Code: '8134', Name: 'Sorter, refuse' },
  { Id: 23981, Code: '8134', Name: 'Sorter, rubber' },
  { Id: 23982, Code: '8134', Name: 'Sorter, sack' },
  { Id: 23983, Code: '8134', Name: 'Sorter, salvage' },
  { Id: 23984, Code: '8134', Name: 'Sorter, scrap' },
  { Id: 23985, Code: '8134', Name: 'Sorter, seed' },
  { Id: 23986, Code: '8134', Name: 'Sorter, seed merchants' },
  { Id: 23987, Code: '8134', Name: 'Sorter, shuttle' },
  { Id: 23988, Code: '8134', Name: 'Sorter, skin' },
  { Id: 23989, Code: '8134', Name: 'Sorter, slag' },
  { Id: 23990, Code: '8134', Name: 'Sorter, sole' },
  { Id: 23991, Code: '8134', Name: 'Sorter, spool' },
  { Id: 23992, Code: '8134', Name: 'Sorter, stick mfr' },
  { Id: 23993, Code: '8134', Name: 'Sorter, stocking' },
  { Id: 23994, Code: '8134', Name: 'Sorter, stores' },
  { Id: 23995, Code: '8134', Name: 'Sorter, tannery' },
  { Id: 23996, Code: '8134', Name: 'Sorter, tape' },
  { Id: 23997, Code: '8134', Name: 'Sorter, textile mfr' },
  { Id: 23998, Code: '8134', Name: 'Sorter, tile, roofing' },
  { Id: 23999, Code: '8134', Name: 'Sorter, timber' },
  { Id: 24000, Code: '8134', Name: 'Sorter, tin' },
  { Id: 24001, Code: '8134', Name: 'Sorter, warehouse, biscuit, ceramics mfr' },
  { Id: 24002, Code: '8134', Name: 'Sorter, waste' },
  { Id: 24003, Code: '8134', Name: 'Sorter, waste merchants' },
  { Id: 24004, Code: '8134', Name: 'Sorter, wholesale fish trade' },
  { Id: 24005, Code: '8134', Name: 'Sorter, wood' },
  { Id: 24006, Code: '8134', Name: 'Sorter, wool' },
  { Id: 24007, Code: '8134', Name: 'Sorter, woollen' },
  { Id: 24008, Code: '8134', Name: 'Sorter, yarn' },
  { Id: 24009, Code: '8134', Name: 'Stuffer, textile bleaching, dyeing' },
  { Id: 24010, Code: '8134', Name: 'Taker, weight' },
  { Id: 24011, Code: '8134', Name: 'Taker-off, textile mfr: wool sorting' },
  { Id: 24012, Code: '8134', Name: 'Taster, coffee' },
  { Id: 24013, Code: '8134', Name: 'Taster, food products mfr' },
  { Id: 24014, Code: '8134', Name: 'Taster, tea' },
  { Id: 24015, Code: '8134', Name: 'Taster, wine' },
  { Id: 24016, Code: '8134', Name: 'Teller, printing' },
  { Id: 24017, Code: '8134', Name: 'Washer, cullet' },
  { Id: 24018, Code: '8134', Name: 'Weighbridgeman' },
  { Id: 24019, Code: '8134', Name: 'Weigher' },
  { Id: 24020, Code: '8134', Name: 'Weigher and mixer, colour' },
  { Id: 24021, Code: '8134', Name: 'Weigherman, card, paste board mfr' },
  { Id: 24022, Code: '8134', Name: 'Weighman' },
  { Id: 24023, Code: '8134', Name: 'Yardman, coal mine' },
  { Id: 24024, Code: '8134', Name: 'Yardsman, coal mine' },
  { Id: 24025, Code: '8135', Name: 'Assistant, garage' },
  { Id: 24026, Code: '8135', Name: 'Fitter, exhaust, MOTOR VEHICLE REPAIR' },
  { Id: 24027, Code: '8135', Name: 'Fitter, exhaust, tyre fitting' },
  { Id: 24028, Code: '8135', Name: 'Fitter, exhaust, VEHICLES' },
  { Id: 24029, Code: '8135', Name: 'Fitter, glass, vehicle mfr' },
  { Id: 24030, Code: '8135', Name: 'Fitter, maintenance, tyre' },
  { Id: 24031, Code: '8135', Name: 'Fitter, screen, vehicle mfr' },
  { Id: 24032, Code: '8135', Name: 'Fitter, towbar' },
  { Id: 24033, Code: '8135', Name: 'Fitter, tyre' },
  { Id: 24034, Code: '8135', Name: 'Fitter, tyre and exhaust' },
  { Id: 24035, Code: '8135', Name: 'Fitter, windscreen' },
  { Id: 24036, Code: '8135', Name: 'Fitter, windscreen fitting' },
  { Id: 24037, Code: '8135', Name: 'Fixer, window, VEHICLES' },
  { Id: 24038, Code: '8135', Name: 'Glazier, automotive' },
  { Id: 24039, Code: '8135', Name: 'Glazier, vehicle' },
  { Id: 24040, Code: '8135', Name: 'Hand, garage' },
  { Id: 24041, Code: '8135', Name: 'Technician, fit, fast' },
  { Id: 24042, Code: '8135', Name: 'Technician, glazing, MOTOR VEHICLES' },
  { Id: 24043, Code: '8135', Name: 'Technician, tyre' },
  { Id: 24044, Code: '8135', Name: 'Worker, garage, PO' },
  { Id: 24045, Code: '8135', Name: 'Worker, tyre, garage' },
  { Id: 24046, Code: '8137', Name: 'Assistant, room, sewing' },
  { Id: 24047, Code: '8137', Name: "Assistant, upholsterer's" },
  { Id: 24048, Code: '8137', Name: 'Backer, clothing mfr' },
  { Id: 24049, Code: '8137', Name: 'Bagger-out' },
  { Id: 24050, Code: '8137', Name: 'Beader, embroidering' },
  { Id: 24051, Code: '8137', Name: 'Binder, blanket' },
  { Id: 24052, Code: '8137', Name: 'Binder, blanket mfr' },
  { Id: 24053, Code: '8137', Name: 'Binder, canvas goods mfr' },
  { Id: 24054, Code: '8137', Name: 'Binder, carpet' },
  { Id: 24055, Code: '8137', Name: 'Binder, fabric glove mfr' },
  { Id: 24056, Code: '8137', Name: 'Binder, hat mfr' },
  { Id: 24057, Code: '8137', Name: 'Binder, hosiery, knitwear mfr' },
  { Id: 24058, Code: '8137', Name: 'Binder, leather, hat' },
  { Id: 24059, Code: '8137', Name: 'Braider, clothing mfr' },
  { Id: 24060, Code: '8137', Name: 'Burler' },
  { Id: 24061, Code: '8137', Name: 'Burler and mender' },
  { Id: 24062, Code: '8137', Name: 'Canvasser, clothing mfr' },
  { Id: 24063, Code: '8137', Name: 'Clocker' },
  { Id: 24064, Code: '8137', Name: 'Closer, clothing mfr' },
  { Id: 24065, Code: '8137', Name: 'Coverer, umbrella' },
  { Id: 24066, Code: '8137', Name: 'Drawer, fine' },
  { Id: 24067, Code: '8137', Name: 'Elasticator' },
  { Id: 24068, Code: '8137', Name: 'Examiner and finisher, NET' },
  { Id: 24069, Code: '8137', Name: 'Examiner and mender, HOSIERY' },
  { Id: 24070, Code: '8137', Name: 'Examiner, heald' },
  { Id: 24071, Code: '8137', Name: 'Fanner, corset mfr' },
  { Id: 24072, Code: '8137', Name: 'Feller, clothing mfr' },
  { Id: 24073, Code: '8137', Name: 'Finisher and liner, fur garment mfr' },
  { Id: 24074, Code: '8137', Name: 'Finisher, cap' },
  { Id: 24075, Code: '8137', Name: 'Finisher, clothing mfr' },
  { Id: 24076, Code: '8137', Name: 'Finisher, coat' },
  { Id: 24077, Code: '8137', Name: 'Finisher, collar' },
  { Id: 24078, Code: '8137', Name: 'Finisher, curtain' },
  { Id: 24079, Code: '8137', Name: 'Finisher, dress' },
  { Id: 24080, Code: '8137', Name: 'Finisher, embroidery mfr' },
  { Id: 24081, Code: '8137', Name: 'Finisher, fur goods mfr' },
  { Id: 24082, Code: '8137', Name: 'Finisher, glove' },
  { Id: 24083, Code: '8137', Name: 'Finisher, gown' },
  { Id: 24084, Code: '8137', Name: 'Finisher, hosiery garment mfr' },
  { Id: 24085, Code: '8137', Name: 'Finisher, kilt' },
  { Id: 24086, Code: '8137', Name: 'Finisher, knitwear mfr' },
  { Id: 24087, Code: '8137', Name: 'Finisher, shirt' },
  { Id: 24088, Code: '8137', Name: 'Finisher, soft furnishings mfr' },
  { Id: 24089, Code: '8137', Name: 'Finisher, soft toy mfr' },
  { Id: 24090, Code: '8137', Name: "Finisher, tailor's" },
  { Id: 24091, Code: '8137', Name: 'Finisher, trouser' },
  { Id: 24092, Code: '8137', Name: 'Finisher, umbrella' },
  { Id: 24093, Code: '8137', Name: 'Finisher, umbrella, parasol mfr' },
  { Id: 24094, Code: '8137', Name: 'Foreman, room, machine, clothing mfr' },
  { Id: 24095, Code: '8137', Name: 'Foreman, room, sewing' },
  { Id: 24096, Code: '8137', Name: 'Friller' },
  { Id: 24097, Code: '8137', Name: 'Glover, glove mfr' },
  { Id: 24098, Code: '8137', Name: 'Hand, buttonhole' },
  { Id: 24099, Code: '8137', Name: 'Hand, collar, clothing mfr' },
  { Id: 24100, Code: '8137', Name: 'Hand, feller' },
  { Id: 24101, Code: '8137', Name: 'Hand, felling' },
  { Id: 24102, Code: '8137', Name: 'Hand, needlework' },
  { Id: 24103, Code: '8137', Name: 'Hand, palm and needle' },
  { Id: 24104, Code: '8137', Name: 'Hand, sample, clothing mfr' },
  { Id: 24105, Code: '8137', Name: 'Hand, sewing' },
  { Id: 24106, Code: '8137', Name: 'Hand, suspender' },
  { Id: 24107, Code: '8137', Name: 'Hemmer' },
  { Id: 24108, Code: '8137', Name: 'Hemstitcher' },
  { Id: 24109, Code: '8137', Name: 'Holer, button' },
  { Id: 24110, Code: '8137', Name: 'Knitter, power, textile mfr' },
  { Id: 24111, Code: '8137', Name: 'Knotter, textile mfr: examining dept' },
  { Id: 24112, Code: '8137', Name: 'Liner, clothing mfr' },
  { Id: 24113, Code: '8137', Name: 'Liner, fur garment mfr' },
  { Id: 24114, Code: '8137', Name: 'Liner, hat mfr' },
  {
    Id: 24115,
    Code: '8137',
    Name: 'Linker, textile mfr: hosiery, knitwear mfr'
  },
  { Id: 24116, Code: '8137', Name: 'Locker, flat' },
  { Id: 24117, Code: '8137', Name: 'Looper, hosiery, knitwear mfr' },
  { Id: 24118, Code: '8137', Name: 'Machinist, automatic, SEWING' },
  { Id: 24119, Code: '8137', Name: 'Machinist, bag, sack mfr' },
  { Id: 24120, Code: '8137', Name: 'Machinist, barring, clothing mfr' },
  { Id: 24121, Code: '8137', Name: 'Machinist, bartack' },
  { Id: 24122, Code: '8137', Name: 'Machinist, basting' },
  { Id: 24123, Code: '8137', Name: 'Machinist, bedding' },
  { Id: 24124, Code: '8137', Name: 'Machinist, belt, conveyor' },
  { Id: 24125, Code: '8137', Name: 'Machinist, binding' },
  { Id: 24126, Code: '8137', Name: 'Machinist, blanket' },
  { Id: 24127, Code: '8137', Name: 'Machinist, blouse' },
  { Id: 24128, Code: '8137', Name: 'Machinist, button, clothing mfr' },
  { Id: 24129, Code: '8137', Name: 'Machinist, buttonhole' },
  { Id: 24130, Code: '8137', Name: 'Machinist, buttoning, clothing mfr' },
  { Id: 24131, Code: '8137', Name: 'Machinist, canvas' },
  { Id: 24132, Code: '8137', Name: 'Machinist, canvas goods mfr' },
  { Id: 24133, Code: '8137', Name: 'Machinist, cap' },
  { Id: 24134, Code: '8137', Name: 'Machinist, carpet' },
  { Id: 24135, Code: '8137', Name: 'Machinist, clothing' },
  { Id: 24136, Code: '8137', Name: 'Machinist, clothing mfr' },
  { Id: 24137, Code: '8137', Name: 'Machinist, coat' },
  { Id: 24138, Code: '8137', Name: 'Machinist, collar, clothing mfr' },
  { Id: 24139, Code: '8137', Name: 'Machinist, cornelly' },
  { Id: 24140, Code: '8137', Name: 'Machinist, cornely' },
  { Id: 24141, Code: '8137', Name: 'Machinist, corset' },
  { Id: 24142, Code: '8137', Name: 'Machinist, darning, textile mfr' },
  { Id: 24143, Code: '8137', Name: 'Machinist, dress' },
  { Id: 24144, Code: '8137', Name: 'Machinist, dressing, surgical' },
  { Id: 24145, Code: '8137', Name: "Machinist, dressmaker's" },
  { Id: 24146, Code: '8137', Name: 'Machinist, elasticator' },
  { Id: 24147, Code: '8137', Name: 'Machinist, electric blanket mfr' },
  { Id: 24148, Code: '8137', Name: 'Machinist, embroidery' },
  { Id: 24149, Code: '8137', Name: 'Machinist, fabric, circular' },
  { Id: 24150, Code: '8137', Name: 'Machinist, fancy' },
  { Id: 24151, Code: '8137', Name: 'Machinist, feather' },
  { Id: 24152, Code: '8137', Name: 'Machinist, felling' },
  { Id: 24153, Code: '8137', Name: 'Machinist, flag' },
  { Id: 24154, Code: '8137', Name: 'Machinist, flat, clothing mfr' },
  { Id: 24155, Code: '8137', Name: 'Machinist, flat, hand' },
  { Id: 24156, Code: '8137', Name: 'Machinist, foot' },
  { Id: 24157, Code: '8137', Name: 'Machinist, fur' },
  { Id: 24158, Code: '8137', Name: 'Machinist, glove, LEATHER' },
  { Id: 24159, Code: '8137', Name: 'Machinist, glove, WOOLLEN' },
  { Id: 24160, Code: '8137', Name: 'Machinist, gown' },
  { Id: 24161, Code: '8137', Name: 'Machinist, hand, clothing mfr' },
  { Id: 24162, Code: '8137', Name: 'Machinist, hand, repairer' },
  { Id: 24163, Code: '8137', Name: 'Machinist, hat mfr' },
  { Id: 24164, Code: '8137', Name: 'Machinist, hemming' },
  { Id: 24165, Code: '8137', Name: 'Machinist, hemstitch' },
  { Id: 24166, Code: '8137', Name: 'Machinist, hosiery' },
  { Id: 24167, Code: '8137', Name: 'Machinist, hosiery, surgical' },
  { Id: 24168, Code: '8137', Name: 'Machinist, jacket' },
  { Id: 24169, Code: '8137', Name: 'Machinist, jacquard' },
  { Id: 24170, Code: '8137', Name: 'Machinist, knicker' },
  { Id: 24171, Code: '8137', Name: 'Machinist, knitting' },
  { Id: 24172, Code: '8137', Name: 'Machinist, lace, lace mfr' },
  { Id: 24173, Code: '8137', Name: 'Machinist, lace, textile mfr' },
  { Id: 24174, Code: '8137', Name: 'Machinist, lashing' },
  { Id: 24175, Code: '8137', Name: 'Machinist, leather, clothing mfr' },
  { Id: 24176, Code: '8137', Name: 'Machinist, linen' },
  { Id: 24177, Code: '8137', Name: 'Machinist, lining, clothing mfr' },
  { Id: 24178, Code: '8137', Name: 'Machinist, lining, hat mfr' },
  { Id: 24179, Code: '8137', Name: 'Machinist, lock, flat' },
  { Id: 24180, Code: '8137', Name: 'Machinist, lockstitch' },
  { Id: 24181, Code: '8137', Name: 'Machinist, mattress mfr' },
  { Id: 24182, Code: '8137', Name: 'Machinist, millinery' },
  { Id: 24183, Code: '8137', Name: 'Machinist, needle' },
  { Id: 24184, Code: '8137', Name: 'Machinist, net' },
  { Id: 24185, Code: '8137', Name: 'Machinist, oilskin' },
  { Id: 24186, Code: '8137', Name: 'Machinist, outerwear' },
  { Id: 24187, Code: '8137', Name: 'Machinist, outer-wear' },
  { Id: 24188, Code: '8137', Name: 'Machinist, overhead' },
  { Id: 24189, Code: '8137', Name: 'Machinist, overlock' },
  { Id: 24190, Code: '8137', Name: 'Machinist, overlocking' },
  { Id: 24191, Code: '8137', Name: 'Machinist, padding, clothing mfr' },
  { Id: 24192, Code: '8137', Name: 'Machinist, plain, SHIRTS' },
  { Id: 24193, Code: '8137', Name: 'Machinist, pleating' },
  { Id: 24194, Code: '8137', Name: 'Machinist, post, clothing mfr' },
  { Id: 24195, Code: '8137', Name: 'Machinist, quilting' },
  { Id: 24196, Code: '8137', Name: 'Machinist, rug and blanket, horse' },
  { Id: 24197, Code: '8137', Name: 'Machinist, sack' },
  { Id: 24198, Code: '8137', Name: 'Machinist, sample' },
  { Id: 24199, Code: '8137', Name: 'Machinist, seamless' },
  { Id: 24200, Code: '8137', Name: 'Machinist, sewing' },
  { Id: 24201, Code: '8137', Name: 'Machinist, shirt' },
  { Id: 24202, Code: '8137', Name: 'Machinist, silk, clothing mfr' },
  { Id: 24203, Code: '8137', Name: 'Machinist, sleeving' },
  { Id: 24204, Code: '8137', Name: 'Machinist, soft furnishings mfr' },
  { Id: 24205, Code: '8137', Name: 'Machinist, special, clothing mfr' },
  { Id: 24206, Code: '8137', Name: 'Machinist, stitch' },
  { Id: 24207, Code: '8137', Name: 'Machinist, stitching, footwear mfr' },
  {
    Id: 24208,
    Code: '8137',
    Name: 'Machinist, stocking, hosiery, knitwear mfr'
  },
  { Id: 24209, Code: '8137', Name: 'Machinist, surgical dressing mfr' },
  { Id: 24210, Code: '8137', Name: "Machinist, tailor's" },
  { Id: 24211, Code: '8137', Name: 'Machinist, tape, hat mfr' },
  { Id: 24212, Code: '8137', Name: 'Machinist, tent' },
  { Id: 24213, Code: '8137', Name: 'Machinist, textile mfr: carpet, rug mfr' },
  { Id: 24214, Code: '8137', Name: 'Machinist, textile mfr: hosiery mfr' },
  { Id: 24215, Code: '8137', Name: 'Machinist, textile mfr: textile weaving' },
  { Id: 24216, Code: '8137', Name: 'Machinist, textile products mfr' },
  { Id: 24217, Code: '8137', Name: 'Machinist, textile, clothing mfr' },
  { Id: 24218, Code: '8137', Name: 'Machinist, tie' },
  { Id: 24219, Code: '8137', Name: 'Machinist, towel' },
  { Id: 24220, Code: '8137', Name: 'Machinist, toy, soft' },
  { Id: 24221, Code: '8137', Name: 'Machinist, trouser' },
  { Id: 24222, Code: '8137', Name: 'Machinist, tucking' },
  { Id: 24223, Code: '8137', Name: "Machinist, upholsterer's" },
  { Id: 24224, Code: '8137', Name: 'Machinist, upholstering' },
  { Id: 24225, Code: '8137', Name: 'Machinist, upholstery' },
  { Id: 24226, Code: '8137', Name: 'Machinist, vest' },
  { Id: 24227, Code: '8137', Name: 'Machinist, welt, clothing mfr' },
  { Id: 24228, Code: '8137', Name: 'Machinist, welting, HOSIERY' },
  { Id: 24229, Code: '8137', Name: 'Machinist, wheel' },
  { Id: 24230, Code: '8137', Name: 'Machinist, zigzag' },
  { Id: 24231, Code: '8137', Name: 'Maid, sewing' },
  { Id: 24232, Code: '8137', Name: 'Maker, accoutrements' },
  { Id: 24233, Code: '8137', Name: 'Maker, bag, canvas goods mfr' },
  { Id: 24234, Code: '8137', Name: 'Maker, bag, gun' },
  { Id: 24235, Code: '8137', Name: 'Maker, bag, rod, fishing' },
  { Id: 24236, Code: '8137', Name: 'Maker, bag, sand' },
  { Id: 24237, Code: '8137', Name: 'Maker, belt' },
  { Id: 24238, Code: '8137', Name: 'Maker, blanket' },
  { Id: 24239, Code: '8137', Name: 'Maker, bow, clothing mfr' },
  { Id: 24240, Code: '8137', Name: 'Maker, canopy' },
  { Id: 24241, Code: '8137', Name: 'Maker, collar, clothing mfr' },
  { Id: 24242, Code: '8137', Name: 'Maker, cover, loose' },
  { Id: 24243, Code: '8137', Name: 'Maker, cover, tyre' },
  { Id: 24244, Code: '8137', Name: 'Maker, dressing, surgical' },
  { Id: 24245, Code: '8137', Name: 'Maker, embroidery' },
  { Id: 24246, Code: '8137', Name: 'Maker, fichu' },
  { Id: 24247, Code: '8137', Name: 'Maker, glove' },
  { Id: 24248, Code: '8137', Name: 'Maker, goods, canvas' },
  { Id: 24249, Code: '8137', Name: 'Maker, hole, button' },
  { Id: 24250, Code: '8137', Name: 'Maker, knapsack' },
  { Id: 24251, Code: '8137', Name: 'Maker, lining, clothing mfr' },
  { Id: 24252, Code: '8137', Name: 'Maker, marquee' },
  { Id: 24253, Code: '8137', Name: 'Maker, mat, wool' },
  { Id: 24254, Code: '8137', Name: 'Maker, parachute' },
  { Id: 24255, Code: '8137', Name: 'Maker, pyjama' },
  { Id: 24256, Code: '8137', Name: 'Maker, sack and bag' },
  { Id: 24257, Code: '8137', Name: 'Maker, sheet, railways' },
  { Id: 24258, Code: '8137', Name: 'Maker, shroud' },
  { Id: 24259, Code: '8137', Name: 'Maker, sleeve, clothing mfr' },
  { Id: 24260, Code: '8137', Name: 'Maker, surplice' },
  { Id: 24261, Code: '8137', Name: 'Maker, suspender' },
  { Id: 24262, Code: '8137', Name: 'Maker, tarpaulin' },
  { Id: 24263, Code: '8137', Name: 'Maker, tie' },
  { Id: 24264, Code: '8137', Name: 'Maker, trimming, clothing mfr' },
  { Id: 24265, Code: '8137', Name: 'Maker, trimmings, clothing mfr' },
  { Id: 24266, Code: '8137', Name: 'Maker-up, hosiery' },
  { Id: 24267, Code: '8137', Name: 'Maker-up, knitwear mfr' },
  { Id: 24268, Code: '8137', Name: 'Maker-up, umbrella, parasol mfr' },
  { Id: 24269, Code: '8137', Name: 'Manufacturer, glove' },
  { Id: 24270, Code: '8137', Name: 'Manufacturer, glove mfr' },
  { Id: 24271, Code: '8137', Name: 'Matron, sewing' },
  { Id: 24272, Code: '8137', Name: 'Mender, bag' },
  { Id: 24273, Code: '8137', Name: 'Mender, carpet' },
  { Id: 24274, Code: '8137', Name: 'Mender, cloth, textile mfr' },
  { Id: 24275, Code: '8137', Name: 'Mender, dress, HOSIERY' },
  { Id: 24276, Code: '8137', Name: 'Mender, embroidery' },
  { Id: 24277, Code: '8137', Name: 'Mender, embroidery mfr' },
  { Id: 24278, Code: '8137', Name: 'Mender, hosiery' },
  { Id: 24279, Code: '8137', Name: 'Mender, hotels, catering, public houses' },
  { Id: 24280, Code: '8137', Name: 'Mender, invisible' },
  {
    Id: 24281,
    Code: '8137',
    Name: 'Mender, laundry, launderette, dry cleaning'
  },
  { Id: 24282, Code: '8137', Name: 'Mender, net' },
  { Id: 24283, Code: '8137', Name: 'Mender, piece' },
  { Id: 24284, Code: '8137', Name: 'Mender, sack' },
  { Id: 24285, Code: '8137', Name: 'Mender, textile mfr' },
  { Id: 24286, Code: '8137', Name: 'Mistress, needle' },
  { Id: 24287, Code: '8137', Name: 'Mistress, room, work' },
  { Id: 24288, Code: '8137', Name: 'Mistress, sewing' },
  { Id: 24289, Code: '8137', Name: 'Operative, rosso' },
  { Id: 24290, Code: '8137', Name: 'Overlocker' },
  { Id: 24291, Code: '8137', Name: 'Padder, clothing mfr' },
  { Id: 24292, Code: '8137', Name: 'Pantographer, embroidery mfr' },
  { Id: 24293, Code: '8137', Name: 'Picker, carpet' },
  { Id: 24294, Code: '8137', Name: 'Picker, cloth' },
  { Id: 24295, Code: '8137', Name: 'Picker, textile mfr' },
  { Id: 24296, Code: '8137', Name: 'Picker, yarn' },
  { Id: 24297, Code: '8137', Name: 'Piecer-up, clothing mfr' },
  { Id: 24298, Code: '8137', Name: 'Pointer, machine' },
  { Id: 24299, Code: '8137', Name: 'Purler' },
  { Id: 24300, Code: '8137', Name: 'Quilter, textile mfr' },
  { Id: 24301, Code: '8137', Name: 'Raiser and finisher, embroidery mfr' },
  { Id: 24302, Code: '8137', Name: 'Rectifier, textile mfr' },
  { Id: 24303, Code: '8137', Name: 'Renovator, CLOTHING' },
  { Id: 24304, Code: '8137', Name: 'Repairer, CANVAS GOODS' },
  { Id: 24305, Code: '8137', Name: 'Repairer, carpet' },
  { Id: 24306, Code: '8137', Name: 'Repairer, CLOTHING' },
  { Id: 24307, Code: '8137', Name: 'Repairer, hosiery' },
  { Id: 24308, Code: '8137', Name: 'Repairer, net' },
  { Id: 24309, Code: '8137', Name: 'Repairer, sack' },
  { Id: 24310, Code: '8137', Name: 'Repairer, sheet' },
  { Id: 24311, Code: '8137', Name: 'Repairer, tarpaulin' },
  { Id: 24312, Code: '8137', Name: 'Repairer, tent' },
  { Id: 24313, Code: '8137', Name: 'Seamer, carpet, rug mfr' },
  { Id: 24314, Code: '8137', Name: 'Seamer, clothing mfr' },
  { Id: 24315, Code: '8137', Name: 'Seamer, corset' },
  { Id: 24316, Code: '8137', Name: 'Seamer, cup, knitwear mfr' },
  { Id: 24317, Code: '8137', Name: 'Seamer, hosiery, knitwear mfr' },
  { Id: 24318, Code: '8137', Name: 'Seamer-round' },
  { Id: 24319, Code: '8137', Name: 'Seamstress' },
  { Id: 24320, Code: '8137', Name: 'Sempstress' },
  { Id: 24321, Code: '8137', Name: 'Sewer, bag' },
  { Id: 24322, Code: '8137', Name: 'Sewer, button' },
  { Id: 24323, Code: '8137', Name: 'Sewer, canvas goods mfr' },
  { Id: 24324, Code: '8137', Name: 'Sewer, carpet' },
  { Id: 24325, Code: '8137', Name: 'Sewer, carpet, rug mfr' },
  { Id: 24326, Code: '8137', Name: 'Sewer, cloth' },
  { Id: 24327, Code: '8137', Name: 'Sewer, cloth mending' },
  { Id: 24328, Code: '8137', Name: 'Sewer, clothing mfr' },
  { Id: 24329, Code: '8137', Name: 'Sewer, felt' },
  { Id: 24330, Code: '8137', Name: 'Sewer, fur' },
  { Id: 24331, Code: '8137', Name: 'Sewer, glove' },
  { Id: 24332, Code: '8137', Name: 'Sewer, glove mfr' },
  { Id: 24333, Code: '8137', Name: 'Sewer, hat' },
  { Id: 24334, Code: '8137', Name: 'Sewer, hat mfr' },
  { Id: 24335, Code: '8137', Name: 'Sewer, piece' },
  { Id: 24336, Code: '8137', Name: 'Sewer, seam' },
  { Id: 24337, Code: '8137', Name: 'Sewer, spangle' },
  { Id: 24338, Code: '8137', Name: 'Sewer, tent' },
  { Id: 24339, Code: '8137', Name: 'Sewer, textile mfr' },
  { Id: 24340, Code: '8137', Name: 'Silker, textile mfr' },
  { Id: 24341, Code: '8137', Name: 'Sleever, clothing mfr' },
  { Id: 24342, Code: '8137', Name: 'Slipper, cushion' },
  { Id: 24343, Code: '8137', Name: 'Slipper, furniture mfr' },
  { Id: 24344, Code: '8137', Name: 'Slipper, tie' },
  { Id: 24345, Code: '8137', Name: 'Spiler, textile mfr' },
  { Id: 24346, Code: '8137', Name: 'Stitcher' },
  { Id: 24347, Code: '8137', Name: 'Stitcher, ball, tennis' },
  { Id: 24348, Code: '8137', Name: 'Stitcher, hem' },
  { Id: 24349, Code: '8137', Name: 'Stitcher, lock' },
  { Id: 24350, Code: '8137', Name: 'Strapper, corset mfr' },
  { Id: 24351, Code: '8137', Name: 'Supervisor, cotton' },
  { Id: 24352, Code: '8137', Name: 'Supervisor, room, sewing' },
  { Id: 24353, Code: '8137', Name: 'Tabber, corset mfr' },
  { Id: 24354, Code: '8137', Name: 'Tacker, hosiery, knitwear mfr' },
  { Id: 24355, Code: '8137', Name: 'Thumber, glove mfr' },
  { Id: 24356, Code: '8137', Name: 'Tier, knot, textile mfr' },
  { Id: 24357, Code: '8137', Name: 'Tipper, umbrella' },
  { Id: 24358, Code: '8137', Name: 'Topper, clothing mfr' },
  { Id: 24359, Code: '8137', Name: 'Trimmer, clothing mfr' },
  { Id: 24360, Code: '8137', Name: 'Welter, hosiery, knitwear mfr' },
  { Id: 24361, Code: '8137', Name: 'Whipper, blanket' },
  { Id: 24362, Code: '8137', Name: 'Woman, needle' },
  { Id: 24363, Code: '8137', Name: 'Worker, badge' },
  { Id: 24364, Code: '8137', Name: 'Worker, buttonhole' },
  { Id: 24365, Code: '8137', Name: 'Worker, fabric' },
  { Id: 24366, Code: '8137', Name: 'Worker, needle' },
  { Id: 24367, Code: '8137', Name: 'Worker, silk, greetings cards mfr' },
  { Id: 24368, Code: '8139', Name: 'Applicator, film' },
  { Id: 24369, Code: '8139', Name: 'Assembler' },
  { Id: 24370, Code: '8139', Name: 'Assembler, action' },
  { Id: 24371, Code: '8139', Name: 'Assembler, aircraft' },
  { Id: 24372, Code: '8139', Name: 'Assembler, ammeter' },
  { Id: 24373, Code: '8139', Name: 'Assembler, bench, engineering' },
  { Id: 24374, Code: '8139', Name: 'Assembler, bi-focal' },
  { Id: 24375, Code: '8139', Name: 'Assembler, binocular' },
  { Id: 24376, Code: '8139', Name: 'Assembler, block, carbon' },
  { Id: 24377, Code: '8139', Name: 'Assembler, box' },
  { Id: 24378, Code: '8139', Name: 'Assembler, brush' },
  { Id: 24379, Code: '8139', Name: 'Assembler, brush, carbon' },
  { Id: 24380, Code: '8139', Name: 'Assembler, cabinet' },
  { Id: 24381, Code: '8139', Name: 'Assembler, camera' },
  { Id: 24382, Code: '8139', Name: 'Assembler, card, printing' },
  { Id: 24383, Code: '8139', Name: 'Assembler, carton' },
  {
    Id: 24384,
    Code: '8139',
    Name: 'Assembler, case, electrical, electronic equipment mfr'
  },
  { Id: 24385, Code: '8139', Name: 'Assembler, clock' },
  { Id: 24386, Code: '8139', Name: 'Assembler, clothing' },
  { Id: 24387, Code: '8139', Name: 'Assembler, conveyor' },
  { Id: 24388, Code: '8139', Name: 'Assembler, cosmetics' },
  { Id: 24389, Code: '8139', Name: 'Assembler, cutlery' },
  { Id: 24390, Code: '8139', Name: 'Assembler, doll' },
  { Id: 24391, Code: '8139', Name: 'Assembler, fencing' },
  { Id: 24392, Code: '8139', Name: 'Assembler, firework' },
  { Id: 24393, Code: '8139', Name: 'Assembler, flask, vacuum' },
  { Id: 24394, Code: '8139', Name: 'Assembler, frame, engineering' },
  { Id: 24395, Code: '8139', Name: 'Assembler, frame, spectacle' },
  { Id: 24396, Code: '8139', Name: 'Assembler, furniture' },
  { Id: 24397, Code: '8139', Name: 'Assembler, instrument' },
  { Id: 24398, Code: '8139', Name: 'Assembler, instrument, optical' },
  { Id: 24399, Code: '8139', Name: 'Assembler, ligature, surgical' },
  { Id: 24400, Code: '8139', Name: 'Assembler, mattress' },
  { Id: 24401, Code: '8139', Name: 'Assembler, meter' },
  { Id: 24402, Code: '8139', Name: 'Assembler, optical' },
  { Id: 24403, Code: '8139', Name: 'Assembler, pad, stamp' },
  { Id: 24404, Code: '8139', Name: 'Assembler, pen' },
  { Id: 24405, Code: '8139', Name: 'Assembler, plastics' },
  { Id: 24406, Code: '8139', Name: 'Assembler, poppy' },
  { Id: 24407, Code: '8139', Name: 'Assembler, pottery' },
  { Id: 24408, Code: '8139', Name: 'Assembler, toy' },
  { Id: 24409, Code: '8139', Name: 'Assembler, transformer' },
  { Id: 24410, Code: '8139', Name: 'Assembler, trim' },
  { Id: 24411, Code: '8139', Name: 'Assembler, tube, plastics goods mfr' },
  { Id: 24412, Code: '8139', Name: 'Assembler, umbrella' },
  { Id: 24413, Code: '8139', Name: 'Assembler, watch' },
  { Id: 24414, Code: '8139', Name: 'Assembler, woodwork' },
  { Id: 24415, Code: '8139', Name: 'Assembler-fitter' },
  { Id: 24416, Code: '8139', Name: "Assistant, dipper's" },
  { Id: 24417, Code: '8139', Name: 'Assistant, house, green, ceramics mfr' },
  { Id: 24418, Code: '8139', Name: "Assistant, lithographer's" },
  { Id: 24419, Code: '8139', Name: "Assistant, painter's" },
  { Id: 24420, Code: '8139', Name: 'Assistant, production' },
  { Id: 24421, Code: '8139', Name: 'Assistant, production, manufacturing' },
  { Id: 24422, Code: '8139', Name: 'Assistant, shop, paint' },
  { Id: 24423, Code: '8139', Name: 'Attacher' },
  { Id: 24424, Code: '8139', Name: "Attendant, dipper's" },
  { Id: 24425, Code: '8139', Name: 'Bander, envelope mfr' },
  { Id: 24426, Code: '8139', Name: 'Binder, footwear mfr: rubber footwear' },
  { Id: 24427, Code: '8139', Name: 'Blacker' },
  { Id: 24428, Code: '8139', Name: 'Blacker-in, ENAMELLED SLATE' },
  { Id: 24429, Code: '8139', Name: 'Boner, CORSETS' },
  { Id: 24430, Code: '8139', Name: 'Brusher, enamel' },
  { Id: 24431, Code: '8139', Name: 'Brusher, paint' },
  { Id: 24432, Code: '8139', Name: 'Brusher-off, metal trades' },
  { Id: 24433, Code: '8139', Name: 'Buckler' },
  { Id: 24434, Code: '8139', Name: 'Builder, heel' },
  { Id: 24435, Code: '8139', Name: 'Bundler, clothing mfr' },
  { Id: 24436, Code: '8139', Name: 'Caner, corset mfr' },
  { Id: 24437, Code: '8139', Name: 'Cementer, envelope' },
  { Id: 24438, Code: '8139', Name: 'Cementer, lens mfr' },
  { Id: 24439, Code: '8139', Name: 'Cementer, plastics goods mfr' },
  { Id: 24440, Code: '8139', Name: 'Cementer, rubber' },
  { Id: 24441, Code: '8139', Name: 'Cementer, rubber goods mfr' },
  { Id: 24442, Code: '8139', Name: 'Checker, chemical mfr' },
  { Id: 24443, Code: '8139', Name: 'Chipper, painting, decorating' },
  { Id: 24444, Code: '8139', Name: 'Clamper, pencil, crayon mfr' },
  { Id: 24445, Code: '8139', Name: 'Clipper, hosiery, knitwear mfr' },
  { Id: 24446, Code: '8139', Name: 'Closer, channel' },
  { Id: 24447, Code: '8139', Name: 'Colourer, ARTIFICIAL FLOWERS' },
  { Id: 24448, Code: '8139', Name: 'Connector, RUBBER BOOTS AND SHOES' },
  { Id: 24449, Code: '8139', Name: 'Converter, foam' },
  { Id: 24450, Code: '8139', Name: 'Corder, printing' },
  { Id: 24451, Code: '8139', Name: 'Corker, fishing rod mfr' },
  { Id: 24452, Code: '8139', Name: 'Coupler, hose pipe mfr' },
  { Id: 24453, Code: '8139', Name: 'Coverer and liner, case' },
  { Id: 24454, Code: '8139', Name: 'Coverer, ball, tennis' },
  { Id: 24455, Code: '8139', Name: 'Coverer, box, WOODEN FIXTURE BOXES' },
  { Id: 24456, Code: '8139', Name: 'Coverer, buckle' },
  { Id: 24457, Code: '8139', Name: 'Coverer, button' },
  { Id: 24458, Code: '8139', Name: 'Coverer, cabinet, furniture mfr' },
  { Id: 24459, Code: '8139', Name: 'Coverer, cardboard box mfr' },
  { Id: 24460, Code: '8139', Name: 'Coverer, case' },
  { Id: 24461, Code: '8139', Name: 'Coverer, fireworks' },
  { Id: 24462, Code: '8139', Name: 'Coverer, hat' },
  { Id: 24463, Code: '8139', Name: 'Coverer, heel' },
  { Id: 24464, Code: '8139', Name: 'Coverer, helmet' },
  { Id: 24465, Code: '8139', Name: 'Coverer, piano, organ mfr' },
  { Id: 24466, Code: '8139', Name: 'Cutter, litho, ceramics mfr' },
  { Id: 24467, Code: '8139', Name: "Cutter, transferrer's" },
  { Id: 24468, Code: '8139', Name: 'Decorator, art' },
  { Id: 24469, Code: '8139', Name: 'Decorator, card, GREETING CARDS' },
  { Id: 24470, Code: '8139', Name: 'Dipper, cellulose' },
  { Id: 24471, Code: '8139', Name: 'Dipper, enamel' },
  { Id: 24472, Code: '8139', Name: 'Dipper, metal trades' },
  { Id: 24473, Code: '8139', Name: 'Dipper, paint' },
  { Id: 24474, Code: '8139', Name: 'Divider, clothing mfr' },
  { Id: 24475, Code: '8139', Name: 'Doper, aircraft mfr' },
  { Id: 24476, Code: '8139', Name: 'Duster, coal mine' },
  { Id: 24477, Code: '8139', Name: 'Dyer, button mfr' },
  { Id: 24478, Code: '8139', Name: 'Enameller' },
  { Id: 24479, Code: '8139', Name: 'Facer, coach painting' },
  { Id: 24480, Code: '8139', Name: 'Filler, pencil, crayon mfr' },
  { Id: 24481, Code: '8139', Name: 'Filler, wood' },
  { Id: 24482, Code: '8139', Name: 'Filler-in, pencil, crayon mfr' },
  { Id: 24483, Code: '8139', Name: "Filler-in, polisher's" },
  { Id: 24484, Code: '8139', Name: 'Finisher, blade' },
  { Id: 24485, Code: '8139', Name: 'Finisher, broom, brush mfr' },
  { Id: 24486, Code: '8139', Name: 'Finisher, brush' },
  { Id: 24487, Code: '8139', Name: 'Finisher, card, card clothing mfr' },
  { Id: 24488, Code: '8139', Name: 'Finisher, card, printing' },
  { Id: 24489, Code: '8139', Name: 'Finisher, case, JEWEL CASES' },
  { Id: 24490, Code: '8139', Name: 'Finisher, faller' },
  { Id: 24491, Code: '8139', Name: 'Finisher, fireworks mfr' },
  { Id: 24492, Code: '8139', Name: 'Finisher, fork' },
  { Id: 24493, Code: '8139', Name: 'Finisher, frame, cycle mfr' },
  { Id: 24494, Code: '8139', Name: 'Finisher, furniture' },
  { Id: 24495, Code: '8139', Name: 'Finisher, furniture mfr' },
  { Id: 24496, Code: '8139', Name: 'Finisher, greetings cards mfr' },
  { Id: 24497, Code: '8139', Name: 'Finisher, hook, spring' },
  { Id: 24498, Code: '8139', Name: 'Finisher, key' },
  { Id: 24499, Code: '8139', Name: 'Finisher, needle, needle mfr' },
  { Id: 24500, Code: '8139', Name: 'Finisher, paper goods mfr' },
  { Id: 24501, Code: '8139', Name: 'Finisher, shop, machine' },
  { Id: 24502, Code: '8139', Name: 'Finisher, spade' },
  { Id: 24503, Code: '8139', Name: 'Finisher, tool, edge' },
  { Id: 24504, Code: '8139', Name: 'Finisher, wrench' },
  { Id: 24505, Code: '8139', Name: 'Fitter, bag, air' },
  { Id: 24506, Code: '8139', Name: 'Fitter, bag, curing' },
  { Id: 24507, Code: '8139', Name: 'Fitter, basket, work' },
  { Id: 24508, Code: '8139', Name: 'Fitter, box, work' },
  { Id: 24509, Code: '8139', Name: 'Fitter, cardboard container mfr' },
  { Id: 24510, Code: '8139', Name: 'Fitter, frame, bag' },
  { Id: 24511, Code: '8139', Name: 'Fitter, glass, watch mfr' },
  { Id: 24512, Code: '8139', Name: 'Fitter, mouthpiece, PIPES' },
  { Id: 24513, Code: '8139', Name: 'Fitter, paste' },
  { Id: 24514, Code: '8139', Name: 'Fitter, pen, fountain' },
  { Id: 24515, Code: '8139', Name: 'Fitter, pipe, briar' },
  { Id: 24516, Code: '8139', Name: 'Fitter, tobacco pipe mfr' },
  { Id: 24517, Code: '8139', Name: 'Fitter, umbrella' },
  { Id: 24518, Code: '8139', Name: 'Fitter, valve, tyre mfr' },
  { Id: 24519, Code: '8139', Name: 'Fitter-up, frame, picture' },
  { Id: 24520, Code: '8139', Name: 'Fixer, transfer, JAPANNING' },
  { Id: 24521, Code: '8139', Name: 'Flatter and polisher' },
  { Id: 24522, Code: '8139', Name: 'Flatter, cellulose' },
  { Id: 24523, Code: '8139', Name: 'Flatter, paint' },
  { Id: 24524, Code: '8139', Name: 'Flatter, vehicle mfr' },
  { Id: 24525, Code: '8139', Name: 'Folder, box, cardboard box mfr' },
  { Id: 24526, Code: '8139', Name: 'Folder, pattern, paper' },
  { Id: 24527, Code: '8139', Name: 'Folder-in, glove mfr' },
  { Id: 24528, Code: '8139', Name: 'Foreman, enamelling' },
  { Id: 24529, Code: '8139', Name: 'Foreman, shop, enamelling' },
  { Id: 24530, Code: '8139', Name: 'Foreman, shop, paint' },
  { Id: 24531, Code: '8139', Name: 'Framer, bag' },
  { Id: 24532, Code: '8139', Name: 'Fuser, textile materials, products mfr' },
  { Id: 24533, Code: '8139', Name: 'Garterer' },
  { Id: 24534, Code: '8139', Name: 'Gilder, composition' },
  { Id: 24535, Code: '8139', Name: 'Gilder, furniture mfr' },
  { Id: 24536, Code: '8139', Name: 'Gluer, furniture mfr' },
  { Id: 24537, Code: '8139', Name: 'Gluer, paper goods mfr' },
  { Id: 24538, Code: '8139', Name: 'Gluer, sports goods mfr' },
  { Id: 24539, Code: '8139', Name: 'Gluer-up, WOODWORK' },
  { Id: 24540, Code: '8139', Name: 'Grommeter' },
  { Id: 24541, Code: '8139', Name: 'Grounder, fur' },
  { Id: 24542, Code: '8139', Name: 'Grounder, wallpaper printing' },
  { Id: 24543, Code: '8139', Name: 'Gummer, paper goods mfr' },
  { Id: 24544, Code: '8139', Name: 'Hand, bench, cardboard box mfr' },
  { Id: 24545, Code: '8139', Name: 'Hand, bench, rubber footwear mfr' },
  { Id: 24546, Code: '8139', Name: 'Hand, brush, coach painting' },
  { Id: 24547, Code: '8139', Name: 'Hand, cracker, paper goods mfr' },
  { Id: 24548, Code: '8139', Name: 'Hand, paint' },
  { Id: 24549, Code: '8139', Name: 'Hand, painter' },
  { Id: 24550, Code: '8139', Name: 'Hand, tassel' },
  { Id: 24551, Code: '8139', Name: 'Hand, wiring' },
  { Id: 24552, Code: '8139', Name: 'Handler, broom, brush mfr' },
  { Id: 24553, Code: '8139', Name: 'Handler, ceramics mfr' },
  { Id: 24554, Code: '8139', Name: 'Handler, edge tool mfr' },
  { Id: 24555, Code: '8139', Name: 'Handler, spade and shovel' },
  { Id: 24556, Code: '8139', Name: 'Handler, tool, edge tool mfr' },
  { Id: 24557, Code: '8139', Name: 'Helver, TOOLS' },
  { Id: 24558, Code: '8139', Name: 'Impregnator, cable mfr' },
  { Id: 24559, Code: '8139', Name: 'Inker, edge' },
  { Id: 24560, Code: '8139', Name: 'Inserter, clothing mfr' },
  { Id: 24561, Code: '8139', Name: 'Insulator, bitumen' },
  { Id: 24562, Code: '8139', Name: 'Insulator, coil' },
  { Id: 24563, Code: '8139', Name: 'Insulator, electrical appliances mfr' },
  { Id: 24564, Code: '8139', Name: 'Japanner' },
  { Id: 24565, Code: '8139', Name: 'Joiner, rubber footwear mfr' },
  { Id: 24566, Code: '8139', Name: 'Jointer, soft toy mfr' },
  { Id: 24567, Code: '8139', Name: 'Lacer, corset' },
  { Id: 24568, Code: '8139', Name: 'Lacer, corset mfr' },
  { Id: 24569, Code: '8139', Name: 'Lacer, shade, lamp' },
  { Id: 24570, Code: '8139', Name: 'Lacquerer' },
  { Id: 24571, Code: '8139', Name: 'Leader, team, assembly' },
  { Id: 24572, Code: '8139', Name: 'Lidder, cardboard box mfr' },
  { Id: 24573, Code: '8139', Name: 'Liner, basket' },
  { Id: 24574, Code: '8139', Name: 'Liner, box, work' },
  { Id: 24575, Code: '8139', Name: 'Liner, brake' },
  { Id: 24576, Code: '8139', Name: 'Liner, cabinet, upholstery mfr' },
  { Id: 24577, Code: '8139', Name: 'Liner, cardboard box mfr' },
  { Id: 24578, Code: '8139', Name: 'Liner, case' },
  { Id: 24579, Code: '8139', Name: 'Liner, cycle' },
  { Id: 24580, Code: '8139', Name: 'Liner, glove' },
  { Id: 24581, Code: '8139', Name: 'Liner, gold, cycle mfr' },
  { Id: 24582, Code: '8139', Name: 'Liner, machine' },
  { Id: 24583, Code: '8139', Name: 'Liner, metal trades' },
  { Id: 24584, Code: '8139', Name: 'Liner, picture' },
  { Id: 24585, Code: '8139', Name: 'Liner, pouch, leather' },
  { Id: 24586, Code: '8139', Name: 'Liner, table' },
  { Id: 24587, Code: '8139', Name: "Looker-to-ware, thrower's" },
  { Id: 24588, Code: '8139', Name: 'Machinist, backer, FOOTWEAR' },
  { Id: 24589, Code: '8139', Name: 'Machinist, bag, plastics goods mfr' },
  { Id: 24590, Code: '8139', Name: 'Machinist, dipping' },
  { Id: 24591, Code: '8139', Name: 'Machinist, gumming, paper goods mfr' },
  { Id: 24592, Code: '8139', Name: 'Machinist, nailing' },
  { Id: 24593, Code: '8139', Name: 'Machinist, pressing, transfer' },
  { Id: 24594, Code: '8139', Name: 'Machinist, riveting, plastics goods mfr' },
  { Id: 24595, Code: '8139', Name: 'Machinist, slugger' },
  { Id: 24596, Code: '8139', Name: 'Machinist, varnishing, metal trades' },
  { Id: 24597, Code: '8139', Name: 'Machinist, welding, plastics' },
  { Id: 24598, Code: '8139', Name: 'Maker, bag, polythene' },
  { Id: 24599, Code: '8139', Name: 'Maker, basket, chip' },
  { Id: 24600, Code: '8139', Name: 'Maker, belt, abrasive paper, cloth mfr' },
  { Id: 24601, Code: '8139', Name: 'Maker, boot, RUBBER' },
  { Id: 24602, Code: '8139', Name: 'Maker, box, cigar' },
  { Id: 24603, Code: '8139', Name: 'Maker, card, shade' },
  { Id: 24604, Code: '8139', Name: 'Maker, case, fireworks mfr' },
  { Id: 24605, Code: '8139', Name: 'Maker, case, pattern' },
  { Id: 24606, Code: '8139', Name: 'Maker, cracker, paper goods mfr' },
  { Id: 24607, Code: '8139', Name: 'Maker, doll' },
  { Id: 24608, Code: '8139', Name: 'Maker, dolly, toy mfr' },
  { Id: 24609, Code: '8139', Name: 'Maker, flag, paper goods mfr' },
  { Id: 24610, Code: '8139', Name: 'Maker, flower, artificial, PLASTICS' },
  { Id: 24611, Code: '8139', Name: 'Maker, frame, cork' },
  { Id: 24612, Code: '8139', Name: 'Maker, hat, paper' },
  { Id: 24613, Code: '8139', Name: 'Maker, map, dissected' },
  { Id: 24614, Code: '8139', Name: 'Maker, meter, gas' },
  { Id: 24615, Code: '8139', Name: 'Maker, postcard' },
  { Id: 24616, Code: '8139', Name: 'Maker, slipper, rubber' },
  { Id: 24617, Code: '8139', Name: 'Maker, stamp, endorsing, METAL' },
  { Id: 24618, Code: '8139', Name: 'Maker, stamp, rubber' },
  { Id: 24619, Code: '8139', Name: 'Maker, trellis' },
  { Id: 24620, Code: '8139', Name: 'Man, enamel, stove mfr' },
  { Id: 24621, Code: '8139', Name: 'Manufacturer, firelighter' },
  { Id: 24622, Code: '8139', Name: 'Marker, bale' },
  { Id: 24623, Code: '8139', Name: 'Marker, box' },
  { Id: 24624, Code: '8139', Name: 'Marker, brewery' },
  { Id: 24625, Code: '8139', Name: 'Marker, dial' },
  {
    Id: 24626,
    Code: '8139',
    Name: 'Marker, laundry, launderette, dry cleaning'
  },
  { Id: 24627, Code: '8139', Name: 'Marker, rolling mill' },
  { Id: 24628, Code: '8139', Name: 'Marker, size' },
  { Id: 24629, Code: '8139', Name: 'Marker, steel sheet, strip mfr' },
  { Id: 24630, Code: '8139', Name: 'Marker, timber' },
  { Id: 24631, Code: '8139', Name: 'Marker, tinplate mfr' },
  { Id: 24632, Code: '8139', Name: 'Marker, trade' },
  { Id: 24633, Code: '8139', Name: 'Marker, valve' },
  { Id: 24634, Code: '8139', Name: 'Masker, metal trades' },
  { Id: 24635, Code: '8139', Name: 'Masker, paint' },
  { Id: 24636, Code: '8139', Name: "Mate, polisher's, french" },
  { Id: 24637, Code: '8139', Name: 'Mounter, card' },
  { Id: 24638, Code: '8139', Name: 'Mounter, drawing' },
  { Id: 24639, Code: '8139', Name: 'Mounter, feather' },
  { Id: 24640, Code: '8139', Name: 'Mounter, handle, umbrella' },
  { Id: 24641, Code: '8139', Name: 'Mounter, lens' },
  { Id: 24642, Code: '8139', Name: 'Mounter, map' },
  { Id: 24643, Code: '8139', Name: "Mounter, print, LITHOGRAPHER'S" },
  { Id: 24644, Code: '8139', Name: 'Mounter, stick, walking' },
  { Id: 24645, Code: '8139', Name: 'Nailer, wooden box mfr' },
  { Id: 24646, Code: '8139', Name: 'Operative, manufacturing, furniture' },
  { Id: 24647, Code: '8139', Name: 'Operator, line, paint' },
  { Id: 24648, Code: '8139', Name: 'Operator, plant, paint' },
  {
    Id: 24649,
    Code: '8139',
    Name: 'Operator, plant, painting, electrophoretic'
  },
  { Id: 24650, Code: '8139', Name: 'Ornamenter, japanned ware mfr' },
  { Id: 24651, Code: '8139', Name: 'Padder, colour' },
  { Id: 24652, Code: '8139', Name: 'Painter, artificial flower mfr' },
  { Id: 24653, Code: '8139', Name: 'Painter, bottom' },
  { Id: 24654, Code: '8139', Name: 'Painter, toy' },
  { Id: 24655, Code: '8139', Name: 'Pairer, corset mfr' },
  { Id: 24656, Code: '8139', Name: 'Paperer, chair' },
  { Id: 24657, Code: '8139', Name: 'Paperer, sand' },
  { Id: 24658, Code: '8139', Name: 'Paperer-on, WHIPS' },
  { Id: 24659, Code: '8139', Name: 'Paster, paper goods mfr' },
  { Id: 24660, Code: '8139', Name: 'Piecer, sole' },
  { Id: 24661, Code: '8139', Name: 'Plugger, rod, fishing' },
  { Id: 24662, Code: '8139', Name: 'Polisher, cellulose' },
  { Id: 24663, Code: '8139', Name: 'Polisher, stick' },
  { Id: 24664, Code: '8139', Name: 'Preparer, litho, ceramics mfr' },
  { Id: 24665, Code: '8139', Name: 'Presser, fusing' },
  { Id: 24666, Code: '8139', Name: 'Putter-together, cutlery mfr' },
  { Id: 24667, Code: '8139', Name: 'Putter-together, scissors' },
  { Id: 24668, Code: '8139', Name: 'Ranger, glass mfr' },
  { Id: 24669, Code: '8139', Name: 'Receiver, tobacco mfr' },
  { Id: 24670, Code: '8139', Name: 'Recorder, LABORATORY' },
  { Id: 24671, Code: '8139', Name: 'Rectifier, paint, vehicle mfr' },
  { Id: 24672, Code: '8139', Name: 'Reliner, brake' },
  { Id: 24673, Code: '8139', Name: 'Riveter, GLOVE FASTENINGS' },
  { Id: 24674, Code: '8139', Name: 'Riveter, PLASTICS GOODS' },
  { Id: 24675, Code: '8139', Name: 'Riveter, soft toy mfr' },
  { Id: 24676, Code: '8139', Name: 'Rubber and flatter, coach building' },
  { Id: 24677, Code: '8139', Name: 'Rubber and polisher, vehicle mfr' },
  { Id: 24678, Code: '8139', Name: 'Rubber, vehicle mfr' },
  { Id: 24679, Code: '8139', Name: 'Rubber-down' },
  { Id: 24680, Code: '8139', Name: 'Sackhand, sack mfr' },
  { Id: 24681, Code: '8139', Name: 'Sander, hand, furniture mfr' },
  { Id: 24682, Code: '8139', Name: 'Sander, wet, motor body mfr' },
  { Id: 24683, Code: '8139', Name: 'Scaler, vehicle mfr' },
  { Id: 24684, Code: '8139', Name: 'Sealer-in' },
  { Id: 24685, Code: '8139', Name: 'Setter, steel, corset mfr' },
  { Id: 24686, Code: '8139', Name: 'Shader, artificial flower mfr' },
  { Id: 24687, Code: '8139', Name: 'Solutioner, rubber goods mfr' },
  { Id: 24688, Code: '8139', Name: 'Solutionist, rubber goods mfr' },
  { Id: 24689, Code: '8139', Name: 'Spotter, artificial flower mfr' },
  { Id: 24690, Code: '8139', Name: 'Springer-in, SPECTACLES' },
  { Id: 24691, Code: '8139', Name: 'Stapler, box, cardboard box mfr' },
  { Id: 24692, Code: '8139', Name: 'Stemmer and waxer, battery carbon mfr' },
  { Id: 24693, Code: '8139', Name: 'Stenciller' },
  { Id: 24694, Code: '8139', Name: 'Stenciller, box' },
  { Id: 24695, Code: '8139', Name: 'Sticker, feather' },
  { Id: 24696, Code: '8139', Name: 'Sticker, leaf, artificial flower mfr' },
  { Id: 24697, Code: '8139', Name: 'Sticker, pattern, paper pattern mfr' },
  { Id: 24698, Code: '8139', Name: 'Sticker-up, ceramics mfr' },
  { Id: 24699, Code: '8139', Name: 'Stitcher, box' },
  { Id: 24700, Code: '8139', Name: 'Stitcher, carton' },
  { Id: 24701, Code: '8139', Name: 'Stitcher, paper goods mfr' },
  { Id: 24702, Code: '8139', Name: 'Stitcher, wire, paper goods mfr' },
  { Id: 24703, Code: '8139', Name: 'Stringer, bag, paper' },
  { Id: 24704, Code: '8139', Name: 'Stringer, bead' },
  { Id: 24705, Code: '8139', Name: 'Stringer, pearl' },
  { Id: 24706, Code: '8139', Name: 'Stringer, printing' },
  { Id: 24707, Code: '8139', Name: 'Stripper, cable' },
  { Id: 24708, Code: '8139', Name: 'Stripper, paint, vehicle, vehicle mfr' },
  { Id: 24709, Code: '8139', Name: 'Stripper, polish' },
  { Id: 24710, Code: '8139', Name: 'Studder, clothing mfr' },
  { Id: 24711, Code: '8139', Name: 'Swiller, enamelling' },
  { Id: 24712, Code: '8139', Name: 'Tackler, bag' },
  { Id: 24713, Code: '8139', Name: 'Tagger, label' },
  { Id: 24714, Code: '8139', Name: 'Tagger, lace mfr' },
  { Id: 24715, Code: '8139', Name: 'Taker-off, ceramics mfr' },
  { Id: 24716, Code: '8139', Name: "Taker-off, dipper's" },
  { Id: 24717, Code: '8139', Name: 'Taker-out' },
  { Id: 24718, Code: '8139', Name: 'Taper, cardboard box mfr' },
  { Id: 24719, Code: '8139', Name: 'Threader, pearl' },
  { Id: 24720, Code: '8139', Name: 'Tier, hook' },
  { Id: 24721, Code: '8139', Name: 'Tier, ring' },
  { Id: 24722, Code: '8139', Name: 'Tier, tackle, FISHING TACKLE' },
  { Id: 24723, Code: '8139', Name: 'Tier-on, fish hook mfr' },
  { Id: 24724, Code: '8139', Name: 'Tier-up, cloth hat mfr' },
  { Id: 24725, Code: '8139', Name: 'Tipper, enamelling' },
  { Id: 24726, Code: '8139', Name: 'Topper, fur dyeing' },
  { Id: 24727, Code: '8139', Name: 'Toucher-up, vehicle mfr' },
  { Id: 24728, Code: '8139', Name: 'Transferer' },
  { Id: 24729, Code: '8139', Name: 'Transferer, japanning, enamelling' },
  { Id: 24730, Code: '8139', Name: 'Turner, sheet, rolling mill' },
  { Id: 24731, Code: '8139', Name: 'Turner-in, steel mfr' },
  { Id: 24732, Code: '8139', Name: 'Turner-over, rolling mill' },
  { Id: 24733, Code: '8139', Name: 'Turner-up, rolling mill' },
  { Id: 24734, Code: '8139', Name: 'Typer, textile mfr' },
  { Id: 24735, Code: '8139', Name: 'Varnisher' },
  { Id: 24736, Code: '8139', Name: 'Welder, plastic' },
  { Id: 24737, Code: '8139', Name: 'Welder, plastics goods mfr' },
  { Id: 24738, Code: '8139', Name: 'Wheeler, vitreous enamelling' },
  { Id: 24739, Code: '8139', Name: 'Wicker' },
  { Id: 24740, Code: '8139', Name: 'Wirer and paperer' },
  { Id: 24741, Code: '8139', Name: 'Worker, enamel' },
  { Id: 24742, Code: '8139', Name: 'Worker, stencil' },
  { Id: 24743, Code: '8139', Name: 'Worker, tackle, fishing' },
  { Id: 24744, Code: '8139', Name: 'Writer, dial' },
  { Id: 24745, Code: '8141', Name: 'Erector, scaffolding' },
  { Id: 24746, Code: '8141', Name: 'Erector, shuttering, metal' },
  { Id: 24747, Code: '8141', Name: 'Erector, stage, ship repairing' },
  { Id: 24748, Code: '8141', Name: 'Fitter, rope and belt' },
  { Id: 24749, Code: '8141', Name: 'Fitter, ropery, wire' },
  { Id: 24750, Code: '8141', Name: 'Fixer, scaffolding' },
  { Id: 24751, Code: '8141', Name: 'Foreman, scaffolder' },
  { Id: 24752, Code: '8141', Name: 'Hanger, bell, CHURCH BELLS' },
  { Id: 24753, Code: '8141', Name: 'Lifter, heavy' },
  { Id: 24754, Code: '8141', Name: 'Maker, gromet, wire rope, cable mfr' },
  { Id: 24755, Code: '8141', Name: 'Maker, grommet, wire rope, cable mfr' },
  { Id: 24756, Code: '8141', Name: 'Rigger' },
  { Id: 24757, Code: '8141', Name: 'Rigger, factory' },
  { Id: 24758, Code: '8141', Name: 'Rigger, film, television production' },
  { Id: 24759, Code: '8141', Name: 'Rigger, offshore' },
  { Id: 24760, Code: '8141', Name: 'Rigger, salvage' },
  { Id: 24761, Code: '8141', Name: 'Rigger, scaffolding' },
  { Id: 24762, Code: '8141', Name: "Rigger, ship's" },
  { Id: 24763, Code: '8141', Name: 'Rigger, stage, shipbuilding' },
  { Id: 24764, Code: '8141', Name: 'Ropeman, mining' },
  { Id: 24765, Code: '8141', Name: 'Scaffolder' },
  { Id: 24766, Code: '8141', Name: 'Scaffolder-foreman' },
  { Id: 24767, Code: '8141', Name: 'Smith, rope, coal mine' },
  { Id: 24768, Code: '8141', Name: 'Splicer, coal mine' },
  { Id: 24769, Code: '8141', Name: 'Splicer, rope, coal mine' },
  { Id: 24770, Code: '8141', Name: 'Splicer, rope, steel mfr' },
  { Id: 24771, Code: '8141', Name: 'Splicer, ROPE, WIRE' },
  { Id: 24772, Code: '8141', Name: 'Splicer, wire' },
  { Id: 24773, Code: '8141', Name: 'Stager, shipbuilding' },
  { Id: 24774, Code: '8141', Name: 'Tackleman, steelworks' },
  { Id: 24775, Code: '8141', Name: 'Tester, rope, coal mine' },
  { Id: 24776, Code: '8142', Name: 'Asphalter' },
  { Id: 24777, Code: '8142', Name: 'Assembler, concrete' },
  { Id: 24778, Code: '8142', Name: 'Chipper, road surfacing' },
  { Id: 24779, Code: '8142', Name: 'Concreter' },
  { Id: 24780, Code: '8142', Name: 'Constructor, road' },
  { Id: 24781, Code: '8142', Name: 'Contractor, lining, road' },
  { Id: 24782, Code: '8142', Name: 'Contractor, road' },
  { Id: 24783, Code: '8142', Name: 'Erector, concrete' },
  {
    Id: 24784,
    Code: '8142',
    Name: 'Finisher, concrete, building construction'
  },
  { Id: 24785, Code: '8142', Name: 'Flagger' },
  { Id: 24786, Code: '8142', Name: 'Hand, maintenance, highways' },
  {
    Id: 24787,
    Code: '8142',
    Name: 'Hand, maintenance, local government: highways dept'
  },
  { Id: 24788, Code: '8142', Name: 'Inspector, road' },
  { Id: 24789, Code: '8142', Name: 'Inspector, roads' },
  { Id: 24790, Code: '8142', Name: 'Layer, asphalt' },
  { Id: 24791, Code: '8142', Name: 'Layer, concrete' },
  { Id: 24792, Code: '8142', Name: 'Layer, floor, concrete' },
  { Id: 24793, Code: '8142', Name: 'Layer, kerb' },
  { Id: 24794, Code: '8142', Name: 'Layer, road' },
  { Id: 24795, Code: '8142', Name: 'Layer, slab' },
  { Id: 24796, Code: '8142', Name: 'Layer, stone' },
  { Id: 24797, Code: '8142', Name: 'Layer, surface, gas supplier' },
  { Id: 24798, Code: '8142', Name: 'Layer, tar' },
  { Id: 24799, Code: '8142', Name: 'Layer, tarmac' },
  { Id: 24800, Code: '8142', Name: 'Lengthman, local government' },
  { Id: 24801, Code: '8142', Name: 'Lengthman, road' },
  { Id: 24802, Code: '8142', Name: 'Leveller, concrete' },
  { Id: 24803, Code: '8142', Name: 'Liner, road' },
  { Id: 24804, Code: '8142', Name: 'Maker, road' },
  { Id: 24805, Code: '8142', Name: 'Man, road, building construction' },
  { Id: 24806, Code: '8142', Name: 'Man, road, local government' },
  { Id: 24807, Code: '8142', Name: 'Man, surface, civil engineering' },
  { Id: 24808, Code: '8142', Name: 'Man, tar, building construction' },
  { Id: 24809, Code: '8142', Name: 'Marker, road' },
  { Id: 24810, Code: '8142', Name: 'Mason, street' },
  { Id: 24811, Code: '8142', Name: 'Mender, road' },
  { Id: 24812, Code: '8142', Name: 'Operative, highways' },
  { Id: 24813, Code: '8142', Name: 'Paver' },
  { Id: 24814, Code: '8142', Name: 'Paver, tar' },
  { Id: 24815, Code: '8142', Name: 'Pavior' },
  { Id: 24816, Code: '8142', Name: 'Pavior, tar' },
  { Id: 24817, Code: '8142', Name: 'Paviour' },
  { Id: 24818, Code: '8142', Name: 'Paviour, tar' },
  { Id: 24819, Code: '8142', Name: 'Raker, asphalt' },
  { Id: 24820, Code: '8142', Name: 'Raker, tarmac' },
  { Id: 24821, Code: '8142', Name: 'Reinstater, ROAD' },
  { Id: 24822, Code: '8142', Name: 'Repairer, road' },
  { Id: 24823, Code: '8142', Name: 'Roadman' },
  { Id: 24824, Code: '8142', Name: 'Roadsman, local government' },
  { Id: 24825, Code: '8142', Name: 'Screeder' },
  { Id: 24826, Code: '8142', Name: 'Setter, kerb' },
  { Id: 24827, Code: '8142', Name: 'Spreader, asphalt' },
  { Id: 24828, Code: '8142', Name: 'Spreader, tar' },
  { Id: 24829, Code: '8142', Name: 'Superintendent, highways' },
  { Id: 24830, Code: '8142', Name: 'Surfacer, road' },
  { Id: 24831, Code: '8142', Name: 'Tamperman' },
  { Id: 24832, Code: '8142', Name: 'Tarmacer' },
  { Id: 24833, Code: '8142', Name: 'Worker, concrete' },
  { Id: 24834, Code: '8142', Name: 'Worker, reinstatement, ROAD' },
  { Id: 24835, Code: '8142', Name: 'Worker, road' },
  { Id: 24836, Code: '8142', Name: 'Worker, surface, road' },
  { Id: 24837, Code: '8142', Name: 'Worker, tarmac' },
  { Id: 24838, Code: '8143', Name: 'Constructor, way, permanent' },
  { Id: 24839, Code: '8143', Name: 'Detector, flaw, rail, ultrasonic' },
  {
    Id: 24840,
    Code: '8143',
    Name: 'Engineer, ultrasonic, railway maintenance and repair'
  },
  { Id: 24841, Code: '8143', Name: 'Engineer, ultrasonic, railways' },
  {
    Id: 24842,
    Code: '8143',
    Name: 'Examiner, bridge, railway maintenance and repair'
  },
  { Id: 24843, Code: '8143', Name: 'Examiner, bridge, railways' },
  { Id: 24844, Code: '8143', Name: 'Examiner, track' },
  {
    Id: 24845,
    Code: '8143',
    Name: 'Hand, maintenance, railway maintenance and repair'
  },
  { Id: 24846, Code: '8143', Name: 'Inspector, line, railways' },
  {
    Id: 24847,
    Code: '8143',
    Name: 'Inspector, ultrasonic, railway maintenance and repair'
  },
  { Id: 24848, Code: '8143', Name: 'Inspector, ultrasonic, railways' },
  { Id: 24849, Code: '8143', Name: 'Installer, way, permanent' },
  { Id: 24850, Code: '8143', Name: 'Layer, block, mine' },
  { Id: 24851, Code: '8143', Name: 'Layer, plate' },
  { Id: 24852, Code: '8143', Name: 'Layer, rail' },
  {
    Id: 24853,
    Code: '8143',
    Name: 'Layer, road, railway maintenance and repair'
  },
  { Id: 24854, Code: '8143', Name: 'Layer, road, railways' },
  { Id: 24855, Code: '8143', Name: 'Layer, track' },
  {
    Id: 24856,
    Code: '8143',
    Name: 'Lengthman, railway maintenance and repair'
  },
  { Id: 24857, Code: '8143', Name: 'Lengthman, railways' },
  {
    Id: 24858,
    Code: '8143',
    Name: 'Man, maintenance, railway maintenance and repair'
  },
  {
    Id: 24859,
    Code: '8143',
    Name: 'Man, maintenance, track, railway maintenance and repair'
  },
  { Id: 24860, Code: '8143', Name: 'Man, maintenance, track, railways' },
  { Id: 24861, Code: '8143', Name: 'Man, maintenance, transport: railways' },
  { Id: 24862, Code: '8143', Name: 'Man, road, mining' },
  { Id: 24863, Code: '8143', Name: 'Man, way, permanent' },
  { Id: 24864, Code: '8143', Name: 'Operator, detector, flaw, rail, ultra' },
  {
    Id: 24865,
    Code: '8143',
    Name: 'Operator, ultrasonic, railway maintenance and repair'
  },
  { Id: 24866, Code: '8143', Name: 'Operator, ultrasonic, railways' },
  { Id: 24867, Code: '8143', Name: 'Patrol, railway maintenance and repair' },
  { Id: 24868, Code: '8143', Name: 'Patrol, railways' },
  {
    Id: 24869,
    Code: '8143',
    Name: 'Patrolman, railway maintenance and repair'
  },
  { Id: 24870, Code: '8143', Name: 'Patrolman, railways' },
  { Id: 24871, Code: '8143', Name: 'Railman, coal mine' },
  { Id: 24872, Code: '8143', Name: 'Relayer, railway maintenance and repair' },
  { Id: 24873, Code: '8143', Name: 'Relayer, railways' },
  { Id: 24874, Code: '8143', Name: 'Repairer, railway' },
  { Id: 24875, Code: '8143', Name: 'Repairer, road, mine: not coal' },
  { Id: 24876, Code: '8143', Name: 'Roadsman, mining' },
  { Id: 24877, Code: '8143', Name: 'Setter, road' },
  {
    Id: 24878,
    Code: '8143',
    Name: 'Sub-inspector, railway maintenance and repair'
  },
  { Id: 24879, Code: '8143', Name: 'Sub-inspector, railways: engineering' },
  { Id: 24880, Code: '8143', Name: 'Trackman, mine: not coal' },
  { Id: 24881, Code: '8143', Name: 'Trackman, railway maintenance and repair' },
  { Id: 24882, Code: '8143', Name: 'Trackman, railways' },
  { Id: 24883, Code: '8143', Name: 'Wagonwayman' },
  { Id: 24884, Code: '8143', Name: 'Worker, maintenance, track, rail' },
  { Id: 24885, Code: '8149', Name: 'Asphalter, mastic' },
  { Id: 24886, Code: '8149', Name: 'Assistant, estate, MAINTENANCE' },
  { Id: 24887, Code: '8149', Name: 'Assistant, estates, MAINTENANCE' },
  { Id: 24888, Code: '8149', Name: 'Assistant, maintenance' },
  { Id: 24889, Code: '8149', Name: 'Blaster, sand, building construction' },
  { Id: 24890, Code: '8149', Name: 'Borer, tunnel' },
  { Id: 24891, Code: '8149', Name: 'Cleaner, building' },
  { Id: 24892, Code: '8149', Name: 'Cleaner, stone' },
  { Id: 24893, Code: '8149', Name: 'Contractor, building construction' },
  { Id: 24894, Code: '8149', Name: 'Contractor, demolition' },
  { Id: 24895, Code: '8149', Name: 'Contractor, engineering, civil' },
  { Id: 24896, Code: '8149', Name: "Contractor, engineer's, civil" },
  { Id: 24897, Code: '8149', Name: 'Contractor, general' },
  { Id: 24898, Code: '8149', Name: 'Contractor, pipe lagging' },
  { Id: 24899, Code: '8149', Name: 'Contractor, works, public' },
  { Id: 24900, Code: '8149', Name: 'Corer, hard' },
  { Id: 24901, Code: '8149', Name: 'Demolisher' },
  { Id: 24902, Code: '8149', Name: 'Dismantler, building construction' },
  { Id: 24903, Code: '8149', Name: 'Engineer, demolition' },
  { Id: 24904, Code: '8149', Name: 'Engineer, drainage' },
  { Id: 24905, Code: '8149', Name: 'Engineer, explosives, demolition' },
  { Id: 24906, Code: '8149', Name: 'Engineer, insulating' },
  { Id: 24907, Code: '8149', Name: 'Engineer, insulation, thermal' },
  { Id: 24908, Code: '8149', Name: 'Engineer, lining, chimney' },
  { Id: 24909, Code: '8149', Name: 'Erector, building, portable' },
  { Id: 24910, Code: '8149', Name: 'Erector, ceiling' },
  { Id: 24911, Code: '8149', Name: 'Erector, garage' },
  { Id: 24912, Code: '8149', Name: 'Erector, greenhouse' },
  { Id: 24913, Code: '8149', Name: 'Erector, prefab' },
  { Id: 24914, Code: '8149', Name: 'Erector, sign' },
  { Id: 24915, Code: '8149', Name: 'Firer, shot, civil engineering' },
  { Id: 24916, Code: '8149', Name: 'Fitter, blind' },
  { Id: 24917, Code: '8149', Name: 'Fitter, ceiling' },
  { Id: 24918, Code: '8149', Name: 'Fitter, distribution, gas supplier' },
  { Id: 24919, Code: '8149', Name: 'Fitter, distribution, water works' },
  { Id: 24920, Code: '8149', Name: 'Fitter, mains, water supplier' },
  { Id: 24921, Code: '8149', Name: 'Fitter, pipe, drain' },
  { Id: 24922, Code: '8149', Name: 'Fitter, shop blind mfr' },
  { Id: 24923, Code: '8149', Name: 'Fitter, sign' },
  { Id: 24924, Code: '8149', Name: 'Fitter, sun-blind' },
  { Id: 24925, Code: '8149', Name: 'Fixer, blind' },
  { Id: 24926, Code: '8149', Name: 'Fixer, board, plaster' },
  { Id: 24927, Code: '8149', Name: 'Fixer, ceiling' },
  { Id: 24928, Code: '8149', Name: 'Fixer, frame, metal' },
  { Id: 24929, Code: '8149', Name: 'Fixer, sign' },
  { Id: 24930, Code: '8149', Name: 'Fixer, wall, curtain' },
  { Id: 24931, Code: '8149', Name: 'Grouter' },
  { Id: 24932, Code: '8149', Name: 'Hand, maintenance, building construction' },
  { Id: 24933, Code: '8149', Name: 'Handyman, general' },
  { Id: 24934, Code: '8149', Name: 'Handyman, maintenance' },
  { Id: 24935, Code: '8149', Name: 'Handyman, nos' },
  { Id: 24936, Code: '8149', Name: 'Installer, ceiling, suspended' },
  { Id: 24937, Code: '8149', Name: 'Installer, wall, cavity' },
  { Id: 24938, Code: '8149', Name: 'Insulator, building' },
  { Id: 24939, Code: '8149', Name: 'Insulator, construction' },
  { Id: 24940, Code: '8149', Name: 'Insulator, thermal' },
  { Id: 24941, Code: '8149', Name: 'Insulator, wall, cavity' },
  { Id: 24942, Code: '8149', Name: 'Jigger, spindle' },
  { Id: 24943, Code: '8149', Name: 'Joiner, pipe' },
  { Id: 24944, Code: '8149', Name: 'Jointer, civil engineering' },
  { Id: 24945, Code: '8149', Name: 'Jointer, gas supplier' },
  { Id: 24946, Code: '8149', Name: 'Jointer, pipe' },
  { Id: 24947, Code: '8149', Name: 'Jointer, water company' },
  {
    Id: 24948,
    Code: '8149',
    Name: 'Layer and fixer, patent flooring and roofing'
  },
  { Id: 24949, Code: '8149', Name: 'Layer, cable' },
  { Id: 24950, Code: '8149', Name: 'Layer, drain' },
  { Id: 24951, Code: '8149', Name: 'Layer, main' },
  { Id: 24952, Code: '8149', Name: 'Layer, mains' },
  { Id: 24953, Code: '8149', Name: 'Layer, pipe' },
  { Id: 24954, Code: '8149', Name: 'Layer, service' },
  { Id: 24955, Code: '8149', Name: 'Layer, track, PIPE' },
  { Id: 24956, Code: '8149', Name: 'Liner, dry' },
  { Id: 24957, Code: '8149', Name: 'Man, leading, building construction' },
  { Id: 24958, Code: '8149', Name: 'Man, maintenance' },
  { Id: 24959, Code: '8149', Name: 'Man, mattock' },
  { Id: 24960, Code: '8149', Name: 'Miner, tunnel' },
  { Id: 24961, Code: '8149', Name: 'Officer, maintenance' },
  { Id: 24962, Code: '8149', Name: 'Officer, signs, motoring organisation' },
  { Id: 24963, Code: '8149', Name: 'Operative, building' },
  { Id: 24964, Code: '8149', Name: 'Operative, concrete' },
  { Id: 24965, Code: '8149', Name: 'Operative, construction' },
  { Id: 24966, Code: '8149', Name: 'Operative, demolition' },
  { Id: 24967, Code: '8149', Name: 'Operative, engineering, civil' },
  { Id: 24968, Code: '8149', Name: 'Operator, building' },
  { Id: 24969, Code: '8149', Name: 'Partitioner, building construction' },
  { Id: 24970, Code: '8149', Name: 'Pitcher, building construction' },
  { Id: 24971, Code: '8149', Name: 'Pitcher, stone' },
  { Id: 24972, Code: '8149', Name: 'Polisher, floor, building construction' },
  { Id: 24973, Code: '8149', Name: 'Proofer, draught' },
  { Id: 24974, Code: '8149', Name: 'Proofer, fire' },
  { Id: 24975, Code: '8149', Name: 'Remover, asbestos' },
  { Id: 24976, Code: '8149', Name: 'Repairer, bridge' },
  { Id: 24977, Code: '8149', Name: "Repairer, builder's" },
  { Id: 24978, Code: '8149', Name: 'Repairer, building' },
  { Id: 24979, Code: '8149', Name: 'Repairer, concrete' },
  { Id: 24980, Code: '8149', Name: 'Repairer, house' },
  { Id: 24981, Code: '8149', Name: 'Repairer, property' },
  { Id: 24982, Code: '8149', Name: 'Restorer, stone' },
  { Id: 24983, Code: '8149', Name: 'Sinker, mine sinking' },
  { Id: 24984, Code: '8149', Name: 'Sinker, pit' },
  { Id: 24985, Code: '8149', Name: 'Sinker, shaft' },
  { Id: 24986, Code: '8149', Name: 'Sinker, well' },
  { Id: 24987, Code: '8149', Name: 'Sprayer, insulation' },
  { Id: 24988, Code: '8149', Name: 'Sprayer, insulation contracting' },
  { Id: 24989, Code: '8149', Name: 'Stripper, asbestos' },
  { Id: 24990, Code: '8149', Name: 'Taper and jointer' },
  { Id: 24991, Code: '8149', Name: 'Taper, Ames' },
  { Id: 24992, Code: '8149', Name: 'Technician, insulation, wall, cavity' },
  { Id: 24993, Code: '8149', Name: 'Technician, wall, cavity' },
  { Id: 24994, Code: '8149', Name: 'Topman, demolition' },
  { Id: 24995, Code: '8149', Name: 'Tunneller' },
  { Id: 24996, Code: '8149', Name: 'Worker, demolition' },
  { Id: 24997, Code: '8149', Name: 'Worker, distribution, mains services' },
  { Id: 24998, Code: '8211', Name: 'Carman, coal merchants' },
  { Id: 24999, Code: '8211', Name: 'Carrier, coal' },
  { Id: 25000, Code: '8211', Name: 'Carrier, general' },
  { Id: 25001, Code: '8211', Name: 'Carrier, mine: not coal' },
  { Id: 25002, Code: '8211', Name: 'Carrier, nos' },
  { Id: 25003, Code: '8211', Name: 'Carrier, railway' },
  { Id: 25004, Code: '8211', Name: 'Carrier, transport' },
  { Id: 25005, Code: '8211', Name: 'Carter, coal' },
  { Id: 25006, Code: '8211', Name: 'Coalman, DELIVERY' },
  { Id: 25007, Code: '8211', Name: 'Collector, milk' },
  { Id: 25008, Code: '8211', Name: 'Collector-driver, refuse' },
  { Id: 25009, Code: '8211', Name: 'Contractor, cartage' },
  { Id: 25010, Code: '8211', Name: 'Contractor, disposal, waste' },
  { Id: 25011, Code: '8211', Name: 'Contractor, haulage' },
  { Id: 25012, Code: '8211', Name: 'Contractor, transport' },
  { Id: 25013, Code: '8211', Name: 'Contractor, transport, ROAD' },
  { Id: 25014, Code: '8211', Name: 'Deliverer, coal' },
  { Id: 25015, Code: '8211', Name: 'Deliveryman, coal' },
  { Id: 25016, Code: '8211', Name: 'Drayman' },
  { Id: 25017, Code: '8211', Name: 'Driver, ARTICULATED LORRY' },
  { Id: 25018, Code: '8211', Name: 'Driver, delivery, HGV' },
  { Id: 25019, Code: '8211', Name: 'Driver, drop, multi, HGV' },
  { Id: 25020, Code: '8211', Name: 'Driver, dustcart' },
  { Id: 25021, Code: '8211', Name: 'Driver, goods' },
  { Id: 25022, Code: '8211', Name: 'Driver, haulage, motor' },
  { Id: 25023, Code: '8211', Name: 'Driver, haulage, road transport' },
  { Id: 25024, Code: '8211', Name: 'Driver, HGV' },
  { Id: 25025, Code: '8211', Name: 'Driver, HGV/LGV' },
  { Id: 25026, Code: '8211', Name: 'Driver, LGV' },
  { Id: 25027, Code: '8211', Name: 'Driver, LGV/HGV' },
  { Id: 25028, Code: '8211', Name: 'Driver, library, mobile' },
  { Id: 25029, Code: '8211', Name: 'Driver, loader' },
  { Id: 25030, Code: '8211', Name: 'Driver, loader, low' },
  { Id: 25031, Code: '8211', Name: 'Driver, local government: cleansing dept' },
  { Id: 25032, Code: '8211', Name: 'Driver, lorry' },
  { Id: 25033, Code: '8211', Name: 'Driver, low-loader' },
  { Id: 25034, Code: '8211', Name: 'Driver, multi-drop, HGV' },
  { Id: 25035, Code: '8211', Name: 'Driver, pump, concrete' },
  { Id: 25036, Code: '8211', Name: 'Driver, refuse' },
  { Id: 25037, Code: '8211', Name: 'Driver, removal' },
  { Id: 25038, Code: '8211', Name: 'Driver, rolley' },
  { Id: 25039, Code: '8211', Name: 'Driver, rolly' },
  { Id: 25040, Code: '8211', Name: 'Driver, scammell' },
  { Id: 25041, Code: '8211', Name: 'Driver, tanker' },
  { Id: 25042, Code: '8211', Name: 'Driver, tipper' },
  { Id: 25043, Code: '8211', Name: 'Driver, tractor, road transport' },
  { Id: 25044, Code: '8211', Name: 'Driver, transport' },
  { Id: 25045, Code: '8211', Name: 'Driver, transporter' },
  { Id: 25046, Code: '8211', Name: 'Driver, truck, road transport' },
  { Id: 25047, Code: '8211', Name: 'Driver, vehicle, articulated' },
  { Id: 25048, Code: '8211', Name: 'Driver, vehicle, goods, large' },
  { Id: 25049, Code: '8211', Name: 'Driver, vehicle, motor' },
  { Id: 25050, Code: '8211', Name: 'Driver, VEHICLES, GOODS TRANSPORT' },
  { Id: 25051, Code: '8211', Name: 'Driver, wagon' },
  { Id: 25052, Code: '8211', Name: 'Driver-fitter' },
  { Id: 25053, Code: '8211', Name: 'Driver-mechanic' },
  { Id: 25054, Code: '8211', Name: 'Hand, haulage, haulage contractor' },
  { Id: 25055, Code: '8211', Name: 'Hauler, coal, retail trade' },
  { Id: 25056, Code: '8211', Name: 'Hauler, timber' },
  { Id: 25057, Code: '8211', Name: 'Haulier' },
  { Id: 25058, Code: '8211', Name: 'Haulier, general' },
  { Id: 25059, Code: '8211', Name: 'Haulier, timber' },
  { Id: 25060, Code: '8211', Name: 'Loader-driver, HEAVY GOODS VEHICLES HGV' },
  { Id: 25061, Code: '8211', Name: 'Man, coal, coal merchants' },
  { Id: 25062, Code: '8211', Name: 'Operator, transport' },
  { Id: 25063, Code: '8211', Name: 'Owner, HEAVY GOODS VEHICLES HGV' },
  { Id: 25064, Code: '8211', Name: 'Owner-driver, haulage service' },
  { Id: 25065, Code: '8211', Name: 'Remover, cattle' },
  { Id: 25066, Code: '8211', Name: 'Roundsman, coal delivery' },
  { Id: 25067, Code: '8211', Name: 'Rullyman' },
  { Id: 25068, Code: '8211', Name: 'Shunter, HGV' },
  { Id: 25069, Code: '8211', Name: 'Transporter, cattle' },
  { Id: 25070, Code: '8211', Name: 'Transporter, livestock' },
  { Id: 25071, Code: '8211', Name: 'Truckman, road transport' },
  { Id: 25072, Code: '8212', Name: 'Assistant, delivery, customer' },
  { Id: 25073, Code: '8212', Name: 'Carman' },
  { Id: 25074, Code: '8212', Name: 'Collector, bag, charity' },
  { Id: 25075, Code: '8212', Name: 'Collector, egg' },
  { Id: 25076, Code: '8212', Name: 'Collector, sack' },
  { Id: 25077, Code: '8212', Name: 'Courier-driver' },
  { Id: 25078, Code: '8212', Name: 'Deliverer, FAST FOOD' },
  { Id: 25079, Code: '8212', Name: 'Deliveryman' },
  { Id: 25080, Code: '8212', Name: 'Driver' },
  { Id: 25081, Code: '8212', Name: 'Driver, courier' },
  { Id: 25082, Code: '8212', Name: 'Driver, delivery' },
  { Id: 25083, Code: '8212', Name: 'Driver, delivery, parcel' },
  { Id: 25084, Code: '8212', Name: 'Driver, delivery, parts' },
  { Id: 25085, Code: '8212', Name: 'Driver, despatch' },
  { Id: 25086, Code: '8212', Name: 'Driver, drop, multi' },
  { Id: 25087, Code: '8212', Name: 'Driver, mail, motor' },
  { Id: 25088, Code: '8212', Name: 'Driver, motor' },
  { Id: 25089, Code: '8212', Name: 'Driver, motor, railway' },
  { Id: 25090, Code: '8212', Name: 'Driver, multi-drop' },
  { Id: 25091, Code: '8212', Name: 'Driver, recovery' },
  { Id: 25092, Code: '8212', Name: 'Driver, van' },
  { Id: 25093, Code: '8212', Name: 'Driver, van, delivery' },
  { Id: 25094, Code: '8212', Name: 'Driver, warehouse' },
  { Id: 25095, Code: '8212', Name: 'Driver-courier' },
  { Id: 25096, Code: '8212', Name: 'Driver-custodian, security services' },
  { Id: 25097, Code: '8212', Name: 'Driver-packer' },
  { Id: 25098, Code: '8212', Name: 'Driver-storeman' },
  { Id: 25099, Code: '8212', Name: 'Driver-warehouseman' },
  { Id: 25100, Code: '8212', Name: 'Leader, coal' },
  {
    Id: 25101,
    Code: '8212',
    Name: 'Member, team, service, customer, delivery service'
  },
  { Id: 25102, Code: '8212', Name: 'Operator, recovery, VEHICLE' },
  { Id: 25103, Code: '8212', Name: 'Packer-driver' },
  { Id: 25104, Code: '8212', Name: 'Porter-driver' },
  { Id: 25105, Code: '8212', Name: 'Storeman-driver' },
  { Id: 25106, Code: '8212', Name: 'Warehouseman-driver' },
  { Id: 25107, Code: '8213', Name: 'Busman' },
  { Id: 25108, Code: '8213', Name: 'Driver, bus' },
  { Id: 25109, Code: '8213', Name: 'Driver, bus service' },
  { Id: 25110, Code: '8213', Name: 'Driver, bus, mini' },
  { Id: 25111, Code: '8213', Name: 'Driver, coach' },
  { Id: 25112, Code: '8213', Name: 'Driver, minibus' },
  { Id: 25113, Code: '8213', Name: 'Driver, PCV' },
  { Id: 25114, Code: '8213', Name: 'Driver, PSV' },
  { Id: 25115, Code: '8213', Name: 'Driver, vehicle, carrying, passenger' },
  { Id: 25116, Code: '8213', Name: 'Driver, vehicle, service, public' },
  {
    Id: 25117,
    Code: '8213',
    Name: 'Driver, VEHICLES, PASSENGER TRANSPORT, BUS, COACH'
  },
  { Id: 25118, Code: '8213', Name: 'Driver-conductor' },
  { Id: 25119, Code: '8213', Name: 'Driver-fitter, PUBLIC SERVICE VEHICLE' },
  { Id: 25120, Code: '8213', Name: 'Driver-mechanic, BUS, COACH' },
  { Id: 25121, Code: '8213', Name: 'Operator, coach' },
  { Id: 25122, Code: '8213', Name: 'Operator, person, one' },
  { Id: 25123, Code: '8213', Name: 'Shunter, bus' },
  { Id: 25124, Code: '8213', Name: 'Shunter, road transport' },
  { Id: 25125, Code: '8214', Name: 'Cabbie' },
  { Id: 25126, Code: '8214', Name: 'Chauffeur' },
  { Id: 25127, Code: '8214', Name: 'Chauffeur-gardener' },
  { Id: 25128, Code: '8214', Name: 'Chauffeur-handyman' },
  { Id: 25129, Code: '8214', Name: 'Chauffeur-mechanic' },
  { Id: 25130, Code: '8214', Name: 'Chauffeur-valet' },
  { Id: 25131, Code: '8214', Name: 'Collector, car' },
  { Id: 25132, Code: '8214', Name: 'Deliverer, car' },
  {
    Id: 25133,
    Code: '8214',
    Name: 'Driver and collector, car delivery service'
  },
  { Id: 25134, Code: '8214', Name: 'Driver and collector, coal mine' },
  { Id: 25135, Code: '8214', Name: 'Driver, cab' },
  { Id: 25136, Code: '8214', Name: 'Driver, cab, mini' },
  { Id: 25137, Code: '8214', Name: 'Driver, car delivery service' },
  { Id: 25138, Code: '8214', Name: 'Driver, car, motor' },
  { Id: 25139, Code: '8214', Name: 'Driver, carriage' },
  { Id: 25140, Code: '8214', Name: 'Driver, delivery, car' },
  { Id: 25141, Code: '8214', Name: 'Driver, delivery, car delivery service' },
  { Id: 25142, Code: '8214', Name: 'Driver, hearse' },
  { Id: 25143, Code: '8214', Name: 'Driver, hire, private' },
  { Id: 25144, Code: '8214', Name: 'Driver, minicab' },
  { Id: 25145, Code: '8214', Name: 'Driver, motor, funeral directors' },
  { Id: 25146, Code: '8214', Name: 'Driver, police, civilian' },
  { Id: 25147, Code: '8214', Name: 'Driver, taxi' },
  { Id: 25148, Code: '8214', Name: 'Driver, taxicab' },
  { Id: 25149, Code: '8214', Name: 'Driver, VEHICLES, PASSENGER TRANSPORT' },
  { Id: 25150, Code: '8214', Name: 'Driver-handyman' },
  {
    Id: 25151,
    Code: '8214',
    Name: 'Driver-mechanic, PASSENGER TRANSPORT VEHICLES'
  },
  { Id: 25152, Code: '8214', Name: 'Gardener-chauffeur' },
  { Id: 25153, Code: '8214', Name: 'Handyman-driver' },
  { Id: 25154, Code: '8214', Name: 'Operator, taxi' },
  { Id: 25155, Code: '8214', Name: 'Owner, carriages, hackney' },
  { Id: 25156, Code: '8214', Name: 'Owner, taxi' },
  { Id: 25157, Code: '8214', Name: 'Owner-driver, taxi' },
  { Id: 25158, Code: '8214', Name: 'Shunter, car' },
  { Id: 25159, Code: '8215', Name: 'Driver-instructor' },
  { Id: 25160, Code: '8215', Name: 'Instructor, cycle, motor' },
  { Id: 25161, Code: '8215', Name: 'Instructor, driving' },
  { Id: 25162, Code: '8215', Name: 'Instructor, driving school' },
  { Id: 25163, Code: '8215', Name: 'Instructor, HGV' },
  { Id: 25164, Code: '8215', Name: 'Instructor, motorcycle' },
  { Id: 25165, Code: '8221', Name: 'Attendant, cage' },
  { Id: 25166, Code: '8221', Name: 'Attendant, engine, winding' },
  { Id: 25167, Code: '8221', Name: 'Attendant, hoist' },
  { Id: 25168, Code: '8221', Name: 'Attendant, mouth, drift, coal mine' },
  { Id: 25169, Code: '8221', Name: 'Attendant, winch' },
  { Id: 25170, Code: '8221', Name: 'Banker, mine: not coal' },
  { Id: 25171, Code: '8221', Name: 'Banksman, coal mine' },
  { Id: 25172, Code: '8221', Name: 'Banksman, mine: not coal' },
  { Id: 25173, Code: '8221', Name: 'Banksman, pit, staple' },
  { Id: 25174, Code: '8221', Name: 'Banksman, salt works' },
  { Id: 25175, Code: '8221', Name: 'Cageman, mine: not coal' },
  { Id: 25176, Code: '8221', Name: 'Corporal, underground' },
  { Id: 25177, Code: '8221', Name: 'Donkeyman' },
  { Id: 25178, Code: '8221', Name: 'Driver, carrier, straddle' },
  { Id: 25179, Code: '8221', Name: 'Driver, crane' },
  { Id: 25180, Code: '8221', Name: 'Driver, derrick' },
  { Id: 25181, Code: '8221', Name: 'Driver, drum, steelworks' },
  { Id: 25182, Code: '8221', Name: 'Driver, elevator' },
  { Id: 25183, Code: '8221', Name: 'Driver, engine, cable' },
  { Id: 25184, Code: '8221', Name: 'Driver, engine, haulage' },
  { Id: 25185, Code: '8221', Name: 'Driver, engine, winding' },
  { Id: 25186, Code: '8221', Name: 'Driver, gantry' },
  { Id: 25187, Code: '8221', Name: 'Driver, grab' },
  { Id: 25188, Code: '8221', Name: 'Driver, haulage, mining' },
  { Id: 25189, Code: '8221', Name: 'Driver, hauler, coal mine' },
  { Id: 25190, Code: '8221', Name: 'Driver, hoist' },
  { Id: 25191, Code: '8221', Name: 'Driver, incline' },
  { Id: 25192, Code: '8221', Name: 'Driver, lift' },
  { Id: 25193, Code: '8221', Name: 'Driver, magnet, steelworks' },
  { Id: 25194, Code: '8221', Name: 'Driver, mail, paddy, coal mine' },
  { Id: 25195, Code: '8221', Name: 'Driver, motor, telpher' },
  { Id: 25196, Code: '8221', Name: 'Driver, paddy, coal mine' },
  { Id: 25197, Code: '8221', Name: 'Driver, skip, blast furnace' },
  { Id: 25198, Code: '8221', Name: 'Driver, tandem, coal mine' },
  { Id: 25199, Code: '8221', Name: 'Driver, tip' },
  { Id: 25200, Code: '8221', Name: 'Driver, winch' },
  { Id: 25201, Code: '8221', Name: 'Engineer, winding, coal mine' },
  { Id: 25202, Code: '8221', Name: 'Engineman, donkey, coal mine' },
  { Id: 25203, Code: '8221', Name: 'Engineman, haulage' },
  { Id: 25204, Code: '8221', Name: 'Engineman, winding' },
  { Id: 25205, Code: '8221', Name: 'Foreman, haulage, coal mine' },
  { Id: 25206, Code: '8221', Name: 'Hand, bank, coal mine' },
  { Id: 25207, Code: '8221', Name: 'Hand, bottom, coal mine' },
  { Id: 25208, Code: '8221', Name: 'Hand, cage, coal mine' },
  { Id: 25209, Code: '8221', Name: 'Hand, capstan, railways' },
  { Id: 25210, Code: '8221', Name: 'Hoister, crane' },
  { Id: 25211, Code: '8221', Name: 'Hoistman' },
  { Id: 25212, Code: '8221', Name: 'Keeper, gate, coal mine' },
  { Id: 25213, Code: '8221', Name: 'Lander, mine: not coal: below ground' },
  { Id: 25214, Code: '8221', Name: 'Lifter, steelworks' },
  { Id: 25215, Code: '8221', Name: 'Liftman, gantry' },
  { Id: 25216, Code: '8221', Name: 'Liftman, iron and steelworks' },
  { Id: 25217, Code: '8221', Name: 'Liftman, steam' },
  { Id: 25218, Code: '8221', Name: 'Man, capstan' },
  { Id: 25219, Code: '8221', Name: 'Man, crane' },
  { Id: 25220, Code: '8221', Name: 'Man, gantry' },
  { Id: 25221, Code: '8221', Name: 'Man, gantry, forge, steelworks' },
  { Id: 25222, Code: '8221', Name: 'Man, motor, haulage' },
  { Id: 25223, Code: '8221', Name: 'Man, motor, screen' },
  { Id: 25224, Code: '8221', Name: 'Man, runner, steelworks' },
  { Id: 25225, Code: '8221', Name: 'Onsetter' },
  { Id: 25226, Code: '8221', Name: 'Onsetter, pit, staple, coal mine' },
  { Id: 25227, Code: '8221', Name: 'Operator, bridge, coal mine' },
  { Id: 25228, Code: '8221', Name: 'Operator, crane' },
  { Id: 25229, Code: '8221', Name: 'Operator, elevator' },
  { Id: 25230, Code: '8221', Name: 'Operator, engine, winding' },
  { Id: 25231, Code: '8221', Name: 'Operator, hoist' },
  { Id: 25232, Code: '8221', Name: 'Operator, winch' },
  { Id: 25233, Code: '8221', Name: 'Runner, steelworks' },
  { Id: 25234, Code: '8221', Name: 'Signaller, mine: not coal' },
  { Id: 25235, Code: '8221', Name: 'Signalman, mining' },
  { Id: 25236, Code: '8221', Name: 'Spillager, coal mine' },
  { Id: 25237, Code: '8221', Name: 'Tenter, crane' },
  { Id: 25238, Code: '8221', Name: 'Tenter, hoist' },
  { Id: 25239, Code: '8221', Name: 'Wagoner, coal mine' },
  { Id: 25240, Code: '8221', Name: 'Winchman' },
  { Id: 25241, Code: '8221', Name: 'Winder, cage, coal mine' },
  { Id: 25242, Code: '8221', Name: 'Winder, engine, mining' },
  { Id: 25243, Code: '8221', Name: 'Winder, incline' },
  { Id: 25244, Code: '8221', Name: 'Winder, mining' },
  { Id: 25245, Code: '8221', Name: 'Winder, pit, staple' },
  { Id: 25246, Code: '8221', Name: 'Winder, skip, coal mine' },
  { Id: 25247, Code: '8221', Name: 'Winderman, coal mine' },
  { Id: 25248, Code: '8222', Name: 'Driver, car, scale' },
  { Id: 25249, Code: '8222', Name: 'Driver, car, weigh' },
  { Id: 25250, Code: '8222', Name: 'Driver, FLT' },
  { Id: 25251, Code: '8222', Name: 'Driver, fork-lift' },
  { Id: 25252, Code: '8222', Name: 'Driver, hyster' },
  { Id: 25253, Code: '8222', Name: 'Driver, internal' },
  { Id: 25254, Code: '8222', Name: 'Driver, lift, fork' },
  { Id: 25255, Code: '8222', Name: 'Driver, loader, side' },
  { Id: 25256, Code: '8222', Name: 'Driver, stacker' },
  { Id: 25257, Code: '8222', Name: 'Driver, transport, INTERNAL TRANSPORT' },
  { Id: 25258, Code: '8222', Name: 'Driver, truck' },
  { Id: 25259, Code: '8222', Name: 'Driver, truck, clamp' },
  { Id: 25260, Code: '8222', Name: 'Driver, truck, electric' },
  { Id: 25261, Code: '8222', Name: 'Driver, truck, fork' },
  { Id: 25262, Code: '8222', Name: 'Driver, truck, fork-lift' },
  { Id: 25263, Code: '8222', Name: 'Driver, truck, lift, fork' },
  { Id: 25264, Code: '8222', Name: 'Driver, truck, power' },
  { Id: 25265, Code: '8222', Name: 'Driver, truck, stacker' },
  { Id: 25266, Code: '8222', Name: 'Driver, truck, works' },
  { Id: 25267, Code: '8222', Name: 'Driver, WORKS TRUCKS' },
  { Id: 25268, Code: '8222', Name: 'Hyster' },
  { Id: 25269, Code: '8222', Name: 'Lifter, fork' },
  { Id: 25270, Code: '8222', Name: 'Operator, fork-lift' },
  { Id: 25271, Code: '8222', Name: 'Operator, lift, fork' },
  { Id: 25272, Code: '8222', Name: 'Operator, loader, side' },
  { Id: 25273, Code: '8222', Name: 'Operator, truck, fork' },
  { Id: 25274, Code: '8222', Name: 'Operator, truck, forklift' },
  { Id: 25275, Code: '8222', Name: 'Operator, truck, lift, fork' },
  { Id: 25276, Code: '8222', Name: 'Stacker-driver' },
  { Id: 25277, Code: '8223', Name: 'Attendant, AGRICULTURAL MACHINERY' },
  { Id: 25278, Code: '8223', Name: 'Baler, hay' },
  { Id: 25279, Code: '8223', Name: 'Binder, straw' },
  { Id: 25280, Code: '8223', Name: 'Contractor, spraying, crop' },
  { Id: 25281, Code: '8223', Name: 'Driver, AGRICULTURAL MACHINERY' },
  { Id: 25282, Code: '8223', Name: 'Driver, engine, agriculture' },
  { Id: 25283, Code: '8223', Name: 'Driver, machine, agriculture' },
  { Id: 25284, Code: '8223', Name: 'Driver, tractor, agriculture' },
  { Id: 25285, Code: '8223', Name: 'Driver, tractor, local government' },
  { Id: 25286, Code: '8223', Name: 'Driver-mechanic, AGRICULTURAL MACHINERY' },
  { Id: 25287, Code: '8223', Name: 'Feeder, drum, AGRICULTURAL MACHINERY' },
  { Id: 25288, Code: '8223', Name: 'Foreman, agricultural contracting' },
  { Id: 25289, Code: '8223', Name: 'Machinist, agricultural' },
  { Id: 25290, Code: '8223', Name: 'Man, plough' },
  { Id: 25291, Code: '8223', Name: 'Operator, AGRICULTURAL MACHINERY' },
  { Id: 25292, Code: '8223', Name: 'Sprayer, agricultural' },
  { Id: 25293, Code: '8223', Name: 'Sprayer, agricultural contracting' },
  { Id: 25294, Code: '8223', Name: 'Tractorman, agriculture' },
  {
    Id: 25295,
    Code: '8223',
    Name: 'Worker, agricultural, agricultural contracting'
  },
  { Id: 25296, Code: '8229', Name: 'Banksman, construction' },
  { Id: 25297, Code: '8229', Name: 'Carman, coke ovens' },
  { Id: 25298, Code: '8229', Name: 'Chargeman, copper, zinc refining' },
  { Id: 25299, Code: '8229', Name: 'Charger, coal' },
  { Id: 25300, Code: '8229', Name: 'Charger, coke ovens' },
  { Id: 25301, Code: '8229', Name: 'Charger, gas works' },
  { Id: 25302, Code: '8229', Name: 'Charger, mine: not coal' },
  { Id: 25303, Code: '8229', Name: 'Charger, ore' },
  { Id: 25304, Code: '8229', Name: 'Charger, oven' },
  { Id: 25305, Code: '8229', Name: 'Charger, retort, gas works' },
  { Id: 25306, Code: '8229', Name: 'Chargerman' },
  { Id: 25307, Code: '8229', Name: 'Dredgeman' },
  { Id: 25308, Code: '8229', Name: 'Dredger' },
  { Id: 25309, Code: '8229', Name: 'Dredgerman' },
  { Id: 25310, Code: '8229', Name: 'Dredgermaster' },
  { Id: 25311, Code: '8229', Name: 'Driver, bobcat' },
  { Id: 25312, Code: '8229', Name: 'Driver, bogie' },
  { Id: 25313, Code: '8229', Name: 'Driver, bowser, water' },
  { Id: 25314, Code: '8229', Name: 'Driver, building construction' },
  { Id: 25315, Code: '8229', Name: 'Driver, bulldozer' },
  { Id: 25316, Code: '8229', Name: 'Driver, car, charger' },
  { Id: 25317, Code: '8229', Name: 'Driver, car, coke, gas ovens' },
  { Id: 25318, Code: '8229', Name: 'Driver, charge' },
  { Id: 25319, Code: '8229', Name: 'Driver, charger' },
  { Id: 25320, Code: '8229', Name: 'Driver, crawler' },
  { Id: 25321, Code: '8229', Name: 'Driver, digger' },
  { Id: 25322, Code: '8229', Name: 'Driver, dozer, angle' },
  { Id: 25323, Code: '8229', Name: 'Driver, dredger' },
  { Id: 25324, Code: '8229', Name: 'Driver, drott' },
  { Id: 25325, Code: '8229', Name: 'Driver, dumper' },
  { Id: 25326, Code: '8229', Name: 'Driver, euclid' },
  { Id: 25327, Code: '8229', Name: 'Driver, excavator' },
  { Id: 25328, Code: '8229', Name: 'Driver, gearhead, coal mine' },
  { Id: 25329, Code: '8229', Name: 'Driver, grader' },
  { Id: 25330, Code: '8229', Name: 'Driver, JCB' },
  { Id: 25331, Code: '8229', Name: 'Driver, loader, building construction' },
  { Id: 25332, Code: '8229', Name: 'Driver, machine, civil engineering' },
  { Id: 25333, Code: '8229', Name: 'Driver, machine, gas works' },
  {
    Id: 25334,
    Code: '8229',
    Name: 'Driver, machine, spreading, ASPHALT, CONCRETE'
  },
  { Id: 25335, Code: '8229', Name: 'Driver, machine, stoking' },
  { Id: 25336, Code: '8229', Name: 'Driver, mine: not coal: above ground' },
  { Id: 25337, Code: '8229', Name: 'Driver, motor, dumpy' },
  { Id: 25338, Code: '8229', Name: 'Driver, navvy' },
  { Id: 25339, Code: '8229', Name: 'Driver, pile' },
  { Id: 25340, Code: '8229', Name: 'Driver, plant hire' },
  { Id: 25341, Code: '8229', Name: 'Driver, plant, building construction' },
  { Id: 25342, Code: '8229', Name: 'Driver, roller' },
  { Id: 25343, Code: '8229', Name: 'Driver, scoop' },
  { Id: 25344, Code: '8229', Name: 'Driver, scraper' },
  { Id: 25345, Code: '8229', Name: 'Driver, sentinel' },
  { Id: 25346, Code: '8229', Name: 'Driver, shovel' },
  { Id: 25347, Code: '8229', Name: 'Driver, steamroller' },
  { Id: 25348, Code: '8229', Name: 'Driver, sweeper' },
  { Id: 25349, Code: '8229', Name: 'Driver, sweeper, road, mechanical' },
  { Id: 25350, Code: '8229', Name: 'Driver, tool, mechanical' },
  { Id: 25351, Code: '8229', Name: 'Driver, tractor, building construction' },
  { Id: 25352, Code: '8229', Name: 'Driver, tractor, coal mine: opencast' },
  { Id: 25353, Code: '8229', Name: 'Driver, tractor, manufacturing' },
  { Id: 25354, Code: '8229', Name: 'Driver, tractor, mining' },
  { Id: 25355, Code: '8229', Name: 'Driver, trolley' },
  { Id: 25356, Code: '8229', Name: 'Driver, truck, bogie' },
  { Id: 25357, Code: '8229', Name: 'Driver, truck, dumper' },
  { Id: 25358, Code: '8229', Name: 'Driver, truck, ransom' },
  { Id: 25359, Code: '8229', Name: 'Dumper, mine: not coal' },
  { Id: 25360, Code: '8229', Name: 'Excavator, building construction' },
  { Id: 25361, Code: '8229', Name: 'Excavator, steelworks' },
  { Id: 25362, Code: '8229', Name: 'Filler, coke ovens' },
  { Id: 25363, Code: '8229', Name: 'Foreman, dredging' },
  { Id: 25364, Code: '8229', Name: 'Foreman, piling, civil engineering' },
  { Id: 25365, Code: '8229', Name: 'Foreman, plant, building construction' },
  { Id: 25366, Code: '8229', Name: 'Hammerman, pile driving' },
  { Id: 25367, Code: '8229', Name: 'Handler, telescopic' },
  { Id: 25368, Code: '8229', Name: 'Leveller, ASPHALT SPREADING MACHINE' },
  { Id: 25369, Code: '8229', Name: 'Machinist, building construction' },
  { Id: 25370, Code: '8229', Name: 'Machinist, chipping' },
  { Id: 25371, Code: '8229', Name: 'Machinist, coal mine: opencast' },
  { Id: 25372, Code: '8229', Name: 'Machinist, gully' },
  { Id: 25373, Code: '8229', Name: 'Machinist, levelling, rail' },
  { Id: 25374, Code: '8229', Name: 'Machinist, paving, concrete' },
  {
    Id: 25375,
    Code: '8229',
    Name: "Machinist, railways: civil engineer's dept"
  },
  { Id: 25376, Code: '8229', Name: 'Machinist, tamping' },
  { Id: 25377, Code: '8229', Name: 'Machinist, tracklaying' },
  { Id: 25378, Code: '8229', Name: 'Man, digger' },
  { Id: 25379, Code: '8229', Name: 'Man, navvy' },
  { Id: 25380, Code: '8229', Name: 'Man, plate, ASPHALT SPREADING' },
  { Id: 25381, Code: '8229', Name: 'Master, dredger' },
  { Id: 25382, Code: '8229', Name: 'Master, dredging' },
  { Id: 25383, Code: '8229', Name: "Mate, driver's, excavator" },
  { Id: 25384, Code: '8229', Name: 'Officer, deck, dredging contractors' },
  { Id: 25385, Code: '8229', Name: 'Operative, piling' },
  { Id: 25386, Code: '8229', Name: 'Operator, bulldozer' },
  { Id: 25387, Code: '8229', Name: 'Operator, caterpillar' },
  { Id: 25388, Code: '8229', Name: 'Operator, charger, rolling mill' },
  { Id: 25389, Code: '8229', Name: 'Operator, CONSTRUCTION MACHINERY' },
  { Id: 25390, Code: '8229', Name: 'Operator, dredger' },
  { Id: 25391, Code: '8229', Name: 'Operator, drill, pneumatic' },
  { Id: 25392, Code: '8229', Name: 'Operator, drott' },
  { Id: 25393, Code: '8229', Name: 'Operator, excavator' },
  { Id: 25394, Code: '8229', Name: 'Operator, hire, plant' },
  { Id: 25395, Code: '8229', Name: 'Operator, JCB' },
  { Id: 25396, Code: '8229', Name: 'Operator, line, drag' },
  { Id: 25397, Code: '8229', Name: 'Operator, plant' },
  { Id: 25398, Code: '8229', Name: "Operator, plant, builder's" },
  { Id: 25399, Code: '8229', Name: 'Operator, plant, building construction' },
  { Id: 25400, Code: '8229', Name: 'Operator, plant, constructional' },
  { Id: 25401, Code: '8229', Name: 'Operator, plant, heavy' },
  { Id: 25402, Code: '8229', Name: 'Operator, plant, opencast mining' },
  { Id: 25403, Code: '8229', Name: 'Operator, pump, concrete' },
  { Id: 25404, Code: '8229', Name: 'Operator, rig' },
  { Id: 25405, Code: '8229', Name: 'Owner-driver, plant hire' },
  { Id: 25406, Code: '8229', Name: 'Screwman, ASPHALT SPREADING' },
  { Id: 25407, Code: '8229', Name: 'Sprayer, tar' },
  { Id: 25408, Code: '8229', Name: 'Sweeper, road, mechanical' },
  { Id: 25409, Code: '8229', Name: 'Topman, pile driving' },
  { Id: 25410, Code: '8229', Name: 'Trucker' },
  { Id: 25411, Code: '8231', Name: 'Driver, car, rail' },
  { Id: 25412, Code: '8231', Name: 'Driver, car, shuttle' },
  { Id: 25413, Code: '8231', Name: 'Driver, diesel, railways' },
  { Id: 25414, Code: '8231', Name: 'Driver, engine, locomotive' },
  { Id: 25415, Code: '8231', Name: 'Driver, engine, railway' },
  { Id: 25416, Code: '8231', Name: 'Driver, engine, railways' },
  { Id: 25417, Code: '8231', Name: 'Driver, engine, shunting' },
  { Id: 25418, Code: '8231', Name: 'Driver, loco' },
  { Id: 25419, Code: '8231', Name: 'Driver, locomotive' },
  { Id: 25420, Code: '8231', Name: 'Driver, railways' },
  { Id: 25421, Code: '8231', Name: 'Driver, shunter' },
  { Id: 25422, Code: '8231', Name: 'Driver, train' },
  { Id: 25423, Code: '8231', Name: 'Driver, tram' },
  { Id: 25424, Code: '8231', Name: 'Engineman, railways' },
  { Id: 25425, Code: '8231', Name: 'Operator, train' },
  { Id: 25426, Code: '8232', Name: 'AB, shipping' },
  { Id: 25427, Code: '8232', Name: 'Attendant, boat' },
  { Id: 25428, Code: '8232', Name: 'Attendant, engine, shipping' },
  { Id: 25429, Code: '8232', Name: 'Attendant, leg, marine' },
  { Id: 25430, Code: '8232', Name: 'Attendant, room, engine, shipping' },
  { Id: 25431, Code: '8232', Name: 'Bargee' },
  { Id: 25432, Code: '8232', Name: 'Bargeman' },
  { Id: 25433, Code: '8232', Name: 'Boatman' },
  { Id: 25434, Code: '8232', Name: 'Boatman, foy' },
  { Id: 25435, Code: '8232', Name: 'Boatswain' },
  { Id: 25436, Code: '8232', Name: 'Bosun' },
  { Id: 25437, Code: '8232', Name: "Bo'sun" },
  { Id: 25438, Code: '8232', Name: 'Cleaner, engine, shipping' },
  { Id: 25439, Code: '8232', Name: 'Cobbleman' },
  { Id: 25440, Code: '8232', Name: 'Donkeyman, shipping' },
  { Id: 25441, Code: '8232', Name: 'Driver, engine, shipping' },
  { Id: 25442, Code: '8232', Name: 'Driver, launch' },
  { Id: 25443, Code: '8232', Name: 'Driver, shipping' },
  { Id: 25444, Code: '8232', Name: 'Driver, tug' },
  { Id: 25445, Code: '8232', Name: 'Electrician, chief, shipping' },
  { Id: 25446, Code: '8232', Name: 'Engineer, chief, fishing' },
  { Id: 25447, Code: '8232', Name: 'Engineer, dredger' },
  { Id: 25448, Code: '8232', Name: 'Engineer, nos, BOAT, BARGE' },
  { Id: 25449, Code: '8232', Name: 'Engineer, nos, fishing' },
  { Id: 25450, Code: '8232', Name: 'Engineer, second, BOAT, BARGE' },
  { Id: 25451, Code: '8232', Name: 'Engineer, second, fishing' },
  { Id: 25452, Code: '8232', Name: 'Engineer, third, fishing' },
  { Id: 25453, Code: '8232', Name: 'Engineman, donkey, shipping' },
  { Id: 25454, Code: '8232', Name: 'Engineman, shipping' },
  { Id: 25455, Code: '8232', Name: 'Ferryman' },
  { Id: 25456, Code: '8232', Name: 'Fireman and trimmer' },
  { Id: 25457, Code: '8232', Name: 'Fireman, fishing' },
  { Id: 25458, Code: '8232', Name: 'Fireman, marine' },
  { Id: 25459, Code: '8232', Name: 'Fireman, shipping' },
  { Id: 25460, Code: '8232', Name: 'Fireman-greaser, shipping' },
  { Id: 25461, Code: '8232', Name: 'Flatman' },
  { Id: 25462, Code: '8232', Name: 'Freeman, RIVER THAMES' },
  { Id: 25463, Code: '8232', Name: 'Fuelman, SHIP' },
  { Id: 25464, Code: '8232', Name: 'Greaser, donkey, shipping' },
  { Id: 25465, Code: '8232', Name: 'Greaser, electric, shipping' },
  { Id: 25466, Code: '8232', Name: 'Greaser, fan, shipping' },
  { Id: 25467, Code: '8232', Name: 'Greaser, fishing' },
  { Id: 25468, Code: '8232', Name: 'Greaser, refrigerating, shipping' },
  { Id: 25469, Code: '8232', Name: 'Greaser, shipping' },
  { Id: 25470, Code: '8232', Name: 'Hand, barge' },
  { Id: 25471, Code: '8232', Name: 'Hand, boat' },
  { Id: 25472, Code: '8232', Name: 'Hand, deck, shipping' },
  { Id: 25473, Code: '8232', Name: 'Hand, launch' },
  { Id: 25474, Code: '8232', Name: 'Hand, salvage, wreck raising service' },
  { Id: 25475, Code: '8232', Name: 'Hand, tug' },
  { Id: 25476, Code: '8232', Name: 'Helmsman' },
  { Id: 25477, Code: '8232', Name: 'Hirer, boat' },
  { Id: 25478, Code: '8232', Name: 'Keeper, boat' },
  { Id: 25479, Code: '8232', Name: 'Keeper, store, room, engine' },
  { Id: 25480, Code: '8232', Name: 'Lifeboatman' },
  { Id: 25481, Code: '8232', Name: 'Lighter, lamp, Trinity House, SHIPS' },
  { Id: 25482, Code: '8232', Name: 'Lighterman' },
  { Id: 25483, Code: '8232', Name: 'Lightsman, LIGHTSHIP' },
  { Id: 25484, Code: '8232', Name: 'Look-out and AB' },
  { Id: 25485, Code: '8232', Name: 'Man, launch' },
  { Id: 25486, Code: '8232', Name: 'Man, lightship' },
  { Id: 25487, Code: '8232', Name: 'Man, motor, shipping' },
  { Id: 25488, Code: '8232', Name: 'Man, room, engine, shipping' },
  { Id: 25489, Code: '8232', Name: 'Mariner' },
  { Id: 25490, Code: '8232', Name: 'Master at Arms, shipping' },
  { Id: 25491, Code: '8232', Name: 'Master, derrick' },
  { Id: 25492, Code: '8232', Name: 'Master, quarter, shipping' },
  { Id: 25493, Code: '8232', Name: 'Mate on barge' },
  { Id: 25494, Code: '8232', Name: 'Mate, BOAT, BARGE' },
  { Id: 25495, Code: '8232', Name: "Mate, boatswain's" },
  { Id: 25496, Code: '8232', Name: 'Mate, docks' },
  { Id: 25497, Code: '8232', Name: 'Mate-in-charge' },
  { Id: 25498, Code: '8232', Name: 'Mechanic, senior, shipping' },
  { Id: 25499, Code: '8232', Name: 'Mechanic, shipping' },
  { Id: 25500, Code: '8232', Name: 'Oilman, shipping' },
  { Id: 25501, Code: '8232', Name: 'Operative, marine' },
  { Id: 25502, Code: '8232', Name: 'OS, shipping' },
  { Id: 25503, Code: '8232', Name: 'Owner, boat' },
  { Id: 25504, Code: '8232', Name: 'Pumpman, fishing' },
  { Id: 25505, Code: '8232', Name: 'Pumpman, shipping' },
  { Id: 25506, Code: '8232', Name: 'QM, shipping' },
  { Id: 25507, Code: '8232', Name: 'Quartermaster, shipping' },
  { Id: 25508, Code: '8232', Name: 'Rating, engine-room, shipping' },
  { Id: 25509, Code: '8232', Name: 'Rating, Navy, Merchant' },
  { Id: 25510, Code: '8232', Name: 'Rating, shipping' },
  { Id: 25511, Code: '8232', Name: 'Runner, shipping' },
  { Id: 25512, Code: '8232', Name: 'Sailor, shipping' },
  { Id: 25513, Code: '8232', Name: 'Seafarer' },
  { Id: 25514, Code: '8232', Name: 'Seaman, merchant' },
  { Id: 25515, Code: '8232', Name: 'Seaman, shipping' },
  { Id: 25516, Code: '8232', Name: 'Steerer, BOAT, BARGE' },
  { Id: 25517, Code: '8232', Name: 'Steersman' },
  { Id: 25518, Code: '8232', Name: 'Stoker, drifter' },
  { Id: 25519, Code: '8232', Name: 'Stoker, engine, BOAT, BARGE' },
  { Id: 25520, Code: '8232', Name: 'Stoker, engine, shipping' },
  { Id: 25521, Code: '8232', Name: 'Stoker, fishing' },
  { Id: 25522, Code: '8232', Name: 'Stoker, shipping' },
  { Id: 25523, Code: '8232', Name: 'Stoker-mechanic, shipping' },
  { Id: 25524, Code: '8232', Name: 'Tindal, first, shipping' },
  { Id: 25525, Code: '8232', Name: 'Trimmer, coal, shipping' },
  { Id: 25526, Code: '8232', Name: 'Trimmer, fishing' },
  { Id: 25527, Code: '8232', Name: 'Trimmer, lamp, shipping' },
  { Id: 25528, Code: '8232', Name: 'Trimmer, shipping' },
  { Id: 25529, Code: '8232', Name: 'Tugboatman' },
  { Id: 25530, Code: '8232', Name: 'Tugman' },
  { Id: 25531, Code: '8232', Name: 'Watchman, BARGE' },
  { Id: 25532, Code: '8232', Name: 'Waterman, water transport' },
  { Id: 25533, Code: '8232', Name: 'Wherryman' },
  { Id: 25534, Code: '8232', Name: 'Winchman, shipping' },
  { Id: 25535, Code: '8232', Name: 'Yachtsman' },
  { Id: 25536, Code: '8233', Name: 'Agent, ramp' },
  { Id: 25537, Code: '8233', Name: 'Agent, service, ramp' },
  { Id: 25538, Code: '8233', Name: 'Attendant, aerodrome' },
  { Id: 25539, Code: '8233', Name: 'Controller, airfield' },
  { Id: 25540, Code: '8233', Name: 'Controller, apron' },
  { Id: 25541, Code: '8233', Name: 'Coordinator, despatch, aircraft' },
  { Id: 25542, Code: '8233', Name: 'Despatcher, aircraft' },
  { Id: 25543, Code: '8233', Name: 'Despatcher, flight' },
  { Id: 25544, Code: '8233', Name: 'Despatcher, traffic, AIRCRAFT' },
  { Id: 25545, Code: '8233', Name: 'Dispatcher, aircraft' },
  { Id: 25546, Code: '8233', Name: 'Dispatcher, flight' },
  { Id: 25547, Code: '8233', Name: 'Dispatcher, traffic, AIRCRAFT' },
  { Id: 25548, Code: '8233', Name: 'Driver, loader, airport' },
  { Id: 25549, Code: '8233', Name: 'Driver, tug, AIRCRAFT' },
  { Id: 25550, Code: '8233', Name: 'Driver-loader, airport' },
  { Id: 25551, Code: '8233', Name: 'Hand, aircraft, airport' },
  { Id: 25552, Code: '8233', Name: 'Hand, airport' },
  { Id: 25553, Code: '8233', Name: 'Hand, apron' },
  { Id: 25554, Code: '8233', Name: 'Hand, general, airport' },
  { Id: 25555, Code: '8233', Name: 'Handler, aircraft' },
  { Id: 25556, Code: '8233', Name: 'Handler, baggage' },
  { Id: 25557, Code: '8233', Name: 'Handler, cargo, airport' },
  { Id: 25558, Code: '8233', Name: 'Leader, team, baggage, airline' },
  { Id: 25559, Code: '8233', Name: 'Loader, aircraft' },
  { Id: 25560, Code: '8233', Name: 'Loader-driver, airport' },
  { Id: 25561, Code: '8233', Name: 'Loadmaster, airlines' },
  { Id: 25562, Code: '8233', Name: 'Man, tarmac, airport' },
  { Id: 25563, Code: '8233', Name: 'Manager, dispatch, airport activities' },
  { Id: 25564, Code: '8233', Name: 'Marshaller, aircraft' },
  { Id: 25565, Code: '8233', Name: 'Master, load, airlines' },
  { Id: 25566, Code: '8233', Name: 'Officer, landing, helicopter' },
  { Id: 25567, Code: '8233', Name: 'Officer, operations, flight' },
  { Id: 25568, Code: '8233', Name: 'Officer, service, ramp' },
  { Id: 25569, Code: '8233', Name: 'Operator, airfield' },
  { Id: 25570, Code: '8233', Name: 'Refueller, aircraft' },
  { Id: 25571, Code: '8233', Name: 'Refueller, airport' },
  { Id: 25572, Code: '8233', Name: 'Staff, ground, airport' },
  { Id: 25573, Code: '8233', Name: 'Superintendent, airport' },
  { Id: 25574, Code: '8233', Name: 'Supervisor, loading, AIRCRAFT' },
  { Id: 25575, Code: '8233', Name: 'Supervisor, ramp' },
  { Id: 25576, Code: '8233', Name: 'Worker, ramp' },
  { Id: 25577, Code: '8234', Name: 'Allocator, traffic' },
  { Id: 25578, Code: '8234', Name: 'Assistant, depot, RAILWAYS' },
  { Id: 25579, Code: '8234', Name: 'Assistant, movement, railways' },
  { Id: 25580, Code: '8234', Name: 'Assistant, movements, railways' },
  { Id: 25581, Code: '8234', Name: 'Attendant, crossing, RAILWAYS' },
  { Id: 25582, Code: '8234', Name: 'Attendant, point' },
  { Id: 25583, Code: '8234', Name: 'Attendant, shunter' },
  { Id: 25584, Code: '8234', Name: 'Attendant, switch, coal mine' },
  { Id: 25585, Code: '8234', Name: 'Attendant, traffic, coal mine' },
  { Id: 25586, Code: '8234', Name: 'Attendant, train, coal mine' },
  { Id: 25587, Code: '8234', Name: 'Brakesman, transport: railways' },
  { Id: 25588, Code: '8234', Name: 'Controller, chief, deputy, railways' },
  { Id: 25589, Code: '8234', Name: 'Controller, locomotive' },
  { Id: 25590, Code: '8234', Name: 'Controller, railways' },
  { Id: 25591, Code: '8234', Name: "Controller, relief, trainsman's" },
  { Id: 25592, Code: '8234', Name: 'Controller, section, railways' },
  { Id: 25593, Code: '8234', Name: 'Controller, train' },
  { Id: 25594, Code: '8234', Name: 'Driver, coal mine below ground, TRAIN' },
  { Id: 25595, Code: '8234', Name: 'Driver, coal mine: above ground' },
  { Id: 25596, Code: '8234', Name: 'Driver, diesel, coal mine' },
  { Id: 25597, Code: '8234', Name: 'Driver, engine, diesel, coal mine' },
  { Id: 25598, Code: '8234', Name: 'Driver, surface, coal mine' },
  { Id: 25599, Code: '8234', Name: 'Dropper, fire, railways' },
  { Id: 25600, Code: '8234', Name: 'Employee, railway' },
  { Id: 25601, Code: '8234', Name: 'Ferryman, railways' },
  { Id: 25602, Code: '8234', Name: 'Fireman, BOILER, LOCOMOTIVE' },
  { Id: 25603, Code: '8234', Name: 'Fireman, engine, LOCOMOTIVE' },
  { Id: 25604, Code: '8234', Name: 'Fireman, loco' },
  { Id: 25605, Code: '8234', Name: 'Fireman, locomotive' },
  { Id: 25606, Code: '8234', Name: 'Fireman, shed' },
  { Id: 25607, Code: '8234', Name: 'Flagman' },
  { Id: 25608, Code: '8234', Name: 'Foreman, capstan' },
  { Id: 25609, Code: '8234', Name: 'Foreman, goods, railways' },
  { Id: 25610, Code: '8234', Name: 'Foreman, locomotive' },
  { Id: 25611, Code: '8234', Name: 'Foreman, parcel, railways' },
  { Id: 25612, Code: '8234', Name: 'Foreman, parcels, railways' },
  { Id: 25613, Code: '8234', Name: 'Foreman, railways' },
  { Id: 25614, Code: '8234', Name: 'Foreman, running, railways' },
  { Id: 25615, Code: '8234', Name: 'Foreman, shed, transport: railways' },
  { Id: 25616, Code: '8234', Name: 'Foreman, train' },
  { Id: 25617, Code: '8234', Name: 'Foreman, transport, railways' },
  { Id: 25618, Code: '8234', Name: 'Foreman, yard, railways' },
  { Id: 25619, Code: '8234', Name: 'Gatekeeper and pointsman' },
  { Id: 25620, Code: '8234', Name: 'Gatekeeper, coal mine' },
  { Id: 25621, Code: '8234', Name: 'Gateman, coal mine' },
  { Id: 25622, Code: '8234', Name: 'Gateman, railways' },
  { Id: 25623, Code: '8234', Name: 'Guard, ballast' },
  { Id: 25624, Code: '8234', Name: 'Guard, goods' },
  { Id: 25625, Code: '8234', Name: 'Guard, loco' },
  { Id: 25626, Code: '8234', Name: 'Inspector, cartage, railways' },
  { Id: 25627, Code: '8234', Name: 'Inspector, district, transport: railways' },
  { Id: 25628, Code: '8234', Name: 'Inspector, goods, railways' },
  { Id: 25629, Code: '8234', Name: 'Inspector, guards, railways' },
  { Id: 25630, Code: '8234', Name: 'Inspector, mineral, railways' },
  { Id: 25631, Code: '8234', Name: 'Inspector, office, head, railways' },
  { Id: 25632, Code: '8234', Name: 'Inspector, operating, railways' },
  { Id: 25633, Code: '8234', Name: 'Inspector, parcels, railways' },
  { Id: 25634, Code: '8234', Name: 'Inspector, railway' },
  { Id: 25635, Code: '8234', Name: 'Inspector, shunting' },
  { Id: 25636, Code: '8234', Name: "Inspector, signalman's" },
  { Id: 25637, Code: '8234', Name: 'Inspector, traffic, railways' },
  { Id: 25638, Code: '8234', Name: 'Inspector, train' },
  { Id: 25639, Code: '8234', Name: 'Inspector, transport: railways' },
  { Id: 25640, Code: '8234', Name: 'Inspector, yard' },
  { Id: 25641, Code: '8234', Name: 'Keeper and pointsman, gate' },
  { Id: 25642, Code: '8234', Name: 'Keeper, crossing' },
  { Id: 25643, Code: '8234', Name: 'Keeper, crossing, level' },
  { Id: 25644, Code: '8234', Name: 'Keeper, gate, crossing, railways' },
  { Id: 25645, Code: '8234', Name: 'Keeper, gate, railways' },
  { Id: 25646, Code: '8234', Name: 'Keeper, switch' },
  { Id: 25647, Code: '8234', Name: 'Lampman, railways' },
  { Id: 25648, Code: '8234', Name: 'Latcher, locomotive' },
  { Id: 25649, Code: '8234', Name: 'Latcher, railways' },
  { Id: 25650, Code: '8234', Name: 'Latcher, wagon, steelworks' },
  { Id: 25651, Code: '8234', Name: 'Man, locomotive, coal mine' },
  { Id: 25652, Code: '8234', Name: 'Man, look-out, railways' },
  { Id: 25653, Code: '8234', Name: 'Man, motor, railways' },
  { Id: 25654, Code: '8234', Name: 'Man, surface, railways' },
  { Id: 25655, Code: '8234', Name: 'Master, protection' },
  { Id: 25656, Code: '8234', Name: "Mate, examiner's, cable, railways" },
  { Id: 25657, Code: '8234', Name: 'Operator, points, railways' },
  { Id: 25658, Code: '8234', Name: 'Operator, signal, railways' },
  { Id: 25659, Code: '8234', Name: 'Pointsman' },
  { Id: 25660, Code: '8234', Name: 'Railman' },
  { Id: 25661, Code: '8234', Name: 'Railwayman' },
  { Id: 25662, Code: '8234', Name: 'Secondman, railways' },
  { Id: 25663, Code: '8234', Name: 'Shedman, railways' },
  { Id: 25664, Code: '8234', Name: 'Sheeter, railways' },
  { Id: 25665, Code: '8234', Name: 'Sheeter, wagon' },
  { Id: 25666, Code: '8234', Name: 'Shunter' },
  { Id: 25667, Code: '8234', Name: 'Signaller' },
  { Id: 25668, Code: '8234', Name: 'Signalman, railway' },
  { Id: 25669, Code: '8234', Name: 'Signalman, railways' },
  { Id: 25670, Code: '8234', Name: 'Signalman, relief' },
  { Id: 25671, Code: '8234', Name: 'Stoker, engine, railways' },
  { Id: 25672, Code: '8234', Name: 'Stower, railways' },
  { Id: 25673, Code: '8234', Name: 'Sub-inspector, railways' },
  { Id: 25674, Code: '8234', Name: 'Supervisor, area, RAILWAY SIGNALLING' },
  { Id: 25675, Code: '8234', Name: 'Supervisor, movements, railways' },
  { Id: 25676, Code: '8234', Name: 'Supervisor, railway, area' },
  { Id: 25677, Code: '8234', Name: 'Supervisor, signalling, railways' },
  { Id: 25678, Code: '8234', Name: 'Supervisor, transport, railways' },
  { Id: 25679, Code: '8234', Name: 'Trainman, railways' },
  { Id: 25680, Code: '8234', Name: 'Worker, goods, railways' },
  { Id: 25681, Code: '8234', Name: 'Worker, railway' },
  { Id: 25682, Code: '8234', Name: 'Worker, shed, railways' },
  { Id: 25683, Code: '8234', Name: 'Yardsman, blast furnace' },
  { Id: 25684, Code: '8239', Name: 'Assistant, depot, ROAD' },
  { Id: 25685, Code: '8239', Name: 'Assistant, marina' },
  { Id: 25686, Code: '8239', Name: 'Assistant, operations, FREIGHT HANDLING' },
  { Id: 25687, Code: '8239', Name: 'Assistant, port' },
  { Id: 25688, Code: '8239', Name: 'Assistant, survey, hydrographic' },
  { Id: 25689, Code: '8239', Name: 'Assistant, terminals, transport' },
  { Id: 25690, Code: '8239', Name: 'Attendant, bridge' },
  { Id: 25691, Code: '8239', Name: 'Attendant, dock' },
  { Id: 25692, Code: '8239', Name: 'Attendant, gate, flood' },
  { Id: 25693, Code: '8239', Name: 'Attendant, pier' },
  { Id: 25694, Code: '8239', Name: 'Attendant, shed, transport' },
  { Id: 25695, Code: '8239', Name: 'Attendant, sluice' },
  { Id: 25696, Code: '8239', Name: 'Attendant, wagon' },
  { Id: 25697, Code: '8239', Name: 'Bankman, rail' },
  { Id: 25698, Code: '8239', Name: 'Brakeman and steersman' },
  { Id: 25699, Code: '8239', Name: 'Braker, wagon' },
  { Id: 25700, Code: '8239', Name: 'Brakesman, transport' },
  { Id: 25701, Code: '8239', Name: 'Breaker, wagon' },
  { Id: 25702, Code: '8239', Name: 'Bridgeman' },
  { Id: 25703, Code: '8239', Name: 'Bridgeman, sluice' },
  { Id: 25704, Code: '8239', Name: 'Bridgemaster' },
  { Id: 25705, Code: '8239', Name: 'Cabman' },
  { Id: 25706, Code: '8239', Name: 'Carter, transport' },
  { Id: 25707, Code: '8239', Name: 'Cartman' },
  { Id: 25708, Code: '8239', Name: 'Coachman' },
  { Id: 25709, Code: '8239', Name: 'Controller, signals' },
  { Id: 25710, Code: '8239', Name: 'Driver, bridge' },
  { Id: 25711, Code: '8239', Name: 'Driver, bridge, swing' },
  { Id: 25712, Code: '8239', Name: 'Driver, cart' },
  { Id: 25713, Code: '8239', Name: 'Driver, engine, traction' },
  { Id: 25714, Code: '8239', Name: 'Driver, horse' },
  { Id: 25715, Code: '8239', Name: 'Driver, lister' },
  { Id: 25716, Code: '8239', Name: 'Driver, motor, electric' },
  { Id: 25717, Code: '8239', Name: 'Driver, ram' },
  { Id: 25718, Code: '8239', Name: 'Driver, ransom' },
  { Id: 25719, Code: '8239', Name: 'Driver, test, motor vehicle mfr' },
  { Id: 25720, Code: '8239', Name: 'Driver, traverser' },
  { Id: 25721, Code: '8239', Name: 'Driver, truck, lister' },
  { Id: 25722, Code: '8239', Name: 'Examiner, ticket, road transport' },
  { Id: 25723, Code: '8239', Name: 'Foreman, bank, transport' },
  { Id: 25724, Code: '8239', Name: 'Foreman, BUS SERVICE' },
  { Id: 25725, Code: '8239', Name: 'Foreman, cartage' },
  { Id: 25726, Code: '8239', Name: 'Foreman, coal merchants' },
  { Id: 25727, Code: '8239', Name: 'Foreman, delivery' },
  { Id: 25728, Code: '8239', Name: 'Foreman, depot, coal merchants' },
  { Id: 25729, Code: '8239', Name: 'Foreman, depot, transport' },
  { Id: 25730, Code: '8239', Name: 'Foreman, export' },
  { Id: 25731, Code: '8239', Name: 'Foreman, installation, oil refining' },
  { Id: 25732, Code: '8239', Name: 'Foreman, local government: council depot' },
  {
    Id: 25733,
    Code: '8239',
    Name: 'Foreman, local government: transport dept'
  },
  { Id: 25734, Code: '8239', Name: 'Foreman, lock' },
  { Id: 25735, Code: '8239', Name: 'Foreman, petroleum distribution' },
  { Id: 25736, Code: '8239', Name: 'Foreman, road transport' },
  { Id: 25737, Code: '8239', Name: 'Foreman, shed, transport: road' },
  { Id: 25738, Code: '8239', Name: 'Foreman, traffic' },
  { Id: 25739, Code: '8239', Name: 'Foreman, transport' },
  { Id: 25740, Code: '8239', Name: 'Foreman, transporting' },
  { Id: 25741, Code: '8239', Name: "Foreman, yard, builders' merchants" },
  { Id: 25742, Code: '8239', Name: 'Foreman, yard, building construction' },
  { Id: 25743, Code: '8239', Name: 'Foreman, yard, canals' },
  { Id: 25744, Code: '8239', Name: 'Foreman, yard, coal' },
  { Id: 25745, Code: '8239', Name: 'Foreman, yard, local government' },
  { Id: 25746, Code: '8239', Name: 'Foreman, yard, road transport' },
  { Id: 25747, Code: '8239', Name: 'Gasman, railways' },
  { Id: 25748, Code: '8239', Name: 'Gateman, bridge' },
  { Id: 25749, Code: '8239', Name: 'Gateman, dock' },
  { Id: 25750, Code: '8239', Name: 'Gateman, docks' },
  { Id: 25751, Code: '8239', Name: 'Gateman, flood' },
  { Id: 25752, Code: '8239', Name: 'Gateman, lock' },
  { Id: 25753, Code: '8239', Name: 'Gateman, waterways' },
  { Id: 25754, Code: '8239', Name: 'Hand, deck, oil rigs' },
  { Id: 25755, Code: '8239', Name: 'Hand, stage, docks' },
  { Id: 25756, Code: '8239', Name: 'Horseman, canals' },
  { Id: 25757, Code: '8239', Name: 'Horseman, TIMBER HAULAGE' },
  { Id: 25758, Code: '8239', Name: 'Inspector, bus' },
  { Id: 25759, Code: '8239', Name: 'Inspector, depot, transport' },
  { Id: 25760, Code: '8239', Name: 'Inspector, district, transport: road' },
  { Id: 25761, Code: '8239', Name: 'Inspector, garage' },
  { Id: 25762, Code: '8239', Name: 'Inspector, loading, transport' },
  { Id: 25763, Code: '8239', Name: 'Inspector, motoring organisation' },
  { Id: 25764, Code: '8239', Name: 'Inspector, road, transport' },
  { Id: 25765, Code: '8239', Name: 'Inspector, roads, transport' },
  { Id: 25766, Code: '8239', Name: 'Inspector, sanitary, railways' },
  { Id: 25767, Code: '8239', Name: 'Inspector, telegraph, railways' },
  { Id: 25768, Code: '8239', Name: 'Inspector, ticket, public transport' },
  { Id: 25769, Code: '8239', Name: 'Inspector, traffic, road transport' },
  { Id: 25770, Code: '8239', Name: 'Inspector, transport, road transport' },
  { Id: 25771, Code: '8239', Name: 'Inspector, transport: road' },
  { Id: 25772, Code: '8239', Name: 'Keeper, bridge' },
  { Id: 25773, Code: '8239', Name: 'Keeper, gate, lock' },
  { Id: 25774, Code: '8239', Name: 'Keeper, house, bridge' },
  { Id: 25775, Code: '8239', Name: 'Keeper, house, light' },
  { Id: 25776, Code: '8239', Name: 'Keeper, light' },
  { Id: 25777, Code: '8239', Name: 'Keeper, lighthouse' },
  { Id: 25778, Code: '8239', Name: 'Keeper, lock' },
  { Id: 25779, Code: '8239', Name: 'Keeper, sluice' },
  { Id: 25780, Code: '8239', Name: 'Keeper, swingbridge' },
  { Id: 25781, Code: '8239', Name: 'Keeper, Trinity House' },
  { Id: 25782, Code: '8239', Name: 'Keeper, weir' },
  { Id: 25783, Code: '8239', Name: 'Man, berthing' },
  { Id: 25784, Code: '8239', Name: 'Man, head, pier' },
  { Id: 25785, Code: '8239', Name: 'Man, lighthouse' },
  { Id: 25786, Code: '8239', Name: 'Man, pontoon' },
  { Id: 25787, Code: '8239', Name: 'Man, swingbridge' },
  { Id: 25788, Code: '8239', Name: 'Marshal, transport' },
  { Id: 25789, Code: '8239', Name: 'Marshaller, transport' },
  { Id: 25790, Code: '8239', Name: 'Master, berthing, MARINA' },
  { Id: 25791, Code: '8239', Name: 'Master, bridge' },
  { Id: 25792, Code: '8239', Name: 'Master, lock' },
  { Id: 25793, Code: '8239', Name: 'Master, swingbridge' },
  { Id: 25794, Code: '8239', Name: 'Operator, bridge' },
  { Id: 25795, Code: '8239', Name: 'Operator, capstan, railways' },
  { Id: 25796, Code: '8239', Name: 'Pierman' },
  { Id: 25797, Code: '8239', Name: 'Planner, load' },
  { Id: 25798, Code: '8239', Name: 'Pointsman, road transport' },
  { Id: 25799, Code: '8239', Name: 'Putter, pony' },
  { Id: 25800, Code: '8239', Name: 'Regulator, traffic' },
  { Id: 25801, Code: '8239', Name: 'Regulator, transport' },
  { Id: 25802, Code: '8239', Name: 'Roadsman, canals' },
  { Id: 25803, Code: '8239', Name: 'Scotcher' },
  { Id: 25804, Code: '8239', Name: 'Seaman, stage, landing' },
  { Id: 25805, Code: '8239', Name: 'Setter, van, railways' },
  { Id: 25806, Code: '8239', Name: 'Shackler' },
  { Id: 25807, Code: '8239', Name: 'Shedman, transport' },
  { Id: 25808, Code: '8239', Name: "Signalman, Lloyd's" },
  { Id: 25809, Code: '8239', Name: 'Sounder, survey' },
  { Id: 25810, Code: '8239', Name: 'Stageman, landing' },
  { Id: 25811, Code: '8239', Name: 'Steersman, bridge' },
  { Id: 25812, Code: '8239', Name: 'Superintendent, depot, transport' },
  { Id: 25813, Code: '8239', Name: 'Superintendent, district, transport' },
  { Id: 25814, Code: '8239', Name: 'Superintendent, divisional, railways' },
  { Id: 25815, Code: '8239', Name: 'Superintendent, docks' },
  { Id: 25816, Code: '8239', Name: 'Superintendent, marine' },
  { Id: 25817, Code: '8239', Name: 'Superintendent, operations, transport' },
  { Id: 25818, Code: '8239', Name: 'Superintendent, railways' },
  { Id: 25819, Code: '8239', Name: 'Superintendent, traffic' },
  { Id: 25820, Code: '8239', Name: 'Superintendent, transport' },
  { Id: 25821, Code: '8239', Name: 'Supervisor, transport' },
  {
    Id: 25822,
    Code: '8239',
    Name: 'Supervisor, transport, public road transport'
  },
  { Id: 25823, Code: '8239', Name: 'Teamster' },
  { Id: 25824, Code: '8239', Name: 'Tender, lock' },
  { Id: 25825, Code: '8239', Name: 'Worker, transport' },
  { Id: 25826, Code: '9111', Name: 'Assistant, farm' },
  { Id: 25827, Code: '9111', Name: 'Assistant, general, agriculture' },
  { Id: 25828, Code: '9111', Name: 'Assistant, poultry' },
  { Id: 25829, Code: '9111', Name: 'Attendant, pig' },
  { Id: 25830, Code: '9111', Name: 'Attendant, poultry' },
  { Id: 25831, Code: '9111', Name: 'Cattleman' },
  { Id: 25832, Code: '9111', Name: 'Collector, egg, poultry farm' },
  { Id: 25833, Code: '9111', Name: 'Cowman' },
  { Id: 25834, Code: '9111', Name: 'Craftsman, agricultural' },
  { Id: 25835, Code: '9111', Name: 'Cropper, agriculture' },
  { Id: 25836, Code: '9111', Name: 'Dairymaid, farming' },
  { Id: 25837, Code: '9111', Name: 'Dairyman, farming' },
  { Id: 25838, Code: '9111', Name: 'Hand, dairy, farming' },
  { Id: 25839, Code: '9111', Name: 'Hand, farm' },
  { Id: 25840, Code: '9111', Name: 'Handler, poultry' },
  { Id: 25841, Code: '9111', Name: 'Handyman, farm' },
  { Id: 25842, Code: '9111', Name: 'Help, farm' },
  { Id: 25843, Code: '9111', Name: 'Herdsman' },
  { Id: 25844, Code: '9111', Name: 'Keeper, cow' },
  { Id: 25845, Code: '9111', Name: 'Keeper, stock, agriculture' },
  { Id: 25846, Code: '9111', Name: 'Labourer, agricultural' },
  { Id: 25847, Code: '9111', Name: 'Labourer, agriculture' },
  { Id: 25848, Code: '9111', Name: 'Labourer, estate' },
  { Id: 25849, Code: '9111', Name: 'Labourer, farm' },
  { Id: 25850, Code: '9111', Name: 'Lamber' },
  { Id: 25851, Code: '9111', Name: 'Landworker' },
  { Id: 25852, Code: '9111', Name: 'Man, gang, agriculture' },
  { Id: 25853, Code: '9111', Name: 'Man, job, odd, agriculture' },
  { Id: 25854, Code: '9111', Name: 'Man, poultry' },
  { Id: 25855, Code: '9111', Name: 'Man, yard, farming' },
  { Id: 25856, Code: '9111', Name: 'Master, gang' },
  { Id: 25857, Code: '9111', Name: 'Operative, agricultural' },
  { Id: 25858, Code: '9111', Name: 'Pigman' },
  { Id: 25859, Code: '9111', Name: 'Shepherd' },
  { Id: 25860, Code: '9111', Name: 'Stockman, farming' },
  { Id: 25861, Code: '9111', Name: 'Stocksman, farming' },
  { Id: 25862, Code: '9111', Name: 'Supervisor, unit, poultry' },
  { Id: 25863, Code: '9111', Name: "Wife, farmer's" },
  { Id: 25864, Code: '9111', Name: 'Worker, agricultural' },
  { Id: 25865, Code: '9111', Name: 'Worker, farm' },
  { Id: 25866, Code: '9111', Name: 'Worker, field' },
  { Id: 25867, Code: '9111', Name: 'Worker, general, farming' },
  { Id: 25868, Code: '9111', Name: 'Worker, land' },
  { Id: 25869, Code: '9111', Name: 'Worker, poultry' },
  { Id: 25870, Code: '9111', Name: 'Worker, wire, hop growing' },
  { Id: 25871, Code: '9111', Name: 'Yardman, farming' },
  { Id: 25872, Code: '9111', Name: 'Yardsman, farming' },
  { Id: 25873, Code: '9112', Name: 'Axeman' },
  { Id: 25874, Code: '9112', Name: 'Caretaker, WOODLANDS' },
  { Id: 25875, Code: '9112', Name: 'Contractor, forestry' },
  { Id: 25876, Code: '9112', Name: 'Craftsman, forest' },
  { Id: 25877, Code: '9112', Name: 'Craftsman, forestry' },
  { Id: 25878, Code: '9112', Name: 'Cutter, copse' },
  { Id: 25879, Code: '9112', Name: 'Cutter, tree' },
  { Id: 25880, Code: '9112', Name: 'Cutter, willow' },
  { Id: 25881, Code: '9112', Name: 'Cutter, wood, forestry' },
  { Id: 25882, Code: '9112', Name: 'Driver, tractor, forestry' },
  { Id: 25883, Code: '9112', Name: 'Feller, forestry' },
  { Id: 25884, Code: '9112', Name: 'Feller, timber' },
  { Id: 25885, Code: '9112', Name: 'Feller, tree' },
  { Id: 25886, Code: '9112', Name: 'Foreman, forestry' },
  { Id: 25887, Code: '9112', Name: 'Forester' },
  { Id: 25888, Code: '9112', Name: 'Grower, CHRISTMAS TREES' },
  { Id: 25889, Code: '9112', Name: 'Grower, OSIER' },
  { Id: 25890, Code: '9112', Name: 'Grower, WILLOW' },
  { Id: 25891, Code: '9112', Name: 'Grower, WITHY' },
  { Id: 25892, Code: '9112', Name: 'Grower, WOOD' },
  { Id: 25893, Code: '9112', Name: 'Holder, small, forestry' },
  { Id: 25894, Code: '9112', Name: 'Jack, lumber' },
  { Id: 25895, Code: '9112', Name: 'Keeper, forest' },
  { Id: 25896, Code: '9112', Name: 'Labourer, Crown' },
  { Id: 25897, Code: '9112', Name: "Labourer, feller's, timber" },
  { Id: 25898, Code: '9112', Name: 'Labourer, forest' },
  { Id: 25899, Code: '9112', Name: 'Labourer, forestry' },
  { Id: 25900, Code: '9112', Name: 'Labourer, wood' },
  { Id: 25901, Code: '9112', Name: 'Lopper, tree' },
  { Id: 25902, Code: '9112', Name: 'Lumberjack' },
  { Id: 25903, Code: '9112', Name: 'Man, timber, forestry' },
  { Id: 25904, Code: '9112', Name: 'Pruner, tree, forestry' },
  { Id: 25905, Code: '9112', Name: 'Ranger, forest' },
  { Id: 25906, Code: '9112', Name: 'Ranger, wood' },
  { Id: 25907, Code: '9112', Name: 'Silviculturist' },
  { Id: 25908, Code: '9112', Name: 'Smallholder, forestry' },
  { Id: 25909, Code: '9112', Name: 'Supervisor, arboricultural' },
  { Id: 25910, Code: '9112', Name: 'Taker, care, WOODLANDS' },
  { Id: 25911, Code: '9112', Name: 'Technician, arboricultural' },
  { Id: 25912, Code: '9112', Name: 'Verderer' },
  { Id: 25913, Code: '9112', Name: 'Verderor' },
  { Id: 25914, Code: '9112', Name: 'Warden, forestry' },
  { Id: 25915, Code: '9112', Name: 'Warden, wood' },
  { Id: 25916, Code: '9112', Name: 'Woodcutter, forestry' },
  { Id: 25917, Code: '9112', Name: 'Woodman' },
  { Id: 25918, Code: '9112', Name: 'Woodsman' },
  { Id: 25919, Code: '9112', Name: 'Worker, coppice' },
  { Id: 25920, Code: '9112', Name: 'Worker, forest' },
  { Id: 25921, Code: '9112', Name: 'Worker, forestry' },
  { Id: 25922, Code: '9112', Name: 'Workman, forest' },
  { Id: 25923, Code: '9119', Name: 'Assistant, farm, fish' },
  { Id: 25924, Code: '9119', Name: 'Assistant, farm, mink' },
  { Id: 25925, Code: '9119', Name: 'Assistant, general, horticulture' },
  { Id: 25926, Code: '9119', Name: 'Assistant, hatchery' },
  { Id: 25927, Code: '9119', Name: 'Attendant, aquarium' },
  { Id: 25928, Code: '9119', Name: 'Attendant, nursery, horticulture' },
  { Id: 25929, Code: '9119', Name: 'Boatswain, fishing' },
  { Id: 25930, Code: '9119', Name: 'Bosun, fishing' },
  { Id: 25931, Code: '9119', Name: "Bo'sun, fishing" },
  { Id: 25932, Code: '9119', Name: 'Breeder, FISH' },
  { Id: 25933, Code: '9119', Name: 'Breeder, MAGGOT' },
  { Id: 25934, Code: '9119', Name: 'Breeder, MEALWORM' },
  { Id: 25935, Code: '9119', Name: 'Carter, farming' },
  { Id: 25936, Code: '9119', Name: 'Catcher, bird' },
  { Id: 25937, Code: '9119', Name: 'Catcher, chicken' },
  { Id: 25938, Code: '9119', Name: 'Catcher, rabbit' },
  { Id: 25939, Code: '9119', Name: 'Chick-sexer' },
  { Id: 25940, Code: '9119', Name: 'Chopper, firewood' },
  { Id: 25941, Code: '9119', Name: 'Classifier, livestock' },
  { Id: 25942, Code: '9119', Name: 'Cockler' },
  { Id: 25943, Code: '9119', Name: 'Collector, fern' },
  { Id: 25944, Code: '9119', Name: 'Collector, kelp' },
  { Id: 25945, Code: '9119', Name: 'Collector, moss' },
  { Id: 25946, Code: '9119', Name: 'Collector, reed' },
  { Id: 25947, Code: '9119', Name: 'Collector, seaweed' },
  { Id: 25948, Code: '9119', Name: 'Crabber, fishing' },
  { Id: 25949, Code: '9119', Name: 'Cultivator, shellfish' },
  { Id: 25950, Code: '9119', Name: 'Cutter, grass' },
  { Id: 25951, Code: '9119', Name: 'Cutter, hay, farming' },
  { Id: 25952, Code: '9119', Name: 'Cutter, heath' },
  { Id: 25953, Code: '9119', Name: 'Cutter, hedge' },
  { Id: 25954, Code: '9119', Name: 'Cutter, mushroom' },
  { Id: 25955, Code: '9119', Name: 'Cutter, peat' },
  { Id: 25956, Code: '9119', Name: 'Cutter, reed' },
  { Id: 25957, Code: '9119', Name: 'Cutter, straw, farming' },
  { Id: 25958, Code: '9119', Name: 'Cutter, vegetable' },
  { Id: 25959, Code: '9119', Name: 'Cutter, watercress' },
  { Id: 25960, Code: '9119', Name: 'Digger, bait' },
  { Id: 25961, Code: '9119', Name: 'Digger, peat' },
  { Id: 25962, Code: '9119', Name: 'Dredgeman, SHELL FISH' },
  { Id: 25963, Code: '9119', Name: 'Dredger, SHELL FISH' },
  { Id: 25964, Code: '9119', Name: 'Dresser, potato' },
  { Id: 25965, Code: '9119', Name: 'Drover' },
  { Id: 25966, Code: '9119', Name: 'Gatherer, AGRICULTURAL PRODUCTS' },
  { Id: 25967, Code: '9119', Name: 'Gatherer, mussel' },
  { Id: 25968, Code: '9119', Name: 'Gatherer, seaweed' },
  { Id: 25969, Code: '9119', Name: 'Gatherer, watercress' },
  { Id: 25970, Code: '9119', Name: 'Grader, pig' },
  { Id: 25971, Code: '9119', Name: 'Grader, stock, live' },
  { Id: 25972, Code: '9119', Name: 'Hand, deck, fishing' },
  {
    Id: 25973,
    Code: '9119',
    Name: 'Hand, maintenance, gardening, grounds keeping services'
  },
  { Id: 25974, Code: '9119', Name: 'Hand, nursery' },
  { Id: 25975, Code: '9119', Name: 'Hand, second, fishing' },
  { Id: 25976, Code: '9119', Name: 'Hand, spare, fishing' },
  { Id: 25977, Code: '9119', Name: 'Hand, third, fishing' },
  { Id: 25978, Code: '9119', Name: 'Hand, trawler' },
  { Id: 25979, Code: '9119', Name: 'Handyman, estate' },
  { Id: 25980, Code: '9119', Name: "Handyman, gardener's" },
  { Id: 25981, Code: '9119', Name: 'Harpooner' },
  { Id: 25982, Code: '9119', Name: 'Harvester, crop' },
  { Id: 25983, Code: '9119', Name: 'Harvester, fruit, vegetable growing' },
  { Id: 25984, Code: '9119', Name: 'Hatcher, fish' },
  { Id: 25985, Code: '9119', Name: 'Heathman' },
  { Id: 25986, Code: '9119', Name: 'Heathsman' },
  { Id: 25987, Code: '9119', Name: 'Hedger' },
  { Id: 25988, Code: '9119', Name: 'Hedger and ditcher' },
  { Id: 25989, Code: '9119', Name: 'Incubationist, agriculture' },
  { Id: 25990, Code: '9119', Name: 'Inseminator, artificial' },
  { Id: 25991, Code: '9119', Name: 'Irrigator' },
  { Id: 25992, Code: '9119', Name: 'Labourer, garden' },
  { Id: 25993, Code: '9119', Name: 'Labourer, garden and parks' },
  { Id: 25994, Code: '9119', Name: "Labourer, gardener's" },
  { Id: 25995, Code: '9119', Name: 'Labourer, horticulture' },
  { Id: 25996, Code: '9119', Name: 'Labourer, landscape' },
  { Id: 25997, Code: '9119', Name: 'Labourer, landscape gardening' },
  { Id: 25998, Code: '9119', Name: 'Labourer, parks' },
  { Id: 25999, Code: '9119', Name: 'Labourer, policy, SCOTLAND' },
  { Id: 26000, Code: '9119', Name: 'Lairman' },
  { Id: 26001, Code: '9119', Name: 'Layer, hedge' },
  { Id: 26002, Code: '9119', Name: 'Layman, mussel' },
  { Id: 26003, Code: '9119', Name: 'Loader, timber, forestry' },
  { Id: 26004, Code: '9119', Name: 'Man, decoy' },
  { Id: 26005, Code: '9119', Name: 'Man, seed' },
  { Id: 26006, Code: '9119', Name: 'Man, trawler' },
  { Id: 26007, Code: '9119', Name: 'Mariner, fishing' },
  { Id: 26008, Code: '9119', Name: 'Mate, boat, fishing' },
  { Id: 26009, Code: '9119', Name: 'Mate, fishing' },
  { Id: 26010, Code: '9119', Name: 'Mate, trawler' },
  { Id: 26011, Code: '9119', Name: 'Member, crew, fishing' },
  { Id: 26012, Code: '9119', Name: 'Milker' },
  { Id: 26013, Code: '9119', Name: 'Milkman, farming' },
  { Id: 26014, Code: '9119', Name: 'Mower, lawn' },
  { Id: 26015, Code: '9119', Name: 'Musseler' },
  { Id: 26016, Code: '9119', Name: 'Operator, hatchery' },
  { Id: 26017, Code: '9119', Name: 'Picker, cockle' },
  { Id: 26018, Code: '9119', Name: 'Picker, flower' },
  { Id: 26019, Code: '9119', Name: 'Picker, fruit, farming' },
  { Id: 26020, Code: '9119', Name: 'Picker, fruit, vegetable growing' },
  { Id: 26021, Code: '9119', Name: 'Picker, hop' },
  { Id: 26022, Code: '9119', Name: 'Picker, moss' },
  { Id: 26023, Code: '9119', Name: 'Picker, mushroom' },
  { Id: 26024, Code: '9119', Name: 'Picker, pea, farming' },
  { Id: 26025, Code: '9119', Name: 'Picker, potato' },
  { Id: 26026, Code: '9119', Name: 'Pisciculturist' },
  { Id: 26027, Code: '9119', Name: 'Puller, pea' },
  { Id: 26028, Code: '9119', Name: 'Riddler, potato' },
  { Id: 26029, Code: '9119', Name: 'Seaman, fishing' },
  { Id: 26030, Code: '9119', Name: 'Sexer, chick' },
  { Id: 26031, Code: '9119', Name: 'Shearer, sheep' },
  { Id: 26032, Code: '9119', Name: 'Shrimper' },
  { Id: 26033, Code: '9119', Name: 'Teamsman, farming' },
  { Id: 26034, Code: '9119', Name: 'Teamster, farming' },
  { Id: 26035, Code: '9119', Name: 'Technician, insemination, artificial' },
  { Id: 26036, Code: '9119', Name: 'Trapper, forestry' },
  { Id: 26037, Code: '9119', Name: 'Trapper, rabbit' },
  { Id: 26038, Code: '9119', Name: 'Trusser, farming' },
  { Id: 26039, Code: '9119', Name: 'Vaccinator, POULTRY' },
  { Id: 26040, Code: '9119', Name: 'Warder, river' },
  { Id: 26041, Code: '9119', Name: 'Washer, fruit, vegetable growing' },
  { Id: 26042, Code: '9119', Name: 'Watcher, river' },
  { Id: 26043, Code: '9119', Name: 'Weeder' },
  { Id: 26044, Code: '9119', Name: 'Worker, bed, oyster' },
  { Id: 26045, Code: '9119', Name: 'Worker, bulb, horticulture' },
  { Id: 26046, Code: '9119', Name: 'Worker, crop, horticulture' },
  { Id: 26047, Code: '9119', Name: 'Worker, estate' },
  { Id: 26048, Code: '9119', Name: 'Worker, farm, fish' },
  { Id: 26049, Code: '9119', Name: 'Worker, farm, fish farm, hatchery' },
  { Id: 26050, Code: '9119', Name: 'Worker, farm, salmon' },
  { Id: 26051, Code: '9119', Name: 'Worker, farm, trout' },
  { Id: 26052, Code: '9119', Name: 'Worker, garden, market' },
  { Id: 26053, Code: '9119', Name: 'Worker, general, horticulture' },
  { Id: 26054, Code: '9119', Name: 'Worker, glass, agriculture' },
  { Id: 26055, Code: '9119', Name: 'Worker, glasshouse' },
  { Id: 26056, Code: '9119', Name: 'Worker, greenhouse, agriculture' },
  { Id: 26057, Code: '9119', Name: 'Worker, hatchery, agriculture' },
  { Id: 26058, Code: '9119', Name: 'Worker, hatchery, fishing' },
  { Id: 26059, Code: '9119', Name: 'Worker, horticultural' },
  { Id: 26060, Code: '9119', Name: 'Worker, nursery' },
  { Id: 26061, Code: '9119', Name: 'Worker, peat' },
  {
    Id: 26062,
    Code: '9119',
    Name: 'Worker, seasonal, agriculture, market gardening'
  },
  { Id: 26063, Code: '9119', Name: 'Worker, turf' },
  { Id: 26064, Code: '9119', Name: 'Worker, watercress' },
  { Id: 26065, Code: '9120', Name: 'Applicator, mastic' },
  { Id: 26066, Code: '9120', Name: "Assistant, bricklayer's" },
  { Id: 26067, Code: '9120', Name: "Assistant, builders'" },
  { Id: 26068, Code: '9120', Name: "Assistant, carpenter's" },
  { Id: 26069, Code: '9120', Name: "Assistant, diver's, sea" },
  {
    Id: 26070,
    Code: '9120',
    Name: "Assistant, electrician's, building construction"
  },
  {
    Id: 26071,
    Code: '9120',
    Name: 'Assistant, general, building construction'
  },
  { Id: 26072, Code: '9120', Name: 'Assistant, general, BUILDING MAINTENANCE' },
  { Id: 26073, Code: '9120', Name: "Assistant, glazier's" },
  { Id: 26074, Code: '9120', Name: "Assistant, joiner's" },
  { Id: 26075, Code: '9120', Name: "Assistant, mason's" },
  { Id: 26076, Code: '9120', Name: "Assistant, plasterer's" },
  { Id: 26077, Code: '9120', Name: "Assistant, plumber's" },
  { Id: 26078, Code: '9120', Name: "Assistant, regulator's, gas" },
  { Id: 26079, Code: '9120', Name: "Assistant, sawyer's" },
  { Id: 26080, Code: '9120', Name: 'Assistant, survey, government' },
  { Id: 26081, Code: '9120', Name: 'Assistant, surveying' },
  { Id: 26082, Code: '9120', Name: "Assistant, surveyor's" },
  { Id: 26083, Code: '9120', Name: "Attendant, diver's" },
  { Id: 26084, Code: '9120', Name: 'Attendant, reservoir' },
  { Id: 26085, Code: '9120', Name: 'Attendant, river' },
  { Id: 26086, Code: '9120', Name: 'Backer-up, sawmilling' },
  { Id: 26087, Code: '9120', Name: 'Bailiff, level, sewer commissioners' },
  { Id: 26088, Code: '9120', Name: 'Bailiff, reservoir' },
  { Id: 26089, Code: '9120', Name: 'Banksman, canal contractors' },
  { Id: 26090, Code: '9120', Name: 'Boiler, tar, building construction' },
  { Id: 26091, Code: '9120', Name: 'Boy, chain' },
  { Id: 26092, Code: '9120', Name: 'Breaker, rock, construction' },
  { Id: 26093, Code: '9120', Name: 'Canalman' },
  { Id: 26094, Code: '9120', Name: 'Caretaker, RESERVOIR' },
  { Id: 26095, Code: '9120', Name: 'Carrier, hod' },
  { Id: 26096, Code: '9120', Name: 'Chainman' },
  { Id: 26097, Code: '9120', Name: 'Cleaner, river' },
  { Id: 26098, Code: '9120', Name: 'Clothier, boiler' },
  { Id: 26099, Code: '9120', Name: 'Contractor, coal mine' },
  { Id: 26100, Code: '9120', Name: 'Contractor, drainage' },
  { Id: 26101, Code: '9120', Name: 'Contractor, penning' },
  { Id: 26102, Code: '9120', Name: 'Coverer, boiler' },
  { Id: 26103, Code: '9120', Name: 'Coverer, pipe' },
  { Id: 26104, Code: '9120', Name: 'Digger, grave' },
  { Id: 26105, Code: '9120', Name: 'Digger, trench' },
  { Id: 26106, Code: '9120', Name: 'Dismantler, furnace' },
  { Id: 26107, Code: '9120', Name: 'Ditcher' },
  { Id: 26108, Code: '9120', Name: 'Drainer' },
  { Id: 26109, Code: '9120', Name: 'Erector, light, street' },
  { Id: 26110, Code: '9120', Name: 'Erector, marquee' },
  { Id: 26111, Code: '9120', Name: 'Erector, tent' },
  { Id: 26112, Code: '9120', Name: 'Examiner, trench' },
  { Id: 26113, Code: '9120', Name: "Fitter, builder's" },
  { Id: 26114, Code: '9120', Name: 'Fitter, insulating' },
  { Id: 26115, Code: '9120', Name: 'Fixer, insulation' },
  { Id: 26116, Code: '9120', Name: 'Fixer, lath, metal' },
  { Id: 26117, Code: '9120', Name: 'Foreman, length, river, water authority' },
  { Id: 26118, Code: '9120', Name: 'Foreman, river, water authority' },
  { Id: 26119, Code: '9120', Name: 'Foreman, surface, coal mine' },
  { Id: 26120, Code: '9120', Name: 'Foreman, waterways' },
  { Id: 26121, Code: '9120', Name: 'Foreman, yard, coal mine' },
  { Id: 26122, Code: '9120', Name: 'Ganger, canals' },
  { Id: 26123, Code: '9120', Name: 'Hand, insulation' },
  { Id: 26124, Code: '9120', Name: 'Hand, maintenance, canals' },
  { Id: 26125, Code: '9120', Name: 'Hand, maintenance, estate' },
  { Id: 26126, Code: '9120', Name: "Handyman, builder's" },
  { Id: 26127, Code: '9120', Name: "Handyman, carpenter's" },
  {
    Id: 26128,
    Code: '9120',
    Name: "Handyman, electrician's, building construction"
  },
  { Id: 26129, Code: '9120', Name: "Handyman, fitter's, PIPE" },
  { Id: 26130, Code: '9120', Name: 'Handyman-labourer' },
  {
    Id: 26131,
    Code: '9120',
    Name: "Help, electrician's, building construction"
  },
  { Id: 26132, Code: '9120', Name: "Helper, bricklayer's" },
  { Id: 26133, Code: '9120', Name: "Helper, carpenter's" },
  {
    Id: 26134,
    Code: '9120',
    Name: "Helper, electrician's, building construction"
  },
  { Id: 26135, Code: '9120', Name: "Helper, mason's" },
  { Id: 26136, Code: '9120', Name: 'Inspector, canal' },
  {
    Id: 26137,
    Code: '9120',
    Name: 'Inspector, navigation, river, water authority'
  },
  { Id: 26138, Code: '9120', Name: 'Inspector, river' },
  { Id: 26139, Code: '9120', Name: 'Inspector, section, waterways' },
  { Id: 26140, Code: '9120', Name: 'Inspector, waterways' },
  { Id: 26141, Code: '9120', Name: 'Insulator, loft' },
  { Id: 26142, Code: '9120', Name: 'Insulator, pipe' },
  { Id: 26143, Code: '9120', Name: 'Insulator, refrigerator' },
  { Id: 26144, Code: '9120', Name: "Jobber, agent's, estate" },
  { Id: 26145, Code: '9120', Name: "Jobber, builder's" },
  { Id: 26146, Code: '9120', Name: 'Jobber, building construction' },
  { Id: 26147, Code: '9120', Name: 'Jobber, property' },
  { Id: 26148, Code: '9120', Name: 'Keeper, reservoir' },
  { Id: 26149, Code: '9120', Name: 'Keeper, wharf, canal' },
  { Id: 26150, Code: '9120', Name: "Labourer, bricklayer's" },
  { Id: 26151, Code: '9120', Name: "Labourer, bricksetter's" },
  { Id: 26152, Code: '9120', Name: "Labourer, builder's" },
  { Id: 26153, Code: '9120', Name: "Labourer, builder's, boat" },
  { Id: 26154, Code: '9120', Name: 'Labourer, building' },
  { Id: 26155, Code: '9120', Name: 'Labourer, building construction' },
  { Id: 26156, Code: '9120', Name: "Labourer, carpenter's" },
  { Id: 26157, Code: '9120', Name: 'Labourer, construction' },
  { Id: 26158, Code: '9120', Name: "Labourer, contractor's" },
  { Id: 26159, Code: '9120', Name: "Labourer, cooper's" },
  {
    Id: 26160,
    Code: '9120',
    Name: "Labourer, electrician's, building construction"
  },
  { Id: 26161, Code: '9120', Name: 'Labourer, engineering, civil' },
  { Id: 26162, Code: '9120', Name: "Labourer, engineer's, civil" },
  { Id: 26163, Code: '9120', Name: "Labourer, fitter's, shop" },
  { Id: 26164, Code: '9120', Name: "Labourer, fixer's, felt" },
  { Id: 26165, Code: '9120', Name: "Labourer, fixer's, sheet" },
  { Id: 26166, Code: '9120', Name: "Labourer, joiner's" },
  { Id: 26167, Code: '9120', Name: "Labourer, layer's, granolithic" },
  { Id: 26168, Code: '9120', Name: "Labourer, layer's, terrazzo" },
  { Id: 26169, Code: '9120', Name: "Labourer, maker's, cabinet" },
  { Id: 26170, Code: '9120', Name: "Labourer, mason's, fixer" },
  { Id: 26171, Code: '9120', Name: "Labourer, mason's, stone" },
  { Id: 26172, Code: '9120', Name: "Labourer, plasterer's" },
  { Id: 26173, Code: '9120', Name: "Labourer, platelayer's" },
  { Id: 26174, Code: '9120', Name: "Labourer, plumber's" },
  { Id: 26175, Code: '9120', Name: "Labourer, roofer's" },
  { Id: 26176, Code: '9120', Name: 'Labourer, roofing' },
  { Id: 26177, Code: '9120', Name: 'Labourer, site' },
  { Id: 26178, Code: '9120', Name: "Labourer, slater's" },
  { Id: 26179, Code: '9120', Name: "Labourer, steeplejack's" },
  { Id: 26180, Code: '9120', Name: 'Labourer, track, electric' },
  { Id: 26181, Code: '9120', Name: 'Lagger' },
  { Id: 26182, Code: '9120', Name: 'Lagger, asbestos' },
  { Id: 26183, Code: '9120', Name: 'Lagger, boiler' },
  { Id: 26184, Code: '9120', Name: 'Lagger, pipe' },
  { Id: 26185, Code: '9120', Name: 'Lather, metal' },
  { Id: 26186, Code: '9120', Name: 'Lengthman, canals' },
  { Id: 26187, Code: '9120', Name: 'Liner, pipe' },
  { Id: 26188, Code: '9120', Name: 'Liner, pipe, building construction' },
  { Id: 26189, Code: '9120', Name: "Linesman, diver's" },
  { Id: 26190, Code: '9120', Name: "Linesman, surveyor's" },
  { Id: 26191, Code: '9120', Name: 'Linesman, water works' },
  { Id: 26192, Code: '9120', Name: 'Maker, composition, BOILER COVERING' },
  { Id: 26193, Code: '9120', Name: 'Man, job, odd' },
  { Id: 26194, Code: '9120', Name: 'Man, maintenance, water works' },
  { Id: 26195, Code: '9120', Name: 'Man, marsh' },
  {
    Id: 26196,
    Code: '9120',
    Name: 'Man, mixer, asphalt, building construction'
  },
  { Id: 26197, Code: '9120', Name: 'Man, timber, building construction' },
  { Id: 26198, Code: '9120', Name: 'Man, timber, coal mine: above ground' },
  { Id: 26199, Code: '9120', Name: 'Man, timber, electricity supplier' },
  { Id: 26200, Code: '9120', Name: 'Man, timber, local government' },
  { Id: 26201, Code: '9120', Name: 'Man, timber, railways' },
  { Id: 26202, Code: '9120', Name: 'Man, timber, water company' },
  { Id: 26203, Code: '9120', Name: "Mate, asphalter's" },
  { Id: 26204, Code: '9120', Name: "Mate, builder's" },
  { Id: 26205, Code: '9120', Name: "Mate, carpenter's" },
  { Id: 26206, Code: '9120', Name: "Mate, carpenter's and joiner's" },
  { Id: 26207, Code: '9120', Name: "Mate, coverer's, boiler" },
  { Id: 26208, Code: '9120', Name: "Mate, craftsman's" },
  {
    Id: 26209,
    Code: '9120',
    Name: "Mate, electrician's, building construction"
  },
  { Id: 26210, Code: '9120', Name: "Mate, fixer's" },
  { Id: 26211, Code: '9120', Name: "Mate, flagger's" },
  { Id: 26212, Code: '9120', Name: "Mate, glazier's" },
  { Id: 26213, Code: '9120', Name: "Mate, joiner's" },
  { Id: 26214, Code: '9120', Name: "Mate, jointer's, pipe" },
  { Id: 26215, Code: '9120', Name: "Mate, lagger's" },
  { Id: 26216, Code: '9120', Name: "Mate, layer's, brick" },
  { Id: 26217, Code: '9120', Name: "Mate, layer's, granolithic" },
  { Id: 26218, Code: '9120', Name: "Mate, layer's, main" },
  { Id: 26219, Code: '9120', Name: "Mate, layer's, plate" },
  { Id: 26220, Code: '9120', Name: "Mate, layer's, service" },
  { Id: 26221, Code: '9120', Name: "Mate, layer's, terrazzo" },
  { Id: 26222, Code: '9120', Name: "Mate, machinist's, wood" },
  { Id: 26223, Code: '9120', Name: "Mate, mason's" },
  { Id: 26224, Code: '9120', Name: "Mate, painter's" },
  { Id: 26225, Code: '9120', Name: "Mate, pavior's" },
  { Id: 26226, Code: '9120', Name: "Mate, plasterer's" },
  { Id: 26227, Code: '9120', Name: "Mate, plumber and jointer's" },
  { Id: 26228, Code: '9120', Name: "Mate, plumber's" },
  { Id: 26229, Code: '9120', Name: "Mate, roofer's" },
  { Id: 26230, Code: '9120', Name: "Mate, sawyer's" },
  { Id: 26231, Code: '9120', Name: "Mate, scaffolder's" },
  { Id: 26232, Code: '9120', Name: "Mate, sheeter's" },
  { Id: 26233, Code: '9120', Name: "Mate, slater's" },
  { Id: 26234, Code: '9120', Name: "Mate, steeplejack's" },
  { Id: 26235, Code: '9120', Name: "Mate, tiler's" },
  { Id: 26236, Code: '9120', Name: "Mate, timberman's" },
  { Id: 26237, Code: '9120', Name: "Mate, wheelwright's" },
  { Id: 26238, Code: '9120', Name: 'Mixer, asphalt, building construction' },
  { Id: 26239, Code: '9120', Name: 'Mixer, tar, building construction' },
  { Id: 26240, Code: '9120', Name: 'Navvy' },
  { Id: 26241, Code: '9120', Name: 'Navvy, pond, slurry' },
  { Id: 26242, Code: '9120', Name: 'Operative, defence, flood' },
  { Id: 26243, Code: '9120', Name: 'Operative, rivers' },
  { Id: 26244, Code: '9120', Name: 'Operator, gun, cement' },
  { Id: 26245, Code: '9120', Name: 'Operator, tank, asphalt' },
  { Id: 26246, Code: '9120', Name: 'Packer, asbestos' },
  { Id: 26247, Code: '9120', Name: 'Pipeliner' },
  { Id: 26248, Code: '9120', Name: 'Porter-handyman' },
  { Id: 26249, Code: '9120', Name: 'Potman, asphalt' },
  { Id: 26250, Code: '9120', Name: 'Potman, building construction' },
  { Id: 26251, Code: '9120', Name: 'Proofer, damp' },
  { Id: 26252, Code: '9120', Name: 'Proofer, water, building construction' },
  { Id: 26253, Code: '9120', Name: 'Propman, coal mine: above ground' },
  { Id: 26254, Code: '9120', Name: 'Propper, coal mine: above ground' },
  { Id: 26255, Code: '9120', Name: 'Puller-off, sawmilling' },
  { Id: 26256, Code: '9120', Name: 'Rammerman' },
  { Id: 26257, Code: '9120', Name: 'Repairer, bank, CANAL' },
  { Id: 26258, Code: '9120', Name: 'Riverman' },
  { Id: 26259, Code: '9120', Name: "Signalman, diver's" },
  { Id: 26260, Code: '9120', Name: 'Spreader, building construction' },
  { Id: 26261, Code: '9120', Name: 'Superintendent, reservoir' },
  { Id: 26262, Code: '9120', Name: 'Tacker, board' },
  { Id: 26263, Code: '9120', Name: 'Taker, care, RESERVOIR' },
  { Id: 26264, Code: '9120', Name: "Tender, diver's" },
  { Id: 26265, Code: '9120', Name: "Tenter, diver's" },
  { Id: 26266, Code: '9120', Name: 'Tester, drain' },
  { Id: 26267, Code: '9120', Name: 'Tester, pipe, main laying' },
  { Id: 26268, Code: '9120', Name: 'Trenchman' },
  { Id: 26269, Code: '9120', Name: 'Tupper, building construction' },
  { Id: 26270, Code: '9120', Name: 'Worker, building' },
  { Id: 26271, Code: '9120', Name: 'Worker, canal' },
  { Id: 26272, Code: '9120', Name: 'Worker, construction' },
  { Id: 26273, Code: '9120', Name: 'Worker, drainage' },
  { Id: 26274, Code: '9120', Name: 'Worker, ground, building construction' },
  { Id: 26275, Code: '9120', Name: 'Worker, river' },
  { Id: 26276, Code: '9132', Name: 'Cleaner and greaser' },
  { Id: 26277, Code: '9132', Name: 'Cleaner, bag' },
  { Id: 26278, Code: '9132', Name: 'Cleaner, barrel' },
  { Id: 26279, Code: '9132', Name: 'Cleaner, bogie' },
  { Id: 26280, Code: '9132', Name: 'Cleaner, boiler' },
  { Id: 26281, Code: '9132', Name: 'Cleaner, book' },
  { Id: 26282, Code: '9132', Name: 'Cleaner, boot' },
  { Id: 26283, Code: '9132', Name: 'Cleaner, bottle' },
  { Id: 26284, Code: '9132', Name: 'Cleaner, box, textile printing' },
  { Id: 26285, Code: '9132', Name: 'Cleaner, brass' },
  { Id: 26286, Code: '9132', Name: 'Cleaner, brass musical instruments mfr' },
  { Id: 26287, Code: '9132', Name: 'Cleaner, buddle' },
  { Id: 26288, Code: '9132', Name: 'Cleaner, card' },
  { Id: 26289, Code: '9132', Name: 'Cleaner, cask' },
  { Id: 26290, Code: '9132', Name: 'Cleaner, crane' },
  { Id: 26291, Code: '9132', Name: 'Cleaner, cycle' },
  { Id: 26292, Code: '9132', Name: 'Cleaner, decomposer, nickel mfr' },
  { Id: 26293, Code: '9132', Name: 'Cleaner, drum' },
  { Id: 26294, Code: '9132', Name: 'Cleaner, engine, carding' },
  { Id: 26295, Code: '9132', Name: 'Cleaner, engine, coal mine' },
  { Id: 26296, Code: '9132', Name: 'Cleaner, engine, railways' },
  { Id: 26297, Code: '9132', Name: 'Cleaner, engine, water works' },
  { Id: 26298, Code: '9132', Name: 'Cleaner, equipment' },
  { Id: 26299, Code: '9132', Name: 'Cleaner, factory' },
  { Id: 26300, Code: '9132', Name: 'Cleaner, frame, CYCLES, MOTORS' },
  { Id: 26301, Code: '9132', Name: 'Cleaner, gulley' },
  { Id: 26302, Code: '9132', Name: 'Cleaner, heald' },
  { Id: 26303, Code: '9132', Name: 'Cleaner, heddle' },
  { Id: 26304, Code: '9132', Name: 'Cleaner, house, boiler' },
  { Id: 26305, Code: '9132', Name: 'Cleaner, house, power' },
  { Id: 26306, Code: '9132', Name: 'Cleaner, hydraulic' },
  { Id: 26307, Code: '9132', Name: 'Cleaner, hygiene, manufacturing' },
  { Id: 26308, Code: '9132', Name: 'Cleaner, industrial' },
  { Id: 26309, Code: '9132', Name: 'Cleaner, jet' },
  { Id: 26310, Code: '9132', Name: 'Cleaner, key, LOCKS' },
  { Id: 26311, Code: '9132', Name: 'Cleaner, laboratory' },
  { Id: 26312, Code: '9132', Name: 'Cleaner, lamp' },
  { Id: 26313, Code: '9132', Name: 'Cleaner, lens' },
  { Id: 26314, Code: '9132', Name: 'Cleaner, line' },
  { Id: 26315, Code: '9132', Name: 'Cleaner, loco' },
  { Id: 26316, Code: '9132', Name: 'Cleaner, locomotive' },
  { Id: 26317, Code: '9132', Name: 'Cleaner, machine' },
  { Id: 26318, Code: '9132', Name: 'Cleaner, machinery' },
  { Id: 26319, Code: '9132', Name: 'Cleaner, maintenance' },
  { Id: 26320, Code: '9132', Name: 'Cleaner, metal goods mfr' },
  { Id: 26321, Code: '9132', Name: 'Cleaner, mould' },
  { Id: 26322, Code: '9132', Name: 'Cleaner, needle mfr' },
  { Id: 26323, Code: '9132', Name: 'Cleaner, optical instrument mfr' },
  { Id: 26324, Code: '9132', Name: 'Cleaner, piano, organ mfr' },
  { Id: 26325, Code: '9132', Name: 'Cleaner, pipe' },
  { Id: 26326, Code: '9132', Name: 'Cleaner, pit, railways' },
  { Id: 26327, Code: '9132', Name: 'Cleaner, plant' },
  { Id: 26328, Code: '9132', Name: "Cleaner, printer's" },
  { Id: 26329, Code: '9132', Name: 'Cleaner, printing' },
  { Id: 26330, Code: '9132', Name: 'Cleaner, road and yard, railways' },
  { Id: 26331, Code: '9132', Name: 'Cleaner, road, mine: not coal' },
  { Id: 26332, Code: '9132', Name: 'Cleaner, sack' },
  { Id: 26333, Code: '9132', Name: 'Cleaner, scrap' },
  { Id: 26334, Code: '9132', Name: 'Cleaner, sheet, plastics mfr' },
  { Id: 26335, Code: '9132', Name: 'Cleaner, silver' },
  { Id: 26336, Code: '9132', Name: 'Cleaner, steam, building construction' },
  { Id: 26337, Code: '9132', Name: 'Cleaner, steam, engineering' },
  { Id: 26338, Code: '9132', Name: 'Cleaner, steam, vehicle trades' },
  { Id: 26339, Code: '9132', Name: 'Cleaner, still' },
  { Id: 26340, Code: '9132', Name: 'Cleaner, tank' },
  { Id: 26341, Code: '9132', Name: 'Cleaner, tape, magnetic' },
  { Id: 26342, Code: '9132', Name: 'Cleaner, tin, BAKERY' },
  { Id: 26343, Code: '9132', Name: 'Cleaner, tin, food canning' },
  { Id: 26344, Code: '9132', Name: 'Cleaner, tray, bakery' },
  { Id: 26345, Code: '9132', Name: 'Cleaner, tube, blast furnace' },
  { Id: 26346, Code: '9132', Name: 'Cleaner, tube, boiler' },
  { Id: 26347, Code: '9132', Name: 'Cleaner, tube, lamp, valve mfr' },
  { Id: 26348, Code: '9132', Name: 'Cleaner, tube, railways' },
  { Id: 26349, Code: '9132', Name: 'Cleaner, vat' },
  { Id: 26350, Code: '9132', Name: 'Cleaner, ware' },
  { Id: 26351, Code: '9132', Name: 'Cleaner, works' },
  { Id: 26352, Code: '9132', Name: 'Cleaner-engineer' },
  { Id: 26353, Code: '9132', Name: 'Cleaner-stoker' },
  { Id: 26354, Code: '9132', Name: 'Conditioner, air' },
  { Id: 26355, Code: '9132', Name: 'Drainman' },
  { Id: 26356, Code: '9132', Name: 'Emptier' },
  { Id: 26357, Code: '9132', Name: 'Fireman, refuse disposal' },
  { Id: 26358, Code: '9132', Name: 'Fluffer, underground railway' },
  { Id: 26359, Code: '9132', Name: 'Foreman, destructor, dust' },
  { Id: 26360, Code: '9132', Name: 'Hygienist' },
  { Id: 26361, Code: '9132', Name: 'Hygienist, night, manufacturing' },
  { Id: 26362, Code: '9132', Name: 'Jetter, water, pressure, high' },
  { Id: 26363, Code: '9132', Name: 'Labourer, industrial cleaning' },
  { Id: 26364, Code: '9132', Name: 'Machinist, cleaning, seed merchants' },
  { Id: 26365, Code: '9132', Name: 'Machinist, jetting, industrial cleaning' },
  { Id: 26366, Code: '9132', Name: 'Machinist, washing, BOTTLE' },
  { Id: 26367, Code: '9132', Name: 'Machinist, washing, transport' },
  { Id: 26368, Code: '9132', Name: 'Man, destructor, refuse' },
  { Id: 26369, Code: '9132', Name: 'Man, dry, mine: not coal' },
  { Id: 26370, Code: '9132', Name: 'Man, gulley' },
  { Id: 26371, Code: '9132', Name: 'Man, jet' },
  { Id: 26372, Code: '9132', Name: 'Operative, cleansing, machine' },
  { Id: 26373, Code: '9132', Name: 'Operative, hygiene' },
  { Id: 26374, Code: '9132', Name: 'Operator, hygiene' },
  { Id: 26375, Code: '9132', Name: 'Operator, incinerator' },
  { Id: 26376, Code: '9132', Name: 'Rinser, bottle' },
  { Id: 26377, Code: '9132', Name: 'Roofman' },
  { Id: 26378, Code: '9132', Name: 'Washer, barrel' },
  { Id: 26379, Code: '9132', Name: 'Washer, basket, docks' },
  { Id: 26380, Code: '9132', Name: 'Washer, bottle' },
  { Id: 26381, Code: '9132', Name: 'Washer, box' },
  { Id: 26382, Code: '9132', Name: 'Washer, bulb, lamp mfr' },
  { Id: 26383, Code: '9132', Name: 'Washer, carriage' },
  { Id: 26384, Code: '9132', Name: 'Washer, cask' },
  { Id: 26385, Code: '9132', Name: 'Washer, drum' },
  { Id: 26386, Code: '9132', Name: 'Washer, jar' },
  { Id: 26387, Code: '9132', Name: 'Washer, keg' },
  { Id: 26388, Code: '9132', Name: 'Washer, pot' },
  { Id: 26389, Code: '9132', Name: 'Washer, transport' },
  { Id: 26390, Code: '9132', Name: 'Worker, hygiene' },
  { Id: 26391, Code: '9134', Name: 'Assistant, bottling' },
  { Id: 26392, Code: '9134', Name: 'Assistant, general, PACKING' },
  { Id: 26393, Code: '9134', Name: 'Bagger' },
  { Id: 26394, Code: '9134', Name: 'Bagger, cake' },
  { Id: 26395, Code: '9134', Name: 'Bagger, coal' },
  { Id: 26396, Code: '9134', Name: 'Bagger, fibre, asbestos mfr' },
  { Id: 26397, Code: '9134', Name: 'Bagger-off, starch mfr' },
  { Id: 26398, Code: '9134', Name: 'Bagman, cement mfr' },
  { Id: 26399, Code: '9134', Name: 'Baler' },
  { Id: 26400, Code: '9134', Name: 'Baler, paper' },
  { Id: 26401, Code: '9134', Name: 'Baler, salle' },
  { Id: 26402, Code: '9134', Name: 'Bander, boot polish mfr' },
  { Id: 26403, Code: '9134', Name: 'Bander, cigar' },
  { Id: 26404, Code: '9134', Name: 'Bander, coil' },
  { Id: 26405, Code: '9134', Name: 'Bander, iron and steelworks' },
  { Id: 26406, Code: '9134', Name: 'Bolter, textile bleaching, dyeing' },
  { Id: 26407, Code: '9134', Name: 'Bottler' },
  { Id: 26408, Code: '9134', Name: 'Boxer' },
  { Id: 26409, Code: '9134', Name: 'Boxer-up, ceramics mfr' },
  { Id: 26410, Code: '9134', Name: 'Buncher, watercress' },
  { Id: 26411, Code: '9134', Name: 'Bundler' },
  { Id: 26412, Code: '9134', Name: 'Bundler and wrapper, CIGARETTES' },
  { Id: 26413, Code: '9134', Name: 'Bundler, bag' },
  { Id: 26414, Code: '9134', Name: 'Bundler, waste, textile mfr' },
  { Id: 26415, Code: '9134', Name: 'Canner' },
  { Id: 26416, Code: '9134', Name: 'Capper, bottle' },
  { Id: 26417, Code: '9134', Name: 'Capper, paper' },
  { Id: 26418, Code: '9134', Name: 'Capper, polish mfr' },
  { Id: 26419, Code: '9134', Name: 'Capsuler' },
  { Id: 26420, Code: '9134', Name: 'Carder, button mfr' },
  { Id: 26421, Code: '9134', Name: 'Carder, comb' },
  { Id: 26422, Code: '9134', Name: 'Carder, hook mfr' },
  { Id: 26423, Code: '9134', Name: 'Carder, pencil, crayon mfr' },
  { Id: 26424, Code: '9134', Name: 'Cartoner' },
  { Id: 26425, Code: '9134', Name: 'Caser, PACKING' },
  { Id: 26426, Code: '9134', Name: 'Cellophaner' },
  { Id: 26427, Code: '9134', Name: 'Checker and packer' },
  { Id: 26428, Code: '9134', Name: 'Coder, manufacturing' },
  { Id: 26429, Code: '9134', Name: 'Cooper, wine' },
  { Id: 26430, Code: '9134', Name: 'Corker' },
  {
    Id: 26431,
    Code: '9134',
    Name: 'Counter, bolt, nail, nut, rivet, screw mfr'
  },
  { Id: 26432, Code: '9134', Name: 'Counterman, hosiery, knitwear mfr' },
  { Id: 26433, Code: '9134', Name: 'Crater, manufacturing' },
  { Id: 26434, Code: '9134', Name: 'Dresser, box' },
  { Id: 26435, Code: '9134', Name: 'Drummer, glycerine' },
  { Id: 26436, Code: '9134', Name: 'Feeder, can' },
  { Id: 26437, Code: '9134', Name: 'Filler, ampoule' },
  { Id: 26438, Code: '9134', Name: 'Filler, bag' },
  { Id: 26439, Code: '9134', Name: 'Filler, barrel' },
  { Id: 26440, Code: '9134', Name: 'Filler, bottle' },
  { Id: 26441, Code: '9134', Name: 'Filler, box' },
  { Id: 26442, Code: '9134', Name: 'Filler, brewery' },
  { Id: 26443, Code: '9134', Name: 'Filler, can, paint mfr' },
  { Id: 26444, Code: '9134', Name: 'Filler, can, petroleum distribution' },
  { Id: 26445, Code: '9134', Name: 'Filler, cement mfr' },
  { Id: 26446, Code: '9134', Name: 'Filler, chemical mfr' },
  { Id: 26447, Code: '9134', Name: 'Filler, cylinder' },
  { Id: 26448, Code: '9134', Name: 'Filler, drum, oil refining' },
  { Id: 26449, Code: '9134', Name: 'Filler, food products mfr' },
  { Id: 26450, Code: '9134', Name: 'Filler, match mfr' },
  { Id: 26451, Code: '9134', Name: 'Filler, medical, oxygen works' },
  { Id: 26452, Code: '9134', Name: 'Filler, oil' },
  { Id: 26453, Code: '9134', Name: 'Filler, oil refining' },
  { Id: 26454, Code: '9134', Name: 'Filler, oxygen' },
  { Id: 26455, Code: '9134', Name: 'Filler, paint' },
  { Id: 26456, Code: '9134', Name: 'Filler, paint mfr' },
  { Id: 26457, Code: '9134', Name: 'Filler, pickle' },
  { Id: 26458, Code: '9134', Name: 'Filler, polish' },
  { Id: 26459, Code: '9134', Name: 'Filler, sterile, CSSD' },
  { Id: 26460, Code: '9134', Name: 'Filler, varnish' },
  { Id: 26461, Code: '9134', Name: 'Finisher, jam' },
  { Id: 26462, Code: '9134', Name: 'Finisher, pharmaceutical products mfr' },
  { Id: 26463, Code: '9134', Name: 'Finisher, toilet preparations mfr' },
  { Id: 26464, Code: '9134', Name: "Fitter, box, artists' colours mfr" },
  { Id: 26465, Code: '9134', Name: 'Fitter, cork' },
  { Id: 26466, Code: '9134', Name: 'Foiler' },
  { Id: 26467, Code: '9134', Name: 'Folder, clothing mfr' },
  { Id: 26468, Code: '9134', Name: 'Folder, curtain' },
  { Id: 26469, Code: '9134', Name: 'Folder, handkerchief' },
  {
    Id: 26470,
    Code: '9134',
    Name: 'Folder, laundry, launderette, dry cleaning'
  },
  { Id: 26471, Code: '9134', Name: 'Folder, net' },
  { Id: 26472, Code: '9134', Name: 'Folder, textile mfr: hosiery finishing' },
  { Id: 26473, Code: '9134', Name: 'Foreman, bottling' },
  { Id: 26474, Code: '9134', Name: 'Foreman, food canning' },
  { Id: 26475, Code: '9134', Name: 'Foreman, packing service' },
  { Id: 26476, Code: '9134', Name: 'Foreman, room, packing' },
  { Id: 26477, Code: '9134', Name: 'Grosser, textile making-up' },
  {
    Id: 26478,
    Code: '9134',
    Name: 'Hand, bench, printing: newspaper printing'
  },
  { Id: 26479, Code: '9134', Name: 'Hand, bench, stationers' },
  { Id: 26480, Code: '9134', Name: 'Hand, box' },
  { Id: 26481, Code: '9134', Name: 'Hanker, textile packing' },
  { Id: 26482, Code: '9134', Name: 'Jennier' },
  { Id: 26483, Code: '9134', Name: 'Kimballer' },
  { Id: 26484, Code: '9134', Name: 'Labeller' },
  { Id: 26485, Code: '9134', Name: 'Lidder, boot polish mfr' },
  { Id: 26486, Code: '9134', Name: 'Linesman, brewery' },
  { Id: 26487, Code: '9134', Name: 'Locker, hat mfr' },
  { Id: 26488, Code: '9134', Name: 'Machinist, baling' },
  { Id: 26489, Code: '9134', Name: 'Machinist, bottling' },
  { Id: 26490, Code: '9134', Name: 'Machinist, capping, bottle' },
  { Id: 26491, Code: '9134', Name: 'Machinist, closing, canned foods mfr' },
  { Id: 26492, Code: '9134', Name: 'Machinist, filling and capping' },
  { Id: 26493, Code: '9134', Name: 'Machinist, filling, cosmetic mfr' },
  { Id: 26494, Code: '9134', Name: 'Machinist, filling, food canning' },
  { Id: 26495, Code: '9134', Name: 'Machinist, labelling' },
  { Id: 26496, Code: '9134', Name: 'Machinist, packing' },
  { Id: 26497, Code: '9134', Name: 'Machinist, seaming, canned foods mfr' },
  { Id: 26498, Code: '9134', Name: 'Machinist, wrapping' },
  { Id: 26499, Code: '9134', Name: 'Machinist-packer' },
  { Id: 26500, Code: '9134', Name: 'Maker-up, cloth' },
  { Id: 26501, Code: '9134', Name: 'Maker-up, handkerchief mfr' },
  { Id: 26502, Code: '9134', Name: 'Maker-up, piece' },
  { Id: 26503, Code: '9134', Name: 'Maker-up, smallware' },
  { Id: 26504, Code: '9134', Name: 'Maker-up, spool' },
  { Id: 26505, Code: '9134', Name: 'Maker-up, yarn' },
  { Id: 26506, Code: '9134', Name: 'Man, room, white' },
  { Id: 26507, Code: '9134', Name: 'Man, tare, textile mfr' },
  { Id: 26508, Code: '9134', Name: 'Operative, packing' },
  { Id: 26509, Code: '9134', Name: 'Operator, baler' },
  { Id: 26510, Code: '9134', Name: 'Operator, bottling' },
  { Id: 26511, Code: '9134', Name: 'Operator, distribution' },
  { Id: 26512, Code: '9134', Name: 'Operator, line, PACKING, WRAPPING' },
  { Id: 26513, Code: '9134', Name: 'Operator, packaging' },
  { Id: 26514, Code: '9134', Name: 'Operator, packing' },
  { Id: 26515, Code: '9134', Name: 'Operator, plant, packaging' },
  { Id: 26516, Code: '9134', Name: 'Operator, process, PACKING' },
  { Id: 26517, Code: '9134', Name: 'Packer' },
  {
    Id: 26518,
    Code: '9134',
    Name: 'Packer and sorter, laundry, launderette, dry cleaning'
  },
  { Id: 26519, Code: '9134', Name: 'Packer and stacker' },
  { Id: 26520, Code: '9134', Name: 'Packer, cable' },
  { Id: 26521, Code: '9134', Name: 'Packer, chlorine' },
  { Id: 26522, Code: '9134', Name: 'Packer, clothing' },
  { Id: 26523, Code: '9134', Name: 'Packer, cop' },
  { Id: 26524, Code: '9134', Name: 'Packer, factory' },
  { Id: 26525, Code: '9134', Name: 'Packer, warehouse' },
  { Id: 26526, Code: '9134', Name: 'Packer, wool' },
  { Id: 26527, Code: '9134', Name: 'Packer-labourer' },
  { Id: 26528, Code: '9134', Name: 'Pager, type foundry' },
  { Id: 26529, Code: '9134', Name: 'Pairer, hosiery, knitwear mfr' },
  { Id: 26530, Code: '9134', Name: 'Palletiser' },
  { Id: 26531, Code: '9134', Name: 'Paperer, ceramics mfr' },
  { Id: 26532, Code: '9134', Name: 'Paperer, lace mfr' },
  { Id: 26533, Code: '9134', Name: 'Parceller' },
  { Id: 26534, Code: '9134', Name: 'Picker-packer' },
  { Id: 26535, Code: '9134', Name: 'Potter, shrimp' },
  { Id: 26536, Code: '9134', Name: 'Presser, hydraulic, packaging activities' },
  { Id: 26537, Code: '9134', Name: 'Presser, hydraulic, paper merchants' },
  { Id: 26538, Code: '9134', Name: 'Presser, paper' },
  { Id: 26539, Code: '9134', Name: 'Presser, textile mfr: textile packing' },
  { Id: 26540, Code: '9134', Name: 'Presser, waste merchants' },
  { Id: 26541, Code: '9134', Name: 'Putter-up, textile mfr' },
  { Id: 26542, Code: '9134', Name: 'Ribboner' },
  { Id: 26543, Code: '9134', Name: 'Roper' },
  { Id: 26544, Code: '9134', Name: 'Sealer, box' },
  { Id: 26545, Code: '9134', Name: 'Seamer, can' },
  { Id: 26546, Code: '9134', Name: 'Sighter, bottle, brewery' },
  { Id: 26547, Code: '9134', Name: 'Sticker, label' },
  { Id: 26548, Code: '9134', Name: 'Sticker, needle mfr' },
  { Id: 26549, Code: '9134', Name: 'Stitcher, bale' },
  { Id: 26550, Code: '9134', Name: 'Supervisor, packing' },
  { Id: 26551, Code: '9134', Name: 'Tabber, glove mfr' },
  { Id: 26552, Code: '9134', Name: 'Tabber, hosiery, knitwear mfr' },
  { Id: 26553, Code: '9134', Name: 'Technician, packaging' },
  { Id: 26554, Code: '9134', Name: 'Ticketer' },
  { Id: 26555, Code: '9134', Name: 'Tier, bag' },
  { Id: 26556, Code: '9134', Name: 'Tier, hook, cork' },
  { Id: 26557, Code: '9134', Name: 'Tier, ream' },
  { Id: 26558, Code: '9134', Name: 'Tinner, food canning' },
  { Id: 26559, Code: '9134', Name: 'Tinner, fruit' },
  { Id: 26560, Code: '9134', Name: 'Topper, boot polish mfr' },
  { Id: 26561, Code: '9134', Name: 'Topper, jam' },
  { Id: 26562, Code: '9134', Name: 'Worker, bench, newspaper printing' },
  { Id: 26563, Code: '9134', Name: 'Worker, cannery' },
  { Id: 26564, Code: '9134', Name: 'Worker, factory, PACKING' },
  {
    Id: 26565,
    Code: '9134',
    Name: 'Worker, process, food products mfr, PACKING'
  },
  { Id: 26566, Code: '9134', Name: 'Worker, process, PACKING' },
  { Id: 26567, Code: '9134', Name: 'Wrapper' },
  { Id: 26568, Code: '9134', Name: 'Wrapper-up' },
  { Id: 26569, Code: '9139', Name: 'Acetoner' },
  { Id: 26570, Code: '9139', Name: "Assistant, barker's" },
  { Id: 26571, Code: '9139', Name: "Assistant, burner's" },
  { Id: 26572, Code: '9139', Name: "Assistant, checker's, iron" },
  { Id: 26573, Code: '9139', Name: 'Assistant, craft, railways' },
  { Id: 26574, Code: '9139', Name: "Assistant, electrician's" },
  { Id: 26575, Code: '9139', Name: "Assistant, engineer's, MAINTENANCE" },
  { Id: 26576, Code: '9139', Name: 'Assistant, factory' },
  { Id: 26577, Code: '9139', Name: "Assistant, fitter's" },
  { Id: 26578, Code: '9139', Name: "Assistant, flanger's" },
  { Id: 26579, Code: '9139', Name: "Assistant, galvaniser's" },
  { Id: 26580, Code: '9139', Name: 'Assistant, general, manufacturing' },
  { Id: 26581, Code: '9139', Name: "Assistant, grinder's" },
  { Id: 26582, Code: '9139', Name: "Assistant, hardener's" },
  { Id: 26583, Code: '9139', Name: "Assistant, jointer's" },
  { Id: 26584, Code: '9139', Name: "Assistant, lifter's" },
  { Id: 26585, Code: '9139', Name: "Assistant, linesman's" },
  { Id: 26586, Code: '9139', Name: 'Assistant, linotype' },
  { Id: 26587, Code: '9139', Name: "Assistant, loader's" },
  { Id: 26588, Code: '9139', Name: "Assistant, maker's, boiler" },
  { Id: 26589, Code: '9139', Name: "Assistant, maker's, coach" },
  { Id: 26590, Code: '9139', Name: "Assistant, maker's, tool" },
  { Id: 26591, Code: '9139', Name: "Assistant, mechanic's" },
  { Id: 26592, Code: '9139', Name: 'Assistant, mill' },
  { Id: 26593, Code: '9139', Name: "Assistant, millwright's" },
  { Id: 26594, Code: '9139', Name: 'Assistant, plant, pilot' },
  { Id: 26595, Code: '9139', Name: "Assistant, plater's" },
  { Id: 26596, Code: '9139', Name: "Assistant, repairer's, wagon" },
  { Id: 26597, Code: '9139', Name: "Assistant, reshearer's" },
  { Id: 26598, Code: '9139', Name: 'Assistant, room, receiving, tailoring' },
  { Id: 26599, Code: '9139', Name: "Assistant, shipwright's" },
  { Id: 26600, Code: '9139', Name: 'Assistant, shop, machine' },
  { Id: 26601, Code: '9139', Name: "Assistant, smith's, boiler" },
  { Id: 26602, Code: '9139', Name: "Assistant, smith's, copper" },
  { Id: 26603, Code: '9139', Name: "Assistant, spreader's, colour" },
  { Id: 26604, Code: '9139', Name: 'Assistant, teaser' },
  { Id: 26605, Code: '9139', Name: "Assistant, temperer's" },
  { Id: 26606, Code: '9139', Name: 'Assistant, trade, shipbuilding' },
  { Id: 26607, Code: '9139', Name: 'Assistant, trades, shipbuilding' },
  { Id: 26608, Code: '9139', Name: "Assistant, tradesman's, metal trades" },
  { Id: 26609, Code: '9139', Name: "Assistant, wireman's" },
  { Id: 26610, Code: '9139', Name: 'Attendant, ash' },
  { Id: 26611, Code: '9139', Name: 'Attendant, battery, mine: not coal' },
  { Id: 26612, Code: '9139', Name: 'Attendant, belt' },
  { Id: 26613, Code: '9139', Name: 'Attendant, bunker' },
  { Id: 26614, Code: '9139', Name: 'Attendant, car, steel mfr' },
  { Id: 26615, Code: '9139', Name: 'Attendant, condenser, blast furnace' },
  { Id: 26616, Code: '9139', Name: 'Attendant, conveyor' },
  { Id: 26617, Code: '9139', Name: 'Attendant, craft, electricity supplier' },
  { Id: 26618, Code: '9139', Name: 'Attendant, elevator, char, wet' },
  { Id: 26619, Code: '9139', Name: "Attendant, engineer's, DETR" },
  { Id: 26620, Code: '9139', Name: 'Attendant, exhaust' },
  { Id: 26621, Code: '9139', Name: 'Attendant, exhauster' },
  { Id: 26622, Code: '9139', Name: 'Attendant, fan' },
  { Id: 26623, Code: '9139', Name: 'Attendant, gearhead' },
  { Id: 26624, Code: '9139', Name: 'Attendant, governor, gas works' },
  { Id: 26625, Code: '9139', Name: 'Attendant, gun' },
  { Id: 26626, Code: '9139', Name: 'Attendant, hopper, mine: not coal' },
  { Id: 26627, Code: '9139', Name: 'Attendant, house, blower' },
  { Id: 26628, Code: '9139', Name: 'Attendant, house, engine' },
  { Id: 26629, Code: '9139', Name: 'Attendant, house, exhaust' },
  { Id: 26630, Code: '9139', Name: 'Attendant, house, meter, gas works' },
  { Id: 26631, Code: '9139', Name: 'Attendant, house, pump' },
  { Id: 26632, Code: '9139', Name: 'Attendant, house, screen' },
  { Id: 26633, Code: '9139', Name: 'Attendant, incinerator, hospital service' },
  { Id: 26634, Code: '9139', Name: 'Attendant, locomotive' },
  { Id: 26635, Code: '9139', Name: 'Attendant, main, hydraulic' },
  { Id: 26636, Code: '9139', Name: 'Attendant, plant, ash' },
  { Id: 26637, Code: '9139', Name: 'Attendant, plant, cleaning, air' },
  { Id: 26638, Code: '9139', Name: 'Attendant, plant, coke' },
  { Id: 26639, Code: '9139', Name: 'Attendant, plant, conditioning, air' },
  { Id: 26640, Code: '9139', Name: 'Attendant, plant, drainage, mining' },
  { Id: 26641, Code: '9139', Name: 'Attendant, plant, refrigerating' },
  { Id: 26642, Code: '9139', Name: 'Attendant, plant, washing, vehicles' },
  { Id: 26643, Code: '9139', Name: 'Attendant, point, transfer, conveyor' },
  { Id: 26644, Code: '9139', Name: 'Attendant, quencher, coke' },
  { Id: 26645, Code: '9139', Name: 'Attendant, refrigerator' },
  { Id: 26646, Code: '9139', Name: 'Attendant, room, battery' },
  { Id: 26647, Code: '9139', Name: 'Attendant, room, engine' },
  { Id: 26648, Code: '9139', Name: 'Attendant, scale, green' },
  { Id: 26649, Code: '9139', Name: 'Attendant, scrubber, coke ovens' },
  { Id: 26650, Code: '9139', Name: 'Attendant, station, gas works' },
  { Id: 26651, Code: '9139', Name: 'Attendant, tank, cable mfr' },
  { Id: 26652, Code: '9139', Name: 'Attendant, tank, local government' },
  { Id: 26653, Code: '9139', Name: 'Attendant, valve' },
  { Id: 26654, Code: '9139', Name: 'Attendant, ventilation' },
  { Id: 26655, Code: '9139', Name: 'Auxiliary, craft, DETR' },
  { Id: 26656, Code: '9139', Name: 'Baler, oil wells' },
  { Id: 26657, Code: '9139', Name: 'Bankman, rolling mill' },
  { Id: 26658, Code: '9139', Name: 'Banksman, blast furnace' },
  { Id: 26659, Code: '9139', Name: 'Bayman, steelworks' },
  { Id: 26660, Code: '9139', Name: 'Beltman, patent fuel mfr' },
  { Id: 26661, Code: '9139', Name: 'Binder, iron' },
  { Id: 26662, Code: '9139', Name: 'Blockman, metal trades' },
  { Id: 26663, Code: '9139', Name: 'Bobber, embroidery mfr' },
  { Id: 26664, Code: '9139', Name: 'Bowler, steelworks' },
  { Id: 26665, Code: '9139', Name: 'Bowler, tyre' },
  { Id: 26666, Code: '9139', Name: 'Boy, best' },
  { Id: 26667, Code: '9139', Name: 'Boy, clay, metal mfr' },
  { Id: 26668, Code: '9139', Name: 'Brakesman, blast furnace' },
  { Id: 26669, Code: '9139', Name: 'Brakesman, engine' },
  { Id: 26670, Code: '9139', Name: 'Breaker, blast or puddling furnace' },
  { Id: 26671, Code: '9139', Name: 'Breaker, iron' },
  { Id: 26672, Code: '9139', Name: 'Breakerman, foundry' },
  { Id: 26673, Code: '9139', Name: 'Breaker-up' },
  { Id: 26674, Code: '9139', Name: 'Brusher, clothing mfr' },
  { Id: 26675, Code: '9139', Name: 'Brusher, metal file mfr' },
  { Id: 26676, Code: '9139', Name: 'Brusher-off, clothing mfr' },
  { Id: 26677, Code: '9139', Name: 'Bunkerman, coal gas, coke ovens' },
  { Id: 26678, Code: '9139', Name: 'Carman, blast furnace' },
  { Id: 26679, Code: '9139', Name: 'Carrier' },
  { Id: 26680, Code: '9139', Name: 'Carrier, bag' },
  { Id: 26681, Code: '9139', Name: 'Carrier, dust' },
  { Id: 26682, Code: '9139', Name: 'Carrier, glass, glass mfr' },
  { Id: 26683, Code: '9139', Name: 'Carrier, lap' },
  { Id: 26684, Code: '9139', Name: 'Carrier, mine: not coal: below ground' },
  { Id: 26685, Code: '9139', Name: 'Carrier, piece' },
  { Id: 26686, Code: '9139', Name: 'Carrier, roller' },
  { Id: 26687, Code: '9139', Name: 'Carrier, ware' },
  { Id: 26688, Code: '9139', Name: 'Carrier-away' },
  { Id: 26689, Code: '9139', Name: 'Carrier-off' },
  { Id: 26690, Code: '9139', Name: 'Cellarman, bacon, ham, meat curing' },
  { Id: 26691, Code: '9139', Name: 'Charger, cupola' },
  { Id: 26692, Code: '9139', Name: 'Charger, dust, flux' },
  { Id: 26693, Code: '9139', Name: 'Charger, furnace, metal mfr' },
  { Id: 26694, Code: '9139', Name: 'Charger, metal mfr' },
  { Id: 26695, Code: '9139', Name: 'Charger, spare, blast furnace' },
  { Id: 26696, Code: '9139', Name: 'Charger, tube, brass tube mfr' },
  { Id: 26697, Code: '9139', Name: 'Charman, sugar refining' },
  { Id: 26698, Code: '9139', Name: 'Charman, wet' },
  { Id: 26699, Code: '9139', Name: 'Chipper and scaler' },
  { Id: 26700, Code: '9139', Name: 'Clearer, bottom, tuyere' },
  { Id: 26701, Code: '9139', Name: 'Coaler' },
  { Id: 26702, Code: '9139', Name: 'Collector, ash' },
  { Id: 26703, Code: '9139', Name: 'Collector, cloth' },
  { Id: 26704, Code: '9139', Name: 'Collector, cop' },
  { Id: 26705, Code: '9139', Name: 'Collector, glass, DRY PLATES' },
  { Id: 26706, Code: '9139', Name: 'Collector, pit, ash' },
  { Id: 26707, Code: '9139', Name: 'Collector, scaleboard' },
  { Id: 26708, Code: '9139', Name: 'Collector, textile mfr' },
  { Id: 26709, Code: '9139', Name: 'Controller, charge, metal mfr' },
  { Id: 26710, Code: '9139', Name: 'Controller, coal, metal mfr' },
  { Id: 26711, Code: '9139', Name: 'Controlman, bunker' },
  { Id: 26712, Code: '9139', Name: 'Conveyor' },
  { Id: 26713, Code: '9139', Name: 'Craftsman, assistant, metal trades' },
  { Id: 26714, Code: '9139', Name: 'Dauber, coal gas, coke ovens' },
  { Id: 26715, Code: '9139', Name: 'Dauber, ladle, iron and steelworks' },
  { Id: 26716, Code: '9139', Name: 'Deliverer, textile mfr' },
  { Id: 26717, Code: '9139', Name: 'Deliveryman, textile mfr' },
  { Id: 26718, Code: '9139', Name: 'Depotman, blast furnace' },
  { Id: 26719, Code: '9139', Name: "Devil, printer's" },
  { Id: 26720, Code: '9139', Name: 'Devil, printing' },
  { Id: 26721, Code: '9139', Name: 'Discharger, coal gas, coke ovens' },
  { Id: 26722, Code: '9139', Name: 'Distributor, manufacturing' },
  { Id: 26723, Code: '9139', Name: 'Distributor, weft' },
  { Id: 26724, Code: '9139', Name: 'Distributor, work' },
  { Id: 26725, Code: '9139', Name: 'Doorman, coke ovens' },
  { Id: 26726, Code: '9139', Name: 'Dragger, pipe, brickworks' },
  { Id: 26727, Code: '9139', Name: 'Dragger, set' },
  { Id: 26728, Code: '9139', Name: 'Dragger, skip' },
  { Id: 26729, Code: '9139', Name: 'Drainer, brewery' },
  { Id: 26730, Code: '9139', Name: 'Drawer, brick' },
  { Id: 26731, Code: '9139', Name: 'Drawer, ceramics mfr' },
  { Id: 26732, Code: '9139', Name: 'Drawer, coke, coke ovens' },
  { Id: 26733, Code: '9139', Name: 'Drawer, kiln, ceramics mfr' },
  { Id: 26734, Code: '9139', Name: 'Drawer, lime, mine: not coal' },
  { Id: 26735, Code: '9139', Name: 'Drawer, mine: not coal' },
  { Id: 26736, Code: '9139', Name: 'Drawer, oven, ceramics mfr' },
  { Id: 26737, Code: '9139', Name: 'Drawer, paper mfr' },
  { Id: 26738, Code: '9139', Name: 'Driver, belt' },
  { Id: 26739, Code: '9139', Name: 'Driver, car, electric, steelworks' },
  { Id: 26740, Code: '9139', Name: 'Driver, car, furnace, blast' },
  { Id: 26741, Code: '9139', Name: 'Driver, charger, steelworks' },
  { Id: 26742, Code: '9139', Name: 'Driver, controller, steelworks' },
  { Id: 26743, Code: '9139', Name: 'Driver, conveyor' },
  { Id: 26744, Code: '9139', Name: 'Driver, exhauster, gas works' },
  { Id: 26745, Code: '9139', Name: 'Driver, fan' },
  { Id: 26746, Code: '9139', Name: 'Driver, guide, coke' },
  { Id: 26747, Code: '9139', Name: 'Driver, manipulator, steelworks' },
  { Id: 26748, Code: '9139', Name: 'Duffer' },
  { Id: 26749, Code: '9139', Name: 'Emptier, biscuit' },
  { Id: 26750, Code: '9139', Name: 'Emptier, ceramics mfr' },
  { Id: 26751, Code: '9139', Name: 'Emptier, rubbish, steelworks' },
  { Id: 26752, Code: '9139', Name: 'Emptier, ware' },
  { Id: 26753, Code: '9139', Name: 'Emptier, wheel' },
  { Id: 26754, Code: '9139', Name: 'Erector, wicket, ceramics mfr' },
  { Id: 26755, Code: '9139', Name: 'Feeder, belt' },
  { Id: 26756, Code: '9139', Name: 'Feeder, bin' },
  { Id: 26757, Code: '9139', Name: 'Feeder, cement mfr' },
  { Id: 26758, Code: '9139', Name: 'Feeder, line' },
  { Id: 26759, Code: '9139', Name: 'Felter, shipbuilding' },
  { Id: 26760, Code: '9139', Name: 'Fetcher, textile mfr' },
  { Id: 26761, Code: '9139', Name: 'Fielder, textile mfr' },
  { Id: 26762, Code: '9139', Name: 'Filler, barrow' },
  { Id: 26763, Code: '9139', Name: 'Filler, blast furnace' },
  { Id: 26764, Code: '9139', Name: 'Filler, box, blast furnace' },
  { Id: 26765, Code: '9139', Name: 'Filler, coke' },
  { Id: 26766, Code: '9139', Name: 'Filler, furnace, blast furnace' },
  { Id: 26767, Code: '9139', Name: 'Filler, mine: not coal' },
  { Id: 26768, Code: '9139', Name: 'Filler, petroleum distribution' },
  { Id: 26769, Code: '9139', Name: 'Filler, pot, steelworks' },
  { Id: 26770, Code: '9139', Name: 'Filler, salt' },
  { Id: 26771, Code: '9139', Name: 'Filler, spare' },
  { Id: 26772, Code: '9139', Name: 'Filler, tray' },
  { Id: 26773, Code: '9139', Name: 'Filler, wagon' },
  { Id: 26774, Code: '9139', Name: 'Filler-loader, petroleum distribution' },
  { Id: 26775, Code: '9139', Name: 'Floater, blast furnace' },
  { Id: 26776, Code: '9139', Name: 'Flyman' },
  { Id: 26777, Code: '9139', Name: 'Foreman, belt, mine: not coal' },
  { Id: 26778, Code: '9139', Name: 'Foreman, flat, card clothing mfr' },
  { Id: 26779, Code: '9139', Name: 'Foreman, scrap merchants, breakers' },
  { Id: 26780, Code: '9139', Name: 'Foreman, transport, internal' },
  { Id: 26781, Code: '9139', Name: 'Foreman, wallpaper mfr' },
  { Id: 26782, Code: '9139', Name: 'Foreman, yard' },
  { Id: 26783, Code: '9139', Name: 'Foreman, yard, scrap' },
  { Id: 26784, Code: '9139', Name: 'Gasman, blast furnace' },
  { Id: 26785, Code: '9139', Name: 'Gatherer, ceramics mfr' },
  { Id: 26786, Code: '9139', Name: 'Gatherer, rag, woollen mfr' },
  { Id: 26787, Code: '9139', Name: 'Grainer, brewery' },
  { Id: 26788, Code: '9139', Name: 'Grater, steelworks' },
  { Id: 26789, Code: '9139', Name: 'Greaser, tin box mfr' },
  { Id: 26790, Code: '9139', Name: 'Grinder, sweep' },
  { Id: 26791, Code: '9139', Name: 'Guard, train, ROPES' },
  { Id: 26792, Code: '9139', Name: 'Hammerman, jute mfr' },
  { Id: 26793, Code: '9139', Name: 'Hand, belt' },
  { Id: 26794, Code: '9139', Name: 'Hand, bench, printing' },
  { Id: 26795, Code: '9139', Name: 'Hand, box, sugar refining' },
  { Id: 26796, Code: '9139', Name: 'Hand, cable' },
  { Id: 26797, Code: '9139', Name: 'Hand, conveyor' },
  { Id: 26798, Code: '9139', Name: 'Hand, datal, mine: not coal' },
  { Id: 26799, Code: '9139', Name: 'Hand, foundry' },
  { Id: 26800, Code: '9139', Name: 'Hand, granary' },
  { Id: 26801, Code: '9139', Name: 'Hand, haulage, mine: not coal' },
  { Id: 26802, Code: '9139', Name: 'Hand, house, waste, textile mfr' },
  { Id: 26803, Code: '9139', Name: 'Hand, maintenance' },
  { Id: 26804, Code: '9139', Name: 'Hand, mill, nitrate' },
  { Id: 26805, Code: '9139', Name: 'Hand, pump' },
  { Id: 26806, Code: '9139', Name: 'Hand, warehouse, lace' },
  { Id: 26807, Code: '9139', Name: 'Handler, body, vehicle mfr' },
  { Id: 26808, Code: '9139', Name: 'Handler, coal' },
  { Id: 26809, Code: '9139', Name: 'Handler, material' },
  { Id: 26810, Code: '9139', Name: 'Handler, materials' },
  { Id: 26811, Code: '9139', Name: 'Handler, sheet, METAL' },
  {
    Id: 26812,
    Code: '9139',
    Name: 'Handler, stock, asbestos-cement goods mfr'
  },
  { Id: 26813, Code: '9139', Name: "Handyman, electrician's" },
  { Id: 26814, Code: '9139', Name: 'Handyman, engineering' },
  { Id: 26815, Code: '9139', Name: "Handyman, engineer's" },
  { Id: 26816, Code: '9139', Name: "Handyman, fitter's" },
  { Id: 26817, Code: '9139', Name: 'Haulerman' },
  { Id: 26818, Code: '9139', Name: "Help, electrician's" },
  { Id: 26819, Code: '9139', Name: "Help, moulder's" },
  { Id: 26820, Code: '9139', Name: "Help, plater's" },
  { Id: 26821, Code: '9139', Name: "Helper, bender's, frame" },
  { Id: 26822, Code: '9139', Name: "Helper, electrician's" },
  { Id: 26823, Code: '9139', Name: "Helper, erector's, steel" },
  { Id: 26824, Code: '9139', Name: "Helper, fitter's" },
  { Id: 26825, Code: '9139', Name: "Helper, maker's, boiler" },
  { Id: 26826, Code: '9139', Name: 'Helper, metal trades' },
  { Id: 26827, Code: '9139', Name: "Helper, plater's" },
  { Id: 26828, Code: '9139', Name: "Helper, shearer's, metal trades" },
  { Id: 26829, Code: '9139', Name: "Helper, smith's, boiler" },
  { Id: 26830, Code: '9139', Name: "Helper, straightener's" },
  { Id: 26831, Code: '9139', Name: 'Helper, textile mfr' },
  { Id: 26832, Code: '9139', Name: 'Helper-up' },
  { Id: 26833, Code: '9139', Name: 'Holder at drill, rolling mill' },
  { Id: 26834, Code: '9139', Name: "Holder-on, RIVETER'S" },
  { Id: 26835, Code: '9139', Name: "Holder-up, boilermaker's" },
  { Id: 26836, Code: '9139', Name: "Holder-up, riveter's" },
  { Id: 26837, Code: '9139', Name: 'Hooker, mine: not coal' },
  { Id: 26838, Code: '9139', Name: 'Hookman' },
  { Id: 26839, Code: '9139', Name: 'Hopperman, dredge' },
  { Id: 26840, Code: '9139', Name: 'Hopperman, dredging contractors' },
  { Id: 26841, Code: '9139', Name: 'Hopperman, iron and steelworks' },
  { Id: 26842, Code: '9139', Name: 'Horseman, tannery' },
  { Id: 26843, Code: '9139', Name: 'Hugger-off' },
  { Id: 26844, Code: '9139', Name: 'Humper, coal' },
  { Id: 26845, Code: '9139', Name: 'Hydraulicman, docks' },
  { Id: 26846, Code: '9139', Name: 'Iron and steelworker' },
  { Id: 26847, Code: '9139', Name: 'Issuer, work, engineering' },
  { Id: 26848, Code: '9139', Name: 'Jackman' },
  { Id: 26849, Code: '9139', Name: 'Jigman, constructional engineering' },
  { Id: 26850, Code: '9139', Name: 'Keeper, stanch' },
  { Id: 26851, Code: '9139', Name: 'Knocker, catch, pit, staple' },
  { Id: 26852, Code: '9139', Name: 'Knocker-off, foundry' },
  { Id: 26853, Code: '9139', Name: 'Knocker-out, foundry' },
  { Id: 26854, Code: '9139', Name: 'Labourer, brewery' },
  { Id: 26855, Code: '9139', Name: 'Labourer, bulking-floor' },
  { Id: 26856, Code: '9139', Name: 'Labourer, dock, dry' },
  { Id: 26857, Code: '9139', Name: "Labourer, electrician's" },
  { Id: 26858, Code: '9139', Name: 'Labourer, engineering' },
  { Id: 26859, Code: '9139', Name: "Labourer, erector's" },
  { Id: 26860, Code: '9139', Name: 'Labourer, factory' },
  { Id: 26861, Code: '9139', Name: "Labourer, fitter's" },
  { Id: 26862, Code: '9139', Name: "Labourer, fixer's, steel" },
  { Id: 26863, Code: '9139', Name: 'Labourer, foundry' },
  { Id: 26864, Code: '9139', Name: 'Labourer, galvanising, tinning' },
  { Id: 26865, Code: '9139', Name: 'Labourer, grab' },
  { Id: 26866, Code: '9139', Name: 'Labourer, manufacturing' },
  { Id: 26867, Code: '9139', Name: "Labourer, mechanic's" },
  { Id: 26868, Code: '9139', Name: 'Labourer, millhouse, textile mfr' },
  { Id: 26869, Code: '9139', Name: "Labourer, millwright's" },
  { Id: 26870, Code: '9139', Name: "Labourer, moulder's" },
  { Id: 26871, Code: '9139', Name: "Labourer, plater's" },
  { Id: 26872, Code: '9139', Name: 'Labourer, production' },
  { Id: 26873, Code: '9139', Name: "Labourer, rigger's" },
  { Id: 26874, Code: '9139', Name: "Labourer, riveter's" },
  { Id: 26875, Code: '9139', Name: "Labourer, shipwright's" },
  { Id: 26876, Code: '9139', Name: 'Labourer, steelworks' },
  { Id: 26877, Code: '9139', Name: 'Labourer, textile' },
  { Id: 26878, Code: '9139', Name: 'Lad, roller, metal trades' },
  { Id: 26879, Code: '9139', Name: 'Lagger, drum' },
  { Id: 26880, Code: '9139', Name: 'Lander, clay' },
  { Id: 26881, Code: '9139', Name: 'Lander, mine: not coal: above ground' },
  { Id: 26882, Code: '9139', Name: 'Layer, glass, plate' },
  { Id: 26883, Code: '9139', Name: 'Layer, tray' },
  { Id: 26884, Code: '9139', Name: 'Layer-on, metal file mfr' },
  { Id: 26885, Code: '9139', Name: 'Layer-out, textile mfr' },
  { Id: 26886, Code: '9139', Name: 'Leader, timber' },
  { Id: 26887, Code: '9139', Name: 'Leader, water' },
  { Id: 26888, Code: '9139', Name: 'Leadsman' },
  { Id: 26889, Code: '9139', Name: 'Leveller, coke ovens' },
  { Id: 26890, Code: '9139', Name: 'Lifter, box' },
  { Id: 26891, Code: '9139', Name: 'Lifter, coke' },
  { Id: 26892, Code: '9139', Name: 'Lifter-up, foundry' },
  { Id: 26893, Code: '9139', Name: 'Lighter, fire' },
  { Id: 26894, Code: '9139', Name: 'Limer, blast furnace' },
  { Id: 26895, Code: '9139', Name: 'Loader, bulk, petroleum distribution' },
  { Id: 26896, Code: '9139', Name: 'Loader, coal' },
  { Id: 26897, Code: '9139', Name: 'Loader, coke' },
  { Id: 26898, Code: '9139', Name: 'Loader, coke ovens' },
  { Id: 26899, Code: '9139', Name: 'Loader, conveyor' },
  { Id: 26900, Code: '9139', Name: 'Loader, furnace, metal trades' },
  { Id: 26901, Code: '9139', Name: 'Loader, machine' },
  { Id: 26902, Code: '9139', Name: 'Loader, mechanical, mine: not coal' },
  { Id: 26903, Code: '9139', Name: 'Loader, milk, dairy' },
  { Id: 26904, Code: '9139', Name: 'Loader, mine: not coal' },
  { Id: 26905, Code: '9139', Name: 'Loader, paper' },
  { Id: 26906, Code: '9139', Name: 'Loader, stone, mine: not coal' },
  { Id: 26907, Code: '9139', Name: 'Loader, timber, sawmilling' },
  { Id: 26908, Code: '9139', Name: 'Loader, wagon' },
  { Id: 26909, Code: '9139', Name: 'Lobber, card, paste board mfr' },
  { Id: 26910, Code: '9139', Name: 'Lowerer, wagon' },
  { Id: 26911, Code: '9139', Name: 'Lubricator, gun mfr' },
  { Id: 26912, Code: '9139', Name: 'Luter, coke ovens' },
  { Id: 26913, Code: '9139', Name: 'Machinist, coal gas, coke ovens' },
  { Id: 26914, Code: '9139', Name: 'Machinist, PLA' },
  { Id: 26915, Code: '9139', Name: 'Man, acid, dyestuffs mfr' },
  { Id: 26916, Code: '9139', Name: 'Man, ash' },
  { Id: 26917, Code: '9139', Name: 'Man, ash and muck' },
  {
    Id: 26918,
    Code: '9139',
    Name: 'Man, bargain, mine: not coal: above ground'
  },
  { Id: 26919, Code: '9139', Name: 'Man, barrow' },
  { Id: 26920, Code: '9139', Name: 'Man, battery, coke ovens' },
  { Id: 26921, Code: '9139', Name: 'Man, battery, mine: not coal' },
  { Id: 26922, Code: '9139', Name: 'Man, bellows, shipbuilding' },
  { Id: 26923, Code: '9139', Name: 'Man, bench, coke ovens' },
  { Id: 26924, Code: '9139', Name: 'Man, bench, rolling mill' },
  { Id: 26925, Code: '9139', Name: 'Man, billet' },
  { Id: 26926, Code: '9139', Name: 'Man, bottom, coke ovens' },
  { Id: 26927, Code: '9139', Name: 'Man, breeze' },
  { Id: 26928, Code: '9139', Name: 'Man, button, haulage' },
  { Id: 26929, Code: '9139', Name: 'Man, cable' },
  { Id: 26930, Code: '9139', Name: 'Man, clinker' },
  { Id: 26931, Code: '9139', Name: 'Man, coal' },
  { Id: 26932, Code: '9139', Name: 'Man, coke, coke ovens' },
  { Id: 26933, Code: '9139', Name: 'Man, conveyor' },
  { Id: 26934, Code: '9139', Name: 'Man, dock, ship repairing' },
  { Id: 26935, Code: '9139', Name: 'Man, exhauster, coal gas, coke ovens' },
  { Id: 26936, Code: '9139', Name: 'Man, fan, coal mine' },
  { Id: 26937, Code: '9139', Name: 'Man, fan, store, cold' },
  { Id: 26938, Code: '9139', Name: 'Man, gang, shore, shipbuilding' },
  { Id: 26939, Code: '9139', Name: 'Man, governor, gas works' },
  { Id: 26940, Code: '9139', Name: 'Man, guide, coke' },
  { Id: 26941, Code: '9139', Name: 'Man, heap, copperas' },
  { Id: 26942, Code: '9139', Name: 'Man, ingot, rolling mill' },
  { Id: 26943, Code: '9139', Name: 'Man, junction' },
  { Id: 26944, Code: '9139', Name: 'Man, knock-out' },
  { Id: 26945, Code: '9139', Name: 'Man, lock' },
  { Id: 26946, Code: '9139', Name: 'Man, mooring' },
  { Id: 26947, Code: '9139', Name: 'Man, motor, belt' },
  { Id: 26948, Code: '9139', Name: 'Man, oncost, mine: not coal' },
  { Id: 26949, Code: '9139', Name: 'Man, pitch' },
  { Id: 26950, Code: '9139', Name: 'Man, powder, bleaching' },
  { Id: 26951, Code: '9139', Name: 'Man, quencher, coal gas, coke ovens' },
  { Id: 26952, Code: '9139', Name: 'Man, ram' },
  { Id: 26953, Code: '9139', Name: 'Man, rope-way, aerial' },
  { Id: 26954, Code: '9139', Name: 'Man, salt' },
  { Id: 26955, Code: '9139', Name: 'Man, scrap' },
  { Id: 26956, Code: '9139', Name: 'Man, service, chemical mfr' },
  { Id: 26957, Code: '9139', Name: 'Man, sluice' },
  { Id: 26958, Code: '9139', Name: 'Man, stopper, coal gas, coke ovens' },
  { Id: 26959, Code: '9139', Name: 'Man, syphon' },
  { Id: 26960, Code: '9139', Name: 'Man, timber, timber merchants' },
  { Id: 26961, Code: '9139', Name: 'Man, tool' },
  { Id: 26962, Code: '9139', Name: 'Man, traffic, coal mine' },
  { Id: 26963, Code: '9139', Name: 'Man, turn, bye, steelworks' },
  { Id: 26964, Code: '9139', Name: 'Man, utility' },
  {
    Id: 26965,
    Code: '9139',
    Name: 'Man, valve and steam, coal gas, coke ovens'
  },
  { Id: 26966, Code: '9139', Name: 'Man, waste, textile mfr' },
  { Id: 26967, Code: '9139', Name: 'Man, waste, wool' },
  { Id: 26968, Code: '9139', Name: 'Man, yard, metal, steelworks' },
  { Id: 26969, Code: '9139', Name: 'Man, yard, vulcanised fibre board mfr' },
  { Id: 26970, Code: '9139', Name: "Marker, plater's" },
  { Id: 26971, Code: '9139', Name: 'Master, hopper' },
  { Id: 26972, Code: '9139', Name: "Mate, bender's, METAL" },
  { Id: 26973, Code: '9139', Name: "Mate, burner's" },
  { Id: 26974, Code: '9139', Name: "Mate, driller's, shipbuilding" },
  { Id: 26975, Code: '9139', Name: "Mate, driver's, dredger" },
  { Id: 26976, Code: '9139', Name: "Mate, electrician's" },
  { Id: 26977, Code: '9139', Name: "Mate, engineer's" },
  { Id: 26978, Code: '9139', Name: "Mate, erector's" },
  { Id: 26979, Code: '9139', Name: "Mate, fitter's" },
  { Id: 26980, Code: '9139', Name: "Mate, fitter's, pipe" },
  { Id: 26981, Code: '9139', Name: "Mate, fuser's" },
  { Id: 26982, Code: '9139', Name: "Mate, grinder's, METAL" },
  { Id: 26983, Code: '9139', Name: "Mate, jointer's, cable" },
  { Id: 26984, Code: '9139', Name: "Mate, jointer's, electricity supplier" },
  { Id: 26985, Code: '9139', Name: "Mate, linesman's" },
  { Id: 26986, Code: '9139', Name: "Mate, maker's, boiler" },
  { Id: 26987, Code: '9139', Name: "Mate, mechanic's" },
  { Id: 26988, Code: '9139', Name: "Mate, miller's, CEMENT" },
  { Id: 26989, Code: '9139', Name: "Mate, millwright's" },
  { Id: 26990, Code: '9139', Name: "Mate, plater's" },
  { Id: 26991, Code: '9139', Name: "Mate, rigger's" },
  { Id: 26992, Code: '9139', Name: "Mate, riveter's" },
  { Id: 26993, Code: '9139', Name: "Mate, shipwright's" },
  { Id: 26994, Code: '9139', Name: "Mate, smith's, BOILER" },
  { Id: 26995, Code: '9139', Name: "Mate, smith's, COPPER" },
  { Id: 26996, Code: '9139', Name: "Mate, tester's, MOTOR CARS" },
  { Id: 26997, Code: '9139', Name: "Mate, welder's" },
  { Id: 26998, Code: '9139', Name: "Mate, wireman's" },
  { Id: 26999, Code: '9139', Name: 'Minder, block, wire mfr' },
  { Id: 27000, Code: '9139', Name: 'Minder, chain' },
  { Id: 27001, Code: '9139', Name: 'Minder, pump' },
  { Id: 27002, Code: '9139', Name: 'Minder, swift, WIRE' },
  { Id: 27003, Code: '9139', Name: 'Navvy, mine: not coal' },
  { Id: 27004, Code: '9139', Name: 'Navvy, pond, slurry, cement mfr' },
  { Id: 27005, Code: '9139', Name: 'Necker' },
  { Id: 27006, Code: '9139', Name: 'Netter, hosiery, knitwear mfr' },
  { Id: 27007, Code: '9139', Name: 'Oddman, bank' },
  { Id: 27008, Code: '9139', Name: 'Oddman, ceramics mfr' },
  { Id: 27009, Code: '9139', Name: 'Officer, salvage, manufacturing' },
  { Id: 27010, Code: '9139', Name: 'Opener, bale' },
  { Id: 27011, Code: '9139', Name: 'Opener, foundry' },
  { Id: 27012, Code: '9139', Name: 'Operative, cotton' },
  { Id: 27013, Code: '9139', Name: 'Operative, foundry' },
  { Id: 27014, Code: '9139', Name: 'Operative, general, textile mfr' },
  { Id: 27015, Code: '9139', Name: 'Operative, maintenance, fire service' },
  { Id: 27016, Code: '9139', Name: 'Operative, mill' },
  { Id: 27017, Code: '9139', Name: 'Operative, textile' },
  { Id: 27018, Code: '9139', Name: 'Operator, belt, CONVEYOR' },
  { Id: 27019, Code: '9139', Name: 'Operator, button' },
  { Id: 27020, Code: '9139', Name: 'Operator, car, ingot' },
  { Id: 27021, Code: '9139', Name: 'Operator, conveyor' },
  { Id: 27022, Code: '9139', Name: 'Operator, electrical, rolling mill' },
  { Id: 27023, Code: '9139', Name: 'Operator, fan, coal mine' },
  { Id: 27024, Code: '9139', Name: 'Operator, hydraulic' },
  { Id: 27025, Code: '9139', Name: 'Operator, oil, metal trades' },
  { Id: 27026, Code: '9139', Name: 'Operator, panel, steel mfr' },
  { Id: 27027, Code: '9139', Name: 'Operator, plant, drying' },
  { Id: 27028, Code: '9139', Name: 'Operator, plant, mobile, steel mfr' },
  { Id: 27029, Code: '9139', Name: 'Operator, plant, refractory' },
  { Id: 27030, Code: '9139', Name: 'Operator, point, transfer' },
  { Id: 27031, Code: '9139', Name: 'Operator, process, foundry' },
  { Id: 27032, Code: '9139', Name: 'Operator, pusher, coke ovens' },
  { Id: 27033, Code: '9139', Name: 'Operator, ropeway' },
  { Id: 27034, Code: '9139', Name: 'Operator, tippler' },
  { Id: 27035, Code: '9139', Name: 'Operator, traverser, wagon' },
  { Id: 27036, Code: '9139', Name: 'Operator, turntable' },
  { Id: 27037, Code: '9139', Name: 'Packman, woollen carding' },
  { Id: 27038, Code: '9139', Name: 'Panner-out' },
  { Id: 27039, Code: '9139', Name: 'Patrol, belt' },
  { Id: 27040, Code: '9139', Name: 'Patrolman, belt' },
  { Id: 27041, Code: '9139', Name: 'Picker, bobbin, textile mfr' },
  { Id: 27042, Code: '9139', Name: 'Picker-up, galvanised sheet mfr' },
  { Id: 27043, Code: '9139', Name: 'Piler' },
  { Id: 27044, Code: '9139', Name: 'Piler, hot' },
  { Id: 27045, Code: '9139', Name: 'Pitcher, flour' },
  { Id: 27046, Code: '9139', Name: 'Plasterer, coke ovens' },
  { Id: 27047, Code: '9139', Name: 'Poker-in, coke ovens' },
  { Id: 27048, Code: '9139', Name: 'Puller-down' },
  { Id: 27049, Code: '9139', Name: 'Puller-off, metal trades' },
  { Id: 27050, Code: '9139', Name: 'Puller-up' },
  { Id: 27051, Code: '9139', Name: 'Pusher, coal gas, coke ovens' },
  { Id: 27052, Code: '9139', Name: 'Pusher, truck' },
  { Id: 27053, Code: '9139', Name: 'Pusher-out' },
  { Id: 27054, Code: '9139', Name: 'Racker, tinplate goods mfr' },
  { Id: 27055, Code: '9139', Name: 'Racker, whiting mfr' },
  { Id: 27056, Code: '9139', Name: 'Raker-out, asbestos mfr' },
  { Id: 27057, Code: '9139', Name: 'Remover, scrap' },
  { Id: 27058, Code: '9139', Name: 'Resiner, brewery' },
  { Id: 27059, Code: '9139', Name: 'Rinser, file' },
  { Id: 27060, Code: '9139', Name: 'Rodsman' },
  { Id: 27061, Code: '9139', Name: 'Roustabout' },
  { Id: 27062, Code: '9139', Name: 'Rucker, blast furnace' },
  { Id: 27063, Code: '9139', Name: 'Ruller, mine: not coal' },
  { Id: 27064, Code: '9139', Name: 'Runner, bobbin' },
  { Id: 27065, Code: '9139', Name: 'Runner, ceramics mfr' },
  { Id: 27066, Code: '9139', Name: 'Runner, clay' },
  { Id: 27067, Code: '9139', Name: 'Runner, rope' },
  { Id: 27068, Code: '9139', Name: 'Runner, skip' },
  { Id: 27069, Code: '9139', Name: 'Runner, textile mfr' },
  { Id: 27070, Code: '9139', Name: 'Runner, wagon' },
  { Id: 27071, Code: '9139', Name: 'Runner, water' },
  { Id: 27072, Code: '9139', Name: 'Runner, wool' },
  { Id: 27073, Code: '9139', Name: 'Sailor, shipbuilding' },
  { Id: 27074, Code: '9139', Name: 'Sailorman' },
  { Id: 27075, Code: '9139', Name: 'Scaleman, rolling mill' },
  { Id: 27076, Code: '9139', Name: 'Scaler, metal' },
  { Id: 27077, Code: '9139', Name: 'Scaler, shipbuilding' },
  { Id: 27078, Code: '9139', Name: 'Scraper, shipbuilding' },
  { Id: 27079, Code: '9139', Name: 'Scrapper, metal mfr' },
  { Id: 27080, Code: '9139', Name: 'Scurfer, aircraft mfr' },
  { Id: 27081, Code: '9139', Name: 'Scurfer, retort' },
  { Id: 27082, Code: '9139', Name: 'Separator, metal and oil' },
  { Id: 27083, Code: '9139', Name: 'Servant, civil, nos, INDUSTRIAL' },
  { Id: 27084, Code: '9139', Name: 'Server, silk mfr' },
  { Id: 27085, Code: '9139', Name: 'Shaker, bag' },
  { Id: 27086, Code: '9139', Name: 'Shifter, iron' },
  { Id: 27087, Code: '9139', Name: 'Shifter, rolling mill' },
  { Id: 27088, Code: '9139', Name: 'Shipper, patent fuel mfr' },
  { Id: 27089, Code: '9139', Name: 'Shipper, tinplate mfr' },
  { Id: 27090, Code: '9139', Name: 'Shopman, railway workshops' },
  { Id: 27091, Code: '9139', Name: 'Shuntman, mine: not coal' },
  { Id: 27092, Code: '9139', Name: 'Shutter, door, coke ovens' },
  { Id: 27093, Code: '9139', Name: 'Sorter, iron, shipbuilding' },
  { Id: 27094, Code: '9139', Name: 'Splitter, bale, rubber goods mfr' },
  { Id: 27095, Code: '9139', Name: 'Spreader, colour' },
  { Id: 27096, Code: '9139', Name: 'Stacker' },
  { Id: 27097, Code: '9139', Name: 'Steelworker' },
  { Id: 27098, Code: '9139', Name: 'Stocker, steel mfr' },
  { Id: 27099, Code: '9139', Name: 'Stocker-up' },
  { Id: 27100, Code: '9139', Name: 'Stockman, manufacturing: blast furnace' },
  { Id: 27101, Code: '9139', Name: 'Stockman, manufacturing: rolling mill' },
  { Id: 27102, Code: '9139', Name: 'Stoker, destructor' },
  { Id: 27103, Code: '9139', Name: 'Stower, cake' },
  { Id: 27104, Code: '9139', Name: 'Stower, cement' },
  { Id: 27105, Code: '9139', Name: 'Stower, grain milling' },
  { Id: 27106, Code: '9139', Name: 'Strapper, textile mfr' },
  { Id: 27107, Code: '9139', Name: 'Stripper, candle mfr' },
  { Id: 27108, Code: '9139', Name: 'Stripper, ceramics mfr' },
  { Id: 27109, Code: '9139', Name: 'Stripper, frame' },
  {
    Id: 27110,
    Code: '9139',
    Name: 'Stripper-assembler, cast concrete products mfr'
  },
  { Id: 27111, Code: '9139', Name: 'Supervisor, rope, trot' },
  { Id: 27112, Code: '9139', Name: 'Supervisor, Stationery Office' },
  { Id: 27113, Code: '9139', Name: 'Sweeper, cotton, textile mfr' },
  { Id: 27114, Code: '9139', Name: 'Swiller, tinplate mfr' },
  { Id: 27115, Code: '9139', Name: 'Taker, work' },
  { Id: 27116, Code: '9139', Name: 'Taker-off, metal trades' },
  { Id: 27117, Code: '9139', Name: 'Taker-out, ceramics mfr' },
  { Id: 27118, Code: '9139', Name: 'Tankman, storage' },
  { Id: 27119, Code: '9139', Name: 'Teemer, coke ovens' },
  { Id: 27120, Code: '9139', Name: 'Tenter, press, metal trades' },
  { Id: 27121, Code: '9139', Name: 'Tenter, pump' },
  { Id: 27122, Code: '9139', Name: 'Thrower, brewery' },
  { Id: 27123, Code: '9139', Name: 'Thrower, distillery' },
  { Id: 27124, Code: '9139', Name: 'Tipman, mine: not coal' },
  { Id: 27125, Code: '9139', Name: 'Tipper, coal, coal patent fuel mfr' },
  { Id: 27126, Code: '9139', Name: 'Tipper, coal, steelworks' },
  { Id: 27127, Code: '9139', Name: 'Tipper, mine: not coal' },
  { Id: 27128, Code: '9139', Name: 'Tipper, ore, steelworks' },
  { Id: 27129, Code: '9139', Name: 'Tipper, scrap' },
  { Id: 27130, Code: '9139', Name: 'Tipper, shale' },
  { Id: 27131, Code: '9139', Name: 'Tipper, slag' },
  { Id: 27132, Code: '9139', Name: 'Tippler, steelworks' },
  { Id: 27133, Code: '9139', Name: 'Topman, battery, coke ovens' },
  { Id: 27134, Code: '9139', Name: 'Topman, coal gas, coke ovens' },
  { Id: 27135, Code: '9139', Name: 'Towerman, paper mfr' },
  { Id: 27136, Code: '9139', Name: 'Trammer' },
  { Id: 27137, Code: '9139', Name: 'Trimmer, boiler' },
  { Id: 27138, Code: '9139', Name: 'Trimmer, boiler house' },
  { Id: 27139, Code: '9139', Name: 'Trimmer, coal' },
  { Id: 27140, Code: '9139', Name: 'Trimmer, coal gas, coke ovens' },
  { Id: 27141, Code: '9139', Name: 'Trimmer, electricity supplier' },
  { Id: 27142, Code: '9139', Name: 'Trimmer, house, boiler' },
  { Id: 27143, Code: '9139', Name: 'Trimmer, power station' },
  { Id: 27144, Code: '9139', Name: 'Trimmer, wagon' },
  { Id: 27145, Code: '9139', Name: 'Trolleyman' },
  { Id: 27146, Code: '9139', Name: 'Truckman, blast furnace' },
  { Id: 27147, Code: '9139', Name: 'Turner, sheet, galvanised sheet mfr' },
  { Id: 27148, Code: '9139', Name: 'Turner, wheel, ceramics mfr' },
  { Id: 27149, Code: '9139', Name: 'Unloader, autoclave, CERAMICS' },
  { Id: 27150, Code: '9139', Name: 'Unloader, kiln, CERAMICS' },
  { Id: 27151, Code: '9139', Name: 'Valveman, coal gas, coke ovens' },
  { Id: 27152, Code: '9139', Name: 'Wagoner, coal mine: above ground' },
  { Id: 27153, Code: '9139', Name: 'Washer, board' },
  { Id: 27154, Code: '9139', Name: 'Washer, flour confectionery mfr' },
  { Id: 27155, Code: '9139', Name: 'Washer, glass' },
  { Id: 27156, Code: '9139', Name: 'Washer, metal' },
  { Id: 27157, Code: '9139', Name: 'Washer, stencil' },
  { Id: 27158, Code: '9139', Name: 'Washer, tin, biscuit' },
  { Id: 27159, Code: '9139', Name: 'Washer, tray' },
  {
    Id: 27160,
    Code: '9139',
    Name: 'Watchman-operator, petroleum distribution'
  },
  { Id: 27161, Code: '9139', Name: 'Waterman, coal gas, coke ovens' },
  { Id: 27162, Code: '9139', Name: 'Waterman, furnace, blast' },
  { Id: 27163, Code: '9139', Name: 'Waterman, steel mfr' },
  { Id: 27164, Code: '9139', Name: 'Weighman, charge, foundry' },
  { Id: 27165, Code: '9139', Name: 'Wheeler' },
  { Id: 27166, Code: '9139', Name: 'Wheeler, frit' },
  { Id: 27167, Code: '9139', Name: 'Wheeler, metal, metal mfr' },
  { Id: 27168, Code: '9139', Name: 'Wheeler, pick, mine: not coal' },
  { Id: 27169, Code: '9139', Name: 'Wheeler-in' },
  { Id: 27170, Code: '9139', Name: 'Wiper, cutlery, cutlery mfr' },
  { Id: 27171, Code: '9139', Name: 'Wiper, glass mfr' },
  { Id: 27172, Code: '9139', Name: 'Wiper, scissors' },
  { Id: 27173, Code: '9139', Name: 'Wirer, metal trades' },
  { Id: 27174, Code: '9139', Name: 'Worker, aircraft' },
  { Id: 27175, Code: '9139', Name: 'Worker, ammunition' },
  { Id: 27176, Code: '9139', Name: 'Worker, asbestos' },
  { Id: 27177, Code: '9139', Name: 'Worker, battery' },
  { Id: 27178, Code: '9139', Name: 'Worker, belt' },
  { Id: 27179, Code: '9139', Name: 'Worker, belt, conveyor' },
  { Id: 27180, Code: '9139', Name: 'Worker, copper, refining' },
  { Id: 27181, Code: '9139', Name: 'Worker, cotton' },
  { Id: 27182, Code: '9139', Name: 'Worker, dock, dry' },
  { Id: 27183, Code: '9139', Name: 'Worker, dock, DRY DOCK' },
  { Id: 27184, Code: '9139', Name: 'Worker, dockyard' },
  { Id: 27185, Code: '9139', Name: 'Worker, engineering' },
  { Id: 27186, Code: '9139', Name: 'Worker, factory' },
  { Id: 27187, Code: '9139', Name: 'Worker, factory, engineering' },
  { Id: 27188, Code: '9139', Name: 'Worker, foundry' },
  { Id: 27189, Code: '9139', Name: 'Worker, gas' },
  { Id: 27190, Code: '9139', Name: 'Worker, general, metal trades' },
  { Id: 27191, Code: '9139', Name: 'Worker, iron, iron and steelworks' },
  { Id: 27192, Code: '9139', Name: 'Worker, metal' },
  { Id: 27193, Code: '9139', Name: 'Worker, mill' },
  { Id: 27194, Code: '9139', Name: 'Worker, mill, corn' },
  { Id: 27195, Code: '9139', Name: 'Worker, mill, cotton' },
  { Id: 27196, Code: '9139', Name: 'Worker, mill, flour' },
  { Id: 27197, Code: '9139', Name: 'Worker, mill, woollen' },
  { Id: 27198, Code: '9139', Name: 'Worker, munitions' },
  { Id: 27199, Code: '9139', Name: 'Worker, paper, printing' },
  { Id: 27200, Code: '9139', Name: 'Worker, refractory' },
  { Id: 27201, Code: '9139', Name: 'Worker, room, ware' },
  { Id: 27202, Code: '9139', Name: 'Worker, service, textile mfr' },
  { Id: 27203, Code: '9139', Name: 'Worker, shop, paint' },
  { Id: 27204, Code: '9139', Name: 'Worker, shop, scrap, CELLULOID' },
  { Id: 27205, Code: '9139', Name: 'Worker, silk' },
  { Id: 27206, Code: '9139', Name: 'Worker, steel' },
  { Id: 27207, Code: '9139', Name: 'Worker, textile' },
  { Id: 27208, Code: '9139', Name: 'Worker, timber' },
  { Id: 27209, Code: '9139', Name: 'Worker-round, iron and steelworks' },
  { Id: 27210, Code: '9139', Name: 'Yardman, metal, steelworks' },
  { Id: 27211, Code: '9139', Name: 'Yardman, vulcanised fibre board mfr' },
  { Id: 27212, Code: '9139', Name: 'Yardsman, dairy' },
  { Id: 27213, Code: '9139', Name: 'Yardsman, metal, steelworks' },
  { Id: 27214, Code: '9139', Name: 'Yardsman, vulcanised fibre board mfr' },
  { Id: 27215, Code: '9211', Name: 'Agent, delivery' },
  { Id: 27216, Code: '9211', Name: 'Assistant, facilities' },
  { Id: 27217, Code: '9211', Name: 'Assistant, room, mail' },
  { Id: 27218, Code: '9211', Name: 'Assistant, room, post' },
  { Id: 27219, Code: '9211', Name: 'Assistant, sorting, PO' },
  { Id: 27220, Code: '9211', Name: 'Attendant, government' },
  { Id: 27221, Code: '9211', Name: 'Attendant, room, mail' },
  { Id: 27222, Code: '9211', Name: 'Attendant, room, post' },
  { Id: 27223, Code: '9211', Name: 'Boy, delivery' },
  { Id: 27224, Code: '9211', Name: 'Boy, newspaper' },
  { Id: 27225, Code: '9211', Name: 'Boy, paper' },
  { Id: 27226, Code: '9211', Name: 'Cadet, postal' },
  { Id: 27227, Code: '9211', Name: 'Clerk and telegraphist, sorting' },
  { Id: 27228, Code: '9211', Name: 'Clerk, mailroom' },
  { Id: 27229, Code: '9211', Name: 'Clerk, post' },
  { Id: 27230, Code: '9211', Name: 'Clerk, postal' },
  { Id: 27231, Code: '9211', Name: 'Clerk, postroom' },
  { Id: 27232, Code: '9211', Name: 'Clerk, room, mail' },
  { Id: 27233, Code: '9211', Name: 'Clerk, room, post' },
  { Id: 27234, Code: '9211', Name: 'Clerk, sorting, PO' },
  { Id: 27235, Code: '9211', Name: 'Courier' },
  { Id: 27236, Code: '9211', Name: 'Courier, bank' },
  { Id: 27237, Code: '9211', Name: 'Deliverer, allowance' },
  { Id: 27238, Code: '9211', Name: 'Deliverer, book' },
  { Id: 27239, Code: '9211', Name: 'Deliverer, newspaper' },
  { Id: 27240, Code: '9211', Name: 'Deliverer, newspapers, magazines' },
  { Id: 27241, Code: '9211', Name: 'Deliverer, parcel' },
  { Id: 27242, Code: '9211', Name: 'Deliveryman, newsagents' },
  { Id: 27243, Code: '9211', Name: 'Distributor, circular' },
  { Id: 27244, Code: '9211', Name: 'Distributor, leaflet' },
  { Id: 27245, Code: '9211', Name: 'Distributor, newspaper' },
  { Id: 27246, Code: '9211', Name: 'Driver, collection, PO' },
  { Id: 27247, Code: '9211', Name: 'Driver-postman' },
  { Id: 27248, Code: '9211', Name: 'Executive, postal, PO, GRADE D' },
  { Id: 27249, Code: '9211', Name: 'Forewoman, PO' },
  { Id: 27250, Code: '9211', Name: 'Girl, paper, newsagents' },
  { Id: 27251, Code: '9211', Name: "Girl, shop, tailor's" },
  { Id: 27252, Code: '9211', Name: 'Handler, parcel' },
  { Id: 27253, Code: '9211', Name: 'Inspector of postmen' },
  { Id: 27254, Code: '9211', Name: 'Inspector, assistant, PO' },
  { Id: 27255, Code: '9211', Name: 'Inspector, PO' },
  { Id: 27256, Code: '9211', Name: 'Inspector, postal' },
  { Id: 27257, Code: '9211', Name: 'Lad, order' },
  { Id: 27258, Code: '9211', Name: 'Manager, line, PO' },
  { Id: 27259, Code: '9211', Name: 'Messenger' },
  { Id: 27260, Code: '9211', Name: 'Messenger, chief' },
  { Id: 27261, Code: '9211', Name: 'Messenger, head' },
  { Id: 27262, Code: '9211', Name: "Messenger, Queen's" },
  { Id: 27263, Code: '9211', Name: 'Officer, controlling, PO' },
  { Id: 27264, Code: '9211', Name: 'Officer, outdoor' },
  { Id: 27265, Code: '9211', Name: 'Officer, supervising, PO' },
  { Id: 27266, Code: '9211', Name: 'Operative, room, post' },
  { Id: 27267, Code: '9211', Name: 'Orderly, OFFICE' },
  { Id: 27268, Code: '9211', Name: 'Overseer, PO: sorting office' },
  { Id: 27269, Code: '9211', Name: 'PHG' },
  { Id: 27270, Code: '9211', Name: 'Porter, room, post' },
  { Id: 27271, Code: '9211', Name: 'Postie' },
  { Id: 27272, Code: '9211', Name: 'Postman' },
  { Id: 27273, Code: '9211', Name: 'Postman, grade, higher' },
  { Id: 27274, Code: '9211', Name: 'Postman, head' },
  { Id: 27275, Code: '9211', Name: 'Postman, works' },
  { Id: 27276, Code: '9211', Name: 'Postman-driver' },
  { Id: 27277, Code: '9211', Name: 'Postwoman' },
  { Id: 27278, Code: '9211', Name: 'Postwoman, grade, higher' },
  { Id: 27279, Code: '9211', Name: 'Postwoman, head' },
  { Id: 27280, Code: '9211', Name: 'Postwoman, works' },
  { Id: 27281, Code: '9211', Name: 'Rider, dispatch' },
  { Id: 27282, Code: '9211', Name: 'Roundsman, newspaper delivery' },
  { Id: 27283, Code: '9211', Name: 'Sorter, head, PO' },
  { Id: 27284, Code: '9211', Name: 'Sorter, letter' },
  { Id: 27285, Code: '9211', Name: 'Sorter, mail' },
  { Id: 27286, Code: '9211', Name: 'Sorter, parcel' },
  { Id: 27287, Code: '9211', Name: 'Sorter, parcels delivery service' },
  { Id: 27288, Code: '9211', Name: 'Sorter, PO' },
  { Id: 27289, Code: '9211', Name: 'Sorter, post' },
  { Id: 27290, Code: '9211', Name: 'Sorter, postal' },
  { Id: 27291, Code: '9211', Name: 'Sorter-loader, parcels delivery service' },
  { Id: 27292, Code: '9211', Name: 'Stamper, PO' },
  { Id: 27293, Code: '9211', Name: 'Supervisor of sorting assistants, PO' },
  { Id: 27294, Code: '9211', Name: 'Supervisor, chief, senior, PO' },
  { Id: 27295, Code: '9211', Name: 'Supervisor, mail' },
  { Id: 27296, Code: '9211', Name: 'Supervisor, PO: sorting office' },
  { Id: 27297, Code: '9211', Name: 'Supervisor, postal' },
  { Id: 27298, Code: '9211', Name: 'Supervisor, room, mail' },
  { Id: 27299, Code: '9211', Name: 'Supervisor, room, post' },
  { Id: 27300, Code: '9211', Name: 'Taker, copy, publishing' },
  { Id: 27301, Code: '9211', Name: 'Waiter, stock exchange' },
  { Id: 27302, Code: '9211', Name: 'Worker, delivery, postal' },
  { Id: 27303, Code: '9211', Name: 'Worker, office, post' },
  { Id: 27304, Code: '9211', Name: 'Worker, postal' },
  { Id: 27305, Code: '9219', Name: 'Assistant, general' },
  { Id: 27306, Code: '9219', Name: 'Assistant, mailing' },
  { Id: 27307, Code: '9219', Name: 'Assistant, reprographic' },
  { Id: 27308, Code: '9219', Name: 'Assistant, scanning' },
  {
    Id: 27309,
    Code: '9219',
    Name: 'Assistant, technical, PHOTOCOPYING, SCANNING'
  },
  { Id: 27310, Code: '9219', Name: 'Associate, scanning' },
  { Id: 27311, Code: '9219', Name: 'Boy, office' },
  { Id: 27312, Code: '9219', Name: 'Clerk, junior' },
  { Id: 27313, Code: '9219', Name: 'Copyist, photo' },
  { Id: 27314, Code: '9219', Name: 'Duplicator' },
  { Id: 27315, Code: '9219', Name: 'Encloser' },
  { Id: 27316, Code: '9219', Name: 'Filler, envelope' },
  { Id: 27317, Code: '9219', Name: 'Filmer, micro' },
  { Id: 27318, Code: '9219', Name: 'Girl, office' },
  { Id: 27319, Code: '9219', Name: 'Hand, progress' },
  { Id: 27320, Code: '9219', Name: 'Handyman, office' },
  { Id: 27321, Code: '9219', Name: 'Inserter, newspapers, magazines' },
  { Id: 27322, Code: '9219', Name: 'Junior, office' },
  { Id: 27323, Code: '9219', Name: 'Keeper, paper' },
  { Id: 27324, Code: '9219', Name: 'Machinist, banking' },
  { Id: 27325, Code: '9219', Name: 'Machinist, office' },
  { Id: 27326, Code: '9219', Name: 'Machinist, OFFICE MACHINERY' },
  { Id: 27327, Code: '9219', Name: 'Machinist, photocopying' },
  { Id: 27328, Code: '9219', Name: 'Mailer' },
  { Id: 27329, Code: '9219', Name: 'Man, progress' },
  { Id: 27330, Code: '9219', Name: 'Officer, reprographics' },
  { Id: 27331, Code: '9219', Name: 'Opener, mail' },
  { Id: 27332, Code: '9219', Name: 'Operator, camera, MICROFILM' },
  { Id: 27333, Code: '9219', Name: 'Operator, camera, PHOTOCOPYING' },
  { Id: 27334, Code: '9219', Name: 'Operator, duplicator' },
  { Id: 27335, Code: '9219', Name: 'Operator, film, micro' },
  { Id: 27336, Code: '9219', Name: 'Operator, machinery, office' },
  { Id: 27337, Code: '9219', Name: 'Operator, photocopy' },
  { Id: 27338, Code: '9219', Name: 'Operator, reprographics' },
  { Id: 27339, Code: '9219', Name: 'Operator, room, print' },
  { Id: 27340, Code: '9219', Name: 'Operator, scanner, printing' },
  { Id: 27341, Code: '9219', Name: 'Operator, scanning' },
  { Id: 27342, Code: '9219', Name: 'Operator, xerox' },
  { Id: 27343, Code: '9219', Name: 'Paperkeeper' },
  { Id: 27344, Code: '9219', Name: 'Photocopier' },
  { Id: 27345, Code: '9219', Name: 'Printer, copy' },
  { Id: 27346, Code: '9219', Name: 'Printer, nos, PHOTOCOPYING' },
  { Id: 27347, Code: '9219', Name: 'Printer, photo' },
  { Id: 27348, Code: '9219', Name: 'Runner, broadcasting' },
  { Id: 27349, Code: '9219', Name: 'Runner, ENTERTAINMENT' },
  { Id: 27350, Code: '9219', Name: 'Shredder, confidential' },
  { Id: 27351, Code: '9219', Name: 'Sorter, coupon' },
  { Id: 27352, Code: '9219', Name: 'Sorter, voucher' },
  { Id: 27353, Code: '9219', Name: 'Supervisor, OFFICE MACHINES' },
  { Id: 27354, Code: '9219', Name: 'Technician, reprographic' },
  { Id: 27355, Code: '9219', Name: 'Worker, office' },
  { Id: 27356, Code: '9219', Name: 'Worker, progress' },
  { Id: 27357, Code: '9231', Name: 'Cleaner, window' },
  { Id: 27358, Code: '9231', Name: 'Cleaner, WINDOWS' },
  { Id: 27359, Code: '9231', Name: 'Contractor, cleaning, window' },
  { Id: 27360, Code: '9232', Name: 'Attendant, market' },
  { Id: 27361, Code: '9232', Name: 'Cleaner, highways' },
  { Id: 27362, Code: '9232', Name: 'Cleaner, road' },
  { Id: 27363, Code: '9232', Name: 'Cleaner, street' },
  { Id: 27364, Code: '9232', Name: 'Operative, cleansing, street cleaning' },
  {
    Id: 27365,
    Code: '9232',
    Name: 'Operative, realm, public, local government'
  },
  { Id: 27366, Code: '9232', Name: 'Orderly, road' },
  { Id: 27367, Code: '9232', Name: 'Orderly, street' },
  { Id: 27368, Code: '9232', Name: 'Picker, litter' },
  { Id: 27369, Code: '9232', Name: 'Sweeper, road' },
  { Id: 27370, Code: '9232', Name: 'Sweeper, street' },
  { Id: 27371, Code: '9232', Name: 'Sweeper, street cleaning' },
  { Id: 27372, Code: '9233', Name: 'Ace, Asda' },
  { Id: 27373, Code: '9233', Name: 'Assistant, bedroom, hotel' },
  { Id: 27374, Code: '9233', Name: 'Assistant, chalet' },
  { Id: 27375, Code: '9233', Name: 'Assistant, domestic' },
  { Id: 27376, Code: '9233', Name: 'Assistant, general, cleaning' },
  { Id: 27377, Code: '9233', Name: 'Assistant, house, boarding' },
  { Id: 27378, Code: '9233', Name: 'Assistant, household' },
  { Id: 27379, Code: '9233', Name: 'Assistant, housekeeping' },
  { Id: 27380, Code: '9233', Name: 'Assistant, hygiene' },
  { Id: 27381, Code: '9233', Name: "Assistant, matron's" },
  { Id: 27382, Code: '9233', Name: 'Assistant, room, hotel' },
  { Id: 27383, Code: '9233', Name: 'Assistant, services, hotel' },
  { Id: 27384, Code: '9233', Name: 'Attendant, cleaning' },
  { Id: 27385, Code: '9233', Name: 'Attendant, room, hotel' },
  { Id: 27386, Code: '9233', Name: 'Bedder, college' },
  { Id: 27387, Code: '9233', Name: 'Carer, domestic' },
  { Id: 27388, Code: '9233', Name: 'Chambermaid' },
  { Id: 27389, Code: '9233', Name: 'Chambermaid-housekeeper' },
  { Id: 27390, Code: '9233', Name: 'Charlady' },
  { Id: 27391, Code: '9233', Name: 'Charwoman' },
  { Id: 27392, Code: '9233', Name: 'Cleaner' },
  { Id: 27393, Code: '9233', Name: 'Cleaner, bank' },
  { Id: 27394, Code: '9233', Name: 'Cleaner, bar, catering' },
  { Id: 27395, Code: '9233', Name: 'Cleaner, berth' },
  { Id: 27396, Code: '9233', Name: 'Cleaner, canteen' },
  { Id: 27397, Code: '9233', Name: 'Cleaner, church' },
  { Id: 27398, Code: '9233', Name: 'Cleaner, closet' },
  { Id: 27399, Code: '9233', Name: 'Cleaner, domestic' },
  { Id: 27400, Code: '9233', Name: 'Cleaner, floor' },
  { Id: 27401, Code: '9233', Name: 'Cleaner, general' },
  { Id: 27402, Code: '9233', Name: 'Cleaner, hospital' },
  { Id: 27403, Code: '9233', Name: 'Cleaner, house' },
  { Id: 27404, Code: '9233', Name: 'Cleaner, kiosk, telephone' },
  { Id: 27405, Code: '9233', Name: 'Cleaner, kitchen' },
  { Id: 27406, Code: '9233', Name: 'Cleaner, library' },
  { Id: 27407, Code: '9233', Name: 'Cleaner, night' },
  { Id: 27408, Code: '9233', Name: 'Cleaner, office' },
  { Id: 27409, Code: '9233', Name: 'Cleaner, room, mess' },
  { Id: 27410, Code: '9233', Name: 'Cleaner, room, show' },
  { Id: 27411, Code: '9233', Name: 'Cleaner, school' },
  { Id: 27412, Code: '9233', Name: 'Cleaner, shop' },
  { Id: 27413, Code: '9233', Name: 'Cleaner, station, railways' },
  { Id: 27414, Code: '9233', Name: 'Cleaner, telephone' },
  { Id: 27415, Code: '9233', Name: 'Cleaner, warehouse' },
  { Id: 27416, Code: '9233', Name: 'Cleaner, workshop' },
  { Id: 27417, Code: '9233', Name: 'Cleaner, yard' },
  { Id: 27418, Code: '9233', Name: 'Cleaner-doorman' },
  { Id: 27419, Code: '9233', Name: 'Closer, night, fast food outlet' },
  { Id: 27420, Code: '9233', Name: 'Contractor, cleaning' },
  { Id: 27421, Code: '9233', Name: 'Controller, hygiene' },
  { Id: 27422, Code: '9233', Name: 'Domestic' },
  { Id: 27423, Code: '9233', Name: 'Engineer, domestic' },
  { Id: 27424, Code: '9233', Name: 'Hand, general, hotel' },
  { Id: 27425, Code: '9233', Name: 'Help, daily' },
  { Id: 27426, Code: '9233', Name: 'Help, domestic' },
  { Id: 27427, Code: '9233', Name: 'Help, domestic service' },
  { Id: 27428, Code: '9233', Name: 'Help, home' },
  { Id: 27429, Code: '9233', Name: 'Helper, domestic' },
  { Id: 27430, Code: '9233', Name: 'Housemaid' },
  { Id: 27431, Code: '9233', Name: 'Houseman, domestic service' },
  { Id: 27432, Code: '9233', Name: 'Houseman, hotel' },
  { Id: 27433, Code: '9233', Name: 'Houseman, school' },
  { Id: 27434, Code: '9233', Name: 'Lady, cleaning' },
  { Id: 27435, Code: '9233', Name: 'Maid' },
  { Id: 27436, Code: '9233', Name: 'Maid, chalet' },
  { Id: 27437, Code: '9233', Name: 'Maid, chamber' },
  { Id: 27438, Code: '9233', Name: 'Maid, house' },
  { Id: 27439, Code: '9233', Name: 'Maid, room' },
  { Id: 27440, Code: '9233', Name: 'Maid, ward' },
  { Id: 27441, Code: '9233', Name: 'Maidservant' },
  { Id: 27442, Code: '9233', Name: 'Maker, bed, hospital service' },
  { Id: 27443, Code: '9233', Name: 'Maker, bed, residential home' },
  { Id: 27444, Code: '9233', Name: 'Maker, bed, school, university' },
  { Id: 27445, Code: '9233', Name: 'Man, cabin, mine: not coal' },
  { Id: 27446, Code: '9233', Name: 'Officer, service, ward' },
  { Id: 27447, Code: '9233', Name: 'Operative, domestic' },
  { Id: 27448, Code: '9233', Name: 'Operator, service, cleaning' },
  { Id: 27449, Code: '9233', Name: 'Operator, sterilizer, telephone' },
  { Id: 27450, Code: '9233', Name: 'Orderly, domestic, hospital service' },
  { Id: 27451, Code: '9233', Name: 'Parlourmaid' },
  { Id: 27452, Code: '9233', Name: 'Parlourman' },
  { Id: 27453, Code: '9233', Name: 'Polisher, floor' },
  { Id: 27454, Code: '9233', Name: 'Porter-cleaner' },
  { Id: 27455, Code: '9233', Name: 'Scout, college' },
  { Id: 27456, Code: '9233', Name: 'Servant' },
  { Id: 27457, Code: '9233', Name: 'Servant, college' },
  { Id: 27458, Code: '9233', Name: 'Servant, daily' },
  { Id: 27459, Code: '9233', Name: 'Servant, domestic' },
  {
    Id: 27460,
    Code: '9233',
    Name: 'Steriliser, telephone sterilising service'
  },
  { Id: 27461, Code: '9233', Name: 'Steward, house' },
  { Id: 27462, Code: '9233', Name: 'Sweeper' },
  { Id: 27463, Code: '9233', Name: 'Sweeper, tube' },
  { Id: 27464, Code: '9233', Name: 'Sweeper-up' },
  { Id: 27465, Code: '9233', Name: 'Technician, cleaning' },
  { Id: 27466, Code: '9233', Name: 'Worker, domestic' },
  { Id: 27467, Code: '9233', Name: 'Worker, house, domestic service' },
  {
    Id: 27468,
    Code: '9234',
    Name: 'Assistant, branch, laundry, launderette, dry cleaning'
  },
  { Id: 27469, Code: '9234', Name: 'Assistant, cleaning, dry' },
  {
    Id: 27470,
    Code: '9234',
    Name: 'Assistant, general, laundry, launderette, dry cleaning'
  },
  { Id: 27471, Code: '9234', Name: 'Assistant, launderette' },
  { Id: 27472, Code: '9234', Name: 'Assistant, laundry' },
  {
    Id: 27473,
    Code: '9234',
    Name: 'Assistant, laundry, launderette, dry cleaning'
  },
  { Id: 27474, Code: '9234', Name: 'Attendant, house, wash' },
  {
    Id: 27475,
    Code: '9234',
    Name: 'Attendant, hydro, laundry, launderette, dry cleaning'
  },
  { Id: 27476, Code: '9234', Name: 'Attendant, launderette' },
  { Id: 27477, Code: '9234', Name: 'Attendant, laundry' },
  { Id: 27478, Code: '9234', Name: 'Attendant, tumbler' },
  { Id: 27479, Code: '9234', Name: 'Beater, carpet' },
  {
    Id: 27480,
    Code: '9234',
    Name: 'Blocker, laundry, launderette, dry cleaning'
  },
  {
    Id: 27481,
    Code: '9234',
    Name: 'Bluer, laundry, launderette, dry cleaning'
  },
  { Id: 27482, Code: '9234', Name: 'Brusher, dyeing and cleaning' },
  {
    Id: 27483,
    Code: '9234',
    Name: 'Calenderer, laundry, launderette, dry cleaning'
  },
  { Id: 27484, Code: '9234', Name: 'Cleaner, carpet' },
  { Id: 27485, Code: '9234', Name: 'Cleaner, clothes' },
  { Id: 27486, Code: '9234', Name: 'Cleaner, dry' },
  { Id: 27487, Code: '9234', Name: 'Cleaner, dyeing and cleaning' },
  { Id: 27488, Code: '9234', Name: 'Cleaner, fur' },
  { Id: 27489, Code: '9234', Name: 'Cleaner, fur goods mfr' },
  { Id: 27490, Code: '9234', Name: 'Cleaner, garment' },
  { Id: 27491, Code: '9234', Name: 'Cleaner, upholstery' },
  { Id: 27492, Code: '9234', Name: 'Cleaner, vacuum' },
  {
    Id: 27493,
    Code: '9234',
    Name: 'Dryer, laundry, launderette, dry cleaning'
  },
  { Id: 27494, Code: '9234', Name: 'Dyer and cleaner' },
  {
    Id: 27495,
    Code: '9234',
    Name: 'Feeder, laundry, launderette, dry cleaning'
  },
  { Id: 27496, Code: '9234', Name: 'Finisher, dyeing and cleaning' },
  { Id: 27497, Code: '9234', Name: 'Foreman, dyeing and cleaning' },
  { Id: 27498, Code: '9234', Name: 'Foreman, house, wash' },
  { Id: 27499, Code: '9234', Name: 'Foreman, laundry' },
  {
    Id: 27500,
    Code: '9234',
    Name: 'Foreman, laundry, launderette, dry cleaning'
  },
  {
    Id: 27501,
    Code: '9234',
    Name: 'Framer, laundry, launderette, dry cleaning'
  },
  {
    Id: 27502,
    Code: '9234',
    Name: 'Hand, calender, laundry, launderette, dry cleaning'
  },
  {
    Id: 27503,
    Code: '9234',
    Name: 'Hand, hydro, laundry, launderette, dry cleaning'
  },
  { Id: 27504, Code: '9234', Name: 'Hand, laundry' },
  {
    Id: 27505,
    Code: '9234',
    Name: 'Hand, leading, laundry, launderette, dry cleaning'
  },
  { Id: 27506, Code: '9234', Name: 'Houseman, wash' },
  { Id: 27507, Code: '9234', Name: 'Ironer' },
  { Id: 27508, Code: '9234', Name: 'Ironer, clothing mfr' },
  { Id: 27509, Code: '9234', Name: 'Ironer, glove' },
  { Id: 27510, Code: '9234', Name: 'Ironer, hosiery, knitwear mfr' },
  {
    Id: 27511,
    Code: '9234',
    Name: 'Ironer, laundry, launderette, dry cleaning'
  },
  { Id: 27512, Code: '9234', Name: 'Launderer' },
  { Id: 27513, Code: '9234', Name: 'Laundress' },
  {
    Id: 27514,
    Code: '9234',
    Name: 'Machinist, collar, laundry, launderette, dry cleaning'
  },
  { Id: 27515, Code: '9234', Name: 'Machinist, dyeing and cleaning' },
  { Id: 27516, Code: '9234', Name: 'Machinist, laundry' },
  {
    Id: 27517,
    Code: '9234',
    Name: 'Machinist, laundry, launderette, dry cleaning'
  },
  {
    Id: 27518,
    Code: '9234',
    Name: 'Machinist, shirt, laundry, launderette, dry cleaning'
  },
  {
    Id: 27519,
    Code: '9234',
    Name: 'Machinist, washing, laundry, launderette, dry cleaning'
  },
  { Id: 27520, Code: '9234', Name: 'Maid, laundry' },
  { Id: 27521, Code: '9234', Name: 'Man, laundry' },
  { Id: 27522, Code: '9234', Name: 'Matron, laundry' },
  { Id: 27523, Code: '9234', Name: 'Operative, cleaning, dry' },
  { Id: 27524, Code: '9234', Name: 'Operative, laundry' },
  { Id: 27525, Code: '9234', Name: 'Operative, restoration, carpet cleaning' },
  {
    Id: 27526,
    Code: '9234',
    Name: 'Operator, calender, laundry, launderette, dry cleaning'
  },
  {
    Id: 27527,
    Code: '9234',
    Name: 'Operator, cleaner, dry, laundry, launderette, dry cleaning'
  },
  { Id: 27528, Code: '9234', Name: 'Operator, hydro' },
  {
    Id: 27529,
    Code: '9234',
    Name: 'Operator, hydro, laundry, launderette, dry cleaning'
  },
  {
    Id: 27530,
    Code: '9234',
    Name: 'Operator, hydro-extractor, laundry, launderette, dry cleaning'
  },
  { Id: 27531, Code: '9234', Name: 'Operator, laundry' },
  { Id: 27532, Code: '9234', Name: 'Operator, presser, steam' },
  {
    Id: 27533,
    Code: '9234',
    Name: 'Operator, tumbler, laundry, launderette, dry cleaning'
  },
  {
    Id: 27534,
    Code: '9234',
    Name: 'Operator, unit, laundry, launderette, dry cleaning'
  },
  {
    Id: 27535,
    Code: '9234',
    Name: 'Preparer, laundry, launderette, dry cleaning'
  },
  { Id: 27536, Code: '9234', Name: 'Presser, blouse' },
  { Id: 27537, Code: '9234', Name: 'Presser, cap' },
  { Id: 27538, Code: '9234', Name: 'Presser, clothes' },
  { Id: 27539, Code: '9234', Name: 'Presser, clothing' },
  { Id: 27540, Code: '9234', Name: 'Presser, clothing mfr' },
  { Id: 27541, Code: '9234', Name: 'Presser, collar' },
  { Id: 27542, Code: '9234', Name: 'Presser, garment' },
  { Id: 27543, Code: '9234', Name: 'Presser, general' },
  { Id: 27544, Code: '9234', Name: 'Presser, hand, tailoring' },
  { Id: 27545, Code: '9234', Name: 'Presser, hand, textile finishing' },
  { Id: 27546, Code: '9234', Name: 'Presser, Hoffman' },
  { Id: 27547, Code: '9234', Name: 'Presser, knitwear' },
  {
    Id: 27548,
    Code: '9234',
    Name: 'Presser, laundry, launderette, dry cleaning'
  },
  { Id: 27549, Code: '9234', Name: 'Presser, lining' },
  { Id: 27550, Code: '9234', Name: 'Presser, machine, clothing mfr' },
  { Id: 27551, Code: '9234', Name: 'Presser, seam' },
  { Id: 27552, Code: '9234', Name: 'Presser, shirt' },
  { Id: 27553, Code: '9234', Name: 'Presser, sleeve' },
  { Id: 27554, Code: '9234', Name: 'Presser, steam, CLOTHING' },
  { Id: 27555, Code: '9234', Name: "Presser, tailor's" },
  { Id: 27556, Code: '9234', Name: 'Presser, top' },
  { Id: 27557, Code: '9234', Name: 'Presser, trouser' },
  { Id: 27558, Code: '9234', Name: 'Smoother, clothing mfr' },
  {
    Id: 27559,
    Code: '9234',
    Name: 'Spotter, laundry, launderette, dry cleaning'
  },
  {
    Id: 27560,
    Code: '9234',
    Name: 'Starcher, laundry, launderette, dry cleaning'
  },
  { Id: 27561, Code: '9234', Name: 'Steamer, dyeing and cleaning' },
  {
    Id: 27562,
    Code: '9234',
    Name: 'Superintendent, laundry, hospital service'
  },
  { Id: 27563, Code: '9234', Name: 'Supervisor, laundry' },
  {
    Id: 27564,
    Code: '9234',
    Name: 'Tabber, laundry, launderette, dry cleaning'
  },
  { Id: 27565, Code: '9234', Name: 'Taker-off, textile mfr: lace finishing' },
  { Id: 27566, Code: '9234', Name: 'Technician, cleaning, carpet' },
  { Id: 27567, Code: '9234', Name: 'Underpresser' },
  {
    Id: 27568,
    Code: '9234',
    Name: 'Washer, laundry, launderette, dry cleaning'
  },
  {
    Id: 27569,
    Code: '9234',
    Name: 'Washerman, laundry, launderette, dry cleaning'
  },
  {
    Id: 27570,
    Code: '9234',
    Name: 'Washman, laundry, launderette, dry cleaning'
  },
  {
    Id: 27571,
    Code: '9234',
    Name: 'Winder, towel, laundry, launderette, dry cleaning'
  },
  { Id: 27572, Code: '9234', Name: 'Worker, factory, laundry' },
  { Id: 27573, Code: '9234', Name: 'Worker, launderette' },
  { Id: 27574, Code: '9234', Name: 'Worker, laundry' },
  {
    Id: 27575,
    Code: '9234',
    Name: 'Worker, process, laundry, launderette, dry cleaning'
  },
  { Id: 27576, Code: '9235', Name: 'Attendant, hopper, refuse destruction' },
  { Id: 27577, Code: '9235', Name: 'Attendant, tip' },
  { Id: 27578, Code: '9235', Name: 'Binman, local government: cleansing dept' },
  { Id: 27579, Code: '9235', Name: 'Cleanser, local government' },
  { Id: 27580, Code: '9235', Name: 'Collector, dust, local government' },
  { Id: 27581, Code: '9235', Name: 'Collector, metal, scrap' },
  { Id: 27582, Code: '9235', Name: 'Collector, refuse' },
  { Id: 27583, Code: '9235', Name: 'Collector, salvage' },
  { Id: 27584, Code: '9235', Name: 'Collector, scrap' },
  { Id: 27585, Code: '9235', Name: 'Collector, waste' },
  { Id: 27586, Code: '9235', Name: 'Dustman' },
  { Id: 27587, Code: '9235', Name: 'Foreman, district, sanitary services' },
  {
    Id: 27588,
    Code: '9235',
    Name: 'Foreman, local government: cleansing dept'
  },
  { Id: 27589, Code: '9235', Name: 'Foreman, local government: refuse tip' },
  { Id: 27590, Code: '9235', Name: 'Foreman, local government: sanitary dept' },
  { Id: 27591, Code: '9235', Name: 'Foreman, salvage' },
  { Id: 27592, Code: '9235', Name: 'Foreman, wreck raising' },
  { Id: 27593, Code: '9235', Name: 'Hand, salvage' },
  { Id: 27594, Code: '9235', Name: 'Inspector, cleansing' },
  {
    Id: 27595,
    Code: '9235',
    Name: 'Inspector, local government: cleansing dept'
  },
  { Id: 27596, Code: '9235', Name: 'Lifter, bin' },
  {
    Id: 27597,
    Code: '9235',
    Name: 'Loader, refuse, local government: cleansing dept'
  },
  {
    Id: 27598,
    Code: '9235',
    Name: 'Man, bin, local government: cleansing dept'
  },
  { Id: 27599, Code: '9235', Name: 'Officer, disposal, refuse' },
  {
    Id: 27600,
    Code: '9235',
    Name: 'Officer, estate, local government: cleansing dept'
  },
  {
    Id: 27601,
    Code: '9235',
    Name: 'Officer, estates, local government: cleansing dept'
  },
  { Id: 27602, Code: '9235', Name: 'Operative, disposal, refuse' },
  { Id: 27603, Code: '9235', Name: 'Operative, disposal, waste' },
  { Id: 27604, Code: '9235', Name: 'Operative, landfill' },
  { Id: 27605, Code: '9235', Name: 'Operative, recycling' },
  { Id: 27606, Code: '9235', Name: 'Operative, refuse' },
  { Id: 27607, Code: '9235', Name: 'Operator, disposal, refuse' },
  { Id: 27608, Code: '9235', Name: 'Operator, disposal, waste' },
  { Id: 27609, Code: '9235', Name: 'Operator, recycling' },
  { Id: 27610, Code: '9235', Name: 'Recycler' },
  { Id: 27611, Code: '9235', Name: 'Recycler, paper' },
  { Id: 27612, Code: '9235', Name: 'Remover, refuse' },
  { Id: 27613, Code: '9235', Name: 'Scavenger' },
  {
    Id: 27614,
    Code: '9235',
    Name: 'Superintendent, cleansing, local government'
  },
  { Id: 27615, Code: '9235', Name: 'Superintendent, sanitary' },
  { Id: 27616, Code: '9235', Name: 'Tatter, WASTE' },
  { Id: 27617, Code: '9235', Name: 'Tipman, refuse' },
  { Id: 27618, Code: '9235', Name: 'Trimmer, disposal, refuse' },
  { Id: 27619, Code: '9235', Name: 'Worker, recycling' },
  { Id: 27620, Code: '9235', Name: 'Worker, salvage' },
  { Id: 27621, Code: '9236', Name: 'Assistant, wash, car' },
  { Id: 27622, Code: '9236', Name: 'Cleaner, aircraft' },
  { Id: 27623, Code: '9236', Name: 'Cleaner, boat' },
  { Id: 27624, Code: '9236', Name: 'Cleaner, bus' },
  { Id: 27625, Code: '9236', Name: 'Cleaner, cab' },
  { Id: 27626, Code: '9236', Name: 'Cleaner, car' },
  { Id: 27627, Code: '9236', Name: 'Cleaner, carriage' },
  { Id: 27628, Code: '9236', Name: 'Cleaner, coach' },
  { Id: 27629, Code: '9236', Name: 'Cleaner, motor, garage' },
  { Id: 27630, Code: '9236', Name: "Cleaner, ship's" },
  { Id: 27631, Code: '9236', Name: 'Cleaner, vehicle' },
  { Id: 27632, Code: '9236', Name: 'Detailer, car' },
  { Id: 27633, Code: '9236', Name: 'Groomer, aircraft' },
  { Id: 27634, Code: '9236', Name: 'Man, service, carriage, railways' },
  { Id: 27635, Code: '9236', Name: 'Operative, care, train' },
  { Id: 27636, Code: '9236', Name: 'Polisher, car, motor, garage' },
  { Id: 27637, Code: '9236', Name: 'Technician, appearance, vehicle' },
  { Id: 27638, Code: '9236', Name: 'Valet, VEHICLES' },
  { Id: 27639, Code: '9236', Name: 'Valeter' },
  { Id: 27640, Code: '9236', Name: 'Valeter, car' },
  { Id: 27641, Code: '9236', Name: 'Valeter, vehicle' },
  { Id: 27642, Code: '9236', Name: 'Washer, cab' },
  { Id: 27643, Code: '9236', Name: 'Washer, car' },
  { Id: 27644, Code: '9236', Name: 'Washer, lorry' },
  { Id: 27645, Code: '9236', Name: 'Washer, van' },
  { Id: 27646, Code: '9239', Name: 'Attendant, amenity' },
  { Id: 27647, Code: '9239', Name: 'Attendant, block, amenity' },
  { Id: 27648, Code: '9239', Name: 'Attendant, cleansing' },
  { Id: 27649, Code: '9239', Name: 'Attendant, lavatory' },
  { Id: 27650, Code: '9239', Name: 'Attendant, local government' },
  { Id: 27651, Code: '9239', Name: 'Attendant, PUBLIC CONVENIENCES' },
  { Id: 27652, Code: '9239', Name: "Attendant, room, ladies'" },
  { Id: 27653, Code: '9239', Name: 'Attendant, room, wash' },
  { Id: 27654, Code: '9239', Name: 'Attendant, sanitary' },
  { Id: 27655, Code: '9239', Name: 'Attendant, toilet' },
  { Id: 27656, Code: '9239', Name: 'Cleaner, chimney' },
  { Id: 27657, Code: '9239', Name: 'Cleaner, drain' },
  { Id: 27658, Code: '9239', Name: 'Cleaner, flue' },
  { Id: 27659, Code: '9239', Name: 'Cleaner, hygiene' },
  { Id: 27660, Code: '9239', Name: 'Cleaner, toilet' },
  { Id: 27661, Code: '9239', Name: 'Man, service, carriage' },
  { Id: 27662, Code: '9239', Name: 'Orderly, sanitary' },
  { Id: 27663, Code: '9239', Name: 'Sweep, chimney' },
  { Id: 27664, Code: '9239', Name: 'Sweeper, chimney' },
  { Id: 27665, Code: '9241', Name: 'Adviser, security' },
  { Id: 27666, Code: '9241', Name: 'Agent, security' },
  { Id: 27667, Code: '9241', Name: 'Agent, security, aviation' },
  { Id: 27668, Code: '9241', Name: 'Agent, trace' },
  { Id: 27669, Code: '9241', Name: 'Assistant, museum, SECURITY' },
  { Id: 27670, Code: '9241', Name: 'Assistant, PO: investigation branch' },
  { Id: 27671, Code: '9241', Name: 'Assistant, security' },
  { Id: 27672, Code: '9241', Name: 'Attendant, court' },
  { Id: 27673, Code: '9241', Name: 'Attendant, gate' },
  { Id: 27674, Code: '9241', Name: 'Attendant, lodge' },
  { Id: 27675, Code: '9241', Name: 'Attendant, park' },
  { Id: 27676, Code: '9241', Name: 'Attendant, security' },
  { Id: 27677, Code: '9241', Name: 'Bodyguard' },
  { Id: 27678, Code: '9241', Name: 'Checker, gate' },
  { Id: 27679, Code: '9241', Name: 'Constable, market' },
  { Id: 27680, Code: '9241', Name: 'Constable, NON-STATUTORY' },
  { Id: 27681, Code: '9241', Name: 'Consultant, security' },
  { Id: 27682, Code: '9241', Name: 'Controller, security' },
  { Id: 27683, Code: '9241', Name: 'Custodian, civilian' },
  { Id: 27684, Code: '9241', Name: 'Custodian, security services' },
  { Id: 27685, Code: '9241', Name: 'Detective, hotel' },
  { Id: 27686, Code: '9241', Name: 'Detective, private' },
  { Id: 27687, Code: '9241', Name: 'Detective, private detective agency' },
  { Id: 27688, Code: '9241', Name: 'Detective, retail trade' },
  { Id: 27689, Code: '9241', Name: 'Detective, store' },
  { Id: 27690, Code: '9241', Name: 'Foreman, security services' },
  { Id: 27691, Code: '9241', Name: 'Gatekeeper' },
  { Id: 27692, Code: '9241', Name: 'Gateman' },
  { Id: 27693, Code: '9241', Name: 'Guard, bank' },
  { Id: 27694, Code: '9241', Name: 'Guard, body' },
  { Id: 27695, Code: '9241', Name: 'Guard, custody' },
  { Id: 27696, Code: '9241', Name: 'Guard, manufacturing' },
  { Id: 27697, Code: '9241', Name: 'Guard, night' },
  { Id: 27698, Code: '9241', Name: 'Guard, road goods transport' },
  { Id: 27699, Code: '9241', Name: 'Guard, security' },
  { Id: 27700, Code: '9241', Name: 'Guard, security services' },
  { Id: 27701, Code: '9241', Name: 'Guard, van' },
  { Id: 27702, Code: '9241', Name: 'Guard, works' },
  { Id: 27703, Code: '9241', Name: 'Inspector of park keepers' },
  { Id: 27704, Code: '9241', Name: 'Inspector, beach' },
  { Id: 27705, Code: '9241', Name: 'Inspector, park' },
  { Id: 27706, Code: '9241', Name: 'Inspector, patrol' },
  { Id: 27707, Code: '9241', Name: 'Investigator, civil' },
  { Id: 27708, Code: '9241', Name: 'Investigator, credit' },
  { Id: 27709, Code: '9241', Name: 'Investigator, fraud' },
  { Id: 27710, Code: '9241', Name: 'Investigator, private' },
  { Id: 27711, Code: '9241', Name: 'Investigator, purchase, hire' },
  { Id: 27712, Code: '9241', Name: 'Investigator, security' },
  { Id: 27713, Code: '9241', Name: 'Investigator, security services' },
  { Id: 27714, Code: '9241', Name: 'Keeper, door' },
  { Id: 27715, Code: '9241', Name: 'Keeper, gate' },
  { Id: 27716, Code: '9241', Name: 'Keeper, head, park' },
  { Id: 27717, Code: '9241', Name: 'Keeper, house, gate' },
  { Id: 27718, Code: '9241', Name: 'Keeper, lodge' },
  { Id: 27719, Code: '9241', Name: 'Keeper, park' },
  { Id: 27720, Code: '9241', Name: 'Keeper, park, head' },
  { Id: 27721, Code: '9241', Name: 'Keeper, ship' },
  { Id: 27722, Code: '9241', Name: 'Man, lodge' },
  { Id: 27723, Code: '9241', Name: 'Man, security' },
  { Id: 27724, Code: '9241', Name: 'Nightwatchman' },
  { Id: 27725, Code: '9241', Name: 'Officer, branch, security services' },
  { Id: 27726, Code: '9241', Name: 'Officer, detention' },
  { Id: 27727, Code: '9241', Name: 'Officer, fraud' },
  { Id: 27728, Code: '9241', Name: 'Officer, investigating' },
  { Id: 27729, Code: '9241', Name: 'Officer, investigating, fraud' },
  { Id: 27730, Code: '9241', Name: 'Officer, investigation' },
  { Id: 27731, Code: '9241', Name: 'Officer, investigation, fraud' },
  { Id: 27732, Code: '9241', Name: 'Officer, monitoring, CCTV' },
  { Id: 27733, Code: '9241', Name: 'Officer, patrol' },
  { Id: 27734, Code: '9241', Name: 'Officer, patrol, security' },
  { Id: 27735, Code: '9241', Name: 'Officer, police, NON-STATUTORY' },
  { Id: 27736, Code: '9241', Name: 'Officer, prevention, loss' },
  { Id: 27737, Code: '9241', Name: 'Officer, protection, close' },
  { Id: 27738, Code: '9241', Name: 'Officer, security' },
  { Id: 27739, Code: '9241', Name: 'Officer, security, chief' },
  { Id: 27740, Code: '9241', Name: 'Operative, security' },
  { Id: 27741, Code: '9241', Name: 'Operator, CCTV' },
  { Id: 27742, Code: '9241', Name: 'Operator, television, circuit, close' },
  { Id: 27743, Code: '9241', Name: 'Patrol' },
  { Id: 27744, Code: '9241', Name: 'Patrolman' },
  { Id: 27745, Code: '9241', Name: 'Porter, gate' },
  { Id: 27746, Code: '9241', Name: 'Porter, lodge' },
  { Id: 27747, Code: '9241', Name: 'Porter, security' },
  { Id: 27748, Code: '9241', Name: 'Security' },
  { Id: 27749, Code: '9241', Name: 'Securityman' },
  { Id: 27750, Code: '9241', Name: 'Sergeant, security' },
  { Id: 27751, Code: '9241', Name: 'Superintendent, park' },
  { Id: 27752, Code: '9241', Name: 'Supervisor, park' },
  { Id: 27753, Code: '9241', Name: 'Supervisor, security' },
  { Id: 27754, Code: '9241', Name: 'Warden, park' },
  { Id: 27755, Code: '9241', Name: 'Warden, security' },
  { Id: 27756, Code: '9241', Name: 'Watcher, fire' },
  { Id: 27757, Code: '9241', Name: 'Watcher, night' },
  { Id: 27758, Code: '9241', Name: 'Watcher, ship' },
  { Id: 27759, Code: '9241', Name: 'Watchman' },
  { Id: 27760, Code: '9242', Name: 'Agent, enforcement, civil' },
  { Id: 27761, Code: '9242', Name: 'Ambassador, centre, city' },
  { Id: 27762, Code: '9242', Name: 'Assistant, meter' },
  { Id: 27763, Code: '9242', Name: 'Assistant, park, car' },
  { Id: 27764, Code: '9242', Name: 'Attendant, meter, parking' },
  { Id: 27765, Code: '9242', Name: 'Attendant, park, car' },
  { Id: 27766, Code: '9242', Name: 'Attendant, parking' },
  { Id: 27767, Code: '9242', Name: 'Clamper, wheel' },
  { Id: 27768, Code: '9242', Name: 'Inspector, park, car' },
  { Id: 27769, Code: '9242', Name: 'Officer, enforcement, civil' },
  { Id: 27770, Code: '9242', Name: 'Officer, park, car' },
  { Id: 27771, Code: '9242', Name: 'Operative, scene, street' },
  { Id: 27772, Code: '9242', Name: 'Sergeant, town' },
  { Id: 27773, Code: '9242', Name: 'Steward, park, car' },
  { Id: 27774, Code: '9242', Name: 'Supervisor, car park' },
  { Id: 27775, Code: '9242', Name: 'Supervisor, park, car' },
  { Id: 27776, Code: '9242', Name: 'Warden, community' },
  { Id: 27777, Code: '9242', Name: 'Warden, estate, housing services' },
  { Id: 27778, Code: '9242', Name: 'Warden, neighbourhood' },
  { Id: 27779, Code: '9242', Name: 'Warden, traffic' },
  { Id: 27780, Code: '9244', Name: 'Assistant, lunchtime, schools' },
  { Id: 27781, Code: '9244', Name: 'Assistant, mealtime, schools' },
  { Id: 27782, Code: '9244', Name: 'Assistant, midday' },
  { Id: 27783, Code: '9244', Name: 'Assistant, playground, schools' },
  { Id: 27784, Code: '9244', Name: 'Assistant, supervisory, lunchtime' },
  { Id: 27785, Code: '9244', Name: 'Assistant, supervisory, midday' },
  { Id: 27786, Code: '9244', Name: 'Assistant, supervisory, SCHOOL MEALS' },
  { Id: 27787, Code: '9244', Name: 'Assistant, supervisory, schools, MIDDAY' },
  { Id: 27788, Code: '9244', Name: 'Assistant, time, lunch, schools' },
  { Id: 27789, Code: '9244', Name: 'Assistant, time, meal, schools' },
  { Id: 27790, Code: '9244', Name: 'Assistant, welfare, dinner' },
  { Id: 27791, Code: '9244', Name: 'Assistant, welfare, schools, LUNCHTIME' },
  { Id: 27792, Code: '9244', Name: 'Attendant, crossing, ROAD' },
  { Id: 27793, Code: '9244', Name: 'Attendant, crossing, schools' },
  { Id: 27794, Code: '9244', Name: 'Attendant, ground, play' },
  { Id: 27795, Code: '9244', Name: 'Attendant, midday, SCHOOL MEALS' },
  {
    Id: 27796,
    Code: '9244',
    Name: 'Auxiliary, lunchtime, educational establishments'
  },
  { Id: 27797, Code: '9244', Name: 'Controller, dinner' },
  { Id: 27798, Code: '9244', Name: 'Controller, lunch, schools' },
  { Id: 27799, Code: '9244', Name: 'Controller, lunchtime, schools' },
  { Id: 27800, Code: '9244', Name: "Help, teacher's, SCHOOL MEALS" },
  { Id: 27801, Code: '9244', Name: 'Lady, dinner, schools' },
  { Id: 27802, Code: '9244', Name: 'Man, lollipop' },
  { Id: 27803, Code: '9244', Name: 'Officer, patrol, crossing, school' },
  { Id: 27804, Code: '9244', Name: 'Organiser, lunchtime' },
  { Id: 27805, Code: '9244', Name: 'Patrol, crossing, school' },
  { Id: 27806, Code: '9244', Name: 'Patrol, crossing, SCHOOLS' },
  { Id: 27807, Code: '9244', Name: 'Patroller, crossing, SCHOOLS' },
  { Id: 27808, Code: '9244', Name: 'Patrolman, crossing, school' },
  { Id: 27809, Code: '9244', Name: 'Patrolman, crossing, SCHOOLS' },
  { Id: 27810, Code: '9244', Name: "Superintendent, children's" },
  { Id: 27811, Code: '9244', Name: "Supervisor, children's" },
  { Id: 27812, Code: '9244', Name: 'Supervisor, dinner' },
  { Id: 27813, Code: '9244', Name: 'Supervisor, lunchtime' },
  { Id: 27814, Code: '9244', Name: 'Supervisor, meals, SCHOOLS' },
  { Id: 27815, Code: '9244', Name: 'Supervisor, midday' },
  { Id: 27816, Code: '9244', Name: 'Supervisor, playground' },
  { Id: 27817, Code: '9244', Name: 'Supervisor, school' },
  { Id: 27818, Code: '9244', Name: 'Supervisor, school meals service' },
  { Id: 27819, Code: '9244', Name: 'Supervisor, time, dinner' },
  { Id: 27820, Code: '9244', Name: 'Warden, crossing, SCHOOLS' },
  { Id: 27821, Code: '9244', Name: 'Warden, dinner, SCHOOLS' },
  { Id: 27822, Code: '9244', Name: 'Warden, patrol, crossing, school' },
  { Id: 27823, Code: '9249', Name: 'Agent, legal' },
  { Id: 27824, Code: '9249', Name: 'Assistant, gallery, art' },
  { Id: 27825, Code: '9249', Name: 'Assistant, lighting, street' },
  { Id: 27826, Code: '9249', Name: 'Attendant, art gallery' },
  { Id: 27827, Code: '9249', Name: 'Attendant, car, airport' },
  { Id: 27828, Code: '9249', Name: 'Attendant, cloakroom' },
  { Id: 27829, Code: '9249', Name: 'Attendant, door' },
  { Id: 27830, Code: '9249', Name: 'Attendant, lamp' },
  { Id: 27831, Code: '9249', Name: 'Attendant, lamp, railways' },
  { Id: 27832, Code: '9249', Name: 'Attendant, lift' },
  { Id: 27833, Code: '9249', Name: 'Attendant, light' },
  { Id: 27834, Code: '9249', Name: 'Attendant, lighting' },
  { Id: 27835, Code: '9249', Name: 'Attendant, luggage, left' },
  { Id: 27836, Code: '9249', Name: "Attendant, mayor's" },
  { Id: 27837, Code: '9249', Name: 'Attendant, museum' },
  { Id: 27838, Code: '9249', Name: 'Attendant, property, lost' },
  { Id: 27839, Code: '9249', Name: 'Attendant, room, cloak' },
  { Id: 27840, Code: '9249', Name: 'Attendant, room, locker' },
  { Id: 27841, Code: '9249', Name: 'Attendant, room, luggage' },
  { Id: 27842, Code: '9249', Name: 'Attendant, room, waiting' },
  { Id: 27843, Code: '9249', Name: 'Attendant, school' },
  { Id: 27844, Code: '9249', Name: 'Attendant, services, visitor, SECURITY' },
  { Id: 27845, Code: '9249', Name: 'Attendant, studio' },
  { Id: 27846, Code: '9249', Name: 'Bailiff' },
  { Id: 27847, Code: '9249', Name: "Bailiff, auctioneer's" },
  { Id: 27848, Code: '9249', Name: 'Bailiff, certificated' },
  { Id: 27849, Code: '9249', Name: 'Bailiff, court' },
  { Id: 27850, Code: '9249', Name: 'Bailiff, rent' },
  { Id: 27851, Code: '9249', Name: 'Bearer, mace' },
  { Id: 27852, Code: '9249', Name: 'Bouncer' },
  {
    Id: 27853,
    Code: '9249',
    Name: 'Boy, door, hotels, catering, public houses'
  },
  { Id: 27854, Code: '9249', Name: 'Clerk, cloakroom' },
  { Id: 27855, Code: '9249', Name: 'Clerk, luggage, left' },
  { Id: 27856, Code: '9249', Name: 'Commissionaire' },
  { Id: 27857, Code: '9249', Name: 'Custodian' },
  { Id: 27858, Code: '9249', Name: 'Doorman' },
  { Id: 27859, Code: '9249', Name: 'Foreman, lighting' },
  { Id: 27860, Code: '9249', Name: 'Gateman, ENTERTAINMENT' },
  { Id: 27861, Code: '9249', Name: 'Greeter, security services' },
  { Id: 27862, Code: '9249', Name: 'Houseman, museum' },
  { Id: 27863, Code: '9249', Name: 'Jockey, car' },
  { Id: 27864, Code: '9249', Name: 'Keeper, floor, Bank of England' },
  { Id: 27865, Code: '9249', Name: 'Lampman, signal' },
  { Id: 27866, Code: '9249', Name: 'Liftman' },
  { Id: 27867, Code: '9249', Name: 'Liftman, service' },
  { Id: 27868, Code: '9249', Name: 'Macer' },
  { Id: 27869, Code: '9249', Name: 'Man, possession' },
  { Id: 27870, Code: '9249', Name: 'Matron, police, prison service' },
  { Id: 27871, Code: '9249', Name: 'Messenger at arms' },
  { Id: 27872, Code: '9249', Name: 'Officer, alarm, community' },
  { Id: 27873, Code: '9249', Name: 'Officer, enforcement' },
  { Id: 27874, Code: '9249', Name: 'Officer, enforcement, court' },
  { Id: 27875, Code: '9249', Name: 'Officer, sheriff' },
  { Id: 27876, Code: '9249', Name: 'Officer, warrant, county court' },
  { Id: 27877, Code: '9249', Name: 'Operative, lighting, public' },
  { Id: 27878, Code: '9249', Name: 'Operator, lift' },
  { Id: 27879, Code: '9249', Name: 'Porter, ENTERTAINMENT' },
  { Id: 27880, Code: '9249', Name: 'Receptionist, door' },
  { Id: 27881, Code: '9249', Name: 'Receptionist, security' },
  { Id: 27882, Code: '9249', Name: 'Searcher, manufacturing' },
  { Id: 27883, Code: '9249', Name: 'Server, process' },
  { Id: 27884, Code: '9249', Name: 'Supervisor, door' },
  { Id: 27885, Code: '9249', Name: 'Usher, court' },
  { Id: 27886, Code: '9249', Name: 'Warden, garage' },
  { Id: 27887, Code: '9249', Name: 'Warden, manufacturing' },
  { Id: 27888, Code: '9249', Name: 'Warden, museum' },
  { Id: 27889, Code: '9249', Name: 'Warden, schools' },
  { Id: 27890, Code: '9249', Name: 'Warden, tourism' },
  { Id: 27891, Code: '9249', Name: 'Warder, museum' },
  { Id: 27892, Code: '9249', Name: 'Warder, yeoman' },
  { Id: 27893, Code: '9249', Name: 'Winder, clock' },
  { Id: 27894, Code: '9249', Name: 'Witness, debt collection' },
  { Id: 27895, Code: '9249', Name: 'Worker, lift' },
  { Id: 27896, Code: '9251', Name: 'Adviser, customer, SHELF FILLING' },
  {
    Id: 27897,
    Code: '9251',
    Name: 'Assistant, display, evening, SHELF FILLING'
  },
  { Id: 27898, Code: '9251', Name: 'Assistant, display, retail trade' },
  { Id: 27899, Code: '9251', Name: 'Assistant, display, SHELF FILLING' },
  { Id: 27900, Code: '9251', Name: 'Assistant, floor, retail trade' },
  { Id: 27901, Code: '9251', Name: 'Assistant, food, fresh, retail trade' },
  { Id: 27902, Code: '9251', Name: 'Assistant, general, retail trade' },
  { Id: 27903, Code: '9251', Name: 'Assistant, grocery' },
  { Id: 27904, Code: '9251', Name: 'Assistant, night, SHELF FILLING' },
  { Id: 27905, Code: '9251', Name: 'Assistant, operations, SHELF FILLING' },
  { Id: 27906, Code: '9251', Name: 'Assistant, produce' },
  { Id: 27907, Code: '9251', Name: 'Assistant, provision' },
  { Id: 27908, Code: '9251', Name: 'Assistant, provisions' },
  { Id: 27909, Code: '9251', Name: 'Assistant, replenishment' },
  { Id: 27910, Code: '9251', Name: 'Assistant, stock, retail trade' },
  { Id: 27911, Code: '9251', Name: 'Assistant, stock, SHELF FILLING' },
  { Id: 27912, Code: '9251', Name: 'Colleague, Asda, SHELF FILLING' },
  { Id: 27913, Code: '9251', Name: 'Controller, ambient' },
  { Id: 27914, Code: '9251', Name: 'Controller, replenishment, ambience' },
  { Id: 27915, Code: '9251', Name: 'Displayman, SHELF FILLING' },
  { Id: 27916, Code: '9251', Name: 'Filler, night, SHELF FILLING' },
  { Id: 27917, Code: '9251', Name: 'Filler, shelf' },
  { Id: 27918, Code: '9251', Name: 'Filler, SHELF FILLING' },
  { Id: 27919, Code: '9251', Name: 'Filler, shop, SHELF FILLING' },
  { Id: 27920, Code: '9251', Name: 'Hand, display, SHELF FILLING' },
  { Id: 27921, Code: '9251', Name: 'Handler, stock' },
  { Id: 27922, Code: '9251', Name: 'Member, team, customer, SHELF FILLING' },
  { Id: 27923, Code: '9251', Name: 'Merchandiser, evening' },
  { Id: 27924, Code: '9251', Name: 'Merchandiser, SHELF FILLING' },
  { Id: 27925, Code: '9251', Name: 'Packer, shelf' },
  { Id: 27926, Code: '9251', Name: 'Replenisher, SHELF FILLING' },
  { Id: 27927, Code: '9251', Name: 'Runner, retail trade' },
  { Id: 27928, Code: '9251', Name: 'Stacker, shelf' },
  { Id: 27929, Code: '9251', Name: 'Stacker, SHELF FILLING' },
  { Id: 27930, Code: '9251', Name: 'Stocker, shelf' },
  { Id: 27931, Code: '9251', Name: 'Stockist, SHELF FILLING' },
  { Id: 27932, Code: '9259', Name: 'Assistant, integrity, price' },
  { Id: 27933, Code: '9259', Name: 'Assistant, shopping, internet' },
  {
    Id: 27934,
    Code: '9259',
    Name: 'Assistant, trolley, wholesale, retail trade'
  },
  { Id: 27935, Code: '9259', Name: 'Attendant, minibar' },
  {
    Id: 27936,
    Code: '9259',
    Name: 'Attendant, trolley, wholesale, retail trade'
  },
  { Id: 27937, Code: '9259', Name: 'Boy, trolley, wholesale, retail trade' },
  { Id: 27938, Code: '9259', Name: 'Caller, checkout' },
  { Id: 27939, Code: '9259', Name: 'Caller, wholesale, retail trade' },
  { Id: 27940, Code: '9259', Name: 'Checker, code, retail trade' },
  { Id: 27941, Code: '9259', Name: 'Checker, ticket, wholesale, retail trade' },
  {
    Id: 27942,
    Code: '9259',
    Name: 'Collector, trolley, wholesale, retail trade'
  },
  {
    Id: 27943,
    Code: '9259',
    Name: 'Controller, code, wholesale, retail trade'
  },
  {
    Id: 27944,
    Code: '9259',
    Name: 'Controller, maintenance, file, wholesale, retail trade'
  },
  {
    Id: 27945,
    Code: '9259',
    Name: 'Controller, price, wholesale, retail trade'
  },
  { Id: 27946, Code: '9259', Name: 'Counter, stock, wholesale, retail trade' },
  { Id: 27947, Code: '9259', Name: 'Fixer, advertisement' },
  { Id: 27948, Code: '9259', Name: 'Greeter, retail trade' },
  { Id: 27949, Code: '9259', Name: 'Hand, advertisement' },
  { Id: 27950, Code: '9259', Name: 'Hanger, poster' },
  { Id: 27951, Code: '9259', Name: 'Inspector, advertisement' },
  { Id: 27952, Code: '9259', Name: 'Inspector, advertising' },
  { Id: 27953, Code: '9259', Name: 'Inspector, bill, advertising' },
  { Id: 27954, Code: '9259', Name: 'Inspector, site, advertising' },
  { Id: 27955, Code: '9259', Name: 'Installer, advertising' },
  { Id: 27956, Code: '9259', Name: 'Issuer, basket, retail trade' },
  { Id: 27957, Code: '9259', Name: 'Man, basket, retail trade' },
  {
    Id: 27958,
    Code: '9259',
    Name: 'Operator, maintenance, file, wholesale, retail trade'
  },
  { Id: 27959, Code: '9259', Name: 'Picker, order, retail trade' },
  { Id: 27960, Code: '9259', Name: 'Picker, retail trade' },
  { Id: 27961, Code: '9259', Name: 'Poster, bill' },
  { Id: 27962, Code: '9259', Name: 'Pricer, retail trade' },
  { Id: 27963, Code: '9259', Name: 'Shopper, home' },
  { Id: 27964, Code: '9259', Name: 'Shopper, internet, retail trade' },
  { Id: 27965, Code: '9259', Name: 'Sticker, bill' },
  { Id: 27966, Code: '9259', Name: 'Tagger, wholesale, retail trade' },
  { Id: 27967, Code: '9260', Name: "Assembler, grocer's" },
  { Id: 27968, Code: '9260', Name: 'Assembler, load' },
  { Id: 27969, Code: '9260', Name: 'Assembler, order' },
  { Id: 27970, Code: '9260', Name: 'Assembler, warehouse' },
  { Id: 27971, Code: '9260', Name: 'Assembler, wholesale, retail trade' },
  { Id: 27972, Code: '9260', Name: "Assistant, auctioneer's" },
  { Id: 27973, Code: '9260', Name: 'Assistant, bay, loading' },
  { Id: 27974, Code: '9260', Name: 'Assistant, delivery' },
  { Id: 27975, Code: '9260', Name: 'Assistant, despatch' },
  { Id: 27976, Code: '9260', Name: "Assistant, drayman's" },
  { Id: 27977, Code: '9260', Name: "Assistant, driver's, crane" },
  { Id: 27978, Code: '9260', Name: "Assistant, driver's, dumper" },
  { Id: 27979, Code: '9260', Name: "Assistant, driver's, lorry" },
  { Id: 27980, Code: '9260', Name: "Assistant, driver's, road transport" },
  { Id: 27981, Code: '9260', Name: "Assistant, driver's, van" },
  { Id: 27982, Code: '9260', Name: 'Assistant, general, wholesale trade' },
  { Id: 27983, Code: '9260', Name: "Assistant, keeper's, store" },
  { Id: 27984, Code: '9260', Name: 'Assistant, operations, warehousing' },
  { Id: 27985, Code: '9260', Name: "Assistant, remover's, furniture" },
  { Id: 27986, Code: '9260', Name: 'Assistant, resource, ambulance' },
  { Id: 27987, Code: '9260', Name: 'Assistant, room, grey' },
  { Id: 27988, Code: '9260', Name: 'Assistant, room, linen' },
  { Id: 27989, Code: '9260', Name: 'Assistant, room, pattern, textile mfr' },
  { Id: 27990, Code: '9260', Name: 'Assistant, room, sale, auctioneers' },
  { Id: 27991, Code: '9260', Name: 'Assistant, room, sample' },
  { Id: 27992, Code: '9260', Name: 'Assistant, stevedore' },
  { Id: 27993, Code: '9260', Name: 'Assistant, store' },
  { Id: 27994, Code: '9260', Name: 'Assistant, stores' },
  { Id: 27995, Code: '9260', Name: 'Assistant, supplies' },
  { Id: 27996, Code: '9260', Name: 'Assistant, warehouse' },
  { Id: 27997, Code: '9260', Name: "Assistant, warehouseman's" },
  { Id: 27998, Code: '9260', Name: 'Assistant, yard' },
  { Id: 27999, Code: '9260', Name: 'Associate, warehouse' },
  { Id: 28000, Code: '9260', Name: 'Attendant, crane' },
  { Id: 28001, Code: '9260', Name: 'Attendant, customs' },
  { Id: 28002, Code: '9260', Name: 'Attendant, elevator, goods' },
  { Id: 28003, Code: '9260', Name: 'Attendant, explosive, coal mine' },
  { Id: 28004, Code: '9260', Name: 'Attendant, explosives, coal mine' },
  { Id: 28005, Code: '9260', Name: 'Attendant, house, powder' },
  { Id: 28006, Code: '9260', Name: 'Attendant, lamp, coal mine' },
  { Id: 28007, Code: '9260', Name: 'Attendant, loading, barge' },
  { Id: 28008, Code: '9260', Name: 'Attendant, magazine' },
  { Id: 28009, Code: '9260', Name: 'Attendant, plan' },
  { Id: 28010, Code: '9260', Name: 'Attendant, ROAD GOODS VEHICLES' },
  { Id: 28011, Code: '9260', Name: 'Attendant, room, grey' },
  { Id: 28012, Code: '9260', Name: 'Attendant, room, lamp' },
  { Id: 28013, Code: '9260', Name: 'Attendant, room, linen' },
  { Id: 28014, Code: '9260', Name: 'Attendant, room, plan' },
  { Id: 28015, Code: '9260', Name: 'Attendant, room, stock' },
  { Id: 28016, Code: '9260', Name: 'Attendant, room, tool' },
  { Id: 28017, Code: '9260', Name: 'Attendant, silo' },
  { Id: 28018, Code: '9260', Name: 'Attendant, statutory' },
  { Id: 28019, Code: '9260', Name: 'Attendant, store' },
  { Id: 28020, Code: '9260', Name: 'Attendant, stores' },
  { Id: 28021, Code: '9260', Name: 'Attendant, van' },
  { Id: 28022, Code: '9260', Name: 'Attendant, wharf, oil' },
  { Id: 28023, Code: '9260', Name: 'Banksman, civil engineering' },
  { Id: 28024, Code: '9260', Name: 'Banksman, crane' },
  { Id: 28025, Code: '9260', Name: 'Banksman, manufacturing' },
  { Id: 28026, Code: '9260', Name: 'Bobber, fish' },
  { Id: 28027, Code: '9260', Name: 'Bobber, fish dock' },
  { Id: 28028, Code: '9260', Name: 'Bummaree' },
  { Id: 28029, Code: '9260', Name: 'Bunker, docks' },
  { Id: 28030, Code: '9260', Name: 'Caller-over, glass mfr' },
  { Id: 28031, Code: '9260', Name: 'Carrier, bag, docks' },
  { Id: 28032, Code: '9260', Name: 'Carrier, box, fish' },
  { Id: 28033, Code: '9260', Name: 'Carrier, deal' },
  { Id: 28034, Code: '9260', Name: 'Carrier, deal, docks' },
  { Id: 28035, Code: '9260', Name: 'Carrier, docks' },
  { Id: 28036, Code: '9260', Name: 'Carrier, pitwood' },
  { Id: 28037, Code: '9260', Name: 'Carrier, prop' },
  { Id: 28038, Code: '9260', Name: 'Carrier, set' },
  { Id: 28039, Code: '9260', Name: 'Carrier, timber, docks' },
  { Id: 28040, Code: '9260', Name: 'Cellarman, oil' },
  { Id: 28041, Code: '9260', Name: 'Cellarman, textile mfr' },
  { Id: 28042, Code: '9260', Name: 'Cellarman, wine merchants' },
  { Id: 28043, Code: '9260', Name: 'Cellarman, wine mfr' },
  { Id: 28044, Code: '9260', Name: 'Chainman, docks' },
  { Id: 28045, Code: '9260', Name: 'Chamberman, cold storage' },
  { Id: 28046, Code: '9260', Name: 'Checker and weigher' },
  { Id: 28047, Code: '9260', Name: 'Checker, coal mine' },
  { Id: 28048, Code: '9260', Name: 'Checker, dock' },
  { Id: 28049, Code: '9260', Name: 'Checker, goods' },
  { Id: 28050, Code: '9260', Name: 'Checker, inwards, goods' },
  {
    Id: 28051,
    Code: '9260',
    Name: 'Checker, linen, hotels, catering, public houses'
  },
  { Id: 28052, Code: '9260', Name: 'Checker, load' },
  { Id: 28053, Code: '9260', Name: 'Checker, steel, coal mine' },
  { Id: 28054, Code: '9260', Name: 'Checker, stock' },
  { Id: 28055, Code: '9260', Name: 'Checker, stores' },
  { Id: 28056, Code: '9260', Name: 'Checker, supports' },
  { Id: 28057, Code: '9260', Name: 'Checker, timber' },
  { Id: 28058, Code: '9260', Name: 'Checker, warehousing' },
  { Id: 28059, Code: '9260', Name: 'Collector of parts' },
  {
    Id: 28060,
    Code: '9260',
    Name: 'Collector, stock, CHARITABLE ORGANISATION'
  },
  { Id: 28061, Code: '9260', Name: 'Collier, BOAT, BARGE' },
  { Id: 28062, Code: '9260', Name: 'Contractor, removal' },
  { Id: 28063, Code: '9260', Name: 'Counter, bobbin' },
  { Id: 28064, Code: '9260', Name: 'Counter, printing' },
  { Id: 28065, Code: '9260', Name: 'Counter, textile mfr' },
  { Id: 28066, Code: '9260', Name: 'Counterman, CHEMICALS' },
  { Id: 28067, Code: '9260', Name: 'Counterman, DRUGS' },
  { Id: 28068, Code: '9260', Name: 'Counterman, wool warehouse' },
  { Id: 28069, Code: '9260', Name: 'Counter-off' },
  { Id: 28070, Code: '9260', Name: 'Crew, dock' },
  { Id: 28071, Code: '9260', Name: 'Crewman' },
  { Id: 28072, Code: '9260', Name: 'Depotman' },
  { Id: 28073, Code: '9260', Name: 'Despatch, goods' },
  { Id: 28074, Code: '9260', Name: 'Despatcher' },
  { Id: 28075, Code: '9260', Name: 'Despatcher, goods' },
  { Id: 28076, Code: '9260', Name: 'Discharger, docks' },
  { Id: 28077, Code: '9260', Name: 'Dispatcher' },
  { Id: 28078, Code: '9260', Name: 'Dispatcher, goods' },
  { Id: 28079, Code: '9260', Name: 'Docker' },
  { Id: 28080, Code: '9260', Name: 'Feeder, stock' },
  { Id: 28081, Code: '9260', Name: 'Filler, docks' },
  { Id: 28082, Code: '9260', Name: 'Filler, order' },
  { Id: 28083, Code: '9260', Name: 'Filler, stock' },
  { Id: 28084, Code: '9260', Name: 'Finder, tool' },
  { Id: 28085, Code: '9260', Name: 'Follower, crane' },
  { Id: 28086, Code: '9260', Name: 'Foreman, auctioneers' },
  { Id: 28087, Code: '9260', Name: 'Foreman, bay, loading' },
  { Id: 28088, Code: '9260', Name: 'Foreman, depot' },
  { Id: 28089, Code: '9260', Name: 'Foreman, despatch' },
  { Id: 28090, Code: '9260', Name: 'Foreman, dock' },
  { Id: 28091, Code: '9260', Name: 'Foreman, docks' },
  { Id: 28092, Code: '9260', Name: 'Foreman, hatch' },
  { Id: 28093, Code: '9260', Name: 'Foreman, jetty' },
  { Id: 28094, Code: '9260', Name: 'Foreman, ordnance depot' },
  { Id: 28095, Code: '9260', Name: 'Foreman, PLA' },
  { Id: 28096, Code: '9260', Name: 'Foreman, quay' },
  { Id: 28097, Code: '9260', Name: 'Foreman, receiving' },
  { Id: 28098, Code: '9260', Name: 'Foreman, removal contracting' },
  { Id: 28099, Code: '9260', Name: 'Foreman, repository' },
  { Id: 28100, Code: '9260', Name: 'Foreman, room, grey, textile mfr' },
  { Id: 28101, Code: '9260', Name: 'Foreman, room, lamp, coal mine' },
  { Id: 28102, Code: '9260', Name: 'Foreman, room, pattern, textile mfr' },
  { Id: 28103, Code: '9260', Name: 'Foreman, shipping' },
  { Id: 28104, Code: '9260', Name: 'Foreman, staithes' },
  { Id: 28105, Code: '9260', Name: 'Foreman, stock' },
  { Id: 28106, Code: '9260', Name: 'Foreman, store' },
  { Id: 28107, Code: '9260', Name: 'Foreman, stores' },
  { Id: 28108, Code: '9260', Name: 'Foreman, timber merchants' },
  { Id: 28109, Code: '9260', Name: 'Foreman, timber, docks' },
  { Id: 28110, Code: '9260', Name: 'Foreman, warehouse' },
  { Id: 28111, Code: '9260', Name: 'Foreman, warehousing' },
  { Id: 28112, Code: '9260', Name: 'Foreman, wharf' },
  { Id: 28113, Code: '9260', Name: 'Foreman, yard, auctioneers' },
  { Id: 28114, Code: '9260', Name: 'Foreman, yard, steel' },
  { Id: 28115, Code: '9260', Name: 'Foreman, yard, stock' },
  { Id: 28116, Code: '9260', Name: 'Foreman, yard, timber' },
  { Id: 28117, Code: '9260', Name: 'Foreman-ganger, docks' },
  { Id: 28118, Code: '9260', Name: 'Ganger, docks' },
  { Id: 28119, Code: '9260', Name: 'Gangwayman' },
  { Id: 28120, Code: '9260', Name: 'Giver-out' },
  { Id: 28121, Code: '9260', Name: 'Hand, despatch' },
  { Id: 28122, Code: '9260', Name: 'Hand, dispatch' },
  { Id: 28123, Code: '9260', Name: 'Hand, jetty' },
  { Id: 28124, Code: '9260', Name: 'Hand, leading, warehousing' },
  { Id: 28125, Code: '9260', Name: 'Hand, removal' },
  { Id: 28126, Code: '9260', Name: 'Hand, room, grey' },
  { Id: 28127, Code: '9260', Name: 'Hand, room, order' },
  { Id: 28128, Code: '9260', Name: 'Hand, room, pattern' },
  { Id: 28129, Code: '9260', Name: 'Hand, storage, cold' },
  { Id: 28130, Code: '9260', Name: 'Hand, warehouse' },
  { Id: 28131, Code: '9260', Name: 'Handler, art' },
  { Id: 28132, Code: '9260', Name: 'Handler, cargo' },
  { Id: 28133, Code: '9260', Name: 'Handler, cargo, docks' },
  { Id: 28134, Code: '9260', Name: 'Handler, cask' },
  { Id: 28135, Code: '9260', Name: 'Handler, freight, warehousing' },
  { Id: 28136, Code: '9260', Name: 'Hanger-on' },
  { Id: 28137, Code: '9260', Name: 'Hatchman' },
  { Id: 28138, Code: '9260', Name: 'Hatchwayman' },
  { Id: 28139, Code: '9260', Name: 'Heaver, coal' },
  { Id: 28140, Code: '9260', Name: 'Helper, ladle' },
  { Id: 28141, Code: '9260', Name: 'Holder, store' },
  { Id: 28142, Code: '9260', Name: 'Holdsman' },
  { Id: 28143, Code: '9260', Name: 'Humper, meat market' },
  { Id: 28144, Code: '9260', Name: 'Humper, slaughterhouse' },
  { Id: 28145, Code: '9260', Name: 'Issuer' },
  { Id: 28146, Code: '9260', Name: 'Jettyman' },
  { Id: 28147, Code: '9260', Name: 'Keeper, die' },
  { Id: 28148, Code: '9260', Name: 'Keeper, granary' },
  { Id: 28149, Code: '9260', Name: 'Keeper, house, ware' },
  { Id: 28150, Code: '9260', Name: 'Keeper, linen' },
  { Id: 28151, Code: '9260', Name: 'Keeper, magazine' },
  { Id: 28152, Code: '9260', Name: 'Keeper, pattern' },
  { Id: 28153, Code: '9260', Name: 'Keeper, plan, railways' },
  { Id: 28154, Code: '9260', Name: 'Keeper, repository, furniture' },
  { Id: 28155, Code: '9260', Name: 'Keeper, stationery' },
  { Id: 28156, Code: '9260', Name: 'Keeper, stock' },
  { Id: 28157, Code: '9260', Name: 'Keeper, store' },
  { Id: 28158, Code: '9260', Name: 'Keeper, store and vault, bonded' },
  { Id: 28159, Code: '9260', Name: 'Keeper, store, head' },
  { Id: 28160, Code: '9260', Name: 'Keeper, tool' },
  { Id: 28161, Code: '9260', Name: 'Keeper, vault' },
  { Id: 28162, Code: '9260', Name: 'Keeper, warehouse' },
  { Id: 28163, Code: '9260', Name: 'Keeper-clerk, store' },
  { Id: 28164, Code: '9260', Name: "Labourer, builders' merchants" },
  { Id: 28165, Code: '9260', Name: 'Labourer, dock' },
  { Id: 28166, Code: '9260', Name: 'Labourer, docks' },
  { Id: 28167, Code: '9260', Name: 'Labourer, fish' },
  { Id: 28168, Code: '9260', Name: 'Labourer, fish processing' },
  { Id: 28169, Code: '9260', Name: 'Labourer, haulage contractor' },
  { Id: 28170, Code: '9260', Name: 'Labourer, jetty' },
  { Id: 28171, Code: '9260', Name: 'Labourer, pitwood' },
  { Id: 28172, Code: '9260', Name: 'Labourer, pontoon' },
  { Id: 28173, Code: '9260', Name: 'Labourer, quay' },
  { Id: 28174, Code: '9260', Name: 'Labourer, quayside' },
  { Id: 28175, Code: '9260', Name: 'Labourer, riverside' },
  { Id: 28176, Code: '9260', Name: 'Labourer, ship' },
  { Id: 28177, Code: '9260', Name: "Labourer, stevedore's" },
  { Id: 28178, Code: '9260', Name: "Labourer, storekeeper's" },
  { Id: 28179, Code: '9260', Name: 'Labourer, stores' },
  { Id: 28180, Code: '9260', Name: 'Labourer, warehouse' },
  { Id: 28181, Code: '9260', Name: 'Labourer, warehousing' },
  { Id: 28182, Code: '9260', Name: 'Labourer, waterside' },
  { Id: 28183, Code: '9260', Name: 'Labourer, wharf' },
  { Id: 28184, Code: '9260', Name: 'Lampman, coal mine' },
  { Id: 28185, Code: '9260', Name: 'Lampman, mine: not coal' },
  { Id: 28186, Code: '9260', Name: 'Lander, fish' },
  { Id: 28187, Code: '9260', Name: 'Lasher, car' },
  { Id: 28188, Code: '9260', Name: 'Latcher, crane, steelworks' },
  { Id: 28189, Code: '9260', Name: 'Latcher, steelworks' },
  { Id: 28190, Code: '9260', Name: 'Leader, team, stockhandlers, warehousing' },
  { Id: 28191, Code: '9260', Name: 'Leader, team, warehouse' },
  { Id: 28192, Code: '9260', Name: 'Lifter, freight' },
  { Id: 28193, Code: '9260', Name: 'Lifter, warehousing' },
  { Id: 28194, Code: '9260', Name: 'Loader' },
  { Id: 28195, Code: '9260', Name: 'Loader and unloader' },
  { Id: 28196, Code: '9260', Name: 'Loader, barge' },
  { Id: 28197, Code: '9260', Name: 'Loader, boat' },
  { Id: 28198, Code: '9260', Name: 'Loader, docks' },
  { Id: 28199, Code: '9260', Name: 'Loader, dockside' },
  { Id: 28200, Code: '9260', Name: 'Loader, fish' },
  { Id: 28201, Code: '9260', Name: 'Loader, lorry' },
  { Id: 28202, Code: '9260', Name: 'Loader, timber, docks' },
  { Id: 28203, Code: '9260', Name: 'Loader, warehouse' },
  { Id: 28204, Code: '9260', Name: 'Loaderman, ship' },
  { Id: 28205, Code: '9260', Name: 'Longshoreman' },
  { Id: 28206, Code: '9260', Name: 'Looker-out, OPHTHALMIC LENSES' },
  { Id: 28207, Code: '9260', Name: 'Looker-up, FOOTWEAR' },
  { Id: 28208, Code: '9260', Name: 'Lumper, docks' },
  { Id: 28209, Code: '9260', Name: 'Lumper, fish' },
  { Id: 28210, Code: '9260', Name: 'Maid, linen' },
  { Id: 28211, Code: '9260', Name: 'Man on lorry, second' },
  { Id: 28212, Code: '9260', Name: 'Man, baggage' },
  { Id: 28213, Code: '9260', Name: 'Man, ballast' },
  { Id: 28214, Code: '9260', Name: 'Man, despatch, bakery' },
  { Id: 28215, Code: '9260', Name: 'Man, dispatch, bakery' },
  { Id: 28216, Code: '9260', Name: 'Man, dock' },
  { Id: 28217, Code: '9260', Name: 'Man, elevator, GOODS' },
  { Id: 28218, Code: '9260', Name: 'Man, explosives, mining' },
  { Id: 28219, Code: '9260', Name: 'Man, gear, docks' },
  { Id: 28220, Code: '9260', Name: 'Man, intake' },
  { Id: 28221, Code: '9260', Name: 'Man, material' },
  { Id: 28222, Code: '9260', Name: 'Man, order' },
  { Id: 28223, Code: '9260', Name: 'Man, powder' },
  { Id: 28224, Code: '9260', Name: 'Man, removal' },
  { Id: 28225, Code: '9260', Name: 'Man, room, cotton' },
  { Id: 28226, Code: '9260', Name: 'Man, room, drug' },
  { Id: 28227, Code: '9260', Name: 'Man, room, grey' },
  { Id: 28228, Code: '9260', Name: 'Man, room, pattern' },
  { Id: 28229, Code: '9260', Name: 'Man, room, stock' },
  { Id: 28230, Code: '9260', Name: 'Man, room, store' },
  { Id: 28231, Code: '9260', Name: 'Man, room, weft' },
  { Id: 28232, Code: '9260', Name: 'Man, stockroom' },
  { Id: 28233, Code: '9260', Name: 'Man, storeroom' },
  { Id: 28234, Code: '9260', Name: 'Man, transport, docks' },
  { Id: 28235, Code: '9260', Name: 'Man, warehouse' },
  { Id: 28236, Code: '9260', Name: 'Man, weft' },
  { Id: 28237, Code: '9260', Name: 'Man, wharf' },
  { Id: 28238, Code: '9260', Name: 'Man, yard' },
  { Id: 28239, Code: '9260', Name: 'Master, baggage' },
  { Id: 28240, Code: '9260', Name: "Mate, driver's" },
  { Id: 28241, Code: '9260', Name: "Mate, driver's, crane" },
  { Id: 28242, Code: '9260', Name: "Mate, driver's, grab" },
  { Id: 28243, Code: '9260', Name: "Mate, driver's, lorry" },
  { Id: 28244, Code: '9260', Name: "Mate, emptier's, gulley" },
  { Id: 28245, Code: '9260', Name: 'Member, team, warehouse' },
  { Id: 28246, Code: '9260', Name: 'Member, team, warehousing' },
  { Id: 28247, Code: '9260', Name: 'Minder, hatch' },
  { Id: 28248, Code: '9260', Name: 'Minder, tool' },
  { Id: 28249, Code: '9260', Name: 'Numberer, piece' },
  { Id: 28250, Code: '9260', Name: 'Operative, bay, loading' },
  { Id: 28251, Code: '9260', Name: 'Operative, despatch' },
  { Id: 28252, Code: '9260', Name: 'Operative, hub' },
  { Id: 28253, Code: '9260', Name: 'Operative, jetty' },
  { Id: 28254, Code: '9260', Name: 'Operative, port' },
  {
    Id: 28255,
    Code: '9260',
    Name: 'Operative, preparation, service, ambulance'
  },
  {
    Id: 28256,
    Code: '9260',
    Name: 'Operative, ready, make, service, ambulance'
  },
  { Id: 28257, Code: '9260', Name: 'Operative, removals' },
  { Id: 28258, Code: '9260', Name: 'Operative, store, cold' },
  { Id: 28259, Code: '9260', Name: 'Operative, warehouse' },
  { Id: 28260, Code: '9260', Name: 'Operator, control, stock' },
  { Id: 28261, Code: '9260', Name: 'Operator, despatch' },
  { Id: 28262, Code: '9260', Name: 'Operator, jetty' },
  { Id: 28263, Code: '9260', Name: 'Operator, port' },
  { Id: 28264, Code: '9260', Name: 'Operator, store, cold' },
  { Id: 28265, Code: '9260', Name: 'Operator, warehouse' },
  { Id: 28266, Code: '9260', Name: 'Packer, removals' },
  { Id: 28267, Code: '9260', Name: 'Partsman' },
  { Id: 28268, Code: '9260', Name: 'Picker, engineering' },
  { Id: 28269, Code: '9260', Name: 'Picker, order' },
  { Id: 28270, Code: '9260', Name: 'Picker, slate' },
  { Id: 28271, Code: '9260', Name: 'Picker, stock' },
  { Id: 28272, Code: '9260', Name: 'Picker, warehouse' },
  { Id: 28273, Code: '9260', Name: 'Picker, warehousing' },
  { Id: 28274, Code: '9260', Name: 'Pickman, coal mine' },
  { Id: 28275, Code: '9260', Name: 'Pitcher, meat market' },
  { Id: 28276, Code: '9260', Name: 'Porter' },
  { Id: 28277, Code: '9260', Name: 'Porter, coal' },
  { Id: 28278, Code: '9260', Name: 'Porter, despatch' },
  { Id: 28279, Code: '9260', Name: 'Porter, dock' },
  { Id: 28280, Code: '9260', Name: 'Porter, docks, FOOD' },
  { Id: 28281, Code: '9260', Name: 'Porter, FOOD' },
  { Id: 28282, Code: '9260', Name: 'Porter, furniture' },
  { Id: 28283, Code: '9260', Name: 'Porter, general' },
  { Id: 28284, Code: '9260', Name: 'Porter, goods' },
  { Id: 28285, Code: '9260', Name: 'Porter, goods, canals' },
  { Id: 28286, Code: '9260', Name: 'Porter, laundry' },
  { Id: 28287, Code: '9260', Name: 'Porter, manufacturing' },
  { Id: 28288, Code: '9260', Name: 'Porter, MARKET' },
  { Id: 28289, Code: '9260', Name: 'Porter, meat' },
  { Id: 28290, Code: '9260', Name: 'Porter, motor' },
  { Id: 28291, Code: '9260', Name: 'Porter, night, MARKET' },
  { Id: 28292, Code: '9260', Name: 'Porter, OFFICE' },
  { Id: 28293, Code: '9260', Name: 'Porter, store' },
  { Id: 28294, Code: '9260', Name: 'Porter, stores' },
  { Id: 28295, Code: '9260', Name: 'Porter, timber' },
  { Id: 28296, Code: '9260', Name: 'Porter, timber yard, TIMBER' },
  { Id: 28297, Code: '9260', Name: 'Porter, timber, furniture mfr' },
  { Id: 28298, Code: '9260', Name: 'Porter, timber, timber yard' },
  { Id: 28299, Code: '9260', Name: 'Porter, transport' },
  { Id: 28300, Code: '9260', Name: 'Porter, van' },
  { Id: 28301, Code: '9260', Name: 'Porter, warehouse' },
  { Id: 28302, Code: '9260', Name: 'Porter-messenger' },
  { Id: 28303, Code: '9260', Name: 'Porter-packer' },
  { Id: 28304, Code: '9260', Name: 'Porter-storeman' },
  { Id: 28305, Code: '9260', Name: 'Puller-back, meat market' },
  { Id: 28306, Code: '9260', Name: 'Quayman' },
  { Id: 28307, Code: '9260', Name: 'Racker' },
  { Id: 28308, Code: '9260', Name: 'Rafter' },
  { Id: 28309, Code: '9260', Name: 'Railman, docks' },
  { Id: 28310, Code: '9260', Name: 'Receiver' },
  { Id: 28311, Code: '9260', Name: 'Removalman' },
  { Id: 28312, Code: '9260', Name: 'Remover, furniture' },
  { Id: 28313, Code: '9260', Name: 'Ripener, banana' },
  { Id: 28314, Code: '9260', Name: 'Runner, deal, timber merchants' },
  { Id: 28315, Code: '9260', Name: 'Runner, docks' },
  { Id: 28316, Code: '9260', Name: 'Selector, government' },
  { Id: 28317, Code: '9260', Name: 'Selector, spares, vehicle mfr' },
  { Id: 28318, Code: '9260', Name: 'Selector, stores' },
  { Id: 28319, Code: '9260', Name: 'Shedman, docks' },
  { Id: 28320, Code: '9260', Name: 'Sheeter, docks' },
  { Id: 28321, Code: '9260', Name: 'Shipper, docks' },
  { Id: 28322, Code: '9260', Name: 'Signalman, steelworks' },
  { Id: 28323, Code: '9260', Name: 'Slinger' },
  { Id: 28324, Code: '9260', Name: 'Sorter, order, mail order house' },
  { Id: 28325, Code: '9260', Name: 'Stacker and packer' },
  { Id: 28326, Code: '9260', Name: 'Staithman' },
  { Id: 28327, Code: '9260', Name: 'Stevedore' },
  { Id: 28328, Code: '9260', Name: 'Stevedore, superintendent' },
  { Id: 28329, Code: '9260', Name: 'Steward, shore' },
  { Id: 28330, Code: '9260', Name: 'Stocker, tinplate mfr' },
  { Id: 28331, Code: '9260', Name: 'Stockman, manufacturing' },
  { Id: 28332, Code: '9260', Name: 'Stocktaker, rolling mill' },
  { Id: 28333, Code: '9260', Name: 'Stocktaker, steel smelting' },
  { Id: 28334, Code: '9260', Name: 'Storehand' },
  { Id: 28335, Code: '9260', Name: 'Storehouseman' },
  { Id: 28336, Code: '9260', Name: 'Storeman' },
  { Id: 28337, Code: '9260', Name: 'Storeman, chief' },
  { Id: 28338, Code: '9260', Name: 'Storeman, head' },
  { Id: 28339, Code: '9260', Name: 'Storeman, ordnance' },
  { Id: 28340, Code: '9260', Name: 'Storer' },
  { Id: 28341, Code: '9260', Name: 'Storesman' },
  { Id: 28342, Code: '9260', Name: 'Storesperson' },
  { Id: 28343, Code: '9260', Name: 'Storewoman, superintendent, PO' },
  { Id: 28344, Code: '9260', Name: 'Stower, ship' },
  { Id: 28345, Code: '9260', Name: 'Supervisor, despatch' },
  { Id: 28346, Code: '9260', Name: 'Supervisor, mail order house' },
  { Id: 28347, Code: '9260', Name: 'Supervisor, store' },
  { Id: 28348, Code: '9260', Name: 'Supervisor, warehouse' },
  { Id: 28349, Code: '9260', Name: 'Tackleman, docks' },
  { Id: 28350, Code: '9260', Name: 'Taker, number' },
  { Id: 28351, Code: '9260', Name: 'Taker, stock, rolling mill' },
  { Id: 28352, Code: '9260', Name: 'Taker, stock, steel smelting' },
  { Id: 28353, Code: '9260', Name: 'Taker-in, piece, textile mfr' },
  { Id: 28354, Code: '9260', Name: 'Tallyman, timber' },
  { Id: 28355, Code: '9260', Name: 'Teemer, coal' },
  { Id: 28356, Code: '9260', Name: 'Tipper, ballast' },
  { Id: 28357, Code: '9260', Name: 'Tipper, coal, docks' },
  { Id: 28358, Code: '9260', Name: 'Tipper, docks' },
  { Id: 28359, Code: '9260', Name: 'Trimmer, ballast' },
  { Id: 28360, Code: '9260', Name: 'Trimmer, barge' },
  { Id: 28361, Code: '9260', Name: 'Trimmer, boat' },
  { Id: 28362, Code: '9260', Name: 'Trimmer, coal, docks' },
  { Id: 28363, Code: '9260', Name: 'Trimmer, docks' },
  { Id: 28364, Code: '9260', Name: 'Unloader' },
  { Id: 28365, Code: '9260', Name: 'Unloader, docks' },
  { Id: 28366, Code: '9260', Name: 'Warehouseman' },
  { Id: 28367, Code: '9260', Name: 'Warehouseman, chief' },
  { Id: 28368, Code: '9260', Name: 'Warehouser' },
  { Id: 28369, Code: '9260', Name: 'Waterman, dock' },
  { Id: 28370, Code: '9260', Name: 'Wharfinger' },
  { Id: 28371, Code: '9260', Name: 'Worker, bond' },
  { Id: 28372, Code: '9260', Name: 'Worker, cargo' },
  { Id: 28373, Code: '9260', Name: 'Worker, depot' },
  { Id: 28374, Code: '9260', Name: 'Worker, despatch' },
  { Id: 28375, Code: '9260', Name: 'Worker, dispatch' },
  { Id: 28376, Code: '9260', Name: 'Worker, dock' },
  { Id: 28377, Code: '9260', Name: 'Worker, dockside' },
  { Id: 28378, Code: '9260', Name: 'Worker, fish, docks' },
  { Id: 28379, Code: '9260', Name: 'Worker, port' },
  { Id: 28380, Code: '9260', Name: 'Worker, room, cotton' },
  { Id: 28381, Code: '9260', Name: 'Worker, room, grey, textile mfr' },
  { Id: 28382, Code: '9260', Name: 'Worker, room, pattern' },
  { Id: 28383, Code: '9260', Name: 'Worker, room, stock' },
  { Id: 28384, Code: '9260', Name: 'Worker, ship' },
  { Id: 28385, Code: '9260', Name: 'Worker, silo' },
  { Id: 28386, Code: '9260', Name: 'Worker, store, cold' },
  { Id: 28387, Code: '9260', Name: 'Worker, supply, retail trade' },
  { Id: 28388, Code: '9260', Name: 'Worker, transport, docks' },
  { Id: 28389, Code: '9260', Name: 'Worker, transport, waterways' },
  { Id: 28390, Code: '9260', Name: 'Worker, warehouse' },
  { Id: 28391, Code: '9260', Name: 'Worker, yard' },
  { Id: 28392, Code: '9260', Name: 'Yardman' },
  { Id: 28393, Code: '9260', Name: 'Yardsman' },
  { Id: 28394, Code: '9271', Name: 'Porter, domestic, hospital service' },
  { Id: 28395, Code: '9271', Name: 'Porter, general, hospital service' },
  { Id: 28396, Code: '9271', Name: 'Porter, hall, hospital service' },
  { Id: 28397, Code: '9271', Name: 'Porter, head, hospital service' },
  { Id: 28398, Code: '9271', Name: 'Porter, hospital' },
  { Id: 28399, Code: '9271', Name: 'Porter, hospital service' },
  { Id: 28400, Code: '9271', Name: 'Porter, night, hospital service' },
  { Id: 28401, Code: '9271', Name: 'Porter, ward' },
  { Id: 28402, Code: '9271', Name: 'Supervisor, portering, hospital services' },
  { Id: 28403, Code: '9272', Name: 'Artist, sandwich' },
  { Id: 28404, Code: '9272', Name: 'Assistant, bar, NON-ALCOHOLIC' },
  { Id: 28405, Code: '9272', Name: 'Assistant, bar, snack' },
  { Id: 28406, Code: '9272', Name: 'Assistant, buttery' },
  { Id: 28407, Code: '9272', Name: 'Assistant, cafe' },
  { Id: 28408, Code: '9272', Name: 'Assistant, cafeteria' },
  { Id: 28409, Code: '9272', Name: 'Assistant, canteen' },
  { Id: 28410, Code: '9272', Name: "Assistant, caterer's" },
  { Id: 28411, Code: '9272', Name: 'Assistant, catering' },
  { Id: 28412, Code: '9272', Name: 'Assistant, chef�s' },
  { Id: 28413, Code: '9272', Name: 'Assistant, cookery' },
  { Id: 28414, Code: '9272', Name: "Assistant, cook's" },
  { Id: 28415, Code: '9272', Name: 'Assistant, counter, catering' },
  { Id: 28416, Code: '9272', Name: 'Assistant, food and beverage' },
  { Id: 28417, Code: '9272', Name: 'Assistant, food, fast' },
  { Id: 28418, Code: '9272', Name: "Assistant, frier's, fish" },
  { Id: 28419, Code: '9272', Name: 'Assistant, general, catering' },
  { Id: 28420, Code: '9272', Name: 'Assistant, general, school meals' },
  { Id: 28421, Code: '9272', Name: 'Assistant, hall, dining' },
  { Id: 28422, Code: '9272', Name: 'Assistant, house, cook' },
  { Id: 28423, Code: '9272', Name: 'Assistant, kitchen' },
  {
    Id: 28424,
    Code: '9272',
    Name: 'Assistant, lunchtime, PREPARING, SERVING FOOD'
  },
  { Id: 28425, Code: '9272', Name: 'Assistant, meals, school' },
  {
    Id: 28426,
    Code: '9272',
    Name: 'Assistant, mealtime, PREPARING, SERVING FOOD'
  },
  { Id: 28427, Code: '9272', Name: 'Assistant, preparation, food' },
  { Id: 28428, Code: '9272', Name: 'Assistant, refectory' },
  { Id: 28429, Code: '9272', Name: 'Assistant, restaurant' },
  { Id: 28430, Code: '9272', Name: 'Assistant, room, dining' },
  { Id: 28431, Code: '9272', Name: 'Assistant, room, still' },
  { Id: 28432, Code: '9272', Name: 'Assistant, room, tea' },
  { Id: 28433, Code: '9272', Name: 'Assistant, service, catering' },
  { Id: 28434, Code: '9272', Name: 'Assistant, service, counter, CATERING' },
  {
    Id: 28435,
    Code: '9272',
    Name: 'Assistant, service, customer, fast food outlet'
  },
  { Id: 28436, Code: '9272', Name: 'Assistant, service, food' },
  { Id: 28437, Code: '9272', Name: 'Assistant, services, catering' },
  { Id: 28438, Code: '9272', Name: 'Assistant, serving, CATERING' },
  { Id: 28439, Code: '9272', Name: 'Assistant, shop, coffee' },
  { Id: 28440, Code: '9272', Name: 'Assistant, staff, canteen' },
  { Id: 28441, Code: '9272', Name: 'Assistant, tearoom' },
  { Id: 28442, Code: '9272', Name: 'Associate, food and beverage' },
  { Id: 28443, Code: '9272', Name: 'Associate, kitchen' },
  { Id: 28444, Code: '9272', Name: 'Attendant, bar, NON-ALCOHOLIC' },
  { Id: 28445, Code: '9272', Name: 'Attendant, bar, snack' },
  { Id: 28446, Code: '9272', Name: 'Attendant, buffet' },
  { Id: 28447, Code: '9272', Name: 'Attendant, canteen' },
  { Id: 28448, Code: '9272', Name: 'Attendant, catering' },
  { Id: 28449, Code: '9272', Name: 'Attendant, hall, dining' },
  { Id: 28450, Code: '9272', Name: 'Attendant, kitchen' },
  { Id: 28451, Code: '9272', Name: 'Attendant, meals, school' },
  { Id: 28452, Code: '9272', Name: 'Attendant, mess' },
  { Id: 28453, Code: '9272', Name: 'Attendant, room, dining' },
  { Id: 28454, Code: '9272', Name: 'Attendant, room, mess' },
  { Id: 28455, Code: '9272', Name: 'Attendant, room, refreshment' },
  { Id: 28456, Code: '9272', Name: 'Attendant, room, still' },
  { Id: 28457, Code: '9272', Name: 'Attendant, stall, coffee' },
  { Id: 28458, Code: '9272', Name: 'Barista' },
  { Id: 28459, Code: '9272', Name: 'Barmaid, coffee' },
  { Id: 28460, Code: '9272', Name: 'Barmaid, tea' },
  { Id: 28461, Code: '9272', Name: 'Barman, coffee' },
  { Id: 28462, Code: '9272', Name: 'Barman, tea' },
  { Id: 28463, Code: '9272', Name: 'Boy, messroom' },
  { Id: 28464, Code: '9272', Name: 'Boy, tea' },
  { Id: 28465, Code: '9272', Name: 'Boy, trolley, catering' },
  { Id: 28466, Code: '9272', Name: 'Carver, FOOD' },
  { Id: 28467, Code: '9272', Name: 'Cleaner, plate, catering' },
  { Id: 28468, Code: '9272', Name: 'Cleaner, silver, domestic service' },
  {
    Id: 28469,
    Code: '9272',
    Name: 'Cleaner, silver, hotels, catering, public houses'
  },
  { Id: 28470, Code: '9272', Name: 'Clearer, table' },
  { Id: 28471, Code: '9272', Name: 'Counterhand' },
  { Id: 28472, Code: '9272', Name: 'Counterman, catering' },
  { Id: 28473, Code: '9272', Name: 'Dispenser, FOOD AND BEVERAGES' },
  { Id: 28474, Code: '9272', Name: 'Domestic, kitchen' },
  { Id: 28475, Code: '9272', Name: 'Foreman, catering' },
  { Id: 28476, Code: '9272', Name: 'Glassman' },
  { Id: 28477, Code: '9272', Name: 'Hand, cafeteria' },
  { Id: 28478, Code: '9272', Name: 'Hand, canteen' },
  { Id: 28479, Code: '9272', Name: 'Hand, galley' },
  { Id: 28480, Code: '9272', Name: 'Hand, kitchen' },
  { Id: 28481, Code: '9272', Name: 'Hand, mess' },
  { Id: 28482, Code: '9272', Name: 'Hand, room, service' },
  { Id: 28483, Code: '9272', Name: 'Hand, servery' },
  { Id: 28484, Code: '9272', Name: 'Hand, service, catering' },
  { Id: 28485, Code: '9272', Name: 'Hand, steam, catering' },
  { Id: 28486, Code: '9272', Name: 'Help, canteen' },
  { Id: 28487, Code: '9272', Name: 'Help, kitchen' },
  { Id: 28488, Code: '9272', Name: 'Help, meals, school' },
  { Id: 28489, Code: '9272', Name: 'Help, room, dining' },
  { Id: 28490, Code: '9272', Name: 'Helper, canteen' },
  { Id: 28491, Code: '9272', Name: 'Helper, catering' },
  { Id: 28492, Code: '9272', Name: 'Helper, kitchen' },
  { Id: 28493, Code: '9272', Name: 'Helper, meals, school' },
  { Id: 28494, Code: '9272', Name: 'Helper, room, dining' },
  { Id: 28495, Code: '9272', Name: 'Host, ward, hospital service' },
  { Id: 28496, Code: '9272', Name: 'Hostess, hospital service' },
  { Id: 28497, Code: '9272', Name: 'Inspector, car, DINING CAR' },
  {
    Id: 28498,
    Code: '9272',
    Name: 'Lady, dinner, schools, PREPARING, SERVING FOOD'
  },
  { Id: 28499, Code: '9272', Name: 'Lady, tea' },
  { Id: 28500, Code: '9272', Name: 'Maid, buffet' },
  { Id: 28501, Code: '9272', Name: 'Maid, canteen, SCHOOLS' },
  { Id: 28502, Code: '9272', Name: 'Maid, coffee' },
  { Id: 28503, Code: '9272', Name: 'Maid, hall, dining' },
  { Id: 28504, Code: '9272', Name: 'Maid, kitchen' },
  { Id: 28505, Code: '9272', Name: 'Maid, pantry' },
  { Id: 28506, Code: '9272', Name: 'Maid, room, coffee' },
  { Id: 28507, Code: '9272', Name: 'Maid, room, dining' },
  { Id: 28508, Code: '9272', Name: 'Maid, room, mess' },
  { Id: 28509, Code: '9272', Name: 'Maid, room, still' },
  { Id: 28510, Code: '9272', Name: 'Maid, table' },
  { Id: 28511, Code: '9272', Name: 'Maker, pizza, FAST FOOD' },
  { Id: 28512, Code: '9272', Name: 'Maker, sandwich' },
  { Id: 28513, Code: '9272', Name: 'Maker, tea' },
  { Id: 28514, Code: '9272', Name: 'Man, despatch, catering' },
  { Id: 28515, Code: '9272', Name: 'Man, dispatch, catering' },
  { Id: 28516, Code: '9272', Name: 'Man, kitchen' },
  {
    Id: 28517,
    Code: '9272',
    Name: 'Man, pan, hotels, catering, public houses'
  },
  { Id: 28518, Code: '9272', Name: 'Man, pantry' },
  {
    Id: 28519,
    Code: '9272',
    Name: 'Man, plate, hotels, catering, public houses'
  },
  {
    Id: 28520,
    Code: '9272',
    Name: 'Man, room, still, hotels, catering, public houses'
  },
  {
    Id: 28521,
    Code: '9272',
    Name: 'Man, still, hotels, catering, public houses'
  },
  {
    Id: 28522,
    Code: '9272',
    Name: 'Man, stillroom, hotels, catering, public houses'
  },
  { Id: 28523, Code: '9272', Name: 'Masher, tea' },
  { Id: 28524, Code: '9272', Name: "Mate, cook's" },
  { Id: 28525, Code: '9272', Name: 'Member, crew, fast food outlet' },
  { Id: 28526, Code: '9272', Name: 'Member, squad, training' },
  { Id: 28527, Code: '9272', Name: 'Member, team, fast food outlet' },
  { Id: 28528, Code: '9272', Name: 'Messman' },
  { Id: 28529, Code: '9272', Name: 'Official, NAAFI' },
  { Id: 28530, Code: '9272', Name: 'Orderly, kitchen' },
  { Id: 28531, Code: '9272', Name: 'Orderly, mess' },
  { Id: 28532, Code: '9272', Name: 'Orderly, room, dining' },
  { Id: 28533, Code: '9272', Name: 'Organiser, meals, school' },
  {
    Id: 28534,
    Code: '9272',
    Name: 'Polisher, silver, hotels, catering, public houses'
  },
  { Id: 28535, Code: '9272', Name: 'Porter, catering' },
  { Id: 28536, Code: '9272', Name: 'Porter, kitchen' },
  { Id: 28537, Code: '9272', Name: 'Potman, hotels, catering, public houses' },
  { Id: 28538, Code: '9272', Name: 'Preparer, sandwich' },
  { Id: 28539, Code: '9272', Name: 'Server, canteen' },
  { Id: 28540, Code: '9272', Name: 'Server, dinner' },
  { Id: 28541, Code: '9272', Name: 'Server, meal' },
  { Id: 28542, Code: '9272', Name: 'Server, meals' },
  { Id: 28543, Code: '9272', Name: 'Server, school meals' },
  { Id: 28544, Code: '9272', Name: 'Silverman' },
  { Id: 28545, Code: '9272', Name: 'Steward, kitchen' },
  { Id: 28546, Code: '9272', Name: 'Steward, offshore, CLEANING' },
  { Id: 28547, Code: '9272', Name: 'Steward, pantry' },
  { Id: 28548, Code: '9272', Name: 'Supervisor, canteen' },
  { Id: 28549, Code: '9272', Name: 'Supervisor, catering' },
  { Id: 28550, Code: '9272', Name: 'Supervisor, kitchen' },
  { Id: 28551, Code: '9272', Name: 'Supervisor, restaurant' },
  {
    Id: 28552,
    Code: '9272',
    Name: 'Supervisor, room, dining, hospital service'
  },
  { Id: 28553, Code: '9272', Name: 'Supervisor, unit, catering' },
  { Id: 28554, Code: '9272', Name: 'Technician, catering' },
  { Id: 28555, Code: '9272', Name: 'Technician, sandwich' },
  { Id: 28556, Code: '9272', Name: 'Trainer, crew, fast food outlet' },
  { Id: 28557, Code: '9272', Name: 'Trolleyman, refreshments' },
  { Id: 28558, Code: '9272', Name: 'Washer, dish' },
  {
    Id: 28559,
    Code: '9272',
    Name: 'Washer, glass, hotels, catering, public houses'
  },
  { Id: 28560, Code: '9272', Name: 'Washer, plate' },
  {
    Id: 28561,
    Code: '9272',
    Name: 'Washer, pot, hotels, catering, public houses'
  },
  {
    Id: 28562,
    Code: '9272',
    Name: 'Washer-up, hotels, catering, public houses'
  },
  { Id: 28563, Code: '9272', Name: 'Worker, cafe' },
  { Id: 28564, Code: '9272', Name: 'Worker, canteen' },
  { Id: 28565, Code: '9272', Name: 'Worker, catering' },
  { Id: 28566, Code: '9272', Name: 'Worker, kitchen' },
  { Id: 28567, Code: '9272', Name: 'Worker, restaurant' },
  { Id: 28568, Code: '9272', Name: 'Worker, room, still' },
  { Id: 28569, Code: '9272', Name: 'Worker, service, school meals' },
  { Id: 28570, Code: '9273', Name: 'Assistant, conference and banqueting' },
  { Id: 28571, Code: '9273', Name: 'Assistant, service, room, hotel' },
  { Id: 28572, Code: '9273', Name: 'Attendant, car, dining' },
  { Id: 28573, Code: '9273', Name: 'Attendant, car, restaurant' },
  { Id: 28574, Code: '9273', Name: 'Attendant, room, ward' },
  { Id: 28575, Code: '9273', Name: 'Attendant, service, room, hotel' },
  { Id: 28576, Code: '9273', Name: 'Boy, saloon' },
  { Id: 28577, Code: '9273', Name: 'Busser' },
  { Id: 28578, Code: '9273', Name: 'Butler, wine' },
  { Id: 28579, Code: '9273', Name: 'Chef de rang' },
  { Id: 28580, Code: '9273', Name: 'Hostess, fast food outlet' },
  { Id: 28581, Code: '9273', Name: 'Hostess, party, fast food outlet' },
  { Id: 28582, Code: '9273', Name: 'Hostess, public house, restaurant' },
  { Id: 28583, Code: '9273', Name: 'Maid, tea' },
  { Id: 28584, Code: '9273', Name: "Maitre d'hotel" },
  { Id: 28585, Code: '9273', Name: 'Organiser, party, fast food outlet' },
  { Id: 28586, Code: '9273', Name: 'Runner, catering' },
  { Id: 28587, Code: '9273', Name: 'Runner, public house' },
  { Id: 28588, Code: '9273', Name: 'Runner, restaurant' },
  { Id: 28589, Code: '9273', Name: 'Runner, shift, fast food outlet' },
  { Id: 28590, Code: '9273', Name: 'Seater, catering' },
  { Id: 28591, Code: '9273', Name: 'Sommelier' },
  { Id: 28592, Code: '9273', Name: 'Steward, canteen' },
  { Id: 28593, Code: '9273', Name: 'Steward, car, dining' },
  { Id: 28594, Code: '9273', Name: 'Steward, catering' },
  { Id: 28595, Code: '9273', Name: 'Steward, chief' },
  { Id: 28596, Code: '9273', Name: 'Steward, mess' },
  { Id: 28597, Code: '9273', Name: 'Steward, messroom' },
  { Id: 28598, Code: '9273', Name: 'Steward, MOD' },
  { Id: 28599, Code: '9273', Name: 'Steward, railways' },
  { Id: 28600, Code: '9273', Name: 'Steward, ship, catering' },
  { Id: 28601, Code: '9273', Name: 'Steward, shipping: catering' },
  { Id: 28602, Code: '9273', Name: 'Steward, university: catering' },
  { Id: 28603, Code: '9273', Name: 'Steward, wine' },
  { Id: 28604, Code: '9273', Name: 'Waiter' },
  { Id: 28605, Code: '9273', Name: 'Waiter, head' },
  { Id: 28606, Code: '9273', Name: 'Waiter, restaurant' },
  { Id: 28607, Code: '9273', Name: 'Waiter, service, silver' },
  { Id: 28608, Code: '9273', Name: 'Waiter, ward' },
  { Id: 28609, Code: '9273', Name: 'Waiter, wine' },
  { Id: 28610, Code: '9273', Name: 'Waitress' },
  { Id: 28611, Code: '9273', Name: 'Waitress, head' },
  { Id: 28612, Code: '9273', Name: 'Waitress, service, silver' },
  { Id: 28613, Code: '9273', Name: 'Waitress, ward' },
  { Id: 28614, Code: '9273', Name: 'Worker, factory, fun' },
  { Id: 28615, Code: '9274', Name: 'Assistant, bar' },
  { Id: 28616, Code: '9274', Name: "Assistant, licensee's" },
  { Id: 28617, Code: '9274', Name: 'Attendant, bar' },
  { Id: 28618, Code: '9274', Name: 'Attendant, saloon' },
  { Id: 28619, Code: '9274', Name: 'Bar-cellarman' },
  { Id: 28620, Code: '9274', Name: 'Barmaid' },
  { Id: 28621, Code: '9274', Name: 'Barmaid, head' },
  { Id: 28622, Code: '9274', Name: 'Barmaid-waitress' },
  { Id: 28623, Code: '9274', Name: 'Barman' },
  { Id: 28624, Code: '9274', Name: 'Barman, head' },
  { Id: 28625, Code: '9274', Name: 'Barperson' },
  { Id: 28626, Code: '9274', Name: 'Barperson, head' },
  { Id: 28627, Code: '9274', Name: 'Barstaff' },
  { Id: 28628, Code: '9274', Name: 'Barstaff, head' },
  { Id: 28629, Code: '9274', Name: 'Bartender' },
  { Id: 28630, Code: '9274', Name: 'Barworker' },
  { Id: 28631, Code: '9274', Name: 'Barworker, head' },
  { Id: 28632, Code: '9274', Name: 'Cellarman, bar' },
  { Id: 28633, Code: '9274', Name: 'Cellarman, catering' },
  { Id: 28634, Code: '9274', Name: 'Cellarman, hotel' },
  { Id: 28635, Code: '9274', Name: 'Cellarman, public house' },
  { Id: 28636, Code: '9274', Name: 'Collector, glass, CATERING' },
  { Id: 28637, Code: '9274', Name: 'Collector, glass, public house' },
  { Id: 28638, Code: '9274', Name: 'Dispenser, drink' },
  { Id: 28639, Code: '9274', Name: 'Dispenser, licensed trade' },
  { Id: 28640, Code: '9274', Name: 'Handyman, bar' },
  { Id: 28641, Code: '9274', Name: 'Help, bar' },
  { Id: 28642, Code: '9274', Name: 'Helper, bar' },
  {
    Id: 28643,
    Code: '9274',
    Name: 'Keeper, bar, hotels, catering, public houses'
  },
  { Id: 28644, Code: '9274', Name: 'Keeper, bar, shipping' },
  { Id: 28645, Code: '9274', Name: 'Maid, bar' },
  { Id: 28646, Code: '9274', Name: 'Mixologist, BEVERAGES' },
  { Id: 28647, Code: '9274', Name: 'Operative, bar' },
  { Id: 28648, Code: '9274', Name: 'Staff, bar' },
  { Id: 28649, Code: '9274', Name: 'Steward, bar' },
  { Id: 28650, Code: '9274', Name: 'Supervisor, bar' },
  { Id: 28651, Code: '9274', Name: 'Tender, bar' },
  { Id: 28652, Code: '9274', Name: 'Waiter, cocktail' },
  { Id: 28653, Code: '9274', Name: 'Waitress, cocktail' },
  { Id: 28654, Code: '9274', Name: 'Worker, bar' },
  { Id: 28655, Code: '9275', Name: 'Assistant, arcade' },
  { Id: 28656, Code: '9275', Name: 'Assistant, cinema' },
  { Id: 28657, Code: '9275', Name: 'Assistant, customer, cinema' },
  { Id: 28658, Code: '9275', Name: 'Assistant, fairground' },
  {
    Id: 28659,
    Code: '9275',
    Name: 'Assistant, front of house, leisure services'
  },
  { Id: 28660, Code: '9275', Name: 'Assistant, funfair' },
  { Id: 28661, Code: '9275', Name: 'Assistant, general, leisure services' },
  { Id: 28662, Code: '9275', Name: 'Attendant, amusement' },
  { Id: 28663, Code: '9275', Name: 'Attendant, arcade' },
  { Id: 28664, Code: '9275', Name: 'Attendant, camp' },
  { Id: 28665, Code: '9275', Name: 'Attendant, cinema' },
  { Id: 28666, Code: '9275', Name: 'Attendant, club' },
  { Id: 28667, Code: '9275', Name: 'Attendant, ENTERTAINMENT' },
  { Id: 28668, Code: '9275', Name: 'Attendant, field, playing' },
  { Id: 28669, Code: '9275', Name: 'Attendant, funfair' },
  { Id: 28670, Code: '9275', Name: 'Attendant, ground, fair' },
  { Id: 28671, Code: '9275', Name: 'Attendant, hall, billiard' },
  { Id: 28672, Code: '9275', Name: 'Attendant, hall, dance' },
  { Id: 28673, Code: '9275', Name: 'Attendant, hotel' },
  { Id: 28674, Code: '9275', Name: 'Attendant, lane' },
  { Id: 28675, Code: '9275', Name: 'Attendant, park, amusement' },
  { Id: 28676, Code: '9275', Name: 'Attendant, park, theme' },
  { Id: 28677, Code: '9275', Name: 'Attendant, pier, ENTERTAINMENT' },
  { Id: 28678, Code: '9275', Name: 'Attendant, rides' },
  { Id: 28679, Code: '9275', Name: 'Attendant, room, ball' },
  { Id: 28680, Code: '9275', Name: 'Attendant, stall, AMUSEMENTS' },
  { Id: 28681, Code: '9275', Name: 'Attendant, theatre' },
  { Id: 28682, Code: '9275', Name: 'Balloonist' },
  { Id: 28683, Code: '9275', Name: 'Boy, call, ENTERTAINMENT' },
  { Id: 28684, Code: '9275', Name: 'Coat, blue, holiday camp' },
  { Id: 28685, Code: '9275', Name: 'Coat, red, holiday camp' },
  { Id: 28686, Code: '9275', Name: 'Crew, balloon' },
  { Id: 28687, Code: '9275', Name: 'Decorator, balloon' },
  { Id: 28688, Code: '9275', Name: 'Decorator, party' },
  { Id: 28689, Code: '9275', Name: 'Foreman, cinema' },
  { Id: 28690, Code: '9275', Name: 'Foreman, ENTERTAINMENT' },
  { Id: 28691, Code: '9275', Name: 'Hand, circus' },
  { Id: 28692, Code: '9275', Name: 'Hand, fairground' },
  { Id: 28693, Code: '9275', Name: 'Holder, stall, ENTERTAINMENT' },
  { Id: 28694, Code: '9275', Name: 'Marshall, lane' },
  { Id: 28695, Code: '9275', Name: 'Multifunctional, cinema' },
  { Id: 28696, Code: '9275', Name: 'Operative, cinema' },
  { Id: 28697, Code: '9275', Name: 'Operator, ride' },
  { Id: 28698, Code: '9275', Name: 'Operator, tow, ski' },
  { Id: 28699, Code: '9275', Name: 'Painter, face' },
  { Id: 28700, Code: '9275', Name: 'Roadie' },
  { Id: 28701, Code: '9275', Name: 'Steward, concert' },
  { Id: 28702, Code: '9275', Name: 'Steward, ground, sports' },
  { Id: 28703, Code: '9275', Name: 'Steward, sports ground' },
  { Id: 28704, Code: '9275', Name: 'Superintendent, range' },
  { Id: 28705, Code: '9275', Name: 'Supervisor, arcade, amusement' },
  { Id: 28706, Code: '9275', Name: 'Usher' },
  { Id: 28707, Code: '9275', Name: 'Usherette' },
  { Id: 28708, Code: '9275', Name: 'Worker, arcade, amusement' },
  { Id: 28709, Code: '9275', Name: 'Worker, camp, holiday' },
  { Id: 28710, Code: '9275', Name: 'Worker, centre, holiday' },
  { Id: 28711, Code: '9275', Name: 'Worker, centre, leisure' },
  { Id: 28712, Code: '9275', Name: 'Worker, cinema' },
  { Id: 28713, Code: '9275', Name: 'Worker, fairground' },
  { Id: 28714, Code: '9275', Name: 'Worker, park, theme' },
  { Id: 28715, Code: '9275', Name: 'Worker, range, driving' },
  { Id: 28716, Code: '9275', Name: 'Worker, range, firing' },
  { Id: 28717, Code: '9275', Name: 'Worker, range, shooting' },
  { Id: 28718, Code: '9279', Name: 'Artist, striptease' },
  { Id: 28719, Code: '9279', Name: 'Assistant, bingo' },
  { Id: 28720, Code: '9279', Name: 'Assistant, general, hotel' },
  { Id: 28721, Code: '9279', Name: 'Assistant, hotel' },
  { Id: 28722, Code: '9279', Name: 'Assistant, sales, book, bingo hall' },
  { Id: 28723, Code: '9279', Name: 'Astrologer' },
  { Id: 28724, Code: '9279', Name: 'Attendant, bath' },
  { Id: 28725, Code: '9279', Name: 'Attendant, baths' },
  { Id: 28726, Code: '9279', Name: 'Attendant, bingo' },
  { Id: 28727, Code: '9279', Name: 'Attendant, chair' },
  { Id: 28728, Code: '9279', Name: 'Attendant, coach' },
  { Id: 28729, Code: '9279', Name: 'Attendant, hall, bingo' },
  { Id: 28730, Code: '9279', Name: 'Attendant, hostel' },
  { Id: 28731, Code: '9279', Name: 'Attendant, house, bath' },
  { Id: 28732, Code: '9279', Name: 'Attendant, house, local government' },
  { Id: 28733, Code: '9279', Name: 'Attendant, hut, beach' },
  { Id: 28734, Code: '9279', Name: 'Attendant, lobby' },
  { Id: 28735, Code: '9279', Name: 'Attendant, room, bath' },
  { Id: 28736, Code: '9279', Name: 'Attendant, room, changing' },
  { Id: 28737, Code: '9279', Name: 'Attendant, room, dressing' },
  { Id: 28738, Code: '9279', Name: 'Attendant, room, rest' },
  { Id: 28739, Code: '9279', Name: 'Attendant, seat' },
  { Id: 28740, Code: '9279', Name: 'Bathman, baths' },
  { Id: 28741, Code: '9279', Name: 'Boy, clapper' },
  { Id: 28742, Code: '9279', Name: 'Caller, bingo' },
  { Id: 28743, Code: '9279', Name: 'Checker, bingo hall' },
  { Id: 28744, Code: '9279', Name: 'Checker, ticket, ENTERTAINMENT' },
  { Id: 28745, Code: '9279', Name: 'Clairvoyant' },
  { Id: 28746, Code: '9279', Name: 'Clapper-loader' },
  { Id: 28747, Code: '9279', Name: 'Collector, ticket' },
  { Id: 28748, Code: '9279', Name: 'Consultant, shui, feng' },
  { Id: 28749, Code: '9279', Name: 'Dancer, lap' },
  { Id: 28750, Code: '9279', Name: 'Dancer, pole' },
  { Id: 28751, Code: '9279', Name: 'Dancer, table' },
  { Id: 28752, Code: '9279', Name: 'Dayman, theatre' },
  { Id: 28753, Code: '9279', Name: 'Diviner, water' },
  { Id: 28754, Code: '9279', Name: 'Escort, ENTERTAINMENT' },
  { Id: 28755, Code: '9279', Name: 'Examiner, ticket, ENTERTAINMENT' },
  { Id: 28756, Code: '9279', Name: 'Foreman, baths' },
  { Id: 28757, Code: '9279', Name: 'Grip' },
  { Id: 28758, Code: '9279', Name: 'Grip, key' },
  { Id: 28759, Code: '9279', Name: 'Hand, scene' },
  { Id: 28760, Code: '9279', Name: 'Hand, stage, ENTERTAINMENT' },
  { Id: 28761, Code: '9279', Name: 'Healer, reiki' },
  { Id: 28762, Code: '9279', Name: 'Healer, spiritual' },
  { Id: 28763, Code: '9279', Name: 'Helper, club, youth' },
  { Id: 28764, Code: '9279', Name: 'Inspector, chair, deck' },
  { Id: 28765, Code: '9279', Name: 'Inspector, ticket, chair' },
  { Id: 28766, Code: '9279', Name: 'Kissogram' },
  { Id: 28767, Code: '9279', Name: 'Knocker-up' },
  { Id: 28768, Code: '9279', Name: 'Loader, clapper' },
  { Id: 28769, Code: '9279', Name: 'Man, lobby' },
  { Id: 28770, Code: '9279', Name: 'Man, property' },
  { Id: 28771, Code: '9279', Name: 'Marker, billiard' },
  {
    Id: 28772,
    Code: '9279',
    Name: 'Marker, board, bookmakers, turf accountants'
  },
  { Id: 28773, Code: '9279', Name: 'Master, reiki' },
  { Id: 28774, Code: '9279', Name: 'Medium' },
  { Id: 28775, Code: '9279', Name: 'Member, team, betting and gambling' },
  { Id: 28776, Code: '9279', Name: 'Operative, bingo' },
  { Id: 28777, Code: '9279', Name: 'Operator, bingo' },
  { Id: 28778, Code: '9279', Name: 'Operator, cam, web' },
  { Id: 28779, Code: '9279', Name: 'Operator, chat-line' },
  { Id: 28780, Code: '9279', Name: 'Operator, scoreboard' },
  { Id: 28781, Code: '9279', Name: 'Operator, turnstile' },
  { Id: 28782, Code: '9279', Name: 'Page, hotel' },
  { Id: 28783, Code: '9279', Name: 'Palmist' },
  { Id: 28784, Code: '9279', Name: 'Performer, web-cam' },
  { Id: 28785, Code: '9279', Name: 'Phrenologist' },
  { Id: 28786, Code: '9279', Name: 'Porter and liftman' },
  { Id: 28787, Code: '9279', Name: 'Porter, club' },
  { Id: 28788, Code: '9279', Name: 'Porter, hall' },
  { Id: 28789, Code: '9279', Name: 'Porter, head, hotel' },
  { Id: 28790, Code: '9279', Name: 'Porter, head, RESIDENTIAL BUILDINGS' },
  { Id: 28791, Code: '9279', Name: 'Porter, hostel' },
  { Id: 28792, Code: '9279', Name: 'Porter, hotel' },
  { Id: 28793, Code: '9279', Name: 'Porter, house' },
  { Id: 28794, Code: '9279', Name: 'Porter, lodging' },
  { Id: 28795, Code: '9279', Name: 'Porter, night' },
  { Id: 28796, Code: '9279', Name: 'Porter, resident' },
  { Id: 28797, Code: '9279', Name: 'Porter, RESIDENTIAL BUILDINGS' },
  { Id: 28798, Code: '9279', Name: 'Practitioner, reiki' },
  { Id: 28799, Code: '9279', Name: 'Practitioner, shui, feng' },
  { Id: 28800, Code: '9279', Name: 'Propman' },
  { Id: 28801, Code: '9279', Name: 'Propsman' },
  { Id: 28802, Code: '9279', Name: 'Psychologist, astrological' },
  { Id: 28803, Code: '9279', Name: 'Receptionist, cinema, theatre' },
  { Id: 28804, Code: '9279', Name: 'Seller, bingo hall' },
  { Id: 28805, Code: '9279', Name: 'Shifter, scene' },
  { Id: 28806, Code: '9279', Name: 'Sitter' },
  { Id: 28807, Code: '9279', Name: 'Starter, golf' },
  { Id: 28808, Code: '9279', Name: 'Steward, room, billiard' },
  { Id: 28809, Code: '9279', Name: 'Superintendent, bath' },
  { Id: 28810, Code: '9279', Name: 'Superintendent, baths' },
  { Id: 28811, Code: '9279', Name: 'Supervisor, field, playing' },
  { Id: 28812, Code: '9279', Name: 'Teller, fortune' },
  { Id: 28813, Code: '9279', Name: 'Washhouseman, baths' },
  { Id: 28814, Code: '9279', Name: 'Worker, hostel' },
  { Id: 28815, Code: '9279', Name: 'Worker, hotel' },
  { Id: 28816, Code: '9279', Name: 'N/A' }
];
